import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { Position, Elements } from 'react-flow-renderer';

import { findFeedback, getPotentialRelatives } from 'src/utils/docType.util';

import { defaultOptions } from './defaultOptions';

export const getInsightHierarchyElements = (
  docType: DoctypeFragment,
  docTypes: DoctypeFragment[],
): Elements => {
  const parents = nodeToArray(docType?.parents);
  const feedback = findFeedback(docTypes);
  const feedbackId = feedback?.id ?? '';

  const potentialRelatives = getPotentialRelatives(docTypes, docType);

  const feedbackElements: Elements = [{
    ...defaultOptions,
    id: feedbackId,
    type: 'custom',
    sourcePosition: Position.Bottom,
    data: {
      doctypeId: feedbackId,
      indexColumn: 0,
      level: 0,
      root: true,
    },
  }];

  const insightElements = parents.flatMap(
    (parent, index) => createInsightElements({
      docType,
      feedbackId,
      parentId: parent.id,
      index,
      isUnlinkableFromInsight: parents.length > 1,
    }),
  );

  const placeholderElements = !potentialRelatives.length
    ? []
    : createInsightElements({
      docType,
      feedbackId,
      index: parents.length,
    });

  return [
    ...feedbackElements,
    ...insightElements,
    ...placeholderElements,
  ];
};

type CreateInsightElementsFn = (options: {
  docType: DoctypeFragment;
  feedbackId: string;
  parentId?: string;
  index: number;
  isUnlinkableFromInsight?: boolean;
}) => Elements;

const createInsightElements: CreateInsightElementsFn = ({
  docType,
  feedbackId,
  parentId,
  index,
  isUnlinkableFromInsight = false,
}): Elements => {
  const doctypeId = docType.id;
  const insightId = `insight-${index}`;
  const asPlaceholder = !parentId;
  return [
    {
      ...defaultOptions,
      id: insightId,
      type: 'custom',
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
      data: {
        doctypeId,
        iteration: index + 1,
        level: 1,
        indexColumn: 0.1,
        indexChild: index * 1.8,
        asPlaceholder,
        rootId: feedbackId,
      },
    },
    {
      ...defaultOptions,
      id: parentId ?? 'add-parent',
      type: 'custom',
      targetPosition: Position.Left,
      data: {
        doctypeId: parentId,
        level: 0.3,
        indexColumn: 0.6,
        indexChild: index * 1.8,
        asPlaceholder,
        rootId: feedbackId,
        target: 'parent',
        isUnlinkableFromInsight,
      },
    },
    {
      id: `link-${feedbackId}-${insightId}`,
      source: feedbackId,
      target: insightId,
      type: 'customEdge',
      data: {
        asPlaceholder,
        rootId: feedbackId,
      },
    },
    {
      id: `link-${insightId}-${parentId}`,
      source: insightId,
      target: parentId ?? 'add-parent',
      type: 'customEdge',
      data: {
        asPlaceholder,
        rootId: feedbackId,
      },
    },
  ];
};
