import { DropdownCss } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  ${DropdownCss};
  overflow-y: hidden;
  /* allows horizontal scroll when not enough space */
  overflow-x: auto;
  display: flex;
  gap: 1px;
  background-color: ${p => p.theme.colors.background.disabled};
`;

export const Category = styled.div`
  padding: 8px;
  gap: 2px;
  display: flex;
  background-color: ${p => p.theme.colors.background.primary};

  &:empty {
    display: none;
  }
`;
