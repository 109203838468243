import { DocBaseFragment, ViewType } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { LinkIcon, PenIcon, TrashIcon, ImageIcon } from '@cycle-app/ui/icons';
import { Setter, nodeToArray, getDocFullUrl } from '@cycle-app/utilities';
import { FC, useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import EditProperty, { OnValueSelectedParams } from 'src/components/EditProperty/EditProperty';
import { Events, Methods, Sources } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { useGetDoctypeAttributeDefinitions } from 'src/hooks/api/cache/cacheDoctype';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { usePageId } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { isAttributeScalar } from 'src/utils/attributes.util';
import { getEditCoverAction } from 'src/utils/doc.util';
import { getDocSlug } from 'src/utils/slug.util';

export interface Props {
  doc: DocBaseFragment;
  setVisible: Setter<boolean>;
  context?: 'doc-panel' | 'doc-item';
  onAddCoverClicked?: VoidFunction;
  onDelete: VoidFunction;
  viewType?: ViewType;
  onSelectProperty?: (isNotCompatible: boolean) => void;
}

const DocOptionsMenu: FC<Props> = ({
  doc,
  setVisible,
  context = 'doc-item',
  onAddCoverClicked,
  onDelete,
  viewType,
  onSelectProperty,
}) => {
  const getUrl = useUrl();
  const pageId = usePageId();
  const getDoctypeAttributes = useGetDoctypeAttributeDefinitions();

  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeDocAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);

  const copyToClipboard = useCopyToClipboard({
    successNotification: `Link to ${doc?._docKey} copied to clipboard!`,
  });

  const onCopyLinkClicked = useCallback(() => {
    const docUrl = getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
    const docFullUrl = getDocFullUrl(docUrl);
    copyToClipboard(docFullUrl);
    trackAnalytics(Events.DocShared, {
      method: Methods.UI,
      source: pageId === PageId.Board ? Sources.Board : Sources.DocPanel,
    });
    setVisible(false);
  }, [getUrl, doc, copyToClipboard, pageId, setVisible]);

  const onAddNewAttributeToDoc = useCallback(() => setVisible(false), [setVisible]);

  const [isEditPropertyShown, { setTrueCallback: showProperty }] = useOptimizedBooleanState(false);

  const attributeDefinitions = useMemo(() => {
    const attributes = getDoctypeAttributes(doc.doctype.id);
    return nodeToArray(attributes) || [];
  }, [doc.doctype.id, getDoctypeAttributes]);

  const options: Array<SelectOption> = useMemo(() => [
    ...(context === 'doc-item' ? [{
      value: 'edit-property',
      label: 'Edit property',
      icon: <PenIcon />,
      onSelect: showProperty,
    }] : []),
    ...(viewType !== ViewType.List ? [{
      value: 'edit-cover',
      label: getEditCoverAction(doc.cover?.url),
      icon: <ImageIcon />,
      onSelect: () => {
        onAddCoverClicked?.();
        setVisible(false);
      },
    }] : []),
    {
      value: 'copyLink',
      label: 'Copy link',
      icon: <LinkIcon />,
      onSelect: onCopyLinkClicked,
    },
    {
      value: 'delete',
      label: 'Delete',
      variant: 'danger',
      icon: <TrashIcon />,
      onSelect: onDelete,
    },
  ], [onDelete, showProperty, setVisible, onAddCoverClicked, context, onCopyLinkClicked, doc.cover?.url, viewType]);

  const onPropertyUpdated = useCallback(async ({
    attributeDefinition,
    propertyValue,
    isValueRemoved,
    notCompatible,
  }: OnValueSelectedParams) => {
    onSelectProperty?.(!!notCompatible);

    if (isValueRemoved && !propertyValue) {
      // A singleSelect attribute is set to none
      const docAttribute = nodeToArray(doc.attributes).find(a => a.definition.id === attributeDefinition.id);
      if (docAttribute?.__typename !== 'DocAttributeSingleSelect' || !docAttribute.selectValue?.id) return;

      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: docAttribute.selectValue?.id,
        notCompatible,
      });
    }

    if (!propertyValue) return;

    if (isAttributeScalar(attributeDefinition)) {
      await changeDocAttributeValueDebounced({
        doc,
        attributeDefinition,
        value: propertyValue,
      });
    } else if (isValueRemoved) {
      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: propertyValue,
        notCompatible,
      });

      if (attributeDefinition.__typename !== 'AttributeMultiSelectDefinition') {
        setVisible(false);
      }
    } else {
      await changeDocAttributeValue({
        doc,
        attributeDefinition,
        value: propertyValue,
        notCompatible,
      });

      if (attributeDefinition.__typename !== 'AttributeMultiSelectDefinition') {
        setVisible(false);
      }
    }
  }, [changeDocAttributeValue, changeDocAttributeValueDebounced, doc, onSelectProperty, removeDocAttributeValue, setVisible]);

  return (
    <div
      role="none"
      onClick={(e) => { e.stopPropagation(); }}
    >
      {isEditPropertyShown
        ? (
          <EditProperty
            possibleAttributes={attributeDefinitions}
            docId={doc.id}
            onValueUpdated={onPropertyUpdated}
            onAddNewAttributeToDoc={onAddNewAttributeToDoc}
          />
        ) : (
          <SelectPanel
            hideSearch
            options={options}
            onOptionChange={({ value: selectedValue }) => {
              const option = options.find(({ value }) => value === selectedValue);
              option?.onSelect?.();
            }}
          />
        )}
    </div>
  );
};

export default DocOptionsMenu;
