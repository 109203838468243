import {
  OnboardUserDocument,
  ProductCustomersDocument,
  ProductCustomersFragment,
  ProductCustomersQueryVariables,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { defaultCustomersPagination } from 'src/utils/pagination.util';

import { useProductFromInitial } from '../useProductFromInitial';

export const useOnboardUser = () => {
  const { product } = useProductFromInitial();
  const [mutation, { loading }] = useSafeMutation(OnboardUserDocument, {
    update: (cache, { data }) => {
      if (product && data?.onboardUser?.id) {
        cache.modify({
          id: cache.identify(product),
          fields: {
            users: (users, { toReference }) => {
              if (data.onboardUser?.id) {
                const node = toReference(data.onboardUser.id);
                return {
                  ...users,
                  edges: [
                    {
                      __typename: 'UserEdge',
                      node,
                    },
                    ...users.edges,
                  ],
                };
              }
              return users;
            },
          },
        });
        const customersQuery = cache.readQuery<{ node: ProductCustomersFragment }, ProductCustomersQueryVariables>({
          query: ProductCustomersDocument,
          variables: {
            productId: product.id,
            ...defaultCustomersPagination,
          },
        });
        if (customersQuery?.node) {
          const customer = customersQuery.node.customers.edges.find(({ node }) => node.email === data.onboardUser?.email);
          if (customer?.node) {
            cache.modify({
              id: cache.identify(customer.node),
              fields: {
                name: () => [data.onboardUser?.firstName, data.onboardUser?.lastName].join(' '),
              },
            });
          }
        }
      }
    },
  });

  async function onboardUser() {
    const { data } = await mutation();
    return data?.onboardUser?.onboarded;
  }

  return {
    onboardUser,
    loading,
  };
};
