import { LinkIcon } from '@cycle-app/ui/icons';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';

import { StyledLink } from './SettingsDocType.styles';

export const CustomersLink = () => {
  const { getUrl } = useNavigate();
  return (
    <StyledLink to={getUrl(PageId.SettingsCustomers)}>
      <LinkIcon />
      <span>
        Linked to
        {' '}
        <strong>Customers</strong>
      </span>
    </StyledLink>
  );
};
