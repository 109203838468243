import {
  StepDetails,
  Tour,
  TourStep,
  TourName,
} from 'src/types/productTour.types';

export const FIRST_TOUR: Tour = {
  name: TourName.FIRST_TOUR,
  step: 0,
  steps: [
    TourStep.CreateFirstFeedback,
    TourStep.SeeProperties,
    TourStep.WriteDocContent,
    TourStep.OpenFirstFeedback,
    TourStep.HighlightFeedbackText,
    TourStep.TurnHighlightIntoInsight,
    TourStep.SetNewInsightInitiative,
    TourStep.SetNewInsightProperties,
    TourStep.SeeCreatedInsight,
    TourStep.ProcessFirstFeedback,
    TourStep.StartGamification,
  ],
};

export const TOURS = [FIRST_TOUR];

export const STEP: Record<TourStep, StepDetails> = {
  [TourStep.CreateFirstFeedback]: {
    chapter: '1/3',
    frameGap: 16,
    tooltipPosition: 'top',
    text: "Let's create your first feedback together. That's the best way to get started in Cycle.",
    title: 'Create your first feedback',
  },
  [TourStep.SeeProperties]: {
    actions: ['next'],
    chapter: '1/3',
    frameGap: 32,
    text: "By default, you're both Customer and Assignee of your own feedback.",
    title: 'Create your first feedback',
    tooltipPosition: 'bottom-start',
  },
  [TourStep.WriteDocContent]: {
    chapter: '1/3',
    frameGap: [16, 16, 64, 16],
    tooltipPosition: 'top',
    text: 'Time to write some product feedback. Follow the instructions and let it all out!',
    title: 'Create your first feedback',
  },
  [TourStep.OpenFirstFeedback]: {
    chapter: '2/3',
    frameGap: 8,
    tooltipPosition: 'bottom-start',
    text: "Well done, you created your first feedback. Let's open it and create your first insight now.",
    title: 'Extract an insight',
  },
  [TourStep.HighlightFeedbackText]: {
    chapter: '2/3',
    frameGap: [72, 0, 0, 0],
    tooltipPosition: 'top',
    text: 'To create your first insight, just highlight some of your text and follow the instructions.',
    title: 'Extract an insight',
  },
  [TourStep.TurnHighlightIntoInsight]: {
    actions: ['back'],
    chapter: '2/3',
    frameGap: [64, 0, 0, 0],
    tooltipPosition: 'top',
    text: 'Now click on the Insight button',
    title: 'Extract an insight',
  },
  [TourStep.SetNewInsightInitiative]: {
    // actions: ['back'],
    chapter: '2/3',
    frameGap: [8, 16, 8, 16],
    tooltipPosition: 'top',
    text: 'Link to an initiative (existing or new)',
    title: 'Extract an insight',
  },
  [TourStep.SetNewInsightProperties]: {
    // actions: ['back'],
    chapter: '2/3',
    frameGap: 8,
    tooltipPosition: 'top',
    text: 'Confirm your insight creation',
    title: 'Extract an insight',
  },
  [TourStep.SeeCreatedInsight]: {
    actions: ['next'],
    chapter: '2/3',
    frameGap: 0,
    tooltipPosition: 'bottom-start',
    text: (name: string) => `Well done, ${name}! You just created your first insight 🎉`,
    title: 'Extract an insight',
  },
  [TourStep.ProcessFirstFeedback]: {
    chapter: '3/3',
    frameGap: 8,
    tooltipPosition: 'bottom-start',
    text: "Now just mark your feedback as processed by clicking here. Note that you'll be able to come back to it later if you want.",
    title: 'Mark your feedback as processed',
  },
  [TourStep.StartGamification]: {
    chapter: '3/3',
    frameGap: 0,
    tooltipPosition: 'top',
    text: "You're now ready to play around with Cycle. Open the Learning center to get started.",
    title: 'Congrats ! 🎉',
  },
};
