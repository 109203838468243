import { ActionButton } from '@cycle-app/ui';
import {
  ImageIcon,
  TrashIcon,
  ExpandIcon,
} from '@cycle-app/ui/icons';
import { VFC } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import CoverDropzoneOverlay from 'src/components/CoverDropzoneOverlay/CoverDropzoneOverlay';
import { ImageMenu } from 'src/components/ImageMenu';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { CoverContainer, Cover, FullCoverModal } from './DocPanelCover.styles';

interface Props {
  docId: string;
  coverUrl: string;
  isDragActive: boolean;
  getDropzoneProps: () => DropzoneRootProps;
  onUpdateCoverClicked: VoidFunction;
  className?: string;
  withNonConsistentDesign?: boolean;
  horizontalLayout?: boolean;
}
const DocPanelCover: VFC<Props> = ({
  docId,
  coverUrl,
  isDragActive,
  getDropzoneProps,
  onUpdateCoverClicked,
  className,
  withNonConsistentDesign,
  horizontalLayout,
}) => {
  const { removeDocCover } = useDocCoverMutations(docId);

  const [isCoverModalVisible, {
    setFalseCallback: hideCoverModal,
    setTrueCallback: showCoverModal,
  }] = useOptimizedBooleanState(false);

  return (
    <CoverContainer
      withNonConsistentDesign={withNonConsistentDesign}
      className={className}
      {...getDropzoneProps?.()}
    >
      {isDragActive && (
        <CoverDropzoneOverlay
          label="Update cover"
          noBorderRadius={withNonConsistentDesign}
          horizontalLayout={horizontalLayout}
        />
      )}

      <Cover src={coverUrl} />

      <ImageMenu forceVisible={isCoverModalVisible}>
        <ActionButton
          tooltip="Open"
          tooltipPlacement="top"
          onClick={showCoverModal}
        >
          <ExpandIcon />
        </ActionButton>

        <ActionButton
          tooltip="Update"
          tooltipPlacement="top"
          onClick={onUpdateCoverClicked}
        >
          <ImageIcon />
        </ActionButton>

        <ActionButton
          tooltip="Delete"
          tooltipPlacement="top"
          onClick={removeDocCover}
        >
          <TrashIcon />
        </ActionButton>
      </ImageMenu>

      {isCoverModalVisible && (
        <FullCoverModal hide={hideCoverModal}>
          <img src={coverUrl} alt="" />
        </FullCoverModal>
      )}
    </CoverContainer>
  );
};

export default DocPanelCover;
