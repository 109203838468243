import { DoctypeType, ViewType } from '@cycle-app/graphql-codegen';
import { Button, Emoji, ViewCardSkeleton, InsightCardSkeleton } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, useMemo, FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useDocTypeInsight, useDocV2, useOptimizedBooleanState } from 'src/hooks';
import { useUpdateDocCustomer } from 'src/hooks/api/mutations/useUpdateDocCustomer';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useBuiltIntSpecificAttributes } from 'src/hooks/api/useAttributes';
import { usePageId } from 'src/hooks/usePageId';
import { Layer } from 'src/types/layers.types';
import { getDocTypeNames } from 'src/utils/docType.util';

import { AddButton, DoctypeInfo, DoctypeLabel } from '../DocHierarchy/DocHierarchyGroup/DocHierarchyGroup.styles';
import DocSearchDropdown from '../DocSearchDropdown/DocSearchDropdown';
import InputLinkDoc from '../InputLinkDoc/InputLinkDoc';
import { InsightCreateModal } from '../InsightCreateModal';
import { CustomerChidrenTabsCounter } from './CustomerChidrenTabsCounter';
import { HierarchyContainer, StyledTab, TabList, Tabs } from './CustomerChildrenTabs.styles';
import { CustomerChildrenTabHierarchy } from './CustomerChildrenTabsHierarchy';

interface Props {
  customerId: string;
}

export const CustomerChildrenTabs: FC<Props> = ({ customerId }) => {
  const pageId = usePageId();
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const { doc: parentDoc } = useDocV2(insightDocParentId);
  const { insight } = useDocTypeInsight();
  const [isDocSearchVisible, {
    setFalseCallback: setDocSearchHidden, setTrueCallback: setDocSearchVisible,
  }] = useOptimizedBooleanState(false);
  const { customerAttribute } = useBuiltIntSpecificAttributes();
  const doctypes = useMemo(() => nodeToArray(customerAttribute?.doctypes), [customerAttribute]);
  const [activeDoctypeId, setActiveDoctypeId] = useState(doctypes[0]?.id);
  const [inputDoctypeId, setInputDoctypeId] = useState('');
  const { linkDocCustomer } = useUpdateDocCustomer();
  const { customer } = useCustomer(customerId);
  const possibleDoctypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);

  const isInDocView = [PageId.Doc, PageId.DocFullPage].includes(pageId);
  const activeDoctype = doctypes.find(({ id }) => activeDoctypeId === id);
  return (
    <>
      <Tabs>
        <TabList>
          {doctypes.map((doctype) => (
            <StyledTab
              key={doctype.id}
              $isActive={activeDoctype?.id === doctype.id}
              forwardedAs="div"
            >
              <Button
                variant="nospace"
                onClick={() => {
                  setActiveDoctypeId(doctype.id);
                  setInputDoctypeId('');
                }}
              >
                <DoctypeInfo>
                  <Emoji emoji={doctype.emoji} />
                  <DoctypeLabel>
                    {doctype.name}
                  </DoctypeLabel>
                  <CustomerChidrenTabsCounter customerId={customerId} doctypeId={doctype.id} />
                </DoctypeInfo>
              </Button>
              {doctype.type === DoctypeType.Insight ? (
                <InsightCreateModal
                  defaultCustomerId={customerId}
                  dropdownProps={{
                    placement: 'bottom',
                  }}
                  hide={() => setInsightDocParentId('')}
                  isOpen={!!insightDocParentId}
                  isCustomerReadonly
                  onBack={() => {
                    setInsightDocParentId('');
                    setDocSearchVisible();
                  }}
                  parentDoc={parentDoc}
                >
                  <DocSearchDropdown
                    childDoctypeId={insight?.id}
                    dropdownProps={{
                      placement: 'bottom',
                      layer: Layer.ModalZ2,
                    }}
                    hideSearchDropdown={setDocSearchHidden}
                    isSearchDropdownVisible={isDocSearchVisible}
                    onAdd={(docId) => {
                      setInsightDocParentId(docId);
                      setDocSearchHidden();
                    }}
                    possibleDoctypes={possibleDoctypes}
                    placeholder={getDocTypeNames(possibleDoctypes, {
                      prefix: 'Search',
                      suffix: possibleDoctypes.length > 1 ? 's or add new' : ' or add new',
                    })}
                  >
                    <AddButton
                      forceFocus={isDocSearchVisible || !!insightDocParentId}
                      onClick={() => {
                        setActiveDoctypeId(doctype.id);
                        setDocSearchVisible();
                      }}
                      tooltipPlacement="top"
                      tooltip={`Add ${doctype.name}`}
                    >
                      <AddIcon />
                    </AddButton>
                  </DocSearchDropdown>
                </InsightCreateModal>
              ) : (
                <AddButton
                  onClick={() => {
                    setActiveDoctypeId(doctype.id);
                    setInputDoctypeId(doctype.id);
                  }}
                  tooltipPlacement="top"
                  tooltip={`Add ${doctype.name}`}
                >
                  <AddIcon />
                </AddButton>
              )}
            </StyledTab>
          ))}
        </TabList>
        {activeDoctype && customer && (
          <HierarchyContainer key={activeDoctype.id}>
            {activeDoctype.id === inputDoctypeId && activeDoctype.type !== DoctypeType.Insight && (
              <InputLinkDoc
                autoFocus
                label={`Add a new ${activeDoctype.name}...`}
                placeholder={`Add a new ${activeDoctype.name}...`}
                hideInput={() => setInputDoctypeId('')}
                onDocLinked={(docId, doctypeId, created, doc) => linkDocCustomer({
                  customer,
                  created,
                  docId,
                  doctypeId,
                  doc,
                })}
                doctype={activeDoctype}
                inheritedAttributes={[]}
                customerId={customerId}
                docFilter={(node) => !node.customer}
              />
            )}
            <CustomerChildrenTabHierarchy
              customerId={customerId}
              doctype={activeDoctype}
              isInDocView={isInDocView}
              onDocLinked={(docId, doctypeId, created, doc) => linkDocCustomer({
                customer,
                created,
                docId,
                doctypeId,
                doc,
              })}
              docsSkeleton={activeDoctype.type === DoctypeType.Insight ? (
                <>
                  <InsightCardSkeleton />
                  <InsightCardSkeleton />
                  <InsightCardSkeleton />
                </>
              ) : (
                <>
                  <ViewCardSkeleton viewType={ViewType.List} />
                  <ViewCardSkeleton viewType={ViewType.List} />
                  <ViewCardSkeleton viewType={ViewType.List} />
                </>
              )}
            />
          </HierarchyContainer>
        )}
      </Tabs>
    </>
  );
};
