import { LogoutIcon, NextArrowIcon } from '@cycle-app/ui/icons';
import { FeatureFlag, nodeToArray } from '@cycle-app/utilities';
import { useMemo, FC } from 'react';

import { DEFAULT_AVATARS } from 'src/constants/avatars.constants';
import { HELP_URL, REQUEST_ACCESS } from 'src/constants/contacts.constants';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks';
import { useMe } from 'src/hooks/api/useMe';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useIntercom } from 'src/hooks/useIntercom';
import { useLogout } from 'src/hooks/useLogout';
import { getLastView } from 'src/reactives/lastView.reactive';

import {
  AvatarImg,
  Body,
  Container,
  Content,
  Contacts,
  Header,
  Logo,
  LogoutButton,
  RequestButton,
  Title,
  Text,
  ContactTitle,
  ContactButton,
  LogoutButtonText,
} from './EmptyState.styles';

export const EmptyState: FC = () => {
  const { me } = useMe();
  const logout = useLogout();
  const { navigate } = useNavigate();
  const { isEnabled: isCreateWSEnabled } = useFeatureFlag(FeatureFlag.EmptyStateCreateWS);
  const { productSlug: lastProductSlug } = getLastView();
  const products = useMemo(() => nodeToArray(me.products), [me]);
  const hasWorkspaces = !!products.length;
  const productSlug = products.find(product => product.slug === lastProductSlug)
    ? lastProductSlug
    : products[0]?.slug;
  useIntercom();
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.3 },
      }}
    >
      <Header>
        <Logo animation="hover" full size={32} />
        <LogoutButton size={14} onClick={logout}>
          <LogoutButtonText>
            {`Connected as ${me.firstName} (${me.email})`}
          </LogoutButtonText>
          <LogoutIcon />
        </LogoutButton>
      </Header>
      <Body>
        <Content>
          <AvatarImg src={DEFAULT_AVATARS[0]} alt="avatar" />
          <div>
            <Title>
              {`Hey, ${me.firstName} 👋,`}
              <br />
              {hasWorkspaces
                ? 'Looks like you’re already logged in'
                : 'Looks like you no longer have access to any workspace'}
            </Title>
            <Text>
              Want a new workspace? You can request it below 👇
            </Text>
            <RequestButton
              size="M"
              onClick={() => window.open(REQUEST_ACCESS, '_black')}
            >
              Request new workspace
            </RequestButton>
            <Contacts>
              {isCreateWSEnabled ? (
                <>
                  {hasWorkspaces && (
                    <>
                      <ContactTitle>Want to go to your current workspace?</ContactTitle>
                      <ContactButton
                        size="M"
                        variant="nospace"
                        onClick={() => {
                          if (productSlug) {
                            navigate(PageId.Inbox, { productSlug });
                          } else {
                            navigate(PageId.Main);
                          }
                        }}
                      >
                        Go to Cycle
                        <NextArrowIcon />
                      </ContactButton>
                    </>
                  )}
                  <ContactTitle>Want to create a workspace with another account?</ContactTitle>
                  <ContactButton
                    size="M"
                    variant="nospace"
                    onClick={() => {
                      logout();
                      navigate(PageId.GetStarted);
                    }}
                  >
                    Log out
                    <NextArrowIcon />
                  </ContactButton>
                </>
              ) : (
                <>
                  <ContactTitle>Need help ?</ContactTitle>
                  <ContactButton size="M" variant="nospace" onClick={() => window.Intercom?.('show')}>
                    Talk to our team
                    <NextArrowIcon />
                  </ContactButton>
                  <ContactTitle>Learn more about Cycle</ContactTitle>
                  <ContactButton
                    size="M"
                    variant="nospace"
                    onClick={() => window.open(HELP_URL, '_black')}
                  >
                    Documentation
                    <NextArrowIcon />
                  </ContactButton>
                </>
              )}
            </Contacts>
          </div>
        </Content>
      </Body>
    </Container>
  );
};
