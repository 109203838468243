import { useLazyQuery, QueryLazyOptions, LazyQueryResult, LazyQueryHookOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useRef } from 'react';

import { useErrorToaster } from './useErrorToaster';

export const useSafeAsyncLazyQuery = <TData, TVariables>(
  document: DocumentNode,
  {
    onCompleted, onError, ...options
  }: LazyQueryHookOptions<{ node: TData }, TVariables> = {},
): [
    (options?: QueryLazyOptions<TVariables> | undefined) => Promise<TData | null>,
    LazyQueryResult<{ node: TData }, TVariables>,
  ] => {
  const { add: addErrorToaster } = useErrorToaster();
  const resolveRef = useRef<(value: TData | null) => void>();
  const promiseRef = useRef<Promise<TData | null>>();

  const [query, result] = useLazyQuery<{ node: TData }, TVariables>(document, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      onCompleted?.(data);
      resolveRef.current?.(data.node);
      if (data.node) return;
      addErrorToaster({ message: 'Server temporary unavailable, please try again later' });
    },
    onError: (data) => {
      onError?.(data);
      resolveRef.current?.(null);
      addErrorToaster({ message: 'Server temporary unavailable, please try again later' });
    },
    ...options,
  });

  const asyncQuery = (params?: QueryLazyOptions<TVariables>) => {
    promiseRef.current = new Promise(resolve => {
      resolveRef.current = resolve;
    });
    query(params);
    return promiseRef.current;
  };

  return [asyncQuery, result];
};
