import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { SWIMLANE_NO_VALUE_ID } from 'src/constants/boardGroups.constants';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import useBoardWithSwimlane from 'src/hooks/api/useBoardWithSwimlane';
import { setBoardDnd } from 'src/reactives/boardDnd.reactive';

import { OnItemsMovedParams, useGroupsDnd } from './useGroupsDnd';

export default function useSwimlaneGroupsDnd() {
  const {
    groups,
    swimlanes,
    boardConfigId,
    groupByProperty,
  } = useBoardWithSwimlane();
  const { moveSelectAttributeValue } = useAttributesMutations();

  const initialItemsCols = useMemo(() => ({
    header: groups
      .map(group => group?.propertyValue?.id)
      .filter(isPresent),
  }), [groups]);

  const onItemsMovedCols = useCallback(async ({
    activeId: valueId,
    itemsId,
    position,
  }: OnItemsMovedParams) => {
    setBoardDnd({ dragging: false });
    if (!boardConfigId) return;

    await moveSelectAttributeValue({
      attributeId: groupByProperty.id,
      valueId,
      position,
      sortedItems: itemsId,
      boardConfigId,
    });
  }, [boardConfigId, groupByProperty.id, moveSelectAttributeValue]);

  const {
    dndContextProps,
    activeId,
    items,
    overId: overIdCols,
  } = useGroupsDnd({
    initialItems: initialItemsCols,
    crossGroupStrategy: 'disabled',
    onStart: () => setBoardDnd({ dragging: true }),
    updateOverId: true,
    onItemsMoved: onItemsMovedCols,
  });

  const draggingGroup = useMemo(() => (
    activeId ? groups.find(g => g.propertyValue?.id === activeId) : null
  ), [groups, activeId]);

  const draggingGroupContent = useMemo(() => {
    if (!activeId) return [];

    return swimlanes.edges
      .map((swimlane) => (swimlane.node?.swimlaneDoc
        ? nodeToArray(
          swimlane.node.docGroups.edges.find(e => e.node.propertyValue?.id === activeId)?.node.docs,
        ) : null))
      .filter(isPresent);
  }, [swimlanes, activeId]);

  const overGroup = useMemo(() => (
    overIdCols ? groups.find(g => g.propertyValue?.id === overIdCols) : null
  ), [groups, overIdCols]);

  /**
   *  Get aggreagated data from dnd context
   */
  const getSwimlaneDataFromDndContext = useCallback((itemId: string) => {
    const swimlane = swimlanes.edges.find(e => {
      const swimlaneDocId = itemId === SWIMLANE_NO_VALUE_ID ? undefined : itemId;
      return e.node.swimlaneDoc?.id === swimlaneDocId;
    })?.node;
    if (!swimlane) return null;

    const groupNoValue = swimlane.docGroups.edges.find(e => !e.node.propertyValue)?.node;

    return {
      swimlaneId: swimlane.id,
      swimlaneDoc: swimlane.swimlaneDoc,
      doctypeChildrenId: swimlane.availableChildrenDoctypes.edges.map(e => e.node.id),
      swimlaneGroups: [
        ...groupNoValue ? [groupNoValue] : [],
        ...items.header
          .map(propertyId => swimlane.docGroups.edges.find(e => e.node.propertyValue?.id === propertyId))
          .filter(isPresent)
          .map(e => e.node),
      ],
    };
  }, [items.header, swimlanes.edges]);

  return {
    dndContextProps,
    items,
    activeId,

    draggingGroup,
    overGroup,
    draggingGroupContent,

    getSwimlaneDataFromDndContext,
  };
}
