import { LinearIssueFullFragment } from '@cycle-app/graphql-codegen';
import { SelectLine, SelectLineProps } from '@cycle-app/ui';
import { LinearIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { SlotText } from 'src/components/Integrations/IntegrationsCommon.styles';
import { LinearStatus } from 'src/components/LinearStatus/LinearStatus';

import { StartSlot } from '../GithubIssueSelectLine/GithubIssueSelectLine.styles';

type Props = {
  issue: LinearIssueFullFragment;
  isSelected: boolean;
  slotText?: string;
  extraProps: Omit<SelectLineProps, 'label' | 'isSelected' | 'startSlot'>;
};

export const LinearIssueSelectLine: FC<Props> = ({
  issue, extraProps, isSelected, slotText,
}) => (
  <SelectLine
    label={issue.title}
    isSelected={isSelected}
    startSlot={(
      <StartSlot>
        <LinearIcon />
        {!!slotText && <SlotText>{slotText}</SlotText>}
        {!!issue.status && <LinearStatus status={issue.status} />}
      </StartSlot>
      )}
    {...extraProps}
  />
);
