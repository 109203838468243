import { useEffect, useMemo, useRef, useState } from 'react';
import { WebsocketProvider } from 'y-websocket';
import { Doc } from 'yjs';

import { useLogout } from '../useLogout';

export interface RealtimeEditorConfig {
  yDoc: Doc | null;
  provider: WebsocketProvider | null;
  isSync: boolean;
}

interface Params {
  docId: string | undefined | null;
  onInit?: (provider: WebsocketProvider) => void;
  onSync?: (syncStatus: boolean) => void;
  isEnabled?: boolean;
}

export default function useRealtimeEditorConfig({
  docId,
  onInit,
  onSync,
  isEnabled = true,
}: Params): RealtimeEditorConfig {
  const collaborativeDoc = useMemo(() => (isEnabled ? new Doc() : null), [isEnabled, docId]);
  const [isSync, setIsSync] = useState(false);
  const previousClientID = useRef<number | null>(null);
  const logout = useLogout();
  const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  if (!auth?.token) {
    logout();
  }
  const yprovider = useMemo(() => {
    if (!docId || !isEnabled || !collaborativeDoc) {
      return null;
    }
    return new WebsocketProvider(
      process.env.WS_API_SYNC_SERVER ?? '',
      `sync/doc/${docId}`,
      collaborativeDoc,
      { params: { bearer: JSON.parse(localStorage.getItem('auth') || '{}').token } },
    );
  }, [docId, collaborativeDoc, isEnabled]);

  useEffect(() => {
    if (yprovider && collaborativeDoc) {
      setIsSync(yprovider.synced);
      previousClientID.current = collaborativeDoc.clientID;

      yprovider.on('status', (event: any) => {
        console.info(`Socket ${docId} - ClientId ${collaborativeDoc.clientID} - ${event.status}`);
      });

      yprovider.on('sync', (status: boolean) => {
        console.info(`Socket ${docId} - ClientId ${collaborativeDoc.clientID} - sync status: ${status}`);
        setIsSync(status);
      });
    }

    return () => {
      if (yprovider && collaborativeDoc) {
        console.info(`Socket ${docId} - ClientID ${collaborativeDoc.clientID} - destroyed`);
        yprovider.destroy();
      }
    };
  }, [yprovider, onInit, onSync, setIsSync, collaborativeDoc, docId]);

  return {
    yDoc: collaborativeDoc,
    provider: yprovider,
    isSync: previousClientID.current === collaborativeDoc?.clientID
      ? isSync
      : false,
  };
}
