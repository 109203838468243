import { CompanyLogo } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import {
  resetCompanyProfileModal,
  getCompanyProfileModal,
  setCustomerProfileModal,
} from 'src/reactives';

import useOptimizedBooleanState from '../../hooks/useOptimizedBooleanState';
import { Layer } from '../../types/layers.types';
import { CustomersAddCustomerModal } from '../CustomersList/CustomersAddCustomerModal';
import { CompanyAvatarEditable } from './CompanyAvatarEditable';
import { CompanyDetails } from './CompanyDetails';
import {
  StyledCustomersListPeople, AddPeopleContainer, PeopleCounter, AddPeopleAction, AddPeopleLabel, PeopleList, ProfileWrapper,
} from './CompanyProfile.styles';
import { CompanyProfileHeader } from './CompanyProfileHeader';
import { HeaderContainer } from './CustomerProfile.styles';

interface Props {
  companyId: string;
  className?: string;
  hideShareLink?: boolean;
  isHeaderCentered?: boolean;
  shyScrollDisabled?: boolean;
  scrollableElement?: HTMLElement | null;
}

export const CompanyProfile: FC<Props> = ({
  className,
  companyId,
  hideShareLink,
  isHeaderCentered,
  scrollableElement,
  shyScrollDisabled,
}) => {
  const {
    company, customers, isLoading, loadMoreCustomers, pageInfo, customersCount,
  } = useCompany(companyId);
  const [isCreateCustomerOpend, { toggleCallback: toggleCreateCustomer }] = useOptimizedBooleanState(false);

  return (
    <ProfileWrapper>
      <HeaderContainer className={className} $isCentered={isHeaderCentered}>
        {company?.isDefault
          ? <CompanyLogo company={company} />
          : <CompanyAvatarEditable company={company} />}
        {company && <CompanyProfileHeader company={company} />}
        {company && <CompanyDetails company={company} hideShareLink={hideShareLink} />}
      </HeaderContainer>
      <PeopleList $hideSeparator>
        <AddPeopleContainer>
          <AddPeopleLabel>
            People
          </AddPeopleLabel>
          <PeopleCounter>
            {customersCount}
          </PeopleCounter>
          <AddPeopleAction tooltip="Add someone" tooltipPlacement="top" onClick={() => toggleCreateCustomer()}>
            <AddIcon />
          </AddPeopleAction>
        </AddPeopleContainer>
      </PeopleList>
      <StyledCustomersListPeople
        isLoading={isLoading}
        hiddenData={{
          companies: true,
          header: true,
        }}
        loadMore={loadMoreCustomers}
        hasNextPage={pageInfo?.hasNextPage}
        customers={customers ?? []}
        searchText=""
        $hideSeparator
        onCustomerClick={customerId => {
          if (getCompanyProfileModal().isOpen) {
            resetCompanyProfileModal();
          }
          setCustomerProfileModal({
            customerId,
            isOpen: true,
          });
        }}
        scrollableElement={scrollableElement}
        shyScrollDisabled={shyScrollDisabled}
        nameCellSize={300}
        emailCellSize={200}
        actionsCellSize={50}
        isFullPage={false}
      />
      {isCreateCustomerOpend && company && (
        <CustomersAddCustomerModal layer={Layer.ModalZ2} defaultCompany={company} onClose={() => toggleCreateCustomer()} />
      )}
    </ProfileWrapper>
  );
};
