import { TextButtonCss, TextButton, typo, ShyScrollbar, CycleLogo } from '@cycle-app/ui';
import { InfoIconOutline, CaretIcon } from '@cycle-app/ui/icons';
import { mobile } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ScrollableContent = styled(ShyScrollbar)`
  flex: 1;
  background-color: ${p => p.theme.colors.settings.bg};
  max-height: 100vh;
  padding: 32px;
`;

export const Background = styled(CycleLogo)`
  position: absolute;
  opacity: .05;
  width: 1759px;
  transform: translate(-70%,-35%);
  z-index: 1;

  svg {
    height: 100%;
    width: 100%;
  }

  ${mobile} {
    width: 700px;
  }
`;

export const StyledScrollableContent = styled(ScrollableContent)`
  overflow-x: hidden;
  padding: 0;
  position: relative;
`;

export const StyledInfoIconOutline = styled(InfoIconOutline)`
  height: 12px;
  width: 12px;
`;

export const StyledCaretIcon = styled(CaretIcon) <{ $isUp?: boolean }>`
  ${p => p.$isUp && css`transform: rotate(180deg);`}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

const StyledTextButtonCss = css`
  ${TextButtonCss}
  ${typo.body400}
  --bgHover: ${p => p.theme.colors.background.tertiary};
  --colorHover: ${p => p.theme.colors.text.secondary};
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
  width: auto;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const StyledTextButton = styled(TextButton)`
  ${StyledTextButtonCss}
`;

export const BackButton = styled(Link)`
  ${StyledTextButtonCss}
  position: relative;
  z-index: 2;
`;

export const Centered = styled.div`
  flex: 1;
  max-width: 1180px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Main = styled.div`
  max-width: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 83px;
`;

export const LogoContainer = styled.div`
  display: inline-flex;
  position: relative;
`;

export const Title = styled.h1`
  ${typo.headerLarge}
  margin-top: 24px;
`;

export const Desc = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin: 12px auto 0;
  max-width: 320px;
`;
