import { IntegrationType, GithubRepositoryFragment, GithubAssignee } from '@cycle-app/graphql-codegen';
import { Range } from '@tiptap/core';
import { ReactRendererOptions } from '@tiptap/react';

export enum FrontEndIntegration {
  ZENDESK = 'ZENDESK',
  CHROME = 'CHROME',
  ZAPIER = 'ZAPIER',
  HUBSPOT = 'HUBSPOT',
  JIRA = 'JIRA',
  GITLAB = 'GITLAB',
  SHORTCUT = 'SHORTCUT',
  CYCLE = 'CYCLE',
}

export type Integration = IntegrationType | FrontEndIntegration;

/**
 * Mainly used for the callback page url
 * e.g. : `/callback/github`
 */
export enum Provider {
  GITHUB = 'github',
  LINEAR = 'linear',
  NOTION = 'notion',
  FIGMA = 'figma',
  INTERCOM = 'intercom',
  MAIL = 'mail',
  HUBSPOT = 'hubspot',
}

export type InstallCodeProviderParams = IntegrationType.Linear | IntegrationType.Notion | IntegrationType.Figma | IntegrationType.Intercom | IntegrationType.Hubspot;

export type CreateGithubIssueParams = {
  title?: string;
  repoName?: string;
  description?: string;
  assignees?: GithubAssignee[];
  status?: string | null;
};

export type CreateGithubIssueFormValues = {
  title?: string;
  repository?: GithubRepositoryFragment;
  description?: string;
  assignees?: GithubAssignee[];
  status?: string | null;
};

export type IntegrationMentionRendererProps = ReactRendererOptions & {
  extensionName: string;
  range: Range;
  query: string;
};

export type GithubStatus = {
  id: string;
  name: string;
  name_html: string;
};
