import { CaretIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { ProductCurrent, ProductsPanel } from '../Product';
import { ItemButton } from './Settings.styles';

export const ProductSelect = () => {
  return (
    <ToggleDropdown
      layer={Layer.DropdownModalZ2}
      button={props => (
        <ItemButton {...props}>
          <ProductCurrent />
          <CaretIcon
            size={8}
            direction={props['data-active'] ? 'top' : 'bottom'}
          />
        </ItemButton>
      )}
      content={props => (
        <ProductsPanel onDone={props.hide} />
      )}
    />
  );
};
