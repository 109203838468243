import { DotsMenuProps, SelectOption } from '@cycle-app/ui';
import { PenIcon, TrashIcon, DuplicateIcon, StarOutlineIcon, WheelIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { FC, useMemo } from 'react';

import { DeleteBoardModal } from 'src/app/Main/Board/DeleteBoardModal/DeleteBoardModal';
import DuplicateBoardModal from 'src/app/Main/Board/DuplicateBoardModal/DuplicateBoardModal';
import EditBoardModal from 'src/app/Main/Board/EditBoardModal/EditBoardModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useBoardStar } from 'src/hooks/api/mutations/boards/useBoardStar';
import { useIsBoardStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';

import { setLimitationsModal } from '../../reactives/limitationsModal.reactive';

interface Props extends Omit<DotsMenuProps, 'options'> {
  boardId: string;
  onColoredBg?: boolean;
  onBoardRemoved?: VoidFunction;
  openBoardConfig?: VoidFunction;
}

const BoardOptions: FC<Props> = ({
  boardId,
  onColoredBg = false,
  openBoardConfig,
  ...props
}) => {
  const {
    star, unStar,
  } = useBoardStar();
  const isBoardStarred = useIsBoardStarred();

  const {
    canCreateBoard, canDeleteBoard, canUpdateBoard,
  } = useGetPermission();
  const { canPlanCreateBoard } = useGetBillingPermission();

  const [isEditModalVisible, {
    toggleCallback: toggleEditModal,
    setFalseCallback: hideEditModal,
  }] = useOptimizedBooleanState(false);

  const [isDuplicateModalVisible, {
    toggleCallback: toggleDuplicateModal,
    setFalseCallback: hideDuplicateModal,
  }] = useOptimizedBooleanState(false);

  const [isDeleteModalVisible, {
    toggleCallback: toggleModalDelete,
    setFalseCallback: hideDeleteModal,
  }] = useOptimizedBooleanState(false);

  const options = useMemo(() => {
    const result: SelectOption[] = [];

    if (canUpdateBoard && openBoardConfig) {
      result.push({
        value: 'settings',
        label: 'View settings',
        onSelect: openBoardConfig,
        icon: <WheelIcon />,
      });
    }

    if (canUpdateBoard && !openBoardConfig) {
      result.push({
        value: 'edit',
        label: 'Edit view',
        onSelect: toggleEditModal,
        icon: <PenIcon />,
      });
    }

    if (canCreateBoard) {
      result.push({
        value: 'duplicate',
        label: 'Duplicate',
        onSelect: () => {
          if (canPlanCreateBoard) {
            toggleDuplicateModal();
          } else {
            setLimitationsModal({
              action: 'BOARD_CREATE',
            });
          }
        },
        icon: <DuplicateIcon />,
      });
    }

    if (isBoardStarred(boardId) === true) {
      result.push({
        value: 'unstar',
        label: 'Unstar',
        onSelect: () => unStar(boardId),
        icon: <StarOutlineIcon />,
      });
    }

    if (isBoardStarred(boardId) === false) {
      result.push({
        value: 'star',
        label: 'Star',
        onSelect: () => star(boardId),
        icon: <StarOutlineIcon />,
      });
    }

    if (canDeleteBoard) {
      result.push({
        value: 'delete',
        label: 'Delete',
        onSelect: toggleModalDelete,
        icon: <TrashIcon />,
        variant: 'danger',
      });
    }

    return result;
  }, [
    boardId, canCreateBoard, canDeleteBoard, canUpdateBoard,
    star, unStar, isBoardStarred,
    toggleDuplicateModal, toggleEditModal, toggleModalDelete,
  ]);

  if (options.length === 0) {
    return null;
  }

  return (
    <>
      <DotsMenuLayer
        tooltip="View actions"
        ariaLabel="View actions"
        options={options}
        onColoredBg={onColoredBg}
        {...props}
      />
      <AnimatePresence>
        {isEditModalVisible && (
          <EditBoardModal
            boardId={boardId}
            onHide={hideEditModal}
          />
        )}

        {isDuplicateModalVisible && (
          <DuplicateBoardModal
            boardId={boardId}
            onHide={hideDuplicateModal}
          />
        )}

        {isDeleteModalVisible && (
          <DeleteBoardModal
            boardId={boardId}
            onHide={hideDeleteModal}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default BoardOptions;
