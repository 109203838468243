import { typo, TextButton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { Heading } from '../Auth.styles';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Form = styled.form`
  display: grid;
  gap: 16px;
  min-width: 350px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const ResetPwdHeading = styled(Heading)`
  margin-bottom: 8px;
`;

export const ResetPwdHint = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.body400};
`;

export const ResetPwdActions = styled(Actions)`
  justify-content: flex-end;
`;

export const TextButtonStyled = styled(TextButton)`
  width: auto;
`;

export const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${mobile} {
    width: 100%;
  }
`;

export const LeftContent = styled.div`
  max-width: 400px;
`;

export const Right = styled.div`
  width: 50%;

  ${mobile} {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 48px;
`;
