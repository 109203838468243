import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const Direction: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.45096 14.929L6.57529 7.79061L13.8764 6.65803C13.9544 6.58951 14.0018 6.48915 14.0135 6.36779C14.0253 6.24642 14.0008 6.10866 13.9429 5.96936C13.885 5.83006 13.7958 5.69452 13.6849 5.57737C13.574 5.46022 13.4456 5.36589 13.3136 5.30459L0.747651 0.490566C0.630117 0.436045 0.513491 0.409461 0.407832 0.413109C0.302173 0.416757 0.210651 0.450526 0.14116 0.511505C0.0716684 0.572485 0.0262942 0.658843 0.00894902 0.763132C-0.00839618 0.867422 0.00280911 0.986513 0.0415973 1.11013L3.18211 14.1951C3.22576 14.3339 3.3026 14.4734 3.40435 14.5986C3.5061 14.7238 3.6289 14.8299 3.75949 14.9054C3.89008 14.9809 4.0235 15.023 4.14536 15.0272C4.26722 15.0313 4.37289 14.9974 4.45096 14.929Z" fill="currentColor" />
  </Svg>
);
