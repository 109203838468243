import { AddNewDocAttributeValue as AddNewDocPropertyValue } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useDocCreate } from 'src/hooks/doc/useDocCreate';

import { useCustomerDocFromCache } from '../api/cache/cacheCustomerDoc';
import { useUpdateChildCache } from '../api/cache/cacheHierarchy';
import { useDocInsightsCache } from './useDocInsights.cache';

type CreateInsightParams = {
  assignee: string;
  contentJSON: string;
  customerId: string;
  docLinkContent: string;
  docLinkSourceId?: string;
  doctypeId: string;
  parentId: string;
  properties: AddNewDocPropertyValue[];
  title: string;
  blockId?: string | null;
  sourceId?: string;
};

export const useDocInsightCreate = () => {
  const {
    create: createDoc, isLoading,
  } = useDocCreate();
  const { add } = useDocInsightsCache();
  const updateChild = useUpdateChildCache();
  const { addCustomerDoc } = useCustomerDocFromCache();

  const create = useCallback(async ({
    assignee,
    contentJSON,
    customerId,
    docLinkContent,
    docLinkSourceId,
    doctypeId,
    parentId,
    properties,
    title,
    blockId,
    sourceId,
  }: CreateInsightParams) => {
    const newInsight = await createDoc({
      assignee,
      attributes: properties,
      contentJSON,
      customerId,
      ...docLinkSourceId && {
        docLink: {
          sourceId: docLinkSourceId,
          blockId,
          content: docLinkContent,
        },
      },
      doctypeId,
      parentId,
      title,
      ...sourceId && {
        source: {
          sourceId,
        },
      },
    });
    if (!newInsight) return;
    if (docLinkSourceId && newInsight.docSource?.__typename) {
      // DocSource and DocTarget have the same id, just different type.
      // DocSource_{uuid}_{uuid}
      const decodedDocSourceId = atob(newInsight.docSource.id);
      add({
        feedbackId: docLinkSourceId,
        docTargetToAdd: {
          __typename: 'DocTarget',
          id: btoa(decodedDocSourceId.replace(newInsight.docSource.__typename, 'DocTarget')),
          content: newInsight.docSource.content,
          blockId: newInsight.docSource.blockId,
          doc: newInsight,
        },
      });
    }
    if (newInsight.customer?.id) {
      addCustomerDoc({
        doc: newInsight,
        customer: newInsight.customer,
      });
    }
    if (newInsight.parent?.id) {
      updateChild({
        action: 'add',
        docId: newInsight.id,
        parentId: newInsight.parent.id,
      });
    }
  }, [add, createDoc, updateChild, addCustomerDoc]);

  return {
    create,
    isLoading,
  };
};
