import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const PasswordIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.64756 3.17427C6.051 2.76087 6.74811 2.35484 8.00008 2.35484C9.25204 2.35484 9.94914 2.76087 10.3525 3.17426C10.6909 3.52095 10.8712 3.98689 10.9582 4.58098C11.0338 5.09689 11.0321 5.64435 11.0298 6.23267H4.97033C4.96804 5.64435 4.96632 5.09689 5.04192 4.58097C5.12898 3.98688 5.30924 3.52096 5.64756 3.17427ZM3.61523 6.23267C3.61247 5.65442 3.61109 5.00084 3.7014 4.38453C3.81111 3.63584 4.06534 2.85575 4.67792 2.22803C5.35905 1.53006 6.41692 1 8.00008 1C9.58322 1 10.6411 1.53006 11.3222 2.22804C11.9348 2.85576 12.189 3.63583 12.2987 4.38453C12.3891 5.00084 12.3877 5.65442 12.3849 6.23267H12.5784C13.4341 6.23267 14.1279 6.92639 14.1279 7.78216V12.5786C14.1279 13.916 13.0437 15.0001 11.7063 15.0001H4.29364C2.95625 15.0001 1.87207 13.916 1.87207 12.5786V7.78216C1.87207 6.92638 2.56582 6.23267 3.42157 6.23267H3.61523ZM3.22691 7.78216C3.22691 7.67465 3.31406 7.5875 3.42157 7.5875H12.5784C12.6859 7.5875 12.773 7.67464 12.773 7.78216V12.5786C12.773 13.1677 12.2955 13.6453 11.7063 13.6453H4.29364C3.70449 13.6453 3.22691 13.1677 3.22691 12.5786V7.78216Z" fill="#171618" />
  </Svg>
);
