import { Color } from '@cycle-app/graphql-codegen';
import { FC, ReactChild } from 'react';

import { Avatar } from '../Avatar/Avatar';
import { Tooltip } from '../Tooltip/Tooltip';
import {
  Container,
  UserName,
  AvatarContainer,
  BulletOnline,
  BulletOffline,
  BulletPending,
  Viewing,
  BulletContainer,
} from './MemberCard.styles';

export type MemberCardProps = {
  avatar?: string;
  color?: Color;
  email: string;
  mode: 'online' | 'offline' | 'pending';
  name?: string;
  viewing: ReactChild;
  onClick?: VoidFunction;
};

const bullets = {
  online: {
    tooltip: 'Online',
    component: <BulletOnline />,
  },
  offline: {
    tooltip: 'Offline',
    component: <BulletOffline />,
  },
  pending: {
    tooltip: 'Not signed up',
    component: <BulletPending />,
  },
};

export const MemberCard: FC<MemberCardProps> = ({
  avatar,
  color = Color.A,
  email,
  mode,
  name,
  viewing,
  onClick = () => undefined,
}) => (
  <Container $color={color} $pending={mode === 'pending'} onClick={onClick}>
    <AvatarContainer>
      <Avatar
        user={{
          id: Date.now().toString(),
          color,
          email,
          incognito: false,
          firstName: name,
        }}
        size={64}
        src={avatar}
        pending={mode === 'pending'}
      />
      <BulletContainer>
        <Tooltip placement="top" content={bullets[mode].tooltip}>
          {bullets[mode].component}
        </Tooltip>
      </BulletContainer>
    </AvatarContainer>
    <UserName>{name}</UserName>
    <Viewing>{viewing}</Viewing>
  </Container>
);
