import { MateFragment, MeFragment } from '@cycle-app/graphql-codegen';
import { Avatar, Warning } from '@cycle-app/ui';
import { isPresent } from 'ts-is-present';

export const INCOGNITO_NAME = 'Masked Cyclist';

export const getUserOptions = (users: MateFragment[], me?: MeFragment, showWarnings = true) => users
  .map(user => ({
    label: `${user.firstName} ${user.lastName} ${user.id === me?.id ? '(me)' : ''}`,
    value: user.id,
    icon: <Avatar user={user} size={20} />,
    end: showWarnings && !user._compatibleWithBoardConfig && <Warning tooltip="The doc will leave the view if you choose this value" />,
  }));

export const getUserLabel = (user?: MateFragment, isMe?: boolean) => {
  const label = (
    user?.firstName || user?.lastName
      ? [user?.firstName, user?.lastName].filter(isPresent).join(' ')
      : user?.email ?? ''
  );
  return isMe ? [label, '(me)'].join(' ') : label;
};
