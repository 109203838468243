import { CompanyFragment } from '@cycle-app/graphql-codegen/generated';
import { CellText, CompanyLogo } from '@cycle-app/ui';
import { ReactChild, FC } from 'react';

import { StyledCellContent } from './CustomerTableCompanyCell.styles';

type Props = {
  company: CompanyFragment;
  name: ReactChild;
  link?: ReactChild;
  onClick?: VoidFunction;
};

export const CustomerTableCompanyCell: FC<Props> = ({
  name, link, onClick, company,
}) => (
  <StyledCellContent onClick={onClick} $limitSize>
    <CompanyLogo size="S" company={company} />
    <CellText>
      {name}
      {link}
    </CellText>
  </StyledCellContent>
);
