import { ActionButton, typo, boxShadowZ1 } from '@cycle-app/ui';
import styled from 'styled-components';

import { Slot, Name } from 'src/utils/customers.util';

export const OpenCustomerButton = styled(ActionButton)`
  --size: 10px;
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  color: ${p => p.theme.colors.text.secondary};
  gap: 4px;
  height: 20px;
  ${typo.caption400}
  ${boxShadowZ1}
`;

export const StyledSlot = styled(Slot)`
  width: 100%;
  overflow: hidden;
`;

export const StyledName = styled(Name)`
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: auto;
`;

export const Email = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Label = styled.div`
  overflow: hidden;
  display: flex;
  gap: 8px;
  white-space: nowrap;
`;
