import { Color, MeFragment, Role, UserJourney } from '@cycle-app/graphql-codegen/generated';
import { Tooltip, Avatar } from '@cycle-app/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect } from 'react';

import useOptimizedBooleanState from '../../hooks/useOptimizedBooleanState';
import mate1 from './mate-1.jpg';
import mate2 from './mate-2.jpg';
import mate3 from './mate-3.jpg';
import {
  CollabIconContainer, CollabTooltipContainer, CollabTooltipContent,
  MatesContainer, StyledCurlyArrowIcon, StyledTooltip, TooltipContent,
} from './OnboardingCollabMates.styles';

export const mates: Omit<MeFragment, 'products'>[] = [
  {
    color: Color.C,
    email: 'email@email.com',
    id: 'mate-1',
    firstName: 'Jane',
    lastName: 'Doe',
    avatar: {
      url: mate1,
    },
    incognito: false,
    onboarded: true,
    role: Role.Admin,
    userJourney: UserJourney.Done,
  },
  {
    color: Color.B,
    email: 'email@email.com',
    id: 'mate-2',
    firstName: 'John',
    lastName: 'Smith',
    avatar: {
      url: mate2,
    },
    incognito: false,
    onboarded: true,
    role: Role.Admin,
    userJourney: UserJourney.Done,
  },
  {
    color: Color.J,
    email: 'email@email.com',
    id: 'mate-3',
    firstName: 'John',
    lastName: 'Doe',
    avatar: {
      url: mate3,
    },
    incognito: false,
    onboarded: true,
    role: Role.Admin,
    userJourney: UserJourney.Done,
  },
];

interface Props {
  hideTip?: boolean;
  className?: string;
}

export const OnboardingCollabMates: FC<Props> = ({
  children, hideTip, className,
}) => {
  const [isArrowVisible, { setTrueCallback: setShowArrow }] = useOptimizedBooleanState(false);
  useEffect(() => {
    setShowArrow();
  }, [setShowArrow]);
  return (
    <MatesContainer className={className}>
      {!hideTip && (
        <CollabTooltipContainer>
          <CollabTooltipContent>
            <StyledTooltip
              content={<TooltipContent>Cycle is real-time &ndash; you can work with your teammates at the same time</TooltipContent>}
              placement="top"
              visible
              withPortal
            >
              <CollabIconContainer>
                <AnimatePresence>
                  {isArrowVisible && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        translateY: 6,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                      }}
                    >
                      <StyledCurlyArrowIcon />
                    </motion.div>
                  )}
                </AnimatePresence>
              </CollabIconContainer>
            </StyledTooltip>
          </CollabTooltipContent>
        </CollabTooltipContainer>
      )}
      {mates.map(mate => (
        <Tooltip
          key={mate.id}
          content={[mate.firstName, mate.lastName].join(' ')}
          placement="bottom"
        >
          <Avatar user={mate} />
        </Tooltip>
      ))}
      {children}
    </MatesContainer>
  );
};
