/* eslint-disable jsx-a11y/no-static-element-interactions */
import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Emoji, Tooltip, SelectPanel, SelectOption } from '@cycle-app/ui';
import { AddIcon, CloseIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback, ReactNode } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { Link } from 'react-router-dom';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import useDoctypeParentsMutations from 'src/hooks/api/mutations/useDoctypeParentsMutations';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useUrl } from 'src/hooks/useUrl';
import { setDoctypeGraph, useGetDoctypeGraph } from 'src/reactives/doctypeGraph.reactive';
import { getPotentialRelatives, isInsight, isFeedback, isBuiltIn, isCustom } from 'src/utils/docType.util';
import { getNativeEmoji } from 'src/utils/emoji.util';
import { FlowData } from 'src/utils/flow.util';

import {
  AddAction,
  Container,
  Content,
  RemoveAction,
  Disc,
  ChildrenArea,
  FeedbackChildrenArea,
  ParentArea,
  TooltipContent,
  Text,
  DeleteConnexion,
  DeleteConnexionIconContainer,
  DeleteInfo,
  DeleteModalStyled,
  DeletePreviewBlock,
  DeletePreview,
  DeletePreviewContent,
  DeletePreviewContainer,
} from './FlowElement.styles';

const styles = {
  handle: (position: Position) => ({
    ...position === Position.Top && { top: -10 },
    ...position === Position.Right && { right: -10 },
    ...position === Position.Bottom && { bottom: -10 },
    ...position === Position.Left && { left: -10 },
  }),
};

const FlowElement = ({
  data: {
    doctypeId,
    root,
    asPlaceholder,
    phantom,
    iteration = 0,
    target,
    rootId,
    isUnlinkableFromInsight = true,
    insightParentId,
  },
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
  id: elementId,
}: NodeProps<FlowData>): ReactNode => {
  const currentDoctype = useDoctype();
  const view = currentDoctype ? 'doctype' : 'global';
  const { doctypes } = useProductDoctypesFull();
  const doctype = doctypes.find(d => doctypeId?.startsWith(d.id));
  const children = nodeToArray(doctype?.children);

  const {
    rootChildrenHover, rootParentIsHover,
  } = useGetDoctypeGraph();

  const {
    addDoctypeParent,
    removeDoctypeParent,
    loading,
  } = useDoctypeParentsMutations(currentDoctype);

  const getUrl = useUrl();

  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdownVisibility,
  }] = useOptimizedBooleanState(false);

  const onRelativeOptionAdded = useCallback(async (option: SelectOption) => {
    toggleDropdownVisibility();
    if (target === 'parent') {
      // addd selected to current
      await addDoctypeParent(option.value);
    } else if (target === 'children' && currentDoctype) {
      // add current to selected
      const doctypeChild = doctypes.find(d => d.id === option.value);
      await addDoctypeParent(currentDoctype.id, doctypeChild);
    }
  }, [addDoctypeParent, target, toggleDropdownVisibility, currentDoctype, doctypes]);

  const potentialRelatives = useMemo(() => getPotentialRelatives(doctypes, currentDoctype), [currentDoctype, doctypes]);

  const placeholderIsVisible = rootId === rootChildrenHover || !rootId;

  const parentPlaceholderIsVisible =
    isBuiltIn(doctype) && asPlaceholder
      ? placeholderIsVisible
      : (rootParentIsHover ||
        (!root && elementId !== 'current') ||
        !asPlaceholder);

  const [showWarningModal, {
    setTrueCallback: setShowWarningModal,
    setFalseCallback: setHideWarningModal,
  }] = useOptimizedBooleanState(false);
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation, setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);

  const handleConfirm = useCallback(async () => {
    if (target === 'parent' && doctype) {
      await removeDoctypeParent(doctype.id);
    } else if (target === 'children' && currentDoctype) {
      await removeDoctypeParent(currentDoctype.id, doctype);
    }
  }, [doctype, removeDoctypeParent, target, currentDoctype]);

  const currentName = `${getNativeEmoji(currentDoctype?.emoji)} ${currentDoctype?.name}`;
  const targetName = `${getNativeEmoji(doctype?.emoji)} ${doctype?.name}`;

  if (asPlaceholder && elementId !== 'current' && isCustom(doctype)) {
    return renderDropdown();
  }

  return render();

  function render() {
    return (
      <>
        <Container
          view={view}
          phantom={phantom || !parentPlaceholderIsVisible}
          asPlaceholder={asPlaceholder}
          isButton={asPlaceholder && isCustom(doctype)}
          forceFocus={isDropdownVisible}
          highlighted={!asPlaceholder && doctypeId === currentDoctype?.id}
          onMouseEnter={onMouseEnterContainer}
          onMouseLeave={onMouseLeaveContainer}
        >
          <Handle
            type="target"
            position={targetPosition}
            isConnectable={false}
            style={{
              opacity: 0,
              ...styles.handle(targetPosition),
            }}
          />
          <Handle
            type="source"
            position={sourcePosition}
            isConnectable={false}
            style={{
              opacity: 0,
              ...styles.handle(sourcePosition),
            }}
          />
          <Handle
            type="target"
            id="insight-target"
            position={sourcePosition}
            isConnectable={false}
            style={{
              opacity: 0,
              left: '30%',
              bottom: -15,
            }}
          />

          {isCustom(doctype) && view === 'doctype' && doctype && doctype.id !== currentDoctype?.id && (
            <RemoveAction>
              <Tooltip
                withPortal
                placement="top"
                content={
                  isUnlinkableFromInsight || !currentDoctype
                    ? 'Remove'
                    : (
                      <TooltipContent>
                        <Emoji emoji={currentDoctype.emoji} />
                        <span>
                          {currentDoctype.name}
                          {' '}
                          must be linked to at least one doc type
                        </span>
                      </TooltipContent>
                    )
                }
              >
                <Disc
                  disabled={!isUnlinkableFromInsight}
                  {...isUnlinkableFromInsight && { onClick: setShowWarningModal }}
                >
                  <CloseIcon />
                </Disc>
              </Tooltip>
            </RemoveAction>
          )}

          {asPlaceholder && renderPlaceholder()}
          {!asPlaceholder && doctype && renderDoctypeContent(doctype)}
        </Container>

        {view === 'doctype' && root && (
          <>
            <ParentArea
              onMouseEnter={onMouseEnterParent}
              onMouseOutCapture={onMouseLeaveParent}
            />
            {isFeedback(doctype) ? (
              <FeedbackChildrenArea
                onMouseEnter={onMouseEnterChildren}
                onMouseOutCapture={onMouseLeaveChildren}
              />
            ) : (
              <ChildrenArea
                onMouseEnter={onMouseEnterChildren}
                onMouseOutCapture={onMouseLeaveChildren}
              />
            )}
          </>
        )}

        {showWarningModal && (
          <DeleteModalStyled
            title="Remove Hierarchy rule"
            info={(
              <>
                <DeletePreviewBlock>
                  <DeletePreview $isReversed={target !== 'parent'}>
                    <DeletePreviewContainer view="doctype">
                      <DeletePreviewContent>{targetName}</DeletePreviewContent>
                    </DeletePreviewContainer>
                    <DeleteConnexion>
                      <DeleteConnexionIconContainer>
                        <CloseIcon />
                      </DeleteConnexionIconContainer>
                    </DeleteConnexion>
                    <DeletePreviewContainer view="doctype">
                      <DeletePreviewContent>{currentName}</DeletePreviewContent>
                    </DeletePreviewContainer>
                  </DeletePreview>
                </DeletePreviewBlock>
                <DeleteInfo>
                  Are you sure you want to remove this hierarchy rule?
                  <br />
                  All corresponding docs will be unlinked. This can&apos;t be undone.
                </DeleteInfo>
              </>
            )}
            confirmLabel="Remove"
            confirmMessage="Yes, permanently remove this hierarchy rule"
            useHighMaskLayer
            hide={() => {
              setDeleteConfirmationFalse();
              setHideWarningModal();
            }}
            onConfirm={handleConfirm}
            loading={loading}
            isConfirmDisabled={!isDeleteConfirmed}
            onToggleConfirmation={toggleDeleteConfirmation}
          />
        )}
      </>
    );
  }

  function renderPlaceholder() {
    return doctype
      ? (
        <Content>
          <Emoji emoji={doctype.emoji} />
          {isBuiltIn(doctype) && (
            <>
              <Text>{doctype.name}</Text>
              {iteration > 0 ? ` ${iteration}` : ''}
            </>
          )}
        </Content>
      )
      : (
        <Content>
          <AddAction>
            <AddIcon />
            <span>Add</span>
          </AddAction>
        </Content>
      );
  }

  function renderDoctypeContent(someDoctype: DoctypeFragment) {
    const content = (
      <Content>
        <Emoji emoji={someDoctype.emoji} />
        <Text>{someDoctype.name}</Text>
        {iteration > 0 ? ` ${iteration}` : ''}
      </Content>
    );

    const isSelectedDoctype = someDoctype?.id === currentDoctype?.id;

    return (
      <Tooltip
        title={isSelectedDoctype ? undefined : 'Open'}
        content={someDoctype.name}
        placement="top"
        withPortal
        displayFullTitle
      >
        {isSelectedDoctype
          ? content
          : (
            <Link to={getUrl(PageId.SettingsDocTypes, { doctypeId: someDoctype.id })}>
              {content}
            </Link>
          )}
      </Tooltip>
    );
  }

  function renderDropdown() {
    if (!currentDoctype) return null;
    // eslint-disable-next-line no-nested-ternary
    // const title = currentDoctype.category === DoctypeCategory.Discovery
    //   ? 'Select discovery type'
    //   : target === 'parent'
    //     ? 'Select delivery type'
    //     : 'Select doctype';
    return (
      <DropdownLayer
        visible={isDropdownVisible}
        hide={hideDropdown}
        placement="bottom-start"
        content={(
          <SelectPanel
            title="Select doctype"
            options={potentialRelatives.map(({
              id, name, emoji,
            }) => ({
              value: id,
              label: name,
              icon: (<Emoji emoji={emoji} />),
            }))}
            onOptionChange={onRelativeOptionAdded}
          />
        )}
      >
        <div
          onClick={toggleDropdownVisibility}
          style={{ opacity: placeholderIsVisible ? 1 : 0 }}
        >
          {render()}
        </div>
      </DropdownLayer>
    );
  }

  function onMouseEnterContainer() {
    if (rootId) {
      onMouseEnterChildren();
    }
    if (root) {
      onMouseEnterParent();
    }
    if (asPlaceholder) {
      return;
    }

    let insightParentHover = null;
    if (isInsight(doctype)) insightParentHover = insightParentId;
    if (children.some(isInsight)) insightParentHover = doctype?.id ?? null;
    setDoctypeGraph({
      elementHover: rootId || elementId,
      insightParentHover,
    });
  }

  function onMouseLeaveContainer() {
    if (asPlaceholder) {
      return;
    }
    setDoctypeGraph({
      elementHover: null,
      insightParentHover: null,
    });
  }

  function onMouseEnterChildren() {
    setDoctypeGraph({ rootChildrenHover: rootId || doctypeId || elementId });
  }

  function onMouseLeaveChildren() {
    setDoctypeGraph({ rootChildrenHover: null });
  }

  function onMouseEnterParent() {
    setDoctypeGraph({ rootParentIsHover: true });
  }

  function onMouseLeaveParent() {
    setDoctypeGraph({ rootParentIsHover: false });
  }
};

export default FlowElement;
