import { Node, mergeAttributes } from '@tiptap/core';
import { SuggestionOptions } from '@tiptap/suggestion';

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

export const mentionDocExtensionName = 'mention-docs';
export const MENTION_DOC_EXTENSION_TAGNAME = 'cycle-editor-mention-doc';

const mentionDocExtension = Node.create<MentionOptions>({
  key: mentionDocExtensionName,
  name: mentionDocExtensionName,

  group: 'inline',

  inline: true,

  selectable: false,

  draggable: true,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: MENTION_DOC_EXTENSION_TAGNAME,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      MENTION_DOC_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },
});

export default mentionDocExtension;
