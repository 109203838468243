import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const ChecklistIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.54629 2.87905C2.66887 2.75647 2.83513 2.6876 3.00849 2.6876H10.3294C10.7035 2.6876 11.0068 2.38431 11.0068 2.01018C11.0068 1.63605 10.7035 1.33276 10.3294 1.33276H3.00849C2.4758 1.33276 1.96494 1.54437 1.58827 1.92104C1.21161 2.2977 1 2.80857 1 3.34125V12.6587C1 13.1914 1.21161 13.7023 1.58827 14.079C1.96494 14.4556 2.4758 14.6672 3.00849 14.6672H12.326C12.8587 14.6672 13.3695 14.4556 13.7462 14.079C14.1229 13.7023 14.3345 13.1914 14.3345 12.6587V8C14.3345 7.62587 14.0312 7.32258 13.657 7.32258C13.2829 7.32258 12.9796 7.62587 12.9796 8V12.6587C12.9796 12.8321 12.9108 12.9984 12.7882 13.1209C12.6656 13.2435 12.4993 13.3124 12.326 13.3124H3.00849C2.83513 13.3124 2.66887 13.2435 2.54629 13.1209C2.42371 12.9984 2.35484 12.8321 2.35484 12.6587V3.34125C2.35484 3.16789 2.42371 3.00164 2.54629 2.87905ZM14.8016 3.15472C15.0662 2.89017 15.0662 2.46125 14.8016 2.1967C14.5371 1.93215 14.1081 1.93215 13.8436 2.1967L7.66725 8.37304L6.14965 6.85545C5.8851 6.5909 5.45619 6.5909 5.19164 6.85545C4.92709 7.11999 4.92709 7.54891 5.19164 7.81346L7.18824 9.81007C7.45279 10.0746 7.88171 10.0746 8.14626 9.81007L14.8016 3.15472Z" fill="#171618" />
  </Svg>
);
