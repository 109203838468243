import { focusEndContenteditable } from '@cycle-app/utilities';
import {
  useImperativeHandle,
  forwardRef, KeyboardEvent, useEffect, useRef, useState, ClipboardEvent,
} from 'react';

import { setToasters } from 'src/reactives/toasters.reactive';

import { Content } from './ContentEditable.styles';

export interface Props {
  className?: string;
  focusEndOnMount?: boolean;
  initialValue?: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  onEnter?: VoidFunction;
  onNext?: VoidFunction;
  placeholder?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'span';
}

const ContentEditable = forwardRef<HTMLHeadingElement, Props>(({
  className,
  focusEndOnMount = true,
  initialValue = '',
  isDisabled = false,
  onChange,
  onEnter,
  onNext,
  placeholder,
  tag,
}, ref) => {
  const inputRef = useRef<HTMLHeadingElement>(null);
  const [value, setValue] = useState(initialValue);

  useImperativeHandle(ref, () => inputRef.current!, [inputRef]);

  useEffect(() => {
    if (focusEndOnMount && inputRef.current && !isDisabled) {
      focusEndContenteditable(inputRef.current);
    }
  }, [focusEndOnMount, isDisabled]);

  return (
    <Content
      $isDisabled={isDisabled}
      ref={inputRef}
      as={tag}
      className={className}
      placeholder={placeholder}
      onInput={onInput}
      contentEditable={!isDisabled}
      suppressContentEditableWarning
      onPaste={(e: ClipboardEvent<HTMLHeadingElement>) => {
        e.preventDefault();

        if (isDisabled) return;

        // We explicitely want to paste the text of the clipboard, in case it would contains html
        const pastedText = e.clipboardData.getData('text');
        const selection = window.getSelection();

        if (!selection?.rangeCount) return;

        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(pastedText));
        const newValue = selection.focusNode?.textContent;
        if (newValue) {
          setValue(newValue);
          onChange(newValue);
        }
      }}
      onKeyDown={(e: KeyboardEvent) => {
        if (e.metaKey || e.ctrlKey || isDisabled) return;
        if (e.code === 'Tab') {
          e.preventDefault();
          onNext?.();
        } else if (e.code === 'Enter') {
          e.preventDefault();
          onEnter?.();
        }
      }}
      onBlur={() => setToasters({ areShortcutsEnabled: true })}
      onFocus={() => setToasters({ areShortcutsEnabled: false })}
    >
      {value}
    </Content>
  );

  function onInput(e: React.FormEvent<HTMLElement>) {
    if (isDisabled) return;

    onChange(e.currentTarget.innerText);
  }
});

export default ContentEditable;
