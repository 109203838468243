import { DocFullFragment } from '@cycle-app/graphql-codegen';
import { FileUploadSource } from '@cycle-app/utilities';
import { Editor } from '@tiptap/react';
import { createContext, FC, useContext } from 'react';

import { UploadReturn } from 'src/hooks/useUploadFile';
import { ActionId } from 'src/services/editor/editorActions';

export interface EditorContextValue {
  disabledActions?: ActionId[];
  doc?: DocFullFragment | null;
  editor: Editor;
  isUploading?: boolean;
  onError?: (message: string) => void;
  onUpload?: (file: File, from: FileUploadSource) => UploadReturn;
  setDragFileIsDisabled?: (value: boolean) => void;
  isReadOnly?: boolean;
}

export const EditorContext = createContext<EditorContextValue | undefined>(undefined);

export const EditorContextProvider: FC<EditorContextValue> = ({
  disabledActions = [],
  doc,
  editor,
  isUploading,
  onError,
  onUpload,
  setDragFileIsDisabled,
  isReadOnly,
  ...otherProps
}) => (
  <EditorContext.Provider
    value={{
      disabledActions,
      doc,
      editor,
      isUploading,
      onError,
      onUpload,
      setDragFileIsDisabled,
      isReadOnly,
    }}
    {...otherProps}
  />
);

export const useEditorContext = (): EditorContextValue => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useCurrentEditor must be used within an EditorProvider');
  }
  return context;
};
