import { useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { getErrorToasters, setErrorToasters } from 'src/reactives/toasters.reactive';
import { ErrorToasterData } from 'src/types/toaster.types';

const DEFAULT_DURATION = 4000;

type AddErrorToasterParam = Omit<ErrorToasterData, 'id' | 'duration'> & {
  // When no duration is specified in add method we want to set a default duration of DEFAULT_DURATION
  // -> "infinite duration" is an edge case that must be explicitely specified
  duration?: number | 'infinite';
};

export const useErrorToaster = () => {
  const timers = useRef<Record<string, number>>({});

  const close = useCallback((toasterId: string) => {
    const { queue: currentQueue } = getErrorToasters();

    setErrorToasters({
      queue: currentQueue.filter(toaster => toaster.id !== toasterId),
    });

    if (timers.current[toasterId]) {
      clearTimeout(timers.current[toasterId]);
    }
  }, []);

  const add = useCallback(({
    duration: requestedDuration,
    closable = true,
    ...toasterConfig
  }: AddErrorToasterParam) => {
    const { queue: currentQueue } = getErrorToasters();

    // When no duration param is given we want to avoid an infinite duration
    const toasterDuration: number | undefined = requestedDuration === 'infinite'
      ? undefined
      : (requestedDuration ?? DEFAULT_DURATION);

    const newToaster: ErrorToasterData = {
      duration: toasterDuration,
      closable,
      ...toasterConfig,
      id: uuid(),
    };

    setErrorToasters({
      queue: [...currentQueue, newToaster],
    });

    if (newToaster.duration) {
      // Close toaster after `duration`ms
      timers.current[newToaster.id] = window.setTimeout(
        () => close(newToaster.id),
        newToaster.duration,
      );
    }
  }, [close]);

  return {
    add,
    close,
  };
};
