import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { CustomerAvatar } from '@cycle-app/ui';
import { TrashIcon, AddIcon } from '@cycle-app/ui/icons';
import { useCallback } from 'react';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { Container, Button, Buttons, StyledCustomerCompanyAction } from './CustomerAvatarEditable.styles';

export type CustomerAvatarEditableProps = {
  customer: CustomerFragment;
  isReadOnly?: boolean;
  onCompanyClick: (companyId: string) => void;
};

export const CustomerAvatarEditable = ({
  customer, isReadOnly = false, onCompanyClick,
}: CustomerAvatarEditableProps) => {
  const updateCustomer = useCustomerUpdate(customer);
  const updateAvatar = useCallback(async (avatar?: File) => {
    if (updateCustomer.isLoading || !customer?.id) return;
    await updateCustomer.update({
      customerId: customer.id,
      name: customer.name ?? '',
      avatarInput: avatar ? { avatar } : null,
    });
  }, [customer.id, customer.name, updateCustomer]);
  const { canReadSettings } = useGetPermission();

  return (
    <Container>
      <ImageInput
        previewModalTitle="New customer picture"
        previewModalSubmitLabel="Set new customer picture"
        onChange={(file) => updateAvatar(file)}
      >
        {(inputRef) => (
          <CustomerAvatar
            {...!isReadOnly && {
              tooltip: 'Click to change avatar',
              isEditable: true,
            }}
            customer={customer}
            isCompanyEditable={!isReadOnly && !customer.company}
            onCompanyClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (customer.company) {
                onCompanyClick(customer.company.id);
              }
            }}
            onClick={() => {
              if (!isReadOnly) {
                inputRef.current?.click();
              }
            }}
            companyTitle={customer.company ? customer.company.name : undefined}
            companyTooltip={customer.company && canReadSettings ? 'Open company page' : undefined}
            companyInput={(
              <StyledCustomerCompanyAction customer={customer}>
                <AddIcon />
              </StyledCustomerCompanyAction>
            )}
          />
        )}
      </ImageInput>
      {!isReadOnly && (
        <Buttons>
          {!!customer.avatar && (
            <Button
              tooltip="Remove avatar"
              tooltipPlacement="top"
              onClick={() => updateAvatar()}
            >
              <TrashIcon />
            </Button>
          )}
        </Buttons>
      )}
    </Container>
  );
};
