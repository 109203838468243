import { FC } from 'react';

import { Notifications } from 'src/components/Notifications';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { useGetInitial } from 'src/reactives/initial.reactive';

import { Container, ItemLoading } from './SidebarHeader.styles';
import SidebarHeaderProduct from './SidebarHeaderProduct/SidebarHeaderProduct';
import { SidebarHeaderUser } from './SidebarHeaderUser';

const SidebarHeader: FC = () => {
  const { loading } = useGetInitial();
  const collapsed = useSidebarCollapsed();

  return (
    <Container $collapsed={collapsed}>
      {renderContent()}
    </Container>
  );

  function renderContent() {
    if (loading) {
      return (
        <>
          <ItemLoading $entity="product" />
          <ItemLoading $entity="user" />
          <ItemLoading $entity="user" />
        </>
      );
    }

    return (
      <>
        <SidebarHeaderProduct />
        {!collapsed && <Notifications />}
        <SidebarHeaderUser />
      </>
    );
  }
};

export default SidebarHeader;
