import { TextButton, Input } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { ViewType } from 'src/types/viewType.types';

export const Container = styled.div<{ viewType: ViewType }>`
  flex-shrink: 0;

  ${p => (p.viewType === ViewType.Kanban && css`
    width: 270px;
    padding-top: 2px;
  `)}
  ${p => (p.viewType === ViewType.List && css`
    width: 100%;
    padding: 8px;
  `)}

`;

export const NewGroupButton = styled(TextButton)`
  width: 100%;
`;

export const StyledInput = styled(Input)`
  width: 100%;

  & input {
    height: 32px;
  }
`;
