import { typo, Card } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.caption400};
  color: ${p => p.theme.colors.text.disabled};

  ${mobile} {
    display: none;
  }
`;

export const Key = styled.div`
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 4px;
  padding: 0px 4px;
`;

export const MotionContainer = styled(motion.div)`
  z-index: ${mappingZindex[Layer.Toaster]};
`;

export const StyledCard = styled(Card)`
  border-radius: 12px;
  padding: 0;
  display: flex;

  img {
    padding: 8px;
    padding-right: 0;
  }
`;

export const CardContent = styled.div`
  padding: 16px;
  ${typo.body500}
  max-width: 190px;
`;

export const CardDescription = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  line-height: 20px;
`;
