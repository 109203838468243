import { Tooltip, Shortcut } from '@cycle-app/ui';
import { MouseEventHandler, FC, ReactChild } from 'react';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { Action as ActionType } from 'src/services/editor/editorActions';

import { ButtonAction, ButtonText, TooltipLabelContainer } from './Action.styles';

type ActionButtonProps = {
  action: ActionType;
  onClick?: MouseEventHandler;
  active?: boolean;
  customIcon?: ReactChild;
  customIconInTooltipLabel?: ReactChild;
  labelComplement?: string;
  isDocMention?: boolean;
  isDisabled?: boolean;
  disabledTooltip?: string;
};

export const ActionButton: FC<ActionButtonProps> = ({
  onClick, active, action, disabledTooltip, customIcon, isDocMention, labelComplement, isDisabled,
}) => {
  const tooltipLabel = (
    <TooltipLabelContainer>
      {disabledTooltip ?? (
        <>
          {action.label}
          {customIcon}
          {labelComplement}
        </>
      )}
    </TooltipLabelContainer>
  );

  return (
    <Tooltip
      content={
        action.shortcut && !disabledTooltip
          ? (
            <Shortcut
              keys={shortcuts[action.shortcut]}
              label={tooltipLabel}
            />
          )
          : tooltipLabel
        }
      placement="top"
    >
      {action.text ? (
        <ButtonText onClick={onClick} active={active} disabled={isDisabled}>
          {customIcon || action.icon}
          {action.text}
        </ButtonText>
      ) : (
        <ButtonAction
          type="button"
          onClick={onClick}
          active={active}
          $hasSmallIcon={isDocMention}
          disabled={isDisabled}
        >
          {customIcon || action.icon}
        </ButtonAction>
      )}
    </Tooltip>
  );
};
