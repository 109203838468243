import { ViewType } from '@cycle-app/graphql-codegen';
import {
  Button, Input, TextArea, typo, Group, ViewCard, EmojiInput, Tag,
  ShyScrollbarCss,
} from '@cycle-app/ui';
import { Header as GroupdHeader } from '@cycle-app/ui/components/Group/Group.styles';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { PortalModalStyled } from 'src/components/DialogModal/DialogModal.styles';

export const StyledPortalModal = styled(PortalModalStyled)`
  padding: 0;
  width: 1126px;
  display: grid;
  grid-template-columns: 474px 1fr;
  grid-template-rows: 100%;

  ${mobile} {
    display: block;
  }
`;

export const ContentSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 598px;
  max-height: 100vh;
`;

export const Content = styled.div`
  ${ShyScrollbarCss}
`;

export const ContentForm = styled.div`
  background-color: ${p => p.theme.colors.background.primary};
  padding: 32px;
`;

export const ContentPreview = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.background.secondary};
  display: flex;
  padding: 0 60px;
  justify-content: center;

  ${mobile} {
    display: none;
  }
`;

export const PreviewBox = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const ViewtypeButton = styled(Button).attrs({
  size: 'L',
  variant: 'outlined',
})`
  outline-offset: -2px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  gap: 15px;
`;

export const TextLabel = styled.p`
  ${typo.body500}
  margin-bottom: 4px;
`;

export const InputStyled = styled(Input)`
  width: 100%;

  label {
    ${typo.body500}
  }
`;

export const EmojiInputStyled = styled(EmojiInput)`
  background-color:  ${p => p.theme.colors.input.bg};
  border: none;
`;

export const TextAreaStyled = styled(TextArea)`
  border-color: transparent;
  border-width: 2px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const GroupContainer = styled.div<GroupListProps>`
  width: 100%;
  ${p => p.$isRow && css`max-width: 50%;`}
`;

interface GroupListProps {
  $isRow?: boolean;
}

interface GroupStyledProps {
  $hasSpaceAfter?: boolean;
  $hasSpaceBefore?: boolean;
}

export const GroupStyled = styled(Group)<GroupStyledProps>`
  ${p => !p.inputName && css`padding-top: 8px;`}
  &,
  ${GroupdHeader} {
    background-color: ${p => p.theme.colors.boardConfig.preview.group.bg};
  }
  width: 100%;
  ${p => p.$hasSpaceAfter && css`padding-bottom: 8px;`}
  ${p => p.$hasSpaceBefore && css`padding-top: 16px;`}
`;

export const GroupList = styled.div<GroupListProps>`
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-direction: ${p => (p.$isRow ? 'row' : 'column')};
`;

export const GroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  padding: 0 8px;
`;

export const SwimlaneContainer = styled.div`
  height: 28px;
  position: relative;
`;

export const SwimlaneContent = styled.div`
  align-items: center;
  bottom: -4px;
  background-color: ${p => p.theme.colors.boardConfig.preview.swimlane.bg};
  border-color: ${p => p.theme.colors.boardConfig.preview.swimlane.border};
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  left: -32px;
  padding: 0 8px;
  position: absolute;
  right: -32px;
  top: -4px;
`;

export const HeaderContainerSkeleton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const commonSkeletonCss = css`
  border-radius: 4px;
  background-color: ${p => p.theme.colors.background.hover};
`;

export const HeaderLineSkeleton = styled.div`
  ${commonSkeletonCss}
  background-color: ${p => p.theme.colors.boardConfig.preview.card.title.bg};
  border-radius: 4px;
  height: 8px;
  width: 48px;
`;

export const TitleLineSkeleton = styled.div`
  ${commonSkeletonCss}
  height: 8px;
`;

export const DescriptionLineSkeleton = styled.div`
  ${commonSkeletonCss}
  height: 8px;
  width: 84px;
`;

export const AttributeSkeleton = styled(Tag)`
  border-radius: 4px;
  height: 16px;
`;

export const ActionsContainerSkeleton = styled.div`
  color: ${p => p.theme.colors.background.hover};
  display: flex;
  gap: 8px;
`;

export const AttributesContainerSkeleton = styled.div`
  display: flex;
  flex: 1;
  gap: 4px;

  & div:first-child {
    flex-basis: 60px;
  }
  & div:nth-child(2) {
    flex-basis: 37px;
  }
  & div:nth-child(3) {
    flex-basis: 43px;
  }
  & div:nth-child(4) {
    flex-basis: 43px;
  }
`;

export const AvatarSkeleton = styled.div`
  background-color: ${p => p.theme.colors.boardConfig.preview.avatar.bg};
  border-radius: 50%;
  height: 14px;
  margin-left: 12px;
  position: relative;
  width: 14px;

  &::before {
    border-radius: 50%;
    border: 2px solid ${p => (p.theme.colors.boardConfig.preview.avatar.enableUserColor ? p.theme.userColors.main : 'transparent')};
    bottom: -3px;
    content: "";
    left: -3px;
    opacity: 0.2;
    position: absolute;
    right: -3px;
    top: -3px;
  }
`;

const ContainerListCSS = css`
  ${TitleLineSkeleton} {
    width: 80%;
  }
  ${AttributesContainerSkeleton} {
    justify-content: flex-end;
  }
`;

export const StyledViewCard = styled(ViewCard)`
  ${p => p.viewType === ViewType.List && ContainerListCSS};
  background-color: ${p => p.theme.colors.boardConfig.preview.card.bg};
`;
