export const emailRegex = /^\S+@\S+\.\S+$/i;

export const isEmail = (value: string, strict = true) => (strict ? emailRegex.test(value) : value.includes('@'));

export const isUrl = (value: string) => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/i
  .test(value);

export const isValidInputDate = (value: string) => value.length === 10;

export const formatBillingAmount = (n: number) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(n);
