import { AddNewDocMutationVariables } from '@cycle-app/graphql-codegen';

export type FormData = Pick<AddNewDocMutationVariables, 'title' | 'assignee' | 'customerId'> & {
  description: string;
  custom: {
    [key: string]: any;
  };
};

export const textDefinitions = [
  'AttributeDateDefinition',
  'AttributeEmailDefinition',
  'AttributeNumberDefinition',
  'AttributePhoneDefinition',
  'AttributeTextDefinition',
  'AttributeUrlDefinition',
] as const;

export type TextDefinition = typeof textDefinitions[number];

export const isTextDefinition = (typename?: string): typename is TextDefinition => textDefinitions.includes(typename as TextDefinition);
