import { make } from 'src/utils/reactives.util';

import { PermissionAction } from '../types/permissionAction.types';

const defaultState: { action: PermissionAction | null } = {
  action: null,
};

type LimitationModalResult = typeof defaultState;

export const {
  getValue: getLimitationsModal,
  hookState: useLimitationsModal,
  resetValue: resetLimitationsModal,
  setValue: setLimitationsModal,
} = make<LimitationModalResult>({
  defaultState,
});
