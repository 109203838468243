import { typo, Tag } from '@cycle-app/ui';
import styled from 'styled-components';

interface ContainerProps {
  $isModal?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 0 8px;

  width: 100%;
  height: 32px;

  cursor: pointer;
  border-radius: 4px;

  background-color: ${p => (p.$isModal ? p.theme.colors.docPanel.hierarchy.bgModal : p.theme.colors.docPanel.hierarchy.bg)};
  &:hover {
    background-color: ${p => p.theme.colors.docPanel.hierarchy.bgHover};
  }
`;

export const DocTitle = styled.div`
  ${typo.body400};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

export const Side = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TagStyled = styled(Tag)`
  ${typo.body500}
  background-color: ${p => p.theme.colors.background.tertiary};
  border: none;
  display: inline-flex;
  height: auto;
`;
