import { FC } from 'react';

import { useMe } from 'src/hooks/api/useMe';

import RealtimeUsers from '../RealtimeUsers/RealtimeUsers';
import { Container } from './BoardRealtime.styles';
import BoardRealtimeUsers from './BoardRealtimeUsers';

interface Props {
  className?: string;
}

const BoardRealtime: FC<Props> = ({ className }) => {
  const { me } = useMe();
  return (
    <Container className={className}>
      {me.incognito ? <RealtimeUsers /> : <BoardRealtimeUsers />}
    </Container>
  );
};

export default BoardRealtime;
