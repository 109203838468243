import { useApolloClient } from '@apollo/client';
import {
  DocBaseFragment,
  DocNodeDocument,
  DocChildFragment,
  DocChildNodeDocument,
  DocChildNodeQueryVariables,
  DocChildrensFragment,
  DocChildrensQueryVariables,
  DocChildrensDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { defaultHierarchyPagination } from '../../../utils/pagination.util';

export const useGetDocFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((docId: string) => cache.readQuery<{ node: DocBaseFragment }>({
    query: DocNodeDocument,
    variables: {
      id: docId,
    },
  })?.node, [cache]);
};

export const useGetDocChildFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((docId: string) => cache.readQuery<{ node: DocChildFragment }, DocChildNodeQueryVariables>({
    query: DocChildNodeDocument,
    variables: {
      id: docId,
    },
  })?.node, [cache]);
};

export const useGetDocChildrensFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((variables: Pick<DocChildrensQueryVariables, 'docId' | 'doctypeId'>) => cache.readQuery<{ node: DocChildrensFragment }, DocChildrensQueryVariables>({
    query: DocChildrensDocument,
    variables: {
      ...variables,
      ...defaultHierarchyPagination,
    },
  })?.node, [cache]);
};
