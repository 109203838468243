import { RemoveDoctypeDocument, ProductDoctypesDocument, DoctypeRelativeFragment } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { TrashIcon } from '@cycle-app/ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { FC, useState, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { DoctypeDeleteModal } from 'src/components/DoctypeDeleteModal/DoctypeDeleteModal';
import { Events } from 'src/constants/analytics.constants';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { useLoader } from 'src/hooks/useLoader';
import { useNavigate } from 'src/hooks/useNavigate';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { usePageId } from 'src/hooks/usePageId';
import { usePathParams } from 'src/hooks/usePathParams';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getSortableStyle } from 'src/utils/dnd.util';
import { isBuiltIn } from 'src/utils/docType.util';

import { SettingsNavigationItem, SettingsNavigationItemMenu } from '../SettingsSidebar.styles';

interface Props {
  doctype: DoctypeRelativeFragment;
  url: string;
  onClick?: () => void;
  isActive: boolean;
  isDeleteDisabled?: boolean;
  deleteWarning?: ReactNode;
}

const SettingsDocTypeItem: FC<Props> = ({
  doctype,
  url,
  onClick,
  isActive,
  isDeleteDisabled = false,
  deleteWarning,
}) => {
  const history = useHistory();
  const pageId = usePageId();
  const { doctypeId: doctypeIdFromUrl } = usePathParams();
  const { navigate } = useNavigate();
  const {
    productId,
    doctypes,
  } = useProductDoctypesFull();

  const [menuVisible, setMenuVisible] = useState(false);
  const [showWarningModal, {
    setTrueCallback: setShowWarningModal,
    setFalseCallback: setHideWarningModal,
  }] = useOptimizedBooleanState(false);

  const {
    active,
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
  } = useSortable({ id: doctype.id });
  const style = getSortableStyle({
    transform,
    transition,
  });

  const [removeDoctype, { loading: loadingRemoveDoctype }] = useSafeMutation(RemoveDoctypeDocument, {
    onCompleted: () => trackAnalytics(Events.DocTypeDeleted),
  });

  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation, setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);

  useLoader({ loading: loadingRemoveDoctype });

  return (
    <>
      <div ref={setNodeRef} {...attributes} style={style}>
        <SettingsNavigationItem
          key={doctype.id}
          {...doctype.emoji && {
            icon: (<Emoji emoji={doctype.emoji} />),
          }}
          label={doctype.name}
          linkTo={url}
          isActive={isActive}
          onClick={() => {
            onClick?.();
            history.push(url);
          }}
          isSortable
          sortableListeners={listeners}
          asPlaceholder={active?.id === doctype.id}
          isChild
          isFocus={menuVisible}
          contentMore={(
            <SettingsNavigationItemMenu
              onVisibilityChange={setMenuVisible}
              options={isBuiltIn(doctype) ? [] : [{
                value: 'delete',
                label: 'Delete',
                icon: <TrashIcon />,
                variant: 'danger',
                onSelect: setShowWarningModal,
                disabled: isDeleteDisabled,
                tooltipContent: deleteWarning,
              }]}
            />
          )}
        />
      </div>
      {showWarningModal && (
        <DoctypeDeleteModal
          doctype={doctype}
          hide={() => {
            setDeleteConfirmationFalse();
            setHideWarningModal();
          }}
          onConfirm={handleConfirmDelete}
          isConfirmDisabled={!isDeleteConfirmed}
          onToggleConfirmation={toggleDeleteConfirmation}
        />
      )}
    </>
  );

  async function handleConfirmDelete() {
    const firstDoctypeAvailable = doctypes.filter(({ id }) => id !== doctype.id)[0];
    if (doctypeIdFromUrl === doctype.id && firstDoctypeAvailable) {
      navigate(pageId, { doctypeId: firstDoctypeAvailable.id });
    }
    await removeDoctype({
      variables: {
        doctypeId: doctype.id,
      },
      update(cache, { data }) {
        if (!data?.removeDoctype || !productId) return;

        cache.writeQuery({
          query: ProductDoctypesDocument,
          variables: { productId },
          data: {
            node: {
              id: productId,
              doctypes: {
                __typename: 'DoctypesConnection',
                edges: doctypes
                  .filter(({ id }) => id !== doctype.id)
                  .map(doctypeData => ({
                    __typename: 'DoctypeEdge' as const,
                    node: doctypeData,
                  })),
              },
            },
          },
        });
      },
    });
  }
};

export default SettingsDocTypeItem;
