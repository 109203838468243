import { make } from 'src/utils/reactives.util';

export const {
  hookState: useAnimation,
  hookValue: useGetAnimation,
  getValue: getAnimation,
  setValue: setAnimation,
} = make({
  defaultState: {
    enabled: true,
  },
});
