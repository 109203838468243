import { InfiniteScroll } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FC, FormEvent, useEffect, useMemo, useState } from 'react';

import { commandSections } from 'src/constants/commandSections.constants';
import { useCommandResult } from 'src/hooks/commandPanel/useCommandResult';
import { useCommandbar } from 'src/reactives/commandbar.reactive';

import {
  Categories,
  Category,
  CategoryName,
  Actions,
} from '../CommandbarModal.styles';
import CommandAction from './CommandAction/CommandAction';
import CommandInput from './CommandInput/CommandInput';
import { Container, NoResult } from './CommandK.styles';

const CommandK: FC = () => {
  const [{ section }] = useCommandbar();
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch('');
  }, [section]);

  const {
    resultByCategory,
    entries,
    loading,
    hasNextPage,
    fetchNextPage,
  } = useCommandResult({ search });
  const optionsValues = useMemo(() => entries.map(action => action.id), [entries]);

  const {
    listProps,
    itemProps,
    selected,
    hoverDisabled,
  } = useListNav({
    optionsValues,
    onSelect: (actionId) => entries.find(a => a.id === actionId)?.onSelect?.(),
    autoFocus: true,
  });

  const hasResult = entries.length > 0;

  const currentCategory = section ? commandSections[section] : null;

  return (
    <Container>
      <CommandInput
        search={search}
        setSearch={setSearch}
        onSearchUpdate={onSearchUpdate}
        isLoading={loading}
      />
      <Categories {...listProps}>
        {
          hasResult ? (
            <>
              {resultByCategory.map(category => (
                <Category key={category.id}>
                  {category.label && <CategoryName>{category.label}</CategoryName>}
                  <Actions>
                    <InfiniteScroll
                      isLoading={loading}
                      hasMoreData={hasNextPage}
                      loadMore={fetchNextPage}
                    >
                      {category.actions
                        .map(action => (
                          <CommandAction
                            key={action.id}
                            action={action}
                            selected={action.id === selected}
                            hoverDisabled={hoverDisabled && action.id !== selected}
                            searchText={search}
                            {...itemProps(action.id)}
                          />
                        ))}
                    </InfiniteScroll>
                  </Actions>
                </Category>
              ))}
            </>
          ) : renderNoResult()
        }
      </Categories>
    </Container>
  );

  function onSearchUpdate(e: FormEvent<HTMLInputElement>) {
    setSearch(e.currentTarget.value);
  }

  function renderNoResult() {
    if (currentCategory?.disableSearch) {
      return null;
    }
    return (
      <Category>
        <CategoryName>No result</CategoryName>
        <Actions>
          <NoResult>
            {section
              ? 'No results. Try different search terms.'
              : 'Try: "Search" or "Go to view"' }
          </NoResult>
        </Actions>
      </Category>
    );
  }
};

export default CommandK;
