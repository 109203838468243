import { NotionMinimalDatabaseFragment, NotionMinimalPageFragment } from '@cycle-app/graphql-codegen';
import { IntegrationCardSkeleton, EditorIntegrationCard } from '@cycle-app/ui';
import { NotionIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useEffect } from 'react';

import { ContainerNodeView } from 'src/components/Integrations/IntegrationsCommon.styles';
import { useEditorContext } from 'src/contexts/editorContext';
import { useNotionPage } from 'src/hooks/api/queries/integrations/useNotionPage';
import { useNodeSelection } from 'src/hooks/editor/useNodeSelection';
import { NotionPageCommonAttribute } from 'src/types/editor.types';

import { NotionEditorMentionViewActions } from './NotionEditorMentionViewActions';

interface Props extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: NotionPageCommonAttribute) => void;
}

export const NotionEditorMentionView: FC<Props> = ({
  node, selected, getPos, updateAttributes,
}) => {
  const { page } = useNotionPage(node.attrs as (NotionMinimalPageFragment | NotionMinimalDatabaseFragment));
  const { editor } = useEditorContext();
  const { isSelected } = useNodeSelection({
    editor,
    selected,
    getPos,
  });

  useEffect(() => {
    if (page) {
      updateAttributes({
        id: page.id,
        url: page.url,
        title: page.title,
        iconEmoji: page.iconEmoji,
      });
    }
  }, [page, updateAttributes]);

  return (
    <ContainerNodeView
      $isSelected={isSelected}
      data-drag-handle
      onClick={() => {
        const url = node.attrs.url || page?.url;
        if (url) {
          window.open(url, '_blank');
        }
      }}
    >
      {renderContent()}
    </ContainerNodeView>
  );

  function renderContent() {
    const {
      url, title, iconEmoji,
    } = node.attrs;
    if (!url) return <IntegrationCardSkeleton />;
    return (
      <EditorIntegrationCard
        title={title}
        preTitleSlot={(
          <>
            <NotionIcon />
            {iconEmoji}
          </>
        )}
        actions={<NotionEditorMentionViewActions editor={editor} getPos={getPos} node={node} />}
      />
    );
  }
};
