import { Input } from '@cycle-app/ui';
import { emailRegex } from '@cycle-app/utilities';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'src/constants/policies.constants';
import { useVerifyEmail } from 'src/hooks';

import { Anchor, CheckboxContainer, Form, StyledButton, StyledCheckboxInput } from './OnboardingStepAccount.styles';

interface FormData {
  email: string;
  terms: boolean;
}

interface Props {
  onSuccess: (email: string) => void;
  defaultValue: string;
}

export const VerifyEmailForm: FC<Props> = ({
  defaultValue, onSuccess,
}) => {
  const {
    handleSubmit, register, formState,
  } = useForm<FormData>({
    defaultValues: {
      email: defaultValue,
      terms: false,
    },
  });
  const {
    verifyEmail, isLoading,
  } = useVerifyEmail();

  const onSubmit = async (data: FormData) => {
    const result = await verifyEmail(data.email);
    if (result.data?.verifyEmail) {
      onSuccess(data.email);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        autoFocus
        label="Email"
        type="email"
        placeholder="example@mail.com"
        error={formState.errors.email?.message}
        {...register('email', {
          required: true,
          pattern: {
            value: emailRegex,
            message: 'Email format is incorrect',
          },
        })}
      />
      <CheckboxContainer>
        <StyledCheckboxInput
          id="terms"
          defaultChecked={false}
          hideLabel
          {...register('terms', { required: true })}
        />
        <div>
          I agree to the Cycle
          {' '}
          <Anchor
            href={TERMS_AND_CONDITIONS}
            target="_blank"
          >
            Terms of Use
          </Anchor>
          ,
          {' '}
          <Anchor
            href={PRIVACY_POLICY}
            target="_blank"
          >
            Privacy Policy and Data Processing Agreement.
          </Anchor>
        </div>
      </CheckboxContainer>
      <StyledButton
        disabled={!!formState.errors.email || !!formState.errors.terms}
        type="submit"
        size="M"
        isLoading={isLoading}
      >
        Next
      </StyledButton>
    </Form>
  );
};
