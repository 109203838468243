import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export const {
  hookState: useSections,
  hookValue: useGetSections,
  getValue: getSections,
  setValue: setSections,
} = make<{ [K in string]: boolean }>({
  defaultState: {},
  localKey: LocalKey.Sections,
});

export const useToggleSection = (id: string): [boolean, VoidFunction] => {
  const [state, setState] = useSections();
  return [
    !!state[id],
    () => setState({
      [id]: !state[id],
    }),
  ];
};
