import { make } from 'src/utils/reactives.util';

interface TemplateState {
  modalVisible: boolean;
  admin: boolean;
  selectedTemplateId: null | string;
  mode: 'list' | 'edit';
  docTypeId?: string;
}

export const {
  hookValue: useGetTemplate,
  getValue: getTemplate,
  setValue: setTemplate,
} = make<TemplateState>({
  defaultState: {
    modalVisible: false,
    admin: false,
    selectedTemplateId: null,
    mode: 'list',
  },
});
