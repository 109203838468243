import { getRandomItem } from '@cycle-app/utilities';
import { useRef } from 'react';

export const useFakeCursors = () => {
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const cursorsContainerRef = useRef<HTMLDivElement>(null);

  return {
    editorContainerRef,
    cursorsContainerRef,
    moveCursorToStart,
    placeCursors,
  };

  function moveCursorToStart(cursor: HTMLDivElement) {
    // eslint-disable-next-line no-param-reassign
    cursor.style.top = '0px';
    // eslint-disable-next-line no-param-reassign
    cursor.style.left = '0px';
    // eslint-disable-next-line no-param-reassign
    cursor.style.opacity = '1';
  }

  function placeCursors() {
    if (!editorContainerRef.current || !cursorsContainerRef.current) return;

    const containerRect = editorContainerRef.current.getBoundingClientRect();
    const cursors = Array.from(cursorsContainerRef.current.querySelectorAll<HTMLDivElement>('div'));
    cursors.forEach(c => {
      // eslint-disable-next-line no-param-reassign
      c.style.opacity = '0';
      // eslint-disable-next-line no-param-reassign
      c.style.top = '0';
    });

    const elements = Array.from(editorContainerRef.current.querySelectorAll<HTMLParagraphElement>('.ProseMirror > *'))
      .filter(({ textContent }) => textContent?.trim());

    const cursor = getRandomItem(cursors);
    if (!cursor) return;
    // If no element found, just place the cursor at the start.
    if (!elements.length) {
      moveCursorToStart(cursor);
      return;
    }
    const el = getRandomItem(elements);
    if (el?.textContent && cursor) {
      const letters = el.textContent.split('');
      const letterIndex = getRandomItem(letters.map((_, i) => i));
      const range = document.createRange();
      const childNodes = Array.from(el.childNodes);
      let textCounter = 0;
      const nodesRanges = childNodes.map(node => {
        const contentLength = node.textContent?.length ?? 0;
        const mappedNode = {
          node: node.firstChild ?? node,
          start: textCounter,
          end: textCounter + contentLength - 1,
        };
        textCounter += contentLength;
        return mappedNode;
      });
      const target = nodesRanges.find(node => letterIndex >= node.start && letterIndex <= node.end);
      if (target) {
        const rangeStart = letterIndex - target.start;
        try {
          // Catch range.
          range.setStart(target.node, rangeStart);
          range.setEnd(target.node, rangeStart);
          const rects = range.getClientRects();
          if (rects.length) {
            const top = rects[0].top - containerRect.top;
            const left = rects[0].left - containerRect.left;
            // 17 is the base size of a text selection.
            const offset = (rects[0].height - 17) / 2;
            cursor.style.top = `${top + offset}px`;
            cursor.style.left = `${left}px`;
            cursor.style.opacity = '1';
          }
        } catch (_) {
          moveCursorToStart(cursor);
        }
      } else {
        moveCursorToStart(cursor);
      }
    }
  }
};
