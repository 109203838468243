import { TextButton, Tag, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const AddOptionButton = styled(TextButton)`
  margin-top: 4px;

  &:hover {
    background-color: ${p => p.theme.colors.selectItem.bgHover};
  }
`;

export const OptionTag = styled.span`
  ${typo.body500}
`;

export const AttributeTagStyled = styled(Tag)`
  ${typo.body500}
  border: none;
  display: inline-flex;
  height: auto;
`;

export const AttributeTagIconContainer = styled.div`
  svg {
    vertical-align: text-top;
  }
`;
