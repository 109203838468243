import { useState, useRef, FC } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import ProductsOptions from 'src/components/ProductsOptions/ProductsOptions';
import { useMaybeMe } from 'src/hooks/api/useMe';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import useQueryParams from 'src/hooks/useQueryParams';

import {
  Container, Header, StyledCycleIcon, StyledZapierIcon, StyledNextArrowIcon,
  Body, Bold, StyledSelectButton, SelectPlaceholder, StyledSubmitButton, StyledSelectedProduct,
} from './Authorize.styles';

const endpoint = `${(process.env.HTTP_API_URI as string)}/oauth/authorize`;

const Authorize: FC = () => {
  const me = useMaybeMe();
  const queryParams = useQueryParams();
  const [isDropdownVisible, {
    setTrueCallback: setShowDropdown,
    setFalseCallback: setHideDropdown,
  }] = useOptimizedBooleanState(false);
  const selectRef = useRef<HTMLButtonElement>(null);
  const [seletedProductSlug, setSeletedProductSlug] = useState('');
  const [isLoading, { toggleCallback: setToggleLoading }] = useOptimizedBooleanState(false);
  const clientId = queryParams.get('client_id');
  const state = queryParams.get('state');
  const redirectUri = queryParams.get('redirect_uri');
  const responseType = queryParams.get('response_type');
  return (
    <Container>
      <Header>
        <StyledZapierIcon />
        <StyledNextArrowIcon />
        <StyledCycleIcon />
      </Header>
      <Body>
        <Bold>Zapier</Bold>
        {' '}
        is requesting access to
        {' '}
        <Bold>Cycle</Bold>
        {' '}
        workspace.
        <br />
        Choose in the list below.
      </Body>
      <DropdownLayer
        content={me && <ProductsOptions onItemSelected={onProductSelected} />}
        visible={isDropdownVisible}
        hide={setHideDropdown}
        placement="bottom-start"
        width={selectRef.current?.getBoundingClientRect().width}
      >
        <StyledSelectButton
          ref={selectRef}
          onClick={() => setShowDropdown()}
          variant="ternary"
          size="M"
          disabled={!me}
          isLoading={!me}
        >
          {renderSelectedProduct()}
        </StyledSelectButton>
      </DropdownLayer>
      <StyledSubmitButton
        size="L"
        isLoading={isLoading}
        onClick={onAuthorize}
        disabled={!seletedProductSlug}
      >
        Authorize
      </StyledSubmitButton>
    </Container>
  );

  function renderSelectedProduct() {
    const product = me?.products.edges.find(({ node }) => node.slug === seletedProductSlug);
    if (!product) return <SelectPlaceholder>Select a workspace</SelectPlaceholder>;
    return (
      <StyledSelectedProduct
        product={product.node}
        isActive={false}
        selected={false}
        disableHover
        hideMembers
      />
    );
  }

  function onProductSelected(slug: string | null) {
    if (slug) {
      setSeletedProductSlug(slug);
    }
    setHideDropdown();
  }

  async function onAuthorize() {
    const product = me?.products.edges.find(({ node }) => node.slug === seletedProductSlug);
    if (
      !product ||
      !clientId ||
      !redirectUri ||
      !responseType ||
      !state
    ) return;
    setToggleLoading();
    const url = new URL(endpoint);
    url.searchParams.append('client_id', clientId);
    url.searchParams.append('redirect_uri', redirectUri);
    url.searchParams.append('product_id', product.node.id);
    url.searchParams.append('response_type', responseType);
    url.searchParams.append('state', state);
    // eslint-disable-next-line no-restricted-globals
    location.href = url.toString();
    setToggleLoading();
  }
};

export default Authorize;
