import { useQuery } from '@apollo/client';
import { DocCommentsDocument, DocCommentsFragment, DocCommentsQueryVariables } from '@cycle-app/graphql-codegen';

interface Params {
  docId: string;
  skip?: boolean;
}
export default function useDocComments({
  docId,
  skip = false,
}: Params) {
  return useQuery<{ node: DocCommentsFragment }, DocCommentsQueryVariables>(DocCommentsDocument, {
    variables: { id: docId },
    skip,
    fetchPolicy: 'cache-and-network',
  });
}
