import { Feature } from '../types/features.types';

export const FEATURES: Record<Feature, string | undefined> = {
  [Feature.CachePersist]: process.env.FEATURE_CACHE_PERSIST,
  [Feature.Figma]: process.env.FEATURE_FIGMA,
  [Feature.EditAttributesModal]: process.env.FEATURE_EDIT_ATTRIBUTE_MODAL,
  [Feature.BoardCreateModal]: process.env.FEATURE_BOARD_CREATE_MODAL,
  [Feature.DocViewRightPanel]: process.env.FEATURE_DOC_VIEW_RIGHT_PANEL,
  [Feature.NewOptionSlackIntegration]: process.env.FEATURE_NEW_OPTION_SLACK_INTEGRATION,
  [Feature.LightOnboarding]: process.env.FEATURE_LIGHT_ONBOARDING,
};
