import { FC, useCallback } from 'react';

import useAppHotkeys from 'src/hooks/useAppHotkeys';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { ShortcutEditor } from 'src/types/shortcuts.types';

import { LinearIssueCreationModal } from './LinearIssueCreationModal';
import { Trigger } from './LinearIssueCreationModal.styles';

type Props = {
  children: JSX.Element;
  shortcut?: ShortcutEditor;
};

export const LinearIssueCreationModalTrigger: FC<Props> = ({
  children, shortcut,
}) => {
  const [isModalOpened, { toggleCallback: toggleModal }] = useOptimizedBooleanState(false);

  const handleToggle = useCallback((e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleModal();
  }, []);

  useAppHotkeys('command+3', handleToggle, {
    enabled: !!shortcut && shortcut === ShortcutEditor.TurnLinearIssue,
  });
  useAppHotkeys('command+"', handleToggle, {
    enabled: !!shortcut && shortcut === ShortcutEditor.TurnLinearIssue,
  });

  return (
    <>
      <Trigger onClick={toggleModal}>
        {children}
      </Trigger>
      {isModalOpened && (
        <LinearIssueCreationModal onHide={toggleModal} />
      )}
    </>
  );
};
