import { SelectLine, SelectOption, Tag, ActionButton } from '@cycle-app/ui';
import { AddIcon, UnlinkIcon } from '@cycle-app/ui/icons';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DoctypesOptionsPreview from 'src/components/DoctypesOptionsPreview/DoctypesOptionsPreview';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { Layer } from 'src/types/layers.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';
import { getOptionFromDoctype } from 'src/utils/selectOptions.util';

import { OptionsCard, StyledButton, TagDoctypeStyled, AttributeTagIconContainer, TagStyled } from './SettingsAttributes.styles';

interface Props {
  attribute: AttributeDefinitionsNode;
  doctypesOptions: SelectOption[];
}

const DoctypesRelationsManager: FC<Props> = ({
  attribute,
  doctypesOptions,
}) => {
  const {
    addAttributeDoctype, removeAttributeDoctype,
  } = useAttributesMutations();

  const options = useMemo(() => attribute.doctypes.edges.map(({ node }) => getOptionFromDoctype(node)), [attribute.doctypes.edges]);

  const [isMainDropdownVisible, {
    setFalseCallback: hideMainDropdown,
    toggleCallback: toggleMainDropdown,
  }] = useOptimizedBooleanState(false);
  const [isAddDoctypeDropdownVisible, {
    setTrueCallback: showAddDoctypeDropdown,
    setFalseCallback: hideAddDoctypeDropdown,
  }] = useOptimizedBooleanState(false);
  const [doctypeIdToUnlink, setDoctypeIdToUnlink] = useState<string | null>(null);

  useEffect(() => {
    if (isMainDropdownVisible && options.length === 0) {
      showAddDoctypeDropdown();
    }
  // We don't want to show the dropdown if options.length goes from 1 to 0, only on opening the main dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMainDropdownVisible, showAddDoctypeDropdown]);

  const unrelatedDocTypes = useMemo(
    () => doctypesOptions.filter(doctypeOption => !options.find(o => o.value === doctypeOption.value)),
    [doctypesOptions, options],
  );

  const resetDoctypeIdToUnlink = useCallback(() => setDoctypeIdToUnlink(null), []);
  const handleRemoveAttributeDoctype = useCallback(async () => {
    if (!doctypeIdToUnlink) return;
    await removeAttributeDoctype(attribute, doctypeIdToUnlink);
  }, [attribute, doctypeIdToUnlink, removeAttributeDoctype]);

  const doctype = useMemo(() => options.find(option => option.value === doctypeIdToUnlink), [options, doctypeIdToUnlink]);

  return (
    <>
      <DropdownLayer
        visible={isMainDropdownVisible}
        hide={hideMainDropdown}
        placement="left"
        content={(
          <OptionsCard disableHover>
            {options.map(option => (
              <SelectLine
                key={option.value}
                label={option.label}
                startSlot={option.icon ? <Tag icon={option.icon} /> : undefined}
                endSlot={(
                  <ActionButton
                    tooltip="Unlink doc type"
                    tooltipPlacement="top"
                    onClick={() => setDoctypeIdToUnlink(option.value)}
                    variant="warning"
                  >
                    <UnlinkIcon />
                  </ActionButton>
                )}
              />
            ))}
            {renderAddDoctypeInternalDropdown()}
          </OptionsCard>
        )}
      >
        <StyledButton onClick={toggleMainDropdown}>
          {options.length > 0
            ? <DoctypesOptionsPreview options={options} />
            : 'Link doc types'}
        </StyledButton>
      </DropdownLayer>

      {doctypeIdToUnlink && doctype && (
        <DialogModal
          title="Unlink doc type"
          info={(
            <>
              {'Are you sure you want to unlink '}
              <TagDoctypeStyled limitSize={false} icon={doctype.icon}>{doctype.label}</TagDoctypeStyled>
              {' from'}
              <TagStyled
                limitSize={false}
                icon={<AttributeTagIconContainer>{getCustomAttributeTypeData(attribute.__typename).icon}</AttributeTagIconContainer>}
              >
                {attribute.name}
              </TagStyled>
              ? Some docs might lose their value for this property.
            </>
          )}
          confirmLabel="Unlink"
          useHighMaskLayer
          hide={resetDoctypeIdToUnlink}
          onConfirm={handleRemoveAttributeDoctype}
        />
      )}
    </>
  );

  function renderAddDoctypeInternalDropdown() {
    return (
      <DropdownLayer
        visible={isAddDoctypeDropdownVisible}
        hide={hideAddDoctypeDropdown}
        placement="right"
        layer={Layer.DropdownZ2}
        content={(
          <OptionsCard disableHover>
            {unrelatedDocTypes.map(option => (
              <SelectLine
                key={option.value}
                label={option.label}
                onClick={onLinkDoctype(option.value)}
                startSlot={<Tag icon={option.icon} />}
              />
            ))}
          </OptionsCard>
        )}
      >
        {unrelatedDocTypes.length > 0 && (
          <SelectLine
            startSlot={<AddIcon />}
            label="Link new"
            onClick={showAddDoctypeDropdown}
          />
        )}
      </DropdownLayer>
    );
  }

  function onLinkDoctype(doctypeId: string) {
    return async () => {
      if (unrelatedDocTypes.length <= 1) {
        hideAddDoctypeDropdown();
      }
      await addAttributeDoctype(attribute, doctypeId);
    };
  }
};

export default DoctypesRelationsManager;
