import { useSubscription } from '@apollo/client';
import {
  CustomersImportedSubscription,
  CustomersImportedDocument,
  CustomersImportedSubscriptionVariables,
} from '@cycle-app/graphql-codegen';
import { CheckIcon } from '@cycle-app/ui/icons';
import { ucFirst } from '@cycle-app/ui/utils/ucFirst.util';

import { useToaster } from 'src/hooks/useToaster';
import { setIntegrationSync, getIntegrationSync } from 'src/reactives/integrationSync.reactive';

export const useCustomersImportedSubscription = (productId?: string | null) => {
  const { add: addToaster } = useToaster();
  return useSubscription<CustomersImportedSubscription, CustomersImportedSubscriptionVariables>(
    CustomersImportedDocument,
    {
      variables: {
        productId: productId as string,
      },
      onSubscriptionData: ({ subscriptionData }) => {
        const sourceType = subscriptionData.data?.customersImported;
        if (!sourceType || !getIntegrationSync()[sourceType]?.isSyncing) return;

        setIntegrationSync({
          [sourceType]: { isSyncing: false },
        });

        addToaster({
          id: `${sourceType}-sync`,
          title: `${ucFirst(sourceType)} customer sync done`,
          icon: <CheckIcon />,
        });
      },
    },
  );
};
