import { ShyScrollbar, typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import SavingLabel from 'src/components/SavingLabel/SavingLabel';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const ScrollableContent = styled(ShyScrollbar)`
  flex: 1;
  background-color: ${p => p.theme.colors.settings.bg};
  max-height: 100vh;
  padding: 0 32px 32px;
`;

export const ContentPage = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 24px;
`;

export const headerMarginBottom = '32px';

export const Header = styled.header`
  margin-bottom: ${headerMarginBottom};

  display: flex;
  gap: 8px;
  align-items: center;

  h1 {
    font-size: 26px;
    font-weight: 600;
    outline: none;

    small {
      font-size: 14px;
      line-height: 20px;
      color: ${(props) => props.theme.colors.text.secondary};
      font-weight: normal;
    }
  }

  padding: 0 8px 32px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.primary};

  ${mobile} {
    margin-top: 32px;
  }
`;

export const SavingLabelStyled = styled(SavingLabel)`
  margin-left: auto;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.body400}
`;

export const Actions = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 16px;
`;

export const EditableTitle = styled.input`
  border:none;
  background-image:none;
  background-color:transparent;
  box-shadow: none;
  min-width: 50px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;

  &:focus {
    outline: none;
  }
`;

export const Content = styled.div`
  /* 83px is the height of the Header, inclunding its margins */
  height: calc(100% - 83px);
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
