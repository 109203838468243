import { OnboardingLayout } from '@cycle-app/ui';

import { setOnboarding } from 'src/reactives/onboarding.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';

import { IntegrationsFlow } from './IntegrationsFlow';
import { IntegrationsLists } from './IntegrationsLists';

export type StepIntegrationsProps = {
  progress: number;
  isReadOnly?: boolean;
};

export const StepIntegrations = ({
  progress, isReadOnly,
}: StepIntegrationsProps) => {
  const isMobile = useIsMobile();

  const nextStep = () => setOnboarding({ screen: isReadOnly ? OnboardingScreen.Collaboration : OnboardingScreen.CreateDocs });

  return (
    <OnboardingLayout
      title={isReadOnly ? 'Your product management hub' : <>Let&apos;s set up your integrations</>}
      description={isReadOnly
        ? 'Cycle helps you collect, unify, and connect all product information across your tools. The above integrations have already been installed in your workspace'
        : 'Integrations are key in Cycle. They let you collect, unify, and connect all product information across your tools'}
      main={!isReadOnly && <IntegrationsLists isReadOnly={!!isReadOnly} />}
      aside={<IntegrationsFlow />}
      progress={progress}
      isSkippable={!isReadOnly}
      skipButtonProps={{ onClick: nextStep }}
      nextButtonProps={{
        onClick: () => {
          if (isReadOnly) {
            nextStep();
            return;
          }
          nextStep();
        },
      }}
      isModal={isReadOnly}
      {...isReadOnly && {
        cover: !isMobile && <IntegrationsFlow isCover isReadOnly />,
        prevButtonProps: {
          onClick: () => setOnboarding({ screen: OnboardingScreen.Colors }),
        },
      }}
      isFixedFooter={isReadOnly}
    />
  );
};
