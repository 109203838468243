import { BoardSectionFragment, BoardWithDraftConfigFragment, BoardWithMinimalConfigFragment, SectionType } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { TriangleIcon, AddIcon, CloseIcon } from '@cycle-app/ui/icons';
import { Feature, isEnabled } from '@cycle-app/utilities';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import {
  FC, HTMLAttributes, useRef, useState, ChangeEvent, useCallback, useEffect, useMemo,
} from 'react';

import { CreateDraftBoardModal } from 'src/components/CreateDraftBoardModal';
import SectionOptions from 'src/components/SectionOptions/SectionOptions';
import useBoardSectionsMutations from 'src/hooks/api/mutations/useBoardSectionsMutations';
import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useIsBoardActive } from 'src/hooks/useIsBoardActive';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import ThemeProvider from 'src/providers/ThemeProvider';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { OnboardingState, useGetOnboarding } from 'src/reactives/onboarding.reactive';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';

import { BoardSectionInput } from '../../SidebarBoard.styles';
import SidebarAddBoardModal from './SidebarAddBoardModal/SidebarAddBoardModal';
import { Container, BoardSectionNavigationItem } from './SidebarBoardSection.styles';
import SidebarItemBoardSortable from './SidebarItemBoard/SidebarItemBoardSortable';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  section: BoardSectionFragment;
  sections: BoardSectionFragment[];
  toggleSection?: (id: string) => void;
  isOpen: boolean;
  asPlaceholder?: boolean;
  isDragging?: boolean;
  boardsId?: string[];
  getBoard?: (boarId: string) => BoardWithMinimalConfigFragment | undefined;
  sortableListeners?: DraggableSyntheticListeners;
  setDraggableNodeRef?: (node: HTMLElement | null) => void;
  setDroppableNodeRef?: (node: HTMLElement | null) => void;
  sectionItemProps?: HTMLAttributes<HTMLDivElement>;
}

const IS_BOARD_NEW_MODAL_ENABLED = isEnabled(Feature.BoardCreateModal);
const OTHER_BOARD_NAME = 'Other views';
const BOARD_NAME = 'Views';
const getSectionName = (currentSection: BoardSectionFragment, AllSections: BoardSectionFragment[]) => {
  if (currentSection.type === SectionType.Default) {
    return AllSections.length === 1 ? BOARD_NAME : OTHER_BOARD_NAME;
  }
  return currentSection.name;
};

const SidebarBoardSection: FC<Props> = ({
  section,
  sections,
  isOpen,
  asPlaceholder,
  toggleSection,
  isDragging,
  boardsId,
  getBoard,
  setDroppableNodeRef,
  setDraggableNodeRef,
  ...htmlProps
}) => {
  const {
    canReorderBoards, canCreateBoard,
  } = useGetPermission();
  const { canPlanCreateBoard } = useGetBillingPermission();
  const collapsed = useSidebarCollapsed();
  const isBoardActive = useIsBoardActive();
  const isOnboarding = useIsOnboarding();
  const {
    removeSection,
    renameSection,
  } = useBoardSectionsMutations();
  const { addSectionDraftBoard } = useProductMutations();
  const { screen } = useGetOnboarding();

  const inputRef = useRef<HTMLInputElement>(null);

  const [draftBoard, setDraftBoard] = useState<{ showModal: boolean; board: BoardWithDraftConfigFragment | null }>({
    showModal: false,
    board: null,
  });
  const [sectionName, setSectionName] = useState(getSectionName(section, sections));
  const [isSectionMenuOpen, setIsSectionMenuOpen] = useState(false);
  const [isAddBoardModalOpen, setIsAddBoardModalOpen] = useState(false);
  const [isEditingName, {
    setTrueCallback: showEditingMode,
    setFalseCallback: hideEditingMode,
  }] = useOptimizedBooleanState(false);

  const handleSubmit = useCallback(
    () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      renameSection(section.id, sectionName);
      hideEditingMode();
    },
    [renameSection, hideEditingMode, section.id, sectionName],
  );

  const onResetEditingMode = useCallback(() => {
    setSectionName(section.name);
    hideEditingMode();
  }, [hideEditingMode, section.name]);

  useAppHotkeys('enter', handleSubmit, {
    enabled: isEditingName && sectionName.length > 0,
  });
  useAppHotkeys('escape', onResetEditingMode, {
    enabled: isEditingName,
  });
  useEffect(() => {
    if (isEditingName) {
      inputRef.current?.focus();
    }
  }, [isEditingName]);
  useEffect(() => {
    if (section.type === SectionType.Default && [OTHER_BOARD_NAME, BOARD_NAME].includes(section.name)) {
      setSectionName(getSectionName(section, sections));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections.length]);

  const onClick = useCallback(() => {
    toggleSection?.(section.id);
  }, [section.id, toggleSection]);

  const triangleIcon = useMemo(() => <TriangleIcon direction={isOpen ? 'bottom' : 'right'} />, [isOpen]);
  const contentPlus = useMemo(() => {
    if (!canCreateBoard) return null;
    return (
      <ActionButton
        ariaLabel="Add view"
        onClick={async (e) => {
          e.stopPropagation();
          if (!canPlanCreateBoard) {
            setLimitationsModal({
              action: 'BOARD_CREATE',
            });
            return;
          }
          if (IS_BOARD_NEW_MODAL_ENABLED) {
            setDraftBoard({
              showModal: true,
              board: null,
            });
            const result = await addSectionDraftBoard({ sectionId: section.id });
            if (result.data?.createDraftBoard) {
              setDraftBoard({
                showModal: true,
                board: result.data?.createDraftBoard,
              });
            }
            return;
          }
          setIsAddBoardModalOpen(true);
        }}
      >
        <AddIcon />
      </ActionButton>
    );
  }, [canCreateBoard, canPlanCreateBoard]);
  const contentMore = useMemo(() => (
    <SectionOptions
      section={section}
      onEditSectionName={showEditingMode}
      onRemoveSection={removeSection}
      onClick={() => setIsSectionMenuOpen(true)}
      onHide={() => setIsSectionMenuOpen(false)}
    />
  ), [removeSection, section, showEditingMode]);

  if (isEditingName) {
    return (
      <BoardSectionInput
        ref={inputRef}
        value={sectionName}
        placeholder="Section name"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSectionName(e.target.value)}
        iconAfter={<CloseIcon />}
        onBlur={onResetEditingMode}
        onClickIcon={onResetEditingMode}
      />
    );
  }

  const screenRevealSections: OnboardingState['screen'][] = [
    OnboardingScreen.Boards,
    OnboardingScreen.Welcome,
  ];
  const screenRevealActiveBoards: OnboardingState['screen'][] = [
    OnboardingScreen.CreateDocs,
    OnboardingScreen.Boards,
    OnboardingScreen.Welcome,
  ];
  const screensRevealBoards: OnboardingState['screen'][] = [
    OnboardingScreen.Boards,
    OnboardingScreen.Welcome,
  ];

  const filteredBoardsdId = boardsId?.filter(boardId => collapsed || isOpen || isBoardActive({ boardId })) ?? [];

  return (
    <Container
      {...htmlProps}
      ref={setDroppableNodeRef}
      $collapsed={collapsed}
    >
      {!collapsed && (
        <BoardSectionNavigationItem
          setNodeRef={setDraggableNodeRef}
          className="item--group"
          onClick={onClick}
          icon={triangleIcon}
          label={sectionName}
          isFocus={isSectionMenuOpen}
          contentPlus={contentPlus}
          contentMore={contentMore}
          isDragging={isDragging}
          asPlaceholder={asPlaceholder || (isOnboarding && !screenRevealSections.includes(screen))}
          isSidebarColor
          placeholderScale={isOnboarding ? [sectionName.length / 23, 0.6] : undefined}
          $isOpen={isOpen || filteredBoardsdId.length > 0}
        />
      )}
      {!isDragging && boardsId && getBoard && (
        <SortableContext
          items={boardsId}
          disabled={!canReorderBoards}
        >
          {filteredBoardsdId
            .map((boardId) => {
              const board = getBoard(boardId);
              return board ? (
                <SidebarItemBoardSortable
                  key={boardId}
                  board={board}
                  isActive={isBoardActive({ boardId })}
                  asPlaceholder={
                    isBoardActive({ boardId })
                      ? isOnboarding && !screenRevealActiveBoards.includes(screen)
                      : isOnboarding && !screensRevealBoards.includes(screen)
                  }
                  placeholderScale={isOnboarding ? [board.name.length / 23, 0.3] : undefined}
                  activeAlpha={isOnboarding ? 1 : undefined}
                />
              ) : null;
            })}
        </SortableContext>
      )}
      {draftBoard.showModal && (
        <ThemeProvider>
          <CreateDraftBoardModal
            onHide={() => setDraftBoard({
              showModal: false,
              board: null,
            })}
            draftBoard={draftBoard.board}
          />
        </ThemeProvider>
      )}
      {isAddBoardModalOpen && (
        <SidebarAddBoardModal
          sectionId={section.id}
          onHide={() => setIsAddBoardModalOpen(false)}
        />
      )}
    </Container>
  );
};

export default SidebarBoardSection;
