import { FC, useCallback } from 'react';

import BoardEditCommonModal from 'src/components/BoardEditCommonModal/BoardEditCommonModal';
import { BoardEditCommonFormData } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { PageId } from 'src/constants/routing.constant';
import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import { useAppParams } from 'src/hooks/useAppParams';
import { useNavigate } from 'src/hooks/useNavigate';
import { getBoardSlug } from 'src/utils/slug.util';

interface SidebarAddBoardModalProps {
  sectionId: string;
  onHide: VoidFunction;
}

/*
  This component is not complete as the board creation mutation lacks parameters
  and the right side preview will be implemented later
  Figma: https://www.figma.com/file/SFnrkZXOc139xH3ctvbjS0/Master-1.0?node-id=933%3A13240
*/
const SidebarAddBoardModal: FC<SidebarAddBoardModalProps> = ({
  sectionId, onHide,
}) => {
  const { navigate } = useNavigate();
  const { productSlug } = useAppParams();
  const {
    addSectionBoard, loading,
  } = useProductMutations();

  const onSubmit = useCallback(async (formData: BoardEditCommonFormData) => {
    const res = await addSectionBoard({
      sectionId,
      ...formData,
    });

    if (res.data?.addSectionBoard && productSlug) {
      onHide();
      navigate(PageId.Board, {
        productSlug,
        boardSlug: getBoardSlug(res.data.addSectionBoard),
      });
    }
  }, [addSectionBoard, navigate, onHide, productSlug, sectionId]);

  return (
    <BoardEditCommonModal
      title="Create new view"
      loading={loading}
      onHide={onHide}
      onSubmit={onSubmit}
    />
  );
};

export default SidebarAddBoardModal;
