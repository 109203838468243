import { FC, ComponentProps } from 'react';

import { Menu } from './ImageMenu.styles';

export type ImageMenuProps = ComponentProps<typeof Menu> & {
  forceVisible?: boolean;
};

export const ImageMenu: FC<ImageMenuProps> = ({
  forceVisible = false,
  ...props
}) => (
  <Menu
    forceVisible={forceVisible}
    className="image-menu"
    {...props}
  />
);
