import { Color, ThemeType } from '@cycle-app/graphql-codegen';

import { colors as eclipseColors } from '../theme/colorThemes/eclipse';
import { DeepPartial } from './partial.types';

export type BaseColorTheme = typeof eclipseColors;
export type SubColorTheme = DeepPartial<BaseColorTheme>;

export const COLOR_LETTERS = Object.values(Color);
export const COLOR_THEMES_TYPES = [ThemeType.Eclipse, ThemeType.Nightfall, ThemeType.Sunrise];
