import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { ContentList } from '../../BoardGroup/BoardGroup.styles';
import { FormContainer } from '../OnboardingView.styles';

interface GroupListProps {
  $isRow?: boolean;
}

export const StyledFormContainer = styled(FormContainer)`
  margin-top: 24px;
`;

export const StyledContentList = styled(ContentList)<GroupListProps>`
  padding-bottom: 8px;
`;

export const GroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  padding: 0 8px;
`;

export const ActionsContainerSkeleton = styled.div`
  color: ${p => p.theme.colors.background.hover};
  display: flex;
  gap: 8px;
`;

export const ErrorHelper = styled.div`
  ${typo.body400};
  color: ${({ theme }) => theme.colors.text.red};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
