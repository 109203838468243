import { Tr, Td, CellSkeleton } from './Table.styles';

const range = (length: number) => Array.from({ length }, (_, i) => i + 1);

export interface RowsSkeletonProps {
  rows: number;
  cols: number;
}

export const RowsSkeleton = ({
  rows, cols,
}: RowsSkeletonProps) => (
  <>
    {range(rows).map(i => (
      <Tr key={i}>
        {range(cols).map(j => (
          <Td key={j}>
            <CellSkeleton />
          </Td>
        ))}
      </Tr>
    ))}
  </>
);
