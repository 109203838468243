import { FC } from 'react';

import { Svg, FILL_USER_COLOR_CLASS_NAME } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const BellActiveIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.00002 2.25635C7.79109 2.25635 7.59071 2.33935 7.44297 2.48709C7.29769 2.63237 7.21501 2.82857 7.21231 3.03374C7.21427 3.15661 7.17993 3.27726 7.11483 3.38028C7.06778 3.45472 7.00466 3.51996 6.92754 3.57018C6.90004 3.58817 6.87104 3.60406 6.84078 3.61763C6.13588 3.95355 5.53492 4.47397 5.1017 5.12381C4.66869 5.77332 4.41947 6.52779 4.38031 7.30725V9.41596C4.38031 9.44119 4.37879 9.4664 4.37576 9.49145C4.31464 9.99641 4.14299 10.4813 3.8735 10.9117H12.1265C11.8571 10.4813 11.6854 9.99641 11.6243 9.49145C11.6213 9.4664 11.6197 9.44119 11.6197 9.41596V8.98216C11.7449 8.99396 11.8717 9 12 9C12.3009 9 12.5941 8.96677 12.8761 8.90377V9.3757C12.9214 9.70547 13.0409 10.0209 13.2257 10.2981C13.4171 10.5851 13.6732 10.8233 13.9734 10.9933C14.2217 11.1339 14.344 11.4241 14.2713 11.7C14.1986 11.9758 13.9492 12.1681 13.6639 12.1681H10.7521V12.2479C10.7521 12.9778 10.4622 13.6778 9.94604 14.1939C9.42992 14.71 8.72991 15 8 15C7.2701 15 6.57008 14.71 6.05396 14.1939C5.53784 13.6778 5.24789 12.9778 5.24789 12.2479V12.1681H2.33618C2.05089 12.1681 1.80142 11.9758 1.72874 11.7C1.65605 11.4241 1.77839 11.1339 2.02664 10.9933C2.32687 10.8233 2.58298 10.5851 2.77437 10.2981C2.95918 10.0209 3.07864 9.70547 3.12397 9.3757V7.29202C3.12397 7.2822 3.1242 7.27238 3.12466 7.26257C3.17217 6.25032 3.49424 5.27008 4.05635 4.42691C4.55197 3.68349 5.21772 3.07133 5.99623 2.63998C6.07522 2.2484 6.26794 1.88537 6.5546 1.59871C6.93795 1.21536 7.45788 1 8.00002 1C8.54216 1 9.0621 1.21536 9.44545 1.59871C9.50578 1.65904 9.56195 1.72276 9.61377 1.78941C9.26699 2.04757 8.96347 2.36059 8.71604 2.71561C8.6774 2.6314 8.62385 2.55387 8.55707 2.48709C8.40933 2.33935 8.20896 2.25635 8.00002 2.25635ZM6.50423 12.1681H9.49577V12.2479C9.49577 12.6446 9.33818 13.025 9.05767 13.3056C8.77716 13.5861 8.3967 13.7437 8 13.7437C7.6033 13.7437 7.22285 13.5861 6.94233 13.3056C6.66182 13.025 6.50423 12.6446 6.50423 12.2479V12.1681Z" fill="currentcolor" />
    <circle className={FILL_USER_COLOR_CLASS_NAME} cx="12" cy="5" r="3.5" fill="#4058F2" stroke="transparent" />
  </Svg>
);
