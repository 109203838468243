import { StarIcon } from '@cycle-app/ui/icons';

import { HomeAddStarBoardDropDown } from './HomeAddStarBoardDropDown';
import {
  CardDescription,
  CardTitle,
  PlaceHolderCard,
  Star,
} from './HomeStarredBoardSection.styles';

export const HomeAddStarPlaceholder = () => (
  <HomeAddStarBoardDropDown>
    {props => (
      <PlaceHolderCard onClick={e => {
        props.setCursorPosition({
          x: e.clientX,
          y: e.clientY,
        });
        props.toggle();
      }}
      >
        <Star>
          <StarIcon />
        </Star>
        <CardTitle>No starred view yet</CardTitle>
        <CardDescription>Click here to select a view</CardDescription>
      </PlaceHolderCard>
    )}
  </HomeAddStarBoardDropDown>
);
