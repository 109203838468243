import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  getActiveProductTourEl,
  getActiveProductTourNextStep,
  setActiveProductTour,
  useActiveProductTour,
} from 'src/reactives/productTours.reactive';
import { TourName, TourStep } from 'src/types/productTour.types';

export const useBubbleMenuProductTour = () => {
  const tour = useActiveProductTour();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);

  const setNextStep = () => {
    const nextStep = getActiveProductTourNextStep();

    if (!nextStep) return;

    setActiveProductTour({ step: nextStep });
  };

  const isActive = tour?.name === TourName.FIRST_TOUR;

  const shouldPreventEsc = isEnabled &&
    tour?.name === TourName.FIRST_TOUR &&
    tour.step === TourStep.TurnHighlightIntoInsight;

  const setTurnIntoInsightStep = () => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.HighlightFeedbackText
    ) return;

    setNextStep();
  };

  const setChooseInitiativeStep = () => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.TurnHighlightIntoInsight
    ) return;

    setNextStep();
  };

  const setInsightActionButtonEl = (el?: HTMLElement | null) => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.TurnHighlightIntoInsight ||
      !el ||
      getActiveProductTourEl() === el
    ) return;

    setActiveProductTour({ el });
  };

  return {
    isActive,
    setChooseInitiativeStep,
    setInsightActionButtonEl,
    setTurnIntoInsightStep,
    shouldPreventEsc,
  };
};
