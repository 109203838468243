import {
  UpdateGithubIssueProjectStatusDocument,
  UpdateGithubIssueProjectStatusMutationVariables,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useChangeStatusGithubIssue = () => {
  const [changeMutation, { loading }] = useSafeMutation(UpdateGithubIssueProjectStatusDocument);

  const change = ({
    issueId, providerId, value,
  }: UpdateGithubIssueProjectStatusMutationVariables) => {
    if (!issueId || !providerId) throw Error('Data are missing');

    return changeMutation({
      variables: {
        providerId,
        issueId,
        value,
      },
    });
  };

  return {
    change,
    isLoading: loading,
  };
};
