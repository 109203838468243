import { FC } from 'react';

import { CompanyProfileModal } from 'src/components/CustomerProfile/CompanyProfileModal';
import { CustomerProfileModal } from 'src/components/CustomerProfile/CustomerProfileModal';
import { DocPreviewModal } from 'src/components/DocPreviewModal/DocPreviewModal';
import { LimitationModal } from 'src/components/LimitationModal';
import { ProductTour } from 'src/components/ProductTour';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';

export const AppGlobalDialogs: FC = () => {
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);

  return (
    <>
      <CompanyProfileModal />
      <CustomerProfileModal />
      <DocPreviewModal />
      <LimitationModal />
      {isEnabled && <ProductTour />}
    </>
  );
};
