/**
 * GraphQL type guards
 * @see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 * @example attributes.filter(isDocAttributeEmail).map(attribute => attribute.emailValue)
 */
export const isTypename = <T extends string>(...typenames: T[]) => <
  N extends { __typename?: string },
>(node?: N | null): node is Extract<N, { __typename?: T }> => typenames.some(t => node?.__typename === t);

export const isBoardQueryWithGroupBy = isTypename('BoardQueryWithGroupBy');
export const isStatus = isTypename('Status');
export const isStatusDefinition = isTypename('StatusDefinition');
export const isBoardQuery = isTypename('BoardQuery');
