import { DoctypeFragment, DoctypeRelativeFragment } from '@cycle-app/graphql-codegen';
import { Position, Elements } from 'react-flow-renderer';

import { findInsight } from 'src/utils/docType.util';
import { FlowData } from 'src/utils/flow.util';

import { defaultOptions } from './defaultOptions';

export type FeedbackHierarchyOptions = {
  integrationsEdgeType?: FlowData['edgeType'];
  insightParents?: DoctypeRelativeFragment[];
};

type GetFeedbackHierarchyFn = (
  docType: DoctypeFragment,
  docTypes: DoctypeFragment[],
  options?: FeedbackHierarchyOptions,
) => Elements;

export const getFeedbackHierarchyElements: GetFeedbackHierarchyFn = (
  docType,
  docTypes,
  {
    integrationsEdgeType = 'default',
    insightParents,
  } = {},
) => {
  const integrationsId = 'integrations';
  const feedbackId = docType.id;
  const insightRelativeDoctype = findInsight(docTypes);
  const insightId = insightRelativeDoctype?.id ?? '';

  const insightElements = insightParents
    ? insightParents.flatMap((parent, i) => createInsightElements({
      feedbackId,
      insightId: `${insightId}-${i}`,
      parentId: parent.id,
      index: i,
      iterate: insightParents.length > 1,
      hideInsightParents: docTypes.length > 3,
    }))
    : createInsightElements({
      feedbackId,
      insightId,
    });

  return [
    {
      ...defaultOptions,
      id: integrationsId,
      type: 'integrations',
      sourcePosition: Position.Right,
      data: {
        indexColumn: 0,
        level: 0,
      },
    },
    {
      ...defaultOptions,
      id: feedbackId,
      type: 'custom',
      targetPosition: Position.Left,
      data: {
        doctypeId: feedbackId,
        indexColumn: 0.5,
        level: 0,
      },
    },
    {
      id: `link-${integrationsId}-${feedbackId}`,
      source: integrationsId,
      target: feedbackId,
      type: 'customEdge',
      data: {
        edgeType: integrationsEdgeType,
        markerType: integrationsEdgeType === 'integrations' ? 'default' : 'arrow',
      },
    },
    ...insightElements,
  ];
};

type CreateInsightElementsFn = (options: {
  feedbackId: string;
  insightId: string;
  parentId?: string;
  index?: number;
  iterate?: boolean;
  hideInsightParents?: boolean;
}) => Elements;

const createInsightElements: CreateInsightElementsFn = ({
  feedbackId,
  insightId,
  parentId,
  index = 0,
  iterate = false,
  hideInsightParents = true,
}) => {
  const parentElements: Elements = !parentId ? [] : [
    {
      ...defaultOptions,
      id: parentId,
      type: 'custom',
      targetPosition: Position.Left,
      data: {
        doctypeId: parentId,
        level: 0.3,
        indexColumn: 1,
        indexChild: index * 1.8,
        target: 'parent',
        phantom: hideInsightParents,
      },
    },
    {
      id: `link-${insightId}-${parentId}`,
      source: insightId,
      target: parentId,
      type: 'customEdge',
      data: {
        edgeType: 'insight',
        markerType: 'insight',
        insightParentId: parentId,
      },
    },
  ];

  return [
    {
      ...defaultOptions,
      id: insightId,
      type: 'custom',
      targetPosition: Position.Left,
      sourcePosition: Position.Top,
      data: {
        doctypeId: insightId,
        level: 1,
        indexColumn: 0.55,
        indexChild: index * 1.8,
        iteration: iterate ? index + 1 : 0,
        insightParentId: parentId,
      },
    },
    {
      id: `link-${insightId}-${feedbackId}`,
      source: feedbackId,
      target: insightId,
      type: 'customEdge',
    },
    ...parentElements,
  ];
};
