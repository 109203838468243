import { useSortable } from '@dnd-kit/sortable';
import { FC } from 'react';

import { getSortableStyle } from 'src/utils/dnd.util';

import SettingsAttributesLine, { SettingsAttributesLineProps } from './SettingsAttributesLine';

interface SettingsAttributesLineSortableProps extends Omit<SettingsAttributesLineProps, 'rowProps' | 'buttonHandleProps'> {
  asPlaceholder?: boolean;
}

const SettingsAttributesLineSortable: FC<SettingsAttributesLineSortableProps> = ({
  attribute, asPlaceholder = false, ...rest
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
  } = useSortable({ id: attribute.id });
  const style = getSortableStyle({
    transform,
    transition,
  });

  return (
    <SettingsAttributesLine
      attribute={attribute}
      rowProps={{
        ref: setNodeRef,
        style,
        asPlaceholder,
      }}
      buttonHandleProps={{
        ...attributes,
        ...listeners,
      }}
      {...rest}
    />
  );
};

export default SettingsAttributesLineSortable;
