/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';

import { delay } from '../../utils/async.util';

const colors = [
  'rgb(255, 210, 49)',
  'rgb(39, 226, 160)',
  'rgb(242, 187, 251)',
];

export const LoginTextAnimation = () => {
  const containerControls = useAnimation();
  const underlineControls = useAnimation();
  const ref1 = useRef<HTMLSpanElement>(null);
  const ref2 = useRef<HTMLSpanElement>(null);
  const ref3 = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const texts = [ref1.current, ref2.current, ref3.current] as HTMLSpanElement[];

    // get the text sizes
    const rects = texts.map(text => text.getBoundingClientRect());

    // show the first text
    for (const text of texts) text.style.display = 'none';
    texts[0].style.display = 'block';
    containerControls.set({
      width: rects[0].width,
      opacity: 1,
    });

    const animateColor = (index: number) => {
      const transition = {
        duration: 0.2,
        delay: 0.3,
      };
      containerControls.start({
        color: colors[index],
        transition,
      });
      underlineControls.start({
        backgroundColor: colors[index],
        transition,
      });
    };

    const animateUp = (index: number) => underlineControls.start({
      height: rects[index].height + 3,
      transition: {
        type: 'spring',
        duration: 0.6,
        bounce: 0.2,
      },
    });

    const animateWidth = (index: number) => containerControls.start({
      width: rects[index].width,
      transition: {
        type: 'spring',
        bounce: 0.2,
        duration: 0.3,
      },
    });

    const animateDown = () => underlineControls.start({
      height: 3,
      transition: {
        type: 'spring',
        duration: 0.4,
        bounce: 0.3,
        delay: 0.1,
      },
    });

    (async function loop(curr: number) {
      await delay(2220);
      const next = (curr + 1) % 3;
      animateColor(next);
      animateUp(next);
      await delay(200);
      texts[curr].style.display = 'none';
      texts[next].style.display = 'block';
      animateWidth(next);
      await animateDown();
      loop(next);
    }(0));
  }, []);

  return (
    <motion.span
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        position: 'relative',
        overflowX: 'clip',
        overflowY: 'visible',
        color: colors[0],
        opacity: 0,
      }}
      animate={containerControls}
    >
      <span ref={ref1}>
        delight
      </span>

      <span ref={ref2}>
        trust
      </span>

      <span ref={ref3}>
        love
      </span>

      <motion.span
        style={{
          display: 'block',
          backgroundColor: colors[0],
          height: 3,
          width: '100%',
          position: 'absolute',
          left: 0,
          bottom: -8,
        }}
        animate={underlineControls}
      />
    </motion.span>
  );
};
