import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const DownloadIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.67747 1.67742C8.67747 1.30329 8.37418 1 8.00005 1C7.62592 1 7.32263 1.30329 7.32263 1.67742V8.47201L4.96649 6.11586C4.70194 5.85131 4.27302 5.85131 4.00847 6.11586C3.74392 6.38041 3.74392 6.80933 4.00847 7.07388L7.51473 10.5801C7.63778 10.7065 7.80975 10.7849 8.00005 10.7849C8.1913 10.7849 8.36404 10.7057 8.48722 10.5782L11.9916 7.07388C12.2561 6.80933 12.2561 6.38041 11.9916 6.11586C11.727 5.85131 11.2981 5.85131 11.0336 6.11586L8.67747 8.47195V1.67742ZM1.67742 9.43027C2.05155 9.43027 2.35484 9.73356 2.35484 10.1077V12.9177C2.35484 13.1107 2.4315 13.2958 2.56795 13.4322C2.7044 13.5687 2.88947 13.6453 3.08244 13.6453H12.9176C13.1105 13.6453 13.2956 13.5687 13.4321 13.4322C13.5685 13.2958 13.6452 13.1107 13.6452 12.9177V10.1077C13.6452 9.73356 13.9485 9.43027 14.3226 9.43027C14.6967 9.43027 15 9.73356 15 10.1077V12.9177C15 13.47 14.7806 13.9997 14.3901 14.3902C13.9995 14.7808 13.4699 15.0002 12.9176 15.0002H3.08244C2.53014 15.0002 2.00046 14.7808 1.60993 14.3902C1.2194 13.9997 1 13.47 1 12.9177V10.1077C1 9.73356 1.30329 9.43027 1.67742 9.43027Z" />
  </Svg>
);
