import { typo, Input, Button, ShyScrollbar } from '@cycle-app/ui';
import styled from 'styled-components';

import { QUICK_ACTIONS_CLASSNAME } from 'src/components/Editor';
import PortalModal from 'src/components/PortalModal/PortalModal';

export const PortalModalStyled = styled(PortalModal)`
  position: absolute;
  left: 84px;
  right: 62px;
  top: 79px;
  bottom: 89px;
  padding: 0;
`;

export const SidebarListContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 242px;
  background-color: ${p => p.theme.colors.background.secondary};
`;

export const SearchTemplate = styled(Input)`
  padding: 16px;
  padding-bottom: 0;
`;

export const TemplateView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 20px 0;
`;

export const TemplateViewHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${p => p.theme.colors.border.hover};
  ${typo.body500};

  > button {
    margin-left: auto;
  }
`;

export const EditorContainer = styled(ShyScrollbar)`
  padding: 32px 16px;
  height: 100%;

  .ProseMirror {
    padding-bottom: 24px;
  }

  /* Setting the padding to ProseMirror class is intentional, 
    to be able to select a line even when clicking outside the editor (right and left)
  */
  .ProseMirror,
  .${QUICK_ACTIONS_CLASSNAME} {
    padding-left: calc((100% - 750px) / 2);
    padding-right: calc((100% - 750px) / 2);
    margin-bottom: 0;
  }
`;

export const SelectButton = styled(Button)`
  padding: inherit 16px;
`;
