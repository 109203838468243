import { FC } from 'react';

import {
  CardContainer,
  CardBody,
  IntegrationTitle,
  LogoSkeleton,
  TitleSkeleton,
  CardSide,
} from './IntegrationCard.styles';

export const IntegrationCardSkeleton: FC = () => (
  <CardContainer>
    <CardBody>
      <IntegrationTitle>
        <LogoSkeleton />
        <TitleSkeleton />
      </IntegrationTitle>

      <CardSide>
        <TitleSkeleton />
      </CardSide>
    </CardBody>
  </CardContainer>
);
