import { FC } from 'react';

import { Svg } from '../components/Svg/Svg.styles';
import { Props } from '../types/icon.types';

export const BuildingIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M0 19C0 19.5304 0.210714 20.0391 0.585786 20.4142C0.960859 20.7893 1.46957 21 2 21H5.75C5.94891 21 6.13968 20.921 6.28033 20.7803C6.42098 20.6397 6.5 20.4489 6.5 20.25V18H9.5V20.25C9.5 20.664 9.836 21 10.25 21H14C14.092 21 14.183 20.994 14.272 20.982C14.3265 20.9941 14.3822 21.0002 14.438 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V11C20 10.6895 19.9277 10.3833 19.7889 10.1056C19.65 9.82786 19.4484 9.58629 19.2 9.4L18.7 9.025C18.5409 8.90565 18.3408 8.85441 18.1439 8.88254C17.947 8.91067 17.7693 9.01587 17.65 9.175C17.5307 9.33413 17.4794 9.53415 17.5075 9.73107C17.5357 9.92798 17.6409 10.1057 17.8 10.225L18.3 10.6C18.3621 10.6466 18.4125 10.707 18.4472 10.7764C18.4819 10.8458 18.5 10.9224 18.5 11V19C18.5 19.1326 18.4473 19.2598 18.3536 19.3536C18.2598 19.4473 18.1326 19.5 18 19.5H15.937C15.978 19.34 16 19.173 16 19V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V19ZM2 19.5C1.86739 19.5 1.74021 19.4473 1.64645 19.3536C1.55268 19.2598 1.5 19.1326 1.5 19V2C1.5 1.86739 1.55268 1.74021 1.64645 1.64645C1.74021 1.55268 1.86739 1.5 2 1.5H14C14.1326 1.5 14.2598 1.55268 14.3536 1.64645C14.4473 1.74021 14.5 1.86739 14.5 2V19C14.5 19.1326 14.4473 19.2598 14.3536 19.3536C14.2598 19.4473 14.1326 19.5 14 19.5H11V17.25C11 17.0511 10.921 16.8603 10.7803 16.7197C10.6397 16.579 10.4489 16.5 10.25 16.5H5.75C5.55109 16.5 5.36032 16.579 5.21967 16.7197C5.07902 16.8603 5 17.0511 5 17.25V19.5H2Z" fill="currentColor" />
  </Svg>
);

export default BuildingIcon;
