import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const ItalicIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.9853 2.00018H12.5936C13.0078 2.00018 13.3436 2.33596 13.3436 2.75016C13.3436 3.16436 13.0078 3.50013 12.5936 3.50013H10.4884L7.11353 12.4998H9.31246C9.72666 12.4998 10.0624 12.8356 10.0624 13.2498C10.0624 13.664 9.72666 13.9998 9.31246 13.9998H6.04986C6.03745 14.0001 6.025 14.0001 6.01253 13.9998H3.40641C2.99221 13.9998 2.65643 13.664 2.65643 13.2498C2.65643 12.8356 2.99221 12.4998 3.40641 12.4998H5.51158L8.88647 3.50013H6.68754C6.27334 3.50013 5.93756 3.16436 5.93756 2.75016C5.93756 2.33596 6.27334 2.00018 6.68754 2.00018H9.95227C9.96326 1.99994 9.97427 1.99994 9.9853 2.00018Z" fill="#171618" />
  </Svg>
);
