import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal/CreateDocModal';
import { HomeHeader } from 'src/components/HomeHeader/HomeHeader';
import { HomeMembersSection } from 'src/components/HomeMembersSection';
import { HomeQuickActionSection } from 'src/components/HomeQuickActionSection';
import { HomeRecentDocsSection } from 'src/components/HomeRecentDocsSection';
import { HomeStarredBoardSection } from 'src/components/HomeStarredBoardSection';

import { Container, Body } from './HomeView.styles';

export const HomeView = () => (
  <Container>
    <HomeHeader />
    <Body>
      <HomeQuickActionSection />
      <HomeStarredBoardSection />
      <HomeMembersSection />
      <HomeRecentDocsSection />
    </Body>
    <CreateDocModal />
  </Container>
);
