/* eslint-disable jsx-a11y/control-has-associated-label */
import { Color as ColorApi, MoveAttributeInProductListDocument } from '@cycle-app/graphql-codegen';
import { Button, Emoji } from '@cycle-app/ui';
import { toObject, isEnabled, Feature } from '@cycle-app/utilities';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useMemo, useState } from 'react';

import { useAttributes } from 'src/hooks/api/useAttributes';
import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { useLoader } from 'src/hooks/useLoader';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useSortableDnd } from 'src/hooks/useSortableDnd';
import { getOptionFromDoctype } from 'src/utils/selectOptions.util';

import {
  Content,
  Header,
  SavingLabelStyled,
} from '../Settings.styles';
import { SettingsAttributeEditModal } from './SettingsAttributeEditModal';
import {
  GridContainer,
  NoContent,
  PropertyPreview,
  PreviewColor,
  PreviewLine,
  PreviewTitle,
  PreviewSubTitle,
  Table,
  AddNewLine,
  StyledDragOverlay,
} from './SettingsAttributes.styles';
import SettingsAttributesLine from './SettingsAttributesLine';
import SettingsAttributesLineDraft from './SettingsAttributesLineDraft';
import SettingsAttributesLineSortable from './SettingsAttributesLineSortable';

const previews = [
  {
    color: ColorApi.A,
    emoji: ':cherry_blossom:',
  },
  {
    color: ColorApi.E,
    emoji: ':fire:',
  },
];

const IS_CREATION_MODAL_ENABLED = isEnabled(Feature.EditAttributesModal);

const SettingsAttributes: FC = () => {
  const attributes = useAttributes();
  const doctypes = useProductDoctypes();
  const [isAddingAttribute, setIsAddingAttribute] = useState(false);
  const [isNewAttributeModalOpened, { toggleCallback: toggleNewAttributeModal }] = useOptimizedBooleanState(false);
  const [moveAttributeInProductList, { loading }] = useSafeMutation(MoveAttributeInProductListDocument);
  useLoader({ loading });

  const {
    activeId,
    dndContextProps,
    items,
  } = useSortableDnd({
    initialItems: attributes.map(attribute => attribute.id),
    onFinish: async ({ position }) => {
      if (!activeId) return;
      await moveAttributeInProductList({
        variables: {
          attributeId: activeId,
          position,
        },
        // For now no need for optimistic updates or such since the DnD hook has it's own internal state updated.
        // Confirm this works well once backend is finilized
      });
    },
  });
  const attributesMapping = toObject(attributes);
  const activeAttribute = activeId ? attributesMapping[activeId] : null;

  const doctypesOptions = useMemo(() => doctypes.map(getOptionFromDoctype), [doctypes]);

  return (
    <>
      <Header>
        <h1>
          Properties
          <small>{attributes.length > 0 && ` (${attributes.length})`}</small>
        </h1>
        <SavingLabelStyled />
      </Header>

      {attributes.length === 0
        ? renderNoContent()
        : renderContent()}

      {isNewAttributeModalOpened && <SettingsAttributeEditModal onHide={toggleNewAttributeModal} />}
    </>
  );

  function renderNoContent() {
    return (
      <GridContainer>
        <NoContent>
          {previews.map((preview) => (
            <PropertyPreview key={preview.color}>
              <PreviewColor color={preview.color} />
              <PreviewLine />
              <Emoji emoji={preview.emoji} />
            </PropertyPreview>
          ))}
          <PreviewTitle>You have no custom properties</PreviewTitle>
          <PreviewSubTitle>Start by adding a new</PreviewSubTitle>
          <Button onClick={onAddProperty}>Add new</Button>
        </NoContent>
      </GridContainer>
    );
  }

  function renderContent() {
    return (
      <DndContext {...dndContextProps}>
        <Content>
          <Table cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th />
                <th colSpan={2} align="left">Name</th>
                <th align="left">Type</th>
                <th align="left">Options</th>
                <th align="center">Related doc types</th>
                <th align="center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items.map((attributeId) => {
                  const lineAttribute = attributesMapping[attributeId];
                  return lineAttribute ? (
                    <SettingsAttributesLineSortable
                      key={attributeId}
                      attribute={attributesMapping[attributeId]}
                      doctypesOptions={doctypesOptions}
                      asPlaceholder={activeId === attributeId}
                      isDeleteDisabled={items.length <= 1}
                    />
                  ) : null;
                })}
              </SortableContext>
              {isAddingAttribute && (
                <SettingsAttributesLineDraft
                  onAttributeAdded={() => setIsAddingAttribute(false)}
                />
              )}
            </tbody>
            <StyledDragOverlay wrapperElement="tbody">
              {activeAttribute && (
                <SettingsAttributesLine
                  key={activeAttribute.id}
                  attribute={activeAttribute}
                  doctypesOptions={doctypesOptions}
                  isDragOverlay
                />
              )}
            </StyledDragOverlay>
          </Table>
          <AddNewLine>
            <Button
              disabled={isAddingAttribute}
              size="L"
              onClick={() => (IS_CREATION_MODAL_ENABLED ? toggleNewAttributeModal() : setIsAddingAttribute(true))}
            >
              Add new
            </Button>
          </AddNewLine>
        </Content>
      </DndContext>
    );
  }

  function onAddProperty() {
    // TODO
  }
};

export default SettingsAttributes;
