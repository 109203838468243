import { useApolloClient } from '@apollo/client';
import { BoardConfigDocument, BoardWithConfigDocument, FetchBoardRecentlyCreatedDocsDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import { useIsCurrentBoardPrivate } from 'src/hooks/boards/useIsCurrentBoardPrivate';
import { isBoardQuery } from 'src/types/graphql.types';
import { defaultPagination } from 'src/utils/pagination.util';
import { useUpdateQuery } from 'src/utils/update-cache/update-query.util';

import { useGetBoardConfigFromCache } from './cacheBoardConfig';

export const useRemoveDocFromBoardDocsList = () => {
  const { cache } = useApolloClient();
  const getBoardConfig = useGetBoardConfigFromCache();

  return useCallback((boardConfigId: string, docId: string) => {
    const boardConfig = getBoardConfig(boardConfigId);
    if (boardConfig?.docQuery?.__typename !== 'BoardQuery') return;

    cache.writeQuery({
      query: BoardConfigDocument,
      variables: {
        id: boardConfigId,
        ...defaultPagination,
      },
      data: {
        node: {
          ...boardConfig,
          docQuery: {
            ...boardConfig.docQuery,
            docGroup: {
              ...boardConfig.docQuery.docGroup,
              docs: {
                ...boardConfig.docQuery.docGroup.docs,
                edges: boardConfig.docQuery.docGroup.docs.edges.filter(
                  edge => edge.node.id !== docId,
                ),
              },
            },
          },
        },
      },
    });
  }, [cache, getBoardConfig]);
};

export const useRemoveDocsFromCache = (boardId?: string | null) => {
  const isPrivateBoard = useIsCurrentBoardPrivate();
  const product = useProductBase();

  const updateBoardDocsQuery = useUpdateQuery({
    query: BoardWithConfigDocument,
    variables: {
      id: boardId,
      ...defaultPagination,
    },
  });

  const updateHomeDocsQuery = useUpdateQuery({
    query: FetchBoardRecentlyCreatedDocsDocument,
    variables: {
      productSlug: product?.slug || '',
      ...defaultPagination,
    },
  });

  return (docIds: string[]) => {
    /* eslint-disable no-param-reassign */
    if (boardId) {
      updateBoardDocsQuery(draft => {
        if (draft?.node?.__typename !== 'Board') return;

        const draftConfig = (
          isPrivateBoard ? draft?.node.savedBoardConfig : null
        ) ?? draft?.node.publishedBoardConfig;

        if (draftConfig?.docQuery.__typename === 'BoardQueryWithGroupBy') {
          const groups = draftConfig.docQuery.docGroups.edges;
          for (const group of groups) {
            const newDocs = group.node.docs.edges.filter(doc => !docIds.includes(doc.node.id));
            group.node.docs.edges = newDocs;
          }
          return;
        }

        if (draftConfig?.docQuery.__typename === 'BoardQuery') {
          const group = draftConfig.docQuery.docGroup;
          const newDocs = group.docs.edges.filter(doc => !docIds.includes(doc.node.id));
          group.docs.edges = newDocs;
          return;
        }

        if (draftConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          const { swimlanes } = draftConfig.docQuery;
          for (const swimlane of swimlanes.edges) {
            for (const group of swimlane.node.docGroups.edges) {
              const newDocs = group.node.docs.edges.filter(doc => !docIds.includes(doc.node.id));
              group.node.docs.edges = newDocs;
            }
          }
        }
      });
    }

    updateHomeDocsQuery(draft => {
      const docQuery = draft?.product?.allDocs.savedBoardConfig?.docQuery;
      if (!isBoardQuery(docQuery)) return;
      docQuery.docGroup.docs.edges = docQuery.docGroup.docs.edges.filter(doc => !docIds.includes(doc.node.id));
    });
    /* eslint-disable no-param-reassign */
  };
};
