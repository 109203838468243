import { Emoji, SelectOption } from '@cycle-app/ui';

interface DoctypeBasicInfo {
  name: string;
  id: string;
  emoji: string;
}

interface SelectableDoctype {
  id: string;
  selected: boolean;
  value: DoctypeBasicInfo;
}

export const getOptionFromDoctype = (doctype: DoctypeBasicInfo): SelectOption => ({
  label: doctype.name,
  value: doctype.id,
  icon: (<Emoji emoji={doctype.emoji} />),
  selected: undefined,
});

export const getOptionFromSelectableDoctype = (data: SelectableDoctype): SelectOption => ({
  selected: data.selected,
  label: data.value.name,
  value: data.value.id,
  icon: (<Emoji emoji={data.value.emoji} />),
});
