import { ViewType } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';

import { Skeleton } from '../../Skeleton/Skeleton';
import { ViewCard } from '../ViewCard/ViewCard';

export const TitleContainer = styled.div`
  flex-wrap: wrap;
  gap: 6px;
`;

export const TitleLineSkeleton = styled(Skeleton)`
  height: 6px;
`;

export const Attributes = styled.div`
  display: flex;
  gap: 6px;
`;

export const AttributeSkeleton = styled(Skeleton)`
  width: 39px;
  height: 15px;
`;

export const AvatarSkeleton = styled(Skeleton)`
  border-radius: 50%;
  width: 17px;
  height: 17px;
`;

const ContainerKanbanCSS = css`
  ${TitleLineSkeleton} {
    width: 100%;
  }
`;

const ContainerListCSS = css`
  ${TitleLineSkeleton} {
    width: 50%;
  }
`;

export const StyledViewCard = styled(ViewCard)<{
  $skipAnimation: boolean;
  $fill: boolean;
}>`
  overflow: clip;

  ${p => p.viewType === ViewType.Kanban && ContainerKanbanCSS};
  ${p => p.viewType === ViewType.List && ContainerListCSS};

  ${p => p.$skipAnimation && css`
    ${Skeleton}::after {
      animation-duration: 0s;
    }
  `}

  ${p => p.$fill && css`
    width: 100%;
    height: 100%;
  `}
`;
