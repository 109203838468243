import { MateFragment } from '@cycle-app/graphql-codegen';

import { useBoard } from 'src/hooks/api/useBoard';
import { useDoc } from 'src/hooks/api/useDoc';

import { EmojiStyled } from './RealtimeUsers.styles';

export const UserLocation = ({ user }: { user: MateFragment }) => {
  const board = useBoard(user._boardId, !user._boardId);
  const { doc } = useDoc(user._docId, !user._docId);

  if (doc) return <>{doc.title}</>;

  if (board) {
    return (
      <>
        {board.emoji && <EmojiStyled emoji={board.emoji} />}
        {board.name}
      </>
    );
  }

  return <>Home</>;
};
