import { ViewType } from '@cycle-app/graphql-codegen';
import { NewDocPosition } from '@cycle-app/ui';
import { FC } from 'react';

import NewDoc, { Props as NewDocProps } from 'src/components/NewDoc/NewDoc';

interface Props extends Pick<NewDocProps, 'className' | 'possibleDoctypesId' | 'parentId'> {
  groupId: string;
  statusId?: string;
  viewType?: ViewType;
  targetPosition: NewDocPosition;
}

const BoardGroupNewDoc: FC<Props> = ({
  groupId,
  statusId,
  viewType = ViewType.Kanban,
  targetPosition,
  ...newDocProps
}) => (
  <NewDoc
    groupId={groupId}
    statusId={statusId}
    from={targetPosition}
    viewType={viewType}
    {...newDocProps}
  />
);
export default BoardGroupNewDoc;
