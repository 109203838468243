import { TextButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled(TextButton)`
  background-color: ${p => p.theme.colors.background.disabled};
  border-radius: 50%;
  position: fixed;
  top: 18px;
  left: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  svg {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
  }
`;
