import { make } from 'src/utils/reactives.util';

const defaultState = {
  customerId: '',
  isOpen: false,
};

type CustomerProfileModalResult = typeof defaultState;

export const {
  getValue: getCustomerProfileModal,
  hookState: useCustomerProfileModal,
  resetValue: resetCustomerProfileModal,
  setValue: setCustomerProfileModal,
} = make<CustomerProfileModalResult>({
  defaultState,
});
