import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import * as typo from '../../../theme/typo';

export const PlanName = styled.h2`
  ${typo.headerLarge}
  margin-top: 8px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${p => p.theme.colors.billing.section.separator};
  color: ${p => p.theme.colors.text.primary};
  display: inline-flex;
  align-items: center;
  gap: 8px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: 8px;

  ${mobile} {
    flex-direction: column;
  }
`;

export const Body = styled.div`
  ${typo.body400}
`;
