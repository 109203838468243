import { BoardWithDraftConfigFragment } from '@cycle-app/graphql-codegen';
import { AddIcon } from '@cycle-app/ui/icons';
import { useReducer, useState } from 'react';

import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import { useInboxSection } from 'src/hooks/inbox';

import { AddButton } from './InboxCreateView.styles';
import { InboxCreateViewModal } from './InboxCreateViewModal';

export const InboxCreateView = () => {
  const { section } = useInboxSection();
  const [isModalOpen, toggleModal] = useReducer(b => !b, false);
  const [draftBoard, setDraftBoard] = useState<BoardWithDraftConfigFragment | null>(null);

  const {
    addSectionDraftBoard, loading,
  } = useProductMutations();

  return (
    <>
      <AddButton
        tooltip="Add view"
        tooltipPlacement="top"
        disabled={!section}
        onClick={async () => {
          if (!section) return;
          toggleModal();
          const { data } = await addSectionDraftBoard({ sectionId: section.id });
          setDraftBoard(data?.createDraftBoard ?? null);
        }}
      >
        <AddIcon size={10} />
      </AddButton>

      {isModalOpen && (
        <InboxCreateViewModal
          hide={() => {
            if (loading) return;
            toggleModal();
            setDraftBoard(null);
          }}
          draftBoard={draftBoard}
        />
      )}
    </>
  );
};
