import { WorkspaceIcon, DocumentIcon } from '@cycle-app/ui/icons';

import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { setTemplate } from 'src/reactives/template.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';

export const useAdminActions = (): CommandActionCategory => ({
  id: 'admin-actions',
  label: 'Admin',
  actions: [
    {
      id: 'create-workspace',
      label: 'Create workspace',
      icon: <WorkspaceIcon />,
      onSelect: () => setCommandbar({
        visible: true,
        section: 'create-workspace',
      }),
    },
    {
      id: 'edit-templates',
      label: 'Update templates',
      icon: <DocumentIcon />,
      onSelect: () => {
        setTemplate({
          modalVisible: true,
          admin: true,
          selectedTemplateId: null,
          mode: 'list',
        });
        setCommandbar({ visible: false });
      },
    },
  ],
});

export const useCreateWorkspaceResult = (): Array<CommandActionCategory> => [{
  id: 'create-workspace',
  actions: [],
}];
