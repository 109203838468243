import { DoctypeType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useProductStatuses } from 'src/hooks';

export const useDefaultNotStartedStatus = () => {
  const statuses = useProductStatuses();
  return useMemo(() => {
    return statuses.notStarted.find(s => s.doctypes.edges.some(e => e.node.type === DoctypeType.Feedback));
  }, [statuses.notStarted]);
};
