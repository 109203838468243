import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const JobsInput = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const Label = styled.div`
  ${typo.body500};
  margin-bottom: 4px;
`;

export const RadioContainer = styled.div`
  width: 100%;
`;
