import 'emoji-mart/css/emoji-mart.css';
import * as EmojiMart from 'emoji-mart';
import { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import { Dropdown, DropdownProps } from '../Dropdown/Dropdown';
import { Button } from './EmojiPicker.styles';

export type EmojiData = EmojiMart.EmojiData;

interface Props {
  className?: string;
  onSelect?(emoji: EmojiData): void;
  dropdownProps?: Partial<DropdownProps>;
}
export const EmojiPicker: FC<Props> = ({
  className,
  children,
  onSelect,
  dropdownProps,
}) => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown
      visible={visible}
      hide={() => setVisible(false)}
      content={(
        <EmojiMart.Picker
          autoFocus
          native
          emojiSize={20}
          showPreview={false}
          showSkinTones={false}
          color={theme.userColors.main}
          theme={getEmojiPickerTheme()}
          onSelect={onEmojiSelect}
          i18n={{
            categories: {
              recent: 'Recent',
            },
          }}
        />
      )}
      {...dropdownProps}
    >
      <Button
        type="button"
        className={className}
        onClick={() => setVisible(!visible)}
      >
        {children}
      </Button>
    </Dropdown>
  );

  function onEmojiSelect(emoji: EmojiData) {
    onSelect?.(emoji);
    setVisible(false);
  }

  function getEmojiPickerTheme() {
    const themeToApply = theme.colors.emojiPicker.theme;
    if (themeToApply === 'light' || themeToApply === 'dark') {
      return themeToApply;
    }
    return 'auto';
  }
};
