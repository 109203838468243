import { CycleLogo } from '@cycle-app/ui';
import { FC, ReactNode } from 'react';

import { ErrorToasters } from 'src/app/ErrorToasters/ErrorToasters';
import { AppToasters } from 'src/components/AppToasters';
import { LightOnboardingSteps } from 'src/constants/onboarding.constants';
import { useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import {
  Aside,
  Body,
  ContentContainer,
  Content,
  Description,
  Header,
  Main,
  ProgressContainer,
  Root,
  SubTitle,
  StyledProgress,
  StyledToastersContainer,
  Title,
  Wrapper,
  RootAnimated,
  StyledErrorToastersContainer,
  Headline,
} from './OnboardingLayout.styles';

interface Props {
  aside?: ReactNode;
  cover?: ReactNode;
  description?: ReactNode;
  main?: ReactNode;
  subtitle?: ReactNode;
  title?: ReactNode;
  headline?: ReactNode;
  hideLogo?: boolean;
  isAnimated?: boolean;
  isVerticalCentered?: boolean;
}

export const OnboardingLayout: FC<Props> = ({
  aside, description, main, subtitle, title, hideLogo, isAnimated, headline, cover, isVerticalCentered = true,
}) => {
  const { screen } = useGetOnboarding();

  function getProgress() {
    return ((LightOnboardingSteps.indexOf(screen as LightOnboardingScreen) + 1) / LightOnboardingSteps.length) * 100;
  }

  return (
    <>
      <Wrapper>
        <Root $isAnimated={isAnimated}>
          <Main $hasHeader={!hideLogo}>
            {!hideLogo && (
              <Header $isCentered={!aside}>
                <CycleLogo animation="hover" full size={32} />
              </Header>
            )}
            <ContentContainer $isVerticalCentered={isVerticalCentered}>
              <Content $isVerticalCentered={isVerticalCentered}>
                <Body>
                  {cover}
                  {headline && <Headline>{headline}</Headline>}
                  {title && <Title>{title}</Title>}
                  {subtitle && <SubTitle>{subtitle}</SubTitle>}
                  {description && <Description>{description}</Description>}
                  {main}
                </Body>
              </Content>
            </ContentContainer>
          </Main>
          {aside && (
            <Aside>
              <ProgressContainer>
                <StyledProgress isNeutral percentage={getProgress()} type="ring" useUserColor={false} />
              </ProgressContainer>
              {aside}
            </Aside>
          )}
          {isAnimated && (
            <RootAnimated
              initial={{
                opacity: 0,
                translateY: 40,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
              }}
            />
          )}
        </Root>
      </Wrapper>
      <StyledToastersContainer>
        <AppToasters />
      </StyledToastersContainer>
      <StyledErrorToastersContainer
        placeLeft={false}
        $sidebarWidth={0}
      >
        <ErrorToasters />
      </StyledErrorToastersContainer>
    </>
  );
};
