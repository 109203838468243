import { DoctypeType } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useGetGroup } from '../api/cache/cacheGroupHooks';
import { useDoctypesFromBoardConfig } from '../api/useDoctypesFromBoardConfig';
import { useProductDoctypes } from '../api/useProductDoctypes';

interface Props {
  possibleDoctypesId?: string[];
  groupId: string;
}

export const usePossibleDoctypes = () => {
  const getGroup = useGetGroup();
  const doctypesFromProduct = useProductDoctypes();
  const doctypesFromBoardConfig = useDoctypesFromBoardConfig();

  const getPossibleDoctypes = useCallback(({
    groupId, possibleDoctypesId,
  }: Props) => {
    // Don't do any filtering if possibleDoctypesId are manyally passed, they are assumed to be dood.
    // eg. in swimlanes.
    if (possibleDoctypesId && possibleDoctypesId.length) {
      return doctypesFromProduct.filter(doctype => possibleDoctypesId.includes(doctype.id)) ?? [];
    }

    const group = getGroup(groupId);

    if (group?.node.propertyValue?.__typename === 'Doctype') {
      const groupDoctype = doctypesFromProduct.find(doctype => doctype.type !== DoctypeType.Insight && doctype.id === group?.node.propertyValue?.id);
      if (groupDoctype) {
        return [groupDoctype];
      }
    }
    return [
      ...doctypesFromBoardConfig.length ? doctypesFromBoardConfig : doctypesFromProduct,
    ].filter(doctype => doctype.type !== DoctypeType.Insight);
  }, [doctypesFromBoardConfig, doctypesFromProduct, getGroup]);

  return { getPossibleDoctypes };
};
