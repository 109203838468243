import { ALL_DOCS_BOARD_SLUG, PageId } from 'src/constants/routing.constant';

import { usePageId } from './usePageId';
import { usePathParams } from './usePathParams';

export const useIsAllDocsPage = () => {
  const pageId = usePageId();
  const { boardSlug } = usePathParams();

  return (
    boardSlug === ALL_DOCS_BOARD_SLUG ||
    [
      PageId.Main,
      PageId.HomeDoc,
      PageId.SettingsCustomer,
      PageId.SettingsCustomers,
      PageId.SettingsCompanies,
      PageId.SettingsCompany,
    ].includes(pageId));
};
