import { AddIcon } from '@cycle-app/ui/icons';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import { FC } from 'react';

import emptyAvatarsUrl from 'src/assets/empty-avatars.svg';
import { useProductPresence } from 'src/hooks';
import { useUsers } from 'src/hooks/api/useUsers';
import { setAddMember } from 'src/reactives/addMember.reactive';

import {
  Header,
  Title,
  MemberCardSkeleton,
  MemberAvatarSkeleton,
  MemberNameSkeleton,
  MemberStatusSkeleton,
  InviteMemberCard,
  Circle,
  SubTitle,
  Text,
  TextInvite,
  MemberEmptyState,
  Image,
  MembersContainerWrapper,
} from './HomeMembersSection.styles';
import { HomeMembersWithScroll } from './HomeMembersWithScroll';
import { Member } from './Member';

export const HomeMembersSection: FC = () => {
  return (
    <section>
      <Header>
        <Title>
          Your team
        </Title>
      </Header>
      <MembersContainerWrapper>
        <Members />
      </MembersContainerWrapper>
    </section>
  );
};

const Members: FC = () => {
  const productPresence = useProductPresence();
  const membersViewing = productPresence.map(user => user.id);

  const {
    users,
    loading: isUsersLoading,
  } = useUsers();

  if (isUsersLoading) return <LoadingState />;

  if (!users.length) return <EmptyState />;

  const usersWithFlags = users
    .filter(user => user.__typename !== 'Me')
    .map(user => ({
      ...user,
      isPending: !user.jobTitle,
      isOnline: membersViewing.includes(user.id),
    }));

  const sortedUsers = orderBy(usersWithFlags,
    ['isPending', 'isOnline'],
    ['asc', 'desc']);

  return (
    <HomeMembersWithScroll>
      <InviteMemberCard key="invite-card" onClick={() => setAddMember({ visible: true })}>
        <Circle><AddIcon /></Circle>
        <TextInvite>Invite new member</TextInvite>
      </InviteMemberCard>
      {sortedUsers.map(user => <Member key={user.id} user={user} />)}
    </HomeMembersWithScroll>
  );
};

const LoadingState = () => (
  <HomeMembersWithScroll>
    {range(3).map(i => (
      <MemberCardSkeleton key={i}>
        <MemberAvatarSkeleton />
        <MemberNameSkeleton />
        <MemberStatusSkeleton />
      </MemberCardSkeleton>
    ))}
  </HomeMembersWithScroll>
);

const EmptyState = () => (
  <MemberEmptyState onClick={() => setAddMember({ visible: true })}>
    <Image src={emptyAvatarsUrl} alt="Empty avatars" />
    <SubTitle>Cycle is more fun with teammates</SubTitle>
    <Text>Click here to invite someone</Text>
  </MemberEmptyState>
);
