import { opacify, rgba } from 'polished';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { Color as ColorApi } from '../../../../graphql-codegen/generated';
import { caption400 } from '../../theme/typo';

interface TagProps {
  $color?: ColorApi | 'grey';
  $onDarkBg?: boolean;
  $hasSideButton?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  href?: string;
}

export const Container = styled.div<TagProps>`
  --bg: ${p => (!p.$color || p.$color === 'grey' ? p.theme.colors.tag.bg : p.theme.nuances[p.$color].bg)};
  --bgHover: ${p => (!p.$color || p.$color === 'grey' ? p.theme.colors.tag.bgHover : opacify(0.1, p.theme.nuances[p.$color].bg))};
  --bgActive: ${p => (!p.$color || p.$color === 'grey' ? p.theme.colors.tag.bgActive : opacify(0.2, p.theme.nuances[p.$color].bg))};
  border-radius: 4px;
  border: ${p => (p.$color ? 'none' : `1px solid ${p.theme.colors.tag.border}`)};
  position: relative;
  display: flex;
  height: 20px;
  overflow: hidden;
  ${caption400};
  max-width: 140px;
  flex: none;

  color: ${p => p.theme.colors.text.primary};
  ${p => p.$onDarkBg && css`
    color: ${p.theme.colors.text.opposite};
  `};

  > *:first-child {
    flex-shrink: 1;
    overflow: hidden;
  }
`;

const TagCss = css<TagProps>`
  padding: ${p => (p.$hasSideButton ? '0 2px 0 4px' : '0 4px')};

  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;

  height: 100%;

  ${p => p.$color && css`
    background-color: var(--bg);
    &:hover {
      background-color: var(--bgHover);
    }
    &:active {
      background-color: var(--bgActive);
    }
  `}

  ${p => (p.onClick || p.href) && p.$color && css`
    &:hover {
      background-color: var(--bgHover);
    }
    &:active {
      background-color: var(--bgActive);
    }
  `};

  ${p => (p.onClick || p.href) && p.$onDarkBg && css`
    &:hover {
      background-color: ${rgba(255, 255, 255, 0.05)};
    }
    &:active {
      background-color: ${rgba(255, 255, 255, 0.15)};
    }
  `};
`;

export const Content = styled.div<Omit<TagProps, 'href'>>`
  ${TagCss}
  ${p => css`
    cursor: ${(p.onClick || p.onClickCapture) ? 'pointer' : 'default'};
  `};

  button {
    background-color: ${p => p.theme.colors.background.primary};
  }
`;

export const Link = styled.a<TagProps>`
  ${TagCss}
  color: ${p => p.theme.colors.text.secondary};
  padding: 0 4px 0 2px;
  > svg {
    width: 10px;
    height: 10px;
  }
`;

interface NameProps {
  $limitSize?: boolean;
}

export const Name = styled.div<NameProps>`
  ${p => p.$limitSize && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const TagButton = styled.button<TagProps>`
  flex: none;
  ${TagCss}
  padding: 0 4px 0 2px;
  cursor: pointer;
  color: ${p => p.theme.colors.text.secondary};
  > svg {
    width: 9px;
    height: 9px;
  }
`;
