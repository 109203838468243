import { FC } from 'react';

import avatar1 from './avatar-1.png';
import avatar2 from './avatar-2.png';
import {
  Container, Title, Skeletons, Skeleton, Avatar1, Avatar2,
} from './Step4Img.styles';

export const Step4Img: FC = () => {
  return (
    <Container>
      <Avatar1><img src={avatar1} alt="" /></Avatar1>
      <Avatar2><img src={avatar2} alt="" /></Avatar2>
      <Title>Release notes</Title>
      <Skeletons>
        <Skeleton
          initial={{
            opacity: 0,
            width: 0,
          }}
          animate={{
            opacity: 1,
            width: '60%',
            transition: {
              duration: 1,
            },
          }}
        />
        <Skeleton
          initial={{
            opacity: 0,
            width: 0,
          }}
          animate={{
            opacity: 1,
            width: '60%',
            transition: {
              duration: 1,
              delay: 0.2,
            },
          }}
        />
        <Skeleton
          initial={{
            opacity: 0,
            width: 0,
          }}
          animate={{
            opacity: 1,
            width: '45%',
            transition: {
              duration: 1,
              delay: 0.4,
            },
          }}
        />
        <Skeleton
          initial={{
            opacity: 0,
            width: 0,
          }}
          animate={{
            opacity: 1,
            width: '50%',
            transition: {
              duration: 1,
              delay: 0.6,
            },
          }}
        />
      </Skeletons>
    </Container>
  );
};
