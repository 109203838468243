import { FC } from 'react';

import BoardRealtime from 'src/components/BoardRealtime/BoardRealtime';
import { InboxCreateView } from 'src/components/InboxCreateView';
import { InboxSources } from 'src/components/InboxSources';
import { InboxTabs } from 'src/components/InboxTabs';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { InboxContainer, ToggleSidebar } from './Inbox.styles';
import { TitleTooltip } from './InboxTooltip';

export const Inbox: FC = ({ children }) => {
  const { canCreateBoard } = useGetPermission();
  return (
    <InboxContainer>
      <header>
        <div>
          <ToggleSidebar />
          <TitleTooltip>
            <h1>Inbox</h1>
          </TitleTooltip>
        </div>

        <div>
          <BoardRealtime />
          <InboxSources />
        </div>
      </header>

      <BoardConfigContextProvider>
        <InboxTabs
          lastTabNode={canCreateBoard ? <InboxCreateView /> : null}
        />
        {children}
      </BoardConfigContextProvider>
    </InboxContainer>
  );
};
