import { typo, Dropdown, Button } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-top: 16px;
  flex-wrap: wrap;

  &:empty {
    margin-top: 0;
  }

  ${mobile} {
    margin-top: 34px;
    flex-direction: column;
  }
`;

export const Action = styled(Button).attrs({ variant: 'secondary' })`
  ${typo.body400}
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  border: 0;
  user-select: none;

  --color: ${p => p.theme.colors.button.secondaryV2.color};
  
  :hover, :focus {
    --color: ${p => p.theme.colors.button.secondaryV2.colorHover};
    --bg: ${p => p.theme.colors.button.secondaryV2.bgHover};
  }

  :active {
    --color: ${p => p.theme.colors.button.secondaryV2.colorHover};
    --bg: ${p => p.theme.colors.button.secondaryV2.bgActive};
  }

  ${p => p.active && css`
    --color: ${p.theme.colors.button.secondaryV2.colorHover};
    --bg: ${p.theme.colors.button.secondaryV2.bgHover};
  `}

  > svg {
    height: 14px;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  width: 400px;
  padding: 16px;
`;
