import { UpdateDocsStatusDocument } from '@cycle-app/graphql-codegen';

import { useProductStatuses } from 'src/hooks/product/useProductStatuses';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setBulkLoading } from 'src/reactives/selection.reactive';

const onDone = () => setBulkLoading({ isLoading: false });

export const useUpdateDocsStatusMutation = () => {
  const statuses = useProductStatuses();
  const [mutate, { loading }] = useSafeMutation(UpdateDocsStatusDocument, {
    onCompleted: onDone,
    onError: onDone,
  });

  const updateDocsStatus = (docIds: string[], statusId: string) => {
    const productStatus = Object.values(statuses).flat().find(status => status?.id === statusId);
    const category = productStatus?.category;
    if (!category) return null;
    return mutate({
      variables: {
        docIds,
        statusId,
      },
      // optimisticResponse: {
      //   updateDocsStatus: docIds.map(id => ({
      //     id,
      //     status: {
      //       id: statusId,
      //       category,
      //     },
      //   })),
      // },
    });
  };

  return {
    loading,
    updateDocsStatus,
  };
};
