import { ViewType } from '@cycle-app/graphql-codegen/generated';
import { Emoji } from '@cycle-app/ui';
import { CommentIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import {
  AvatarSkeleton,
  AttributeSkeleton,
  ActionsContainerSkeleton,
  DescriptionLineSkeleton,
  Doctype,
  Dockey,
  DockeySkeleton,
  StyledViewCard,
  TitleLineSkeleton,
} from './PreviewCard.style';

interface Props {
  emoji: string;
  viewType: ViewType;
}

export const PreviewCard: FC<Props> = ({
  emoji, viewType,
}) => (
  <StyledViewCard
    disableHover
    preToolbar={(
      <>
        <DescriptionLineSkeleton />
        <AttributeSkeleton>
          <Doctype>
            <Emoji size={12} emoji={emoji} />
          </Doctype>
          <Dockey>
            <DockeySkeleton />
          </Dockey>
        </AttributeSkeleton>
      </>
    )}
    sectionStart={(
      <ActionsContainerSkeleton>
        <CommentIcon />
      </ActionsContainerSkeleton>
    )}
    sectionEnd={<AvatarSkeleton />}
    title={<TitleLineSkeleton />}
    viewType={viewType}
  />
);
