import {
  ChangeBoardConfigGroupbyPropertyDocument,
  ChangeBoardConfigGroupbyPropertyMutation,
  HideAllGroupsDocument,
  HideBoardConfigGroupValueDocument,
  PropertyFragment,
  RemoveBoardConfigGroupbyPropertyDocument,
  ShowBoardConfigGroupValueDocument,
  ShowAllGroupsDocument,
  BoardWithConfigDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events, Methods, Objects } from 'src/constants/analytics.constants';
import { useGetBoardWithConfigFromCache, useGetDraftBoardConfigFromCache } from 'src/hooks/api/cache/cacheBoardConfig';
import { useBoard } from 'src/hooks/api/useBoard';
import { useIsCurrentBoardPrivate } from 'src/hooks/boards/useIsCurrentBoardPrivate';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { defaultPagination } from 'src/utils/pagination.util';

const trackGroupByUpdated = () => trackAnalytics(Events.BoardUpdated, {
  method: Methods.UI,
  object: Objects.ToolbarGroupby,
});
const trackHideGroupUpdated = () => trackAnalytics(Events.BoardUpdated, {
  method: Methods.UI,
  object: Objects.ToolbarHideGroup,
});

export default function useManageGroupby(boardConfigId: string, boardID?: string) {
  const board = useBoard(boardID);
  const isPrivateBoard = useIsCurrentBoardPrivate();
  const boardId = board?.id ?? '';

  const [changeGroupByPropertyMutation] = useSafeMutation(ChangeBoardConfigGroupbyPropertyDocument, { onCompleted: trackGroupByUpdated });
  const [removeGroupByPropertyMutation] = useSafeMutation(RemoveBoardConfigGroupbyPropertyDocument, { onCompleted: trackGroupByUpdated });
  const [hideBoardConfigGroupValueMutation] = useSafeMutation(HideBoardConfigGroupValueDocument, { onCompleted: trackHideGroupUpdated });
  const [showBoardConfigGroupValueMutation] = useSafeMutation(ShowBoardConfigGroupValueDocument, { onCompleted: trackHideGroupUpdated });
  const [hideAllGroupsMutation, { loading: loadingHideAllGroups }] = useSafeMutation(HideAllGroupsDocument, {
    onCompleted: trackHideGroupUpdated,
  });
  const [showAllGroupsMutation, { loading: loadingShowAllGroups }] = useSafeMutation(ShowAllGroupsDocument, {
    onCompleted: trackHideGroupUpdated,
  });
  const getDraftBoardConfigFromCache = useGetDraftBoardConfigFromCache(boardId);
  const getBoardWithConfigFromCache = useGetBoardWithConfigFromCache(boardId);

  const changeGroupByProperty = useCallback(async (property: PropertyFragment) => {
    const draftBoardConfig = getDraftBoardConfigFromCache();

    const optimisticResponse: ChangeBoardConfigGroupbyPropertyMutation | undefined =
      draftBoardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
      draftBoardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
        ? {
          changeBoardConfigGroupbyProperty: {
            ...draftBoardConfig,
            docQuery: {
              ...draftBoardConfig.docQuery,
              groupbyConfig: {
                ...draftBoardConfig.docQuery.groupbyConfig,
                property,
              },
            },
          },
        } : undefined;

    return changeGroupByPropertyMutation({
      variables: {
        boardConfigId,
        propertyId: property.id,
        ...defaultPagination,
      },
      optimisticResponse,
    });
  }, [boardConfigId, changeGroupByPropertyMutation, getDraftBoardConfigFromCache]);

  const removeGroupByProperty = useCallback(() => removeGroupByPropertyMutation({
    variables: {
      boardConfigId,
      ...defaultPagination,
    },
  }), [removeGroupByPropertyMutation, boardConfigId]);

  const hideBoardConfigGroupValue = useCallback(
    (docGroupId: string, options: { updateDocGroups?: boolean } = {}) => hideBoardConfigGroupValueMutation({
      variables: {
        docGroupId,
      },
      optimisticResponse: {
        hideDocGroupWithPropertyValue: {
          __typename: 'DocGroupWithPropertyValue',
          id: docGroupId,
          hidden: true,
        },
      },
      ...options.updateDocGroups && {
        update: (cache) => {
          if (!boardId) return;

          const boardWithPublishedConfig = getBoardWithConfigFromCache();

          const boardConfig = (
            isPrivateBoard
              ? boardWithPublishedConfig?.savedBoardConfig
              : null
          ) ?? boardWithPublishedConfig?.publishedBoardConfig;

          if (boardWithPublishedConfig && boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy') {
            cache.writeQuery({
              query: BoardWithConfigDocument,
              variables: {
                id: boardId,
                ...defaultPagination,
              },
              data: {
                node: {
                  ...boardWithPublishedConfig,
                  ...isPrivateBoard && boardWithPublishedConfig.savedBoardConfig
                    ? ({
                      savedBoardConfig: {
                        ...boardConfig,
                        docQuery: {
                          ...boardConfig.docQuery,
                          docGroups: {
                            ...boardConfig.docQuery.docGroups,
                            edges: boardConfig.docQuery.docGroups.edges
                              .filter(e => e.node.baseId !== docGroupId),
                          },
                        },
                      },
                    }) : ({
                      publishedBoardConfig: {
                        ...boardConfig,
                        docQuery: {
                          ...boardConfig.docQuery,
                          docGroups: {
                            ...boardConfig.docQuery.docGroups,
                            edges: boardConfig.docQuery.docGroups.edges
                              .filter(e => e.node.baseId !== docGroupId),
                          },
                        },
                      },
                    }),
                },
              },
            });
          }
        },
      },
    }),
    [hideBoardConfigGroupValueMutation, boardId, getBoardWithConfigFromCache, isPrivateBoard],
  );

  const showBoardConfigGroupValue = useCallback((docGroupId: string) => showBoardConfigGroupValueMutation({
    variables: {
      docGroupId,
      ...defaultPagination,
    },
    optimisticResponse: {
      showDocGroupWithPropertyValue: {
        __typename: 'DocGroupWithPropertyValue',
        id: docGroupId,
        hidden: false,
      },
    },
  }), [showBoardConfigGroupValueMutation]);

  const hideAllGroups = useCallback(() => hideAllGroupsMutation({
    variables: {
      boardConfigId,
    },
  }), [boardConfigId, hideAllGroupsMutation]);

  const showAllGroups = useCallback(() => showAllGroupsMutation({
    variables: {
      boardConfigId,
    },
  }), [boardConfigId, showAllGroupsMutation]);

  return {
    changeGroupByProperty,
    removeGroupByProperty,
    hideBoardConfigGroupValue,
    showBoardConfigGroupValue,
    hideAllGroups,
    showAllGroups,
    loadingHideAllGroups,
    loadingShowAllGroups,
  };
}
