import { boxShadowZ1, typo, ActionButton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { EditParentBtn } from 'src/components/DocParentDropdown/DocPanelParent.styles';
import DocParentDropdown from 'src/components/DocParentDropdown/DocParentDropdown';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  gap: 8px;
  padding: 12px;
  ${boxShadowZ1};
`;

export const RightSide = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  margin-left: 8px;
  gap: 16px;
`;

export const LeftActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledActionButton = styled(ActionButton)`
  padding: 5px;
  ${p => p.disabled && css`
    color: ${p.theme.colors.actionButton.colorDisabled};
  `}
`;

export const Separator = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  height: 16px;
  width: 1px;
  background-color: ${p => p.theme.colors.docPanel.header.breadCrumbSeparatorColor};
`;

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  color: ${p => p.theme.colors.docPanel.header.breadCrumbSeparatorColor};
  overflow: visible;

  ${mobile} {
    display: none;
  }
`;

export const StyledDocParentDropdown = styled(DocParentDropdown)`
  margin-right: 2px;
  ${typo.body400}

  &:hover {
    background-color: ${p => p.theme.colors.background.hover};

    ${EditParentBtn} {
      position: absolute;
      top: -4px;
      right: -4px;
      padding: 2px;
      ${boxShadowZ1}

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const DocTitle = styled.div`
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
  
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
`;

export const RightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    padding: 5px;
  }
`;
