import * as Sentry from '@sentry/react';
import { EditorView } from 'prosemirror-view';

// https://github.com/cycle-app/cycle-tesla/issues/3647
EditorView.prototype.updateState = function updateState(state) {
  if (!(this as any).docView) return;
  try {
    (this as any).updateStateInner(state, this.state.plugins !== state.plugins);
  } catch (error) {
    Sentry.captureException(error);
  }
};
