import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: ${p => p.theme.colors.components.HelpMeWriting.bg};
  border-radius: 12px;
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  max-width: 807px;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
  margin: 12px auto;
  gap: 16px;
  border: 1px solid ${p => p.theme.colors.border.primary};
`;

export const IllustrationContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 50px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Text = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
`;

export const Img = styled.img`
  width: 70px;
  object-fit: contain;
`;
