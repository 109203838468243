import { NavigationItem, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const NewSectionItem = styled(NavigationItem)`
  color: ${p => p.theme.colors.sidebar.newSection.color};
  ${typo.caption400}
  
  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-color: transparent;
  }
`;
