import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { Tooltip, TooltipProps } from '@cycle-app/ui';
import { ReactNode, FC } from 'react';

import { useGetPermission } from 'src/reactives/permission.reactive';

import { TooltipContent } from './InboxTabs.styles';

export type TabTooltipProps = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  description?: ReactNode;
  tooltip?: Omit<TooltipProps, 'content' | 'title'>;
  isActive: boolean;
  withBoardName?: boolean;
};

export const TabTooltip: FC<TabTooltipProps> = ({
  board, description = board.description, tooltip, children, isActive, withBoardName = true,
}) => {
  const { canUpdateBoard } = useGetPermission();
  return (
    <Tooltip
      title={(withBoardName || description) && (
        <div>
          {withBoardName && <div>{board.name}</div>}
          {description && <div>{description}</div>}
        </div>
      )}
      content={isActive && canUpdateBoard && <TooltipContent>Open settings</TooltipContent>}
      placement="top"
      withPortal
      {...tooltip}
      disabled={!isActive && !description && !withBoardName}
    >
      {children}
    </Tooltip>
  );
};
