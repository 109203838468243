import { FC } from 'react';

import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { setCompanyProfileModal, getCustomerProfileModal, resetCustomerProfileModal } from 'src/reactives';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { CustomerChildrenTabs } from '../CustomerChildrenTabs/CustomerChildrenTabs';
import { CustomerAvatarEditable } from './CustomerAvatarEditable';
import { CustomerDetails } from './CustomerDetails';
import { HeaderContainer, Container } from './CustomerProfile.styles';
import { CustomerProfileHeader } from './CustomerProfileHeader';

interface Props {
  customerId: string;
  className?: string;
  hideShareLink?: boolean;
  hideSeparator?: boolean;
  isHeaderCentered?: boolean;
  docsSkeleton?: JSX.Element;
}

export const CustomerProfile: FC<Props> = ({
  className, isHeaderCentered, hideShareLink, customerId,
}) => {
  const { customer } = useCustomer(customerId);

  if (!customer) return null;

  const onCompanyClick = (companyId: string) => {
    if (getCustomerProfileModal().isOpen) {
      resetCustomerProfileModal();
    }
    setCompanyProfileModal({
      companyId,
      isOpen: true,
    });
  };

  return (
    <Container className={className}>
      <HeaderContainer $isCentered={isHeaderCentered}>
        <CustomerAvatarEditable
          customer={customer}
          isReadOnly={isDefaultCustomer(customer)}
          onCompanyClick={onCompanyClick}
        />
        <CustomerProfileHeader customer={customer} />
        <CustomerDetails
          customer={customer}
          hideShareLink={hideShareLink}
          isReadOnly={isDefaultCustomer(customer)}
          onCompanyClick={onCompanyClick}
        />
      </HeaderContainer>
      <CustomerChildrenTabs customerId={customerId} />
    </Container>
  );
};
