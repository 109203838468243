import { ThemeType, DoctypeType } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import {
  ActivityIcon,
  BulbIcon,
  CalendarIcon,
  CaretIcon,
  NoCommentIcon,
  UserIcon,
} from '@cycle-app/ui/icons';
import { format, parseISO } from 'date-fns';
import memoize from 'fast-memoize';
import { useMemo, useCallback, FC, useEffect } from 'react';

import DocChat from 'src/components/DocComments/DocChat';
import { FeedbackInsightList, InsightChildrenList } from 'src/components/InsightsList';
import { useDocChildrens, useDocInsightsCount } from 'src/hooks';
import useDocComments from 'src/hooks/api/queries/useDocComments';
import { useDocPanel, Content } from 'src/reactives/docRightPanel.reactive';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

import {
  NoComment,
  Container,
  Actions,
  DisplayedContent,
  Hint,
  ActivityItem,
  ActivityContent,
  ActivityAvatar,
  FakeActivities,
  Blur,
  ComingSoon,
  StyledActionButton,
  Toggle,
  ToggleBar,
  ToggleButton,
  CommentsLoaderContainer,
} from './DocPanelRightPanel.styles';

interface Props {
  doc: FullDocWithPublicId;
}
const DocPanelRightPanel: FC<Props> = ({ doc }) => {
  const [{
    content: rightPanelContent, isExpanded: rightPanelExpanded,
  }, setDocPanel] = useDocPanel();
  const { colorTheme } = useGetThemeConfig();
  const insightChildrenDocType = useMemo(() => doc.doctype.children?.edges.find(edge => edge.node.type === DoctypeType.Insight)?.node, [doc]);
  const {
    count: docInsightsCount, isLoading: isInsightsCountLoading,
  } = useDocInsightsCount({
    docId: doc.id,
    skip: doc.doctype.type !== DoctypeType.Feedback,
  });
  const {
    count: docInsightChildrenCount, loading: isInsightChildrenCountLoading,
  } = useDocChildrens({
    docId: doc.id,
    doctypeId: insightChildrenDocType?.id,
  });
  const {
    data, loading: isCommentsLoading,
  } = useDocComments({
    docId: doc.id,
    skip: !rightPanelExpanded || rightPanelContent !== Content.Comments,
  });
  const insightCount = insightChildrenDocType ? docInsightChildrenCount : docInsightsCount;
  const insightCountLoading = isInsightsCountLoading || isInsightChildrenCountLoading;

  const onButtonClicked = memoize((content: Content) => () => {
    setDocPanel({
      content,
      isExpanded: !rightPanelExpanded || rightPanelContent !== content,
    });
  });

  useEffect(() => () => setDocPanel({
    isExpanded: false,
    content: undefined,
  }), []);

  useEffect(() => {
    if (doc.doctype.type === DoctypeType.Feedback || insightChildrenDocType) {
      setDocPanel({
        isExpanded: true,
        content: Content.Insights,
      });
    } else {
      setDocPanel({
        content: undefined,
        isExpanded: false,
      });
    }
  }, [doc.id, insightChildrenDocType]);

  const togglePanel = useCallback(() => {
    setDocPanel({ isExpanded: !rightPanelExpanded });
  }, [rightPanelExpanded, setDocPanel]);

  const isFeedbackDocType = doc.doctype.type === DoctypeType.Feedback;
  const noComments = !data?.node.comments.edges.length;

  return (
    <Container $collapsed={!rightPanelExpanded}>
      <Actions $expanded={rightPanelExpanded}>
        {[
          {
            content: Content.Comments,
            icon: <NoCommentIcon />,
          },
          ...(isFeedbackDocType || insightChildrenDocType) ? [{
            content: Content.Insights,
            icon: <BulbIcon />,
            ...insightCountLoading ? {} : { number: insightCount },
          }] : [],
          {
            content: Content.Activity,
            icon: <ActivityIcon />,
          },
        ].map((tab) => (
          <StyledActionButton
            key={tab.content}
            tooltip={rightPanelExpanded ? undefined : tab.content}
            forceFocus={rightPanelExpanded && rightPanelContent === tab.content}
            size="L"
            onClick={onButtonClicked(tab.content)}
          >
            {tab.icon}
            {rightPanelExpanded && tab.content}
            {'number' in tab && rightPanelExpanded && !!tab.number && <span>{tab.number}</span>}
          </StyledActionButton>
        ))}
      </Actions>

      {rightPanelExpanded && (
        <DisplayedContent
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {rightPanelContent === Content.Comments && (
            <>
              {isCommentsLoading
                ? (
                  <CommentsLoaderContainer>
                    <Spinner />
                  </CommentsLoaderContainer>
                ) : (
                  <>
                    {noComments && (
                      <NoComment>
                        <img
                          src={`/images/empty-comments-${convertLegacyLocalStorageValue(colorTheme) === ThemeType.Nightfall ? 'dark' : 'light'}.png`}
                          alt="empty-comments"
                        />
                        <p>No comment yet</p>
                      </NoComment>
                    )}
                    <DocChat
                      docId={doc.id}
                      comments={data?.node.comments}
                    />
                    {noComments && (
                      <Hint>use @ to mention someone</Hint>
                    )}
                  </>
                )}
            </>
          )}

          {rightPanelContent === Content.Activity && (
            <ActivityContent>
              <ActivityItem>
                <UserIcon />
                <span>Created by</span>
                <ActivityAvatar size={20} user={doc.creator} />
                <span>{`${doc.creator.firstName} ${doc.creator.lastName}`}</span>
              </ActivityItem>

              <ActivityItem>
                <CalendarIcon />
                <span>{`Created on ${doc.createdAt ? format(parseISO(doc.createdAt), 'MMMM d, yyyy') : ''}`}</span>
              </ActivityItem>

              <FakeActivities>
                <Blur>
                  {[10, 30, 20, 40, 16, 22, 32, 12].map(nbItems => (
                    <ActivityItem key={nbItems}>
                      <CalendarIcon />
                      <span>{'Fake activity that will be coming soon!'.slice(0, nbItems)}</span>
                    </ActivityItem>
                  ))}
                </Blur>
                <ComingSoon>More coming soon  👀</ComingSoon>
              </FakeActivities>
            </ActivityContent>
          )}

          {rightPanelContent === Content.Insights && (
            (insightChildrenDocType
              ? <InsightChildrenList doc={doc} doctypeId={insightChildrenDocType.id} />
              : <FeedbackInsightList doc={doc} />
            )
          )}
        </DisplayedContent>
      )}

      <Toggle onClick={togglePanel}>
        <ToggleBar />
        <ToggleButton $isExpanded={rightPanelExpanded}>
          <CaretIcon />
        </ToggleButton>
      </Toggle>
    </Container>
  );
};

export default DocPanelRightPanel;
