import { MutableRefObject, Ref, useCallback, useRef, useImperativeHandle } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { DocItemHandle } from './DocItem.types';

type FocusableHook = (ref: Ref<DocItemHandle>) => [
  MutableRefObject<HTMLDivElement | null>,
  boolean,
  VoidFunction,
  VoidFunction,
];

export const useIsDocFocused: FocusableHook = (ref) => {
  const docRef = useRef<HTMLDivElement>(null);

  const [isDocFocused, {
    setTrueCallback: setIsFocusedTrue,
    setFalseCallback: setIsFocusedFalse,
  }] = useOptimizedBooleanState(false);

  const focus = useCallback(() => {
    setIsFocusedTrue();
    if (docRef.current) {
      scrollIntoView(docRef.current, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
      });
    }
  }, [setIsFocusedTrue]);

  useImperativeHandle(ref, () => ({
    focus,
    unfocus: setIsFocusedFalse,
  }));

  return [docRef, isDocFocused, setIsFocusedTrue, setIsFocusedFalse];
};
