import { ActionButton, SelectOption, Tag } from '@cycle-app/ui';
import { TrashIcon, ReorderIcon } from '@cycle-app/ui/icons';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { FC, HTMLAttributes, useState, FocusEvent, KeyboardEvent } from 'react';

import { isElementOutside } from 'src/utils/elements.util';

import { ReorderContainer, SaveButton, SelectLineStyled } from './SelectOptionsManager.styles';

export interface SelectOptionsEditableLineProps extends HTMLAttributes<HTMLDivElement> {
  setNodeRef?: (node: HTMLElement | null) => void;
  option: SelectOption;
  onDelete?: (optionId: string) => void;
  onEdit?: (optionId: string, value: string) => void;
  sortable?: boolean;
  listeners?: DraggableSyntheticListeners;
  disableHover?: boolean;
  isDragging?: boolean;
  isSaving?: boolean;
}

const SelectOptionsEditableLine: FC<SelectOptionsEditableLineProps> = ({
  option,
  onDelete,
  onEdit,
  sortable,
  listeners,
  isSaving,
  ...props
}) => {
  const [label, setLabel] = useState(option.label);
  const isValueChanged = option.label !== label;
  return (
    <SelectLineStyled
      key={option.value}
      label={label}
      isEditable={!!onEdit}
      onChangeLabel={setLabel}
      startSlot={renderStart()}
      startOnlyOnHover
      endSlot={renderEndSlot()}
      $isValueChanged={isValueChanged}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      {...props}
    />
  );

  function handleBlur(e: FocusEvent<HTMLDivElement>) {
    const {
      currentTarget, relatedTarget,
    } = e;
    // If blurs outside the current line, reset the value.
    if (isValueChanged && isElementOutside(currentTarget, relatedTarget)) {
      setLabel(option.label);
    }
  }

  function renderEndSlot() {
    if (isValueChanged) {
      return onEdit && (
        <SaveButton
          isLoading={isSaving}
          disabled={!label.trim()}
          onClick={() => onEdit(option.value, label)}
          type="button"
        >
          Save
        </SaveButton>
      );
    }
    return (
      onDelete && (
        <ActionButton
          tooltip="Delete option"
          tooltipPlacement="top"
          onClick={() => onDelete(option.value)}
          variant="warning"
        >
          <TrashIcon />
        </ActionButton>
      ));
  }

  function renderStart() {
    return (
      <>
        {option.icon && <Tag icon={option.icon} />}
        {sortable && <ReorderContainer {...listeners}><ReorderIcon /></ReorderContainer>}
      </>
    );
  }

  function handleKeyUp(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' && onEdit && label.trim() && isValueChanged) {
      onEdit(option.value, label);
    }
  }
};

export default SelectOptionsEditableLine;
