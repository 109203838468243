import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { body400 } from '../../../theme/typo';
import { Shortcut } from '../../Shortcut/Shortcut';
import { VariantOption } from '../option.type';

interface LineProps {
  $isSelected?: boolean;
  $isDisabled?: boolean;
  $isFocused?: boolean;
  $hoverDisabled?: boolean;
  $size?: 'S' | 'M';
  $variant?: VariantOption;
  startOnlyOnHover?: boolean;
  disableHover?: boolean;
  isDragging?: boolean;
  specialLabel?: boolean;
}

export const LineShortcut = styled(Shortcut)`
  opacity: 0;
`;

export const LineHover = styled.div``;

export const Line = styled.div<LineProps>`
  --bg: ${p => p.theme.colors.selectItem.bg};
  --lineEnd-flex-basis: auto;
  position: relative;
  ${body400};
  background-color: var(--bg);
  color: ${p => p.theme.colors.selectItem.color};
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 0 8px;
  z-index: 1;

  ${p => p.$isSelected && css`
    --bg: ${p.theme.colors.selectItem.bgHover};
  `};

  ${p => p.$size === 'S' && css`height: 24px;`};
  ${p => p.$size === 'M' && css`height: 30px;`};

  ${p => p.$isDisabled && css`
    --color: ${p.theme.colors.selectItem.colorDisabled};
    pointer-events: none;
    opacity: 0.75;
  `};

  ${p => p.startOnlyOnHover && css`
    ${LineStart} {
      opacity: 0;
    }
  `};

  &:not(:hover) ${LineHover} {
    display: none;
  }

  &:hover {
    ${p => !p.$hoverDisabled && css`
      background-color: ${p.theme.colors.selectItem.bgHover};

      ${p.$variant === 'danger' && css`
        color: ${p.theme.colors.selectItemDanger.colorHover};
        background-color: ${p.theme.colors.selectItemDanger.bgHover};
      `}
      ${p.$variant === 'special' && css`
        color: ${p.theme.colors.selectItemSpecial.colorHover};
      `}

      ${LineShortcut} {
        opacity: 1;
      }
    `};

    ${p => p.startOnlyOnHover && css`
      ${LineStart} {
        opacity: 1;
      }
    `};
  }

  ${p => p.disableHover && css`
    pointer-events: none;
  `};

  ${p => p.isDragging && css`
    z-index: 100;
    background-color: ${p.theme.colors.selectItem.bgHover};
    cursor: grabbing;
  `};

  &:focus-within {
    border-color: ${p => p.theme.userColors.main};
    box-shadow: 0 0 2px ${p => rgba(p.theme.userColors.main, 0.5)};
  }

  ${p => p.specialLabel && css`
    ${Label} {
      background-color: ${p.theme.colors.background.grey};
      border-radius: 4px;
      height: 24px;
      padding: 0 4px;
    }
    &:hover {
      background-color: ${p.theme.colors.background.disabled};
    }
    ${p.$isSelected && css`
      background-color: ${p.theme.colors.background.disabled};
    `}
  `};
`;

export const Label = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LineStart = styled.div`
  display: flex;
  align-items: center;
`;

export const LineEnd = styled.div`
  flex-basis: var(--lineEnd-flex-basis);
  margin-left: auto;
`;
