import { Emoji } from '@cycle-app/ui';
import { TrashIcon, LinkIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { getDocFullUrl } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { PageId } from 'src/constants/routing.constant';
import { useOptimizedBooleanState } from 'src/hooks';
import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { useNavigate } from 'src/hooks/useNavigate';
import { useUrl } from 'src/hooks/useUrl';
import { setHighlight } from 'src/reactives/highlight.reactive';
import { getDocSlug } from 'src/utils/slug.util';

import DialogModal from '../DialogModal/DialogModal';
import { TagStyled } from './InsightCardOptions.styles';

interface Props {
  className?: string;
  blockId?: string | null;
  doc: {
    _docKey: string;
    id: string;
    title: string;
    doctype: {
      emoji: string;
    };
  };
  isOpen?: boolean;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
}

export const InsightCardOptions: FC<Props> = ({
  className,
  blockId,
  doc,
  isOpen,
  onClose,
  onOpen,
}) => {
  const [isModalDeleteOpen, {
    toggleCallback: toggleModalDelete,
    setFalseCallback: hideDeleteModal,
  }] = useOptimizedBooleanState(false);

  const getUrl = useUrl();
  const { navigate } = useNavigate();
  const docUrl = useMemo(() => getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) }), [doc, getUrl]);
  const copyToClipboard = useCopyToClipboard({
    successNotification: `Link to ${doc._docKey || 'the insight'} copied to clipboard!`,
  });
  const { removeDoc } = useRemoveDoc(true);
  const fullDocUrl = useMemo(() => getDocFullUrl(docUrl), [docUrl]);

  return (
    <div className={className}>
      <DotsMenuLayer
        placement="bottom-end"
        options={[
          {
            value: 'open',
            icon: <ExpandIcon />,
            label: 'Open',
            onSelect: () => navigate(PageId.DocFullPage, {
              docSlug: getDocSlug({
                title: doc.title,
                id: doc.id,
              }),
            }),
          },
          {
            value: 'copy-link',
            icon: <LinkIcon />,
            label: 'Copy link',
            onSelect: () => copyToClipboard(fullDocUrl),
          },
          {
            value: 'delete',
            icon: <TrashIcon />,
            label: 'Delete',
            onSelect: () => toggleModalDelete(),
          },
        ]}
        visible={isOpen}
        hide={onClose}
        setVisible={onOpen}
      />
      {isModalDeleteOpen && (
        <DialogModal
          hide={hideDeleteModal}
          title="Delete doc"
          onConfirm={async () => {
            const result = await removeDoc(doc.id);
            if (blockId && result.data?.removeDoc?.id) {
              setHighlight({ blockIdsToDelete: [blockId] });
            }
          }}
          confirmLabel="Delete"
          info={(
            <>
              {'Are you sure to delete '}
              <TagStyled limitSize={false} icon={<Emoji emoji={doc.doctype.emoji} />}>
                {doc._docKey}
              </TagStyled>
              {' ?'}
              <br />
              This action can&apos;t be undone.
            </>
          )}
        />
      )}
    </div>
  );
};
