import { FC, useEffect, useState } from 'react';

import { NavigationItem } from '../NavigationItem/NavigationItem';
import { NAV_ITEM_ANIM_INTERVAL } from './nav-item-duration';

interface Props {
  maxLines?: number;
}

export const NavigationSectionSkeleton: FC<Props> = ({ maxLines = 5 }) => {
  const [nbItems, setNbItems] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNbItems(nb => {
        if (nb < maxLines) {
          return nb + 1;
        }
        clearInterval(interval);
        return nb;
      });
    }, NAV_ITEM_ANIM_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {Array(nbItems).fill(0).map((_item, i) => (
        <NavigationItem
          // eslint-disable-next-line react/no-array-index-key
          key={`nav-item-${i}`}
          isLoading
          label=""
        />
      ))}
    </>
  );
};
