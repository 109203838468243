import { Role } from '@cycle-app/graphql-codegen';
import {
  AddIcon,
  WheelIcon,
  KanbanIcon,
  UserIcon,
  HomeIcon,
  PaletteIcon,
} from '@cycle-app/ui/icons';

import { StyledCreateDocIconContainer } from 'src/app/Modals/CommandbarModal/CommandbarModal.styles';
import { StyledInboxIcon } from 'src/app/Modals/CommandbarModal/CommandK/CommandAction/CommandAction.styles';
import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useMe } from 'src/hooks/api/useMe';
import { useBoardShortcutCallbacks } from 'src/hooks/shortcuts/useBoardShortcutListener';
import { useGlobalShortcutCallbacks } from 'src/hooks/shortcuts/useGlobalShortcutListener';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { usePageId } from 'src/hooks/usePageId';
import { useGetCommandbar } from 'src/reactives/commandbar.reactive';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';
// import { useGetDocItem } from 'src/reactives/docItem.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useResponsive } from 'src/reactives/responsive.reactive';
import { CommandActionCategory, CommandActionType, CommandSection } from 'src/types/commandbar.types';
import { Shortcut, ShortcutBoard } from 'src/types/shortcuts.types';
import { execCallbackAndHide } from 'src/utils/commandbar.util';

import { useOnboardingPreviewBoard } from '../useOnboardingPreviewBoard';
import { useAdminActions, useCreateWorkspaceResult } from './useAdminActions';
import { useBoardContextActions } from './useBoardContextActions';
import { useBoardsResult } from './useBoardsResult';
// import { useDocContextActions } from './useDocContextActions';
import { useDocSearch } from './useDocSearch';
import { useSettingsResult } from './useSettingsResult';
import { useThemesResult } from './useThemesResult';
import { useWorkspacesSearch } from './useWorkspacesSearch';

interface UseCommandResultParams {
  search: string;
}

const RECENT_CATEGORY = 'docs';

export const useCommandResult = ({ search }: UseCommandResultParams) => {
  const pageId = usePageId();
  const { section } = useGetCommandbar();
  const defaultResult = useDefaultResult(search);
  const docSearch = useDocSearch(search, !section || section === 'search-doc');
  const boardsResult = useBoardsResult();
  const themesResult = useThemesResult();
  const settingsResult = useSettingsResult();
  const workspacesSearch = useWorkspacesSearch(search, section === 'workspace');
  const createWorkspaceResult = useCreateWorkspaceResult();

  const results: Record<CommandSection, Array<CommandActionCategory>> = {
    'go-to-board': boardsResult,
    'search-doc': docSearch.result,
    'switch-theme': themesResult,
    'go-to-settings': settingsResult,
    'create-workspace': createWorkspaceResult,
    workspace: workspacesSearch.result,
  };
  const filteredResult = section === 'workspace'
    ? results[section]
    : filterResult(section ? results[section] : defaultResult, search, pageId);

  return {
    resultByCategory: groupResult(filteredResult, search, section),
    entries: filteredResult.map(cat => cat.actions).flat(),
    loading: docSearch.loading || workspacesSearch.loading,
    hasNextPage: workspacesSearch.hasNextPage,
    fetchNextPage: workspacesSearch.fetchNextPage,
  };
};

function groupResult(filteredResult: Array<CommandActionCategory>, search: string, section: CommandSection | null) {
  // search-doc is not displayed by default, but still can be triggered with cmd+p.
  return (search.length && !section) || section === 'search-doc'
    ? [
      {
        id: 'commands',
        label: 'Commands',
        actions: filteredResult
          .filter(res => res.id !== RECENT_CATEGORY).map(res => res.actions).flat()
          .reduce<CommandActionType[]>((acc, current) => (
          acc.find(action => action.id === current.id || action.label === current.label) ? acc : ([...acc, current])), []),
      },
      {
        id: RECENT_CATEGORY,
        label: 'Doc results',
        actions: filteredResult.filter(res => res.id === RECENT_CATEGORY).map(res => res.actions).flat(),
      },
    ].filter(res => res.actions.length)
    : filteredResult;
}

const filterResult = (result: Array<CommandActionCategory>, search: string, currentPageId: PageId) => {
  function filterAction(action: CommandActionType) {
    return (
      // Has no defined callback
      !(process.env.NODE_ENV === 'production' && !action.onSelect) &&

      // The current page doesn't match with the scope specified in `onlyOn: Array<PageId>`
      !(action.onlyOn && !action.onlyOn.includes(currentPageId)) &&

      // Should match with the search term
      !!action.label?.toLowerCase().includes(search.toLowerCase())
    );
  }

  function filterCategory(category: CommandActionCategory) {
    return category.actions.filter(filterAction).length > 0;
  }

  return result
    .filter(filterCategory)
    .map(cat => ({
      ...cat,
      actions: cat.actions.filter(filterAction),
    }))
    .map((cat, _, filteredResult) => (
      cat.mapCategory ? cat.mapCategory(cat, {
        filteredResult,
        search,
      }) : cat
    ));
};

const useDefaultResult = (search: string): Array<CommandActionCategory> => {
  const { me } = useMe();
  const {
    boardId, section,
  } = useGetCommandbar();
  // const { hoverDocId } = useGetDocItem();
  const [{ breakpoint }] = useResponsive();
  const docSearch = useDocSearch(search, !section);
  const boardInbox = useOnboardingPreviewBoard({ name: 'inbox' });
  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);

  const adminActions = useAdminActions();
  const boardContextActions = useBoardContextActions();

  // Add them again when we will manage the case of contextual commands well
  // const docContextActions = useDocContextActions();

  const globalCallbacks = useGlobalShortcutCallbacks();
  const boardCallbacks = useBoardShortcutCallbacks();

  const { canReadSettings } = useGetPermission();

  const isAdmin = process.env.NODE_ENV === 'development' || me.role === Role.SuperAdmin;
  const isMobile = breakpoint === 'mobile';

  const defaultResult: Array<CommandActionCategory> = [
    {
      id: 'general-actions',
      actions: [
        {
          id: 'create-doc',
          label: 'New feedback',
          icon: (
            <StyledCreateDocIconContainer>
              <AddIcon />
            </StyledCreateDocIconContainer>
          ),
          shortcut: shortcuts[ShortcutBoard.CreateDoc],
          onSelect: execCallbackAndHide(() => setCreateDoc({ modalVisible: true })),
          onlyOn: [PageId.Board, PageId.Main],
        },
        {
          id: 'home',
          label: 'Go to Home',
          icon: <HomeIcon />,
          shortcut: shortcuts[Shortcut.GoToHome],
          onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToHome]),
        },
        {
          id: 'theme',
          label: 'Change theme',
          icon: <PaletteIcon />,
          shortcut: shortcuts[Shortcut.SwitchTheme],
          onSelect: globalCallbacks[Shortcut.SwitchTheme],
        },
      ],
    },
    {
      id: RECENT_CATEGORY,
      label: 'Recents',
      actions: docSearch.result[0]?.actions ?? [],
      mapCategory: (cat, { search: searchText }) => (
        !searchText.length
          ? {
            ...cat,
            actions: cat.actions.slice(0, 3),
          }
          : cat),
    },
    {
      id: 'navigation',
      label: 'Navigation',
      actions: [
        {
          id: 'navigation-home',
          label: 'Go to Home',
          icon: <HomeIcon />,
          shortcut: shortcuts[Shortcut.GoToHome],
          onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToHome]),
        },
        ...(isInboxEnabled || !!boardInbox) ? [
          {
            id: 'inbox',
            label: 'Go to Inbox',
            icon: <StyledInboxIcon />,
            shortcut: shortcuts[Shortcut.GoToInbox],
            onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToInbox]),
          }] : [],
        ...!isMobile ? [
          ...canReadSettings ? [{
            id: 'settings',
            label: 'Go to Settings',
            icon: <WheelIcon />,
            shortcut: shortcuts[Shortcut.GoToSettings],
            onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToSettings]),
          }] : [],
          {
            id: 'my-account',
            label: 'Go to My account',
            icon: <UserIcon />,
            shortcut: shortcuts[ShortcutBoard.GoToMyAccount],
            onSelect: execCallbackAndHide(boardCallbacks[ShortcutBoard.GoToMyAccount]),
            onlyOn: [PageId.Board, PageId.Doc],
          },
        ] : [],
        {
          id: 'go-to-board',
          label: 'Go to view',
          icon: <KanbanIcon />,
          shortcut: shortcuts[Shortcut.GoToBoard],
          onSelect: execCallbackAndHide(globalCallbacks[Shortcut.GoToBoard]),
        },
      ],
    }];

  return [
    ...defaultResult,
    ...(isAdmin ? [adminActions] : []),
    // ...(hoverDocId ? [docContextActions] : []),
    ...(boardId && boardContextActions.actions.length ? [boardContextActions] : []),
  ];
};
