import { DocChildFragment, DoctypeFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji, Tag, Tooltip, SelectOption } from '@cycle-app/ui';
import { UnlinkIcon, TrashIcon } from '@cycle-app/ui/icons';
import { getDocFullUrl } from '@cycle-app/utilities';
import { MouseEvent, memo, useCallback, VFC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import { DocSource } from 'src/components/DocSource/DocSource';
import { DocStatus } from 'src/components/DocStatus';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { Events, Methods, Sources } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useUrl } from 'src/hooks/useUrl';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getDocKey, shouldShowSource } from 'src/utils/doc.util';
import { getDocSlug } from 'src/utils/slug.util';

import { Container, DocTitle, Side, TagStyled } from './DocHierarchyItem.styles';

interface Props {
  parentId?: string;
  doc: DocChildFragment;
  doctype: DoctypeFragment;
  onDocRemove?: (doc: DocChildFragment) => void;
  refefresDeleteQueries?: boolean;
  hideDocCustomer?: boolean;
  isModal?: boolean;
}

const DocHierarchyItem: VFC<Props> = ({
  parentId,
  doc,
  doctype,
  onDocRemove,
  refefresDeleteQueries = true,
  hideDocCustomer,
  isModal,
}) => {
  const { removeDoc } = useRemoveDoc(refefresDeleteQueries);
  const [modalDeleteVisible, {
    setFalseCallback: hideModalDelete,
    toggleCallback: toggleModalDelete,
  }] = useOptimizedBooleanState(false);

  const onConfirmDelete = useCallback(async () => {
    await removeDoc(doc.id);
    onDocRemove?.(doc);
  }, [doc, removeDoc, onDocRemove]);

  const getUrl = useUrl();
  const product = useProductBase();

  const onDelete = useCallback(() => {
    toggleModalDelete();
  }, [toggleModalDelete]);

  const changeDocParent = useChangeDocParent();
  const unlinkDoc = useCallback(async () => {
    if (parentId) {
      await changeDocParent({
        docId: doc.id,
        parentId,
        remove: true,
      });
    }
  }, [doc, parentId]);

  const docKey = getDocKey(product?.key, doc.publicId);

  const copyToClipboard = useCopyToClipboard({
    successNotification: `Link to ${docKey} copied to clipboard!`,
  });

  const onTagDocIdClicked = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const docUrl = getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
    const fullDocUrl = getDocFullUrl(docUrl);
    copyToClipboard(fullDocUrl);
    trackAnalytics(Events.DocShared, {
      method: Methods.UI,
      source: Sources.DocPanel,
    });
  }, [copyToClipboard, doc, getUrl]);

  const deleteAction = useMemo<SelectOption>(() => ({
    label: 'Delete',
    value: 'delete',
    icon: <TrashIcon />,
    variant: 'danger',
    onSelect: onDelete,
  }), [onDelete]);

  const unlinkAction = useMemo<SelectOption>(() => ({
    label: 'Unlink',
    value: 'unlink',
    icon: <UnlinkIcon />,
    onSelect: unlinkDoc,
  }), [unlinkDoc]);

  const options = useMemo(() => (
    parentId ? [unlinkAction, deleteAction] : [deleteAction]), [deleteAction, parentId, unlinkAction]);

  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);

  return (
    <>
      <Link
        to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) })}
        onMouseEnter={() => {
          setDocItem({ hoverDocId: doc.id });
          if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: doc.id });
        }}
        onMouseLeave={() => setDocItem({ hoverDocId: null })}
      >
        <Container $isModal={isModal}>
          <Emoji emoji={doctype.emoji} />
          <Tooltip
            placement="top"
            title="Doc ID"
            content="Copy link"
            withPortal
          >
            <Tag onClick={onTagDocIdClicked}>{docKey}</Tag>
          </Tooltip>
          {isStatusEnabled && doc.status && (
            <DocStatus
              docId={doc.id}
              statusId={doc.status.id}
              docTypeId={doc.doctype.id}
              hideLabel
            />
          )}
          <DocTitle>
            {doc.title}
          </DocTitle>
          {shouldShowSource(doc) && doc.source && <DocSource source={doc.source} />}
          {!hideDocCustomer && !!doc?.doctype.customer && <DocCustomer doc={doc} isCompact />}
          <Side>
            <DocAssignee
              docId={doc.id}
              assignee={doc.assignee}
              layer={Layer.DropdownModalZ3}
              isRemovable={doc.doctype.type === DoctypeType.Custom}
            />
            <DotsMenuLayer
              layer={Layer.DropdownModalZ3}
              placement="bottom-end"
              options={options}
            />
          </Side>
        </Container>
      </Link>
      {modalDeleteVisible && (
        <DialogModal
          hide={hideModalDelete}
          title="Delete doc"
          onConfirm={onConfirmDelete}
          confirmLabel="Delete"
          info={(
            <>
              {'Are you sure to delete '}
              <TagStyled limitSize={false} icon={<Emoji emoji={doc.doctype.emoji} />}>
                {docKey}
              </TagStyled>
              {' ?'}
              <br />
              This action can&apos;t be undone.
            </>
          )}
        />
      )}
    </>
  );
};

export default memo(DocHierarchyItem);
