/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SchemaLink } from '@apollo/client/link/schema';
import { isEnabled, Feature } from '@cycle-app/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { GraphQLSchema } from 'graphql';
import { v4 as uuid } from 'uuid';

import { getAuth } from 'src/reactives/auth.reactive';

import { cache } from './cache';
import { WebSocketLink } from './WebSocketLink';

const sessionId = uuid();

const authLink = setContext((_, { headers }) => {
  const { token } = getAuth();
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
      session: sessionId,
    },
  };
});

// Stolen from https://github.com/dotansimha/graphql-code-generator/issues/3063
const fragmentDeDupeLink = new ApolloLink((operation, forward) => {
  const previousDefinitions = new Set<string>();
  const definitions = operation.query.definitions.filter((def) => {
    if (def.kind !== 'FragmentDefinition') return true;
    const name = `${def.name.value}-${def.typeCondition.name.value}`;
    if (previousDefinitions.has(name)) {
      return false;
    }
    previousDefinitions.add(name);
    return true;
  });
  const newQuery = {
    ...operation.query,
    definitions,
  };
  // eslint-disable-next-line no-param-reassign
  operation.query = newQuery;
  return forward(operation);
});

let hasWebsocketClosedBefore = false;

// hack: cast createUploadLink to unknown and ApolloLink to be used in ApolloLink.from
const uploadLink = createUploadLink({ uri: process.env.HTTP_API_GRAPHQL_URI }) as unknown as ApolloLink;
const subscriptionlink = new WebSocketLink({
  url: (process.env.WS_API_GRAPHQL_URI as string),
  on: {
    connected: async () => {
      if (hasWebsocketClosedBefore) {
        setTimeout(async () => {
          await onReconnectCallback();
        }, 1000 + Math.random() * 10000);
      }
    },
    closed: () => {
      hasWebsocketClosedBefore = true;
    },
  },
  retryAttempts: Infinity,
  retryWait: async function waitForServerHealthyBeforeRetry() {
    await new Promise((resolve) => setTimeout(resolve, 1000 + Math.random() * 10000));
  },
  connectionParams: () => {
    const { token } = getAuth();
    if (!token) {
      return {};
    }
    return {
      Authorization: `Bearer ${token}`,
      Session: sessionId,
    };
  },
});

export const getApolloClient = (schema?: GraphQLSchema) => {
  if (isEnabled(Feature.CachePersist)) {
    // eslint-disable-next-line
    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });
  }

  if (schema) { // For tests
    const errorLink = onError(({
      graphQLErrors, networkError, operation,
    }) => {
      const { operationName } = operation;
      if (graphQLErrors) {
        graphQLErrors.forEach(({
          message, locations, path,
        }) => console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Operation name: ${operationName}`,
        ));
      }
      if (networkError) console.error(`[Network error]: ${networkError}`);
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, fragmentDeDupeLink, authLink, subscriptionlink, new SchemaLink({ schema })]),
      cache,
    });
  }

  return new ApolloClient({
    link: ApolloLink.from([fragmentDeDupeLink, authLink, subscriptionlink, uploadLink]),
    cache,
  });
};

const client = getApolloClient();
const onReconnectCallback = () => client.reFetchObservableQueries();

export default client;
