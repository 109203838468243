import { useQuery } from '@apollo/client';
import { MateFragment, UserNodeDocument } from '@cycle-app/graphql-codegen';
import { Avatar, Tooltip } from '@cycle-app/ui';
import { memo } from 'react';

import { Viewer, TooltipContent } from './DocItem.styles';

interface Props {
  userId: string;
}

export const DocItemViewer = memo(({ userId }: Props) => {
  const { data } = useQuery<{ node: MateFragment }>(UserNodeDocument, {
    variables: {
      userId,
    },
  });

  const user = data?.node;
  if (!user) return null;

  return (
    <Tooltip
      withPortal
      placement="top"
      content={(
        <TooltipContent>
          <Avatar user={user} />
          <span>{`${user.firstName} ${user.lastName}`}</span>
        </TooltipContent>
      )}
    >
      <Viewer bgColor={user.color} />
    </Tooltip>
  );
});
