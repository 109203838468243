import { typo, ShyScrollbar, Avatar } from '@cycle-app/ui';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { CommentEditor } from 'src/components/Editor';
import MyAvatar from 'src/components/MyAvatar/MyAvatar';

export const newCommentMaxHeight = 128;

export const Container = styled.div``;

export const StyledDropdownLayer = styled(DropdownLayer)`
  width: 348px;
`;

export const DocChat = styled.div<{ noComments: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${p => (p.noComments ? 'auto' : '100%')};
  width: 100%;
  cursor: default;
`;

export const CommentMenuContainer = styled.div`
  opacity: 0;
  height: 20px;
  margin-left: auto;
`;

export const Comment = styled.div<{ sending: boolean }>`
  display: flex;
  gap: 8px;
  padding: 8px 16px;

  &:last-child {
    padding-top: 16px;
  }

  &:hover, &.force-hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
    ${CommentMenuContainer} {
      opacity: 1;
    }
  }

  ${p => p.sending && css`
    &:hover, &.force-hover {
      ${CommentMenuContainer} {
        opacity: 0;
      }
    }
  `};
`;

export const CommentsList = styled(ShyScrollbar)<{ $maxHeight?: string }>`
  display: flex;
  flex-direction: column-reverse;

  ${p => p.$maxHeight && css`
    max-height: calc(${p.$maxHeight} - ${newCommentMaxHeight + 70}px);
  `};
  word-break: break-word;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .ProseMirror {
    p {
      color: ${p => p.theme.colors.comments.color};
    }
  }
`;

export const UserAvatar = styled(Avatar)`
  margin-top: 4px;
`;

export const Info = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;

  > strong {
    ${typo.body500};
  }
  > small {
    ${typo.tiny400};
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const AddComment = styled.div`
  margin-top: auto;
  display: flex;
  word-break: break-word;
  border-radius: 4px;
`;

export const StyledMyAvatar = styled(MyAvatar)`
  padding: 8px;
`;

export const StyledCommentEditor = styled(CommentEditor)`
  width: 100%;
  .ProseMirror {
    padding: 6px;
    padding-left: 0;
    cursor: text;
  }
`;

export const ButtonsContainer = styled.div`
  padding: 3px 6px 6px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const NewCommentContainer = styled.div`
  margin-top: auto;
  padding: 16px;
`;

export const NewCommentContainerInner = styled.div<{ $focused: boolean }>`
  outline: 2px solid ${p => (p.$focused ? rgba(p.theme.userColors.main, 0.5) : 'transparent')};
  border-radius: 4px;

  background-color: ${p => (p.$focused ? 'inherit' : p.theme.colors.input.bg)};
  :hover {
    background-color: ${p => (p.$focused ? 'inherit' : p.theme.colors.input.hover)};
  }
`;

export const CommentsLoader = styled.div`
  ${typo.caption500};
`;
