import { useQuery } from '@apollo/client';
import { FetchLinearIssueByIdDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';

export const useLinearIssue = (issueId: string) => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Linear);
  const isActive = !!integration?.provider;
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery(FetchLinearIssueByIdDocument, {
    skip: !issueId || !isActive || !integration?.id,
    variables: {
      integrationId: integration?.id,
      issueId,
    },
  });

  const issue = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Linear'
      ) return null;

      return data.node.provider.issues[0] || null;
    },
    [data],
  );

  return {
    integration,
    isActive,
    isLoading,
    issue,
    refetch,
  };
};
