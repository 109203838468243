import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from 'src/app/Main/Main';
import { PageId, routing } from 'src/constants/routing.constant';
import { useProductFromInitial } from 'src/hooks/api/useProductFromInitial';
import { useUrl } from 'src/hooks/useUrl';
import { LocationState, RouteParams } from 'src/types/routes.types';

export interface OnboardingPreviewRouteProps {
  isOnboarded?: boolean;
  locationState?: LocationState;
  pageId: PageId;
  routeParams?: RouteParams;
}

export const OnboardingPreviewRoute: FC<OnboardingPreviewRouteProps> = ({
  children, isOnboarded, locationState, pageId, routeParams,
}) => {
  const getUrl = useUrl();
  const { product } = useProductFromInitial();

  return product
    ? (
      <Switch
        location={{
          pathname: getUrl(pageId, {
            ...routeParams,
            productSlug: product.slug,
          }),
          hash: '',
          search: '',
          state: {
            isOnboarding: !isOnboarded,
            ...locationState,
          },
        }}
      >
        <Route path={routing[pageId]}>
          {children ?? <Main />}
        </Route>
      </Switch>
    )
    : null;
};
