import { GithubAssignee } from '@cycle-app/graphql-codegen';
import { IntegrationCardSkeleton, EditorIntegrationCard, GithubStatus, Tooltip } from '@cycle-app/ui';
import { getComaSeparatedValue } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { useEffect, FC } from 'react';

import { GithubDescription } from 'src/components/GithubDescription/GithubDescription';
import { IntegrationEditorMenu } from 'src/components/Integrations/IntegrationEditorMenu/IntegrationEditorMenu';
import {
  ContainerNodeView, SlotText, FooterValue, Col, FooterLabel, FooterContentWrapper,
} from 'src/components/Integrations/IntegrationsCommon.styles';
import { useEditorContext } from 'src/contexts/editorContext';
import { useGithubIssue } from 'src/hooks/api/queries/integrations/useGithubIssue';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import { useNodeSelection } from 'src/hooks/editor/useNodeSelection';
import { GithubIssuesCommandAttributes } from 'src/types/editor.types';
import { parseGithubIssueLink, isGithubAssignee, isGithubLabel } from 'src/utils/integrations.utils';

import { GithubAssignees } from './Assignees/GithubAssignees';
import { StyledIcon } from './GithubIssueView.styles';
import { GithubStatuses } from './Statuses/GithubStatuses';

interface GithubIssueViewProps extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: GithubIssuesCommandAttributes) => void;
}
export const GithubIssueView: FC<GithubIssueViewProps> = ({
  node, getPos, selected, updateAttributes,
}) => {
  const {
    issue, refetch,
  } = useGithubIssue(node.attrs.id);
  const { statuses } = useGithubProject();
  const { editor } = useEditorContext();
  const { isSelected } = useNodeSelection({
    editor,
    selected,
    getPos,
  });

  useEffect(() => {
    if (issue) {
      updateAttributes({
        id: issue.id,
        url: issue.url,
        title: issue.title ?? node.attrs.title ?? '',
        state: issue.state ?? node.attrs.state ?? '',
        description: issue.description ?? node.attrs.description ?? '',
        creator: issue.creator ?? node.attrs.creator ?? '',
        cardStatus: issue.cardStatus ?? node.attrs.cardStatus ?? '',
        milestone: issue.milestone ?? node.attrs.milestone ?? '',
        labels: issue.labels?.filter(isGithubLabel) ?? node.attrs.labels ?? [],
        assignees: issue.assignees.filter(isGithubAssignee) ?? node.attrs.assignees ?? [],
      });
    }
  }, [issue, updateAttributes]);

  return (
    <ContainerNodeView
      $isSelected={isSelected}
      onClick={() => {
        const url = node.attrs.url || issue?.url;
        if (url) {
          window.open(url, '_blank');
        }
      }}
      data-drag-handle
    >
      {renderContent()}
    </ContainerNodeView>
  );

  function renderContent() {
    const {
      url, state, title, description, assignees,
    } = node.attrs;
    const showSkeleton = !url && !state && !title;
    if (showSkeleton) return <IntegrationCardSkeleton />;

    const issueAssignees = (assignees ?? issue?.assignees ?? []) as GithubAssignee[];
    const issueDescription = description ?? issue?.description ?? 'No description';
    const urlToParse = url || issue?.url;
    const parsedUrl = urlToParse
      ? parseGithubIssueLink(urlToParse)
      : null;

    return (
      <EditorIntegrationCard
        title={title ?? issue?.title ?? ''}
        preTitleSlot={renderPreTitleSlot(parsedUrl?.repository, parsedUrl?.publicId, state)}
        actions={(
          <IntegrationEditorMenu
            editor={editor}
            getPos={getPos}
            node={node}
            url={url}
            onRefetch={refetch}
          />
        )}
        assignees={(
          <GithubAssignees
            issueAssignees={issueAssignees}
            issuePublicId={parsedUrl?.publicId}
            repository={parsedUrl?.repository}
          />
        )}
        description={<GithubDescription description={issueDescription} />}
        footerSlot={renderFooterSlot()}
      />
    );
  }

  function renderPreTitleSlot(repository?: string, publicId?: string, state?: string) {
    const status = (state || issue?.state) === 'OPEN' ? 'open' : 'closed';

    return (
      <>
        <StyledIcon />
        <GithubStatus status={status} />
        <SlotText>{repository}</SlotText>
        <SlotText>{`#${publicId}`}</SlotText>
      </>
    );
  }

  function renderFooterSlot() {
    const {
      creator, milestone, labels, cardStatus, id,
    } = node.attrs;

    const [firstLabel, ...otherLabels] = labels || [];
    const hasFooter = !!creator || !!firstLabel || !!milestone;

    if (!hasFooter) return null;

    return (
      <FooterContentWrapper>
        {/**
         * Having statuses means that is there a GitHub project associated and
         * some statuses in there. Those statuses could be use to update the
         * GitHub issue status (From the associated project). An issue could
         * not have Status but if the project is associated we want to
         * display `No value` and nothing is no project at all.
         */}
        {(cardStatus || !!statuses?.length) && (
          <Col>
            <FooterLabel>Status</FooterLabel>
            <GithubStatuses
              availableStatuses={statuses || []}
              currentStatus={cardStatus || 'No value'}
              issueId={id}
            />
          </Col>
        )}
        {creator && (
          <Col>
            <FooterLabel>Creator</FooterLabel>
            <FooterValue isReadOnly>{creator}</FooterValue>
          </Col>
        )}
        {!!firstLabel && (
          <Col>
            <FooterLabel>Labels</FooterLabel>
            <FooterValue isReadOnly>
              {firstLabel}
              {otherLabels?.length ? (
                <Tooltip content={getComaSeparatedValue({ array: otherLabels })}>
                  +
                  {otherLabels.length}
                </Tooltip>
              ) : null}
            </FooterValue>
          </Col>
        )}
        {milestone && (
          <Col>
            <FooterLabel>Milestones</FooterLabel>
            <FooterValue isReadOnly>{milestone}</FooterValue>
          </Col>
        )}
      </FooterContentWrapper>
    );
  }
};
