import { InputHTMLAttributes, forwardRef } from 'react';

import { CheckIcon } from '../../../icons';
import { Container, Label, Text, Square, StyledInput } from './CheckboxInput.styles';

export interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value?: string | number;
  label?: string;
  hideLabel?: boolean;
  useUserColor?: boolean;
}
export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(({
  className,
  label: labelFromProps,
  hideLabel,
  useUserColor = true,
  ...inputProps
}, ref) => {
  const label = labelFromProps ?? inputProps.value;
  return (
    <Container
      className={className}
      $isDisabled={!!inputProps.disabled}
      $useUserColor={useUserColor}
    >
      <StyledInput
        ref={ref}
        type="checkbox"
        $useUserColor={useUserColor}
        {...inputProps}
      />
      <Label htmlFor={inputProps.id}>
        <Square>
          <CheckIcon />
        </Square>
        {!!label && !hideLabel && (
          <Text>{label}</Text>
        )}
      </Label>
    </Container>
  );
});
