import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { useProductStatuses } from 'src/hooks/product/useProductStatuses';

export const useDefaultDocTypeStatus = (docTypeId?: string) => {
  const statuses = useProductStatuses();
  return useMemo(() => {
    if (!docTypeId) return undefined;
    const docTypeStatuses = sortBy(statuses.notStarted.filter(s => s.doctypes.edges.some(e => e.node.id === docTypeId), 'position'));
    return docTypeStatuses?.[0];
  }, [docTypeId, statuses.notStarted]);
};
