import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { FC } from 'react';
import { useController, Control } from 'react-hook-form';

import DocSearchDropdown from 'src/components/DocSearchDropdown/DocSearchDropdown';
import { useDoc } from 'src/hooks/api/useDoc';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { CreateDocMentionFormValues } from 'src/types/doc.types';
import { Layer } from 'src/types/layers.types';

import {
  Container, Label, LoadingState, Input, Caret, DocTitle,
} from './ParentField.styles';

type Props = {
  control: Control<CreateDocMentionFormValues>;
  docType: DoctypeFragment;
};

export const ParentField: FC<Props> = ({
  control, docType,
}) => {
  const [isVisible, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(true);
  const {
    field: {
      onChange, value,
    },
  } = useController({
    name: 'parentDocId',
    control,
  });
  const {
    doc, loading,
  } = useDoc(value, !value);

  if (!docType.parents?.edges.length) return null;

  const possibleParentsDocTypes = docType.parents.edges.map(({ node }) => node);
  const parentsNames = possibleParentsDocTypes.reduce((acc, curr, index) => {
    if (index > 2) return acc;

    return acc ? `${acc}, ${curr.name}` : curr.name;
  }, '');

  return (
    <Container>
      <Label>{parentsNames}</Label>
      <DocSearchDropdown
        onAdd={(parentDocId) => {
          onChange(parentDocId);
          setFalseCallback();
        }}
        onRemove={() => {
          onChange(undefined);
          setFalseCallback();
        }}
        childDoctypeId={docType.id}
        hideSearchDropdown={setFalseCallback}
        isSearchDropdownVisible={isVisible}
        dropdownProps={{ layer: Layer.DropdownModalZ1 }}
        showNoneOption
        possibleDoctypes={possibleParentsDocTypes}
      >
        <Input onClick={setTrueCallback}>
          {value && (doc || loading) ? (
            <>
              {loading && !doc ? <LoadingState /> : null}
              {!loading && doc ? <Emoji emoji={doc?.doctype.emoji} /> : null}
              {!loading && <DocTitle>{doc?.title}</DocTitle>}
            </>
          ) : 'Choose from list'}
          <Caret />
        </Input>
      </DocSearchDropdown>
    </Container>
  );
};
