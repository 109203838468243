import { NewDocPosition } from '@cycle-app/ui';

import { make } from 'src/utils/reactives.util';

interface BoardNewDocPosition {
  groupId: string | null;
  draftPosition: NewDocPosition | null;
}

export const {
  hookValue: useBoardNewDocPositionState,
  getValue: getBoardNewDocPositionState,
  setValue: setBoardNewDocPositionState,
} = make<BoardNewDocPosition>({
  defaultState: {
    groupId: null,
    draftPosition: null,
  },
});
