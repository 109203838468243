import { IntegrationFullFragment } from '@cycle-app/graphql-codegen';

import {
  ONBOARDING_SOURCES_INTEGRATIONS,
  ONBOARDING_COLLAB_INTEGRATIONS,
  ONBOARDING_SOURCES_INTEGRATIONS_READONLY,
} from 'src/constants/integrations.constants';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { Integration } from 'src/types/integrations.types';

type IntegrationsByType = Record<Integration, IntegrationFullFragment | undefined>;

export type IntegrationItem = {
  type: Integration;
  integration?: IntegrationFullFragment;
};

export const useIntegrationsByType = (isReadOnly?: boolean) => {
  const query = useProductIntegrations();
  const byType: IntegrationsByType = Object.fromEntries(query.list.map((i) => [i.type, i]));
  return {
    sources: (isReadOnly ? ONBOARDING_SOURCES_INTEGRATIONS_READONLY : ONBOARDING_SOURCES_INTEGRATIONS).map(type => ({
      type,
      integration: byType[type],
    })),
    collaboration: ONBOARDING_COLLAB_INTEGRATIONS.map(type => ({
      type,
      integration: byType[type],
    })),
    isLoading: !query.isCalled || query.isLoading,
  };
};
