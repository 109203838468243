import { useEffect, useRef } from 'react';

export const usePointerAnimation = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;
      const angle = Math.atan2(e.clientY - y, e.clientX - x) / 5;
      const dy = (y - e.clientY) / 50;
      const dx = (x - e.clientX) / 40;
      if (angle < 0.3 && angle > -0.4) {
        ref.current.style.transform = `translate3d(${dx}px, ${dy}px, 0px) rotateZ(${angle}rad)`;
      }
    };

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return ref;
};
