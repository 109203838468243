import { LinearIssueFullFragment } from '@cycle-app/graphql-codegen/generated';
import { Button, SelectPanel, Spinner, Info } from '@cycle-app/ui';
import { Helper } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { CloseIcon, InfoIcon } from '@cycle-app/ui/icons';
import { useMeasure } from '@cycle-app/utilities';
import { useMemo, FC } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  PortalModalStyled, Title, Actions, Body, Header, CloseButtonStyled, Footer,
} from 'src/components/DialogModal/DialogModal.styles';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { LINEAR_EXTENSION_NAME } from 'src/constants/editor.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useCreateLinearIssues } from 'src/hooks/api/mutations/integrations/useCreateLinearIssues';
import { useLinearAssignees } from 'src/hooks/api/queries/integrations/useLinearAssignees';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { insertIssues } from 'src/utils/editor/editor.utils';
import { isLinearStatus, isLinearTeam } from 'src/utils/integrations.utils';

import { LinearAssignee } from '../LinearAssignee/LinearAssignee';
import { LinearStatus } from '../LinearStatus/LinearStatus';
import {
  Caret,
  CustomInputBox,
  CustomLabel, DualRowBox,
  FormRows,
  ProgressionLabelContainer,
  ProgressionText,
  SelectButtonTitle,
  StyledInput,
  StyledSelectButton,
  InfoContainer,
} from './LinearIssueCreationModal.styles';

type FormData = {
  assigneeId: string;
  description: string;
  statusId: string;
  title: string;
  teamId: string;
};

type Props = {
  onHide: VoidFunction;
  defaultValues?: Partial<FormData>;
  onIssuesCreated?: (issues: LinearIssueFullFragment[]) => void;
};

export const LinearIssueCreationModal: FC<Props> = ({
  onHide, defaultValues, onIssuesCreated,
}) => {
  const {
    editor, doc,
  } = useEditorContext();
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const { state } = editor;
  const {
    from, to,
  } = state.selection;
  const newTitles = getDocTitlesFromSelectedText(editor.state);
  const isMulti = newTitles.selectionValues.length > 1;
  const text = state.doc.textBetween(from, to, ' ');
  const {
    control,
    formState,
    getValues,
    handleSubmit,
    setValue,
    register,
  } = useForm<FormData>({
    defaultValues: {
      assigneeId: '',
      description: '',
      statusId: '',
      title: isMulti ? '' : newTitles.selectionValues[0] ?? text,
      teamId: defaultValues?.teamId ?? '',
      ...defaultValues,
    },
  });
  const {
    teams,
    isLoading: isTeamsLoading,
  } = useLinearTeams({
    onCompleted: (data) => {
      if (data.node?.__typename === 'Integration' && data?.node.provider?.__typename === 'Linear') {
        const defaultTeam = data.node.provider.teams.filter(isLinearTeam)[0];
        const defaultTeamId = defaultValues?.teamId ?? defaultTeam.id;
        if (defaultTeamId) {
          setValue('teamId', defaultTeamId);
          setValue('statusId', defaultValues?.statusId ?? defaultTeam.availableStatus?.[0]?.id ?? '');
        }
      }
    },
  });
  const {
    assignees, isLoading: isAssigneesLoading,
  } = useLinearAssignees();
  const {
    createIssues, progression: createIssuesProgression,
  } = useCreateLinearIssues();

  const assigneesOptions = assignees.map(assignee => ({
    value: assignee.id,
    label: assignee.name,
    icon: <LinearAssignee assignee={assignee} />,
  }));

  const teamsOptions = useMemo(() => teams.map(team => ({
    value: team.id,
    label: team.name,
  })), [teams]);

  const [isTeamSelectOpened, {
    toggleCallback: toggleTeamSelect,
    setFalseCallback: setTeamSelectClosed,
  }] = useOptimizedBooleanState(false);
  const [isStatusSelectOpened, {
    toggleCallback: toggleStatusSelect,
    setFalseCallback: setStatusSelectClosed,
  }] = useOptimizedBooleanState(false);
  const [isAssigneeSelectOpened, {
    toggleCallback: toggleAssigneeSelect,
    setFalseCallback: setAssigneeSelectClosed,
  }] = useOptimizedBooleanState(false);

  const selectedTeamId = getValues('teamId');
  const statusOptions = useMemo(() => teams
    .find(team => team?.id === selectedTeamId)?.availableStatus.filter(isLinearStatus).map(status => ({
      value: status.id,
      label: status.name,
      icon: <LinearStatus status={status} />,
    })) ?? [], [teams, selectedTeamId]);

  const {
    rect: selectedTeamButtonRect,
    ref: selectedTeamButtonRef,
  } = useMeasure<HTMLButtonElement>();
  const {
    rect: selectedStatusButtonRect,
    ref: selectedStatusButtonRef,
  } = useMeasure<HTMLButtonElement>();
  const {
    rect: selectedAssigneeButtonRect,
    ref: selectedAssigneeButtonRef,
  } = useMeasure<HTMLButtonElement>();

  return (
    <PortalModalStyled hide={onHide} useHighMaskLayer layer={Layer.DropdownBoardConfig}>
      <Header>
        <Title>
          {`Create Linear issue${(isMulti && 's') || ''}`}
        </Title>
        <CloseButtonStyled size="L" onClick={onHide}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      {isMulti && (
        <InfoContainer>
          <Info icon={<InfoIcon />}>
            {`You will create ${newTitles.selectionValues.length} Linear issue${newTitles.selectionValues.length ? 's' : ''}`}
          </Info>
        </InfoContainer>
      )}
      <Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRows>
            {!isMulti && (
              <StyledInput
                id="linearIssue-title"
                label="Issue title"
                placeholder="Your issue title"
                error={formState.errors.title?.message}
                autoFocus
                {...register('title', {
                  required: 'You must have at least one character.',
                })}
              />
            )}
            <CustomInputBox>
              <CustomLabel>Team</CustomLabel>
              <Controller
                control={control}
                name="teamId"
                rules={{ required: 'You must select a team.' }}
                render={({ field }) => (
                  <DropdownLayer
                    hide={setTeamSelectClosed}
                    layer={Layer.DropdownModal}
                    placement="bottom-start"
                    visible={isTeamSelectOpened}
                    width={selectedTeamButtonRect?.width}
                    content={(
                      <SelectPanel
                        hideSearch
                        isMulti={false}
                        options={teamsOptions}
                        onOptionChange={({ value }) => {
                          field.onChange(value);
                          const statusId = teams.find(team => team.id === value)?.availableStatus[0]?.id ?? '';
                          setValue('statusId', statusId);
                          toggleTeamSelect();
                        }}
                        selectedValue={field.value}
                      />
                    )}
                  >
                    <StyledSelectButton
                      isLoading={isTeamsLoading}
                      ref={selectedTeamButtonRef}
                      variant="light"
                      onClick={toggleTeamSelect}
                    >
                      {getSelectedTeam(field.value)?.label ?? 'Select your team...'}
                      <Caret direction={isTeamSelectOpened ? 'top' : 'bottom'} />
                    </StyledSelectButton>
                    {formState.errors.teamId && <Helper $hasError>{formState.errors.teamId.message}</Helper>}
                  </DropdownLayer>
                )}
              />
            </CustomInputBox>
            <DualRowBox>
              <CustomInputBox>
                <CustomLabel>Status</CustomLabel>
                <Controller
                  control={control}
                  name="statusId"
                  render={({ field }) => (
                    <DropdownLayer
                      hide={setStatusSelectClosed}
                      layer={Layer.DropdownModal}
                      placement="bottom-start"
                      visible={isStatusSelectOpened}
                      width={selectedStatusButtonRect?.width}
                      content={(
                        <SelectPanel
                          hideSearch
                          isMulti={false}
                          options={statusOptions}
                          onOptionChange={(option) => { field.onChange(option.value); toggleStatusSelect(); }}
                          selectedValue={field.value}
                        />
                      )}
                    >
                      <StyledSelectButton
                        disabled={!selectedTeamId}
                        ref={selectedStatusButtonRef}
                        variant="light"
                        onClick={toggleStatusSelect}
                        key={selectedTeamId}
                      >
                        <SelectButtonTitle>
                          {getStatusText(field.value)}
                        </SelectButtonTitle>
                        <Caret direction={isStatusSelectOpened ? 'top' : 'bottom'} />
                      </StyledSelectButton>
                    </DropdownLayer>
                  )}
                />
              </CustomInputBox>
              <CustomInputBox>
                <CustomLabel>Assignee</CustomLabel>
                <Controller
                  control={control}
                  name="assigneeId"
                  render={({ field }) => (
                    <DropdownLayer
                      hide={setAssigneeSelectClosed}
                      layer={Layer.DropdownModal}
                      placement="bottom-start"
                      visible={isAssigneeSelectOpened}
                      width={selectedAssigneeButtonRect?.width}
                      content={(
                        <SelectPanel
                          hideSearch
                          isMulti={false}
                          options={assigneesOptions}
                          onOptionChange={(option) => { field.onChange(option.value); toggleAssigneeSelect(); }}
                          selectedValue={field.value}
                        />
                      )}
                    >
                      <StyledSelectButton
                        isLoading={isAssigneesLoading}
                        ref={selectedAssigneeButtonRef}
                        variant="light"
                        onClick={toggleAssigneeSelect}
                      >
                        <SelectButtonTitle>
                          {getSelectedAssignee(field.value) ? (
                            <>
                              {getSelectedAssignee(field.value)?.icon}
                              {getSelectedAssignee(field.value)?.label}
                            </>
                          ) : 'Select an assignee...'}
                        </SelectButtonTitle>
                        <Caret direction={isAssigneeSelectOpened ? 'top' : 'bottom'} />
                      </StyledSelectButton>
                    </DropdownLayer>
                  )}
                />
              </CustomInputBox>
            </DualRowBox>
          </FormRows>
          <Footer>
            {createIssuesProgression.status === 'in-progress' && createIssuesProgression.issuesTotal > 1 && (
              <ProgressionLabelContainer>
                <Spinner />
                <ProgressionText>
                  {`Issue created ${createIssuesProgression.issuesSuccess} / ${createIssuesProgression.issuesTotal}`}
                </ProgressionText>
              </ProgressionLabelContainer>
            )}
            <Actions>
              <Button
                onClick={onHide}
                variant="secondary"
                size="M"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="M"
                isLoading={createIssuesProgression.status === 'in-progress'}
                disabled={!!Object.keys(formState.errors).length}
              >
                {isMulti ? `Create ${newTitles.selectionValues.length} issues` : 'Create'}
              </Button>
            </Actions>
          </Footer>
        </form>
      </Body>
    </PortalModalStyled>
  );

  function getSelectedTeam(teamId: FormData['teamId']) {
    return teamsOptions.find(({ value }) => value === teamId);
  }

  function getSelectedAssignee(assigneeId: FormData['assigneeId']) {
    return assigneesOptions.find(({ value }) => value === assigneeId);
  }

  function getSelectedStatus(statusId: FormData['statusId']) {
    return statusOptions?.find(({ value }) => value === statusId);
  }

  function getStatusText(statusId: FormData['statusId']) {
    if (selectedTeamId && !statusOptions.length) return 'No status available.';
    if (selectedTeamId && !statusId) return 'Select a status...';
    const selectedStatus = getSelectedStatus(statusId);
    return selectedStatus ? (
      <>
        {selectedStatus.icon}
        {selectedStatus.label}
      </>
    ) : 'Select a status...';
  }

  async function onSubmit(data: FormData) {
    if (!doc) return;
    const {
      assigneeId, teamId, title, statusId,
    } = data;
    const newIssues = await createIssues({
      docId: doc.id,
      titles: isMulti ? newTitles.selectionValues : [title],
      statusId,
      teamId,
      ...assigneeId && { assigneeId },
    });
    if (!newIssues.length) return;
    if (onIssuesCreated) {
      onIssuesCreated(newIssues);
    } else {
      insertIssues({
        editor,
        newIssues,
        extensionName: LINEAR_EXTENSION_NAME,
      });
    }
    onHide();
  }
};
