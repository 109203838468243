import { UserJourney } from '@cycle-app/graphql-codegen';
import { NoCommentIcon } from '@cycle-app/ui/icons';
import { useEffect, FC, ReactNode, useRef } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { FEEDBACK_URL } from 'src/constants/contacts.constants';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate, useWindowSize } from 'src/hooks';
import { useMe } from 'src/hooks/api/useMe';
import { useLockProductTour } from 'src/hooks/productTour/useLockProductTour';
import { useElObserver } from 'src/hooks/useElObserver';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import {
  getActiveProductTourEl,
  getActiveProductTourNextStep,
  getActiveProductTourPreviousStep,
  getActiveProductTourStepDetails,
  setActiveProductTour,
  useActiveProductTour,
  useStartTour,
  resetTour,
  useFinishTour,
} from 'src/reactives/productTours.reactive';
import { Layer } from 'src/types/layers.types';
import {
  ElementPosition,
  ProductTourLock,
  StepDetails,
  TourName,
  TourStepAction,
} from 'src/types/productTour.types';
import {
  getElementPosition,
  getFramePosition,
  getTooltipPositions,
} from 'src/utils/productTour.util';

import {
  ActionButton,
  Actions,
  Chapter,
  Chapters,
  HelpButton,
  HelpDropdown,
  ModalContainer,
  HelpDropdownOverlay,
  HelpDropdownContent,
  Overlay,
  P,
  Title,
  NextArrowIcon,
} from './ProductTour.styles';

const OUTER_RECT_POSITION = `
  0 0, /* outer rect bottom left */
  100% 0, /* top left */
  100%  100%, /* top right */
  0% 100%, /* bottom right */
  0 0, /* bottom left */
`;

type ProductTourProps = {
  children?: ReactNode;
};

export const ProductTour: FC<ProductTourProps> = () => {
  const tour = useActiveProductTour();
  const el = getActiveProductTourEl();
  const startTour = useStartTour();
  const { locks } = useLockProductTour(tour?.name);
  const { navigate } = useNavigate();
  const { me } = useMe();

  useEffect(() => {
    if (!tour && me.userJourney === UserJourney.ProductTour) {
      startTour(TourName.FIRST_TOUR);
    }
  }, []);

  useEffect(() => {
    if (locks.includes(ProductTourLock.REDIRECT_INBOX)) {
      navigate(PageId.Inbox);
      resetTour(TourName.FIRST_TOUR);
    }
  }, [locks.length]);

  if (!tour || !el) return null;

  return <ProductTourOverlay el={el} />;
};

type ProductTourOverlayProps = {
  el: HTMLElement;
};

export const ProductTourOverlay: FC<ProductTourOverlayProps> = ({ el }) => {
  useWindowSize();
  const { positions: pos } = useElObserver({ el });
  const details = getActiveProductTourStepDetails();

  const positions = pos.x ? pos : getElementPosition(el);

  return (
    <>
      {details && <ProductTourModal data={details} positions={positions} />}
      <ProductTourHelper />
      <Overlay
        animate={{
          opacity: 1,
          clipPath: `
            polygon(
              evenodd,
              ${OUTER_RECT_POSITION}
              ${getFramePosition(positions, 'bottom-left', details?.frameGap || 0)},
              ${getFramePosition(positions, 'top-left', details?.frameGap || 0)},
              ${getFramePosition(positions, 'top-right', details?.frameGap || 0)},
              ${getFramePosition(positions, 'bottom-right', details?.frameGap || 0)},
              ${getFramePosition(positions, 'bottom-left', details?.frameGap || 0)}
            )
          `,
        }}
        initial={{
          opacity: 0,
          clipPath: `
            polygon(
              evenodd,
              ${OUTER_RECT_POSITION}
              ${OUTER_RECT_POSITION}
            )
          `,
        }}
        transition={{ duration: 0.4 }}
      />
    </>
  );
};

type ProductTourModalProps = {
  data: StepDetails;
  positions: ElementPosition;
};

const ProductTourModal: FC<ProductTourModalProps> = ({
  data, positions,
}) => {
  const { me } = useMe();
  const tooltipElement = useRef<HTMLDivElement>(null);
  const [currentChapter, totalChapters] = data.chapter.split('/');
  const contentText = typeof data.text === 'string'
    ? data.text
    : data.text(me.firstName);

  return (
    <ModalContainer
      ref={tooltipElement}
      animate={getTooltipPositions(
        tooltipElement.current,
        positions,
        data.tooltipPosition,
        data.frameGap,
      )}
      initial={{
        left: 0,
        top: 0,
      }}
      transition={{ duration: 0.4 }}
    >
      <Chapters>
        {Array
          .from(Array(Number(totalChapters)).keys())
          .map((_, index) => (
            <Chapter
              key={`${currentChapter}${_}${data.chapter}`}
              $isActive={Number(currentChapter) > index}
            />
          ))}
      </Chapters>
      <Title>{data.title}</Title>
      <P>{contentText}</P>
      {data.actions?.length && (
        <Actions $placement={getButtonPlacement(data.actions)}>
          {data.actions.includes('back') && (
            <ActionButton
              onClick={() => {
                const previousStep = getActiveProductTourPreviousStep();
                if (previousStep) {
                  setActiveProductTour({ step: previousStep });
                }
              }}
            >
              Back
            </ActionButton>
          )}
          {data.actions.includes('next') && (
            <ActionButton
              onClick={() => {
                const nextStep = getActiveProductTourNextStep();
                if (nextStep) {
                  setActiveProductTour({ step: nextStep });
                }
              }}
            >
              Next
            </ActionButton>
          )}
        </Actions>
      )}
    </ModalContainer>
  );
};

function getButtonPlacement(actions: TourStepAction[]) {
  if (actions.length > 1) return 'space-between';
  if (actions[0] === 'back') return 'flex-start';
  if (actions[0] === 'next') return 'flex-end';
  return 'space-between';
}

const ProductTourHelper = () => {
  const finishTour = useFinishTour();
  const [isVisible, {
    setTrueCallback,
    setFalseCallback,
  }] = useOptimizedBooleanState(false);
  const [isWarningVisible, {
    setTrueCallback: openWarningModal,
    setFalseCallback: hideWarningModal,
  }] = useOptimizedBooleanState(false);

  return (
    <>
      {isWarningVisible && (
        <DialogModal
          layer={Layer.ProductTour}
          type="warning"
          title="Skip onboarding"
          confirmLabel="Skip onboarding"
          cancelLabel="Cancel"
          hide={hideWarningModal}
          info="Are you sure you want to skip the onboarding? Please note you won't be able to do it again in future."
          onCancel={hideWarningModal}
          onConfirm={() => {
            finishTour(TourName.FIRST_TOUR);
            hideWarningModal();
          }}
        />
      )}
      <HelpButton onClick={setTrueCallback}>?</HelpButton>
      <HelpDropdownOverlay $isVisible={isVisible} onClick={setFalseCallback} />
      <HelpDropdown $isVisible={isVisible}>
        <HelpDropdownContent
          hideSearch
          options={[
            {
              label: 'Give us feedback',
              value: 'feedback',
              icon: <NoCommentIcon />,
            },
            {
              label: 'Skip onboarding',
              value: 'skip',
              icon: <NextArrowIcon />,
            },
          ]}
          isMulti={false}
          onOptionChange={({ value }) => {
            if (!isVisible || !value) return;

            if (value === 'skip') {
              openWarningModal();
              setFalseCallback();
            } else if (value === 'feedback') {
              window.open(FEEDBACK_URL, '_blank');
              setFalseCallback();
            }
          }}
        />
      </HelpDropdown>
    </>
  );
};
