import { Color, ViewType } from '@cycle-app/graphql-codegen';
import { MovingBorderLoader, boxShadowZ1, typo, truncate } from '@cycle-app/ui';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

function getRotate(direction = 'right'): number {
  return direction === 'right' ? 2 : -2;
}

interface ContainerProps {
  grabbing?: boolean;
  locked?: boolean;
  isDraggingMulti?: boolean;
}

const getLinkCursor = ({
  grabbing, locked,
}: ContainerProps): string => {
  if (grabbing) return 'grabbing';
  if (locked) return 'default';
  return 'pointer';
};

export const PlaceholderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PlaceholderMulti = styled.div<{ direction?: 'left' | 'right' }>`
  position: absolute;
  top: 4px;
  left: 4px;

  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.userColors.br};
  background-color: ${p => p.theme.userColors.bg};

  transform: translate3d(10px, 0, 0) rotate(${p => getRotate(p.direction)}deg);
`;

export const CoverLoader = styled(MovingBorderLoader)`
  position: absolute;
  z-index: 10;
  top: -1px;
  left: -1px;
`;

export const DropzoneContainer = styled.div`
  margin: 1px;
  position: relative;
`;

export const Viewers = styled.div`
  z-index: 1;
  position: absolute;
  right: 2px;
  top: -5px;

  display: flex;
  align-items: center;
  gap: 3px;
`;

export const Viewer = styled.div<{ bgColor: Color }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${p => transparentize(0.5, p.theme.nuances[p.bgColor].main)};

  transition: transform .2s ease-in-out;
  &:hover {
    transform: scale(1.5);
  }
`;

export const BulkContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 1px;
  left: -17px;
`;

interface BulkCheckboxProps {
  viewType: ViewType;
  isSelected?: boolean;
  isFocused?: boolean;
}
export const BulkCheckbox = styled.button<BulkCheckboxProps>`
  padding: 3px;

  ${p => p.viewType === 'LIST' && css`
    top: 50%;
    transform: translateY(-50%);
  `}

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 8px;
    height: 8px;
  }
  div {
    display: flex;
  }

  &:hover {
    opacity: 1;
  }

  ${p => p.isFocused && css`
    opacity: 1;
  `}

  ${p => p.isSelected && css`
    opacity: 1;
    background-color: ${p.theme.userColors.main};
    color: ${p.theme.colors.text.white};
  `};

  ${p => !p.isSelected && css`
    background-color: ${p.theme.colors.background.primary};
    color: ${p.theme.colors.text.disabled};
    border: 1px solid ${p.theme.colors.border.surprisingGrey};
    &:hover {
      color: ${p.theme.colors.text.secondary};
    }
  `};

  ${boxShadowZ1};
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  a {
    cursor: ${(props) => getLinkCursor(props)};
  }

  &:hover {
    ${BulkCheckbox} {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
`;

export const TitleContent = styled.span`
  ${truncate}
`;

export const Title = styled.span`
  ${typo.body500}
`;

export const Description = styled.span`
  margin-left: 8px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
