import { CustomerWithDoctypeCountFragment, DoctypeCategory } from '@cycle-app/graphql-codegen';
import {
  RowsSkeleton,
  THead,
  TBody,
  Tr,
  TextHighlighter,
  CellContent,
  Tooltip,
  Emoji,
  InfiniteScroll,
} from '@cycle-app/ui';
import { OpenIcon } from '@cycle-app/ui/icons';
import { useMemo, FC } from 'react';

import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { CustomerLinkedProfiles } from '../CustomerProfile/CustomerLinkedProfiles';
import { CustomerActionsMenu } from './CustomerActionsMenu';
import {
  StyledTable,
  TdActions,
  StyledTr,
  CellLink,
  StyledTh,
  StyledTd,
  StyledShyScrollbar,
  DocCountCell,
  StyledThActions,
} from './Customers.styles';
import { CustomerTableCompanyCell } from './CustomerTableCompanyCell';
import { CustomerTableNameCell } from './CustomerTableNameCell';

type HiddenData = {
  header?: boolean;
  action?: boolean;
  profiles?: boolean;
  companies?: boolean;
  docs?: boolean;
};

interface Props {
  customers: CustomerWithDoctypeCountFragment[];
  searchText: string;
  loadMore: VoidFunction;
  className?: string;
  onCustomerClick?: (id: string) => void;
  onCompanyClick?: (id: string) => void;
  isLoading?: boolean;
  hasNextPage?: boolean;
  isFullPage?: boolean;
  shyScrollDisabled?: boolean;
  scrollableElement?: HTMLElement | null;
  nameCellSize?: number;
  emailCellSize?: number;
  actionsCellSize?: number;
  hideLastDiscovery?: boolean;
  hiddenData?: HiddenData;
  isCompact?: boolean;
}

export const CustomersListPeople: FC<Props> = ({
  shyScrollDisabled,
  scrollableElement,
  customers,
  searchText,
  className,
  onCustomerClick,
  onCompanyClick,
  isLoading,
  loadMore,
  hasNextPage,
  isFullPage = true,
  nameCellSize = 300,
  emailCellSize = 300,
  actionsCellSize,
  hiddenData = {},
  isCompact = false,
}) => {
  const doctypes = useProductDoctypes();
  const copyEmailToClipboard = useCopyToClipboard({
    successNotification: 'Email copied to clipboard',
  });

  const doctypesDiscovery = useMemo(() => doctypes.filter(doctype => doctype.category === DoctypeCategory.Discovery), [doctypes]);

  const showHeader = !hiddenData.header;
  const showActions = !hiddenData.action;
  const showCompanies = !hiddenData.companies;
  const showDocs = !hiddenData.docs;
  const showProfiles = !hiddenData.profiles;

  const sizeCell = isCompact ? 180 : nameCellSize;
  const sizeCellTablet = isCompact ? 180 : 250;

  return (
    <StyledShyScrollbar disable={shyScrollDisabled}>
      <InfiniteScroll
        scrollableElement={scrollableElement}
        isLoading={!!isLoading}
        hasMoreData={!!hasNextPage}
        loadMore={loadMore}
      >
        <StyledTable className={className} $isPrimary={isFullPage}>
          {showHeader && (
            <THead>
              <Tr>
                <StyledTh $size={sizeCell} $sizeTablet={sizeCellTablet} $sizeMobile={sizeCellTablet}>
                  Name
                </StyledTh>
                {showCompanies && <StyledTh $size={sizeCellTablet}>Company</StyledTh>}
                <StyledTh $size={emailCellSize}>Email</StyledTh>
                {showDocs && doctypesDiscovery.map(doctype => (
                  <StyledTh $size={80} key={doctype.id}>
                    <Tooltip content={doctype.name} placement="top" withPortal>
                      <Emoji emoji={doctype.emoji} />
                    </Tooltip>
                  </StyledTh>
                ))}
                {showProfiles && <StyledTh $size={120}>Linked profiles</StyledTh>}
                {showActions && <StyledThActions $size={actionsCellSize} />}
              </Tr>
            </THead>
          )}
          <TBody>
            {customers.map(customer => (
              <StyledTr key={customer.id}>
                <StyledTd $size={sizeCell} $sizeTablet={sizeCellTablet} $sizeMobile={sizeCellTablet}>
                  <CustomerTableNameCell
                    customer={customer}
                    name={customer.name && <TextHighlighter className="highlight" searchWords={[searchText]} textToHighlight={customer.name} />}
                    isCompact={isCompact}
                    {...onCustomerClick && {
                      link: (
                        <CellLink>
                          <OpenIcon />
                          Open
                        </CellLink>
                      ),
                      onClick: () => onCustomerClick(customer.id),
                    }}
                  />
                </StyledTd>
                {showCompanies && (
                  <StyledTd $size={sizeCellTablet}>
                    {customer.company?.id && customer.company.name && (
                      <CustomerTableCompanyCell
                        name={customer.company.name}
                        company={customer.company}
                        {...onCompanyClick && {
                          link: (
                            <CellLink>
                              <OpenIcon />
                              Open
                            </CellLink>
                          ),
                          onClick: () => onCompanyClick(customer.company?.id ?? ''),
                        }}
                      />
                    )}
                  </StyledTd>
                )}
                <StyledTd $size={emailCellSize}>
                  {customer.email && (
                    <CellContent $isClickbable $limitSize>
                      <Tooltip content="Copy email" title={customer.email} placement="top" withPortal>
                        <TextHighlighter
                          onClick={() => customer.email && copyEmailToClipboard(customer.email)}
                          className="highlight"
                          searchWords={[searchText]}
                          textToHighlight={customer.email}
                        />
                      </Tooltip>
                    </CellContent>
                  )}
                </StyledTd>
                {showDocs && doctypesDiscovery.map(doctype => (
                  <StyledTd $size={80} key={doctype.id}>
                    <DocCountCell $limitSize>
                      {!showHeader ? (
                        <Tooltip content={doctype.name} placement="top" withPortal>
                          <CellContent>
                            <Emoji emoji={doctype.emoji} />
                            {customer.docsCountByDoctype.find(docs => docs?.doctype.id === doctype.id)?.count ?? 0}
                          </CellContent>
                        </Tooltip>
                      ) : customer.docsCountByDoctype.find(docs => docs?.doctype.id === doctype.id)?.count ?? 0}
                    </DocCountCell>
                  </StyledTd>
                ))}
                {showProfiles && (
                  <StyledTd $size={120}>
                    <CustomerLinkedProfiles customer={customer} />
                  </StyledTd>
                )}
                <TdActions $size={actionsCellSize}>
                  {showActions && !isDefaultCustomer(customer) && (
                    <CustomerActionsMenu customer={customers.find(c => c.id === customer.id)} />
                  )}
                </TdActions>
              </StyledTr>
            ))}
            {isLoading && (
              <RowsSkeleton
                rows={5}
                cols={(
                  4 +
                  (showCompanies ? 1 : 0) +
                  doctypesDiscovery.length
                )}
              />
            )}
          </TBody>
        </StyledTable>
      </InfiniteScroll>
    </StyledShyScrollbar>
  );
};
