import { EmbedType } from '../types/iframe.types';

// eslint-disable-next-line no-useless-escape
const FIGMA_EMBED_REGEX = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;
const FIGMA_EMBED_IFRAME_REGEX = /^<iframe(.)+src="(https:\/\/www.figma.com\/embed\?(.)+)" allowfullscreen><\/iframe>$/;
const MIRO_EMBED_REGEX = /^https:\/\/miro.com\/app\/board\/(.*)=\/$/;
const MIRO_EMBED_IFRAME_REGEX = /^<iframe width="\d+" height="\d+" src="(https:\/\/miro.com\/app\/(board|live-embed)\/.+)" frameBorder="0" scrolling="no" allowFullScreen><\/iframe>$/;

const getEmbedabbleFigmaUrl = (url: string) => `https://www.figma.com/embed?embed_host=cycle-embed&url=${encodeURIComponent(url)}`;

type getFigmaOrMiroEmbedReturn = {
  isEmbeddable: boolean;
  url: string;
};

export const getFigmaOrMiroEmbed = (url:string): getFigmaOrMiroEmbedReturn => {
  if (url.includes(EmbedType.FIGMA) && !url.includes('iframe')) {
    return {
      isEmbeddable: FIGMA_EMBED_REGEX.test(url),
      url: getEmbedabbleFigmaUrl(url),
    };
  }

  if (url.includes(EmbedType.FIGMA) && url.includes('iframe')) {
    const [,,figmaUrl] = FIGMA_EMBED_IFRAME_REGEX.exec(url) || [];

    return {
      isEmbeddable: FIGMA_EMBED_IFRAME_REGEX.test(url),
      url: figmaUrl,
    };
  }

  if (url.includes(EmbedType.MIRO) && !url.includes('iframe')) {
    return {
      isEmbeddable: MIRO_EMBED_REGEX.test(url),
      url: url.replace('board', 'live-embed'),
    };
  }

  if (url.includes(EmbedType.MIRO) && url.includes('iframe')) {
    const [,miroUrl] = MIRO_EMBED_IFRAME_REGEX.exec(url) || [];

    return {
      isEmbeddable: MIRO_EMBED_IFRAME_REGEX.test(url),
      url: miroUrl,
    };
  }

  return {
    isEmbeddable: false,
    url,
  };
};
