import { Color as ColorApi } from '../../../graphql-codegen/generated';
import { colors, nuance } from './colorThemes/eclipse';

export const defaultUserColor = ColorApi.A;

// As the default theme — This should stay implicitely typed
const baseTheme = {
  colors: {
    ...colors,
  },
  nuances: {
    ...nuance,
  },
  userColors: {
    ...nuance[defaultUserColor],
    main: nuance[defaultUserColor].fg,
  },
};

export type BaseTheme = typeof baseTheme;

export default baseTheme;
