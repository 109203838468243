import { FeatureFlag } from '@cycle-app/utilities';
import { useFeature } from '@unrevealed/react';

export { FeatureFlag };

export const useFeatureFlag = (featureId: FeatureFlag) => {
  const flag = useFeature(featureId);

  return {
    hasError: flag.error,
    isEnabled: flag.enabled,
    isLoading: flag.loading,
  };
};
