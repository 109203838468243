import { InfiniteScroll } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import { useProducts } from 'src/hooks/api/useProducts';

import {
  Container,
  List,
  StyledProductItem,
} from './ProductsOptions.styles';

interface Props {
  onItemSelected: (productSlug: string | null) => void;
}

const ProductsOptions: FC<Props> = ({ onItemSelected }) => {
  const currentProduct = useProductBase();
  const {
    products, loading, hasNextPage, fetchNextPage,
  } = useProducts();

  const optionsValues = useMemo(() => [
    ...products.map(product => product.slug),
  ], [products]);

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues,
    onSelect: onItemSelected,
  });

  return (
    <Container {...listProps}>
      <List>
        <InfiniteScroll
          isLoading={loading}
          hasMoreData={hasNextPage}
          loadMore={fetchNextPage}
        >
          {products.map(product => (
            <StyledProductItem
              key={product.id}
              product={product}
              selected={product.slug === selected}
              isActive={product.id === currentProduct?.id}
              hideMembers
              {...itemProps(product.slug)}
            />
          ))}
        </InfiniteScroll>
      </List>
    </Container>
  );
};

export default ProductsOptions;
