import { CycleLogo, Emoji } from '@cycle-app/ui';

import { Form, FormContent } from './Form';
import { Me } from './Me';
import {
  Container, Header, Title, Body, Scrollable, Content,
} from './NewDoc.styles';
import { ProductCurrent } from './Product';
import { Settings } from './Settings/Settings';

export const NewDoc = () => {
  return (
    <Container>
      <Header>
        <CycleLogo animation="hover" size={20} />

        <Title>
          New
          <Emoji emoji="speech_balloon" />
          Feedback
        </Title>

        <ProductCurrent />

        <Me />

        <Settings />
      </Header>

      <Body>
        <Scrollable>
          <Content>
            <Form>
              <FormContent />
            </Form>
          </Content>
        </Scrollable>
      </Body>
    </Container>
  );
};
