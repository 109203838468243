import styled, { css } from 'styled-components';

export const Dropzone = styled.div<{ noBorderRadius?: boolean }>`
  background: ${p => p.theme.userColors.main};
  position: absolute;
  opacity: 0.9;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: ${p => (p.noBorderRadius ? 0 : '4px')};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: ${p => p.theme.colors.text.white};
`;

export const DropzoneInner = styled.div<{ horizontalLayout: boolean }>`
  text-align: center;
  > svg {
    margin-bottom: 4px;
    width: 24px;
    height: 24px;
  }

  ${p => p.horizontalLayout && css`
    display: flex;
    align-items: center;

    > svg {
      margin-bottom: 0;
      margin-right: 4px;
    }
  `};
`;
