import {
  BoldIcon,
  BulletListIcon,
  CaretIcon,
  ChecklistIcon,
  CodeIcon,
  DocumentIcon,
  EmbedIcon,
  FigmaIcon,
  FileIcon,
  GithubIcon,
  H1Icon,
  H2Icon,
  H3Icon,
  ImageIcon,
  ItalicIcon,
  LinearIcon,
  LinkIcon,
  LoomIcon,
  MinusIcon,
  MiroIcon,
  NotionIcon,
  NumberedListIcon,
  QuoteIcon,
  SmileyIcon,
  StrikeIcon,
  UnderlineIcon,
  UserIcon,
} from '@cycle-app/ui/icons';
import { Editor } from '@tiptap/core';
import { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

import { COMMANDS } from 'src/constants/editor.constants';
import { ShortcutEditor } from 'src/types/shortcuts.types';

export enum ActionId {
  AddEmoji = 'add-emoji',
  Bold = 'bold',
  BulletList = 'bullet-list',
  CheckList = 'check-list',
  Code = 'code',
  Divider = 'divider',
  Embed = 'embed',
  File = 'file',
  GithubIssue = 'github-issue',
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Image = 'image',
  Italic = 'italic',
  Linear = 'linear',
  Link = 'link',
  MentionDoc = 'mention-doc',
  MentionUser = 'mention-user',
  Notion = 'notion',
  NumberedList = 'ordered-list',
  Quote = 'quote',
  Strike = 'strike',
  TurnTextIntoDocMention = 'turn-text-into-doc-mention',
  TurnTextIntoDocMentionLastUsed = 'turn-text-into-doc-mention-last-used',
  TurnTextIntoGithubIssueMention = 'turn-text-into-github-issue-mention',
  TurnTextIntoLinearMention = 'turn-text-into-linear-mention',
  TurnTextIntoInsight = 'turn-text-into-insight',
  Underline = 'underline',
}

export interface Action {
  id: ActionId;
  label: string;
  labelAlt?: string;
  icon?: ReactNode;
  node?: string;
  nodeParams?: Record<string, string | number>;
  toggle?: (editor: Editor) => void;
  quickActionIcon?: ReactNode;
  shortcut?: ShortcutEditor;
  // Will use a <ButtonText />
  text?: ReactNode;
}

export const actions: Record<ActionId, Action> = {
  [ActionId.Heading1]: {
    id: ActionId.Heading1,
    label: 'Heading 1',
    icon: <H1Icon />,
    node: 'heading',
    nodeParams: {
      level: 1,
    },
    toggle: (editor) => editor.chain().focus().toggleHeading({ level: 1 }).run(),
  },
  [ActionId.Heading2]: {
    id: ActionId.Heading2,
    label: 'Heading 2',
    icon: <H2Icon />,
    node: 'heading',
    nodeParams: {
      level: 2,
    },
    toggle: (editor) => editor.chain().focus().toggleHeading({ level: 2 }).run(),
  },
  [ActionId.Heading3]: {
    id: ActionId.Heading3,
    label: 'Heading 3',
    icon: <H3Icon />,
    node: 'heading',
    nodeParams: {
      level: 3,
    },
    toggle: (editor) => editor.chain().focus().toggleHeading({ level: 3 }).run(),
  },
  [ActionId.Bold]: {
    id: ActionId.Bold,
    label: 'Bold',
    icon: <BoldIcon />,
    node: 'bold',
    toggle: (editor) => editor.chain().focus().toggleBold().run(),
  },
  [ActionId.Italic]: {
    id: ActionId.Italic,
    label: 'Italic',
    icon: <ItalicIcon />,
    node: 'italic',
    toggle: (editor) => editor.chain().focus().toggleItalic().run(),
  },
  [ActionId.Strike]: {
    id: ActionId.Strike,
    label: 'Strike',
    icon: <StrikeIcon />,
    node: 'strike',
    toggle: (editor) => editor.chain().focus().toggleStrike().run(),
  },
  [ActionId.Underline]: {
    id: ActionId.Underline,
    label: 'Underline',
    icon: <UnderlineIcon />,
    node: 'underline',
    toggle: (editor) => editor.chain().focus().toggleUnderline().run(),
  },

  [ActionId.CheckList]: {
    id: ActionId.CheckList,
    label: 'Check list',
    icon: <ChecklistIcon />,
    toggle: (editor) => editor.chain().focus().toggleTaskList().run(),
  },
  [ActionId.BulletList]: {
    id: ActionId.BulletList,
    label: 'Bullet list',
    icon: <BulletListIcon />,
    toggle: (editor) => editor.chain().focus().toggleBulletList().run(),
  },
  [ActionId.NumberedList]: {
    id: ActionId.NumberedList,
    label: 'Numbered list',
    icon: <NumberedListIcon />,
    toggle: (editor) => editor.chain().focus().toggleOrderedList().run(),
  },
  [ActionId.Embed]: {
    id: ActionId.Embed,
    label: 'Embed',
    labelAlt: 'Embed',
    icon: <EmbedIcon />,
    quickActionIcon: (
      <>
        <FigmaIcon />
        <MiroIcon />
        <LoomIcon />
      </>
    ),
  },
  [ActionId.Link]: {
    id: ActionId.Link,
    label: 'Link',
    node: 'link',
    icon: <LinkIcon />,
  },
  [ActionId.Image]: {
    id: ActionId.Image,
    label: 'Image',
    icon: <ImageIcon size={14} />,
  },
  [ActionId.Quote]: {
    id: ActionId.Quote,
    label: 'Quote',
    icon: <QuoteIcon />,
    toggle: (editor) => editor.chain().focus().toggleBlockquote().run(),
  },
  [ActionId.Code]: {
    id: ActionId.Code,
    label: 'Code',
    icon: <CodeIcon />,
    node: 'codeBlock',
    toggle: (editor) => editor.chain().focus().toggleCodeBlock().run(),
  },
  [ActionId.File]: {
    id: ActionId.File,
    label: 'Upload file',
    labelAlt: 'File',
    icon: <FileIcon />,
  },
  [ActionId.MentionUser]: {
    id: ActionId.MentionUser,
    label: 'Mention user',
    labelAlt: 'Mention a user',
    icon: <UserIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.USER_MENTION).run(),
  },
  [ActionId.MentionDoc]: {
    id: ActionId.MentionDoc,
    label: 'Mention doc',
    labelAlt: 'Mention a doc',
    icon: <DocumentIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.DOC_MENTION).run(),
  },
  [ActionId.TurnTextIntoDocMention]: {
    id: ActionId.TurnTextIntoDocMention,
    label: 'Turn into doc mention',
    labelAlt: 'Turn into doc mentions',
    icon: <CaretIcon />,
  },
  [ActionId.TurnTextIntoDocMentionLastUsed]: {
    id: ActionId.TurnTextIntoDocMentionLastUsed,
    label: 'Turn into',
    labelAlt: 'Turn into',
  },
  [ActionId.TurnTextIntoGithubIssueMention]: {
    id: ActionId.TurnTextIntoGithubIssueMention,
    label: 'Turn into GitHub issues',
    labelAlt: 'Turn into GitHub issues',
    icon: <GithubIcon />,
    shortcut: ShortcutEditor.TurnGithubIssue,
  },
  [ActionId.TurnTextIntoLinearMention]: {
    id: ActionId.TurnTextIntoLinearMention,
    label: 'Turn into Linear issue',
    labelAlt: 'Turn into Linear issue',
    icon: <LinearIcon />,
    shortcut: ShortcutEditor.TurnLinearIssue,
  },
  [ActionId.TurnTextIntoInsight]: {
    id: ActionId.TurnTextIntoInsight,
    label: 'Create Insight',
    text: 'Insight',
    shortcut: ShortcutEditor.TurnIntoInsight,
    toggle: (editor) => editor.chain().setHighlightMark({ id: uuid() }).run(),
  },
  [ActionId.AddEmoji]: {
    id: ActionId.AddEmoji,
    label: 'Emoji',
    labelAlt: 'Emoji',
    icon: <SmileyIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.EMOJI).run(),
  },
  [ActionId.GithubIssue]: {
    id: ActionId.GithubIssue,
    label: 'GitHub issues',
    icon: <GithubIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.GITHUB_ISSUE).run(),
  },
  [ActionId.Linear]: {
    id: ActionId.Linear,
    label: 'Linear issues',
    icon: <LinearIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.LINEAR).run(),
  },
  [ActionId.Notion]: {
    id: ActionId.Notion,
    label: 'Notion',
    icon: <NotionIcon />,
    toggle: (editor) => editor.chain().focus().insertContent(COMMANDS.NOTION).run(),
  },
  [ActionId.Divider]: {
    id: ActionId.Divider,
    label: 'Divider',
    icon: <MinusIcon />,
    toggle: (editor) => editor.chain().focus().setHorizontalRule().run(),
  },
};

export type ActionCategory = 'insight' | 'title' | 'text' | 'elements' | 'lists' | 'mentions' | 'integrations';

interface ActionCategoryData {
  category: ActionCategory;
  actions: Action[];
}

export const slashActions: Array<ActionCategoryData> = [
  {
    category: 'text',
    actions: [
      actions[ActionId.Heading1],
      actions[ActionId.Heading2],
      actions[ActionId.Heading3],
    ],
  },
  {
    category: 'elements',
    actions: [
      actions[ActionId.CheckList],
      actions[ActionId.BulletList],
      actions[ActionId.NumberedList],
      actions[ActionId.Embed],
      actions[ActionId.Image],
      actions[ActionId.AddEmoji],
      actions[ActionId.Quote],
      actions[ActionId.Code],
      actions[ActionId.File],
      actions[ActionId.Divider],
    ],
  },
  {
    category: 'mentions',
    actions: [
      actions[ActionId.MentionUser],
      actions[ActionId.MentionDoc],
    ],
  },
  {
    category: 'integrations',
    actions: [
      actions[ActionId.GithubIssue],
      actions[ActionId.Linear],
      actions[ActionId.Notion],
    ],
  },
];

export const toolbarActions: Array<ActionCategoryData> = [
  {
    category: 'insight',
    actions: [
      actions[ActionId.TurnTextIntoInsight],
    ],
  },
  {
    category: 'title',
    actions: [
      actions[ActionId.Heading1],
      actions[ActionId.Heading2],
      actions[ActionId.Heading3],
    ],
  },
  {
    category: 'text',
    actions: [
      actions[ActionId.Bold],
      actions[ActionId.Italic],
      actions[ActionId.Underline],
      actions[ActionId.Strike],
    ],
  },
  {
    category: 'elements',
    actions: [
      actions[ActionId.Link],
      actions[ActionId.Quote],
      actions[ActionId.Code],
    ],
  },
  {
    category: 'lists',
    actions: [
      actions[ActionId.BulletList],
      actions[ActionId.NumberedList],
      actions[ActionId.CheckList],
    ],
  },
  {
    category: 'integrations',
    actions: [
      actions[ActionId.TurnTextIntoDocMentionLastUsed],
      actions[ActionId.TurnTextIntoGithubIssueMention],
      actions[ActionId.TurnTextIntoLinearMention],
      actions[ActionId.TurnTextIntoDocMention],
    ],
  },

];

export const presetComplete = Object.values(ActionId);

export const presetFloating = presetComplete.filter((action) => action !== ActionId.Embed);

export const templateDisabledActions = [
  ActionId.MentionDoc,
  ActionId.MentionUser,
  ActionId.TurnTextIntoDocMention,
  ActionId.TurnTextIntoDocMentionLastUsed,
  ActionId.TurnTextIntoInsight,
  ActionId.TurnTextIntoGithubIssueMention,
  ActionId.TurnTextIntoLinearMention,
  ActionId.Notion,
  ActionId.Linear,
  ActionId.GithubIssue,
  ActionId.File,
];
