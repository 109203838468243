import { CycleLogo } from '@cycle-app/ui';
import { FigmaIcon, MiroIcon, PitchIcon, LoomIcon } from '@cycle-app/ui/icons';
import { useMeasure } from '@cycle-app/utilities/src/hooks/useMeasure';
import { useMemo, FC, useState } from 'react';
import ReactFlow, { ReactFlowProps, Position } from 'react-flow-renderer';

import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';

import { CollabEdge } from './Edges/CollabEdge';
import { SourceEdge } from './Edges/SourceEdge';
import { IntegrationsSection, Offsets } from './IntegrationsSection';
import {
  FlowContainer,
  Handle,
} from './SettingsIntegrations.styles';

const LOGO_SIZE = 90;
const DEFAULT_FLOW_HEIGHT = 500;

export const SettingsIntegrations: FC = () => {
  const {
    isLoading, isCalled, sourcesByStatus, collabByStatus,
  } = useProductIntegrations();

  const loading = !isCalled || isLoading;

  const {
    ref: flowRef,
    rect: flowRect,
  } = useMeasure<HTMLDivElement>();
  const flowWidth = flowRect?.width ?? 0;

  const [sourceOffsets, setSourceOffsets] = useState<Offsets>();
  const [collabOffsets, setCollabOffsets] = useState<Offsets>();

  const flowHeight = Math.max(
    sourceOffsets?.height ?? 0,
    collabOffsets?.height ?? 0,
    DEFAULT_FLOW_HEIGHT,
  );

  const elements = useMemo<ReactFlowProps['elements']>(() => {
    if (!flowWidth) return [];

    const nodes = [
      {
        id: 'sources',
        data: {
          label: (
            <IntegrationsSection
              title="Sources"
              installed={sourcesByStatus.installed}
              uninstalled={sourcesByStatus.uninstalled}
              loading={loading}
              onRender={setSourceOffsets}
            />),
        },
        position: {
          x: 5,
          y: 0,
        },
      },
      {
        id: 'logo',
        data: { label: <CycleLogo animation="loop" size={LOGO_SIZE} color="blue" /> },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        position: {
          x: flowWidth / 2 - LOGO_SIZE / 2,
          y: Math.max(sourceOffsets?.center ?? 0, collabOffsets?.center ?? 0) - LOGO_SIZE / 2,
        },
      },
      {
        id: 'collab',
        data: {
          label: (
            <IntegrationsSection
              title="Collaboration"
              preInstalled={{
                Figma: <FigmaIcon />,
                Miro: <MiroIcon />,
                Pitch: <PitchIcon />,
                Loom: <LoomIcon />,
              }}
              installed={collabByStatus.installed}
              uninstalled={collabByStatus.uninstalled}
              loading={loading}
              onRender={setCollabOffsets}
            />
          ),
        },
        targetPosition: Position.Left,
        position: {
          x: flowWidth - (collabOffsets?.width ?? 0) - 5,
          y: 0,
        },
      },
    ];

    const sourceNodes = sourceOffsets?.handles.map((y, i) => ({
      id: `source${i}`,
      data: {
        label: <Handle />,
      },
      sourcePosition: Position.Right,
      position: {
        x: sourceOffsets.width,
        y,
      },
    })) ?? [];

    const collabNodes = collabOffsets?.handles.map((y, i) => ({
      id: `collab${i}`,
      data: {
        label: <Handle />,
      },
      targetPosition: Position.Left,
      position: {
        x: flowWidth - collabOffsets.width,
        y,
      },
    })) ?? [];

    const sourceEdges = sourceOffsets?.handles.map((_, i) => ({
      id: `source${i}-logo`,
      source: `source${i}`,
      target: 'logo',
      type: 'source-edge',
    })) ?? [];

    const collabEdges = collabOffsets?.handles.map((_, i) => ({
      id: `collab${i}-logo`,
      source: 'logo',
      target: `collab${i}`,
      type: 'collab-edge',
    })) ?? [];

    return [...nodes, ...sourceNodes, ...collabNodes, ...sourceEdges, ...collabEdges].map(element => ({
      ...element,
      className: 'nodrag',
    }));
  }, [flowWidth, sourcesByStatus, loading, sourceOffsets, collabOffsets, collabByStatus]);

  return (
    <>
      <SettingsViewHeader title="Integrations" description="Collect, unify, and connect all product information across apps" />
      <FlowContainer
        ref={flowRef}
        style={{ height: flowHeight }}
      >
        <ReactFlow
          elements={elements}
          zoomOnScroll={false}
          zoomOnPinch={false}
          zoomOnDoubleClick={false}
          paneMoveable={false}
          edgeTypes={{
            'source-edge': SourceEdge,
            'collab-edge': CollabEdge,
          }}
        />
      </FlowContainer>
    </>
  );
};
