import styled, { css } from 'styled-components';

import { Button } from '../../Buttons/Button/Button';
import { Input } from '../../Inputs';
import { ShyScrollbarCss } from '../../ShyScrollbar/ShyScrollbar.styles';
import { Label } from '../SelectLine/SelectLine.styles';

export const titleLineHeight = 24;
export const inputHeight = 32;
export const containerPadding = 8;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const List = styled.div<{ listMaxHeight: string }>`
  ${ShyScrollbarCss};

  margin-left: -8px;
  margin-right: -8px;
  max-height: ${p => p.listMaxHeight};
`;

export const Footer = styled.div`
  border-top: 1px solid ${p => p.theme.colors.border.primary};
  padding-top: 8px;
  display: flex;
  gap: 8px;
`;

export const Separator = styled.div`
  margin: 4px 0;
  height: 1px;
  width: 100%;
  background: ${p => p.theme.colors.background.tertiary};
`;

export const FooterButton = styled(Button).attrs({ variant: 'light' })`
  flex: 1;
  width: 100%;
`;

export const InputStyled = styled(Input)`
  input {
    height: ${inputHeight}px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 6px;
  }
`;

export const Container = styled.div<{ noPointerEvents: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: ${containerPadding}px;
  min-width: 136px;

  ${p => p.noPointerEvents && css`
    pointer-events: none;
  `};
`;

export const CreateOptionLabel = styled.span`
  background-color: ${p => p.theme.userColors.bg};
  border-radius: 4px;
  padding: 4px;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${p => p.theme.colors.dropdown.bg};
  ${Label} {
    font-weight: 500;
  }
`;
