import { useHover } from '@cycle-app/utilities';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { Toaster, ToasterProps } from '../Toaster';
import { Container } from './Toasters.style';

const TOASTER_MIN_HEIGHT = 48;
const TOASTERS_GAP = 1;

export type ToastersProps = {
  toasters: ToasterProps[];
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
};

export const Toasters: FC<ToastersProps> = ({
  toasters, onMouseEnter, onMouseLeave,
}) => {
  const [isHover, ref] = useHover<HTMLDivElement>({
    onMouseEnter,
    onMouseLeave,
  });

  return (
    <Container ref={ref}>
      <AnimatePresence>
        {toasters.map((props, index) => {
          const i = toasters.length - index - 1;
          return (
            <motion.div
              key={props.id}
              className="toaster"
              style={{
                position: 'relative',
                zIndex: index,
                paddingTop: 8,
                boxSizing: 'content-box',
              }}
              initial={{
                height: 'auto',
              }}
              animate={{
                height: isHover || i === 0 ? 'auto' : TOASTER_MIN_HEIGHT + TOASTERS_GAP,
                translateY: isHover ? 0 : i * (TOASTER_MIN_HEIGHT - TOASTERS_GAP),
                scale: isHover ? 1 : Math.max(0, 1 - 0.03 * i),
              }}
              transition={{
                duration: 0.15,
              }}
            >
              <Toaster {...props} />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Container>
  );
};
