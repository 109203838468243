export const SENTRY_IGNORED_ERRORS: Array<string | RegExp> = [
  // network error
  'Failed to fetch',
  // benign navigator error with no consequence
  'ResizeObserver loop limit exceeded',

  // Prosemirror errors
  'Cannot read properties of null (reading \'nodeType\')',
  'Cannot read properties of null (reading \'matchesNode\')',

  // Security errors
  /Blocked a frame with origin/i,
];
