import { FC } from 'react';

import { Shortcut } from './Shortcut';
import { ShortcutsContainer } from './Shortcut.styles';

export interface ShortcutsProps {
  className?: string;
  shortcuts: ShortcutData[];
  onActive?: boolean;
  colors?: 'oposite' | 'dark';
}

interface ShortcutData {
  label?: string;
  keys: string[];
}

export const Shortcuts: FC<ShortcutsProps> = ({
  className,
  shortcuts,
  colors,
  onActive = false,
}) => (
  <ShortcutsContainer className={className}>
    {shortcuts.map(shortcutData => (
      <Shortcut
        key={shortcutData.label}
        {...shortcutData}
        colors={colors}
        onActive={onActive}
      />
    ))}
  </ShortcutsContainer>
);
