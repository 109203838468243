import { Rect } from '@cycle-app/utilities';
import Tippy from '@tippyjs/react/headless';
import { AnimatePresence, motion, MotionProps, Variants } from 'framer-motion';
import {
  useEffect,
  CSSProperties, ComponentProps, FC, ReactNode, useState, isValidElement,
} from 'react';
import { Placement, GetReferenceClientRect } from 'tippy.js';

import {
  motionProps as defaultMotionProps,
  variantsContent as defaultMotionVariants,
} from './Popover.motion';

export interface Props {
  controlled?: boolean;
  content: ReactNode;
  disabled?: boolean;
  placement?: Placement;
  popperOptions?: ComponentProps<typeof Tippy>['popperOptions'];
  animation?: boolean;
  interactive?: boolean;
  withPortal?: boolean;
  withWrapper?: boolean;
  onMount?: () => void;
  onHide?: () => void;
  visible?: boolean;
  hide?: () => void;
  offset?: [number, number];
  overridePosition?: Rect;
  enableOnMobile?: boolean;
  zIndex?: number;
  motionProps?: MotionProps;
  motionVariants?: Variants;
  style?: CSSProperties;
}

export const Popover: FC<Props> = ({
  interactive,
  controlled = false,
  content,
  children,
  placement = 'bottom-end',
  popperOptions,
  disabled = false,
  onMount: onMountProps,
  onHide: onHideProps,
  animation = false,
  enableOnMobile = true,
  withPortal = false,
  withWrapper = true,
  visible,
  hide,
  offset = [0, 8],
  overridePosition,
  zIndex,
  motionProps,
  motionVariants,
  style,
}) => {
  const [renderContent, setRenderContent] = useState(false);
  // Will be used if !withWrapper (+ add a fallback to render a valid element).
  const validChildren = isValidElement(children)
    ? children
    : <div data-popover style={style}>{children}</div>;

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        hide?.();
      }
    };
    if (renderContent && controlled) {
      document.addEventListener('keyup', onKeyUp);
    }
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [renderContent, controlled, hide]);

  return (
    <Tippy
      zIndex={zIndex}
      onMount={onMount}
      onHide={onHide}
      disabled={disabled}
      visible={visible}
      placement={placement}
      popperOptions={popperOptions}
      animation={animation}
      interactive={interactive}
      {...controlled && {
        onClickOutside: hide,
      }}
      appendTo={controlled || withPortal ? document.body : 'parent'}
      offset={offset}
      touch={enableOnMobile}
      getReferenceClientRect={overridePosition ? (() => overridePosition) as GetReferenceClientRect : null}
      render={(attrs) => (
        <AnimatePresence>
          {renderContent ? (
            <motion.div
              onClick={e => e.stopPropagation()}
              onMouseDown={e => e.stopPropagation()}
              tabIndex={-1}
              variants={motionVariants ?? defaultMotionVariants({
                placement,
                delay: controlled ? 0 : 0.1,
              })}
              {...motionProps ?? defaultMotionProps}
              {...attrs}
            >
              {content}
            </motion.div>
          ) : null}
        </AnimatePresence>
      )}
    >
      {withWrapper ? (
        <div data-popover style={style}>
          {children}
        </div>
      ) : validChildren}
    </Tippy>
  );

  function onMount(): void {
    setRenderContent(true);
    onMountProps?.();
  }

  function onHide(): void {
    onHideProps?.();
    setRenderContent(false);
  }
};
