import { typo, Avatar } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    ${typo.caption400};
    color: ${p => p.theme.colors.text.secondary};
    white-space: nowrap;
  }
`;

export const Avatars = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Description = styled.span`
  ${mobile} {
    display: none;
  }
`;

export const StyledAvatar = styled(Avatar)`
  --border-size: 0px;
  --shadow-size: 0px;
  --padding: 0px;
  --shadow-size-hover: 0px;
  ${p => p.userColor && css`--bg-color: ${transparentize(0.85, p.theme.nuances[p.userColor].fg)};`}
  border-radius: 50%;
  img {
    transform: scale(0.8);
  }
`;
