import { ActionButton, Tag, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

interface StyledActionButtonProps {
  hide: boolean;
}
export const StyledActionButton = styled(ActionButton) <StyledActionButtonProps>`
  svg {
    width: 16px;
    height: 16px;
  }

  ${p => p.hide && css`
    opacity: 0;
    pointer-events: none;
    &:hover {
      opacity: 0;
    }
  `};
`;

export const TagStyled = styled(Tag)`
  ${typo.body500}
  background-color: ${p => p.theme.colors.background.tertiary};
  border: none;
  display: inline-flex;
  height: auto;
`;
