import { TypedDocumentNode, useApolloClient } from '@apollo/client';
import { DeepPartial } from '@cycle-app/utilities';
import { mergeDeepWith } from 'ramda';

interface UseWriteQueryParams {
  query: TypedDocumentNode;
}

type MergingStrategy = 'start' | 'end' | 'replace';

export const useMergeQuery = <R extends Record<string, unknown>, V extends Record<string, unknown>>({ query }: UseWriteQueryParams) => {
  const { cache } = useApolloClient();

  return (variables: V, replacing: DeepPartial<R>, mergingStrategy: MergingStrategy = 'start') => {
    const existing = cache.readQuery<R, V>({
      query,
      variables,
    });

    if (!existing) return;

    const mergingFunction = getMergingFunction(mergingStrategy);

    const data = mergeDeepWith(mergingFunction, replacing, existing);

    cache.writeQuery({
      query,
      variables,
      data,
    });
  };

  function getMergingFunction(strategy: MergingStrategy): (l1: any[], l2: any[]) => any[] {
    if (strategy === 'replace') {
      return (l1: any[]) => l1;
    }
    return strategy === 'start'
      ? (l1: any[], l2: any[]) => [...l1, ...l2]
      : (l1: any[], l2: any[]) => [...l2, ...l1];
  }
};
