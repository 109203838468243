import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  getActiveProductTourEl,
  setActiveProductTour,
  useActiveProductTour,
  useFinishTour,
} from 'src/reactives/productTours.reactive';
import { TourName, TourStep } from 'src/types/productTour.types';

export const useSidebarProductTour = () => {
  const tour = useActiveProductTour();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);
  const finishTour = useFinishTour();

  const isActive = isEnabled && tour?.name === TourName.FIRST_TOUR;
  const shouldHighlight = isActive && tour.step === TourStep.StartGamification;

  const terminateTour = () => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.StartGamification
    ) return;

    finishTour(TourName.FIRST_TOUR);
  };

  const setLearningCenterButtonEl = (el?: HTMLElement | null) => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.StartGamification ||
      !el ||
      getActiveProductTourEl() === el
    ) return;

    setActiveProductTour({ el });
  };

  const showBluePoint = isActive && tour.step === TourStep.StartGamification;

  return {
    isActive,
    setLearningCenterButtonEl,
    shouldHighlight,
    showBluePoint,
    terminateTour,
  };
};
