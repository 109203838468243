import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const WhatsNewIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="-1 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M9.66898 11.9687L8.46898 15.225C8.43346 15.3207 8.36951 15.4032 8.28571 15.4615C8.20192 15.5198 8.10229 15.551 8.00023 15.551C7.89816 15.551 7.79854 15.5198 7.71474 15.4615C7.63095 15.4032 7.56699 15.3207 7.53148 15.225L6.33148 11.9687C6.30615 11.9 6.26621 11.8376 6.21442 11.7858C6.16262 11.734 6.10021 11.6941 6.03148 11.6687L2.77523 10.4687C2.67954 10.4332 2.59701 10.3693 2.53873 10.2855C2.48046 10.2017 2.44922 10.1021 2.44922 9.99998C2.44922 9.89791 2.48046 9.79829 2.53873 9.7145C2.59701 9.6307 2.67954 9.56675 2.77523 9.53123L6.03148 8.33123C6.10021 8.30591 6.16262 8.26597 6.21442 8.21418C6.26621 8.16238 6.30615 8.09996 6.33148 8.03123L7.53148 4.77498C7.56699 4.67929 7.63095 4.59677 7.71474 4.53849C7.79854 4.48021 7.89816 4.44897 8.00023 4.44897C8.10229 4.44897 8.20192 4.48021 8.28571 4.53849C8.36951 4.59677 8.43346 4.67929 8.46898 4.77498L9.66898 8.03123C9.6943 8.09996 9.73424 8.16238 9.78603 8.21418C9.83783 8.26597 9.90025 8.30591 9.96898 8.33123L13.2252 9.53123C13.3209 9.56675 13.4034 9.6307 13.4617 9.7145C13.52 9.79829 13.5512 9.89791 13.5512 9.99998C13.5512 10.1021 13.52 10.2017 13.4617 10.2855C13.4034 10.3693 13.3209 10.4332 13.2252 10.4687L9.96898 11.6687C9.90025 11.6941 9.83783 11.734 9.78603 11.7858C9.73424 11.8376 9.6943 11.9 9.66898 11.9687V11.9687Z" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 2V5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 3.5H10.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 5.5V7.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 6.5H14" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);
