import { wrappingInputRule } from '@tiptap/core';
import TaskItemTipTap from '@tiptap/extension-task-item';

export const inputRegex = /^\s*(\[([ |x])\])\s$/;
export const inputRegexEmpty = /^\s*(\[\])\s$/;

const TaskItem = TaskItemTipTap.extend({
  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: match => ({
          checked: match[match.length - 1] === 'x',
        }),
      }),
      wrappingInputRule({
        find: inputRegexEmpty,
        type: this.type,
        getAttributes: () => ({
          checked: false,
        }),
      }),
    ];
  },
});

export default TaskItem;
