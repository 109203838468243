import { Editor } from '@tiptap/react';

// Get link hack
// Stolen from https://github.com/ueberdosis/tiptap/issues/490#issuecomment-620202725
export const getCurrentLink = (editor: Editor) => {
  const { state } = editor;
  const {
    from, to,
  } = state.selection;

  // Let any for the moment, its hack anyway
  let marks: Array<any> = [];
  state.doc.nodesBetween(from, to, (node) => {
    marks = [...marks, ...node.marks];
  });
  const mark = marks.find((markItem) => markItem.type.name === 'link');
  return mark?.attrs?.href ?? '';
};
