import { typo, transitionCss, ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${p => p.theme.colors.text.primary};
  background-color: ${p => p.theme.colors.popup.bg};
  ${transitionCss(['color', 'background-color'])}
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 32px;
  border-bottom: 1px solid ${p => p.theme.colors.popup.separator};
  font-weight: 500;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h1`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.headerLight}
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const Scrollable = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  ${ShyScrollbarCss}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 544px;
  height: fit-content;
  padding: 16px 22px;
`;
