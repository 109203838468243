import { FC, memo } from 'react';

import LinkDraggable from 'src/components/LinkDraggable/LinkDraggable';
import { useDocContext } from 'src/contexts/docContext';
import { useLocation } from 'src/hooks';
import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { useIsSelectionLocked } from 'src/reactives/selection.reactive';

type Props = {
  groupId?: string | null;
  docUrl?: string;
  isDisabled: boolean;
};

export const DocItemLink: FC<Props> = memo(({
  isDisabled, ...props
}) => {
  const isCreating = useDocContext(ctx => ctx._creating);
  return isDisabled || !!isCreating
    ? <>{props.children}</>
    : <DocItemLinkClickable {...props} />;
});

export const DocItemLinkClickable: FC<Omit<Props, 'isDisabled'>> = ({
  docUrl, groupId, children,
}) => {
  const docId = useDocContext(ctx => ctx.id);
  const location = useLocation();
  const isLocked = useIsSelectionLocked();
  const { toggleSelectDoc: toggleSelect } = useDocsSelection();

  return (
    <LinkDraggable
      to={{
        pathname: docUrl,
        state: {
          groupId,
          fromStarredBoard: location.state?.fromStarredBoard,
        },
      }}
      onClick={e => {
        if (!isLocked && !e.shiftKey) return;
        e.preventDefault();
        toggleSelect?.(docId);
      }}
    >
      {children}
    </LinkDraggable>
  );
};
