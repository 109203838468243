import { Tooltip } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { FC, useState } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import ProductsMenu from 'src/components/ProductsMenu/ProductsMenu';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';

import {
  Item,
  PageName,
  Logo,
  Workspace,
  Caret,
  Wrapper,
} from '../SidebarHeader.styles';

const SidebarHeaderProduct: FC = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const currentProduct = useProductBase();
  const collapsed = useSidebarCollapsed();

  return (
    <Wrapper>
      <DropdownLayer
        content={(
          <ProductsMenu hide={hideDropdown} />
      )}
        visible={isDropdownVisible}
        hide={hideDropdown}
      >
        <Tooltip
          content={currentProduct?.name}
          placement="right"
          disabled={!collapsed}
          withPortal
        >
          <Item
            className={isDropdownVisible ? 'force-focus' : ''}
          // @todo Find way to not re-check dropdownDisabled and make Item no-clickable if disabled === true
            onClick={toggleDropdown}
            $collapsed={collapsed}
          >
            <Workspace>
              <Logo bgSrc={currentProduct?.logo?.url} />

              <PageName>
                <h1>{currentProduct?.name}</h1>
              </PageName>
            </Workspace>
            <Caret>
              <CaretIcon direction={isDropdownVisible ? 'top' : 'bottom'} />
            </Caret>
          </Item>
        </Tooltip>
      </DropdownLayer>
    </Wrapper>
  );

  function hideDropdown() {
    setIsDropdownVisible(false);
  }

  function toggleDropdown() {
    setIsDropdownVisible(!isDropdownVisible);
  }
};

export default SidebarHeaderProduct;
