import { FlashIcon } from '@cycle-app/ui/icons';

import { Progress } from '../Progress/Progress';
import { Container, Title, UpgradeButton, Text, TextSecondary } from './FreePlan.style';

export type FreePlanProps = {
  maxDocs: number;
  nbDocs?: number;
  onClickUpgrade?: VoidFunction;
  isLoadingStripePortal?: boolean;
};

export const FreePlan = ({
  maxDocs,
  nbDocs = 0,
  onClickUpgrade,
  isLoadingStripePortal = false,
}: FreePlanProps) => {
  const isExceeded = nbDocs >= 50;
  const message = isExceeded
    ? `${nbDocs}/${maxDocs} Limit exceeded`
    : `${nbDocs} of ${maxDocs} created`;

  return (
    <Container warning={isExceeded}>
      <header>
        <Title>Starter plan</Title>

        {onClickUpgrade && (
          <UpgradeButton
            isLoading={isLoadingStripePortal}
            warning={isExceeded}
            onClick={onClickUpgrade}
            size="M"
          >
            <FlashIcon />
            Upgrade
          </UpgradeButton>
        )}
      </header>

      {nbDocs != null && (
        <>
          <section>
            <Text>Monthly docs</Text>
            <Progress
              type="bar"
              useUserColor
              percentage={(nbDocs * 100) / maxDocs}
            />
          </section>

          <TextSecondary warning={isExceeded}>
            {message}
          </TextSecondary>
        </>
      )}
    </Container>
  );
};
