import { OnboardingIntegrationCard } from '@cycle-app/ui';
import { FigmaIcon, MiroIcon, LoomIcon, PitchIcon } from '@cycle-app/ui/icons';

import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { integrationsData } from 'src/utils/integrations.utils';

import { ListsContainer, List, Title, Footer } from './StepIntegrations.styles';
import { useIntegrationsByType, IntegrationItem } from './useIntegrationsByType';

export const IntegrationsLists = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const {
    sources, collaboration, isLoading,
  } = useIntegrationsByType();

  const install = useInstallIntegration();

  return (
    <ListsContainer>
      {renderList('Sources', sources)}
      {renderList('Collaboration', collaboration)}

      <Footer>
        <FigmaIcon />
        <MiroIcon />
        <PitchIcon />
        <LoomIcon />
        <span>Installed by default</span>
      </Footer>
    </ListsContainer>
  );

  function renderList(title: string, items: typeof sources) {
    return (
      <div>
        <Title>{title}</Title>
        <List>
          {items.map(renderItem)}
        </List>
      </div>
    );
  }

  function renderItem(item: IntegrationItem) {
    const data = integrationsData[item.type];
    const isInstalled = !!item.integration?.provider;
    // eslint-disable-next-line no-nested-ternary
    const status = isLoading ? 'loading' : isInstalled ? 'installed' : 'uninstalled';
    return (
      <OnboardingIntegrationCard
        key={item.type}
        title={data.label}
        description={data.description}
        logo={data.icon}
        status={status}
        {...!isInstalled && {
          onClick: () => install(item.integration),
        }}
        isReadOnly={isReadOnly}
      />
    );
  }
};
