import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { setAuth } from 'src/reactives/auth.reactive';

export const useAuthFromUrl = () => {
  const location = useLocation();
  const tokenFromUrl = useMemo(
    () => new URLSearchParams(location.search).get('token'),
    [location.search],
  );
  const meIdFromUrl = useMemo(
    () => new URLSearchParams(location.search).get('userId'),
    [location.search],
  );

  useEffect(() => {
    if (tokenFromUrl && meIdFromUrl) {
      setAuth({
        token: tokenFromUrl,
        userId: meIdFromUrl,
      });
    }
  }, [meIdFromUrl, tokenFromUrl]);

  return {
    location,
    meIdFromUrl,
    tokenFromUrl,
  };
};
