import {
  CustomerFragment,
  namedOperations,
  RemoveCustomerDocument,
  RemoveCustomerMutationVariables,
} from '@cycle-app/graphql-codegen';

import { useErrorToaster } from 'src/hooks/useErrorToaster';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { NormalizedCustomerConnection } from 'src/types/customers.types';

import { useProduct } from '../../useProduct';

export const useCustomerRemove = (currentCustomer: CustomerFragment) => {
  const { product } = useProduct();
  const { add: addErrorToaster } = useErrorToaster();
  const [changeMutation, { loading }] = useSafeMutation(RemoveCustomerDocument, {
    onError: () => {
      addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' });
    },
    refetchQueries: [namedOperations.Query.boardWithConfig],
    update(cache, { data }) {
      if (!data?.removeCustomer || !product) return;

      const idToRemove = data.removeCustomer.id;

      cache.modify({
        id: cache.identify(product),
        fields: {
          customerCount: (count) => Math.max(0, count - 1),
          customers: (customers: NormalizedCustomerConnection, { readField }) => ({
            ...customers,
            edges: customers.edges.filter(({ node: nodeRef }) => idToRemove !== readField('id', nodeRef)),
          }),
        },
      });

      if (currentCustomer.company) {
        cache.modify({
          id: cache.identify(currentCustomer.company),
          fields: {
            countCustomers: (count) => Math.max(0, count - 1),
            customers: (customers: NormalizedCustomerConnection, { readField }) => ({
              ...customers,
              edges: customers.edges.filter(({ node: nodeRef }) => idToRemove !== readField('id', nodeRef)),
            }),
          },
        });
      }
      // We now have customers inside board filters, we need to evict.
      const normalizedId = cache.identify({
        id: data.removeCustomer.id,
        __typename: data.removeCustomer.__typename,
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const remove = ({ customerId }: RemoveCustomerMutationVariables) => {
    if (!customerId) throw Error('Data are missing');

    return changeMutation({
      variables: {
        customerId,
      },
    });
  };

  return {
    remove,
    isLoading: loading,
  };
};
