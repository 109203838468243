import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { caption400, caption500 } from '../../theme/typo';
import { boxShadowZ1 } from '../../utils/styles.util';
import { Label, Key, Then } from '../Shortcut/Shortcut.styles';

export const Container = styled(motion.div)<{ $width?: number }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.tooltip.bg};
  padding: 4px 8px;
  border-radius: 6px;
  max-width: ${p => p.$width ?? 300}px;
  white-space: pre-wrap;
  ${boxShadowZ1}

  ${Key} {
    background: ${p => p.theme.colors.tooltip.shortcut.bg};
    color: ${p => p.theme.colors.tooltip.shortcut.text};
  }
`;

export const Title = styled.div<{ showEllipsis?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  &, ${Label}, ${Then} {
    ${caption500};
    color: ${p => p.theme.colors.tooltip.color};
  }

  ${p => p.showEllipsis && css`
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &, ${Label}, ${Then} {
    ${caption400};
    color: ${p => p.theme.colors.tooltip.textAlt};
  }
`;
