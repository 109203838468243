import { ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  padding: 24px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  ${ShyScrollbarCss}
  padding-bottom: 20vh;
`;
