import { MotionProps } from 'framer-motion';
import { FC } from 'react';

import { CycleLogo } from '../CycleLogo/CycleLogo';
import { Emoji } from '../Emoji/Emoji';
import {
  Container, Label, Input, Header, Title, StyledDownIcon, InputValue, Skeleton,
} from './MockChrome.styles';

interface Props extends MotionProps {
  className?: string;
  customer?: JSX.Element;
  assignee?: JSX.Element;
}

export const MockChrome: FC<Props> = ({
  assignee, customer, className, ...props
}) => (
  <Container className={className} {...props}>
    <Header>
      <CycleLogo size={20} />
      <Title>
        New
        <Emoji emoji="speech_balloon" />
        feedback
      </Title>
    </Header>
    <div>
      <Label>
        Customer
      </Label>
      <Input>
        <InputValue>
          {customer}
          <Skeleton />
        </InputValue>
        <StyledDownIcon />
      </Input>
    </div>
    <div>
      <Label>
        Assignee
      </Label>
      <Input>
        <InputValue>
          {assignee}
          <Skeleton />
        </InputValue>
        <StyledDownIcon />
      </Input>
    </div>
  </Container>
);
