import { getBreakpoint, Breakpoint } from '@cycle-app/utilities';

import { make } from 'src/utils/reactives.util';

export interface ResponsiveState {
  breakpoint: Breakpoint;
}

export const {
  hookState: useResponsive,
  getValue: getResponsive,
  setValue: setResponsive,
} = make<ResponsiveState>({
  defaultState: {
    breakpoint: getBreakpoint(),
  },
});

export const useIsMobile = () => {
  const [{ breakpoint }] = useResponsive();
  return breakpoint === 'mobile';
};
