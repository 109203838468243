import { SelectPanel } from '@cycle-app/ui';
import { useFormContext, useWatch } from 'react-hook-form';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useCustomersByProductId } from 'src/hooks/api/queries/customers/useCustomers';

import { useProductBase } from '../Product';
import { FieldButton, SkeletonInput, CustomerAvatar } from './Form.styles';
import { FormData } from './Form.types';

export const FieldCustomer = () => {
  const { setValue } = useFormContext<FormData>();
  const value = useWatch<FormData>({ name: 'customerId' });

  const {
    customer: selectedCustomer, loading,
  } = useCustomer(value, {
    onCompleted: (data) => {
      if (!data) setValue('customerId', undefined);
    },
  });

  const { product } = useProductBase();
  const {
    customers, fetchNextPage, hasNextPage, searchCustomers,
  } = useCustomersByProductId(product?.id);

  const options = customers
    .map(customer => ({
      label: customer?.name ?? customer?.email ?? '',
      value: customer.id,
      icon: <CustomerAvatar customer={customer} size="S" />,
    }));

  return (
    <ToggleDropdown
      placement="bottom-start"
      button={props => (loading ? <SkeletonInput /> : (
        <FieldButton
          iconStart={selectedCustomer && <CustomerAvatar customer={selectedCustomer} size="S" />}
          {...props}
        >
          {value
            ? selectedCustomer?.name ?? selectedCustomer?.email
            : 'Choose from list'}
        </FieldButton>
      ))}
      content={props => (
        <SelectPanel
          options={options}
          selectedValue={value}
          onOptionChange={option => {
            setValue('customerId', option.value);
            props.hide();
          }}
          onSearchChange={searchCustomers}
          debounceSearch
          infiniteScroll={{
            isLoading: loading,
            hasMoreData: hasNextPage,
            loadMore: fetchNextPage,
          }}
        />
      )}
    />
  );
};
