import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, useMemo, useRef, useEffect, FC } from 'react';

import { useDocInsights, useDocTypeInsight, useDocV2, useOptimizedBooleanState } from 'src/hooks';
import { useInsightsProductTour } from 'src/hooks/productTour/useInsightsProductTour';
import { getDocTypeNames } from 'src/utils/docType.util';

import { useGetHighlight } from '../../reactives/highlight.reactive';
import DocSearchDropdown from '../DocSearchDropdown/DocSearchDropdown';
import { InsightCreateModal } from '../InsightCreateModal';
import { InsightsList } from './InsightsList';
import { AddInsightButton } from './InsightsList.styles';

interface Props {
  doc: DocBaseFragment;
}

export const FeedbackInsightList: FC<Props> = ({ doc }) => {
  const {
    insights, isLoading,
  } = useDocInsights(doc.id);
  const [isVisible, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(false);
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const { insight } = useDocTypeInsight();
  const { doc: parentDoc } = useDocV2(insightDocParentId);
  const { isActive: isProductTourActive } = useInsightsProductTour();
  const possibleDocTypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const highlight = useGetHighlight();

  useEffect(() => {
    if (containerRef.current && highlight.blockId && highlight.context !== 'doc-link-list') {
      const block = containerRef.current.querySelector(`[data-block-id="${highlight.blockId}"]`);
      if (block) {
        block.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [highlight.blockId, highlight.context]);

  return (
    <InsightsList
      ref={containerRef}
      hideAssignee
      hideCustomer
      hideSource
      insights={insights}
      isLoading={isLoading}
      modal={(
        <InsightCreateModal
          isOpen={!!insightDocParentId}
          hide={() => setInsightDocParentId('')}
          onBack={() => {
            setInsightDocParentId('');
            setTrueCallback();
          }}
          parentDoc={parentDoc}
          feedbackDoc={doc}
          defaultContent={doc.title}
        >
          <DocSearchDropdown
            hideSearchDropdown={() => setFalseCallback()}
            dropdownProps={{}}
            isSearchDropdownVisible={isVisible}
            onAdd={(docId) => {
              setInsightDocParentId(docId);
              setFalseCallback();
            }}
            childDoctypeId={insight?.id}
            possibleDoctypes={possibleDocTypes}
            placeholder={getDocTypeNames(possibleDocTypes, {
              prefix: 'Search',
              suffix: possibleDocTypes.length > 1 ? 's or add new' : ' or add new',
            })}
          >
            <AddInsightButton
              onClick={() => !isProductTourActive && setTrueCallback()}
              forceFocus={isVisible || !!insightDocParentId}
              $isFocus={isVisible || !!insightDocParentId}
              $isFullWidth
              disabled={isProductTourActive}
            >
              {`Add ${insight?.name}`}
            </AddInsightButton>
          </DocSearchDropdown>
        </InsightCreateModal>
      )}
    />
  );
};
