import { OnboardingLayout } from '@cycle-app/ui';
import { NextArrowIcon } from '@cycle-app/ui/icons';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { AnimatePresence } from 'framer-motion';
import React, { FC, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { AnimState } from 'src/app/Auth/Welcome/Welcome';
import { AppPreview, previewVariant } from 'src/app/Auth/Welcome/Welcome.styles';
import MainLayout from 'src/app/Main/MainLayout/MainLayout';
import { Inbox, InboxView } from 'src/components/Views/Inbox';
import { PageId } from 'src/constants/routing.constant';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useOnboardUser } from 'src/hooks/api/mutations/useOnboardUser';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useNavigate } from 'src/hooks/useNavigate';
import { setAuth } from 'src/reactives/auth.reactive';
import { getBoardParams, resetBoardParams } from 'src/reactives/boardParams.reactive';
import { getOnboarding } from 'src/reactives/onboarding.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { DocPreview } from './DocPreview';

type Props = {
  progress: number;
  isReadOnly?: boolean;
};

export const StepWelcome: FC<Props> = ({ progress }) => {
  const me = useMaybeMe();
  const {
    navigate, navigateToInbox,
  } = useNavigate();
  const {
    onboardUser, loading: isOnboardingLoading,
  } = useOnboardUser();

  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);

  const isMobile = useIsMobile();
  const boardSlug = useBoardConfig(ctx => ctx.boardSlug);
  const [docIdPreview, setDocIdPreview] = useState('');

  const [isOnboarded, setIsOnboarded] = useState(false);
  const selectedTheme = getTheme(getOnboarding().theme, getOnboarding().userColor);

  return (
    <OnboardingLayout
      title="It all starts with your feedback inbox"
      // eslint-disable-next-line max-len
      description="All the product feedback from many sources gets into your inbox. The goal is to get to inbox zero by breaking down each feedback into insights."
      aside={(
        <>
          <AppPreview
            initial="glimpse"
            animate={isOnboarded ? 'full' : 'glimpse'}
            onAnimationComplete={onAnimationComplete}
            $isLoading={false}
            $full={me?.onboarded}
            variants={previewVariant}
          >
            <ThemeProvider theme={selectedTheme}>
              <MainLayout>
                <Inbox>
                  <InboxView />
                </Inbox>
              </MainLayout>
            </ThemeProvider>
          </AppPreview>
          {!isMobile && (
            <AnimatePresence>
              {docIdPreview && <DocPreview docId={docIdPreview} onClose={() => setDocIdPreview('')} />}
            </AnimatePresence>
          )}
        </>
      )}
      progress={progress}
      nextButtonProps={{
        children: 'Take me to the inbox',
        iconEnd: <NextArrowIcon />,
        size: 'L',
        isLoading: isOnboardingLoading,
        onClick: async () => {
          setDocIdPreview('');
          await onboardUser();
          setIsOnboarded(true);
        },
      }}
    />
  );

  function onAnimationComplete(animationState: AnimState) {
    if (animationState !== 'full') return;
    setAuth({ onboarded: true });

    const { productSlug } = getBoardParams();

    if (!productSlug) {
      throw new Error('productSlug is not defined in welcomeVar(), it means onAnimationComplete was triggered despite that initial query failed');
    }

    if (isInboxEnabled) {
      navigateToInbox();
    } else if (boardSlug) {
      navigate(PageId.Board, {
        productSlug,
        boardSlug,
      }, { isOnboarding: false });
    } else {
      navigate(PageId.Main, {
        productSlug,
      }, { isOnboarding: false });
    }
    resetBoardParams();
  }
};
