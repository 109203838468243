import { getPairSplitArray } from '@cycle-app/utilities';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';

import { CustomPropertyFormField } from 'src/components/CustomPropertyFormField';
import { CustomPropertyFormData } from 'src/types/property.types';
import { FormGroup } from 'src/utils/form.util.styles';

type CustomPropertiesFormFieldsProps<T> = {
  data: CustomPropertyFormData[];
  control: Control<T>;
  autoFocus?: boolean;
};

/**
 * Issue with compiler
 * see here: https://www.ashbyhq.com/blog/engineering/generic-arrow-function-in-tsx
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const CustomPropertiesFormFields = <ControlTypes,>({
  data,
  control,
  autoFocus,
}: CustomPropertiesFormFieldsProps<ControlTypes>) => {
  const dataByPairs = useMemo(() => {
    if (!data.length) return [];

    return getPairSplitArray<CustomPropertyFormData>(data);
  }, [data]);

  return (
    <>
      {dataByPairs.map((pair) => (
        <FormGroup key={pair[0].id}>
          {pair.map((customFieldData) => (
            <CustomPropertyFormField
              key={customFieldData.id}
              control={control}
              data={customFieldData}
              autoFocus={autoFocus}
            />
          ))}
        </FormGroup>
      ))}
    </>
  );
};
