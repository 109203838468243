import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';

import { NotionEditorMentionView } from 'src/components/NotionEditorMentionView/NotionEditorMentionView';
import { NotionSearchDropdown } from 'src/components/NotionSearchDropdown/NotionSearchDropdown';
import { COMMANDS } from 'src/constants/editor.constants';
import { renderIntegrationMentionSuggestion } from 'src/utils/integrations.utils';

type NotionMentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

export const extensionName = 'notion-mention';
export const NOTION_EXTENSION_TAGNAME = 'notion-tagname';

const getNotionMentionExtension = () => Node.create<NotionMentionOptions>({
  name: extensionName,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,
  atom: true,

  addOptions: () => ({
    HTMLAttributes: {
      class: extensionName,
    },
    suggestion: {
      allowSpaces: true,
      char: COMMANDS.NOTION,
      render: () => renderIntegrationMentionSuggestion(extensionName, NotionSearchDropdown),
    },
  }),

  addAttributes() {
    return {
      id: { default: null },
      __typename: { default: null },
      title: {
        default: null,
        rendered: false,
      },
      url: {
        default: null,
        rendered: false,
      },
      iconEmoji: {
        default: null,
        rendered: false,
      },
    };
  },

  parseHTML() {
    return [
      { tag: NOTION_EXTENSION_TAGNAME },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      NOTION_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(NotionEditorMentionView);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${extensionName}`),
      }),
    ];
  },
});

export default getNotionMentionExtension;
