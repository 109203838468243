import { FC } from 'react';

import { ViewType } from 'src/types/viewType.types';

import {
  Top,
  Side,
  RightSide,
  TitleSkeleton,
  BoardConfigSkeleton,
} from './Board.styles';
import { BoardContentSkeleton } from './BoardContent/BoardContentSkeleton';

const BoardSkeleton: FC = () => (
  <>
    <Top>
      <Side>
        <TitleSkeleton />
      </Side>

      <RightSide>
        <BoardConfigSkeleton />
      </RightSide>
    </Top>

    {/* Set props allDocs/List considering that BoardSkeleton is only loaded for unknown board */}
    <BoardContentSkeleton viewType={ViewType.List} allDocs />
  </>
);

export default BoardSkeleton;
