import { DoctypeRelativeFragment } from '@cycle-app/graphql-codegen';
import { FlowElement } from 'react-flow-renderer';

import { getChildren } from './getChildren';

export const parseChildren = (doctypes: DoctypeRelativeFragment[], level: number) => (cElement: FlowElement) => {
  const parentId = cElement.id;
  const doctypeIdc = 'data' in cElement && 'doctypeId' in cElement.data ? cElement.data.doctypeId : '';

  const target = doctypes.find(e => e.id === doctypeIdc);

  return getChildren({
    parentDoctype: target,
    level,
    parentElementId: parentId,
    asLink: true,
    data: {
      markerType: 'default',
    },
  });
};
