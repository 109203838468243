import { make } from 'src/utils/reactives.util';

interface NotificationsResult {
  isNotifCenterVisible: boolean;
}

export const {
  hookState: useNotifications,
  hookValue: useGetNotifications,
  getValue: getNotifications,
  setValue: setNotifications,
  resetValue: resetNotifications,
} = make<NotificationsResult>({
  defaultState: {
    isNotifCenterVisible: false,
  },
});
