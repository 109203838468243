import { NotificationFragment, NotificationType } from '@cycle-app/graphql-codegen';

export const welcomeNotificationText = '👋 Welcome to your notifications center! Mention your teammates using @ to notify them. Enjoy!';
export const COMMENT_SEARCH_PARAM = 'commentId';

export const shouldLinkToAComment = (type: NotificationType) => (
  type === NotificationType.UserMentionedInComment ||
  type === NotificationType.ThreadReplied ||
  type === NotificationType.ThreadUpdated ||
  type === NotificationType.ThreadCreated
);

const getNotificationLabel = (type: Exclude<NotificationType, NotificationType.WelcomeNotification>) => {
  switch (type) {
    case NotificationType.UserMentionedInComment:
      return 'mentioned you in';
    case NotificationType.UserMentionedInDoc:
      return 'mentioned you in';
    case NotificationType.ThreadReplied:
      return 'commented in';
    case NotificationType.ThreadUpdated:
      return 'modified a comment in';
    case NotificationType.ThreadCreated:
      return 'created a thread in';
    case NotificationType.AssignedToDoc:
      return 'assigned you on';
    default:
      return '';
  }
};

export interface NotificationTitleProps {
  name: string;
  label?: string;
  docTitle?: string;
}

export const getNotificationTitleProps = ({
  creator, type, doc,
}: NotificationFragment): NotificationTitleProps => {
  if (type === NotificationType.WelcomeNotification) {
    return {
      name: 'Cycle',
      label: '',
      docTitle: '',
    };
  }

  const name = creator?.firstName || creator?.email || 'Someone';
  const label = getNotificationLabel(type);
  const docTitle = doc?.title || 'a doc';

  return {
    name,
    label,
    docTitle,
  };
};
