import { ActionButton } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';

interface Props {
  tooltip?: ReactNode;
  className?: string;
  url: string;
}

export const CustomerShareButton: FC<Props> = ({
  className, children, tooltip, url,
}) => {
  const copyToClipboard = useCopyToClipboard({
    successNotification: 'Link successfully copied to clipboard',
  });
  return (
    <ActionButton
      className={className}
      tooltipPlacement="top"
      tooltip={tooltip}
      onClick={() => copyToClipboard([window.location.origin, url].join(''))}
      size="L"
    >
      <LinkIcon />
      {children}
    </ActionButton>
  );
};
