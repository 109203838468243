import { useCallback, useLayoutEffect, useRef, RefObject, MouseEvent as ReactMouseEvent } from 'react';

interface UseSidebarResize {
  (p: UseSidebarResizeParams): {
    startListen: (e: ReactMouseEvent) => void;
  };
}
interface UseSidebarResizeParams {
  resizing: boolean;
  containerRef: RefObject<HTMLDivElement>;
  currentWidth: number;
  minWidth: number;
  maxWidth: number;
  onResizeStart?: () => void;
  onResizeEnd?: (sidebarWidth: number) => void;
  onResizeMove?: (sidebarWidth: number) => void;
  disabled?: boolean;
}

export const HANDLE_RESIZE_DIV_ID = 'resize-bar';

export const useSidebarResize: UseSidebarResize = ({
  resizing,
  containerRef,
  currentWidth,
  minWidth,
  maxWidth,
  onResizeStart,
  onResizeEnd,
  onResizeMove,
  disabled = false,
}) => {
  const origin = useRef<number | null>(null);
  const initialWidth = useRef(currentWidth);

  const listenMove = useCallback((e: MouseEvent) => {
    if (!origin.current) {
      origin.current = e.clientX;
    }

    const delta = e.clientX - origin.current;
    const widthUpdated = initialWidth.current + delta;

    if (containerRef.current && widthUpdated >= minWidth && widthUpdated <= maxWidth) {
      onResizeMove?.(widthUpdated);
      // eslint-disable-next-line no-param-reassign
      containerRef.current.style.width = `${widthUpdated}px`;
    }
  }, [containerRef, minWidth, maxWidth, onResizeMove]);

  const startListen = useCallback((e: ReactMouseEvent) => {
    // @ts-ignore react EventTarget type is wrongly typed (does not have optionnal id)
    if (disabled || e.target?.id !== HANDLE_RESIZE_DIV_ID) return;
    initialWidth.current = currentWidth;
    onResizeStart?.();
    origin.current = e.clientX;
  }, [disabled, currentWidth, onResizeStart]);

  const listenEnd = useCallback(() => {
    if (!containerRef.current) return;
    const sidebarWidth = parseInt(containerRef.current.style.width.replace('px', ''), 10);
    onResizeEnd?.(sidebarWidth);
  }, [onResizeEnd, containerRef]);

  useLayoutEffect(() => {
    if (!resizing) {
      return () => {
        // Intentional empty function
      };
    }
    document.addEventListener('mousemove', listenMove);
    document.addEventListener('mouseup', listenEnd);
    return () => {
      document.removeEventListener('mousemove', listenMove);
      document.removeEventListener('mouseup', listenEnd);
    };
  }, [resizing, listenMove, listenEnd]);

  return {
    startListen,
  };
};
