import { Tag, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import DocHierarchy from '../DocHierarchy/DocHierarchy';

const hoverCSS = css`
  background-color: ${p => p.theme.colors.background.hover};
  color: ${p => p.theme.colors.text.primary};
`;

export const StyledTag = styled(Tag)<{ forceFocus: boolean }>`
  color: ${p => p.theme.colors.text.secondary};
  ${typo.tiny600};
  height: 22px;

  &:hover {
    ${hoverCSS};
  }
  ${p => p.forceFocus && hoverCSS};
`;

export const TagContent = styled.div`
  display: flex;
  align-items: center;

  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const StyledDocHierarchy = styled(DocHierarchy)`
  padding: 16px;
  width: 581px;
  max-height: 416px;
`;
