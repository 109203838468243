import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: inherit;
`;

export const Header = styled.header`
  grid-area: header;
  margin-top: 12px;
`;

export const HeaderContainer = styled.div<{ $isCentered?: boolean }>`
  align-items: center;
  column-gap: 24px;
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-areas:
    "avatar header"
    "avatar details";

  ${p => p.$isCentered && css`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `}

  ${mobile} {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const Title = styled.span<{ $isEditable?: boolean }>`
  ${p => !p.$isEditable && css`color: ${p.theme.colors.text.primary};`}
`;

export const DocsList = styled.ul<{ $hideSeparator?: boolean }>`
  border-top: 1px solid ${p => p.theme.colors.border.greyLight};
  margin-top: 32px;
  padding-top: 30px;

  ${p => p.$hideSeparator && css`
    border-top: none;
    padding-top: 0;
    margin-top: 16px;
  `}
`;

export const DocsListItem = styled.li`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;
