import { CustomerFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { useGetPermission } from 'src/reactives/permission.reactive';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';
import { Logo } from '../CustomerCompanyAction/CustomerCompanyAction.styles';
import { Container, Details, StyledCustomerEmail } from './CustomerDetails.styles';
import { CustomerLinkedProfiles } from './CustomerLinkedProfiles';

interface Props {
  className?: string;
  customer: CustomerFragment;
  hideShareLink?: boolean;
  isReadOnly?: boolean;
  isFullPage?: boolean;
  onCompanyClick: (companyId: string) => void;
}

export const CustomerDetails: FC<Props> = ({
  onCompanyClick,
  isFullPage,
  isReadOnly = false,
  customer,
  className,
}) => {
  const { canReadSettings } = useGetPermission();

  const canGoToCompany = canReadSettings && !isFullPage;

  return (
    <Container className={className}>
      <Details>
        <StyledCustomerEmail customer={customer} readOnly={isReadOnly || !!customer.email} />
        <CustomerCompanyAction
          customer={customer}
          isReadOnly={isDefaultCustomer(customer)}
          isCompact={false}
          {...customer.company && canGoToCompany && {
            tooltip: 'Open company',
            onCompanyClick,
          }}
        >
          {customer.company
            ? (
              <>
                <Logo company={customer.company} />
                {customer.company.name}
              </>
            )
            : 'No company'}
        </CustomerCompanyAction>
        {!!customer.profiles?.length && <CustomerLinkedProfiles customer={customer} />}
      </Details>
    </Container>
  );
};
