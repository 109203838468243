import { typo } from '@cycle-app/ui';
import { CornerDownRightIcon } from '@cycle-app/ui/icons';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const ParentIcon = styled(CornerDownRightIcon)`
  transform: rotateX(-180deg) rotateZ(90deg);
`;

export const PublicId = styled.div`
  ${typo.caption400};
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  ${typo.caption400};
`;

export const Container = styled.div<{ $minimal?: boolean; readonly: boolean; size: 'XS' | 'S' | 'M' }>`
  border-radius: 4px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0 4px;
  white-space: nowrap;
  color: ${p => p.theme.colors.text.secondary};

  > [data-popover] {
    align-items: inherit;
    display: inherit;
    gap: inherit;
  }

  ${p => !p.readonly && css`
    cursor: pointer;
    &:hover {
      color: ${p.theme.colors.text.primary};
      background-color: ${p.theme.colors.background.hoverSoft};
    }
  `}

  ${p => p.$minimal && css`
    border: none;

    ${!p.readonly && css`
      &:hover {
        background-color: ${transparentize(0.5, p.theme.colors.background.hover)};
        ${PublicId} {
          color: ${p.theme.colors.text.primary};
        }
      }
      ${PublicId} {
        color: ${p.theme.colors.text.secondary};
        ${typo.caption500};
      }
    `}
  `};

  ${p => p.size === 'XS' && css`
      ${PublicId}, ${Title} {
        ${typo.caption400}
      }
  `}

  ${p => p.size === 'M' && css`
      ${PublicId}, ${Title} {
        ${typo.body400}
      }
  `}
`;
