import { ReactNode, FC } from 'react';

import { CycleStandardIcon } from '../../../icons';
import { FlexCol } from '../../../utils/styles.util';
import { Button } from '../../Buttons/Button/Button';
import { Container, Label } from '../Billing.styles';
import { PlanName, ButtonsGroup, Body } from './CurrentPlanSection.styles';

type Actions = 'manage' | 'info' | 'history';

export type CurrentPlanSectionProps = {
  isStandard: boolean;
  action: Actions | null;
  isLoadingStripePortal?: boolean;
  onViewBillingInformation?: (action: 'manage' | 'info' | 'history') => void;
  body: ReactNode;
};

export const CurrentPlanSection: FC<CurrentPlanSectionProps> = ({
  isStandard,
  isLoadingStripePortal = false,
  onViewBillingInformation,
  body,
  action,
}) => (
  <Container>
    <FlexCol>
      <Label>Current plan</Label>
      <PlanName>
        {isStandard ? (
          <>
            <CycleStandardIcon />
            Pro
          </>
        ) : 'Cycle Starter'}

      </PlanName>
    </FlexCol>

    <Body>
      {body}
    </Body>

    {onViewBillingInformation && (
      <ButtonsGroup>
        <Button
          isLoading={action === 'manage' && isLoadingStripePortal}
          onClick={() => onViewBillingInformation('manage')}
          size="M"
        >
          Manage subscription
        </Button>
        <Button
          isLoading={action === 'info' && isLoadingStripePortal}
          onClick={() => onViewBillingInformation('info')}
          size="M"
          variant="secondary"
        >
          Billing information
        </Button>
        <Button
          isLoading={action === 'history' && isLoadingStripePortal}
          onClick={() => onViewBillingInformation('history')}
          size="M"
          variant="secondary"
        >
          Invoice history
        </Button>
      </ButtonsGroup>
    )}
  </Container>
);
