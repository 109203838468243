import { Emoji, Tooltip, Spinner } from '@cycle-app/ui';

import {
  CREATE_VALUE_PREFIX,
  DOC_TYPES_AMOUNT_LIMIT,
} from './DocSearchDropdown.constants';
import {
  NewItemButton,
  NewItemContainer,
  TooltipContent,
  ButtonContent,
} from './DocSearchDropdown.styles';
import { cropDocTypes } from './DocSearchDropdown.utils';
import { OtherDocTypeButton, OtherDocTypeButtonProps } from './OtherDocTypesButton';

export type AddNewItemLineProps = {
  docTypeIdLoading: string;
  search: string;
} & Omit<OtherDocTypeButtonProps, 'isLoading'>;

export const AddNewItemLine = ({
  docTypeIdLoading,
  docTypes,
  getItemProps,
  isHoverDisabled,
  search,
  selected,
  onSelect,
}: AddNewItemLineProps) => {
  const docTypesToDisplay = docTypes.length > DOC_TYPES_AMOUNT_LIMIT
    ? cropDocTypes(docTypes)
    : docTypes;
  const otherDocTypes = docTypes.length > DOC_TYPES_AMOUNT_LIMIT
    ? cropDocTypes(docTypes, true)
    : [];

  return (
    <NewItemContainer $hasFlexNone={!!otherDocTypes.length}>
      {docTypesToDisplay.map(docType => (
        <Tooltip
          key={docType.id}
          placement="top"
          content={(
            <TooltipContent>
              Create
              <Emoji emoji={docType.emoji} size={14} />
              {search}
            </TooltipContent>
          )}
        >
          <NewItemButton
            variant="secondary"
            isSelected={selected === `${CREATE_VALUE_PREFIX}${docType.id}`}
            hoverDisabled={isHoverDisabled}
            useUserColor={false}
            {...getItemProps(`${CREATE_VALUE_PREFIX}${docType.id}`)}
          >
            {docTypeIdLoading === docType.id ? <Spinner /> : <Emoji emoji={docType.emoji} size={14} />}
            <ButtonContent>
              {`Create ${docType.name}`}
            </ButtonContent>
          </NewItemButton>
        </Tooltip>
      ))}
      {!!otherDocTypes.length && (
        <OtherDocTypeButton
          docTypes={otherDocTypes}
          getItemProps={getItemProps}
          isHoverDisabled={isHoverDisabled}
          isLoading={!!docTypeIdLoading && !!otherDocTypes.find(d => d.id === docTypeIdLoading)}
          selected={selected}
          onSelect={onSelect}
        />
      )}
    </NewItemContainer>
  );
};
