import { IntegrationType } from '@cycle-app/graphql-codegen';
import { getArrayWithoutDuplicates, Feature, isEnabled } from '@cycle-app/utilities';

import { Integration, FrontEndIntegration } from 'src/types/integrations.types';
import { byIntegrationOrder } from 'src/utils/integrations.utils';

export const INTEGRATIONS: Integration[] = getArrayWithoutDuplicates([
  ...Object.values(IntegrationType),
  ...Object.values(FrontEndIntegration),
]).sort(byIntegrationOrder);

export const ACTIVE_INTEGRATIONS: Integration[] = [
  IntegrationType.Github,
  IntegrationType.Slack,
  IntegrationType.Linear,
  IntegrationType.Notion,
  ...isEnabled(Feature.Figma) ? [IntegrationType.Figma] : [],
  IntegrationType.Intercom,
  IntegrationType.Hubspot,
  IntegrationType.Mail,
  FrontEndIntegration.ZAPIER,
];
export const SOURCE_INTEGRATIONS: Integration[] = [
  IntegrationType.Slack,
  IntegrationType.Intercom,
  IntegrationType.Mail,
  FrontEndIntegration.CHROME,
  FrontEndIntegration.ZENDESK,
  FrontEndIntegration.ZAPIER,
  FrontEndIntegration.HUBSPOT,
].sort(byIntegrationOrder);

export const CUSTOMERS_INTEGRATIONS: IntegrationType[] = [
  IntegrationType.Slack,
  IntegrationType.Intercom,
  IntegrationType.Hubspot,
];

export const PREINSTALLED_SOURCE_INTEGRATIONS: Integration[] = [
  FrontEndIntegration.ZAPIER,
  FrontEndIntegration.CHROME,
];

export const COLLAB_INTEGRATIONS: Integration[] = [
  IntegrationType.Github,
  IntegrationType.Notion,
  IntegrationType.Linear,
  FrontEndIntegration.JIRA,
  FrontEndIntegration.GITLAB,
  FrontEndIntegration.SHORTCUT,
].sort(byIntegrationOrder);

export const ONBOARDING_SOURCES_INTEGRATIONS = [
  IntegrationType.Slack,
  IntegrationType.Intercom,
].sort(byIntegrationOrder);

export const ONBOARDING_SOURCES_INTEGRATIONS_READONLY = [
  IntegrationType.Slack,
  IntegrationType.Intercom,
  IntegrationType.Mail,
  FrontEndIntegration.ZAPIER,
  FrontEndIntegration.CHROME,
].sort(byIntegrationOrder);

export const ONBOARDING_COLLAB_INTEGRATIONS = [
  IntegrationType.Github,
  IntegrationType.Notion,
  IntegrationType.Linear,
].sort(byIntegrationOrder);

export const ZAPIER_CYCLE_APP_URL = 'https://zapier.com/apps/cycle/integrations';
export const CHROME_STORE_CYCLE_APP_URL = process.env.NODE_ENV === 'production'
  ? 'https://chrome.google.com/webstore/detail/cycle-turn-feedback-into/dlbjafeipdkijijmlllpenhccdhegeaa'
  : 'https://chrome.google.com/webstore/detail/fepoahfpjeokkegjnnjflbccogmnjlph';
