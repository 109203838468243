import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useOptimizedBooleanState } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';
import { setAuth } from 'src/reactives/auth.reactive';
import { resetOnboarding } from 'src/reactives/lightOnboarding.reactive';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { SubTitle, Title } from '../OnboardingLayout/OnboardingLayout.styles';
import { Content, StyledButton, StyledLogo, StyledLogoLoader } from './OnboardingStepDone.styles';

interface Props {
  products: { slug: string }[];
}

export const OnboardingStepDone: FC<Props> = ({ products }) => {
  const [isLoading, { setTrueCallback: setIsLoading }] = useOptimizedBooleanState(false);
  const { navigate } = useNavigate();

  const prepareRedirect = () => {
    setIsLoading();
    // Fake ws setup. will be removed with a subscription.
    window.setTimeout(() => {
      setAuth({ onboarded: true });
      if (products.length) {
        navigate(PageId.Inbox, {
          productSlug: products[0]?.slug,
        });
      } else {
        navigate(PageId.Main);
      }
      resetOnboarding();
    }, 2000);
  };

  return (
    <OnboardingLayout
      main={(
        <Content>
          {isLoading ? (
            <>
              <StyledLogoLoader id="cycle_main_loader" size={100} animation="loop" />
              <SubTitle>We’re setting up your workspace. It can take a few seconds...</SubTitle>
            </>
          ) : (
            <>
              <StyledLogo size={100} color="blue" />
              <Title>Ready to enjoy the ride?</Title>
              <SubTitle>Join your workspace and make things happen, with Cycle.</SubTitle>
              <StyledButton
                onClick={prepareRedirect}
                size="M"
              >
                Let’s go
              </StyledButton>
            </>
          )}
        </Content>
      )}
    />
  );
};
