import { typo, SelectLine } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div<{ $width: number }>`
  width: ${p => p.$width}px;
  padding: 8px 0;
  color: ${p => p.theme.colors.sidebar.header.menus.color};
`;

export const Header = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.dropdown.sep};
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

export const HeaderItem = styled.div<{ selected: boolean }>`
  background-color: ${p => (p.selected ? p.theme.colors.selectItem.bgHover : p.theme.colors.selectItem.bg)};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 6px 8px;

  &:hover {
    background-color: ${p => p.theme.colors.selectItem.bgHover};
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
`;

export const Username = styled.div`
  ${typo.body500};
  line-height: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UserEmail = styled.div`
  ${typo.caption400};
  line-height: 1;
  color: ${p => p.theme.colors.sidebar.header.menus.emailColor};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledSelectLine = styled(SelectLine)`
  padding-left: 12px;
  padding-right: 12px;
`;
