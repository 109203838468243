import {
  DocResultFragment,
  MateFragment,
  LinearStatus,
  LinearAssignee,
  GithubAssignee,
  NotionMinimalPageFragment,
  NotionMinimalDatabaseFragment,
} from '@cycle-app/graphql-codegen';

export interface Output {
  html: string;
  json: Record<string, unknown>; // @Todo to define
}

export type People = Array<MateFragment>;

export interface DocMention extends DocResultFragment {
  url: string;
}

export interface FileData {
  src: string;
  title?: string;
}

export enum ImageSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export interface ImageViewAttributes extends FileData {
  size: ImageSize;
}

export type GithubIssuesCommandAttributes = {
  id: string;
  state: string | null | undefined;
  url: string;
  title: string | null | undefined;
  description: string | undefined;
  creator: string | null | undefined;
  cardStatus: string | null | undefined;
  milestone: string | null | undefined;
  labels: string[];
  assignees: GithubAssignee[] | null | undefined;
};

export type LinearIssuesCommandAttributes = {
  id: string;
  publicId: number;
  status: LinearStatus | null | undefined;
  url: string | null | undefined;
  title: string | null | undefined;
  teamKey: string | null;
  teamName: string | null;
  assignee: LinearAssignee | null | undefined;
  description: string | null;
};

export type NotionPageCommonAttribute = NotionMinimalPageFragment | NotionMinimalDatabaseFragment;
