import { PenFilledIcon } from '@cycle-app/ui/icons';
import { FC, MouseEventHandler, ReactNode } from 'react';

import { FeatureItem, FeatureItemCount, FeatureItemName, FeatureItemTitle, StyledTextButton } from './BillingDowngrade.styles';

interface Props {
  icon: ReactNode;
  name: string;
  count: number;
  limit: number;
  onEdit?: MouseEventHandler;
}

export const BillingDowngradeFeatureItem: FC<Props> = ({
  icon, name, count, limit, onEdit,
}) => (
  <FeatureItem $isError={count > limit}>
    <FeatureItemTitle>
      {icon}
      <FeatureItemName>{name}</FeatureItemName>
      <FeatureItemCount>
        {count}
        /
        {limit}
      </FeatureItemCount>
    </FeatureItemTitle>
    {onEdit && (
      <StyledTextButton size={10} onClick={onEdit} $isError={count > limit}>
        <PenFilledIcon />
        Edit
      </StyledTextButton>
    )}
  </FeatureItem>
);
