import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

import { body400, body500 } from '../../../theme/typo';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  background: ${p => p.theme.colors.editor.filePreview.bg};

  &:hover {
    background: ${p => p.theme.colors.editor.filePreview.bgHover};
    cursor: pointer;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;

  svg {
    margin-right: 8px;
  }

  path {
    fill: none;
    stroke: ${({ theme }) => theme.colors.editor.text};
  }
`;

export const FileName = styled.span`
  ${body500}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.editor.text};
  `;

export const FileSize = styled.span`
  ${body400}
  margin: 0 8px;
  white-space: nowrap;
  color: ${Color.Grey700};
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;
