import { ApolloError } from '@apollo/client';
import { IntegrationType } from '@cycle-app/graphql-codegen';
import { CycleLoader } from '@cycle-app/ui';
import { normalizeString } from '@cycle-app/utilities';
import { FC, useEffect, useCallback } from 'react';

import { INTEGRATIONS } from 'src/constants/integrations.constants';
import { useCreateIntegration } from 'src/hooks/api/mutations/integrations/useCreateIntegration';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePathParams } from 'src/hooks/usePathParams';
import useQueryParams from 'src/hooks/useQueryParams';
import { useGetLastView } from 'src/reactives/lastView.reactive';
import { Provider, InstallCodeProviderParams } from 'src/types/integrations.types';

import { PageId } from '../../constants/routing.constant';

const IntegrationCallback: FC = () => {
  const { provider } = usePathParams();
  const queryParams = useQueryParams();
  const { productSlug } = useGetLastView();
  const productBase = useProductBase(productSlug);
  const productId = productBase?.id;
  const { navigate } = useNavigate();

  const {
    createIntegration, isLoading,
  } = useCreateIntegration();

  const installGithubProvider: VoidFunction = useCallback(async () => {
    if (!productId) return;
    try {
      const EXPECTED_SETUP_ACTION = 'install';
      const installationId = Number(queryParams.get('installation_id'));
      const setupAction = queryParams.get('setup_action');

      if (setupAction !== EXPECTED_SETUP_ACTION || Number.isNaN(installationId)) {
        throw new Error(`Error with setup action or installation id: ${setupAction} ${installationId}`);
      }

      const response = await createIntegration({
        productId,
        installationId,
        type: IntegrationType.Github,
      });

      if (response == null) throw new Error('Something went wrong during the GitHub Installation');

      onSetupIntegrationSuccess();
    } catch (error: any) {
      onSetupIntegrationError(error);
    }
  }, [queryParams, productId]);

  const installProvider: (type: IntegrationType) => void = useCallback(async (type) => {
    if (!productId) return;
    try {
      const installationCode = queryParams.get('code');
      if (!installationCode) throw new Error(`Installation code missing: ${installationCode}`);
      if (isLoading) return;

      const response = await createIntegration({
        productId,
        installationCode,
        type: type as InstallCodeProviderParams,
      });

      if (response == null) throw new Error('Something went wrong during the Linear Installation');

      onSetupIntegrationSuccess();
    } catch (error: any) {
      onSetupIntegrationError(error);
    }
  }, [queryParams, productId]);

  useEffect(() => {
    if (isLoading || !productId) return;
    switch (provider) {
      case Provider.GITHUB:
        installGithubProvider();
        break;
      case Provider.LINEAR:
        installProvider(IntegrationType.Linear);
        break;
      case Provider.NOTION:
        installProvider(IntegrationType.Notion);
        break;
      case Provider.FIGMA:
        installProvider(IntegrationType.Figma);
        break;
      case Provider.INTERCOM:
        installProvider(IntegrationType.Intercom);
        break;
      case Provider.HUBSPOT:
        installProvider(IntegrationType.Hubspot);
        break;
      default:
        break;
    }
  }, [provider, installGithubProvider, installProvider, isLoading, productId]);

  const isProviderSupported = provider && INTEGRATIONS.some(integration => normalizeString(integration).includes(provider));

  if (!isProviderSupported && provider) {
    window.opener?.onIntegrationEnd?.[provider]?.('error');
  }

  return <CycleLoader />;

  function onSetupIntegrationSuccess() {
    if (provider) {
      if (window.opener) {
        window.opener.onIntegrationEnd?.[provider]?.('success');
      } else {
        navigate(PageId.SettingsIntegrations);
      }
    }
  }

  function onSetupIntegrationError(error: ApolloError | Error) {
    if (provider) window.opener?.onIntegrationEnd?.[provider]?.('error', error);
  }
};

export default IntegrationCallback;
