import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { SENTRY_IGNORED_ERRORS } from 'src/constants/sentry.constants';
import { ErrorType } from 'src/types/errors.types';

export const logError = (error: ApolloError | Error): void => {
  const shouldIgnore = SENTRY_IGNORED_ERRORS.some(
    pattern => (typeof pattern === 'string'
      ? error.message === pattern
      : pattern.test(error.message)),
  );

  if (shouldIgnore) return;

  // @WARNING console log below is used for debugging api with full story
  // eslint-disable-next-line no-console
  console.error('error', error);

  Sentry.captureException(error);
};

type getErrorInfoReturn = {
  isError: boolean;
  type: ErrorType | null;
  message: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorInfo = (error: any): getErrorInfoReturn => {
  if (
    error?.networkError?.result?.errors?.length &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error.networkError.result.errors.find((err: any) => err.message === 'unauthorized')
  ) {
    return {
      isError: true,
      type: ErrorType.AUTHORIZATION,
      message: ErrorMessage.UNAUTHORIZED,
    };
  }
  if (error) {
    return {
      isError: true,
      type: ErrorType.GENERIC,
      message: ErrorMessage._GENERIC,
    };
  }

  return {
    isError: false,
    type: null,
    message: null,
  };
};
