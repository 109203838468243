/* eslint-disable @typescript-eslint/indent */
import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Editor } from '@tiptap/core';
import { FC, useState, useCallback, KeyboardEvent } from 'react';
import { useTheme } from 'styled-components';

import { EditorProps } from 'src/components/Editor';
import { useFullDoc } from 'src/hooks/api/useDoc';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useMe } from 'src/hooks/api/useMe';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
// import { useDirtyInternalAnchorTippy } from 'src/hooks/useDirtyInternalAnchorTippy';
import { getLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';
import { INCOGNITO_NAME } from 'src/utils/users.util';

import { StyledDocHierarchy, StyledEditorSkeleton, StyledEditor } from './DocEditor.styles';

if (!process.env.WS_API_SYNC_SERVER) {
  throw new Error('The variable WS_API_SYNC_SERVER is not defined');
}

type DocEditorProps = Pick<
  EditorProps,
  | 'parentRef'
  | 'autoFocus'
  | 'content'
  | 'applyTemplateOnDoctypeUpdate'
  | 'defaultDoctypeId'
  | 'onUpdate'
  | 'hideQuickActions'
  | 'disabledActions'
>;

type Props = DocEditorProps & {
  docId?: string;
  displayLoader?: boolean;
  prefillTemplate?: boolean;
  isChildrenCreationEnabled?: boolean;
  applyTemplateOnMount?: boolean;
  draft?: boolean;
  onEditorReady?: (editor: Editor) => void;
  onEscape?: VoidFunction;
  hideHierarchy?: boolean;
  showAddTemplate?: boolean;
  showIntegrations?: boolean;
};

const DocEditor: FC<Props> = ({
  docId,
  displayLoader = true,
  isChildrenCreationEnabled = true,
  defaultDoctypeId,
  draft,
  onEditorReady,
  onEscape,
  hideHierarchy,
  showAddTemplate,
  showIntegrations,
  ...editorProps
}) => {
  const { me } = useMe();
  const theme = useTheme();
  const { doc } = useFullDoc({
    docId: docId ?? null,
    draft,
  });

  const doctypeId = doc?.doctype.id ?? defaultDoctypeId;
  const doctype = useDoctype(doctypeId);

  const {
    provider,
    yDoc,
    isSync,
  } = useRealtimeEditorConfig({
    docId,
    isEnabled: !draft,
  });

  const cursorName = me.incognito ? INCOGNITO_NAME : `${me.firstName} ${me.lastName}`;
  const [_, setDocContent] = useState('');

  const showHierarchy = !hideHierarchy && (doctype?.children?.edges ?? []).length > 0;

  // useDirtyInternalAnchorTippy({ docContent }); // @TODO this hook is creating big memory leak, we need to rework this part to enable the feature again.

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Escape' || getLayer()[Layer.DropdownZ1] || getLayer()[Layer.Dropdown]) return;
    e.stopPropagation();
    onEscape?.();
  }, [onEscape]);

  if (displayLoader && !isSync) return <StyledEditorSkeleton />;

  const insightChildrenDocType = doctype?.children?.edges.find(edge => edge.node.type === DoctypeType.Insight)?.node;

  return (
    <>
      <div {...onEscape && { onKeyDown }}>
        <StyledEditor
          showAddTemplate={showAddTemplate}
          showIntegrations={showIntegrations}
          showHierarchy={showHierarchy}
          doc={doc}
          collaboration={yDoc ? { document: yDoc } : undefined}
          cursors={provider ? {
            provider,
            user: {
              color: theme.userColors.main,
              name: cursorName,
            },
          } : undefined}
          defaultDoctypeId={defaultDoctypeId}
          setDocContent={setDocContent}
          onEditorReady={onEditorReady}
          isDraft={draft}
          isLoading={displayLoader && !isSync}
          {...editorProps}
        />
      </div>

      {showHierarchy && (
        <StyledDocHierarchy
          docId={docId}
          doctypeId={doctypeId}
          isChildrenCreationEnabled={isChildrenCreationEnabled}
          excludeDoctypeId={insightChildrenDocType?.id}
        />
      )}
    </>
  );
};

export default DocEditor;
