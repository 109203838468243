import { useQuery } from '@apollo/client';
import { SearchDocDocument, SearchDocQuery } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { useMemo, useRef } from 'react';
import { useDebounce } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useNavigate } from 'src/hooks/useNavigate';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';
import { getDocKey } from 'src/utils/doc.util';
import { getDocSlug } from 'src/utils/slug.util';

export const useDocSearch = (search: string, enabled: boolean): { result: Array<CommandActionCategory>; loading: boolean } => {
  const { navigate } = useNavigate();
  const product = useProductBase();
  const [text] = useDebounce(search, INPUT_ONCHANGE_DEBOUNCE);

  const lastData = useRef<SearchDocQuery | null>(null);

  const {
    data, loading,
  } = useQuery(SearchDocDocument, {
    variables: {
      text,
      productId: product?.id ?? '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !enabled || !product?.id,
  });

  if (data) {
    lastData.current = data;
  }

  const result = useMemo(() => {
    const dataSearch = data || lastData.current;
    return [{
      id: 'docs',
      actions: dataSearch?.searchDoc?.edges.map(({ node: doc }) => ({
        id: doc.id,
        label: `${getDocKey(product?.key, doc.publicId)} · ${doc.title}`,
        icon: <Emoji emoji={doc.doctype.emoji} />,
        onSelect: () => {
          navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
          setCommandbar({ visible: false });
        },
      })) ?? [],
    }];
  }, [data, navigate, product?.key]);

  return {
    result,
    loading,
  };
};
