import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { useDocTypeInsight, useOptimizedBooleanState } from 'src/hooks';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useDocInsightChildren } from 'src/hooks/insight/useDocInsightChildren';

import { useDocChildrenSubscription } from '../../hooks/api/useDocChildrenSubscription';
import { InsightCreateModal } from '../InsightCreateModal';
import { InsightsList } from './InsightsList';
import { AddInsightButton } from './InsightsList.styles';

interface Props {
  doc: DocBaseFragment;
  doctypeId: string;
}

export const InsightChildrenList: FC<Props> = ({
  doc, doctypeId,
}) => {
  const {
    insights, isLoading, loadMore, pageInfo, isPaginationLoading,
  } = useDocInsightChildren({
    docId: doc.id,
    doctypeId,
  });
  useDocChildrenSubscription(doc.id);
  const { insight } = useDocTypeInsight();
  const [isCreateModalOpen, {
    setFalseCallback: setCreateModalClose, setTrueCallback: setCreateModalOpen,
  }] = useOptimizedBooleanState(false);
  const doctype = useDoctype(doctypeId);

  return doctype ? (
    <InsightsList
      cursor={pageInfo?.endCursor}
      canCopyContext
      hasNextPage={pageInfo?.hasNextPage}
      insights={insights}
      isLoading={isLoading}
      isPaginationLoading={isPaginationLoading}
      loadMore={loadMore}
      modal={(
        <>
          <AddInsightButton
            onClick={setCreateModalOpen}
            forceFocus={isCreateModalOpen}
            $isFocus={isCreateModalOpen}
            $isFullWidth
          >
            {`Add ${insight?.name}`}
          </AddInsightButton>
          <InsightCreateModal
            hide={setCreateModalClose}
            hideParentDoc
            isOpen={isCreateModalOpen}
            parentDoc={doc}
          />
        </>
      )}
    />
  ) : null;
};
