import { useReactiveVar as hookReactiveVar } from '@apollo/client';
import { Setter } from '@cycle-app/utilities';

import { StateHookResult, ParamsReactive, ParamsState } from 'src/types/state.types';

type HookLocalState = <T>(p: ParamsState<T> & ParamsReactive<T>) => () => StateHookResult<T>;
export const hookLocalState: HookLocalState = (params) => () => [hookReactiveVar(params.reactive), setLocalState(params)];

type HookGetLocalState = <T>(p: ParamsReactive<T>) => () => T;
export const hookGetLocalState: HookGetLocalState = ({ reactive }) => () => hookReactiveVar(reactive);

type GetLocalState = <T>(p: ParamsReactive<T>) => () => T;
export const getLocalState: GetLocalState = ({ reactive }) => () => reactive();

type SetLocalState = <T>(p: ParamsState<T> & ParamsReactive<T>) => Setter<Partial<T>>;
export const setLocalState: SetLocalState = ({
  reactive,
  localKey,
  defaultState,
  mergeTarget = 'current',
}) => (newState) => {
  const state = mergeTarget === 'initial' ? defaultState : reactive();
  const updatedState = {
    ...state,
    ...newState,
  };
  reactive(updatedState);
  if (localKey) {
    localStorage.setItem(localKey, JSON.stringify(updatedState));
  }
};

type ResetLocalState = <T>(p: ParamsState<T> & ParamsReactive<T>) => VoidFunction;
export const resetLocalState: ResetLocalState = ({
  localKey,
  defaultState,
  ...setterParams
}) => {
  const setState = setLocalState({
    defaultState,
    ...setterParams,
  });
  return () => {
    if (localKey) {
      localStorage.removeItem(localKey);
    }
    setState(defaultState);
  };
};
