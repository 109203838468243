import { DocumentEmptyIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { Container, InfoContainer, FileName, FileSize, MenuContainer } from './FileCard.styles';

export type Props = {
  actions?: ReactNode;
  onClick?: () => unknown;
  size?: string;
  title: string;
};

const FileCard: FC<Props> = ({
  actions, onClick, title, size,
}) => (
  <Container onClick={onClick}>
    <InfoContainer>
      <DocumentEmptyIcon />
      <FileName>{title}</FileName>
      {size && <FileSize>{size}</FileSize>}
    </InfoContainer>
    {!!actions && <MenuContainer>{actions}</MenuContainer>}
  </Container>
);

export default FileCard;
