import { Tooltip, typo } from '@cycle-app/ui';
import { mobile, backgroundImageDpr } from '@cycle-app/utilities';
import styled from 'styled-components';

import { AuthSidebarWidth } from 'src/utils/styleConstantes.util';

export const AuthSidebar = styled.div`
  background-color: ${p => p.theme.colors.authLayout.sidebar.bg};
  ${backgroundImageDpr('/images/auth/auth-sidebar.png')}
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  width: ${AuthSidebarWidth}px;
  max-width: 30%;
  color: ${p => p.theme.colors.text.primary};

  img {
    width: 118px;
  }

  ${typo.headerLarge600};

  ${mobile} {
    position: fixed;
    z-index: 1;
    top: 0;
    padding: 24px;
    height: 100vh;
    width: 100vw;
    max-width: none;
  }
`;

export const SidebarTooltip = styled(Tooltip)`
  background-color: ${p => p.theme.colors.authLayout.sidebar.tooltip.bg};
  color: ${p => p.theme.colors.authLayout.sidebar.tooltip.color};
`;

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.authLayout.content.bg};
  display: flex;
  height: 100vh;
  width: 100vw;
  color: ${(p) => p.theme.colors.authLayout.content.color};
`;

export const Content = styled.div<{ $hasResponsive: boolean }>`
  position: relative;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  ${mobile} {
    padding: ${p => (p.$hasResponsive ? '12px 32px' : 0)};
  }
`;

export const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 40px;
  ${typo.headerLarge600};
`;
