import { useEffect, FC, RefObject, useRef, useCallback } from 'react';

import DocEditor from 'src/components/DocEditor/DocEditor';
import { FeedbackPreviewSection } from 'src/components/FeedbackPreviewSection';
import { useDocDocSource, useWindowSize } from 'src/hooks';
import useDocCoverDropzone from 'src/hooks/doc/useCoverDropzone';
import { useDocPanelProductTour } from 'src/hooks/productTour/useDocPanelProductTour';
import useQueryParams from 'src/hooks/useQueryParams';
import { useGetHighlight } from 'src/reactives/highlight.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { isInsight, isNotInsight } from 'src/utils/docType.util';
import { COMMENT_SEARCH_PARAM } from 'src/utils/notifications.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import DocPanelCover from '../DocPanelCover/DocPanelCover';
import {
  HeaderContainer,
  ScrollableContent,
  DocPanelActions,
  DocPanelDocAttributes,
  CoverContainer,
  DocPanelEditableTitle,
  EditorContainer,
} from './DocPanelContent.styles';

interface Props extends Omit<DocPanelBaseProps, 'getDropzonePropsTopBar'> {
  containerRef: RefObject<HTMLDivElement>;
  doc: FullDocWithPublicId;
  widthSidebar: number;
  isDraggingCover: boolean;
  isUploadingCover: boolean;
  onEscapeEdition?: VoidFunction;
  focusOnMount?: boolean;
}

export const DocPanelContent: FC<Props> = ({
  containerRef,
  doc,
  getDropzonePropsCover,
  mode = 'edit-doc',
  onOpenCoverInputFile,
  onTitleUpdated,
  widthSidebar,
  onEscapeEdition,
  focusOnMount,
  isDraggingCover: isDraggingCoverFromTopOrCover,
  isUploadingCover: isUploadingCoverFromTopOrCover,
}) => {
  // useWindowSize is necessary to trigger a re-render when the window size changes: we might need to hide or show the floating actions
  useWindowSize();
  const searchCommentId = useQueryParams().get(COMMENT_SEARCH_PARAM) || undefined;
  const {
    blockId,
    docSource,
    isLoading: isDocSourceLoading,
  } = useDocDocSource({
    // Empty string is used to skip the query in case is not a built-in insight
    docId: isInsight(doc.doctype) ? doc.id : '',
  });
  const {
    getRootProps: getDropzonePropsTitleAndAttrs,
    isDragActive: isDragActiveFromTitleAndAttrs,
    isUploadingCover: isUploadingCoverTitleAndAttrs,
  } = useDocCoverDropzone({ docId: doc.id });
  const highlight = useGetHighlight();
  const {
    isActive: isProductTourActive, setEditorEl,
  } = useDocPanelProductTour();

  useEffect(() => {
    if (isNotInsight(doc.doctype) || !highlight.blockId) return;

    const highlightEl = document.querySelector(`[data-mark-id="${highlight.blockId}"]`);
    if (highlightEl) {
      highlightEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [doc.doctype, highlight.blockId]);

  const scrollableContentRef = useRef<HTMLDivElement>(null);

  const isDraggingCover = isDraggingCoverFromTopOrCover || isDragActiveFromTitleAndAttrs;
  const isUploadingCover = isUploadingCoverFromTopOrCover || isUploadingCoverTitleAndAttrs;

  const focusToContent = useCallback(() => {
    const editor = containerRef.current?.querySelector('.ProseMirror') as HTMLElement | null;
    editor?.focus();
  }, [containerRef]);

  return (
    <ScrollableContent
      ref={scrollableContentRef}
      widthSidebar={widthSidebar}
    >
      {doc.cover?.url && (
        <CoverContainer>
          <DocPanelCover
            docId={doc.id}
            coverUrl={doc.cover.url}
            isDragActive={isDraggingCover}
            getDropzoneProps={getDropzonePropsCover}
            onUpdateCoverClicked={onOpenCoverInputFile}
          />
        </CoverContainer>
      )}

      <HeaderContainer
        {...getDropzonePropsTitleAndAttrs()}
        coloredTopBorder={!doc?.cover?.url && (isDraggingCover || isUploadingCover)}
      >
        <DocPanelActions
          doc={doc}
          onAddCoverClicked={onOpenCoverInputFile}
          isDraggingCover={isDraggingCover}
          isUploadingCover={isUploadingCover}
          startOpenedOnComment={searchCommentId}
          docSource={docSource}
        />
        <DocPanelEditableTitle
          id={doc.id}
          title={doc.title}
          onTitleUpdated={onTitleUpdated}
          onNext={() => containerRef.current?.click()}
          onEscape={onEscapeEdition}
          onEnter={focusToContent}
          focusEndOnMount={focusOnMount}
          isDisabled={isProductTourActive}
        />
        <DocPanelDocAttributes doc={doc} readOnly={isProductTourActive} />
      </HeaderContainer>

      {isInsight(doc.doctype) && docSource && (
        <FeedbackPreviewSection
          blockId={blockId}
          docSource={docSource}
          isLoading={isDocSourceLoading}
        />
      )}

      <EditorContainer ref={el => setEditorEl(el)}>
        <DocEditor
          docId={doc.id}
          parentRef={containerRef}
          displayLoader={mode === 'edit-doc'}
          onEscape={onEscapeEdition}
          showAddTemplate
          showIntegrations
        />
      </EditorContainer>
    </ScrollableContent>
  );
};
