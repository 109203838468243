import { typo, ShyScrollbar } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { QUICK_ACTIONS_CLASSNAME } from 'src/components/Editor';
import { EDITOR_MAX_CONTENT_WIDTH, EDITOR_LATERAL_PADDING } from 'src/constants/editor.constants';

import { PADDING_EDITOR_BOTTOM } from '../DocPanel.styles';
import DocPanelActionsRaw from '../DocPanelActions/DocPanelActions';
import DocPanelDocAttributesRaw from '../DocPanelDocAttributes/DocPanelDocAttributes';
import DocPanelEditableTitleRaw from '../DocPanelEditableTitle/DocPanelEditableTitle';

export const FLOATING_SECTION_MAX_WIDTH = 150;
export const FLOATING_SECTION_MARGIN_LEFT = 42;

export const TopBar = styled.div`
  display: flex;
  padding: 12px 16px;
`;

export const TopSide = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const ReadonlyTitle = styled.p`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CoverContainer = styled.div`
  padding-top: 2px;
  > * {
    max-width: ${EDITOR_MAX_CONTENT_WIDTH}px;
    padding: 0 ${EDITOR_LATERAL_PADDING}px;
    margin: 0 auto;
  }
`;

export const HeaderContainer = styled.div<{ coloredTopBorder: boolean }>`
  border-top: 2px solid ${p => (p.coloredTopBorder ? p.theme.userColors.main : 'transparent')};
  width: 100%;
  max-width: ${EDITOR_MAX_CONTENT_WIDTH}px;
  padding: 0 ${EDITOR_LATERAL_PADDING}px;
  margin: 0 auto;
`;

export const DocPanelActions = styled(DocPanelActionsRaw)`
  padding-top: 12px;
`;

export const DocPanelDocAttributes = styled(DocPanelDocAttributesRaw)``;

export const DocPanelEditableTitle = styled(DocPanelEditableTitleRaw)`
  margin: 10px 0;
`;

export const EditorContainer = styled.div`
  position: relative;
  margin-top: 18px;
  width: 100%;
  height: 100%;

  ${mobile} {
    max-width: 100vw;
    overflow-x: hidden;
  }
`;

const minimumLateralPadding = css`
  padding-left: ${EDITOR_LATERAL_PADDING}px;
  padding-right: ${EDITOR_LATERAL_PADDING}px;
`;
export const ScrollableContent = styled(ShyScrollbar)<{ widthSidebar: number }>`
  --lateral-padding: max(calc((100% - ${EDITOR_MAX_CONTENT_WIDTH - (EDITOR_LATERAL_PADDING * 2)}px) / 2), ${EDITOR_LATERAL_PADDING}px);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: inherit;

  /* Hide DocPanelHeader box shadow when content is at scroll top 0 position */
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0px;
    height: 2px;
    width: 100%;
    background-color: inherit;
  }

  ${CoverContainer} + ${HeaderContainer} {
    padding-top: 24px;
  }

  .ProseMirror {
    height: 100%;
    padding: 0 var(--lateral-padding);
  }
  .${QUICK_ACTIONS_CLASSNAME} {
    padding: 0 var(--lateral-padding) ${PADDING_EDITOR_BOTTOM}px;
  }

  @media only screen and (max-width: ${p => EDITOR_MAX_CONTENT_WIDTH + p.widthSidebar + 1}px) {
    width: 100%;

    /* weird stylelint error when putting 3 rules here */
    ${HeaderContainer},
    .ProseMirror {
      ${minimumLateralPadding};
    }
  }
`;
