import { ViewCard, Tag } from '@cycle-app/ui';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const StyledViewCard = styled(ViewCard)`
  background-color: ${p => p.theme.colors.boardConfig.preview.card.bg};
  padding-top: 16px;
`;

const commonSkeletonCss = css`
  border-radius: 3px;
  background-color: ${p => p.theme.colors.background.hover};
`;

export const TitleLineSkeleton = styled.div`
  ${commonSkeletonCss}
  background-color: ${p => p.theme.colors.boardConfig.preview.card.title.bg};
  height: 8px;
  width: 50%;
`;

export const DescriptionLineSkeleton = styled.div`
  ${commonSkeletonCss}
  background-color: ${p => p.theme.colors.boardConfig.preview.card.title.bg};
  height: 8px;
  width: 40%;
  margin-top: 4px;
`;

export const AttributeSkeleton = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 20px;
`;

export const ActionsContainerSkeleton = styled.div`
  color: ${p => p.theme.colors.background.active};
  display: flex;
  gap: 8px;
`;

export const AvatarSkeleton = styled.div`
  background-color: ${p => transparentize(0.65, p.theme.userColors.fg)};
  border-radius: 50%;
  height: 14px;
  margin-left: 12px;
  position: relative;
  width: 14px;

  &::before {
    border-radius: 50%;
    border: 2px solid ${p => p.theme.userColors.main};
    bottom: -3px;
    content: "";
    left: -3px;
    position: absolute;
    right: -3px;
    top: -3px;
  }
`;

export const Doctype = styled(Tag)`
  height: 20px;
`;

export const Dockey = styled(Tag)`
  height: 20px;
`;

export const DockeySkeleton = styled.div`
  ${commonSkeletonCss}
  height: 8px;
  width: 22px;
`;
