import styled from 'styled-components';

import { Content } from 'src/app/Main/Board/BoardContent/BoardContent.styles';

import { ContentList } from '../../BoardGroup/BoardGroup.styles';

export const StyledContent = styled(Content)`
  padding-left: 6px;
  padding-right: 6px;
`;

export const StyledContentList = styled(ContentList)`
  padding-bottom: 8px;
`;

export const BoardNameSkeleton = styled.div`
  background-color: ${p => p.theme.colors.background.disabled};
  border-radius: 3px;
  height: 8px;
  width: 134px;
`;

export const BoardHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
