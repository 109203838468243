import { ActionButtonProps } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { FloatingPenButtonWrapper, StyledFloatingPenButton } from './FloatingPenButton.styles';

interface Props extends ActionButtonProps {
  className?: string;
}

export const FloatingPenButton: FC<Props> = ({
  className, onClick, tooltip, tooltipPlacement,
}) => (
  <FloatingPenButtonWrapper className={className}>
    <StyledFloatingPenButton
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
      onClick={onClick}
      size="M"
    >
      <PenFilledIcon />
    </StyledFloatingPenButton>
  </FloatingPenButtonWrapper>
);
