import { Emoji, Button, SelectOption } from '@cycle-app/ui';
import { PenIcon, TrashIcon } from '@cycle-app/ui/icons';
import { TabPanel } from '@reach/tabs';
import { FC, useState, useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { DoctypeDeleteModal } from 'src/components/DoctypeDeleteModal/DoctypeDeleteModal';
import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import useDoctypesMutations from 'src/hooks/api/mutations/useDoctypesMutations';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useNavigate } from 'src/hooks/useNavigate';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { usePageId } from 'src/hooks/usePageId';
import { usePathParams } from 'src/hooks/usePathParams';
import { setTemplate } from 'src/reactives/template.reactive';
import { DoctypeRouteParams } from 'src/types/routes.types';
import { isBuiltIn } from 'src/utils/docType.util';

import { SavingLabelStyled } from '../Settings.styles';
import { Workflows } from '../SettingsWorkflows';
import { CustomersLink } from './CustomersLink';
import {
  HeaderStyled,
  DoctypeTemplates,
  ContentTemplate,
  SectionTitle,
  HierarchyContainer,
  Body,
  Title,
  HeaderActions,
  Description,
  Tabs, TabList, Tab, TabPanels,
} from './SettingsDocType.styles';
import SettingsDoctypeHierarchy from './SettingsDoctypeHierarchy/SettingsDoctypeHierarchy';
import SettingsDocTypeProperties from './SettingsDocTypeProperties/SettingsDocTypeProperties';

export const DRAFT_DOCTYPE = {
  id: 'new',
  sidebarName: 'Untitled',
  emoji: 'ok_hand',
};

const SettingsDoctype: FC = () => {
  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);

  const pageId = usePageId();
  const { doctypeId } = useParams<DoctypeRouteParams>();
  const isDraft = doctypeId === DRAFT_DOCTYPE.id;
  const { doctypes } = useProductDoctypesFull();
  const { doctypeId: doctypeIdFromUrl } = usePathParams();
  const { navigate } = useNavigate();
  const {
    addDoctypeAttribute,
    removeDoctypeAttribute,
    deleteDoctype,
  } = useDoctypesMutations(doctypeId);
  const [isEditModalVisible, { toggleCallback: toggleEditModal }] = useOptimizedBooleanState(false);
  const [isDeleteModalVisible, { toggleCallback: toggleDeleteModal }] = useOptimizedBooleanState(false);
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation, setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);
  const doctype = useMemo(() => doctypes.find(d => d.id === doctypeId), [doctypes, doctypeId]);
  const fallbackDoctype = doctypes[0];

  const editOptions: SelectOption[] = [
    {
      value: 'edit',
      label: 'Edit doc type',
      onSelect: toggleEditModal,
      icon: <PenIcon />,
    },
    {
      value: 'delete',
      label: 'Delete',
      onSelect: toggleDeleteModal,
      icon: <TrashIcon />,
      variant: 'danger',
      disabled: isBuiltIn(doctype),
    },
  ];

  // TODO: Fetch doctype's template when api ready

  const { getUrl } = useNavigate();

  const [name, setName] = useState(doctype?.name ?? '');
  const [emoji, setEmoji] = useState(doctype?.emoji ?? DRAFT_DOCTYPE.emoji);

  useEffect(() => {
    if (doctype) {
      setName(doctype.name);
      setEmoji(doctype.emoji);
    } else if (isDraft) {
      setName('');
      setEmoji(DRAFT_DOCTYPE.emoji);
    }
  }, [doctype, isDraft]);

  if (!isDraft && !doctypeId) {
    return fallbackDoctype ? (
      <Redirect
        to={getUrl(pageId, { doctypeId: fallbackDoctype.id })}
      />
    ) : null;
  }

  return (
    <div>
      <HeaderStyled>
        <Emoji
          emoji={emoji}
          size={24}
        />
        <Title>{name}</Title>
        <HeaderActions>
          <DotsMenuLayer
            options={editOptions}
            tooltip="Edit doc type"
          />
        </HeaderActions>
        {doctype && isEditModalVisible && (
          <DoctypesEditCommonModal
            doctype={doctype}
            onHide={toggleEditModal}
          />
        )}
        {doctype && isDeleteModalVisible && (
          <DoctypeDeleteModal
            doctype={doctype}
            hide={() => {
              setDeleteConfirmationFalse();
              toggleDeleteModal();
            }}
            onConfirm={onDelete}
            onToggleConfirmation={toggleDeleteConfirmation}
            isConfirmDisabled={!isDeleteConfirmed}
          />
        )}
        <SavingLabelStyled />
        {isBuiltIn(doctype) && <CustomersLink />}
      </HeaderStyled>

      {doctype?.description && <Description>{doctype.description}</Description>}

      <Body>
        <HierarchyContainer>
          <SectionTitle>Hierarchy</SectionTitle>
          <SettingsDoctypeHierarchy />
        </HierarchyContainer>

        <DoctypeTemplates>
          <SectionTitle>Templates</SectionTitle>
          <ContentTemplate>
            {doctype?.template?.title || 'No content template'}
            <Button
              onClick={() => setTemplate({
                admin: false,
                selectedTemplateId: doctype?.template?.id,
                mode: doctype?.template ? 'edit' : 'list',
                modalVisible: true,
              })}
            >
              {doctype?.template ? 'Open' : 'Add'}
            </Button>
          </ContentTemplate>
        </DoctypeTemplates>

        <Tabs>
          <TabList>
            {isStatusEnabled && <Tab>Workflow</Tab>}
            <Tab>Properties</Tab>
          </TabList>

          <TabPanels>
            {isStatusEnabled && (
              <TabPanel>
                <Workflows docType={doctype} />
              </TabPanel>
            )}

            <TabPanel>
              <SettingsDocTypeProperties
                disabled={isDraft}
                doctype={doctype}
                onAddProperty={addDoctypeAttribute}
                onRemoveProperty={removeDoctypeAttribute}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Body>
    </div>
  );

  async function onDelete() {
    if (!doctype) return;
    await deleteDoctype();
    const availableDocs = doctypes.filter(({ id }) => id !== doctype.id);
    if (!availableDocs.length) {
      navigate(pageId);
      return;
    }
    const firstDoctypeAvailable = availableDocs[0];
    if (doctypeIdFromUrl === doctype.id && firstDoctypeAvailable) {
      navigate(pageId, { doctypeId: firstDoctypeAvailable.id });
    }
  }
};

export default SettingsDoctype;
