import { typo, ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const Modal = styled(PortalModal)`
  ${ShyScrollbarCss}
  width: 450px;
  label {
    display: block;
    ${typo.body500};
    margin-bottom: 8px;
  }
`;
