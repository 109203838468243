import { lighten, opacify, rgba, transparentize } from 'polished';

import { StatusCategory, Color as ColorApi } from '../../../../graphql-codegen/generated';
import { ColorNuance } from '../../types/nuance.types';
import { DeepPartial } from '../../types/partial.types';
import { SubColorTheme } from '../../types/theme.types';
import { Color, LegacyColor } from '../baseColors';
import { sidebarColors as eclipseSidebarColors } from './eclipse';

const text = {
  primary: Color.White,
  secondary: Color.Grey500,
  opposite: Color.Black,
  disabled: Color.Grey700,
  highlight: Color.Black,

  white: Color.White,
  black: Color.Black,
  red: Color.OrangeRed600,
};

const background = {
  primary: Color.Black,
  secondary: Color.Grey900,
  tertiary: Color.Grey800,
  opposite: Color.White,
  hover: Color.Grey700,
  hoverSoft: Color.Grey850,
  disabled: Color.Grey900,
  transparent: 'transparent',

  red: Color.OrangeRed600,
  grey: Color.Grey100,
  white: Color.White,
  black: Color.Black,
};

const border = {
  primary: Color.Grey800,
  secondary: Color.Grey900,
  hover: Color.Grey700,
  input: 'transparent',

  white: Color.White,
  red: Color.OrangeRed600,
  grey: Color.Grey400,

  surprisingGreyDark: rgba(Color.White, 0.05),
};

export const colors: SubColorTheme = {
  text,
  background,
  border,
  authLayout: {
    content: {
      bg: Color.Grey100,
    },
    sidebar: {
      bg: Color.Blue700,
    },
  },
  avatar: {
    bg: Color.Black,
    bgOpposite: Color.White,
    bgLight: Color.Grey850,
    pending: Color.Grey700,
    pendingLight: Color.Grey800,
  },
  body: {
    bg: background.primary,
    color: Color.Grey300,
  },
  button: {
    light: {
      bg: Color.Grey850,
      bgHover: background.hover,
    },
    outlined: {
      bg: Color.Grey800,
      bgHover: Color.Grey700,
      bgActive: Color.Grey800,
      colorHover: text.primary,
      colorActive: text.primary,
    },
    ternary: {
      color: text.primary,
      bg: Color.Grey800,
      bgHover: Color.Grey700,
      bgActive: Color.Grey600,
      bgFocus: Color.Grey800,
    },
    secondary: {
      bgHover: Color.Grey800,
      bgFocus: Color.Grey800,
      bgActive: Color.Grey700,
      color: Color.White,
      enableUserColor: false,
    },
    warningSecondary: {
      color: text.red,
    },
    secondaryV2: {
      bg: background.transparent,
      bgHover: Color.Grey800,
      bgActive: lighten(0.03, Color.Grey800),
      color: Color.Grey500,
      colorHover: Color.White,
    },
  },
  bulkActions: {
    bg: background.tertiary,
    border: 'transparent',
    outline: Color.Grey800,
  },
  card: {
    bg: Color.Grey850,
    bgHover: Color.Grey800,
  },
  commandbar: {
    color: text.primary,
    bg: rgba(background.tertiary, 0.8),
    bgFilter: 'blur(50px)',
    outline: 'none',
    borderFullProp: `1px solid ${rgba(Color.White, 0.1)}`,
    shadow: `
      0px 0px 120px rgba(43, 43, 43, 0.7),
      0px 34px 64px rgba(0, 0, 0, 0.32),
      0px -38px 32px rgba(0, 0, 0, 0.04),
      0px 24px 24px rgba(0, 0, 0, 0.04),
      0px 4px 24px rgba(0, 0, 0, 0.04),
      0px 4px 4px rgba(0, 0, 0, 0.04)
    `,
    category: {
      color: text.disabled,
      bg: rgba(Color.Grey850, 0.1),
    },
    input: {
      color: text.primary,
      placeholder: transparentize(0.7, text.primary),
      icon: transparentize(0.7, text.primary),
    },
    action: {
      color: text.primary,
      colorHover: text.primary,
      bgHover: transparentize(0.75, Color.Grey700),
    },
    shortCut: {
      color: text.secondary,
      bg: Color.Grey850,
    },
    tag: {
      bg: background.tertiary,
      color: text.primary,
    },
  },
  comments: {
    color: Color.Grey300,
  },
  docPanel: {
    bg: background.secondary,
    overlay: rgba(0, 0, 0, 0.8),
    header: {
      breadCrumbSeparatorColor: Color.Grey800,
    },
    rightPanel: {
      border: Color.Grey850,
    },
    hierarchy: {
      bg: Color.Grey850,
      bgModal: rgba(background.tertiary, 0.8),
      bgHover: Color.Grey800,
    },
    insightButton: {
      enableUserColor: false,
    },
  },
  dropdown: {
    border: Color.Grey800,
    color: text.primary,
    bg: Color.Grey850,
    sep: LegacyColor.Grey200,
    outline: Color.Grey800,
  },
  dropZone: {
    bg: Color.Grey900,
  },
  editor: {
    text: Color.White,
    textHighlight: rgba(Color.White, 0.08),
    filePreview: {
      bg: Color.Grey850,
      bgHover: Color.Grey800,
    },
  },
  emojiPicker: {
    theme: 'dark',
  },
  flowElement: {
    preview: {
      bg: Color.Grey700,
    },
  },
  flowPath: {
    stroke: Color.Grey400,
  },
  group: {
    bg: Color.Grey900,
  },
  mark: {
    bg: rgba(Color.Yellow300, 0.5),
  },
  input: {
    bg: Color.Grey800,
    bgAlt: Color.Grey800,
    hover: Color.Grey800,
  },
  inputRadio: {
    bg: background.tertiary,
  },
  notificationCenter: {
    border: Color.Grey800,
    color: text.primary,
    bg: Color.Grey850,
  },
  scrollbar: {
    color: rgba(LegacyColor.White, 0.2),
    colorHover: rgba(LegacyColor.White, 0.5),
  },
  selectItem: {
    color: text.primary,
    bgHover: lighten(0.03, Color.Grey850),
    bgActive: lighten(0.05, Color.Grey850),
  },
  selectItemDanger: {
    colorHover: Color.Red300,
    bgHover: Color.Red800,
  },
  customProperty: {
    input: {
      bgHover: Color.Grey800,
    },
  },
  settings: {
    bg: background.primary,
    navigationItem: {
      bgHover: Color.Grey850,
      draggingBg: rgba(Color.Grey850, 0.94),
      draggingColor: Color.White,
      draggingBorderColor: 'transparent',
      menu: {
        bgHover: background.hover,
      },
    },
    row: {
      bgHover: background.primary,
    },
  },
  workflows: {
    status: {
      bg: Color.Grey900,
      bgHover: Color.Grey850,
    },
    button: {
      color: Color.Grey700,
      colorHover: Color.Grey400,
      bgHover: Color.Grey800,
      bgActive: lighten(0.05, Color.Grey800),
    },
    buttonAlt: {
      color: Color.Grey700,
      colorHover: Color.Grey400,
      bgHover: Color.Grey800,
      bgActive: lighten(0.05, Color.Grey800),
    },
  },
  sidebar: {
    bg: Color.Grey900,
    border: Color.Grey850,
    header: {
      item: {
        color: text.secondary,
        bgHover: Color.Grey850,
        caret: {
          colorHover: Color.White,
        },
      },
    },
  },
  selectPanel: {
    options: {
      bg: Color.Grey800,
    },
  },
  tag: {
    border: border.hover,
    bg: Color.Grey850,
    bgHover: Color.Grey800,
    bgActive: Color.Grey850,
  },
  toaster: {
    bg: Color.Grey800,
    buttons: {
      bgActive: Color.Grey400,
      bgHover: Color.Grey500,
      bg: Color.Grey700,
    },
  },
  tooltip: {
    bg: Color.Grey700,
    color: Color.White,
    textLight: Color.Grey400,
    shortcut: {
      bg: Color.Grey800,
      text: Color.Grey400,
    },
  },
  modal: {
    border: Color.Grey800,
    content: {
      bg: Color.Grey850,
      bgNoMask: Color.Grey850,
    },
    icon: {
      warning: {
        bg: background.red,
        color: background.white,
      },
    },
  },
  boardConfig: {
    filters: {
      fakeInputBg: Color.Grey900,
      element: {
        bg: Color.Grey850,
        bgHover: Color.Grey700,
        bgFocus: Color.Grey800,
      },
    },
    toolbar: {
      viewTypesBg: background.primary,
      selectedViewTypeBg: background.secondary,
      button: {
        bg: Color.Grey850,
      },
    },
    preview: {
      swimlane: {
        bg: background.tertiary,
      },
      avatar: {
        bg: Color.Grey700,
        enableUserColor: false,
      },
      group: {
        bg: Color.Grey850,
      },
      card: {
        bg: Color.Grey800,
        title: {
          bg: Color.Grey700,
        },
      },
    },
  },
  userModal: {
    themePreview: {
      bg: Color.Grey800,
      bgHover: Color.Grey700,
    },
  },
  settingsModal: {
    leftPanel: {
      bg: background.secondary,
    },
    rightPanel: {
      bg: Color.Grey850,
      notifications: {
        setupSlack: {
          bg: background.tertiary,
        },
      },
    },
  },
  helpCenter: {
    button: {
      bg: Color.Grey800,
    },
  },
  actionButton: {
    outline: Color.Grey800,
    colorDisabled: text.disabled,
    colorHover: text.primary,
    bgHover: Color.Grey800,
    bgActive: Color.Grey700,
    bgHoverDark: Color.Grey800,
    bgActiveDark: Color.Grey700,
  },
  customers: {
    tab: {
      bgHover: Color.Grey850,
    },
    badge: {
      bg: Color.Grey850,
      bgHover: Color.Grey800,
    },
    link: {
      text: Color.Grey500,
      textHover: Color.White,
      bg: Color.Grey850,
      bgHover: Color.Grey800,
      border: rgba(Color.Grey100, 0.06),
    },
  },
  ui: {
    info: {
      default: {
        title: Color.Grey300,
        text: Color.Grey500,
        bg: Color.Grey850,
        bgHover: Color.Grey800,
      },
      danger: {
        title: Color.Red300,
        text: Color.Red400,
        bg: Color.Red900,
        bgHover: Color.Red800,
      },
    },
  },
  logo: {
    main: Color.White,
    alt: '#2E48ED',
  },
  popup: {
    bg: Color.Grey850,
    separator: rgba(Color.White, 0.1),
  },
  billing: {
    section: {
      bg: Color.Grey850,
      border: 'rgba(0, 0, 0, 0)',
      shadow: 'rgba(0, 0, 0, 0)',
      separator: Color.Grey800,
    },
    history: {
      bg: Color.Grey800,
    },
    plan: {
      free: {
        fg: text.secondary,
      },
    },
  },
  onboarding: {
    background: {
      body: Color.Grey850,
      aside: Color.Grey850,
      backdrop: rgba(Color.Black, 0.78),
      docPanel: background.tertiary,
    },
    integration: {
      circle: Color.Blue300,
    },
  },
  lightOnboarding: {
    layout: {
      headline: {
        color: Color.Blue500,
      },
      card: {
        bg: '#353535',
      },
    },
    background: {
      body: Color.Grey800,
      main: Color.Grey900,
      aside: '#292929',
    },
    asideApp: {
      gradient: 'linear-gradient(164.2deg, rgba(36, 36, 36, 0) 37.74%, rgba(36, 36, 36, 0.5) 77.11%)',
      container: {
        border: 'transparent',
      },
      sidebar: {
        bg: Color.Grey900,
      },
      sidebarItem: {
        color: text.disabled,
        bg: Color.Grey850,
      },
      main: {
        bg: rgba(Color.Grey900, 0.8),
      },
      mainHeader: {
        bg: Color.Grey850,
      },
      mainCard: {
        bg: Color.Grey850,
      },
    },
    doneLoader: {
      color: Color.Grey700,
    },
  },
  home: {
    separator: Color.Grey850,
    card: {
      bg: Color.Grey900,
      bgHover: Color.Grey850,
    },
    search: {
      bg: Color.Grey850,
      text: Color.Grey500,
    },
    cmdk: {
      bg: Color.Grey800,
      text: Color.Grey500,
    },
  },
  inbox: {
    sources: {
      bg: Color.Grey850,
    },
    zero: {
      item: {
        text: Color.Grey800,
        description: rgba(Color.Grey800, 0.6),
        bg: lighten(0.03, Color.Black),
      },
    },
  },
  chrome: {
    skeleton: {
      bg: Color.Grey800,
      title: rgba(Color.Grey700, 0.6),
    },
  },
  components: {
    EditorIntegrationCard: {
      border: Color.Grey800,
    },
    FeedbackStatus: {
      [StatusCategory.NotStarted]: {
        main: Color.Grey300,
        text: Color.Grey300,
        bg: Color.Grey800,
        bgHover: lighten(0.1, Color.Grey800),
        bgActive: lighten(0.05, Color.Grey800),
      },
      [StatusCategory.Started]: {
        main: Color.Blue300,
        text: Color.Blue300,
        bg: Color.Blue800,
        bgHover: lighten(0.1, Color.Blue800),
        bgActive: lighten(0.05, Color.Blue800),
      },
      [StatusCategory.Completed]: {
        main: Color.Green300,
        text: Color.Green300,
        bg: Color.Green900,
        bgHover: lighten(0.1, Color.Green900),
        bgActive: lighten(0.05, Color.Green900),
      },
      [StatusCategory.Canceled]: {
        main: Color.Red300,
        text: Color.Red300,
        bg: Color.Red800,
        bgHover: lighten(0.1, Color.Red800),
        bgActive: lighten(0.05, Color.Red800),
      },
    },
    Toaster: {
      bg: Color.Grey900,
      border: Color.Grey800,
      color: Color.Grey700,
      light: Color.Grey700,
    },
    HelpMeWriting: {
      bg: Color.Grey850,
    },
  },
};

export const sidebarColors: DeepPartial<typeof colors> = eclipseSidebarColors;

export const nuance: ColorNuance = {
  a: {
    bg: '#2958FF1A',
    br: '#2958FF26',
    fg: '#6A8BFF',
    main: '#6A8BFF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  b: {
    bg: '#3583DF1A',
    br: '#3583DF26',
    fg: '#69ACFC',
    main: '#69ACFC',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  c: {
    bg: '#13CD661A',
    br: '#13CD6626',
    fg: '#13CD66',
    main: '#13CD66',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  d: {
    bg: '#0DCAD61A',
    br: '#0DC9D626',
    fg: '#0DC9D6',
    main: '#0DC9D6',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  e: {
    bg: '#6523F01A',
    br: '#6523F026',
    fg: '#A790FF',
    main: '#A790FF',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  f: {
    bg: '#D237EB1A',
    br: '#D237EB26',
    fg: '#E769FC',
    main: '#E769FC',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  g: {
    bg: '#DF36351A',
    br: '#DF363526',
    fg: '#FF8484',
    main: '#FF8484',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  h: {
    bg: '#FF00001A',
    br: '#FF000026',
    fg: '#FF6464',
    main: '#FF6464',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  i: {
    bg: '#F078341A',
    br: '#F0783426',
    fg: '#FFA775',
    main: '#FFA775',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  j: {
    bg: '#FF430A1A',
    br: '#FF430A26',
    fg: '#FF8762',
    main: '#FF8762',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  k: {
    bg: '#FF5C001A',
    br: '#FF5C0026',
    fg: '#FF5C00',
    main: '#FF5C00',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
  l: {
    bg: '#FFE6001A',
    br: '#FFE60026',
    fg: '#E2CC00',
    main: '#E2CC00',
    textHighlight: '',
    markHighlight: '',
    markHighlightBorder: '',
  },
};

for (const key of Object.keys(nuance)) {
  const color = key as ColorApi;
  nuance[color].textHighlight = opacify(0.2, nuance[color].bg);
  nuance[color].markHighlight = opacify(0.14, nuance[color].bg);
  nuance[color].markHighlightBorder = opacify(0.20, nuance[color].bg);
}
