import { ChangeIncognitoDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useMe } from 'src/hooks/api/useMe';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useChangeIncognito = () => {
  const [changeIncognitoMutation, { loading }] = useSafeMutation(ChangeIncognitoDocument);
  const { me } = useMe();

  const changeIncognito = useCallback((incognito: boolean) => changeIncognitoMutation({
    variables: { incognito },
    optimisticResponse: {
      goIncognito: {
        ...me,
        incognito,
      },
    },
  }), [changeIncognitoMutation, me]);

  return {
    changeIncognito,
    loading,
  };
};
