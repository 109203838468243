import { CommandSection } from 'src/types/commandbar.types';

interface Section {
  label: string;
  tagLabel?: string;
  placeholder: string;
  disableSearch?: boolean;
}

export const commandSections: Record<CommandSection, Section> = {
  'search-doc': {
    label: 'Search doc',
    placeholder: 'Search…',
  },
  'go-to-board': {
    label: 'Go to View',
    tagLabel: 'Views',
    placeholder: 'Search view',
  },
  'switch-theme': {
    label: 'Switch theme',
    tagLabel: 'Theme',
    placeholder: 'Select a theme…',
  },
  'go-to-settings': {
    label: 'Go to settings',
    tagLabel: 'Settings',
    placeholder: 'Select a page…',
  },
  'create-workspace': {
    label: 'Create workspace',
    placeholder: 'Workspace name',
    disableSearch: true,
  },
  workspace: {
    label: 'Go to workspace',
    placeholder: 'Select a workspace…',
  },
};
