import { useApolloClient } from '@apollo/client';
import { AttributeDefinitionDocument, AttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

export const useGetAttributeDefinitionFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((id: string) => cache.readQuery<{ node: AttributeDefinitionFragment }>({
    query: AttributeDefinitionDocument,
    variables: {
      id,
    },
  })?.node, [cache]);
};
