import { typo } from '@cycle-app/ui';
import { mobile, tablet } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import SidebarToggleButton from 'src/components/SidebarToggleButton/SidebarToggleButton';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

import { RIGHT_PANEL_COLLAPSED_WIDTH } from '../DocPanelRightPanel/DocPanelRightPanel.styles';

export const TopBar = styled.div`
  display: flex;
  padding: 12px 16px;
`;

export const StyledSidebarToggleButton = styled(SidebarToggleButton)`
  z-index: ${mappingZindex[Layer.DocPanel] + 1};
`;

export const TopSide = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const ReadonlyTitle = styled.p`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const containerBaseCSS = css<{ $widthSidebar: number }>`
  background-color: ${p => p.theme.colors.docPanel.bg};
  position: absolute;
  z-index: ${mappingZindex[Layer.DocPanel]};
  top: 0;
  right: 0;
  width: ${p => `calc(100vw - ${p.$widthSidebar}px)`};
  height: 100vh;

  ${mobile} {
    width: 100%;
  }
`;
export const Container = styled.div<{ $widthSidebar: number }>`
  ${containerBaseCSS};
  display: flex;
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: inherit;
  ${tablet} {
    padding-right: ${RIGHT_PANEL_COLLAPSED_WIDTH}px;
  }
  ${mobile} {
    padding-right: 0;
  }
`;

export const LoaderContainer = styled.div<{ $widthSidebar: number }>`
  ${containerBaseCSS};
  display: flex;
  align-items: center;
  justify-content: center;
`;
