import { typo, Avatar, boxShadowZ3, ActionButton } from '@cycle-app/ui';
import { mobile, tablet } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const RIGHT_PANEL_WIDTH = 390;
export const RIGHT_PANEL_COLLAPSED_WIDTH = 58;

export const Actions = styled.div<{ $expanded: boolean }>`
  display: flex;

  ${p => (p.$expanded ? css`
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 5px;
    padding: 16px 16px 8px 16px;
    overflow-x: hidden;
  ` : css`
    padding: 16px 0px 8px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  `)}
`;

export const DisplayedContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const NoComment = styled.div`
  margin-top: 42px;
  text-align: center;
  ${typo.body500};

  img {
    max-width: 185px;
  }
`;

export const Hint = styled.div`
  text-align: center;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.caption400};
`;

export const ActivityContent = styled.div`
  padding: 16px;
`;

export const ActivityItem = styled.div`
  display: flex;
  align-items: center;
  ${typo.body400};

  svg {
    margin-right: 12px;
    color: ${p => p.theme.colors.text.secondary};
  }

  & + & {
    margin-top: 16px;
  }
`;

export const ActivityAvatar = styled(Avatar)`
  margin: 0 4px;
`;

export const FakeActivities = styled.div`
  position: relative;
  margin-top: 27px;
`;

export const Blur = styled.div`
  filter: blur(7px);

  > * {
    opacity: 0.6;
  }
`;

export const ComingSoon = styled.div`
  ${boxShadowZ3}
  background-color: ${p => p.theme.colors.background.primary};
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  padding: 4px 14px;
  ${typo.body500}

  color: ${p => p.theme.colors.text.primary};
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;

export const Container = styled.div<{ $collapsed: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;

  color: ${p => p.theme.colors.text.primary};
  width: ${RIGHT_PANEL_COLLAPSED_WIDTH}px;
  transition: width 150ms ease-in-out;

  ${tablet} {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: inherit;
    z-index: 1;
  }

  ${p => !p.$collapsed && css`
    width: ${RIGHT_PANEL_WIDTH}px;

    ${Actions} {
      flex-direction: row;

      button {
        padding: 3px 6px;
      }
    }
  `}

  ${mobile} {
    display: none;
  }
`;

export const StyledActionButton = styled(ActionButton)`
  overflow: hidden;
  padding: 8px;
  ${typo.caption400};
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;

  &:focus, &.force-focus {
    background-color: transparent;
  }
`;

export const ToggleButton = styled.div<{ $isExpanded: boolean }>`
  position: absolute;
  top: 46px;
  left: -9px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;

  background: ${p => p.theme.userColors.main};
  color: #fff;

  svg {
    width: 11px;
    height: 11px;
    transform: rotate(${p => (p.$isExpanded ? -90 : 90)}deg);
  }
`;

export const ToggleBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: ${p => transparentize(0.6, p.theme.userColors.main)};
`;

export const Toggle = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  height: 100%;
  width: 4px;
  padding: 0;
  border-left: 1px solid;
  border-color: ${p => p.theme.colors.docPanel.rightPanel.border};
  cursor: pointer;

  > * {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &:hover {
    border-color: transparent;
    > * {
      opacity: 1;
    }
  }
`;

export const CommentsLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;
