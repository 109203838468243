import { FileUploadedData } from '@cycle-app/utilities';
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import FileView from 'src/components/Editor/NodeViews/File/FileView';

type SetFileAttributes = {
  file: FileUploadedData;
};

const extensionName = 'file';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [extensionName]: {
      setFile: (options: SetFileAttributes) => ReturnType;
    };
  }
}

const getFileExtension = () => Node.create({
  name: extensionName,
  draggable: true,
  group: 'block',

  atom: false,

  addAttributes: () => ({
    file: {
      default: null,
      rendered: false,
    },
  }),

  addNodeView: () => ReactNodeViewRenderer(FileView),

  addCommands() {
    return {
      setFile: (options) => ({ commands }) => {
        if (options.file.type === 'image') {
          return commands.setImage(options.file);
        }

        return commands.insertContent([
          {
            type: this.name,
            attrs: options,
          },
        ]);
      },
    };
  },

  parseHTML() {
    return [
      { tag: 'react-component' },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'react-component',
      mergeAttributes(HTMLAttributes),
    ];
  },
});

export default getFileExtension;
