import { AnimatePresence, MotionProps, Variants } from 'framer-motion';
import { FC, useEffect, useState } from 'react';

import { useGetDocItem } from 'src/reactives/docItem.reactive';
import { useGetDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useUserPreferences } from 'src/reactives/userPreferences.reactive';

import {
  StyledCard,
  CardContent,
  CardDescription,
  MotionContainer,
} from './DocPreviewHint.styles';

const POPUP_DELAY = 2000;

const motionProps: MotionProps = {
  initial: 'exit',
  exit: 'exit',
  animate: 'enter',
};

const motionVariants: Variants = {
  exit: {
    y: 100,
    opacity: 0,
    transition: {
      duration: 0.15,
      delay: 0,
    },
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.15,
      delay: 0.3,
    },
  },
};

const DocPreviewHintPopup: FC = () => {
  const { hoverDocId } = useGetDocItem();
  const { docIdPreview } = useGetDocIdPreview();
  const [{ isDocPreviewHintEnabled }] = useUserPreferences();

  const [showHintPopup, setShowHintPopup] = useState(false);

  useEffect(() => {
    let displayPopAfterDelay: NodeJS.Timeout | null = null;
    if (hoverDocId && !docIdPreview) {
      displayPopAfterDelay = setTimeout(
        () => setShowHintPopup(true),
        POPUP_DELAY,
      );
    } else {
      setShowHintPopup(false);
      if (displayPopAfterDelay) {
        clearTimeout(displayPopAfterDelay);
      }
    }

    return () => {
      if (displayPopAfterDelay) {
        clearTimeout(displayPopAfterDelay);
      }
    };
  }, [hoverDocId, docIdPreview]);

  return (
    <AnimatePresence>
      {showHintPopup && isDocPreviewHintEnabled && (
        <MotionContainer
          {...motionProps}
          variants={motionVariants}
        >
          <StyledCard z={2}>
            <img src="/images/doc-preview.png" alt="doc-preview-hint" />
            <CardContent>
              <div>Preview</div>
              <CardDescription>Hold ⇧ + Space to get an instant preview.</CardDescription>
            </CardContent>
          </StyledCard>
        </MotionContainer>
      )}
    </AnimatePresence>
  );
};

export default DocPreviewHintPopup;
