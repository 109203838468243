import styled, { css } from 'styled-components';

interface ButtonActionProps {
  active?: boolean;
  $hasSmallIcon?: boolean;
}

export const ButtonAction = styled.button<ButtonActionProps>`
  cursor: pointer;

  width: 24px;
  height: 24px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${p => (p.active ? p.theme.colors.text.opposite : p.theme.colors.text.secondary)};
  background-color: ${p => (p.active ? p.theme.userColors.main : 'transparent')};

  ${p => !p.active && css`
    &:hover {
      color: ${p.theme.colors.text.primary};
      background-color: ${p.theme.colors.background.secondary};
    }
  `};

  ${p => p.$hasSmallIcon && css`
    svg {
      width: 12px;
      height: 12px;
    }
  `};

  padding: 1px;
`;

export const ButtonText = styled(ButtonAction)`
  width: auto;
  gap: 4px;
  padding-left: 4px;
  padding-right: 4px;
`;

export const TooltipLabelContainer = styled.div`
  display: inline-flex;
  gap: 2px;
`;
