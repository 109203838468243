import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
  NodeWithPos,
} from '@tiptap/core';

import { HIGHLIGHT_DATA_ID, HIGHLIGHT_EXTENSION_NAME, HIGHLIGHT_TAG_NAME } from '../constants/editor.constants';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlightMark: {
      cleanDraftHighlights: () => ReturnType;
      cleanPublishedHighlights: (attrs: { publishedIds: string[] }) => ReturnType;
      setHighlightMark: (attrs: { id: string }) => ReturnType;
      publishHighlightMark: (attrs: { id: string }) => ReturnType;
      toggleHighlightMark: (attrs: { id: string }) => ReturnType;
      unsetHighlightMark: (attrs?: { ids: string[] }) => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

export const getHighlightMarkExtension = () => Mark.create({
  name: HIGHLIGHT_EXTENSION_NAME,

  inclusive: false,

  addOptions() {
    return {
      HTMLAttributes: {
        [HIGHLIGHT_DATA_ID]: true,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute(HIGHLIGHT_DATA_ID),
        renderHTML: attributes => ({
          [HIGHLIGHT_DATA_ID]: attributes.id,
        }),
      },
      isDraft: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: HIGHLIGHT_TAG_NAME,
        getAttrs: node => node instanceof HTMLElement && node.getAttribute(HIGHLIGHT_DATA_ID) !== null && null,
      },
    ];
  },

  priority: 9999,

  renderHTML({ HTMLAttributes }) {
    return [HIGHLIGHT_TAG_NAME, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      cleanPublishedHighlights: (attrs: { publishedIds: string[] }) => ({
        chain, tr,
      }) => {
        const { publishedIds } = attrs;
        if (!publishedIds.length) {
          return chain()
            .selectAll()
            .unsetMark(this.name)
            // Focus start helps to not keep the all content selected
            .setTextSelection(0)
            .run();
        }
        const toRemove: { pos: number; node: NodeWithPos['node'] }[] = [];
        tr.doc.nodesBetween(tr.doc.resolve(0).pos, tr.doc.resolve(tr.doc.content.size).pos, (node, pos) => {
          if (
            node.marks.find(mark => mark.type.name === this.name && !publishedIds.includes(mark.attrs?.id))
          ) {
            toRemove.push({
              pos,
              node,
            });
          }
        });
        toRemove.forEach(({
          pos, node,
        }) => tr.removeMark(pos, pos + node.nodeSize, node.marks.find(mark => mark.type.name === this.name)));
        return true;
      },
      cleanDraftHighlights: () => ({ tr }) => {
        const toRemove: { pos: number; textNode: NodeWithPos['node'] }[] = [];
        tr.doc.nodesBetween(tr.doc.resolve(0).pos, tr.doc.resolve(tr.doc.content.size).pos, (node, pos) => {
          if (
            node.marks.find(mark => mark.type.name === this.name && mark.attrs?.isDraft === true)
          ) {
            toRemove.push({
              pos,
              textNode: node,
            });
          }
        });
        toRemove.forEach(({
          pos, textNode,
        }) => tr.removeMark(pos, pos + textNode.nodeSize, textNode.marks.find(mark => mark.type.name === this.name)));
        return true;
      },
      setHighlightMark: (attrs: { id: string }) => ({ commands }) => commands.setMark(this.name, {
        id: attrs.id,
        isDraft: true,
      }),
      publishHighlightMark: (attrs: { id: string }) => ({ commands }) => commands.setMark(this.name, {
        id: attrs.id,
        isDraft: false,
      }),
      toggleHighlightMark: (attrs: { id: string }) => ({ commands }) => {
        const isActive = this.editor.isActive(this.name);
        return commands.toggleMark(this.name, isActive ? undefined : attrs);
      },
      unsetHighlightMark: (attrs?: { ids: string[] }) => ({
        chain, tr,
      }) => {
        if (attrs?.ids) {
          const toRemove: { pos: number; textNode: NodeWithPos['node'] }[] = [];
          tr.doc.nodesBetween(tr.doc.resolve(0).pos, tr.doc.resolve(tr.doc.content.size).pos, (node, pos) => {
            if (
              node.marks.find(mark => mark.type.name === this.name && attrs.ids.includes(mark.attrs?.id))
            ) {
              toRemove.push({
                pos,
                textNode: node,
              });
            }
          });
          toRemove.forEach(({
            pos, textNode,
          }) => tr.removeMark(pos, pos + textNode.nodeSize, textNode.marks.find(mark => mark.type.name === this.name)));
          return true;
        }
        return chain()
          .selectAll()
          .unsetMark(this.name)
          // Focus start helps to not keep the all content selected
          .setTextSelection(0)
          .run();
      },
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
      }),
    ];
  },
});
