import { TriangleIcon } from '@cycle-app/ui/icons';

import { useIsOnboarding, useSidebarCollapsed, useBoardsStarred } from 'src/hooks';
import { useIsBoardActive } from 'src/hooks/useIsBoardActive';
import { useToggleSection } from 'src/reactives/sections.reactive';

import { BoardSectionNavigationItem, Container } from './SidebarBoardSection.styles';
import SidebarItemBoard from './SidebarItemBoard/SidebarItemBoard';

const STARRED_SECTION_ID = '_starred';

export const SidebarBoardStarredSections = () => {
  const isBoardActive = useIsBoardActive();
  const isCollapsed = useSidebarCollapsed();
  const isOnboarding = useIsOnboarding();
  const [isSectionOpen, toggleSection] = useToggleSection(STARRED_SECTION_ID);
  const { boards } = useBoardsStarred();

  if (isCollapsed || isOnboarding || !boards.length) return null;
  const filteredBoards = boards.filter(board => isSectionOpen || isBoardActive({
    boardId: board.id,
    isStarred: true,
  }));

  return (
    <Container>
      <BoardSectionNavigationItem
        onClick={toggleSection}
        icon={<TriangleIcon direction={isSectionOpen ? 'bottom' : 'right'} />}
        label="Starred"
        $isOpen={isSectionOpen || filteredBoards.length > 0}
        isSidebarColor
      />

      {filteredBoards.map(board => (
        <SidebarItemBoard
          key={board.id}
          board={board}
          isActive={isBoardActive({
            boardId: board.id,
            isStarred: true,
          })}
          linkState={{ fromStarredBoard: true }}
        />
      ))}
    </Container>
  );
};
