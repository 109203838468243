import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { Setter } from '@cycle-app/utilities';
import { ChangeEvent, FC, ReactNode, useRef } from 'react';

import { Dropdown } from '../Dropdown/Dropdown';
import { CheckboxInput } from '../Inputs';
import { Warning } from '../Warning/Warning';
import {
  Container,
  Property,
  PropertyInput,
  InputBase,
  InputSelect,
  End,
  PropertyTag,
} from './CustomProperty.styles';
import { CustomPropertyInputText } from './CustomPropertyInputText';

export type PropertyInputType = 'text' | 'date' | 'checkbox' | 'email' | 'select' | 'number' | 'phone' | 'url';

interface Props {
  id: string;
  label: string;
  icon: ReactNode;
  color?: ColorApi;
  values?: string[];
  type: PropertyInputType;
  dropdownValue: ReactNode;
  dropdownValueVisible: boolean;
  setDropdownValueVisible: Setter<boolean>;
  onDropdownMount: VoidFunction;
  onDropdownHide: VoidFunction;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onLabelInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  warning?: string;
}

export const CustomProperty: FC<Props> = ({
  id,
  label,
  icon,
  color,
  type,
  values = [],
  dropdownValue,
  dropdownValueVisible,
  setDropdownValueVisible,
  onDropdownMount,
  onDropdownHide,
  onInputChange,
  onLabelInputChange,
  warning,
}) => {
  const labelInputRef = useRef<HTMLInputElement>(null);
  return (
    <Container>
      <Property>
        {icon}
        <PropertyInput
          ref={labelInputRef}
          type="text"
          defaultValue={label}
          onChange={onLabelInputChange}
          onKeyUp={e => {
            if (e.code === 'Enter') {
              labelInputRef.current?.blur();
            }
          }}
        />
      </Property>

      <div>
        {(type === 'email' ||
        type === 'phone' ||
        type === 'number' ||
        type === 'date' ||
        type === 'url' ||
        type === 'text'
        ) && (
          <CustomPropertyInputText
            type={type}
            values={values}
            color={color}
            onInputChange={onInputChange}
            warning={warning}
          />
        )}
        {type === 'checkbox' && (
          <InputBase>
            <CheckboxInput
              id={id}
              value="on"
              hideLabel
              checked={!!(values.length && values[0] === 'checked')}
              onChange={onInputChange}
            />
            {warning && (
              <End>
                <Warning tooltip={warning} />
              </End>
            )}
          </InputBase>
        )}
        {type === 'select' && (
          <Dropdown
            content={<div>{dropdownValue}</div>}
            visible={dropdownValueVisible}
            hide={hide}
            onMount={onDropdownMount}
            onHide={onDropdownHide}
            placement="bottom"
          >
            <InputSelect onClick={toggle}>
              {values.length
                ? values.map(value => (
                  <PropertyTag
                    key={value}
                    color={color}
                    tooltip={{
                      content: value,
                      placement: 'bottom',
                    }}
                  >
                    {value}
                  </PropertyTag>
                ))
                : <PropertyTag color="grey">Choose from list</PropertyTag>}
            </InputSelect>
          </Dropdown>
        )}
      </div>
    </Container>
  );

  function hide() {
    setDropdownValueVisible(false);
  }

  function toggle() {
    setDropdownValueVisible(!dropdownValueVisible);
  }
};
