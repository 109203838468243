import { typo, ShinyItem } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import SavingLabel from 'src/components/SavingLabel/SavingLabel';

export const BoardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Top = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px; /* Just in case the RealtimeUsers are huge and touch the title */
  height: 52px; /* Prevents a layout jump as the content load asynchronously */

  padding: 12px 24px 0;
  flex-grow: 0;
  overflow-x: auto;

  ${mobile} {
    padding: 14px 22px 6px 42px;
  }
`;

export const Side = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

export const RightSide = styled(Side)`
  justify-content: flex-end;
  align-items: center;
  overflow: auto; /* This is required in order to let Realtime handle the overflow */
  gap: 12px;
`;

export const SavingLabelStyled = styled(SavingLabel)`
  margin-right: 16px;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.body400}
`;

export const TitleSkeleton = styled(ShinyItem)`
  width: 200px;
`;

export const BoardConfigSkeleton = styled(ShinyItem)`
  width: 400px;
`;
