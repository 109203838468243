import { DocAttributeFragment } from '@cycle-app/graphql-codegen';
import { CustomProperty } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import PropertyDropdownValue from 'src/components/PropertyDropdownValue/PropertyDropdownValue';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useFullDoc } from 'src/hooks/api/useDoc';
import { useCompatibility } from 'src/hooks/useCompatibility';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setLayer } from 'src/reactives/layer.reactive';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { Layer } from 'src/types/layers.types';
import { customAttributeTypeData, getCustomAttributeTypeData, getDocAttributeValue } from 'src/utils/attributes.util';

import { ContainerProperty } from './DocPanelPropertiesDropdown.styles';
import DocPanelPropertyOptions from './DocPanelPropertyOptions';

interface Props {
  attributeDefinitionId: string;
}

const DocPanelProperty: FC<Props> = ({ attributeDefinitionId }) => {
  const { doc } = useFullDoc();
  const [dropdownVisible, {
    setValueCallback: setDropdownVisible,
    setFalseCallback: hideDropdown,
  }] = useOptimizedBooleanState(false);
  const { shouldDisplayWarning } = useCompatibility();

  const { changeAttributeName } = useAttributesMutations();

  const attributeDefinitions = nodeToArray(doc?.doctype?.attributeDefinitions);
  const attributeDefinition = attributeDefinitions.find(a => a.id === attributeDefinitionId);

  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);
  const changeAttributeNameDebounced = useDebouncedCallback(changeAttributeName, INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => () => changeAttributeValueDebounced.flush(), []);

  const onLabelInputChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    await changeAttributeNameDebounced(attributeDefinition as AttributeDefinitionsNode, e.target.value);
  }, [changeAttributeNameDebounced, attributeDefinition]);

  const onDropdownMount = useCallback(() => setLayer(Layer.DropdownCustomProperty, true), []);
  const onDropdownHide = useCallback(() => setLayer(Layer.DropdownCustomProperty, false), []);

  const getInputValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (attributeDefinition?.__typename === 'AttributeCheckboxDefinition') {
      return e.target.checked ? 'checked' : '';
    }
    return e.target.value;
  }, [attributeDefinition]);
  const onInputChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (!doc || !attributeDefinition) return;

    await changeAttributeValueDebounced({
      attributeDefinition,
      doc,
      value: getInputValue(e),
    });
  }, [changeAttributeValueDebounced, attributeDefinition, doc, getInputValue]);

  if (!doc || !attributeDefinition) return null;

  const attributeData = getCustomAttributeTypeData(attributeDefinition.__typename);
  const docAttribute = nodeToArray(doc?.attributes).find(a => a.definition.id === attributeDefinition.id);

  const showWarning = shouldDisplayWarning(attributeDefinition);

  return (
    <ContainerProperty>
      <DocPanelPropertyOptions
        doc={doc}
        attributeDefinition={attributeDefinition}
      />
      <CustomProperty
        id={attributeDefinitionId}
        icon={attributeData.icon}
        label={attributeDefinition.name}
        color={attributeDefinition.color}
        values={getValues()}
        dropdownValue={(
          <PropertyDropdownValue
            docId={doc.id}
            attributeDefinitionId={attributeDefinitionId}
            hide={hideDropdown}
          />
        )}
        dropdownValueVisible={dropdownVisible}
        setDropdownValueVisible={setDropdownVisible}
        onDropdownMount={onDropdownMount}
        onDropdownHide={onDropdownHide}
        type={customAttributeTypeData[attributeDefinition.__typename].input}
        onInputChange={onInputChange}
        onLabelInputChange={onLabelInputChange}
        warning={showWarning && !doc.isDraft ? 'The doc will leave the view if you update this property' : undefined}
      />
    </ContainerProperty>
  );

  function getValues(): string[] {
    if (!attributeDefinition || !docAttribute) return [];

    if (docAttribute?.__typename === 'DocAttributeSingleSelect' && docAttribute.selectValue) {
      return [docAttribute.selectValue.value];
    }
    if (docAttribute?.__typename === 'DocAttributeMultiSelect' && docAttribute.selectValues) {
      return docAttribute.selectValues.map(({ value }) => (value));
    }
    if (docAttribute?.__typename === 'DocAttributeCheckbox') {
      return [docAttribute.checkboxValue?.value ? 'checked' : ''];
    }

    const attributeValue = getDocAttributeValue(docAttribute as DocAttributeFragment);

    if (attributeValue && typeof attributeValue === 'string') {
      return [attributeValue];
    }
    if (attributeValue !== null && typeof attributeValue === 'number') {
      return [`${attributeValue}`];
    }
    return [];
  }
};

export default DocPanelProperty;
