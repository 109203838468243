import styled from 'styled-components';

import * as typo from '../../theme/typo';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 24px;
  padding: 20px;
  box-shadow: 0px 2px 5px ${p => p.theme.colors.billing.section.shadow};
  border: 1px solid ${p => p.theme.colors.billing.section.border};
  background-color: ${p => p.theme.colors.billing.section.bg};
  user-select: text;
`;

export const Label = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;
