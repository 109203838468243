import { ChangeLinearIssueStatusDocument, ChangeLinearIssueStatusMutationVariables, IntegrationType } from '@cycle-app/graphql-codegen/generated';

import useSafeMutation from 'src/hooks/useSafeMutation';

import { useProductIntegrations } from '../../useProductIntegrations';

export const useChangeLinearStatusIssue = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Linear);
  const isActive = !!integration?.provider;
  const [changeLinearStatusMutation, { loading }] = useSafeMutation(ChangeLinearIssueStatusDocument);

  const changeLinearStatus = (variables: Omit<ChangeLinearIssueStatusMutationVariables, 'integrationId'>) => (
    isActive && integration?.provider?.id && changeLinearStatusMutation({
      variables: {
        integrationId: integration?.provider?.id,
        ...variables,
      },
    }));

  return {
    changeLinearStatus,
    isLoading: loading,
  };
};
