import { delay } from '@cycle-app/utilities/src/utils/async.util';
import { useCallback } from 'react';

import { useCreateDraftBoardConfig } from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import { setBoardConfigModal } from 'src/reactives/boardConfig.reactive';

export const useOpenBoardConfig = () => {
  const { createDraftBoardConfig } = useCreateDraftBoardConfig();

  return useCallback(async () => {
    setBoardConfigModal({
      visible: true,
      // disable form controls while displaying optimistic data
      disabled: true,
    });
    // Wait for the end of the modal animation
    // TODO: find another way to prioritize UI updates
    await delay(250);
    await createDraftBoardConfig();
  }, [createDraftBoardConfig]);
};
