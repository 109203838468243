import { GithubIssueBaseFragment, LinearIssueFullFragment, DocInitialBaseFragment } from '@cycle-app/graphql-codegen';
import { last } from '@cycle-app/utilities';
import { Editor, NodeWithPos } from '@tiptap/core';
import { Node, NodeViewRendererProps } from '@tiptap/react';
import { EditorState, Plugin } from 'prosemirror-state';

import { BULLET_LIST_TYPE, CHECK_LIST_TYPE, ORDERED_LIST_TYPE, PARAGRAPH_TYPE } from 'src/constants/editor.constants';
import { MENTION_EXTENSION_TAGNAME } from 'src/editorExtensions/Mention/MentionExtension';
import { mentionDocExtensionName } from 'src/editorExtensions/MentionDoc/MentionDocExtension';

export const lastNodeIsParagraph = (editor: Editor) => {
  const json = editor.getJSON();
  const lastNode = last(json.content) as Node;
  return lastNode.type === 'paragraph';
};

export const fixCommentContent = (editor: Editor) => {
  const jsonContent = editor.getJSON().content;
  if (!jsonContent) return null;

  const lastNode = last(jsonContent);
  const lastNodeIndex = jsonContent.length - 1;
  const lastContent = lastNode?.content
    ? last(lastNode.content)
    : undefined;

  if (lastContent?.type === 'hardBreak') {
    jsonContent[lastNodeIndex] = {
      ...lastNode,
      content: lastNode?.content?.slice(0, -1),
    };

    editor.commands.setContent({
      type: 'doc',
      content: jsonContent,
    });

    return {
      html: editor.getHTML(),
      json: editor.getJSON(),
    };
  }
  return null;
};

export const extractMentionedUsers = (html: string): string[] => {
  const htmlElement = document.createElement('html');
  htmlElement.innerHTML = html;

  const mentionExtensionTags = htmlElement.getElementsByTagName(MENTION_EXTENSION_TAGNAME);
  return Array.prototype.slice.call(mentionExtensionTags).map(element => element.id);
};

/**
 * This is a workaround in order to re-order prosemirror plugins so that the
 * event from suggestions will have a higher priority then the ones from the native
 * extension like `heading`.
 *
 * Note that the priority of tiptap extension doesn't work since it only reorder extensions and not
 * plugins.
 *
 * - https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/Extension.ts#L32
 * - https://github.com/ueberdosis/tiptap/issues/2570
 */
export const reorderProsemirrorPlugins = (editor: Editor): void => {
  const {
    state, view,
  } = editor;

  if (state.plugins.length > 0) {
    const restOfPlugins: Plugin<any, any>[] = [];
    const suggestionPlugins: Plugin<any, any>[] = [];

    state.plugins.forEach((plugin) => {
      // @ts-ignore: The `Plugin` type does not include `key`
      if ((plugin.key as string).includes('suggestion')) {
        suggestionPlugins.push(plugin);
      } else {
        restOfPlugins.push(plugin);
      }
    });

    view.updateState(
      state.reconfigure({
        plugins: [...suggestionPlugins, ...restOfPlugins],
      }),
    );
  }
};

export const deleteNodeRange = ({
  editor, node, getPos,
}: Pick<NodeViewRendererProps, 'editor' | 'node' | 'getPos'>) => {
  if (!node || typeof getPos !== 'function') return undefined;

  const from = getPos();
  const to = from + node.nodeSize;

  return editor.commands.deleteRange({
    from,
    to,
  });
};

export const getRootNodeListFromSelection = (state: EditorState) => {
  const {
    $head, $anchor,
  } = state.selection;
  const headPaths = ($head as { path?: NodeWithPos['node'][] }).path?.filter(p => !!p.type) ?? [];
  const anchorPaths = ($anchor as { path?: NodeWithPos['node'][] }).path?.filter(p => !!p.type) ?? [];
  return [...anchorPaths, ...headPaths].find(p => [ORDERED_LIST_TYPE, BULLET_LIST_TYPE, CHECK_LIST_TYPE].includes(p.type.name));
};

export const getNodeAfterRootNode = (state: EditorState, rootNode: NodeWithPos['node'] | null) => {
  const nodeAfterRootNode: { pos: number; node: NodeWithPos['node'] | null } = {
    pos: 0,
    node: null,
  };
  if (!rootNode) return nodeAfterRootNode;
  let rootNodeFound = false;
  state.doc.descendants((node, pos, parent) => {
    if (parent.type.name === 'doc') {
      if (!nodeAfterRootNode.node && rootNodeFound) {
        nodeAfterRootNode.pos = pos;
        nodeAfterRootNode.node = node;
      }
      if (!rootNodeFound && node.eq(rootNode)) {
        rootNodeFound = true;
      }
      // We want only for first descendants of the doc.
      // return false will prevent from going deeper down.
      return false;
    }
    return true;
  });
  return nodeAfterRootNode;
};

type InsertIssuesParams = {
  editor: Editor;
  newIssues: GithubIssueBaseFragment[] | LinearIssueFullFragment[];
  extensionName: 'linear-mention' | 'github-issue';
};
export const insertIssues = ({
  editor, newIssues, extensionName,
}: InsertIssuesParams) => {
  const selectionNodeName = editor.state.selection.content().content.firstChild?.type.name;
  const issuesNodesContent = newIssues.map((issue) => ({
    type: extensionName,
    attrs: { id: issue.id },
  }));

  switch (selectionNodeName) {
    case BULLET_LIST_TYPE:
    case CHECK_LIST_TYPE:
    case ORDERED_LIST_TYPE: {
      editor
        .chain()
        .clearNodes()
        .insertContent([...issuesNodesContent])
        .run();
      return;
    }
    case PARAGRAPH_TYPE:
    default: {
      editor
        .chain()
        .deleteSelection()
        .insertContent([...issuesNodesContent])
        .createParagraphNear()
        .run();
    }
  }
};

type InsertDocMentionsParams = {
  editor: Editor;
  newDocs: DocInitialBaseFragment[];
  selectionType: string;
};
export const insertDocMentions = ({
  editor, newDocs, selectionType,
}: InsertDocMentionsParams) => {
  if (!newDocs.length) return true;

  if (newDocs.length === 1) {
    return editor
      .chain()
      .insertContent([
        {
          type: mentionDocExtensionName,
          attrs: { id: newDocs[0].id },
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run();
  }

  const [firstCreatedDoc, ...otherCreatedDocs] = newDocs;

  return editor
    .chain()
    .insertContent([
      {
        type: mentionDocExtensionName,
        attrs: { id: firstCreatedDoc.id },
      },
      {
        type: 'text',
        text: ' ',
      },
      ...otherCreatedDocs
        .map(({ id }) => (selectionType === PARAGRAPH_TYPE ? {
          type: 'paragraph',
          content: [
            {
              type: mentionDocExtensionName,
              attrs: { id },
            },
          ],
        } : {
          type: selectionType === CHECK_LIST_TYPE ? 'taskItem' : 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: mentionDocExtensionName,
                  attrs: { id },
                },
                {
                  type: 'text',
                  text: ' ',
                },
              ],
            },
          ],
        })),
    ])
    .run();
};
