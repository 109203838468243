import { make } from 'src/utils/reactives.util';

interface BoardParamsResult {
  productSlug: string | null;
  productId: string | null;
  boardConfigId: string | null;
}

export const {
  hookValue: useGetBoardParams,
  getValue: getBoardParams,
  setValue: setBoardParams,
  resetValue: resetBoardParams,

} = make<BoardParamsResult>({
  defaultState: {
    productId: null,
    productSlug: null,
    boardConfigId: null,
  },
});
