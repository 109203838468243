import {
  OperatorIsInOrNot,
  OperatorNumberSingleValue,
  OperatorIsEmptyOrNot,
  CustomAttributeDefinitionFragment,
  FilterPropertyRuleSingleSelectFragment,
  FilterPropertyRuleNumberFragment,
  FilterPropertyRuleTextFragment,
  OperatorTextMultipleValues,
  FilterPropertyRuleEmailFragment,
  FilterPropertyRulePhoneFragment,
  FilterPropertyRuleUrlFragment,
  OperatorDateSingleValue,
  FilterPropertyRuleDateFragment,
  FilterPropertyRuleCheckboxFragment,
  OperatorCheckbox,
} from '@cycle-app/graphql-codegen';
import { add, sub } from 'date-fns';

// SingleSelect
export function getDefaultSingleSelectAttributeValue(
  filterProperty: FilterPropertyRuleSingleSelectFragment,
  attributes: CustomAttributeDefinitionFragment[],
): string | undefined {
  const {
    singleSelectRule,
    attribute: { id: attributeId },
  } = filterProperty;

  const attribute = attributes.find(a => a.id === attributeId);

  if (singleSelectRule.__typename === 'RuleSingleSelectMultipleValues' &&
    singleSelectRule.operator === OperatorIsInOrNot.Is
  ) {
    const selectedValues = singleSelectRule.values.edges.filter(({ node }) => node.selected);
    return selectedValues[0]?.node.value.id;
  }

  if (singleSelectRule.__typename === 'RuleIsEmptyOrNot' &&
    attribute?.__typename === 'AttributeSingleSelectDefinition' &&
    singleSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty &&
    attribute.values.edges.length
  ) {
    const firstAvailableValue = attribute.values.edges[0].node;
    return firstAvailableValue.id;
  }

  return undefined;
}

// Text
const operatorsMatchingText = [OperatorTextMultipleValues.Is, OperatorTextMultipleValues.Contains];

export function getDefaultTextAttributeValue(filterProperty: FilterPropertyRuleTextFragment): string | undefined {
  const { textRule } = filterProperty;

  if (textRule.__typename === 'RuleTextMultipleValues' &&
    operatorsMatchingText.includes(textRule.operator) &&
    textRule.values.edges.length
  ) {
    return textRule.values.edges[0].node.value;
  }
  return undefined;
}

// Email
export function getDefaultEmailAttributeValue(filterProperty: FilterPropertyRuleEmailFragment): string | undefined {
  const { emailRule } = filterProperty;

  if (emailRule.__typename === 'RuleEmailMultipleValues' &&
    emailRule.operator === OperatorIsInOrNot.Is &&
    emailRule.values.edges.length
  ) {
    return emailRule.values.edges[0].node.value;
  }
  return undefined;
}

// Phone
export function getDefaultPhoneAttributeValue(filterProperty: FilterPropertyRulePhoneFragment): string | undefined {
  const { phoneRule } = filterProperty;

  if (phoneRule.__typename === 'RulePhoneMultipleValues' &&
    phoneRule.operator === OperatorIsInOrNot.Is &&
    phoneRule.values.edges.length
  ) {
    return phoneRule.values.edges[0].node.value;
  }
  return undefined;
}

// Url
export function getDefaultUrlAttributeValue(filterProperty: FilterPropertyRuleUrlFragment): string | undefined {
  const { urlRule } = filterProperty;

  if (urlRule.__typename === 'RuleUrlMultipleValues' &&
    urlRule.operator === OperatorIsInOrNot.Is &&
    urlRule.values.edges.length
  ) {
    return urlRule.values.edges[0].node.value;
  }
  return undefined;
}

const operatorsMatchingNumber: OperatorNumberSingleValue[] = [
  OperatorNumberSingleValue.Is,
  OperatorNumberSingleValue.IsGreaterThanOrEqualTo,
  OperatorNumberSingleValue.IsLowerThanOrEqualTo,
];

// Number
export function getDefaultNumberAttributeValue(filterProperty: FilterPropertyRuleNumberFragment): number | undefined {
  const { numberRule } = filterProperty;

  if (numberRule.__typename === 'RuleIsEmptyOrNot' && numberRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
    return 0;
  }
  if (numberRule.__typename === 'RuleNumberSingleValue' && numberRule.value) {
    if (operatorsMatchingNumber.includes(numberRule.operator)) {
      return numberRule.value.value;
    }
    if (numberRule.operator === OperatorNumberSingleValue.IsGreaterThan) {
      return numberRule.value.value + 1;
    }
    if (numberRule.operator === OperatorNumberSingleValue.IsLowerThan) {
      return numberRule.value.value - 1;
    }
  }
  return undefined;
}

const operatorsMatchingDate: OperatorDateSingleValue[] = [
  OperatorDateSingleValue.Is,
  OperatorDateSingleValue.IsGreaterThanOrEqualTo,
  OperatorDateSingleValue.IsLowerThanOrEqualTo,
];

// Date
export function getDefaultDateAttributeValue(filterProperty: FilterPropertyRuleDateFragment): string | undefined {
  const { dateRule } = filterProperty;
  if (!dateRule) return undefined;

  if (dateRule.__typename === 'RuleIsEmptyOrNot' && dateRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) {
    return new Date().toISOString();
  }
  if (dateRule.__typename === 'RuleDateSingleValue' && dateRule.value) {
    if (operatorsMatchingDate.includes(dateRule.operator)) {
      return dateRule.value.value;
    }
    if (dateRule.operator === OperatorDateSingleValue.IsGreaterThan) {
      return add(new Date(dateRule.value.value), { days: 1 }).toISOString();
    }
    if (dateRule.operator === OperatorDateSingleValue.IsLowerThan) {
      return sub(new Date(dateRule.value.value), { days: 1 }).toISOString();
    }
  }
  return undefined;
}

// Checkbox
export function getDefaultCheckboxAttributeValue(filterProperty: FilterPropertyRuleCheckboxFragment): true | undefined {
  const { checkboxOperator } = filterProperty;

  return checkboxOperator === OperatorCheckbox.IsTrue ? true : undefined;
}
