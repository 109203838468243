import { CustomFieldCheckbox } from './CustomFieldCheckbox';
import { CustomFieldSelect } from './CustomFieldSelect';
import { CustomFieldSkeleton } from './CustomFieldSkeleton';
import { CustomFieldText } from './CustomFieldText';
import { CustomFieldsContainer, FieldLabel } from './Form.styles';
import { isTextDefinition } from './Form.types';
import { useCustomPropertyDefinitions } from './useCustomPropertyDefinitions';

export const CustomFields = () => {
  const definitions = useCustomPropertyDefinitions();
  return (
    <CustomFieldsContainer count={definitions?.length ?? 2}>
      {definitions ? definitions.map(definition => (
        <div key={definition.id}>
          <FieldLabel>{definition.name}</FieldLabel>
          {definition.__typename === 'AttributeCheckboxDefinition' && <CustomFieldCheckbox definition={definition} />}
          {definition.__typename === 'AttributeSingleSelectDefinition' && <CustomFieldSelect definition={definition} />}
          {isTextDefinition(definition.__typename) && <CustomFieldText definition={definition} />}
        </div>
      )) : (
        <>
          <CustomFieldSkeleton />
          <CustomFieldSkeleton />
        </>
      )}
    </CustomFieldsContainer>
  );
};
