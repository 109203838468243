import { DocAttribute, ThemeType } from '@cycle-app/graphql-codegen';
import { InsightCard, InsightCardSkeleton } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, forwardRef, ReactNode } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { CustomerAvatar } from 'src/components/DocCustomer/DocCustomer';
import { DocSource } from 'src/components/DocSource';
import { DocTagKey } from 'src/components/DocTagKey';
import { DocTagProperties } from 'src/components/DocTagProperties';
import {
  useProductBase,
  useCopyToClipboard,
  useOptimizedBooleanState,
} from 'src/hooks';
import { Insight as InsightType, InsightList } from 'src/hooks/insight/useDocInsights.types';
import { useInsightsProductTour } from 'src/hooks/productTour/useInsightsProductTour';
import { useNavigate } from 'src/hooks/useNavigate';
import { setCustomerProfileModal, setCompanyProfileModal } from 'src/reactives';
import { useGetHighlight, setHighlight } from 'src/reactives/highlight.reactive';
import { getThemeConfig } from 'src/reactives/theme.reactive';
import { getDocKey } from 'src/utils/doc.util';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

import { InsightCardOptions } from '../InsightCardOptions';
import { IllustrationDark } from './IllustrationDark';
import { IllustrationLight } from './IllustrationLight';
import {
  ButtonEmptyWrapper,
  ButtonWrapper,
  Container,
  EmptyStateContainer,
  Paragraph,
  ParagraphSkeleton,
  PropertiesContainer,
  ScrollableContainer,
  Title,
  TitleSkeleton,
} from './InsightsList.styles';

type InsightListProps = {
  canCopyContext?: boolean;
  children?: ReactNode;
  cursor?: string | null;
  hasNextPage?: boolean;
  insights: InsightList;
  isLoading?: boolean;
  isPaginationLoading?: boolean;
  loadMore?: (cursor: string) => void;
  modal?: ReactNode;
  hideSource?: boolean;
  hideCustomer?: boolean;
  hideAssignee?: boolean;
};

export const InsightsList = forwardRef<HTMLDivElement, InsightListProps>(({
  canCopyContext = false,
  children,
  cursor,
  hasNextPage,
  insights,
  isLoading,
  isPaginationLoading,
  loadMore,
  modal,
  hideSource,
  hideCustomer,
  hideAssignee,
}, containerRef) => {
  const { colorTheme } = getThemeConfig();
  const { isActive: isProductTourActive } = useInsightsProductTour();
  const Illustration = convertLegacyLocalStorageValue(colorTheme) === ThemeType.Nightfall
    ? <IllustrationDark />
    : <IllustrationLight />;

  if (isLoading) {
    return (
      <Container $isDisabled={isProductTourActive}>
        <EmptyStateContainer>
          {Illustration}
          <TitleSkeleton />
          <ParagraphSkeleton />
        </EmptyStateContainer>
      </Container>
    );
  }

  if (!insights.length) {
    return (
      <Container $isDisabled={isProductTourActive}>
        <EmptyStateContainer>
          {Illustration}
          <Title>Add your first insight</Title>
          <Paragraph>
            Insights let you connect feedback to
            <br />
            product delivery workflows
          </Paragraph>
          <ButtonEmptyWrapper>
            {modal}
          </ButtonEmptyWrapper>
        </EmptyStateContainer>
      </Container>
    );
  }

  return (
    <Container ref={containerRef} $isDisabled={isProductTourActive}>
      <ButtonWrapper>
        {modal}
      </ButtonWrapper>
      <ScrollableContainer
        isLoading={!!isLoading}
        hasMoreData={!!hasNextPage}
        loadMore={() => loadMore?.(cursor ?? '')}
        id="insights-list"
      >
        {insights.map((insightLink, index) => (
          <Insight
            index={index}
            key={insightLink?.id}
            insightLink={insightLink}
            canCopyContext={canCopyContext}
            hideSource={hideSource}
            hideCustomer={hideCustomer}
            hideAssignee={hideAssignee}
          />
        ))}
        {isPaginationLoading && <InsightCardSkeleton />}
      </ScrollableContainer>
      {children}
    </Container>
  );
});

type InsightProps = {
  canCopyContext: boolean;
  hideAssignee?: boolean;
  hideCustomer?: boolean;
  hideSource?: boolean;
  index: number;
  insightLink: InsightType;
};

const Insight: FC<InsightProps> = ({
  canCopyContext,
  insightLink,
  hideSource,
  hideCustomer,
  hideAssignee,
  index,
}) => {
  const { navigateToDocFullPage } = useNavigate();
  const copy = useCopyToClipboard({ successNotification: 'Quote copied to clipboard!' });
  const highlight = useGetHighlight();
  const product = useProductBase();
  const [isOptionFocus, {
    setTrueCallback, setFalseCallback,
  }] = useOptimizedBooleanState(false);
  const {
    setProcessFirstFeedbackStep,
    isActive: isProductTourActive,
    setInsightEl,
  } = useInsightsProductTour();

  if (!insightLink?.doc) return null;

  return (
    <InsightCard
      ref={el => index === 0 && setInsightEl(el)}
      key={insightLink.doc.id}
      title={insightLink.doc.title || ''}
      context={insightLink.content}
      isHighlighted={!!highlight.blockId && insightLink.blockId === highlight.blockId}
      blockId={insightLink.blockId}
      parent={{
        emoji: insightLink.doc.parent?.doctype.emoji || '',
        title: insightLink.doc.parent?.title || '',
      }}
      onParentClick={(e) => {
        e.stopPropagation();
        if (!insightLink?.doc?.parent) return;

        navigateToDocFullPage(
          {
            title: insightLink.doc.parent.title,
            id: insightLink.doc.parent.id,
          },
          {},
          e.metaKey,
        );
      }}
      onClick={(e) => {
        if (!insightLink.doc) return;

        if (isProductTourActive) {
          setProcessFirstFeedbackStep();
          return;
        }

        navigateToDocFullPage({
          title: insightLink.doc.title,
          id: insightLink.doc.id,
        }, {}, e.metaKey);
      }}
      onMouseEnter={() => !!insightLink.blockId && setHighlight({
        blockId: insightLink.blockId,
        context: 'doc-link-list',
      })}
      onMouseLeave={() => !!highlight.blockId && setHighlight({
        blockId: null,
        context: null,
      })}
      onContextCopied={(context => copy(context))}
      properties={(
        <PropertiesContainer>
          <DocTagKey
            docTitle={insightLink.doc.title}
            docKey={insightLink.doc._docKey || ''}
            docId={insightLink.doc.id}
          />
          {!hideSource && <DocSource source={insightLink.doc.source} />}
          <DocTagProperties properties={nodeToArray(insightLink.doc.attributes) as DocAttribute[]} />
        </PropertiesContainer>
      )}
      options={(
        <InsightCardOptions
          blockId={insightLink.blockId}
          doc={{
            ...insightLink.doc,
            _docKey: getDocKey(product?.key, insightLink.doc.publicId) || '',
          }}
          isOpen={isOptionFocus}
          onOpen={setTrueCallback}
          onClose={setFalseCallback}
        />
      )}
      footerRight={(
        <>
          {!hideCustomer && !!insightLink.doc.customer && (
            <CustomerAvatar
              customer={insightLink.doc.customer}
              isCompact
              isDisabled
              onCompanyModalOpen={(companyId) => {
                setCompanyProfileModal({
                  companyId,
                  isOpen: true,
                });
              }}
              onClick={(e) => {
                if (!insightLink.doc?.customer?.id) return;

                e.stopPropagation();
                setCustomerProfileModal({
                  customerId: insightLink.doc.customer.id,
                  isOpen: true,
                });
              }}
            />
          )}
          {!hideAssignee && !!insightLink.doc.assignee?.id && (
            <DocAssignee
              assignee={insightLink.doc.assignee}
              showAssigneeName={false}
              tooltipPlacement="top"
              isDisabled={!!insightLink.id}
              isRemovable={false}
              docId={insightLink.doc.id}
            />
          )}
        </>
      )}
      isOptionFocus={isOptionFocus}
      isContextReadOnly={!canCopyContext}
    />
  );
};
