import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  background-color: ${p => p.theme.colors.background.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled(motion.div)`
  max-width: 100px;
  max-height: 100px;
  animation: ${rotate} 2s infinite linear;
`;
