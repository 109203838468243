import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const UnlinkIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.69703 13.8664L9.43534 12.1728L8.19469 10.9319L6.46292 12.6206C6.25944 12.8271 6.01703 12.9913 5.74972 13.1035C5.4824 13.2158 5.19549 13.2739 4.90556 13.2746C4.61563 13.2753 4.32845 13.2185 4.06062 13.1074C3.79279 12.9964 3.54963 12.8334 3.3452 12.6278C3.14077 12.4222 2.97913 12.1781 2.86963 11.9097C2.76013 11.6412 2.70493 11.3537 2.70724 11.0638C2.70955 10.7739 2.76932 10.4873 2.88308 10.2206C2.99685 9.95397 3.16235 9.71249 3.37003 9.51019L5.05017 7.78694L3.8095 6.54609L2.12071 8.27724C1.39657 9.02023 0.994002 10.0184 1.00007 11.0559C1.00613 12.0934 1.42035 13.0868 2.15312 13.8212C2.8859 14.5557 3.87834 14.9722 4.91581 14.9807C5.95328 14.9891 6.95237 14.5889 7.69703 13.8664Z" />
    <path d="M10.8969 8.2302L12.1376 9.47104L13.8465 7.71923C14.5852 6.97793 15 5.97408 15 4.92756C15 3.88105 14.5852 2.8772 13.8465 2.1359C13.0951 1.42043 12.0976 1.02069 11.06 1.01917C10.0224 1.01765 9.02381 1.41445 8.27022 2.12771L6.51123 3.84383L7.75191 5.08469L9.50434 3.37703C9.70684 3.16653 9.94935 2.9986 10.2176 2.88309C10.4859 2.76759 10.7746 2.70683 11.0666 2.70439C11.3587 2.70195 11.6484 2.75788 11.9185 2.86888C12.1887 2.97989 12.434 3.14374 12.64 3.35083C12.846 3.55791 13.0085 3.80405 13.1181 4.07481C13.2277 4.34557 13.2821 4.63549 13.2781 4.92755C13.2741 5.21962 13.2118 5.50794 13.0949 5.77561C12.978 6.04328 12.8088 6.2849 12.5972 6.48629L10.8969 8.2302Z" />
    <path d="M9.70319 5.03806L8.70416 6.03709L9.94462 7.27773L10.9432 6.27919C11.027 6.1983 11.0939 6.10153 11.1399 5.99451C11.1859 5.88749 11.2102 5.77238 11.2112 5.65589C11.2123 5.5394 11.1902 5.42387 11.1461 5.31602C11.102 5.20818 11.0369 5.1102 10.9546 5.02778C10.8723 4.94537 10.7744 4.88018 10.6666 4.83602C10.5588 4.79185 10.4432 4.7696 10.3267 4.77056C10.2103 4.77151 10.0951 4.79566 9.98806 4.84159C9.881 4.88752 9.78416 4.95431 9.70319 5.03806Z" />
    <path d="M7.24262 9.97972L6.00217 8.73908L5.02409 9.71716C4.94256 9.79864 4.87789 9.89538 4.83376 10.0019C4.78964 10.1083 4.76693 10.2225 4.76693 10.3377C4.76693 10.453 4.78964 10.5671 4.83376 10.6736C4.87789 10.7801 4.94256 10.8768 5.02409 10.9583C5.10539 11.0399 5.202 11.1047 5.3084 11.1489C5.41479 11.1931 5.52886 11.2158 5.64407 11.2158C5.75928 11.2158 5.87335 11.1931 5.97974 11.1489C6.08614 11.1047 6.18275 11.0399 6.26405 10.9583L7.24262 9.97972Z" />
    <path d="M14.6541 14.1832C14.6099 14.2896 14.5451 14.3862 14.4635 14.4675C14.382 14.549 14.2853 14.6137 14.1788 14.6578C14.0723 14.702 13.9582 14.7247 13.843 14.7247C13.7277 14.7247 13.6136 14.702 13.5071 14.6578C13.4006 14.6137 13.3039 14.549 13.2224 14.4675L1.52464 2.76977C1.44088 2.6888 1.37409 2.59196 1.32816 2.4849C1.28224 2.37784 1.25809 2.2627 1.25713 2.14621C1.25617 2.02972 1.27843 1.91421 1.32259 1.80641C1.36675 1.69861 1.43194 1.60068 1.51436 1.51835C1.59677 1.43601 1.69476 1.37091 1.8026 1.32685C1.91044 1.28279 2.02598 1.26064 2.14247 1.26171C2.25896 1.26278 2.37407 1.28703 2.48109 1.33306C2.5881 1.37909 2.68488 1.44598 2.76577 1.52981L14.4635 13.2276C14.5451 13.3089 14.6099 13.4055 14.6541 13.5119C14.6983 13.6183 14.7211 13.7323 14.7211 13.8475C14.7211 13.9627 14.6983 14.0768 14.6541 14.1832Z" />
  </Svg>
);
