import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { TicketIcon } from '@cycle-app/ui/icons';
import { useEffect, Fragment, useCallback, useState, ReactNode } from 'react';

import { useEditorContext } from 'src/contexts/editorContext';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { setTemplate } from 'src/reactives/template.reactive';
import { actions, Action as ActionType, ActionId } from 'src/services/editor/editorActions';

import { QuickIntegrations } from '../QuickIntegrations';
import EmbedForm from '../SlashDropdown/EmbedForm/EmbedForm';
import FileForm from '../SlashDropdown/FileForm/FileForm';
import { Container, Action, DropdownStyled } from './QuickActions.styles';

export interface QuickActionsProps {
  className?: string;
  showAddTemplate?: boolean;
  showIntegrations?: boolean;
  applyTemplate: VoidFunction;
  disabledActions?: ActionId[];
  onShowPreviewTemplate?: VoidFunction;
  onHidePreviewTemplate?: VoidFunction;
  docType?: DoctypeFragment;
}

const quickActions = [
  actions[ActionId.File],
  actions[ActionId.Image],
];

const actionsWithDropdown = [ActionId.File, ActionId.Image, ActionId.Embed];

const QuickActions = ({
  className,
  applyTemplate,
  disabledActions = [],
  onShowPreviewTemplate,
  onHidePreviewTemplate,
  docType,
  showAddTemplate = false,
  showIntegrations = false,
}: QuickActionsProps) => {
  const isMobile = useIsMobile();
  const { canReadSettings } = useGetPermission();
  const { editor } = useEditorContext();
  const [dropdownToShow, setDropdownToShow] = useState<ActionId | null>(null);

  const hideDropdown = useCallback(() => {
    setDropdownToShow(null);
  }, []);

  // Ensure that the preview template is closed on unmount
  useEffect(() => onHidePreviewTemplate, [onHidePreviewTemplate]);

  return (
    <Container className={className}>
      {docType?.template && (
        <Action
          onClick={applyTemplate}
          onMouseLeave={onHidePreviewTemplate}
          onMouseEnter={onShowPreviewTemplate}
        >
          <Emoji emoji={docType.emoji} />
          {`${docType.name} template`}
        </Action>
      )}

      {!docType?.template && showAddTemplate && !isMobile && canReadSettings && (
        <Action onClick={() => setTemplate({
          admin: false,
          selectedTemplateId: docType?.template?.id,
          mode: docType?.template ? 'edit' : 'list',
          modalVisible: true,
          docTypeId: docType?.id,
        })}
        >
          <TicketIcon />
          Add template
        </Action>
      )}

      {quickActions
        .filter(action => !disabledActions.includes(action.id))
        .map(action => {
          if (action.id === ActionId.Image || action.id === ActionId.File) {
            return renderDropdown(action, (
              <FileForm
                onCancel={hideDropdown}
                type={action.id}
                submitLabel={`Add ${action.id}`}
              />
            ));
          }

          return (
            <Fragment key={action.id}>
              {renderAction(action)}
            </Fragment>
          );
        })}

      {showIntegrations && <QuickIntegrations />}

      {!disabledActions.includes(ActionId.Embed) && renderDropdown(actions[ActionId.Embed], (
        <EmbedForm onCancel={hideDropdown} />
      ))}
    </Container>
  );

  function renderDropdown(action: ActionType, contentDropdown: ReactNode) {
    return (
      <DropdownStyled
        key={action.id}
        content={contentDropdown}
        placement="bottom-start"
        visible={dropdownToShow === action.id}
        hide={hideDropdown}
      >
        {renderAction(action)}
      </DropdownStyled>
    );
  }

  function renderAction(action: ActionType) {
    return (
      <Action
        key={action.id}
        onClick={() => onActionSelected(action)}
        active={dropdownToShow === action.id}
      >
        {action.quickActionIcon ?? action.icon}
        {action.labelAlt || action.label}
      </Action>
    );
  }

  function onActionSelected(action: ActionType) {
    if (actionsWithDropdown.includes(action.id)) {
      setDropdownToShow(action.id);
    }
    action.toggle?.(editor);
  }
};

export default QuickActions;
