import { ActionButton, SelectOption, TextButton } from '@cycle-app/ui';
import { TagIcon, AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useMemo, useCallback } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { useAttributes } from 'src/hooks/api/useAttributes';
import { useFullDoc } from 'src/hooks/api/useDoc';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { customAttributeTypeData } from 'src/utils/attributes.util';

import { Container, Title, Lines } from './DocPanelPropertiesDropdown.styles';
import DocPanelProperty from './DocPanelProperty';

const DocPanelPropertiesDropdown: FC = () => {
  const { doc } = useFullDoc();
  const properties = useAttributes();

  const { addAttributeDoctype } = useAttributesMutations();

  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);
  const [isLinkPropertyDropdownVisible, {
    setFalseCallback: hideLinkPropertyDropdown,
    setValueCallback: setIsLinkPropertyDropdownVisible,
  }] = useOptimizedBooleanState(false);

  const unlinkedProperties: Array<SelectOption> = useMemo(
    () => properties
      .filter(property => !doc?.doctype.attributeDefinitions.edges.map(a => a.node.id).includes(property.id))
      .map(property => ({
        label: property.name,
        value: property.id,
        icon: customAttributeTypeData[property.__typename].icon,
      })),
    [properties, doc?.doctype.attributeDefinitions.edges],
  );
  const attributeDefinitions = nodeToArray(doc?.doctype?.attributeDefinitions);

  const onUnlinkedPropertyClicked = useCallback(async (option: SelectOption) => {
    const selectedProperty = properties.find(p => p.id === option.value);
    if (!selectedProperty || !doc?.doctype.id) return;
    await addAttributeDoctype(selectedProperty, doc.doctype.id);
  }, [addAttributeDoctype, properties, doc?.doctype.id]);

  if (!doc) return null;

  return (
    <DropdownLayer
      visible={isDropdownVisible}
      hide={hideDropdown}
      content={renderDropdown()}
    >
      <ActionButton
        className={isDropdownVisible ? 'force-focus' : ''}
        onClick={toggleDropdown}
        size="L"
      >
        <TagIcon />
      </ActionButton>
    </DropdownLayer>
  );

  function renderDropdown() {
    return (
      <Container>
        <Title>Properties</Title>

        <Lines>
          {attributeDefinitions.map((attributeDefinition) => (
            <DocPanelProperty
              key={attributeDefinition.id}
              attributeDefinitionId={attributeDefinition.id}
            />
          ))}
        </Lines>

        {doc && unlinkedProperties.length > 0 && (
          <DropdownSelectLayer
            visible={isLinkPropertyDropdownVisible}
            setVisible={setIsLinkPropertyDropdownVisible}
            hide={hideLinkPropertyDropdown}
            onChange={onUnlinkedPropertyClicked}
            options={unlinkedProperties}
            placement="bottom-start"
            layer={Layer.DropdownCustomProperty}
          >
            <TextButton className={isLinkPropertyDropdownVisible ? 'force-focus' : ''} size={12}>
              <AddIcon />
              Link new
            </TextButton>
          </DropdownSelectLayer>
        )}
      </Container>
    );
  }
};

export default DocPanelPropertiesDropdown;
