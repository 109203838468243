import { CheckIcon as CheckIconRaw } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Card } from '../Cards';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { ColorItem } from '../ColorPicker/ColorPicker.styles';
import { Dropdown as DropdownRaw } from '../Dropdown/Dropdown';

type Size = 'S' | 'M';

export interface ContainerProps {
  $size?: Size;
}

export const Container = styled(Card)<ContainerProps>`
  padding: 10px;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  ${p => (p.$size === 'S' && css`height: 32px; width: 32px;`)}
  ${p => (p.$size === 'M' && css`height: 40px; width: 42px;`)}
`;

export const Dropdown = styled(DropdownRaw)`
  padding: 12px;
`;

export const ColorPickerStyled = styled(ColorPicker)`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(6, 1fr);
`;

export const ColorItemStyled = styled(ColorItem)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
`;

export const MotionDiv = styled(motion.div).attrs({
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    type: 'spring',
    duration: 0.25,
    bounce: 0,
  },
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckIcon = styled(CheckIconRaw)`
  width: 10px;
  height: 10px;
`;

export const SelectedColorPicker = styled(ColorPicker)`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
