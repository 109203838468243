import { DocAttributeFragment, CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { Warning, SelectOption, CheckboxInput, CustomPropertyInputText, ActionButton } from '@cycle-app/ui';
import { ArrowRightUpIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { ChangeEvent, FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useAddNewAttributeToDoc, useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import { useFullDoc } from 'src/hooks/api/useDoc';
import { useCompatibility } from 'src/hooks/useCompatibility';
import { customAttributeTypeData, getDocAttributeValue } from 'src/utils/attributes.util';

import { SelectPanelStyled, InputContainer, InputTextContainer, Label, Name } from './PropertyDropdownValue.styles';

interface Props {
  docId: string;
  attributeDefinitionId: string;
  hide: VoidFunction;
  onRemoveValue?: (attributeId: string, valueId: string) => void;
}

const PropertyDropdownValue: FC<Props> = ({
  docId,
  attributeDefinitionId,
  hide,
  onRemoveValue,
}) => {
  const { addNewAttributeToDoc } = useAddNewAttributeToDoc();
  const { doc } = useFullDoc({ docId });
  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeDocAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);
  const {
    getCompatibleOptions,
    shouldDisplayWarning,
    isPropertyRequiredToBeVisible,
  } = useCompatibility();

  const attributeDefinitions: CustomAttributeDefinitionFragment[] = nodeToArray(doc?.doctype?.attributeDefinitions);
  const attributeDefinition = attributeDefinitions.find(a => a.id === attributeDefinitionId);
  const docAttribute = nodeToArray(doc?.attributes).find(a => a.definition.id === attributeDefinitionId);

  const notCompatibleValues = attributeDefinition?.__typename === 'AttributeSingleSelectDefinition'
    ? nodeToArray(attributeDefinition.values)
      .filter(({ id }) => !getCompatibleOptions(attributeDefinition).map(a => a.node.id).includes(id))
      .map(a => a.id) : [];

  if (!doc || !attributeDefinition) return null;

  const { input: inputType } = customAttributeTypeData[attributeDefinition.__typename];
  const attributeValue = docAttribute ? getDocAttributeValue(docAttribute as DocAttributeFragment) : null;

  const isPropertyRequired = isPropertyRequiredToBeVisible(attributeDefinition);
  const warning = !doc.isDraft && shouldDisplayWarning(attributeDefinition) ? 'The doc will leave the view if you update this property' : undefined;
  const dataTestId = `PropertyDropdownValue-docId=${docId}`;

  if (attributeDefinition.__typename === 'AttributeSingleSelectDefinition') {
    const options = nodeToArray(attributeDefinition.values).map(({
      id,
      value,
    }) => ({
      value: id,
      label: value,
      end: !doc.isDraft && notCompatibleValues.includes(id) ? (
        <Warning tooltip="The doc will leave the view if you choose this value" />
      ) : undefined,
    }));

    const selectedValue = docAttribute?.__typename === 'DocAttributeSingleSelect' ? docAttribute.selectValue?.id : undefined;

    return (
      <SelectPanelStyled
        dataTestId={dataTestId}
        options={options}
        onOptionChange={async (selectedOption) => {
          hide();
          await onSelectChange(selectedOption);
        }}
        selectedValue={selectedValue}
        onClearValue={selectedValue ? onClear : undefined}
        warningOnNoneValue={isPropertyRequired}
        onCreateOption={async (label) => {
          await addNewAttributeToDoc({
            textValue: label,
            attributeDefinition,
            doc,
          });
          hide();
        }}
      />
    );
  }

  if (attributeDefinition.__typename === 'AttributeMultiSelectDefinition') {
    const selectedValuesIds: string[] = docAttribute?.__typename === 'DocAttributeMultiSelect'
      ? docAttribute.selectValues?.map(v => v.id) ?? []
      : [];

    const options: SelectOption[] = nodeToArray(attributeDefinition.values).map(({
      id,
      value,
    }) => ({
      value: id,
      label: value,
      selected: selectedValuesIds.includes(id),
    }));

    return (
      <SelectPanelStyled
        data-testid={dataTestId}
        isMulti
        options={options}
        onCreateOption={async (label) => {
          await addNewAttributeToDoc({
            textValue: label,
            attributeDefinition,
            doc,
          });
        }}
        onSelectOption={onSelectChange}
        onUnselectOption={removeValue}
      />
    );
  }

  if (inputType === 'email' ||
    inputType === 'phone' ||
    inputType === 'number' ||
    inputType === 'date' ||
    inputType === 'url' ||
    inputType === 'text') {
    return (
      <InputTextContainer onClick={e => e.stopPropagation()}>
        <CustomPropertyInputText
          variant="dropdown"
          label={(
            <Label>
              <Name>{attributeDefinition.name}</Name>
              {inputType === 'url' && (
                <>
                  <ActionButton
                    tooltip="Open"
                    tooltipPlacement="top"
                    onClick={() => {
                      window.open(String(attributeValue), '_blank', 'noopener,noreferrer');
                    }}
                  >
                    <ArrowRightUpIcon />
                  </ActionButton>

                  <ActionButton
                    tooltip="Copy link"
                    tooltipPlacement="top"
                    onClick={async () => {
                      await navigator.clipboard.writeText(String(attributeValue));
                    }}
                  >
                    <DuplicateIcon />
                  </ActionButton>
                </>
              )}
            </Label>
          )}
          type={inputType}
          values={[attributeValue as string]}
          onInputChange={onInputChange}
          warning={warning}
          hasHelper={false}
        />
      </InputTextContainer>
    );
  }

  if (inputType === 'checkbox') {
    return (
      <InputContainer onClick={e => e.stopPropagation()}>
        <CheckboxInput
          id={attributeDefinitionId}
          value="on"
          label={attributeDefinition.name}
          defaultChecked
          onChange={onCheckboxChange}
        />
        {warning && <Warning tooltip={warning} />}
      </InputContainer>
    );
  }

  return null;

  async function onSelectChange(selectedValue: SelectOption) {
    if (!attributeDefinition || !doc) return;

    await changeDocAttributeValue({
      attributeDefinition,
      doc,
      value: selectedValue.value,
      notCompatible: notCompatibleValues.includes(selectedValue.value),
    });
  }

  async function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    if (!attributeDefinition || !doc) return;
    if (e.target.value === '') {
      if (!docAttribute) return;
      await removeValue({
        label: docAttribute.id,
        value: docAttribute.id,
      });
    } else {
      await changeDocAttributeValueDebounced({
        attributeDefinition,
        doc,
        value: e.target.value,
        notCompatible: !!warning,
      });
    }
  }

  async function onCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    if (!attributeDefinition || !doc) return;

    await changeDocAttributeValue({
      attributeDefinition,
      doc,
      value: e.target.checked ? 'checked' : '',
      notCompatible: !!warning,
    });
  }

  async function onClear() {
    const selectedValue = docAttribute?.__typename === 'DocAttributeSingleSelect' ? docAttribute.selectValue : undefined;
    if (!selectedValue) return;

    await removeValue({
      value: selectedValue.id,
      label: selectedValue.value,
    });
    hide();
  }

  async function removeValue({
    label, value,
  }: SelectOption) {
    if (!attributeDefinition) return;

    onRemoveValue?.(attributeDefinition.id, label);
    await removeDocAttributeValue({
      doc,
      attributeDefinition,
      valueId: value,
      notCompatible: isPropertyRequired,
    });
  }
};

export default PropertyDropdownValue;
