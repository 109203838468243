import { FC } from 'react';
import { createPortal } from 'react-dom';

import { PortalId } from 'src/types/portal.types';

interface Props {
  portalId: PortalId;
}

const Portal: FC<Props> = ({
  portalId, children,
}) => {
  const portal = document.getElementById(`portal-${portalId}`);

  if (!portal) {
    throw new Error(`Element #${`portal-${portalId}`} not present in document`);
  }

  return createPortal(children, portal);
};

export default Portal;
