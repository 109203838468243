import styled from 'styled-components';

export const FillSvg = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
})`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
