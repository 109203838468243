import { InfoColorType } from '@cycle-app/utilities';
import { useRef, useEffect } from 'react';

import { getColorTypeByPercentage } from '../../utils/colors.utils';
import { ProgressBar, Circle, ProgressCircle } from './Progress.styles';

export type ProgressProps = {
  percentage?: number;
  type?: 'bar' | 'ring';
  isNeutral?: boolean;
  useUserColor?: boolean;
  className?: string;
};

export const Progress = ({
  percentage = 0,
  type = 'bar',
  /**
   * If neutral, color won't be added based on the percentage
   */
  isNeutral = false,
  useUserColor,
  className,
}: ProgressProps) => {
  const progressRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    if (type !== 'ring') return;

    const circle = progressRef.current;

    if (!circle) return;

    const normalizePercentage = percentage / 100;
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    const offset = circumference - normalizePercentage * circumference;
    circle.style.strokeDashoffset = `${offset}`;
  }, [percentage, type]);

  const defaultColorType: InfoColorType | undefined = useUserColor ? 'default' : undefined;
  const colorType = isNeutral ? defaultColorType : getColorTypeByPercentage(percentage);

  if (type === 'ring') {
    return (
      <svg
        className={className}
        height="32"
        width="32"
        viewBox="0 0 32 32"
      >
        <Circle
          strokeWidth="4"
          r="14"
          cx="16"
          cy="16"
          $colorType={colorType}
        />
        <ProgressCircle
          ref={progressRef}
          strokeWidth="4"
          r="14"
          cx="16"
          cy="16"
          strokeDasharray="10 20"
          $colorType={colorType}
        />
      </svg>
    );
  }

  return <ProgressBar className={className} $percentage={percentage} $colorType={colorType} />;
};
