import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

type DraftCommentsState = Record<string, string>;

const {
  hookValue,
  getValue,
  setValue,
} = make<DraftCommentsState>({
  defaultState: {},
  localKey: LocalKey.DraftComments,
  mergeTarget: 'initial',
});

export const useDraftComment = (docId: string) => {
  const draftComments = hookValue();
  const draftComment = draftComments[docId];

  const setDraftComment = useDebouncedCallback((comment: string) => {
    if (comment === draftComment) return;

    if (comment === '<p></p>') {
      deleteDraftComment();
      return;
    }

    setValue({
      ...draftComments,
      [docId]: comment,
    });
  }, INPUT_ONCHANGE_DEBOUNCE);

  const deleteDraftComment = () => {
    setDraftComment.cancel();
    if (!draftComment) return;
    const {
      [docId]: _, ...rest
    } = draftComments;
    setValue(rest);
  };

  return {
    draftComment,
    deleteDraftComment,
    setDraftComment,
  };
};

export const deleteDraftComments = (docIds: string[]) => {
  const newValue = { ...getValue() };
  for (const docId of docIds) delete newValue[docId];
  setValue(newValue);
};
