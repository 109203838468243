import { MeFragment } from '@cycle-app/graphql-codegen';
import { Avatar, Input } from '@cycle-app/ui';
import { slugify, readFileSrc } from '@cycle-app/utilities';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import defaultLogo from 'src/assets/workspace-default-logo.png';
import { FieldLabel } from 'src/components/Form/Form.styles';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useEnhancedForm, ErrorMap } from 'src/hooks/form/useEnhancedForm';
import { useErrorToaster } from 'src/hooks/useErrorToaster';
import { useCreateWorkspace } from 'src/hooks/user/useCreateWorkspace';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { AsideApp } from '../OnboardingLayout/AsideApp';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { ErrorHelper, Footer, Form, FormGrid, NextButton } from '../OnboardingLayout/OnboardingLayout.styles';
import { Logo } from './Logo';
import { Domain, LogoInput, LogoInputPreview, StyledImageInput, UploadButton } from './OnboardingStepWorkspace.styles';

interface Props {
  me: MeFragment;
}

interface FormData {
  name: string;
  logoString: string;
  logoFile: File | null;
  slug: string;
}

const slugErrorPattern = /slug/i;

const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    messagePattern: slugErrorPattern,
    fieldName: 'slug',
  },
];

const formatSlugInputError = (message?: string) => {
  if (message?.match(slugErrorPattern)) {
    return 'This workspace domain is already used, please try something else.';
  }
  return message;
};

export const OnboardingStepWorkspace: FC<Props> = ({ me }) => {
  const {
    handleSubmit,
    setValue,
    watch,
    register,
    control,
    getFieldState,
    formState: { errors },
    displayFieldsErrors,
    clearErrors,
    setError,
  } = useEnhancedForm<FormData>({
    defaultValues: {
      name: '',
      slug: '',
      logoFile: null,
      logoString: defaultLogo,
    },
  });
  const { add: addErrorToaster } = useErrorToaster();
  const {
    logoString, name, slug,
  } = watch();
  const {
    createWorkspace, isLoading,
  } = useCreateWorkspace(me, {
    onError: (apolloError) => {
      if (apolloError.message.match('code 413')) {
        setError('logoFile', {
          type: 'manual',
          message: 'This image was not accepted. Please try another one.',
        });
      } else {
        addErrorToaster({
          message: ErrorMessage._GENERIC,
        });
      }
    },
  });

  const onSubmit = async (data: FormData) => {
    const logoInput = data.logoFile
      ? { avatar: data.logoFile }
      : { avatarUrl: data.logoString };
    const result = await createWorkspace({
      variables: {
        name: data.name,
        slug: data.slug,
        logo: logoInput,
        inviteMe: true,
      },
    });
    if (result.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap);
      return;
    }
    setOnboarding({ screen: LightOnboardingScreen.Readonly });
  };

  return (
    <OnboardingLayout
      title="Let’s create your workspace"
      aside={(
        <AsideApp
          color={me.color}
          logo={<Logo src={logoString} name={name} />}
          avatar={(
            <Avatar
              user={me}
              size={18}
              userColor={me.color}
              src={me.avatar?.url}
              pending={false}
            />
          )}
        />
      )}
      main={(
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGrid>
            <div>
              <FieldLabel>Workspace logo</FieldLabel>
              <StyledImageInput
                previewModalTitle="Confirm your logo"
                previewModalSubmitLabel="Set new logo"
                previewBorderColor={me.color}
                onChange={async (newLogoFile) => {
                  clearErrors('logoFile');
                  setValue('logoFile', newLogoFile);
                  const logoSrc = await readFileSrc(newLogoFile);
                  setValue('logoString', logoSrc);
                }}
              >
                {(inputRef) => (
                  <LogoInput>
                    <LogoInputPreview onClick={() => inputRef.current?.click()} src={logoString} />
                    <UploadButton
                      variant="ternary"
                      size="L"
                      onClick={(e) => {
                        e.preventDefault();
                        inputRef.current?.click();
                      }}
                    >
                      Upload image
                    </UploadButton>
                  </LogoInput>
                )}
              </StyledImageInput>
              {errors.logoFile?.message && <ErrorHelper $hasError $size="S">{errors.logoFile.message}</ErrorHelper>}
            </div>
            <div>
              <Input
                autoFocus
                label="Workspace name"
                placeholder="Example"
                error={errors.name?.message}
                {...register('name', {
                  required: true,
                  onChange: (e) => {
                    if (!getFieldState('slug').isDirty || !slug) {
                      clearErrors('slug');
                      setValue('slug', slugify(e.target.value));
                    }
                  },
                })}
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="slug"
                render={({
                  field: {
                    value, onChange,
                  },
                }) => {
                  return (
                    <Input
                      iconBefore={<Domain>cycle.com/</Domain>}
                      iconPadding={80}
                      value={slugify(value, false)}
                      onChange={onChange}
                      label="Domain"
                      error={formatSlugInputError(errors.slug?.message)}
                      helperSize="S"
                    />
                  );
                }}
              />
            </div>
          </FormGrid>
          <Footer>
            <NextButton
              size="M"
              isLoading={isLoading}
              disabled={!name.trim() || !slug.trim() || !!Object.keys(errors).length}
              type="submit"
            >
              Next
            </NextButton>
          </Footer>
        </Form>
      )}
    />
  );
};
