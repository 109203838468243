import styled from 'styled-components';

import { InputCss } from '../Input/Input.styles';

export const StyledTextArea = styled.textarea`
  ${InputCss};
  width: 100%;
  padding: 11px 16px;
  border: 1px solid ${p => p.theme.colors.border.input};
  border-radius: 4px;
  resize: vertical;
`;
