import { Color } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { nodeToArray } from '@cycle-app/utilities';
import * as FullStory from '@fullstory/browser';
import { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import step1gifDark from 'src/assets/capture-feedback-dark.gif';
import step1pngDark from 'src/assets/capture-feedback-dark.png';
import step1gif from 'src/assets/capture-feedback.gif';
import step1png from 'src/assets/capture-feedback.png';
import step2gifDark from 'src/assets/extract-insights-dark.gif';
import step2pngDark from 'src/assets/extract-insights-dark.png';
import step2gif from 'src/assets/extract-insights.gif';
import step2png from 'src/assets/extract-insights.png';
import step3gifDark from 'src/assets/to-process-dark.gif';
import step3pngDark from 'src/assets/to-process-dark.png';
import step3gif from 'src/assets/to-process.gif';
import step3png from 'src/assets/to-process.png';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { EmptyState } from 'src/components/Views/EmptyState';
import { useAuthFromUrl } from 'src/hooks/user/useAuthFromUrl';
import { useRole } from 'src/hooks/useRoles';
import { setOnboarding, useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { isProductionEnv } from 'src/utils/env.util';

import { OnboardingStepAccount } from '../OnboardingStepAccount/OnboardingStepAccount';
import { OnboardingStepCustomize } from '../OnboardingStepCustomize/OnboardingStepCustomize';
import { OnboardingStepDone } from '../OnboardingStepDone/OnboardingStepDone';
import { OnboardingStepPassword } from '../OnboardingStepPassword/OnboardingStepPassword';
import { OnboardingStepReadonly } from '../OnboardingStepReadonly/OnboardingStepReadonly';
import { OnboardingStepWelcome } from '../OnboardingStepWelcome/OnboardingStepWelcome';
import { OnboardingStepWorkspace } from '../OnboardingStepWorkspace/OnboardingStepWorkspace';
import { Loading } from './Onboarding.styles';

const Loader = () => <Loading><Spinner /></Loading>;

export const Onboarding = () => {
  const { tokenFromUrl } = useAuthFromUrl();
  const {
    isLoading, me, error,
  } = useRole();

  useEffect(() => {
    if (me && isProductionEnv()) {
      FullStory.identify(me.id, {
        displayName: `${me.firstName} ${me.lastName}`,
        email: me.email,
      });
    }
  }, [me]);

  const {
    screen, theme,
  } = useGetOnboarding();
  const products = useMemo(() => nodeToArray(me?.products), [me]);

  if (error) return <ErrorPage />;

  if ((tokenFromUrl && !me) || isLoading) return <Loader />;

  const content = () => {
    switch (screen) {
      case LightOnboardingScreen.AccountVerify:
        if (me?.onboarded) {
          return <EmptyState />;
        }
        // No need to verify the account. (comes from email invit)
        if (products.length) {
          setOnboarding({ screen: LightOnboardingScreen.AccountInfos });
          return null;
        }
        return <OnboardingStepAccount />;
      case LightOnboardingScreen.AccountInfos:
        if (me?.onboarded) {
          setOnboarding({ screen: LightOnboardingScreen.AccountPreferences });
          return null;
        }
        return me ? <OnboardingStepPassword me={me} /> : <Loader />;
      case LightOnboardingScreen.AccountPreferences:
        return me ? <OnboardingStepCustomize me={me} products={products} /> : <Loader />;
      case LightOnboardingScreen.Workspace:
        if (products.length) {
          setOnboarding({ screen: LightOnboardingScreen.Done });
          return null;
        }
        return me ? <OnboardingStepWorkspace me={me} /> : <Loader />;
      case LightOnboardingScreen.Readonly:
      case LightOnboardingScreen.Readonly2:
      case LightOnboardingScreen.Readonly3:
      case LightOnboardingScreen.Readonly4:
        return <OnboardingStepReadonly />;
      case LightOnboardingScreen.Done:
        return me ? <OnboardingStepDone products={products} /> : <Loader />;
      default:
        if (me?.onboarded) {
          return <EmptyState />;
        }
        return <OnboardingStepWelcome />;
    }
  };

  return (
    <ThemeProvider theme={getTheme(theme, Color.A)}>
      <Helmet>
        <link rel="prefetch" as="image" href={step1gifDark} />
        <link rel="prefetch" as="image" href={step1pngDark} />
        <link rel="prefetch" as="image" href={step1gif} />
        <link rel="prefetch" as="image" href={step1png} />
        <link rel="prefetch" as="image" href={step2pngDark} />
        <link rel="prefetch" as="image" href={step2png} />
        <link rel="prefetch" as="image" href={step2gifDark} />
        <link rel="prefetch" as="image" href={step2gif} />
        <link rel="prefetch" as="image" href={step3gifDark} />
        <link rel="prefetch" as="image" href={step3pngDark} />
        <link rel="prefetch" as="image" href={step3gif} />
        <link rel="prefetch" as="image" href={step3png} />
      </Helmet>
      {content()}
    </ThemeProvider>
  );
};
