import { routing, PageId } from 'src/constants/routing.constant';
import { useActiveProductTourStep } from 'src/reactives/productTours.reactive';
import { TourName, ProductTourLock, TourStep } from 'src/types/productTour.types';

import { useRouteMatch } from '../router/useRouteMatch';

type UseLockProductTourReturn = {
  locks: ProductTourLock[];
};

const DEFAULT_LOCK: UseLockProductTourReturn = { locks: [] };
const STEPS_WHERE_DOC_SHOULD_BE_OPEN = [
  TourStep.HighlightFeedbackText,
  TourStep.TurnHighlightIntoInsight,
  TourStep.SetNewInsightInitiative,
  TourStep.SetNewInsightProperties,
  TourStep.SeeCreatedInsight,
  TourStep.ProcessFirstFeedback,
  TourStep.StartGamification,
];

export const useLockProductTour = (tourName?: TourName): UseLockProductTourReturn => {
  const inboxRoute = useRouteMatch(routing[PageId.Inbox]);
  const currentStep = useActiveProductTourStep();

  if (currentStep === null) return DEFAULT_LOCK;

  const getRedirectLock = () => {
    const isFirstProductTourActive = tourName === TourName.FIRST_TOUR;
    const isOnInboxPage = !!inboxRoute;
    const shouldCloseDocPanel = !STEPS_WHERE_DOC_SHOULD_BE_OPEN.includes(currentStep) &&
      // Wanted to use const { pathname } = useLocation(); but not working
      window.location.href.includes('/doc/');

    if (
      (isFirstProductTourActive && !isOnInboxPage) ||
      (isFirstProductTourActive && shouldCloseDocPanel)
    ) return [ProductTourLock.REDIRECT_INBOX];

    return [];
  };

  const getEmptyInboxLock = () => {
    if (tourName !== TourName.FIRST_TOUR) return [];

    if (![
      TourStep.CreateFirstFeedback,
      TourStep.SeeProperties,
      TourStep.WriteDocContent,
    ].includes(currentStep)) return [];

    return [ProductTourLock.SHOW_EMPTY_INBOX];
  };

  const locks = [
    ...getRedirectLock(),
    ...getEmptyInboxLock(),
  ];

  return { locks };
};
