import { ChangeAttributeColorDocument, DoctypeFragment } from '@cycle-app/graphql-codegen';
import { NuancePicker, TextButton, SelectOption } from '@cycle-app/ui';
import { UnlinkIcon, AddIcon } from '@cycle-app/ui/icons';
import { FC, useCallback, useState, useMemo } from 'react';

import AttributeOptionsDropdown from 'src/app/Main/Settings/SettingsAttributes/AttributeOptionsDropdown';
import { AttributeUnlinkDoctypeModal } from 'src/components/AttributeUnlinkDoctypeModal/AttributeUnlinkDoctypeModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useAttributes } from 'src/hooks/api/useAttributes';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import {
  Container,
  AddNewLine,
  PropertyLine,
  PropertyName,
  StyledColorPicker,
  PropertyIcon,
  StyledSelectPanel,
} from './SettingsDocTypeProperties.styles';

interface Props {
  doctype?: DoctypeFragment;
  disabled?: boolean;
  onAddProperty: (propertyId: string) => void;
  onRemoveProperty: (propertyId: string) => void;
}

const SettingsDocTypeProperties: FC<Props> = ({
  doctype,
  disabled = false,
  onAddProperty,
  onRemoveProperty,
}) => {
  const allAttributes = useAttributes();

  const [updateAttributeColor] = useSafeMutation(ChangeAttributeColorDocument);

  const [isNewPropertyDropdownVisible, {
    toggleCallback: toggleNewPropertyDropdown,
    setFalseCallback: hideNewPropertyDropdown,
  }] = useOptimizedBooleanState(false);
  const [propertyIdToRemove, setPropertyIdToRemove] = useState<string | null>(null);

  const onOptionChange = useCallback((selectedOption: SelectOption) => {
    hideNewPropertyDropdown();
    onAddProperty(selectedOption.value);
  }, [hideNewPropertyDropdown, onAddProperty]);

  const linkedProperties = useMemo(() => doctype?.attributeDefinitions.edges
    .filter(e => !!e.node.__typename)
    .map(e => ({
      ...e.node,
      type: getCustomAttributeTypeData(e.node.__typename),
    })) || [], [doctype?.attributeDefinitions.edges]);

  const linkedPropertyIds = linkedProperties.map(p => p.id);
  const unlinkedProperties = allAttributes.filter(p => !linkedPropertyIds.includes(p.id));

  const resetPropertyIdToRemove = useCallback(() => setPropertyIdToRemove(null), []);

  const attribute = useMemo(() => linkedProperties.find(property => property.id === propertyIdToRemove), [linkedProperties, propertyIdToRemove]);

  return (
    <Container>
      {linkedProperties.map(property => (
        <PropertyLine key={property.id}>
          <DotsMenuLayer
            vertical
            placement="bottom-start"
            options={[
              {
                label: 'Unlink',
                value: 'Unlink',
                icon: <UnlinkIcon />,
                onSelect: () => setPropertyIdToRemove(property.id),
              },
            ]}
          />

          <NuancePicker
            color={property.color}
            // TODO: Mutation not yet implemented on the backend, check that it works well once done
            onClick={(newColor) => updateAttributeColor({
              variables: {
                attributeId: property.id,
                color: newColor,
              },
            })}
            placement="bottom-start"
          >
            <StyledColorPicker color={property.color} />
          </NuancePicker>

          <PropertyIcon>{property.type.icon}</PropertyIcon>
          <PropertyName>{property.name}</PropertyName>

          {'values' in property && (
            <AttributeOptionsDropdown attribute={property} />
          )}
        </PropertyLine>
      ))}

      <AddNewLine>
        <DropdownLayer
          visible={isNewPropertyDropdownVisible}
          hide={hideNewPropertyDropdown}
          placement="bottom-start"
          content={(
            <StyledSelectPanel
              title="Property"
              options={unlinkedProperties.map(a => ({
                value: a.id,
                label: a.name,
                icon: getCustomAttributeTypeData(a.__typename).icon,
              }))}
              onOptionChange={onOptionChange}
            />
          )}
        >
          {unlinkedProperties.length > 0 && (
            <TextButton
              disabled={disabled}
              onClick={toggleNewPropertyDropdown}
            >
              <AddIcon />
              Link New
            </TextButton>
          )}
        </DropdownLayer>
      </AddNewLine>
      {propertyIdToRemove && doctype && attribute && (
        <AttributeUnlinkDoctypeModal
          doctype={doctype}
          attribute={attribute}
          hide={resetPropertyIdToRemove}
          onConfirm={() => onRemoveProperty(propertyIdToRemove)}
        />
      )}
    </Container>
  );
};

export default SettingsDocTypeProperties;
