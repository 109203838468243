import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { SlackIcon, IntercomIcon, CycleIcon, SyncIcon, HubSpotIcon } from '@cycle-app/ui/icons';

import { PageId } from 'src/constants/routing.constant';
import { useProductIntegrations } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { SyncButton } from './SyncedWith.styles';

export const SyncedWith = () => {
  const { canReadSettings } = useGetPermission();
  const { navigate } = useNavigate();
  return (
    <SyncButton
      iconStart={<SyncIcon />}
      variant="secondary"
      useUserColor={false}
      size="S"
      disabled={!canReadSettings}
      onClick={() => navigate(PageId.SettingsIntegrations)}
    >
      Synchronized with
      <Tooltip content="Cycle"><CycleIcon /></Tooltip>
      <SyncedIntegrations />
    </SyncButton>
  );
};

const SyncedIntegrations = () => {
  const { list } = useProductIntegrations();
  const Slack = list.find(i => i.type === IntegrationType.Slack && !!i.provider) ? SlackIcon : null;
  const Intercom = list.find(i => i.type === IntegrationType.Intercom && !!i.provider) ? IntercomIcon : null;
  const Hubspot = list.find(i => i.type === IntegrationType.Hubspot && !!i.provider) ? HubSpotIcon : null;
  return (
    <>
      {Slack && (
        <Tooltip content="Slack">
          <Slack />
        </Tooltip>
      )}
      {Intercom && (
        <Tooltip content="Intercom">
          <Intercom />
        </Tooltip>
      )}
      {Hubspot && (
        <Tooltip content="Hubspot">
          <Hubspot />
        </Tooltip>
      )}
    </>
  );
};
