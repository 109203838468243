import { CompanyFragment } from '@cycle-app/graphql-codegen';
import { TrashIcon, PenIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { CompanyRemoveModal } from './CompanyRemoveModal';
import { CompanyUpdateModal } from './CompanyUpdateModal';
import { StyledDotsMenuLayer } from './Customers.styles';

type Props = {
  company: CompanyFragment;
};

export const CompanyActionsMenu: FC<Props> = ({ company }) => {
  const [isUpdateModalOpen, {
    setFalseCallback: closeUpdateModal, setTrueCallback: openUpdateModal,
  }] = useOptimizedBooleanState(false);
  const [isRemoveModalOpen, {
    setFalseCallback: closeRemoveModal, setTrueCallback: openRemoveModal,
  }] = useOptimizedBooleanState(false);

  return (
    <>
      <StyledDotsMenuLayer
        tooltip="Company actions"
        placement="bottom-end"
        options={[
          {
            value: 'edit',
            label: 'Edit',
            icon: <PenIcon />,
            onSelect: openUpdateModal,
          },
          {
            value: 'delete',
            label: 'Delete',
            icon: <TrashIcon />,
            disabled: company.isDefault,
            variant: 'danger',
            onSelect: openRemoveModal,
          },
        ]}
      />
      {isUpdateModalOpen && (
        <CompanyUpdateModal
          company={company}
          defaultValues={{
            name: company.name ?? '',
            logo: company.logo,
          }}
          onClose={closeUpdateModal}
        />
      )}
      {isRemoveModalOpen && (
        <CompanyRemoveModal
          company={company}
          onClose={closeRemoveModal}
        />
      )}
    </>
  );
};
