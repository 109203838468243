import { BoardFragment } from '@cycle-app/graphql-codegen';
import { KanbanIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useState, FC } from 'react';

import { DeleteBoardModal } from 'src/app/Main/Board/DeleteBoardModal/DeleteBoardModal';
import { BILLING_LIMIT_BOARDS } from 'src/constants/billing.constants';
import { useProductCounters } from 'src/hooks';
import { useBoardSections } from 'src/hooks/api/useBoardSections';

import { DeletableItemList, DeleteItemSkeleton, StyledBoardEmoji } from './BillingDowngrade.styles';
import { BillingDowngradeDeletableItem } from './BillingDowngradeDeletableItem';
import { BillingDowngradeFeatureItem } from './BillingDowngradeFeatureItem';
import { BillingDowngradeModal } from './BillingDowngradeModal';

interface Props {
  onClose: VoidFunction;
}

export const BillingDowngradeModalBoards: FC<Props> = ({ onClose }) => {
  const {
    boardsCount, inboxCountWithMyInbox,
  } = useProductCounters();
  const {
    sections, loading, inboxWitoutMyInbox,
  } = useBoardSections();
  const boards = useMemo(() => (
    sections.reduce<Pick<BoardFragment, 'id' | 'name' | 'emoji'>[]>((acc, section) => {
      const sectionBoards = nodeToArray(section.boards).map(board => ({
        id: board.id,
        name: board.name,
        emoji: board.emoji,
      }));
      return [
        ...acc,
        ...sectionBoards,
      ];
    }, [])
  ), [sections]);
  const [boardToDelete, setBoardToDelete] = useState<typeof boards[number] | null>(null);
  return (
    <>
      <BillingDowngradeModal
        onHide={onClose}
        title="Remove views"
        top={(
          <BillingDowngradeFeatureItem
            count={boardsCount + inboxCountWithMyInbox}
            icon={<KanbanIcon />}
            limit={BILLING_LIMIT_BOARDS}
            name="Unlimited views"
          />
        )}
      >
        {loading && (
          <DeletableItemList>
            <li><DeleteItemSkeleton /></li>
            <li><DeleteItemSkeleton /></li>
            <li><DeleteItemSkeleton /></li>
          </DeletableItemList>
        )}
        {!!boards.length && (
          <DeletableItemList>
            {[...inboxWitoutMyInbox, ...boards].map(board => (
              <BillingDowngradeDeletableItem
                key={board.id}
                onDelete={() => setBoardToDelete(board)}
              >
                <StyledBoardEmoji emoji={board.emoji} inline />
                {board.name}
              </BillingDowngradeDeletableItem>
            ))}
          </DeletableItemList>
        )}
      </BillingDowngradeModal>
      {boardToDelete && (
        <DeleteBoardModal
          boardId={boardToDelete.id}
          onHide={() => setBoardToDelete(null)}
        />
      )}
    </>
  );
};
