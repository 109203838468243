import { AddIcon } from '@cycle-app/ui/icons';

import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { CompanyAddModal } from './CompanyAddModal';
import { ActionButton } from './Customers.styles';

export const CompanyAddButton = () => {
  const [isAddCustomerModalVisible, {
    setTrueCallback: showAddCustomerModal,
    setFalseCallback: hideAddCustomerModal,
  }] = useOptimizedBooleanState(false);

  return (
    <>
      <ActionButton
        iconStart={<AddIcon />}
        onClick={showAddCustomerModal}
      >
        Add
      </ActionButton>

      {isAddCustomerModalVisible && (
        <CompanyAddModal
          onClose={hideAddCustomerModal}
        />
      )}
    </>
  );
};
