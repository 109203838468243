import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { motion } from 'framer-motion';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, 24px);
`;

interface ColorItemProps {
  $color: ColorApi;
  $hasIcon?: boolean;
  $small?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}
export const ColorItem = styled.div<ColorItemProps>`
  background-color: ${p => p.theme.nuances[p.$color].main};
  border-radius: 50%;
  cursor: ${p => (p.onClick ? 'pointer' : 'initial')};
  width: 24px;
  height: 24px;

  ${p => p.$small && css`
    width: 20px;
    height: 20px;
  `};

  ${p => p.$hasIcon && css`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${p.theme.colors.text.white};
      width: 12px;
      height: 12px;
    }
  `}
`;

export const CheckContainer = styled(motion.div).attrs({
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    type: 'spring',
    duration: 0.25,
    bounce: 0,
  },
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;
