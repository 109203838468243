import { ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

export const StyledDropdownLayer = styled(DropdownLayer)`
  ${ShyScrollbarCss}

  [data-scroll] & {
    max-height: calc(100vh - 48px);
  }
`;
