import { FC } from 'react';

import { StyledBoardEmoji } from './BoardsIllustrationCard.styles';
import { CardIconsGrid, CardIconsGridItem } from './IllustationCards.styles';

interface Props {
  className?: string;
}

export const BoardsIllustrationCard: FC<Props> = ({ className }) => (
  <CardIconsGrid className={className}>
    <CardIconsGridItem>
      <StyledBoardEmoji emoji="art" size={24} />
      <StyledBoardEmoji emoji="heart_eyes" size={24} />
    </CardIconsGridItem>
    <CardIconsGridItem>
      <StyledBoardEmoji emoji="four_leaf_clover" size={24} />
      <StyledBoardEmoji emoji="cherry_blossom" size={24} />
    </CardIconsGridItem>
    <CardIconsGridItem>
      <StyledBoardEmoji emoji="fire" size={24} />
    </CardIconsGridItem>
    <CardIconsGridItem>
      <StyledBoardEmoji emoji="bulb" size={24} />
      <StyledBoardEmoji emoji="test_tube" size={24} />
    </CardIconsGridItem>
  </CardIconsGrid>
);
