// import { DragOverEvent } from '@dnd-kit/core';
import { useCallback, useMemo } from 'react';

import { SWIMLANE_NO_VALUE_ID } from 'src/constants/boardGroups.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import useManageSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageSwimlanes';
import { useMoveDocs } from 'src/hooks/api/mutations/useMoveDoc';
import useBoardWithSwimlane from 'src/hooks/api/useBoardWithSwimlane';
import { setBoardDnd } from 'src/reactives/boardDnd.reactive';
import { Items } from 'src/types/item.types';
import { getSwimlanesCollisionDetection } from 'src/utils/dnd.util';

import { OnItemsMovedParams, OnSwimlaneSortedParams, useGroupsDnd } from './useGroupsDnd';

export default function useSwimlaneDocsDnd() {
  const isDndGroupsEnabled = useBoardConfig(ctx => ctx.isDndGroupsEnabled);
  const {
    boardConfigId,
    swimlanes,
    swimlaneFlattenGroups,
    getDoc,
    groupByProperty,
  } = useBoardWithSwimlane();

  const { moveSwimlane } = useManageSwimlanes(boardConfigId);
  const { moveDocs } = useMoveDocs();

  const initialItems: Items = useMemo(() => swimlaneFlattenGroups
    .reduce(
      (acc, docGroup) => ({
        ...acc,
        [docGroup.node.id]: docGroup.node.docs.edges.map(e => e.node.id),
      }),
      {},
    ), [swimlaneFlattenGroups]);

  const initialSwimlaneItems = useMemo(() => (
    swimlanes.edges.map(e => e.node.swimlaneDoc?.id ?? SWIMLANE_NO_VALUE_ID)
  ), [swimlanes]);

  /**
   * Callbacks
   */
  const onItemsMoved = useCallback(async ({
    groupId,
    previousGroupIds,
    itemsId,
    updatedItems,
    position,
  }: OnItemsMovedParams) => {
    setBoardDnd({ dragging: false });

    await moveDocs({
      groupId,
      previousGroupIds,
      docsMoved: itemsId.map(itemId => getDoc(itemId)),
      docsListUpdated: updatedItems[groupId].map(itemId => getDoc(itemId)),
      groupByProperty,
      position,
    });
  }, [getDoc, moveDocs, groupByProperty]);

  const onSwimlaneSorted = useCallback(async ({
    swimlaneActiveId,
    position,
  }: OnSwimlaneSortedParams) => {
    setBoardDnd({ dragging: false });

    const swimlane = swimlanes.edges.find(e => e.node.swimlaneDoc?.id === swimlaneActiveId);
    if (!swimlane || !boardConfigId) return;

    const swimlaneBefore = 'before' in position ? swimlanes.edges.find(e => e.node.swimlaneDoc?.id === position.before)?.node : undefined;
    const swimlaneAfter = 'after' in position ? swimlanes.edges.find(e => e.node.swimlaneDoc?.id === position.after)?.node : undefined;

    await moveSwimlane({
      swimlaneId: swimlane.node.id,
      position: swimlaneBefore?.swimlaneDoc
        ? { before: swimlaneBefore.id }
        : { after: swimlaneAfter?.id ?? '' },
    });
  }, [boardConfigId, swimlanes, moveSwimlane]);

  const onDndStart = useCallback(() => setBoardDnd({ dragging: true }), []);
  // const shouldPreventDragOver = useCallback((e: DragOverEvent): boolean => {
  //   const canHaveSwimlaneDoctypeParent: boolean = e.active.data.current?.canHaveSwimlaneDoctypeParent ?? true;
  //   if (canHaveSwimlaneDoctypeParent) return false;
  //   return e.over?.data.current?.swimlaneDocId !== SWIMLANE_NO_VALUE_ID;
  // }, []);

  const {
    dndContextProps,
    direction,
    activeId,
    activeType,
    items,
    swimlaneItems,
  } = useGroupsDnd({
    initialItems,
    initialSwimlaneItems,
    withVoidItems: true,
    crossGroupStrategy: isDndGroupsEnabled ? 'sorting' : 'disabled',
    collisionDetection: getSwimlanesCollisionDetection,
    onStart: onDndStart,
    // shouldPreventDragOver,
    onItemsMoved,
    onSwimlaneSorted,
  });

  return {
    dndContextProps,
    direction,
    activeId,
    activeType,
    items,
    swimlaneItems,

    activeSwimlaneDoc: activeId && activeType === 'swimlane' ? getDoc(activeId) : null,
  };
}
