import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 450px;
  gap: 16px;
`;

export const Label = styled.h2`
  ${typo.headerSmall};
`;

export const Text = styled.p`
  ${typo.body400};
`;

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
