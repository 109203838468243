import { ViewCardSkeleton } from '@cycle-app/ui';
import { useCallback, memo, forwardRef, useRef } from 'react';

import { useDocItemProductTour } from 'src/hooks/productTour/useDocItemProductTour';

import { DocItemHandle, DocItemProps } from './DocItem.types';
import { DocItemCard } from './DocItemCard';
import { DocItemContainer } from './DocItemContainer';
import { DocItemCoverZone } from './DocItemCoverZone';
import { DocItemInView } from './DocItemInView';
import { DocItemLink } from './DocItemLink';
import { DocItemSortable } from './DocItemSortable';
import { DocItemTouchContainer } from './DocItemTouchContainer';

const DocItem = forwardRef<DocItemHandle, DocItemProps>(({
  docIndex,
  isGroupInBoardView = true,
  isLazy = false,
  className,
  docUrl,
  groupId,
  asPlaceholder,
  itemId,
  ...cardProps
}, ref) => {
  const coverInputRef = useRef<HTMLInputElement>(null);
  const onAddCoverClicked = useCallback(() => coverInputRef.current?.click(), []);
  const {
    isActive: isProductTourActive,
    setNextStep,
  } = useDocItemProductTour();

  const {
    viewType, isDragging,
  } = cardProps;

  return (
    <DocItemContainer
      ref={ref}
      docIndex={docIndex}
      isGroupInBoardView={isGroupInBoardView}
      isLazy={isLazy}
      groupId={groupId}
      viewType={viewType}
      skeleton={docHeight => (
        <ViewCardSkeleton
          viewType={viewType}
          skipAnimation
          fill
          minimal={!!docHeight && docHeight < 85}
        />
      )}
      onClick={setNextStep}
    >
      {isFocused => (
        <DocItemSortable
          itemId={itemId}
          isDragging={isDragging}
        >
          {asPlaceholder ? (
            <DocItemCard
              asPlaceholder
              {...cardProps}
            />
          ) : (
            <DocItemInView
              className={className}
              docUrl={docUrl}
              groupId={groupId}
              isFocused={isFocused}
              isDragging={isDragging}
            >
              <DocItemTouchContainer>
                <DocItemCoverZone
                  ref={coverInputRef}
                  disabled={isDragging}
                  viewType={viewType}
                >
                  <DocItemLink
                    docUrl={docUrl}
                    groupId={groupId}
                    isDisabled={!docUrl}
                  >
                    <DocItemCard
                      {...cardProps}
                      isFocused={isFocused}
                      onAddCoverClicked={onAddCoverClicked}
                        // eslint-disable-next-line react/jsx-indent-props
                        isReadOnly={isProductTourActive}
                    />
                  </DocItemLink>
                </DocItemCoverZone>
              </DocItemTouchContainer>
            </DocItemInView>
          )}
        </DocItemSortable>
      )}
    </DocItemContainer>
  );
});

export default memo(DocItem);
