import { BoardWithMinimalConfigFragment, BoardSectionsFragment, BoardSectionFragment } from '@cycle-app/graphql-codegen';
import { insert, move } from 'ramda';

interface AddBoardSectionArgs {
  atIndex: number;
  sectionId: string;
  board: BoardWithMinimalConfigFragment;
}
export function addBoardToSection(sections: BoardSectionsFragment['edges'], {
  atIndex,
  sectionId,
  board,
}: AddBoardSectionArgs): BoardSectionsFragment['edges'] {
  return sections
    .map(edge => (edge.node.id === sectionId
      ? {
        ...edge,
        node: updateSectionBoards(
          edge.node,
          insert(
            atIndex,
            {
              __typename: 'BoardEdge',
              node: {
                ...board,
                isBuiltIn: false,
              },
            },
            edge.node.boards.edges,
          ),
        ),
      }
      : edge));
}

export function removeBoardFromSection(sections: BoardSectionsFragment['edges'], boardId: string): BoardSectionsFragment['edges'] {
  return sections
    .map(edge => ({
      ...edge,
      node: updateSectionBoards(
        edge.node,
        edge.node.boards.edges.filter(({ node }) => node.id !== boardId),
      ),
    }));
}

interface MoveBoardInSameSectionArgs {
  sectionId: string;
  boardId: string;
  shouldBeBefore: string;
}
export function moveBoardInSameSection(sections: BoardSectionsFragment['edges'], {
  sectionId,
  boardId,
  shouldBeBefore,
}: MoveBoardInSameSectionArgs) {
  return sections
    .map(section => {
      // not the target section
      if (section.node.id !== sectionId) {
        return section;
      }

      // target section
      const boardIndexToMove = section.node.boards.edges.findIndex(({ node }) => node.id === boardId);
      const targetIndex = getTargetIndexInSameSection(section.node, boardIndexToMove, shouldBeBefore);

      if (boardIndexToMove === -1) {
        return section;
      }
      return {
        ...section,
        node: updateSectionBoards(
          section.node,
          move(
            boardIndexToMove,
            targetIndex,
            section.node.boards.edges,
          ),
        ),
      };
    });
}

interface MoveBoardToSection {
  fromSectionId: string;
  toSectionId: string;
  boardId: string;
}
export function moveBoardToAnotherSection(sections: BoardSectionsFragment['edges'], {
  fromSectionId,
  toSectionId,
  boardId,
}: MoveBoardToSection): BoardSectionsFragment['edges'] {
  const boardToMove = sections
    .find(section => section.node.boards.edges.find(({ node }) => node.id === boardId))
    ?.node.boards.edges.find(({ node }) => node.id === boardId)?.node;

  if (!boardToMove) return sections;

  return sections
    .map(section => {
      const sectionId = section.node.id;

      if (![fromSectionId, toSectionId].includes(sectionId)) {
        return section;
      }

      if (sectionId === fromSectionId) {
        // remove the board from the section
        return {
          ...section,
          node: updateSectionBoards(
            section.node,
            section.node.boards.edges.filter(({ node }) => node.id !== boardToMove.id),
          ),
        };
      }

      // Add the board to the new section
      return {
        ...section,
        node: updateSectionBoards(
          section.node,
          insert(
            0,
            {
              __typename: 'BoardEdge',
              node: boardToMove,
            },
            section.node.boards.edges,
          ),
        ),
      };
    });
}

interface MoveBoardInSectionsParams {
  boardId: string;
  fromSectionId: string;
  toSectionId: string;
  shouldBeBefore: string;
}
export function moveBoardInSections(sections: BoardSectionsFragment['edges'], {
  boardId,
  fromSectionId,
  toSectionId,
  shouldBeBefore,
}: MoveBoardInSectionsParams) {
  return (!fromSectionId || fromSectionId === toSectionId)
    ? moveBoardInSameSection(sections, {
      sectionId: toSectionId,
      boardId,
      shouldBeBefore,
    })
    : moveBoardToAnotherSection(sections, {
      boardId,
      fromSectionId,
      toSectionId,
    });
}

interface MoveSectionBoardsParams {
  fromSectionId: string;
  toSectionId: string;
}
export function moveSectionBoards(sections: BoardSectionsFragment['edges'], {
  fromSectionId,
  toSectionId,
}: MoveSectionBoardsParams) {
  const boardsToMove = sections.find(({ node }) => node.id === fromSectionId)?.node.boards.edges ?? [];

  return sections
    .filter(({ node }) => node.id !== fromSectionId)
    .map(sectionEdge => (sectionEdge.node.id !== toSectionId
      ? sectionEdge
      : ({
        ...sectionEdge,
        node: {
          ...sectionEdge.node,
          boards: {
            ...sectionEdge.node.boards,
            edges: [
              ...sectionEdge.node.boards.edges,
              ...boardsToMove,
            ],
          },
        },
      })));
}

/**
 * Private functions
 */

function getTargetIndexInSameSection(section: BoardSectionFragment, initialIndex: number, shouldBeBefore: string): number {
  const nextBoardIndex = section.boards.edges.findIndex(({ node }) => node.id === shouldBeBefore);
  if (nextBoardIndex < 0) {
    return 0;
  }
  return initialIndex > nextBoardIndex
    ? nextBoardIndex
    : nextBoardIndex - 1;
}

function updateSectionBoards(section: BoardSectionFragment, boardsEdges: BoardSectionFragment['boards']['edges']): BoardSectionFragment {
  return {
    ...section,
    boards: {
      ...section.boards,
      edges: boardsEdges,
    },
  };
}
