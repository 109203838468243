import { useFormContext, useWatch } from 'react-hook-form';

import { FormData } from './Form.types';

export const useCustomField = (fieldId: string) => {
  const { setValue } = useFormContext<FormData>();
  return {
    value: useWatch<FormData>({ name: `custom.${fieldId}` }),
    onChange: (newValue: unknown) => setValue(`custom.${fieldId}`, newValue),
  };
};
