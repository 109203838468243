import { VFC } from 'react';

interface Props {
  color: string;
}

export const ThemeNightfall: VFC<Props> = ({ color }) => (
  <svg width="228" height="183" viewBox="0 0 228 183" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4C0 1.79086 1.79086 0 4 0H38.192V183H4C1.79086 183 0 181.209 0 179V4Z" fill="#171618" />
    <rect x="6.55176" y="86.2715" width="24.8966" height="10.4571" rx="4" fill={color} />
    <path d="M38 0H224C226.209 0 228 1.79086 228 4V179C228 181.209 226.209 183 224 183H38V0Z" fill="#242424" />
    <path d="M49.9436 14.457C49.9436 12.2479 51.7345 10.457 53.9436 10.457H113.514C115.723 10.457 117.514 12.2479 117.514 14.457V168.543C117.514 170.752 115.723 172.543 113.514 172.543H53.9436C51.7345 172.543 49.9436 170.752 49.9436 168.543V14.457Z" fill="#313131" />
    <rect x="61.6948" y="20.9141" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <rect x="61.6948" y="43.1355" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <rect x="61.6948" y="105.878" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <rect x="61.6948" y="65.3569" width="44.0677" height="30.0643" rx="4" fill="#404040" />
    <rect x="61.6948" y="128.1" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <rect x="61.6948" y="150.321" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <path d="M129.265 14.457C129.265 12.2479 131.056 10.457 133.265 10.457H192.836C195.045 10.457 196.836 12.2479 196.836 14.457V121.486C196.836 123.695 195.045 125.486 192.836 125.486H133.265C131.056 125.486 129.265 123.695 129.265 121.486V14.457Z" fill="#313131" />
    <rect x="141.016" y="20.9141" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <rect x="141.016" y="43.1355" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <rect x="141.016" y="105.878" width="44.0677" height="11.7643" rx="4" fill="#404040" />
    <rect x="141.016" y="65.3569" width="44.0677" height="30.0643" rx="4" fill="#404040" />
  </svg>
);
