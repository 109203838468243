import { Reference, useApolloClient } from '@apollo/client';
import {
  CustomerDocsFragment,
  CustomerFragment,
  DocChildFragment,
  DocsByCustomerDocument,
  DocsByCustomerQueryVariables,
  DoctypeFragmentDoc,
} from '@cycle-app/graphql-codegen/generated';

import { defaultCustomerDocsPagination } from 'src/utils/pagination.util';

interface Params {
  doc: DocChildFragment;
  customer?: CustomerFragment | null;
  doctypeId?: string;
  newCustomer?: CustomerFragment | null;
}

type DocsCountByDoctype = { count?: number; doctype?: Reference };

export const useCustomerDocFromCache = () => {
  const { cache } = useApolloClient();
  const updateDocCustomer = ({
    doc, customer: optionalCustomer, doctypeId: optionalDoctypeId,
  }: Params, add = true) => {
    if (doc.isDraft) return;

    const customer = optionalCustomer ?? doc.customer;
    const doctypeId = optionalDoctypeId ?? doc.doctype.id;

    if (!customer || !doctypeId) return;

    const queryRef = cache.readQuery<{ node: CustomerDocsFragment }, DocsByCustomerQueryVariables>({
      query: DocsByCustomerDocument,
      variables: {
        id: customer.id,
        doctypeId,
        ...defaultCustomerDocsPagination,
      },
    });

    if (queryRef?.node.docs) {
      const children = [...queryRef.node.docs.edges ?? []];

      // Docs are sorted from most recent to older.
      if (add) {
        children.splice(0, 0, {
          __typename: 'DocEdge',
          cursor: '',
          node: {
            ...doc,
            customer,
          },
        });
      }
      if (!add) {
        const indexToRemove = children.findIndex(({ node }) => node.id === doc.id);
        if (indexToRemove >= 0) {
          children.splice(indexToRemove, 1);
        }
      }

      cache.writeQuery({
        query: DocsByCustomerDocument,
        variables: {
          id: customer.id,
          doctypeId,
          ...defaultCustomerDocsPagination,
        },
        data: {
          node: {
            ...queryRef.node,
            docs: {
              ...queryRef.node.docs,
              count: Math.max(0, (queryRef.node.docs.count ?? 0) + (add ? 1 : -1)),
              pageInfo: {
                ...queryRef.node.docs.pageInfo,
                endCursor: children[children.length - 1]?.cursor ?? '',
              },
              edges: children,
            },
          },
        },
      });
    }

    cache.modify({
      id: cache.identify(customer),
      fields: {
        docsCountByDoctype: (docsCountByDoctype: DocsCountByDoctype[], {
          readField, toReference,
        }) => {
          const doctypeExists = !!docsCountByDoctype.find(doctypeCount => readField('id', doctypeCount?.doctype) === doctypeId);
          if (add && !doctypeExists) {
            const doctypeDataFromCache = cache.readFragment({
              id: doctypeId,
              fragment: DoctypeFragmentDoc,
              fragmentName: 'Doctype',
            });
            if (doctypeDataFromCache) {
              return [
                ...docsCountByDoctype,
                {
                  count: 1,
                  doctype: toReference(doctypeDataFromCache),
                },
              ];
            }
          }
          return (
            docsCountByDoctype.map(doctypeCount => {
              if (readField('id', doctypeCount?.doctype) === doctypeId) {
                return {
                  ...doctypeCount,
                  count: Math.max(0, (doctypeCount?.count ?? 0) + (add ? 1 : -1)),
                };
              }
              return doctypeCount;
            })
          );
        },
      },
    });
  };

  const addCustomerDoc = ({
    doc, customer, doctypeId,
  }: Params) => {
    updateDocCustomer({
      doc,
      customer,
      doctypeId,
    });
  };

  const removeCustomerDoc = ({
    doc, customer, doctypeId,
  }: Params) => {
    updateDocCustomer({
      doc,
      customer,
      doctypeId,
    }, false);
  };

  const toggleCustomerDoc = ({
    doc, customer: optionalCustomer, doctypeId, newCustomer,
  }: Params) => {
    const customer = optionalCustomer ?? doc.customer;
    if (customer) {
      removeCustomerDoc({
        doc,
        customer,
        doctypeId,
      });
    }
    if (newCustomer) {
      addCustomerDoc({
        doc,
        customer: newCustomer,
        doctypeId,
      });
    }
  };

  return {
    addCustomerDoc,
    removeCustomerDoc,
    toggleCustomerDoc,
  };
};
