import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { EditTextButton } from '../EditTextButton/EditTextButton';
import { EditButton } from '../EditTextButton/EditTextButton.styles';

export const StyledEditTextButton = styled(EditTextButton)`
  ${EditButton} {
    ${typo.headerLight};
  }
`;
