import { Spinner } from '@cycle-app/ui';
import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useConfirmEmail, useOptimizedBooleanState, useToaster, useVerifyEmail } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';
import { setAuth } from 'src/reactives/auth.reactive';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { BackButton, Description } from '../OnboardingLayout/OnboardingLayout.styles';
import {
  CodeInputContainer, StyledCodeInput, ConfirmFooter, VerifySpinner, NospaceButton, ConfirmError,
} from './OnboardingStepAccount.styles';

interface Props {
  email: string;
  onBack: VoidFunction;
}

export const ConfirmEmailForm: FC<Props> = ({
  email, onBack,
}) => {
  const { navigate } = useNavigate();
  const [hasConfirmError, {
    setTrueCallback: setConfirmError, setFalseCallback: resetConfirmError,
  }] = useOptimizedBooleanState(false);
  const {
    confirmEmail, isLoading: isConfirmLoading,
  } = useConfirmEmail();
  const {
    verifyEmail, isLoading: isVerifyLoading,
  } = useVerifyEmail();
  const { add: addToaster } = useToaster();

  const onChange = async (code: string) => {
    resetConfirmError();
    const result = await confirmEmail(email, code);
    if (result.data?.confirmEmail?.me) {
      const {
        me, token,
      } = result.data.confirmEmail;
      setAuth({
        token,
        userId: me.id,
        onboarded: me.onboarded,
      });
      if (me.onboarded) {
        const productSlug = me.products.edges[0]?.node.slug;
        if (productSlug) {
          navigate(PageId.Inbox, { productSlug });
        } else {
          navigate(PageId.Main);
        }
        return;
      }
      setOnboarding({ screen: LightOnboardingScreen.AccountInfos });
    } else {
      setConfirmError();
    }
  };

  return (
    <>
      <Description>
        We&apos;ve sent an email with a code to
        {' '}
        {email}
        , please enter it below to create your Cycle account.
      </Description>
      {hasConfirmError && <ConfirmError>Something went wrong, please try again.</ConfirmError>}
      <CodeInputContainer>
        <StyledCodeInput autoFocus disabled={isConfirmLoading} onChange={onChange} />
        {isConfirmLoading && <Spinner />}
      </CodeInputContainer>
      <ConfirmFooter>
        Didn&apos;t receive a code?
        {' '}
        <NospaceButton
          size="M"
          variant="nospace"
          onClick={async () => {
            if (isVerifyLoading) return;
            const verify = await verifyEmail(email);
            if (verify.data?.verifyEmail) {
              addToaster({
                message: `We've sent an email with a code to ${email}`,
              });
            }
          }}
        >
          Send new code
          {isVerifyLoading && <VerifySpinner />}
        </NospaceButton>
      </ConfirmFooter>
      <BackButton onClick={onBack} size="M">Back</BackButton>
    </>
  );
};
