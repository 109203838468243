import { BoardSectionFragment, SectionType } from '@cycle-app/graphql-codegen';
import { DotsMenuProps, SelectOption } from '@cycle-app/ui';
import { PenIcon, TrashIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { useToggle } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useGetPermission } from 'src/reactives/permission.reactive';

import DialogModal from '../DialogModal/DialogModal';
import { SectionTag } from './SectionOptions.styles';

interface Props extends Omit<DotsMenuProps, 'options'> {
  section: BoardSectionFragment;
  onEditSectionName: VoidFunction;
  onRemoveSection: (sectionId: string) => void;
}

const SectionOptions: FC<Props> = ({
  section,
  onEditSectionName,
  onRemoveSection,
  ...props
}) => {
  const [modalDelete, toggleDeleteModal] = useToggle();
  const {
    canDeleteBoardSection, canUpdateBoardSection,
  } = useGetPermission();

  const options: SelectOption[] = [];

  if (canUpdateBoardSection) {
    options.push({
      value: 'edit',
      label: 'Edit section',
      icon: <PenIcon />,
      onSelect: onEditSectionName,
    });
  }

  if (canDeleteBoardSection) {
    options.push({
      value: 'delete',
      label: 'Delete',
      icon: <TrashIcon />,
      variant: 'danger',
      onSelect: toggleDeleteModal,
    });
  }

  if (options.length === 0) return null;

  const isDefaultSection = section.type === SectionType.Default;

  return (
    <>
      <DotsMenuLayer
        tooltip={isDefaultSection ? "This section can't be renamed nor deleted" : 'More'}
        options={options}
        icon={isDefaultSection ? <InfoIconOutline size={14} /> : undefined}
        visible={isDefaultSection ? false : undefined}
        {...props}
      />

      <AnimatePresence>
        {modalDelete && (
          <DialogModal
            hide={toggleDeleteModal}
            title="Delete section"
            info={(
              <>
                {'Are you sure you want to delete '}
                <SectionTag>{section.name}</SectionTag>
                {' ? Views will move to '}
                <SectionTag>Other views</SectionTag>
                {' in your sidebar.'}
              </>
            )}
            confirmLabel="Delete"
            onConfirm={() => onRemoveSection(section.id)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default SectionOptions;
