import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { TourName } from 'src/types/productTour.types';
import { getBoardSlug } from 'src/utils/slug.util';

import { useCurrentInboxSlug } from '../inbox/useInboxCurrent';
import { useInboxSection } from '../inbox/useInboxSection';

export const useMainProductTour = () => {
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);
  const { boards } = useInboxSection();
  const currentSlug = useCurrentInboxSlug();

  const shouldRedirectToMyInbox = () => {
    const tour = getActiveProductTour();
    if (
      !isEnabled ||
      !tour ||
      tour?.name !== TourName.FIRST_TOUR
    ) return null;

    return currentSlug?.includes('my-inbox')
      ? null
      : getBoardSlug({
        id: boards[0].id,
        name: boards[0].name,
      });
  };

  return {
    shouldRedirectToMyInbox,
  };
};
