import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const FigmaIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g clipPath="url(#clip0_624_32980)">
      <path d="M9.04338 27.9999C11.6194 27.9999 13.7101 25.9092 13.7101 23.3332V18.6666H9.04338C6.46738 18.6666 4.37671 20.7573 4.37671 23.3332C4.37671 25.9092 6.46738 27.9999 9.04338 27.9999Z" fill="#0ACF83" />
      <path d="M4.37671 14C4.37671 11.4241 6.46738 9.3334 9.04338 9.3334H13.7101V18.6667H9.04338C6.46738 18.6667 4.37671 16.576 4.37671 14Z" fill="#A259FF" />
      <path d="M4.37671 4.66666C4.37671 2.09066 6.46738 0 9.04338 0H13.7101V9.33332H9.04338C6.46738 9.33332 4.37671 7.24266 4.37671 4.66666Z" fill="#F24E1E" />
      <path d="M13.7103 0H18.377C20.953 0 23.0437 2.09066 23.0437 4.66666C23.0437 7.24266 20.953 9.33332 18.377 9.33332H13.7103V0Z" fill="#FF7262" />
      <path d="M23.0437 14C23.0437 16.576 20.953 18.6667 18.377 18.6667C15.801 18.6667 13.7103 16.576 13.7103 14C13.7103 11.4241 15.801 9.3334 18.377 9.3334C20.953 9.3334 23.0437 11.4241 23.0437 14Z" fill="#1ABCFE" />
    </g>
    <defs>
      <clipPath id="clip0_624_32980">
        <rect width="18.6704" height="28" fill="white" transform="translate(4.375)" />
      </clipPath>
    </defs>
  </Svg>
);
