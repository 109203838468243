import { useEditor, EditorContent, Content } from '@tiptap/react';
import React, { FC, useEffect } from 'react';
import { Doc } from 'yjs';

import { EditorContextProvider } from 'src/contexts/editorContext';
import { displayExtensions } from 'src/editorExtensions/editorExtensions';
import useEditorContributors from 'src/hooks/editor/useEditorContributors';

import { ReadOnlyEditorContainer, CommentEditorContent } from './Editor.styles';

import type { Editor } from '@tiptap/core';

interface Props {
  className?: string;
  key?: string;
  content?: Content;
  interactive?: boolean;
  onEditorReady?: (e: Editor) => void;
  collaboration?: {
    document: Doc;
  };
}

export const ReadOnlyEditor: FC<Props> = ({
  content,
  interactive = true,
  collaboration,
  onEditorReady,
  ...otherProps
}) => {
  const contributors = useEditorContributors();

  const editor = useEditor({
    extensions: displayExtensions({
      people: contributors,
      ...(collaboration ? { collaboration } : {}),
    }),
    content,
    editable: false,
    autofocus: false,
  }, [collaboration?.document.clientID, content]);

  useEffect(() => {
    if (editor && onEditorReady) {
      onEditorReady(editor);
    }
  }, [editor, onEditorReady]);

  if (!editor) {
    return null;
  }

  return (
    <EditorContextProvider editor={editor} isReadOnly>
      <ReadOnlyEditorContainer
        interactive={interactive}
        {...otherProps}
      >
        <CommentEditorContent className="content-editor">
          <EditorContent editor={editor} />
        </CommentEditorContent>
      </ReadOnlyEditorContainer>
    </EditorContextProvider>
  );
};
