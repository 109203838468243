import { Role } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { PermissionsByRoles, PermissionsByPlan } from 'src/constants/permission.constants';
import { useRole } from 'src/hooks/useRoles';
import { setPermission, setBillingPermission, setIntegrationPermission } from 'src/reactives/permission.reactive';

import { BILLING_LIMIT_BOARDS, BILLING_LIMIT_INTEGRATIONS } from '../constants/billing.constants';
import { useCurrentBilling } from './api/useProduct';
import { useProductCounters } from './useProductCounters';

export const useInitPermission = () => {
  const {
    role, globalRole,
  } = useRole();
  const currentBilling = useCurrentBilling();
  const {
    installedIntegrationsCount, boardsCount, inboxCountWithMyInbox,
  } = useProductCounters();
  useEffect(() => {
    const roles = PermissionsByRoles[role];
    setPermission({
      canInviteAdmin: roles.includes('USER_ADMIN_CREATE'),
      canInviteMaker: roles.includes('USER_MAKER_CREATE'),
      canInviteCollaborator: roles.includes('USER_COLLABORATOR_CREATE'),
      canDeleteAdmin: roles.includes('USER_ADMIN_DELETE'),
      canCreateBoard: roles.includes('BOARD_CREATE'),
      canUpdateBoard: roles.includes('BOARD_UPDATE'),
      canDeleteBoard: roles.includes('BOARD_DELETE'),
      canCreateBoardSection: roles.includes('BOARD_SECTION_CREATE'),
      canUpdateBoardSection: roles.includes('BOARD_SECTION_UPDATE'),
      canDeleteBoardSection: roles.includes('BOARD_SECTION_DELETE'),
      canReorderBoards: roles.includes('BOARDS_REORDER'),
      canCreateGroup: roles.includes('GROUP_CREATE'),
      canUpdateGroup: roles.includes('GROUP_UPDATE'),
      canReorderGroups: roles.includes('GROUPS_REORDER'),
      canReadPlayground: roles.includes('PLAYGROUND_READ'),
      canReadSettings: roles.includes('SETTINGS_READ'),
      canUpdateUserRoleCollaborator: roles.includes('USER_UPDATE_ROLE_COLLABORATOR'),
      canUpdateUserRoleMaker: roles.includes('USER_UPDATE_ROLE_MAKER'),
      canUpdateUserRoleAdmin: roles.includes('USER_UPDATE_ROLE_ADMIN'),
      canUpgradeBilling: roles.includes('SETTINGS_READ'),
    });
  }, [role]);

  useEffect(() => {
    const roles = PermissionsByRoles[role];
    const plans = currentBilling?.plan ? PermissionsByPlan[currentBilling.plan] : [];
    setBillingPermission({
      canCurrentPlanDeleteMaker: (
        globalRole === Role.SuperAdmin ||
        ((currentBilling?.nbMakers ?? 0) > 1)
      ),
      canCurrentPlanUpdateMaker: (
        globalRole === Role.SuperAdmin ||
        ((currentBilling?.nbMakers ?? 0) > 1)
      ),
      canGoIncognito:
        globalRole === Role.SuperAdmin ||
        plans.includes('INCOGNITO'),
      canUploadNotRestricted:
        globalRole === Role.SuperAdmin ||
        plans.includes('UPLOAD_NOT_RESTRICTED'),
      // We need two rules for section/board as we want to display the ctas:
      // if (canCreateBoardSection)
      //   <button onClick={ if !canPlanCreateBoardSection -> trigger limitation modal } />
      canPlanCreateBoardSection:
        globalRole === Role.SuperAdmin ||
        (
          roles.includes('BOARD_CREATE') &&
          plans.includes('BOARD_SECTION_CREATE')
        ),
      canPlanCreateBoard:
        globalRole === Role.SuperAdmin ||
        (
          roles.includes('BOARD_CREATE') &&
          (
            plans.includes('BOARD_CREATE') ||
            boardsCount + inboxCountWithMyInbox < BILLING_LIMIT_BOARDS
          )
        ),
    });
  }, [boardsCount, currentBilling, globalRole, role, inboxCountWithMyInbox]);

  useEffect(() => {
    const roles = PermissionsByRoles[role];
    const plans = currentBilling?.plan ? PermissionsByPlan[currentBilling.plan] : [];
    setIntegrationPermission({
      canAddIntegration:
        globalRole === Role.SuperAdmin ||
        (
          roles.includes('SETTINGS_READ') &&
          (
            plans.includes('INTEGRATION_ADD') ||
            installedIntegrationsCount < BILLING_LIMIT_INTEGRATIONS
          )
        ),
    });
  }, [currentBilling, globalRole, installedIntegrationsCount, role]);
};
