import { ReactNode } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import useQueryParams from 'src/hooks/useQueryParams';

import { FormContainer } from './Form.styles';
import { FormData } from './Form.types';
import { useSubmitForm } from './useSubmitForm';

export type FormProps = {
  children: ReactNode;
};

export const Form = ({ children }: FormProps) => {
  const queryParams = useQueryParams();
  const submit = useSubmitForm();

  const form = useForm<FormData>({
    defaultValues: {
      title: queryParams.get('title') ?? undefined,
      assignee: queryParams.get('assignee') ?? undefined,
      customerId: queryParams.get('customer') ?? undefined,
      description: undefined,
      custom: {},
    },
  });

  return (
    <FormProvider {...form}>
      <FormContainer
        onSubmit={form.handleSubmit(submit)}
        onReset={() => form.reset()}
      >
        {children}
      </FormContainer>
    </FormProvider>
  );
};
