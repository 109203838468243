import { Node, mergeAttributes } from '@tiptap/core';
import { Editor, ReactNodeViewRenderer, ReactRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';
import { FC } from 'react';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import DocMentionView from 'src/components/Editor/NodeViews/DocMention/DocMentionView';
import { Events } from 'src/constants/analytics.constants';
import { COMMANDS } from 'src/constants/editor.constants';
import { pastedDocPlugin } from 'src/plugins/pastedDocPlugin';
import { suggestionPlugin } from 'src/plugins/suggestionPlugin';
import { Layer } from 'src/types/layers.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

import MentionDocDropdown from './MentionDocDropdown';

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

interface MentionDocParams {
  readOnly?: boolean;
  onDocMentioned?: (docId: string) => void;
}

export const mentionDocExtensionName = 'mention-docs';
export const MENTION_DOC_EXTENSION_TAGNAME = 'cycle-editor-mention-doc';

const getMentionDocExtension = ({
  readOnly = false,
  onDocMentioned,
}: MentionDocParams = {}) => Node.create<MentionOptions>({
  key: mentionDocExtensionName,
  name: mentionDocExtensionName,

  addOptions: () => ({
    HTMLAttributes: {
      class: mentionDocExtensionName,
    },
    suggestion: {
      char: COMMANDS.DOC_MENTION,

      allowSpaces: true,

      command: ({
        editor,
        range,
        props,
      }) => {
        if (!props.id) return;
        trackAnalytics(Events.DocMention);
        onDocMentioned?.(props.id);
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: mentionDocExtensionName,
              attrs: props,
            },
            {
              type: 'text',
              text: ' ',
            },
          ])
          .run();
      },

      allow: ({
        editor,
        range,
      }) => editor.can().insertContentAt(range, { type: mentionDocExtensionName }),

      render: () => {
        // To avoid rendering the dropdown if last char is # in readonly editor
        if (readOnly) return {};

        let reactRenderer: ReactRenderer;
        let popup: TippyInstance<TippyProps>[];

        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionDocDropdown as FC, {
              props: {
                ...props,
                hide: () => {
                  popup[0].hide();
                },
              },
              editor: props.editor as Editor,
            });

            popup = getTippyPopup({
              props,
              reactRenderer,
              layer: Layer.DropdownZ1,
              options: {
                hideOnClick: false,
                onHide: () => {
                  if (props.editor) {
                    const state = {
                      active: false,
                      key: null,
                      range: {},
                      query: null,
                      text: null,
                      composing: false,
                    };
                    props.editor.view.dispatch(
                      props.editor.view.state.tr.setMeta(
                        // @ts-ignore
                        props.pluginKey,
                        state,
                      ),
                    );
                  }
                },
              },
            });
          },
          onUpdate(props) {
            reactRenderer.updateProps(props);
            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          },
          onKeyDown(props) {
            if (props.event.key === 'Tab' || props.event.key === 'ArrowRight') {
              if (popup.length && !popup[0].state.isDestroyed) {
                props.event.stopImmediatePropagation();
                popup[0].hide();
              }
              return true;
            }

            return onKeyDown({
              ...props,
              popupInstance: popup[0],
            });
          },
          onExit() {
            popup[0].destroy();
            reactRenderer.destroy();
          },
        };
      },
    },
  }),

  group: 'inline',

  inline: true,

  selectable: false,

  draggable: true,

  atom: true,

  addAttributes() {
    return {
      id: { default: null },
    };
  },

  parseHTML() {
    return [
      { tag: MENTION_DOC_EXTENSION_TAGNAME },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      MENTION_DOC_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(DocMentionView);
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({
        tr, state,
      }) => {
        let isMention = false;
        const { selection } = state;
        const {
          empty, anchor,
        } = selection;

        if (!empty) {
          return false;
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true;
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize);

            return false;
          }
          return false;
        });

        return isMention;
      }),
    };
  },

  addProseMirrorPlugins() {
    return [
      suggestionPlugin({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${mentionDocExtensionName}`),
      }),
      pastedDocPlugin({
        editor: this.editor,
        extensionName: mentionDocExtensionName,
      }),
    ];
  },
});

export default getMentionDocExtension;
