import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { ViewType } from '../../../../../graphql-codegen/generated';
import { Container as TagContainer } from '../../Tag/Tag.styles';
import { DraggableCard } from '../DraggableCard/DraggableCard';

export const Cover = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 135px;
  object-fit: cover;
`;

export const Title = styled.span<{
  isEmpty?: boolean;
}>`
  font-size: 14px;
  word-break: break-word;
  ${p => p.isEmpty && css`color: ${p.theme.colors.text.disabled};`};
`;

export const Description = styled.span`
  font-size: 14px;
  word-break: break-word;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  gap: 4px;
`;

export const ToolbarSection = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Actions = styled.div<{ forceVisible?: boolean }>`
  opacity: ${p => (p.forceVisible ? 1 : 0)};

  ${mobile} {
    opacity: 1;

    button {
      background-color: transparent;
    }
  }
`;

const InfoList = css`
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 24px;

  ${Title} {
    margin-right: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 200px;
    flex: 1;
  }

  ${Toolbar} {
    margin-top: 0;
  }

  ${mobile} {
    ${Title} {
      min-width: 30%;
    }
  }
`;

const InfoKanban = css`
  ${Actions} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const StyledDraggableCard = styled(DraggableCard)`
  position: relative;

  &:hover {
    ${Actions} {
      opacity: 1;
    }
  }
  ${p => p.isFocused && css`
    ${Actions} {
      opacity: 1;
    }
  `};

  ${p => p.isSelected && css`
    pointer-events: none;
  `};

  /* TODO: delete once the docitem redesign is complete */
  /* stylelint-disable */
  ${TagContainer} {
    height: 24px;
  }
`;

export const Info = styled.div<{ viewType?: ViewType }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: inherit;

  ${props => props.viewType === ViewType.List && InfoList};
  ${props => props.viewType === ViewType.Kanban && InfoKanban}

  div {
    display: flex;
  }

  &:hover {
    ${Actions} {
      opacity: 1;
    }
  }
`;
