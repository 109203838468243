import styled, { css } from 'styled-components';

import { ShyScrollbar } from '../ShyScrollbar/ShyScrollbar.styles';

export const StyledShyScrollbar = styled(ShyScrollbar)`
  margin-right: -8px;
`;

export const ObservedDiv = styled.div<{ $direction: 'horizontal' | 'vertical' }>`
  pointer-events: none;
  z-index: 0;
  min-width: 1px;
  min-height: 1px;

  display: flex;

  ${p => p.$direction === 'vertical' && css`
    flex-direction: column;
    margin-top: 4px;
    gap: 4px;
  `};

  ${p => p.$direction === 'horizontal' && css`
    &::after {
      content: '';
      flex: 0 0 32px;
    }
  `};
`;
