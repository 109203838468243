import { FieldLabel, SkeletonInput, SkeletonLabel } from './Form.styles';

export const CustomFieldSkeleton = () => (
  <div>
    <FieldLabel>
      <SkeletonLabel />
    </FieldLabel>
    <SkeletonInput />
  </div>
);
