/* eslint-disable import/no-extraneous-dependencies */
import { InMemoryCache, defaultDataIdFromObject, FieldPolicy } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import generatedIntrospection, { PagintationWhereDirectionInput } from '@cycle-app/graphql-codegen';

export const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject: (responseObject) => (
    responseObject.id
      ? responseObject.id as string
      : defaultDataIdFromObject(responseObject)
  ),
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies: {
    Me: {
      fields: {
        products: customRelayStylePagination(['searchText']),
      },
    },
    Comment: {
      fields: {
        _sending: {
          read: (sending?: boolean) => !!sending,
        },
      },
    },
    Doc: {
      fields: {
        _creating: {
          read: (creating?: boolean) => !!creating,
        },
        _groupId: {
          read: (groupId?: string) => groupId || null,
        },
        _docKey: {
          read: (docKey?: string) => docKey || null,
        },
        _viewers: {
          read: (viewers?: string[]) => viewers || [],
        },
        children: customRelayStylePagination(['doctypeId', '@connection', ['key']]),
      },
    },
    DocAttributeMultiSelect: {
      fields: {
        values: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    User: {
      fields: {
        _compatibleWithBoardConfig: {
          read: (compatibleWithBoardConfig?: boolean) => !!compatibleWithBoardConfig,
        },
        _boardId: {
          read: (boardId?: string) => boardId || null,
        },
        _docId: {
          read: (docId?: string) => docId || null,
        },
      },
    },
    Product: {
      fields: {
        users: customRelayStylePagination(['onboarded']),
        notifications: customRelayStylePagination(),
        customers: customRelayStylePagination(['searchText']),
        companies: customRelayStylePagination(['searchText']),
      },
    },
    Billing: {
      fields: {
        billing: customRelayStylePagination(),
      },
    },
    Customer: {
      fields: {
        _compatibleWithBoardConfig: {
          read: (compatibleWithBoardConfig?: boolean) => !!compatibleWithBoardConfig,
        },
        docs: customRelayStylePagination(['doctypeId', '@connection', ['key']]),
        displayName: (_, { readField }) => {
          const name = readField('name')?.toString();
          const email = readField('email')?.toString();
          return name?.trim() ? name : email;
        },
      },
    },
    BoardConfig: {
      fields: {
        docQuery: {
          merge: true,
        },
      },
    },
    Company: {
      fields: {
        customers: customRelayStylePagination(),
      },
    },
    DocGroupWithPropertyValue: {
      fields: {
        docs: customRelayStylePagination(),
      },
    },
    DocGroupWithoutPropertyValue: {
      fields: {
        docs: customRelayStylePagination(),
      },
    },
    BoardQueryWithGroupBy: {
      fields: {
        docGroups: customRelayStylePagination(),
      },
    },
    BoardQueryWithSwimlaneBy: {
      fields: {
        swimlanes: customRelayStylePagination(),
      },
    },
    SwimlanebyConfig: {
      fields: {
        filterPropertiesV2: {
          merge: true,
        },
        filterableProperties: {
          merge: true,
        },
      },
    },
    RuleDocParent: {
      fields: {
        availableValues: customRelayStylePagination(['searchText']),
      },
    },
    FilterPropertyRuleDocParent: {
      fields: {
        rule: {
          merge: true,
        },
      },
    },
    FilterPropertyRuleCustomer: {
      fields: {
        rule: {
          merge: true,
        },
      },
    },
    FilterPropertyRuleCompany: {
      fields: {
        rule: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        node: {
          read: (_, {
            variables, toReference,
          }) => {
            const variableId =
              variables?.id ||
              variables?.productId ||
              variables?.boardId ||
              variables?.boardConfigId ||
              variables?.groupConfigId ||
              variables?.docId ||
              variables?.groupId ||
              variables?.userId ||
              variables?.integrationId;

            if (variableId === undefined) {
              let debugVars = '';
              try {
                debugVars = JSON.stringify(variables);
              } catch {
                //
              }
              throw new Error(`variableId is undefined in cache.ts. Vars: ${debugVars}`);
            }

            return toReference(variableId);
          },
        },
      },
    },
  },
});

/* Since our implementation of the relay style Cursor Connections (https://relay.dev/graphql/connections.htm#sec-Connection-Types)
  is not exact (our query arguments are shaped differently) the apollo utility function relayStylePagination does not work out of
  box without the following tweaking of the arguments.
  Code for that function can be found here: https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts
*/
function customRelayStylePagination(keyArgs?: FieldPolicy<any>['keyArgs']): FieldPolicy {
  const {
    merge,
    ...fieldsPolicy
  } = relayStylePagination(keyArgs);

  return ({
    merge(existing, incoming, mergeOptions) {
      const { args: mergeArgs } = mergeOptions;
      /* In our implentation we are able to query paginated fields with no paginated arguments
        which on the backend side defaults to a defined size.
        However our client cache can't know that so in order for the relayStylePagination to not fail in that case
        we replace the existing data with the incoming one in that case. Not optimal for cache performance but acceptable.

        If we wantto improve that in the future one way is to make sure all the queries to paginated fields actually ask for pagination arguments,
        we could define the default values on the front instead of the backend.
      */

      if (!mergeArgs) return incoming;

      const normalizedMergeArgs = {
        ...mergeArgs,
        before: mergeArgs?.pagination?.where?.direction === PagintationWhereDirectionInput.Before &&
          mergeArgs?.pagination?.where?.cursor,
        after: mergeArgs?.pagination?.where?.direction === PagintationWhereDirectionInput.After &&
          mergeArgs?.pagination?.where?.cursor,
      };

      if (typeof merge !== 'boolean') {
        const mergeResult = merge?.(existing, incoming, {
          ...mergeOptions,
          args: normalizedMergeArgs,
        });
        return mergeResult;
      }
      return merge;
    },
    ...fieldsPolicy,
  });
}
