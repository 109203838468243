import { GroupByConfigBoardQueryWithGroupByFragment, SwimlanebyConfigFragment, SwimlaneByDoctypeFragment } from '@cycle-app/graphql-codegen';
import { Emoji, SelectPanel, SelectOption } from '@cycle-app/ui';
import { EyeClosedIcon, EyeIcon, SumIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import memoize from 'fast-memoize';
import { FC, useMemo, useCallback, useState } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import FilterRules from 'src/components/FilterRules/FilterRules';
import useManageHiddenSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageHiddenSwimlanes';
import useManageSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageSwimlanes';
import useSwimlaneFilterMutations from 'src/hooks/api/mutations/boardConfig/useSwimlaneFilterMutations';
import { Layer } from 'src/types/layers.types';
import { getOptionFromDoctype } from 'src/utils/selectOptions.util';

import {
  LightButton,
  Label,
  GroupByButtons,
  GroupByButton,
  StyledToggleInput,
  Caret,
} from '../BoardConfigForm.styles';
import HiddenSwimlanes from './HiddenSwimlanes';

type DropdownVisible = 'groupby' | 'hiddenGroups' | 'filters' | null;

interface Props {
  boardConfigId: string;
  groupByConfig: GroupByConfigBoardQueryWithGroupByFragment['groupbyConfig'] | null;
  availableSwimlaneByDoctypes: SwimlaneByDoctypeFragment['availableSwimlaneByDoctypes'] | null;
  swimlaneByConfig: SwimlanebyConfigFragment | null;
  boardID?: string;
}
const BoardConfigFormSwimlanes: FC<Props> = ({
  boardConfigId,
  groupByConfig,
  availableSwimlaneByDoctypes,
  swimlaneByConfig,
  boardID,
}) => {
  const {
    addSwimlaneDoctype,
    removeSwimlane,
    loadingAddSwimlane,
    loadingRemoveSwimlane,
  } = useManageSwimlanes(boardConfigId);
  const {
    showSwimlane,
    hideSwimlane,
    showAllSwimlanes,
    hideAllSwimlanes,
  } = useManageHiddenSwimlanes({
    boardConfigId,
    swimlaneConfigId: swimlaneByConfig?.id,
    boardID,
  });
  const {
    loadingChangeOperator,
    changeFilterRuleOperator,
    removeFilterRule,
    addFilterRule,
    addFilterValue,
    changeFilterRuleAttribute,
    removeFilterValue,
  } = useSwimlaneFilterMutations({
    boardConfigId,
    swimlaneConfigId: swimlaneByConfig?.id ?? '',
    boardID,
  });

  const [dropdownVisible, setDropdownVisible] = useState<DropdownVisible>(null);
  const toggleDrodown = useMemo(
    () => memoize((dropdown: DropdownVisible) => () => {
      const newState = dropdownVisible ? null : dropdown;
      setDropdownVisible(newState);
    }),
    [dropdownVisible],
  );
  const hideDropdown = useCallback(() => setDropdownVisible(null), []);

  const selectedGroupbyDoctype = swimlaneByConfig?.doctype;
  const groupByOptions = useMemo(
    () => nodeToArray(availableSwimlaneByDoctypes).map(getOptionFromDoctype),
    [availableSwimlaneByDoctypes],
  );

  const nbShownSwimlanes = useMemo(
    () => swimlaneByConfig?.hiddenSwimlanes.edges.filter(({ node }) => !node.hidden)?.length ?? 0,
    [swimlaneByConfig?.hiddenSwimlanes],
  );
  const nbHiddenSwimlanes = useMemo(
    () => swimlaneByConfig?.hiddenSwimlanes.edges.filter(({ node }) => node.hidden)?.length ?? 0,
    [swimlaneByConfig?.hiddenSwimlanes],
  );
  const buttonConfig = useMemo(() => (nbShownSwimlanes > nbHiddenSwimlanes
    ? {
      label: `${nbHiddenSwimlanes} hidden swimlane${nbHiddenSwimlanes > 1 ? 's' : ''}`,
      icon: <EyeClosedIcon />,
    }
    : {
      label: `${nbShownSwimlanes} shown swimlane${nbShownSwimlanes > 1 ? 's' : ''}`,
      icon: <EyeIcon />,
    }),
  [nbHiddenSwimlanes, nbShownSwimlanes]);

  const {
    filters,
    filterableProperties,
  } = useMemo(
    () => ({
      filters: nodeToArray(swimlaneByConfig?.filterProperties).filter(f => f.__typename !== 'SwimlaneByFilterPropertyRuleDocChildren'),
      filterableProperties: nodeToArray(swimlaneByConfig?.filterableProperties).filter(f => f.__typename !== 'ChildrenDefinition'),
    }),
    [swimlaneByConfig?.filterProperties, swimlaneByConfig?.filterableProperties],
  );

  const swimlaneChildrenAvailableFilterRule = useMemo(
    () => swimlaneByConfig
      ?.filterableProperties
      .edges
      .find(({ node }) => node.__typename === 'ChildrenDefinition')?.node,
    [swimlaneByConfig?.filterableProperties],
  );
  const swimlaneChildrenActiveFilter = useMemo(
    () => swimlaneByConfig
      ?.filterProperties
      .edges
      .find(({ node }) => node.__typename === 'SwimlaneByFilterPropertyRuleDocChildren')?.node,
    [swimlaneByConfig?.filterProperties],
  );

  const onRemoveSwimlane = useCallback(() => {
    setDropdownVisible(null);
    return removeSwimlane();
  }, [removeSwimlane]);

  const onDoctypeOptionChange = useCallback((option: SelectOption) => {
    setDropdownVisible(null);
    return addSwimlaneDoctype(option.value);
  },
  [addSwimlaneDoctype]);

  const changeFilter = useCallback(async (filterId: string, valueId: string) => {
    const propertyAdded = filterableProperties.find(p => p.id === valueId);
    if (propertyAdded) {
      await changeFilterRuleAttribute(filterId, propertyAdded);
    }
  }, [changeFilterRuleAttribute, filterableProperties]);

  const addFirstFilterAvailable = useCallback(async () => {
    if (!filterableProperties.length) return;
    await addFilterRule(filterableProperties[0]);
  }, [filterableProperties, addFilterRule]);

  const onToggleEmptySwimlanesFilter = useCallback(async () => {
    if (swimlaneChildrenActiveFilter) {
      await removeFilterRule(swimlaneChildrenActiveFilter.id);
    } else if (swimlaneChildrenAvailableFilterRule) {
      await addFilterRule(swimlaneChildrenAvailableFilterRule);
    }
  }, [swimlaneChildrenAvailableFilterRule, swimlaneChildrenActiveFilter, addFilterRule, removeFilterRule]);

  return (
    <>
      <Label>Swimlane by</Label>

      <GroupByButtons>
        <DropdownLayer
          layer={Layer.DropdownModalZ1}
          visible={dropdownVisible === 'groupby'}
          hide={hideDropdown}
          placement="bottom-start"
          content={(
            <SelectPanel
              options={groupByOptions}
              isMulti={false}
              {...!!swimlaneByConfig && { onClearValue: onRemoveSwimlane }}
              onOptionChange={onDoctypeOptionChange}
            />
          )}
        >
          {selectedGroupbyDoctype
            ? (
              <GroupByButton
                type="button"
                onClick={toggleDrodown('groupby')}
                forceFocus={dropdownVisible === 'groupby'}
                position="left"
              >
                <Emoji size={12} emoji={selectedGroupbyDoctype.emoji} />
                {selectedGroupbyDoctype.name}
                <Caret />
              </GroupByButton>
            )
            : (
              <LightButton
                onClick={toggleDrodown('groupby')}
                forceFocus={dropdownVisible === 'groupby'}
                disabled={!groupByConfig}
                isLoading={loadingAddSwimlane || loadingRemoveSwimlane}
              >
                + Add swimlane
              </LightButton>
            )}
        </DropdownLayer>

        {selectedGroupbyDoctype && (
          <>
            <HiddenSwimlanes
              swimlanebyConfig={swimlaneByConfig}
              isVisible={dropdownVisible === 'hiddenGroups'}
              placement="top"
              hideDropdown={hideDropdown}
              onHideSwimlane={hideSwimlane}
              onShowSwimlane={showSwimlane}
              onHideSwimlanes={hideAllSwimlanes}
              onShowSwimlanes={showAllSwimlanes}
              lowerdropdownLayer={Layer.DropdownModalZ1}
              upperDropdownLayer={Layer.DropdownModalZ2}
            >
              <GroupByButton
                type="button"
                onClick={toggleDrodown('hiddenGroups')}
                forceFocus={dropdownVisible === 'hiddenGroups'}
                position="middle"
                // To prevent collision with doc preview using space
                onKeyUp={e => e.preventDefault()}
              >
                {buttonConfig.icon}
                {buttonConfig.label}
              </GroupByButton>
            </HiddenSwimlanes>

            <FilterRules
              dropdownLayer={Layer.DropdownModalZ1}
              dropdownPlacement="top"
              filtersDropdownLayer={Layer.DropdownModalZ2}
              visible={dropdownVisible === 'filters'}
              hide={hideDropdown}
              filters={filters}
              filterableProperties={filterableProperties}
              loadingChangeOperator={loadingChangeOperator}
              onAddFirstFilterAvailable={addFirstFilterAvailable}
              onAddValue={addFilterValue}
              onRemoveValue={removeFilterValue}
              onChangeFilter={changeFilter}
              onDeleteFilter={removeFilterRule}
              onOperatorUpdated={changeFilterRuleOperator}
            >
              <GroupByButton
                type="button"
                onClick={toggleDrodown('filters')}
                forceFocus={dropdownVisible === 'filters'}
                position="right"
              >
                <SumIcon />
                <span>{filters.length}</span>
              </GroupByButton>
            </FilterRules>
          </>
        )}
      </GroupByButtons>

      {!!selectedGroupbyDoctype && (
        <StyledToggleInput
          id="hide-empty-swimlanes-toggle"
          label="Hide empty swimlanes"
          checked={!!swimlaneChildrenActiveFilter}
          onChange={onToggleEmptySwimlanesFilter}
        />
      )}
    </>
  );
};
export default BoardConfigFormSwimlanes;
