import {
  ComponentPropsWithRef,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react';

import { InfoIcon } from '../../../icons';
import { Tooltip } from '../../Tooltip/Tooltip';
import {
  Container,
  Label,
  StyledInput,
  InputContainer,
  IconBeforeContainer,
  Helper,
  TooltipContainer,
  ActionIcon,
  Required,
  EndContainer,
} from './Input.styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, ComponentPropsWithRef<'input'> {
  label?: string | ReactNode;
  tooltipLabel?: string;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  iconPadding?: number;
  inputHidden?: boolean;
  onClickIcon?: () => void;
  isRequired?: boolean;
  error?: string;
  helper?: string | ReactNode;
  readOnly?: boolean;
  endInput?: ReactNode;
  helperSize?: 'S' | 'M';
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    label,
    tooltipLabel,
    iconBefore,
    iconAfter,
    iconPadding,
    inputHidden,
    onClickIcon,
    isRequired,
    error,
    helper,
    endInput,
    helperSize,
    ...inputProps
  } = props;

  return (
    <Container
      className={className}
      onKeyDown={e => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.code)) e.stopPropagation();
      }}
    >
      {label && (
        <Label
          {...inputProps.id ? {
            as: 'label',
            htmlFor: inputProps.id,
          } : {
            as: 'div',
          }}
        >
          {label}

          {isRequired && (
            <Required>(required)</Required>
          )}

          {tooltipLabel && (
            <TooltipContainer>
              <Tooltip content={tooltipLabel}>
                <InfoIcon />
              </Tooltip>
            </TooltipContainer>
          )}
        </Label>
      )}

      <InputContainer
        $hasIconBefore={!!iconBefore}
        $hasIconAfter={!!iconAfter || !!endInput}
        $iconPadding={iconPadding}
      >
        <StyledInput
          ref={ref}
          {...inputProps}
          $hasError={!!error}
          style={{
            ...inputHidden && { display: 'none' },
          }}
        />

        {iconBefore && <IconBeforeContainer>{iconBefore}</IconBeforeContainer>}

        {iconAfter && (
          <ActionIcon onClick={onClickIcon}>
            {iconAfter}
          </ActionIcon>
        )}

        {endInput && <EndContainer>{endInput}</EndContainer>}
      </InputContainer>

      {helper && !error && (
        <Helper role="alert" $size={helperSize}>{helper}</Helper>
      )}

      {error && (
        <Helper role="alert" $hasError $size={helperSize}>{error}</Helper>
      )}
    </Container>
  );
});

export const InputComponents = {
  ActionIcon,
  EndContainer,
  Helper,
  Required,
};
