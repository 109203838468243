import { ViewType } from '@cycle-app/graphql-codegen';
import { FC, Fragment } from 'react';

import {
  BoardGroupSkeleton,
  Props as BoardGroupSkeletonProps,
} from 'src/components/BoardGroup/BoardGroupSkeleton';

import {
  SwimlaneDocGroups,
  GroupHeaderSkeleton,
  Container,
  Content,
  GroupByHeaders,
  SwimlaneDocSkeleton,
  EmojiSkeleton,
  DocTitleSkeleton,
} from './BoardContentWithSwimlane.styles';

interface Props {
  className?: string;
}

type GroupsData = Partial<BoardGroupSkeletonProps>[];

const GROUPS_DATA: Array<GroupsData> = [
  [
    { nbCards: 2 },
    { nbCards: 2 },
    { nbCards: 2 },
  ],
  [
    { nbCards: 2 },
    { nbCards: 2 },
    { nbCards: 2 },
  ],
];

export const BoardContentWithSwimlaneSkeleton: FC<Props> = ({ className }) => (
  <Container className={className}>
    <Content>
      <GroupByHeaders>
        {GROUPS_DATA[0].map((_, idx) => (
          <GroupHeaderSkeleton
            // eslint-disable-next-line react/no-array-index-key
            key={`group-header-${idx}`}
            viewType={ViewType.Kanban}
          />
        ))}
      </GroupByHeaders>

      {GROUPS_DATA.map((swimlane, swimlaneIdx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`swimlane-${swimlaneIdx}`}>
          <SwimlaneDocSkeleton>
            <EmojiSkeleton />
            <DocTitleSkeleton />
          </SwimlaneDocSkeleton>

          <SwimlaneDocGroups>
            {swimlane.map((groupProps, groupIdx) => (
              <BoardGroupSkeleton
                // eslint-disable-next-line react/no-array-index-key
                key={`swimlane-${swimlaneIdx}-group-${groupIdx}`}
                viewType={ViewType.Kanban}
                {...groupProps}
              />
            ))}
          </SwimlaneDocGroups>
        </Fragment>
      ))}
    </Content>
  </Container>
);
