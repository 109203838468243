import { ViewType } from '@cycle-app/graphql-codegen';
import { typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { WithViewType } from 'src/types/viewType.types';

const ContentKanbanCss = css`
  /* In Kanban view we want the scroll overflow to be on the extreme sides, not inside the padding area */
  left: 0;
  right: 0;
  bottom: 0;
`;

const ContentListCss = css`
  left: var(--spacing);
  right: var(--spacing);
  bottom: 0;
`;

export const Content = styled.div<WithViewType>`
  position: absolute;
  top: 8px;

  ${(props) => (props.viewType === ViewType.Kanban ? ContentKanbanCss : ContentListCss)};
`;

export const Container = styled.div`
  --spacing: 24px;
  flex: auto;
  position: relative;
  height: 100%;

  ${mobile} {
    --spacing: 16px;
  }
`;

export const LoadMoreGroups = styled.button<{ viewType: ViewType; isLoading: boolean }>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  white-space: nowrap;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.caption500};

  &:hover {
    background-color: ${p => p.theme.colors.background.hover};
    color: ${p => p.theme.colors.text.primary};
  }

  ${p => p.viewType === ViewType.Kanban && css`
    padding: 0;
    flex-direction: column;
    
    background-color: ${p.theme.colors.group.bg};
    
    min-width: 85px;
    min-height: 60px;
  `}

  ${p => p.viewType === ViewType.List && css`
    margin-right: auto;
    padding: 2px 4px;
    flex-direction: row;
    gap: 4px;
    min-width: 90px;

    background-color: transparent;

    ${p.isLoading && css`
      margin: auto;
    `}
  `}
`;
