import { typo, ButtonCss } from '@cycle-app/ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  BoardsAnimationContainer,
  BoardsIllustrationCard,
  IncognitoIllustationCard,
  IntegrationIllustrationCard,
  PapersPinnedIllustationCard,
  SectionsIllustrationCard,
} from '../IllustationCards';
import PortalModal from '../PortalModal/PortalModal';

export const StyledPortalModal = styled(PortalModal)`
  width: 580px;
  padding: 24px 24px 56px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const Title = styled.h2`
  ${typo.headerSmall}
`;

export const StyledButton = styled(Link).attrs({
  $variant: 'primary',
  $size: 'M',
})`
  ${ButtonCss}
  --bg: ${p => p.theme.colors.background.blue};
  --color: ${p => p.theme.colors.background.white};

  &:hover, &:focus {
    --bg: ${p => p.theme.colors.background.blueDarker};
  }
`;

export const Desc = styled.p`
  ${typo.body400}
  max-width: 350px;
  margin: 24px auto 0;
  text-align: center;
`;

export const StyledSectionsIllustrationCard = styled(SectionsIllustrationCard)`
  max-width: 185px;
  margin: 60px auto 0;
`;

export const StyledPapersPinnedIllustationCard = styled(PapersPinnedIllustationCard)`
  height: 260px;
  max-width: 245px;
  margin: 0 auto;
`;

export const StyledBoardsAnimationContainer = styled(BoardsAnimationContainer)`
  height: 95px;
  margin: 50px auto 0;
  width: 95px;
`;

export const StyledBoardsIllustrationCard = styled(BoardsIllustrationCard)`
  width: 100%;
  height: 100%;
`;

export const StyledIntegrationIllustrationCard = styled(IntegrationIllustrationCard)`
  height: 95px;
  margin: 50px auto 0;
  width: 95px;

  svg {
    width: 23px;
    height: 23px;
  }
`;

export const StyledIncognitoIllustationCard = styled(IncognitoIllustationCard)`
  display: flex;
  height: 90px;
  justify-content: center;
  margin-top: 40px;
`;

export const ModalOverlay = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${p => p.theme.colors.docPanel.overlay};
`;
