import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const CustomerIconOutline: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6875 8C13.6875 11.1412 11.1412 13.6875 8 13.6875V15C11.866 15 15 11.866 15 8H13.6875ZM8 13.6875C4.85888 13.6875 2.3125 11.1412 2.3125 8H1C1 11.866 4.134 15 8 15V13.6875ZM2.3125 8C2.3125 4.85888 4.85888 2.3125 8 2.3125V1C4.134 1 1 4.134 1 8H2.3125ZM8 2.3125C11.1412 2.3125 13.6875 4.85888 13.6875 8H15C15 4.134 11.866 1 8 1V2.3125Z" />
    <path d="M9.3125 6.25C9.3125 6.97485 8.72485 7.5625 8 7.5625V8.875C9.44979 8.875 10.625 7.69979 10.625 6.25H9.3125ZM8 7.5625C7.27515 7.5625 6.6875 6.97485 6.6875 6.25H5.375C5.375 7.69979 6.55021 8.875 8 8.875V7.5625ZM6.6875 6.25C6.6875 5.52512 7.27515 4.9375 8 4.9375V3.625C6.55021 3.625 5.375 4.80026 5.375 6.25H6.6875ZM8 4.9375C8.72485 4.9375 9.3125 5.52512 9.3125 6.25H10.625C10.625 4.80026 9.44979 3.625 8 3.625V4.9375Z" />
    <path d="M3.29384 11.9547C3.06174 12.2331 3.09924 12.647 3.3776 12.879C3.65598 13.1111 4.06979 13.0736 4.30189 12.7953L3.29384 11.9547ZM11.6983 12.7953C11.9304 13.0736 12.3442 13.1111 12.6226 12.879C12.9009 12.647 12.9385 12.2331 12.7063 11.9547L11.6983 12.7953ZM4.30189 12.7953C5.18571 11.7353 6.51403 11.0625 8.00013 11.0625V9.75C6.10826 9.75 4.41634 10.6085 3.29384 11.9547L4.30189 12.7953ZM8.00013 11.0625C9.48614 11.0625 10.8145 11.7353 11.6983 12.7953L12.7063 11.9547C11.5839 10.6085 9.89197 9.75 8.00013 9.75V11.0625Z" />
  </Svg>
);
