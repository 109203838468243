import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const useParentFromBoardConfig = (): string | null => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  const parentId = useMemo(() => {
    const parentFilter = boardConfig?.filterProperties.edges
      .find(filterProperty => filterProperty.node.__typename === 'FilterPropertyRuleDocParent');

    if (parentFilter?.node.__typename === 'FilterPropertyRuleDocParent' &&
      parentFilter.node.docParentRule.__typename === 'RuleDocParentMultipleValues'
    ) {
      const selectedValues = nodeToArray(parentFilter.node.docParentRule.selectedValues).map(({ value }) => value.id);
      return selectedValues.length === 1 ? selectedValues[0] : null;
    }

    return null;
  }, [boardConfig]);

  return parentId;
};
