import {
  AddNewDocDocument,
  AddNewDocMutationVariables,
  BoardConfigDocument,
  PublishedBoardConfigFullFragment,
  BoardConfigQueryVariables,
  GroupNodeDocument,
  GroupFilteredFragment,
  GroupNodeQueryVariables,
  DocEdge,
  Doc,
  DoctypeRelativeFragment,
  CreateDocPosition,
} from '@cycle-app/graphql-codegen';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useAssigneeFromBoardConfig } from 'src/hooks/api/useAssigneeFromBoardConfig';
import { useAttributesFromBoardConfig } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromBoardConfig';
import { useParentFromBoardConfig } from 'src/hooks/api/useParentIdFromBoardConfig';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useMergeQuery } from 'src/hooks/useMergeQuery';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setLastDoctypeIdUsed } from 'src/reactives/lastView.reactive';
import { defaultGroupPagination, defaultPagination } from 'src/utils/pagination.util';

import { useCustomerDocFromCache } from '../cache/cacheCustomerDoc';
import { useCustomerFromBoardConfig } from '../useCustomerFromBoardConfig';

interface UseCreateDocParams {
  from?: 'top' | 'bottom';
  groupId?: string;
  statusId?: string;
}

interface CreateParams {
  title: string;
  doctype: DoctypeRelativeFragment;
  parentId?: string;
}

export const useCreateDoc = ({
  groupId,
  statusId,
  from,
}: UseCreateDocParams = {}) => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const attributesFromBoardConfig = useAttributesFromBoardConfig();
  const product = useProductBase();
  const [createDoc, { loading }] = useSafeMutation(AddNewDocDocument);
  const { addCustomerDoc } = useCustomerDocFromCache();

  const hasDefaultAttributes = attributesFromBoardConfig.length > 0;

  const mergeBoardConfig = useMergeQuery<{ node: PublishedBoardConfigFullFragment }, BoardConfigQueryVariables>({
    query: BoardConfigDocument,
  });

  const mergeGroup = useMergeQuery<{ node: GroupFilteredFragment }, GroupNodeQueryVariables>({
    query: GroupNodeDocument,
  });

  const assigneeFromBoardConfig = useAssigneeFromBoardConfig(groupId);
  const { firstSelectedCustomerId } = useCustomerFromBoardConfig();
  const parentIdFromBoardConfig = useParentFromBoardConfig();

  function create({
    doctype,
    ...params
  }: CreateParams) {
    if (!product || !boardConfig) return null;

    const doctypeId = doctype.id;

    const variables: AddNewDocMutationVariables = {
      ...params,
      doctypeId,
      productId: product?.id,
      groupId,
      statusId,
      attributes: attributesFromBoardConfig,
      isDraft: false,
      position: from === 'top' ? CreateDocPosition.Start : CreateDocPosition.End,
      ...!params.parentId && { parentId: parentIdFromBoardConfig },
      ...assigneeFromBoardConfig && { assignee: assigneeFromBoardConfig },
      ...firstSelectedCustomerId && { customerId: firstSelectedCustomerId },
    };

    setLastDoctypeIdUsed(doctypeId);

    return createDoc({
      variables,
      update: (_, { data }) => {
        if (!data?.addNewDoc) return;

        const nodeNewDoc: Omit<DocEdge, 'cursor'> = {
          __typename: 'DocEdge',
          node: data?.addNewDoc as Doc,
        };

        if (data.addNewDoc.customer) {
          addCustomerDoc({
            doc: data.addNewDoc,
            customer: data.addNewDoc.customer,
            doctypeId,
          });
        }

        if (boardConfig?.docQuery.__typename === 'BoardQuery') {
          mergeBoardConfig(
            {
              id: boardConfig?.id ?? '',
              ...defaultPagination,
            },
            {
              node: {
                docQuery: {
                  docGroup: {
                    docs: {
                      edges: [nodeNewDoc],
                    },
                  },
                },
              },
            },
            from === 'top' ? 'start' : 'end',
          );
        } else if (boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' || boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          mergeGroup(
            {
              groupId: groupId ?? '',
              ...defaultGroupPagination,
            },
            {
              node: {
                docs: {
                  edges: [nodeNewDoc],
                },
              },
            },
            from === 'top' ? 'start' : 'end',
          );
        }
      },
    });
  }

  return {
    createDoc: create,
    hasDefaultAttributes,
    loading,
  };
};
