import { useMemo } from 'react';

import { getDocKey } from '../../utils/doc.util';
import { useDocChildrens } from '../api/useDoc';
import { useProductBase } from '../api/useProduct';
import { InsightList } from './useDocInsights.types';

export const useDocInsightChildren = ({
  docId, doctypeId,
}: { docId: string; doctypeId: string }) => {
  const product = useProductBase();
  const {
    docs,
    pageInfo,
    loading: isLoading,
    loadMore,
    count,
    isPaginationLoading,
  } = useDocChildrens({
    doctypeId,
    docId,
  });

  const insights = useMemo<InsightList>(() => {
    return docs.map(node => {
      const {
        docSource, ...rest
      } = node;
      const linkedDoc = {
        ...rest,
        _docKey: getDocKey(product?.key, rest.publicId),
      };
      if (!docSource?.__typename) {
        return {
          __typename: 'DocTarget',
          // it is fine to set an empty id here as it is not used.
          // loops keys should be done on the doc id.
          id: '',
          doc: linkedDoc,
        };
      }
      const decodedDocSourceId = atob(docSource.id);
      return {
        __typename: 'DocTarget',
        id: btoa(decodedDocSourceId.replace(docSource.__typename, 'DocTarget')),
        blockId: docSource.blockId,
        content: docSource.content,
        doc: linkedDoc,
      };
    });
  }, [docs, product]);

  return {
    count,
    insights,
    isLoading,
    loadMore,
    pageInfo,
    isPaginationLoading,
  };
};
