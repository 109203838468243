import { boxShadowZ3 } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

const zIndexSidebar = mappingZindex[Layer.Sidebar];

interface ToggleSidebarBtnProps {
  $collapsed: boolean;
}
export const ToggleSidebarButton = styled.button<ToggleSidebarBtnProps>`
  position: fixed;
  top: 16px;
  border-radius: 50%;
  background-color: ${p => p.theme.userColors.main};
  width: 24px;
  height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  
  transition: transform 200ms ease-in-out;
  transform: 
    translateX(calc(-50% + 1px))
    scale(1);
  
  svg {
    transform: ${p => (p.$collapsed ? 'rotate(-90deg)' : 'rotate(90deg)')};
  }

  &:hover {
    transform: 
      translateX(calc(-50% + 1px))
      scale(1.2);
  }
`;

export const HandleResize = styled.div<{ resizing: boolean; resizingDisabled: boolean }>`
  cursor: ${p => (p.resizingDisabled ? 'pointer' : 'ew-resize')};

  position: absolute;
  top: 0;
  right: -1px;

  width: 2px;
  height: 100%;

  &:before {
    content: "";
    position: fixed;
    width: 2px;
    height: 100%;
    ${p => p.resizing && css`
      background: ${p.theme.userColors.main};
    `};
  }

  &:hover {
    &:before {
      background: ${p => p.theme.userColors.main};
    }

    ${ToggleSidebarButton} {
      opacity: ${p => (p.resizing ? 0 : 1)};
    }
  }
`;

export const ToggleLine = styled.div`
  position: absolute;
  top: -16px;
  right: 8px;
  width: 4px;
  height: 500px;
`;

export const Container = styled(motion.div)<{
  $withBorder: boolean;
}>`
  z-index: ${zIndexSidebar};
  position: relative;
  border-right: 1px solid ${p => (p.$withBorder
    ? p.theme.colors.sidebar.border
    : 'transparent')
};

  ${mobile} {
    position: absolute;
    z-index: ${zIndexSidebar};
    height: 100%;
    ${boxShadowZ3}
  }
`;

export const Mask = styled.div`
  position: absolute;
  z-index: ${zIndexSidebar - 1};
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;
