import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const QuestionOutlineIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2.35484C4.88226 2.35484 2.35484 4.88226 2.35484 8C2.35484 11.1177 4.88226 13.6452 8 13.6452C11.1177 13.6452 13.6452 11.1177 13.6452 8C13.6452 4.88226 11.1177 2.35484 8 2.35484ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8.00001 12.215C8.4365 12.215 8.79034 11.8612 8.79034 11.4247C8.79034 10.9882 8.4365 10.6344 8.00001 10.6344C7.56353 10.6344 7.20969 10.9882 7.20969 11.4247C7.20969 11.8612 7.56353 12.215 8.00001 12.215ZM8.4919 4.20971C8.00278 4.11242 7.49579 4.16236 7.03504 4.3532C6.5743 4.54405 6.18049 4.86724 5.90343 5.2819C5.62636 5.69656 5.47848 6.18406 5.47848 6.68277C5.47848 7.0569 5.78177 7.36019 6.1559 7.36019C6.53002 7.36019 6.83332 7.0569 6.83332 6.68277C6.83332 6.45202 6.90174 6.22646 7.02993 6.0346C7.15813 5.84275 7.34034 5.69321 7.55352 5.60491C7.7667 5.51661 8.00128 5.4935 8.22759 5.53852C8.4539 5.58354 8.66178 5.69465 8.82494 5.85781C8.9881 6.02097 9.09922 6.22885 9.14423 6.45516C9.18925 6.68147 9.16614 6.91605 9.07784 7.12923C8.98954 7.34241 8.84 7.52462 8.64815 7.65282C8.45629 7.78101 8.23073 7.84944 7.99998 7.84944C7.82032 7.84944 7.64802 7.92081 7.52097 8.04785C7.39393 8.17489 7.32256 8.34719 7.32256 8.52686V9.05374C7.32256 9.42787 7.62585 9.73116 7.99998 9.73116C8.35439 9.73116 8.64523 9.45899 8.67491 9.11227C8.93167 9.04094 9.17689 8.92898 9.40086 8.77932C9.81552 8.50226 10.1387 8.10845 10.3295 7.64771C10.5204 7.18696 10.5703 6.67997 10.473 6.19085C10.3757 5.70172 10.1356 5.25243 9.78296 4.8998C9.43032 4.54716 8.98103 4.30701 8.4919 4.20971Z" fill="currentcolor" />
  </Svg>
);
