import { gql } from '@apollo/client';
import {
  AddProductSectionDocument,
  AddSectionBoardDocument,
  AddSectionBoardMutationVariables,
  AddSectionDraftBoardDocument,
  AddSectionDraftBoardMutationVariables,
  BoardNodeDocument,
  MarkNotificationAsReadDocument,
  NotificationsConnectionFragment,
  SectionType,
} from '@cycle-app/graphql-codegen';

import { Events, Sources } from 'src/constants/analytics.constants';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useLoader } from 'src/hooks/useLoader';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { DEFAULT_EMOJI } from 'src/utils/emoji.util';
import { defaultPagination } from 'src/utils/pagination.util';

export default function useProductMutations() {
  const product = useProductBase();

  const [addProductSectionMutation, { loading: loadingAddProductSection }] = useSafeMutation(AddProductSectionDocument);
  const [addSectionBoardMutation, { loading: loadingAddSectionBoard }] = useSafeMutation(AddSectionBoardDocument, {
    onCompleted: () => trackAnalytics(Events.BoardCreated, {
      source: Sources.Sidebar,
    }),
  });
  const [addSectionDraftBoardMutation, { loading: loadingAddSectionDraftBoard }] = useSafeMutation(AddSectionDraftBoardDocument);
  const [markNotificationAsReadMutation, { loading: loadingMarkNotificationAsRead }] = useSafeMutation(MarkNotificationAsReadDocument);

  const loading =
    loadingAddProductSection ||
    loadingAddSectionBoard ||
    loadingMarkNotificationAsRead || loadingAddSectionDraftBoard;

  useLoader({ loading });

  return {
    loading,
    addProductSection,
    addSectionBoard,
    addSectionDraftBoard,
    markNotificationAsRead,
  };

  function addSectionDraftBoard(variables: AddSectionDraftBoardMutationVariables) {
    return addSectionDraftBoardMutation({
      variables,
      update: (cache, { data }) => {
        if (data?.createDraftBoard) {
          const {
            draftBoardConfig, id,
          } = data.createDraftBoard;
          // Write temp BoardWithMinimalConfig
          cache.writeQuery({
            query: BoardNodeDocument,
            variables: { id },
            data: {
              node: {
                id,
                name: 'temp name',
                emoji: DEFAULT_EMOJI,
                description: 'temp description',
                publishedBoardConfig: draftBoardConfig && {
                  id: 'temp-id',
                  viewType: draftBoardConfig.viewType,
                  docQuery: draftBoardConfig.docQuery,
                },
                savedBoardConfig: null,
                draftBoardConfig,
              },
            },
          });
        }
      },
    });
  }

  function addProductSection(name: string) {
    if (!product?.id) return null;
    return addProductSectionMutation({
      variables: {
        productId: product.id,
        name,
      },
      optimisticResponse: {
        addProductSection: {
          __typename: 'BoardSection',
          id: 'temp-id',
          name,
          type: SectionType.Default,
          boards: {
            edges: [],
          },
        },
      },
      update: (cache, { data }) => {
        if (!data?.addProductSection) return;

        const newBoardSectionRef = cache.writeFragment({
          data: data.addProductSection,
          fragment: gql`
            fragment NewBoardSection on BoardSection {
              id
            }
          `,
        });

        if (!newBoardSectionRef) return;

        cache.modify({
          id: product.id,
          fields: {
            boardSections: (boardSections) => ({
              ...boardSections,
              // For now we add it to the end of the list, but after refresh it is not positioned there but usually at the top.
              edges: boardSections.edges.concat({
                __typename: 'BoardSectionEdge',
                node: newBoardSectionRef,
              }),
            }),
          },
        });
      },
    });
  }

  function addSectionBoard({
    sectionId, emoji, name, description,
  }: Pick<AddSectionBoardMutationVariables, 'sectionId' | 'emoji' | 'name' | 'description'>) {
    return addSectionBoardMutation({
      variables: {
        sectionId,
        emoji,
        name,
        description,
        ...defaultPagination,
      },
      // The update is already handled by the product subscription
    });
  }

  function markNotificationAsRead(productId: string, currentNotifications: NotificationsConnectionFragment) {
    const optimisticNotifications = {
      ...currentNotifications,
      edges: currentNotifications.edges.map(edge => ({
        ...edge,
        node: {
          ...edge.node,
          read: true,
        },
      })),
    };

    return markNotificationAsReadMutation({
      variables: { productId },
      optimisticResponse: {
        markNotificationAsRead: {
          __typename: 'Product',
          id: productId,
          notificationsNotRead: 0,
          notifications: optimisticNotifications,
        },
      },
    });
  }
}
