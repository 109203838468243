import { CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { UnlinkIcon, PenIcon, ReorderIcon } from '@cycle-app/ui/icons';
import { FC, useCallback } from 'react';

import AttributeOptionsManager from 'src/components/AttributeOptionsManager/AttributeOptionsManager';
import { AttributeUnlinkDoctypeModal } from 'src/components/AttributeUnlinkDoctypeModal/AttributeUnlinkDoctypeModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { Layer } from 'src/types/layers.types';
import { getAttributeOptions } from 'src/utils/attributes.util';

interface Props {
  doc: FullDocWithPublicId;
  attributeDefinition: CustomAttributeDefinitionFragment;
}

const DocPanelPropertyOptions: FC<Props> = ({
  doc, attributeDefinition,
}) => {
  const [isEditingOptions, {
    setTrueCallback: setIsEditingOptions,
    setFalseCallback: setIsNotEditingOptions,
  }] = useOptimizedBooleanState(false);
  const [showWarningModal, {
    setTrueCallback: setShowWarningModal,
    setFalseCallback: setHideWarningModal,
  }] = useOptimizedBooleanState(false);

  const { removeAttributeDoctype } = useAttributesMutations();

  const showEditProperties =
    attributeDefinition.__typename === 'AttributeSingleSelectDefinition' ||
    attributeDefinition.__typename === 'AttributeMultiSelectDefinition';

  const options: Array<SelectOption> = [
    ...(showEditProperties ? [{
      label: 'Edit options',
      value: 'edit-properties',
      icon: <PenIcon />,
      onSelect: setIsEditingOptions,
      keepDropdownOnSelect: true,
    }] : []),
    {
      label: 'Unlink',
      value: 'unlink',
      variant: 'danger',
      icon: <UnlinkIcon />,
      onSelect: setShowWarningModal,
    },
  ];

  const handleConfirm = useCallback(async () => {
    if (!doc?.doctype.id) return;
    await removeAttributeDoctype(attributeDefinition, doc.doctype.id, doc);
  }, [attributeDefinition, doc, removeAttributeDoctype]);

  return (
    <>
      <DotsMenuLayer
        options={options}
        icon={<ReorderIcon />}
        placement="bottom-end"
        contentDropdown={isEditingOptions
          ? (
            <AttributeOptionsManager
              attributeDefinition={attributeDefinition}
              options={getAttributeOptions(attributeDefinition)}
            />
          )
          : undefined}
        layer={Layer.DropdownCustomProperty}
        onHide={setIsNotEditingOptions}
      />
      {showWarningModal && doc && (
        <AttributeUnlinkDoctypeModal
          doctype={doc.doctype}
          attribute={attributeDefinition}
          useHighMaskLayer
          hide={setHideWarningModal}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default DocPanelPropertyOptions;
