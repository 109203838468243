import styled, { css } from 'styled-components';

import { boxShadowZ3 } from '../../../utils/styles.util';
import { Card } from '../Card/Card';

export interface ContainerProps {
  draft?: boolean;
  isCreating?: boolean;
  isDragging?: boolean;
  isHover?: boolean;
  isFocused?: boolean;
  asPlaceholder?: boolean;
  direction?: 'left' | 'right';
  isSelected?: boolean;
  selectedLength?: number;
}

function getRotate(direction = 'right'): number {
  return direction === 'right' ? 2 : -2;
}

export const Content = styled.div`
  z-index: 1;
  position: relative;
`;

const ContainerDraggingCss = css<ContainerProps>`
  transform: translate3d(10px, 0, 0) rotate(${p => getRotate(p.direction)}deg);
  border-color: ${p => p.theme.userColors.main};
  border-width: 2px;
`;

const ContainerDraftCss = css`
  color: ${p => p.theme.userColors.fg};
  border-color: ${p => p.theme.userColors.br};
  background-color: ${p => p.theme.userColors.bg};
  border-style: solid;

  &:hover {
    border-color: ${p => p.theme.userColors.main};
    background-color: ${p => p.theme.userColors.bg};
  }
`;

const ContainerPlaceholderCss = css`
  color: ${p => p.theme.userColors.fg};
  border-color: ${p => p.theme.userColors.br};
  background-color: ${p => p.theme.userColors.bg};
  border-style: dashed;

  ${Content} {
    opacity: 0;
  }
`;

const ContainerHoverCss = css`
  background-color: ${p => p.theme.colors.card.bgHover};
`;

const ContainerSelectedCss = css`
  border-color: ${p => p.theme.userColors.main};
  ${boxShadowZ3};
  &:hover {
    border-color: ${p => p.theme.userColors.main};
  }
`;

const ContainerCreatingCss = css`
  opacity: 0.5;
`;

export const Container = styled(Card)<ContainerProps>`
  padding: 8px;

  ${p => p.draft && ContainerDraftCss};
  ${p => p.isDragging && ContainerDraggingCss};
  ${p => p.isHover && ContainerHoverCss};
  ${p => p.isSelected && ContainerSelectedCss};
  ${p => p.isCreating && ContainerCreatingCss};
  ${p => p.asPlaceholder && ContainerPlaceholderCss};

  ${p => (p.isDragging || p.asPlaceholder || p.isCreating) && css`
    pointer-events: none;
  `};
`;

export const Counter = styled.div<{ direction?: 'left' | 'right' }>`
  position: absolute;
  top: -8px;
  right: -8px;

  width: 16px;
  height: 16px;

  text-align: center;
  align-items: center;
  justify-content: center;

  padding: 2px;
  border-radius: 50%;
  background-color: ${p => p.theme.userColors.main};
  color: white;

  font-size: 10px;
`;
