import { getRandomItem } from '@cycle-app/utilities';
import { FC, useRef, useEffect } from 'react';

import { mates } from '../OnboardingCollabMates/OnboardingCollabMates';
import {
  Container, AvatarContainer, IconDiretionConainer, StyledAvatar, StyledDirectionIcon, IconRotationConainer,
} from './OnboardingFloatingMates.styles';

interface Props {
  className?: string;
  positions?: { y: number; x: number; rot: number }[];
}

export const OnboardingFloatingMates: FC<Props> = ({
  className, positions,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const containerRect = containerRef.current.getBoundingClientRect();
    const elements = Array.from(containerRef.current?.querySelectorAll<HTMLDivElement>(':scope > div') ?? []);
    const randomPositions = [...Array(100)].map((_, i) => i);

    elements.forEach((el, i) => {
      const x = getRandomItem(randomPositions);
      const y = getRandomItem(randomPositions);
      // eslint-disable-next-line no-param-reassign
      el.style.top = `${positions?.[i]?.y ?? y}%`;
      // eslint-disable-next-line no-param-reassign
      el.style.left = `${positions?.[i]?.x ?? x}%`;
    });

    elements.forEach((el, i) => {
      const rect = el.getBoundingClientRect();
      const x = (rect.left) + (rect.width / 2);
      const y = (rect.top) + (rect.height / 2);
      // Cursors will point to the center of the container.
      const rad = Math.atan2(
        containerRect.left + containerRect.width / 2 - x,
        containerRect.top + containerRect.height / 2 - y,
      );
      const rot = (rad * (180 / Math.PI) * -1) + 90;
      el.style.setProperty('--rotate', `${positions?.[i]?.rot ?? rot}deg`);
    });
  }, [positions]);

  return (
    <Container className={className} ref={containerRef}>
      {mates.map((mate, i) => (
        <AvatarContainer
          key={mate.id}
          $color={mate.color}
          initial={{
            opacity: 0,
            rotate: '-180deg',
            scale: 0,
          }}
          animate={{
            opacity: 1,
            rotate: 0,
            scale: 1,
          }}
          transition={{
            scale: {
              type: 'spring',
              bounce: 0.5,
            },
            delay: i * 0.1,
          }}
        >
          <IconRotationConainer>
            <IconDiretionConainer>
              <StyledDirectionIcon />
            </IconDiretionConainer>
          </IconRotationConainer>
          <StyledAvatar user={mate} size={24} />
        </AvatarContainer>
      ))}
    </Container>
  );
};
