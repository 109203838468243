import { DocFullFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { useHotkeys, Setter, FeatureFlag } from '@cycle-app/utilities';
import { Editor as TiptapCoreEditor } from '@tiptap/core';
import { useEditor, EditorContent } from '@tiptap/react';
import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  memo,
} from 'react';
import { useTheme } from 'styled-components';
import { WebsocketProvider } from 'y-websocket';
import { Doc } from 'yjs';

import Bubble from 'src/components/Editor/Bubble/Bubble';
import { EditorContextProvider } from 'src/contexts/editorContext';
import { fullEditorExtensions } from 'src/editorExtensions/editorExtensions';
import useEditorContributors from 'src/hooks/editor/useEditorContributors';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { useForcedFocus } from 'src/hooks/editor/useForcedFocus';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import useUploadFile from 'src/hooks/useUploadFile';
import { useGetHighlight } from 'src/reactives/highlight.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetBillingPermission } from 'src/reactives/permission.reactive';
import { setToasters } from 'src/reactives/toasters.reactive';
import { ActionId } from 'src/services/editor/editorActions';
import { reorderProsemirrorPlugins } from 'src/utils/editor/editor.utils';
import { isUploadRestricted } from 'src/utils/files.util';

import { HighlightMenu } from '../HighlightMenu/HighlightMenu';
import { StyledContent, EmptyBlock } from './Editor.styles';
import { EditorBubbleContainer } from './EditorBubbleContainer';
import { EditorQuickActions } from './EditorQuickActions';
import { useEditorTemplate } from './useEditorTemplate';

export interface EditorProps {
  className?: string;
  doc?: DocFullFragment | null;
  parentRef?: RefObject<HTMLDivElement>;
  collaboration?: {
    document: Doc;
  };
  cursors?: {
    provider: WebsocketProvider;
    user: {
      color: string;
      name: string;
    };
  };
  autoFocus?: boolean;
  content?: string;
  onUpdate?: (p: { html: string; json: string; text: string }) => void;
  applyTemplateOnDoctypeUpdate?: boolean;
  disabledActions?: ActionId[];
  defaultDoctypeId?: string;
  setDocContent?: Setter<string>;
  autoFocusStart?: boolean;
  onEditorReady?: (editor: TiptapCoreEditor) => void;
  showHierarchy?: boolean;
  isLoading?: boolean;
  isDraft?: boolean;
  hideQuickActions?: boolean;
  showAddTemplate?: boolean;
  showIntegrations?: boolean;
}
export const Editor: FC<EditorProps> = memo(({
  className,
  doc,
  parentRef,
  collaboration,
  cursors,
  autoFocus = false,
  autoFocusStart,
  content,
  onUpdate,
  applyTemplateOnDoctypeUpdate = false,
  disabledActions = [],
  defaultDoctypeId,
  setDocContent,
  onEditorReady,
  showHierarchy = false,
  isLoading,
  isDraft,
  hideQuickActions,
  showAddTemplate,
  showIntegrations,
}) => {
  const isFeedback = doc?.doctype.type === DoctypeType.Feedback;
  const { isEnabled: isInsightTurnIntoEnabled } = useFeatureFlag(FeatureFlag.InsightDocLinkTurnInto);
  const disabledEditorActions = useMemo(() => {
    const actionsToDisable = [...disabledActions];
    if (isDraft) {
      actionsToDisable.push(...[ActionId.Notion, ActionId.GithubIssue, ActionId.Linear]);
    }
    if (!isInsightTurnIntoEnabled || !isFeedback) {
      actionsToDisable.push(ActionId.TurnTextIntoInsight);
    }
    return actionsToDisable;
  }, [isInsightTurnIntoEnabled, isDraft, disabledActions, isFeedback]);
  const theme = useTheme();
  const {
    onError,
    onUserMentioned,
  } = useEditorLogic(doc?.id);
  const {
    isUploading, onUpload,
  } = useUploadFile();
  const highlight = useGetHighlight();
  const contributors = useEditorContributors();

  const initialDoctypeId = useRef(defaultDoctypeId);

  const [dragFileIsDisabled, setDragFileIsDisabled] = useState(false);

  const isUpdated = useRef(false);
  const templateUpdated = useRef(false);

  const { canUploadNotRestricted } = useGetBillingPermission();

  const templateJson = doc?.doctype.template?.contentJSON;

  const onPastedFile = useCallback(async (editor: TiptapCoreEditor, file: File) => {
    if (isUploadRestricted(file, canUploadNotRestricted)) {
      setLimitationsModal({ action: 'UPLOAD_NOT_RESTRICTED' });
      return;
    }

    const uploadedFile = await onUpload(file, 'paste');

    if (!uploadedFile) return;

    editor?.chain()
      .setFile({ file: uploadedFile })
      .createParagraphNear()
      .run();
  }, [onUpload, canUploadNotRestricted]);

  const editor = useEditor({
    content,
    onCreate: (params) => reorderProsemirrorPlugins(params.editor),
    onUpdate: ({ editor: e }) => {
      const html = e.getHTML();
      const json = JSON.stringify(e.getJSON());
      const text = e.getText();
      setDocContent?.(html);
      onUpdate?.({
        html,
        json,
        text,
      });
      if (!e.isEmpty && json !== templateJson) {
        isUpdated.current = true;
      }
      if (e.isFocused) {
        e.commands.scrollIntoView();
      }
    },
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
    },
    extensions: fullEditorExtensions({
      disabledActions: disabledEditorActions,
      people: contributors,
      userColor: theme.userColors.main,
      onUserMentioned,
      onPastedFile,
      ...(collaboration ? { collaboration } : {}),
      ...(cursors ? { cursors } : {}),
    }),
    ...(autoFocus ? { autofocus: autoFocusStart ? 'start' : 'end' } : {}),
  }, [collaboration?.document.clientID]);

  useEffect(() => {
    if (editor) {
      onEditorReady?.(editor);
    }
  }, [editor, onEditorReady]);

  useEffect(() => {
    if (collaboration?.document.clientID) {
      console.info(`Editor for ClientID ${collaboration?.document.clientID}`);
    }
  }, [collaboration?.document.clientID]);

  useForcedFocus({
    editor,
    parentRef,
    isEnabled: autoFocus,
    position: autoFocusStart ? 'start' : 'end',
  });

  useHotkeys('tab', (e) => {
    e.preventDefault();
  });

  const {
    applyTemplate,
    previewTemplateModal,
    openPreviewTemplateModal,
    closePreviewTemplateModal,
  } = useEditorTemplate({
    editor,
    docType: doc?.doctype,
    onUpdate,
  });

  useEffect(() => {
    if (
      applyTemplateOnDoctypeUpdate &&
      !isUpdated.current &&
      doc?.doctype.id &&
      (doc.doctype.id !== initialDoctypeId.current || templateUpdated.current)
    ) {
      applyTemplate();
      templateUpdated.current = true;
    }
  }, [doc?.doctype.id]);

  const onCreateEmptyLine = () => {
    editor?.chain().focus('end')
      .createParagraphNear()
      .run();
  };

  if (!editor) {
    return null;
  }

  return (
    <EditorContextProvider
      doc={doc}
      editor={editor}
      isUploading={isUploading}
      onError={onError}
      onUpload={onUpload}
      setDragFileIsDisabled={setDragFileIsDisabled}
      disabledActions={disabledEditorActions}
    >
      <EditorBubbleContainer className={className} disabled={dragFileIsDisabled}>
        {onMouseDown => (
          <>
            <Bubble disabledActions={disabledEditorActions} />
            <HighlightMenu isEnabled={!disabledEditorActions.includes(ActionId.TurnTextIntoInsight)} />
            <StyledContent
              className="content-editor"
              onMouseDown={onMouseDown}
              $highlightId={highlight.blockId || undefined}
            >
              <EditorContent
                editor={editor}
                onBlur={() => setToasters({ areShortcutsEnabled: true })}
                onFocus={() => setToasters({ areShortcutsEnabled: false })}
              />
              {!hideQuickActions && editor.isEmpty && !isLoading && (
                <EditorQuickActions
                  applyTemplate={applyTemplate}
                  disabledActions={disabledEditorActions}
                  docType={doc?.doctype}
                  onHidePreviewTemplate={closePreviewTemplateModal}
                  onShowPreviewTemplate={openPreviewTemplateModal}
                  showAddTemplate={showAddTemplate}
                  showIntegrations={showIntegrations}
                />
              )}
              <EmptyBlock onClick={onCreateEmptyLine} $hasBigHeight={!showHierarchy} />
            </StyledContent>
          </>
        )}
      </EditorBubbleContainer>

      {previewTemplateModal}
    </EditorContextProvider>
  );
});
