import { BillingFrequency, StripePortalDocument, StripePortalFragment, StripePortalQueryVariables } from '@cycle-app/graphql-codegen';

import { useProductBase } from 'src/hooks/api/useProduct';
import { useSafeAsyncLazyQuery } from 'src/hooks/useSafeAsyncLazyQuery';

import { Events } from '../../../constants/analytics.constants';
import { trackAnalytics } from '../../../utils/analytics/analytics';

export const useFetchStripePortal = () => {
  const product = useProductBase();
  const [query, { loading }] = useSafeAsyncLazyQuery<StripePortalFragment, StripePortalQueryVariables>(StripePortalDocument, {
    onCompleted: () => trackAnalytics(Events.BillingUpgradeViewed, {
      slug: product?.slug,
      productId: product?.id,
    }),
  });

  const fetch = async (isAnnual?: boolean) => {
    if (!product?.id) return null;
    const data = await query({
      variables: {
        productId: product.id,
        ...isAnnual && { frequency: BillingFrequency.Yearly },
      },
    });
    return data?.stripePortal;
  };

  return {
    fetch,
    loading,
  };
};
