import { ViewCardSkeleton, Group } from '@cycle-app/ui';
import { FC } from 'react';

import { ViewType } from 'src/types/viewType.types';

import { ContentList } from './BoardGroup.styles';
import {
  SkeletonGroupName,
  EmojiSkeleton,
  GroupNameSkeleton,
  NewDocContentSkeleton,
  NewDocContainer,
} from './BoardGroupSkeleton.styles';

export interface Props {
  className?: string;
  viewType: ViewType;
  nbCards?: number;
  withTitle?: boolean;
  withNewDoc?: boolean;
}

export const BoardGroupSkeleton: FC<Props> = ({
  className,
  nbCards = 1,
  withTitle = true,
  withNewDoc = true,
  viewType,
}) => (
  <Group
    className={className}
    loading
    inputName={withTitle
      ? (
        <SkeletonGroupName>
          <EmojiSkeleton />
          <GroupNameSkeleton />
        </SkeletonGroupName>
      ) : undefined}
    viewType={viewType}
  >
    {nbCards > 0 && (
      <ContentList>
        {Array(nbCards).fill(0).map((_item, i) => (
          <ViewCardSkeleton
            // eslint-disable-next-line react/no-array-index-key
            key={`nav-item-${i}`}
            viewType={viewType}
          />
        ))}
      </ContentList>
    )}

    {withNewDoc && (
      <NewDocContainer>
        <EmojiSkeleton />
        <NewDocContentSkeleton />
      </NewDocContainer>
    )}
  </Group>
);
