import { Input, Button } from '@cycle-app/ui';
import { FC, ReactNode } from 'react';
import { useForm } from 'react-hook-form';

import { CreateGithubIssueParams, CreateGithubIssueFormValues } from 'src/types/integrations.types';

import { AssigneesField } from './AssigneesField/AssigneesField';
import { Form, FieldsGroup, FormFooter } from './GithubIssueCreationForm.styles';
import { RepositoriesField } from './RepositoriesField/RepositoriesField';
import { StatusesField } from './StatusesField/StatusesField';

type Props = {
  initialValues: CreateGithubIssueFormValues;
  onSubmit: (params: CreateGithubIssueParams) => void;
  isLoading: boolean;
  submitLabel?: string;
  footerSlot?: ReactNode;
};

export const GithubIssueCreationForm: FC<Props> = ({
  initialValues,
  onSubmit,
  isLoading,
  submitLabel = 'Create',
  footerSlot,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateGithubIssueFormValues>({
    defaultValues: {
      title: initialValues.title,
      repository: undefined,
      description: initialValues.title ? '' : undefined,
      assignees: [],
    },
  });

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      {!!initialValues.title && (
        <Input
          id="title"
          type="title"
          label="Issue title"
          autoComplete="off"
          {...register('title', {
            required: true,
            minLength: 3,
          })}
          error={errors.title?.message}
        />
      )}

      <RepositoriesField control={control} />

      {!!initialValues.title && (
        <Input
          id="description"
          type="description"
          label="Description"
          autoComplete="off"
          placeholder="Your doc type description"
          {...register('description')}
          error={errors.title?.message}
        />
      )}

      <FieldsGroup>
        <StatusesField control={control} />
        <AssigneesField control={control} />
      </FieldsGroup>

      <FormFooter>
        {footerSlot}
        <Button
          type="submit"
          size="L"
          isLoading={isLoading}
        >
          {submitLabel}
        </Button>
      </FormFooter>

    </Form>
  );

  async function onFormSubmit(data: CreateGithubIssueFormValues) {
    if (!data.repository?.name) return;

    onSubmit({
      title: data.title,
      repoName: data.repository.name,
      description: data.description,
      assignees: data.assignees,
      status: data.status,
    });
  }
};
