import { useQuery } from '@apollo/client';
import { FetchBoardRecentlyCreatedDocsDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { defaultPagination } from 'src/utils/pagination.util';

import { getDocKey } from '../../utils/doc.util';
import { useProduct } from '../api/useProduct';

export const useBoardRecentlyCreatedDocs = () => {
  const { product } = useProduct();
  const {
    data, loading, refetch, fetchMore,
  } = useQuery(FetchBoardRecentlyCreatedDocsDocument, {
    skip: !product?.slug,
    variables: {
      productSlug: product?.slug || '',
      ...defaultPagination,
    },
    notifyOnNetworkStatusChange: true,
  });
  const returnData = useMemo(() => {
    if (
      data?.product?.__typename !== 'Product' ||
      data.product.allDocs.savedBoardConfig?.docQuery.__typename !== 'BoardQuery'
    ) return null;

    const groupdId = data.product.allDocs.savedBoardConfig.docQuery.docGroup.id;

    return {
      docs: nodeToArray(data.product.allDocs.savedBoardConfig?.docQuery.docGroup.docs).map(doc => ({
        ...doc,
        _docKey: getDocKey(product?.key, doc.publicId),
        _groupId: groupdId,
      })),
      groupId: groupdId,
      endCursor: data.product.allDocs.savedBoardConfig.docQuery.docGroup.docs.pageInfo.endCursor,
      hasNextPage: data.product.allDocs.savedBoardConfig.docQuery.docGroup.docs.pageInfo.hasNextPage,
      savedBoardConfig: data.product.allDocs.savedBoardConfig,
    };
  }, [data, product]);

  return {
    isLoading: loading,
    ...returnData,
    refetch,
    fetchMore,
  };
};
