import {
  CustomerFragment,
  DocChildFragment,
  UpdateDocCustomerDocument,
  UpdateDocCustomerMutationVariables,
} from '@cycle-app/graphql-codegen/generated';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { useRemoveDocFromBoardDocsList } from '../cache/cacheBoardDocsList';
import { useCustomerDocFromCache } from '../cache/cacheCustomerDoc';
import { useGetDocFromCache } from '../cache/cacheDoc';
import { useGetDocGroup, useUpdateDocsGroup } from '../cache/cacheGroupHooks';
import { useBoardGroups } from '../useBoardGroups';

interface LinkDocCustomerVariables {
  customer: CustomerFragment; docId: string; doctypeId: string; created: boolean; doc?: DocChildFragment;
}

export const useUpdateDocCustomer = () => {
  const customerIsRequired = useBoardConfig(ctx => ctx.customerIsRequired);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const getDocGroup = useGetDocGroup();
  const { groups } = useBoardGroups();
  const updateDocsGroup = useUpdateDocsGroup();
  const getDoc = useGetDocFromCache();
  const removeDocFromBoard = useRemoveDocFromBoardDocsList();
  const {
    addCustomerDoc, toggleCustomerDoc,
  } = useCustomerDocFromCache();

  const [updateDocCustomerMutation, { loading: isLoading }] = useSafeMutation(UpdateDocCustomerDocument);

  const updateDocCustomer = (variables: UpdateDocCustomerMutationVariables, isNotCompatibleWithBoardConfig = true) => {
    const doc = getDoc(variables.docId);
    return updateDocCustomerMutation({
      variables,
      update: () => {
        if (boardConfig?.docQuery.__typename === 'BoardQuery' && !variables.customerId && customerIsRequired) {
          // !variables.customerId: Customer was removed from doc.
          removeDocFromBoard(boardConfig?.id, variables.docId);
          return;
        }
        if (isNotCompatibleWithBoardConfig && doc && groups) {
          const docGroup = getDocGroup(doc.id);
          if (!docGroup) return;
          updateDocsGroup({
            groupData: docGroup,
            updatedDocs: docGroup.node.docs.edges
              .filter(edge => variables.docId !== edge.node.id)
              .map(edge => edge.node),
          });
        }
      },
    });
  };

  const linkDocCustomer = async (variables: LinkDocCustomerVariables) => {
    const {
      customer, created, doc, docId, doctypeId,
    } = variables;
    if (!created) {
      if (doc && doc?.customer?.id !== customer.id) {
        toggleCustomerDoc({
          doc,
          newCustomer: customer,
        });
      }
      await updateDocCustomer({
        customerId: customer.id,
        docId,
      });
      return;
    }
    if (doc) {
      addCustomerDoc({
        customer,
        doc,
        doctypeId,
      });
    }
  };

  return {
    updateDocCustomer,
    linkDocCustomer,
    isLoading,
  };
};
