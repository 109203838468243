import { People } from '@cycle-app/front/src/types/editor.types';
import { Avatar, SelectLine } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FC, useEffect } from 'react';

import { Container } from './MentionUserDropdown.styles';

interface Props {
  items: People;
  command: (p: { id: string }) => void;
  hide: () => void;
}

const MentionUserDropdown: FC<Props> = ({
  items, command, hide,
}) => {
  const {
    selected,
    listProps,
    itemProps,
    hoverDisabled,
  } = useListNav({
    optionsValues: items.map(item => item.id),
    autoFocus: true,
    onSelect,
    value: null,
  });

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      e.stopPropagation();
      hide();
    };
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [hide]);

  return (
    <Container {...listProps}>
      {items.length === 0 && (
        <SelectLine
          label="No team mate found"
          isDisabled
        />
      )}
      {items.map((item) => (
        <SelectLine
          key={item.id}
          label={`${item.firstName} ${item.lastName}${item.__typename === 'Me' ? ' (me)' : ''}`}
          isSelected={item.id === selected}
          hoverDisabled={hoverDisabled}
          startSlot={(
            <Avatar user={item} />
          )}
          {...itemProps(item.id)}
        />
      ))}
    </Container>
  );

  function onSelect(itemId: string | null) {
    if (itemId) {
      command({ id: itemId });
    }
  }
};

export default MentionUserDropdown;
