import { StatusFragment } from '@cycle-app/graphql-codegen';
import { TrashIcon, PenIcon, DownIcon, UpIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useState } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useDeleteStatus, useMoveStatusInProductList } from 'src/hooks/status';
import { useGetWorkflows } from 'src/reactives/settingsWorkflows.reactive';
import { Layer } from 'src/types/layers.types';

import { EditStatusForm } from './EditStatusForm';
import { LinkedDocTypesLabel } from './LinkedDocTypesLabel';
import { LinkedDocTypesPanel } from './LinkedDocTypesPanel';
import {
  Status, StatusName, StatusAction, StatusActions,
  ActionContainer, DocTypeAction, TextSecondary,
} from './SettingsWorkflows.styles';
import { UnlinkStatusDocType } from './UnlinkStatusDocType';

export type SettingsStatusProps = {
  status: StatusFragment;
  isDeletable: boolean;
  usedNames: string[];
  docTypeId?: string;
  isDefault?: boolean;
};

export const SettingsStatus = ({
  status, usedNames, docTypeId, isDefault, ...props
}: SettingsStatusProps) => {
  const {
    deleteStatus, loading: isDeleting,
  } = useDeleteStatus(status);
  const linkedDocTypeIds = nodeToArray(status.doctypes).map(d => d.id);
  const isDeletable = props.isDeletable && !linkedDocTypeIds.length;
  const [isEditing, setEditing] = useState(false);
  const { lock: isWorkflowLocked } = useGetWorkflows();
  const {
    move: moveUp, isMoveable: isMoveableUp, loading: isMovingUp,
  } = useMoveStatusInProductList(status, 'up', docTypeId);
  const {
    move: moveDown, isMoveable: isMoveableDown, loading: isMovingDown,
  } = useMoveStatusInProductList(status, 'down', docTypeId);

  return (
    <Status>
      {isEditing ? (
        <EditStatusForm
          status={status}
          usedNames={usedNames}
          onDone={() => setEditing(false)}
        />
      ) : (
        <>
          <StatusName>
            <span>{status.value}</span>
            {isDefault && <TextSecondary>Default</TextSecondary>}
          </StatusName>

          <ToggleDropdown
            placement="bottom-start"
            offsetY={6}
            layer={Layer.Dropdown}
            button={buttonProps => (
              <DocTypeAction {...buttonProps}>
                <LinkedDocTypesLabel
                  ids={linkedDocTypeIds}
                  placeholder="Link doc type…"
                  currentDocTypeId={docTypeId}
                />
              </DocTypeAction>
            )}
            content={(
              <LinkedDocTypesPanel
                status={status}
                docTypeIds={linkedDocTypeIds}
                currentDocTypeId={docTypeId}
              />
            )}
          />
        </>
      )}

      <StatusActions>
        <ActionContainer $isDisabled={!isMoveableUp || isWorkflowLocked}>
          <StatusAction
            tooltip={isMoveableUp ? 'Move up' : 'This status can’t be moved up'}
            tooltipPlacement="top"
            disabled={!isMoveableUp || isWorkflowLocked}
            onClick={moveUp}
            isLoading={isMovingUp}
          >
            <UpIcon size={16} />
          </StatusAction>
        </ActionContainer>

        <ActionContainer $isDisabled={!isMoveableDown || isWorkflowLocked}>
          <StatusAction
            tooltip={isMoveableDown ? 'Move down' : 'This status can’t be moved down'}
            tooltipPlacement="top"
            disabled={!isMoveableDown || isWorkflowLocked}
            onClick={moveDown}
            isLoading={isMovingDown}
          >
            <DownIcon size={16} />
          </StatusAction>
        </ActionContainer>

        <StatusAction
          tooltip="Edit"
          tooltipPlacement="top"
          onClick={() => setEditing(true)}
        >
          <PenIcon size={16} />
        </StatusAction>

        {docTypeId ? (
          <UnlinkStatusDocType
            docTypeId={docTypeId}
            status={status}
          />
        ) : (
          <ActionContainer $isDisabled={!isDeletable || isWorkflowLocked}>
            <StatusAction
              tooltip={isDeletable
                ? 'Delete'
                : 'This status can’t be deleted. Make sure to unlink its doc types first.'}
              tooltipPlacement="top"
              disabled={!isDeletable || isWorkflowLocked}
              onClick={deleteStatus}
              isLoading={isDeleting}
            >
              <TrashIcon size={14} />
            </StatusAction>
          </ActionContainer>
        )}
      </StatusActions>
    </Status>
  );
};
