import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const JiraIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M20.2541 3H11.5884C11.5884 5.16 13.3369 6.90857 15.4969 6.90857H17.0912V8.45145C17.0912 10.6115 18.8398 12.36 20.9998 12.36V3.74572C20.9998 3.33428 20.6655 3 20.2541 3Z" fill="#2684FF" />
    <path d="M15.9599 7.32007H7.29419C7.29419 9.48006 9.04275 11.2286 11.2027 11.2286H12.797V12.7715C12.797 14.9315 14.5456 16.6801 16.7056 16.6801V8.06579C16.7056 7.65434 16.3713 7.32007 15.9599 7.32007Z" fill="url(#paint0_linear_3167_38205)" />
    <path d="M11.6657 11.6401H3C3 13.8001 4.74857 15.5487 6.90857 15.5487H8.50282V17.0916C8.50282 19.2516 10.2514 21.0001 12.4114 21.0001V12.3859C12.4114 11.9744 12.0772 11.6401 11.6657 11.6401Z" fill="url(#paint1_linear_3167_38205)" />
    <defs>
      <linearGradient id="paint0_linear_3167_38205" x1="16.5257" y1="7.32948" x2="12.8386" y2="11.1319" gradientUnits="userSpaceOnUse">
        <stop offset="0.176" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_3167_38205" x1="12.4808" y1="11.6729" x2="8.2176" y2="15.8209" gradientUnits="userSpaceOnUse">
        <stop offset="0.176" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </Svg>
);
