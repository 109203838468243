import styled, { css } from 'styled-components';

import { DEFAULT_AVATARS } from '../../../../../front/src/constants/avatars.constants';
import { Avatar } from '../../Avatar/Avatar';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';

export type Size = 'S' | 'M';

export const Container = styled.div<{ $size?: Size; $isEditable?: boolean }>`
  ${p => p.$size === 'M' && css`
    --customer-logo-size: 64px;
    --customer-logo-font-size: 32px;
    --customer-company-logo-size: 24px;
    --customer-company-logo-icon-size: 12px;
    --customer-company-border-size: 4px;
    --customer-company-pos-x: -8px;
    --customer-company-pos-y: -8px;
    --mask-image: radial-gradient(
      circle at 56px 56px,
      transparent 16px,
      white 16px
    );
  `}

  ${p => p.$size === 'S' && css`
    --customer-logo-size: 20px;
    --customer-logo-font-size: 10px;
    --customer-company-logo-size: 16px;
    --customer-company-logo-icon-size: 10px;
    --customer-company-border-size: 2px;
    --customer-company-pos-x: -12px;
    --customer-company-pos-y: -4px;
    --mask-image: radial-gradient(
      circle at 22px 14px,
      transparent 10px,
      white 10px
    );
  `}

  ${p => p.$isEditable && css`cursor: pointer;`}
`;

export const ImgAvatar = styled.img`
  --size: var(--customer-logo-size);
  border-radius: 50%;
`;

export const StyledAvatar = styled(Avatar)`
  --border-size: 0px;
  --shadow-size: 0px;
  --padding: 0px;
  --shadow-size-hover: 0px;
  border-radius: 50%;
  background-color: #fff;
  > img,
  > div {
    color: ${p => p.theme.colors.text.secondary} !important;
    background-color: ${p => p.theme.colors.avatar.bgLight} !important;
  }
`;

export const LetterAvatar = styled(StyledAvatar)`
  --size: var(--customer-logo-size);
  --font-size: var(--customer-logo-font-size);
`;

export const DefaultAvatar = styled(StyledAvatar).attrs({
  src: DEFAULT_AVATARS[0],
})`
  --size: var(--customer-logo-size);
`;

export const ImageContainer = styled.div<{ $showCompany?: boolean }>`
  position: relative;
  height: var(--customer-logo-size);
  width: var(--customer-logo-size);
  ${p => p.$showCompany && css`
    ${ImgAvatar}, ${LetterAvatar}, ${StyledAvatar} {
      mask-image: var(--mask-image);
    }
  `}
`;

export const CompanyContainer = styled.div`
  width: calc(var(--customer-company-logo-size));
  height: calc(var(--customer-company-logo-size));
  bottom: calc(var(--customer-company-pos-y) + var(--customer-company-border-size));
  right: calc(var(--customer-company-pos-x) + var(--customer-company-border-size));
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
`;

export const StyledCompanyLogo = styled(CompanyLogo)`
  --company-logo-size: var(--customer-company-logo-size);
  --company-logo-icon-size: var(--customer-company-logo-icon-size);
`;
