import { Color } from '@cycle-app/graphql-codegen/generated';
import { boxShadowZ1, ShyScrollbar } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import DocPanelRealtime from 'src/app/Main/Board/DocPanel/DocPanelRealtime/DocPanelRealtime';

import { CollaborationCursorCSS, COLLABORATION_CURSOR_CARET_CLASS } from '../../Editor/Editors/Editor.styles';

export const Container = styled.div`
  ${boxShadowZ1}
  border-radius: 12px;
  bottom: 70px;
  background: ${p => p.theme.colors.onboarding.background.body};
  left: 40px;
  padding: 16px 0 32px;
  position: absolute;
  right: 40px;
  top: 70px;
  z-index: 1;
  flex-direction: column;
`;

export const Content = styled(ShyScrollbar)`
  flex: 1;
  padding-left: 28px;
  padding-right: 28px;
  height: 100%;
  overflow-x: hidden;
`;

export const EditorContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const StepItem = styled.div<{ $isActive?: boolean }>`
  --bg: ${p => p.theme.colors.border.greyLight};

  align-items: center;
  display: flex;
  gap: 20px;
  position: relative;
  transition: color .2s, background-color .2s;

  &:not(:last-child)::after {
    background-color: var(--bg);
    content: '';
    height: 40px;
    left: 15px;
    position: absolute;
    transform: translate(-50%, 0);
    top: calc(100% + 4px);
    width: 2px;
  }

  ${p => p.$isActive && css`
    --bg: ${p.theme.userColors.main};
  `}
`;

export const StepContent = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledDocPanelRealtime = styled(DocPanelRealtime)`
  padding-right: 0px;
  gap: 0px;
  flex-direction: row-reverse;
`;

export const StyledDocAttributes = styled(DocPanelDocAttributes)`
  margin-top: 10px;
  margin-bottom: 18px;
`;

export const LoaderContainer = styled.div`
  padding: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CursorContainer = styled.div`
  position: relative;
  z-index: 1;
  ${CollaborationCursorCSS}
`;

export const Cursor = styled.div<{ $color: Color }>`
  position: absolute;
  opacity: 0;

  .${COLLABORATION_CURSOR_CARET_CLASS} {
    border-color: ${p => p.theme.nuances[p.$color].main};
  }
`;

export const CursorLabel = styled.span<{ $color: Color }>`
  background-color: ${p => p.theme.nuances[p.$color].main};
`;

export const StyledCheckContainer = styled.div<{ $isActive?: boolean }>`
  --bg: transparent;
  --border: ${p => p.theme.colors.border.greyLight};
  --color: ${p => p.theme.colors.text.white};

  transition: color .2s, background-color .2s;
  align-items: center;
  border: 2px solid var(--border);
  background-color: var(--bg);
  border-radius: 50%;
  color: var(--color);
  display: flex;
  justify-content: center;
  height: 28px;
  width: 28px;

  ${p => p.$isActive && css`
    --border: ${p.theme.userColors.main};
    --bg: ${p.theme.userColors.main};
    --color: ${p.theme.colors.text.white};
  `}
`;

export const StyledCheckIcon = styled(CheckIcon)<{ $isActive?: boolean }>`
  height: 18px;
  width: 18px;
`;

export const SteperContainer = styled.div`
  display: grid;
  gap: 50px;
`;
