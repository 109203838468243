import { Skeleton } from '@cycle-app/ui';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 700px;
  padding: 4px 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Text = styled(Skeleton)`
  width: 400px;
  height: 14px;
  margin: 5px 0;
`;

const Placeholder = styled(Text)`
  width: 400px;
`;

const QuickActions = styled(Text)`
  width: 460px;
`;

export const EditorSkeleton = () => (
  <Container>
    <Placeholder />
    <QuickActions />
  </Container>
);
