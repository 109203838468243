import { Editor } from '@tiptap/core';
import Image from '@tiptap/extension-image';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { Plugin, PluginKey } from 'prosemirror-state';

import ImageView from 'src/components/Editor/NodeViews/Image/ImageView';
import { FileData, ImageSize } from 'src/types/editor.types';

interface Args {
  onPastedFile?: (editor: Editor, file: File) => Promise<void>;
}
const getImageExtension = ({ onPastedFile }: Args) => Image.extend({
  addOptions: () => ({
    sizes: Object.values(ImageSize),
  }),

  addAttributes() {
    return {
      ...this.parent?.(),
      size: {
        default: ImageSize.LARGE,
        rendered: false, // Not add `size` attribute into the dom
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handlePastedImage'),
        props: {
          handlePaste: (_, event) => {
            const clipboardFile: File | undefined = Array.from(event.clipboardData?.files ?? [])[0];
            if (!clipboardFile) {
              return false;
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onPastedFile?.(this.editor, clipboardFile);
            return true;
          },
        },
      }),
    ];
  },

  addNodeView: () => ReactNodeViewRenderer(ImageView),

  addCommands() {
    return {
      setImage: (options: FileData) => ({ commands }) => commands.insertContent([
        {
          type: this.name,
          attrs: {
            ...options,
            size: ImageSize.LARGE,
          },
        },
      ]),
    };
  },
});

export default getImageExtension;
