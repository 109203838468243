import { ChangeLinearIssueAssigneeDocument, ChangeLinearIssueAssigneeMutationVariables, IntegrationType } from '@cycle-app/graphql-codegen/generated';

import useSafeMutation from 'src/hooks/useSafeMutation';

import { useProductIntegrations } from '../../useProductIntegrations';

export const useChangeAssigneeLinearIssue = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Linear);
  const isActive = !!integration?.provider;
  const [changeLinearAssigneeMutation, { loading }] = useSafeMutation(ChangeLinearIssueAssigneeDocument);

  const changeLinearAssignee = (variables: Omit<ChangeLinearIssueAssigneeMutationVariables, 'integrationId'>) => (
    isActive && integration?.provider?.id && changeLinearAssigneeMutation({
      variables: {
        integrationId: integration.provider.id,
        ...variables,
      },
    }));

  return {
    changeLinearAssignee,
    isLoading: loading,
  };
};
