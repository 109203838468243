/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { Color as ColorApi } from '../../../../graphql-codegen/generated';
import { body600 } from '../../theme/typo';

// Define the size according to the <img /> height defined in Figma
export type Size = 16 | 18 | 20 | 24 | 40 | 64 | 128;

const mappingBorder: Record<Size, number> = {
  16: 1,
  18: 1,
  20: 1,
  24: 2,
  40: 2,
  64: 2,
  128: 2,
};

const mappingSizeContainer: Record<Size, number> = {
  16: 14,
  18: 16,
  20: 18,
  24: 20,
  40: 34,
  64: 58,
  128: 124,
};

const mappingBoxShadow: Record<Size, number> = {
  16: 2,
  18: 2,
  20: 2,
  24: 2,
  40: 2,
  64: 2,
  128: 3,
};

const mappingFontSize: Record<Size, number> = {
  16: 8,
  18: 9,
  20: 10,
  24: 10,
  40: 14,
  64: 18,
  128: 18,
};

export const Background = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(var(--size) - 2 * var(--border-size));
  width: calc(var(--size) - 2 * var(--border-size));
  margin: var(--border-size);
  background: var(--bg-color);
`;

export const Img = styled.img<{
  incognito?: boolean;
  oppositeColor: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: var(--border-size) solid var(--border-color);
  box-shadow: 0 0 0 var(--shadow-size) var(--color);
  transition: box-shadow 0.15s ease-in-out;

  width: var(--size);
  height: var(--size);
  object-fit: cover;

  ${body600}
  text-transform: uppercase;
  color: ${p => (p.oppositeColor ? p.theme.colors.avatar.bgOpposite : p.theme.colors.avatar.bg)};

  svg {
    width: calc(var(--size) - 4 * var(--border-size));
    height: calc(var(--size) - 4 * var(--border-size));
  }

  ${p => p.incognito && css`
    color: ${p.oppositeColor ? p.theme.colors.avatar.bgOpposite : p.theme.colors.avatar.bg};
  `};
`;

// For god's sake, why there's $ in these props?
// Because 👇
// https://styled-components.com/docs/api#transient-props
interface ContainerProps {
  $size: Size;
  $color?: ColorApi;
  $pointer?: boolean;
  $padding?: number;
  $pending?: boolean;
}

export const Container = styled.div<ContainerProps>`
  --color: ${p => (
    p.$pending
      ? p.theme.colors.avatar.pending
      : p.$color
        ? p.theme.nuances[p.$color].main
        : p.theme.userColors.main)};
  --bg-color: ${p => (
    p.$pending
      ? p.theme.colors.avatar.pendingLight
      : 'var(--color)')};
  --border-size: ${p => mappingBorder[p.$size]}px;
  --shadow-size: ${p => mappingBoxShadow[p.$size]}px;
  --padding: ${p => mappingBoxShadow[p.$size] + (p.$padding ?? 1)}px;
  --shadow-size-hover: ${p => mappingBoxShadow[p.$size] * 1.5}px;
  --font-size: ${p => mappingFontSize[p.$size]}px;
  --size: ${p => mappingSizeContainer[p.$size]}px;
  --border-color: transparent;

  box-sizing: content-box;
  position: relative;

  width: var(--size);
  height: var(--size);
  flex-shrink: 0;

  padding: var(--padding);
  ${p => ((p.onClick || p.$pointer) && 'cursor: pointer;')}


  color: var(--color);

  &:hover ${Img} {
    ${p => p.onClick && 'box-shadow: 0 0 0 var(--shadow-size-hover) var(--color)'};
  }

  ${Img} {
    font-size: var(--font-size);
  }
`;
