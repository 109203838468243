import { StatusFragment, StatusCategory } from '@cycle-app/graphql-codegen';
import { Emoji, Tooltip } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { ToggleDialog } from 'src/components/DialogModal/ToggleDialog';
import { useProduct } from 'src/hooks';
import { useRemoveStatusDocType } from 'src/hooks/status';
import { isInsight } from 'src/utils/docType.util';

import { UnlinkAction, FontMedium } from './SettingsWorkflows.styles';

type Props = {
  docTypeId: string;
  status: StatusFragment;
};

export const UnlinkStatusDocType = ({
  docTypeId, status,
}: Props) => {
  const { removeStatusDocType } = useRemoveStatusDocType(status, docTypeId);

  const { product } = useProduct();
  const docType = product?.doctypes.edges.find(e => e.node.id === docTypeId)?.node;
  if (!docType) return null;

  const linkedStatusCount = docType.status.edges.filter(e => e.node.category === status.category).length;
  const isParentOfInsight = !!docType.children?.edges.some(e => isInsight(e.node));
  const isLastStatus = isParentOfInsight && status.category === StatusCategory.Completed
    ? linkedStatusCount < 3
    : linkedStatusCount < 2;
  const isUnlinkable = isInsight(docType) || isLastStatus;

  const docTypeEmoji = <Emoji emoji={docType.emoji} size={14} inline />;

  return (
    <Tooltip
      content="This doc type can’t be unlinked"
      placement="top"
      {...isUnlinkable && { style: { cursor: 'not-allowed' } }}
    >
      <ToggleDialog
        title="Unlink"
        info={(
          <div>
            {'Are you sure you want to unlink '}
            {docTypeEmoji}
            <FontMedium>{` ${docType.name} ?`}</FontMedium>
            <br />
            Docs that had this status will change to the default status.
          </div>
            )}
        confirmLabel="Unlink"
        onConfirm={removeStatusDocType}
        button={buttonProps => (
          <UnlinkAction
            disabled={isUnlinkable}
            {...buttonProps}
          >
            <CloseIcon size={10} />
          </UnlinkAction>
        )}
      />
    </Tooltip>
  );
};
