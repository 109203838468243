import { typo, boxShadowZ2, boxShadowZ3 } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { HEIGHT_ELEMENT, WIDTH_ELEMENT } from 'src/utils/flow.util';

import DialogModal from '../DialogModal/DialogModal';

export const RemoveAction = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -6px;
`;

export const Disc = styled.div<{
  disabled: boolean;
}>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${p => (p.disabled ? p.theme.colors.background.disabled : p.theme.userColors.main)};
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
  }
`;

interface ContainerProps {
  asPlaceholder?: boolean;
  phantom?: boolean;
  forceFocus?: boolean;
  view?: 'doctype' | 'global' | 'integrations';
  highlighted?: boolean;
  isButton?: boolean;
}
export const Container = styled.div<ContainerProps>`
  z-index: 2;
  position: relative;
  cursor: default;
  background-color: ${p => p.theme.colors.background.primary};
  border: none;
  border-radius: 8px;
  padding: 0;
  ${boxShadowZ2};
  ${typo.body500};

  ${p => p.view === 'doctype' && p.highlighted && css`
    outline: 2px solid ${p.theme.userColors.main};
  `};

  &:hover {
    outline: 1px solid ${p => p.theme.colors.border.surprisingGrey};
    ${boxShadowZ3};
    ${RemoveAction} {
      display: block;
    }
    ${p => (p.view === 'doctype' || p.view === 'integrations') && css`
      outline: 2px solid ${p.highlighted ? p.theme.userColors.main : p.theme.userColors.br};
    `};
  }

  ${p => p.phantom && css`
    opacity: 0;
    pointer-events: none;
  `};

  ${p => p.asPlaceholder && css`
    color: ${p.theme.colors.text.secondary};
    background-color: transparent;
    box-shadow: none;
    border: 2px dashed ${p.theme.colors.background.disabled};
    &:hover {
      outline: 0;
      box-shadow: none;
    };
    svg {
      width: 12px;
    }
  `};

  ${p => p.isButton && css`
    cursor: pointer;
    &:hover {
      background-color: ${p.theme.colors.background.disabled};
    };
    ${p.forceFocus && css`
      background-color: ${p.theme.colors.background.disabled};
    `};
  `};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: ${WIDTH_ELEMENT}px;
  height: ${HEIGHT_ELEMENT}px;
`;

export const Text = styled.div`
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AddAction = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ParentArea = styled.div`
  z-index: 1;
  position: absolute;
  top: -20px;
  left: -320px;
  width: ${WIDTH_ELEMENT + 340}px;
  height: 86px;
`;

export const ChildrenArea = styled.div`
  z-index: 1;
  position: absolute;
  top: 143px;
  left: 280px;
  width: ${WIDTH_ELEMENT + 40}px;
  height: 920px;
`;

export const FeedbackChildrenArea = styled(ChildrenArea)`
  top: 10px;
  left: 120px;
  width: 450px;
`;

export const SpanDoctype = styled.span`
  border-radius: 4px;
  padding: 1px;
  background-color: ${p => p.theme.colors.input.bg};
  font-weight: 500;
`;

export const DeletePreviewBlock = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.background.tertiary};
  border-radius: 6px;
  display: flex;
  height: 162px;
  justify-content: center;
`;

interface DeletePreviewProps {
  $isReversed?: boolean;
}

export const DeleteModalStyled = styled(DialogModal)`
  width: 548px;
`;

export const DeletePreview = styled.div<DeletePreviewProps>`
  align-items: center;
  display: flex;
  flex-direction: ${p => (p.$isReversed ? 'column-reverse' : 'column')};
  justify-content: center;
`;

export const DeleteInfo = styled.p`
  margin-top: 16px;
`;

export const DeletePreviewContainer = styled(Container)`
  background-color: ${p => p.theme.colors.flowElement.preview.bg};

  &:hover {
    outline: none;
  }
`;

export const DeletePreviewContent = styled(Content)`
  height: 40px;
  padding-left: 24px;
  padding-right: 24px;
  width: auto;
`;

export const DeleteConnexion = styled.div`
  background-color: ${p => p.theme.colors.background.red};
  height: 35px;
  position: relative;
  width: 2px;
  z-index: 3;

  &::before,
  &::after {
    background-color: ${p => p.theme.colors.background.primary};
    border: 2px solid ${p => p.theme.colors.border.red};
    border-radius: 50%;
    content: ' ';
    display: block;
    left: 50%;
    position: absolute;
    height: 6px;
    width: 6px;
    z-index: 4;
  }

  &::before {
    top: 0;
    transform: translate(-50%, -50%);
  }

  &::after {
    bottom: 0;
    transform: translate(-50%, 50%);
  }
`;

export const DeleteConnexionIconContainer = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.background.red};
  color: ${p => p.theme.colors.text.white};
  border-radius: 50%;
  display: flex;
  height: 14px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 14px;

  svg {
    height: 8px;
    width: 8px;
  }
`;

export const IntegrationsContainer = styled(Container)`
  cursor: pointer;
`;

export const IntegrationsContent = styled(Content)`
  flex-direction: column;
  height: auto;
  min-height: 72px;
  margin-top: -12px;
  padding: 8px;
`;

export const IntegrationsList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const TooltipContent = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;
