import { LinearIssueFullFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { FC, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { LinearIssuesSearchList } from 'src/components/LinearIssuesSearchList/LinearIssuesSearchList';
import { Events, Methods } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import {
  Container,
  LoadingState,
} from 'src/editorExtensions/Github/GithubIssuesDropdown.styles';
import { useLinkLinearIssue } from 'src/hooks/api/mutations/integrations/useLinkLinearIssue';
import { useSearchLinearIssues } from 'src/hooks/api/queries/integrations/useSearchLinearIssues';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { IntegrationMentionRendererProps } from 'src/types/integrations.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';

const MIN_LENGTH_QUERY = 3;

export const LinearSearchIssueDropdown: FC<IntegrationMentionRendererProps> = ({
  query, range, extensionName,
}) => {
  const {
    search,
    isSearchLoading,
    issues,
    isInstalled,
    isIntegrationLoading,
  } = useSearchLinearIssues();
  const { link } = useLinkLinearIssue();
  const { doc } = useDocPanelProps();
  const { editor } = useEditorContext();

  const searchDebounced = useDebouncedCallback(search, INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => {
    if (query.length < MIN_LENGTH_QUERY || !isInstalled) return;
    searchDebounced(query);
  }, [searchDebounced, query, isInstalled]);

  return (
    <Container>
      {isIntegrationLoading
        ? <LoadingState />
        : (
          <LinearIssuesSearchList
            search={query}
            results={issues}
            isIntegrationActive={isInstalled}
            onLink={onLink}
            onCreated={insertInEditor}
            isLoading={isSearchLoading}
            docId={doc?.id}
          />
        )}
    </Container>
  );

  function onLink(issue: LinearIssueFullFragment) {
    link({
      variables: {
        docId: doc?.id || '',
        issueId: issue.id,
      },
    })?.then(() => {
      trackAnalytics(Events.IntegrationIssueMentioned, {
        type: IntegrationType.Linear,
        method: Methods.SlashCommand,
      });
    });
    insertInEditor(issue);
  }

  function insertInEditor(issue: LinearIssueFullFragment) {
    editor
      .chain()
      .focus()
      .deleteRange({
        from: range?.from ?? 0,
        to: range?.to ?? 0,
      })
      .insertContent({
        type: extensionName,
        attrs: {
          id: issue.id,
        },
      })
      .createParagraphNear()
      .run();
  }
};
