import { getFileType, FileUploadedData } from '@cycle-app/utilities';

import { FILE_SIZE_LIMIT, FILE_SIZE_LIMIT_RESTRICTED } from 'src/constants/upload.constant';

const MEGA_OCTET = 1000000;
const KILO_OCTET = 1000;

export const getFileSizeWithUnit = (size: number, isRound = false): string => {
  const divider = size > MEGA_OCTET ? MEGA_OCTET : KILO_OCTET;
  const unit = size > MEGA_OCTET ? 'MB' : 'KB';
  const total = size / divider;

  return isRound ? `${Math.floor(total)} ${unit}` : `${(total).toFixed(2)} ${unit}`;
};

export const isImageLink = (url: string): boolean => /\.(jpg|jpeg|png|webp|avif|gif|svg)/.test(url);

type FileErrorType = 'file-too-large' | 'file-empty';
type GetFileErrorReturn = { message:string; type: FileErrorType } | null;
export const getFileError = (file: File): GetFileErrorReturn => {
  const fileType = getFileType(file);
  const variantText = fileType === 'image' ? 'image' : 'file';
  if (file.size > FILE_SIZE_LIMIT) {
    return {
      message: `🙀 Wow, this ${variantText} is huge, sorry we can’t handle it (max ${getFileSizeWithUnit(FILE_SIZE_LIMIT, true)})`,
      type: 'file-too-large',
    };
  }
  if (file.size <= 0) {
    return {
      message: `🤭 Oops, looks like your ${variantText} is empty so we can't upload it`,
      type: 'file-empty',
    };
  }

  return null;
};

type GetUrlErrorReturn = { message:string; type: 'wrong-url' } | null;
export const getUrlError = (url: string): GetUrlErrorReturn => {
  if (url.length && !isImageLink(url)) {
    return {
      message: '🤭 Oops, we can’t handle this link, are you sure it’s an image?',
      type: 'wrong-url',
    };
  }

  return null;
};

export const downloadFile = async (fileData: Pick<FileUploadedData, 'mime' | 'src' | 'title'>) => {
  const link = document.createElement('a');
  const response = await fetch(fileData.src);
  const blobPart = await response.blob();
  const blob = new Blob([blobPart], { type: fileData.mime });
  link.href = window.URL.createObjectURL(blob);
  link.download = fileData.title || 'File downloaded from Cycle App';
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isUploadRestricted = (file: File, canUploadNotRestricted: boolean) => (
  !canUploadNotRestricted && file.size > FILE_SIZE_LIMIT_RESTRICTED
);
