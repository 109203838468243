import { RemoveStatusDoctypeDocument, StatusFragment, ProductBySlugDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import produce from 'immer';
import remove from 'lodash/remove';
import { useCallback } from 'react';

import { useProductBase } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useUpdateQuery } from 'src/utils/update-cache/update-query.util';

export const useRemoveStatusDocType = (status: StatusFragment, docTypeId: string) => {
  const product = useProductBase();

  const updateQuery = useUpdateQuery({
    query: ProductBySlugDocument,
    variables: {
      slug: product?.slug,
    },
  });

  const [mutate, result] = useSafeMutation(RemoveStatusDoctypeDocument);

  const removeStatusDocType = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    mutate({
      variables: {
        statusId: status.id,
        doctypeId: docTypeId,
      },
      optimisticResponse: {
        removeStatusDoctype: produce(status, draft => {
          remove(draft.doctypes.edges, e => e.node.id === docTypeId);
        }),
      },
      update: (_, { data }) => {
        // Unlink the status to the doc type
        updateQuery(draft => {
          const docTypes = nodeToArray(draft.product?.doctypes);
          const index = docTypes.findIndex(d => d.id === docTypeId);
          docTypes[index].status.edges =
            docTypes[index].status.edges.filter(e => e.node.id !== data?.removeStatusDoctype?.id);
        });
      },
    });
  }, [docTypeId, mutate, status, updateQuery]);

  return {
    removeStatusDocType,
    ...result,
  };
};
