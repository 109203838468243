import { make } from 'src/utils/reactives.util';

export enum Content {
  Comments = 'Comments',
  Activity = 'Activity',
  Insights = 'Insights',
}

interface DocPanelState {
  content: Content;
  isExpanded: boolean;
}

export const {
  hookState: useDocPanel,
  hookValue: useGetDocPanel,
  getValue: getDocPanel,
  setValue: setDocPanel,
} = make<DocPanelState>({
  defaultState: {
    content: Content.Comments,
    isExpanded: false,
  },
});
