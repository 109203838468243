import { IntegrationType, NotionMinimalDatabaseFragment, NotionMinimalPageFragment } from '@cycle-app/graphql-codegen';
import { FC, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Events, Methods } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { Container } from 'src/editorExtensions/Github/GithubIssuesDropdown.styles';
import { useLinkNotionPage } from 'src/hooks/api/mutations/integrations/useLinkNotionPage';
import { useSearchNotion } from 'src/hooks/api/queries/integrations/useSearchNotion';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { IntegrationMentionRendererProps } from 'src/types/integrations.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { NotionSearchList } from '../NotionSearchList/NotionSearchList';

const MIN_LENGTH_QUERY = 3;

export const NotionSearchDropdown: FC<IntegrationMentionRendererProps> = ({
  query, range, extensionName,
}) => {
  const {
    isLoading,
    isInstalled,
    searchPages,
    searchPagesResult,
  } = useSearchNotion();
  const { linkNotionPage } = useLinkNotionPage();
  const { doc } = useDocPanelProps();
  const { editor } = useEditorContext();

  const searchDebounced = useDebouncedCallback(searchPages, INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => {
    if (query.length < MIN_LENGTH_QUERY || !isInstalled) return;
    searchDebounced(query);
  }, [searchDebounced, query, isInstalled]);

  return (
    <Container>
      <NotionSearchList
        docId={doc?.id}
        isIntegrationActive={isInstalled}
        isLoading={isLoading}
        onLink={onLink}
        search={query}
        searchResult={searchPagesResult}
      />
    </Container>
  );

  async function onLink(page: NotionMinimalPageFragment | NotionMinimalDatabaseFragment) {
    insertInEditor(page);
    // We can only link pages as we cannot write content into a database.
    if (page.__typename === 'NotionPage' && doc) {
      const result = await linkNotionPage({
        docId: doc.id,
        pageId: page.id,
      });
      if (result?.data?.linkNotionPage) {
        trackAnalytics(Events.IntegrationIssueMentioned, {
          type: IntegrationType.Notion,
          method: Methods.SlashCommand,
        });
      }
    }
  }

  function insertInEditor(page: NotionMinimalPageFragment | NotionMinimalDatabaseFragment) {
    editor
      .chain()
      .focus()
      .deleteRange({
        from: range?.from ?? 0,
        to: range?.to ?? 0,
      })
      .insertContent({
        type: extensionName,
        attrs: {
          id: page.id,
          __typename: page.__typename,
          iconEmoji: page.iconEmoji,
          url: page.url,
          title: page.title,
        },
      })
      .createParagraphNear()
      .run();
  }
};
