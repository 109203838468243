import { ActionButton, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { Badge, Header } from '../CustomersList/Customers.styles';
import { CustomersListPeople } from '../CustomersList/CustomersListPeople';

export const StyledCustomersListPeople = styled(CustomersListPeople)<{ $hideSeparator?: boolean }>`
  border-top: 1px solid ${p => p.theme.colors.border.greyLight};
  margin-top: 32px;
  padding-top: 30px;

  ${p => p.$hideSeparator && css`
    border-top: none;
    padding-top: 0;
    margin-top: 16px;
  `}
`;

export const ProfileWrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

export const AddPeopleAction = styled(ActionButton)`
  height: 18px;
  width: 18px;
  opacity: 0;
  transition: opacity .1s;
`;

export const AddPeopleLabel = styled.span`
  ${typo.body500};
`;

export const AddPeopleContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  &:hover ${AddPeopleAction} {
    opacity: 1;
  }
`;

export const PeopleCounter = styled(Badge)`
  height: 18px;
  margin-left: 0px;
`;

export const PeopleList = styled.div<{ $hideSeparator?: boolean }>`
  border-top: 1px solid ${p => p.theme.colors.border.greyLight};
  margin-top: 32px;
  padding-top: 30px;

  ${p => p.$hideSeparator && css`
    border-top: none;
    padding-top: 0;
    margin-top: 16px;
  `}
`;

export const StyledHeader = styled(Header)`
  margin-top: 0px;
  margin-bottom: 0px;
`;
