import { CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import {
  SelectOption,
  ActionButton,
  CheckboxInput,
  CustomPropertyInputText,
  Warning,
  OnSelectOptionsChangeMetaData,
} from '@cycle-app/ui';
import { BackArrowIcon, StatusIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useGetDocFromCache } from 'src/hooks/api/cache/cacheDoc';
import { useAddNewAttributeToDoc } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useAttributes } from 'src/hooks/api/useAttributes';
import { useDirtyAssigneeDefinition, useDirtyParentDefinition } from 'src/hooks/api/useDirtyAssigneeDefinition';
import { useMe } from 'src/hooks/api/useMe';
import { useCompatibility } from 'src/hooks/useCompatibility';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { customAttributeTypeData, ATTRIBUTE_ICON_MAPPING, getAttributeName } from 'src/utils/attributes.util';
import { getIsAtLeastOneInsightFromDocs } from 'src/utils/doc.util';
import { isInsight } from 'src/utils/docType.util';
import { getUserOptions } from 'src/utils/users.util';

import {
  Container,
  CurrentProperty,
  CurrentPropertyName,
  CurrentPropertyValue,
  StyledSelectPanel,
  InputTextContainer,
  SelectDocStyled,
} from './EditProperty.styles';
import { EditStatus } from './EditStatus';

export type OnValueSelectedParams = {
  attributeDefinition: CustomAttributeDefinitionFragment;
  propertyValue?: string;
  isValueRemoved?: boolean;
  notCompatible?: boolean;
};

export interface OnAddNewAttributeToDocArgs {
  attributeDefinition: CustomAttributeDefinitionFragment;
  textValue: string;
}

interface Props {
  possibleAttributes: CustomAttributeDefinitionFragment[];
  goBack?: VoidFunction;
  onValueUpdated?: (p: OnValueSelectedParams) => void;
  onAddNewAttributeToDoc?: (args: OnAddNewAttributeToDocArgs) => Promise<void> | void;
  onAssigneeUpdated?: (userId: string | null, notCompatible: boolean) => void;
  onParentUpdated?: (parentDocId: string) => void;
  bulk?: boolean;
  docId?: string;
  hide?: VoidFunction;
  commonDoctypeParents?: Array<string>;
}

// @todo refacto to use common code with PropertyDropdownValue

const EditProperty: FC<Props> = ({
  possibleAttributes,
  goBack,
  onValueUpdated,
  onAddNewAttributeToDoc,
  onAssigneeUpdated,
  onParentUpdated,
  bulk,
  docId: docIdProps,
  hide,
  commonDoctypeParents,
}) => {
  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);
  const [propertyId, setPropertyId] = useState<string | null>(null);
  const [checked, setChecked] = useState(false);
  const { addNewAttributeToDoc } = useAddNewAttributeToDoc();

  const { selected } = useGetSelection();
  const getDoc = useGetDocFromCache();
  const attributes = useAttributes();
  const usersForAssignee = useBoardConfig(ctx => ctx.usersForAssignee);
  const assigneeIsRequired = useBoardConfig(ctx => ctx.assigneeIsRequired);
  const { me } = useMe();
  const assigneeAttribute = useDirtyAssigneeDefinition();
  const parentAttribute = useDirtyParentDefinition();
  const {
    getCompatibleOptions, isPropertyRequiredToBeVisible,
  } = useCompatibility();

  const currentProperty = useMemo(() => {
    if (propertyId === 'status') return undefined;

    const customAttribute = attributes.find(attribute => attribute.id === propertyId);
    if (customAttribute) return customAttribute;
    // TODO: Improve this later once we can get the AssigneeDefinition easily from the API

    if (propertyId === 'parent' && parentAttribute?.__typename) {
      return {
        ...parentAttribute,
        name: getAttributeName(parentAttribute),
      };
    }

    if (propertyId && assigneeAttribute?.__typename) {
      return {
        ...assigneeAttribute,
        name: getAttributeName(assigneeAttribute),
        values: usersForAssignee.map(user => ({
          value: user.id,
          label: user.firstName,
        })),
      };
    }
    return undefined;
  }, [assigneeAttribute, attributes, propertyId, usersForAssignee, parentAttribute]);

  const getDocs = useCallback(() => {
    return (bulk ? selected : [docIdProps])
      .map((docId) => (docId ? getDoc(docId) : undefined))
      .filter(isPresent);
  }, [bulk, docIdProps, getDoc, selected]);

  const propertiesOptions: SelectOption[] = useMemo(() => {
    const attrs: SelectOption[] = possibleAttributes.map(attribute => ({
      value: attribute?.id ?? '',
      label: attribute?.name ?? '',
      icon: attribute?.__typename ? ATTRIBUTE_ICON_MAPPING[attribute.__typename] : undefined,
    }));
    const isAnInsight = getIsAtLeastOneInsightFromDocs(getDocs());

    if (
      parentAttribute?.__typename &&
      onParentUpdated &&
      !isAnInsight
    ) {
      attrs.unshift({
        value: parentAttribute?.id,
        label: getAttributeName(parentAttribute),
        icon: ATTRIBUTE_ICON_MAPPING[parentAttribute.__typename],
      });
    }

    if (
      onAssigneeUpdated &&
      assigneeAttribute?.__typename &&
      !isAnInsight
    ) {
      attrs.push({
        value: assigneeAttribute?.id,
        label: getAttributeName(assigneeAttribute),
        icon: ATTRIBUTE_ICON_MAPPING[assigneeAttribute.__typename],
      });
    }

    if (
      bulk &&
      isStatusEnabled &&
      /**
       * We didn't show the status is at least one insight is in selection
       * We use `some` so the loop stops as soon as one item match the condition
       */
      !getDocs().some(doc => isInsight(doc?.doctype))
    ) {
      attrs.push(({
        value: 'status',
        label: 'Status',
        icon: <StatusIcon />,
      }));
    }

    return attrs;
  }, [parentAttribute, onParentUpdated, possibleAttributes, onAssigneeUpdated, assigneeAttribute, bulk, isStatusEnabled, getDocs]);

  const onCreateOption = useCallback(async (textValue: string) => {
    if (currentProperty?.__typename !== 'AttributeSingleSelectDefinition') return;
    const doc = docIdProps ? getDoc(docIdProps) : null;
    if (doc) {
      await addNewAttributeToDoc({
        doc,
        attributeDefinition: currentProperty,
        textValue,
      });
    }
    await onAddNewAttributeToDoc?.({
      attributeDefinition: currentProperty,
      textValue,
    });
  }, [addNewAttributeToDoc, onAddNewAttributeToDoc, currentProperty, docIdProps, getDoc]);

  useEffect(() => {
    setChecked(isInitialChecked());
  }, [currentProperty]);

  const onMultiSelectOptionsChange = useCallback((newOptions: SelectOption[], metaData: OnSelectOptionsChangeMetaData) => {
    if (currentProperty?.__typename !== 'AttributeMultiSelectDefinition') return;
    const isPropertyRequired = isPropertyRequiredToBeVisible(currentProperty);
    const filteredOptions = getCompatibleOptions(currentProperty);

    const addedOption = metaData?.addedOptions?.[0];
    const removedOption = metaData?.removedOptions?.[0];
    const option = addedOption || removedOption;
    if (!option) return;

    const isValueRemoved = !!removedOption;
    const notCompatible = isValueRemoved && newOptions.length === 0
      ? isPropertyRequired
      : !filteredOptions.map(a => a.node.id).includes(option.value);

    onValueUpdated?.({
      attributeDefinition: currentProperty,
      propertyValue: option.value,
      isValueRemoved,
      notCompatible,
    });
  }, [currentProperty, getCompatibleOptions, isPropertyRequiredToBeVisible, onValueUpdated]);

  return (
    <Container>
      {!currentProperty?.__typename && propertyId !== 'status' ? (
        <StyledSelectPanel
          title={(
            <>
              {goBack && (
                <ActionButton onClick={goBack}>
                  <BackArrowIcon />
                </ActionButton>
              )}
              <span>Select property</span>
            </>
          )}
          onOptionChange={(option) => {
            setPropertyId(option.value);
          }}
          options={propertiesOptions}
        />
      ) : (
        <CurrentProperty>
          <CurrentPropertyName>
            <ActionButton onClick={() => setPropertyId(null)}>
              <BackArrowIcon />
            </ActionButton>
            {propertyId === 'status' && 'Status'}
            {currentProperty?.__typename && (
              <>
                {ATTRIBUTE_ICON_MAPPING[currentProperty.__typename]}
                {currentProperty.name}
              </>
            )}
          </CurrentPropertyName>

          <CurrentPropertyValue>
            {renderPropertyInput()}
          </CurrentPropertyValue>
        </CurrentProperty>
      )}
    </Container>
  );

  function renderPropertyInput() {
    if (propertyId === 'status') {
      const docIds = bulk ? selected : [docIdProps];
      const docTypeIds = docIds
        .map(docId => (docId ? getDoc(docId)?.doctype.id : undefined))
        .filter(isPresent);
      return (
        <EditStatus
          onChange={hide}
          docTypeIds={docTypeIds}
        />
      );
    }

    if (!currentProperty?.__typename) {
      return null;
    }

    if (currentProperty.__typename === 'AssigneeDefinition') {
      const showWarnings = !docIdProps || !getDoc(docIdProps)?.isDraft;
      return (
        <StyledSelectPanel
          onOptionChange={({ value: userId }) => {
            const notCompatible = !usersForAssignee.find(u => u.id === userId)?._compatibleWithBoardConfig;
            onAssigneeUpdated?.(userId, notCompatible);
            hide?.();
          }}
          options={getUserOptions(usersForAssignee, me, showWarnings)}
          onClearValue={() => {
            onAssigneeUpdated?.(null, assigneeIsRequired);
            hide?.();
          }}
          warningOnNoneValue={assigneeIsRequired}
        />
      );
    }

    if (currentProperty.__typename === 'AttributeSingleSelectDefinition') {
      const filteredOptions = getCompatibleOptions(currentProperty);
      const isPropertyRequired = isPropertyRequiredToBeVisible(currentProperty);

      return (
        <StyledSelectPanel
          dataTestId="EditProperty-singleSelect"
          onOptionChange={({ value }) => {
            onValueUpdated?.({
              attributeDefinition: currentProperty,
              propertyValue: value,
              notCompatible: !filteredOptions.map(a => a.node.id).includes(value),
            });

            setPropertyId(null);
            hide?.();
          }}
          onClearValue={() => {
            onValueUpdated?.({
              attributeDefinition: currentProperty,
              isValueRemoved: true,
              notCompatible: isPropertyRequired,
            });

            setPropertyId(null);
            hide?.();
          }}
          options={nodeToArray(currentProperty.values).map((value) => ({
            label: value.value,
            value: value.id,
            end: !filteredOptions.map(a => a.node.id).includes(value.id) ? (
              <Warning tooltip="The doc will leave the view if you choose this value" />
            ) : undefined,
          }))}
          warningOnNoneValue={isPropertyRequired}
          onCreateOption={onCreateOption}
        />
      );
    }

    if (currentProperty.__typename === 'AttributeMultiSelectDefinition') {
      if (!docIdProps) return null;

      const filteredOptions = getCompatibleOptions(currentProperty);

      const doc = getDoc(docIdProps);
      const docAttribute = nodeToArray(doc?.attributes).find(a => a.definition.id === currentProperty.id);
      const selectedValues: string[] = docAttribute?.__typename === 'DocAttributeMultiSelect'
        ? docAttribute.selectValues?.map(v => v.id) ?? []
        : [];

      return (
        <StyledSelectPanel
          isMulti
          options={nodeToArray(currentProperty.values).map((value) => ({
            label: value.value,
            value: value.id,
            selected: selectedValues.includes(value.id),
            end: !filteredOptions.map(a => a.node.id).includes(value.id) ? (
              <Warning tooltip="The doc will leave the view if you choose this value" />
            ) : undefined,
          }))}
          onOptionsChange={onMultiSelectOptionsChange}
        />
      );
    }

    if (currentProperty.__typename === 'AttributeCheckboxDefinition') {
      return (
        <CheckboxInput
          id="edit-property-checkbox"
          value={currentProperty.name}
          hideLabel
          checked={checked}
          onChange={() => {
            const nextChecked = !checked;
            setChecked(nextChecked);
            onValueUpdated?.({
              attributeDefinition: currentProperty,
              propertyValue: nextChecked ? 'checked' : 'uncheck',
            });
          }}
        />
      );
    }

    if (currentProperty.__typename === 'ParentDefinition' && commonDoctypeParents) {
      return (
        <SelectDocStyled
          commonDoctypeParents={commonDoctypeParents}
          onSelect={(parentDocId) => {
            onParentUpdated?.(parentDocId);
          }}
        />
      );
    }

    if (currentProperty.__typename !== 'ParentDefinition') {
      const { input: inputType } = customAttributeTypeData[currentProperty.__typename];

      if (inputType === 'email' ||
        inputType === 'phone' ||
        inputType === 'number' ||
        inputType === 'date' ||
        inputType === 'url' ||
        inputType === 'text') {
        return (
          <InputTextContainer>
            <CustomPropertyInputText
              type={inputType}
              variant="dropdown"
              values={['']}
              onInputChange={(e) => {
                onValueUpdated?.({
                  // @ts-expect-error Typescript doesn't understand it can't get AssigneeDefinition here
                  attributeDefinition: currentProperty,
                  propertyValue: e.target.value,
                });
              }}
            />
          </InputTextContainer>
        );
      }
    }

    return null;
  }

  function isInitialChecked() {
    if (currentProperty?.__typename !== 'AttributeCheckboxDefinition') {
      return false;
    }

    const docs = getDocs();

    const checkedValues = docs.map(doc => {
      const docAttribute = nodeToArray(doc?.attributes).find(attribute => attribute.definition.id === currentProperty?.id);
      if (docAttribute?.__typename !== 'DocAttributeCheckbox') {
        return false;
      }
      return !!docAttribute?.checkboxValue?.value;
    });

    return !checkedValues.includes(false);
  }
};

export default EditProperty;
