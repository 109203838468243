import { useLocation } from 'src/hooks';
import { useGetNavigation } from 'src/reactives/navigation.reactive';

export const useIsBoardActive = () => {
  const location = useLocation();
  const { activeItem } = useGetNavigation();

  return ({
    boardId, isStarred = false,
  }: {
    boardId?: string;
    isStarred?: boolean;
  }) => {
    if (!boardId) return false;

    if (activeItem) {
      if (!activeItem.includes(boardId)) return false;
      return isStarred ? activeItem.includes('starred') : !activeItem.includes('starred');
    }

    if (!location.pathname.includes(boardId)) return false;
    return isStarred ? location.state?.fromStarredBoard : !location.state?.fromStarredBoard;
  };
};
