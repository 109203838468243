import { IndentIncreaseIcon } from '@cycle-app/ui/icons';
import { useState, FC } from 'react';

import { BILLING_LIMIT_BOARD_SECTIONS } from 'src/constants/billing.constants';
import { useProductCounters } from 'src/hooks';
import useBoardSectionsMutations from 'src/hooks/api/mutations/useBoardSectionsMutations';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useErrorToaster } from 'src/hooks/useErrorToaster';

import DialogModal from '../../DialogModal/DialogModal';
import { Bold, DeletableItemList, DeleteItemSkeleton } from './BillingDowngrade.styles';
import { BillingDowngradeDeletableItem } from './BillingDowngradeDeletableItem';
import { BillingDowngradeFeatureItem } from './BillingDowngradeFeatureItem';
import { BillingDowngradeModal } from './BillingDowngradeModal';

interface Props {
  onClose: VoidFunction;
}

export const BillingDowngradeModalSections: FC<Props> = ({ onClose }) => {
  const { customSectionsCount } = useProductCounters();
  const {
    customSections, loading,
  } = useBoardSections();
  const { add: addErrorToaster } = useErrorToaster();
  const {
    removeSection, loadingRemoveSection,
  } = useBoardSectionsMutations();
  const [sectionToDelete, setSectionToDelete] = useState<{ id: string; name: string } | null>(null);
  return (
    <>
      <BillingDowngradeModal
        info="Views inside the sections will not be deleted."
        onHide={onClose}
        title="Remove sections"
        top={(
          <BillingDowngradeFeatureItem
            count={customSectionsCount}
            icon={<IndentIncreaseIcon />}
            limit={BILLING_LIMIT_BOARD_SECTIONS}
            name="Sections"
          />
        )}
      >
        {loading && (
          <DeletableItemList>
            <li><DeleteItemSkeleton /></li>
            <li><DeleteItemSkeleton /></li>
            <li><DeleteItemSkeleton /></li>
          </DeletableItemList>
        )}
        {!!customSections.length && (
          <DeletableItemList>
            {customSections.map(section => (
              <BillingDowngradeDeletableItem
                key={section.id}
                onDelete={() => setSectionToDelete({
                  id: section.id,
                  name: section.name,
                })}
              >
                {section.name}
              </BillingDowngradeDeletableItem>
            ))}
          </DeletableItemList>
        )}
      </BillingDowngradeModal>
      {sectionToDelete && (
        <DialogModal
          confirmLabel="Delete"
          hide={() => setSectionToDelete(null)}
          info={(
            <>
              {'Are you sure you want to delete '}
              <Bold>{sectionToDelete.name}</Bold>
              {' ? Views will move to '}
              <Bold>Other views</Bold>
              {' in your sidebar.'}
            </>
          )}
          loading={loadingRemoveSection}
          onConfirm={async () => {
            const result = await removeSection(sectionToDelete.id);
            if (result.data?.removeProductSection) {
              setSectionToDelete(null);
            } else {
              addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' });
            }
          }}
          title="Delete section"
        />
      )}
    </>
  );
};
