import { CycleLogo } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';

import { BillingUpdateFeatures } from './BillingUpdateFeatures';
import {
  Container,
  Centered,
  Main,
  Title,
  Desc,
  BackButton,
  StyledScrollableContent,
  LogoContainer,
  Background,
} from './BillingUpdateLayout.style';

interface Props {
  badge?: ReactNode;
  main: ReactNode;
  title: string;
}

export const BillingUpdateLayout = ({
  main, badge, title,
}: Props) => {
  const { getUrl } = useNavigate();
  const backToBoardUrl = getUrl(PageId.Main);
  return (
    <StyledScrollableContent>
      <Container>
        <Centered>
          <BackButton size={10} to={backToBoardUrl}>
            <CaretIcon direction="left" />
            Back to my workspace
          </BackButton>
          <Main>
            <Background size={10} />
            <div>
              <LogoContainer>
                <CycleLogo size={64} />
                {badge}
              </LogoContainer>
              <Title>{title}</Title>
              <Desc>Cycle is free to use, but if you want to bring extra superpowers, upgrade to Cycle Pro.</Desc>
            </div>
            {main}
          </Main>
          <BillingUpdateFeatures />
        </Centered>
      </Container>
    </StyledScrollableContent>
  );
};
