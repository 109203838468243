import { HIGHLIGHT_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import { DocMentionLastUsed } from 'src/components/Editor/DocMentionLastUsed/DocMentionLastUsed';
import { GithubIssuesCreationModal } from 'src/components/GithubIssuesCreationModal/GithubIssuesCreationModal';
import { useEditorContext } from 'src/contexts/editorContext';
import { useDocTypeInsight } from 'src/hooks';
import { useLastUsedDocType } from 'src/hooks/api/queries/docTypes/useLastUsedDocType';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { useBubbleMenuProductTour } from 'src/hooks/productTour/useBubbleMenuProductTour';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import { Action as ActionType, ActionId } from 'src/services/editor/editorActions';

import { LinearIssueCreationModalTrigger } from '../../LinearIssueCreationModal/LinearIssueCreationModalTrigger';
import { DocMentionDropdown } from '../DocMentionDropdown/DocMentionDropdown';
import LinkDropdown from '../LinkDropdown/LinkDropdown';
import { LinkInput } from '../LinkInput';
import { ActionButton } from './ActionButton';

type ActionProps = {
  action: ActionType;
  onClick?: VoidFunction;
  active?: boolean;
};

export const Action: FC<ActionProps> = (props) => {
  const { action } = props;
  const { disabledActions } = useEditorContext();
  const { isActive: isProductTourActive } = useBubbleMenuProductTour();

  if (action.id === ActionId.Link && disabledActions?.includes(ActionId.MentionDoc)) {
    if (isProductTourActive) return null;
    return (
      <LinkInput
        button={buttonProps => (
          <ActionButton
            {...props}
            onClick={(e) => {
              buttonProps.onClick(e);
              props.onClick?.();
            }}
          />
        )}
      />
    );
  }

  if (action.id === ActionId.Link && !disabledActions?.includes(ActionId.MentionDoc)) {
    if (isProductTourActive) return null;
    return (
      <LinkDropdown>
        <ActionButton {...props} />
      </LinkDropdown>
    );
  }

  if (action.id === ActionId.TurnTextIntoInsight) return <TurnTextIntoInsight {...props} />;
  if (action.id === ActionId.TurnTextIntoDocMention) return isProductTourActive ? null : <TurnTextIntoDocMention {...props} />;
  if (action.id === ActionId.TurnTextIntoDocMentionLastUsed) return isProductTourActive ? null : <TurnTextIntoDocMentionLastUsedButton {...props} />;
  if (action.id === ActionId.TurnTextIntoGithubIssueMention) return isProductTourActive ? null : <GitHubButton {...props} />;
  if (action.id === ActionId.TurnTextIntoLinearMention) return isProductTourActive ? null : <LinearButton {...props} />;
  return <ActionButton {...props} />;
};

const TurnTextIntoInsight = (props: ActionProps) => {
  const {
    onClick, action,
  } = props;
  const { insight } = useDocTypeInsight();
  const { editor } = useEditorContext();
  const isActiveHighlight = editor.isActive(HIGHLIGHT_EXTENSION_NAME);
  const {
    isActive: isProductTourActive,
    setChooseInitiativeStep,
  } = useBubbleMenuProductTour();

  const actionButton = (
    <ActionButton
      {...props}
      action={{
        ...action,
        label: '',
        text: (
          <>
            {insight?.name}
            <Emoji emoji={insight?.emoji} />
          </>
        ),
      }}
      labelComplement={`Create ${insight?.name}`}
      onClick={isActiveHighlight ? undefined : onClick}
      disabledTooltip={isActiveHighlight ? 'Your selection is already linked to an insight' : undefined}
    />
  );

  if (isProductTourActive) {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div onClick={() => setChooseInitiativeStep()}>
        {actionButton}
      </div>
    );
  }

  return actionButton;
};

const TurnTextIntoDocMention = (props: ActionProps) => {
  const { editor } = useEditorContext();
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const { selectionValues } = getDocTitlesFromSelectedText(editor.state);
  const isTurnIntoMentionDisabled = !selectionValues.length;
  return (
    <DocMentionDropdown
      isDisabled={isTurnIntoMentionDisabled}
      button={buttonProps => (
        <ActionButton
          {...props}
          isDocMention
          onClick={(e) => {
            props.onClick?.();
            buttonProps.onClick(e);
          }}
        />
      )}
    />
  );
};

const TurnTextIntoDocMentionLastUsedButton = (props: ActionProps) => {
  const { action } = props;
  const docType = useLastUsedDocType();
  const { editor } = useEditorContext();
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const { isEnabled: isInsightTurnIntoEnabled } = useFeatureFlag(FeatureFlag.InsightDocLinkTurnInto);
  const { selectionValues } = getDocTitlesFromSelectedText(editor.state);
  const isTurnIntoMentionDisabled = !selectionValues.length;
  if (!docType || isInsightTurnIntoEnabled) return null;
  return (
    <DocMentionLastUsed isDisabled={isTurnIntoMentionDisabled} shortcut={action.shortcut}>
      <ActionButton
        {...props}
        customIcon={<Emoji emoji={docType?.emoji} />}
        customIconInTooltipLabel={<Emoji emoji={docType.emoji} />}
        labelComplement={docType.name}
        {...isTurnIntoMentionDisabled && {
          disabledTooltip: 'We are not able to create docs from your current selection.',
        }}
      />
    </DocMentionLastUsed>
  );
};

const GitHubButton = (props: ActionProps) => {
  const {
    list,
    getIntegration,
  } = useProductIntegrations();
  const { action } = props;
  const isGithubActive = useMemo(() => !!getIntegration(IntegrationType.Github)?.provider?.id, [list]);
  if (!isGithubActive) return null;
  return (
    <GithubIssuesCreationModal shortcut={action.shortcut}>
      <ActionButton {...props} />
    </GithubIssuesCreationModal>
  );
};

const LinearButton = (props: ActionProps) => {
  const {
    list,
    getIntegration,
  } = useProductIntegrations();
  const { action } = props;
  const isLinearActive = useMemo(() => !!getIntegration(IntegrationType.Linear)?.provider?.id, [list]);
  if (!isLinearActive) return null;
  return (
    <LinearIssueCreationModalTrigger shortcut={action.shortcut}>
      <ActionButton {...props} />
    </LinearIssueCreationModalTrigger>
  );
};
