import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { PageId } from '../../constants/routing.constant';
import { useNavigate } from '../../hooks';
import { useDocPanelProps } from '../../hooks/useDocPanelProps';
import DocHierarchyGroup, { DocHierarchyGroupProps } from '../DocHierarchy/DocHierarchyGroup/DocHierarchyGroup';

interface Props {
  customerId: string;
  doctype: DoctypeFragment;
  isInDocView?: boolean;
  docsSkeleton?: JSX.Element;
  onDocLinked: DocHierarchyGroupProps['onDocLinked'];
}

export const CustomerChildrenTabHierarchy: FC<Props> = ({
  customerId, docsSkeleton, doctype, isInDocView, onDocLinked,
}) => {
  const docPanelProps = useDocPanelProps();
  const { navigate } = useNavigate();
  return (
    <DocHierarchyGroup
      customerId={customerId}
      createChildrenEnabled={false}
      docAttributes={[]}
      doctypeChildren={doctype}
      filterByCustomer
      onDocRemove={removedDoc => {
        // When we open a customer profile on a doc and we delete current doc from his list,
        // it should go back to the board(like we do now on doc deletion).
        if (isInDocView && removedDoc.id === docPanelProps.doc?.id) {
          navigate(PageId.Board);
        }
      }}
      onDocLinked={onDocLinked}
      refefresDeleteQueries={!isInDocView}
      skeleton={docsSkeleton}
      showInput={false}
      isModal
    />
  );
};
