/* eslint-disable react/no-unused-prop-types */
import { BillingFragment, BillingFrequency, BillingPlan } from '@cycle-app/graphql-codegen';

import { getExpectedStandardFee } from 'src/utils/billing.util';
import { currencyFormat } from 'src/utils/currency.utils';

import { PricePeriod, PriceTag, Pricing, UpgradeButton } from './BillingPlan.styles';

export type UpgradeClickProps = BillingPlan.Free | BillingPlan.Standard | 'BUSINESS';

interface UpgradeProps {
  isFreePlan: boolean;
  isStandardPlan: boolean;
  onClick?: (plan: UpgradeClickProps) => void;
}

export const plans = [{
  id: BillingPlan.Free,
  name: 'Starter',
  pricing() {
    return (
      <Pricing>
        <PriceTag>{currencyFormat(0)}</PriceTag>
        <PricePeriod> forever</PricePeriod>
      </Pricing>
    );
  },
  upgrade({
    isFreePlan, isStandardPlan, onClick,
  }: UpgradeProps) {
    const changePlanLabel = isStandardPlan ? 'Downgrade' : 'Upgrade';
    const isDisabled = isFreePlan;
    return (
      <UpgradeButton
        onClick={() => onClick?.(BillingPlan.Free)}
        size="M"
        disabled={isDisabled}
        $isStroke={isStandardPlan}
      >
        {isFreePlan ? 'Current plan' : changePlanLabel}
      </UpgradeButton>
    );
  },
}, {
  id: BillingPlan.Standard,
  name: 'Pro',
  pricing(currentBilling: BillingFragment) {
    const {
      nbMakers, frequency,
    } = currentBilling;
    const isAnnual = frequency === BillingFrequency.Yearly;
    return (
      <Pricing>
        <PriceTag>{`${currencyFormat(getExpectedStandardFee(currentBilling, isAnnual))} / ${isAnnual ? 'year' : 'mo'}`}</PriceTag>
        <PricePeriod>{` for ${nbMakers} maker${nbMakers > 1 ? 's' : ''}`}</PricePeriod>
      </Pricing>
    );
  },
  upgrade({
    onClick,
    isStandardPlan,
  }: UpgradeProps) {
    return (
      <UpgradeButton
        onClick={() => onClick?.(BillingPlan.Standard)}
        size="M"
        disabled={isStandardPlan}
      >
        {isStandardPlan ? 'Current plan' : 'Upgrade'}
      </UpgradeButton>
    );
  },
},
{
  id: 'BUSINESS',
  name: 'Business+',
  pricing() {
    return <PriceTag>Custom</PriceTag>;
  },
  upgrade() {
    return (
      <UpgradeButton
        id="contact-sales"
        onClick={() => window.Intercom?.('show')}
        size="M"
      >
        Contact sales
      </UpgradeButton>
    );
  },
}];
