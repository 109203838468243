import { AddIcon } from '@cycle-app/ui/icons';
import range from 'lodash/range';

import { useBoardsStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';

import { HomeAddStarBoardDropDown } from './HomeAddStarBoardDropDown';
import { HomeAddStarPlaceholder } from './HomeAddStarPlaceholder';
import { HomeStarredBoards } from './HomeStarredBoards';
import {
  Body, Header, LoadingCard, LoadingTitle, LoadingDesc, Title, AddButton,
} from './HomeStarredBoardSection.styles';

export const HomeStarredBoardSection = () => {
  const {
    boards: starredBoards, isLoading,
  } = useBoardsStarred();
  return (
    <section>
      <Header>
        <Title>
          Starred views
        </Title>
        {starredBoards.length > 0 && (
          <HomeAddStarBoardDropDown>
            {props => (
              <AddButton
                size="S"
                variant="secondary"
                useUserColor={false}
                iconStart={<AddIcon />}
                active={props.visible}
                onClick={props.toggle}
              />
            )}
          </HomeAddStarBoardDropDown>
        )}
      </Header>
      <Body>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading
          ? range(3).map((i) => (
            <LoadingCard key={i}>
              <LoadingTitle />
              <LoadingDesc />
            </LoadingCard>
          ))
          : !starredBoards.length
            ? <HomeAddStarPlaceholder />
            : <HomeStarredBoards />}
      </Body>
    </section>
  );
};
