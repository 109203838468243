import { Button, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const SyncButton = styled(Button)`
  ${typo.body400}
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;
