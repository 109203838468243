import { NextArrowIcon } from '@cycle-app/ui/icons';
import { useEffect, useState, FC, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { MembersContainer, ScrollLeftButton, ScrollRightButton } from './HomeMembersSection.styles';

export const HomeMembersWithScroll: FC = ({ children }) => {
  const membersContainer = useRef<HTMLDivElement | null>(null);
  const [isScrollableRight, setIsScrollableRight] = useState(false);
  const [isScrollableLeft, setIsScrollableLeft] = useState(false);

  const checkScroll = () => {
    const el = membersContainer.current;
    if (!el) return false;
    const scrollableSpace = (el.scrollWidth ?? 0) - (el.offsetWidth ?? 0);
    setIsScrollableRight(el.scrollLeft < scrollableSpace);
    setIsScrollableLeft(el.scrollLeft > 0);
    return null;
  };

  const handleScroll = useThrottledCallback(checkScroll, 300);

  useEffect(() => {
    checkScroll();
  }, []);

  const handleScrollClick = (direction: 'left' | 'right') => {
    if (!membersContainer.current) return;
    const scrollableWidth = membersContainer.current.scrollWidth ?? 0;
    const containerWidth = (membersContainer.current.offsetWidth - 16) ?? 0;
    const scrollableSpace = scrollableWidth - containerWidth;

    if (scrollableSpace <= 0) return;

    const scrollLeft = membersContainer.current.scrollLeft ?? 0;
    const distanceToScroll = (containerWidth * 0.8);

    membersContainer.current.scroll({
      behavior: 'smooth',
      left: direction === 'left'
        ? scrollLeft - distanceToScroll
        : scrollLeft + distanceToScroll,
    });
  };

  return (
    <MembersContainer
      ref={membersContainer}
      onScroll={handleScroll}
    >
      {isScrollableRight && (
        <ScrollRightButton onClick={() => handleScrollClick('right')}>
          <NextArrowIcon />
        </ScrollRightButton>
      )}
      {isScrollableLeft && (
        <ScrollLeftButton onClick={() => handleScrollClick('left')}>
          <NextArrowIcon />
        </ScrollLeftButton>
      )}
      {children}
    </MembersContainer>
  );
};
