import { ColorPicker, ShyScrollbarCss, SelectPanel } from '@cycle-app/ui';
import styled from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

export const Container = styled.div``;

export const PropertyLine = styled.div`
  display: flex;
  align-items: center;
  height: 48px;

  gap: 4px;

  padding: 0 24px 0 0;

  &:hover {
    background: ${p => p.theme.colors.settings.row.bgHover}
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid ${p => p.theme.colors.border.secondary}
  }
`;

export const ActionsDropdownLayer = styled(DropdownLayer)`
  display: flex;
  align-items: center;
  padding: 13px 123px 10px 16px;
  cursor: pointer;

  &:hover {
    background: ${p => p.theme.colors.selectItem.bgHover}
  }
`;

export const ActionLabel = styled.span`
  margin-left: 8px;
  line-height: 20px;
`;

export const StyledColorPicker = styled(ColorPicker)`
  height: 11px;
  width: 11px;
`;

export const PropertyIcon = styled.span`
  display: flex;
  margin-left: 16px;
`;

export const PropertyName = styled.span`
  flex: 1;
  margin-left: 12px;
`;

export const AddNewLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 8px;
  padding: 6px 0;

  & svg {
    cursor: pointer;
  }
`;

export const StyledSelectPanel = styled(SelectPanel)`
  ${ShyScrollbarCss};
  max-height: 49vh;
`;

export const PropertyMenu = styled(DotsMenuLayer)`
  min-width: 136px;
`;
