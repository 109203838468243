import { Input, typo, truncate } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { FormGroup } from 'src/utils/form.util.styles';

import { FieldCustomer, FieldEditor } from '../Form';

export const Form = styled.form`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 450px;
  border-radius: 8px;
  gap: 16px;
`;

export const FormInput = styled(Input)`
  width: 100%;
`;

export const Footer = styled(FormGroup)`
  justify-content: flex-end;
`;

export const LeftIcon = styled(DownIcon)`
  transform: rotate(90deg);
`;

export const InputBox = styled.div`
  width: 100%;
  border-radius: 6px;
  padding: 8px;
  border: 1px solid ${p => p.theme.colors.border.greyLight};
  cursor: not-allowed;
`;

export const ParentDoc = styled.div`
  ${typo.body400}
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ParentTitle = styled.span`
  ${truncate}
`;

export const StyledFieldCustomer = styled(FieldCustomer)`
  width: 100%;
`;

export const StyledFieldDescription = styled(FieldEditor)`
  height: auto;
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const FormRow = styled.div`
  width: 100%;
`;
