import { Color, MateFragment, Role } from '@cycle-app/graphql-codegen';
import { FC, MouseEvent } from 'react';

import { IncognitoIcon } from '../../icons';
import { Container, Img, Size, Background } from './Avatar.styles';

export interface AvatarProps {
  className?: string;
  src?: string;
  size?: Size; // Scope the size to keep consistency design
  onClick?: (e: MouseEvent) => void;
  pointer?: boolean;
  user?: MateFragment;
  incognito?: boolean;
  userColor?: Color;
  oppositeColor?: boolean;
  padding?: number;
  pending?: boolean;
}

export const Avatar: FC<AvatarProps> = ({
  className,
  size = 24,
  onClick,
  pointer,
  user,
  src: srcProps,
  incognito,
  userColor,
  oppositeColor = false,
  padding,
  pending = !user?.jobTitle && user?.role !== Role.SuperAdmin,
}) => {
  const color = userColor || user?.color;

  return (
    <Container
      className={className}
      $size={size}
      $color={color}
      $pointer={pointer}
      onClick={onClick}
      $padding={padding}
      $pending={pending}
    >
      <Background />
      <Content
        letter={(user?.firstName ?? user?.email)?.trimStart()[0]}
        src={srcProps ?? user?.avatar?.url}
        incognito={incognito}
        oppositeColor={oppositeColor}
      />
    </Container>
  );
};

const Content = ({
  letter,
  src,
  incognito,
  oppositeColor = false,
  pending = false,
}: {
  letter: any;
  src?: string;
  incognito?: boolean;
  oppositeColor?: boolean;
  pending?: boolean;
}) => {
  if (!pending && incognito) {
    return (
      <Img
        as="div"
        incognito={incognito}
        oppositeColor={oppositeColor}
      >
        {incognito && <IncognitoIcon />}
      </Img>
    );
  }

  if (!pending && src && !incognito) {
    return (
      <Img
        oppositeColor={oppositeColor}
        src={src}
        alt="avatar"
      />
    );
  }

  if (!pending && !src && !!letter && !incognito) {
    return (
      <Img
        oppositeColor={oppositeColor}
        as="div"
      >
        {letter}
      </Img>
    );
  }

  return (
    <Img
      oppositeColor={oppositeColor}
      as="div"
    />
  );
};
