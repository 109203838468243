import { getDocIdFromUrl } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import { find } from 'linkifyjs';
import { Plugin, PluginKey } from 'prosemirror-state';

interface Params {
  editor: Editor;
  extensionName: string;
}

export const pastedDocPlugin = ({
  editor,
  extensionName,
}: Params) => new Plugin({
  key: new PluginKey('handlePastedDocMention'),
  props: {
    handlePaste: (view, __, slice) => {
      let textContentLines: string[] = [];

      slice.content.forEach(node => {
        textContentLines = [...textContentLines, node.textContent];
      });

      // content contains no text, keeping original paste
      if (textContentLines.length === 0) {
        return false;
      }

      const firstLineDocId = extratDocIdFromMaybeUrl(textContentLines[0]);

      // The first line of the pasted text is not a doc url, keeping the original paste
      if (!firstLineDocId) return false;

      if (view.state.selection.from !== view.state.selection.to) {
        editor.commands.setLink({
          href: textContentLines[0],
          target: '_blank',
        });
        // Returning true prevents the original paste
        return true;
      }

      textContentLines.forEach(textContent => {
        const link = find(textContent)
          .find(item => item.isLink && item.value === textContent);
        const docIdFromUrl = getDocIdFromUrl(link?.href ?? '');

        if (docIdFromUrl) {
          editor.chain()
            .focus()
            .insertContent([
              {
                type: extensionName,
                attrs: { id: docIdFromUrl },
              },
              {
                type: 'text',
                text: ' ',
              }])
            .run();
        }
      });

      return true;
    },
  },
});

function extratDocIdFromMaybeUrl(text: string) {
  const link = find(text)
    .find(item => item.isLink && item.value === text);

  return getDocIdFromUrl(link?.href ?? '');
}
