import { Avatar, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const StyledAvatar = styled(Avatar)`
  margin: -1px;
`;

type ContainerProps = {
  showAssigneeName: boolean;
  forceFocus: boolean;
  $isDisabled?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  ${p => p.$isDisabled && css`cursor: not-allowed;`}

  p {
    ${typo.body500}
  }

  ${p => p.showAssigneeName && css`
    border-radius: 4px;
    padding: 2px 4px;

    &:hover {
      background-color: ${p.$isDisabled ? 'transparent' : p.theme.colors.background.hoverSoft};
    }
    ${p.forceFocus && `background-color: ${p.theme.colors.background.hoverSoft};`}
  `}
`;

interface ActionButtonCursorProps {
  $isDisabled?: boolean;
  $showAssigneeName?: boolean;
}

export const ActionButtonCursor = styled.span<ActionButtonCursorProps>`
  ${p => p.$showAssigneeName && p.$isDisabled && css`cursor: not-allowed;`}
`;
