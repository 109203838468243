import { StatusCategory } from '@cycle-app/graphql-codegen';
import { ComponentProps, FC } from 'react';

import { GroupCanceledIcon, GroupCompletedIcon, GroupStartedIcon, GroupNotStartedIcon } from '../../icons';
import { Container } from './StatusIcon.style';

export type GroupStatusIconProps = ComponentProps<typeof Container> & {
  category: StatusCategory;
  withBackground?: boolean;
};

export const StatusIcon: FC<GroupStatusIconProps> = ({
  category, withBackground, ...props
}) => (
  <Container $category={category} $withBackground={withBackground} {...props}>
    {category === StatusCategory.Canceled && <GroupCanceledIcon />}
    {category === StatusCategory.Completed && <GroupCompletedIcon />}
    {category === StatusCategory.NotStarted && <GroupNotStartedIcon />}
    {category === StatusCategory.Started && <GroupStartedIcon />}
  </Container>
);
