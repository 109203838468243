import { SelectOption, ActionButton, Shortcuts } from '@cycle-app/ui';
import { AddIcon, EyeClosedIcon, PenIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';
import { Placement } from 'tippy.js';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { useGroupOptionsShortcutListener } from 'src/hooks/shortcuts/useGroupOptionsShortcutListener';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { ShortcutBoard } from 'src/types/shortcuts.types';

import { ToolbarDotsMenuLayer } from './BoardGroup.styles';

interface Props {
  groupId: string;
  dropdownPlacement?: Placement;
  onCreateDoc?: VoidFunction;
  onHideGroup?: VoidFunction;
  onRenameGroup?: VoidFunction;
  isGroupByProperty?: boolean;
}

const BoardGroupToolbar: FC<Props> = ({
  groupId,
  dropdownPlacement = 'bottom',
  onCreateDoc,
  onRenameGroup,
  onHideGroup,
  isGroupByProperty,
}) => {
  const [isDropdownVisible, {
    setValueCallback: setIsDropdownVisible,
    setFalseCallback: hideMenu,
  }] = useOptimizedBooleanState(false);

  useGroupOptionsShortcutListener({
    groupId,
    onCreateDocTriggered: hideMenu,
    enabled: !!onCreateDoc,
  });

  const groupOptions: SelectOption[] = [
    {
      value: 'create-doc',
      label: 'Create new',
      icon: <AddIcon />,
      ...onCreateDoc && {
        onSelect: onCreateDoc,
      },
      shortcut: shortcuts[ShortcutBoard.CreateDoc],
      disabled: !onCreateDoc,
    },
    ...onRenameGroup ? [{
      value: 'rename',
      label: 'Rename',
      icon: <PenIcon />,
      onSelect: onRenameGroup,
    }] : [],
    ...onHideGroup ? [{
      value: 'hide',
      label: 'Hide',
      icon: <EyeClosedIcon />,
      onSelect: onHideGroup,
    }] : [],
  ];

  if (onCreateDoc && !isGroupByProperty) {
    return (
      <ActionButton
        onClick={onCreateDoc}
        tooltipPlacement="top"
        tooltip={(
          <Shortcuts
            shortcuts={[
              {
                label: 'Create new',
                keys: shortcuts[ShortcutBoard.CreateDoc],
              },
            ]}
          />
        )}
      >
        <AddIcon />
      </ActionButton>
    );
  }

  return (
    <ToolbarDotsMenuLayer
      visible={isDropdownVisible}
      setVisible={setIsDropdownVisible}
      placement={dropdownPlacement}
      tooltip="More"
      options={groupOptions}
    />
  );
};

export default BoardGroupToolbar;
