import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { SelectPanel, SelectOption, ActionButtonProps } from '@cycle-app/ui';
import { useMemo, FC, ReactNode } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useCompanyCreate } from 'src/hooks/api/mutations/customers/useCompanyCreate';
import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { useCompanies } from 'src/hooks/api/queries/customers/useCompanies';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { getCompanyOption } from 'src/utils/companies.util';

import DropdownLayer from '../DropdownLayer/DropdownLayer';
import { FloatingPenButton } from '../FloatingPenButton/FloatingPenButton';
import { Caret, StyledActionButton, StyledFloatingPenButtonContainer } from './CustomerCompanyAction.styles';

interface Props {
  customer?: CustomerFragment;
  className?: string;
  onOptionChange?: (selectedOption: SelectOption) => void;
  onClearValue?: VoidFunction;
  size?: ActionButtonProps['size'];
  tooltip?: ReactNode;
  showCaret?: boolean;
  isReadOnly?: boolean;
  layer?: Layer;
  isCompact?: boolean;
  onCompanyClick?: (companyId: string) => void;
}

export const CustomerCompanyAction: FC<Props> = ({
  className,
  children,
  customer,
  size = 'L',
  onOptionChange,
  onClearValue,
  tooltip,
  showCaret,
  isReadOnly = false,
  layer = Layer.DropdownModalZ3,
  isCompact = true,
  onCompanyClick,
}) => {
  const {
    companies, searchCompanies,
  } = useCompanies();
  const {
    createCompany, isLoading,
  } = useCompanyCreate();
  const companiesOptions = useMemo(() => companies.map(getCompanyOption), [companies]);
  const { update } = useCustomerUpdate(customer);
  const searchCompaniesDebounced = useDebouncedCallback((search) => searchCompanies(search), 300);
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    setTrueCallback: showDropdown,
  }] = useOptimizedBooleanState(false);

  const hasCompany = !!customer?.company?.id;

  if (isReadOnly) {
    return renderContent();
  }

  return (
    <DropdownLayer
      layer={layer}
      visible={isDropdownVisible}
      hide={hideDropdown}
      placement="bottom-start"
      content={(
        companiesOptions && (
          <SelectPanel
            isLoading={isLoading}
            filterOptionsOnInputChange={false}
            options={companiesOptions}
            onSearchChange={searchCompaniesDebounced}
            onOptionChange={handleOnOptionChange}
            onClearValue={handleOnClearValue}
            onCreateOption={async (label) => {
              const result = await createCompany({ name: label });
              if (result?.data?.createCompany) {
                await handleOnOptionChange(getCompanyOption(result.data.createCompany));
              }
              hideDropdown();
            }}
          />
        )
      )}
    >
      {renderContent()}
    </DropdownLayer>
  );

  function renderContent() {
    return isCompact ? (
      <StyledActionButton
        tooltip={tooltip}
        className={className}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          showDropdown();
        }}
        size={size}
        tooltipPlacement="top"
        disabled={isReadOnly}
      >
        {children}
        {showCaret && <Caret direction={isDropdownVisible ? 'top' : 'bottom'} />}
      </StyledActionButton>
    ) : (
      <StyledFloatingPenButtonContainer>
        <StyledActionButton
          tooltip={!isReadOnly && !hasCompany ? 'Edit company' : tooltip}
          size={size}
          tooltipPlacement="top"
          onClick={e => {
            e.stopPropagation();
            if (hasCompany) {
              onCompanyClick?.(customer?.company?.id || '');
            } else if (!isReadOnly) {
              showDropdown();
            }
          }}
        >
          {children}
        </StyledActionButton>
        {!isReadOnly && hasCompany && (
          <FloatingPenButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              showDropdown();
            }}
            tooltip="Edit company"
            tooltipPlacement="top"
          />
        )}
      </StyledFloatingPenButtonContainer>
    );
  }

  async function handleOnOptionChange(selectedOption: SelectOption) {
    onOptionChange?.(selectedOption);
    if (customer) {
      await update({
        customerId: customer.id,
        name: customer.name ?? '',
        companyId: selectedOption.value,
      });
    }
    hideDropdown();
  }

  async function handleOnClearValue() {
    onClearValue?.();
    if (customer) {
      await update({
        customerId: customer.id,
        name: customer.name ?? '',
        companyId: null,
      });
    }
    hideDropdown();
  }
};
