import { StatusCategory, ViewType } from '@cycle-app/graphql-codegen';
import { useEffect, FC } from 'react';

import BoardContent from 'src/app/Main/Board/BoardContent/BoardContent';
import { BoardContentSkeleton } from 'src/app/Main/Board/BoardContent/BoardContentSkeleton';
import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal/CreateDocModal';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { InboxZero } from 'src/components/InboxZero';
import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useInitBoard } from 'src/hooks/boards/useInitBoard';
import { useCurrentInboxView } from 'src/hooks/inbox';
import { useInboxZeroProductTour } from 'src/hooks/productTour/useInboxZeroProductTour';
import { setLastInboxBoard } from 'src/reactives/lastView.reactive';

import { ViewContainer } from './Inbox.styles';

export const InboxView: FC = ({ children }) => {
  const hasError = useBoardConfig(ctx => ctx.hasError);
  if (hasError) return <ErrorPage />;
  return (
    <ViewContainer id={BULK_SELECTION_AREA_ID}>
      <ViewContent />
      <CreateDocModal />
      {children}
    </ViewContainer>
  );
};

const ViewContent = () => {
  const {
    board, boardSlug,
  } = useCurrentInboxView();
  const hasInboxZero = useHasInboxZero();
  const loading = useBoardConfig(ctx => ctx.loading);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const boardConfigId = boardConfig?.id;
  const { shouldForceEmptyInboxState } = useInboxZeroProductTour();

  useInitBoard({
    board,
    boardConfigId,
  });

  useEffect(() => {
    setLastInboxBoard({
      boardSlug,
      boardId: board?.id,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardSlug]);

  if (loading && !shouldForceEmptyInboxState) return <BoardContentSkeleton viewType={ViewType.List} />;

  if (hasInboxZero || shouldForceEmptyInboxState) return <InboxZero />;

  // Hide My Inbox for super admin users
  const isMyInbox = board?.isBuiltIn && board.name === 'My Inbox';
  if (isMyInbox && !boardConfigId) return null;

  return (
    <BoardContent
      viewType={ViewType.List}
      boardConfigId={boardConfigId}
    />
  );
};

const useHasInboxZero = (): boolean => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  if (boardConfig?.docQuery.__typename !== 'BoardQueryWithGroupBy') return false;
  const groups = boardConfig.docQuery.docGroups.edges;
  if (groups.length !== 1) return false;
  const group = groups[0].node;
  if (group.propertyValue?.__typename !== 'Status') return false;
  return group.propertyValue.category === StatusCategory.NotStarted && group.docs.edges.length === 0;
};
