import { ViewType } from '@cycle-app/graphql-codegen';
import { ViewCardSkeleton } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import memoize from 'fast-memoize';
import range from 'lodash/range';
import { useMemo, useRef, FC } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import DocPanel from 'src/app/Main/Board/DocPanel/DocPanel';
import BoardContentListeners from 'src/components/BoardContentListeners/BoardContentListeners';
import { DocItemHandle, DocItem } from 'src/components/DocItem';
import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { routing, PageId } from 'src/constants/routing.constant';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { DocProvider } from 'src/contexts/docContext';
import { useBoardRecentlyCreatedDocs, useFeedbackDocType } from 'src/hooks';
import { useBoardConfigDocsSubscription } from 'src/hooks/api/useBoardConfigDocsSubscription';
import { useBoardSubscription } from 'src/hooks/api/useBoardSubscription';
import { useDocUrl } from 'src/hooks/useUrl';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { defaultPagination } from 'src/utils/pagination.util';

import { HomeRecentAssignee } from '../HomeRecentAssignee/HomeRecentAssignee';
import {
  Container,
  Header,
  Title,
  Body,
  AddButton,
  DocsContainer,
  LoadingState,
  HeaderToolbar,
  StyledFormProperties,
  Caret,
  StyledSpinner,
} from './HomeRecentDocsSection.styles';

export const HomeRecentDocsSection: FC = () => {
  const {
    docs, isLoading, endCursor, groupId, hasNextPage, savedBoardConfig, refetch, fetchMore,
  } = useBoardRecentlyCreatedDocs();
  const builtInDisplay = useBoardConfig(ctx => ctx.builtInDisplay);
  const boardId = useBoardConfig(ctx => ctx.boardId);

  useBoardSubscription(boardId);
  useBoardConfigDocsSubscription(savedBoardConfig?.id);
  const { getDocPanelUrl } = useDocUrl();
  const isMobile = useIsMobile();
  const location = useLocation();

  const docIds = useMemo(() => ({ 1: docs?.map(d => d.id) ?? [] }), [docs]);
  const docItemRefs = useRef<DocItemHandle[][]>([[]]);

  const makeMemoizedSetRef = useMemo(() => memoize(
    (index: number) => (docItemHandle: DocItemHandle) => {
      if (docItemHandle !== null) {
        docItemRefs.current[0][index] = docItemHandle;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const feedback = useFeedbackDocType();

  return (
    <>
      <Route path={routing[PageId.Main]}>
        <Container>
          <Header>
            <HeaderToolbar>
              <Title>
                Recently created
              </Title>
              {boardId && savedBoardConfig && (
                <HomeRecentAssignee
                  boardConfigId={savedBoardConfig.id}
                  filterProperties={savedBoardConfig.filterProperties}
                  filterableProperties={savedBoardConfig.filterableProperties}
                  onComplete={() => refetch()}
                />
              )}
              {boardId && savedBoardConfig && (
                <StyledFormProperties
                  boardConfigId={savedBoardConfig.id}
                  viewType={savedBoardConfig.viewType}
                  properties={savedBoardConfig.properties}
                  boardID={boardId}
                  variant="light"
                  caret={(isOpened) => <Caret $isOn={isOpened} />}
                />
              )}
              {isLoading && <StyledSpinner />}
            </HeaderToolbar>
            <AddButton
              size="S"
              variant="secondary"
              useUserColor={false}
              onClick={() => setCreateDoc({
                modalVisible: true,
                doctypeId: feedback?.id,
              })}
              iconStart={<AddIcon />}
            />
          </Header>
          <Body id={BULK_SELECTION_AREA_ID}>
            <DocsContainer
              isLoading={isLoading}
              hasMoreData={!!hasNextPage}
              loadMore={() => {
                if (!hasNextPage) return;

                fetchMore({
                  variables: {
                    ...defaultPagination,
                    sizeGroups: 1,
                    cursor: endCursor,
                    groupId,
                  },
                }).catch(console.warn);
              }}
            >
              <BoardContentListeners
                activeId={null}
                viewType={ViewType.List}
                docIds={docIds}
                docItemRefs={docItemRefs}
              />
              {docs?.map((doc, index) => {
                return (
                  <DocProvider key={doc.id} value={doc}>
                    <DocItem
                      ref={makeMemoizedSetRef(index)}
                      docUrl={getDocPanelUrl(doc)}
                      groupId={groupId}
                      viewType={ViewType.List}
                      showAssignee={builtInDisplay?.assignee}
                      showComments={builtInDisplay?.comments && !isMobile}
                      showCreator={builtInDisplay?.creator && !isMobile}
                      showCreatedAt={builtInDisplay?.createdAt && !doc.isDraft && !isMobile}
                      showCover={builtInDisplay?.cover}
                      showDocParent={builtInDisplay?.parent && !isMobile}
                      showChildren={builtInDisplay?.children}
                      showCustomer={builtInDisplay?.customer && !!doc?.doctype.customer}
                      showDocId={builtInDisplay?.docId && !doc.isDraft}
                      showDocType={builtInDisplay?.docType}
                      showSource={builtInDisplay?.source && !doc.isDraft && !!doc.source}
                      showProperties={false}
                      isSelectable
                    />
                  </DocProvider>
                );
              })}
              {isLoading && (
                <LoadingState>
                  {range(3).map(i => <ViewCardSkeleton key={i} viewType={ViewType.List} />)}
                </LoadingState>
              )}
            </DocsContainer>
          </Body>
        </Container>
      </Route>
      {savedBoardConfig && (
        <Switch location={location}>
          <Route path={routing[PageId.HomeDoc]}>
            <DocPanel />
          </Route>
        </Switch>
      )}
    </>
  );
};
