import { SetOnboardUserDocument, SetupMyPasswordDocument, UserJourney } from '@cycle-app/graphql-codegen';
import { Input } from '@cycle-app/ui';
import { FieldErrors, useForm } from 'react-hook-form';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Footer, Form, FormGrid, NextButton } from '../OnboardingLayout/OnboardingLayout.styles';
import { StyledAside } from '../OnboardingStepAccount/OnboardingStepAccount.styles';

type FormData = {
  password: string;
  passwordConfirm: string;
};

export const PasswordForm = () => {
  const [setupPassword, { loading: isPasswordLoading }] = useSafeMutation(SetupMyPasswordDocument);
  const [onboardUser, { loading: isOnboardingLoading }] = useSafeMutation(SetOnboardUserDocument);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });
  const { password } = watch();

  const onError = (data: FieldErrors<FormData>) => {
    if (data.password?.type === 'required') {
      setError('password', { message: 'Please provide your password.' });
    }
    if (data.password?.type === 'minLength') {
      setError('password', { message: 'Please set a password longer than 7 characters.' });
    }
  };

  const onSubmit = async (data: FormData) => {
    if (data.passwordConfirm !== data.password) {
      setError('passwordConfirm', { message: 'Password and confirmation are different' });
      return;
    }
    const result = await setupPassword({ variables: { password: data.password } });
    if (result.data?.setupMyPassword?.id) {
      const onboardResult = await onboardUser({
        variables: {
          userJourney: UserJourney.ProductTour,
        },
      });
      if (onboardResult.data?.updateMe?.id) {
        setOnboarding({
          screen: LightOnboardingScreen.AccountPreferences,
        });
      }
    }
  };

  return (
    <OnboardingLayout
      title="Please choose a password"
      aside={<StyledAside />}
      main={(
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <FormGrid>
            <Input
              id="password"
              type="password"
              label="Password"
              autoComplete="off"
              placeholder="At least 8 characters."
              {...register('password', {
                required: true,
                minLength: 8,
              })}
              error={errors.password?.message}
              helperSize="S"
            />
            <Input
              id="password-confirm"
              type="password"
              label="Password (confirmation)"
              autoComplete="off"
              {...register('passwordConfirm', {
                required: true,
              })}
              error={errors.passwordConfirm?.message}
              helperSize="S"
            />
          </FormGrid>
          <Footer>
            <NextButton
              disabled={!password.length || !!Object.keys(errors).length}
              type="submit"
              isLoading={isPasswordLoading || isOnboardingLoading}
              size="M"
            >
              Next
            </NextButton>
          </Footer>
        </Form>
      )}
    />
  );
};
