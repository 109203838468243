import { DocFullFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon } from '@cycle-app/ui/icons';
import { Feature, isEnabled } from '@cycle-app/utilities';
import { ReactChild, VFC } from 'react';

import DocOptions from 'src/components/DocOptions/DocOptions';

import DocPanelInfoDropdown from '../DocPanelInfoDropdown/DocPanelInfoDropdown';
import DocPanelPropertiesDropdown from '../DocPanelPropertiesDropdown/DocPanelPropertiesDropdown';
import DocPanelRealtime from '../DocPanelRealtime/DocPanelRealtime';
import {
  RightSide,
  Container,
  LeftActions,
  StyledActionButton,
  Separator,
  BreadCrumb,
  StyledDocParentDropdown,
  DocTitle,
  RightActions,
} from './DocPanelHeader.styles';

const isRightPanelEnabled = isEnabled(Feature.DocViewRightPanel);

interface Props {
  className?: string;
  doc: DocFullFragment;
  onClose?: VoidFunction;
  onExpand?: VoidFunction;
  onDeleteDoc: VoidFunction;
  onOpenCoverInputFile: VoidFunction;
  prevNextButtons?: ReactChild;
  processButton?: ReactChild;
}

export const DocPanelHeader: VFC<Props> = ({
  className,
  doc,
  onClose,
  onExpand,
  onDeleteDoc,
  onOpenCoverInputFile,
  prevNextButtons,
  processButton,
}) => {
  const canHaveParent = (doc.doctype.parents?.edges ?? []).length > 0;

  return (
    <Container className={className}>
      {(onClose || onExpand) && (
        <LeftActions>
          {onClose && (
            <StyledActionButton
              size="L"
              onClick={onClose}
              tooltip="Close"
              tooltipPlacement="bottom"
            >
              <CloseIcon />
            </StyledActionButton>
          )}
          {onExpand && (
            <StyledActionButton
              size="L"
              onClick={onExpand}
              tooltip="Open full page"
              tooltipPlacement="bottom"
            >
              <ExpandIcon />
            </StyledActionButton>
          )}
          <Separator />
        </LeftActions>
      )}

      {prevNextButtons}

      <BreadCrumb>
        {canHaveParent && (
          <>
            <StyledDocParentDropdown
              doc={doc}
              size="M"
              showIcon={false}
              minimal
            />
            /
          </>
        )}
        <Tooltip
          content={doc.title}
          placement="bottom"
          withPortal
          displayFullTitle
        >
          <DocTitle>{doc.title}</DocTitle>
        </Tooltip>
      </BreadCrumb>

      <RightSide>
        <DocPanelRealtime />
        {processButton}
        <RightActions>
          {isRightPanelEnabled
            ? (
              <DocOptions
                doc={doc}
                context="doc-panel"
                onAddCoverClicked={onOpenCoverInputFile}
                onDeleteDoc={onDeleteDoc}
                buttonSize="L"
              />
            ) : (
              <>
                <DocPanelPropertiesDropdown />
                <DocPanelInfoDropdown doc={doc} />
                <DocOptions
                  doc={doc}
                  context="doc-panel"
                  onAddCoverClicked={onOpenCoverInputFile}
                  onDeleteDoc={onDeleteDoc}
                  buttonSize="L"
                />
              </>
            )}
        </RightActions>
      </RightSide>
    </Container>
  );
};

export default DocPanelHeader;
