import { useApolloClient } from '@apollo/client';
import {
  FetchDocLinkedDocsDocument,
  DocTargetFragment,
} from '@cycle-app/graphql-codegen/generated';
import { useCallback } from 'react';

type AddParams = {
  feedbackId: string;
  docTargetToAdd: DocTargetFragment;
};

export const useDocInsightsCache = () => {
  const { cache } = useApolloClient();

  const add = useCallback(({
    feedbackId,
    docTargetToAdd,
  }: AddParams) => {
    const feedbackDocTargets = cache.readQuery({
      query: FetchDocLinkedDocsDocument,
      variables: {
        id: feedbackId,
      },
    });

    if (feedbackDocTargets?.node?.__typename !== 'Doc') return;

    cache.modify({
      id: cache.identify(feedbackDocTargets.node),
      fields: {
        docTargetsCount: (currentDocTargetsCount) => currentDocTargetsCount + 1,
      },
    });

    cache.writeQuery({
      query: FetchDocLinkedDocsDocument,
      variables: {
        id: feedbackId,
      },
      data: {
        node: {
          ...feedbackDocTargets.node,
          docTargets: {
            ...feedbackDocTargets.node.docTargets,
            edges: [
              {
                __typename: 'DocTargetEdge',
                node: {
                  __typename: 'DocTarget',
                  id: docTargetToAdd.id,
                  content: docTargetToAdd.content,
                  blockId: docTargetToAdd.blockId,
                  doc: docTargetToAdd.doc,
                },
              },
              ...feedbackDocTargets.node.docTargets.edges,
            ],
          },
        },
      },
    });
  }, [cache]);

  return {
    add,
  };
};
