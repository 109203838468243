import { GroupNodeDocument, GroupFilteredFragment, GroupNodeQueryVariables } from '@cycle-app/graphql-codegen';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useIsDocInBoard } from 'src/hooks/useIsDocInBoard';
import { useMergeQuery } from 'src/hooks/useMergeQuery';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { defaultGroupPagination } from 'src/utils/pagination.util';

export const usePublishDocInCache = () => {
  const docQuery = useBoardConfig(ctx => ctx.boardConfig?.docQuery);
  const isDocInBoard = useIsDocInBoard();

  const mergeGroup = useMergeQuery<{ node: GroupFilteredFragment }, GroupNodeQueryVariables>({
    query: GroupNodeDocument,
  });

  return (doc: FullDocWithPublicId) => {
    if (!isDocInBoard(doc)) return;

    // TODO: implement the other cases
    if (docQuery?.__typename !== 'BoardQueryWithGroupBy') return;
    if (docQuery.groupbyConfig.property.__typename !== 'StatusDefinition') return;

    const groupId = docQuery.docGroups.edges.find(e => e.node.propertyValue?.id === doc?.status?.id)?.node.id;
    if (!groupId) return;

    mergeGroup(
      {
        groupId,
        ...defaultGroupPagination,
      },
      {
        node: {
          docs: {
            edges: [{
              __typename: 'DocEdge',
              node: {
                ...doc,
                isDraft: false,
                docSource: null,
              },
            }],
          },
        },
      },
      'start',
    );
  };
};
