import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { ActionButton, Avatar } from '@cycle-app/ui';
import { ActivityIcon, CalendarIcon } from '@cycle-app/ui/icons';
import { toTagDate } from '@cycle-app/utilities';
import { VFC } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { Container, Line, Value, Label } from './DocPanelInfoDropdown.styles';

interface Props {
  doc: DocBaseFragment;
}

const DocPanelInfoDropdown: VFC<Props> = ({ doc }) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  return (
    <DropdownLayer
      visible={isDropdownVisible}
      hide={hideDropdown}
      content={renderDropdown()}
    >
      <ActionButton
        className={isDropdownVisible ? 'force-focus' : ''}
        onClick={toggleDropdown}
        size="L"
      >
        <ActivityIcon />
      </ActionButton>
    </DropdownLayer>
  );

  function renderDropdown() {
    const createdAtFormatted = doc.createdAt ? toTagDate(doc.createdAt) : '';

    return (
      <Container>
        <Line>
          <Label>
            <CalendarIcon />
            Creation
          </Label>
          <Value>
            <span>{`${createdAtFormatted} by `}</span>
            <Avatar user={doc.creator} />
            <span>{`${doc.creator.firstName} ${doc.creator.lastName}`}</span>
          </Value>
        </Line>
      </Container>
    );
  }
};

export default DocPanelInfoDropdown;
