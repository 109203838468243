import { ElementPosition, StepDetails } from 'src/types/productTour.types';

const GAP = 16;

export const getElementPosition = (el: HTMLElement): ElementPosition => {
  const {
    y,
    x,
    height,
    width,
  } = el.getBoundingClientRect();

  return {
    height,
    left: x,
    top: y,
    width,
  };
};

const getFrameValues = (frameGap?: StepDetails['frameGap']) => {
  const gapNumber = typeof frameGap === 'number'
    ? frameGap
    : frameGap?.[0] ?? 0;
  const gapArray = typeof frameGap === 'object'
    ? frameGap
    : [gapNumber, gapNumber, gapNumber, gapNumber]; // [top, right, bottom, left]

  return {
    gapNumber,
    gapArray,
  };
};

type Position = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

export const getFramePosition = (
  elPos: ElementPosition,
  position: Position = 'bottom-left',
  frameGap?: StepDetails['frameGap'],
) => {
  const { gapArray } = getFrameValues(frameGap);

  switch (position) {
    case 'bottom-right': {
      const x = elPos.left + elPos.width + gapArray[1];
      const y = elPos.top + elPos.height + gapArray[2];
      return `${x}px ${y}px`;
    }
    case 'top-right': {
      const x = elPos.left + elPos.width + gapArray[1];
      const y = elPos.top - gapArray[0];
      return `${x}px ${y}px`;
    }
    case 'top-left': {
      const x = elPos.left - gapArray[3];
      const y = elPos.top - gapArray[0];
      return `${x}px ${y}px`;
    }
    case 'bottom-left':
    default: {
      const x = elPos.left - gapArray[3];
      const y = elPos.top + elPos.height + gapArray[2];
      return `${x}px ${y}px`;
    }
  }
};

export const getTooltipPositions = (
  el: HTMLDivElement | null,
  framePositions: ElementPosition,
  placement: StepDetails['tooltipPosition'],
  frameGap?: StepDetails['frameGap'],
) => {
  const {
    height,
    width,
  } = el?.getBoundingClientRect() || {};
  const { gapArray } = getFrameValues(frameGap);

  if (placement === 'top') {
    let left = el && width
      ? framePositions.left + (framePositions.width / 2) - (width / 2)
      : framePositions.left;
    let top = el && height
      ? framePositions.top - height - GAP - gapArray[0]
      : framePositions.top;

    if (left < GAP) { left = GAP; }
    if (top < GAP) { top = GAP; }

    return {
      left,
      top,
    };
  }

  if (placement === 'bottom-start') {
    let left = el && width
      ? framePositions.left - gapArray[3]
      : framePositions.left;
    let top = el && height
      ? framePositions.top + framePositions.height + GAP + gapArray[0]
      : framePositions.top + framePositions.height;

    if (left < GAP) { left = GAP; }
    if (top < GAP) { top = GAP; }

    return {
      left,
      top,
    };
  }

  return {
    left: 0,
    top: 0,
  };
};
