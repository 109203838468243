import { ActionButton, ShyScrollbar, typo, Skeleton, CircleSkeleton } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import ContentEditable from 'src/components/ContentEditable/ContentEditable';
import { QUICK_ACTIONS_CLASSNAME } from 'src/components/Editor';
import PortalModal from 'src/components/PortalModal/PortalModal';

import DocPanelCover from '../DocPanel/DocPanelCover/DocPanelCover';

const MAX_CONTENT_WIDTH = 700;
const LATERAL_PADDING = 16;

export const PortalModalStyled = styled(PortalModal)`
  padding: 0;
  border-radius: 11px;
`;

export const Container = styled.div`
  width: 940px;
  max-width: 80vw;
`;

export const Actions = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  align-items: center;
  gap: 3px;
  min-height: 32px;
`;

export const BigActionButton = styled(ActionButton) <{ incredibleExceptionSize?: boolean }>`
  width: 32px;
  height: 32px;
  ${p => p.incredibleExceptionSize && css`
    svg {
      width: 14px;
      height: 14px;
    }
  `};
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH}px;
  padding: 0 ${LATERAL_PADDING}px;
`;

export const DocTop = styled.div``;

export const DocContainer = styled.div`
  --lateral-padding: max(calc((100% - ${MAX_CONTENT_WIDTH - (LATERAL_PADDING * 2)}px) / 2), ${LATERAL_PADDING}px);

  .ProseMirror {
    height: 100%;
    padding: 0 var(--lateral-padding);
  }
  .${QUICK_ACTIONS_CLASSNAME} {
    padding: 0 var(--lateral-padding);
  }

  min-height: 360px;

  ${mobile} {
    padding: 0;
  }
`;

export const Toolbar = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 9px;
  min-height: 28px;
  ${p => p.$isDisabled && css`
    pointer-events: none;
  `}
`;

export const ActionButtonStyled = styled(ActionButton)`
  ${typo.body500};
  gap: 4px;
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ContentEditableStyled = styled(ContentEditable)`
  margin-top: 8px;
  margin-bottom: 10px;
  outline: none;
  width: 100%;

  ${typo.headerLarge};
`;

export const Attributes = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Buttons = styled.div`
  padding: 0 ${LATERAL_PADDING}px ${LATERAL_PADDING}px ${LATERAL_PADDING}px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const DocPanelCoverStyled = styled(DocPanelCover)`
  margin-top: 10px;
`;

export const ScrollableContent = styled(ShyScrollbar)`
  max-height: calc(100vh - 325px);
`;

export const DocTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SkeletonAvatar = styled(CircleSkeleton)`
  width: 22px;
  height: 22px;
`;

export const SkeletonUsername = styled(Skeleton)`
  width: 90px;
  height: 14px;
`;

export const SkeletonCustomer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
