import { PageId } from 'src/constants/routing.constant';
import { make } from 'src/utils/reactives.util';

interface HistoryResult {
  previousPage: PageId | null;
}

export const {
  hookValue: useGetHistory,
  getValue: getHistory,
  setValue: setHistory,
} = make<HistoryResult>({
  defaultState: {
    previousPage: null,
  },
});
