import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

interface PreviewProps {
  $isSelected?: boolean;
}

export const Preview = styled.div<PreviewProps>`
  --outline: transparent;
  --outline-width: 1px;
  background: var(--bg);
  border-radius: 8px;
  cursor: pointer;
  height: 100px;
  overflow: hidden;
  position: relative;
  transition: border .1s;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    box-shadow: inset 0px 0px 0px var(--outline-width) var(--outline);
    z-index: 1;
    border-radius: inherit;
  }

  ${p => p.$isSelected && css`
    --outline: ${p.theme.userColors.main};
  `}
`;

export const PreviewMain = styled.div`
  background: var(--main);
  border-radius: 10px 0px 0px 0px;
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.01);
  bottom: 0;
  display: flex;
  left: 20px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 20px;
`;

export const PreviewBar = styled.div`
  background: var(--bar);
  flex: 0 0 35px;
  height: 100%;
`;

export const StyledPreviewLight = styled(Preview)`
  --bg: ${p => p.theme.colors.lightOnboarding.themePreview.light.bg};
  --main: ${p => p.theme.colors.lightOnboarding.themePreview.light.main};
  --bar: ${p => p.theme.colors.lightOnboarding.themePreview.light.bar};
  --outline: ${p => p.theme.colors.lightOnboarding.themePreview.light.outline};

  ${p => p.$isSelected && css`
    --outline: ${p.theme.userColors.main};
    --outline-width: 3px;
  `}
`;

export const StyledPreviewDark = styled(Preview)`
  --bg: ${p => p.theme.colors.lightOnboarding.themePreview.dark.bg};
  --main: ${p => p.theme.colors.lightOnboarding.themePreview.dark.main};
  --bar: ${p => p.theme.colors.lightOnboarding.themePreview.dark.bar};

  ${p => p.$isSelected && css`
    --outline-width: 3px;
  `}
`;

export const Name = styled.div`
  margin-top: 8px;
`;
