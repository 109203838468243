import { FC, DOMAttributes, forwardRef } from 'react';

import { Container, Z } from './Card.styles';

export interface CardProps extends DOMAttributes<HTMLDivElement> {
  className?: string;
  disableHover?: boolean;
  z?: Z;
}

export const Card: FC<CardProps> = forwardRef<HTMLDivElement, CardProps>(({
  className,
  children,
  disableHover = false,
  z,
  ...otherProps
}, ref) => (
  <Container
    ref={ref}
    className={className}
    $disableHover={disableHover}
    $z={z}
    {...otherProps}
  >
    {children}
  </Container>
));
