import { HIGHLIGHT_DATA_ID, HIGHLIGHT_TAG_NAME } from '@cycle-app/editor-extensions';
import { typo, Button } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { ReadOnlyEditor } from 'src/components/Editor/Editors/ReadOnlyEditor';
import {
  EDITOR_MAX_CONTENT_WIDTH,
  EDITOR_LATERAL_PADDING,
} from 'src/constants/editor.constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: ${EDITOR_MAX_CONTENT_WIDTH}px;
  padding: 0 ${EDITOR_LATERAL_PADDING}px;
  margin: 16px auto 0 auto;
`;

export const FeedbackCard = styled.article`
  display: flex;
  flex-direction: column;
  border: 1px solid ${p => p.theme.colors.border.greyLight}88;
  width: 100%;
  padding: 12px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  &:hover, &:active, &:focus {
    border: 1px solid ${p => p.theme.colors.border.greyLight};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Body = styled.div`
  max-height: 480px;
`;

export const Caret = styled(CaretIcon).attrs({ size: 12 })`
  transform: rotate(-90deg);
  color: ${p => p.theme.colors.text.disabled};
`;

export const Title = styled.h4`
  ${typo.body400}
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${p => p.theme.colors.text.primary};
`;

export const CaretButton = styled(Button) <{ $isRotated: boolean }>`
  transition: transform 0.2s;
  transform: ${p => (p.$isRotated ? 'rotate(90deg)' : 'rotate(0)')};
`;

interface StyledReadOnlyEditorProps {
  $blockId?: string | null;
}

export const StyledReadOnlyEditor = styled(ReadOnlyEditor)<StyledReadOnlyEditorProps>`
  margin-top: 10px;

  ${p => p.$blockId && `
    ${HIGHLIGHT_TAG_NAME}:not([${HIGHLIGHT_DATA_ID}="${p.$blockId}"]) {
      pointer-events: none;
    }

    ${HIGHLIGHT_TAG_NAME}[${HIGHLIGHT_DATA_ID}="${p.$blockId}"] {
      background-color: ${p.theme.userColors.markHighlight};
    }
  `}
`;
