import { JobTitle as JobTitleType, MateFragment, Role } from '@cycle-app/graphql-codegen';
import { InfiniteScroll, SelectOption } from '@cycle-app/ui';
import { TrashIcon, PenIcon } from '@cycle-app/ui/icons';
import { FC, useCallback, useState } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useProduct } from 'src/hooks/api/useProduct';
import { useUsers } from 'src/hooks/api/useUsers';
import { setAddMember } from 'src/reactives/addMember.reactive';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { jobsLabel } from 'src/utils/jobs.util';

import { Header, SavingLabelStyled } from '../Settings.styles';
import { SettingsUserChangeProductRoleModal } from './SettingsUserChangeProductRoleModal';
import SettingsUserRemoveUserModal from './SettingsUserRemoveUserModal';
import {
  HeaderAction,
  JobTitle,
  Content,
  UsersListHeader,
  NameAndEmailContainer,
  ActionsColumnName,
  GridLine,
  UserAvatar,
  NameCell,
  FullName,
  Email,
  DotsMenuContainer,
  StyledShyScrollbar,
  ProductRole,
} from './SettingsUsers.styles';

const planMessage: Partial<SelectOption> = {
  tooltipContent: 'You need at least 1 maker',
  tooltipPlacement: 'bottom',
  tooltipOffset: [0, 20],
};

const SettingsUsers: FC = () => {
  const permission = useGetPermission();
  const billingPermission = useGetBillingPermission();
  const { product } = useProduct();
  const totalUsers = product && product.users.edges.length + product.notSignedUpUsers.edges.length;
  const {
    users,
    loading,
    pageInfo,
    fetchMore,
  } = useUsers();
  const [userToRemove, setUserToRemove] = useState<MateFragment | null>(null);
  const [userToEdit, setUserToEdit] = useState<MateFragment | null>(null);

  const loadMoreUsers = useCallback(async () => {
    if (pageInfo.endCursor) {
      await fetchMore(pageInfo.endCursor ?? '');
    }
  }, [fetchMore, pageInfo.endCursor]);

  return (
    <>
      <Header>
        <h1>
          Members
          {totalUsers !== null && (<small>{` (${totalUsers})`}</small>)}
        </h1>
        <SavingLabelStyled />
        <HeaderAction
          size="L"
          onClick={() => {
            setAddMember({ visible: true });
          }}
        >
          Add member
        </HeaderAction>
      </Header>

      <Content>
        <UsersListHeader>
          <div>Name</div>
          <div>Job</div>
          <div>Role</div>
          <ActionsColumnName>Actions</ActionsColumnName>
        </UsersListHeader>

        <StyledShyScrollbar>
          <InfiniteScroll
            isLoading={loading}
            hasMoreData={pageInfo.hasNextPage}
            loadMore={loadMoreUsers}
          >
            {users.map(user => {
              const isPending = !user.jobTitle; // TODO: get that data from the back
              const canDelete = user.productRole !== Role.Admin || permission.canDeleteAdmin;
              const cannotPlanDelete = user.productRole === Role.Maker && !billingPermission.canCurrentPlanDeleteMaker;
              const canUpdateRole =
                (user.productRole === Role.Admin && permission.canUpdateUserRoleAdmin) ||
                (user.productRole === Role.Maker && permission.canUpdateUserRoleMaker) ||
                (user.productRole === Role.Collaborator && permission.canUpdateUserRoleCollaborator);
              const options: SelectOption[] = [];
              if (canUpdateRole) {
                options.push({
                  value: 'edit',
                  label: 'Edit role',
                  icon: <PenIcon />,
                  onSelect: () => setUserToEdit(user),
                });
              }
              if (canDelete) {
                options.push({
                  value: 'remove',
                  label: 'Remove',
                  icon: <TrashIcon />,
                  onSelect: () => setUserToRemove(user),
                  ...cannotPlanDelete && {
                    disabled: true,
                    ...planMessage,
                  },
                });
              }
              return (
                <GridLine key={user.id}>
                  <NameCell>
                    <UserAvatar user={user} />
                    <NameAndEmailContainer>
                      <FullName>{`${user.firstName} ${user.lastName}`}</FullName>
                      <Email>{user.email}</Email>
                    </NameAndEmailContainer>
                  </NameCell>

                  <JobTitle aria-disabled={isPending}>
                    {isPending
                      ? 'Not signed up yet'
                      : jobsLabel[user.jobTitle as JobTitleType]}
                  </JobTitle>

                  <ProductRole>{user.productRole}</ProductRole>

                  {(canDelete || canUpdateRole) && (
                    <DotsMenuContainer>
                      <DotsMenuLayer
                        tooltip="Member actions"
                        placement="bottom-start"
                        options={options}
                      />
                    </DotsMenuContainer>
                  )}
                </GridLine>
              );
            })}
          </InfiniteScroll>
        </StyledShyScrollbar>
      </Content>
      {userToRemove && (
        <SettingsUserRemoveUserModal
          user={userToRemove}
          onClose={() => setUserToRemove(null)}
        />
      )}
      {userToEdit && (
        <SettingsUserChangeProductRoleModal
          user={userToEdit}
          onClose={() => setUserToEdit(null)}
        />
      )}
    </>
  );
};

export default SettingsUsers;
