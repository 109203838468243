import { Color, ThemeType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { getTheme } from '../../utils/theme.util';
import { ThemeEclipse } from '../ThemePreview/ThemeEclipse';
import { ThemeNightfall } from '../ThemePreview/ThemeNightfall';
import { ThemeSunrise } from '../ThemePreview/ThemeSunrise';
import { ThemePreviews, ThemePreview, StyledCheckedDot, ThemePreviewContainer } from './ThemePicker.styles';

interface Props {
  theme: ThemeType;
  onChange: (theme: ThemeType) => void;
  userColor: Color;
}

export const ThemePicker: FC<Props> = ({
  theme,
  userColor,
  onChange,
}) => {
  const selectedMainColor = getTheme(theme, userColor).nuances[userColor].main;
  const themeSvg = {
    [ThemeType.Eclipse]: <ThemeEclipse color={selectedMainColor} />,
    [ThemeType.Nightfall]: <ThemeNightfall color={selectedMainColor} />,
    [ThemeType.Sunrise]: <ThemeSunrise color={selectedMainColor} />,
  };

  return (
    <ThemePreviews>
      {[
        {
          value: ThemeType.Eclipse,
          label: 'Eclipse',
          img: '/images/preview-theme-eclipse.png',
          imgAlt: 'preview-theme-eclipse',
        },
        {
          value: ThemeType.Sunrise,
          label: 'Sunrise',
          img: '/images/preview-theme-sunrise.png',
          imgAlt: 'preview-theme-sunrise',
        },
        {
          value: ThemeType.Nightfall,
          label: 'Nightfall',
          img: '/images/preview-theme-nightfall.png',
          imgAlt: 'preview-theme-nightfall',
        },
      ].map(item => (
        <ThemePreviewContainer
          $color={userColor}
          $selected={theme === item.value}
          key={item.value}
        >
          <ThemePreview
            key={item.value}
            onClick={() => onChange(item.value)}
          >
            {themeSvg[item.value]}
            {theme === item.value && <StyledCheckedDot color={userColor} />}
          </ThemePreview>
        </ThemePreviewContainer>
      ))}
    </ThemePreviews>
  );
};
