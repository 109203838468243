import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const CalendarIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3334 11.8888C11.1925 11.8888 11.8889 11.1924 11.8889 10.3333C11.8889 9.47413 11.1925 8.77771 10.3334 8.77771C9.47425 8.77771 8.77783 9.47413 8.77783 10.3333C8.77783 11.1924 9.47425 11.8888 10.3334 11.8888Z" fill="#171618" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 1C2.04467 1 1 2.04467 1 3.33333V12.6667C1 13.9554 2.04467 15 3.33333 15H12.6667C13.9554 15 15 13.9554 15 12.6667V3.33333C15 2.04467 13.9554 1 12.6667 1H3.33333ZM2.55556 12.6667V4.11111H13.4444V12.6667C13.4444 13.0962 13.0962 13.4444 12.6667 13.4444H3.33333C2.90378 13.4444 2.55556 13.0962 2.55556 12.6667Z" fill="#171618" />
  </Svg>
);
