import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Button, Emoji } from '@cycle-app/ui';
import { ParentIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { CustomPropertiesFormFields } from 'src/components/CustomPropertiesFormFields';
import { useDocInsightCreate, useDocTypeInsight } from 'src/hooks';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { useMe } from 'src/hooks/api/useMe';
import { useInsightFormProductTour } from 'src/hooks/productTour/useInsightFormProductTour';
import { CustomPropertyFormData, GetCustomPropertyValueReturn } from 'src/types/property.types';
import { FormGroup } from 'src/utils/form.util.styles';
import { getCustomPropertyValues, getSubmittableCustomPropertyValues } from 'src/utils/properties.util';

import { FieldAssignee } from '../Form';
import { SkeletonInput } from '../Form/Form.styles';
import {
  Footer,
  Form,
  FormInput,
  InputBox,
  LeftIcon,
  ParentDoc,
  ParentTitle,
  StyledFieldCustomer,
  Label,
  FormRow,
} from './InsightCreateForm.styles';

const getCustomFieldFormValues = (
  data: CustomPropertyFormData[],
) => data.reduce<Record<string, GetCustomPropertyValueReturn>>(
  (previousValue, currentValue) => ({
    ...previousValue,
    [currentValue.id]: currentValue.inheritValue,
  }),
  {},
);

type InsightCreateFormProps = {
  onBack?: VoidFunction;
  onInsightCreated?: VoidFunction;
  onInsightCreating?: VoidFunction;
  parentDoc?: DocBaseFragment | null;
  feedbackDoc?: DocBaseFragment;
  customCommonPropertiesData: CustomPropertyFormData[];
  customSpecificPropertiesData: CustomPropertyFormData[];
  defaultContent?: string;
  defaultCustomerId?: string;
  defaultAssignee?: string;
  blockId?: string | null;
  hideParentDoc?: boolean;
  isCustomerReadonly?: boolean;
  isAssigneeReadonly?: boolean;
};

type InsightCreateFormData = {
  parentId: string;
  customerId: string;
  assignee: string;
  content: string;
  title: string;
  [key: string]: GetCustomPropertyValueReturn;
};

export const InsightCreateForm: FC<InsightCreateFormProps> = ({
  onBack,
  onInsightCreated,
  onInsightCreating,
  parentDoc,
  feedbackDoc,
  customCommonPropertiesData,
  customSpecificPropertiesData,
  defaultContent,
  defaultAssignee,
  defaultCustomerId,
  blockId,
  hideParentDoc,
  isCustomerReadonly,
  isAssigneeReadonly,
}) => {
  const { me } = useMe();
  const { insight } = useDocTypeInsight();
  const {
    create, isLoading,
  } = useDocInsightCreate();
  const {
    handleSubmit,
    register,
    control,
    setValue,
  } = useForm<InsightCreateFormData>({
    defaultValues: {
      parentId: (parentDoc?.title || '') + (parentDoc?.id || '') || '',
      ...getCustomFieldFormValues(customCommonPropertiesData),
      ...getCustomFieldFormValues(customSpecificPropertiesData),
      content: defaultContent || '',
      assignee: defaultAssignee || '',
      customerId: defaultCustomerId || '',
      title: '',
    },
  });
  const {
    searchCustomers, loading: isCustomersLoading,
  } = useCustomers({
    defaultSearch: me.email,
    onCompleted: async (data) => {
      const defaultCustomer = data.node.customers.edges.find(edge => edge.node.email === me.email)?.node;
      if (defaultCustomer && !defaultCustomerId) {
        setValue('customerId', defaultCustomer.id);
      }
      setValue('title', getDefaultTitleValue(feedbackDoc?.customer?.displayName || defaultCustomer?.displayName));
      // Reset the default results.
      await searchCustomers('');
    },
  });
  const {
    setInsightFormEl,
    setSeeCreatedInsightStep,
    isActive: isProductTourActive,
  } = useInsightFormProductTour();

  function getDefaultTitleValue(name?: string) {
    const customerName = name
      ? ` from ${name}`
      : '';
    const parentTitle = parentDoc?.title
      ? ` on ${parentDoc.title}`
      : '';

    return `Insight${customerName}${parentTitle}`;
  }

  const onSubmit = async (data: InsightCreateFormData) => {
    const customPropertyValues = getCustomPropertyValues(data, {
      // Make sure we keep only custom field key/values
      ignoredKeys: ['title', 'parentId', 'content'],
    });
    onInsightCreating?.();
    await create({
      assignee: data.assignee,
      contentJSON: '',
      customerId: data.customerId,
      docLinkContent: data.content,
      docLinkSourceId: feedbackDoc?.id,
      doctypeId: insight?.id || '',
      parentId: parentDoc?.id || '',
      properties: [
        ...getSubmittableCustomPropertyValues(customPropertyValues, customCommonPropertiesData),
        ...getSubmittableCustomPropertyValues(customPropertyValues, customSpecificPropertiesData),
      ],
      title: data.title,
      blockId: blockId || null,
      sourceId: feedbackDoc?.source?.id,
    });
    onInsightCreated?.();
    setSeeCreatedInsightStep();
  };

  return (
    <Form
      data-popper-overflow
      onSubmit={handleSubmit(onSubmit)}
      ref={el => setInsightFormEl(el)}
    >
      {onBack && (
        <FormGroup>
          <Button
            iconStart={<LeftIcon />}
            onClick={onBack}
            variant="secondary"
            useUserColor={false}
          />
        </FormGroup>
      )}
      {!hideParentDoc && (
        <FormGroup>
          <InputBox>
            {parentDoc ? (
              <ParentDoc>
                <ParentIcon />
                <Emoji emoji={parentDoc.doctype.emoji} size={14} />
                <ParentTitle>{parentDoc.title}</ParentTitle>
              </ParentDoc>
            ) : 'Loading'}
          </InputBox>
        </FormGroup>
      )}
      {!feedbackDoc && (
        <>
          {!isCustomerReadonly && (
            <FormRow>
              <Label>Customer</Label>
              <Controller
                control={control}
                name="customerId"
                render={({ field }) => (
                  <StyledFieldCustomer
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormRow>
          )}
          {!isAssigneeReadonly && (
            <FormRow>
              <Label>Assignee</Label>
              <Controller
                control={control}
                name="assignee"
                render={({ field }) => (
                  <FieldAssignee
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormRow>
          )}
        </>
      )}
      {isCustomersLoading ? (
        <FormRow>
          <Label>Title</Label>
          <SkeletonInput />
        </FormRow>
      ) : (
        <FormInput
          id="title"
          type="text"
          label="Title"
          autoFocus
          {...register('title')}
        />
      )}
      {!!customSpecificPropertiesData.length && !isProductTourActive && (
        <CustomPropertiesFormFields<InsightCreateFormData>
          data={customSpecificPropertiesData}
          control={control}
          autoFocus={false}
        />
      )}

      <Footer>
        <Button type="submit" size="M" isLoading={isLoading}>Confirm</Button>
      </Footer>
    </Form>
  );
};
