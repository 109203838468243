import { FC } from 'react';

import { useMe } from 'src/hooks/api/useMe';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import {
  Header, Left, Right, Title, Description, FakeInput, CmdK, SidebarToggle, Hand,
} from './HomeHeader.styles';

export const HomeHeader: FC = () => {
  const { me } = useMe();

  const isMobile = useIsMobile();

  return (
    <Header>
      {isMobile && (
        <SidebarToggle />
      )}
      <Left>
        <Title>
          {`Hey, ${me.firstName}`}
          {' '}
          <Hand>👋</Hand>
        </Title>
        <Description>
          Here’s what’s happening in your workspace
        </Description>
      </Left>
      <Right>
        <FakeInput onClick={() => {
          setCommandbar({
            visible: true,
            section: null,
          });
        }}
        >
          Search…
          <CmdK>CMD+K</CmdK>
        </FakeInput>
      </Right>
    </Header>
  );
};
