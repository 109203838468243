import { IMAGE_TYPE_REG_EXP, VIDEO_TYPE_REG_EXP } from '../constants/files.constants';
import { FileType } from '../types/files.types';

const isImageType = (type: string): boolean => IMAGE_TYPE_REG_EXP.test(type);
const isVideoType = (type: string): boolean => VIDEO_TYPE_REG_EXP.test(type);

export const getFileType = ({ type }: File): FileType => {
  if (isImageType(type)) return 'image';

  if (isVideoType(type)) return 'video';

  return 'file';
};

export const readFileSrc = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const imgSrc = e.target?.result;
    if (typeof imgSrc !== 'string') return reject(new Error("Couldn't read this file"));
    return resolve(imgSrc);
  };

  reader.readAsDataURL(file);
});
