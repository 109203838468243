import { useQuery } from '@apollo/client';
import { FetchProductIntegrationsDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';

import { Integration as IntegrationType } from 'src/types/integrations.types';

import { COLLAB_INTEGRATIONS, SOURCE_INTEGRATIONS } from '../../constants/integrations.constants';
import { getIntegrationsByInstallStatus, IntegrationsByType } from '../../utils/integrations.utils';
import { useProductBase } from './useProduct';

export const useProductIntegrations = () => {
  const product = useProductBase();
  const {
    data, loading, called, ...query
  } = useQuery(FetchProductIntegrationsDocument, {
    skip: !product?.id,
    variables: {
      productId: product?.id ?? '',
    },
  });

  const integrations = useMemo(() => {
    if (data?.node?.__typename !== 'Product') return [];

    return nodeToArray(data.node.integrations);
  }, [data]);

  const getIntegration = useCallback(
    (type: IntegrationType) => integrations.find(i => i.type === type),
    [integrations],
  );

  const refetch: VoidFunction = useCallback(async () => {
    const productId = product?.id;
    if (!productId) return;
    await query.refetch({ productId });
  }, [product?.id, query]);

  const integrationsByType = useMemo<IntegrationsByType>(() => Object.fromEntries(integrations.map((i) => [i.type, i])), [integrations]);
  const sourcesByStatus = useMemo(() => getIntegrationsByInstallStatus(SOURCE_INTEGRATIONS, integrationsByType), [integrationsByType]);
  const collabByStatus = useMemo(() => getIntegrationsByInstallStatus(COLLAB_INTEGRATIONS, integrationsByType), [integrationsByType]);

  return {
    isCalled: called,
    isLoading: loading,
    list: integrations,
    product,
    getIntegration,
    refetch,
    sourcesByStatus,
    collabByStatus,
    installedCount: sourcesByStatus.installed.length + collabByStatus.installed.length,
  };
};
