import { FC, ReactNode } from 'react';

import { Container, Top, Bottom, Title, SubTitle } from './SettingsViewHeader.styles';

type SettingsViewHeaderProps = {
  actionsSlot?: ReactNode;
  description?: string;
  title: string | ReactNode;
};

export const SettingsViewHeader: FC<SettingsViewHeaderProps> = ({
  actionsSlot,
  description,
  title,
}) => {
  return (
    <Container>
      <Top>
        {renderTitle()}
        {actionsSlot}
      </Top>
      {!!description && (
        <Bottom>
          <SubTitle>{description}</SubTitle>
        </Bottom>
      )}
    </Container>
  );

  function renderTitle() {
    if (typeof title === 'string') {
      return <Title>{title}</Title>;
    }

    return title;
  }
};
