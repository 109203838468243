import { InfoColorType, mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import * as typo from '../../../theme/typo';
import { getColorFromType } from '../../../utils/colors.utils';
import { Flex, FlexCol } from '../../../utils/styles.util';
import { Button } from '../../Buttons/Button/Button';

export const Header = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 24px;
  border-bottom: 1px solid ${p => p.theme.colors.billing.section.separator};
`;

export const HeaderButton = styled(Button)`
  font-weight: 400;

  ${mobile} {
    display: none;
  }
`;

export const ProgressBox = styled(FlexCol)`
  gap: 8px;
`;

export const ProgressInfo = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`;

export const ProgressLabel = styled.div`
  ${typo.body500}
`;

export const ProgressData = styled.p<{ $colorType?: InfoColorType }>`
  ${typo.body400}
  color: ${p => (p.$colorType && p.$colorType !== 'default' ? getColorFromType(p.$colorType) : p.theme.colors.text.secondary)};
`;

export const Label = styled.h3`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.text.primary};
  margin-bottom: 4px;
  font-weight: 500;
`;

export const SubLabel = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledButton = styled(Button)`
  display: none;
  width: 100%;

  ${mobile} {
    display: flex;
  }
`;
