import { ForgotPasswordDocument, LoginDocument } from '@cycle-app/graphql-codegen';
import { Button, Input, CycleLogo, LoginIllustration } from '@cycle-app/ui';
import { emailRegex } from '@cycle-app/utilities';
import { FC, useState, useEffect } from 'react';
import { UnpackNestedValue, useForm, UseFormRegisterReturn } from 'react-hook-form';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { setAuth } from 'src/reactives/auth.reactive';
import { defaultPagination } from 'src/utils/pagination.util';

import { Heading } from '../Auth.styles';
import {
  Content,
  Form,
  Actions,
  ResetPwdHeading,
  ResetPwdHint,
  ResetPwdActions,
  TextButtonStyled,
  Left,
  LeftContent,
  Right,
  LogoContainer,
} from './Login.styles';

interface LoginFormData {
  email: string;
  password: string;
}

interface ResetPasswordFormData {
  email: string;
}

type LoginContent = 'signIn' | 'resetPwd' | 'resetPwdSuccessfull';

const emailValidationOptions = {
  required: 'Email is required',
  pattern: {
    value: emailRegex,
    message: 'Email format is incorrect',
  },
};

const Login: FC = () => {
  const [login, {
    loading: loginLoading, error: loginError,
  }] = useSafeMutation(LoginDocument);
  const [forgotPassword, { loading: forgotPwdLoading }] = useSafeMutation(ForgotPasswordDocument);

  const {
    handleSubmit: handleLoginFormSubmit,
    register: loginRegister,
    formState: loginFormState,
    setError: setLoginError,
  } = useForm<LoginFormData>();
  const {
    handleSubmit: handleResetPwdSubmit,
    register: resetPwdRegister,
    formState: resetPwdFormState,
    setError: setResetPwdError,
    reset: resetForgotPwdForm,
  } = useForm<ResetPasswordFormData>();

  const [content, setContent] = useState<LoginContent>('signIn');

  useEffect(() => {
    if (content !== 'resetPwd') {
      resetForgotPwdForm();
    }
  }, [content]);

  return (
    <Content>
      <Left>
        <LogoContainer>
          <CycleLogo animation="hover" full size={32} />
        </LogoContainer>
        <LeftContent>
          {content === 'signIn' && renderSignInForm()}
          {content === 'resetPwd' && renderResetPwdForm()}
          {content === 'resetPwdSuccessfull' && renderResetPwdSent()}
        </LeftContent>
      </Left>
      <Right>
        <LoginIllustration plugged={loginFormState.isSubmitting || (!loginError && loginFormState.isSubmitSuccessful)} />
      </Right>
    </Content>
  );

  function renderSignInForm() {
    return (
      <>
        <Heading>Sign in</Heading>

        <Form onSubmit={handleLoginFormSubmit(onSubmitLogin)}>
          {renderEmailInput({
            error: loginFormState.errors.email?.message,
            registerProps: loginRegister('email', emailValidationOptions),
          })}

          <Input
            id="password"
            label="Password"
            type="password"
            placeholder="Type your password"
            error={loginFormState.errors.password?.message}
            autoComplete="current-password"
            {...loginRegister('password', { required: 'Password is required' })}
          />

          <Actions>
            <TextButtonStyled
              type="button"
              size={14}
              onClick={() => setContent('resetPwd')}
            >
              Forgot password ?
            </TextButtonStyled>

            <Button
              type="submit"
              size="L"
              isLoading={loginLoading || (!loginError && loginFormState.isSubmitSuccessful)}
            >
              Sign in
            </Button>
          </Actions>
        </Form>
      </>
    );
  }

  function renderResetPwdForm() {
    return (
      <>
        <ResetPwdHeading>Reset password</ResetPwdHeading>
        <ResetPwdHint>Please enter your email address and we will send you a password reset link.</ResetPwdHint>
        <Form onSubmit={handleResetPwdSubmit(onSubmitResetPwd)}>
          {renderEmailInput({
            error: resetPwdFormState.errors.email?.message,
            registerProps: resetPwdRegister('email', emailValidationOptions),
          })}

          <ResetPwdActions>
            <Button
              type="button"
              size="L"
              variant="secondary"
              onClick={() => setContent('signIn')}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              size="L"
              isLoading={forgotPwdLoading || resetPwdFormState.isSubmitSuccessful}
            >
              Reset password
            </Button>
          </ResetPwdActions>
        </Form>
      </>
    );
  }

  function renderResetPwdSent() {
    return (
      <>
        <ResetPwdHeading>Reset password</ResetPwdHeading>
        <ResetPwdHint>
          Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
        </ResetPwdHint>
        <ResetPwdActions>
          <Button
            type="submit"
            size="L"
            onClick={() => setContent('signIn')}
          >
            Sign in
          </Button>
        </ResetPwdActions>
      </>
    );
  }

  function renderEmailInput({
    error, registerProps,
  }: { error: string | undefined; registerProps: UseFormRegisterReturn }) {
    return (
      <Input
        id="email"
        label="Email"
        type="text"
        placeholder="Type your email"
        error={error}
        autoComplete="username"
        {...registerProps}
      />
    );
  }

  async function onSubmitLogin(formData: UnpackNestedValue<LoginFormData>) {
    const { data } = await login({
      variables: {
        email: formData.email,
        password: formData.password,
        ...defaultPagination,
      },
    });

    if (data?.login === null) {
      setLoginError('password', {
        message: 'The email or password you entered is incorrect. Please try again.',
      }, {
        shouldFocus: true,
      });
      return;
    }

    if (data?.login) {
      setAuth({
        token: data.login.token,
        userId: data.login.me.id,
        onboarded: data.login.me.onboarded,
      });
    }
  }

  async function onSubmitResetPwd(formData: UnpackNestedValue<ResetPasswordFormData>) {
    const { data } = await forgotPassword({
      variables: {
        email: formData.email,
      },
    });

    if (!data?.forgotPassword) {
      setResetPwdError('email', {
        message: 'That email is not linked to a user account',
      }, {
        shouldFocus: true,
      });
      return;
    }

    setContent('resetPwdSuccessfull');
  }
};

export default Login;
