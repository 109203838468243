import { EmbedIcon } from '@cycle-app/ui/icons';
import { useState, useMemo, FC } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { BILLING_LIMIT_INTEGRATIONS } from 'src/constants/billing.constants';
import { useProductCounters, useProductIntegrations } from 'src/hooks';
import { useRemoveIntegration } from 'src/hooks/api/mutations/integrations/useRemoveIntegration';
import { useErrorToaster } from 'src/hooks/useErrorToaster';
import { useToaster } from 'src/hooks/useToaster';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { integrationNameTitles, integrationsData } from 'src/utils/integrations.utils';

import DialogModal from '../../DialogModal/DialogModal';
import { DeletableItemList, DeleteItemSkeleton, IntegrationIconContainer } from './BillingDowngrade.styles';
import { BillingDowngradeDeletableItem } from './BillingDowngradeDeletableItem';
import { BillingDowngradeFeatureItem } from './BillingDowngradeFeatureItem';
import { BillingDowngradeModal } from './BillingDowngradeModal';

interface Props {
  onClose: VoidFunction;
}

export const BillingDowngradeModalIntegrations: FC<Props> = ({ onClose }) => {
  const { installedIntegrationsCount } = useProductCounters();
  const {
    sourcesByStatus, collabByStatus, isLoading,
  } = useProductIntegrations();
  const {
    remove, isLoading: isRemoveLoading,
  } = useRemoveIntegration();
  const { add: addToaster } = useToaster();
  const { add: addErrorToaster } = useErrorToaster();
  const integrations = useMemo(() => ([
    ...sourcesByStatus.installed,
    ...collabByStatus.installed,
  ]).map((inte, i) => ({
    ...inte,
    // uninstallable last
    order: inte.integration ? i : -1,
  })).sort((a, b) => b.order - a.order), [sourcesByStatus, collabByStatus]);
  const [integrationToRemove, setIntegrationToRemove] = useState<typeof integrations[number] | null>(null);
  return (
    <>
      <BillingDowngradeModal
        onHide={onClose}
        title="Remove integrations"
        top={(
          <BillingDowngradeFeatureItem
            count={installedIntegrationsCount}
            icon={<EmbedIcon />}
            limit={BILLING_LIMIT_INTEGRATIONS}
            name="Unlimited integrations"
          />
        )}
      >
        {isLoading && (
          <DeletableItemList>
            <li><DeleteItemSkeleton /></li>
            <li><DeleteItemSkeleton /></li>
            <li><DeleteItemSkeleton /></li>
          </DeletableItemList>
        )}
        {!!installedIntegrationsCount && (
          <DeletableItemList>
            {integrations.map(inte => (
              <BillingDowngradeDeletableItem
                key={inte.integrationType}
                {...inte.integration
                  ? { onDelete: () => setIntegrationToRemove(inte) }
                  : {
                    isDisabled: true,
                    tooltip: 'This integration cannot be removed',
                  }
              }
              >
                <IntegrationIconContainer>
                  {integrationsData[inte.integrationType].icon}
                </IntegrationIconContainer>
                {integrationsData[inte.integrationType].label}
              </BillingDowngradeDeletableItem>
            ))}
          </DeletableItemList>
        )}
      </BillingDowngradeModal>
      {integrationToRemove?.integration?.type && (
        <DialogModal
          title={
            integrationsData[integrationToRemove.integrationType].remove?.title ??
            `Uninstall ${integrationNameTitles[integrationToRemove.integration.type] ?? integrationToRemove.integrationType}`
          }
          info={integrationToRemove.integration?.type && (
            <p>
              {integrationsData[integrationToRemove.integrationType].remove?.text ??
                `Are you sure you want to uninstall ${integrationNameTitles[integrationToRemove.integration.type]} integration?`}
            </p>
          )}
          hide={() => setIntegrationToRemove(null)}
          onConfirm={async () => {
            if (integrationToRemove.integration?.id) {
              await onRemoveIntegration(integrationToRemove.integration.id);
            }
          }}
          loading={isRemoveLoading}
        />
      )}
    </>
  );

  async function onRemoveIntegration(integrationId: string) {
    const result = await remove(integrationId);
    if (result.data?.removeIntegration) {
      setIntegrationToRemove(null);
      trackAnalytics(Events.IntegrationRemoved, {
        type: integrationToRemove?.integrationType,
      });
      addToaster({
        title: 'Successfully uninstalled',
        message: 'Your integration has been successfully uninstalled',
      });
    } else {
      addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' });
    }
  }
};
