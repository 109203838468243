import {
  AddCommentToDocDocument,
  AddCommentToDocMutationVariables,
  ResolveThreadDocument,
  ResolveThreadMutationVariables,
  DeleteDocCommentDocument,
  DeleteDocCommentMutationVariables,
  DocCommentsFragment,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events, Sources } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useMe } from 'src/hooks/api/useMe';
import { usePageId } from 'src/hooks/usePageId';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useDraftComment } from 'src/reactives/draftComments.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';

interface Params {
  docId: string;
  comments: DocCommentsFragment['comments']['edges'];
}
export default function useCommentsMutations({
  docId,
  comments,
}: Params) {
  const { me } = useMe();
  const pageId = usePageId();

  const isThread = comments.length > 0;

  const { deleteDraftComment } = useDraftComment(docId);

  const [addCommentMutation, { loading: addCommentLoading }] = useSafeMutation(AddCommentToDocDocument, {
    onCompleted: () => {
      deleteDraftComment();
      trackAnalytics(isThread ? Events.ThreadStarted : Events.CommentSent, {
        source: pageId === PageId.Doc ? Sources.DocPanel : Sources.Board,
      });
    },
  });
  const [resolveThreadMutation, { loading: resolveThreadLoading }] = useSafeMutation(ResolveThreadDocument, {
    onCompleted: () => trackAnalytics(Events.ThreadResolved, {
      source: pageId === PageId.Doc ? Sources.DocPanel : Sources.Board,
    }),
  });
  const [deleteCommentMutation, { loading: deleteCommentLoading }] = useSafeMutation(DeleteDocCommentDocument);

  const loading =
  addCommentLoading ||
  resolveThreadLoading ||
  deleteCommentLoading;

  const addComment = useCallback((variables: AddCommentToDocMutationVariables) => addCommentMutation({
    variables,
    optimisticResponse: {
      addComment: {
        __typename: 'Comment',
        id: 'temp-id',
        content: variables.content,
        creator: me,
        updatedAt: new Date().toISOString(),
        doc: {
          __typename: 'Doc',
          id: docId,
          commentsCount: comments.length + 1,
          comments: {
            __typename: 'CommentsConnection',
            edges: [
              {
                __typename: 'CommentEdge',
                node: {
                  __typename: 'Comment',
                  id: 'temp-id',
                  content: variables.content,
                  creator: me,
                  updatedAt: new Date().toISOString(),
                  _sending: true,
                },
              },
              ...comments,
            ],
          },
        },
      },
    },
  }), [addCommentMutation, docId, me, comments]);
  const deleteComment = useCallback((variables: DeleteDocCommentMutationVariables) => deleteCommentMutation({
    variables,
    optimisticResponse: {
      deleteComment: {
        __typename: 'Comment',
        id: variables.commentId,
        doc: {
          id: docId,
          commentsCount: Math.max(0, comments.length - 1),
          comments: {
            __typename: 'CommentsConnection',
            edges: comments
              .filter(({ node }) => node.id !== variables.commentId)
              .reverse(),
          },
        },
      },
    },
  }), [deleteCommentMutation, comments, docId]);
  const resolveThread = useCallback((variables: ResolveThreadMutationVariables) => resolveThreadMutation({
    variables,
    optimisticResponse: {
      resolveThread: {
        __typename: 'Comment',
        id: variables.commentId,
        doc: {
          id: docId,
          commentsCount: 0,
          comments: {
            __typename: 'CommentsConnection',
            edges: [],
          },
        },
      },
    },
  }), [resolveThreadMutation, docId]);

  return {
    addComment,
    resolveThread,
    deleteComment,
    loading,
  };
}
