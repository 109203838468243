import { createGlobalStyle } from 'styled-components';

import { InputCss } from './components/Inputs/Input/Input.styles';

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    font-family: Inter, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;

    margin: 0;
    padding: 0;

    background-color: ${p => p.theme.colors.body.bg};
    color: ${p => p.theme.colors.body.color};

    overflow: hidden;
    height: 100%;
  }

  #app {
    width: 100%;
    height: 100%;
  }

  .highlight {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    mark {
      background-color: ${p => p.theme.colors.mark.bg};
    }
  }

  img {
    max-width: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, ul, li, input, label {
    padding: 0;
    margin: 0;
    user-select: text;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }

  * {
    box-sizing: border-box;
    user-select: none;
  }

  pre, code {
    font-family: Roboto Mono, monospace;
  }

  button {
    border: none;
    background: none;
    font-family: Inter, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    color: inherit;
  }

  /* to unset the default css values set globally */
  input, mark, textarea {
    color: unset;
    font-family: unset;
  }

  /* @Todo handle focus styles */
  *[role="button"], *[tabindex] {
    outline: none;
  }

  ::selection {
    background-color: ${p => p.theme.userColors.textHighlight};
  }

  #portal-selection {
    z-index: 200;
    position: absolute;
    background-color: ${p => p.theme.userColors.bg};
    border-color: ${p => p.theme.userColors.br};
    border-width: 0;
    border-style: solid;
  }

  #portal-toaster {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .emoji-mart {
    background-color: transparent;
    border-radius: 0;
    border: none;
    button {
      cursor: pointer;
    }
  }

  .emoji-mart-category {
    li, button.emoji-mart-emoji, .emoji-mart-emoji span {
      cursor: pointer;
    }
  }

  .emoji-mart-category .emoji-mart-emoji:hover:before {
    border-radius: 4px;
  }

  .emoji-mart-search-icon {
    display: none;
  }

  .emoji-mart-search {
    margin-top: 8px;
    padding-bottom: 6px;
    input {
      ${InputCss};
    }
  }

  .tippy-box {
    max-width: auto !important;
  }
`;
