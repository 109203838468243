import { Color } from '@cycle-app/graphql-codegen';
import { SelectPanel, Button, NuancePicker } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useMeasure } from '@cycle-app/utilities';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { useEnhancedForm, ErrorMap } from 'src/hooks/form/useEnhancedForm';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { CustomAttributeType, AttributeDefinitionsNode } from 'src/types/attribute.types';
import { Layer } from 'src/types/layers.types';
import { customAttributeTypeData, getCustomAttributeTypeData } from 'src/utils/attributes.util';

import {
  Form, Row, InputStyled, DivLabel, Caret, SelectedTypeButton,
} from './SettingsAttributeEditModal.styles';

type FormData = { type: CustomAttributeType } & Pick<AttributeDefinitionsNode, 'name' | 'color'>;

interface Props {
  attribute?: AttributeDefinitionsNode;
  onHide: VoidFunction;
}

const attributeTypeOptions = Object.entries(customAttributeTypeData).map(([type, {
  icon, label,
}]) => ({
  value: type,
  icon,
  label,
}));

// We do not have an unified format for business errors from the server yet.
// so we need to filter only expected errors.
const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    messagePattern: /^A property named/i,
    fieldName: 'name',
  },
];

export const SettingsAttributeEditModal: FC<Props> = (props) => {
  const {
    attribute,
    onHide,
  } = props;
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors: formErrors },
    displayFieldsErrors,
  } = useEnhancedForm<FormData>({
    defaultValues: {
      color: attribute?.color || Color.A,
      name: attribute?.name || '',
      type: attribute?.__typename || 'AttributeSingleSelectDefinition',
    },
    reValidateMode: 'onChange',
  });
  const {
    loading, addNewAttribute, updateAttribute,
  } = useAttributesMutations();
  const [isAttributeTypeSelectOpened, {
    toggleCallback: toggleAttributeTypeSelect, setFalseCallback: setAttributeTypeSelectClosed,
  }] = useOptimizedBooleanState(false);
  const {
    rect: selectedTypeButtonRect,
    ref: selectedTypeButtonRef,
  } = useMeasure<HTMLButtonElement>();
  const isEditing = !!attribute?.id;
  return (
    <PortalModalStyled hide={onModalHide}>
      <Header>
        <Title>{isEditing ? 'Edit property' : 'Add new property'}</Title>
        <CloseButtonStyled size="L" onClick={onModalHide}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <div>
            <DivLabel>
              Color
            </DivLabel>
            <Controller
              control={control}
              name="color"
              render={({ field }) => (
                <NuancePicker
                  placement="bottom-start"
                  color={field.value}
                  onClick={color => field.onChange(color)}
                  size="M"
                />
              )}
            />
          </div>
          <InputStyled
            id="attributeEdit-name"
            label="Name"
            placeholder="Your property name"
            autoFocus
            {...register('name', {
              maxLength: {
                value: 25,
                message: 'You can\'t add more than 25 characters.',
              },
              required: 'You must have at least one character.',
            })}
            error={formErrors.name?.message}
          />
        </Row>
        {!isEditing && (
          <div>
            <DivLabel>
              Type
            </DivLabel>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <DropdownLayer
                  layer={Layer.DropdownModal}
                  visible={isAttributeTypeSelectOpened}
                  hide={setAttributeTypeSelectClosed}
                  placement="bottom-start"
                  width={selectedTypeButtonRect?.width}
                  content={(
                    <SelectPanel
                      isMulti={false}
                      hideSearch
                      selectedValue={field.value}
                      options={attributeTypeOptions}
                      onOptionChange={(option) => { field.onChange(option.value); toggleAttributeTypeSelect(); }}
                    />
            )}
                >
                  <SelectedTypeButton ref={selectedTypeButtonRef} variant="light" type="button" onClick={toggleAttributeTypeSelect}>
                    {getCustomAttributeTypeData(field.value).icon}
                    {getCustomAttributeTypeData(field.value).label}
                    <Caret />
                  </SelectedTypeButton>
                </DropdownLayer>
              )}
            />
          </div>
        )}
        <Actions>
          <Button
            type="button"
            size="M"
            variant="secondary"
            disabled={loading}
            onClick={onModalHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="M"
            disabled={!!Object.keys(formErrors).length}
            isLoading={loading}
          >
            Save
          </Button>
        </Actions>
      </Form>
    </PortalModalStyled>
  );

  async function onSubmit({
    type, ...data
  }: FormData) {
    const result = isEditing ? await updateAttribute({
      ...data,
      attributeId: attribute.id,
    }) : await addNewAttribute({
      ...data,
      type: getCustomAttributeTypeData(type).typeInput,
    });
    if (result?.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap);
      return;
    }
    onHide();
  }

  function onModalHide() {
    reset();
    onHide();
  }
};
