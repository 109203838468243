import { CycleLoader } from '@cycle-app/ui';
import { FC } from 'react';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { EmptyState } from '../../components/Views';
import { useMaybeMeV2 } from '../../hooks/api/useMe';

export const MainRoot: FC = ({ children }) => {
  const {
    me, error, isLoading,
  } = useMaybeMeV2();
  if (isLoading) {
    return <CycleLoader />;
  }
  if (me?.onboarded && !me.products.edges.length) {
    return <EmptyState />;
  }
  if (error) {
    return <ErrorPage />;
  }
  return <>{children}</>;
};
