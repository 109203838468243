import { DoctypeRelativeFragment } from '@cycle-app/graphql-codegen/generated';
import { Emoji } from '@cycle-app/ui';
import { FC } from 'react';

import { DialogModalProps } from 'src/components/DialogModal/DialogModal';

import { DialogModalStyled, TagStyled } from './DoctypeDeleteModal.styles';

interface Props extends Omit<DialogModalProps, 'title'> {
  doctype: DoctypeRelativeFragment;
  isConfirmDisabled: DialogModalProps['isConfirmDisabled'];
  onToggleConfirmation: DialogModalProps['onToggleConfirmation'];
}

export const DoctypeDeleteModal: FC<Props> = ({
  doctype, ...props
}) => (
  <DialogModalStyled
    title="Delete doc type"
    info={(
      <>
        {'Are you sure you want to delete '}
        <TagStyled limitSize={false} icon={<Emoji emoji={doctype.emoji} />}>
          {doctype.name}
        </TagStyled>
        {' ?'}
        <br />
        All corresponding docs will be deleted. This can&apos;t be undone.
      </>
    )}
    confirmLabel="Delete"
    confirmMessage="Yes, permanently delete this doc type"
    {...props}
  />
);
