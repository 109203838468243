import styled from 'styled-components';

import { DocItem } from 'src/components/DocItem';
import LoadMore from 'src/components/LoadMore/LoadMore';

export const DocsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 0 8px;
`;

export const StyledDocItem = styled(DocItem)`
  & + & {
    margin-top: 8px;
  }
`;

export const StyledLoadMore = styled(LoadMore)`
  margin-bottom: 8px;
`;
