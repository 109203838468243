import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const IndentIncreaseIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.83333 2.16666C6.189 2.16666 5.66667 2.68899 5.66667 3.33332C5.66667 3.97766 6.189 4.49999 6.83333 4.49999H13.8333C14.4777 4.49999 15 3.97766 15 3.33332C15 2.68899 14.4777 2.16666 13.8333 2.16666H6.83333Z" />
    <path d="M9.16667 6.83361C8.52233 6.83361 8 7.35594 8 8.00028C8 8.64461 8.52233 9.16694 9.16667 9.16694H13.8333C14.4777 9.16694 15 8.64461 15 8.00028C15 7.35594 14.4777 6.83361 13.8333 6.83361H9.16667Z" />
    <path d="M5.66667 12.6667C5.66667 12.0223 6.189 11.5 6.83333 11.5H13.8333C14.4777 11.5 15 12.0223 15 12.6667C15 13.311 14.4777 13.8333 13.8333 13.8333H6.83333C6.189 13.8333 5.66667 13.311 5.66667 12.6667Z" />
    <path d="M4.61558 8.00142L1.21318 4.59881C1.05204 4.43771 0.977494 4.232 1.00593 4.02693C1.02002 3.92539 1.05896 3.82706 1.12054 3.73756C1.18212 3.64806 1.26513 3.56914 1.36483 3.5053C1.56619 3.37638 1.82331 3.31674 2.07963 3.3395C2.33596 3.36225 2.57048 3.46553 2.73162 3.62663L6.62008 7.51533C6.75812 7.65327 6.83333 7.82471 6.83333 8.00142C6.83333 8.17812 6.75812 8.34956 6.62008 8.4875L2.73162 12.3762C2.65183 12.456 2.55319 12.5224 2.44132 12.5716C2.32945 12.6209 2.20655 12.6521 2.07963 12.6633C1.95272 12.6746 1.82427 12.6658 1.70162 12.6373C1.57897 12.6089 1.46453 12.5614 1.36483 12.4975C1.26513 12.4337 1.18212 12.3548 1.12054 12.2653C1.05896 12.1758 1.02002 12.0774 1.00593 11.9759C0.991852 11.8744 1.00291 11.7716 1.03847 11.6735C1.07403 11.5754 1.13339 11.4838 1.21318 11.404L4.61558 8.00142Z" />
  </Svg>
);
