import { Tooltip } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useAnimation } from 'framer-motion';
import shuffle from 'lodash/shuffle';
import { useEffect, useMemo } from 'react';

import { DropdownLayer, useToggleDropdown } from 'src/components/DropdownLayer';
import { useProductIntegrations } from 'src/hooks';
import { useIntegrationAction } from 'src/hooks/useIntegrationAction';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetIntegrationPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { FrontEndIntegration } from 'src/types/integrations.types';
import { integrationsData } from 'src/utils/integrations.utils';

import { sourceVariants } from './InboxZeroSources.motion';
import { Container, SourceContainer, SourceButton, AddSourceButton } from './InboxZeroSources.styles';
import { SourcesPanel } from './SourcesPanel';

const openLimitationsModal = () => setLimitationsModal({ action: 'INTEGRATION_ADD' });

const CYCLE_INTEGRATION = {
  integrationType: FrontEndIntegration.CYCLE,
  integration: undefined,
};

type InboxZeroSourcesProps = {
  isReadOnly: boolean;
};

export const InboxZeroSources = ({ isReadOnly }: InboxZeroSourcesProps) => {
  const { sourcesByStatus } = useProductIntegrations();
  const { canAddIntegration } = useGetIntegrationPermission();
  const { canReadSettings } = useGetPermission();
  const controls = useAnimation();
  const getIntegrationAction = useIntegrationAction();

  const installed = useMemo(() => shuffle([
    ...sourcesByStatus.installed,
    CYCLE_INTEGRATION,
  ]), [sourcesByStatus.installed]);

  const {
    buttonRef,
    isDropdownVisible,
    openDropdown,
    hideDropdown,
  } = useToggleDropdown();

  const half = Math.floor(installed.length / 2);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    controls.start('rest');
  }, [controls, installed]);

  return (
    <Container
      onMouseEnter={() => controls.start('hover')}
      onMouseLeave={() => controls.start('rest')}
      onBlur={() => controls.start('rest')}
      $isReadOnly={isReadOnly}
    >
      {installed.map((source, index) => {
        const data = integrationsData[source.integrationType];
        const customIndex = index - half;
        return (
          <SourceContainer
            key={source.integrationType}
            variants={sourceVariants(isDropdownVisible)}
            custom={customIndex}
            animate={controls}
            style={{
              zIndex: index >= half ? installed.length - index : index,
            }}
          >
            <Tooltip
              content={data.action ?? data.label}
              placement="top"
              withPortal
            >
              <SourceButton onClick={getIntegrationAction(source.integrationType, source.integration)}>
                {data.icon}
              </SourceButton>
            </Tooltip>
          </SourceContainer>
        );
      })}

      {canReadSettings && sourcesByStatus.uninstalled.length && (
        <SourceContainer
          variants={sourceVariants(isDropdownVisible)}
          custom={installed.length - half}
          animate={controls}
        >
          <DropdownLayer
            placement="bottom"
            offsetY={8}
            visible={isDropdownVisible}
            hide={hideDropdown}
            content={<SourcesPanel hide={hideDropdown} />}
          >
            <Tooltip
              content="Add source"
              placement="top"
              withPortal
              style={{ height: 42 }}
            >
              <AddSourceButton
                ref={buttonRef}
                data-active={isDropdownVisible}
                onClick={canAddIntegration ? openDropdown : openLimitationsModal}
              >
                <AddIcon size={14} />
              </AddSourceButton>
            </Tooltip>
          </DropdownLayer>
        </SourceContainer>
      )}
    </Container>
  );
};
