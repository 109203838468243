import { DoctypeType } from '@cycle-app/graphql-codegen/generated';
import { useMemo } from 'react';

import { useProductBase } from '../../useProduct';
import { useDocTypes } from './useDocTypes';

export const useDocTypeFeedback = () => {
  const product = useProductBase();
  const {
    docTypes, isLoading,
  } = useDocTypes(product?.id);

  const feedback = useMemo(() => {
    return docTypes.find(d => d.type === DoctypeType.Feedback);
  }, [docTypes]);

  return {
    feedback,
    isLoading,
  };
};
