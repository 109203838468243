import { useQuery } from '@apollo/client';
import { SearchProductCompaniesDocument } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';

import { defaultCompaniesPagination } from 'src/utils/pagination.util';

import { useProduct } from '../../useProduct';

export const useCompanies = () => {
  const { product } = useProduct();

  const {
    data, loading, fetchMore, refetch,
  } = useQuery(SearchProductCompaniesDocument, {
    fetchPolicy: 'cache-first',
    skip: !product?.id,
    variables: {
      id: product?.id ?? '',
      ...defaultCompaniesPagination,
    },
  });

  const companies = useMemo(() => (data?.node?.__typename === 'Product' ? nodeToArray(data.node.companies) : []), [data]);
  const pageInfo = data?.node?.__typename === 'Product' ? data.node.companies.pageInfo : null;

  const fetchNextPage = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return;
    await fetchMore({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
  }, [pageInfo, fetchMore]);

  const searchCompanies = useCallback(async (searchText: string) => {
    await refetch({
      searchText,
      cursor: '',
    });
  }, [refetch]);

  return {
    companies,
    companiesCount: (data?.node?.__typename === 'Product' && data.node.companyCount) || 0,
    isLoading: loading,
    fetchNextPage,
    pageInfo,
    searchCompanies,
  };
};
