import { MateFragment, Role } from '@cycle-app/graphql-codegen';
import { InfiniteScroll, Avatar, DropdownSelect, SelectOption } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import SettingsUserRemoveUserModal from 'src/app/Main/Settings/SettingsUsers/SettingsUserRemoveUserModal';
import { useCurrentBilling } from 'src/hooks';
import { useChangeUserProductRole } from 'src/hooks/api/mutations/useChangeUserProductRole';
import { useUsers } from 'src/hooks/api/useUsers';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';

import {
  UsersToggleButton,
  UserItem,
  UserName,
  UsersList,
  RoleButtonLabel,
  UpdateInfo,
  StyledCaretIcon,
  StyledTextButton,
  UserNameLabel,
} from './BillingUpgrade.styles';

const planMessage: Partial<SelectOption> = {
  tooltipContent: 'You need at least 1 maker',
  tooltipPlacement: 'bottom',
  tooltipOffset: [0, 20],
};

export const BillingUpgradeUsers = () => {
  const {
    users,
    loading,
    pageInfo,
    fetchMore,
  } = useUsers();
  const [isUsersVisible, { toggleCallback: toggleUsersVisible }] = useOptimizedBooleanState(false);
  const [userToRemove, setUserToRemove] = useState<MateFragment | null>(null);
  const permission = useGetPermission();
  const billingPermission = useGetBillingPermission();
  const currentBilling = useCurrentBilling();
  const { changeUserProductRole } = useChangeUserProductRole();
  return (
    <>
      <UsersToggleButton size={10} disabled={!users.length} onClick={toggleUsersVisible} $isUp={isUsersVisible}>
        {currentBilling?.nbMakers
          ? `You currently have ${currentBilling.nbMakers} maker${(currentBilling.nbMakers > 1 && 's') || ''}`
          : 'You currently have no makers'}
        {users.length && <StyledCaretIcon $isUp={isUsersVisible} />}
      </UsersToggleButton>
      {isUsersVisible && (
        <UsersList>
          <InfiniteScroll
            isLoading={loading}
            hasMoreData={pageInfo.hasNextPage}
            loadMore={loadMoreUsers}
          >
            <ul>
              {users.map(user => {
                const cannotPlanUpdate = user.productRole === Role.Maker && !billingPermission.canCurrentPlanUpdateMaker;
                const cannotPlanDelete = user.productRole === Role.Maker && !billingPermission.canCurrentPlanDeleteMaker;
                const canDelete = user.productRole !== Role.Admin || permission.canDeleteAdmin;
                const canUpdateRole =
                  (user.productRole === Role.Admin && permission.canUpdateUserRoleAdmin) ||
                  (user.productRole === Role.Maker && permission.canUpdateUserRoleMaker) ||
                  (user.productRole === Role.Collaborator && permission.canUpdateUserRoleCollaborator);
                return (
                  <UserItem key={user.id}>
                    <UserName $isDisabled={!user.jobTitle}>
                      <Avatar user={user} />
                      <UserNameLabel>
                        {user.jobTitle ? `${user.firstName} ${user.lastName}` : user.email}
                      </UserNameLabel>
                    </UserName>
                    {canUpdateRole && (
                      <DropdownSelect
                        placement="bottom-end"
                        hideSearch
                        options={[{
                          label: 'Maker',
                          value: Role.Maker,
                          onSelect: async () => {
                            if (user.productRole) {
                              await changeUserProductRole(Role.Maker, user.id, user.productRole);
                            }
                          },
                          disabled: !canUpdateRole || cannotPlanUpdate,
                          ...cannotPlanUpdate && planMessage,
                        }, {
                          label: 'Collaborator',
                          value: Role.Collaborator,
                          onSelect: async () => {
                            if (user.productRole) {
                              await changeUserProductRole(Role.Collaborator, user.id, user.productRole);
                            }
                          },
                          disabled: !canUpdateRole || cannotPlanUpdate,
                          ...cannotPlanUpdate && planMessage,
                        }, {
                          label: 'Remove',
                          value: 'remove',
                          variant: 'danger',
                          onSelect: () => setUserToRemove(user),
                          disabled: !canDelete || cannotPlanDelete,
                          ...cannotPlanDelete && planMessage,
                        }]}
                      >
                        <StyledTextButton size={10}>
                          <RoleButtonLabel>
                            {user.productRole}
                          </RoleButtonLabel>
                          <CaretIcon />
                        </StyledTextButton>
                      </DropdownSelect>
                    )}
                  </UserItem>
                );
              })}
            </ul>
            <UpdateInfo>
              The billing will automatically update if you add or remove makers later down the line.
            </UpdateInfo>
          </InfiniteScroll>
        </UsersList>
      )}
      {userToRemove && (
        <SettingsUserRemoveUserModal
          user={userToRemove}
          onClose={() => setUserToRemove(null)}
        />
      )}
    </>
  );

  async function loadMoreUsers() {
    if (pageInfo.endCursor) {
      await fetchMore(pageInfo.endCursor);
    }
  }
};
