import { SelectPanel, Button, typo } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import { FakeInput } from '../Filter.styles';

export const Container = styled(Button).attrs({
  variant: 'ternary',
})<{ disabled?: boolean }>`
  background-color: ${p => p.theme.colors.boardConfig.filters.element.bg};
  color: ${p => p.theme.colors.text.primary};
  min-height: 28px;

  ${p => p.disabled && css`
    color: ${p.theme.colors.text.disabled};
    cursor: pointer;
    pointer-events: none;
  `}
  ${p => !p.disabled && css`
    &:hover {
      background-color: ${p.theme.colors.boardConfig.filters.element.bgHover};
    }
    &:active, &:focus, &.force-focus {
      background-color: ${p.theme.colors.boardConfig.filters.element.bgFocus};
    }
  `}
`;

export const StyledSelectPanel = styled(SelectPanel)`
  max-width: 350px;
`;

export const MoreValues = styled.span`
  margin-left: 2px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const TagContainer = styled(Container)`
  padding: 0 4px;
`;

export const SelectedOptionTagContainer = styled(FakeInput)`
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  padding: 4px;
`;

export const SelectedOptionTag = styled(Container)`
  ${typo.caption500};
  height: 22px;
  min-height: auto;
`;

export const SelectedOptionCloseIcon = styled(CloseIcon)`
  color: ${p => p.theme.colors.actionButton.color};
  height: 10px;
  width: 10px;
`;
