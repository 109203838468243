import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { ReactNode } from 'react';

import { BoardEditForm } from 'src/components/BoardEditCommonModal/BoardEditForm';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { isViewMyInbox } from 'src/utils/inbox.util';

export type InboxEditViewFormProps = {
  onDone?: VoidFunction;
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
  children: (isLoading: boolean) => ReactNode;
};

export const InboxEditViewForm = ({
  onDone, board, draftBoardConfigId, children,
}: InboxEditViewFormProps) => {
  const boardMutations = useBoardMutations();
  const draftMutations = useDraftBoardConfigMutations(draftBoardConfigId, board.id);
  const isNameDisabled = isViewMyInbox(board);

  return (
    <BoardEditForm
      isIconHidden
      isNameDisabled={isNameDisabled}
      defaultValues={board}
      focusNameOnMount
      onSubmit={async (formData) => {
        const draftMutation = isViewMyInbox(board)
          ? draftMutations.saveMyBoardConfig
          : draftMutations.publishBoardConfig;

        const [publishResult, boardResult] = await Promise.all([
          draftMutation(),
          boardMutations.updateBoard({
            ...formData,
            name: isNameDisabled ? board.name : formData.name,
            boardId: board.id,
          }),
        ]);
        if (publishResult.errors || boardResult.errors) return;
        onDone?.();
      }}
    >
      {children(boardMutations.loading || draftMutations.loading.publish)}
    </BoardEditForm>
  );
};
