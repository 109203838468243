import { ActionButton, typo, boxShadowZ1, CustomerAvatar, SelectLine } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';
import { FloatingPenButtonContainer } from '../FloatingPenButton/FloatingPenButton.styles';

export const StyledActionButton = styled(props => <ActionButton as="div" {...props} />)<{ $isSelected?: boolean }>`
  ${p => p.$isSelected && css`
    ${typo.body500}
    color: ${p.theme.colors.text.primary};
  `}
  white-space: nowrap;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const EditButtonContainer = styled(FloatingPenButtonContainer)<{ $isCompact?: boolean }>`
  &:hover ${StyledActionButton} {
    color: ${p => p.theme.colors.actionButton.colorHover};
    background-color: ${p => p.theme.colors.actionButton.bgHover};
  }

  ${p => p.$isCompact && css`
    padding: 0;

    ${StyledActionButton} {
      padding-bottom: 0;
      padding-left: 2px;
      padding-top: 0;
    }

    &:hover ${StyledActionButton} {
      background-color: transparent;
    }
  `}
`;

export const SelectionIcon = styled(CustomerAvatar)`
  margin-right: 5px;
`;

export const StyledCustomerAvatar = styled(CustomerAvatar)`
  margin-right: 8px;
`;

export const TooltipCustomerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TooltipCustomerContentUpdateLabel = styled.div`
  color: ${p => p.theme.colors.tooltip.textLight};
`;

export const CreateCustomerLine = styled(SelectLine)`
  color: ${p => p.theme.colors.text.secondary};
  margin-left: -8px;
  margin-right: -8px;

  svg {
    height: 8px;
    width: 8px;
  }
`;

export const OpenCustomerButton = styled(ActionButton)`
  --size: 10px;
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  color: ${p => p.theme.colors.text.secondary};
  gap: 4px;
  height: 20px;
  ${typo.caption400}
  ${boxShadowZ1}
`;

export const StyledCustomerCompanyAction = styled(CustomerCompanyAction)`
  --bg: ${p => p.theme.colors.actionButton.bgHover};
  border-radius: 50%;
  height: var(--customer-company-logo-size);
  min-height: auto;
  width: 100%;
`;

interface ActionButtonCursorProps {
  $isCompact?: boolean;
}

export const ActionButtonCursor = styled.span<ActionButtonCursorProps>`
  ${p => !p.$isCompact && css`cursor: not-allowed;`}
`;
