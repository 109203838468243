import { Container, SvgLogo, SvgName, ContainerProps } from './CycleLogo.styles';

export type CycleLogoProps = {
  animation?: ContainerProps['$animation'];
  size: ContainerProps['$size'];
  color?: ContainerProps['$color'];
  className?: string;
  id?: string;
  full?: boolean;
};

export const CycleLogo = ({
  size = 90,
  animation,
  full = false,
  color = 'themed',
  className,
  id = 'cycle_logo',
}: CycleLogoProps) => (
  <Container className={className} $animation={animation} $size={size} $color={color}>
    <SvgLogo viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M90 46.3007C89.6896 55.4667 86.5745 64.3778 80.9652 71.8082C74.3212 80.6163 64.6874 86.6582 53.9209 88.8657C53.1476 89.1911 52.3361 89.4243 51.5029 89.5599C42.686 90.7965 33.7002 89.4135 25.6675 85.5843C17.6403 81.7552 10.9255 75.6482 6.36723 68.0334C1.81445 60.4185 -0.385696 51.6322 0.0554227 42.7753C0.496541 33.9185 3.55169 25.3925 8.84511 18.2604C14.1331 11.1283 21.4197 5.71543 29.7901 2.69445C38.1604 -0.32653 47.2388 -0.820083 55.8869 1.27888C64.535 3.37783 72.3716 7.9771 78.4057 14.4963C84.4397 21.0156 88.4044 29.1619 89.8094 37.9157C89.9891 38.9137 90.0218 39.9225 89.9183 40.915C89.597 42.2764 88.9653 43.5672 88.0613 44.7116C86.3948 46.8051 83.9932 48.1448 81.2539 48.4919C79.8652 48.7034 78.4656 48.6763 77.0878 48.4105C75.7481 48.1502 74.4846 47.6512 73.3464 46.9299C72.2627 46.2411 71.3532 45.357 70.6344 44.294C69.9591 43.3177 69.5016 42.1896 69.311 41.0235C69.3001 40.9693 69.2892 40.915 69.2729 40.8608C69.2239 40.3727 69.213 39.8791 69.2348 39.3856C68.6357 36.869 67.65 34.4554 66.294 32.2372C64.5513 29.3952 62.2531 26.9274 59.5247 24.9911C56.8018 23.0603 53.7139 21.6936 50.4464 20.9831C47.1734 20.2726 43.7969 20.2237 40.5131 20.8529C34.5879 22.0136 29.2836 25.2786 25.6022 30.046C21.9153 34.808 20.0909 40.7469 20.4776 46.7455C20.7172 50.0864 21.6321 53.3406 23.1787 56.3182C24.7199 59.2904 26.8493 61.9209 29.447 64.0524C32.061 66.1676 35.0726 67.7405 38.3129 68.6679C40.9923 69.4327 43.7806 69.731 46.558 69.5628C47.1625 69.373 47.7779 69.232 48.4151 69.1561C54.8685 68.2178 60.6847 64.7737 64.6057 59.5833C67.4158 55.8627 69.0877 51.4532 69.4798 46.8865C70.2041 47.7109 71.0482 48.4322 71.9958 49.0397C73.4063 49.9346 74.9584 50.5475 76.6085 50.8675C77.5398 51.0464 78.4765 51.1332 79.4132 51.1332C80.1484 51.1332 80.8781 51.079 81.6024 50.9705C84.9462 50.5475 87.9306 48.8878 90 46.3007Z" fill={`url(#${id})`} />
      <defs>
        <linearGradient id={id} x1="45" y1="90" x2="45" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="currentcolor" />
          <stop offset="1" stopColor="currentcolor" />
        </linearGradient>
      </defs>
    </SvgLogo>

    {full && (
      <SvgName viewBox="40 6 74.05 29.41" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 17.1716C40 14.9891 40.4634 13.0607 41.3902 11.3864C42.3171 9.7022 43.6126 8.38671 45.2769 7.43996C46.9512 6.48324 48.8945 6.00488 51.1069 6.00488C53.0901 6.00488 54.8042 6.3786 56.2493 7.12604C57.6943 7.86351 58.94 8.9149 59.9864 10.2802L56.4735 13.1205C55.7858 12.2235 55.0235 11.5558 54.1863 11.1173C53.3492 10.6689 52.3526 10.4447 51.1966 10.4447C49.8711 10.4447 48.74 10.7386 47.8032 11.3266C46.8664 11.9046 46.1489 12.7019 45.6506 13.7184C45.1523 14.7349 44.9032 15.886 44.9032 17.1716C44.9032 18.4572 45.1523 19.6082 45.6506 20.6247C46.1489 21.6313 46.8664 22.4285 47.8032 23.0165C48.74 23.6045 49.8711 23.8985 51.1966 23.8985C52.3626 23.8985 53.3691 23.6842 54.2162 23.2557C55.0733 22.8172 55.8407 22.1545 56.5183 21.2675L59.9864 24.0629C58.95 25.4382 57.7043 26.4946 56.2493 27.2321C54.8042 27.9695 53.0901 28.3383 51.1069 28.3383C48.8945 28.3383 46.9512 27.8649 45.2769 26.9181C43.6126 25.9614 42.3171 24.6409 41.3902 22.9567C40.4634 21.2725 40 19.3441 40 17.1716Z" fill="currentcolor" />
        <path d="M90.4749 25.0645C89.7374 26.1607 88.8056 26.9829 87.6795 27.531C86.5533 28.0692 85.2926 28.3383 83.8974 28.3383C82.3129 28.3383 80.9127 28.0044 79.6968 27.3367C78.481 26.669 77.5293 25.7422 76.8416 24.5562C76.154 23.3603 75.8102 21.9851 75.8102 20.4304C75.8102 18.8658 76.154 17.4855 76.8416 16.2896C77.5392 15.0837 78.5009 14.142 79.7267 13.4643C80.9525 12.7766 82.3727 12.4328 83.9871 12.4328C85.3425 12.4328 86.5782 12.7069 87.6944 13.255C88.8106 13.8031 89.7374 14.6253 90.4749 15.7215L87.1114 18.2927C86.6928 17.6748 86.2294 17.2164 85.7212 16.9174C85.2129 16.6085 84.605 16.454 83.8974 16.454C82.8311 16.454 81.9591 16.8128 81.2814 17.5303C80.6137 18.2479 80.2798 19.2096 80.2798 20.4154C80.2798 21.6113 80.6137 22.5631 81.2814 23.2706C81.9491 23.9782 82.8211 24.332 83.8974 24.332C85.3026 24.332 86.4038 23.744 87.2011 22.5681L90.4749 25.0645Z" fill="currentcolor" />
        <path d="M96.4693 6.30386V23.465C96.4693 23.8736 96.6786 24.0779 97.0971 24.0779H97.7848V28.0393H96.0059C94.7103 28.0393 93.7187 27.7104 93.0311 27.0527C92.3534 26.385 92.0146 25.3236 92.0146 23.8686V6.30386H96.4693Z" fill="currentcolor" />
        <path d="M112.928 25.498C112.26 26.4348 111.368 27.1424 110.252 27.6207C109.146 28.0991 107.885 28.3383 106.47 28.3383C104.856 28.3383 103.445 28.0044 102.24 27.3367C101.034 26.659 100.097 25.7272 99.4291 24.5413C98.7714 23.3454 98.4425 21.9701 98.4425 20.4154C98.4425 18.8608 98.7714 17.4855 99.4291 16.2896C100.087 15.0837 101.009 14.142 102.195 13.4643C103.391 12.7766 104.776 12.4328 106.35 12.4328C108.074 12.4328 109.539 12.8265 110.745 13.6138C111.951 14.4011 112.838 15.5023 113.406 16.9174C113.974 18.3326 114.164 19.9819 113.974 21.8655H103.017C103.186 22.7624 103.575 23.465 104.183 23.9732C104.801 24.4815 105.583 24.7356 106.53 24.7356C107.287 24.7356 107.94 24.6061 108.488 24.347C109.036 24.0878 109.505 23.7341 109.893 23.2856L112.928 25.498ZM106.35 15.9906C105.444 15.9906 104.711 16.2298 104.153 16.7082C103.595 17.1865 103.231 17.8642 103.062 18.7412H109.594C109.475 17.8742 109.136 17.2015 108.578 16.7231C108.02 16.2348 107.277 15.9906 106.35 15.9906Z" fill="currentcolor" />
        <path d="M61 13.0105L67.2644 28.3235L66.7582 29.4625C66.2203 30.6331 64.8599 31.4874 63.4678 31.4874L65.5559 35.4105C67.7074 35.3156 69.4475 33.7653 70.4283 31.1077L77.0723 13.0105H72.2L69.2893 23.1348L65.9356 13.0105H61Z" fill="currentcolor" />
      </SvgName>
    )}
  </Container>
);
