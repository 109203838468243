import { forwardRef } from 'react';

import { Color } from '../../theme/baseColors';
import { Container, Content } from './CycleLoader.styles';

export const CycleLoader = forwardRef<HTMLDivElement>((_, ref) => (
  <Container role="main" aria-busy="true" ref={ref}>
    <Content
      animate={{
        transform: 'scale(1)',
        opacity: 1,
      }}
      initial={{
        transform: 'scale(0.9)',
        opacity: 0,
      }}
      transition={{
        duration: 0.3,
        ease: 'easeIn',
      }}
    >
      <LogoVector />
    </Content>
  </Container>
));

const LogoVector = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M100 47.6598C99.9926 48.4393 99.9375 49.1783 99.4997 50.3548C98.9773 51.8328 98.1717 53.1858 97.1306 54.3402C96.0895 55.4947 94.835 56.4249 93.4371 57.0719C92.0392 57.7227 90.5309 58.083 88.9968 58.1271C87.4628 58.1713 85.9361 57.9065 84.505 57.3403C83.074 56.7742 81.768 55.9212 80.6607 54.8329C79.5534 53.7446 78.6742 52.4394 78.0672 50.9982C77.7545 50.2519 77.5522 49.4025 77.4271 48.5349C76.6656 34.0711 64.6912 22.5744 50.0276 22.5744C35.364 22.5744 22.5876 34.8542 22.5876 49.9982C22.5876 65.1421 34.8711 77.4183 50.0239 77.4183C63.2859 77.4183 74.3479 68.0172 76.9047 55.5204C77.9495 56.9617 79.1782 58.2632 80.6497 59.2632C81.5252 59.8551 82.4743 60.436 83.2211 60.7339C85.0936 61.4729 87.0912 61.8221 89.0961 61.7633C91.1011 61.7045 93.0766 61.2339 94.9012 60.3846C95.2397 60.2265 95.6259 59.9949 96.038 59.7154C97.355 58.8257 98.5726 57.7742 99.6211 56.583C96.3985 81.0839 75.4221 100 50.0239 100C22.3964 99.9963 0 77.6095 0 49.9982C0 22.3869 22.3964 0 50.0276 0C77.6588 0 98.7786 21.1331 100 47.6598Z" fill={Color.Grey200} />
  </svg>
);
