import { Emoji } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import EditBoardModal from 'src/app/Main/Board/EditBoardModal/EditBoardModal';
import BoardOptions from 'src/components/BoardOptions/BoardOptions';
import { BoardPresence } from 'src/components/BoardPresence';
import { useBoardsStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';
import { useProduct } from 'src/hooks/api/useProduct';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getBoardSlug } from 'src/utils/slug.util';

import {
  CardDescription,
  CardFooter,
  CardTitle,
  StarBoardCard,
  ActionsContainer,
  EditDescriptionButton,
} from './HomeStarredBoardSection.styles';

export const HomeStarredBoards = () => {
  const { product } = useProduct();
  const { canUpdateBoard } = useGetPermission();
  const [editingBoardId, setEditingBoardId] = useState('');
  const { boards: starredBoards } = useBoardsStarred();
  return (
    <>
      {starredBoards.map((starBoard) => (
        <StarBoardCard
          key={starBoard.id}
          to={`/app/${product?.slug || ''}/board/${getBoardSlug(starBoard)}`}
        >
          <ActionsContainer>
            <BoardOptions boardId={starBoard.id} />
          </ActionsContainer>
          <CardTitle>
            {starBoard.emoji && <Emoji emoji={starBoard.emoji} />}
            {starBoard.name}
          </CardTitle>
          <CardDescription>
            {/* eslint-disable-next-line no-nested-ternary */}
            {starBoard.description
              ? starBoard.description
              : canUpdateBoard
                ? (
                  <EditDescriptionButton
                    variant="nospace"
                    useUserColor={false}
                    iconStart={<PenFilledIcon size={16} />}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditingBoardId(starBoard.id);
                    }}
                  >
                    Add description
                  </EditDescriptionButton>
                )
                : null}
          </CardDescription>
          <CardFooter>
            <BoardPresence boardId={starBoard.id} />
          </CardFooter>
        </StarBoardCard>
      ))}
      {!!editingBoardId && (
        <EditBoardModal
          boardId={editingBoardId}
          onHide={() => setEditingBoardId('')}
          focusDescription
        />
      )}
    </>
  );
};
