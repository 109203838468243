import { Button, Shortcuts, ShortcutComponents } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

export const StyledShortcuts = styled(Shortcuts)`
  ${ShortcutComponents.Key} {
    background-color: ${Color.Grey800};
    color: ${Color.Grey400};
  }
`;

export const ProcessButton = styled(Button).attrs({
  size: 'L',
  variant: 'secondary',
})<{
  $isActive: boolean;
  $parent: 'doc-item' | 'doc-panel';
}>`
  padding: ${p => (p.$parent === 'doc-item' ? '4px' : '6px')};
  border: ${p => (p.$parent === 'doc-item' ? 0 : '1px')} solid ${p => p.theme.colors.border.primary};
  color: ${p => (p.$isActive ? p.theme.userColors.main : p.theme.colors.text.disabled)};

  &,:focus {
    background-color: ${p => p.theme.colors.button.secondary.bg};
    border-color: ${p => p.theme.colors.border.primary};
  }

  :hover {
    background-color: ${p => p.theme.colors.button.secondary.bgHover};
    border-color: ${p => p.theme.colors.button.secondary.bgHover};
  }
  
  :active {
    background-color: ${p => p.theme.colors.button.secondary.bgFocus};
    border-color: ${p => p.theme.colors.button.secondary.bgFocus};
  }
`;
