import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const UserIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.81251 4.5C5.81251 3.29188 6.79189 2.3125 8.00001 2.3125C9.20813 2.3125 10.1875 3.29188 10.1875 4.5C10.1875 5.70812 9.20813 6.6875 8.00001 6.6875C6.79189 6.6875 5.81251 5.70812 5.81251 4.5ZM8.00001 1C6.06701 1 4.50001 2.567 4.50001 4.5C4.50001 6.433 6.06701 8 8.00001 8C9.93301 8 11.5 6.433 11.5 4.5C11.5 2.567 9.93301 1 8.00001 1ZM8.00001 9.09375C6.12288 9.09375 4.7212 9.50372 3.6809 10.1247C2.64009 10.746 1.99935 11.5556 1.60906 12.2946C1.24701 12.9801 1.36554 13.6953 1.77742 14.2125C2.1719 14.7079 2.81042 15 3.49157 15H12.5084C13.1896 15 13.8281 14.7079 14.2226 14.2125C14.6345 13.6953 14.753 12.9802 14.391 12.2947C14.0007 11.5557 13.3599 10.746 12.3191 10.1247C11.2788 9.50372 9.87715 9.09375 8.00001 9.09375ZM2.76964 12.9076C3.06572 12.3469 3.55057 11.731 4.35362 11.2517C5.15719 10.772 6.31765 10.4062 8.00001 10.4062C9.68238 10.4062 10.8428 10.772 11.6464 11.2517C12.4495 11.731 12.9343 12.3469 13.2304 12.9076C13.3349 13.1055 13.3019 13.2617 13.1959 13.3949C13.0724 13.55 12.8265 13.6875 12.5084 13.6875H3.49157C3.17355 13.6875 2.92765 13.55 2.80415 13.3949C2.69807 13.2617 2.66508 13.1055 2.76964 12.9076Z" />
  </Svg>
);
