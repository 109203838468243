import styled, { css } from 'styled-components';

import { body400, body500 } from '../../../theme/typo';
import { InfiniteScroll } from '../../InfiniteScroll/InfiniteScroll';
import { ShyScrollbarCss } from '../../ShyScrollbar/ShyScrollbar.styles';

export const HistoryList = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${p => p.theme.colors.text.primary};
  ${body400}

  max-height: 238px;
  margin-right: -10px;
  padding-right: 10px;
  ${ShyScrollbarCss}
`;

export const HistoryItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  padding: 14px 16px;
  border-radius: 6px;
  background: ${p => p.theme.colors.billing.history.bg};
  > * {
    flex: none;
  }
`;

export const Month = styled.div`
  width: 82px;
  ${body500}
`;

export const DocCount = styled.div`
  flex-grow: 1;
  color: ${p => p.theme.colors.text.secondary};
`;

export const Amount = styled.div`
  flex-grow: 1;
  text-align: right;
  ${body500}
`;

export const InvoiceButton = styled.a<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  border-radius: 4px;

  ${p => p.disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  color: ${p => p.theme.colors.text.secondary};

  :hover {
    color: ${p => p.theme.colors.text.primary};
    background: ${p => p.theme.colors.background.hover};
  }

  :active {
    color: ${p => p.theme.colors.text.primary};
    background: ${p => p.theme.colors.background.active};
  }

  > svg {
    width: 14px;
    height: 14px;
  }
`;
