import { MateFragment } from '@cycle-app/graphql-codegen';
import { MemberCard, Emoji } from '@cycle-app/ui';
import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useMemberViewing, useBoard, useDoc } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';
import { MemberViewing } from 'src/types/realTime.type';
import { getBoardSlug, getDocSlug } from 'src/utils/slug.util';

type MemberProps = {
  user: MateFragment;
};

export const Member: FC<MemberProps> = ({ user }) => {
  const {
    status, viewing, id,
  } = useMemberViewing(user);
  const board = useBoard(
    viewing === MemberViewing.BOARD ? id : undefined,
    viewing !== MemberViewing.BOARD,
  );
  const { doc } = useDoc(
    viewing === MemberViewing.DOC ? id : undefined,
    viewing !== MemberViewing.DOC,
  );
  const { navigate } = useNavigate();

  const memberName = (user.firstName || user.lastName)
    ? `${user.firstName} ${user.lastName}`
    : user.email;

  return (
    <MemberCard
      key={user.id}
      email={user.email}
      mode={status}
      name={memberName}
      avatar={user.avatar?.url || undefined}
      color={user.color}
      viewing={renderViewing()}
      onClick={() => {
        if (viewing === MemberViewing.BOARD && board) {
          navigate(PageId.Board, { boardSlug: getBoardSlug(board) });
        } else if (viewing === MemberViewing.DOC && doc) {
          navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
        }
      }}
    />
  );

  function renderViewing() {
    if (status === 'pending') return 'Not signed up';
    if (status === 'offline') return 'Offline';

    if (viewing && board) {
      return (
        <p>
          Viewing
          {' '}
          {!!board?.emoji && <Emoji emoji={board.emoji} />}
          {' '}
          {board.name}
        </p>
      );
    }

    if (viewing && doc) {
      return (
        <p>
          Viewing
          {' '}
          {!!doc.doctype?.emoji && <Emoji emoji={doc.doctype.emoji} />}
          {' '}
          {doc.title}
        </p>
      );
    }

    return 'Online';
  }
};
