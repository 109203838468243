export enum Feature {
  CachePersist = 'FEATURE_CACHE_PERSIST',
  Figma = 'FEATURE_FIGMA',
  EditAttributesModal = 'FEATURE_EDIT_ATTRIBUTE_MODAL',
  BoardCreateModal = 'FEATURE_BOARD_CREATE_MODAL',
  DocViewRightPanel = 'FEATURE_DOC_VIEW_RIGHT_PANEL',
  NewOptionSlackIntegration = 'FEATURE_NEW_OPTION_SLACK_INTEGRATION',
  LightOnboarding = 'FEATURE_LIGHT_ONBOARDING',
}

/**
 * String values should correspond to unrevealed feature identifier
 *
 * See:
 * https://app.unrevealed.tech/cycle/49fa8092-f2f9-47cb-ae6a-691bf2fb32cf
 */
export enum FeatureFlag {
  Billing = 'billing',
  BillingPlan = 'billing-plan',
  FirstProductTour = 'first-product-tour',
  Inbox = 'inbox',
  InboxDescription = 'inbox-description',
  InsightDocLinkCreationFromFeedback = 'insight-doc-link-creation-from-feedback',
  InsightDocLinkTurnInto = 'insight-doc-link-turn-into',
  Status = 'status',
  EmptyStateCreateWS = 'empty-state-create-ws',
}

export enum FeatureValue {
  On = 'on',
  Off = 'off',
}
