import {
  Color,
  DocBaseFragment,
  DocChildFragment,
  DocFullFragment,
  DoctypeCategory,
  DoctypeType,
  Role,
} from '@cycle-app/graphql-codegen';

export const getEditCoverAction = (coverUrl: string | undefined): string => (coverUrl ? 'Update cover' : 'Add cover');

export const canHaveParent = (doc: Pick<DocFullFragment | DocBaseFragment, 'doctype'>) => (doc.doctype.parents?.edges ?? []).length > 0;

export const getDocKey = (productKey?: string | null, docPublicId?: string | null) => (
  productKey && docPublicId
    ? `${productKey}-${docPublicId}`
    : undefined
);

export const createDummyDoc = (doc: Partial<DocBaseFragment>): DocBaseFragment => ({
  id: '',
  isDraft: true,
  title: '',
  commentsCount: 0,
  createdAt: '',
  doctype: {
    id: '',
    emoji: 'smile',
    category: DoctypeCategory.Discovery,
    name: '',
    attributeDefinitions: { edges: [] },
    type: DoctypeType.Custom,
  },
  creator: {
    id: '',
    color: Color.A,
    email: '',
    incognito: true,
    role: Role.Admin,
  },
  childrenCount: 0,
  attributes: {
    edges: [{
      node: {
        __typename: 'DocAttributeText',
        id: '',
        definition: {
          id: '',
          name: '',
          color: Color.A,
        },
      },
    }],
  },
  ...doc,
});

export const shouldShowSource = (
  doc?: Partial<DocChildFragment>,
) => !doc?.isDraft && !!doc?.source && (doc.source.__typename !== 'SourceCycle' || !!doc?.doctype?.customer);

type MinimalDocDocType = { doctype: { type: DoctypeType } };

export const getIsAtLeastOneInsightFromDocIds = (
  docIds: string[],
  getDoc: (docId: string) => MinimalDocDocType | undefined,
): boolean => (docIds.length ? docIds.some(id => getDoc(id)?.doctype.type === DoctypeType.Insight) : false);

export const getIsAtLeastOneInsightFromDocs = (
  docs: MinimalDocDocType[],
): boolean => (docs.length ? docs.some(doc => doc.doctype.type === DoctypeType.Insight) : false);
