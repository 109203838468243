import { useProduct } from 'src/hooks/api/useProduct';
import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

interface LastViewState {
  boardId: string | null;
  boardSlug: string | null;
  productSlug: string | null;
  lastDoctypeIdsUsed: Record<string, string | null>;
  lastDocMentionDocTypeIdsUsed: Record<string, string | null>;
  settingsFromUrl: string | null;
}

export const {
  hookState: useLastView,
  hookValue: useGetLastView,

  getValue: getLastView,
  setValue: setLastView,
  resetValue: resetLastView,
} = make<LastViewState>({
  defaultState: {
    productSlug: null,
    boardId: null,
    boardSlug: null,
    lastDoctypeIdsUsed: {},
    lastDocMentionDocTypeIdsUsed: {},
    settingsFromUrl: null,
  },
  localKey: LocalKey.LastView,
});

export const getLastDoctypeIdUsed = () => {
  const {
    productSlug, lastDoctypeIdsUsed,
  } = getLastView();
  if (productSlug == null) return null;
  return lastDoctypeIdsUsed[productSlug] ?? null;
};

export const setLastDoctypeIdUsed = (doctypeId: string) => {
  const {
    productSlug, lastDoctypeIdsUsed,
  } = getLastView();
  if (productSlug == null) return;
  setLastView({
    lastDoctypeIdsUsed: {
      ...lastDoctypeIdsUsed,
      [productSlug]: doctypeId,
    },
  });
};

/**
 * Returns the last used docType id.
 * If not defined or no longer part of the product
 * returns the first docType id of the product and sets it as the last one used.
 */
export const useLastDoctypeIdUsed = () => {
  const { product } = useProduct();
  const { lastDoctypeIdsUsed } = useGetLastView();
  if (!product?.slug) return undefined;
  const lastId = lastDoctypeIdsUsed[product.slug];
  const found = lastId && product.doctypes.edges.some(edge => edge.node.id === lastId);
  if (found) return lastId;
  const newLastId = product.doctypes.edges[0].node.id;
  setLastDoctypeIdUsed(newLastId);
  return newLastId;
};

export const useLastDocMentionDocTypeIdUsed = () => {
  const {
    productSlug, lastDocMentionDocTypeIdsUsed,
  } = useGetLastView();
  if (productSlug == null) return null;
  return lastDocMentionDocTypeIdsUsed[productSlug] ?? null;
};

export const setLastDocMentionDocTypeIdUsed = (doctypeId: string) => {
  const {
    productSlug, lastDocMentionDocTypeIdsUsed,
  } = getLastView();
  if (productSlug == null) return;
  setLastView({
    lastDocMentionDocTypeIdsUsed: {
      ...lastDocMentionDocTypeIdsUsed,
      [productSlug]: doctypeId,
    },
  });
};

export const {
  hookValue: useGetLastInboxBoard,
  getValue: getLastInboxBoard,
  setValue: setLastInboxBoard,
} = make<{
  boardSlug?: string;
  boardId?: string;
}>({
  defaultState: {},
  localKey: LocalKey.LastInboxBoard,
});
