import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { caption400, caption600 } from '../../theme/typo';

export const Label = styled.div`
  margin-right: 7px;
  ${caption600};
`;

export const Then = styled.div`
  ${caption400};
`;

export const Keys = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Key = styled.div`
  ${caption600}
  border-radius: 4px;
  padding: 0 4px;

  min-width: 20px;
  height: 20px;
  text-align: center;
`;

export const ShortcutContainer = styled.div<{
  $onActive: boolean;
  $colors?: 'oposite' | 'dark';
}>`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  ${Key} {
    background: ${p => p.theme.colors.shortcut.bg};
    color: ${p => p.theme.colors.shortcut.color};
  }
  
  ${Then} {
    color: ${p => p.theme.colors.shortcut.bg};
  }

  ${p => p.$colors === 'oposite' && css`
    ${Key} {
      background: ${p.theme.colors.shortcut.oposite.bg};
      color: ${p.theme.colors.shortcut.oposite.color};
    }

    ${Then} {
      color: ${p.theme.colors.shortcut.oposite.bg};
    }
  `};

  ${p => p.$colors === 'dark' && css`
    ${Key} {
      background: ${p.theme.colors.shortcut.dark.bg};
      color: ${p.theme.colors.shortcut.dark.color};
    }

    ${Then} {
      color: ${p.theme.colors.shortcut.dark.bg};
    }
  `};

  ${p => p.$onActive && css`
    ${Key} {
      background: ${p.theme.colors.shortcut.active.bg};
      color: ${p.theme.userColors.main};
    }

    ${Then} {
      color: ${p.theme.colors.shortcut.active.bg};
    }
  `};

  ${mobile} {
    display: none !important;
  }
`;

export const ShortcutsContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;

  ${Keys} {
    margin-left: auto;
  }
`;
