import { Spinner } from '@cycle-app/ui';
import { isEnabled, Feature } from '@cycle-app/utilities';
import { useCallback, useRef, VFC } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import { DocProcessButton } from 'src/components/DocProcessButton';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { Size } from 'src/components/ErrorPage/ErrorPage.types';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';
import { useResponsive } from 'src/reactives/responsive.reactive';
import { isFeedback } from 'src/utils/docType.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import { DocPanelContent } from '../DocPanelContent/DocPanelContent';
import DocPanelHeader from '../DocPanelHeader/DocPanelHeader';
import DocPanelRightPanel from '../DocPanelRightPanel/DocPanelRightPanel';
import {
  Container,
  MainSection,
  LoaderContainer,
  StyledSidebarToggleButton,
} from './DocPanelFullPage.styles';

const isRightPanelEnabled = isEnabled(Feature.DocViewRightPanel);

interface Props extends DocPanelBaseProps {
  widthSidebar: number;
  isDocLoading: boolean;
  getDropzonePropsTopBar: () => DropzoneRootProps;
}

const DocPanelFullPage: VFC<Props> = ({
  doc,
  getDropzonePropsCover,
  isDocLoading,
  isDraggingCover,
  isUploadingCover,
  onOpenCoverInputFile,
  onTitleUpdated,
  widthSidebar,
}) => {
  const { navigate } = useNavigate();
  const [{ breakpoint }] = useResponsive();

  const containerRef = useRef<HTMLDivElement>(null);

  const onDeleteDoc = useCallback(() => {
    navigate(PageId.Main);
  }, [navigate]);

  const isMobile = breakpoint === 'mobile';

  if (isDocLoading) {
    return (
      <LoaderContainer $widthSidebar={widthSidebar}>
        <Spinner />
      </LoaderContainer>
    );
  }

  if (doc === null) {
    return (
      <Container
        ref={containerRef}
        $widthSidebar={widthSidebar}
      >
        {isMobile && (
          <StyledSidebarToggleButton />
        )}
        <ErrorPage message="Oops, this doc no longer exists" size={Size.SMALL} />
      </Container>
    );
  }

  if (!doc && !isDocLoading) {
    return (
      <Container
        ref={containerRef}
        $widthSidebar={widthSidebar}
      >
        {isMobile && (
          <StyledSidebarToggleButton />
        )}
        <ErrorPage size={Size.SMALL} />
      </Container>
    );
  }

  return (
    <Container
      ref={containerRef}
      $widthSidebar={widthSidebar}
    >
      {isMobile && (
        <StyledSidebarToggleButton />
      )}
      <MainSection>
        <DocPanelHeader
          doc={doc}
          onDeleteDoc={onDeleteDoc}
          onOpenCoverInputFile={onOpenCoverInputFile}
          {...isFeedback(doc.doctype) && doc.status && {
            processButton: (
              <DocProcessButton
                docId={doc.id}
                docStatusCategory={doc.status.category}
                parent="doc-panel"
              />
            ),
          }}
        />
        <DocPanelContent
          doc={doc}
          containerRef={containerRef}
          getDropzonePropsCover={getDropzonePropsCover}
          isDraggingCover={isDraggingCover}
          isUploadingCover={isUploadingCover}
          widthSidebar={widthSidebar}
          onTitleUpdated={onTitleUpdated}
          onOpenCoverInputFile={onOpenCoverInputFile}
        />
      </MainSection>

      {isRightPanelEnabled && (
        <DocPanelRightPanel doc={doc} />
      )}
    </Container>
  );
};

export default DocPanelFullPage;
