import { CustomerFragment } from '@cycle-app/graphql-codegen';
import {
  SelectOption, Warning, typo, CustomerAvatar, TextHighlighter, LineHover,
} from '@cycle-app/ui';
import styled from 'styled-components';

export const SelectionIcon = styled(CustomerAvatar)`
  margin-right: 10px;
`;

export const Slot = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
  display: flex;
  gap: 8px;
`;

export const Name = styled.div`
  color: ${p => p.theme.colors.text.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${typo.body500}
`;

interface CustomerOptionLabelOptions { customer: CustomerFragment; filterText: string; lineHover?: JSX.Element }

export const renderCustomerOptionLabel = ({
  customer, filterText, lineHover,
}: CustomerOptionLabelOptions) => (
  <Slot>
    {customer.name?.trim() && (
      <Name>
        <TextHighlighter
          searchWords={[filterText]}
          textToHighlight={customer.name}
          className="highlight"
        />
      </Name>
    )}
    {customer.email && (
      <TextHighlighter
        searchWords={[filterText]}
        textToHighlight={customer.email}
        className="highlight"
      />
    )}
    {lineHover && <LineHover>{lineHover}</LineHover>}
  </Slot>
);

export const getCustomerOption = (customer: CustomerFragment, showWarnings = true): SelectOption => ({
  value: customer.id,
  label: customer.displayName,
  renderLabel: (filterText) => renderCustomerOptionLabel({
    customer,
    filterText,
  }),
  icon: <SelectionIcon customer={customer} size="S" />,
  end: showWarnings && !customer._compatibleWithBoardConfig && <Warning tooltip="The doc will leave the view if you choose this value" />,
});

/**
 * A default customer is a customer from the workspace, so every user who has
 * a CycleProfile are inside the current workspace so are considered as
 * default.
 */
export const isDefaultCustomer = (customer: CustomerFragment) => !!customer.profiles?.find(profile => profile?.__typename === 'ProfileCycle');
