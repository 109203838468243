import { DoctypeTemplateCategory } from '@cycle-app/graphql-codegen';

export const TemplateCategoriesLabel: Record<DoctypeTemplateCategory, string> = {
  [DoctypeTemplateCategory.Prd]: 'Product',
  [DoctypeTemplateCategory.DesignBrief]: 'Design',
  [DoctypeTemplateCategory.ProductStrategy]: 'Strategy',
  [DoctypeTemplateCategory.Bug]: 'Bug',
  [DoctypeTemplateCategory.UserResearch]: 'User research',
  [DoctypeTemplateCategory.UserStory]: 'User story',
  [DoctypeTemplateCategory.Other]: 'Other',
};

export const TemplateCategories = Object.values(DoctypeTemplateCategory) as Array<DoctypeTemplateCategory>;
