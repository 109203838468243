import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const CurlyArrowIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M2.14524 1C0.637829 8.66258 -0.321411 14.57 5.5419 20.4334C8.34052 23.232 12.1208 23.912 15.6762 21.9368C22.1461 18.3426 12.4012 11.53 9.27266 16.5356C3.53731 25.7119 14.9515 33.8863 22.915 35.1894C25.2358 35.5691 27.8827 35.9579 30.2095 36.0803C32.2278 36.1866 29.8313 36.7167 29.0958 36.9713C27.7334 37.4429 26.6412 38.2365 25.3094 38.6975C22.979 39.5041 25.1141 38.5067 26.2003 38.1963C29.3163 37.306 32.2114 37.2468 29.2072 34.0757C28.6026 33.4376 25.7742 29.9297 25.6992 30.5677C25.4864 32.3764 24.2953 34.3884 24.1957 36.0803C24.0595 38.3964 23.2635 39.671 24.8083 36.5815C25.3123 35.5734 25.6415 34.5813 26.089 33.5746C26.8263 31.9156 26.2429 34.2731 26.089 34.4655C25.6418 35.0244 25.4314 36.5575 26.089 35.078C26.2583 34.6971 26.7015 33.069 26.7015 33.5746C26.7015 35.171 25.107 37.2716 26.2003 36.6929C27.1191 36.2065 27.2614 35.5759 27.2026 34.5769C27.1826 34.2368 26.2003 33 26.2003 33.5746" stroke="currentColor" strokeWidth="1.56609" strokeLinecap="round" />
  </Svg>
);
