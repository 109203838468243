import {
  DoctypeCategory,
  DoctypeType,
  DraftBoardConfigFragment,
  FilterPropertyRuleSelectableValueDoctypeFragment,
  PropertyValueFragment,
  ViewType,
} from '@cycle-app/graphql-codegen/generated';
import { EmojiData, Button, Emoji } from '@cycle-app/ui';
import { KanbanIcon, ListIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import BoardConfigFormDoctypes from 'src/components/BoardConfigForm/BoardConfigFormDoctypes';
import BoardConfigFormGroupby from 'src/components/BoardConfigForm/BoardConfigFormGroupby';
import BoardConfigFormSwimlanes from 'src/components/BoardConfigForm/BoardConfigFormSwimlanes/BoardConfigFormSwimlanes';
import { Header, Title, Actions } from 'src/components/DialogModal/DialogModal.styles';
import { PageId } from 'src/constants/routing.constant';
import useChangeViewType from 'src/hooks/api/mutations/boardConfig/useChangeViewtype';
import { usePublishBoardConfigMutation } from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useNavigate } from 'src/hooks/useNavigate';
import { getAttributeName } from 'src/utils/attributes.util';
import { getBoardConfigGroupBy, isFilterDoctypePropertyRule } from 'src/utils/boardConfig/boardConfig.util';
import { DEFAULT_EMOJI } from 'src/utils/emoji.util';
import { getGroupName } from 'src/utils/groups.util';
import { getBoardSlug } from 'src/utils/slug.util';

import {
  ContentForm,
  ContentPreview,
  ViewtypeButton,
  Form,
  InputStyled,
  TextAreaStyled,
  EmojiInputStyled,
  Row,
  TextLabel,
  Content,
  PreviewBox,
  GroupContent,
  GroupContainer,
  GroupList,
  GroupStyled,
  SwimlaneContainer,
  SwimlaneContent,
} from './CreateDraftBoardModal.styles';
import { PreviewCard } from './PreviewCard';

interface Props {
  draftBoardConfig: DraftBoardConfigFragment;
  draftBoardId: string;
  onHide: VoidFunction;
}

interface FormData {
  name: string;
  description: string;
  emoji: string;
}

const MAX_ITEMS = 2;

export const CreateDraftBoardModalContent: FC<Props> = ({
  draftBoardId, draftBoardConfig, onHide,
}) => {
  const {
    control,
    formState,
    handleSubmit,
    register,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      emoji: DEFAULT_EMOJI,
    },
  });
  const { navigate } = useNavigate();
  const {
    id: draftBoardConfigId, viewType, docQuery, groupableProperties, availableSwimlaneByDoctypes, filterProperties,
  } = draftBoardConfig;

  const { updateViewType } = useChangeViewType(draftBoardConfigId, draftBoardId);
  const {
    loading: isUpdateLoading,
    publishBoard,
    removeBoard,
    updateBoard,
  } = useBoardMutations();
  const [publishBoardConfig, { loading: isPublishLoading }] = usePublishBoardConfigMutation(draftBoardConfigId);

  const doctypesFilter = useMemo(() => filterProperties.edges
    .find(isFilterDoctypePropertyRule)?.node, [filterProperties.edges]);

  const doctypesFilterArray = useMemo(() => nodeToArray(doctypesFilter?.doctypeRule.values), [doctypesFilter]);
  const doctypesFilterSelected = useMemo(() => doctypesFilterArray
    .filter(doctypeFilter => doctypeFilter.selected), [doctypesFilterArray]);
  const doctypesFilterArrayAllSelected =
    doctypesFilterArray.every(doctypeFilter => !doctypeFilter.selected) ||
    doctypesFilterArray.every(doctypeFilter => doctypeFilter.selected);
  const doctypes = useMemo(() => (doctypesFilterArrayAllSelected ? doctypesFilterArray : doctypesFilterSelected),
    [doctypesFilterArray, doctypesFilterArrayAllSelected, doctypesFilterSelected]);

  const isCreateLoading = isUpdateLoading || isPublishLoading;
  const isGrouped = (docQuery.__typename === 'BoardQueryWithGroupBy' || docQuery.__typename === 'BoardQueryWithSwimlaneBy');
  const isKanban = viewType === ViewType.Kanban;
  const isList = viewType === ViewType.List;
  const isSwimlaneBy = isKanban && docQuery.__typename === 'BoardQueryWithSwimlaneBy';
  const attributeName = isGrouped ? getAttributeName(docQuery.groupbyConfig.property) : null;

  const groupConfigArray = useMemo(() => ((
    docQuery.__typename === 'BoardQueryWithGroupBy' ||
    docQuery.__typename === 'BoardQueryWithSwimlaneBy') &&
    nodeToArray(docQuery.groupbyConfig.values)) || [], [docQuery]);

  const cards = useMemo(() => {
    const cardsList = doctypes.slice(0, MAX_ITEMS);
    // Edge case where the product do not have any doctypes.
    if (!cardsList.length) {
      return [createFakeCard({
        id: 'card-1',
        emoji: 'cherry_blossom',
      }), createFakeCard({
        id: 'card-2',
        emoji: 'rainbow',
      })];
    }
    // UX: we should always display at least two cards.
    if (cardsList.length <= 1) {
      return [
        ...cardsList,
        createFakeCard({
          id: 'clone',
        }, cardsList[0]),
      ];
    }
    return cardsList;
  }, [doctypes]);

  const groups = useMemo(() => {
    const groupList = groupConfigArray.filter((group) => group.propertyValue && getGroupName(group, attributeName));
    const groupListOrdered = (
      groupList.every(group => group.propertyValue?.__typename === 'Doctype')
        // UX: make sure doctypes and properties are in the same order.
        ? cards.map(card => (doctypesFilterArrayAllSelected || card.selected) && groupList.find(group => group.propertyValue?.id === card.value.id))
        : groupList)
      .filter(Boolean)
      .slice(0, MAX_ITEMS);

    if (isGrouped && !groupListOrdered.length) {
      // Edge case where the product do not have any groupable values.
      return [createFakeGroup({
        id: 'group-1',
        text: '🎨 In design',
      }), createFakeGroup({
        id: 'group-2',
        text: '✅ Done',
      })];
    }
    return groupListOrdered;
  }, [cards, groupConfigArray, isGrouped, doctypesFilterArrayAllSelected, attributeName]);

  const groupByConfig = getBoardConfigGroupBy(draftBoardConfig);

  return (
    <>
      <Content>
        <ContentForm>
          <Header>
            <Title>Create new view</Title>
          </Header>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <Row>
              <Controller
                name="emoji"
                control={control}
                render={({
                  field: {
                    value,
                    onChange,
                  },
                }) => (
                  <div>
                    <TextLabel>Icon</TextLabel>
                    <EmojiInputStyled
                      emoji={value}
                      onSelect={({ id }: EmojiData) => onChange(id)}
                    />
                  </div>
                )}
              />
              <InputStyled
                id="boardEdit-name"
                label="View name"
                placeholder="Your view name"
                autoFocus
                {...register('name', {
                  required: 'You must have at least one character.',
                })}
                error={formState.errors.name?.message}
              />
            </Row>
            <div>
              <TextLabel>Description</TextLabel>
              <TextAreaStyled
                id="boardEdit-description"
                placeholder="Your view description"
                {...register('description')}
              />
            </div>
            <div>
              <BoardConfigFormDoctypes
                boardID={draftBoardId}
                draftBoardConfigId={draftBoardConfigId}
                doctypesFilter={doctypesFilter}
                label="Doc types"
              />
            </div>
            <div>
              <TextLabel>Visualization</TextLabel>
              <Row>
                <ViewtypeButton
                  active={isKanban}
                  onClick={() => isList && updateViewType(ViewType.Kanban)}
                >
                  <KanbanIcon />
                  Kanban
                </ViewtypeButton>
                <ViewtypeButton
                  active={isList}
                  onClick={() => isKanban && updateViewType(ViewType.List)}
                >
                  <ListIcon />
                  List
                </ViewtypeButton>
              </Row>
            </div>
            <div>
              {groupableProperties && (
                <BoardConfigFormGroupby
                  boardID={draftBoardId}
                  boardConfigId={draftBoardConfigId}
                  groupableProperties={groupableProperties}
                  groupByConfig={groupByConfig}
                  hideHiddenGroups
                  label="Group by"
                />
              )}
            </div>
            <div>
              {isKanban && (
                <BoardConfigFormSwimlanes
                  boardID={draftBoardId}
                  boardConfigId={draftBoardConfigId}
                  availableSwimlaneByDoctypes={availableSwimlaneByDoctypes}
                  groupByConfig={groupByConfig}
                  swimlaneByConfig={docQuery.__typename === 'BoardQueryWithSwimlaneBy'
                    ? docQuery.swimlanebyConfig
                    : null}
                />
              )}
            </div>
            <Actions>
              <Button
                size="M"
                variant="secondary"
                onClick={handleHide}
                disabled={isCreateLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="M"
                disabled={!!Object.keys(formState.errors).length}
                isLoading={isCreateLoading}
              >
                Save
              </Button>
            </Actions>
          </Form>
        </ContentForm>
      </Content>
      <ContentPreview>
        <PreviewBox>
          {renderGroupList({ hasSwimlaneAfter: isSwimlaneBy })}
          {isSwimlaneBy && (
            <>
              {docQuery.__typename === 'BoardQueryWithSwimlaneBy' && (
                <SwimlaneContainer>
                  <SwimlaneContent>
                    {docQuery.swimlanebyConfig.doctype?.emoji && (
                      <Emoji emoji={docQuery.swimlanebyConfig.doctype.emoji} />
                    )}
                    <span>
                      {docQuery.swimlanebyConfig.doctype?.name}
                    </span>
                  </SwimlaneContent>
                </SwimlaneContainer>
              )}
              {renderGroupList({ hasSwimlaneBefore: true })}
            </>
          )}
        </PreviewBox>
      </ContentPreview>
    </>
  );

  function renderGroupList({
    hasSwimlaneAfter, hasSwimlaneBefore,
  }: { hasSwimlaneAfter?: boolean; hasSwimlaneBefore?: boolean }) {
    return viewType && (
      <GroupList $isRow={isKanban}>
        {isGrouped
          ? groups
            .map(group => group && (
              <GroupContainer key={group.id} $isRow={isKanban}>
                <GroupStyled
                  $hasSpaceAfter={hasSwimlaneAfter}
                  $hasSpaceBefore={hasSwimlaneBefore}
                  inputName={!hasSwimlaneBefore && <h2>{getGroupName(group, attributeName)}</h2>}
                  viewType={viewType}
                  withGroupBy
                >
                  <GroupContent>
                    {cards.slice(hasSwimlaneBefore ? 1 : 0, hasSwimlaneAfter ? 1 : MAX_ITEMS).map(doc => (
                      <PreviewCard emoji={doc.value.emoji} key={doc.id} viewType={viewType} />
                    ))}
                  </GroupContent>
                </GroupStyled>
              </GroupContainer>
            ))
          : (
            <GroupContainer $isRow={isKanban}>
              <GroupStyled viewType={viewType}>
                <GroupContent>
                  {cards.map(doc => (
                    <PreviewCard key={doc.id} emoji={doc.value.emoji} viewType={viewType} />
                  ))}
                </GroupContent>
              </GroupStyled>
            </GroupContainer>
          )}
      </GroupList>
    );
  }

  async function handleHide() {
    if (isCreateLoading) return;
    onHide();
    await removeBoard(draftBoardId);
  }

  async function handleFormSubmit(formData: FormData) {
    if (isCreateLoading) return;
    const [updateBoardResult, publishBoardResult] = await Promise.all([
      updateBoard({
        boardId: draftBoardId,
        ...formData,
      }),
      publishBoard({ boardId: draftBoardId }),
    ]);
    if (updateBoardResult.data?.updateBoard && publishBoardResult.data?.publishBoard) {
      // Publish config works only on published board.
      const publishBoardConfigResult = await publishBoardConfig();
      if (publishBoardConfigResult.data?.publishBoardConfigV2) {
        onHide();
        navigate(PageId.Board, { boardSlug: getBoardSlug(updateBoardResult.data.updateBoard) });
      }
    }
  }
};

function createFakeGroup({
  id, text,
}: { id: string; text: string }): { id: string; name: string; propertyValue: PropertyValueFragment } {
  return {
    id,
    name: '',
    propertyValue: {
      __typename: 'AttributeTextValue',
      id: '',
      text,
    },
  };
}

function createFakeCard({
  id, emoji,
}: { id: string; emoji?: string }, fromCard?: FilterPropertyRuleSelectableValueDoctypeFragment): FilterPropertyRuleSelectableValueDoctypeFragment {
  return (fromCard ? {
    ...fromCard,
    selected: false,
    id: `${fromCard.id}-${id}`,
  } : {
    id,
    selected: true,
    value: {
      id: '',
      emoji: emoji ?? 'cherry_blossom',
      name: '',
      category: DoctypeCategory.Discovery,
      type: DoctypeType.Custom,
    },
  });
}
