import { typo, ActionButton } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ReadOnlyEditor } from '../../Editor';
import { Container, Content } from '../StepEditDocs/StepEditDocs.styles';

export const Layer = styled(motion.div)`
  background-color: grey;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: ${(p) => p.theme.colors.background.hoverDark};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 38px;
`;

export const StyledCloseButton = styled(ActionButton)`
  height: 24px;
  width: 24px;
`;

export const StyledContainer = styled(Container)`
  top: 16px;
  right: 16px;
  bottom: 16px;
  left: 16px;
  padding: 12px;
`;

export const Title = styled.h1`
  margin: 16px 0 20px;
  outline: none;
  width: 100%;
  ${typo.headerLarge};
`;

export const StyledReadOnlyEditor = styled(ReadOnlyEditor)`
  height: auto;
  pointer-events: all;
`;

export const StyledContent = styled(Content)`
  padding-left: 20px;
  padding-right: 20px;
  height: calc(100% - 60px);
`;
