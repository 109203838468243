import { boxShadowZ1, ActionButton, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import LinkDraggable from 'src/components/LinkDraggable/LinkDraggable';

export const swimlaneDocCSS = css`
  background-color: ${p => p.theme.colors.card.bg};
  border-radius: 8px 8px 0px 0px;
  ${boxShadowZ1};

  position: sticky;
  z-index: 2;
  left: 0;
  top: 56px;
  width: 100%;

  display: flex;
  
  span.content, a {
    display: flex;
    align-items: center;

    gap: 8px;
    padding: 8px 12px;
    width: 100%;
  }
`;

export const IndicatorBtn = styled(ActionButton) <{ $collapsed: boolean }>`
  padding: 0;
  color: ${p => p.theme.colors.text.disabled};
  svg {
    width: 16px;
    height: 16px;
    transition: transform 100ms linear;

    ${p => p.$collapsed && css`
      transform: rotate(-90deg);
    `};
  }
`;

export const Menu = styled(DotsMenuLayer)`
  margin-left: auto;
`;

export const StyledLinkDraggable = styled(LinkDraggable) <{ $isMenuVisible: boolean }>`
  overflow: hidden;
  align-items: center;
  width: 100%;

  ${Menu} {
    opacity: ${p => (p.$isMenuVisible ? 1 : 0)}
  }
  &:hover {
    ${Menu} {
      opacity: 1;
    }
  }
`;

export const Container = styled.div<{ asPlaceholder?: boolean }>`
  ${swimlaneDocCSS};

  ${p => p.asPlaceholder && css`
    background-color: ${p.theme.userColors.bg};

    ${SwimlaneGroupName} {
      opacity: 0;
    }
    ${Menu} {
      opacity: 0;
    }
    ${IndicatorBtn} {
      opacity: 0;
    }
  `};
`;

export const SwimlaneDocCreating = styled.div`
  ${swimlaneDocCSS};
  justify-content: center;
`;

export const SwimlaneGroupName = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  overflow: hidden;
`;

export const DocTitle = styled.div`
  margin-bottom: 0;
  ${typo.body500};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
