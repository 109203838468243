import { make } from 'src/utils/reactives.util';

interface DocItem {
  hoverDocId: string | null;
  disableHover: boolean;
  unfocusCurrentDoc: VoidFunction;
}

export const {
  getValue: getDocItem,
  setValue: setDocItem,
  hookValue: useGetDocItem,
  resetValue: resetDocItem,
} = make<DocItem>({
  defaultState: {
    hoverDocId: null,
    disableHover: false,
    unfocusCurrentDoc: () => {
      // Intentional empty function
    },
  },
});
