import { useMemo } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useIsWelcomePage } from 'src/hooks/useIsWelcomePage';
import { useNavigate } from 'src/hooks/useNavigate';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { Layer } from 'src/types/layers.types';
import { Shortcut } from 'src/types/shortcuts.types';

import { getBoardSlug } from '../../utils/slug.util';
import { useOnboardingPreviewBoard } from '../useOnboardingPreviewBoard';

export const useGlobalShortcutListener = () => {
  const isWelcomePage = useIsWelcomePage();
  const callbacks = useGlobalShortcutCallbacks();

  useHotkeyListener({
    callbacks,
    shortcuts: Object.values(Shortcut),
    enabled: !isWelcomePage,
    disableOnLayers: [Layer.Dropdown, Layer.Modal],
  });
};

export const useGlobalShortcutCallbacks = () => {
  const { navigate } = useNavigate();
  const boardInbox = useOnboardingPreviewBoard({ name: 'inbox' });
  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);

  return useMemo(() => ({
    // Navigation
    [Shortcut.GoToSettings]: () => {
      if (getActiveProductTour()) return;

      setCommandbar({
        visible: true,
        section: 'go-to-settings',
      });
    },
    [Shortcut.GoToHome]: () => {
      if (getActiveProductTour()) return;

      navigate(PageId.Main);
    },
    [Shortcut.GoToInbox]: () => {
      if (getActiveProductTour()) return;

      if (isInboxEnabled) navigate(PageId.Inbox);
      else if (boardInbox) navigate(PageId.Board, { boardSlug: getBoardSlug(boardInbox) });
    },

    // CommandBar
    [Shortcut.SearchDoc]: () => {
      if (getActiveProductTour()) return;

      setCommandbar({
        visible: true,
        section: 'search-doc',
      });
    },
    [Shortcut.GoToBoard]: () => {
      if (getActiveProductTour()) return;

      setCommandbar({
        visible: true,
        section: 'go-to-board',
      });
    },
    [Shortcut.SwitchTheme]: () => {
      if (getActiveProductTour()) return;

      setCommandbar({
        visible: true,
        section: 'switch-theme',
      });
    },
    [Shortcut.GoToWorkspace]: () => {
      if (getActiveProductTour()) return;

      setCommandbar({
        visible: true,
        section: 'workspace',
      });
    },
  }), [navigate, isInboxEnabled, boardInbox]);
};
