import { Node, mergeAttributes } from '@tiptap/core';

const extensionName = 'file';

const fileExtension = Node.create({
  name: extensionName,
  draggable: true,
  group: 'block',

  atom: false,

  addAttributes: () => ({
    file: {
      default: null,
      rendered: false,
    },
  }),

  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'react-component',
      mergeAttributes(HTMLAttributes),
    ];
  },
});

export default fileExtension;
