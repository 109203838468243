import { useQuery } from '@apollo/client';
import {
  Maybe,
  BoardNodeDocument,
  BoardWithMinimalConfigFragment,
  BoardWithConfigQueryVariables,
  BoardWithFullConfigFragment,
  BoardWithConfigDocument,
  BoardConfigQueryVariables,
} from '@cycle-app/graphql-codegen';
import { isEmpty } from 'ramda';

import { useAppParams } from 'src/hooks/useAppParams';
import { defaultPagination } from 'src/utils/pagination.util';

import { useIsCurrentBoardPrivate } from '../boards/useIsCurrentBoardPrivate';
import { useIsAllDocsPage } from '../useIsAllDocsPage';
import { useLazyQueryAsync } from '../useLazyQueryAsync';
import { useProduct } from './useProduct';

export const useBoard = (boardId?: string | null, skip = false): Maybe<BoardWithMinimalConfigFragment> => {
  const { boardId: boardIdParams } = useAppParams();
  const { product } = useProduct();
  const isPrivateBoard = useIsCurrentBoardPrivate();
  const isAllDocs = useIsAllDocsPage();
  const allDocsId = isAllDocs
    ? product?.allDocs.id
    : null;

  const boardIdToFetch = boardId || boardIdParams || allDocsId;

  const { data } = useQuery<{ node: BoardWithMinimalConfigFragment }, BoardWithConfigQueryVariables>(BoardNodeDocument, {
    fetchPolicy: 'cache-first',
    skip: skip || !boardIdToFetch,
    variables: {
      id: boardIdToFetch as string,
      ...defaultPagination,
    },
  });

  const board = data && !isEmpty(data?.node)
    ? data.node
    : null;

  return board ? {
    ...board,
    publishedBoardConfig: (
      isPrivateBoard
        ? board.savedBoardConfig
        : null
    ) ?? board.publishedBoardConfig,
  } : null;
};

export const useBoardPrefetch = () => {
  const prefetchQuery = useLazyQueryAsync<{ node: BoardWithFullConfigFragment }, BoardConfigQueryVariables>(BoardWithConfigDocument);

  return (boardId: string) => prefetchQuery({
    id: boardId,
    ...defaultPagination,
  });
};
