import { namedOperations, PublishDocDocument, UpdateDocContentDocument } from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { useGetDocFromCache } from 'src/hooks/api/cache/cacheDoc';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useNavigate } from 'src/hooks/useNavigate';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useToaster } from 'src/hooks/useToaster';
import { getCreateDoc, setCreateDoc } from 'src/reactives/createDoc.reactive';
import { ShortcutCreateModal } from 'src/types/shortcuts.types';
import { getDocKey } from 'src/utils/doc.util';
import { getDocSlug } from 'src/utils/slug.util';

import { useCustomerDocFromCache } from '../api/cache/cacheCustomerDoc';
import { usePageId } from '../usePageId';

export const useCreateModalShortcutCallbacks = () => {
  const product = useProductBase();
  const { add: addToaster } = useToaster();
  const page = usePageId();
  const { addCustomerDoc } = useCustomerDocFromCache();
  const {
    navigate, getUrl,
  } = useNavigate();
  const [publishDoc, { loading }] = useSafeMutation(PublishDocDocument);
  const [updateDocContent] = useSafeMutation(UpdateDocContentDocument);
  const getDoc = useGetDocFromCache();

  const saveDoc = useCallback(async () => {
    const { docId } = getCreateDoc();
    if (docId) {
      const { data } = await publishDoc({
        variables: { docId },
        refetchQueries: () => (page !== PageId.DocFullPage ? [namedOperations.Query.boardWithConfig] : []),
      });
      if (docId === getCreateDoc().docId) {
        setCreateDoc({
          modalVisible: false,
          docId: null,
        });
      } else {
        setCreateDoc({
          docId: null,
        });
      }

      if (data?.publishDoc) {
        const docUrl = getUrl(PageId.DocFullPage, {
          docSlug: getDocSlug(data.publishDoc),
        });

        addToaster({
          title: 'Successfully created',
          message: (
            <>
              <Link to={docUrl}>
                {getDocKey(product?.key, data?.publishDoc?.publicId)}
              </Link>
              {' '}
              was successfully created
            </>
          ),
        });
      }
    }
  }, [publishDoc, addToaster, getUrl, product?.key]);

  const saveAndOpenDoc = useCallback(async (docContentJson) => {
    const { docId } = getCreateDoc();
    if (!docId) return;

    const doc = getDoc(docId);
    if (!doc) return;
    if (docContentJson) {
      await updateDocContent({
        variables: {
          docId: doc.id,
          contentJSON: docContentJson.current.contentJSON || JSON.stringify({ type: 'doc' }),
        },
      });
    }

    if (doc.customer) {
      addCustomerDoc({
        doc: {
          ...doc,
          isDraft: false,
        },
      });
    }

    await saveDoc();
    navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
  }, [getDoc, navigate, saveDoc]);

  return useMemo(() => ({
    [ShortcutCreateModal.Save]: saveDoc,
    [ShortcutCreateModal.SaveAndOpen]: saveAndOpenDoc,
    loading,
  }), [saveDoc, saveAndOpenDoc, loading]);
};
