import { ReactNode } from 'react';

import { Tooltip } from '../Tooltip/Tooltip';
import {
  CardContainer,
  CardBody,
  IntegrationTitle,
  IntegrationName,
  Logos,
  Logo,
  CardSide,
  WorkspaceLogo,
  WorkspaceName,
  Information,
} from './IntegrationCard.styles';

export interface IntegrationCardProps {
  title?: string;
  description?: string;
  logo?: ReactNode;
  logos?: Record<string, ReactNode>;
  information?: ReactNode;
  workspaceName?: string;
  workspaceLogoUrl?: string;
  actions?: ReactNode;
  slot?: ReactNode;
}

export const IntegrationCard = ({
  title,
  description,
  logo,
  logos,
  information,
  workspaceLogoUrl,
  workspaceName,
  actions,
  slot,
}: IntegrationCardProps) => (
  <CardContainer>
    <CardBody>
      <IntegrationTitle title={description}>
        <Logos>
          {logo && <Logo>{logo}</Logo>}
          {logos && Object.entries(logos).map(([key, value]) => (
            <Tooltip
              key={key}
              content={key}
              placement="top"
            >
              <Logo>{value}</Logo>
            </Tooltip>
          ))}
        </Logos>
        {title && <IntegrationName>{title}</IntegrationName>}
      </IntegrationTitle>

      <CardSide>
        {information && <Information>{information}</Information>}
        {workspaceLogoUrl && <WorkspaceLogo $src={workspaceLogoUrl} />}
        {workspaceName && <WorkspaceName>{workspaceName}</WorkspaceName>}
        {actions}
      </CardSide>
    </CardBody>

    {slot}
  </CardContainer>
);
