import styled from 'styled-components';

export const Container = styled.div<{ $visible: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: ${p => (p.$visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;

  svg {
    height: 1em;
    width: 1em;
  }
`;
