import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ALL_DOCS_BOARD_SLUG, PageId } from 'src/constants/routing.constant';
import { useParentPage } from 'src/hooks/usePageId';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { RouteParams } from 'src/types/routes.types';
import { getDocSlug } from 'src/utils/slug.util';

import { useLocation } from './router/useLocation';
import { useAppParams } from './useAppParams';
import { useUrl } from './useUrl';

export const useNavigate = () => {
  const history = useHistory();
  const getUrl = useUrl();
  const { productSlug } = useAppParams();
  const { canReadPlayground } = useGetPermission();
  const parentPageId = useParentPage();

  const location = useLocation();
  const fromStarredBoard = location.state?.fromStarredBoard;

  const getAuthorizedUrl = useCallback((pageId: PageId, params?: RouteParams) => {
    if (
      productSlug && // Make sure we are in the app.
      pageId === PageId.Board &&
      params?.boardSlug === ALL_DOCS_BOARD_SLUG &&
      !canReadPlayground
    ) {
      return getUrl(PageId.Main);
    }
    return getUrl(pageId, params);
  }, [canReadPlayground, getUrl, productSlug]);

  const navigate = useCallback(
    (
      pageId: PageId,
      params?: RouteParams,
      state?: Record<string, unknown>,
      metaKey?: boolean,
    ) => {
      const newState = fromStarredBoard ? ({
        fromStarredBoard,
        ...state,
      }) : state;

      if (metaKey) {
        window.open(getAuthorizedUrl(pageId, params), '_blank');
      } else {
        history.push(getAuthorizedUrl(pageId, params), newState);
      }
    },
    [fromStarredBoard, getAuthorizedUrl, history],
  );

  const navigateToDocFullPage = useCallback(
    (
      doc: { title: string; id: string },
      state?: Record<string, unknown>,
      metaKey?: boolean,
    ) => navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) }, state, metaKey),
    [navigate],
  );

  const navigateToDocPanelPage = useCallback(
    (
      doc: { title: string; id: string },
      state?: Record<string, unknown>,
      metaKey?: boolean,
    ) => {
      const pageIds: Partial<Record<typeof parentPageId, PageId>> = {
        inbox: PageId.InboxDoc,
        home: PageId.HomeDoc,
      };

      const newState = fromStarredBoard ? ({
        fromStarredBoard,
        ...state,
      }) : state;

      navigate(pageIds[parentPageId] || PageId.Doc, { docSlug: getDocSlug(doc) }, newState, metaKey);
    },
    [fromStarredBoard, navigate, parentPageId],
  );

  const navigateToDocPanelParent = useCallback(() => {
    switch (parentPageId) {
      case 'inbox': {
        navigate(PageId.InboxView);
        return;
      }
      case 'home': {
        navigate(PageId.Main);
        return;
      }
      default:
        navigate(PageId.Board);
    }
  }, [navigate, parentPageId]);

  const navigateToCustomer = useCallback(
    (
      customerId: string,
      state?: Record<string, unknown>,
      metaKey?: boolean,
    ) => {
      navigate(PageId.SettingsCustomer, { customerId }, state, metaKey);
    },
    [navigate],
  );

  const navigateToCompany = useCallback(
    (
      companyId: string,
      state?: Record<string, unknown>,
      metaKey?: boolean,
    ) => {
      navigate(PageId.SettingsCompany, { companyId }, state, metaKey);
    },
    [navigate],
  );

  const navigateToCustomers = useCallback(
    (state?: Record<string, unknown>, metaKey?: boolean) => {
      navigate(PageId.SettingsCustomers, {}, state, metaKey);
    },
    [navigate],
  );

  const navigateToCompanies = useCallback(
    (state?: Record<string, unknown>, metaKey?: boolean) => {
      navigate(PageId.SettingsCompanies, {}, state, metaKey);
    },
    [navigate],
  );

  const navigateToInbox = useCallback(
    (
      boardSlug?: string,
      state?: Record<string, unknown>,
      metaKey?: boolean,
    ) => {
      if (boardSlug) {
        navigate(PageId.InboxView, { boardSlug }, state, metaKey);
      } else {
        navigate(PageId.Inbox, {}, state, metaKey);
      }
    },
    [navigate],
  );

  return {
    getUrl: getAuthorizedUrl,
    navigate,
    navigateToDocFullPage,
    navigateToDocPanelPage,
    navigateToDocPanelParent,
    navigateToCustomer,
    navigateToCustomers,
    navigateToCompany,
    navigateToCompanies,
    navigateToInbox,
  };
};
