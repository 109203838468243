/* eslint-disable jsx-a11y/label-has-associated-control */
import BoardConfigFormFilters from 'src/components/BoardConfigForm//BoardConfigFormFilters';
import BoardConfigFormDoctypes from 'src/components/BoardConfigForm/BoardConfigFormDoctypes';
import BoardConfigFormProperties from 'src/components/BoardConfigForm/BoardConfigFormProperties';
import { InboxGroupByStatus } from 'src/components/InboxGroupByStatus';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { findFilterDoctypePropertyRule } from 'src/utils/boardConfig/boardConfig.util';

export type InboxBoardConfigFieldsProps = {
  boardId: string;
};

export const InboxBoardConfigFields = ({ boardId }: InboxBoardConfigFieldsProps) => {
  const { data } = useDraftBoardConfig(boardId);
  const draftBoardConfig = data?.node?.draftBoardConfig;
  if (!draftBoardConfig) return null;
  return (
    <>
      <div>
        <label>Doc type</label>
        <BoardConfigFormDoctypes
          draftBoardConfigId={draftBoardConfig.id}
          doctypesFilter={findFilterDoctypePropertyRule(draftBoardConfig)}
          boardID={boardId}
          disabled
        />
      </div>

      <div>
        <label>Filters</label>
        <BoardConfigFormFilters
          boardConfigId={draftBoardConfig.id}
          filterProperties={draftBoardConfig.filterProperties}
          filterableProperties={draftBoardConfig.filterableProperties}
          boardID={boardId}
        />
      </div>

      <div>
        <label>Displayed properties</label>
        <BoardConfigFormProperties
          boardConfigId={draftBoardConfig.id}
          viewType={draftBoardConfig.viewType}
          properties={draftBoardConfig.properties}
          boardID={boardId}
        />
      </div>

      <div>
        <label>Group by status</label>
        <InboxGroupByStatus
          boardId={boardId}
          draftBoardConfig={draftBoardConfig}
        />
      </div>
    </>
  );
};
