import { slashActions } from '@cycle-app/front/src/services/editor/editorActions';
import Placeholder from '@tiptap/extension-placeholder';

const toCheck = slashActions.map(c => c.actions).flat();

interface Params {
  defaultPlaceholder: string;
  emptyPlaceholder?: string;
}
export function getPlaceholderExtension({
  defaultPlaceholder,
  emptyPlaceholder = defaultPlaceholder,
}: Params) {
  return Placeholder.configure({
    placeholder: ({ editor }) => {
      const activeNodes = toCheck
        .filter((action) => {
          if (!action.node) return false;
          return editor.isActive(action.node, action.nodeParams);
        });

      if (!activeNodes.length) {
        return editor.isEmpty
          ? emptyPlaceholder
          : defaultPlaceholder;
      }

      return activeNodes[0].label;
    },
  });
}
