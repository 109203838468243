import { Input, Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import {
  PortalModalStyled,
  Header,
  Title,
  CloseButtonStyled,
  Body,
  Actions,
} from 'src/components/DialogModal/DialogModal.styles';
import { useBillingDowngrade } from 'src/hooks/billing';
import { useToaster } from 'src/hooks/useToaster';

import { SubmitForm, SubmitFormFooter } from './BillingDowngrade.styles';

interface Props {
  onClose: VoidFunction;
}

interface FormData {
  reason: string;
}

export const BillingDowngradeModalSubmit: FC<Props> = ({ onClose }) => {
  const {
    downgrade, isLoading,
  } = useBillingDowngrade();
  const { add: addToaster } = useToaster();
  const {
    register, handleSubmit, formState,
  } = useForm<FormData>({
    defaultValues: {
      reason: '',
    },
  });
  return (
    <PortalModalStyled hide={onClose}>
      <Header>
        <Title>
          Would you mind telling us why?
        </Title>
        <CloseButtonStyled onClick={onClose}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      <Body>
        <p>We might be able to help if you&apos;re considering switching to a smaller plan.</p>
        <SubmitForm onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Why are you downgrading?"
            placeholder="Tell us why..."
            error={formState.errors.reason?.message}
            {...register('reason', { required: 'This field is required' })}
          />
          <SubmitFormFooter>
            <Actions>
              <Button
                onClick={onClose}
                variant="secondary"
                size="M"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="M"
                isLoading={isLoading}
              >
                Submit
              </Button>
            </Actions>
          </SubmitFormFooter>
        </SubmitForm>
      </Body>
    </PortalModalStyled>
  );

  async function onSubmit(data: FormData) {
    const result = await downgrade({
      reason: data.reason,
    });
    if (result?.data?.downgradeToFreePlan) {
      onClose();
      addToaster({
        title: 'Successfully downgraded',
        message: 'Your plan was successfully downgraded',
      });
    }
  }
};
