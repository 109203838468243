import { Color } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import React from 'react';

import { useIsFreePlan, useIsStandardPlan } from 'src/hooks';

import {
  BodyTd,
  BodyCell,
  Container,
  FeatureGroup,
  FeatureValue,
  FeatureTitle,
  FeatureHelper,
  FeatureStatusTag,
  Intro,
  StyledHeader,
  StyledTable,
  StyledCheckIcon,
  StyledInfoIconOutline,
} from './BillingPlan.styles';
import { BillingPlanHeader } from './BillingPlanHeader';
import { features, Status } from './features';

export const BillingPlans = () => {
  const isFreePlan = useIsFreePlan();
  const isStandardPlan = useIsStandardPlan();
  return (
    <div>
      <StyledHeader>
        <h1>Plans</h1>
        <Intro>
          {`This workspace is in ${getPlanTitle()}`}
        </Intro>
      </StyledHeader>
      <Container>
        <StyledTable>
          <BillingPlanHeader />
          <tbody>
            {features.map(group => (
              <React.Fragment key={group.name}>
                <tr>
                  <BodyTd colSpan={5}>
                    <BodyCell $isGroup>
                      <FeatureGroup>{group.name}</FeatureGroup>
                    </BodyCell>
                  </BodyTd>
                </tr>
                {group.features.map(feature => (
                  <tr key={feature.name}>
                    <BodyTd>
                      <BodyCell>
                        <FeatureTitle>
                          {feature.name}
                          {feature.tooltip && (
                            <Tooltip displayFullTitle placement="top" content={feature.tooltip}>
                              <StyledInfoIconOutline />
                            </Tooltip>
                          )}
                          {feature?.status === Status.soon && (
                            <FeatureStatusTag>{Status.soon}</FeatureStatusTag>
                          )}
                          {feature?.status === Status.beta && (
                            <FeatureStatusTag color={Color.I}>{Status.beta}</FeatureStatusTag>
                          )}
                          {feature?.status === Status.new && (
                            <FeatureStatusTag color={Color.A}>{Status.new}</FeatureStatusTag>
                          )}
                        </FeatureTitle>
                      </BodyCell>
                    </BodyTd>
                    {feature.plans.map((plan, i, arr) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <BodyTd key={i} {...i === arr.length - 1 && { colSpan: 2 }}>
                        <BodyCell>
                          <FeatureValue>
                            {typeof plan?.value === 'boolean' ? <StyledCheckIcon /> : plan?.value}
                          </FeatureValue>
                        </BodyCell>
                      </BodyTd>
                    ))}
                  </tr>
                ))}
                {group.helper && (
                  <tr>
                    <BodyTd colSpan={5}>
                      <BodyCell>
                        <FeatureHelper>{group.helper}</FeatureHelper>
                      </BodyCell>
                    </BodyTd>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>
      </Container>
    </div>
  );

  function getPlanTitle() {
    if (isFreePlan) {
      return 'Starter plan';
    }
    if (isStandardPlan) {
      return 'Pro plan';
    }
    return 'Business+ plan';
  }
};
