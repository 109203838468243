import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { APP_NAME, PAGE_TITLE_SEPARATOR } from 'src/constants/app.constants';
import { PageId } from 'src/constants/routing.constant';
import { useBoard } from 'src/hooks/api/useBoard';
import { useDoc } from 'src/hooks/api/useDoc';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useIsBuiltinBoard } from 'src/hooks/api/useIsBuiltinBoard';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useIsAllDocsPage } from 'src/hooks/useIsAllDocsPage';
import { usePageId, useParentPage } from 'src/hooks/usePageId';
import { getNativeEmoji } from 'src/utils/emoji.util';

const PageTitle: FC = () => {
  const pageId = usePageId();
  const { doc } = useDoc();
  const board = useBoard();
  const product = useProductBase();
  const doctype = useDoctype();

  const parentPage = useParentPage();
  const isAllDocsPage = useIsAllDocsPage();
  const isBuiltinBoard = useIsBuiltinBoard();

  const emoji = board && !isBuiltinBoard(board?.id) ? `${getNativeEmoji(board.emoji)} ` : '';

  const boardTitle = [
    ...(doc ? [doc.title] : []),
    ...(!isAllDocsPage && board ? [`${emoji}${board.name}`] : []),
  ];

  const settingsTitle = [
    ...(pageId === PageId.SettingsAttributes ? ['Properties'] : []),
    ...(pageId === PageId.SettingsUsers ? ['Members'] : []),
    ...(pageId === PageId.SettingsBilling ? ['Billing'] : []),
    ...(pageId === PageId.SettingsWorkflows ? ['Workflows'] : []),
    ...(pageId === PageId.SettingsCustomers || pageId === PageId.SettingsCustomer ? ['Customers'] : []),
    ...(pageId === PageId.SettingsCompanies || pageId === PageId.SettingsCompany ? ['Companies'] : []),
    ...(doctype ? [`${getNativeEmoji(doctype.emoji)} ${doctype.name}`] : []),
    'Settings',
  ];

  const pageTitle = [
    ...(parentPage === 'board' ? boardTitle : []),
    ...(parentPage === 'settings' ? settingsTitle : []),
    ...(parentPage === 'inbox' ? [...boardTitle, 'Inbox'] : []),
    ...(parentPage === 'billing-update' ? ['Update plan'] : []),
    ...(product?.name ? [product.name] : []),
    APP_NAME,
  ];

  return (
    <Helmet>
      <title>{pageTitle.join(PAGE_TITLE_SEPARATOR).trim()}</title>
    </Helmet>
  );
};

export default PageTitle;
