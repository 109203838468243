import { ChangeDocParentDocument, ChangeDocParentMutation, OperatorIsEmptyOrNot, OperatorIsInOrNot } from '@cycle-app/graphql-codegen';

import { Events } from 'src/constants/analytics.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useGetDocGroup, useUpdateDocsGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import { useUpdateChildCache } from 'src/hooks/api/cache/cacheHierarchy';
import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { useLazyDoc } from 'src/hooks/api/useDoc';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';

interface Params {
  docId: string;
  parentId: string;
  remove?: boolean;
}

export const useChangeDocParent = () => {
  const [changeParentMutation] = useSafeMutation(ChangeDocParentDocument, {
    onCompleted: ({ changeDocParent }) => changeDocParent?.parent && trackAnalytics(Events.HierarchyAdded),
  });

  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const { groups } = useBoardGroups();

  const getDocGroup = useGetDocGroup();
  const updateChild = useUpdateChildCache();
  const updateGroup = useUpdateDocsGroup();

  const getDoc = useLazyDoc();

  async function changeParent({
    docId,
    parentId,
    remove = false,
  }: Params) {
    const [doc, docParent] = await Promise.all([getDoc(docId), getDoc(parentId)]);
    if (!docParent || !doc || (parentId === doc.parent?.id && !remove)) return null;

    if (doc.parent && doc.parent.id !== parentId) {
      updateChild({
        parentId: doc.parent.id,
        docId,
        action: 'remove',
      });
    }

    return changeParentMutation({
      variables: {
        docId,
        parentId: remove ? undefined : parentId,
      },
      optimisticResponse: {
        changeDocParent: {
          id: docId,
          parent: !remove
            ? {
              ...docParent,
              childrenCount: remove ? docParent.childrenCount - 1 : docParent.childrenCount + 1,
            }
            : null,
        },
      },
      update: (cache, { data }) => {
        updateChild({
          parentId,
          docId,
          action: remove ? 'remove' : 'add',
        });

        if (remove) {
          cache.modify({
            id: cache.identify(docParent),
            fields: {
              childrenCount: () => docParent.childrenCount - 1,
            },
          });
        }

        if (!groups) return;

        const parentFilter = boardConfig?.filterProperties.edges.find(({ node }) => node.__typename === 'FilterPropertyRuleDocParent');
        const parentRule = parentFilter?.node.__typename === 'FilterPropertyRuleDocParent'
          ? parentFilter.node.docParentRule
          : undefined;
        if (!parentRule) return;
        // There is a parent rule, doc in board might be affacted

        if (!shouldRemoveDocFromBoard(data)) return;

        const docGroup = getDocGroup(docId);
        if (!docGroup) return;

        updateGroup({
          groupData: docGroup,
          updatedDocs: docGroup.node.docs.edges
            .filter(({ node }) => node.id !== docId)
            .map(edge => edge.node),
        });
      },
    });
  }

  function shouldRemoveDocFromBoard(updatedDocData: ChangeDocParentMutation | null | undefined): boolean {
    const parentFilter = boardConfig?.filterProperties.edges.find(({ node }) => node.__typename === 'FilterPropertyRuleDocParent');
    const parentRule = parentFilter?.node.__typename === 'FilterPropertyRuleDocParent'
      ? parentFilter.node.docParentRule
      : undefined;
    if (!parentRule) return false;

    const parentId = updatedDocData?.changeDocParent?.parent?.id;
    if (!parentId) {
      // A doc without parent only stay is the filter rule is "parent is empty"
      return !(parentRule.__typename === 'RuleIsEmptyOrNot' && parentRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty);
    }

    const selectedParents = parentRule.__typename === 'RuleDocParentMultipleValues'
      ? parentRule.values.edges
        .filter(({ node }) => node.selected)
        .map(e => e.node)
      : [];
    const shouldRemoveDocWithParent =
      // Board filter docs without parent
      (parentRule.__typename === 'RuleIsEmptyOrNot' && parentRule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) ||
      // Doc parent is not in the parents whitelist
      (parentRule.__typename === 'RuleDocParentMultipleValues' &&
        parentRule.operator === OperatorIsInOrNot.Is &&
        !selectedParents.find(({ value }) => value.id === parentId)) ||
      // Doc parent is in the parents blacklist
      (parentRule.__typename === 'RuleDocParentMultipleValues' &&
        parentRule.operator === OperatorIsInOrNot.IsNot &&
        !!selectedParents.find(({ value }) => value.id === parentId)
      );
    return shouldRemoveDocWithParent;
  }

  return changeParent;
};
