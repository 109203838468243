import { BoardWithMinimalConfigFragment } from '@cycle-app/graphql-codegen';
import { Emoji, Tooltip } from '@cycle-app/ui';
import { PlaceHolderIcon, StarIcon } from '@cycle-app/ui/icons';
import { FC, HTMLAttributes, useCallback, useMemo, useState } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { useBoardStar } from 'src/hooks/api/mutations/boards/useBoardStar';
import { useIsBoardStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';
import { useMobileSidebarActions } from 'src/hooks/useMobileSidebarActions';
import { useBoardUrl } from 'src/hooks/useUrl';
import { LocationState } from 'src/types/routes.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { BoardNavigationItem } from '../SidebarBoardSection.styles';
import { StyledBoardOptions, More, StyledActionButton } from './SidebarItemBoard.styles';

export interface Props extends HTMLAttributes<HTMLElement> {
  board: BoardWithMinimalConfigFragment;
  isActive?: boolean;
  asPlaceholder?: boolean;
  isDragging?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  activeAlpha?: number;
  placeholderScale?: [number, number];
  linkState?: LocationState;
}

const SidebarItemBoard: FC<Props> = ({
  board,
  isActive,
  asPlaceholder,
  isDragging,
  placeholderScale,
  activeAlpha,
  linkState,
  ...htmlProps
}) => {
  const { unStar } = useBoardStar();
  const isBoardStarred = useIsBoardStarred();

  const { close: closeMobileSidebar } = useMobileSidebarActions();
  const boardUrl = useBoardUrl(board);

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const handleOnClick = useCallback(() => {
    closeMobileSidebar();
    trackAnalytics(Events.BoardViewed);
  }, [closeMobileSidebar]);

  const icon = useMemo(() => (board.emoji
    ? <Emoji emoji={board.emoji} size={16} />
    : <PlaceHolderIcon />),
  [board.emoji]);

  const contentMore = useMemo(() => (
    <More>
      {isBoardStarred(board.id) === true && (
        <Tooltip content="Unstar" placement="top">
          <StyledActionButton
            onColoredBg={isActive}
            onClick={(e) => {
              e.preventDefault();
              return unStar(board.id);
            }}
          >
            <StarIcon />
          </StyledActionButton>
        </Tooltip>
      )}

      <StyledBoardOptions
        boardId={board.id}
        onColoredBg={isActive}
        onClick={() => setMenuIsVisible(true)}
        onHide={() => setMenuIsVisible(false)}
      />
    </More>
  ), [board.id, isActive, isBoardStarred, unStar]);

  const isDraft = board.draftBoardConfig != null;

  return (
    <BoardNavigationItem
      {...htmlProps}
      linkId={linkState?.fromStarredBoard ? `${board.id}--starred` : board.id}
      key={board.id}
      label={board.name}
      icon={icon}
      linkTo={{
        pathname: boardUrl,
        state: linkState,
      }}
      isStarredBoard={!!linkState?.fromStarredBoard}
      isActive={isActive}
      isChild
      isFocus={menuIsVisible}
      warning={isDraft ? 'Unsaved changes' : null}
      contentMore={contentMore}
      asPlaceholder={asPlaceholder}
      isDragging={isDragging}
      isSidebarColor
      placeholderScale={placeholderScale}
      activeAlpha={activeAlpha}
      onClick={handleOnClick}
    />
  );
};

export default SidebarItemBoard;
