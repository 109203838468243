import { IncognitoMask } from '@cycle-app/ui';
import styled, { keyframes } from 'styled-components';

export const StyledIncognitoCardIcon = styled.div``;

export const StyledIncognitoMask = styled(IncognitoMask)`
  position absolute;
  top: 0;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 17px);
  transition: transform .2s ease-out;
  transform-origin: left;
`;

const eyesAnim = keyframes`
  0% { transform: translate(0, -10%); }
  10% { transform: translate(0, -15%); }
  15% { transform: translate(0, -10%); }
  20% { transform: translate(0, -15%); }
  30% { transform: translate(0, -10%); }
  100% { transform: translate(0, -10%); }
`;

export const StyledIncognitoEyes = styled.img`
  transform: translate(0, -10%);
`;

export const IncognitoFace = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 120px;
  height: 95px;

  &:hover ${StyledIncognitoMask} {
    transform: translate(-50%,17px) rotate(-100deg);
  }

  &:hover ${StyledIncognitoEyes} {
    animation: ${eyesAnim} 2s linear;
  }
`;
