import { Emoji } from '@cycle-app/ui';
import styled, { keyframes } from 'styled-components';

import { CardIconsGridItem } from './IllustationCards.styles';

const boardTopLeft1 = keyframes`
  0% { width: 0; height: 0; }
  85% { width: 0; height: 0; }
  99% { width: auto; height: auto; }
  100% { width: 0; height: 0; }
`;

const boardTopLeft2 = keyframes`
  0% { width: auto; height: auto; }
  85% { width: auto; height: auto; }
  99% { width: 0; height: 0; }
  100% { width: auto; height: auto; }
`;

const boardTopRight1 = keyframes`
  0% { width: 0; height: 0; }
  75% { width: 0; height: 0; }
  80% { width: auto; height: auto; }
  100% { width: 0; height: 0; }
`;

const boardTopRight2 = keyframes`
  0% { width: auto; height: auto; }
  75% { width: auto; height: auto; }
  80% { width: 0; height: 0; }
  100% { width: auto; height: auto; }
`;

const boardBottomRight1 = keyframes`
  0% { width: 0; height: 0; }
  100% { width: auto; height: auto; }
`;

const boardBottomRight2 = keyframes`
  0% { width: auto; height: auto; }
  100% { width: 0; height: 0; }
`;

export const StyledBoardEmoji = styled(Emoji)`
  overflow: hidden;
  &:last-child {
    width: 0;
    height: 0;
  }
  &:first-child {
    width: auto;
    height: auto;
  }
`;

export const BoardsAnimationContainer = styled.div`
  &:hover {
    ${CardIconsGridItem}:first-child {
      ${StyledBoardEmoji}:first-child {
        animation: ${boardTopLeft1} 3s none infinite;
      }
      ${StyledBoardEmoji}:last-child {
        animation: ${boardTopLeft2} 3s none infinite;
      }
    }

    ${CardIconsGridItem}:nth-child(2) {
      ${StyledBoardEmoji}:first-child {
        animation: ${boardTopRight1} 3s none infinite;
      }
      ${StyledBoardEmoji}:last-child {
        animation: ${boardTopRight2} 3s none infinite;
      }
    }

    ${CardIconsGridItem}:last-child {
      ${StyledBoardEmoji}:first-child {
        animation: ${boardBottomRight1} 4s none infinite;
      }
      ${StyledBoardEmoji}:last-child {
        animation: ${boardBottomRight2} 4s none infinite;
      }
    }
  }
`;
