import { FC, ReactNode } from 'react';

import { CloseIcon } from '../../icons';
import {
  Container, Content, Icon, SideActions, BottomActions, CloseButton,
  Action, Title, Message, StyledShortcut,
} from './Toaster.style';

export { Action as ToasterAction };
export { StyledShortcut as ToasterShortcut };
export { Title as ToasterTitle };
export { Message as ToasterDescription };

export type ToasterProps = {
  id?: string;
  icon?: ReactNode;
  title?: ReactNode;
  message?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  onClose?: (id: string) => void;
};

export const Toaster: FC<ToasterProps> = ({
  id, icon, title, message, actions, children, onClose,
}) => {
  const actionsOnSide = !message && !children;

  return (
    <Container
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        visible: {
          scale: 1,
          height: 'auto',
          opacity: 1,
        },
        hidden: {
          scale: 0.9,
          height: 0,
          opacity: 0,
        },
      }}
      transition={{
        duration: 0.15,
      }}
    >
      <Content>
        {(onClose || (actions && actionsOnSide)) && (
          <SideActions>
            {actionsOnSide && actions}
            {onClose && id && (
              <CloseButton onClick={() => onClose(id)}>
                <CloseIcon />
              </CloseButton>
            )}
          </SideActions>
        )}

        {title && (
          <Title>
            {icon && <Icon>{icon}</Icon>}
            {title}
          </Title>
        )}

        {(message || children) && (
          <Message>
            {icon && !title && <Icon>{icon}</Icon>}
            {message}
            {children}
          </Message>
        )}

        {actions && !actionsOnSide && (
          <BottomActions>
            {actions}
          </BottomActions>
        )}
      </Content>
    </Container>
  );
};
