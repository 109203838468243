import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { body400 } from '../../theme/typo';
import { Button } from '../Buttons/Button/Button';
import { Skeleton } from '../Skeleton/Skeleton';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
`;

const logoDimensions = css`
  width: 28px;
  height: 28px;
`;

export const Logo = styled.div`
  flex: none;
  align-self: flex-start;
  margin-top: 8px;
  > svg {
    ${logoDimensions}
  }
`;

export const Text = styled.div`
  flex: 1;
  ${body400};
  line-height: 22px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const Title = styled.span`
  color: ${p => p.theme.colors.text.primary};
  font-weight: 500;
`;

const ActionButton = styled(Button).attrs({
  size: 'L',
  variant: 'secondary',
})`
  flex: none;
  height: 28px;
  padding: 2px 12px;
  border: none;
`;

export const InstallButton = styled(ActionButton)`
  color: ${p => p.theme.userColors.main};
  background-color: ${p => transparentize(0.90, p.theme.userColors.main)};
  &:hover, &:focus {
    background-color: ${p => transparentize(0.85, p.theme.userColors.main)};
  }
  &:active {
    background-color: ${p => transparentize(0.80, p.theme.userColors.main)};
  }
`;

export const UninstallButton = styled(ActionButton)`
  color: ${p => p.theme.colors.text.disabled};
  background-color: ${p => transparentize(0.90, p.theme.colors.text.disabled)};
  &:hover, &:focus {
    background-color: ${p => transparentize(0.85, p.theme.colors.text.disabled)};
  }
  &:active {
    background-color: ${p => transparentize(0.80, p.theme.colors.text.disabled)};
  }
`;

export const ButtonSkeleton = styled(Skeleton)`
  height: 28px;
  width: 70px;
`;
