import { typo, Button, ToggleInput } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

import { PortalModalStyled } from '../DialogModal/DialogModal.styles';

const modalPadding = '20px';

export const StyledPortalModal = styled(PortalModalStyled)`
  padding: 0;
`;

export const Header = styled.div`
  background-color: ${p => p.theme.colors.modal.content.bg};
  display: flex;
  position: sticky;
  top: 0;
  z-index: ${mappingZindex[Layer.Modal] + 1};
  padding: ${modalPadding} ${modalPadding} 0;
`;

export const Content = styled.div`
  padding-left: ${modalPadding};
  padding-right: ${modalPadding};
`;

export const Title = styled.h1`
  ${typo.headerSmall};
  margin-right: auto;
`;

export const View = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 14px;
`;

export const Section = styled.div`
  margin-top: 20px;
`;

const focusCSS = css`
  border-color: ${p => p.theme.userColors.main};
  outline: 1px solid ${p => p.theme.userColors.main};
`;
export const SelectedDoctypes = styled.button<{ forceFocus: boolean }>`
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 7px 8px;

  width: 100%;
  ${typo.body400};

  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  ${p => p.forceFocus && focusCSS}
  &:focus {
    ${focusCSS};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${p => p.theme.colors.input.bg};
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const OtherDoctypesCount = styled.span`
  color: ${p => p.theme.colors.text.disabled};
`;

export const Caret = styled(CaretIcon)`
  margin-left: auto;
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const LightButton = styled(Button).attrs(p => ({
  variant: p.variant ?? 'ternary',
  size: 'S',
}))`
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ViewtypeButton = styled(Button).attrs({
  size: 'L',
  variant: 'outlined',
})`
  width: 100%;
`;

export const DropdownContainer = styled.div`
  display: flex;
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const FiltersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddAnotherFilterBtn = styled(Button).attrs({
  size: 'S',
  variant: 'secondary',
})`
  margin-top: 8px;
  width: auto;
`;

export const GroupByButtons = styled.div<{ $isFullWidth?: boolean }>`
  display: ${p => (p.$isFullWidth ? 'block' : 'flex')};
`;

export const Separator = styled.div`
  background-color: ${p => p.theme.colors.border.primary};
  width: 1px;
`;

const groupbyButtonFocusCSS = css`
  background-color: ${p => p.theme.colors.background.hover};
  border-color: ${p => p.theme.userColors.main};
  outline: 1px solid ${p => p.theme.userColors.main};
  outline-offset: -2px;
`;

export const GroupByButton = styled.button<{ forceFocus: boolean; position?: 'left' | 'middle' | 'right' }>`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: transparent;
  padding: 7px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  ${typo.body400};

  ${p => p.position === 'left' && css`
    border-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right-color: transparent;
  `}
  ${p => p.position === 'right' && css`
    border-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left-color: transparent;
  `}
  ${p => p.position === 'middle' && css`border-radius: 0px;`}

  &:hover {
    background-color: ${p => p.theme.colors.background.tertiary};
  }
  ${p => p.forceFocus && groupbyButtonFocusCSS};
  &:focus {
    ${groupbyButtonFocusCSS};
  }
`;

export const StyledToggleInput = styled(ToggleInput)`
  margin-top: 10px;
  display: flex;
`;

export const FormButtons = styled.div`
  background-color: ${p => p.theme.colors.modal.content.bg};
  margin-top: 24px;
  padding: 0 0 ${modalPadding};

  position: sticky;
  bottom: 0;
  z-index: ${mappingZindex[Layer.Modal] + 1};

  display: flex;
  gap: 8px;

  >:first-child {
    margin-left: auto;
    width: auto;
  }
`;
