import {
  BoardWithDraftConfigDocument,
  CreateBoardConfigDraftDocument,
  PublishBoardConfigDraftDocument,
  RevertBoardConfigDraftDocument,
  DocQueryFragmentDoc,
  DocQueryFragment,
  SaveMyBoardConfigDocument,
  RevertUnsavedBoardConfigDraftDocument,
  BoardWithConfigDocument,
  namedOperations,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useBoard } from 'src/hooks/api/useBoard';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setBoardConfigModal } from 'src/reactives/boardConfig.reactive';
import { getOptimisticDraftBoardConfig } from 'src/utils/boardConfig/boardConfig.util';
import { defaultPagination } from 'src/utils/pagination.util';

import { useGetBoardWithConfigFromCache } from '../../cache/cacheBoardConfig';

export const useCreateDraftBoardConfig = () => {
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  const [createDraftBoardConfigMutation, { loading }] = useSafeMutation(CreateBoardConfigDraftDocument, {
    variables: {
      boardConfigId: boardConfig?.id ?? '',
    },
  });

  const createDraftBoardConfig = useCallback(async () => {
    const result = await createDraftBoardConfigMutation({
      ...boardConfig && boardId && {
        optimisticResponse: {
          createDraftBoardConfig: getOptimisticDraftBoardConfig(boardConfig),
        },
      },
      update: (cache, { data }) => {
        if (!data?.createDraftBoardConfig || !boardId) return;

        cache.writeQuery({
          query: BoardWithDraftConfigDocument,
          data: {
            node: {
              id: boardId,
              draftBoardConfig: data.createDraftBoardConfig,
            },
          },
        });

        // Merging docs from published config to avoid loosing the data with auto cache merging
        if (boardConfig?.docQuery) {
          cache.writeFragment({
            fragment: DocQueryFragmentDoc,
            fragmentName: 'DocQuery',
            id: data.createDraftBoardConfig.id,
            data: {
              __typename: 'BoardConfig',
              id: data.createDraftBoardConfig.id,
              docQuery: {
                ...boardConfig.docQuery,
                ...data.createDraftBoardConfig.docQuery,
              } as DocQueryFragment['docQuery'],
            },
          });
        }
      },
    });
    setBoardConfigModal({ disabled: false });
    return result;
  }, [createDraftBoardConfigMutation, boardId, boardConfig]);

  return {
    createDraftBoardConfig,
    isLoading: loading,
  };
};

// Make it stand-alone as useBoardConfig() needs to be inside a board context,
// and it only needs draftBoardConfigId.
export const usePublishBoardConfigMutation = (draftBoardConfigId: string) => useSafeMutation(PublishBoardConfigDraftDocument, {
  variables: {
    boardConfigId: draftBoardConfigId,
    ...defaultPagination,
  },
});

export default function useDraftBoardConfigMutations(draftBoardConfigId: string, boardID?: string) {
  const board = useBoard(boardID);
  const maybeBoardId = useBoardConfig(ctx => ctx.boardId);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const boardId = maybeBoardId ?? '';
  const getBoardWithConfigFromCache = useGetBoardWithConfigFromCache(boardId);
  const [publishBoardConfig, { loading: loadingPublish }] = usePublishBoardConfigMutation(draftBoardConfigId);

  const [revertDraftConfig, { loading: loadingRevert }] = useSafeMutation(RevertBoardConfigDraftDocument, {
    variables: {
      boardConfigId: draftBoardConfigId,
    },
    ...boardConfig && board && {
      optimisticResponse: {
        revertBoardConfigDraft: {
          __typename: 'BoardConfig',
          id: draftBoardConfigId,
        },
      },
    },
    refetchQueries: [namedOperations.Query.boardWithConfig],
    update: (cache) => {
      cache.writeQuery({
        query: BoardWithDraftConfigDocument,
        data: {
          node: {
            id: boardId,
            draftBoardConfig: null,
          },
        },
      });
      const boardWithPublishedConfig = getBoardWithConfigFromCache();
      if (boardWithPublishedConfig?.savedBoardConfig) {
        cache.writeQuery({
          query: BoardWithConfigDocument,
          variables: {
            id: boardId,
            ...defaultPagination,
          },
          data: {
            node: {
              ...boardWithPublishedConfig,
              savedBoardConfig: null,
            },
          },
        });
      }
    },
  });

  const [revertUnsavedDraftConfig, { loading: loadingRevertUnsaved }] = useSafeMutation(RevertUnsavedBoardConfigDraftDocument, {
    variables: {
      boardConfigId: draftBoardConfigId,
    },
    ...boardConfig && board && {
      optimisticResponse: {
        revertUnsavedBoardConfigDraft: {
          __typename: 'BoardConfig',
          id: draftBoardConfigId,
        },
      },
    },
    update: (cache) => {
      cache.writeQuery({
        query: BoardWithDraftConfigDocument,
        data: {
          node: {
            id: boardId,
            draftBoardConfig: null,
          },
        },
      });
    },
  });

  const [saveMyBoardConfig, { loading: loadingSave }] = useSafeMutation(SaveMyBoardConfigDocument, {
    variables: {
      boardConfigId: draftBoardConfigId,
      ...defaultPagination,
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: BoardWithDraftConfigDocument,
        data: {
          node: {
            id: boardId,
            draftBoardConfig: null,
          },
        },
      });
      const boardWithPublishedConfig = getBoardWithConfigFromCache();
      if (data?.saveMyBoardConfig && boardWithPublishedConfig) {
        cache.writeQuery({
          query: BoardWithConfigDocument,
          variables: {
            id: boardId,
            ...defaultPagination,
          },
          data: {
            node: {
              ...boardWithPublishedConfig,
              savedBoardConfig: data.saveMyBoardConfig,
            },
          },
        });
      }
    },
  });

  return {
    publishBoardConfig,
    revertUnsavedDraftConfig,
    revertDraftConfig,
    saveMyBoardConfig,
    loading: {
      publish: loadingPublish,
      revertUnsaved: loadingRevertUnsaved,
      revert: loadingRevert,
      saveDraft: loadingSave,
    },
  };
}
