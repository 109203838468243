import {
  Card, ColorPicker, EditableText, TextButton, typo, Button, Tag,
} from '@cycle-app/ui';
import { DragOverlay } from '@dnd-kit/core';
import styled, { css } from 'styled-components';

import DialogModal from 'src/components/DialogModal/DialogModal';

import {
  Content as ContentRaw,
  headerMarginBottom,
} from '../Settings.styles';

export const GridContainer = styled(ContentRaw)`
  display: grid;
  align-items: center;
`;

export const PropertyPreview = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 8px;

  padding: 11px 18px;

  display: flex;
  align-items: center;
  gap: 17px;

  box-shadow:
    0px 2px 5px rgba(0, 0, 0, 0.05),
    0px 5px 17px rgba(0, 0, 0, 0.05);
`;

export const NoContent = styled.div`
  margin: auto;
  text-align: center;

  ${PropertyPreview} + ${PropertyPreview} {
    margin-top: 7px;
  }
`;

export const PreviewColor = styled(ColorPicker)`
  width: 10px;
  height: 10px;
`;

export const PreviewLine = styled.div`
  margin-right: auto;
  width: 117px;
  height: 10px;

  background: ${p => p.theme.colors.background.secondary};
  border-radius: 4px;
`;

export const PreviewTitle = styled.h2`
  margin-top: 48px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const PreviewSubTitle = styled.p`
  margin-top: 8px;
  margin-bottom: 24px;
  line-height: 20px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const NameInput = styled(EditableText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 16px 0 32px;
`;

export const NameButton = styled(TextButton)`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  margin-left: 16px;
  margin-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: auto;
`;

export const ReorderButton = styled.button.attrs({
  type: 'button',
})`
  padding: 0;
  opacity: 0;
  cursor: grab;
  touch-action: none;

  svg {
    width: 12px;
  }
`;

export const RowContainer = styled.tr<{
  asPlaceholder?: boolean;
  isDragOverlay?: boolean;
}>`
  opacity: ${p => (p.asPlaceholder ? 0 : 1)};
  flex-grow: 1;
  ${p => p.isDragOverlay && css`
    display: flex;
    align-items: center;
  `};
`;

export const Cell = styled.td`
  border-top: 1px solid ${p => p.theme.colors.border.secondary};
  padding: 12px 0;
  min-width: 1px;
  text-align: left;
`;

export const ReorderCell = styled(Cell)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid transparent;
  width: 52px;
  text-align: center;
  color: ${p => p.theme.colors.text.disabled};
`;

export const ColorCell = styled(Cell)`
  width: 32px;
`;

export const NameCell = styled(Cell)`
  width: 250px;
`;

export const ActionsCell = styled(Cell)`
  width: 60px;
  button {
    margin: auto;
  }
`;
/* This is used to keep the same row width on drag */
export const GrowingCell = styled(Cell)`
  flex-grow: 1;
`;

export const Table = styled.table`
  margin-left: -53px;
  min-width: 853px;
  font-size: 14px;
  table-layout: fixed;

  thead {
    position: sticky;
    top: -${headerMarginBottom};
    z-index: 1;
    background-color: ${p => p.theme.colors.background.primary};
  }

  th {
    color: ${p => p.theme.colors.text.secondary};
    font-weight: normal;
    padding: 12px 0 16px;
    text-align: left;
  }

  tbody {
    tr:hover {
      background-color: ${p => p.theme.colors.settings.row.bgHover};

      ${ReorderButton} {
        opacity: 1;
      }

      ${ReorderCell} {
        border-color: ${p => p.theme.colors.border.secondary};
      }
    }
  }
`;

export const StyledDragOverlay = styled(DragOverlay)`
  display: flex;
`;

export const AddNewLine = styled.div`
  padding: 8px 0 32px;
`;

export const Doctype = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

export const OptionsCard = styled(Card)`
  min-width: 326px;
  max-height: 400px;
  overflow-y: auto;
  padding: 8px 0px;
  border: none;
`;

export const StyledButton = styled(TextButton).attrs({ size: 14 })`
  width: auto;
`;

export const ColorButton = styled(Button)`
  border: none;
`;

export const TagStyled = styled(Tag)`
  ${typo.body500}
  border: none;
  display: inline-flex;
  height: auto;
`;

export const AttributeTagIconContainer = styled.div`
  svg {
    vertical-align: text-top;
  }
`;

export const TagDoctypeStyled = styled(Tag)`
  ${typo.body500}
  background-color: ${p => p.theme.colors.background.tertiary};
  border: none;
  display: inline-flex;
  height: auto;
`;

export const DeleteDialogModalStyled = styled(DialogModal)`
  width: 500px;
`;
