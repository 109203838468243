import { getBreakpoint, isEnabled, Feature } from '@cycle-app/utilities';
import { FC, useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NewDoc } from 'src/app/NewDoc';
import PageTitleGuest from 'src/components/PageTitle/PageTitleGuest';
import ProtectedRoute from 'src/components/ProtectedRoute/ProtectedRoute';
import { PageId, routing } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { usePathParams } from 'src/hooks/usePathParams';
import { useGetAuth } from 'src/reactives/auth.reactive';
import { resetDocItem } from 'src/reactives/docItem.reactive';
import { setHistory } from 'src/reactives/history.reactive';
import { getLastView } from 'src/reactives/lastView.reactive';
import { getResponsive, setResponsive } from 'src/reactives/responsive.reactive';

import { Onboarding } from '../components/Views/Onboarding';
import Auth from './Auth/Auth';
import IntegrationCallback from './IntegrationCallback/IntegrationCallback';
import Main from './Main/Main';
import { MainRoot } from './Main/MainRoot';

interface Props {
  redirectTo?: PageId;
}

const isLightOnboardingEnabled = isEnabled(Feature.LightOnboarding);

const AppRouter: FC<Props> = ({ redirectTo }) => {
  const pageId = usePageId();
  const {
    token,
    onboarded,
  } = useGetAuth();
  const { productSlug } = usePathParams();
  const { navigate } = useNavigate();
  const defaultPage = token ? PageId.Main : PageId.Auth;

  const previousPageIdRef = useRef<PageId>();

  useEffect(() => {
    resetDocItem();
    setHistory({ previousPage: previousPageIdRef.current });
    previousPageIdRef.current = pageId;
  }, [pageId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    const {
      productSlug: lastProductSlug,
      boardSlug: lastBoardSlug,
    } = getLastView();

    if (
      !token ||
      !onboarded ||
      productSlug ||
      !lastProductSlug ||
      pageId === PageId.Callback ||
      pageId === PageId.OAuth ||
      pageId === PageId.NewDoc ||
      pageId === PageId.GetStarted
    ) {
      return;
    }

    if (lastBoardSlug) {
      navigate(PageId.Board, {
        productSlug: lastProductSlug,
        boardSlug: lastBoardSlug,
      });
    } else {
      navigate(PageId.Main, {
        productSlug: lastProductSlug,
      });
    }
  }, [token, onboarded, pageId, navigate]);

  return (
    <>
      <PageTitleGuest />
      <Switch>
        {isLightOnboardingEnabled && (
          <Route path={routing[PageId.GetStarted]}>
            <Onboarding />
          </Route>
        )}
        <Route path={routing[PageId.Callback]}>
          <IntegrationCallback />
        </Route>
        <ProtectedRoute path={routing[PageId.Main]}>
          <MainRoot>
            <Main />
          </MainRoot>
        </ProtectedRoute>
        <ProtectedRoute path={routing[PageId.NewDoc]}>
          <NewDoc />
        </ProtectedRoute>
        <Route path={routing[PageId.Auth]}>
          <Auth />
        </Route>
        <Redirect to={routing[redirectTo || defaultPage]} />
      </Switch>
    </>
  );

  function handleWindowResize() {
    const newBreakpoint = getBreakpoint();
    const currentBreakpoint = getResponsive().breakpoint;

    if (newBreakpoint !== currentBreakpoint) {
      setResponsive({ breakpoint: newBreakpoint });
    }
  }
};

export default AppRouter;
