import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { TooltipProps, Emoji } from '@cycle-app/ui';
import {
  useCallback, useState, useLayoutEffect, FC, KeyboardEvent, useRef,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { PageId } from 'src/constants/routing.constant';
import { useCreateDraftBoardConfig } from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import { useCurrentInboxSlug } from 'src/hooks/inbox';
import { useNavigate } from 'src/hooks/useNavigate';
import { useUrl } from 'src/hooks/useUrl';
import { getBoardSlug } from 'src/utils/slug.util';

import { BoardModals } from './BoardModals';
import { StyledTab, TabLink } from './InboxTabs.styles';
import { TabTooltip } from './TabTooltip';

export type InboxTabProps = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  tooltip?: Omit<TooltipProps, 'content' | 'title'>;
  hideIcon?: boolean;
  emoji?: string;
};

export const InboxTab: FC<InboxTabProps> = ({
  hideIcon = false, emoji, ...props
}) => {
  const {
    createDraftBoardConfig, isLoading,
  } = useCreateDraftBoardConfig();
  const { board } = props;
  const tabRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLSpanElement>(null);
  const getUrl = useUrl();
  const { navigateToInbox } = useNavigate();
  const currentSlug = useCurrentInboxSlug();
  const [isOverflowing, setOverflowing] = useState(false);

  const checkOverflowing = useCallback(() => {
    const parent = contentRef.current?.parentElement;
    if (!parent) return;
    const contentWidth = contentRef.current.getBoundingClientRect().width;
    const parentStyle = getComputedStyle(parent);
    const parentPadding = parseFloat(parentStyle.paddingLeft) + parseFloat(parentStyle.paddingRight);
    const parentWidth = parent.getBoundingClientRect().width - parentPadding;
    setOverflowing(contentWidth > parentWidth);
  }, []);

  const debouncedCheckOverflowing = useDebouncedCallback(checkOverflowing, 500);

  useLayoutEffect(() => {
    checkOverflowing();
  }, [board.name, checkOverflowing]);

  useLayoutEffect(() => {
    window.addEventListener('resize', debouncedCheckOverflowing);
    return () => {
      window.removeEventListener('resize', debouncedCheckOverflowing);
    };
  }, [debouncedCheckOverflowing]);

  const boardSlug = getBoardSlug(board);
  const isActive = currentSlug === boardSlug;
  const isMyInbox = board.isBuiltIn && board.name === 'My Inbox';

  return (
    <>
      <StyledTab
        ref={tabRef}
        forwardedAs="div"
        $isActive={isActive}
        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
          if (!['Enter', 'Space'].includes(e.code)) return;
          navigateToInbox(boardSlug);
        }}
      >
        <TabTooltip
          {...props}
          isActive={isActive}
          withBoardName={isOverflowing}
        >
          <TabLink
            to={getUrl(PageId.InboxView, { boardSlug })}
            onClick={async (e) => {
              e.stopPropagation();
              if (!isActive) return;
              e.preventDefault();
              if (isMyInbox && !board.savedBoardConfig) return;
              await createDraftBoardConfig();
            }}
          >
            <span ref={contentRef}>
              {!hideIcon && <Emoji inline emoji={emoji ?? board.emoji} />}
              {board.name}
            </span>
          </TabLink>
        </TabTooltip>
      </StyledTab>

      {board.draftBoardConfig?.id && (
        <BoardModals
          board={board}
          draftBoardConfigId={board.draftBoardConfig.id}
          onHide={() => tabRef.current?.focus()}
          isLoadingDraftBoardConfig={isLoading}
        />
      )}
    </>
  );
};
