import { typo, Shortcut, ShortcutComponents } from '@cycle-app/ui';
import { InboxIcon } from '@cycle-app/ui/icons';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const ShortcutStyled = styled(Shortcut)`
  ${ShortcutComponents.Key} {
    background-color: ${p => p.theme.colors.commandbar.shortCut.bg};
    color: ${p => p.theme.colors.commandbar.shortCut.color};
  }
  margin-left: auto;
  opacity: 0.5;
`;

export const StyledInboxIcon = styled(InboxIcon)``;

export const Action = styled.button<{ hoverDisabled?: boolean }>`
  ${typo.body400};
  transition: color .1s, background-color .1s;

  color: ${p => p.theme.colors.commandbar.action.color};

  cursor: pointer;
  width: 100%;
  height: 36px;

  padding: 8px 16px;
  text-align: left;
  border-radius: 6px;

  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    color: ${p => p.theme.colors.commandbar.actionIcon.color};
  }

  ${StyledInboxIcon} {
    width: 16px;
    height: 14px;
  }

  mark {
    color: inherit;
    background-color: inherit;
    text-decoration: underline;
  }

  &:hover, &:focus, &.selected {
    background-color: ${p => p.theme.colors.commandbar.action.bgHover};
    ${ShortcutStyled} {
      opacity: 1;
    }
  }
  ${p => p.hoverDisabled && css`
    color: ${p.theme.colors.commandbar.action.color};
    &:hover {
      background-color: transparent;
    }
  `};

  .hightlight {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-value="create-doc"] {
    color: ${p => p.theme.userColors.main};

    &:hover, &:focus, &.selected {
      background-color: ${p => rgba(p.theme.userColors.main, 0.2)};

      ${ShortcutComponents.Key} {
        background-color: ${p => rgba(p.theme.userColors.main, 0.2)};
        color: ${p => p.theme.userColors.main};
      }
    }

    svg {
      color: ${p => p.theme.userColors.main};
      width: 8px;
      height: 8px;
    }
  }
`;
