import { ReactNode, RefObject, useRef } from 'react';

import DialogModal, { DialogModalProps } from 'src/components/DialogModal/DialogModal';
import { useOptimizedBooleanState } from 'src/hooks';

export type ToggleDialogProps = Omit<DialogModalProps, 'hide'> & {
  button: ReactNode | ((props: {
    ref: RefObject<HTMLButtonElement>;
    onClick: VoidFunction;
  }) => ReactNode);
};

export const ToggleDialog = ({
  button, ...props
}: ToggleDialogProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [isVisible, {
    setTrueCallback: open,
    setFalseCallback: close,
  }] = useOptimizedBooleanState(false);

  const hide = () => {
    ref.current?.focus();
    close();
  };

  return (
    <>
      {typeof button === 'function' ? button({
        ref,
        onClick: open,
      }) : button}

      {isVisible && (
        <DialogModal
          hide={hide}
          useHighMaskLayer
          variantCancelButton="secondary"
          variantSubmitButton="primary"
          {...props}
        />
      )}
    </>
  );
};
