import { IntegrationType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { GithubStatusesSelectDropdown } from 'src/components/GithubStatusesSelectDropdown/GithubStatusesSelectDropdown';
import { FooterValue } from 'src/components/Integrations/IntegrationsCommon.styles';
import { useChangeStatusGithubIssue } from 'src/hooks/api/mutations/integrations/useChangeStatusGithubIssue';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useErrorToaster } from 'src/hooks/useErrorToaster';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { GithubStatus } from 'src/types/integrations.types';

import { LoadingState } from './GithubStatuses.styles';

type Props = {
  availableStatuses: GithubStatus[];
  currentStatus: GithubStatus['name'];
  issueId?: string;
};
export const GithubStatuses: FC<Props> = ({
  availableStatuses = [], currentStatus, issueId,
}) => {
  const [isAssigneeModalOpen, { toggleCallback }] = useOptimizedBooleanState(false);
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Github);
  const {
    change, isLoading,
  } = useChangeStatusGithubIssue();
  const { add: addErrorToaster } = useErrorToaster();

  return (
    <GithubStatusesSelectDropdown
      statuses={availableStatuses}
      isVisible={isAssigneeModalOpen}
      onClose={toggleCallback}
      selectedStatus={currentStatus}
      onChange={async (newStatus) => {
        if (!integration?.provider?.id || !issueId) return;

        const result = await change({
          issueId,
          providerId: integration.provider.id,
          value: newStatus === 'none' ? null : newStatus.id,
        });

        if (result.data?.updateGithubCardStatus) {
          toggleCallback();
        } else {
          addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' });
        }
      }}
      isLoading={isLoading}
    >
      <FooterValue isReadOnly={!availableStatuses?.length} onClick={toggleCallback}>
        {isLoading ? <LoadingState /> : currentStatus}
      </FooterValue>
    </GithubStatusesSelectDropdown>
  );
};
