import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const PitchIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="33" height="13" viewBox="0 0 33 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_74_11920)">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.7415 6.03698C21.4797 6.03698 22.1268 6.41665 22.501 6.99129L24.0683 6.04724C23.3706 4.92876 22.1471 4.18994 20.7415 4.18994C18.5674 4.18994 16.7979 5.98568 16.7979 8.19186C16.7979 10.3981 18.5674 12.1938 20.7415 12.1938C22.137 12.1938 23.3605 11.455 24.0683 10.3467L22.4808 9.42323C22.0965 9.97734 21.4595 10.3467 20.7415 10.3467C19.5685 10.3467 18.618 9.38218 18.618 8.19186C18.618 7.00155 19.5685 6.03698 20.7415 6.03698Z" fill="currentcolor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.2863 4.18947C28.3257 4.18947 27.4561 4.56914 26.8089 5.19508V0.803223H24.9888V11.8855H26.8089V8.19139C26.8089 7.00107 27.7594 6.03651 28.9324 6.03651C30.1054 6.03651 31.0559 7.00107 31.0559 8.19139V11.8855H32.8761V7.83224C32.8761 5.82102 31.2683 4.18947 29.2863 4.18947Z" fill="currentcolor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.18 10.336H14.9778C14.9171 10.336 14.8564 10.3257 14.7958 10.3155C14.3812 10.2334 14.0677 9.85373 14.0677 9.41249V6.34435H15.8879V4.49731H14.0677V0.803223H12.2476V9.42275C12.2476 10.9517 13.4711 12.1933 14.9778 12.1933C15.6654 12.1933 16.2923 11.9368 16.7777 11.5058L15.898 9.98713C15.7362 10.2026 15.4733 10.336 15.18 10.336Z" fill="currentcolor" />
      <path d="M10.1235 2.85549C10.682 2.85549 11.1347 2.39608 11.1347 1.82936C11.1347 1.26264 10.682 0.803223 10.1235 0.803223C9.56503 0.803223 9.1123 1.26264 9.1123 1.82936C9.1123 2.39608 9.56503 2.85549 10.1235 2.85549Z" fill="currentcolor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.21387 4.49707H11.034V11.8852H9.21387V4.49707Z" fill="currentcolor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.90512 0.803223H0.416504V11.8855H2.23665V9.73059H3.90512C6.33199 9.73059 8.30382 7.72963 8.30382 5.26691C8.30382 2.80418 6.33199 0.803223 3.90512 0.803223ZM3.90512 7.88355H2.23665V2.65026H3.90512C5.33091 2.65026 6.48367 3.82006 6.48367 5.26691C6.48367 6.71376 5.33091 7.88355 3.90512 7.88355Z" fill="currentcolor" />
    </g>
    <defs>
      <clipPath id="clip0_74_11920">
        <rect width="32.4593" height="11.6364" fill="white" transform="translate(0.416504 0.680176)" />
      </clipPath>
    </defs>
  </Svg>
);
