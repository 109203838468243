import { NavigationItem, NavigationItemProps, Tooltip } from '@cycle-app/ui';
import { nextFrame } from '@cycle-app/utilities/src/utils/async.util';
import { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { useIsBoardActive } from 'src/hooks/useIsBoardActive';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { useGetNavigation, setNavigation } from 'src/reactives/navigation.reactive';

type Props = NavigationItemProps & {
  linkId?: string;
  isStarredBoard?: boolean;
};

export const CollapsableNavigationItem: FC<Props> = memo(({
  linkId, isStarredBoard = false, ...props
}) => {
  const {
    label, linkTo, isActive, onClick,
  } = props;
  const collapsed = useSidebarCollapsed();
  const history = useHistory();
  const { activeItem } = useGetNavigation();
  const isBoardActive = useIsBoardActive();

  if (!linkTo || !linkId) {
    return (
      <NavigationItem
        {...props}
        isSidebarCollapsed={collapsed}
      />
    );
  }

  const item = (
    <NavigationItem
      {...props}
      isSidebarCollapsed={collapsed}
      isActive={activeItem ? isBoardActive({
        boardId: linkId,
        isStarred: isStarredBoard,
      }) : isActive}
      isDisabled={!!activeItem}
      onClick={async (e) => {
        if (typeof linkTo === 'function') return;
        setNavigation({ activeItem: `${linkId}${isStarredBoard ? '_starred' : ''}` });
        onClick?.(e);
        await nextFrame();
        history.push(linkTo);
        setNavigation({ activeItem: null });
      }}
    />
  );

  // Conditional render because of CSS issue with Item embeded into Tooltip
  if (!collapsed) return item;

  return (
    <Tooltip
      content={label}
      placement="right"
      withPortal
    >
      {item}
    </Tooltip>
  );
});
