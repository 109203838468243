import Emoji, { EmojiStorage } from '@tiptap-pro/extension-emoji';
import { ReactRenderer } from '@tiptap/react';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import { Layer } from 'src/types/layers.types';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

import EmojiList from './EmojiList';

export default Emoji.configure({
  suggestion: {
    items({
      editor, query,
    }) {
      const emojisInStorage = (editor.storage.emoji as EmojiStorage).emojis;

      if (query.length === 0) return emojisInStorage.slice(0, 20);

      return emojisInStorage
        .filter(({
          shortcodes,
          tags,
        }) => shortcodes.find(shortcode => shortcode.startsWith(query.toLowerCase())) ||
          tags.find(tag => tag.startsWith(query.toLowerCase())));
    },

    render() {
      let reactRenderer: ReactRenderer;
      let popup: TippyInstance<TippyProps>[];

      return {
        onStart: (props) => {
          reactRenderer = new ReactRenderer(EmojiList, {
            props,
            editor: props.editor,
          });

          popup = getTippyPopup({
            props,
            reactRenderer,
            layer: Layer.DropdownZ1,
          });
        },
        onUpdate(props) {
          reactRenderer.updateProps(props);
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },
        onKeyDown({ event }) {
          if (event.key === 'Escape') {
            popup[0].hide();
            return true;
          }
          return onKeyDown({
            event,
            popupInstance: popup[0],
          });
        },

        onExit() {
          popup[0].destroy();
          reactRenderer.destroy();
        },
      };
    },
  },
});
