import { Tooltip } from '@cycle-app/ui';
import { FC, MouseEvent, ReactNode } from 'react';
import { Placement } from 'tippy.js';

import { ActionButtonStyled } from './DocAction.styles';

interface Props {
  className?: string;
  buttonIcon?: ReactNode;
  label?: string;
  counter?: number;
  onClick?: (e: MouseEvent) => void;
  tooltipPlacement?: Placement;
  forceFocus?: boolean;
  disabled?: boolean;
  size?: 'S' | 'M' | 'L';
}

const DocAction: FC<Props> = ({
  className,
  buttonIcon,
  label,
  counter,
  onClick,
  forceFocus,
  children,
  tooltipPlacement = 'top',
  disabled = false,
  size = 'M',
}) => {
  return label
    ? (
      <Tooltip
        placement={tooltipPlacement}
        content={label}
        withPortal
        disabled={disabled}
      >
        {renderActionButton()}
      </Tooltip>
    )
    : renderActionButton();

  function renderActionButton() {
    return (
      <ActionButtonStyled
        onClick={onClick}
        counter={counter}
        className={forceFocus ? `${className} force-focus` : className}
        disabled={disabled}
        size={size}
      >
        {buttonIcon}
        {children}
      </ActionButtonStyled>
    );
  }
};

export default DocAction;
