import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useCustomerProfileModal, resetCustomerProfileModal } from 'src/reactives';
import { Layer } from 'src/types/layers.types';

import { CustomerProfile } from './CustomerProfile';
import { Header, StyledPortalModal } from './CustomerProfileModal.styles';

type CustomerProfileModalProps = {
  layer?: Layer;
};

export const CustomerProfileModal: FC<CustomerProfileModalProps> = ({ layer = Layer.Modal }) => {
  const [{
    customerId, isOpen,
  }] = useCustomerProfileModal();

  if (!isOpen) return null;

  return (
    <StyledPortalModal placement="top" layer={layer} hide={resetCustomerProfileModal}>
      <Header>
        <ActionButton tooltipPlacement="top" onClick={resetCustomerProfileModal}><CloseIcon /></ActionButton>
      </Header>
      <BoardConfigContextProvider>
        {customerId && <CustomerProfile customerId={customerId} isHeaderCentered hideShareLink hideSeparator />}
      </BoardConfigContextProvider>
    </StyledPortalModal>
  );
};
