import { FC } from 'react';

import pinBlue from 'src/assets/pin-blue.png';
import pinViolet from 'src/assets/pin-violet.png';
import pinYellow from 'src/assets/pin-yellow.png';

import { PaperGroup, PapersContainer, PaperWrapper, StyledPaper } from './PapersPinnedIllustationCard.styles';

export type PapersPinnedProps = {
  className?: string;
};

export const PapersPinnedIllustationCard: FC<PapersPinnedProps> = ({ className }) => (
  <PapersContainer className={className}>
    <PaperWrapper>
      <img alt="pin" src={pinViolet} width={60} height={90} />
      <PaperGroup>
        <StyledPaper />
      </PaperGroup>
    </PaperWrapper>
    <PaperWrapper>
      <img alt="pin" src={pinBlue} width={60} height={90} />
      <PaperGroup>
        <StyledPaper />
        <StyledPaper />
      </PaperGroup>
    </PaperWrapper>
    <PaperWrapper>
      <img alt="pin" src={pinYellow} width={60} height={90} />
      <PaperGroup>
        <StyledPaper />
      </PaperGroup>
    </PaperWrapper>
  </PapersContainer>
);
