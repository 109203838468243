import { ViewType } from '@cycle-app/graphql-codegen';
import { DraggableCard, typo, Button } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import ContentEditable from 'src/components/ContentEditable/ContentEditable';
import DoctypesOptions from 'src/components/DoctypesOptions/DoctypesOptions';

export const DraggableCardStyled = styled(DraggableCard).attrs({
  draft: true,
  isSelected: true,
}) <{ from?: 'top' | 'bottom' }>`
  margin-bottom: ${p => (p.from === 'bottom' ? 8 : 0)}px;
`;

export const Form = styled.form<{ $viewType: ViewType }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${p => p.$viewType === 'LIST' && css`
    flex-direction: row;
  `}
`;

export const DoctypeContainer = styled.div`
  display: flex;
`;

export const DoctypeSelect = styled(DoctypesOptions)`
  border-color: ${p => p.theme.userColors.main};
  color: ${p => p.theme.userColors.main};
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const DocTitleEditable = styled(ContentEditable)<{ $viewType: ViewType }>`
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${p => p.theme.colors.text.primary};
  ${typo.body400}

  ${p => p.$viewType === 'LIST' && css`
    white-space: nowrap;
    overflow: hidden;
  `}
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2px;

  div {
    display: flex;
  }
`;

export const StyledButton = styled(Button)`
  padding: 2px 8px;
`;
