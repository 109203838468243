import { FC, useMemo } from 'react';

import AttributeOptionsManager from 'src/components/AttributeOptionsManager/AttributeOptionsManager';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { getAttributeOptions } from 'src/utils/attributes.util';

import { StyledButton } from './SettingsAttributes.styles';

interface AttributeOptionsDropdownProps {
  attribute: AttributeDefinitionsNode;
}

const AttributeOptionsDropdown: FC<AttributeOptionsDropdownProps> = ({ attribute }) => {
  const [isDropdownVisible, {
    setTrueCallback: showDropdown,
    setFalseCallback: hideDropdown,
  }] = useOptimizedBooleanState(false);

  const options = useMemo(() => getAttributeOptions(attribute), [attribute]);

  return (
    <DropdownLayer
      visible={isDropdownVisible}
      hide={hideDropdown}
      placement="bottom-end"
      content={(
        <AttributeOptionsManager
          attributeDefinition={attribute}
          options={options}
        />
      )}
    >
      <StyledButton onClick={showDropdown}>
        {getLabel()}
      </StyledButton>
    </DropdownLayer>
  );

  function getLabel() {
    if (options.length === 0) { return 'Add option'; }
    if (options.length === 1) { return '1 option'; }
    return `${options.length} options`;
  }
};

export default AttributeOptionsDropdown;
