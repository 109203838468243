import { typo, Button, InfiniteScroll, Spinner } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import BoardConfigFormProperties from '../BoardConfigForm/BoardConfigFormProperties';

export const StyledSpinner = styled(Spinner)`
  opacity: .2;
`;

export const StyledFormProperties = styled(BoardConfigFormProperties)`
  --bg: transparent;
  padding-left: 8px;
  padding-right: 8px;
  gap: 4px;
  ${typo.body400}

  &.force-focus {
    --bg: ${p => p.theme.colors.button.secondary.bgFocus};
  }

  svg:last-child {
    color: ${p => p.theme.colors.text.disabled};
  }

  &:hover {
    svg:last-child {
      color: ${p => p.theme.colors.text.primary};
    }
  }
`;

export const Container = styled.section`
  width: 100%;
`;

export const Header = styled.header`
  display: flex;
  gap: 8px;
  padding: 12px 32px;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 3;
  background-color: ${p => p.theme.colors.body.bg};
`;

export const HeaderToolbar = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Title = styled.h2`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.text.primary};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 0;
  padding: 0 32px;
`;

export const ActionsContainerSkeleton = styled.div`
  color: ${p => p.theme.colors.background.hover};
  display: flex;
  gap: 8px;
`;

export const AddButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 2px;
  color: ${p => p.theme.colors.text.secondary};
  svg {
     width: 12px;
     height: 12px;
  }
`;

export const DocsContainer = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding-top: 4px;
`;

export const LoadingState = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
`;

export const Caret = styled(DownIcon) <{ $isOn?: boolean }>`
  ${p => p.$isOn && 'transform: rotate(180deg);'}
  width: 12px;
`;
