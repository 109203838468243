import { FC } from 'react';

import SortableItem from 'src/components/SortableItem/SortableItem';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useIsAllDocsPage } from 'src/hooks/useIsAllDocsPage';

export const DocItemSortable: FC<{
  isDragging?: boolean;
  itemId?: string;
}> = ({
  children, isDragging, itemId,
}) => {
  const isAllDocs = useIsAllDocsPage();
  const savedBoardConfig = useBoardConfig(ctx => ctx.savedBoardConfig);
  if (isDragging || !itemId) return <>{children}</>;
  /* For Dnd to work properly SortableItem must be mounted only when is is in a real group and not in a DragOverlay */
  return (
    <SortableItem id={itemId} disabled={isAllDocs && !savedBoardConfig}>
      {children}
    </SortableItem>
  );
};
