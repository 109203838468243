import { Color } from '@cycle-app/graphql-codegen/generated';
import { VFC } from 'react';

import { DEFAULT_AVATARS } from 'src/constants/avatars.constants';

import { Container, Avatars, Description, StyledAvatar } from './DefaultAvatarPicker.styles';

interface Props {
  onSelect: (src: string) => void;
  hideDescription?: boolean;
  color?: Color;
}

const DefaultAvatarPicker: VFC<Props> = ({
  onSelect, hideDescription, color,
}) => (
  <Container>
    <Avatars>
      {DEFAULT_AVATARS.map(src => (
        <StyledAvatar
          key={src}
          src={src}
          size={40}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(src);
          }}
          userColor={color}
        />
      ))}
    </Avatars>
    {!hideDescription && <Description>or select one of our personalized</Description>}
  </Container>
);

export default DefaultAvatarPicker;
