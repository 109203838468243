import { boxShadowZ4, typo, Button } from '@cycle-app/ui';
import { CycleIcon, ZapierIcon, NextArrowIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import ProductItem from 'src/components/ProductsMenu/ProductItem/ProductItem';

export const Container = styled.div`
  border: 1px solid ${p => p.theme.colors.border.greyLight};
  border-radius: 8px;
  flex: 1;
  max-width: 500px;
  padding: 24px 32px;
  margin: 24px;
  ${boxShadowZ4};
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
`;

export const Body = styled.div`
  margin-top: 32px;
  ${typo.headerMedium};
  font-weight: normal;
  text-align: center;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const StyledCycleIcon = styled(CycleIcon)`
  height: 60px;
  width: 60px;
`;

export const StyledZapierIcon = styled(ZapierIcon)`
  width: 60px;
  height: 60px;
  border: 1px solid ${p => p.theme.colors.border.greyLight};
  border-radius: 18px;
  padding: 6px;
`;

export const StyledNextArrowIcon = styled(NextArrowIcon)`
  color: ${p => p.theme.colors.text.disabled};
  height: 30px;
  width: 30px;
`;

export const StyledSelectButton = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

export const SelectPlaceholder = styled.div`
  padding: 5px 0;
`;

export const StyledSelectedProduct = styled(ProductItem)`
  padding-left: 0px;
  padding-right: 0px;
`;
