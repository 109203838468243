import { ReactNode } from 'react';

import {
  Container, Logo, Text, Title, InstallButton, UninstallButton, ButtonSkeleton,
} from './OnboardingIntegrationCard.styles';

export type OnboardingIntegrationCardProps = {
  title: string;
  description?: string;
  logo: ReactNode;
  status: 'loading' | 'installed' | 'uninstalled';
  onClick?: VoidFunction | null;
  isReadOnly: boolean;
};

export const OnboardingIntegrationCard = ({
  title, logo, description, status, onClick, isReadOnly,
}: OnboardingIntegrationCardProps) => (
  <Container>
    <Logo>{logo}</Logo>

    <Text>
      <Title>{title}</Title>
      <br />
      {description}
    </Text>

    {!isReadOnly && <CardButton status={status} onClick={onClick} />}
  </Container>
);

const CardButton = ({
  status, onClick,
}: Pick<OnboardingIntegrationCardProps, 'status' | 'onClick'>) => {
  switch (status) {
    case 'loading':
      return <ButtonSkeleton />;
    case 'installed':
      return (
        <UninstallButton disabled>
          Installed
        </UninstallButton>
      );
    case 'uninstalled':
      return (
        <InstallButton onClick={() => onClick?.()}>
          Install
        </InstallButton>
      );
    default:
      return null;
  }
};
