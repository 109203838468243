import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const NumberedListIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.67419 1.11328H2.48339L1 2.05247V3.17598L2.3722 2.31579H2.40731V7.10534H3.67419V1.11328ZM6.4176 3.21461C6.4176 2.83541 6.725 2.52802 7.10419 2.52802H14.3134C14.6926 2.52802 15 2.83541 15 3.21461C15 3.5938 14.6926 3.9012 14.3134 3.9012H7.10419C6.725 3.9012 6.4176 3.5938 6.4176 3.21461ZM6.4176 8.0207C6.4176 7.6415 6.725 7.33411 7.10419 7.33411H14.3134C14.6926 7.33411 15 7.6415 15 8.0207C15 8.39989 14.6926 8.70729 14.3134 8.70729H7.10419C6.725 8.70729 6.4176 8.39989 6.4176 8.0207ZM7.90522 12.1403C7.52602 12.1403 7.21863 12.4477 7.21863 12.8268C7.21863 13.206 7.52602 13.5134 7.90522 13.5134H14.3134C14.6926 13.5134 15 13.206 15 12.8268C15 12.4477 14.6926 12.1403 14.3134 12.1403H7.90522ZM5.339 14.8866H1.06731V13.9738L3.20023 11.9988C3.74443 11.4751 4.03701 11.1474 4.03701 10.6705C4.03701 10.1439 3.64495 9.81618 3.11831 9.81618C2.57118 9.81618 2.20838 10.1673 2.2113 10.7407H1.0088C1.00587 9.56456 1.86898 8.81262 3.12708 8.81262C4.41151 8.81262 5.26 9.547 5.26 10.5857C5.26 11.2674 4.92938 11.8291 3.69762 12.9526L2.8228 13.8099V13.8509H5.339V14.8866Z" fill="#171618" />
  </Svg>
);
