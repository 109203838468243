import { ViewType } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $context?: 'doc-item' | 'doc-panel';
  $viewType?: ViewType;
}>`
  display: flex;
  align-items: center;
  gap: ${p => (p.$viewType === ViewType.List ? 8 : 4)}px;
  
  ${p => p.$context === 'doc-panel' && css`
    gap: 6px;
  `}
`;

export const SkeletonTag = styled(Skeleton)`
  width: 48px;
  height: 12px;
`;
