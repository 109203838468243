import { useQuery } from '@apollo/client';
import { MeNodeDocument, MeFragment } from '@cycle-app/graphql-codegen';

import { useGetAuth } from 'src/reactives/auth.reactive';
import { useGetInitial } from 'src/reactives/initial.reactive';

export const useMyColor = () => {
  const { loading } = useGetInitial();
  const { userId } = useGetAuth();

  const { data } = useQuery<{ node: MeFragment }>(MeNodeDocument, {
    skip: !userId || loading,
    fetchPolicy: 'cache-only',
    variables: {
      id: userId,
    },
  });

  return data?.node.color;
};
