import { Emoji } from '@cycle-app/ui';
import { ComponentProps } from 'react';

import { useSortedDocTypesFromCache } from 'src/hooks/useSortedDocTypes';

import { DocTypes } from './SettingsWorkflows.styles';

type Props = ComponentProps<typeof DocTypes> & {
  ids?: string[];
  placeholder: string;
  currentDocTypeId?: string;
};

export const LinkedDocTypesLabel = ({
  ids, placeholder, currentDocTypeId, ...props
}: Props) => {
  const docTypes = useSortedDocTypesFromCache(ids, currentDocTypeId);
  if (!docTypes.length) return <>{placeholder}</>;

  return (
    <DocTypes {...props}>
      {docTypes
        .slice(0, 4)
        .map(d => (
          <Emoji key={d.id} emoji={d.emoji} size={14} />
        ))}
      {docTypes.length > 4 && `+${docTypes.length - 4}`}
    </DocTypes>
  );
};
