/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { Variants, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

import { delay } from '../../utils/async.util';

export const flameVariants: Variants = {
  hidden: i => ({
    scale: 0,
    transition: {
      duration: 0.2,
      delay: i * 0.1,
      ease: 'easeInOut',
    },
  }),
  visible: i => ({
    scale: 1,
    transition: {
      duration: 0.3,
      delay: i * 0.2,
      ease: 'easeInOut',
    },
  }),
};

export const starsVariants: Variants = {
  hidden: i => ({
    scale: 0,
    rotate: i ? 13 : -13,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  }),
  scale: i => ({
    scale: 1,
    rotate: i ? 13 : -13,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  }),
  rotate: {
    scale: 1,
    rotate: 0,
    transition: {
      duration: 0.1,
      ease: 'linear',
    },
  },
};

export const heartsVariants: Variants = {
  hidden: {
    scale: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  visible: {
    scale: 0.8,
    transition: {
      duration: 0.3,
      type: 'spring',
      damping: 5,
    },
  },
};

export const useEyesAnimation = () => {
  const flames = useAnimation();
  const stars = useAnimation();
  const hearts = useAnimation();

  useEffect(() => {
    (async function loop() {
      await flames.start('visible');
      await delay(2000);
      await flames.start('hidden');

      await stars.start('scale');
      await delay(500);
      await stars.start('rotate');
      await delay(1500);
      await stars.start('hidden');

      await hearts.start('visible');
      await delay(2000);
      await hearts.start('hidden');
      loop();
    }());
  }, []);

  return {
    flames,
    stars,
    hearts,
  };
};
