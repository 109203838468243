import { SectionType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useOnboardingPreviewBoard } from 'src/hooks/useOnboardingPreviewBoard';
import { getBoardSlug } from 'src/utils/slug.util';

import { OnboardingPreviewRoute, OnboardingPreviewRouteProps } from '../OnboardingPreviewRoute/OnboardingPreviewRoute';

interface Props extends Omit<OnboardingPreviewRouteProps, 'pageId' | 'routeParams'> {
  name?: string;
  emoji?: string | null;
  pageId?: PageId;
  sectionType?: SectionType;
}

export const OnboardingPreviewBoardRoute: FC<Props> = ({
  name, emoji, pageId, sectionType, ...props
}) => {
  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);
  const board = useOnboardingPreviewBoard({
    name,
    emoji,
    sectionType,
  });

  return board
    ? (
      <OnboardingPreviewRoute
        pageId={pageId ?? (isInboxEnabled ? PageId.InboxView : PageId.Board)}
        routeParams={{ boardSlug: getBoardSlug(board) }}
        {...props}
      />
    )
    : (
      <OnboardingPreviewRoute
        pageId={PageId.Main}
        {...props}
      />
    );
};
