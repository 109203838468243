import { CustomAttributeDefinitionFragment, OperatorIsEmptyOrNot, OperatorIsInOrNot } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const NONE_VALUE = 'none';

export const useCompatibility = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  const shouldDisplayWarning = useCallback(
    (attributeDefinition: CustomAttributeDefinitionFragment) => !!boardConfig?.filterProperties.edges?.find(
      (edge) => 'attribute' in edge.node && edge.node.attribute.id === attributeDefinition.id,
    ),
    [boardConfig],
  );

  const getCompatibleOptions = useCallback((attributeDefinition: CustomAttributeDefinitionFragment) => {
    if (attributeDefinition.__typename === 'AttributeSingleSelectDefinition') {
      const relativeRule = boardConfig?.filterProperties.edges.find(edge => {
        if (edge.node.__typename === 'FilterPropertyRuleSingleSelect') {
          return edge.node.attribute.id === attributeDefinition.id;
        }
        return false;
      });

      if (!relativeRule || relativeRule.node.__typename !== 'FilterPropertyRuleSingleSelect') {
        return attributeDefinition.values.edges;
      }

      if (
        relativeRule.node.singleSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.singleSelectRule.isEmptyOperator === 'IS_EMPTY'
      ) {
        return [];
      }

      return attributeDefinition.values.edges
        .filter((edge) => {
          if (
            relativeRule.node.__typename === 'FilterPropertyRuleSingleSelect' &&
            relativeRule.node.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues'
          ) {
            const isAttributeOnFilter = relativeRule.node.singleSelectRule.values.edges
              .filter(({ node }) => node.selected)
              .map(e => e.node.value.id).includes(edge.node.id);
            return relativeRule.node.singleSelectRule.operator === 'IS' ? isAttributeOnFilter : !isAttributeOnFilter;
          }
          return true;
        });
    }

    if (attributeDefinition.__typename === 'AttributeMultiSelectDefinition') {
      const relativeRule = boardConfig?.filterProperties.edges.find(({ node: rule }) => {
        if (rule.__typename === 'FilterPropertyRuleMultiSelect') {
          return rule.attribute.id === attributeDefinition.id;
        }
        return false;
      });

      if (!relativeRule || relativeRule.node.__typename !== 'FilterPropertyRuleMultiSelect') {
        return attributeDefinition.values.edges;
      }

      if (
        relativeRule.node.multiSelectRule.__typename === 'RuleIsEmptyOrNot' &&
        relativeRule.node.multiSelectRule.isEmptyOperator === 'IS_EMPTY'
      ) {
        return [];
      }

      return attributeDefinition.values.edges
        .filter((edge) => {
          if (
            relativeRule.node.__typename === 'FilterPropertyRuleMultiSelect' &&
            relativeRule.node.multiSelectRule.__typename === 'RuleMultiSelectMultipleValues'
          ) {
            const isAttributeOnFilter = relativeRule.node.multiSelectRule.values.edges
              .filter(({ node }) => node.selected)
              .map(e => e.node.id).includes(edge.node.id);
            return relativeRule.node.multiSelectRule.operator === 'IS' ? isAttributeOnFilter : !isAttributeOnFilter;
          }
          return true;
        });
    }
    return [];
  }, [boardConfig]);

  const isPropertyRequiredToBeVisible = useCallback((attributeDefinition: CustomAttributeDefinitionFragment) => {
    const relativeRule = boardConfig?.filterProperties.edges.find(edge => {
      if (edge.node.__typename === 'FilterPropertyRuleSingleSelect' ||
        edge.node.__typename === 'FilterPropertyRuleMultiSelect') {
        return edge.node.attribute.id === attributeDefinition.id;
      }
      return false;
    });

    if (relativeRule?.node.__typename === 'FilterPropertyRuleSingleSelect') {
      const shouldBeDefined =
      relativeRule.node.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues' &&
      relativeRule.node.singleSelectRule.operator === OperatorIsInOrNot.Is;

      const shouldBeNotEmpty =
      relativeRule.node.singleSelectRule.__typename === 'RuleIsEmptyOrNot' &&
      relativeRule.node.singleSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;

      return shouldBeDefined || shouldBeNotEmpty;
    }

    if (relativeRule?.node.__typename === 'FilterPropertyRuleMultiSelect') {
      const shouldBeDefined =
      relativeRule.node.multiSelectRule.__typename === 'RuleMultiSelectMultipleValues' &&
      relativeRule.node.multiSelectRule.operator === OperatorIsInOrNot.Is;

      const shouldBeNotEmpty =
      relativeRule.node.multiSelectRule.__typename === 'RuleIsEmptyOrNot' &&
      relativeRule.node.multiSelectRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;

      return shouldBeDefined || shouldBeNotEmpty;
    }

    return false;
  }, [boardConfig]);

  return {
    shouldDisplayWarning,
    getCompatibleOptions,
    isPropertyRequiredToBeVisible,
  };
};
