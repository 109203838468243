import { Button, typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { ToggleDropdown } from 'src/components/DropdownLayer';

export const ShareButton = styled(Button)`
  padding: 3px 8px;
  border: none;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Dropdown = styled(ToggleDropdown)`
  width: 450px;
  padding: 16px;
  background: ${p => p.theme.colors.background.secondary};
`;

export const Title = styled.p`
  ${typo.headerLight}
`;

export const Description = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const Field = styled.div`
  margin: 8px 0;
  position: relative;
  display: flex;
  align-items: center;
  input {
    border-radius: 6px;
    width: 100%;
    ${typo.body400};
    padding: 8px 40px 8px 12px;
    border: 0;
    background: ${p => p.theme.colors.input.bg};
    color: ${p => p.theme.colors.text.primary};
    cursor: text;
  }
  button {
    position: absolute;
    right: 8px;
  }
`;

export const Info = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 8px;
`;
