import { useQuery } from '@apollo/client';
import {
  AddNewDocAttributeValue,
  AddNewDocDocument,
  DocChildFragment,
  DoctypeFragment,
  namedOperations,
  SearchDocHierarchyDocument,
  SearchDocHierarchyQuery,
  SearchDocHierarchyQueryVariables,
} from '@cycle-app/graphql-codegen';
import { ActionButton, Emoji, TextHighlighter, Spinner } from '@cycle-app/ui';
import { CloseIcon, AddIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import {
  useState,
  useRef,
  HTMLAttributes,
  VFC,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { useUpdateChildCache } from 'src/hooks/api/cache/cacheHierarchy';
import { useProductBase } from 'src/hooks/api/useProduct';
import { usePageId } from 'src/hooks/usePageId';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { Layer } from 'src/types/layers.types';
import { getDocKey } from 'src/utils/doc.util';

import {
  Container,
  Input,
  Clear,
  DropdownLayerStyled,
  DropdownResult,
  Line,
  TagStyled,
  CreateSpan,
  MarkNewDoc,
  AddButton,
} from './InputLinkDoc.styles';

interface Props extends HTMLAttributes<HTMLInputElement> {
  onDocLinked?: (docId: string, doctypeId: string, created: boolean, doc?: DocChildFragment) => Promise<void>;
  doctype: DoctypeFragment;
  parentId?: string;
  hideInput?: VoidFunction;
  autoFocus?: boolean;
  inheritedAttributes?: AddNewDocAttributeValue[];
  sourceId?: string;
  customerId?: string;
  docFilter?: (node: DocChildFragment) => boolean;
  label?: string;
}

const CREATE_DOC_ID = 'create-doc';

const InputLinkDoc: VFC<Props> = ({
  onDocLinked,
  hideInput,
  doctype,
  autoFocus,
  parentId,
  inheritedAttributes,
  sourceId,
  customerId,
  docFilter,
  label,
  ...inputProps
}) => {
  const page = usePageId();
  const product = useProductBase();
  const [createDoc, { loading }] = useSafeMutation(AddNewDocDocument);
  const [term, setTerm] = useState('');
  const [toSearch, setToSearch] = useState('');
  const [isFocus, setIsFocus] = useState(autoFocus);

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const updateChild = useUpdateChildCache();

  const create = useCallback(async () => {
    if (!product?.id || !term || loading) return;

    setToSearch('');

    const newDocData = await createDoc({
      variables: {
        title: term,
        doctypeId: doctype.id,
        productId: product.id,
        parentId,
        attributes: inheritedAttributes,
        ...sourceId && {
          source: {
            sourceId,
          },
        },
        customerId,
      },
      refetchQueries: () => (page !== PageId.DocFullPage ? [namedOperations.Query.boardWithConfig] : []),
    });

    setTerm('');

    const newDoc = newDocData?.data?.addNewDoc;
    if (!newDoc) return;

    if (parentId) {
      updateChild({
        parentId,
        docId: newDoc.id,
        action: 'add',
      });
    }

    onDocLinked?.(newDoc.id, doctype.id, true, newDoc);
  }, [createDoc, term, onDocLinked, doctype, product, parentId, updateChild, inheritedAttributes, loading, page, customerId, sourceId]);

  const { data: docResultData } = useQuery<SearchDocHierarchyQuery, SearchDocHierarchyQueryVariables>(SearchDocHierarchyDocument, {
    variables: {
      text: toSearch,
      productId: product?.id ?? '',
      doctypeIds: [doctype.id],
      ...parentId && { hasParent: false },
    },
    skip: !product?.id || toSearch === '',
    fetchPolicy: 'cache-and-network',
  });

  const onSearchDocDebounced = useDebouncedCallback((text: string) => setToSearch(text), INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => {
    onSearchDocDebounced(term);
  }, [onSearchDocDebounced, term]);

  const docsResult = useMemo(
    () => {
      const nodes = docResultData?.searchDoc?.edges.map(({ node }) => node) ?? [];
      return docFilter ? nodes.filter(docFilter) : nodes;
    },
    [docFilter, docResultData],
  );

  const items = useMemo(() => [
    {
      id: CREATE_DOC_ID,
      onSelect: create,
    },
    ...docsResult.map((doc) => ({
      id: doc.id,
      onSelect: () => {
        setTerm('');
        setToSearch('');
        return onDocLinked?.(doc.id, doctype.id, false, doc);
      },
    })),
  ], [
    docsResult,
    create,
    onDocLinked,
    doctype,
  ]);

  const isDropdownVisible = term !== '' && isFocus;
  const optionsValues = useMemo(() => items.map(item => item.id), [items]);

  const [isInput, toggleInput] = useReducer(b => !b, !!hideInput);

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    enabled: isDropdownVisible,
    value: null,
    optionsValues,
    autoFocus: true,
    onSelect: async (itemId) => {
      await items.find(i => i.id === itemId)?.onSelect?.();
      toggleInput();
      hideInput?.();
    },
  });

  const onHideDropdown = useCallback(() => setIsFocus(false), []);

  return isInput || hideInput
    ? (
      <DropdownLayerStyled
        placement="bottom-start"
        content={renderDropdown()}
        visible={isDropdownVisible}
        hide={onHideDropdown}
        layer={Layer.DropdownModalZ3}
        animation={false}
      >
        <Container>
          <Input
            ref={inputRef}
            onChange={(e) => setTerm(e.target.value)}
            value={term}
            autoFocus
            onFocus={() => setIsFocus(true)}
            onBlur={(e) => {
              if (e.target.id !== listRef.current?.id) {
                setIsFocus(false);
              }
            }}
            onKeyDown={e => {
              if (e.code === 'Escape') {
                e.stopPropagation();
                toggleInput();
                hideInput?.();
              }
            }}
            {...inputProps}
          />
          <Clear>
            <ActionButton onClick={hideInput ?? toggleInput}>
              <CloseIcon />
            </ActionButton>
          </Clear>
        </Container>
      </DropdownLayerStyled>
    )
    : (
      <AddButton
        iconStart={<AddIcon />}
        onClick={toggleInput}
      >
        {label}
      </AddButton>
    );
  function renderDropdown() {
    return (
      <DropdownResult
        {...listProps}
        ref={listRef}
      >
        <Line
          selected={selected === CREATE_DOC_ID}
          {...itemProps(CREATE_DOC_ID)}
        >
          <CreateSpan>Create </CreateSpan>
          <MarkNewDoc>{term}</MarkNewDoc>
          {loading && <Spinner />}
        </Line>
        {docsResult.map((doc) => (
          <Line
            key={doc.id}
            selected={selected === doc.id}
            {...itemProps(doc.id)}
          >
            <Emoji emoji={doc.doctype.emoji} />
            <TagStyled>
              {getDocKey(product?.key, doc.publicId)}
            </TagStyled>
            <TextHighlighter
              searchWords={[term]}
              textToHighlight={doc.title}
              className="highlight"
            />
          </Line>
        ))}
      </DropdownResult>
    );
  }
};

export default InputLinkDoc;
