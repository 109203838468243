import { StatusFragment, StatusCategory } from '@cycle-app/graphql-codegen';
import { StatusIcon, Shortcut, SelectOption } from '@cycle-app/ui';
import { useCallback, FC, useMemo, useState, useEffect } from 'react';

import { shortcuts, ShortcutStatus } from 'src/constants/shortcuts.constants';
import { useUpdateDocsStatus } from 'src/hooks/doc/useUpdateDocsStatus';
import { useProductStatuses } from 'src/hooks/product/useProductStatuses';
import { useHotkeyListener, UseHotkeyParams } from 'src/hooks/useHotkeyListener';
import { useGetDocItem } from 'src/reactives/docItem.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutDocPanel } from 'src/types/shortcuts.types';

import { ToggleDropdown } from '../DropdownLayer';
import { Label, StyledTag, StyledSelectPanel } from './DocStatus.style';

export type DocStatusProps = {
  docId?: string;
  docTypeId: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  statusId: string;
  enableShortcut?: boolean;
};

export const DocStatus: FC<DocStatusProps> = ({
  docId,
  docTypeId,
  hideLabel,
  isDisabled = false,
  statusId,
  ...props
}) => {
  const statuses = useProductStatuses([docTypeId]);
  const statusList = useMemo(() => Object.values(statuses).flat(), [statuses]);
  const [value, setValue] = useState<StatusFragment>();

  useEffect(() => {
    setValue(statusList.find(status => status?.id === statusId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, statusId]);

  const options = statusList.map((status, i) => ({
    label: status.value ?? '',
    value: status.id ?? '',
    disabled: !status.id,
    icon: <StatusIcon category={status.category} withBackground />,
    shortcut: status.id && i < 9 ? shortcuts[ShortcutStatus[`Status${i + 1}` as ShortcutStatus]] : undefined,
  }));

  const { hoverDocId } = useGetDocItem();
  const enableShortcut = hoverDocId === docId || props.enableShortcut;

  if (!value) return null;

  if (isDisabled) {
    return (
      <Tag
        category={value.category}
        value={value.value}
        hideLabel={hideLabel}
        isReadOnly
      />
    );
  }

  return (
    <ToggleDropdown
      placement="bottom-start"
      layer={Layer.DropdownModal}
      shortcut={enableShortcut ? ShortcutDocPanel.Status : undefined}
      button={buttonProps => (
        <Tag
          category={value.category}
          value={value.value}
          hideLabel={hideLabel}
          {...buttonProps}
        />
      )}
      content={contentProps => (
        <StatusPanel
          onDone={contentProps.hide}
          options={options}
          docId={docId}
          value={value}
          setValue={setValue}
          statusList={statusList}
        />
      )}
    />
  );
};

type TagProps = {
  category: StatusCategory;
  isReadOnly?: boolean;
  value: string;
  hideLabel?: boolean;
};

const Tag: FC<TagProps> = ({
  category,
  isReadOnly = false,
  value,
  hideLabel,
  ...props
}) => {
  const statusLabel = (
    <Label>
      <StatusIcon category={category} style={{ color: 'currentcolor' }} />
      {value}
    </Label>
  );

  return (
    <StyledTag
      $category={category}
      color="grey"
      tooltip={{
        ...(isReadOnly ? {
          content: statusLabel,
        } : {
          title: statusLabel,
          content: (
            <Label>
              Change status
              <Shortcut keys={shortcuts[ShortcutDocPanel.Status]} />
            </Label>
          ),
        }),
        placement: 'top',
        withPortal: true,
      }}
      {...props}
    >
      <Label>
        <StatusIcon category={category} />
        {!hideLabel && value}
      </Label>
    </StyledTag>
  );
};

const StatusPanel = ({
  docId, onDone, options, value, setValue, statusList,
}: {
  docId?: string;
  onDone: VoidFunction;
  options: SelectOption[];
  value?: StatusFragment;
  setValue: (status?: StatusFragment) => void;
  statusList: StatusFragment[];
}) => {
  const { updateDocsStatus } = useUpdateDocsStatus();

  const handleChange = useCallback((id: string) => {
    const newStatus = statusList.find(status => status?.id === id);
    if (!newStatus || !docId) return;
    onDone();
    setValue(newStatus);
    // The mutation updateDocStatus causes a lot of re-rendering
    // Delaying it improves performance
    setTimeout(() => {
      updateDocsStatus([docId], id, false)
        .catch(() => setValue(value));
    }, 200);
  }, []);

  useHotkeyListener(statusList.slice(0, 9).reduce<UseHotkeyParams<ShortcutStatus>>((acc, s, index) => {
    const key = ShortcutStatus[`Status${index + 1}` as ShortcutStatus];
    acc.shortcuts.push(key);
    acc.callbacks[key] = () => handleChange(s.id);
    return acc;
  }, {
    callbacks: {},
    shortcuts: [],
  }));

  return (
    <StyledSelectPanel
      options={options}
      onOptionChange={option => handleChange(option.value)}
    />
  );
};
