import { CustomerCompanies } from 'src/components/CustomersList/CustomersCompanies';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';

export const CompaniesView = () => (
  <>
    <SettingsViewHeader title="Companies" />
    <BoardConfigContextProvider>
      <CustomerCompanies />
    </BoardConfigContextProvider>
  </>
);
