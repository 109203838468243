import { DocFullFragment, DocLinkDataFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { ImageIcon, NoCommentIcon, UserIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import DocComments from 'src/components/DocComments/DocComments';
import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import { setCustomerProfileModal, setCompanyProfileModal } from 'src/reactives';
import { useResponsive } from 'src/reactives/responsive.reactive';

import {
  SavingLabelStyled,
  Container,
  AddCoverAction,
  StyledDocPanelBreadcrumb,
} from './DocPanelActions.styles';

interface Props {
  className?: string;
  doc: DocFullFragment;
  onAddCoverClicked: VoidFunction;
  isUploadingCover: boolean;
  isDraggingCover: boolean;
  startOpenedOnComment?: string;
  isVertical?: boolean;
  minimalBreadcrumb?: boolean;
  docSource?: DocLinkDataFragment | null;
}

const DocPanelActions: FC<Props> = ({
  className,
  doc,
  onAddCoverClicked,
  isUploadingCover,
  isDraggingCover,
  startOpenedOnComment,
  isVertical,
  minimalBreadcrumb,
  docSource,
}) => {
  const [{ breakpoint }] = useResponsive();
  const coverActionLabel = getCoverActionButtonLabel();
  const isCoverActionEmphasized = isUploadingCover || isDraggingCover;
  const isMobile = breakpoint === 'mobile';

  return (
    <Container className={className}>
      {isVertical && <SavingLabelStyled />}
      <DocAssignee
        docId={doc.id}
        assignee={doc.assignee}
        tooltipPlacement="bottom"
        showLabel={!isMobile}
        showAssigneeName
        size="L"
        buttonIcon={<UserIcon />}
        isDisabled={!!docSource}
        isRemovable={doc.doctype.type === DoctypeType.Custom}
      />
      {doc?.doctype.customer && (
        <DocCustomer
          onCustomerModalOpen={customerId => setCustomerProfileModal({
            customerId,
            isOpen: true,
          })}
          onCompanyModalOpen={companyId => setCompanyProfileModal({
            companyId,
            isOpen: true,
          })}
          doc={doc}
          isDisabled={!!docSource}
          isRemovable={doc.doctype.type === DoctypeType.Custom}
        />
      )}
      <DocComments
        docId={doc.id}
        nbComments={doc.commentsCount}
        startOpenedOnComment={startOpenedOnComment}
        tooltipPlacement="bottom"
        size="L"
        showLabel={!isMobile}
        buttonIcon={<NoCommentIcon />}
        maxHeight="70vh"
      />
      <AddCoverAction
        label={coverActionLabel}
        onClick={onAddCoverClicked}
        userColor={isCoverActionEmphasized}
        disabled={isUploadingCover}
        tooltipPlacement="bottom"
        size="L"
      >
        <ImageIcon />
        {!isMobile && <span>{coverActionLabel}</span>}
      </AddCoverAction>

      {minimalBreadcrumb && (
        <StyledDocPanelBreadcrumb
          doc={doc}
          showParentTitle={false}
        />
      )}
      {!isVertical && <SavingLabelStyled />}
    </Container>
  );

  function getCoverActionButtonLabel(): string {
    if (isUploadingCover) return 'Uploading...';
    return doc?.cover?.url
      ? 'Update cover'
      : 'Add cover';
  }
};

export default DocPanelActions;
