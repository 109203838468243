import { FC, useEffect } from 'react';

import { useMaybeMe } from 'src/hooks/api/useMe';
import { useProductBase } from 'src/hooks/api/useProduct';
import { groupAnalytics, identifyAnalytics, loadAnalytics } from 'src/utils/analytics/analytics';

import { useRole } from '../hooks/useRoles';

/**
 * The useEffect hook need to be isolated outside of the App to avoid
 * trigerring a re-render each time me changes
 */
const Analytics: FC = () => {
  const me = useMaybeMe();
  const productRole = useRole();
  const product = useProductBase();

  useEffect(() => {
    if (process.env.SEGMENT_KEY) {
      loadAnalytics();
    }
  }, []);

  useEffect(() => {
    if (product && me) {
      const {
        id, email, firstName, lastName,
      } = me;
      identifyAnalytics(id, {
        email,
        name: `${firstName} ${lastName}`,
        jobTitle: me.jobTitle ? me.jobTitle : null,
        onboarded: me.onboarded,
        company: {
          id: product.id,
          name: product.name,
        },
        role: productRole.role ?? productRole.globalRole,
        productSlug: product.slug,
        productId: product.id,
        productName: product.name,
      });
      groupAnalytics(product.id, {
        productId: product.id,
        name: product.name,
        slug: product.slug,
      });
    }
  }, [me?.id, me?.email, me?.firstName, me?.lastName, me?.onboarded, product?.name, product?.id, product?.slug, productRole.role]);

  return null;
};

export default Analytics;
