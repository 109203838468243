import { useQuery } from '@apollo/client';
import { ProductBaseFragment, ProductBaseBySlugDocument, ProductBaseBySlugQueryVariables } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useEffect } from 'react';

import { useMaybeMe } from 'src/hooks/api/useMe';
import useQueryParams from 'src/hooks/useQueryParams';

import { useSetProductSlug } from './useSetProductSlug';

export const useProductBase = () => {
  const setCurrentProductSlug = useSetProductSlug();
  const me = useMaybeMe();
  const query = useQueryParams();

  const productSlug = query.get('product');
  const products = nodeToArray(me?.products);
  const productInCache = products.find(p => p.slug === productSlug);

  const result = useQuery<{ product: ProductBaseFragment }, ProductBaseBySlugQueryVariables>(ProductBaseBySlugDocument, {
    skip: !productSlug || !me || !!productInCache,
    fetchPolicy: 'cache-first',
    variables: {
      slug: productSlug as string,
    },
  });

  const productFound = productInCache ?? result.data?.product;
  const productBase = productFound ?? (me ? products[0] : undefined);

  useEffect(() => {
    if (!me || !productBase || result.loading) return;
    setCurrentProductSlug(productBase.slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, productBase]);

  return {
    product: productBase,
    isLoading: result.loading,
  };
};
