import { CheckIcon } from '@cycle-app/ui/icons';
import { ReactChild } from 'react';
import { Handle, Position, NodeProps } from 'react-flow-renderer';

import { Check, NodeContainer } from './StepIntegrations.styles';

export interface CustomNodeData {
  label?: ReactChild;
  installed?: boolean;
  isReadOnly?: boolean;
}

export const CustomNode = ({ data }: NodeProps<CustomNodeData>) => (
  <>
    <Handle type="target" position={Position.Left} />
    <NodeContainer
      initial={{
        opacity: 0,
        translateY: 10,
      }}
      animate={{
        opacity: (data.installed || data.isReadOnly) ? 1 : 0.5,
        translateY: 0,
      }}
      transition={{
        delay: 1,
      }}
      $installed={data.installed}
    >
      {data.label}
    </NodeContainer>
    {!data.isReadOnly && data.installed && (
      <Check
        initial={{
          opacity: 0,
          translateY: 10,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
        }}
        transition={{
          delay: 1,
        }}
      >
        <CheckIcon />
      </Check>
    )}
    <Handle type="source" position={Position.Right} />
  </>
);
