import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { FetchDocLinkedDocsDocument, FetchDocLinkedDocsQuery } from '@cycle-app/graphql-codegen/generated';
import { produce } from 'immer';
import { useMemo } from 'react';

import { useProduct } from 'src/hooks/api/useProduct';
import { getDocKey } from 'src/utils/doc.util';

interface Options {
  onCompleted: (data: FetchDocLinkedDocsQuery) => void;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

/**
 * Doc linked are used as insights for a feedback doc type doc. We might need
 * in the future to create any links between any doc type but for now we don't
 * allow that.
 *
 * So an insight is a doc link as a target from a feedback source.
 */
export const useDocInsights = (docId?: string, options?: Options) => {
  const { product } = useProduct();

  const {
    data, loading,
  } = useQuery(FetchDocLinkedDocsDocument, {
    skip: options?.skip || !docId,
    variables: {
      id: docId,
    },
    fetchPolicy: options?.fetchPolicy,
    onCompleted: options?.onCompleted,
  });

  const insights = useMemo(() => {
    if (data?.node?.__typename !== 'Doc') return [];

    const results = data.node.docTargets.edges.map(e => e.node);

    return produce(results, draft => {
      draft.forEach(d => {
        if (d?.doc) {
          // eslint-disable-next-line no-param-reassign
          d.doc._docKey = getDocKey(product?.key, d?.doc?.publicId);
        }
      });
    });
  }, [data, product]);

  return {
    insights,
    isLoading: loading,
  };
};
