import { useEffect } from 'react';

import { getOtherUserId } from 'src/utils/me.util';

import { useMaybeMe } from '../api/useMe';
import { useAppParams } from '../useAppParams';
import { PresenceChannel, UserLocation } from './types';

export const useTriggerLocation = (channel: PresenceChannel, eventName: string) => {
  const me = useMaybeMe();
  const meOtherUserId = getOtherUserId(me?.id ?? '');

  const {
    boardId: boardIdParam,
    docId: docIdParam,
  } = useAppParams();

  useEffect(() => {
    if (!me) return;

    const userLocation: UserLocation = {
      userId: meOtherUserId,
      docId: docIdParam ?? null,
      boardId: boardIdParam ?? null,
    };

    channel?.trigger(eventName, userLocation);
  }, [boardIdParam, channel, docIdParam, eventName, me, meOtherUserId]);
};
