import { Avatar, SelectPanel } from '@cycle-app/ui';
import { useFormContext, useWatch } from 'react-hook-form';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { useUsersByProductId } from 'src/hooks/api/useUsers';
import { getUserLabel } from 'src/utils/users.util';

import { useProductBase } from '../Product';
import { FieldButton, SkeletonInput } from './Form.styles';
import { FormData } from './Form.types';

export const FieldAssignee = () => {
  const { setValue } = useFormContext<FormData>();
  const value = useWatch<FormData>({ name: 'assignee' });

  const { product } = useProductBase();
  const me = useMaybeMe();

  const {
    users, fetchMore, loading, pageInfo,
  } = useUsersByProductId(product?.id);

  const options = users
    .map(user => ({
      label: getUserLabel(user),
      value: user.id,
      icon: <Avatar user={user} size={20} />,
    }));

  const selectedUser = users.find(user => user.id === value) || users.find(user => user.id === me?.id);

  return (
    <ToggleDropdown
      placement="bottom-start"
      button={props => (loading ? <SkeletonInput /> : (
        <FieldButton
          iconStart={selectedUser && <Avatar user={selectedUser} size={20} />}
          {...props}
        >
          {selectedUser
            ? getUserLabel(selectedUser)
            : 'Choose from list'}
        </FieldButton>
      ))}
      content={props => (
        <SelectPanel
          options={options}
          selectedValue={value}
          onOptionChange={option => {
            setValue('assignee', option.value);
            props.hide();
          }}
          infiniteScroll={{
            isLoading: loading,
            hasMoreData: pageInfo.hasNextPage,
            loadMore: async () => {
              if (!pageInfo.endCursor) return;
              await fetchMore(pageInfo.endCursor);
            },
          }}
        />
      )}
    />
  );
};
