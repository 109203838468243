import { typo, truncate, transitionCss, ShyScrollbarCss } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { Tabs as ReachTabs, TabList as ReachTabList, Tab } from '@reach/tabs';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Tabs = styled(ReachTabs)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 8px 0 8px 32px;
  border-bottom: 1px solid ${p => p.theme.colors.home.separator};

  ${mobile} {
    padding: 8px 16px;
  } 
`;

export const TabList = styled(ReachTabList)`
  ${ShyScrollbarCss}
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-width: 0;
  overflow-x: scroll; // Always show the scroll bar for margin consistency
  overflow-y: visible;
  padding-right: 32px;
  padding-bottom: 10px;
  margin-bottom: -18px; // Compensate the scrollbar space
`;

export const TooltipContent = styled.div`
  color: ${p => p.theme.colors.tooltip.textAlt};
`;

export const TabLink = styled(Link)`
  ${truncate}
  ${typo.body400}
  display: block;
  height: 30px;
  padding: 3px 8px;
  outline: none;
  border-radius: 4px;
  color: ${p => p.theme.colors.text.secondary};
  &:hover, &:focus, &.force-focus {
    background-color: ${p => p.theme.colors.actionButton.bgHover};
    color: ${p => p.theme.colors.text.primary};
  }
  &:active {
    background-color: ${p => p.theme.colors.actionButton.bgActive};
    color: ${p => p.theme.colors.text.primary};
  }
  [role=img] {
    vertical-align: middle;
    margin-right: 4px;
  }
`;

export const StyledTab = styled(Tab) <{ $isActive?: boolean }>`
  position: relative;
  width: min-content;
  max-width: 200px;

  :after {
    content: "";
    height: 2px;
    width: 100%;
    background: ${p => p.theme.userColors.main};
    position: absolute;
    left: 0;
    bottom: -9.5px;
    opacity: 0;
    transform: scale(0.75);
    ${transitionCss(['transform', 'opacity'])}
  }

  ${p => p.$isActive && css`
    flex-shrink: 0;
    :after {
      opacity: 1;
      transform: scale(1);
    }

    ${TabLink} {
      ${typo.body500}
      color: ${p.theme.colors.text.primary};
    }
  `}
`;
