/* eslint-disable no-nested-ternary */
import { Color, Role } from '@cycle-app/graphql-codegen/generated';
import { Avatar } from '@cycle-app/ui';
import { CustomerIconOutline, UserIcon } from '@cycle-app/ui/icons';
import { useCallback, useEffect, VFC } from 'react';

import DocParent from 'src/components/DocParent/DocParent';
import { Events } from 'src/constants/analytics.constants';
import { useFullDoc, useDoc } from 'src/hooks/api/useDoc';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { StyledAvatar } from '../DocAssignee/DocAssignee.styles';
import EditorSkeleton from '../EditorSkeleton/EditorSkeleton';
import {
  Container,
  Header,
  Title,
  StyledDocAttributes,
  StyledReadOnlyEditor,
  Assignee,
  AssigneeFullname,
} from './DocPreview.styles';

interface Props {
  className?: string;
  id: string;
}
const DocPreview: VFC<Props> = ({
  id,
  ...otherProps
}) => {
  const { doc: partialDocFromCache } = useDoc(id, false, 'cache-only');
  const { doc } = useFullDoc({ docId: id });
  const {
    yDoc, isSync,
  } = useRealtimeEditorConfig({ docId: id });

  const preventKeyPress = useCallback((e: KeyboardEvent) => e.preventDefault(), []);

  useEffect(() => {
    trackAnalytics(Events.DocPreview);
    window.addEventListener('keydown', preventKeyPress);
    return () => {
      window.removeEventListener('keydown', preventKeyPress);
    };
  }, [preventKeyPress]);

  if (!partialDocFromCache) return null;

  return (
    <Container {...otherProps}>
      <Header>
        {partialDocFromCache.parent && (
          <DocParent
            doc={partialDocFromCache.parent}
            minimal
            readonly
          />
        )}

        <Assignee>
          {partialDocFromCache.assignee
            ? (
              <>
                <Avatar user={partialDocFromCache.assignee} size={20} />
                <AssigneeFullname>{`${partialDocFromCache.assignee.firstName} ${partialDocFromCache.assignee.lastName}`}</AssigneeFullname>
              </>
            )
            : (
              <>
                <UserIcon />
                No assignee
              </>
            )}
          {// eslint-disable-next-line no-nested-ternary
          doc?.doctype.customer
            ? doc.customer?.id
              ? (
                <>
                  <StyledAvatar
                    size={20}
                    user={{
                      id: doc.customer.id,
                      firstName: doc.customer.name,
                      email: doc.customer.email || '',
                      color: Color.A,
                      role: Role.User,
                      incognito: false,
                    }}
                  />
                  {doc.customer.name || doc.customer.email}
                </>
              )
              : (
                <>
                  <CustomerIconOutline />
                  No customer
                </>
              ) : null
}
        </Assignee>
      </Header>

      <Title>{partialDocFromCache.title}</Title>
      {doc && (
        <StyledDocAttributes
          doc={doc}
          readOnly
          context="doc-panel"
          showCreator={false}
          showCreatedAt={false}
        />
      )}

      {!isSync && (
        <EditorSkeleton nbLines={11} />
      )}
      {yDoc && isSync && (
        <StyledReadOnlyEditor
          interactive={false}
          collaboration={{ document: yDoc }}
        />
      )}
    </Container>
  );
};

export default DocPreview;
