import { useCallback } from 'react';

import { useToaster } from './useToaster';

interface HookArgs {
  successNotification?: string;
  onTextCopied?: (textCopied: string) => void;
}
type HookResult = (textToCopy: string) => void;

export function useCopyToClipboard({
  successNotification: notification,
  onTextCopied,
}: HookArgs): HookResult {
  const { add: addToaster } = useToaster();

  const copyTextToClipboard = useCallback(async (text: string) => {
    await navigator.clipboard.writeText(text);

    onTextCopied?.(text);

    if (notification) {
      addToaster({
        title: 'Copied to clipboard',
        message: notification,
      });
    }
  }, [addToaster, onTextCopied, notification]);

  return copyTextToClipboard;
}
