import styled, { css } from 'styled-components';

type ContentProps = {
  placeholder?: string;
  $isDisabled: boolean;
};

export const Content = styled.h1<ContentProps>`
  cursor: ${p => (p.$isDisabled ? 'not-allowed' : 'text')};

  ${p => p.placeholder && css`
    &:empty::before {
      content: '${p.placeholder}';
      color: ${p.theme.colors.text.disabled};
    }
  `}
`;
