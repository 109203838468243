import { FC } from 'react';

import { useCustomerDocs } from 'src/hooks/api/queries/customers/useCustomerDocs';

import { DoctypeCounter } from '../DocHierarchy/DocHierarchyGroup/DocHierarchyGroup.styles';
import { CounterSkeleton } from './CustomerChildrenTabs.styles';

interface Props {
  customerId: string;
  doctypeId: string;
}

export const CustomerChidrenTabsCounter: FC<Props> = ({
  customerId, doctypeId,
}) => {
  const {
    count,
    isLoading,
  } = useCustomerDocs({
    doctypeId,
    id: customerId,
  });
  return (
    <DoctypeCounter>
      {isLoading ? <CounterSkeleton /> : count}
    </DoctypeCounter>
  );
};
