import {
  DocAttributeFragment,
  CustomAttributeDefinition,
  DocBaseFragment,
} from '@cycle-app/graphql-codegen';
import { Tag, TooltipProps } from '@cycle-app/ui';
import { CalendarIcon } from '@cycle-app/ui/icons';
import { nodeToArray, toTagDate } from '@cycle-app/utilities';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { Placement } from 'tippy.js';

import DocPrimaryAttributes from 'src/components/DocPrimaryAttributes/DocPrimaryAttributes';
import { DocSource } from 'src/components/DocSource/DocSource';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import PropertyDropdownValue from 'src/components/PropertyDropdownValue/PropertyDropdownValue';
import { useAttributes } from 'src/hooks/api/useAttributes';
import { Layer } from 'src/types/layers.types';
import { ViewType } from 'src/types/viewType.types';
import {
  getCustomAttributeTypeData,
  getDocAttributeValue,
  getAttributeIdsOrder,
  AttributeIdsOrder,
  isAttributeScalar,
} from 'src/utils/attributes.util';
import { shouldShowSource } from 'src/utils/doc.util';
import { isBuiltIn } from 'src/utils/docType.util';

import { Container, Info, Url, TooltipSubtitle } from './DocAttributes.styles';

interface Props {
  className?: string;
  doc: Partial<Pick<DocBaseFragment, 'doctype' | 'attributes' | 'createdAt' | 'source' | 'creator' | 'id' | 'status'>>;
  displayedPropertiesIds?: string[];
  isDragging?: boolean;
  viewType?: ViewType;
  displayPrimaryAttributes?: boolean;
  dropdownPlacement?: Placement;
  context?: 'doc-item' | 'doc-panel';
  showCreator?: boolean;
  showCreatedAt?: boolean;
  showDocId?: boolean;
  showDocType?: boolean;
  showSource?: boolean;
  showStatus?: boolean;
  layer?: Layer;
  readOnly?: boolean;
  limitSize?: boolean;
  isDocTypeReadOnly?: boolean;
  enableStatusShortcut?: boolean;
}

const DocAttributes: FC<Props> = ({
  className,
  viewType,
  displayPrimaryAttributes = true,
  displayedPropertiesIds = [],
  dropdownPlacement = 'bottom',
  doc,
  isDragging,
  children,
  context = 'doc-item',
  showCreator = true,
  showDocId = true,
  showDocType = true,
  showStatus = true,
  layer = Layer.Dropdown,
  readOnly = false,
  limitSize,
  isDocTypeReadOnly = false,
  enableStatusShortcut,
  ...props
}) => {
  const productAttributes = useAttributes();
  const [dropdownAttribute, setDropdownAttribute] = useState<{
    id: string;
    selectValue: string | null;
  } | null>(null);

  const onHideDropdown = useCallback(() => setDropdownAttribute(null), []);
  const onAttributeClicked = useCallback((attribute: CustomAttributeDefinition, value: string) => (e: MouseEvent) => {
    e.preventDefault();
    if (attribute && value) {
      setDropdownAttribute(dropdownAttribute ? null : {
        id: attribute.id,
        selectValue: isAttributeScalar(attribute) ? null : value,
      });
    }
  }, [dropdownAttribute]);

  const onRemoveValue = useCallback((attributeId: string, valueId: string) => {
    if (dropdownAttribute?.id === attributeId && dropdownAttribute?.selectValue === valueId) {
      setDropdownAttribute(null);
    }
  }, [dropdownAttribute?.id, dropdownAttribute?.selectValue]);

  // TODO: to remove once the api has implemented attributes order
  const doctypeAttributeDefIdsOrder: AttributeIdsOrder = useMemo(
    () => getAttributeIdsOrder(nodeToArray(doc?.doctype?.attributeDefinitions)),
    [doc?.doctype?.attributeDefinitions],
  );
  const globalAttributeDefIdsOrder: AttributeIdsOrder = useMemo(
    () => getAttributeIdsOrder(productAttributes),
    [productAttributes],
  );

  const attributesSortedByDoctypeOrder = useMemo(
    () => {
      if (!doc) return [];

      return nodeToArray(doc.attributes)
        .sort((attr1, attr2) => (doctypeAttributeDefIdsOrder[attr1.definition.id] > doctypeAttributeDefIdsOrder[attr2.definition.id] ? 1 : -1));
    },
    [doc, doctypeAttributeDefIdsOrder],
  );
  const attributesSortedByGlobalOrder = useMemo(
    () => {
      if (!doc) return [];

      return nodeToArray(doc.attributes)
        .sort((attr1, attr2) => (globalAttributeDefIdsOrder[attr1.definition.id] > globalAttributeDefIdsOrder[attr2.definition.id] ? 1 : -1));
    },
    [doc, globalAttributeDefIdsOrder],
  );
  const docAttributes = viewType === ViewType.List
    ? attributesSortedByGlobalOrder
    : attributesSortedByDoctypeOrder;

  const displayAttributesFilter = useCallback(({ definition }: DocAttributeFragment) => (context === 'doc-panel'
    ? true
    : displayedPropertiesIds.includes(definition.id)),
  [displayedPropertiesIds, context]);

  const createdAtFormatted = doc?.createdAt ? toTagDate(doc.createdAt) : '';
  const tooltipBaseProps: Partial<TooltipProps> = {
    placement: 'top',
    withPortal: true,
    disabled: isDragging,
  };

  const showSource = (props.showSource ?? true) && doc && shouldShowSource(doc);
  const showCreatedAt = (props.showCreatedAt ?? true) && !!createdAtFormatted;

  if ((!displayPrimaryAttributes || (!showDocId && !showDocType && !showStatus)) &&
      !showSource && !showCreator && !showCreatedAt &&
      docAttributes.length === 0) {
    return null;
  }

  return (
    <Container
      className={className}
      viewType={viewType}
      nbAttributes={docAttributes.length}
      readOnly={readOnly}
      $context={context}
    >
      {displayPrimaryAttributes && doc && (
        <DocPrimaryAttributes
          doc={doc}
          viewType={ViewType.Kanban}
          dropdownPlacement={dropdownPlacement}
          layer={layer}
          readOnly={readOnly}
          showDocId={showDocId}
          showDocType={showDocType}
          showStatus={showStatus}
          context={context}
          isDocTypeReadOnly={isDocTypeReadOnly || isBuiltIn(doc.doctype)}
          enableStatusShortcut={enableStatusShortcut}
        />
      )}
      {showSource && (
        <DocSource
          source={doc.source}
          context={context}
        />
      )}
      {showCreator && (
        <Tag
          limitSize={limitSize}
          tooltip={{
            ...tooltipBaseProps,
            content: 'Creator',
          }}
        >
          {doc?.creator ? `${doc.creator.firstName} ${doc.creator.lastName}` : null}
        </Tag>
      )}
      {showCreatedAt && (
        <Tag
          limitSize={limitSize}
          tooltip={{
            ...tooltipBaseProps,
            title: (
              <>
                <CalendarIcon />
                {' '}
                Creation date
              </>
            ),
            content: createdAtFormatted,
          }}
        >
          {createdAtFormatted}
        </Tag>
      )}

      {docAttributes.filter(displayAttributesFilter).map((attribute) => {
        const { definition } = attribute;
        const attributeData = getCustomAttributeTypeData(definition.__typename ?? 'AttributeTextDefinition');
        const attributeValueRaw = getDocAttributeValue(attribute as DocAttributeFragment);
        const attributeValues = Array.isArray(attributeValueRaw) ? attributeValueRaw : [attributeValueRaw];

        return attributeValues.map(attributeValue => {
          if (attributeValue == null) return null;

          const key = isAttributeScalar(attribute.definition as CustomAttributeDefinition)
            ? definition.id
            : `${definition.id}-${attributeValue}`;
          const isPropertyDropdownVisible =
            dropdownAttribute?.id === definition.id &&
            (dropdownAttribute.selectValue === null || dropdownAttribute.selectValue === String(attributeValue));

          return (
            <DropdownLayer
              key={key}
              closingArea={false}
              placement={dropdownPlacement}
              content={(
                doc.id && (
                  <PropertyDropdownValue
                    docId={doc.id}
                    attributeDefinitionId={definition.id}
                    hide={onHideDropdown}
                    onRemoveValue={onRemoveValue}
                  />
                )
              )}
              visible={isPropertyDropdownVisible}
              hide={onHideDropdown}
            >
              <Tag
                color={definition.color}
                onClick={onAttributeClicked(definition as CustomAttributeDefinition, String(attributeValue))}
                limitSize={limitSize}
                tooltip={{
                  ...tooltipBaseProps,
                  content: (
                    <div>
                      <Info>
                        {attributeData.icon}
                        <span>{definition.name}</span>
                      </Info>
                      {definition.__typename === 'AttributeUrlDefinition' && (
                        <Url>
                          {attributeValue}
                        </Url>
                      )}
                      <TooltipSubtitle>
                        Click to update
                      </TooltipSubtitle>
                    </div>
                  ),
                }}
                {...definition.__typename === 'AttributeUrlDefinition' && {
                  externalLink: String(attributeValue),
                }}
              >
                {(() => {
                  switch (definition.__typename) {
                    case ('AttributeUrlDefinition'): return definition.name;
                    case ('AttributeDateDefinition'): return toTagDate(String(attributeValue));
                    default: return attributeValue;
                  }
                })()}
              </Tag>
            </DropdownLayer>
          );
        });
      })}
      {children}
    </Container>
  );
};

export default DocAttributes;
