import { captureException } from '@sentry/browser';
import { Component, ErrorInfo, ReactNode } from 'react';

import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { isStagingEnv } from 'src/utils/env.util';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (isStagingEnv()) {
      console.group();
      console.warn(`[ERROR CAUGHT IN "componentDidCatch"]
        ==========================
        ERROR LOGS
        ==========================
      `);
      console.error(error);
      console.warn(`
        ==========================
        ERROR INFOS
        ==========================
      `);
      console.error(errorInfo);
      console.groupEnd();
    }
    captureException(error, {
      extra: {
        errorInfo,
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) return <ErrorPage />;

    return children;
  }
}
