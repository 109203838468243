import { FC } from 'react';
import { useController, Control } from 'react-hook-form';

import { GithubStatusesSelectDropdown } from 'src/components/GithubStatusesSelectDropdown/GithubStatusesSelectDropdown';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { CreateGithubIssueFormValues } from 'src/types/integrations.types';

import { Container, Label, Caret, Select } from './StatusesField.styles';

type Props = {
  control: Control<CreateGithubIssueFormValues>;
};

export const StatusesField: FC<Props> = ({ control }) => {
  const { statuses } = useGithubProject();
  const [isVisible, { toggleCallback }] = useOptimizedBooleanState(false);
  const {
    field: {
      onChange, onBlur, name, ref, value,
    },
  } = useController({
    name: 'status',
    control,
    defaultValue: null,
  });

  return (
    <Container>
      <Label>Status</Label>
      <GithubStatusesSelectDropdown
        statuses={statuses || []}
        isVisible={isVisible}
        onClose={() => {
          onBlur();
          toggleCallback();
        }}
        selectedStatus={value || ''}
        onChange={(newStatus) => {
          onChange(typeof newStatus === 'string' ? null : newStatus.id);
        }}
        isLoading={false}
      >
        <Select
          name={name}
          ref={ref}
          onClick={toggleCallback}
          type="button"
          forceFocus={isVisible}
          disabled={!statuses?.length}
        >
          {value ? statuses?.find(s => s.id === value)?.name ?? '' : 'None'}
          <Caret />
        </Select>
      </GithubStatusesSelectDropdown>
    </Container>
  );
};
