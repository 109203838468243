import { StatusFragment, StatusType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { AddIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import differenceBy from 'lodash/differenceBy';
import { useMemo } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useAddStatusDocType } from 'src/hooks/status';
import { useSortedDocTypes, useSortedDocTypesFromCache } from 'src/hooks/useSortedDocTypes';
import { Layer } from 'src/types/layers.types';
import { isNotInsight } from 'src/utils/docType.util';

import { SelectDocType } from './SelectDocType';
import { DocTypePanel, LinkNew, DocTypeLine, DocTypeName } from './SettingsWorkflows.styles';
import { UnlinkStatusDocType } from './UnlinkStatusDocType';

type Props = {
  status: StatusFragment;
  docTypeIds: string[];
  currentDocTypeId?: string;
};

export const LinkedDocTypesPanel = ({
  status, docTypeIds, currentDocTypeId,
}: Props) => {
  const { addStatusDocType } = useAddStatusDocType();

  const linkedDocTypes = useSortedDocTypesFromCache(docTypeIds, currentDocTypeId);
  const allDocTypes = useSortedDocTypes();
  const unlinkedDocTypes = useMemo(() => {
    return differenceBy(allDocTypes, linkedDocTypes, d => d.id)
      .filter(isNotInsight);
  }, [allDocTypes, linkedDocTypes]);

  const isEditable = status.type !== StatusType.LoopClosed;

  return (
    <ToggleDropdown
      placement="right-start"
      offset={[0, 8]}
      layer={Layer.DropdownZ1}
      button={props => (
        <DocTypePanel>
          {linkedDocTypes.map(docType => (
            <DocTypeLine
              key={docType.id}
              $withAction
            >
              <Emoji emoji={docType.emoji} size={14} inline />
              <DocTypeName>{docType.name}</DocTypeName>
              {isEditable && (
                <UnlinkStatusDocType
                  docTypeId={docType.id}
                  status={status}
                />
              )}
            </DocTypeLine>
          ))}

          {!isEditable && (
            <DocTypeLine>
              <InfoIconOutline size={14} />
              Doc types can’t be edited for this status
            </DocTypeLine>
          )}

          {isEditable && unlinkedDocTypes.length > 0 && (
            <DocTypeLine as={LinkNew} {...props}>
              <AddIcon size={11} />
              Link new
            </DocTypeLine>
          )}
        </DocTypePanel>
      )}
      content={(
        <SelectDocType
          docTypes={unlinkedDocTypes}
          onSelect={docTypeId => addStatusDocType(status, docTypeId)}
        />
      )}
    />
  );
};
