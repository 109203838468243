import { CompanyFragment } from '@cycle-app/graphql-codegen/generated';
import { HTMLAttributes } from 'react';

import { BuildingIcon } from '../../../icons';
import { Container, ImageContainer, Size, Logo } from './CompanyLogo.styles';

export interface CompanyLogoProps extends HTMLAttributes<unknown> {
  className?: string;
  company?: CompanyFragment | null;
  src?: string | null;
  size?: Size;
  isEditable?: boolean;
}

export const CompanyLogo = ({
  size = 'M', company, src, isEditable, ...props
}: CompanyLogoProps) => {
  const logoSrc = src ?? company?.logo;

  return (
    <Container $size={size} $isEditable={isEditable} {...props}>
      <ImageContainer>
        {logoSrc
          ? <Logo alt="logo" src={logoSrc} />
          : (
            <Logo as="div">
              {company && (company.name?.trimStart()[0] || <BuildingIcon />)}
            </Logo>
          )}
      </ImageContainer>
    </Container>
  );
};
