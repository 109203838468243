import { IntegrationFullFragment } from '@cycle-app/graphql-codegen';

import { CHROME_STORE_CYCLE_APP_URL, ZAPIER_CYCLE_APP_URL } from 'src/constants/integrations.constants';
import { useCopyToClipboard } from 'src/hooks';
import { Integration } from 'src/types/integrations.types';

export const useIntegrationAction = () => {
  const copyEmailToClipboard = useCopyToClipboard({
    successNotification: 'Email copied to clipboard',
  });

  return (type: Integration, integration?: IntegrationFullFragment) => {
    switch (type) {
      case 'CHROME': return () => {
        window.open(CHROME_STORE_CYCLE_APP_URL, '_blank');
      };
      case 'ZAPIER': return () => {
        window.open(ZAPIER_CYCLE_APP_URL, '_blank');
      };
      case 'MAIL': return () => {
        if (integration?.provider?.__typename !== 'Mail') return;
        copyEmailToClipboard(integration?.provider.mail);
      };
      default: return undefined;
    }
  };
};
