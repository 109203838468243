import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  useActiveProductTour,
  getActiveProductTourEl,
  setActiveProductTour,
} from 'src/reactives/productTours.reactive';
import { TourName, TourStep } from 'src/types/productTour.types';

export const useDocPanelProductTour = () => {
  const tour = useActiveProductTour();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);

  const isActive = isEnabled && tour?.name === TourName.FIRST_TOUR;

  const setEditorEl = (el?: HTMLElement | null) => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.HighlightFeedbackText ||
      !el ||
      getActiveProductTourEl() === el
    ) return;

    setActiveProductTour({ el });
  };

  return {
    isActive,
    setEditorEl,
  };
};
