import { AnimatePresence } from 'framer-motion';
import { VFC, useCallback, useEffect } from 'react';

import DocPreview from 'src/components/DocPreview/DocPreview';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useGetDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { useGetLayerDocPanel } from 'src/reactives/layer.reactive';
import { setUserPreferences } from 'src/reactives/userPreferences.reactive';

import { StyledModal } from './DocPreviewModal.styles';

export const DocPreviewModal: VFC = () => {
  const { visible: isLayerDocPanelOpened } = useGetLayerDocPanel();
  const { docIdPreview } = useGetDocIdPreview();

  const onHideModal = useCallback(() => setDocIdPreview({ docIdPreview: null }), []);

  useEffect(() => {
    if (docIdPreview) {
      // Stop showing the hint popup once the user has previewed a doc
      setUserPreferences({ isDocPreviewHintEnabled: false });
    }
  }, [docIdPreview]);

  useEffect(() => {
    window.addEventListener('blur', onHideModal);
    return () => {
      window.removeEventListener('blur', onHideModal);
    };
  }, [onHideModal]);

  return (
    <AnimatePresence>
      {docIdPreview && (
        <StyledModal
          hide={onHideModal}
          isDocPanelLayerOpened={isLayerDocPanelOpened}
          noMask
          disableOverlayPointerEvents
        >
          <BoardConfigContextProvider>
            <DocPreview id={docIdPreview} />
          </BoardConfigContextProvider>
        </StyledModal>
      )}
    </AnimatePresence>
  );
};
