export const density1x = '@media (-webkit-max-device-pixel-ratio: 1.9)';
export const density2x = '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
export const density3x = '@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi)';

export function backgroundImageDpr(path: string) {
  return `
    ${density1x} {
      background-image: url('${path}');
    }

    ${density2x} {
      background-image: url('${path.replace('.', '@2x.')}');
    }

    ${density3x} {
      background-image: url('${path.replace('.', '@3x.')}');
    }
  `;
}
