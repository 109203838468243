import { ApolloQueryResult, useQuery } from '@apollo/client';
import {
  MateWithProductRoleFragment,
  ProductMembersDocument,
  ProductMembersFragment,
  ProductMembersQueryVariables,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { useProductBase } from './useProduct';

const PAGINATION_SIZE = 20;

interface UseUsers {
  users: MateWithProductRoleFragment[];
  loading: boolean;
  pageInfo: ProductMembersFragment['users']['pageInfo'];
  fetchMore: (cursor: string) => Promise<ApolloQueryResult<{ node: ProductMembersFragment }>>;
}

export const getProductMembersQueryVariables = (productId: string) => ({
  productId,
  cursor: '',
  size: PAGINATION_SIZE,
});

export const useUsers = (): UseUsers => {
  const product = useProductBase();
  return useUsersByProductId(product?.id);
};

export const useUsersByProductId = (productId?: string, notSignedUpUsers = true): UseUsers => {
  const {
    data,
    loading,
    fetchMore: fetchMoreUsers,
  } = useQuery<{ node: ProductMembersFragment }, ProductMembersQueryVariables>(ProductMembersDocument, {
    notifyOnNetworkStatusChange: true,
    skip: !productId,
    variables: getProductMembersQueryVariables(productId as string),
  });

  const users = useMemo(() => ([
    ...nodeToArray(data?.node?.users),
    ...notSignedUpUsers ? nodeToArray(data?.node?.notSignedUpUsers) : [],
  ]), [data?.node?.users, data?.node?.notSignedUpUsers, notSignedUpUsers]);

  const fetchMore = useCallback((cursor: string) => fetchMoreUsers({
    variables: {
      cursor,
    },
  }), [fetchMoreUsers]);

  return {
    users,
    pageInfo: data?.node?.users?.pageInfo ?? {
      hasNextPage: false,
      endCursor: '',
    },
    loading,
    fetchMore,
  };
};

export const useAssignees = (): UseUsers => {
  const product = useProductBase();
  return useUsersByProductId(product?.id, false);
};
