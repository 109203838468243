import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { CellText, CustomerAvatar } from '@cycle-app/ui';
import { ReactChild, FC } from 'react';

import { StyledCellContent } from './CustomerTableNameCell.styles';

type Props = {
  customer: CustomerFragment;
  name?: ReactChild | null;
  link?: ReactChild;
  onClick?: VoidFunction;
  isCompact?: boolean;
};
export const CustomerTableNameCell: FC<Props> = ({
  customer, name, link, onClick, isCompact = false,
}) => (
  <StyledCellContent onClick={onClick} $isInteractive={!!onClick} $limitSize>
    <CustomerAvatar size="S" customer={customer} hideCompany />
    <CellText $isCompact={isCompact}>
      {name}
      {link}
    </CellText>
  </StyledCellContent>
);
