import React, { FC } from 'react';

import { StyledSvg } from './MovingBorderLoader.styles';

interface Props {
  className?: string;
  width?: number;
  height?: number;
  borderWidth?: number;
}
export const MovingBorderLoader: FC<Props> = ({
  className = '',
  width = 100,
  height = 100,
}) => {
  const pathLength = width * 2 + height * 2;

  const midTopToTopLeft = `M${width / 2 - 1} 1 L5 1`;
  const topLeftCorner = 'S1,1 1,5';
  const leftSide = `L1 ${height - 5}`;
  const bottomLeftCorner = `S1,${height - 1} 5, ${height - 1}`;
  const bottomSide = `L${width - 5} ${height - 1}`;
  const bottomRightCorner = `S${width - 1},${height - 1} ${width - 1},${
    height - 5
  }`;
  const rightSide = `L${width - 1} 5`;
  const topRightCorner = `S${width - 1},1 ${width - 5}, 1`;
  const topRightToMid = `L${width / 2 - 1} 1`;

  return (
    <StyledSvg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      totalLength={pathLength}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`
          ${midTopToTopLeft} 
          ${topLeftCorner} 
          ${leftSide} 
          ${bottomLeftCorner} 
          ${bottomSide} 
          ${bottomRightCorner} 
          ${rightSide} 
          ${topRightCorner} 
          ${topRightToMid}
        `}
        strokeLinecap="square"
        strokeWidth="2"
        strokeDasharray={pathLength}
        strokeDashoffset={pathLength}
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
};
