import { TextHighlighter } from '@cycle-app/ui';
import { FC, HTMLAttributes } from 'react';

import { CommandActionType } from 'src/types/commandbar.types';

import { Action, ShortcutStyled } from './CommandAction.styles';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  action: CommandActionType;
  selected: boolean;
  hoverDisabled: boolean;
  searchText: string;
}

const CommandAction: FC<Props> = ({
  action,
  selected,
  hoverDisabled,
  searchText,
  ...buttonProps
}) => (
  <Action
    key={action.id}
    id={action.id}
    className={selected ? 'selected' : ''}
    hoverDisabled={hoverDisabled}
    {...buttonProps}
  >
    {action.icon}
    <TextHighlighter
      searchWords={[searchText]}
      textToHighlight={action.label ?? ''}
      className="hightlight"
    />
    {action.shortcut && <ShortcutStyled keys={action.shortcut} hideThen />}
    {action.followUp && '->'}
  </Action>
);

export default CommandAction;
