import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const AvatarImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

export const Label = styled.div`
  ${typo.body500};
  margin-bottom: 16px;
`;
