import { motion } from 'framer-motion';
import { ComponentProps } from 'react';

import flame from '../../assets/eyes-flame.png';
import heartLeft from '../../assets/eyes-heart-left.png';
import heartRight from '../../assets/eyes-heart-right.png';
import starLeft from '../../assets/eyes-star-left.png';
import starRight from '../../assets/eyes-star-right.png';
import eyes from '../../assets/eyes.png';
import { flameVariants, starsVariants, heartsVariants, useEyesAnimation } from './LoginEyes.motion';
import { usePointerAnimation } from './usePointerAnimation';

type EyeProps = ComponentProps<typeof motion.img> & Pick<ComponentProps<'img'>, 'style'>;

const Eye = ({
  style, ...props
}: EyeProps) => (
  <motion.img
    loading="eager"
    alt=""
    style={{
      maxWidth: 'none',
      position: 'absolute',
      filter: 'drop-shadow(-8px 4px 4px rgb(0 0 0 / 12%))',
      height: 51,
      top: 78,
      ...style,
    }}
    initial="hidden"
    {...props}
  />
);

export const LoginEyes = () => {
  const ref = usePointerAnimation();
  const controls = useEyesAnimation();

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        width: 'fit-content',
      }}
    >
      <img
        src={eyes}
        loading="eager"
        alt="A 3D cartoon character wearing a blue cap, its eyes rotate between stars, hearts and flames"
        style={{
          height: 165,
          maxWidth: 'none',
        }}
      />

      <Eye
        src={flame}
        animate={controls.flames}
        variants={flameVariants}
        custom={0}
        style={{
          left: 38,
          filter: 'none',
        }}
      />

      <Eye
        src={flame}
        animate={controls.flames}
        variants={flameVariants}
        custom={1}
        style={{
          left: 90,
          filter: 'none',
        }}
      />

      <Eye
        src={starLeft}
        animate={controls.stars}
        variants={starsVariants}
        custom={0}
        style={{
          left: 34,
        }}
      />

      <Eye
        src={starRight}
        animate={controls.stars}
        variants={starsVariants}
        custom={1}
        style={{
          left: 86,
        }}
      />

      <Eye
        src={heartLeft}
        animate={controls.hearts}
        variants={heartsVariants}
        style={{
          left: 30,
        }}
      />

      <Eye
        src={heartRight}
        animate={controls.hearts}
        variants={heartsVariants}
        style={{
          left: 86,
        }}
      />
    </div>
  );
};
