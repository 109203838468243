import { NotificationFragment, NotificationType } from '@cycle-app/graphql-codegen';
import { Avatar } from '@cycle-app/ui';
import { toNotificationDateTime } from '@cycle-app/utilities/src/utils/date.util';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import cycleLogo from 'src/assets/cycle-logo.png';
import { PageId } from 'src/constants/routing.constant';
import { useUrl } from 'src/hooks/useUrl';
import { setNotifications } from 'src/reactives/notifications.reactive';
import {
  COMMENT_SEARCH_PARAM,
  getNotificationTitleProps,
  shouldLinkToAComment,
  welcomeNotificationText,
} from 'src/utils/notifications.util';
import { getDocSlug } from 'src/utils/slug.util';

import {
  Container,
  MainArea,
  Date,
  Content,
  UnreadDot,
  Title,
  ImageContainer,
  GreyPart,
  CycleLogo,
} from './NotificationCard.styles';

interface NotificationCardProps {
  notification: NotificationFragment;
  onClickLink?: VoidFunction;
}

const NotificationCard: FC<NotificationCardProps> = ({
  notification, onClickLink,
}) => {
  const {
    creator, comment, doc, createdAt, read, type,
  } = notification;
  const dateString = createdAt && toNotificationDateTime(createdAt);
  const getUrl = useUrl();

  const docUrl = doc ? getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) }) : null;

  const image = creator ? (
    <Avatar
      user={creator}
      size={20}
    />
  ) : (
    <CycleLogo
      alt="Cycle logo"
      src={cycleLogo}
    />
  );

  const isWelcomeNotification = type === NotificationType.WelcomeNotification;

  const titleProps = getNotificationTitleProps(notification);
  const content = isWelcomeNotification ? welcomeNotificationText : comment?.content;
  const isContentBoxed = !!comment?.content;
  const searchParams = shouldLinkToAComment(type) ? `${COMMENT_SEARCH_PARAM}=${comment?.id}` : '';

  if (docUrl) {
    return (
      <Link
        to={{
          pathname: docUrl,
          search: searchParams,
        }}
        onClick={() => {
          setNotifications({ isNotifCenterVisible: false });
          onClickLink?.();
        }}
      >
        {render()}
      </Link>
    );
  }

  return render();

  function render() {
    return (
      <Container
        isWelcomeNotification={isWelcomeNotification}
        isContentBoxed={isContentBoxed}
      >
        <ImageContainer>
          {image}
        </ImageContainer>
        <MainArea>
          {!read && <UnreadDot />}
          <Title isFromCycle={isWelcomeNotification}>
            {titleProps.name}
            {' '}
            <GreyPart>{titleProps.label}</GreyPart>
            {' '}
            {titleProps.docTitle}
          </Title>
          {content && (
            <Content
              content={content}
              isContentBoxed={isContentBoxed}
            />
          )}
          {dateString && (
            <Date>{dateString}</Date>
          )}
        </MainArea>
      </Container>
    );
  }
};

export default NotificationCard;
