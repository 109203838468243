import { DoctypeTemplateCategory, TemplateFragment } from '@cycle-app/graphql-codegen';
import { NavigationSection, TextHighlighter } from '@cycle-app/ui';
import { TriangleIcon } from '@cycle-app/ui/icons';
import memoize from 'fast-memoize';
import { Fragment, useMemo, useState, VFC } from 'react';

import { TemplateCategoriesLabel } from 'src/constants/templates.constants';
import useDoctypeTemplates from 'src/hooks/api/useDoctypeTemplates';
import { setTemplate, useGetTemplate } from 'src/reactives/template.reactive';

import { SearchTemplate } from '../../TemplateModal.styles';
import { CategoryNavigationItem, Container, StyledSpinner, TemplateNavigationItem } from './TemplateList.styles';

type TemplatesByCategory = Partial<Record<DoctypeTemplateCategory, TemplateFragment[]>>;

const TemplateList: VFC = () => {
  const {
    templates,
    loading,
  } = useDoctypeTemplates();
  const { selectedTemplateId } = useGetTemplate();

  const [search, setSearch] = useState('');
  const [hiddenCategories, setHiddenCategories] = useState<Record<DoctypeTemplateCategory, boolean>>({
    BUG: false,
    DESIGN_BRIEF: false,
    OTHER: false,
    PRD: false,
    PRODUCT_STRATEGY: false,
    USER_RESEARCH: false,
    USER_STORY: false,
  });

  const filteredTemplates = templates.filter(template => (template.title.toLowerCase()).includes(search.toLowerCase()));

  const templatesByCategory = useMemo(() => (
    !loading ? filteredTemplates.reduce<TemplatesByCategory>((prev, template) => ({
      ...prev,
      [template.category]: [
        ...prev[template.category] ?? [],
        template,
      ],
    }), {}) : null
  ) ?? {}, [loading, filteredTemplates]);

  const categories: DoctypeTemplateCategory[] = useMemo(
    () => Object.keys(templatesByCategory) as DoctypeTemplateCategory[],
    [templatesByCategory],
  );

  const onCategoryClicked = useMemo(() => memoize((category: DoctypeTemplateCategory) => () => setHiddenCategories({
    ...hiddenCategories,
    [category]: !hiddenCategories[category],
  })), [hiddenCategories]);

  return (
    <>
      <SearchTemplate
        placeholder="Search a template..."
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <Container>
        <NavigationSection>
          {loading && (<StyledSpinner />)}
          {categories.map((category) => (
            <Fragment key={category}>
              <CategoryNavigationItem
                key={category}
                label={TemplateCategoriesLabel[category]}
                icon={<TriangleIcon />}
                isSidebarColor
                onClick={onCategoryClicked(category)}
                $collapsed={hiddenCategories[category]}
              />
              {!hiddenCategories[category] && (templatesByCategory[category] ?? []).map(template => (
                <TemplateNavigationItem
                  key={template.id}
                  label={(
                    <TextHighlighter
                      textToHighlight={template.title}
                      searchWords={[search]}
                    />
                  )}
                  isChild
                  isActive={template.id === selectedTemplateId}
                  onClick={() => setTemplate({ selectedTemplateId: template.id })}
                />
              ))}
            </Fragment>
          ))}
        </NavigationSection>
      </Container>
    </>
  );
};

export default TemplateList;
