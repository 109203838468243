import {
  CreateGithubIntegrationDocument,
  CreateLinearIntegrationDocument,
  CreateNotionIntegrationDocument,
  CreateFigmaIntegrationDocument,
  CreateIntercomIntegrationDocument,
  CreateMailIntegrationDocument,
  CreateHubspotIntegrationDocument,
  CreateGithubIntegrationMutationVariables,
  CreateLinearIntegrationMutationVariables,
  IntegrationType,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { InstallCodeProviderParams } from 'src/types/integrations.types';

type CreateParams =
  { productId: string; type: IntegrationType.Github } & Pick<CreateGithubIntegrationMutationVariables, 'installationId'> |
  { productId: string; type: InstallCodeProviderParams } & Pick<CreateLinearIntegrationMutationVariables, 'installationCode'> |
  { productId: string; type: IntegrationType.Mail };

export const useCreateIntegration = () => {
  const [createGithubIntegrationMutation, { loading: isGithubLoading }] = useSafeMutation(CreateGithubIntegrationDocument);
  const [createLinearIntegrationMutation, { loading: isLinearLoading }] = useSafeMutation(CreateLinearIntegrationDocument);
  const [createNotionIntegrationMutation, { loading: isNotionLoading }] = useSafeMutation(CreateNotionIntegrationDocument);
  const [createFigmaIntegrationMutation, { loading: isFigmaLoading }] = useSafeMutation(CreateFigmaIntegrationDocument);
  const [createIntercomIntegrationMutation, { loading: isIntercomLoading }] = useSafeMutation(CreateIntercomIntegrationDocument);
  const [createMailIntegrationMutation, { loading: isMailLoading }] = useSafeMutation(CreateMailIntegrationDocument);
  const [createHubspotIntegrationMutation, { loading: isHubspotLoading }] = useSafeMutation(CreateHubspotIntegrationDocument);

  const createIntegration = async (params: CreateParams) => {
    if (!params.productId) throw Error('Product id is missing');

    switch (params.type) {
      case IntegrationType.Github:
        return createGithubIntegrationMutation({
          variables: {
            productId: params.productId,
            installationId: params.installationId,
          },
        });
      case IntegrationType.Linear:
        return createLinearIntegrationMutation({
          variables: {
            productId: params.productId,
            installationCode: params.installationCode,
          },
        });
      case IntegrationType.Notion:
        return createNotionIntegrationMutation({
          variables: {
            productId: params.productId,
            installationCode: params.installationCode,
          },
        });
      case IntegrationType.Figma:
        return createFigmaIntegrationMutation({
          variables: {
            productId: params.productId,
            installationCode: params.installationCode,
          },
        });
      case IntegrationType.Intercom:
        return createIntercomIntegrationMutation({
          variables: {
            productId: params.productId,
            installationCode: params.installationCode,
          },
        });
      case IntegrationType.Mail:
        return createMailIntegrationMutation({
          variables: {
            productId: params.productId,
          },
        });
      case IntegrationType.Hubspot:
        return createHubspotIntegrationMutation({
          variables: {
            productId: params.productId,
            installationCode: params.installationCode,
          },
        });
      default:
        throw Error('Not supported integration');
    }
  };

  return {
    createIntegration,
    isLoading: isGithubLoading ||
      isLinearLoading ||
      isNotionLoading ||
      isFigmaLoading ||
      isIntercomLoading ||
      isMailLoading ||
      isHubspotLoading,
  };
};
