import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { GithubStatus } from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';

type Props = {
  isVisible: boolean;
  children: JSX.Element;
  onClose: () => void;
  statuses: GithubStatus[];
  selectedStatus: GithubStatus['name'];
  onChange: (assignee: GithubStatus | 'none') => void;
  isLoading: boolean;
};

export const GithubStatusesSelectDropdown: FC<Props> = ({
  isVisible,
  onClose,
  children,
  statuses,
  selectedStatus,
  onChange,
  isLoading,
}) => {
  const options: SelectOption[] = useMemo(() => statuses.map(s => ({
    label: s.name,
    value: s.name,
    selected: selectedStatus === s.name,
  })) || [], [statuses, selectedStatus]);

  return (
    <DropdownLayer
      layer={Layer.DropdownModal}
      visible={isVisible}
      hide={onClose}
      placement="bottom-start"
      content={(
        <SelectPanel
          options={[
            {
              label: 'None',
              value: 'none',
              selected: !selectedStatus,
            },
            ...options,
          ]}
          hideSearch
          onOptionChange={(newOption) => {
            if (newOption.value === 'none') {
              onChange('none');
              return;
            }
            const selectedNewStatus = statuses.find(s => s.name === newOption.value);

            if (!selectedNewStatus || selectedNewStatus?.name === selectedStatus) return;

            onChange(selectedNewStatus);
          }}
          isLoading={isLoading}
        />
      )}
    >
      {children}
    </DropdownLayer>
  );
};
