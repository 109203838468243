import { motion } from 'framer-motion';
import styled from 'styled-components';

import { DownIcon } from '../../icons';
import { body500, headerSmall } from '../../theme/typo';
import { DropdownCss } from '../Dropdown/Dropdown.styles';

export const Container = styled(motion.div)`
  ${DropdownCss}
  padding: 20px 20px 30px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.div`
  ${headerSmall}
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Label = styled.div`
  ${body500};
`;

export const Input = styled.div`
  align-items: center;
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: ${p => p.theme.colors.input.bgAlt};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-top: 8px;
`;

export const InputValue = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledDownIcon = styled(DownIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;

export const Skeleton = styled.div`
  border-radius: 3px;
  width: 104px;
  height: 8px;
  background: ${p => p.theme.colors.background.active};
`;
