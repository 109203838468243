import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji, Tooltip } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';

import { useProductDoctypes } from 'src/hooks';

import { SyncButton } from './LinkedWith.styles';

export const LinkedWith = () => (
  <SyncButton
    iconStart={<LinkIcon />}
    variant="secondary"
    useUserColor={false}
    size="S"
  >
    Linked to
    <LinkedDocTypes />
  </SyncButton>
);

const LinkedDocTypes = () => {
  const docTypes = useProductDoctypes();
  const feedback = docTypes.find(d => d.type === DoctypeType.Feedback) || null;
  const insight = docTypes.find(d => d.type === DoctypeType.Insight) || null;
  return (
    <>
      {feedback && (
        <Tooltip content={feedback.name}>
          <Emoji emoji={feedback.emoji} size={14} />
        </Tooltip>
      )}
      {insight && (
        <Tooltip content={insight.name}>
          <Emoji emoji={insight.emoji} size={14} />
        </Tooltip>
      )}
    </>
  );
};
