import { typo, waveCss } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import SidebarToggleButton from 'src/components/SidebarToggleButton/SidebarToggleButton';

export const Header = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  padding: 32px;
  background-color: ${p => p.theme.colors.body.bg};
  border-bottom: 1px solid ${p => p.theme.colors.home.separator};
  z-index: 2;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;

export const Right = styled.div`
  ${mobile} {
    display: none;
  }
`;

export const Title = styled.h1`
  ${typo.headerMedium}
  color: ${p => p.theme.colors.text.primary};

  ${mobile} {
    transform: translateX(32px);
  }
`;

export const Description = styled.h1`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const FakeInput = styled.button`
  ${typo.body400}
  background-color: ${p => p.theme.colors.home.search.bg};
  color: ${p => p.theme.colors.home.search.text};
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colors.home.cmdk.bg};
  }
`;

export const CmdK = styled.span`
  ${typo.caption500}
  background-color: ${p => p.theme.colors.home.cmdk.bg};
  color: ${p => p.theme.colors.home.cmdk.text};
  border-radius: 4px;
  padding: 0 4px;
  margin-left: 80px;
  display: inline-block;
`;

export const SidebarToggle = styled(SidebarToggleButton)`
  top: 32px;
  left: 32px;
`;

export const Hand = styled.span`
  display: inline-block;
  :hover {
    ${waveCss}
  }
`;
