import { Button } from '@cycle-app/ui';
import styled from 'styled-components';

import ImageInput from 'src/components/ImageInput/ImageInput';

export const UploadButton = styled(Button)`
  --border: ${p => p.theme.colors.border.primary};
  height: 36px;
`;

export const LogoInput = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 36px;
`;

export const StyledImageInput = styled(ImageInput)`
  margin-right: auto;
`;

export const Domain = styled.span`
  margin-top: -2px;
`;

export const LogoInputPreview = styled.img`
  display: block;
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 4px;
`;
