import { CreateLinearIssueDocument, CreateLinearIssueMutationVariables } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCreateLinearIssue = () => {
  const [createMutation, { loading }] = useSafeMutation(CreateLinearIssueDocument);

  const createIssue = ({
    title, teamId, docId, assigneeId, statusId,
  }: CreateLinearIssueMutationVariables) => {
    if (!title || !teamId || !docId) throw Error('Data are missing');
    return createMutation({
      variables: {
        docId,
        teamId,
        statusId,
        title,
        assigneeId,
      },
    });
  };

  return {
    createIssue,
    isLoading: loading,
  };
};
