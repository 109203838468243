import { motion } from 'framer-motion';
import styled from 'styled-components';

export const CardIconsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  flex: 1 0 96px;
`;

export const CardContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardIconsGridItem = styled(motion.div)`
  align-items: center;
  background-color: ${p => p.theme.colors.background.primary};
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.greyDark};
  display: flex;
  justify-content: center;
`;
