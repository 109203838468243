import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

export const Container = styled(NodeViewWrapper)`
  position: relative;
  border: none;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  ${(p) => p.$isSelected && css`
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${p.theme.userColors.main};
  `}
  ${(p) => (p.$hasMinHeight ? css`min-height: 162px;` : css`height: 450px;`)}
`;

export const Toolbar = styled.div`
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const Embed = styled.div``;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
