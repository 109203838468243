import { gql } from '@apollo/client';
import { CreateCompanyDocument, CreateCompanyMutationVariables } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { NormalizedCompanyConnection, NormalizedCompanyEdge } from 'src/types/companies.types';

export const useCompanyCreate = () => {
  const { product } = useProduct();
  const [createCompanyMutation, { loading }] = useSafeMutation(CreateCompanyDocument);

  const createCompany = useCallback((variables: Omit<CreateCompanyMutationVariables, 'productId'>) => {
    const productId = product?.id;
    if (!productId) return null;

    return createCompanyMutation({
      variables: {
        productId,
        ...variables,
      },
      update(cache, { data }) {
        if (!data?.createCompany || !product) return;

        const newCompanyRef = cache.writeFragment({
          data: data.createCompany,
          fragment: gql`
            fragment NewCompany on Fragment {
              id
            }
          `,
        });

        cache.modify({
          id: cache.identify(product),
          fields: {
            companyCount: (companyCount) => companyCount + 1,
            companies: (companies: NormalizedCompanyConnection, { readField }) => {
              const newEdge = {
                __typename: 'CompanyEdge',
                node: newCompanyRef,
              } as NormalizedCompanyEdge;
              const index = companies.edges.findIndex(({ node: nodeRef }) => (readField('name', nodeRef) ?? '') > variables.name);
              return {
                ...companies,
                edges: index === -1 ? [...companies.edges, newEdge] : [
                  ...companies.edges.slice(0, index),
                  newEdge,
                  ...companies.edges.slice(index),
                ],
              };
            },
          },
        });
      },
    });
  }, [createCompanyMutation, product]);

  return {
    createCompany,
    isLoading: loading ?? false,
  };
};
