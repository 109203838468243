import { ComponentProps, FC, ReactNode, forwardRef } from 'react';

import { Color as ColorApi } from '../../../../graphql-codegen/generated';
import { CloseIcon, LinkIcon } from '../../icons';
import { ArrowRightUpIcon } from '../../icons/content/ArrowRightUpIcon';
import { TooltipProps, Tooltip } from '../Tooltip/Tooltip';
import { Container, Content, Link, Name, TagButton } from './Tag.styles';

export type TagProps = Omit<ComponentProps<'div'>, 'ref'> & {
  icon?: ReactNode;
  color?: ColorApi | 'grey';
  limitSize?: boolean;
  tooltip?: TooltipProps;
  externalLink?: string;
  onDelete?: () => void;
  onDarkBg?: boolean;
  onClickCopyLink?: VoidFunction;
};

export const Tag: FC<TagProps> = forwardRef<HTMLDivElement, TagProps>(({
  className,
  icon,
  children,
  color,
  limitSize = true,
  onClick,
  onDelete,
  onDarkBg,
  tooltip,
  externalLink,
  onClickCopyLink,
  ...props
}, ref) => {
  const content = (
    <Content
      $color={color}
      $onDarkBg={onDarkBg}
      onClickCapture={onClick}
      $hasSideButton={!!externalLink || !!onClickCopyLink || !!onDelete}
    >
      {icon}
      {children && <Name $limitSize={limitSize}>{children}</Name>}
    </Content>
  );

  return (
    <Container
      className={className}
      $color={color}
      $onDarkBg={onDarkBg}
      ref={ref}
      {...props}
    >
      {tooltip
        ? (
          <Tooltip {...tooltip}>
            {content}
          </Tooltip>
        )
        : content}

      {externalLink && (
        <Tooltip
          placement="top"
          withPortal
          content="Open link"
        >
          <Link
            href={externalLink}
            target="_blank"
            rel="noopener noreferrer"
            $color={color}
            $onDarkBg={onDarkBg}
            onClick={e => e.stopPropagation()}
          >
            <ArrowRightUpIcon />
          </Link>
        </Tooltip>
      )}

      {onClickCopyLink && (
        <Tooltip
          placement="top"
          withPortal
          content="Copy link"
        >
          <TagButton
            $color={color}
            $onDarkBg={onDarkBg}
            onClick={e => {
              e.stopPropagation();
              onClickCopyLink();
            }}
          >
            <LinkIcon size={14} />
          </TagButton>
        </Tooltip>
      )}

      {onDelete && (
        <TagButton
          onClick={onDelete}
          $color={color}
          $onDarkBg={onDarkBg}
        >
          <CloseIcon />
        </TagButton>
      )}
    </Container>
  );
});
