import styled, { css } from 'styled-components';

export const truncate = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Right now in the design system all components use those box-shadows values.
 * If another one is used it's a design smell (or the design system should be updated)
 */
export const BOX_SHADOW_COLOR_Z1 = 'rgba(0, 0, 0, 0.05)';
export const BOX_SHADOW_COLOR_Z2 = 'rgba(0, 0, 0, 0.05)';
export const BOX_SHADOW_COLOR_Z3 = 'rgba(0, 0, 0, 0.05)';
export const BOX_SHADOW_COLOR_Z4 = 'rgba(0, 0, 0, 0.2)';
export const BOX_SHADOW_COLOR_Z5 = 'rgba(0, 0, 0, 0.15)';

export const boxShadowZ1 = css`
  box-shadow: 0px 1px 2px ${BOX_SHADOW_COLOR_Z1};
`;
export const boxShadowZ2 = css`
  box-shadow: 0px 2px 5px ${BOX_SHADOW_COLOR_Z2};
`;
export const boxShadowZ3 = css`
  box-shadow: 0px 3px 11px ${BOX_SHADOW_COLOR_Z3};
`;
export const boxShadowZ2Z3 = css`
  box-shadow: 0px 2px 5px ${BOX_SHADOW_COLOR_Z2}, 0px 3px 11px ${BOX_SHADOW_COLOR_Z3};
`;
export const boxShadowZ4 = css`
  box-shadow: 0px 6px 15px ${BOX_SHADOW_COLOR_Z4};
`;
export const boxShadowZ5 = css`
  box-shadow: 0px 0px 20px ${BOX_SHADOW_COLOR_Z5}, 0px 25px 30px ${BOX_SHADOW_COLOR_Z5};
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
