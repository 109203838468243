import { ViewType } from '@cycle-app/graphql-codegen';
import { Button, Skeleton } from '@cycle-app/ui';
import { CloseIcon, KanbanIcon, ListIcon } from '@cycle-app/ui/icons';
import { useEffect, useCallback } from 'react';

import DuplicateBoardModal from 'src/app/Main/Board/DuplicateBoardModal/DuplicateBoardModal';
import { BoardEditForm, BoardEditCommonFormData } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { CloseButtonStyled } from 'src/components/DialogModal/DialogModal.styles';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import useChangeViewType from 'src/hooks/api/mutations/boardConfig/useChangeViewtype';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useBoard } from 'src/hooks/api/useBoard';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useGetBoardConfigModal, setBoardConfigModal } from 'src/reactives/boardConfig.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { setLoader } from 'src/reactives/loader.reactive';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { getBoardConfigGroupBy, findFilterDoctypePropertyRule, getOptimisticDraftBoardConfig } from 'src/utils/boardConfig/boardConfig.util';

import {
  StyledPortalModal, Title, Header, FormButtons, Section, Label, Content, View, ViewtypeButton,
} from './BoardConfigForm.styles';
import BoardConfigFormDoctypes from './BoardConfigFormDoctypes';
import BoardConfigFormFilters from './BoardConfigFormFilters';
import BoardConfigFormGroupby from './BoardConfigFormGroupby';
import BoardConfigFormProperties from './BoardConfigFormProperties';
import BoardConfigFormSwimlanes from './BoardConfigFormSwimlanes/BoardConfigFormSwimlanes';

export const BoardConfigForm = () => {
  const boardMutations = useBoardMutations();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const modal = useGetBoardConfigModal();
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const board = useBoard(boardId);
  const {
    data, loading: loadingDraftBoardConfig,
  } = useDraftBoardConfig(boardId);
  const draftBoardConfig = data?.node?.draftBoardConfig ?? getOptimisticDraftBoardConfig(boardConfig);
  const { canCreateBoard } = useGetPermission();
  const { canPlanCreateBoard } = useGetBillingPermission();
  const { updateViewType } = useChangeViewType(draftBoardConfig?.id ?? '', board?.id);
  const {
    publishBoardConfig,
    revertUnsavedDraftConfig,
    loading,
  } = useDraftBoardConfigMutations(draftBoardConfig?.id ?? '', board?.id);

  const isMutationLoading = Object.values(loading).some(b => b);

  // Open the modal if the board has a draft config
  useEffect(() => {
    if (!isMutationLoading && data?.node?.draftBoardConfig) {
      setBoardConfigModal({ visible: true });
    }
  }, [data?.node?.draftBoardConfig, isMutationLoading]);

  const [isDuplicateModalVisible, {
    toggleCallback: toggleDuplicateModal,
    setFalseCallback: hideDuplicateModal,
  }] = useOptimizedBooleanState(false);

  const disabled = (!loadingDraftBoardConfig && !data?.node?.draftBoardConfig) || modal.disabled;

  const hide = useCallback(async () => {
    if (disabled) return;
    setBoardConfigModal({ visible: false });
    await new Promise(r => setTimeout(r, 150));
    await revertUnsavedDraftConfig();
  }, [disabled, revertUnsavedDraftConfig]);

  const onSubmit = useCallback(async (formData: BoardEditCommonFormData) => {
    if (disabled || !board) return;
    setLoader({ loading: true });
    const [publishResult, boardResult] = await Promise.all([
      publishBoardConfig(),
      boardMutations.updateBoard({
        ...formData,
        boardId: board.id,
      }),
    ]);
    if (publishResult.errors || boardResult.errors) return;
    setBoardConfigModal({ visible: false });
  }, [board, boardMutations, disabled, publishBoardConfig]);

  if (!modal.visible) return null;

  return (
    <>
      <StyledPortalModal hide={hide}>
        <Header>
          <Title>View settings</Title>
          <CloseButtonStyled onClick={hide}>
            <CloseIcon />
          </CloseButtonStyled>
        </Header>

        <Content>
          <BoardEditForm
            defaultValues={board}
            focusNameOnMount
            onSubmit={onSubmit}
          >
            <>
              <View>
                {!draftBoardConfig ? <Skeleton height={40} /> : (
                  <ViewtypeButton
                    active={draftBoardConfig.viewType === ViewType.Kanban}
                    {...draftBoardConfig.viewType === ViewType.List && {
                      onClick: () => updateViewType(ViewType.Kanban),
                    }}
                  >
                    <KanbanIcon />
                    Kanban
                  </ViewtypeButton>
                )}

                {!draftBoardConfig ? <Skeleton height={40} /> : (
                  <ViewtypeButton
                    active={draftBoardConfig.viewType === ViewType.List}
                    {...draftBoardConfig.viewType === ViewType.Kanban && {
                      onClick: () => updateViewType(ViewType.List),
                    }}
                  >
                    <ListIcon />
                    List
                  </ViewtypeButton>
                )}
              </View>

              <Section>
                <Label>Doc types</Label>
                {!draftBoardConfig ? <Skeleton height={40} /> : (
                  <BoardConfigFormDoctypes
                    draftBoardConfigId={draftBoardConfig.id}
                    doctypesFilter={findFilterDoctypePropertyRule(draftBoardConfig)}
                    boardID={board?.id}
                  />
                )}
              </Section>

              <Section>
                <Label>Filters</Label>
                {!draftBoardConfig ? <Skeleton height={36} /> : (
                  <BoardConfigFormFilters
                    boardConfigId={draftBoardConfig.id}
                    filterProperties={draftBoardConfig.filterProperties}
                    filterableProperties={draftBoardConfig.filterableProperties}
                    boardID={board?.id}
                  />
                )}
              </Section>

              <Section>
                <Label>Group by</Label>
                {!draftBoardConfig ? (
                  <Skeleton height={40} width={250} />
                ) : (
                  <BoardConfigFormGroupby
                    boardConfigId={draftBoardConfig.id}
                    groupableProperties={draftBoardConfig?.groupableProperties}
                    groupByConfig={getBoardConfigGroupBy(draftBoardConfig)}
                    boardID={board?.id}
                  />
                )}
              </Section>

              {draftBoardConfig?.viewType === ViewType.Kanban && (
                <Section>
                  <BoardConfigFormSwimlanes
                    boardConfigId={draftBoardConfig?.id}
                    availableSwimlaneByDoctypes={draftBoardConfig.availableSwimlaneByDoctypes}
                    groupByConfig={getBoardConfigGroupBy(draftBoardConfig)}
                    swimlaneByConfig={draftBoardConfig.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
                      ? draftBoardConfig.docQuery.swimlanebyConfig
                      : null}
                    boardID={board?.id}
                  />
                </Section>
              )}

              <Section>
                <Label>Properties</Label>
                {!draftBoardConfig ? <Skeleton height={28} width={170} /> : (
                  <BoardConfigFormProperties
                    boardConfigId={draftBoardConfig.id}
                    viewType={draftBoardConfig.viewType}
                    properties={draftBoardConfig.properties}
                    boardID={board?.id}
                  />
                )}
              </Section>

              <FormButtons>
                <Button
                  variant="secondary"
                  size="M"
                  onClick={hide}
                  isLoading={loading.revert}
                  disabled={disabled}
                >
                  Cancel
                </Button>
                {canCreateBoard && (
                  <Button
                    variant="secondary"
                    size="M"
                    onClick={handleToggleDuplicateModal}
                    disabled={disabled}
                  >
                    Save view as…
                  </Button>
                )}

                <Button
                  size="M"
                  type="submit"
                  isLoading={boardMutations.loading || loading.publish}
                  disabled={disabled}
                >
                  Apply
                </Button>
              </FormButtons>
            </>
          </BoardEditForm>
        </Content>
      </StyledPortalModal>

      {!!board && isDuplicateModalVisible && draftBoardConfig && (
        <DuplicateBoardModal
          boardId={board.id}
          onHide={hideDuplicateModal}
          onDone={hide}
          boardConfigId={draftBoardConfig.id}
        />
      )}
    </>
  );

  function handleToggleDuplicateModal() {
    if (canPlanCreateBoard) {
      toggleDuplicateModal();
    } else {
      setLimitationsModal({ action: 'BOARD_CREATE' });
    }
  }
};
