import { getOS } from '@cycle-app/utilities';
import { Emoji as EmojiMart } from 'emoji-mart';
import { FC, DOMAttributes } from 'react';

import { Container, Size } from './Emoji.styles';

interface Props extends DOMAttributes<HTMLDivElement> {
  emoji?: string | null;
  inline?: boolean;
  size?: Size;
}

const USE_NATIVE_EMOJI = getOS() === 'macOS';

export const Emoji: FC<Props> = ({
  emoji,
  size = 16,
  inline = false,
  ...otherProps
}) => {
  if (!emoji) return null;
  return (
    <Container
      $size={size}
      $inline={inline}
      $native={USE_NATIVE_EMOJI}
      role="img"
      {...otherProps}
    >
      <EmojiMart
        emoji={emoji}
        set="apple"
        native={USE_NATIVE_EMOJI}
        size={size}
      />
    </Container>
  );
};
