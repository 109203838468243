import { FC, forwardRef, InputHTMLAttributes } from 'react';

import { Label } from '../CheckboxInput/CheckboxInput.styles';
import { Container, Toggle, ToggleDot, StyledInput, LabelText } from './ToggleInput.styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  checked?: boolean;
}
export const ToggleInput: FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    ...inputProps
  } = props;

  return (
    <Container
      className={className}
      $isDisabled={!!inputProps.disabled}
    >
      <StyledInput
        ref={ref}
        type="checkbox"
        {...inputProps}
      />
      <Label htmlFor={inputProps.id}>
        {label && (
          <LabelText>{label}</LabelText>
        )}
        <Toggle>
          <ToggleDot />
        </Toggle>
      </Label>
    </Container>
  );
});
