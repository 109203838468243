import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const KanbanIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.40625 1C3.00014 1 2.61066 1.16133 2.32349 1.44849C2.03633 1.73566 1.875 2.12514 1.875 2.53125V13.4688C1.875 14.314 2.561 15 3.40625 15H5.59375C5.99986 15 6.38934 14.8387 6.67651 14.5515C6.96367 14.2643 7.125 13.8749 7.125 13.4688V2.53125C7.125 2.12514 6.96367 1.73566 6.67651 1.44849C6.38934 1.16133 5.99986 1 5.59375 1H3.40625ZM3.1875 2.53125C3.1875 2.47323 3.21055 2.41759 3.25157 2.37657C3.29259 2.33555 3.34823 2.3125 3.40625 2.3125H5.59375C5.65177 2.3125 5.70741 2.33555 5.74843 2.37657C5.78945 2.41759 5.8125 2.47323 5.8125 2.53125V13.4688C5.8125 13.5268 5.78945 13.5824 5.74843 13.6234C5.70741 13.6645 5.65177 13.6875 5.59375 13.6875H3.40625C3.34823 13.6875 3.29259 13.6645 3.25157 13.6234C3.21055 13.5824 3.1875 13.5268 3.1875 13.4688V2.53125ZM10.4062 1C10.0001 1 9.61066 1.16133 9.32349 1.44849C9.03633 1.73566 8.875 2.12514 8.875 2.53125V8.21875C8.875 9.064 9.561 9.75 10.4062 9.75H12.5938C12.9999 9.75 13.3893 9.58867 13.6765 9.30151C13.9637 9.01434 14.125 8.62486 14.125 8.21875V2.53125C14.125 2.12514 13.9637 1.73566 13.6765 1.44849C13.3893 1.16133 12.9999 1 12.5938 1H10.4062ZM10.1875 2.53125C10.1875 2.47323 10.2105 2.41759 10.2516 2.37657C10.2926 2.33555 10.3482 2.3125 10.4062 2.3125H12.5938C12.6518 2.3125 12.7074 2.33555 12.7484 2.37657C12.7895 2.41759 12.8125 2.47323 12.8125 2.53125V8.21875C12.8125 8.27677 12.7895 8.33241 12.7484 8.37343C12.7074 8.41445 12.6518 8.4375 12.5938 8.4375H10.4062C10.3482 8.4375 10.2926 8.41445 10.2516 8.37343C10.2105 8.33241 10.1875 8.27677 10.1875 8.21875V2.53125Z" />
  </Svg>
);
