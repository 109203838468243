import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { isEmail, isUrl, isValidInputDate } from '../utils/format.util';

type UseInputError = (p: UseInputErrorParams) => [InputError, (value: string) => boolean];

type InputType = 'email' | 'url' | string;
type InputError = string | null;

interface UseInputErrorParams {
  type: InputType;
  value: string;
  debounceTimeout?: number;
}

export const useInputError: UseInputError = ({
  type,
  value,
  debounceTimeout = 600,
}) => {
  const [formatError, setFormatError] = useState<string | null>(null);
  const [valueDebounced] = useDebounce(value, debounceTimeout);

  useEffect(() => {
    // Hide immediately error when the value is updated
    setFormatError(null);
  }, [type, value]);

  useEffect(() => {
    // Show potential error if the value has not changed since `debounceTimeout` ms
    setFormatError(getInputError(type, valueDebounced));
  }, [type, valueDebounced]);

  const checkInput = (val: string) => !getInputError(type, val);

  return [formatError, checkInput];
};

const getInputError = (type: InputType, value: string) => {
  if (type === 'email' && value && !isEmail(value)) {
    return 'Email format is incorrect';
  }
  if (type === 'url' && value && !isUrl(value)) {
    return 'URL format is incorrect';
  }
  if (type === 'date' && value && !isValidInputDate(value)) {
    return 'Date format is incorrect';
  }
  return null;
};
