import { BulkCreateDocDocument, MutationAddNewDocArgs, namedOperations } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useProductBase } from 'src/hooks/api/useProduct';
import { usePageId } from 'src/hooks/usePageId';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { useUpdateChildCache } from '../api/cache/cacheHierarchy';

type CreateDocsParams = {
  assigneeId?: string;
  attributes?: MutationAddNewDocArgs['attributes'];
  customerId?: string;
  docTypeId: string;
  parentDocId?: string;
  parentId?: string;
  sourceId?: string;
  titles: string[];
};

export const useDocsCreate = () => {
  const page = usePageId();
  const productId = useProductBase()?.id ?? '';
  const updateChild = useUpdateChildCache();

  const [bulkCreateDocs, { loading }] = useSafeMutation(BulkCreateDocDocument, {
    refetchQueries: () => (page !== PageId.DocFullPage ? [namedOperations.Query.boardWithConfig] : []),
  });

  const createDocs = useCallback(async ({
    assigneeId,
    customerId,
    docTypeId,
    attributes,
    titles,
    sourceId,
    parentId,
  }: CreateDocsParams) => {
    const baseNewDocData: Omit<MutationAddNewDocArgs, 'title'> = {
      doctypeId: docTypeId,
      isDraft: false,
      productId,
      assignee: assigneeId || undefined,
      customerId,
      parentId,
      source: sourceId ? { sourceId } : undefined,
      attributes: attributes || undefined,
    };

    const newDocsData: MutationAddNewDocArgs[] = titles.map(title => ({
      ...baseNewDocData,
      title,
    }));

    const { data } = await bulkCreateDocs({
      variables: {
        newDocs: newDocsData,
        productId,
      },
    });
    const createdDocs = data?.bulkCreateDoc ?? [];
    const docsWithParent = createdDocs.filter(doc => doc.parent?.id);
    if (docsWithParent.length) {
      docsWithParent.forEach(doc => updateChild({
        parentId: doc.parent?.id ?? '',
        docId: doc.id,
        action: 'add',
      }));
    }
    return createdDocs;
  }, [bulkCreateDocs, productId, updateChild]);

  return {
    createDocs,
    isLoading: loading,
  };
};
