import { make } from 'src/utils/reactives.util';

interface BoardConfigModalState {
  visible: boolean;
  disabled: boolean;
}

export const {
  hookValue: useGetBoardConfigModal,
  setValue: setBoardConfigModal,
} = make<BoardConfigModalState>({
  defaultState: {
    visible: false,
    disabled: false,
  },
});
