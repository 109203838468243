import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const HelpIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 8C2.5 6.66125 2.97831 5.43422 3.77336 4.48048L5.43519 6.1423C5.05657 6.66412 4.83331 7.30599 4.83331 8C4.83331 8.69402 5.05657 9.33588 5.43519 9.8577L3.77336 11.5195C2.97831 10.5658 2.5 9.33875 2.5 8ZM6.1423 5.4352L4.48047 3.77337C5.43422 2.97831 6.66125 2.5 8 2.5C9.33875 2.5 10.5658 2.97831 11.5195 3.77337L9.85769 5.43521C9.33586 5.0566 8.69399 4.83334 7.99998 4.83334C7.30597 4.83334 6.66411 5.05659 6.1423 5.4352ZM10.5648 6.14232C10.9434 6.66414 11.1666 7.306 11.1666 8C11.1666 8.69401 10.9434 9.33587 10.5648 9.85768L12.2266 11.5195C13.0217 10.5658 13.5 9.33875 13.5 8C13.5 6.66125 13.0217 5.43422 12.2266 4.48047L10.5648 6.14232ZM4.48047 12.2266C5.43422 13.0217 6.66125 13.5 8 13.5C9.33875 13.5 10.5658 13.0217 11.5195 12.2266L9.85769 10.5648C9.33586 10.9434 8.69399 11.1667 7.99998 11.1667C7.30597 11.1667 6.66411 10.9434 6.14229 10.5648L4.48047 12.2266ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM5.83331 8C5.83331 6.80339 6.80336 5.83334 7.99998 5.83334C9.1966 5.83334 10.1666 6.80339 10.1666 8C10.1666 9.19662 9.1966 10.1667 7.99998 10.1667C6.80336 10.1667 5.83331 9.19662 5.83331 8Z" fill="#171618" />
  </Svg>
);
