import { AddIcon } from '@cycle-app/ui/icons';

import { useFeedbackDocType } from 'src/hooks';
import { useInboxZeroProductTour } from 'src/hooks/productTour/useInboxZeroProductTour';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';

import { Action } from './InboxZero.style';

export const FeedbackAction = () => {
  const feedback = useFeedbackDocType();
  const {
    isActive: isProductTourActive, setSeePropertiesStep,
  } = useInboxZeroProductTour();

  return (
    <Action
      iconStart={<AddIcon size={14} />}
      onClick={() => {
        setSeePropertiesStep();

        setCreateDoc({
          modalVisible: true,
          doctypeId: feedback?.id,
        });
      }}
    >
      {isProductTourActive ? `Create your first ${feedback?.name}` : `New ${feedback?.name}`}
    </Action>
  );
};
