import {
  ChangeDoctypeEmojiDocument,
  ChangeDoctypeNameDocument,
  ChangeDoctypeDescriptionDocument,
  AddDoctypeAttributeDefinitionDocument,
  RemoveDoctypeAttributeDocument,
  AddNewDoctypeDocument,
  ProductBySlugDocument,
  ProductDoctypesDocument,
  RemoveDoctypeDocument,
  UpdateDoctypeDocument,
  UpdateDoctypeMutationVariables,
  MutationAddNewDoctypeArgs,
  BuiltInSpecificAttributeDefinitionFragmentDoc,
  DoctypeCategory,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useAttributes, useBuiltIntSpecificAttributes } from 'src/hooks/api/useAttributes';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useProduct } from 'src/hooks/api/useProduct';
import { useLoader } from 'src/hooks/useLoader';
import { useNavigate } from 'src/hooks/useNavigate';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { isCustom } from 'src/utils/docType.util';

import { useProductDoctypesFull } from '../useProductDoctypes';

export interface Options {
  redirectOnCreate?: boolean;
}

export default function useDoctypesMutations(doctypeId?: string, options: Options = { redirectOnCreate: true }) {
  const { product } = useProduct();
  const {
    doctypes, doctypesConnection, productId,
  } = useProductDoctypesFull();
  const allAttributes = useAttributes();
  const { builtIntSpecificAttributes } = useBuiltIntSpecificAttributes();
  const doctype = useDoctype(doctypeId);

  const { navigate } = useNavigate();

  const [updateDoctypeEmojiMutation, { loading: loadingUpdateDoctypeEmoji }] = useSafeMutation(ChangeDoctypeEmojiDocument, {
    onCompleted: () => trackAnalytics(Events.DocTypeUpdated),
  });
  const [updateDoctypeNameMutation, { loading: loadingUpdateDoctypeName }] = useSafeMutation(ChangeDoctypeNameDocument, {
    onCompleted: () => trackAnalytics(Events.DocTypeUpdated),
  });
  const [updateDoctypeDescriptionMutation, { loading: loadingUpdateDoctypeDescription }] = useSafeMutation(ChangeDoctypeDescriptionDocument, {
    onCompleted: () => trackAnalytics(Events.DocTypeUpdated),
  });
  const [addDoctypeAttributeMutation, { loading: loadingAddDoctypeAttribute }] = useSafeMutation(AddDoctypeAttributeDefinitionDocument, {
    onCompleted: () => trackAnalytics(Events.DoctypeAttributeCreated),
  });
  const [removeDoctypeAttributeMutation, { loading: loadingremoveDoctypeAttribute }] = useSafeMutation(RemoveDoctypeAttributeDocument, {
    onCompleted: () => trackAnalytics(Events.DoctypeAttributeDeleted),
  });
  const [addNewDoctype, { loading: loadingAddNewDoctype }] = useSafeMutation(AddNewDoctypeDocument, {
    onCompleted(data) {
      if (data) {
        trackAnalytics(Events.DocTypeCreated, { name: data.addNewDoctype?.name ?? '' });
        const category = data.addNewDoctype?.category;
        if (category && options.redirectOnCreate) {
          navigate(PageId.SettingsDocTypes, {
            doctypeId: data.addNewDoctype?.id,
          });
        }
      }
    },
  });
  const [deleteDoctypeMutation, { loading: loadingRemoveDoctype }] = useSafeMutation(RemoveDoctypeDocument, {
    onCompleted: () => trackAnalytics(Events.DocTypeDeleted),
  });
  const [updateDoctypeMutation, { loading: loadingEditDoctype }] = useSafeMutation(UpdateDoctypeDocument, {
    onCompleted: () => trackAnalytics(Events.DocTypeUpdated),
  });

  const loading =
    loadingUpdateDoctypeEmoji ||
    loadingUpdateDoctypeName ||
    loadingUpdateDoctypeDescription ||
    loadingAddDoctypeAttribute ||
    loadingremoveDoctypeAttribute ||
    loadingAddNewDoctype ||
    loadingRemoveDoctype ||
    loadingEditDoctype;

  useLoader({ loading });

  const addDoctype = useCallback((newDoctype: Omit<MutationAddNewDoctypeArgs, 'productId' | 'category'>) => product && addNewDoctype({
    variables: {
      category: DoctypeCategory.Delivery,
      name: newDoctype.name.trim(),
      emoji: newDoctype.emoji,
      productId: product.id,
      ...newDoctype.description?.trim() && {
        description: newDoctype.description.trim(),
      },
    },
    update(cache, { data }) {
      if (!data?.addNewDoctype) return;

      cache.writeQuery({
        query: ProductBySlugDocument,
        variables: {
          slug: product.slug,
        },
        data: {
          product: {
            ...product,
            doctypes: {
              __typename: 'DoctypesConnection',
              edges: [
                {
                  __typename: 'DoctypeEdge' as const,
                  node: data.addNewDoctype,
                },
                ...product.doctypes.edges,
              ],
            },
          },
        },
      });

      if (!doctypesConnection) return;

      cache.writeQuery({
        query: ProductDoctypesDocument,
        variables: {
          productId: product.id,
        },
        data: {
          node: {
            id: product.id,
            doctypes: {
              __typename: 'DoctypesConnection',
              edges: [
                {
                  __typename: 'DoctypeEdge' as const,
                  node: data.addNewDoctype,
                },
                ...doctypesConnection.edges,
              ],
            },
          },
        },
      });
    },
    errorPolicy: 'all',
  }), [addNewDoctype, product, doctypesConnection]);

  const updateDoctypeEmoji = useCallback(async (emoji: string) => {
    if (!doctypeId) return;

    await updateDoctypeEmojiMutation({
      variables: {
        doctypeId,
        emoji,
      },
      optimisticResponse: {
        changeDoctypeEmoji: {
          __typename: 'Doctype',
          id: doctypeId,
          emoji,
        },
      },
    });
  }, [updateDoctypeEmojiMutation, doctypeId]);

  const updateDoctypeName = useCallback(async (name: string) => {
    if (!doctypeId) return;
    await updateDoctypeNameMutation({
      variables: {
        doctypeId,
        name,
      },
      optimisticResponse: {
        changeDoctypeName: {
          __typename: 'Doctype',
          id: doctypeId,
          name,
        },
      },
    });
  }, [updateDoctypeNameMutation, doctypeId]);

  const updateDoctypeDescription = useCallback(async (description: string) => {
    if (!doctypeId) return;
    await updateDoctypeDescriptionMutation({
      variables: {
        doctypeId,
        description,
      },
      optimisticResponse: {
        changeDoctypeDescription: {
          __typename: 'Doctype',
          id: doctypeId,
          description,
        },
      },
    });
  }, [updateDoctypeDescriptionMutation, doctypeId]);

  const addDoctypeAttribute = useCallback(async (newAttributeId: string, customDoctype?: typeof doctype) => {
    const finalDoctype = doctype ?? customDoctype;
    if (!finalDoctype) return;
    const newAttribute = [...allAttributes, ...builtIntSpecificAttributes].find(a => a.id === newAttributeId);
    if (!newAttribute) return;
    const isAttributeBuiltIn = builtIntSpecificAttributes.find(attribute => newAttributeId === attribute.id);
    await addDoctypeAttributeMutation({
      variables: {
        doctypeId: finalDoctype.id,
        attributeDefinitionId: newAttributeId,
      },
      update(cache, { data }) {
        if (!isAttributeBuiltIn || !product || !finalDoctype) return;
        const builtInFragmentRef = cache.readFragment({
          id: newAttributeId,
          fragmentName: 'BuiltInSpecificAttributeDefinition',
          fragment: BuiltInSpecificAttributeDefinitionFragmentDoc,
        });
        if (builtInFragmentRef) {
          cache.writeFragment({
            fragmentName: 'BuiltInSpecificAttributeDefinition',
            fragment: BuiltInSpecificAttributeDefinitionFragmentDoc,
            data: {
              ...builtInFragmentRef,
              doctypes: {
                ...builtInFragmentRef.doctypes,
                edges: [
                  ...builtInFragmentRef.doctypes.edges,
                  {
                    node: {
                      ...finalDoctype,
                      ...data?.addDoctypeAttributeDefinition,
                    },
                  },
                ],
              },
            },
          });
        }
        if (!doctypesConnection) return;
        cache.writeQuery({
          query: ProductDoctypesDocument,
          variables: {
            productId: product.id,
          },
          data: {
            node: {
              id: product.id,
              doctypes: {
                __typename: 'DoctypesConnection',
                edges: doctypesConnection.edges.map((edge) => {
                  if (
                    edge.node.id === data?.addDoctypeAttributeDefinition?.id
                  ) {
                    return {
                      ...edge,
                      node: {
                        ...edge.node,
                        ...data?.addDoctypeAttributeDefinition,
                      },
                    };
                  }
                  return edge;
                }),
              },
            },
          },
        });
      },
      optimisticResponse: {
        addDoctypeAttributeDefinition: {
          __typename: 'Doctype',
          id: finalDoctype.id,
          customer: {
            id: newAttribute.id,
          },
          attributeDefinitions: {
            __typename: 'CustomAttributeDefinitionsConnection',
            edges:
              newAttribute.__typename === 'BuiltInCustomerDefinition' ||
              newAttribute.__typename === 'BuiltInCompanyDefinition'
                ? finalDoctype?.attributeDefinitions.edges ?? []
                : [
                  ...(finalDoctype?.attributeDefinitions.edges ?? []),
                  {
                    __typename: 'CustomAttributeDefinitionEdge',
                    node: newAttribute,
                  },
                ],
          },
        },
      },
    });
  }, [allAttributes, builtIntSpecificAttributes, addDoctypeAttributeMutation, doctype, product, doctypesConnection]);

  const removeDoctypeAttribute = useCallback(async (removedAttributeId: string, customDoctype?: typeof doctype) => {
    const finalDoctype = doctype || customDoctype;
    if (!finalDoctype) return;
    const isAttributeBuiltIn = builtIntSpecificAttributes.find(attribute => removedAttributeId === attribute.id);
    await removeDoctypeAttributeMutation({
      variables: {
        doctypeId: finalDoctype.id,
        attributeId: removedAttributeId,
      },
      update(cache) {
        if (!isAttributeBuiltIn || !product) return;
        const builtInFragmentRef = cache.readFragment({
          id: removedAttributeId,
          fragmentName: 'BuiltInSpecificAttributeDefinition',
          fragment: BuiltInSpecificAttributeDefinitionFragmentDoc,
        });
        if (builtInFragmentRef) {
          cache.writeFragment({
            fragmentName: 'BuiltInSpecificAttributeDefinition',
            fragment: BuiltInSpecificAttributeDefinitionFragmentDoc,
            data: {
              ...builtInFragmentRef,
              doctypes: {
                ...builtInFragmentRef.doctypes,
                edges: builtInFragmentRef.doctypes.edges.filter(({ node }) => node.id !== finalDoctype.id),
              },
            },
          });
        }
        if (!doctypesConnection) return;
        cache.writeQuery({
          query: ProductDoctypesDocument,
          variables: {
            productId: product.id,
          },
          data: {
            node: {
              id: product.id,
              doctypes: {
                __typename: 'DoctypesConnection',
                edges: doctypesConnection.edges.map(edge => {
                  if (edge.node.id === finalDoctype.id) {
                    return {
                      ...edge,
                      node: {
                        ...edge.node,
                        customer: null,
                      },
                    };
                  }
                  return edge;
                }),
              },
            },
          },
        });
      },
      optimisticResponse: {
        removeDoctypeAttribute: {
          __typename: 'Doctype',
          id: finalDoctype.id,
          attributeDefinitions: {
            __typename: 'CustomAttributeDefinitionsConnection',
            edges: finalDoctype.attributeDefinitions?.edges.filter(edge => edge.node.id !== removedAttributeId),
          },
        },
      },
    });
  }, [builtIntSpecificAttributes, doctype, doctypesConnection, product, removeDoctypeAttributeMutation]);

  const deleteDoctype = useCallback(async () => doctype && isCustom(doctype) && deleteDoctypeMutation({
    variables: {
      doctypeId: doctype.id,
    },
    update(cache, { data }) {
      if (!data?.removeDoctype || !productId || !product) return;
      cache.writeQuery({
        query: ProductBySlugDocument,
        variables: {
          slug: product.slug,
        },
        data: {
          product: {
            ...product,
            builtInSpecificAttributeDefinitions: {
              ...product.builtInSpecificAttributeDefinitions,
              edges: product.builtInSpecificAttributeDefinitions.edges.map(edge => {
                if (edge.__typename === 'BuiltInSpecificAttributeDefinitionEdge') {
                  return {
                    ...edge,
                    node: {
                      ...edge.node,
                      doctypes: {
                        ...edge.node.doctypes,
                        edges: edge.node.doctypes.edges.filter(d => d.node.id !== doctype.id),
                      },
                    },
                  };
                }
                return edge;
              }),
            },
          },
        },
      });
      cache.writeQuery({
        query: ProductDoctypesDocument,
        variables: { productId },
        data: {
          node: {
            id: productId,
            doctypes: {
              __typename: 'DoctypesConnection',
              edges: doctypes
                .filter(({ id }) => id !== doctypeId)
                .map(doctypeData => ({
                  __typename: 'DoctypeEdge' as const,
                  node: doctypeData,
                })),
            },
          },
        },
      });
    },
  }), [doctype, deleteDoctypeMutation, productId, product, doctypes, doctypeId]);

  const updateDoctype = useCallback(({
    name, emoji, description,
  }: Omit<UpdateDoctypeMutationVariables, 'doctypeId'>) => doctype && updateDoctypeMutation({
    variables: {
      doctypeId: doctype.id,
      name: name.trim(),
      description: description?.trim() || null,
      emoji,
    },
    errorPolicy: 'all',
  }), [doctype, updateDoctypeMutation]);

  return {
    loading,
    addDoctype,
    updateDoctypeEmoji,
    updateDoctypeName,
    updateDoctypeDescription,
    addDoctypeAttribute,
    removeDoctypeAttribute,
    deleteDoctype,
    updateDoctype,
    doctypes,
  };
}
