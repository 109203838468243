import { useMemo } from 'react';

import { useBoardSections } from './api/useBoardSections';
import { useProductIntegrations } from './api/useProductIntegrations';

export const useProductCounters = () => {
  const {
    sections, customSections, inbox, inboxWitoutMyInbox,
  } = useBoardSections();
  const boardsCount = useMemo(() => (
    sections.reduce((acc, section) => acc + section.boards.edges.length, 0)
  ), [sections]);
  const { installedCount: installedIntegrationsCount } = useProductIntegrations();
  const customSectionsCount = useMemo(() => customSections.length, [customSections]);
  return {
    boardsCount,
    customSectionsCount,
    installedIntegrationsCount,
    inboxCount: inbox.length,
    inboxCountWithMyInbox: inboxWitoutMyInbox.length,
  };
};
