import {
  DocFullFragment, FilterPropertyRuleDocParentFragment, FilterPropertyRuleAssigneeFragment, FilterPropertyRuleDoctypeFragment,
  FilterPropertyRuleSingleSelectFragment, FilterPropertyRuleMultiSelectFragment, FilterPropertyRuleCheckboxFragment,
  FilterPropertyRulePhoneFragment, FilterPropertyRuleUrlFragment, FilterPropertyRuleEmailFragment,
  FilterPropertyRuleNumberFragment, FilterPropertyRuleTextFragment,
  OperatorIsInOrNot, OperatorIsEmptyOrNot, OperatorCheckbox, OperatorNumberSingleValue, OperatorTextMultipleValues,
  FilterPropertyRuleCustomerFragment,
  FilterPropertyRuleCompanyFragment,
  FilterPropertyRuleStatusFragment,
} from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

import { isCompanyCompatible, isCustomerCompatible } from '../utils/compatibility.util';

const isDocTypeCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleDoctypeFragment) => {
  const allTypes = nodeToArray(filter?.doctypeRule.values);
  const selectedTypes = allTypes.filter(t => t.selected);
  const finalTypes = selectedTypes.length > 0 ? selectedTypes : allTypes;
  return finalTypes.some(type => type.value.id === doc.doctype.id);
};

const isParentCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleDocParentFragment) => {
  const parentId = doc.parent?.id;
  const rule = filter.docParentRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !parentId;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!parentId;
  }

  if (rule.__typename === 'RuleDocParentMultipleValues') {
    const selected = nodeToArray(rule.values).filter(p => p.selected);
    const match = selected.some(p => p.value.id === parentId);
    if (rule.operator === OperatorIsInOrNot.Is) return selected.length === 0 || match;
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

const isAssigneeCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleAssigneeFragment) => {
  const assigneeId = doc.assignee?.id;
  const rule = filter.assigneeRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !assigneeId;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!assigneeId;
  }

  if (rule.__typename === 'RuleUserMultipleValues') {
    const selected = nodeToArray(rule.values).filter(a => a.selected);
    const match = selected.some(a => a.value.id === assigneeId);
    if (rule.operator === OperatorIsInOrNot.Is) return selected.length === 0 || match;
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

const isSingleSelectCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleSingleSelectFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const valueId = attribute?.__typename === 'DocAttributeSingleSelect' && attribute.selectValue?.id;
  const rule = filter.singleSelectRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !valueId;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!valueId;
  }

  if (rule.__typename === 'RuleSingleSelectMultipleValues') {
    const selected = nodeToArray(rule.values).filter(v => v.selected);
    const match = selected.some(v => v.value.id === valueId);
    if (rule.operator === OperatorIsInOrNot.Is) return selected.length === 0 || match;
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

const isMultipleSelectCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleMultiSelectFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const valueIds = attribute?.__typename === 'DocAttributeMultiSelect' && attribute.selectValues?.map(v => v.id);
  const rule = filter.multiSelectRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !valueIds;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!valueIds;
  }

  if (rule.__typename === 'RuleMultiSelectMultipleValues') {
    const selected = nodeToArray(rule.values).filter(v => v.selected);
    const match = valueIds && valueIds.some(id => selected.some(v => v.value.id === id));
    if (rule.operator === OperatorIsInOrNot.Is) return selected.length === 0 || match;
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

const isCheckboxCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleCheckboxFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const isChecked = attribute?.__typename === 'DocAttributeCheckbox' && (attribute.checkboxValue?.value ?? false);
  if (filter.checkboxOperator === OperatorCheckbox.IsTrue) return isChecked;
  if (filter.checkboxOperator === OperatorCheckbox.IsFalse) return !isChecked;
  return true;
};

const isPhoneCompatible = (doc: DocFullFragment, filter: FilterPropertyRulePhoneFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const value = attribute?.__typename === 'DocAttributePhone' && attribute.phoneValue?.value;
  const rule = filter.phoneRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !attribute;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!attribute;
  }

  if (rule.__typename === 'RulePhoneMultipleValues') {
    const values = nodeToArray(rule.values);
    const match = values.some(v => v.value === value);
    if (rule.operator === OperatorIsInOrNot.Is) return (values.length === 0 && !!value) || (values.length > 0 && match);
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

const isUrlCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleUrlFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const value = attribute?.__typename === 'DocAttributeUrl' && attribute.urlValue?.value;
  const rule = filter.urlRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !attribute;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!attribute;
  }

  if (rule.__typename === 'RuleUrlMultipleValues') {
    const values = nodeToArray(rule.values);
    const match = values.some(v => v.value === value);
    if (rule.operator === OperatorIsInOrNot.Is) return (values.length === 0 && !!value) || (values.length > 0 && match);
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

const isEmailCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleEmailFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const value = attribute?.__typename === 'DocAttributeEmail' && attribute.emailValue?.value;
  const rule = filter.emailRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !attribute;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!attribute;
  }

  if (rule.__typename === 'RuleEmailMultipleValues') {
    const values = nodeToArray(rule.values);
    const match = values.some(v => v.value === value);
    if (rule.operator === OperatorIsInOrNot.Is) return (values.length === 0 && !!value) || (values.length > 0 && match);
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

const isNumberCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleNumberFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const value = attribute?.__typename === 'DocAttributeNumber' && attribute.numberValue?.value;
  const rule = filter.numberRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !attribute;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!attribute;
  }

  if (rule.__typename === 'RuleNumberSingleValue') {
    const filterValue = rule.value?.value;
    if (rule.operator === OperatorNumberSingleValue.IsNot) return value !== filterValue;
    if (value === undefined || value === false) return false;
    if (filterValue === undefined) return true;
    if (rule.operator === OperatorNumberSingleValue.Is) return value === filterValue;
    if (rule.operator === OperatorNumberSingleValue.IsLowerThan) return value < filterValue;
    if (rule.operator === OperatorNumberSingleValue.IsGreaterThan) return value > filterValue;
    if (rule.operator === OperatorNumberSingleValue.IsLowerThanOrEqualTo) return value <= filterValue;
    if (rule.operator === OperatorNumberSingleValue.IsGreaterThanOrEqualTo) return value >= filterValue;
  }

  return true;
};

const isTextCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleTextFragment) => {
  const attribute = nodeToArray(doc.attributes).find(a => a.definition.id === filter.attribute.id);
  const docValue = attribute?.__typename === 'DocAttributeText' && attribute.textValue?.value;
  const rule = filter.textRule;

  if (rule.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !attribute;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!attribute;
  }

  if (rule.__typename === 'RuleTextMultipleValues') {
    const filterValues = nodeToArray(rule.values).map(v => v.value);
    const isFilterUseless = filterValues.length === 0 && !!docValue;

    const matchExact = docValue && filterValues.includes(docValue);
    if (rule.operator === OperatorTextMultipleValues.Is) return isFilterUseless || (filterValues.length > 0 && matchExact);
    if (rule.operator === OperatorTextMultipleValues.IsNot) return !docValue || !matchExact;

    const matchContains = docValue && filterValues.some(v => docValue.includes(v));
    if (rule.operator === OperatorTextMultipleValues.Contains) return isFilterUseless || (filterValues.length > 0 && matchContains);
    if (rule.operator === OperatorTextMultipleValues.DoesNotContain) return !docValue || !matchContains;
  }

  return true;
};

const isCustomerDocCompatible = (doc: DocFullFragment | null, filter: FilterPropertyRuleCustomerFragment) => (
  isCustomerCompatible(doc?.customer?.id ?? '', filter));

const isCompanyDocCompatible = (doc: DocFullFragment | null, filter: FilterPropertyRuleCompanyFragment) => (
  isCompanyCompatible(doc?.customer?.company?.id ?? '', filter));

const isStatusCompatible = (doc: DocFullFragment, filter: FilterPropertyRuleStatusFragment) => {
  const statusId = doc.status?.id;
  const rule = filter.statusRule;

  if (rule.__typename === 'RuleStatusMultipleValues') {
    const selected = nodeToArray(rule.values).filter(a => a.selected);
    const match = selected.some(a => a.value.id === statusId);
    if (rule.operator === OperatorIsInOrNot.Is) return selected.length === 0 || match;
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

export const useIsDocInBoard = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  return useCallback((doc: DocFullFragment | null): boolean | undefined => {
    if (!doc || !boardConfig) return undefined;

    for (const filter of nodeToArray(boardConfig?.filterProperties)) {
      const { __typename } = filter;
      if (__typename === 'FilterPropertyRuleDoctype' && !isDocTypeCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleDocParent' && !isParentCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleAssignee' && !isAssigneeCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleSingleSelect' && !isSingleSelectCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleMultiSelect' && !isMultipleSelectCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleCheckbox' && !isCheckboxCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRulePhone' && !isPhoneCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleUrl' && !isUrlCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleEmail' && !isEmailCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleNumber' && !isNumberCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleText' && !isTextCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleCustomer' && !isCustomerDocCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleCompany' && !isCompanyDocCompatible(doc, filter)) return false;
      if (__typename === 'FilterPropertyRuleStatus' && !isStatusCompatible(doc, filter)) return false;
    }

    return true;
  }, [boardConfig]);
};
