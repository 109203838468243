import { TextButton, typo, boxShadowZ4, Button, truncate } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

const zIndexDropdown = mappingZindex[Layer.Dropdown];

export const ContentList = styled.ul`
  --gap: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  list-style: none;

  padding: 0px 8px 2px;

  /* This is necessary because dnd-kit needs a ghost item in every column */
  li.void {
    overflow: hidden;
    height: 0;
    margin-bottom: calc(var(--gap) * -1);
  }
`;

export const InputGroupName = styled.input`
  border: none;
  outline: none;
  width: 100%;
  height: 24px;

  ${p => p.readOnly && css`
    pointer-events: none;
    cursor: inherit;
  `};
`;

export const MoreButton = styled(TextButton)`
  ${typo.caption600};
`;

interface InputContainerProps {
  $isFixedWidth?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  ${boxShadowZ4};
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${p => p.theme.colors.dropdown.border};
  background-color: ${p => p.theme.colors.background.secondary};
  display: flex;
  gap: 4px;
  height: 32px;
  left: 8px;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: ${zIndexDropdown};

  ${p => p.$isFixedWidth && css`
    right: auto;
    width: 270px;
  `}
`;

export const SaveButton = styled(Button)`
  border-radius: 4px;
`;

export const GroupNameTitle = styled.h2`
  max-width: 100%;
  overflow: hidden;
  display: inline;
`;

export const TitleContent = styled.div`
  width: 100%;
  ${truncate}
`;

export const ToolbarDotsMenuLayer = styled(DotsMenuLayer)`
  --bgHover: ${p => p.theme.colors.actionButton.bgHoverDark};
  --bgActive: ${p => p.theme.colors.actionButton.bgActiveDark};
`;
