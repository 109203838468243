import { Events } from 'src/constants/analytics.constants';
import { Analytics, Params, UnknownParams, TrackAnalyticsParams } from 'src/types/segment.types';

declare global {
  interface Window {
    analytics?: Analytics;
  }
}

const sessionId = `${Math.round(Date.now() / 1000)}`;

const integrations = {
  Amplitude: {
    session_id: sessionId,
  },
};

export const identifyAnalytics = (id: string, params: Params): void => {
  if (process.env.SEGMENT_KEY) {
    window.analytics?.identify(id, params, {
      integrations,
    });
  }
};

export const groupAnalytics = (groupId: string, params: UnknownParams): void => {
  if (process.env.SEGMENT_KEY) {
    window.analytics?.group(groupId, params);
  }
};

export const trackAnalytics = (
  event: Events,
  params?: TrackAnalyticsParams,
): void => {
  if (process.env.SEGMENT_KEY) {
    window.requestIdleCallback(() => window.analytics?.track(event, params, { integrations }));
  }
};

export const loadAnalytics = (): void => {
  window.analytics?.load(process.env.SEGMENT_KEY ?? '');
};

export const pageAnalytics = (url?: string): void => {
  if (process.env.SEGMENT_KEY) {
    window.requestIdleCallback(() => window.analytics?.page(url));
  }
};
