import styled, { css } from 'styled-components';

import { caption400, caption500, caption600 } from '../../theme/typo';
import { truncate } from '../../utils/styles.util';
import { transitionCss } from '../../utils/transition.util';
import { Button } from '../Buttons/Button/Button';

type Props = {
  warning: boolean;
};

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;

  color: ${p => (p.warning
    ? p.theme.colors.sidebar.freePlanWarning.text.primary
    : p.theme.colors.sidebar.freePlan.text.primary)};

  background: ${p => (p.warning
    ? p.theme.colors.sidebar.freePlanWarning.bg
    : p.theme.colors.sidebar.freePlan.bg)};

  ${transitionCss(['background', 'color'])}

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const Text = styled.div`
  ${caption400}
  ${truncate}
  max-width: 100%;
  flex: none;
  color: ${p => p.theme.colors.sidebar.freePlan.text.primary};
`;

export const TextSecondary = styled(Text)<Props>`
  color: ${p => (p.warning
    ? p.theme.colors.sidebar.freePlanWarning.text.secondary
    : p.theme.colors.sidebar.freePlan.text.secondary)};
`;

export const Title = styled(Text)`
  ${caption500}
  color: ${p => p.theme.colors.sidebar.freePlan.text.primary};
`;

export const UpgradeButton = styled(Button)<Props>`
  flex: none;
  width: auto;
  padding: 0 4px;
  border-radius: 4px;
  max-width: 100%;
  gap: 4px;
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;
  ${caption600}
  ${truncate}

  ${p => p.warning && css`
    &, :hover, :focus {
      color: ${p.theme.colors.sidebar.freePlanWarning.link.text};
    }

    background: ${p.theme.colors.sidebar.freePlanWarning.link.bg};

    :hover, :focus {
      background: ${p.theme.colors.sidebar.freePlanWarning.link.bgHover};
    }

    :active {
      background: ${p.theme.colors.sidebar.freePlanWarning.link.bgActive};
    }
  `}

`;
