import { typo, boxShadowZ2 } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import DialogModal from 'src/components/DialogModal/DialogModal';

export const ListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.h2`
  ${typo.headerSmall};
  font-weight: 500;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};

  > svg {
    height: 16px;
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const List = styled.div`
  > * {
    margin: 8px 0;
  }
  > * + * {
    border-top: 1px solid ${p => p.theme.colors.border.hover};
  }
`;

export const StyledModal = styled(DialogModal)`
  width: 548px;
`;

export const FlowContainer = styled.div<{ $isCover?: boolean }>`
  height: 100%;

  ${p => p.$isCover && css`
    display: flex;
    justify-content: center;
    width: 80%;
    margin-bottom: 24px;
  `}

  .react-flow__node {
    cursor: default;
    background: transparent;
    border: none;
    padding: 0;
    box-shadow: none !important;
    width: auto;
    height: auto;
  }

  .react-flow__handle {
    visibility: hidden;
  }

  .react-flow__edge {
    color: ${p => p.theme.colors.border.greyLight};
    opacity: 0;
    transition: opacity .2s;
  }

  .react-flow__edge-path {
    stroke: currentColor;
    fill: none;
  }

  .react-flow__edge-circle {
    stroke: transparent;
    stroke: ${p => p.theme.colors.border.greyLight};
    fill: ${p => p.theme.colors.onboarding.background.body};
  }

  &[data-loaded] .react-flow__edge {
    opacity: 1;
  }
`;

export const NodeContainer = styled(motion.div)<{ $installed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 46px;
  height: 46px;
  border-radius: 13px;
  border: 1px solid ${p => p.theme.colors.border.greyLight};
  background: ${p => p.theme.colors.onboarding.background.body};

  opacity: ${p => (p.$installed ? 1 : 0.5)};

  > svg {
    min-width: 24px;
    min-height: 24px;
    max-width: 32px;
    max-height: 32px;
  }

  ${boxShadowZ2}
`;

export const Check = styled(motion.div)`
  position: absolute;
  top: -8px;
  right: -8px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50%;

  background: ${Color.Green700};
  color: #fff;
`;
