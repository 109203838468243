import { useSubscription } from '@apollo/client';
import {
  DeletedDocSubscription,
  DeletedDocDocument,
  DeletedDocSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useCustomerDocFromCache } from './cache/cacheCustomerDoc';
import { useGetDocFromCache } from './cache/cacheDoc';
import { useUpdateChildCache } from './cache/cacheHierarchy';

export const useDeletedDocSubscription = (productId?: string | null) => {
  const getDoc = useGetDocFromCache();
  const updateChild = useUpdateChildCache();
  const { removeCustomerDoc } = useCustomerDocFromCache();
  return useSubscription<DeletedDocSubscription, DeletedDocSubscriptionVariables>(
    DeletedDocDocument,
    {
      variables: {
        productId: productId as string,
      },
      onSubscriptionData: ({
        subscriptionData: { data },
        client,
      }) => {
        if (data?.deletedDoc) {
          const doc = getDoc(data.deletedDoc);
          if (doc?.parent?.id) {
            updateChild({
              docId: doc.id,
              parentId: doc.parent.id,
              action: 'remove',
            });
          }
          if (doc?.customer?.id) {
            removeCustomerDoc({ doc });
          }
          client.cache.evict({ id: data.deletedDoc });
        }
      },
    },
  );
};
