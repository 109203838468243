import { useApolloClient, DataProxy } from '@apollo/client';
import { produce } from 'immer';

type Options<QueryType, TVariables> = DataProxy.ReadQueryOptions<QueryType, TVariables>;
type Recipe<QueryType> = (draft: QueryType) => void;
type UpdateQuery<QueryType> = (recipe: Recipe<QueryType>) => void;

/**
 * Hook to update a query in the cache using Immer
 * cache.updateQuery is available in Apollo Client 3.5 or later
 */
export const useUpdateQuery = <QueryType, TVariables>(options: Options<QueryType, TVariables>): UpdateQuery<QueryType> => {
  const { cache } = useApolloClient();
  return (recipe) => {
    cache.writeQuery({
      ...options,
      data: produce(cache.readQuery(options), recipe),
    });
  };
};
