import BoardEditCommonModal from 'src/components/BoardEditCommonModal/BoardEditCommonModal';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useBoard } from 'src/hooks/api/useBoard';

interface EditBoardModalProps {
  boardId: string;
  onHide: VoidFunction;
  focusDescription?: boolean;
}

const EditBoardModal = ({
  boardId, onHide, focusDescription = false,
}: EditBoardModalProps) => {
  const board = useBoard(boardId);
  const {
    changeBoardEmoji,
    changeBoardInfo,
  } = useBoardMutations();

  if (!board) return null;

  return (
    <BoardEditCommonModal
      defaultValues={board}
      title="Edit view"
      onHide={onHide}
      onSubmit={async (formData) => {
        onHide();
        if (formData.emoji !== board.emoji) {
          await changeBoardEmoji(board.id, formData.emoji);
        }
        if (formData.name !== board.name || formData.description !== board.description) {
          await changeBoardInfo(board.id, formData.name, formData.description);
        }
      }}
      focusDescriptionOnMount={focusDescription}
    />
  );
};

export default EditBoardModal;
