/* eslint-disable no-nested-ternary */
import { useApolloClient } from '@apollo/client';
import {
  DocChildrensDocument,
  DocChildrensQueryVariables,
  DocChildrensFragment,
} from '@cycle-app/graphql-codegen';

import { defaultHierarchyPagination } from 'src/utils/pagination.util';

import { useGetDocChildFromCache, useGetDocChildrensFromCache, useGetDocFromCache } from './cacheDoc';

interface Params {
  parentId: string;
  docId: string;
  action: 'add' | 'remove';
}

export const useUpdateChildCache = () => {
  const { cache } = useApolloClient();

  const getDoc = useGetDocFromCache();
  const getDocChildrens = useGetDocChildrensFromCache();
  const getDocChild = useGetDocChildFromCache();

  return ({
    parentId,
    docId,
    action,
  }: Params) => {
    const docParent = getDoc(parentId);
    if (!docParent) return;

    const docChild = getDocChild(docId);

    const doctypeId = docChild?.doctype.id;
    if (!doctypeId) return;

    const docWithChildrens = getDocChildrens({
      docId: parentId,
      doctypeId,
    });
    if (!docWithChildrens) return;

    const docChildren = getDocChild(docId);
    if (!docChildren) return;

    const childrensData = cache.readQuery<{ node: DocChildrensFragment }, DocChildrensQueryVariables>({
      query: DocChildrensDocument,
      variables: {
        docId: parentId,
        doctypeId,
        ...defaultHierarchyPagination,
      },
    });

    const children = [...childrensData?.node.children.edges ?? []];
    // @TODO refactor
    const isChildInCacheDirty = children.some(c => c.node.id === docChildren.id);

    // Add the doc only if we have no pagination. This prevents having duplicated data
    // between the the doc we cache and the same that will be loaded after the "load more".
    if (action === 'add' && !docWithChildrens.children.pageInfo.hasNextPage) {
      if (isChildInCacheDirty) {
        children.filter(c => c.node.id !== docChildren.id).push({
          __typename: 'DocEdge',
          cursor: '',
          node: docChildren,
        });
      } else {
        children.push({
          __typename: 'DocEdge',
          cursor: '',
          node: docChildren,
        });
      }
    }
    if (action === 'remove') {
      const indexToRemove = children.findIndex(({ node }) => node.id === docId);
      if (indexToRemove >= 0) {
        children.splice(indexToRemove, 1);
      }
    }

    cache.writeQuery({
      query: DocChildrensDocument,
      variables: {
        docId: parentId,
        doctypeId,
        ...defaultHierarchyPagination,
      },
      data: {
        node: {
          __typename: 'Doc',
          id: parentId,
          children: {
            ...docWithChildrens.children,
            count: docWithChildrens.children.count + (action === 'add' ? isChildInCacheDirty ? 0 : 1 : -1),
            pageInfo: {
              ...docWithChildrens.children.pageInfo,
              endCursor: children[children.length - 1]?.cursor ?? '',
            },
            edges: children,
          },
        },
      },
    });

    cache.modify({
      id: cache.identify(docParent),
      fields: {
        childrenCount: () => docParent.childrenCount + (action === 'add' ? isChildInCacheDirty ? 0 : 1 : -1),
      },
    });
  };
};
