import { VFC } from 'react';

interface Props {
  color: string;
}

export const ThemeSunrise: VFC<Props> = ({ color }) => (
  <svg width="228" height="183" viewBox="0 0 228 183" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4C0 1.79086 1.79086 0 4 0H38.192V183H4C1.79086 183 0 181.209 0 179V4Z" fill="white" />
    <rect x="5.55176" y="85.2715" width="26.8966" height="12.4571" rx="5" fill={color} stroke="white" strokeWidth="2" />
    <path d="M38 0H224C226.209 0 228 1.79086 228 4V179C228 181.209 226.209 183 224 183H38V0Z" fill="#F7F7F7" />
    <path d="M49.9436 14.457C49.9436 12.2479 51.7345 10.457 53.9436 10.457H113.514C115.723 10.457 117.514 12.2479 117.514 14.457V168.543C117.514 170.752 115.723 172.543 113.514 172.543H53.9436C51.7345 172.543 49.9436 170.752 49.9436 168.543V14.457Z" fill="#E6E6E6" />
    <g filter="url(#filter0_d_2954:68117)">
      <rect x="61.6948" y="20.9141" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter1_d_2954:68117)">
      <rect x="61.6948" y="43.1355" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter2_d_2954:68117)">
      <rect x="61.6948" y="105.878" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter3_d_2954:68117)">
      <rect x="61.6948" y="65.3569" width="44.0677" height="30.0643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter4_d_2954:68117)">
      <rect x="61.6948" y="128.1" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter5_d_2954:68117)">
      <rect x="61.6948" y="150.321" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <path d="M129.265 14.457C129.265 12.2479 131.056 10.457 133.265 10.457H192.836C195.045 10.457 196.836 12.2479 196.836 14.457V121.486C196.836 123.695 195.045 125.486 192.836 125.486H133.265C131.056 125.486 129.265 123.695 129.265 121.486V14.457Z" fill="#E6E6E6" />
    <g filter="url(#filter6_d_2954:68117)">
      <rect x="141.016" y="20.9141" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter7_d_2954:68117)">
      <rect x="141.016" y="43.1355" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter8_d_2954:68117)">
      <rect x="141.016" y="105.878" width="44.0677" height="11.7643" rx="4" fill="white" />
    </g>
    <g filter="url(#filter9_d_2954:68117)">
      <rect x="141.016" y="65.3569" width="44.0677" height="30.0643" rx="4" fill="white" />
    </g>
    <defs>
      <filter id="filter0_d_2954:68117" x="59.6948" y="19.9141" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter1_d_2954:68117" x="59.6948" y="42.1355" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter2_d_2954:68117" x="59.6948" y="104.878" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter3_d_2954:68117" x="59.6948" y="64.3569" width="48.0676" height="34.0642" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter4_d_2954:68117" x="59.6948" y="127.1" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter5_d_2954:68117" x="59.6948" y="149.321" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter6_d_2954:68117" x="139.016" y="19.9141" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter7_d_2954:68117" x="139.016" y="42.1355" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter8_d_2954:68117" x="139.016" y="104.878" width="48.0676" height="15.7644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
      <filter id="filter9_d_2954:68117" x="139.016" y="64.3569" width="48.0676" height="34.0642" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.87451 0 0 0 0 0.894118 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2954:68117" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2954:68117" result="shape" />
      </filter>
    </defs>
  </svg>
);
