import { useSubscription } from '@apollo/client';
import {
  DocChildrenSubscription,
  DocChildrenDocument,
  DocChildrenSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useUpdateChildCache } from './cache/cacheHierarchy';

export const useDocChildrenSubscription = (parentDocId?: string | null) => {
  const updateDocChild = useUpdateChildCache();
  useSubscription<DocChildrenSubscription, DocChildrenSubscriptionVariables>(
    DocChildrenDocument,
    {
      variables: {
        parentDocId: parentDocId as string,
      },
      onSubscriptionData({ subscriptionData: { data } }) {
        if (!parentDocId || !data) return;

        updateDocChild({
          parentId: parentDocId,
          docId: data?.docChildren.child.id,
          action: 'add',
        });
      },
    },
  );
};
