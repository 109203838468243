import { slugify } from '@cycle-app/utilities';

export const getBoardSlug = (board?: { name: string; id: string } | null) => {
  if (!board) return '';
  return `${slugify(board.name)}-${board.id}`;
};

export const getBoardId = (boardSlug: string): string | undefined => {
  const splitSlug = boardSlug?.split('-');
  return splitSlug[splitSlug.length - 1];
};

export const getDocSlug = (doc: { title?: string; id?: string }) => (doc?.id
  ? `${slugify(doc?.title ?? '')}-${doc.id}`
  : '');
