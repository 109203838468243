import keyBy from 'lodash/keyBy';

type NodeToArray = <T>(entry?: { edges: { node: T }[] } | null) => T[];
type NodeToObject = <T>(entry?: { edges: { node: T }[] } | null) => Record<string, T>;

export const nodeToArray: NodeToArray = (entry) => {
  try {
    return entry?.edges.map(({ node }) => node) ?? [];
  } catch (err) {
    // TODO: log in sentry
    console.error(err);
    return [];
  }
};

export const nodeToObject: NodeToObject = (entry) => keyBy(nodeToArray(entry), 'id');
