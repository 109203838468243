import { SelectPanel, TextHighlighter, SelectOption } from '@cycle-app/ui';
import { AddIcon, OpenIcon } from '@cycle-app/ui/icons';
import { isEmail, useMeasure } from '@cycle-app/utilities';
import { useState, FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { CustomersAddCustomerModal } from 'src/components/CustomersList/CustomersAddCustomerModal';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useOptimizedBooleanState } from 'src/hooks';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { Layer } from 'src/types/layers.types';
import { getCustomerOption, renderCustomerOptionLabel } from 'src/utils/customers.util';

import { FieldButton, SkeletonInput, CustomerAvatar, CreateCustomerLine, Caret } from '../Form.styles';
import { OpenCustomerButton, StyledSlot, StyledName, Label, Email } from './FieldCustomer.styles';

interface Props {
  className?: string;
  createCustomerLayer?: Layer;
  createCustomerDropdownsLayer?: Layer;
  defaultEmail?: string;
  layer?: Layer;
  onChange: (value: string) => void;
  value: string;
  onCustomerModalOpen?: (customerId: string) => void;
  showClearOption?: boolean;
}

export const FieldCustomer: FC<Props> = ({
  className,
  createCustomerLayer = Layer.ModalZ3,
  createCustomerDropdownsLayer = Layer.DropdownModalZ4,
  defaultEmail,
  layer = Layer.DropdownModalZ3,
  onChange,
  value,
  onCustomerModalOpen,
  showClearOption,
}) => {
  const {
    rect: itemRect,
    ref: itemRef,
  } = useMeasure<HTMLDivElement>();
  const [searchText, setSearchText] = useState('');
  const {
    customer: selectedCustomer, loading,
  } = useCustomer(value, {
    onCompleted: (data) => {
      if (!data) onChange('');
    },
  });

  const {
    customers, fetchNextPage, hasNextPage, searchCustomers, loading: isSearchLoading,
  } = useCustomers({
    defaultSearch: defaultEmail,
    onCompleted: async (data) => {
      if (defaultEmail) {
        const defaultCustomer = data.node.customers.edges.find(edge => edge.node.email === defaultEmail);
        if (defaultCustomer) {
          onChange(defaultCustomer.node.id);
        }
        // Display the default results.
        await searchCustomers('');
      }
    },
  });
  const searchCustomersDebounced = useDebouncedCallback((search: string) => searchCustomers(search), INPUT_ONCHANGE_DEBOUNCE);
  const [isCreateCustomerVisible, { toggleCallback: toggleCreateCustomer }] = useOptimizedBooleanState(false);

  const options: SelectOption[] = customers
    .map(customer => ({
      ...getCustomerOption(customer),
      renderLabel: (filterText) => (
        renderCustomerOptionLabel({
          customer,
          filterText,
          ...onCustomerModalOpen && {
            lineHover: (
              <OpenCustomerButton
                size="S"
                onClick={e => {
                  e.stopPropagation();
                  onCustomerModalOpen(customer.id);
                }}
              >
                <OpenIcon />
                Open
              </OpenCustomerButton>
            ),
          },
        })
      ),
      end: null,
    }));

  return (
    <div ref={itemRef}>
      <ToggleDropdown
        placement="bottom-start"
        layer={layer}
        width={itemRect?.width}
        button={props => (loading || isSearchLoading ? <SkeletonInput /> : (
          <FieldButton
            className={className}
            iconStart={selectedCustomer && <CustomerAvatar customer={selectedCustomer} size="S" />}
            {...props}
          >
            <StyledSlot>
              {selectedCustomer
                ? (
                  <Label>
                    {selectedCustomer?.name?.trim() && (
                      <StyledName>{selectedCustomer.name}</StyledName>
                    )}
                    <Email>
                      {selectedCustomer.email}
                    </Email>
                  </Label>
                )
                : (
                  <span>
                    Choose from list
                  </span>
                )}
            </StyledSlot>
            <Caret direction={props['data-active'] ? 'top' : 'bottom'} />
          </FieldButton>
        ))}
        content={props => (
          <>
            <SelectPanel
              filterOptionsOnInputChange={false}
              options={options}
              selectedValue={value}
              onOptionChange={option => {
                onChange(option.value);
                props.hide();
              }}
              {...showClearOption && value && {
                onClearValue: () => {
                  onChange('');
                  props.hide();
                },
              }}
              onSearchChange={async (search) => {
                setSearchText(search);
                await searchCustomersDebounced(search);
              }}
              infiniteScroll={{
                isLoading: loading,
                hasMoreData: hasNextPage,
                loadMore: fetchNextPage,
              }}
            >
              <CreateCustomerLine
                startSlot={<AddIcon />}
                label={(
                  <>
                    Create
                    {' '}
                    {searchText ? (
                      <TextHighlighter
                        searchWords={[searchText]}
                        textToHighlight={searchText}
                        className="highlight"
                      />
                    ) : 'new customer'}
                  </>
                )}
                onClick={toggleCreateCustomer}
              />
            </SelectPanel>
            {isCreateCustomerVisible && (
              <CustomersAddCustomerModal
                defaultValues={{
                  name: isEmail(searchText, false) ? '' : searchText,
                  email: isEmail(searchText, false) ? searchText : '',
                }}
                layer={createCustomerLayer}
                dropdownsLayer={createCustomerDropdownsLayer}
                onClose={toggleCreateCustomer}
                onCreated={async data => {
                  onChange(data.id);
                  setSearchText('');
                  await searchCustomers('');
                  props.hide();
                }}
              />
            )}
          </>
        )}
      />
    </div>
  );
};
