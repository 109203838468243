import { Dropdown, DropdownProps } from '@cycle-app/ui';
import { FC, useEffect, useRef } from 'react';

import Portal from 'src/components/Portal/Portal';
import { mappingZindex } from 'src/constants/zIndex.constant';
import useDropdown from 'src/hooks/useDropdown';
import { setClosingArea } from 'src/reactives/closingArea.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';

import { ClosingArea } from './DropdownLayer.styles';

/**
 * The purpose of this component is to serve <Dropdown /> from @cycle-app/ui
 * and update the local state Layer on mount/hide
 */

export interface DropdownLayerProps extends DropdownProps {
  layer?: Layer;
  closingArea?: boolean;
  disableDocPreview?: boolean;
}

export const DropdownLayer: FC<DropdownLayerProps> = ({
  layer = Layer.Dropdown,
  visible,
  hide: hideProps,
  onMount: onMountProps,
  onHide: onHideProps,
  closingArea = true,
  disableDocPreview = true,
  ...props
}) => {
  const docPreviewEnabledInitialValue = useRef<boolean>(getDocIdPreview().enabled);

  const dropdownProps = useDropdown({
    layer,
    hide: hideProps,
    onDropdownMounted: () => {
      setDocIdPreview({ enabled: !disableDocPreview });
      onMountProps?.();
    },
    onDropdownHidden: () => {
      if (docPreviewEnabledInitialValue.current === true && disableDocPreview) {
        setDocIdPreview({ enabled: true });
      }
      onHideProps?.();
    },
  });

  useEffect(() => {
    if (!closingArea) return;
    setClosingArea({ displayed: visible });
  }, [closingArea, visible]);

  return (
    <>
      <Dropdown
        {...props}
        {...dropdownProps}
        visible={visible}
        zIndex={mappingZindex[layer]}
      />
      {closingArea && visible && (
        <Portal portalId={PortalId.DropdownClosingArea}>
          <ClosingArea onClick={e => { e.stopPropagation(); setClosingArea({ displayed: false }); }} layer={layer} />
        </Portal>
      )}
    </>
  );
};

export default DropdownLayer;
