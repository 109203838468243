import { Spinner, Shortcut, Toaster } from '@cycle-app/ui';
import { DownIcon, UpIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { isEnabled, Feature } from '@cycle-app/utilities';
import { FC, useCallback, useRef, useState, useReducer } from 'react';

import { DocProcessButton } from 'src/components/DocProcessButton';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { Size } from 'src/components/ErrorPage/ErrorPage.types';
import Portal from 'src/components/Portal/Portal';
import { PageId } from 'src/constants/routing.constant';
import { shortcuts, ShortcutDocPanel } from 'src/constants/shortcuts.constants';
import { useBoard } from 'src/hooks/api/useBoard';
import { useCreateDocModalFirstProductTour } from 'src/hooks/productTour/useCreateDocModalProductTour';
import { useDocShortcutListener } from 'src/hooks/shortcuts/useDocPanelShortcutListener';
import { useInitLayer } from 'src/hooks/state/useInitLayer';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useIsDocInBoard } from 'src/hooks/useIsDocInBoard';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePrevNextDoc } from 'src/hooks/usePrevNextDoc';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';
import { isFeedback } from 'src/utils/docType.util';
import { getDocSlug } from 'src/utils/slug.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import { DocPanelContent } from '../DocPanelContent/DocPanelContent';
import DocPanelHeader from '../DocPanelHeader/DocPanelHeader';
import DocPanelRightPanel from '../DocPanelRightPanel/DocPanelRightPanel';
import {
  Container,
  MainSection,
  Overlay,
  ToasterContainer,
  StyledEmoji,
  PrevNextActions,
  StyledActionButton,
} from './DocPanelBoardPage.styles';

const isRightPanelEnabled = isEnabled(Feature.DocViewRightPanel);

interface Props extends DocPanelBaseProps {
  widthSidebar: number;
  isDocLoading: boolean;
}

const DocPanelBoardPage: FC<Props> = ({
  doc,
  widthSidebar,
  getDropzonePropsCover,
  isDocLoading,
  isDraggingCover,
  isUploadingCover,
  onOpenCoverInputFile,
  onTitleUpdated,
}) => {
  useInitLayer(Layer.DocPanel);
  useDocShortcutListener(doc);
  const [expanded, expand] = useReducer(() => true, false);
  const { isActive: isProductTourActive } = useCreateDocModalFirstProductTour();
  const {
    navigate, navigateToDocPanelParent,
  } = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const [visible, hide] = useReducer(() => false, true);

  const goBack = useCallback(() => {
    if (isProductTourActive) return;
    hide();
    setTimeout(navigateToDocPanelParent);
  }, [navigateToDocPanelParent, isProductTourActive]);

  const onExpand = useCallback(() => {
    expand();
    setTimeout(() => {
      navigate(PageId.DocFullPage, { docSlug: doc ? getDocSlug(doc) : undefined });
    }, 100);
  }, [navigate, doc]);

  const {
    navigateToPrevDoc, navigateToNextDoc, isFirstDoc, isLastDoc, loadingNextDoc,
  } = usePrevNextDoc(doc);

  const board = useBoard();

  const getIsDocInBoard = useIsDocInBoard();
  const isDocInBoard = getIsDocInBoard(doc);

  const [autofocus, setAutofocus] = useState<'content' | 'prev' | 'next'>('content');
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const prevButtonRef = useRef<HTMLButtonElement>(null);

  const focusOnPrevButton = () => {
    setAutofocus('prev');
    prevButtonRef.current?.focus();
  };

  const focusOnNextButton = () => {
    setAutofocus('next');
    nextButtonRef.current?.focus();
  };

  const onEscapeEdition = () => {
    if (isProductTourActive) return;
    if (isLastDoc) {
      focusOnPrevButton();
    } else {
      focusOnNextButton();
    }
  };

  useAppHotkeys('control+shift+k', () => {
    focusOnPrevButton();
    navigateToPrevDoc();
  }, { enabled: !isProductTourActive });

  useAppHotkeys('control+shift+j', () => {
    focusOnNextButton();
    navigateToNextDoc();
  }, { enabled: !isProductTourActive });

  if (!visible) return null;

  return (
    <Portal portalId={PortalId.DocPanel}>
      <Overlay onClick={isProductTourActive ? undefined : goBack} />

      <Container
        ref={containerRef}
        $widthSidebar={widthSidebar}
        $expanded={expanded}
      >
        {!isDocLoading && (doc ? (
          <>
            <MainSection>
              <DocPanelHeader
                doc={doc}
                onClose={goBack}
                onExpand={onExpand}
                onOpenCoverInputFile={onOpenCoverInputFile}
                onDeleteDoc={goBack}
                prevNextButtons={(
                  <PrevNextActions isNotInBoard={!isDocInBoard}>
                    <StyledActionButton
                      size="L"
                      onClick={() => {
                        setAutofocus('content');
                        navigateToPrevDoc();
                      }}
                      tooltip={!isDocInBoard ? <NotInBoardMessage /> : (
                        <Shortcut
                          keys={shortcuts[ShortcutDocPanel.PrevDoc]}
                          label="Previous doc"
                        />
                      )}
                      tooltipPlacement="bottom"
                      disabled={isFirstDoc}
                      ref={prevButtonRef || !isDocInBoard}
                    >
                      <UpIcon />
                    </StyledActionButton>
                    <StyledActionButton
                      size="L"
                      onClick={() => {
                        setAutofocus('content');
                        navigateToNextDoc();
                      }}
                      tooltip={!isDocInBoard ? <NotInBoardMessage /> : (
                        <Shortcut
                          keys={shortcuts[ShortcutDocPanel.NextDoc]}
                          label="Next doc"
                        />
                      )}
                      tooltipPlacement="bottom"
                      disabled={isLastDoc || !isDocInBoard}
                      ref={nextButtonRef}
                    >
                      {!isLastDoc && loadingNextDoc ? <Spinner /> : <DownIcon />}
                    </StyledActionButton>
                  </PrevNextActions>
                )}
                {...isFeedback(doc.doctype) && doc.status && {
                  processButton: (
                    <DocProcessButton
                      docId={doc.id}
                      docStatusCategory={doc.status.category}
                      onCompleted={navigateToNextDoc}
                      parent="doc-panel"
                    />
                  ),
                }}
              />

              <DocPanelContent
                doc={doc}
                containerRef={containerRef}
                getDropzonePropsCover={getDropzonePropsCover}
                isDraggingCover={isDraggingCover}
                isUploadingCover={isUploadingCover}
                widthSidebar={widthSidebar}
                onTitleUpdated={onTitleUpdated}
                onOpenCoverInputFile={onOpenCoverInputFile}
                onEscapeEdition={onEscapeEdition}
                focusOnMount={autofocus === 'content'}
              />

              {!isDocInBoard && (
                <ToasterContainer>
                  <Toaster
                    icon={<InfoIconOutline />}
                    title={(
                      <>
                        {`${doc._docKey} is no longer in `}
                        {board?.emoji && <StyledEmoji inline emoji={board.emoji} size={12} />}
                        {` ${board?.name}`}
                      </>
                    )}
                  />
                </ToasterContainer>
              )}
            </MainSection>

            {isRightPanelEnabled && (
              <DocPanelRightPanel doc={doc} />
            )}
          </>
        ) : <ErrorPage size={Size.SMALL} message="Oops, this doc no longer exists" />)}
      </Container>
    </Portal>
  );
};

export default DocPanelBoardPage;

const NotInBoardMessage = () => {
  const board = useBoard();
  return (
    <span>
      {'The doc is no longer in '}
      {board?.emoji && <StyledEmoji inline emoji={board.emoji} size={12} />}
      {` ${board?.name}`}
    </span>
  );
};
