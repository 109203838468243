export enum PageId {
  Auth = 'auth',
  Login = 'login',
  OAuth = 'oauth',
  ResetPwd = 'reset-password',
  Welcome = 'welcome',
  Callback = 'callback',

  Main = 'main',
  HomeDoc = 'home-doc',

  Board = 'board',

  Inbox = 'inbox',
  InboxView = 'inbox-view',
  InboxDoc = 'inbox-doc',

  Doc = 'doc',
  DocFullPage = 'doc-fullpage',

  Settings = 'settings',
  SettingsUsers = 'settings-users',
  SettingsDocTypes = 'settings-doc-types',
  SettingsAttributes = 'settings-attributes',
  SettingsHierarchy = 'settings-hierarchy',
  SettingsIntegrations = 'settings-integrations',
  SettingsCustomers = 'settings-customers',
  SettingsCustomer = 'settings-customer',
  SettingsCompany = 'settings-company',
  SettingsCompanies = 'settings-companies',
  SettingsBilling = 'settings-billing',
  SettingsWorkflows = 'settings-workflows',

  NewDoc = 'new-doc',

  BillingUpgrade = 'upgrade',
  BillingPlans = 'plans',
  BillingDowngrade = 'downgrade',
  GetStarted = 'get-started',
}

export const routing: Record<PageId, string> = {
  [PageId.Auth]: '/',
  [PageId.ResetPwd]: '/password_reset/:token?',
  [PageId.Login]: '/login',
  [PageId.Welcome]: '/welcome',
  [PageId.OAuth]: '/oauth/authorize',

  [PageId.Callback]: '/callback/:provider',

  [PageId.Main]: '/app/:productSlug?',
  [PageId.HomeDoc]: '/app/:productSlug/home/doc/:docSlug',

  [PageId.Inbox]: '/app/:productSlug/inbox',
  [PageId.InboxView]: '/app/:productSlug/inbox/:boardSlug',
  [PageId.InboxDoc]: '/app/:productSlug/inbox/:boardSlug/doc/:docSlug',

  [PageId.Board]: '/app/:productSlug/board/:boardSlug',
  [PageId.Doc]: '/app/:productSlug/board/:boardSlug/doc/:docSlug',
  [PageId.DocFullPage]: '/app/:productSlug/doc/:docSlug',

  [PageId.Settings]: '/app/:productSlug/settings',
  [PageId.SettingsUsers]: '/app/:productSlug/settings/members',
  [PageId.SettingsDocTypes]: '/app/:productSlug/settings/doc-types/:doctypeId?',
  [PageId.SettingsAttributes]: '/app/:productSlug/settings/properties',
  [PageId.SettingsHierarchy]: '/app/:productSlug/settings/hierarchy',
  [PageId.SettingsIntegrations]: '/app/:productSlug/settings/integrations',
  [PageId.SettingsCustomers]: '/app/:productSlug/settings/customers',
  [PageId.SettingsCustomer]: '/app/:productSlug/settings/customers/:customerId',
  [PageId.SettingsCompany]: '/app/:productSlug/settings/companies/:companyId',
  [PageId.SettingsCompanies]: '/app/:productSlug/settings/companies',
  [PageId.SettingsBilling]: '/app/:productSlug/settings/billing',
  [PageId.SettingsWorkflows]: '/app/:productSlug/settings/workflows',

  [PageId.BillingUpgrade]: '/app/:productSlug/upgrade',
  [PageId.BillingPlans]: '/app/:productSlug/settings/plans',
  [PageId.BillingDowngrade]: '/app/:productSlug/downgrade',

  [PageId.NewDoc]: '/new',
  [PageId.GetStarted]: '/get-started',
};

export const ALL_DOCS_BOARD_SLUG = 'all-docs';
