import { CaretIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { GithubProjectForm } from 'src/components/GithubProjectForm/GithubProjectForm';
import { useGithubProject } from 'src/hooks/api/queries/integrations/useGithubProject';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';

import { AddProjectButton, LoadingState } from './GithubProjectDropdown.styles';

export const GithubProjectDropdown: FC = () => {
  const [isModalOpen, { toggleCallback: toggleModal }] = useOptimizedBooleanState(false);
  const {
    project, isLoading,
  } = useGithubProject();

  return (
    <DropdownLayer
      layer={Layer.Dropdown}
      visible={isModalOpen}
      placement="bottom-start"
      hide={toggleModal}
      content={<GithubProjectForm isEditing={!!project} onSubmit={toggleModal} />}
    >
      <AddProjectButton $isMuted={!project} onClick={toggleModal}>
        {isLoading ? <LoadingState /> : (
          <>
            <span>{project ? project.title : 'Add project'}</span>
            <CaretIcon />
          </>
        )}
      </AddProjectButton>
    </DropdownLayer>
  );
};
