import { typo, ActionButton, InsightCard } from '@cycle-app/ui';
import styled from 'styled-components';

export const AddButton = styled(ActionButton)`
  display: flex;
`;

export const Doctype = styled.div``;

export const DoctypeInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};

  ${AddButton} {
    opacity: 0;
  }

  &:hover {
    ${AddButton} {
      opacity: 1;
    }
  }
`;

export const DoctypeLabel = styled.div`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
`;

export const DoctypeCounter = styled.div`
  ${typo.caption500};
  width: 18px;
  height: 18px;
  border-radius: 4px;
  color: ${p => p.theme.colors.text.secondary};
  background-color: ${p => p.theme.colors.background.tertiary};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Items = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledInsightCard = styled(InsightCard)`
  border-radius: 8px;
  background-color: ${p => p.theme.colors.docPanel.hierarchy.bgModal};

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:hover {
    background-color: ${p => p.theme.colors.docPanel.hierarchy.bgHover};
  }
`;
