import {
  TextButtonCss, TextButton, typo, Button, ShyScrollbar, Tooltip, TextButtonProps, ToggleInput, Tag,
} from '@cycle-app/ui';
import { InfoIconOutline, CaretIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const StyledInfoIconOutline = styled(InfoIconOutline)`
  height: 12px;
  width: 12px;
`;

export const StyledCaretIcon = styled(CaretIcon)<{ $isUp?: boolean }>`
  ${p => p.$isUp && css`transform: rotate(180deg);`}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

const StyledTextButtonCss = css`
  ${TextButtonCss}
  ${typo.body400}
  --bgHover: ${p => p.theme.colors.background.tertiary};
  --colorHover: ${p => p.theme.colors.text.secondary};
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
  width: auto;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const ProBadge = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.billing.plan.standard.bg};
  border-radius: 30px;
  color: ${p => p.theme.colors.text.white};
  display: flex;
  height: 24px;
  padding: 0 9px;
  position: absolute;
  right: -38%;
`;

export const StyledTextButton = styled(TextButton)`
  ${StyledTextButtonCss}
`;

export const ProductRole = styled.span`
  text-transform: lowercase;
  :first-letter {
    text-transform: uppercase;
  }
`;

export const UpdateInfo = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin: 20px;
  text-align: left;
`;

export const PricingBox = styled.div`
  border-radius: 12px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: ${p => p.theme.colors.background.primary};
  margin-top: 40px;
  position: relative;
  z-index: 2;
`;

export const Pricing = styled.div`
  color: ${p => p.theme.colors.text.disabled};
  margin-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

export const PricingHeader = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const PriceTooltip = styled(Tooltip)`
  text-align: center;
`;

export const PriceTag = styled.span`
  ${typo.headerLarge}
  color: ${p => p.theme.colors.text.primary};
  font-size: 32px;
`;

export const PriceInfo = styled.div`
  [data-popover] {
    display: flex;
    align-items: center;
  }
`;

export const PriceSymbol = styled.sup`
  ${typo.headerLarge}
  color: ${p => p.theme.colors.text.disabled};
  font-weight: 400;
  margin-right: 2px;
  vertical-align: text-top;
`;

export const PricePeriod = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 3px;
`;

export const PricingFrequency = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 18px;
  margin-top: 40px;
  position: relative;
  z-index: 2;
`;

interface PricingFrequencyLabelProps {
  $isActive?: boolean;
}

export const PricingFrequencyLabel = styled.span<PricingFrequencyLabelProps>`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  position: relative;
  flex: 1;

  &:first-child {
    text-align: right;
  }

  &:last-child {
    text-align: left;
  }

  ${p => p.$isActive && css`
    ${typo.body500}
    color: ${p.theme.colors.text.primary};
  `}
`;

export const PricingFrequencyLabelContent = styled.span`
  position: relative;
`;

export const PricingDiscount = styled(Tag)`
  ${typo.body500}
  border: 1px solid ${p => p.theme.colors.billing.discount.annual.border};
  background-color: ${p => p.theme.colors.billing.discount.annual.bg};
  color: ${p => p.theme.colors.billing.discount.annual.color};
  position: absolute;
  top: 50%;
  left: calc(100% + 6px);
  transform: translate(0, -50%);
`;

interface UpgradeButtonProps { $isCurrent?: boolean }

export const UpgradeButton = styled(Button)<UpgradeButtonProps>`
  margin-top: 20px;
  width: 100%;

  &:not([disabled]) {
    --bg: ${p => p.theme.colors.background.blue};

    &:hover, &:focus {
      --bg: ${p => p.theme.colors.background.blueDarker};
    }
  }

  &[disabled] {
    --color: ${p => p.theme.colors.text.secondary};
    --bg: ${p => p.theme.colors.background.disabled};
    font-weight: 500;
  }
`;

interface UsersToggleButtonProps extends TextButtonProps {
  $isUp?: boolean;
}

export const UsersToggleButton = styled(TextButton)<UsersToggleButtonProps>`
  ${StyledTextButtonCss}
  margin: 0 auto 20px;
  ${p => p.$isUp && css`margin-bottom: 4px;`}
`;

export const UsersList = styled(ShyScrollbar)`
  max-height: 390px;
`;

export const UserItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  padding: 12px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  }
`;

export const UserName = styled.div<{ $isDisabled?: boolean }>`
  align-items: center;
  display: flex;
  gap: 6px;
  text-align: left;
  overflow: hidden;
  ${p => p.$isDisabled && css`color: ${p.theme.colors.text.disabled};`}
`;

export const UserNameLabel = styled.div`
  text-overflow: ellipsis;
  verflow: hidden;
  white-space: nowrap;
`;

export const RoleButtonLabel = styled.span`
  ${typo.body400}
  display: block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledFrequencyToggle = styled(ToggleInput)`
  --bg: ${p => p.theme.colors.background.blue};
  --bgChecked: ${p => p.theme.colors.background.blue};
  --bgHover: ${p => p.theme.colors.background.blueDarker};
  display: flex;
`;
