import styled, { css } from 'styled-components';

import { boxShadowZ1, boxShadowZ2, boxShadowZ3 } from '../../../utils/styles.util';

export type Z = 0 | 1 | 2;

interface ContainerProps {
  $disableHover: boolean;
  $z: Z | undefined;
}

export const Container = styled.div<ContainerProps>`
  color: ${p => p.theme.colors.body.color};
  padding: 16px;
  background-color: ${p => p.theme.colors.card.bg};
  border: 1px solid transparent;
  border-radius: 6px;
  ${p => (p.$z === undefined || p.$z === 0) && boxShadowZ1};
  ${p => p.$z === 1 && boxShadowZ2};
  ${p => p.$z === 2 && boxShadowZ3};

  transition: box-shadow 100ms linear;

  ${p => !p.$disableHover && p.$z === undefined && css`
    &:hover {
      background-color: ${p.theme.colors.card.bgHover};
    }
  `};
`;
