import { makeVar, ReactiveVar } from '@apollo/client';
import { Setter } from '@cycle-app/utilities';
// TODO: upgrade @types/react
// @ts-ignore
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import {
  getLocalState,
  resetLocalState,
  setLocalState,
  hookGetLocalState,
  hookLocalState,
} from 'src/hooks/state/useLocalState';
import { ParamsState, StateHookResult } from 'src/types/state.types';

/**
 * @see https://reactjs.org/docs/hooks-reference.html#usesyncexternalstore
 * @see https://github.com/apollographql/apollo-client/blob/main/src/cache/inmemory/reactiveVars.ts
 */
export const useReactiveWithSelector = <T, S>(
  reactive: ReactiveVar<T>,
  selector: (state: T) => S,
  isEqual?: (a: S, b: S) => boolean,
): S => useSyncExternalStoreWithSelector(
    (onChange: VoidFunction) => {
      let unsubscribe: VoidFunction;
      const listener = () => {
        // Notify parent listener
        onChange();
        // Resubscribe to the next variable value change.
        unsubscribe = reactive.onNextChange(listener);
      };
      unsubscribe = reactive.onNextChange(listener);
      return unsubscribe;
    },
    reactive,
    reactive,
    selector,
    isEqual,
  );

type MakeResult<T> = {
  hookState: () => StateHookResult<T>;
  hookValue: () => T;
  hookWithSelector: <S>(
    selector: (state: T) => S,
    isEqual?: (a: S, b: S) => boolean,
  ) => S;
  getValue: () => T;
  setValue: Setter<Partial<T>>;
  resetValue: VoidFunction;
};

const parseContent = (content: string) => {
  try {
    return JSON.parse(content);
  } catch (e) {
    return {};
  }
};

export const make = <T>({
  defaultState, localKey, mergeTarget,
}: ParamsState<T>): MakeResult<T> => {
  const fromLocalStorage = localKey ? localStorage.getItem(localKey) : undefined;

  const reactive = makeVar<T>({
    ...defaultState,
    ...(fromLocalStorage ? parseContent(fromLocalStorage) : {}),
  });

  return {
    hookState: hookLocalState({
      reactive,
      localKey,
      defaultState,
    }),
    hookValue: hookGetLocalState({ reactive }),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    hookWithSelector: (selector, isEqual) => useReactiveWithSelector(reactive, selector, isEqual),
    getValue: getLocalState({ reactive }),
    setValue: setLocalState({
      reactive,
      localKey,
      mergeTarget,
      defaultState,
    }),
    resetValue: resetLocalState({
      defaultState,
      reactive,
      localKey,
    }),
  };
};
