import { JobTitle, UpdateUserOnboardingStep2Document } from '@cycle-app/graphql-codegen';
import { Input, RadioInput, OnboardingLayout, LoginIllustration } from '@cycle-app/ui';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Events, Objects } from 'src/constants/analytics.constants';
import { ErrorMessage } from 'src/constants/errors.constants';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'src/constants/policies.constants';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { useProductFromInitial } from 'src/hooks/api/useProductFromInitial';
import { useErrorToaster } from 'src/hooks/useErrorToaster';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setOnboarding } from 'src/reactives/onboarding.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';
import { jobsLabel } from 'src/utils/jobs.util';

import { FormContainer, SubtitleLoadingState } from '../OnboardingView.styles';
import {
  Label, JobsInput, RadioContainer, Anchor, StyledCheckboxInput, CheckboxContainer,
} from './StepConfigureAccount.styles';

type FormData = {
  first: string;
  last: string;
  job: JobTitle;
  terms: boolean;
};

type Props = {
  progress: number;
  isReadOnly?: boolean;
};

export const StepConfigureAccount: FC<Props> = ({
  progress, isReadOnly,
}) => {
  const me = useMaybeMe();
  const { add: addErrorToaster } = useErrorToaster();
  const { product } = useProductFromInitial();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState,
  } = useForm<FormData>({
    defaultValues: {
      first: me?.firstName || '',
      last: me?.lastName || '',
      job: me?.jobTitle || undefined,
      terms: false,
    },
  });

  const [updateUserOnboardingStep2, { loading }] = useSafeMutation(UpdateUserOnboardingStep2Document, {
    onCompleted: () => trackAnalytics(Events.UserAccountUpdated, {
      object: Objects.UserName,
    }),
  });

  const {
    first, last, job, terms,
  } = watch();
  const canSubmit = !Object.keys(formState.errors).length && !!first && !!last && !!job && terms;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OnboardingLayout
        title={<>Let&apos;s configure your account</>}
        description={!product ? <SubtitleLoadingState /> : `to join ${product?.name} workspace`}
        main={(
          <FormContainer>
            <Input
              id="firstName"
              type="text"
              label="First name"
              required
              {...register('first', { required: true })}
            />
            <Input
              id="lastName"
              type="text"
              label="Last name"
              required
              {...register('last', { required: true })}
            />
            <RadioContainer>
              <Label>Job</Label>
              <JobsInput>
                {Object.values(JobTitle).map(jobTitle => (
                  <RadioInput
                    key={jobTitle}
                    id={jobTitle}
                    label={jobsLabel[jobTitle]}
                    value={jobTitle}
                    checked={jobTitle === job}
                    onChange={() => setValue('job', jobTitle)}
                  />
                ))}
              </JobsInput>
            </RadioContainer>
            <CheckboxContainer>
              <StyledCheckboxInput
                id="terms"
                defaultChecked={false}
                hideLabel
                {...register('terms', { required: true })}
              />
              <div>
                I agree to the Cycle
                {' '}
                <Anchor
                  href={TERMS_AND_CONDITIONS}
                  target="_blank"
                >
                  Terms of Use
                </Anchor>
                ,
                {' '}
                <Anchor
                  href={PRIVACY_POLICY}
                  target="_blank"
                >
                  Privacy Policy and Data Processing Agreement.
                </Anchor>
              </div>
            </CheckboxContainer>
          </FormContainer>
        )}
        aside={<LoginIllustration plugged />}
        progress={progress}
        nextButtonProps={{
          type: 'submit',
          disabled: !canSubmit,
          isLoading: loading,
        }}
        isModal={isReadOnly}
      />
    </form>
  );

  async function onSubmit(values: FormData) {
    try {
      await updateUserOnboardingStep2({
        variables: {
          firstName: values.first,
          lastName: values.last,
          jobTitle: values.job,
        },
      });
      setOnboarding({ screen: OnboardingScreen.CreatePassword });
    } catch (error: any) {
      logError(new Error(error));
      addErrorToaster({ message: ErrorMessage._GENERIC });
    }
  }
};
