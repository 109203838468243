import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const UnionBoxIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.03039 0.259353C6.32526 0.0894426 6.65962 0 6.99997 0C7.34032 0 7.67468 0.0894426 7.96956 0.259353L7.971 0.260187L12.3946 2.78797L12.3973 2.78947L12.3973 2.78948C12.6922 2.95976 12.9372 3.20461 13.1076 3.49946C13.278 3.79431 13.3679 4.1288 13.3683 4.46936L13.3683 4.47005L13.3683 9.52863V9.52933C13.3679 9.86989 13.278 10.2044 13.1076 10.4992C12.9372 10.7941 12.6922 11.0389 12.3973 11.2092L12.3946 11.2107L7.971 13.7385L7.9694 13.7394C7.68984 13.9005 7.37481 13.9892 7.05284 13.998C7.0354 13.9993 7.01777 14 6.99998 14C6.9822 14 6.96458 13.9993 6.94715 13.998C6.62516 13.9892 6.31011 13.9005 6.03055 13.7394L6.02894 13.7385L1.6053 11.2107L1.60268 11.2092C1.30775 11.0389 1.06277 10.7941 0.892338 10.4992C0.721904 10.2044 0.632002 9.86989 0.631653 9.52933V9.52863V4.47005V4.46936C0.632002 4.1288 0.721903 3.79431 0.892338 3.49946C1.06277 3.20461 1.30775 2.95976 1.60268 2.78948L1.6053 2.78797L1.6053 2.78797L6.03039 0.259353ZM7.6774 12.3458L11.7198 10.0359L11.7209 10.0353C11.8096 9.9838 11.8833 9.91 11.9346 9.82121C11.9861 9.73222 12.0132 9.63129 12.0135 9.52851L12.0135 9.52794V4.90247L7.6774 7.39159V12.3458ZM6.32257 7.39157V12.3459L2.2801 10.0359L2.27904 10.0353C2.19033 9.98381 2.11664 9.91001 2.06531 9.82121C2.01383 9.73214 1.98665 9.63111 1.98649 9.52823V4.90243L6.32257 7.39157ZM6.99997 1.35484C6.89689 1.35484 6.79563 1.38197 6.70636 1.43351L6.70375 1.43502L6.70374 1.43501L2.67646 3.73631L7.00001 6.21825L11.3235 3.73633L7.2962 1.43501L7.29358 1.43352L7.29358 1.43351C7.20431 1.38197 7.10305 1.35484 6.99997 1.35484Z" fill="white" />
  </Svg>
);
