const DOC_FULL_PAGE_URL_PATTERN = /\/app\/[\S]*\/doc\/[\S]*-[\S]*/g;

export function getDocFullUrl(docUrl: string): string {
  return `${window.location.origin}${docUrl}`;
}

export function getDocIdFromUrl(url: string): string | undefined {
  const isDocLink =
    url.startsWith(window.location.origin) &&
    !!url.match(DOC_FULL_PAGE_URL_PATTERN);

  if (!isDocLink) return undefined;

  const splittedLink = url.split('/') ?? [];
  const splittedDocSlug = splittedLink[splittedLink.length - 1].split('-');
  const docId: string | undefined = splittedDocSlug[splittedDocSlug.length - 1];

  return docId;
}
