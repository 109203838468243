import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const OpenIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.91667 2.39583C2.91667 2.10819 3.14985 1.875 3.4375 1.875H7.60417C7.89182 1.875 8.125 2.10819 8.125 2.39583V6.5625C8.125 6.85016 7.89182 7.08333 7.60417 7.08333C7.31651 7.08333 7.08333 6.85016 7.08333 6.5625V3.65323L2.76412 7.97245C2.56072 8.17583 2.23095 8.17583 2.02755 7.97245C1.82415 7.76906 1.82415 7.43927 2.02755 7.23589L6.34677 2.91667H3.4375C3.14985 2.91667 2.91667 2.68348 2.91667 2.39583Z" fill="#707070" />
  </Svg>
);
