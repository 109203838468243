import { ActionButton } from '@cycle-app/ui';
import { InfoIconOutline, DuplicateIcon } from '@cycle-app/ui/icons';

import { useProductBase } from 'src/hooks';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { useUrl, PageId } from 'src/hooks/useUrl';

import {
  Panel, ShareButton, Dropdown, Title, Description, Info, Field,
} from './ShareBoard.style';

export type ShareBoardProps = {
  boardSlug: string;
};

export const ShareBoard = ({ boardSlug }: ShareBoardProps) => {
  const product = useProductBase();
  const getUrl = useUrl();

  const path = getUrl(PageId.Board, { boardSlug });
  const viewUrl = `${window.location.host}${path}`;

  const copyToClipboard = useCopyToClipboard({
    successNotification: 'View URL copied to clipboard!',
  });

  return (
    <Dropdown
      offsetY={8}
      button={props => (
        <ShareButton {...props}>
          Share
        </ShareButton>
      )}
      content={props => (
        <Panel>
          <Title>
            Share view
          </Title>

          <Description>
            Invite new team member to start collaborating
          </Description>

          <Field>
            <input
              disabled
              value={viewUrl}
            />
            <ActionButton
              onClick={() => {
                copyToClipboard(viewUrl);
                props.hide();
              }}
            >
              <DuplicateIcon size={16} />
            </ActionButton>
          </Field>

          {product && (
            <Info>
              <InfoIconOutline size={14} />
              {`Everyone at ${product.name} can access this view`}
            </Info>
          )}
        </Panel>
      )}
    />
  );
};
