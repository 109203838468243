import {
  DocumentNode,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { useCallback } from 'react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { ErrorType } from 'src/types/errors.types';
import { logError, getErrorInfo } from 'src/utils/errors.utils';

import { useErrorToaster } from './useErrorToaster';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useSafeMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  const [mutationFn, mutationResult] = useMutation(mutation, options);
  const { add: addErrorToaster } = useErrorToaster();

  const safeMutation = useCallback(async (args?: MutationFunctionOptions<TData, TVariables> | undefined) => {
    try {
      return await mutationFn(args);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const {
        message,
        isError,
        type,
      } = getErrorInfo(error);

      if (!isError) return { data: null };

      logError(error);
      if (type === ErrorType.AUTHORIZATION) {
        addErrorToaster({ message });
      } else {
        addErrorToaster({
          message: ErrorMessage.SERVER_UNAVAILABLE,
        });
      }

      return { data: null };
    }
  }, [addErrorToaster, mutationFn]);

  return [safeMutation, mutationResult];
}
