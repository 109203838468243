import { Button, Stepper, ShyScrollbar, typo } from '@cycle-app/ui';
import { mobile, tablet } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

const offsetLeft = '60%';
const offsetTop = '100px';

export const Container = styled(ShyScrollbar)`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  position: relative;
`;

export const Page = styled.div<{ withAppPreview?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${p => p.withAppPreview && css`
    width: calc(100% - 35vw);
  `};
`;

export const StepperStyled = styled(Stepper)`
  margin-bottom: 24px;
`;

export const Content = styled.div`
  ${tablet} {
    margin-right: 0;
  }

  ${mobile} {
    padding: 32px 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 556px;
  height: 100%;
  margin: 0 auto;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const AppBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  transition: filter .3s;
  pointer-events: none;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    z-index: ${mappingZindex[Layer.Sidebar] + 2};
    backdrop-filter: blur(var(--blur));
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const Overlay = styled(motion.div)`
  background: ${p => p.theme.colors.onboarding.background.backdrop};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${mappingZindex[Layer.Sidebar] + 1};
`;

export const AppPreview = styled(motion.div) <{ $full?: boolean; $isLoading: boolean }>`
  display: ${p => (p.$isLoading ? 'none' : 'block')};

  position: fixed;
  inset: 0;

  pointer-events: none;

  border: 1px solid ${p => p.theme.colors.appPreview.border};
  border-radius: 10px;

  overflow: hidden;

  ${p => p.$full && css`
    pointer-events: initial;
    border: 0;
    border-radius: 0;
  `};
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  ${typo.headerLarge};
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  margin-bottom: 24px;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`;

export const StyledImageInput = styled(ImageInput)`
  margin-right: auto;
`;

export const Label = styled.div`
  ${typo.body500};
  margin-bottom: 4px;
`;

export const Submit = styled(Button)`
  margin-top: 24px;
  margin-bottom: 15px;
  align-self: flex-end;
`;

export const Intro = styled.div`
  ${typo.body400};
`;

export const Team = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TeamTitle = styled.div`
  ${typo.headerMedium};
`;

export const TeamMember = styled.div`
  ${typo.body400};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Buttons = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const Jobs = styled.div``;

export const JobsInput = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  padding-right: 120px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const AvatarInput = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 40px;
  > button {
    ${mobile} {
      display: none;
    }
  }
`;

export const previewVariant = {
  hidden: {
    x: '110%',
    y: '110%',
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
      delay: 0,
    },
  },
  glimpse: {
    x: offsetLeft,
    y: offsetTop,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
      delay: 0,
    },
  },
  full: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
      delay: 0,
    },
  },
};
