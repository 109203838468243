import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const UnionIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6722 7.45003C13.8181 7.30417 14.0159 7.22222 14.2222 7.22222C14.4285 7.22222 14.6263 7.30417 14.7722 7.45003C14.9181 7.59589 15 7.79372 15 8V12.6667C15 13.2855 14.7542 13.879 14.3166 14.3166C13.879 14.7542 13.2855 15 12.6667 15H3.33333C2.71449 15 2.121 14.7542 1.68342 14.3166C1.24583 13.879 1 13.2855 1 12.6667V3.33333C1 2.71449 1.24583 2.121 1.68342 1.68342C2.121 1.24583 2.71449 1 3.33333 1H8C8.20628 1 8.40411 1.08194 8.54997 1.22781C8.69583 1.37367 8.77778 1.5715 8.77778 1.77778C8.77778 1.98406 8.69583 2.18189 8.54997 2.32775C8.40411 2.47361 8.20628 2.55556 8 2.55556H3.33333C3.12705 2.55556 2.92922 2.6375 2.78336 2.78336C2.6375 2.92922 2.55556 3.12705 2.55556 3.33333V12.6667C2.55556 12.8729 2.6375 13.0708 2.78336 13.2166C2.92922 13.3625 3.12705 13.4444 3.33333 13.4444H12.6667C12.8729 13.4444 13.0708 13.3625 13.2166 13.2166C13.3625 13.0708 13.4444 12.8729 13.4444 12.6667V8C13.4444 7.79372 13.5264 7.59589 13.6722 7.45003Z" fill="#171618" />
    <path d="M12.3401 2.55556H11.1112C10.9049 2.55556 10.7071 2.47361 10.5612 2.32775C10.4153 2.18189 10.3334 1.98406 10.3334 1.77778C10.3334 1.5715 10.4153 1.37367 10.5612 1.22781C10.7071 1.08194 10.9049 1 11.1112 1H14.2223C14.4286 1 14.6264 1.08194 14.7723 1.22781C14.9181 1.37367 15.0001 1.5715 15.0001 1.77778V4.88889C15.0001 5.09517 14.9181 5.293 14.7723 5.43886C14.6264 5.58472 14.4286 5.66667 14.2223 5.66667C14.016 5.66667 13.8182 5.58472 13.6723 5.43886C13.5264 5.293 13.4445 5.09517 13.4445 4.88889V3.66L8.55228 8.54444C8.47998 8.61734 8.39396 8.67521 8.29918 8.71469C8.2044 8.75418 8.10274 8.77451 8.00006 8.77451C7.89739 8.77451 7.79573 8.75418 7.70095 8.71469C7.60617 8.67521 7.52014 8.61734 7.44784 8.54444C7.37494 8.47214 7.31708 8.38612 7.27759 8.29134C7.2381 8.19656 7.21777 8.0949 7.21777 7.99222C7.21777 7.88955 7.2381 7.78789 7.27759 7.69311C7.31708 7.59833 7.37494 7.5123 7.44784 7.44L12.3401 2.55556Z" fill="#171618" />
  </Svg>
);
