import { Tooltip, Avatar } from '@cycle-app/ui';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC } from 'react';

import useEditorContributors from 'src/hooks/editor/useEditorContributors';

import {
  MentionNodeView,
  TooltipContent,
} from './MentionView.styles';

const MentionView: FC<NodeViewRendererProps> = ({ node }) => {
  const contributors = useEditorContributors();
  const mate = contributors.find(p => p.id === node.attrs.id);

  if (!mate) {
    return null;
  }

  return (
    <MentionNodeView $color={mate.color}>
      <Tooltip
        placement="top"
        withPortal
        content={(
          <TooltipContent>
            <Avatar
              src={mate.avatar?.url}
              oppositeColor
            />
            <div>
              {`${mate.firstName} ${mate.lastName?.[0]}.${mate.__typename === 'Me' ? ' (me)' : ''}`}
            </div>
          </TooltipContent>
        )}
      >
        {`@${mate.firstName}`}
      </Tooltip>
    </MentionNodeView>
  );
};

export default MentionView;
