import { forwardRef, ComponentPropsWithRef, TextareaHTMLAttributes } from 'react';

import { StyledTextArea } from './TextArea.styles';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, ComponentPropsWithRef<'textarea'> {
  className?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => (
  <StyledTextArea
    ref={ref}
    {...props}
  />
));

export default TextArea;
