import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { toInputDate, useInputError } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import { ReactNode, ChangeEvent, FC, useState } from 'react';

import { Input } from '../Inputs';
import { Warning } from '../Warning/Warning';
import { errorMotionProps } from './CustomProperty.motion';
import { EndInput, ErrorContainer, ErrorMessage, InputText, PropertyTag } from './CustomProperty.styles';

export type TextType = 'phone' | 'url' | 'text' | 'email' | 'date' | 'number';

type PropsVariant = {
  variant?: 'dropdown';
  label?: ReactNode;
} | {
  variant?: 'panel';
  label?: never;
};

type Props = {
  values?: string[];
  color?: ColorApi;
  type: TextType;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  warning?: string;
  hasHelper?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
} & PropsVariant;

export const CustomPropertyInputText: FC<Props> = ({
  variant = 'panel',
  color,
  label,
  type,
  values = [],
  onInputChange,
  warning,
  hasHelper = true,
  placeholder,
  autoFocus = true,
}) => {
  const [inputValue, setInputValue] = useState(getInitialValue());
  const [inputError, checkInput] = useInputError({
    type,
    value: inputValue,
  });
  const [isFocused, setIsFocused] = useState(false);

  if (variant === 'dropdown') {
    return (
      <Input
        label={label}
        type={type}
        value={inputValue}
        onChange={onTextChange}
        error={inputError ?? undefined}
        autoFocus={autoFocus}
        step="any"
        helper={hasHelper ? ' ' : undefined}
        endInput={warning && <Warning tooltip={warning} />}
        placeholder={placeholder}
      />
    );
  }

  if (!isFocused) {
    return (
      <PropertyTag
        color={values.length ? color : 'grey'}
        onClick={() => setIsFocused(true)}
        tooltip={values.length ? {
          placement: 'bottom',
          content: inputValue,
        } : undefined}
        externalLink={type === 'url' && !!values.length ? inputValue : undefined}
      >
        {values.length ? inputValue : 'No value'}
      </PropertyTag>
    );
  }

  return (
    <>
      <AnimatePresence>
        {inputError && isFocused && (
          <ErrorContainer {...errorMotionProps}>
            <ErrorMessage>
              {inputError}
            </ErrorMessage>
          </ErrorContainer>
        )}
      </AnimatePresence>
      <InputText
        type={['phone', 'url'].includes(type) ? 'text' : type}
        placeholder="No value"
        step="any"
        autoFocus
        onChange={onTextChange}
        value={inputValue}
        inError={!!inputError}
        onBlur={() => setIsFocused(false)}
        withWarning={!!warning}
        typeUrl={type === 'url'}
      />
      {(!!warning || type === 'url') && (
        <EndInput>
          {warning && <Warning tooltip={warning} />}
        </EndInput>
      )}
    </>
  );

  function onTextChange(e: ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
    if (e.target.validity.valid && checkInput(e.target.value)) {
      onInputChange(e);
    }
  }

  function getInitialValue() {
    const initialValue = values.length ? values[0] : '';
    if (type === 'date') {
      return toInputDate(initialValue);
    }
    return initialValue;
  }
};
