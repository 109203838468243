import { SortingStrategy, useSortable } from '@dnd-kit/sortable';
import { FC, memo, ReactNode } from 'react';

import { getSortableStyle } from 'src/utils/dnd.util';

interface Props {
  id: string;
  children: ReactNode;
  disabled?: boolean;
  strategy?: SortingStrategy;
  metaData?: Record<string, unknown>;
}

const Sortable: FC<Props> = ({
  children,
  id,
  disabled,
  strategy,
  metaData,
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
  } = useSortable({
    id,
    disabled,
    strategy,
    data: {
      type: 'item',
      ...metaData,
    },
  });

  const isVoid = id.includes('-void');
  const style = !isVoid
    ? getSortableStyle({
      transform,
      transition,
    }) : undefined;

  return (
    <li
      ref={setNodeRef}
      className={isVoid ? 'void' : undefined}
      style={style}
      {...attributes}
      {...listeners}
    >
      {!isVoid && children}
    </li>
  );
};

export default memo(Sortable);
