import { Input } from '@cycle-app/ui';
import { Range } from '@tiptap/react';
import { FC, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import MiniForm from 'src/components/Editor/MiniForm/MiniForm';
import { Events } from 'src/constants/analytics.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { addEmbed, isEmbedSupported } from 'src/utils/editor/iframely.util';

import { Caption } from './EmbedForm.styles';

interface Props {
  range?: Range;
  onCancel?: VoidFunction;
}

const EmbedForm: FC<Props> = ({
  range,
  onCancel,
}) => {
  const {
    editor,
    onError,
  } = useEditorContext();
  const [url, setUrl] = useState('');

  const {
    execute: addEmbedASync, loading,
  } = useAsyncCallback(addEmbed);

  const hasFormError = url ? !isEmbedSupported(url) : false;

  return (
    <MiniForm
      title="Embed"
      isLoading={loading}
      onCancel={onCancel}
      onSubmit={submit}
      submitLabel="Embed"
      isSubmitDisabled={!url || hasFormError}
    >
      <Caption>You can embed any public link you want</Caption>
      <Input
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="URL to embed"
        autoFocus
        onKeyDown={(e) => e.code === 'Enter' && submit()}
        error={hasFormError ? '🧐 Hmm, it doesn’t look a link we can process' : undefined}
      />
    </MiniForm>
  );

  async function submit() {
    await addEmbedASync({
      url,
      editor,
      onError,
      range,
    });
    trackAnalytics(Events.LinkEmbeded);
  }
};

export default EmbedForm;
