import { Input } from '@cycle-app/ui';
import { FileType } from '@cycle-app/utilities';
import { Range } from '@tiptap/react';
import { FC, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { DropArea } from 'src/components/Editor/ContainerDropFile/ContainerDropFile.styles';
import DropPlaceholder from 'src/components/Editor/ContainerDropFile/Placeholder/DropPlaceholder';
import MiniForm from 'src/components/Editor/MiniForm/MiniForm';
import { useEditorContext } from 'src/contexts/editorContext';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetBillingPermission } from 'src/reactives/permission.reactive';
import { getFileError, getUrlError, isUploadRestricted } from 'src/utils/files.util';

import { DropContainer, Or, ErrorMessage } from './FileForm.styles';
import FilePreview from './FilePreview/FilePreview';

const AUTHORIZED_FILES: { [type in Exclude<FileType, 'video'>]: string[] | undefined } = {
  image: ['image/jpeg', 'image/png', 'image/gif'],
  // Controls are done server-side.
  file: undefined,
};

const IMAGE_LINK_TITLE = 'External image';

interface Props {
  onCancel?: VoidFunction;
  type: Exclude<FileType, 'video'>;
  submitLabel: string;
  title?: string;
  range?: Range;
}

const FileForm: FC<Props> = ({
  onCancel,
  type,
  submitLabel,
  title,
  range,
}) => {
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [url, setUrl] = useState('');
  const {
    editor,
    isUploading,
    onError,
    onUpload,
    setDragFileIsDisabled,
  } = useEditorContext();
  const { canUploadNotRestricted } = useGetBillingPermission();

  const {
    getInputProps,
    getRootProps,
    isDragActive,
  } = useDropzone({
    accept: AUTHORIZED_FILES[type],
    onDropAccepted: (files) => setCurrentFile(files[0]),
    onDropRejected: () => {
      onError?.('🧐 Oops, looks like something went wrong on our side, we’re on it!');
    },
  });

  useEffect(() => {
    setDragFileIsDisabled?.(true);

    return () => setDragFileIsDisabled?.(false);
  }, []);

  const currentFileError = currentFile ? getFileError(currentFile) : null;
  const urlError = url ? getUrlError(url) : null;
  const isFileOK = currentFile ? !currentFileError : false;
  const isUrlOK = url ? !urlError : false;
  const isSubmitDisabled = !isFileOK && !isUrlOK;

  return (
    <MiniForm
      onCancel={onCancel}
      onSubmit={submit}
      isLoading={isUploading}
      isSubmitDisabled={isSubmitDisabled}
      submitLabel={submitLabel}
      title={title}
    >
      <DropContainer>
        <DropArea
          {...getRootProps()}
          isLoading={isUploading}
          isActive={isDragActive}
          hasFullCoverage
          hideBorder={!!currentFile}
        >
          {currentFile ? (
            <FilePreview file={currentFile} />
          ) : (
            <DropPlaceholder hasClickOption>
              <input {...getInputProps()} />
            </DropPlaceholder>
          )}
        </DropArea>
      </DropContainer>

      {currentFileError && <ErrorMessage $hasError>{currentFileError.message}</ErrorMessage>}

      {!currentFile && type === 'image' && (
        <>
          <Or>
            Or add via url
          </Or>
          <Input
            value={url}
            onChange={({ target: { value } }) => setUrl(value)}
            placeholder="Image URL"
            autoFocus
            onKeyDown={(e) => e.code === 'Enter' && submit()}
            error={urlError?.message}
          />
        </>
      )}
    </MiniForm>
  );

  async function submit() {
    if (currentFile) {
      if (isUploadRestricted(currentFile, canUploadNotRestricted)) {
        onCancel?.();
        setLimitationsModal({ action: 'UPLOAD_NOT_RESTRICTED' });
        return;
      }
      const uploadedFile = await onUpload?.(currentFile, 'slash-menu');

      if (!uploadedFile) return;

      editor
        .chain()
        .focus()
        .deleteRange({
          from: range?.from ?? 0,
          to: range?.to ?? 0,
        })
        .setFile({ file: uploadedFile })
        .createParagraphNear()
        .focus()
        .run();
    }

    if (url) {
      editor
        .chain()
        .focus()
        .deleteRange({
          from: range?.from ?? 0,
          to: range?.to ?? 0,
        })
        .setImage({
          src: url,
          title: IMAGE_LINK_TITLE,
        })
        .createParagraphNear()
        .run();
    }
  }
};

export default FileForm;
