import { ButtonHTMLAttributes, forwardRef } from 'react';

import { Spinner } from '../../Spinner/Spinner.styles';
import { Tooltip, TooltipPlacement } from '../../Tooltip/Tooltip';
import { SizeTextButton, Container, TextButtonCss, TextButtonProps } from './TextButton.styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  tooltip?: string;
  tooltipTitle?: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipDisplayFullTitle?: boolean;
  tooltipWithPortal?: boolean;
  tooltipWithWrapper?: boolean;
  size?: SizeTextButton;
  forceFocus?: boolean;
  noPointerEvents?: boolean;
  isLoading?: boolean;
}
export const TextButton = forwardRef<HTMLButtonElement, Props>(({
  className,
  children,
  tooltip,
  tooltipTitle,
  tooltipPlacement,
  tooltipDisplayFullTitle,
  tooltipWithPortal,
  tooltipWithWrapper,
  size = 10,
  forceFocus,
  isLoading,
  noPointerEvents,
  ...buttonProps
}, ref) => {
  const button = (
    <Container
      {...buttonProps}
      noPointerEvents={noPointerEvents ?? isLoading}
      className={`${className} ${forceFocus ? 'force-focus' : ''}`}
      size={size}
      ref={ref}
    >
      {children}
      {isLoading && <Spinner />}
    </Container>
  );

  return tooltip
    ? (
      <Tooltip
        title={tooltipTitle}
        content={tooltip}
        placement={tooltipPlacement}
        displayFullTitle={tooltipDisplayFullTitle}
        withPortal={tooltipWithPortal}
        withWrapper={tooltipWithWrapper}
      >
        {button}
      </Tooltip>
    )
    : button;
});

export type { TextButtonProps };

export { TextButtonCss };
