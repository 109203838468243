import { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';

import NewPasswordForm from 'src/components/NewPasswordForm/NewPasswordForm';
import { PageId } from 'src/constants/routing.constant';
import { useResetPassword } from 'src/hooks/api/mutations/useResetPassword';
import { useNavigate } from 'src/hooks/useNavigate';
import useQueryParams from 'src/hooks/useQueryParams';

import {
  Container,
  FormTitle,
  ResetStatusContainer,
  Title,
  Text,
  BackButton,
} from './ResetPwd.styles';

type Content = 'form' | 'reset-success' | 'token-expired';

const ResetPwd: FC = () => {
  const { navigate } = useNavigate();
  const tokenFromUrl = useQueryParams().get('token');

  const {
    reset,
    loading,
  } = useResetPassword();

  const [content, setContent] = useState<Content>('form');

  if (!tokenFromUrl) {
    return <Redirect to={PageId.Login} />;
  }

  if (content === 'form') {
    return (
      <Container>
        <FormTitle>Change your password</FormTitle>
        <NewPasswordForm
          isLoading={loading}
          onSubmit={onSubmit}
          submitLabel="Change password"
        />
      </Container>
    );
  }

  return (
    <ResetStatusContainer>
      {content === 'reset-success' && (
        <>
          <Title>Password changed</Title>
          <Text>
            Your password has ben changed successfully.
            <br />
            Use your new password to log in.
          </Text>
        </>
      )}

      {content === 'token-expired' && (
        <>
          <Title>Link expired</Title>
          <Text>
            The link used to reset your password has expired
          </Text>
        </>
      )}

      <BackButton
        size="L"
        type="button"
        onClick={() => navigate(PageId.Login)}
      >
        Back to sign in
      </BackButton>
    </ResetStatusContainer>
  );

  async function onSubmit(password: string) {
    if (!tokenFromUrl) return;

    const result = await reset(password, tokenFromUrl);
    setContent(result?.data?.resetPassword
      ? 'reset-success'
      : 'token-expired');
  }
};

export default ResetPwd;
