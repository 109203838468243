import { DropdownCss, SelectLine, Spinner } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${DropdownCss};

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  width: 458px;
  max-width: 40vw;
`;

export const Suggestion = styled(SelectLine)`
  > :last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Loader = styled(Spinner)`
  margin: auto;
`;
