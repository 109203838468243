import { Avatar, AvatarProps } from '@cycle-app/ui';
import { FC } from 'react';

import { useMe } from 'src/hooks/api/useMe';

const MyAvatar: FC<AvatarProps> = (props) => {
  const { me } = useMe();

  return (
    <Avatar
      {...props}
      user={me}
      incognito={me.incognito}
    />
  );
};

export default MyAvatar;
