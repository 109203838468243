import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { useMemo, useState, FC } from 'react';

import { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import { useDocTypeInsight, useDocV2, useOptimizedBooleanState } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useDocSearchProductTour } from 'src/hooks/productTour/useDocSearchProductTour';
import { useUrl } from 'src/hooks/useUrl';
import { getDocTypeNames } from 'src/utils/docType.util';
import { getDocSlug } from 'src/utils/slug.util';

import DocSearchDropdown from '../../DocSearchDropdown/DocSearchDropdown';
import { InsightCreateModal } from '../../InsightCreateModal';
import { StyledDocParent, EditIconContainer, StyledPenFilledIcon, StyledLink } from './InsightDropdown.styles';

interface Props {
  blockId?: string;
  dropdownProps?: Partial<Omit<DropdownLayerProps, 'content'>>;
  defaultContent?: string;
  feedback: DocBaseFragment;
  docTarget?: {
    doc?: {
      id: string;
      parent?: {
        id: string;
        title: string;
        doctype: {
          id: string;
          emoji: string;
        };
      } | null;
    } | null;
  } | null;
  isInitialVisible?: boolean;
  isVisible?: boolean;
  onHide?: VoidFunction;
  onInsightCreating?: VoidFunction;
  onInsightCreated?: VoidFunction;
}

export const InsightDropdown: FC<Props> = ({
  blockId,
  dropdownProps,
  defaultContent,
  docTarget,
  feedback,
  isInitialVisible,
  isVisible,
  onHide,
  onInsightCreating,
  onInsightCreated,
}) => {
  const [isDocSearchVisible, {
    setTrueCallback: showDocSearch, setFalseCallback: hideDocSearch,
  }] = useOptimizedBooleanState(!!isInitialVisible);
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const { insight } = useDocTypeInsight();
  const { doc: parentDoc } = useDocV2(insightDocParentId);
  const changeDocParent = useChangeDocParent();
  const getUrl = useUrl();
  const possibleDoctypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);
  const { setNewInsightPropertiesStep } = useDocSearchProductTour();

  return feedback ? (
    <InsightCreateModal
      isOpen={!!insightDocParentId}
      hide={() => {
        if (!isDocSearchVisible) {
          onHide?.();
          return;
        }
        setInsightDocParentId('');
      }}
      onBack={() => {
        showDocSearch();
        setInsightDocParentId('');
      }}
      parentDoc={parentDoc}
      feedbackDoc={feedback}
      dropdownProps={dropdownProps}
      defaultContent={defaultContent}
      blockId={blockId}
      onInsightCreating={onInsightCreating}
      onInsightCreated={onInsightCreated}
    >
      <DocSearchDropdown
        childDoctypeId={insight?.id}
        possibleDoctypes={possibleDoctypes}
        dropdownProps={dropdownProps}
        hideSearchDropdown={() => {
          if (!insightDocParentId) {
            onHide?.();
            return;
          }
          hideDocSearch();
        }}
        isSearchDropdownVisible={isDocSearchVisible}
        onAdd={async (docId) => {
          let parentId = docId;
          if (docTarget?.doc) {
            const result = await changeDocParent({
              docId: docTarget.doc.id,
              parentId: docId,
            });
            if (result?.data?.changeDocParent?.id) {
              parentId = result.data.changeDocParent.id;
            }
            onHide?.();
            return;
          }
          setInsightDocParentId(parentId);
          hideDocSearch();
          setNewInsightPropertiesStep();
        }}
        placeholder={getDocTypeNames(possibleDoctypes, {
          prefix: 'Search',
          suffix: possibleDoctypes.length > 1 ? 's or add new' : ' or add new',
        })}
      >
        {isVisible && docTarget?.doc?.parent && (
          <motion.div
            initial={{
              opacity: 0,
              translateY: -6,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <StyledLink to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(docTarget.doc.parent) })}>
              <StyledDocParent
                doc={docTarget.doc.parent}
                showTitle
                showIcon
                size="XS"
                tooltip={docTarget.doc.parent.title}
              >
                <EditIconContainer
                  onClick={(e) => {
                    e.preventDefault();
                    showDocSearch();
                  }}
                >
                  <Tooltip content="Change parent" placement="bottom">
                    <StyledPenFilledIcon />
                  </Tooltip>
                </EditIconContainer>
              </StyledDocParent>
            </StyledLink>
          </motion.div>
        )}
      </DocSearchDropdown>
    </InsightCreateModal>
  ) : null;
};
