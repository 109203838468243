import { typo, Table, Button, Tag } from '@cycle-app/ui';
import { CheckIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { Header } from 'src/app/Main/Settings/Settings.styles';

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${p => p.theme.userColors.main};
`;

export const StyledHeader = styled(Header)`
  align-items: flex-start;
  border-bottom: none;
  flex-direction: column;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const Intro = styled.p`
  ${typo.body400}
`;

export const Container = styled.div`
  margin: 0 -32px;
`;

export const StyledTable = styled(Table)`
  padding-top: 32px;
  margin-bottom: 180px;

  tr {
    height: 1px;
  }

  tbody {
    ${typo.body400}
  }
`;

export const HeadTh = styled.th`
  padding: 16px 8px;
  min-width: 214px;
  width: 270px;
`;

interface PlanRowProps {
  $isSticky?: boolean;
}

export const PlanRow = styled.tr<PlanRowProps>`
  background-color: ${p => p.theme.colors.settings.bg};
  position: sticky;
  top: 0;
  z-index: 999;
  padding-bottom: 12px;

  th {
    vertical-align: top;
    height: inherit;
  }

  ${p => p.$isSticky && css`
    th {
      border-bottom: 1px solid ${p.theme.colors.border.primary};
    }
  `}
`;

export const UpgradeRow = styled.tr<PlanRowProps>`
  ${HeadTh} {
    padding-top: 0;
  }

  ${p => !p.$isSticky && css`
    th {
      border-bottom: 1px solid ${p.theme.colors.border.primary};
    }
  `}
`;

interface PlanCellProps {
  $isFree?: boolean;
  $isStandard?: boolean;
}

export const PlanCell = styled.div<PlanCellProps>`
  ${typo.headerSmall}
  --bg: ${p => p.theme.colors.billing.plan.business.bg};
  --color: ${p => p.theme.colors.billing.plan.business.color};
  --fg: ${p => p.theme.colors.billing.plan.business.fg};
  align-items: baseline;
  border-radius: 8px;
  background-color: var(--bg);
  color: var(--color);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  padding: 8px 12px;

  ${p => p.$isFree && css`
    --bg: ${p.theme.colors.billing.plan.free.bg};
    --color: ${p.theme.colors.billing.plan.free.color};
    --fg: ${p.theme.colors.billing.plan.free.fg};
  `}
  ${p => p.$isStandard && css`
    --bg: ${p.theme.colors.billing.plan.standard.bgLight};
    --color: ${p.theme.colors.billing.plan.standard.color};
    --fg: ${p.theme.colors.billing.plan.standard.fg};
  `}
`;

export const PlaceholderCell = styled.th`
  width: 30px;
`;

export const FeatureNameCell = styled.th`
  width: 30vw;
  min-width: 290px;
`;

export const FeatureValue = styled.div`
  margin-left: 30%;

  td:last-child & {
    margin-left: calc(30% - 30px);
  }
`;

export const FeatureTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  [data-popover] {
    align-items: center;
    display: flex;
    height: 1em;
  }
`;

export const FeatureStatusTag = styled(Tag)`
  ${p => !p.color && css`
    background-color: ${p.theme.colors.background.tertiary};
    border-color: transparent;
    color: ${p.theme.colors.text.secondary};
  `}
`;

export const FeatureGroup = styled.div`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
`;

export const FeatureHelper = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  padding-top: 2px;
`;

export const BodyCell = styled.div<{ $isGroup?: boolean }>`
  border-top: 1px solid ${p => p.theme.colors.border.primary};
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 10px;

  ${p => p.$isGroup && css`
    border: none;
    margin-top: 22px;
  `}
`;

export const BodyTd = styled.td`
  padding: 0;
  vertical-align: top;
  height: inherit;

  &:first-child {
    color: ${p => p.theme.colors.text.secondary};

    ${BodyCell} {
      margin-left: 48px;
    }
  }
`;

export const Pricing = styled.div`
  white-space: nowrap;
`;

export const PriceTag = styled.span`
  color: var(--fg);
`;

export const PricePeriod = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};
`;

export const CustomPriceTag = styled.div`
  ${typo.headerLarge}
  display: block;
  margin-top: 12px;
`;

interface UpgradeButtonProps {
  $isStroke?: boolean;
}

export const UpgradeButton = styled(Button)<UpgradeButtonProps>`
  width: 100%;

  ${p => p.$isStroke && css`
    --bg: transparent;
    --border: ${p.theme.userColors.main};
    --color: ${p.theme.userColors.main};
    border-width: 2px;

    &:hover, &:focus {
      --bg: ${darken(0.1, p.theme.userColors.main)};
      --border: ${darken(0.1, p.theme.userColors.main)};
      --color: ${p.theme.colors.text.white};
    }
  `}
`;

export const StyledInfoIconOutline = styled(InfoIconOutline)`
  height: 12px;
  width: 12px;
`;
