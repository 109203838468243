import { Tag, TagProps } from '@cycle-app/ui';
import { getDocFullUrl } from '@cycle-app/utilities';
import { FC, useCallback, MouseEvent, useMemo } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { useUrl } from 'src/hooks/useUrl';
import { getDocSlug } from 'src/utils/slug.util';

export type DocTagKeyProps = TagProps & {
  docId: string;
  docKey: string;
  docTitle: string;
};

export const DocTagKey: FC<DocTagKeyProps> = ({
  docKey,
  docTitle,
  docId,
  ...props
}) => {
  const getUrl = useUrl();
  const copyToClipboard = useCopyToClipboard({
    successNotification: `Link to ${docKey} copied to clipboard!`,
  });
  const docUrl = useMemo(() => getUrl(PageId.DocFullPage, {
    docSlug: getDocSlug({
      id: docId,
      title: docTitle,
    }),
  }), [docId, docTitle, getUrl]);

  const onClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const fullDocUrl = docUrl && getDocFullUrl(docUrl);

    if (!fullDocUrl) {
      console.warn('No doc url');
      return;
    }

    copyToClipboard(fullDocUrl);
  }, [copyToClipboard, docUrl]);

  return (
    <Tag
      {...props}
      onClick={onClick}
      tooltip={{
        placement: 'top',
        title: 'Doc ID',
        content: 'Copy link',
        withPortal: true,
      }}
    >
      {docKey}
    </Tag>
  );
};
