import { LinearStatus as LinearStatusType } from '@cycle-app/graphql-codegen';
import { IntegrationCardSkeleton, EditorIntegrationCard, SelectPanel } from '@cycle-app/ui';
import { LinearIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useEffect, useMemo } from 'react';

import { IntegrationEditorMenu } from 'src/components/Integrations/IntegrationEditorMenu/IntegrationEditorMenu';
import { ContainerNodeView, SlotText } from 'src/components/Integrations/IntegrationsCommon.styles';
import { LinearAssignee } from 'src/components/LinearAssignee/LinearAssignee';
import { LinearStatus } from 'src/components/LinearStatus/LinearStatus';
import { useEditorContext } from 'src/contexts/editorContext';
import { useChangeAssigneeLinearIssue } from 'src/hooks/api/mutations/integrations/useChangeAssigneeLinearIssue';
import { useChangeLinearStatusIssue } from 'src/hooks/api/mutations/integrations/useChangeStatusLinearIssue';
import { useLinearAssignees } from 'src/hooks/api/queries/integrations/useLinearAssignees';
import { useLinearIssue } from 'src/hooks/api/queries/integrations/useLinearIssue';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import { useNodeSelection } from 'src/hooks/editor/useNodeSelection';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { LinearIssuesCommandAttributes } from 'src/types/editor.types';
import { Layer } from 'src/types/layers.types';
import { isLinearStatus } from 'src/utils/integrations.utils';

import DropdownLayer from '../DropdownLayer/DropdownLayer';
import { GithubDescription } from '../GithubDescription/GithubDescription';
import { InfosList, InfosTitle, StyledAssigneeSelectButton, StyledSelectButton } from './LinearEditorMentionView.styles';

type RenderPreTitleSlotParams = {
  publicId?: string;
  status?: LinearStatusType;
  teamKey?: string;
  teamName?: string;
};

interface Props extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: Partial<LinearIssuesCommandAttributes>) => void;
}
export const LinearEditorMentionView: FC<Props> = ({
  node, selected, getPos, updateAttributes,
}) => {
  const {
    issue, refetch,
  } = useLinearIssue(node.attrs.id);
  const {
    teams, isLoading: isTeamsLoading,
  } = useLinearTeams();
  const {
    assignees, isLoading: isAssigneesLoading,
  } = useLinearAssignees();
  const { changeLinearStatus } = useChangeLinearStatusIssue();
  const { changeLinearAssignee } = useChangeAssigneeLinearIssue();
  const teamStatus = useMemo(() => teams
    .find(team => team?.id === issue?.team?.id)?.availableStatus.filter(isLinearStatus) ?? [], [teams, issue]);
  const statusOptions = useMemo(() => teamStatus.map(status => ({
    value: status.id,
    label: status.name,
    icon: <LinearStatus status={status} />,
  })) ?? [], [teamStatus]);
  const assigneesOptions = assignees.map(assignee => ({
    value: assignee.id,
    label: assignee.name,
    icon: (
      <LinearAssignee assignee={assignee} tooltip={false} />
    ),
  }));
  const { editor } = useEditorContext();
  const { isSelected } = useNodeSelection({
    editor,
    selected,
    getPos,
  });
  const [isStatusSelectOpened, { toggleCallback: toggleStatusSelect }] = useOptimizedBooleanState(false);
  const [isAssigneeSelectOpened, { toggleCallback: toggleAssigneeSelect }] = useOptimizedBooleanState(false);

  useEffect(() => {
    if (issue) {
      updateAttributes({
        id: issue.id,
        publicId: issue.publicId,
        url: issue.url,
        title: issue.title,
        teamKey: issue.team?.key || null,
        teamName: issue.team?.name || null,
        assignee: issue.assignee,
        status: issue.status,
        description: issue.description ?? null,
      });
    }
  }, [issue, updateAttributes]);

  return (
    <ContainerNodeView
      $isSelected={isSelected}
      data-drag-handle
      onClick={() => {
        const url = node.attrs.url || issue?.url;
        if (url) {
          window.open(url, '_blank');
        }
      }}
    >
      {renderContent()}
    </ContainerNodeView>
  );

  function renderContent() {
    const {
      publicId, url, status, title, teamKey, teamName, assignee,
    } = node.attrs;
    const showSkeleton = !url && !title && !publicId;

    if (showSkeleton) return <IntegrationCardSkeleton />;

    const assigneeData = assignee || issue?.assignee;
    const statusData = status || issue?.status;
    return (
      <EditorIntegrationCard
        title={title ?? issue?.title ?? ''}
        preTitleSlot={renderPreTitleSlot({
          publicId,
          status,
          teamKey,
          teamName,
        })}
        actions={(
          <IntegrationEditorMenu
            editor={editor}
            getPos={getPos}
            node={node}
            url={url}
            onRefetch={refetch}
          />
        )}
        description={issue?.description && <GithubDescription description={issue.description} />}
        hasExpandContent
        assignees={(
          <DropdownLayer
            layer={Layer.DropdownModal}
            visible={isAssigneeSelectOpened}
            hide={toggleAssigneeSelect}
            placement="bottom-end"
            content={(
              <SelectPanel
                options={assigneesOptions}
                header="Linear assignee"
                isLoading={!issue && isAssigneesLoading}
                onOptionChange={({ value }) => onAssigneeChange(value)}
              />
            )}
          >
            <StyledAssigneeSelectButton size="S" variant="nospace" onClick={e => { e.stopPropagation(); toggleAssigneeSelect(); }}>
              <LinearAssignee assignee={assigneeData} />
            </StyledAssigneeSelectButton>
          </DropdownLayer>
        )}
        footerSlot={(
          <InfosList>
            <div>
              <InfosTitle>Status</InfosTitle>
              {statusData && (
                <DropdownLayer
                  layer={Layer.DropdownModal}
                  visible={isStatusSelectOpened}
                  hide={toggleStatusSelect}
                  placement="bottom-start"
                  content={(
                    <SelectPanel
                      options={statusOptions}
                      hideSearch
                      isLoading={!issue && isTeamsLoading}
                      onOptionChange={({ value }) => onStatusChange(value)}
                    />
                )}
                >
                  <StyledSelectButton size="S" variant="light" onClick={e => { e.stopPropagation(); toggleStatusSelect(); }}>
                    <LinearStatus status={statusData} />
                    {statusData.name}
                  </StyledSelectButton>
                </DropdownLayer>
              )}
            </div>
          </InfosList>
        )}
      />
    );
  }

  async function onStatusChange(statusId: string) {
    if (!issue) return;
    updateAttributes({
      status: teamStatus.find(status => status.id === statusId),
    });
    toggleStatusSelect();
    await changeLinearStatus({
      issueId: issue.id,
      stateId: statusId,
    });
  }

  async function onAssigneeChange(assigneeId: string) {
    if (!issue) return;
    updateAttributes({
      assignee: assignees.find(assignee => assignee.id === assigneeId),
    });
    toggleAssigneeSelect();
    await changeLinearAssignee({
      issueId: issue.id,
      assigneeId,
    });
  }

  function renderPreTitleSlot({
    publicId, teamKey, teamName, status,
  }:RenderPreTitleSlotParams) {
    const text = teamKey && teamName && publicId ? `${teamKey}-${publicId} · ${teamName}` : null;
    const linearStatus = status || issue?.status || null;

    return (
      <>
        <LinearIcon />
        {!!text && <SlotText>{text}</SlotText> }
        {!!linearStatus && <LinearStatus status={linearStatus} />}
      </>
    );
  }
};
