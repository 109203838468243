import { FC, ReactNode } from 'react';
import { Placement } from 'tippy.js';

import { Popover, Props as PopoverProps } from '../Popover/Popover';
import { TooltipContent } from './TooltipContent';

export interface TooltipProps extends PopoverProps {
  className?: string;
  title?: ReactNode;
  content: ReactNode;
  disabled?: boolean;
  visible?: boolean;
  withPortal?: boolean;
  placement?: Placement;
  enableOnMobile?: boolean;
  displayFullTitle?: boolean;
  width?: number;
}

export type TooltipPlacement = Placement;

export const Tooltip: FC<TooltipProps> = ({
  className,
  content,
  title,
  children,
  visible,
  withPortal = false,
  placement = 'auto',
  disabled = false,
  enableOnMobile = false,
  displayFullTitle = true,
  width,
  ...popoverProps
}) => (
  <Popover
    disabled={disabled}
    visible={visible}
    withPortal={withPortal}
    placement={placement}
    enableOnMobile={enableOnMobile}
    animation
    content={(
      <TooltipContent
        className={className}
        title={title}
        content={content}
        displayFullTitle={displayFullTitle}
        width={width}
      />
    )}
    {...popoverProps}
  >
    {children}
  </Popover>
);
