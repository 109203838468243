import { FC } from 'react';

import incognitoEyes from 'src/assets/incognito-eyes.png';

import {
  IncognitoFace,
  StyledIncognitoCardIcon,
  StyledIncognitoEyes,
  StyledIncognitoMask,
} from './IncognitoIllustationCard.styles';

interface Props {
  className?: string;
}

export const IncognitoIllustationCard: FC<Props> = ({ className }) => (
  <StyledIncognitoCardIcon className={className}>
    <IncognitoFace>
      <StyledIncognitoMask />
      <StyledIncognitoEyes alt="incognito" src={incognitoEyes} width={118} height={118} />
    </IncognitoFace>
  </StyledIncognitoCardIcon>
);
