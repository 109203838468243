import { Tag, typo, ActionButton } from '@cycle-app/ui';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import BoardOptions from 'src/components/BoardOptions/BoardOptions';

type ButtonProps = {
  onColoredBg?: boolean;
};

const buttonCss = css<ButtonProps>`
  &:hover, &.force-focus {
    background-color: ${p => p.theme.colors.sidebar.item.dotsMenu.bgHover};
    color: ${p => p.theme.colors.sidebar.item.dotsMenu.colorHover};

    ${p => p.onColoredBg && css`
      background-color: ${darken(0.2, p.theme.userColors.main)};
      color: ${p.theme.colors.text.white};
    `}
  }
`;

export const StyledBoardOptions = styled(BoardOptions)`
  ${buttonCss}
`;

export const TagStyled = styled(Tag)`
  ${typo.body500}
  border: none;
  display: inline-flex;
`;

export const More = styled.div`
  display: flex;
  gap: 4px;
`;

export const StyledActionButton = styled(ActionButton)<ButtonProps>`
  ${buttonCss};
`;
