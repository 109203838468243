import { ViewType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import {
  StyledViewCard, TitleLineSkeleton, AttributeSkeleton, Attributes, AvatarSkeleton, TitleContainer,
} from './ViewCardSkeleton.styles';

export interface ViewCardSkeletonProps {
  index?: number;
  viewType: ViewType;
  skipAnimation?: boolean;
  fill?: boolean;
  minimal?: boolean;
}

export const ViewCardSkeleton: FC<ViewCardSkeletonProps> = ({
  viewType,
  skipAnimation = false,
  fill = false,
  minimal = false,
}) => {
  return (
    <StyledViewCard
      $skipAnimation={skipAnimation}
      $fill={fill}
      viewType={viewType}
      title={(
        <TitleContainer>
          <TitleLineSkeleton />
          {viewType === ViewType.Kanban && (
            <TitleLineSkeleton />
          )}
        </TitleContainer>
      )}
      {...viewType === ViewType.Kanban
        ? {
          preToolbar: !minimal && (
            <Attributes>
              <AttributeSkeleton />
              <AttributeSkeleton />
            </Attributes>
          ),
        }
        : {
          preTitle: !minimal && (
            <Attributes>
              <AttributeSkeleton />
              <AttributeSkeleton />
            </Attributes>
          ),
        }
      }
      sectionStart={' '}
      sectionEnd={!minimal && <AvatarSkeleton />}
    />
  );
};
