import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { Layer } from 'src/types/layers.types';
import { ShortcutDocPanel } from 'src/types/shortcuts.types';
import { getOpenedLayersAbove } from 'src/utils/layers.util';
import { getDocSlug } from 'src/utils/slug.util';

export const useDocShortcutListener = (doc: DocBaseFragment | null) => {
  const {
    navigate, navigateToDocPanelParent,
  } = useNavigate();
  const pageId = usePageId();

  const callbacks = useMemo(() => ({
    [ShortcutDocPanel.CloseDoc]: () => {
      if (pageId === PageId.DocFullPage || getOpenedLayersAbove(Layer.DocPanel)) {
        return;
      }
      navigateToDocPanelParent();
    },

    [ShortcutDocPanel.ExpandDoc]: () => {
      if (doc) {
        navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
      }
    },

  }), [doc, navigate, navigateToDocPanelParent, pageId]);

  useHotkeyListener({
    callbacks,
    shortcuts: Object.values(ShortcutDocPanel),
    disableOnLayers: [Layer.Dropdown, Layer.Modal],
  });
};
