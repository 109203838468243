import { SelectOption } from '@cycle-app/ui';
import { UnionIcon, DuplicateIcon, TrashIcon } from '@cycle-app/ui/icons';
import { TweetEmbed } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useEffect } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useEditorContext } from 'src/contexts/editorContext';
import { useNodeSelection } from 'src/hooks/editor/useNodeSelection';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import { Container, Toolbar, Embed, Iframe } from './IframelyView.styles';

interface Props extends NodeViewRendererProps {
  selected: boolean;
}

const IframelyView: FC<Props> = ({
  node,
  selected,
  getPos,
}) => {
  const { editor } = useEditorContext();
  const { isSelected } = useNodeSelection({
    editor,
    selected,
    getPos,
  });

  const html = node.attrs.html as string;
  const url = node.attrs.url as string;

  const iframeOptions: Array<SelectOption> = [
    {
      label: 'Open',
      value: 'open',
      icon: <UnionIcon />,
      onSelect: () => window.open(node.attrs.url, '_blank'),
    },
    {
      label: 'Copy',
      value: 'copy',
      icon: <DuplicateIcon />,
      onSelect: () => navigator.clipboard.writeText(node.attrs.url),
    },
    {
      label: 'Delete',
      value: 'delete',
      icon: <TrashIcon />,
      onSelect: () => deleteNodeRange({
        editor,
        node,
        getPos,
      }),
    },
  ];

  useEffect(() => {
    // Fix iframely.load with dirty setTimeout
    if (node.attrs.url) {
      setTimeout(() => {
        iframely.load();
      });
    }
  }, [node.attrs.url]);

  if (!node.attrs.url) {
    return null;
  }

  return (
    <Container $isSelected={isSelected} $hasMinHeight={!!html}>
      {renderEmbed()}
    </Container>
  );

  function renderEmbed() {
    if (url.includes('twitter.com')) {
      const tweetId = /status\/(\d+)/.exec(url)?.[1];
      return (
        <TweetEmbed id={tweetId ?? ''} />
      );
    }
    return (
      <>
        <Toolbar>
          <DotsMenuLayer
            options={iframeOptions}
            placement="bottom-end"
          />
        </Toolbar>
        {html ? <Embed dangerouslySetInnerHTML={{ __html: html }} /> : <Iframe src={url} />}
      </>
    );
  }
};

export default IframelyView;
