import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';

import { LinearEditorMentionView } from 'src/components/LinearEditorMentionView/LinearEditorMentionView';
import { LinearSearchIssueDropdown } from 'src/components/LinearSearchIssueDropdown/LinearSearchIssueDropdown';
import { COMMANDS, LINEAR_EXTENSION_TAGNAME, LINEAR_EXTENSION_NAME } from 'src/constants/editor.constants';
import { renderIntegrationMentionSuggestion } from 'src/utils/integrations.utils';

type LinearMentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

const getLinearMentionExtension = () => Node.create<LinearMentionOptions>({
  name: LINEAR_EXTENSION_NAME,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,
  atom: true,

  addOptions: (): LinearMentionOptions => ({
    HTMLAttributes: {
      class: 'mention-linear',
    },
    suggestion: {
      allowSpaces: true,
      char: COMMANDS.LINEAR,
      render: () => renderIntegrationMentionSuggestion(LINEAR_EXTENSION_NAME, LinearSearchIssueDropdown),
    },
  }),

  addAttributes() {
    return {
      id: { default: null },
      publicId: { default: null },
      status: {
        default: null,
        rendered: false,
      },
      url: {
        default: null,
        rendered: false,
      },
      title: {
        default: null,
        rendered: false,
      },
      teamKey: {
        default: null,
        rendered: false,
      },
      teamName: {
        default: null,
        rendered: false,
      },
      assignee: {
        default: null,
        rendered: false,
      },
      description: {
        default: null,
        rendered: false,
      },
    };
  },

  parseHTML() {
    return [
      { tag: LINEAR_EXTENSION_TAGNAME },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      LINEAR_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LinearEditorMentionView);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${LINEAR_EXTENSION_NAME}`),
      }),
    ];
  },
});

export default getLinearMentionExtension;
