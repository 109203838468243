import {
  Color as ColorApi,
  UpdateUserOnboardingStep3Document,
  ThemeType,
  SectionType,
} from '@cycle-app/graphql-codegen';
import { Avatar, ColorPicker, ThemePicker, Button, OnboardingLayout } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { readFileSrc } from '@cycle-app/utilities';
import React, { FC, RefObject, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ThemeProvider } from 'styled-components';

import { StyledImageInput, AvatarContainer, AvatarInput, AppPreview, previewVariant } from 'src/app/Auth/Welcome/Welcome.styles';
import Main from 'src/app/Main/Main';
import DefaultAvatarPicker from 'src/components/DefaultAvatarPicker/DefaultAvatarPicker';
import { OnboardingPreviewBoardRoute } from 'src/components/OnboardingPreviewBoardRoute/OnboardingPreviewBoardRoute';
import { Events, Objects } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useMaybeMe } from 'src/hooks/api/useMe';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setOnboarding, getOnboarding } from 'src/reactives/onboarding.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { setThemeConfig } from 'src/reactives/theme.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';

import { FormContainer } from '../OnboardingView.styles';
import { PreviewBoard } from './PreviewBoard';
import { Label } from './StepPreferences.styles';

interface FormData {
  color: ColorApi;
  avatarFile: File | null;
  avatarString: string;
  theme: ThemeType;
}

type Props = {
  progress: number;
  isReadOnly?: boolean;
};

export const StepPreferences: FC<Props> = ({
  progress, isReadOnly,
}) => {
  const isMobile = useIsMobile();
  const me = useMaybeMe();
  const {
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      color: getOnboarding().userColor,
      avatarFile: null,
      avatarString: '',
      theme: getOnboarding().theme,
    },
  });

  const [updateUserOnboardingStep3, { loading: isLoading }] = useSafeMutation(UpdateUserOnboardingStep3Document, {
    onCompleted: () => trackAnalytics(Events.UserAccountUpdated, {
      object: Objects.UserTheme,
    }),
  });
  const {
    avatarString, avatarFile, color, theme,
  } = watch();

  useEffect(() => {
    async function syncAvatarFileToStringValue(file: File) {
      const avatarSrc = await readFileSrc(file);
      setValue('avatarString', avatarSrc);
    }
    if (avatarFile) {
      syncAvatarFileToStringValue(avatarFile).catch(() => {
        logError(new Error('Error when get url from image file'));
      });
    }
  }, [avatarFile]);

  const selectedTheme = getTheme(theme, color);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OnboardingLayout
        title="Make yourself at home"
        description="Pick your color, profile picture, and theme"
        main={(
          <FormContainer>
            <div>
              <Label>Choose your color</Label>
              <ColorPicker
                color={color}
                onClick={(newColor) => {
                  setOnboarding({ userColor: newColor });
                  setValue('color', newColor);
                }}
              />
            </div>

            <div>
              <Label>Profile picture</Label>
              <StyledImageInput
                previewModalTitle="Confirm your profile picture"
                previewModalSubmitLabel="Set new profile picture"
                previewBorderColor={me?.color || color}
                onChange={(newAvatarFile) => setValue('avatarFile', newAvatarFile)}
              >
                {(inputRef) => (
                  <AvatarContainer>
                    <AvatarInput>
                      {renderAvatar(inputRef)}
                      <Button
                        isLoading={false}
                        variant="ternary"
                        onClick={(e) => {
                          e.preventDefault();
                          inputRef.current?.click();
                        }}
                      >
                        Upload image
                      </Button>
                    </AvatarInput>
                    <DefaultAvatarPicker
                      onSelect={(avatarUrl) => {
                        setValue('avatarFile', null);
                        setValue('avatarString', avatarUrl);
                      }}
                      hideDescription
                      color={color}
                    />
                  </AvatarContainer>
                )}
              </StyledImageInput>
            </div>

            <div>
              <Label>Choose your theme</Label>
              <ThemePicker
                theme={theme}
                onChange={(newTheme) => {
                  setOnboarding({ theme: newTheme });
                  setValue('theme', newTheme);
                  setThemeConfig({ colorTheme: newTheme });
                }}
                userColor={color}
              />
            </div>
          </FormContainer>
        )}
        aside={me ? (
          <AppPreview
            initial="hidden"
            animate="glimpse"
            $isLoading={false}
            $full={false}
            variants={previewVariant}
          >
            <ThemeProvider theme={selectedTheme}>
              <OnboardingPreviewBoardRoute name="initiatives" pageId={PageId.Board} sectionType={SectionType.Default}>
                <Main>
                  <PreviewBoard />
                </Main>
              </OnboardingPreviewBoardRoute>
            </ThemeProvider>
          </AppPreview>
        ) : null}
        progress={progress}
        nextButtonProps={{
          type: 'submit',
          isLoading,
        }}
        isModal={isReadOnly}
        isFixedFooter={isReadOnly}
      />
    </form>
  );

  function renderAvatar(inputRef: RefObject<HTMLInputElement>) {
    return (
      <Avatar
        user={me}
        size={40}
        onClick={() => inputRef.current?.click()}
        userColor={color}
        src={avatarString}
      />
    );
  }

  async function onSubmit(values: FormData) {
    // eslint-disable-next-line no-nested-ternary
    const avatarInput = values.avatarFile && !values.avatarString
      ? { avatar: values.avatarFile }
      : values.avatarString
        ? { avatarUrl: values.avatarString }
        : null;
    await updateUserOnboardingStep3({
      variables: {
        avatarInput,
        color,
        theme,
      },
    });
    setOnboarding({ screen: isMobile ? OnboardingScreen.Welcome : OnboardingScreen.Integrations });
  }
};
