import { ShyScrollbarCss, Button, SelectLine } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 8px 0px;
  max-height: 49vh;
  ${ShyScrollbarCss};
`;

export const SaveButton = styled(Button)`
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translate(0, -50%);
`;

export const ReorderContainer = styled.div`
  display: flex;
  color: ${p => p.theme.colors.text.disabled};
  cursor: grab;
`;

interface SelectLineStyledProps {
  $isValueChanged?: boolean;
}

export const SelectLineStyled = styled(SelectLine)<SelectLineStyledProps>`
  ${p => p.$isValueChanged && css`
    --lineEnd-flex-basis: 38px;
  `}
`;
