import { make } from 'src/utils/reactives.util';

export interface LoaderState {
  loading: boolean;
  labelLoading: string;
  labelSuccess: string;
}

export const {
  hookValue: useGetLoader,
  getValue: getLoader,
  setValue: setLoader,
} = make<LoaderState>({
  defaultState: {
    loading: false,
    labelLoading: 'Saving…',
    labelSuccess: 'Changes saved',
  },
  mergeTarget: 'initial',
});
