import styled, { css } from 'styled-components';

import DocHierarchy from 'src/components/DocHierarchy/DocHierarchy';
import { Editor } from 'src/components/Editor';
import EditorSkeleton from 'src/components/EditorSkeleton/EditorSkeleton';

export const StyledDocHierarchy = styled(DocHierarchy)`
  overflow: visible;
  margin: 0 auto;
  max-width: 700px;
  padding-bottom: 60px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const StyledEditorSkeleton = styled(EditorSkeleton)`
  max-width: 700px;
  padding: 0 16px;
`;

export const StyledEditor = styled(Editor)<{ showHierarchy: boolean }>`
  ${p => p.showHierarchy && css`
    height: auto;
  `}
`;
