import { CloseIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { ReactNode, FC } from 'react';

import {
  ModalBody, ModalCloseButtonStyled, ModalHeader, ModalInfo, ModalTitle, ModalTop, PortalModalStyled,
} from './BillingDowngrade.styles';

interface Props {
  info?: ReactNode;
  onHide: VoidFunction;
  title: string;
  top?: ReactNode;
}

export const BillingDowngradeModal: FC<Props> = ({
  children, title, onHide, info, top,
}) => (
  <PortalModalStyled hide={onHide} $isHeaderFixed={!!top}>
    <div>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalCloseButtonStyled size="L" onClick={onHide}>
          <CloseIcon />
        </ModalCloseButtonStyled>
      </ModalHeader>
      {top && <ModalTop>{top}</ModalTop>}
    </div>
    <ModalBody>
      {children}
    </ModalBody>
    {info && (
      <ModalInfo>
        <InfoIconOutline />
        {info}
      </ModalInfo>
    )}
  </PortalModalStyled>
);
