import { typo, CheckboxInput } from '@cycle-app/ui';
import styled from 'styled-components';

export const JobsInput = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  padding-right: 120px;
`;

export const Label = styled.div`
  ${typo.body500};
  margin-bottom: 4px;
`;

export const RadioContainer = styled.div`
  width: 100%;
`;

export const Anchor = styled.a`
  color: ${p => p.theme.colors.text.primary};
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  margin-top: 4px;
`;

export const CheckboxContainer = styled.div`
  ${typo.body400}
  display: flex;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
`;
