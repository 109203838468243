import { useState } from 'react';

import { useOptimizedBooleanState } from 'src/hooks';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { ConfirmEmailForm } from './ConfirmEmailForm';
import { StyledAside } from './OnboardingStepAccount.styles';
import { VerifyEmailForm } from './VerifyEmailForm';

export const OnboardingStepAccount = () => {
  const [isConfirmForm, { setTrueCallback: showConfirmForm }] = useOptimizedBooleanState(false);
  const [email, setEmail] = useState('');
  return (
    <OnboardingLayout
      title={email ? 'Confirm your email' : 'Let’s create your account'}
      aside={<StyledAside />}
      main={(
        <>
          {isConfirmForm
            ? <ConfirmEmailForm email={email} onBack={showConfirmForm} />
            : (
              <VerifyEmailForm
                defaultValue={email}
                onSuccess={(emailValue) => {
                  setEmail(emailValue);
                  showConfirmForm();
                }}
              />
            )}
        </>
      )}
    />
  );
};
