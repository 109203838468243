import styled, { keyframes } from 'styled-components';

const dashOffsetAnimation = (pathLength: number) => keyframes`
  0% {
    stroke-dashoffset: ${pathLength};
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -${pathLength};
  }
`;

export const StyledSvg = styled.svg<{ totalLength: number }>`
  path {
    animation: ${(props) => dashOffsetAnimation(props.totalLength)} 4s
      cubic-bezier(0.06, 0.49, 0.9, 0.38) infinite;

    stroke: ${p => p.theme.userColors.main};
    fill: none;
  }
`;
