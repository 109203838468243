import { Color } from '@cycle-app/graphql-codegen/generated';
import styled from 'styled-components';

import { CollaborationCursorCSS, COLLABORATION_CURSOR_CARET_CLASS } from '../Editor/Editors/Editor.styles';

export const CursorContainer = styled.div`
  position: relative;
  z-index: 1;
  ${CollaborationCursorCSS}
`;

export const Cursor = styled.div<{ $color: Color }>`
  position: absolute;
  opacity: 0;

  .${COLLABORATION_CURSOR_CARET_CLASS} {
    border-color: ${p => p.theme.nuances[p.$color].main};
  }
`;

export const CursorLabel = styled.span<{ $color: Color }>`
  background-color: ${p => p.theme.nuances[p.$color].main};
`;
