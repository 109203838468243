import { AddIcon, CloseIcon } from '@cycle-app/ui/icons';
import {
  useCallback, useEffect, useState, FC, ChangeEvent, useRef,
} from 'react';

import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetBillingPermission } from 'src/reactives/permission.reactive';

import { BoardSectionInput } from '../../SidebarBoard.styles';
import { NewSectionItem } from './SidebarBoardNewSection.styles';

const SidebarBoardNewSection: FC = () => {
  const collapsed = useSidebarCollapsed();
  const { canPlanCreateBoardSection } = useGetBillingPermission();
  const [isCreatingSection, {
    setTrueCallback: openEditionMode,
    setFalseCallback: closeEditionMode,
  }] = useOptimizedBooleanState(false);
  const [name, setName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { addProductSection } = useProductMutations();

  const handleSubmit = useCallback(async () => {
    if (!isCreatingSection || !name) return;

    closeEditionMode();
    await addProductSection(name);
    setName('');
  }, [addProductSection, isCreatingSection, name, closeEditionMode]);

  const handleEscape = useCallback(() => {
    if (isCreatingSection) {
      setName('');
      closeEditionMode();
    }
  }, [isCreatingSection, closeEditionMode]);

  useAppHotkeys('enter', handleSubmit);
  useAppHotkeys('escape', handleEscape);

  useEffect(() => {
    if (isCreatingSection) {
      inputRef.current?.focus();
    }
  }, [isCreatingSection]);

  if (collapsed) return null;

  if (isCreatingSection) {
    return (
      <BoardSectionInput
        ref={inputRef}
        value={name}
        placeholder="Your section name"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        iconAfter={<CloseIcon />}
        onBlur={handleEscape}
        onClickIcon={handleEscape}
      />
    );
  }

  return (
    <NewSectionItem
      label="New Section"
      icon={<AddIcon />}
      onClick={() => {
        if (canPlanCreateBoardSection) {
          openEditionMode();
        } else {
          setLimitationsModal({
            action: 'BOARD_SECTION_CREATE',
          });
        }
      }}
      isSidebarColor
    />
  );
};

export default SidebarBoardNewSection;
