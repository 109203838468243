import styled from 'styled-components';

import { body500, body400 } from '../../theme/typo';
import { truncate } from '../../utils/styles.util';
import { TextButton } from '../Buttons/TextButton/TextButton';
import { Skeleton } from '../Skeleton/Skeleton';

type ContainerProps = {
  $withBorder?: boolean;
  $isHighlighted?: boolean;
};

export const OptionContainer = styled.div<{ $isFocus: boolean }>`
  position: absolute;
  display: ${p => (p.$isFocus ? 'block' : 'none')};
  top: 16px;
  right: 16px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.background.hoverSoft};
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  border-radius: ${p => (p.$withBorder ? '8px' : '0')};
  border: ${p => (p.$withBorder ? `1px solid ${p.theme.colors.components.EditorIntegrationCard.border}` : 'none')};
  box-shadow: ${p => (p.$withBorder ? '0px 2px 5px rgba(0, 0, 0, 0.05)' : 'none')};
  gap: 8px;
  transition: background .2s;
  background-color: ${p => (p.$isHighlighted ? p.theme.userColors.br : 'none')};
  box-sizing: border-box;

  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
    cursor: pointer;

    ${OptionContainer} {
      display: block;
    }
  }
`;

export const Title = styled.span`
  ${body400}
  flex: 1;
  color: ${({ theme }) => theme.colors.editor.text};
  width: 100%;
`;

export const ContextButton = styled(TextButton)`
  background-color: ${p => p.theme.colors.background.tertiary};
  color: ${p => p.theme.colors.text.secondary};
  border-radius: 4px;
  width: auto;
`;

export const ContextButtons = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  gap: 4px;
  z-index: 1;
`;

export const Context = styled.div`
  position: relative;

  /* This helps to let the mouse keep the hover state under the context content box */
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 295px;
    height: 40px;
    z-index: 0;
  }

  &:hover {
    ${ContextButtons} {
      display: flex;
    }
  }
`;

export const ContextContent = styled.blockquote`
  ${body400}
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  border-left: 3px solid ${p => p.theme.userColors.bg};
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 0 11px;
  overflow: hidden;
`;

export const ParentButton = styled(TextButton)`
  ${body500}
  width: auto;
  max-width: 100%;
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg) scaleY(-1);
    color: ${p => p.theme.colors.text.disabled};
    flex: 0 0 16px;
  }

  &:hover svg {
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const ParentTitle = styled.span`
  ${truncate}
  margin-left: 4px;
`;

export const Footer = styled.footer`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  min-height: 20px;
`;

export const TitleSkeleton = styled(Skeleton)`
  height: 12px;
  width: 100%;
`;
