import { DocBaseFragment, DocDocSourceIdFragment } from '@cycle-app/graphql-codegen';
import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';

type DocContextDocument = DocBaseFragment & DocDocSourceIdFragment;

const DocContext = createContext<DocContextDocument>({} as DocContextDocument);

DocContext.displayName = 'DocContext';

export const DocProvider = DocContext.Provider;

export const useDocContext = <T extends unknown = DocContextDocument>(selector?: ContextSelector<DocContextDocument, T>) => {
  const isWrappedWithContext = useHasParentContext(DocContext);
  if (!isWrappedWithContext) throw new Error('useDocContext must be used within a DocProvider');
  return useContextSelector(DocContext, selector ?? (ctx => ctx as T));
};
