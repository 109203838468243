import { useQuery } from '@apollo/client';
import { ProductNotificationsDocument, ProductNotificationsFragment } from '@cycle-app/graphql-codegen';
import { InfiniteScroll } from '@cycle-app/ui';
import { useCallback, FC, useMemo } from 'react';

import { SCROLL_CONTAINER_ID } from 'src/constants/notifications.constants';
import useProductMutations from 'src/hooks/api/mutations/useProductMutations';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useNotifications } from 'src/reactives/notifications.reactive';
import { SIZE_NOTIFICATIONS_PAGE } from 'src/utils/pagination.util';

import NotificationCard from './NotificationCard/NotificationCard';
import { NotificationCardLazy } from './NotificationCardLazy';
import { StyledDropdownLayer, EmptyState } from './NotificationCenter.styles';

interface Props {
  className?: string;
  offset?: [number, number];
}
const NotificationCenter: FC<Props> = ({
  className,
  offset = [0, 20],
  children,
}) => {
  const product = useProductBase();
  const [{ isNotifCenterVisible }, setNotifications] = useNotifications();
  const { markNotificationAsRead } = useProductMutations();

  const {
    data,
    fetchMore,
    loading,
  } = useQuery<{ node?: ProductNotificationsFragment }>(ProductNotificationsDocument, {
    skip: !product?.id,
    fetchPolicy: 'cache-first',
    variables: {
      productId: product?.id as string,
      size: SIZE_NOTIFICATIONS_PAGE,
      cursor: '',
    },
  });

  const pageInfo = useMemo(() => data?.node?.notifications.pageInfo, [data?.node?.notifications.pageInfo]);
  const notifications = useMemo(() => data?.node?.notifications, [data?.node?.notifications]);

  const markNotifsAsRead = useCallback(async () => {
    if (product?.id && notifications) {
      await markNotificationAsRead(product.id, notifications);
    }
  }, [markNotificationAsRead, product?.id, notifications]);

  const hideNotifCenter = useCallback(async () => {
    setNotifications({ isNotifCenterVisible: false });
    if ((data?.node?.notificationsNotRead ?? 0) > 0) {
      await markNotifsAsRead();
    }
  }, [markNotifsAsRead, data?.node?.notificationsNotRead, setNotifications]);

  const loadMore = useCallback(() => {
    if (!pageInfo?.hasNextPage || !pageInfo.endCursor) return null;
    return fetchMore({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
  }, [fetchMore, pageInfo]);

  return (
    <StyledDropdownLayer
      offset={offset}
      placement="right-start"
      visible={isNotifCenterVisible}
      hide={hideNotifCenter}
      popperOptions={{
        modifiers: [{
          name: 'preventOverflow',
          options: {
            mainAxis: false,
          },
        }],
      }}
      content={(
        <InfiniteScroll
          className={className}
          id={SCROLL_CONTAINER_ID}
          isLoading={loading}
          hasMoreData={pageInfo?.hasNextPage ?? false}
          loadMore={loadMore}
          rootMargin="300px"
        >
          {notifications?.edges?.length === 0 && (
            <EmptyState>You have no notifications yet</EmptyState>
          )}
          {notifications?.edges?.map(({ node: notification }) => (
            <NotificationCardLazy key={notification.id}>
              <NotificationCard
                notification={notification}
                onClickLink={markNotifsAsRead}
              />
            </NotificationCardLazy>
          ))}
        </InfiniteScroll>
      )}
    >
      {children}
    </StyledDropdownLayer>
  );
};

export default NotificationCenter;
