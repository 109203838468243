import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useState, useEffect, useRef, FC } from 'react';

import { useCompanyProfileModal, resetCompanyProfileModal } from 'src/reactives';
import { Layer } from 'src/types/layers.types';

import { CompanyProfile } from './CompanyProfile';
import { Header, StyledPortalModal } from './CustomerProfileModal.styles';

type CompanyProfileModalProps = {
  layer?: Layer;
};

export const CompanyProfileModal: FC<CompanyProfileModalProps> = ({ layer = Layer.Modal }) => {
  const [{
    companyId, isOpen,
  }] = useCompanyProfileModal();

  const modalContentRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // When we want to use some refs inside a portal, we need to add a mounted flag, unless the refs will be null.
    setIsMounted(true);
  }, []);

  if (!isOpen) return null;

  return (
    <StyledPortalModal layer={layer} contentRef={modalContentRef} hide={resetCompanyProfileModal}>
      <Header>
        <ActionButton tooltipPlacement="top" onClick={resetCompanyProfileModal}><CloseIcon /></ActionButton>
      </Header>
      {isMounted && companyId && (
        <CompanyProfile
          companyId={companyId}
          isHeaderCentered
          hideShareLink
          scrollableElement={modalContentRef.current}
        />
      )}
    </StyledPortalModal>
  );
};
