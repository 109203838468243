import {
  GithubAssigneeFragment,
  GithubRepositoryFragment,
  IntegrationFullFragment,
  IntegrationType,
  LinearAssigneeBaseFragment,
  LinearStatusBaseFragment,
  LinearTeamBaseFragment,
} from '@cycle-app/graphql-codegen';
import {
  ChromeIcon,
  FigmaIcon,
  GithubIcon,
  IntercomIcon,
  LinearIcon,
  NotionIcon,
  SlackIcon,
  ZendeskIcon,
  GitLabIcon,
  HubSpotIcon,
  JiraIcon,
  ShortcutAppIcon,
  ZapierIcon,
  EmailIcon,
  CycleIcon,
} from '@cycle-app/ui/icons';
import { ReactRenderer } from '@tiptap/react';
import { SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion';
import { ComponentType, ReactChild } from 'react';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import { CommandKey } from 'src/constants/editor.constants';
import {
  Integration,
  FrontEndIntegration,
  IntegrationMentionRendererProps,
} from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

const GITHUB_LINK_PREFIX = 'https://github.com/';

export const parseGithubIssueLink = (link: string) => {
  if (!link.startsWith(GITHUB_LINK_PREFIX)) throw new Error('Not valid Github issue link');

  const [owner, repository,,publicId] = link.replace(GITHUB_LINK_PREFIX, '').split('/');

  return {
    owner,
    repository,
    publicId,
  };
};

export type ParseGithubProjectLinkReturn = {
  owner: string;
  projectId: number;
};
/**
 * Github project link is structured as follow:
 * https://github.com/orgs/cycle-app/projects/7
 */
export const parseGithubProjectLink = (link: string): ParseGithubProjectLinkReturn | null => {
  const githubProjectLinkPrefix = `${GITHUB_LINK_PREFIX}orgs/`;

  if (!link.startsWith(githubProjectLinkPrefix)) return null;

  const [owner,,projectId] = link.replace(githubProjectLinkPrefix, '').split('/');

  if (!owner || Number.isNaN(projectId)) return null;

  return {
    owner,
    projectId: Number(projectId),
  };
};

export const integrationNameTitles: Record<IntegrationType, string> = {
  [IntegrationType.Github]: 'Github Issues',
  [IntegrationType.Linear]: 'Linear',
  [IntegrationType.Notion]: 'Notion',
  [IntegrationType.Slack]: 'Slack',
  [IntegrationType.Figma]: 'Figma',
  [IntegrationType.Intercom]: 'Intercom',
  [IntegrationType.Mail]: 'Mail',
  [IntegrationType.Hubspot]: 'Hubspot',
};

export type IntegrationsData = {
  icon?: ReactChild;
  title?: string;
  label: string;
  labelOne?: string;
  description?: string;
  order: number;
  commandKey?: CommandKey;
  action?: string;
  remove?: {
    title: string;
    text: string | JSX.Element;
  };
};
export const integrationsData: Record<Integration, IntegrationsData> = {
  [IntegrationType.Github]: {
    icon: <GithubIcon />,
    title: 'GitHub',
    label: 'GitHub issues',
    labelOne: 'GitHub issue',
    description: 'Link projects and issues',
    order: 1,
    commandKey: 'GITHUB_ISSUE',
    remove: {
      title: 'Remove Github integration',
      text: (
        <>
          Are you sure you want to remove Github integration?
          <br />
          You will no longer access Github issues.
        </>
      ),
    },
  },
  [IntegrationType.Slack]: {
    icon: <SlackIcon />,
    label: 'Slack',
    description: 'Create docs and view notifications',
    order: 2,
    remove: {
      title: 'Remove Slack integration',
      text: (
        <>
          Are you sure you want to remove Slack integration?
          <br />
          You will no longer be able to create docs and view notifications from Slack.
        </>
      ),
    },
  },
  [IntegrationType.Linear]: {
    icon: <LinearIcon />,
    title: 'Linear',
    label: 'Linear issues',
    labelOne: 'Linear issue',
    description: 'Link projects and issues',
    order: 3,
    commandKey: 'LINEAR',
    remove: {
      title: 'Remove Linear integration',
      text: (
        <>
          Are you sure you want to remove Linear integration?
          <br />
          You will no longer access Linear issues.
        </>
      ),
    },
  },
  [IntegrationType.Notion]: {
    icon: <NotionIcon />,
    title: 'Notion',
    label: 'Notion',
    labelOne: 'Notion page',
    description: 'Link, preview, and embed pages',
    order: 4,
    commandKey: 'NOTION',
    remove: {
      title: 'Remove Notion integration',
      text: (
        <>
          Are you sure you want to remove Notion integration?
          <br />
          You will no longer access Notion pages.
        </>
      ),
    },
  },
  [IntegrationType.Figma]: {
    icon: <FigmaIcon />,
    label: 'Figma',
    description: 'Link, preview, and embed pages',
    order: 5,
  },
  [IntegrationType.Intercom]: {
    icon: <IntercomIcon />,
    label: 'Intercom',
    description: 'Sync customers and create docs',
    order: 6,
  },
  [IntegrationType.Mail]: {
    icon: <ChromeIcon />,
    label: 'Mail Integration',
    description: 'Create docs from you email inbox',
    order: 9,
  },
  [FrontEndIntegration.ZENDESK]: {
    icon: <ZendeskIcon />,
    label: 'Zendesk',
    description: 'Sync customers and create docs',
    order: 7,
  },
  [FrontEndIntegration.CHROME]: {
    icon: <ChromeIcon />,
    label: 'Chrome Extension',
    description: 'Create docs on the fly from any web app',
    order: 8,
  },
  [IntegrationType.Mail]: {
    icon: <EmailIcon />,
    label: 'Email',
    action: 'Click to copy email',
    order: 9,
  },
  [FrontEndIntegration.ZAPIER]: {
    icon: <ZapierIcon />,
    label: 'Zapier',
    order: 10,
  },
  [FrontEndIntegration.HUBSPOT]: {
    icon: <HubSpotIcon />,
    label: 'HubSpot',
    order: 11,
  },
  [FrontEndIntegration.JIRA]: {
    icon: <JiraIcon />,
    label: 'Jira',
    order: 12,
  },
  [FrontEndIntegration.GITLAB]: {
    icon: <GitLabIcon />,
    label: 'GitLab',
    order: 13,
  },
  [FrontEndIntegration.SHORTCUT]: {
    icon: <ShortcutAppIcon />,
    label: 'Shortcut',
    order: 14,
  },
  [FrontEndIntegration.CYCLE]: {
    icon: <CycleIcon />,
    label: 'Cycle',
    order: 15,
  },
};

export const isGithubRepository = (
  repo: GithubRepositoryFragment | null | undefined,
): repo is GithubRepositoryFragment & { name: string } => !!repo?.name;

export const isGithubAssignee = (
  assignee: GithubAssigneeFragment | null | undefined,
): assignee is GithubAssigneeFragment => !!assignee;

export const isGithubLabel = (
  label: string | null | undefined,
): label is string => !!label;

export const isLinearTeam = (
  team?: LinearTeamBaseFragment | null,
): team is LinearTeamBaseFragment & { name: string } => !!team?.name;

export const isLinearStatus = (
  status?: LinearStatusBaseFragment | null,
): status is LinearStatusBaseFragment & { name: string } => !!status?.name;

export const isLinearAssignee = (
  assignee?: LinearAssigneeBaseFragment | null,
): assignee is LinearAssigneeBaseFragment & { name: string } => !!assignee?.name;

export const byIntegrationOrder = (integrationA:Integration, integrationB:Integration) => (
  integrationsData[integrationA].order - integrationsData[integrationB].order);

export const renderIntegrationMentionSuggestion = (extensionName: string, renderer: ComponentType<IntegrationMentionRendererProps>) => {
  let reactRenderer: ReactRenderer;
  let popup: TippyInstance<TippyProps>[];

  return {
    onStart: (props: SuggestionProps) => {
      reactRenderer = new ReactRenderer(renderer, {
        props: {
          ...props,
          extensionName,
        },
        editor: props.editor,
      });

      popup = getTippyPopup({
        props,
        reactRenderer,
        layer: Layer.DropdownZ1,
        options: {
          hideOnClick: false,
        },
      });
    },
    onUpdate(props: SuggestionProps) {
      reactRenderer.updateProps(props);
      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      });
    },
    onKeyDown({ event }: SuggestionKeyDownProps) {
      return onKeyDown({
        event,
        popupInstance: popup[0],
      });
    },
    onExit() {
      popup[0].destroy();
      reactRenderer.destroy();
    },
  };
};

type IntegrationItemProps = {
  integration?: IntegrationFullFragment;
  integrationType: Integration;
};

type IntegrationItemsByStatus = {
  installed: IntegrationItemProps[];
  uninstalled: IntegrationItemProps[];
};

export type IntegrationsByType = Record<Integration, IntegrationFullFragment>;

export const getIntegrationsByInstallStatus = (
  integrationTypes: Integration[],
  integrationsByType: IntegrationsByType,
) => integrationTypes.reduce<IntegrationItemsByStatus>((acc, integrationType) => {
  const integration = integrationsByType[integrationType];
  const isIntegrationInstalled = !!integration?.provider ||
    integrationType === FrontEndIntegration.ZAPIER ||
    integrationType === FrontEndIntegration.CHROME;
  acc[isIntegrationInstalled ? 'installed' : 'uninstalled'].push({
    integrationType,
    integration,
  });
  return acc;
}, {
  installed: [],
  uninstalled: [],
});
