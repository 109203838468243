import { Button } from '@cycle-app/ui';
import { InfoIcon } from '@cycle-app/ui/icons';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionListItem = styled.li`
  margin-top: 16px;
`;

export const SectionName = styled.div`
  color: ${p => p.theme.colors.text.secondary};
`;

export const BoardListItem = styled.li`
  margin-top: 8px;
`;

export const BoardInfo = styled.div`
  height: 12px;
  width: 12px;
  display: none;
  align-items: center;
  justify-content: center;
`;

export const BoardItem = styled(Button)<{ $isActive?: boolean }>`
  gap: 40px;
  padding-left: 8px;
  padding-right: 8px;

  ${p => p.$isActive && css`
    ${BoardInfo} {
      display: flex;
    }
  `}
  ${p => !p.$isActive && css`
    --bg: transparent;
  `}
`;

export const BoardName = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${p => transparentize(0.15, p.theme.colors.sidebar.activeItem.color)};
  height: 100%;
  width: 100%;
`;

export const BoardSkeletonContainer = styled.div`
  display: grid;
  gap: 8px;
`;
