import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const AddMemberOutlineIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.57336 3.5C4.57336 2.29188 5.55274 1.3125 6.76086 1.3125C7.96899 1.3125 8.94836 2.29188 8.94836 3.5C8.94836 4.70812 7.96899 5.6875 6.76086 5.6875C5.55274 5.6875 4.57336 4.70812 4.57336 3.5ZM6.76086 0C4.82787 0 3.26086 1.567 3.26086 3.5C3.26086 5.433 4.82787 7 6.76086 7C8.69386 7 10.2609 5.433 10.2609 3.5C10.2609 1.567 8.69386 0 6.76086 0ZM1.63005 11.8803C2.2744 10.7922 3.70183 9.40625 6.97963 9.40625C7.34207 9.40625 7.63588 9.11244 7.63588 8.75C7.63588 8.38756 7.34207 8.09375 6.97963 8.09375C3.23879 8.09375 1.38168 9.72379 0.500694 11.2116C0.0956913 11.8956 0.187104 12.6329 0.595809 13.1736C0.986624 13.6906 1.6389 14 2.34172 14H6.97963C7.34207 14 7.63588 13.7062 7.63588 13.3438C7.63588 12.9813 7.34207 12.6875 6.97963 12.6875H2.34172C2.01248 12.6875 1.76367 12.542 1.64286 12.3822C1.53995 12.246 1.50921 12.0844 1.63005 11.8803ZM11.1359 8.75C11.4983 8.75 11.7921 9.04381 11.7921 9.40625V10.7188H13.1046C13.4671 10.7188 13.7609 11.0126 13.7609 11.375C13.7609 11.7374 13.4671 12.0312 13.1046 12.0312H11.7921V13.3438C11.7921 13.7062 11.4983 14 11.1359 14C10.7734 14 10.4796 13.7062 10.4796 13.3438V12.0312H9.16711C8.80468 12.0312 8.51086 11.7374 8.51086 11.375C8.51086 11.0126 8.80468 10.7188 9.16711 10.7188H10.4796V9.40625C10.4796 9.04381 10.7734 8.75 11.1359 8.75Z" />
  </Svg>
);
