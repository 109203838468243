import { MateFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { AddMemberIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode, useCallback } from 'react';
import { Placement } from 'tippy.js';

import DocAction from 'src/components/DocAction/DocAction';
import { DocAssigneesDropdown } from 'src/components/DocAssigneesDropdown/DocAssigneesDropdown';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useRemoveDocAssignee, useUpdateDocAssignee } from 'src/hooks/api/mutations/updateDocHooks';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';

import { StyledAvatar, Container, ActionButtonCursor } from './DocAssignee.styles';

interface DocAssigneeProps {
  layer?: Layer;
  docId?: string;
  assignee?: MateFragment | null;
  showAssigneeName?: boolean;
  tooltipPlacement?: Placement;
  size?: 'S' | 'M' | 'L';
  showLabel?: boolean;
  onSelect?: (user: MateFragment | null) => void;
  onClear?: VoidFunction;
  buttonIcon?: ReactNode;
  isDisabled?: boolean;
  isRemovable?: boolean;
  showWarnings?: boolean;
  dropdownPlacement?: Placement;
}

const DocAssignee: FC<DocAssigneeProps> = ({
  layer = Layer.Dropdown,
  docId,
  assignee,
  tooltipPlacement = 'top',
  showAssigneeName,
  size = 'M',
  showLabel,
  onSelect,
  onClear,
  buttonIcon = <AddMemberIcon />,
  isDisabled = false,
  isRemovable = true,
  showWarnings,
  dropdownPlacement,
}) => {
  const users = useBoardConfig(ctx => ctx.usersForAssignee);
  const assigneeIsRequired = useBoardConfig(ctx => ctx.assigneeIsRequired);

  const [isDropdownVisible, {
    setValueCallback: setIsDropdownVisible,
    setFalseCallback: onHideDropdown,
  }] = useOptimizedBooleanState(false);

  const { removeDocAssignee } = useRemoveDocAssignee();
  const { updateDocAssignee } = useUpdateDocAssignee();

  const onClearValue = useCallback(async () => {
    onHideDropdown();
    onClear?.();
    if (docId) {
      await removeDocAssignee({ docId }, assigneeIsRequired);
    }
  }, [removeDocAssignee, docId, onHideDropdown, assigneeIsRequired, onClear]);

  const onSelectUser = useCallback(
    async (user: MateFragment | null) => {
      const selectedUser = users.find(u => u.id === user?.id);
      if (selectedUser) {
        onSelect?.(selectedUser);
      }
      onHideDropdown();
      if (docId) {
        if (user) {
          await updateDocAssignee(
            {
              docId,
              userId: user.id,
            },
            !selectedUser?._compatibleWithBoardConfig,
          );
        } else {
          await removeDocAssignee({ docId }, assigneeIsRequired);
        }
      }
    },
    [
      users,
      docId,
      onHideDropdown,
      onSelect,
      updateDocAssignee,
      removeDocAssignee,
      assigneeIsRequired,
    ],
  );

  if (isDisabled) {
    return (
      <AssigneeContent
        assignee={assignee}
        buttonIcon={buttonIcon}
        forceFocus={isDropdownVisible}
        isDisabled={isDisabled}
        showAssigneeName={showAssigneeName}
        showLabel={showLabel}
        size={size}
      />
    );
  }

  return (
    <DocAssigneesDropdown
      layer={layer}
      isVisible={isDropdownVisible}
      onShow={() => setIsDropdownVisible(true)}
      onHide={onHideDropdown}
      onChange={onSelectUser}
      selectedId={assignee?.id}
      onClear={onClearValue}
      hasWarningOnNoneValue={assigneeIsRequired}
      isRemovable={isRemovable}
      showWarnings={showWarnings}
      dropdownPlacement={dropdownPlacement}
    >
      <AssigneeContent
        assignee={assignee}
        buttonIcon={buttonIcon}
        forceFocus={isDropdownVisible}
        isDisabled={isDisabled}
        showAssigneeName={showAssigneeName}
        showLabel={showLabel}
        size={size}
        tooltipPlacement={tooltipPlacement}
      />
    </DocAssigneesDropdown>
  );
};

export default DocAssignee;

type AssigneeContentProps = {
  assignee: DocAssigneeProps['assignee'];
  buttonIcon?: DocAssigneeProps['buttonIcon'];
  forceFocus?: boolean;
  isDisabled?: DocAssigneeProps['isDisabled'];
  showAssigneeName: DocAssigneeProps['showAssigneeName'];
  showLabel?: DocAssigneeProps['showLabel'];
  size?: DocAssigneeProps['size'];
  tooltipPlacement?: DocAssigneeProps['tooltipPlacement'];
};

export const AssigneeContent: FC<AssigneeContentProps> = ({
  assignee,
  buttonIcon = <AddMemberIcon />,
  forceFocus = false,
  isDisabled = false,
  showAssigneeName = false,
  showLabel = false,
  size = 'M',
  tooltipPlacement,
}) => {
  const content = assignee
    ? (
      <Container
        showAssigneeName={showAssigneeName}
        forceFocus={forceFocus}
        $isDisabled={isDisabled}
      >
        <StyledAvatar
          user={assignee}
          size={20}
          pointer={!isDisabled}
        />
        {showAssigneeName && (
          <p>{`${assignee.firstName} ${assignee.lastName}`}</p>
        )}
      </Container>
    )
    : (
      <ActionButtonCursor $showAssigneeName={showAssigneeName} $isDisabled={isDisabled}>
        <DocAction
          forceFocus={forceFocus}
          buttonIcon={buttonIcon}
          size={size}
          disabled={isDisabled}
        >
          {showLabel && (isDisabled ? 'No assignee' : 'Add assignee')}
        </DocAction>
      </ActionButtonCursor>
    );

  if (!tooltipPlacement) {
    return content;
  }

  return (
    <Tooltip
      title={assignee ? `${assignee.firstName} ${assignee.lastName}` : undefined}
      content={!isDisabled && `${assignee ? 'Change' : 'Add'} assignee`}
      placement={tooltipPlacement}
      withPortal
      disabled={size === 'L' && !assignee}
    >
      {content}
    </Tooltip>
  );
};
