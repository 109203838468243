import { EmojiPicker, typo, ActionButton, TextButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TitleButton = styled(TextButton)`
  --bgHover: transparent;
  --bgActive: transparent;
  padding: 0;
  h1 {
    ${typo.headerLight}
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const EmojiPickerStyled = styled(EmojiPicker)`
  border-radius: 4px;
  padding: 4px;
  :hover, :focus {
    background: ${p => p.theme.colors.actionButton.bgHover};
  }
  :active {
    background: ${p => p.theme.colors.actionButton.bgActive};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Action = styled(ActionButton)`
  --colorHover: var(--color);
`;

export const StarAction = styled(Action)`
  color: ${p => p.theme.colors.text.yellow};
`;
