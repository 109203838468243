import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LinkIcon: FC<SvgProps> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.76683 6.37536L6.36761 8.77608C6.31139 8.83187 6.26677 8.89825 6.23631 8.97139C6.20586 9.04453 6.19019 9.12297 6.19019 9.20221C6.19019 9.28144 6.20586 9.35988 6.23631 9.43302C6.26677 9.50616 6.31139 9.57254 6.36761 9.62833C6.42337 9.68459 6.48971 9.72924 6.5628 9.75971C6.63589 9.79018 6.71429 9.80587 6.79347 9.80587C6.87265 9.80587 6.95105 9.79018 7.02414 9.75971C7.09723 9.72924 7.16357 9.68459 7.21933 9.62833L9.61856 7.22761C9.7315 7.1146 9.79496 6.96132 9.79496 6.80149C9.79496 6.64166 9.7315 6.48838 9.61856 6.37536C9.50561 6.26234 9.35242 6.19885 9.1927 6.19885C9.03297 6.19885 8.87978 6.26234 8.76683 6.37536Z" />
    <path d="M8.16105 11.2428L7.3933 12.005C6.96229 12.4494 6.38289 12.7195 5.7656 12.7638C5.1483 12.8081 4.53632 12.6234 4.04638 12.2451C3.78742 12.0316 3.57611 11.7661 3.42608 11.4657C3.27605 11.1654 3.19065 10.8369 3.17539 10.5015C3.16013 10.1661 3.21535 9.83126 3.33748 9.51853C3.45961 9.20581 3.64594 8.9222 3.88443 8.68602L4.73615 7.82776C4.79237 7.77197 4.837 7.70559 4.86745 7.63245C4.8979 7.55931 4.91358 7.48086 4.91358 7.40163C4.91358 7.3224 4.8979 7.24396 4.86745 7.17082C4.837 7.09768 4.79237 7.0313 4.73615 6.97551C4.68039 6.91925 4.61406 6.8746 4.54096 6.84413C4.46787 6.81366 4.38947 6.79797 4.31029 6.79797C4.23111 6.79797 4.15271 6.81366 4.07962 6.84413C4.00653 6.8746 3.94019 6.91925 3.88443 6.97551L3.12268 7.74374C2.48098 8.36552 2.08656 9.19918 2.01265 10.0899C1.93875 10.9807 2.19037 11.868 2.72081 12.5872C3.03563 12.9959 3.43376 13.3328 3.88874 13.5757C4.34372 13.8186 4.84515 13.9618 5.3597 13.9959C5.87426 14.0299 6.39016 13.954 6.87316 13.7733C7.35615 13.5925 7.79517 13.3109 8.16105 12.9473L9.01277 12.095C9.12572 11.982 9.18917 11.8287 9.18917 11.6689C9.18917 11.5091 9.12572 11.3558 9.01277 11.2428C8.89983 11.1298 8.74664 11.0663 8.58691 11.0663C8.42718 11.0663 8.27399 11.1298 8.16105 11.2428Z" />
    <path d="M12.5876 2.73222C11.864 2.19593 10.9699 1.94159 10.0726 2.0167C9.17517 2.0918 8.3358 2.49122 7.71121 3.14034L7.06342 3.80054C6.98938 3.85439 6.92747 3.92321 6.88169 4.00253C6.83591 4.08185 6.80729 4.1699 6.79768 4.26099C6.78807 4.35208 6.79768 4.44417 6.82589 4.53131C6.8541 4.61844 6.90029 4.69868 6.96146 4.76682C7.01721 4.82308 7.08355 4.86773 7.15665 4.8982C7.22974 4.92867 7.30814 4.94436 7.38732 4.94436C7.4665 4.94436 7.5449 4.92867 7.61799 4.8982C7.69108 4.86773 7.75742 4.82308 7.81318 4.76682L8.59293 3.98059C9.02155 3.53425 9.60027 3.26268 10.2173 3.21834C10.8343 3.174 11.4459 3.36004 11.9338 3.74052C12.1947 3.95406 12.4077 4.22021 12.5589 4.52163C12.7102 4.82305 12.7962 5.15296 12.8115 5.48988C12.8268 5.82679 12.7709 6.16315 12.6476 6.47703C12.5243 6.79091 12.3363 7.07528 12.0958 7.31159L11.2441 8.16984C11.1879 8.22564 11.1432 8.29202 11.1128 8.36515C11.0823 8.43829 11.0667 8.51674 11.0667 8.59597C11.0667 8.6752 11.0823 8.75365 11.1128 8.82678C11.1432 8.89992 11.1879 8.9663 11.2441 9.0221C11.2998 9.07835 11.3662 9.123 11.4393 9.15347C11.5124 9.18394 11.5908 9.19963 11.6699 9.19963C11.7491 9.19963 11.8275 9.18394 11.9006 9.15347C11.9737 9.123 12.04 9.07835 12.0958 9.0221L12.9475 8.16984C13.3099 7.80379 13.5904 7.36488 13.7705 6.88218C13.9506 6.39948 14.0261 5.884 13.9921 5.36989C13.958 4.85578 13.8152 4.35476 13.5731 3.90004C13.331 3.44531 12.9951 3.04725 12.5876 2.73222Z" />
  </Svg>
);
