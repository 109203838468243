import { Spinner, TooltipProps } from '@cycle-app/ui';
import {
  KeyboardEventHandler, FocusEventHandler, FocusEvent, KeyboardEvent, ChangeEventHandler, forwardRef,
} from 'react';

import useOptimizedBooleanState from '../../hooks/useOptimizedBooleanState';
import { isElementOutside } from '../../utils/elements.util';
import { FloatingPenButton } from '../FloatingPenButton/FloatingPenButton';
import {
  Container, EditButton, EditButtonContainer, InputBorder, InputName, SpinnerContainer,
} from './EditTextButton.styles';

interface Props {
  className?: string;
  disabled?: boolean;
  editTooltip?: TooltipProps['content'];
  isLoading?: boolean;
  required?: boolean;
  name?: string;
  onBlur?: FocusEventHandler;
  onCancel?: KeyboardEventHandler;
  onChange: ChangeEventHandler;
  placeholder?: string | JSX.Element;
  tooltip?: TooltipProps['content'];
  type?: string;
  value: string;
}

export const EditTextButton = forwardRef<HTMLInputElement, Props>(({
  className,
  disabled,
  editTooltip,
  isLoading,
  name,
  onChange,
  onCancel,
  onBlur,
  placeholder,
  required,
  type,
  tooltip,
  value,
}, ref) => {
  const [isEditing, {
    toggleCallback: toggleEdition, setFalseCallback: disableEdition,
  }] = useOptimizedBooleanState(false);

  return (
    <Container className={className} tabIndex={-1} onBlur={handleBlur}>
      {isEditing ? (
        <InputBorder>
          <InputName
            ref={ref}
            autoFocus
            name={name}
            onChange={onChange}
            onKeyUp={handleKeyUp}
            required={required}
            type={type}
            value={value}
          />
          {isLoading && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </InputBorder>
      ) : (
        <EditButtonContainer>
          <EditButton
            disabled={disabled}
            $isFilled={!!value}
            tooltip={tooltip}
            size="L"
            {...!value && {
              onClick: toggleEdition,
            }}
          >
            {value || placeholder}
          </EditButton>
          {!disabled && value && (
            <FloatingPenButton
              onClick={toggleEdition}
              tooltip={editTooltip}
              tooltipPlacement="top"
            />
          )}
        </EditButtonContainer>
      )}
    </Container>
  );

  function handleBlur(e: FocusEvent<HTMLDivElement>) {
    const {
      currentTarget, relatedTarget,
    } = e;
    if (isElementOutside(currentTarget, relatedTarget)) {
      disableEdition();
    }
    onBlur?.(e);
  }

  function handleKeyUp(e: KeyboardEvent<HTMLInputElement>) {
    const { key } = e;
    if (key === 'Escape') {
      e.stopPropagation();
      disableEdition();
      onCancel?.(e);
    }
  }
});
