import { useApolloClient } from '@apollo/client';
import { DoctypeNodeDocument, DoctypeFragment, DoctypeAttributeDefinitionsFragmentDoc } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

export const useGetDoctypeFromCache = () => {
  const { cache } = useApolloClient();

  return useCallback((doctypeId: string) => cache.readQuery<{ node?: DoctypeFragment }>({
    query: DoctypeNodeDocument,
    variables: {
      id: doctypeId,
    },
  })?.node, [cache]);
};

export const useGetDoctypeAttributeDefinitions = () => {
  const { cache } = useApolloClient();

  return useCallback((doctypeId: string) => cache.readFragment({
    fragment: DoctypeAttributeDefinitionsFragmentDoc,
    fragmentName: 'DoctypeAttributeDefinitions',
    id: doctypeId,
  })?.attributeDefinitions, [cache]);
};
