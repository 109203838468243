import { MouseEventHandler } from 'react';

import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  getActiveProductTourNextStep,
  setActiveProductTour,
  getActiveProductTourEl,
  useActiveProductTour,
} from 'src/reactives/productTours.reactive';
import { TourName, TourStep } from 'src/types/productTour.types';

export const useDocItemProductTour = () => {
  const tour = useActiveProductTour();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);

  const isActive = isEnabled && tour?.name === TourName.FIRST_TOUR;

  const setClickFeedbackEl = (el?: HTMLElement, index?: number) => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.OpenFirstFeedback ||
      !el ||
      getActiveProductTourEl() === el ||
      index !== 0 // We want to target only the first doc item of the group
    ) return;

    setActiveProductTour({ el });
  };

  /**
   * Should move to step HighlightFeedbackText from OpenFirstFeedback
   */
  const setNextStep: MouseEventHandler<HTMLDivElement> = () => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step === TourStep.HighlightFeedbackText
    ) return;

    const nextStep = getActiveProductTourNextStep();

    if (!nextStep) return;

    setActiveProductTour({ step: nextStep });
  };

  return {
    isActive,
    setClickFeedbackEl,
    setNextStep,
  };
};
