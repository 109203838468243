import { ThemeType } from '@cycle-app/graphql-codegen';

import {
  colors as eclipseColors,
  sidebarColors as eclipseSidebarColors,
  nuance as eclipseNuance,
} from './eclipse';
import {
  colors as nightfallColors,
  sidebarColors as nightfallSidebarColors,
  nuance as nightfallNuance,
} from './nightfall';
import {
  colors as sunriseColors,
  sidebarColors as sunriseSidebarColors,
} from './sunrise';

export const COLOR_THEMES = {
  [ThemeType.Eclipse]: eclipseColors,
  [ThemeType.Nightfall]: nightfallColors,
  [ThemeType.Sunrise]: sunriseColors,
};

export const SIDEBAR_COLOR_THEMES = {
  [ThemeType.Eclipse]: eclipseSidebarColors,
  [ThemeType.Nightfall]: nightfallSidebarColors,
  [ThemeType.Sunrise]: sunriseSidebarColors,
};

export const COLOR_NUANCES = {
  [ThemeType.Eclipse]: eclipseNuance,
  [ThemeType.Nightfall]: nightfallNuance,
  [ThemeType.Sunrise]: eclipseNuance,
};
