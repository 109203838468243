import { useQuery } from '@apollo/client';
import {
  DoctypeRelativeWithTemplateAndAttributeDefinitionsFragment,
  ProductDoctypesDocument,
  ProductDoctypesWithHierarchyFragment,
  ProductDoctypesQueryVariables,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { findFeedback } from 'src/utils/docType.util';

import { useProduct, useProductBase } from './useProduct';

/**
 * If you don't need doctypes hierarchy (children/parents) or attributeDefinitions
 * just use doctypes from product (useProduct hook)
 */
export const useProductDoctypesFull = () => {
  const product = useProductBase();
  const {
    data, loading,
  } = useQuery<{ node?: ProductDoctypesWithHierarchyFragment }, ProductDoctypesQueryVariables>(ProductDoctypesDocument, {
    skip: !product?.id,
    variables: {
      productId: product?.id ?? '',
    },
  });

  const doctypes = useMemo(() => nodeToArray(data?.node?.doctypes), [data?.node?.doctypes]);

  return {
    productId: product?.id,
    doctypesConnection: data?.node?.doctypes,
    doctypes,
    isLoading: loading,
  };
};

export const useProductDoctypes = (): DoctypeRelativeWithTemplateAndAttributeDefinitionsFragment[] => {
  const { product } = useProduct();
  return nodeToArray(product?.doctypes);
};

export const useFeedbackDocType = () => {
  const docTypes = useProductDoctypes();
  return findFeedback(docTypes);
};
