import { NavigationItem, typo, ShyScrollbarCss } from '@cycle-app/ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';

export const Container = styled.div`
  background-color: ${p => p.theme.colors.background.secondary};
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  height: 100%;
  width: 220px;

  ${ShyScrollbarCss};
`;

export const SettingsNavigationItem = styled(NavigationItem)`
  padding: 4px 8px;
  line-height: 24px;
  ${p => {
    if (p.isDragging) {
      return css`
        background-color: ${p.theme.colors.settings.navigationItem.draggingBg} !important;
        color: ${p.theme.colors.settings.navigationItem.draggingColor} !important;
        border: 1px solid ${p.theme.colors.settings.navigationItem.draggingBorderColor};
      `;
    }
    return null;
  }}
`;

export const SettingsNavigationItemMenu = styled(DotsMenuLayer)`
  &:hover, &.force-focus {
    background-color: ${p => p.theme.colors.settings.navigationItem.menu.bgHover};
  }
`;

export const SidebarHeader = styled.header`
  display: flex;
`;

export const Title = styled.h1`
  ${typo.headerSmall}
`;

export const Back = styled(Link)`
  ${typo.headerSmall}
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  font-weight: 500;

  &:hover {
    background-color: ${p => p.theme.colors.settings.navigationItem.bgHover};
  }

  svg {
    width: 14px;
    height: 14px;
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const Logo = styled.div<{ $bgSrc?: string }>`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-image: ${p => (p.$bgSrc ? `url(${p.$bgSrc})` : 'none')};
  background-size: cover;
  background-position: center;
  transition: width .2s ease-in-out, height .2s ease-in-out;
`;

export const Separator = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  margin: 8px 0;
`;

export const SettingsNavigationSection = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: none;

  ${typo.body400};
  ${ShyScrollbarCss};
  margin-right: -${14}px;
  padding-right: ${14}px;
`;
