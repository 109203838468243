import { PortalId } from 'src/types/portal.types';

const createElement = (id: string): void => {
  const element = document.createElement('div');
  element.id = id;
  document.body.appendChild(element);
};

export const createPortals = (): void => Object.values(PortalId).forEach((portalId) => createElement(`portal-${portalId}`));

export const createApp = (): void => createElement('app');

export const isElementOutside = <T>(parent: HTMLElement, target: T) => !(target instanceof HTMLElement) || !parent.contains(target);
