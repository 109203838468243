import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { NuancePicker, SelectOption } from '@cycle-app/ui';
import { TrashIcon, ReorderIcon } from '@cycle-app/ui/icons';
import { isEnabled, Feature } from '@cycle-app/utilities';
import { FC, useMemo, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import AttributeOptionsDropdown from './AttributeOptionsDropdown';
import DoctypesRelationsManager from './DoctypesRelationsManager';
import { SettingsAttributeEditModal } from './SettingsAttributeEditModal';
import {
  GrowingCell,
  ColorCell,
  NameCell,
  NameInput,
  ReorderButton,
  ReorderCell,
  ActionsCell,
  Doctype,
  RowContainer,
  NameButton,
  ColorButton,
  TagStyled,
  AttributeTagIconContainer,
  DeleteDialogModalStyled,
} from './SettingsAttributes.styles';

const IS_EDIT_MODAL_ENABLED = isEnabled(Feature.EditAttributesModal);

export interface SettingsAttributesLineProps {
  attribute: AttributeDefinitionsNode;
  doctypesOptions: SelectOption[];
  isDragOverlay?: boolean;
  rowProps?: {
    ref: (node: HTMLElement | null) => void;
    style: CSSProperties;
    asPlaceholder: boolean;
  };
  buttonHandleProps?: Record<string, any>;
  isDeleteDisabled?: boolean;
}

const SettingsAttributesLine: FC<SettingsAttributesLineProps> = ({
  attribute,
  doctypesOptions,
  isDragOverlay = false,
  rowProps,
  buttonHandleProps,
  isDeleteDisabled = false,
}) => {
  const [name, setName] = useState(attribute.name);
  const [showWarningModal, {
    setTrueCallback: setShowWarningModal,
    setFalseCallback: setHideWarningModal,
  }] = useOptimizedBooleanState(false);
  const [isEditionModalOpened, { toggleCallback: toggleEditionModal }] = useOptimizedBooleanState(false);
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirm, setFalseCallback: setDeleteConformFalse,
  }] = useOptimizedBooleanState(false);

  const {
    removeAttribute,
    changeAttributeColor,
    changeAttributeName,
  } = useAttributesMutations();

  const debouncedOnNameChanged = useDebouncedCallback(changeAttributeName,
    INPUT_ONCHANGE_DEBOUNCE);

  const actionOptions = useMemo(() => [
    ...isDeleteDisabled
      ? []
      : [{
        value: 'delete',
        label: 'Delete',
        variant: 'danger' as const,
        icon: <TrashIcon />,
        onSelect: setShowWarningModal,
      }],
  ], [setShowWarningModal, isDeleteDisabled]);

  return (
    <RowContainer isDragOverlay={isDragOverlay} {...rowProps}>
      <ReorderCell>
        <ReorderButton {...buttonHandleProps}>
          <ReorderIcon />
        </ReorderButton>
      </ReorderCell>

      <ColorCell>
        {IS_EDIT_MODAL_ENABLED ? (
          <ColorButton variant="nospace" type="button" onClick={toggleEditionModal}>
            <NuancePicker
              color={attribute.color}
              placement="bottom-start"
              disabled
            />
          </ColorButton>
        ) : (
          <NuancePicker
            color={attribute.color}
            onClick={makeOnColorClicked()}
            placement="bottom-start"
            disabled={isDragOverlay}
          />
        )}
      </ColorCell>

      <NameCell>
        {IS_EDIT_MODAL_ENABLED ? (
          <NameButton type="button" size={14} onClick={toggleEditionModal}>{attribute.name}</NameButton>
        ) : (
          <NameInput
            value={name}
            onChange={onChangeName}
          />
        )}
      </NameCell>

      <GrowingCell>
        {attribute.__typename && (
          <Doctype>
            {getCustomAttributeTypeData(attribute.__typename).icon}
            {getCustomAttributeTypeData(attribute.__typename).label}
          </Doctype>
        )}
      </GrowingCell>

      <GrowingCell>
        {'values' in attribute && (
          <AttributeOptionsDropdown attribute={attribute} />
        )}
      </GrowingCell>

      <GrowingCell>
        <DoctypesRelationsManager
          attribute={attribute}
          doctypesOptions={doctypesOptions}
        />
      </GrowingCell>

      <ActionsCell>
        {actionOptions.length && (
          <DotsMenuLayer
            options={actionOptions}
          />
        )}
      </ActionsCell>
      {showWarningModal && (
        <DeleteDialogModalStyled
          title="Delete property"
          info={(
            <>
              Are you sure you want to delete
              <TagStyled
                limitSize={false}
                icon={<AttributeTagIconContainer>{getCustomAttributeTypeData(attribute.__typename).icon}</AttributeTagIconContainer>}
              >
                {attribute.name}
              </TagStyled>
              ? All docs with a value for this property will lose it. This can&apos;t be undone
            </>
          )}
          confirmLabel="Delete"
          confirmMessage="Yes, permanently delete this property"
          hide={() => {
            setDeleteConformFalse();
            setHideWarningModal();
          }}
          onConfirm={onDelete}
          onToggleConfirmation={toggleDeleteConfirm}
          isConfirmDisabled={!isDeleteConfirmed}
        />
      )}
      {isEditionModalOpened && (
        <SettingsAttributeEditModal attribute={attribute} onHide={toggleEditionModal} />
      )}
    </RowContainer>
  );

  async function onChangeName(value: string) {
    setName(value);
    if (value) {
      await debouncedOnNameChanged(attribute, value);
    }
  }

  async function onDelete() {
    await removeAttribute(attribute.id);
  }

  function makeOnColorClicked() {
    if (isDragOverlay) {
      return () => {
      // Intentional empty function
      };
    }
    return (colorPicked: ColorApi) => changeAttributeColor(attribute, colorPicked);
  }
};

export default SettingsAttributesLine;
