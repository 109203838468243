/* eslint-disable jsx-a11y/label-has-associated-control */
import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { Header, Title, Actions, CloseButtonStyled } from 'src/components/DialogModal/DialogModal.styles';
import { InboxBoardConfigFields } from 'src/components/InboxBoardConfigFields/InboxBoardConfigFields';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';

import { Modal } from './InboxEditView.styles';
import { InboxEditViewForm } from './InboxEditViewForm';

export type InboxEditViewModalProps = {
  onHide: VoidFunction;
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
  isLoadingDraftBoardConfig: boolean;
};

export const InboxEditViewModal = ({
  onHide, board, draftBoardConfigId, isLoadingDraftBoardConfig,
}: InboxEditViewModalProps) => {
  const {
    revertUnsavedDraftConfig,
    loading,
  } = useDraftBoardConfigMutations(draftBoardConfigId, board.id);

  const revert = async () => {
    if (isLoadingDraftBoardConfig || loading.publish || loading.saveDraft) return;
    await revertUnsavedDraftConfig();
    onHide();
  };

  return (
    <Modal hide={revert}>
      <Header>
        <Title>Edit view</Title>
        <CloseButtonStyled size="L" onClick={revert}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <InboxEditViewForm
        board={board}
        onDone={onHide}
        draftBoardConfigId={draftBoardConfigId}
      >
        {(isLoading) => (
          <>
            <InboxBoardConfigFields
              boardId={board.id}
            />

            <Actions>
              <Button
                size="M"
                variant="secondary"
                onClick={revert}
                isLoading={loading.revertUnsaved}
              >
                Cancel
              </Button>

              <Button
                size="M"
                type="submit"
                isLoading={isLoading || loading.saveDraft || loading.publish}
              >
                Save
              </Button>
            </Actions>
          </>
        )}
      </InboxEditViewForm>
    </Modal>
  );
};
