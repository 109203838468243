import { FC, ReactNode } from 'react';

import { CloseIcon } from '../../icons';
import { ActionButton } from '../Buttons';
import { Spinner } from '../Spinner/Spinner.styles';
import {
  Toast,
  SimpleToast,
  Content,
  Message,
  Title,
  Actions,
  ActionBtn,
} from './ErrorToaster.styles';

export interface ErrorToasterProps extends ErrorToasterConfig {
  className?: string;
  onClose?: VoidFunction;
}

export interface ErrorToasterConfig {
  title?: string;
  message: ReactNode;
  isLoading?: boolean;
  duration?: number;
  actions?: ErrorToasterAction[];
  disabled?: boolean;
  closable?: boolean;
}

interface ErrorToasterAction {
  label: string;
  onClick: VoidFunction;
  loading?: boolean;
  strong?: boolean;
}

export const ErrorToaster: FC<ErrorToasterProps> = ({
  className,
  title,
  isLoading = false,
  message,
  onClose,
  actions,
  disabled = false,
}) => {
  if (!actions && !title) {
    return (
      <SimpleToast className={className}>
        {isLoading && <Spinner />}
        {message}
        {onClose && (
          <ActionButton onClick={onClose} size="S">
            <CloseIcon />
          </ActionButton>
        )}
      </SimpleToast>
    );
  }

  return (
    <Toast className={className}>
      <Content>
        {title && <Title>{title}</Title>}
        <Message>{message}</Message>
      </Content>

      {actions && (
        <Actions $nbActions={actions.length}>
          {actions.map((action) => (
            <ActionBtn
              key={action.label}
              onClick={action.onClick}
              $strong={action.strong}
              $loading={action.loading}
              disabled={disabled}
            >
              {action.loading
                ? (<Spinner />)
                : action.label}
            </ActionBtn>
          ))}
        </Actions>
      )}
    </Toast>
  );
};
