import { ViewType, DocBaseWithDocSourceFragment, Property } from '@cycle-app/graphql-codegen';
import { Group, ViewCardSkeleton, InfiniteScroll } from '@cycle-app/ui';
import { DownArrowIcon } from '@cycle-app/ui/icons';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import memoize from 'fast-memoize';
import {
  forwardRef, HTMLAttributes, memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { DocItem, DocItemHandle } from 'src/components/DocItem';
import { BOARD_CONTENT_ID } from 'src/constants/board.constant';
import { MESSAGE_CREATE_DOC_DISABLED_HAS_NEXT_PAGE, MESSAGE_CREATE_DOC_DISABLED_BOARD_CONFIG } from 'src/constants/boardGroups.constants';
import { BuiltInDisplay, useBoardConfig } from 'src/contexts/boardConfigContext';
import { DocProvider } from 'src/contexts/docContext';
import { useGetGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import useManageHiddenGroups from 'src/hooks/api/mutations/boardConfig/useManageGroupby';
import { BoardGroup as BoardGroupType } from 'src/hooks/api/useBoardGroups';
import { useMoreDocsInGroup } from 'src/hooks/api/useMoreDocsInGroup';
import { usePossibleDoctypes } from 'src/hooks/boards/usePossibleDoctypes';
import { DndItemType } from 'src/hooks/dnd/useGroupsDnd';
import { useDefaultNotStartedStatus } from 'src/hooks/status/useDefaultNotStartedStatus';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useParentPage } from 'src/hooks/usePageId';
import { useDocUrl } from 'src/hooks/useUrl';
import { getAuth } from 'src/reactives/auth.reactive';
import { useGetBoardDnd } from 'src/reactives/boardDnd.reactive';
import { setBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { useBoardNewDocPositionState, setBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { setDnd } from 'src/reactives/dnd.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { SIZE_DOCS_PAGE } from 'src/utils/pagination.util';

import { ContentList, MoreButton } from './BoardGroup.styles';
import { BoardGroupName } from './BoardGroupName';
import BoardGroupNewDoc from './BoardGroupNewDoc';
import BoardGroupToolbar from './BoardGroupToolbar';

const INITIAL_GROUPS_IN_BOARD_VIEW = 5;

const GROUP_BY_RULES_WITH_DISABLED_RENAMING: Array<Property['__typename']> = [
  'CreatorDefinition',
  'AssigneeDefinition',
  'DoctypeDefinition',
];

export interface Props extends HTMLAttributes<HTMLDivElement> {
  groupIndex?: number;
  group: BoardGroupType;
  groupId: string;
  boardConfigId: string | undefined;
  groupByProperty?: Property;
  items: string[];
  activeId?: string | null;
  activeType: DndItemType;
  viewType: ViewType;
  getDoc: (docId: string) => DocBaseWithDocSourceFragment | undefined;
  withGroupBy: boolean;
  setSortableRef?: (node: HTMLElement | null) => void;
  setDraggableNodeRef?: (node: HTMLElement | null) => void;
  setDroppableNodeRef?: (node: HTMLElement | null) => void;
  sortableListeners?: DraggableSyntheticListeners;
  isDragging?: boolean;
  isDraggable?: boolean;
  asPlaceholder?: boolean;
  collapse?: boolean;
  toggleCollapse?: (groupId: string) => void;
  builtInDisplay?: BuiltInDisplay;
}

const BoardGroup = forwardRef<DocItemHandle[], Props>(({
  groupIndex,
  group,
  groupId,
  boardConfigId,
  groupByProperty,
  items,
  activeId,
  activeType,
  viewType,
  getDoc,
  withGroupBy,
  setSortableRef,
  setDroppableNodeRef,
  setDraggableNodeRef,
  sortableListeners,
  isDragging,
  asPlaceholder,
  isDraggable,
  collapse,
  toggleCollapse,
  builtInDisplay,
  ...htmlProps
}, forwardedRef) => {
  const hasFilterPreventingNewDoc = useBoardConfig(ctx => ctx.hasFilterPreventingNewDoc);
  const parentPage = useParentPage();
  const isInboxSection = parentPage === 'inbox';

  const {
    groupId: newDocGroupId,
    draftPosition: draftDocPosition,
  } = useBoardNewDocPositionState();
  const getGroup = useGetGroup();

  const { selected } = useGetSelection();
  const [readOnly, setReadOnly] = useState(true);
  const docItemRefs = useRef<DocItemHandle[]>([]);
  const isOnboarding = useIsOnboarding();

  useEffect(() => {
    if (typeof forwardedRef === 'function' && docItemRefs.current) {
      forwardedRef(docItemRefs.current);
    }
  }, [forwardedRef]);

  useEffect(() => {
    setDnd({ enabled: readOnly });
  }, [readOnly]);

  const isList = viewType === ViewType.List;
  const isKanban = viewType === ViewType.Kanban;

  const {
    moreDocs,
    loading: loadingMoreDocs,
  } = useMoreDocsInGroup(groupId);
  const { hideBoardConfigGroupValue } = useManageHiddenGroups(boardConfigId ?? '');

  const [viewRef, isGroupInBoardView] = useInView({
    root: document.getElementById(BOARD_CONTENT_ID),
    rootMargin: '800px',
    initialInView: groupIndex ? groupIndex < INITIAL_GROUPS_IN_BOARD_VIEW : true,
  });

  const groupListRef = useRef<HTMLDivElement>();
  const setGroupListRef = useCallback((node) => {
    viewRef(node);
    groupListRef.current = node;
  }, [viewRef]);

  const hasNextPage = group.pageInfo?.hasNextPage && Object.keys(group.docs).length >= SIZE_DOCS_PAGE;
  const showMore = !isOnboarding && withGroupBy && isList && !loadingMoreDocs && hasNextPage;
  const scrollableElement = isList ? document.getElementById(BOARD_CONTENT_ID) : groupListRef.current;

  const inputGroupNameRef = useRef<HTMLInputElement>(null);

  const onHideGroup = useCallback(() => hideBoardConfigGroupValue(groupId, { updateDocGroups: true }), [groupId, hideBoardConfigGroupValue]);

  const onNewDocClicked = useCallback((pos: 'top' | 'bottom') => setBoardNewDocPositionState({
    groupId,
    draftPosition: pos,
  }), [groupId]);

  const onCreateDocFromMenu = useCallback(() => setBoardNewDocPositionState({
    groupId,
    draftPosition: 'top',
  }), [groupId]);
  const onRenameGroup = useCallback(() => {
    setReadOnly(false);
  }, []);

  const onMouseEnterGroup = useCallback(() => {
    if (draftDocPosition) return;
    setBoardGroupState({ hoverGroupId: groupId });
  }, [groupId, draftDocPosition]);

  const onMouseLeaveGroup = useCallback(() => {
    if (draftDocPosition) return;
    setBoardGroupState({ hoverGroupId: null });
  }, [draftDocPosition]);

  const onLoadMoreDocs = useCallback(async () => {
    if (withGroupBy && isKanban) {
      await moreDocs(group.pageInfo?.endCursor ?? '');
    } else {
      group?.more?.exec?.();
    }
  }, [withGroupBy, isKanban, moreDocs, group.pageInfo?.endCursor, group?.more]);

  const {
    canUpdateGroup, canReorderGroups,
  } = useGetPermission();

  const groupFiltered = getGroup(groupId);
  const { getPossibleDoctypes } = usePossibleDoctypes();
  const possibleDoctypes = useMemo(() => getPossibleDoctypes({
    groupId,
  }), [getPossibleDoctypes, groupId]);

  const hasMoreData = (group?.pageInfo?.hasNextPage ?? false) && !!Object.keys(group?.docs).length;

  const defaultNotStartedStatus = useDefaultNotStartedStatus();
  const isHidingDisabled = !canReorderGroups ||
    (isInboxSection && groupByProperty?.__typename === 'StatusDefinition' && group.statusId === defaultNotStartedStatus?.id);

  const isRenamingDisabled = !canUpdateGroup || GROUP_BY_RULES_WITH_DISABLED_RENAMING.includes(groupByProperty?.__typename);
  const isCreatingDoc = draftDocPosition !== null;
  const isDocCreationDisabled =
    !possibleDoctypes.length ||
    (
      groupFiltered?.node.propertyValue?.__typename === 'Doctype' &&
      !possibleDoctypes.find(doctype => doctype.id === groupFiltered?.node?.propertyValue?.id)
    ) ||
    isOnboarding ||
    hasFilterPreventingNewDoc ||
    (groupByProperty?.__typename === 'CreatorDefinition' && group.attributeValueId !== getAuth().userId);

  const { dragging: dndIsActive } = useGetBoardDnd();

  const reasonCreateDocDisabled: string | null = useMemo(() => {
    if (hasNextPage) return MESSAGE_CREATE_DOC_DISABLED_HAS_NEXT_PAGE;
    if (isDocCreationDisabled) return MESSAGE_CREATE_DOC_DISABLED_BOARD_CONFIG;
    return null;
  }, [hasNextPage, isDocCreationDisabled]);

  const docCount = items.length;

  const filteredItems = useMemo(() => items.filter((itemId) => {
    const isSelected = selected.includes(itemId);
    return activeType !== 'item' || !isSelected || activeId === itemId;
  }), [activeId, activeType, items, selected]);

  const isMobile = useIsMobile();
  // Using useDocUrl directly in DocItem would trigger a re-render each time the route changes
  const { getDocPanelUrl } = useDocUrl();

  const makeMemoizedSetRef = useMemo(() => memoize(
    (index: number) => (docItemHandle: DocItemHandle) => {
      if (docItemHandle !== null) {
        // eslint-disable-next-line no-param-reassign
        docItemRefs.current[index] = docItemHandle;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);

  return (
    <Group
      data-groupid={groupId}
      groupId={groupId}
      groupName={group.name}
      groupCategory={group.category}
      inputName={(
        <BoardGroupName
          ref={inputGroupNameRef}
          groupName={group.name}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          attributeValueId={group.attributeValueId}
          docGroupId={groupId}
          isList={isList}
          statusId={group.statusId}
        />
      )}
      setListRef={setGroupListRef}
      setSortableRef={setSortableRef}
      setDraggableNodeRef={setDraggableNodeRef}
      setDroppableNodeRef={setDroppableNodeRef}
      sortableListeners={sortableListeners}
      viewType={viewType}
      onNewDocClick={onNewDocClicked}
      withGroupBy={withGroupBy}
      dragActive={!!activeId}
      isDragging={isDragging}
      asPlaceholder={asPlaceholder}
      isDraggable={isDraggable}
      onMouseEnter={onMouseEnterGroup}
      onMouseLeave={onMouseLeaveGroup}
      reasonCreateDocDisabled={reasonCreateDocDisabled}
      createDocHidden={isOnboarding || (isCreatingDoc && newDocGroupId === groupId)}
      toolbar={(
        <BoardGroupToolbar
          groupId={groupId}
          onCreateDoc={isDocCreationDisabled ? undefined : onCreateDocFromMenu}
          onHideGroup={!isHidingDisabled ? onHideGroup : undefined}
          onRenameGroup={!isRenamingDisabled ? onRenameGroup : undefined}
          dropdownPlacement="bottom-end"
          isGroupByProperty={!!groupByProperty}
        />
      )}
      collapse={collapse}
      toggleCollapse={toggleCollapse}
      docCount={`${docCount}${hasNextPage ? '+' : ''}`}
      showDocCount={isInboxSection && !!group.name}
      {...htmlProps}
    >
      <InfiniteScroll
        disabled={(isList && withGroupBy) || dndIsActive || items.length < SIZE_DOCS_PAGE}
        isLoading={false}
        loadMore={onLoadMoreDocs}
        hasMoreData={hasMoreData}
        direction="vertical"
        scrollableElement={scrollableElement}
        loader={(
          <ContentList>
            {Array(3).fill(0).map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ViewCardSkeleton key={i} viewType={viewType} />
            ))}
          </ContentList>
        )}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <ContentList>
            {!isDocCreationDisabled && newDocGroupId === groupId && draftDocPosition === 'top' && (
              <BoardGroupNewDoc
                targetPosition="top"
                groupId={groupId}
                statusId={group.statusId}
                viewType={viewType}
              />
            )}

            {filteredItems.map((itemId, itemIndex) => {
              const doc = getDoc(itemId);

              return doc && (
                <DocProvider
                  key={itemId}
                  value={doc}
                >
                  <DocItem
                    itemId={itemId}
                    docIndex={itemIndex}
                    isGroupInBoardView={isGroupInBoardView}
                    ref={makeMemoizedSetRef(itemIndex)}
                    docUrl={getDocPanelUrl(doc)}
                    groupId={groupId}
                    asPlaceholder={itemId === activeId}
                    viewType={viewType}
                    showAssignee={builtInDisplay?.assignee}
                    showComments={builtInDisplay?.comments && !(viewType === ViewType.List && isMobile)}
                    showCreator={builtInDisplay?.creator && !(viewType === ViewType.List && isMobile)}
                    showCreatedAt={builtInDisplay?.createdAt && !doc.isDraft && !isMobile}
                    showCover={builtInDisplay?.cover}
                    showDocParent={builtInDisplay?.parent && !(viewType === ViewType.List && isMobile)}
                    showChildren={builtInDisplay?.children}
                    showCustomer={builtInDisplay?.customer && !!doc?.doctype.customer}
                    showDocId={builtInDisplay?.docId && !doc.isDraft}
                    showDocType={builtInDisplay?.docType}
                    showSource={builtInDisplay?.source && !doc.isDraft && !!doc.source}
                    showStatus={isStatusEnabled && builtInDisplay?.status}
                    isLazy
                    isSelectable
                  />
                </DocProvider>
              );
            })}

            {showMore && (
              <MoreButton onClick={() => moreDocs(group.pageInfo?.endCursor ?? '')}>
                <DownArrowIcon />
                {`Load ${SIZE_DOCS_PAGE} more`}
              </MoreButton>
            )}

            {(loadingMoreDocs || !!group.more?.loading) && (
              <>
                {Array(3).fill(0).map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ViewCardSkeleton key={i} viewType={viewType} />
                ))}
              </>
            )}

            {!isDocCreationDisabled && newDocGroupId === groupId && draftDocPosition === 'bottom' && (
              <BoardGroupNewDoc
                targetPosition="bottom"
                groupId={groupId}
                statusId={group.statusId}
                viewType={viewType}
              />
            )}
          </ContentList>
        </SortableContext>
      </InfiniteScroll>
    </Group>
  );
});

export default memo(BoardGroup);
