import { DocFullFragment } from '@cycle-app/graphql-codegen';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import React, { VFC, useCallback, useMemo } from 'react';
import { Placement } from 'tippy.js';
import { useDebouncedCallback } from 'use-debounce';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import EditProperty, { OnValueSelectedParams } from 'src/components/EditProperty/EditProperty';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { isAttributeScalar } from 'src/utils/attributes.util';

import {
  StyledDocAttributes,
  AddPropertyButton,
} from './DocPanelDocAttributes.styles';

interface Props {
  className?: string;
  doc: DocFullFragment;
  displayPrimaryAttributes?: boolean;
  dropdownPlacement?: Placement;
  layer?: Layer;
  limitSize?: boolean;
  readOnly?: boolean;
  isDocTypeReadOnly?: boolean;
}
const DocPanelDocAttributes: VFC<Props> = ({
  className,
  doc,
  displayPrimaryAttributes,
  dropdownPlacement,
  layer = Layer.Dropdown,
  limitSize,
  readOnly,
  isDocTypeReadOnly = false,
}) => {
  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);
  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeDocAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);

  const [isEditPropertyShown, {
    setFalseCallback: hideEditProperty,
    toggleCallback: toggleShowEditProperty,
  }] = useOptimizedBooleanState(false);

  const onPropertyUpdated = useCallback(async ({
    attributeDefinition,
    propertyValue,
    isValueRemoved,
    notCompatible,
  }: OnValueSelectedParams) => {
    if (!propertyValue) return;
    if (isAttributeScalar(attributeDefinition)) {
      await changeDocAttributeValueDebounced({
        doc,
        attributeDefinition,
        value: propertyValue,
      });
    } else if (isValueRemoved) {
      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: propertyValue,
        notCompatible,
      });
    } else {
      await changeDocAttributeValue({
        doc,
        attributeDefinition,
        value: propertyValue,
        notCompatible,
      });
    }
  }, [changeDocAttributeValue, changeDocAttributeValueDebounced, doc, removeDocAttributeValue]);

  const undefinedAttributeDefinitions = useMemo(() => {
    const docDefinedAttributeIds = nodeToArray(doc.attributes).map(a => a.definition.id);
    const docPotentialAttributes = nodeToArray(doc.doctype.attributeDefinitions)
      .filter(a => a && !docDefinedAttributeIds.includes(a.id));
    return docPotentialAttributes;
  }, [doc.attributes, doc.doctype.attributeDefinitions]);
  return (
    <StyledDocAttributes
      className={className}
      context="doc-panel"
      doc={doc}
      displayPrimaryAttributes={displayPrimaryAttributes}
      dropdownPlacement={dropdownPlacement}
      layer={layer}
      limitSize={limitSize}
      readOnly={readOnly}
      showCreator={false}
      showCreatedAt={false}
      showStatus={isStatusEnabled}
      isDocTypeReadOnly={isDocTypeReadOnly}
      enableStatusShortcut
    >
      {undefinedAttributeDefinitions.length > 0 && (
        <DropdownLayer
          layer={layer}
          visible={isEditPropertyShown}
          hide={hideEditProperty}
          content={(
            <EditProperty
              docId={doc.id}
              possibleAttributes={undefinedAttributeDefinitions}
              onValueUpdated={onPropertyUpdated}
            />
          )}
        >
          <AddPropertyButton
            tooltip="Add property"
            tooltipPlacement="top"
            onClick={toggleShowEditProperty}
            forceFocus={isEditPropertyShown}
            size="L"
          >
            <AddIcon />
          </AddPropertyButton>
        </DropdownLayer>
      )}
    </StyledDocAttributes>
  );
};

export default DocPanelDocAttributes;
