import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TriggerWrapper = styled.span`
  flex-grow: 1;
`;

export const Input = styled.input`
  border:none;
  background-image:none;
  background-color:transparent;
  box-shadow: none;
  cursor: text;
  width: 100%;

  &:focus {
    outline: none;
  }
`;
