import { FetchPolicy, useQuery } from '@apollo/client';
import {
  Maybe,

  ProductFragment,
  ProductBaseFragment,

  ProductBySlugDocument,
  ProductBySlugQueryVariables,

  ProductBaseBySlugDocument,
  ProductBaseBySlugQueryVariables,
  BillingPlan,
} from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useAppParams } from 'src/hooks/useAppParams';
import { useGetInitial } from 'src/reactives/initial.reactive';

interface UseProductResult {
  product: Maybe<ProductFragment>;
  loading: boolean;
}

export const useProduct = (fetchPolicy: FetchPolicy = 'cache-first'): UseProductResult => {
  const { productSlug } = useAppParams();

  const {
    data, loading,
  } = useQuery<{ product: ProductFragment }, ProductBySlugQueryVariables>(ProductBySlugDocument, {
    skip: !productSlug,
    fetchPolicy,
    variables: {
      slug: productSlug as string,
    },
  });

  return useMemo(() => ({
    product: data?.product ?? null,
    loading,
  }), [data?.product, loading]);
};

export const useProductBase = (productSlug?: string | null): Maybe<ProductBaseFragment> => {
  const params = useAppParams();
  const slug = productSlug || params.productSlug;

  // Check if the product is present in the initial product list
  const { products } = useGetInitial();
  const productBase = useMemo(() => products.find(p => p.slug === slug), [products, slug]);

  // If not, fetch it using his slug
  const { data } = useQuery<{ product: ProductBaseFragment }, ProductBaseBySlugQueryVariables>(ProductBaseBySlugDocument, {
    skip: !slug,
    fetchPolicy: 'cache-first',
    variables: {
      slug: slug as string,
    },
  });

  return productBase ?? data?.product ?? null;
};

export const useCurrentBilling = () => {
  const { product } = useProduct();
  return product?.billings.edges[0]?.node;
};
export const useIsStandardPlan = () => {
  const billing = useCurrentBilling();
  return billing?.plan === BillingPlan.Standard;
};

export const useIsFreePlan = () => {
  const billing = useCurrentBilling();
  return billing?.plan === BillingPlan.Free;
};
