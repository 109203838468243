import { ViewType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { DocItem } from 'src/components/DocItem';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { DocProvider } from 'src/contexts/docContext';
import { useDoc } from 'src/hooks/api/useDoc';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';

import { Container } from './DocItemOverlay.styles';

interface Props {
  activeId: string | null;
  direction?: 'left' | 'right';
  viewType: ViewType;
}

const DocItemOverlay: FC<Props> = ({
  activeId,
  direction,
  viewType,
}) => {
  const isMobile = useIsMobile();
  const { doc } = useDoc(activeId);
  const { selected } = useGetSelection();
  const builtInDisplay = useBoardConfig(ctx => ctx.builtInDisplay);
  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);

  if (!doc) return null;

  return (
    <Container>
      <DocProvider value={doc}>
        <DocItem
          isDragging
          isDraggingMulti={selected.length > 0}
          direction={direction}
          viewType={viewType}
          showAssignee={builtInDisplay?.assignee}
          showComments={builtInDisplay?.comments && !(viewType === ViewType.List && isMobile)}
          showCreator={builtInDisplay?.creator && !(viewType === ViewType.List && isMobile)}
          showCreatedAt={builtInDisplay?.createdAt && !doc.isDraft && !isMobile}
          showCover={builtInDisplay?.cover}
          showDocParent={builtInDisplay?.parent && !(viewType === ViewType.List && isMobile)}
          showChildren={builtInDisplay?.children}
          showCustomer={builtInDisplay?.customer && !!doc?.doctype.customer}
          showDocId={builtInDisplay?.docId && !doc.isDraft}
          showDocType={builtInDisplay?.docType}
          showSource={builtInDisplay?.source && !doc.isDraft && !!doc.source}
          showStatus={isStatusEnabled && builtInDisplay?.status}
        />
      </DocProvider>
    </Container>
  );
};

export default DocItemOverlay;
