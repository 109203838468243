import { FC } from 'react';

import { WarningIcon } from '../../icons';
import { ActionButton } from '../Buttons/ActionButton/ActionButton';

interface Props {
  tooltip?: string;
}

export const Warning: FC<Props> = ({ tooltip = 'The doc will leave the view if you choose this value' }) => (
  <ActionButton tooltipPlacement="top" tooltip={tooltip}>
    <WarningIcon />
  </ActionButton>
);
