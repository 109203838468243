import { getDropCursorExtension, getHighlightMarkExtension } from '@cycle-app/editor-extensions';
import { Editor, textInputRule } from '@tiptap/core';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Collaboration, { CollaborationOptions } from '@tiptap/extension-collaboration';
import CollaborationCursor, { CollaborationCursorOptions } from '@tiptap/extension-collaboration-cursor';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import TaskList from '@tiptap/extension-task-list';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { ReactNodeViewRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import lowlight from 'lowlight';

import BlockCode from 'src/components/Editor/NodeViews/BlockCode/BlockCodeView';
import { ActionId } from 'src/services/editor/editorActions';
import { People } from 'src/types/editor.types';

import { CLASSNAME_EDITOR_ANCHOR } from '../hooks/useDirtyInternalAnchorTippy';
import EmojisExtension from './Emojis/EmojisExtension';
import PasteEmojisExtension from './Emojis/PasteEmojisExtension';
import getFileExtension from './File/FileExtension';
import getGithubIssuesExtension from './Github/GithubIssuesExtension';
import Iframely from './Iframely/IframelyExtension';
import getImageExtension from './Image/ImageExtension';
import getLinearMentionExtension from './Linear/LinearMentionExtension';
import getMentionExtension from './Mention/MentionExtension';
import getMentionDocExtension from './MentionDoc/MentionDocExtension';
import getNotionMentionExtension from './Notion/NotionMentionExtension';
import { getPlaceholderExtension } from './Placeholder/PlaceholderExtension';
import getSlashExtension from './Slash/SlashExtension';
import TaskItem from './TaskItem/TaskItemExtension';

interface EditorExtensionsBaseParams {
  people: People;
  disabledActions: ActionId[];
}

interface FullEditorExtensionsParams extends EditorExtensionsBaseParams {
  collaboration?: Partial<CollaborationOptions>;
  cursors?: Partial<CollaborationCursorOptions>;
  userColor: string;
  onUserMentioned?: (userId: string) => void;
  onPastedFile?: (editor: Editor, file: File) => Promise<void>;
  emptyPlaceholder?: string;
}

interface ReadonlyEditorExtensionsParams {
  people: People;
  collaboration?: Partial<CollaborationOptions>;
}

const LinkExtension = Link.configure({
  HTMLAttributes: {
    class: CLASSNAME_EDITOR_ANCHOR,
  },
});
const CodeBlockExtension = CodeBlockLowlight.extend({
  addNodeView() {
    return ReactNodeViewRenderer(BlockCode);
  },
}).configure({ lowlight });

const TypographyExtension = Typography.extend({
  addOptions: () => ({
    oneHalf: false,
    oneQuarter: false,
    threeQuarters: false,
  }),
  addInputRules() {
    return [
      ...(this.parent?.() ?? []),
      textInputRule({
        find: /\D(1\/2)\D$/,
        replace: '½',
      }),
      textInputRule({
        find: /^(1\/2)\D$/,
        replace: '½',
      }),
      textInputRule({
        find: /\D(1\/4)\D$/,
        replace: '¼',
      }),
      textInputRule({
        find: /^(1\/4)\D$/,
        replace: '¼',
      }),
      textInputRule({
        find: /\D(3\/4)\D$/,
        replace: '¾',
      }),
      textInputRule({
        find: /^(3\/4)\D$/,
        replace: '¾',
      }),
    ];
  },
});

export const fullEditorExtensions = ({
  people,
  userColor,
  collaboration,
  cursors,
  onUserMentioned,
  disabledActions,
  onPastedFile,
  emptyPlaceholder,
}: FullEditorExtensionsParams) => {
  const SlashExtension = getSlashExtension();
  const MentionExtension = getMentionExtension({
    people,
    onUserMentioned,
  });
  const MentionDocExtension = getMentionDocExtension({
    onDocMentioned: undefined, // TODO
  });
  const GithubExtensions = getGithubIssuesExtension();

  return [
    StarterKit.configure({
      // putting history: false would remove the tipap warning but would also remove the do / undo shortcuts
      codeBlock: false,
      dropcursor: false,
      gapcursor: false,
    }),
    getDropCursorExtension({
      color: userColor,
    }),

    ...!disabledActions.includes(ActionId.Image)
      ? [getImageExtension({ onPastedFile })]
      : [],
    TaskList,
    TaskItem,
    Underline,
    TypographyExtension,

    // With custom node view
    ...!disabledActions.includes(ActionId.Code) ? [CodeBlockExtension] : [],
    LinkExtension,
    EmojisExtension,
    PasteEmojisExtension,
    GithubExtensions,
    getLinearMentionExtension(),
    getNotionMentionExtension(),
    // Custom extensions
    getFileExtension(),
    getPlaceholderExtension({
      defaultPlaceholder: 'Type \'/\' for commands',
      emptyPlaceholder: emptyPlaceholder ?? 'Type \'/\' for commands, start typing, or pick an item below',
    }),
    SlashExtension,
    ...!disabledActions.includes(ActionId.MentionDoc) ? [MentionDocExtension] : [],
    ...!disabledActions.includes(ActionId.MentionUser) ? [MentionExtension] : [],
    Iframely,
    ...collaboration ? [Collaboration.configure({
      ...collaboration,
      field: 'sharedDoc',
    })] : [],
    ...cursors ? [CollaborationCursor.configure(cursors)] : [],
    getHighlightMarkExtension(),
  ];
};

type BasicEditorExtensionsParams = {
  disabledActions: ActionId[];
  userColor: string;
  onUserMentioned?: (userId: string) => void;
  onPastedFile?: (editor: Editor, file: File) => Promise<void>;
  emptyPlaceholder?: string;
};

export const basicEditorExtensions = ({
  userColor,
  disabledActions,
  onPastedFile,
  emptyPlaceholder,
}: BasicEditorExtensionsParams) => [
  StarterKit.configure({
    // putting history: false would remove the tipap warning but would also remove the do / undo shortcuts
    codeBlock: false,
    dropcursor: false,
    gapcursor: false,
  }),
  getDropCursorExtension({
    color: userColor,
  }),

  ...!disabledActions.includes(ActionId.Image)
    ? [getImageExtension({ onPastedFile })]
    : [],
  TaskList,
  TaskItem,
  Underline,
  TypographyExtension,

  // With custom node view
  ...!disabledActions.includes(ActionId.Code) ? [CodeBlockExtension] : [],
  LinkExtension,
  EmojisExtension,
  PasteEmojisExtension,
  getGithubIssuesExtension(),
  getLinearMentionExtension(),
  getNotionMentionExtension(),
  // Custom extensions
  getFileExtension(),
  getPlaceholderExtension({
    defaultPlaceholder: 'Type \'/\' for commands',
    emptyPlaceholder: emptyPlaceholder ?? 'Type \'/\' for commands, start typing, or pick an item below',
  }),
  getSlashExtension(),
  Iframely,
];

interface CommentEditorExtensionsParams extends EditorExtensionsBaseParams {
  emptyContentPlaceholder: string;
}
export const commentEditorExtensions = ({
  people,
  emptyContentPlaceholder,
}: CommentEditorExtensionsParams) => {
  const MentionExtension = getMentionExtension({ people });
  const MentionDocExtension = getMentionDocExtension({
    onDocMentioned: undefined,
  });

  return [
    StarterKit.configure({
      heading: false,
      codeBlock: false,
    }),
    Underline,
    TypographyExtension,

    // With custom node view
    LinkExtension,
    EmojisExtension,

    // Custom extensions
    getPlaceholderExtension({
      defaultPlaceholder: '',
      emptyPlaceholder: emptyContentPlaceholder,
    }),
    MentionExtension,
    MentionDocExtension,
  ];
};

export const displayExtensions = ({
  people,
  collaboration,
}: ReadonlyEditorExtensionsParams) => [
  StarterKit.configure({
    codeBlock: false,
  }),
  TaskList,
  TaskItem,
  Underline,
  TypographyExtension,
  Image,
  Iframely,
  CodeBlockExtension,
  EmojisExtension,
  LinkExtension,
  ...collaboration ? [Collaboration.configure({
    ...collaboration,
    field: 'sharedDoc',
  })] : [],

  getFileExtension(),
  getGithubIssuesExtension(),
  getLinearMentionExtension(),
  getNotionMentionExtension(),
  getMentionExtension({
    people,
    readOnly: true,
  }),
  getMentionDocExtension({ readOnly: true }),
  getHighlightMarkExtension(),
];
