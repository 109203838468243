import { useQuery } from '@apollo/client';
import { FetchDocDocSourceDocument } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

type useDocDocSourceParams = {
  docId: string;
};

export const useDocDocSource = ({ docId }: useDocDocSourceParams) => {
  const {
    data, loading,
  } = useQuery(FetchDocDocSourceDocument, {
    skip: !docId,
    variables: { docId },
  });
  const doc = useMemo(() => (data?.node?.__typename === 'Doc' ? data.node : null), [data]);
  return {
    blockId: doc?.docSource?.blockId,
    docSource: doc?.docSource?.doc,
    isLoading: loading,
  };
};
