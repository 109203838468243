import { CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';

import { Checkbox } from './Form.styles';
import { useCustomField } from './useCustomField';

export type CustomFieldCheckboxProps = {
  definition: CustomAttributeDefinitionFragment;
};

export const CustomFieldCheckbox = ({ definition }: CustomFieldCheckboxProps) => {
  const field = useCustomField(definition.id);
  return (
    <Checkbox
      id={definition.id}
      checked={field.value || false}
      onChange={() => field.onChange(!field.value)}
    />
  );
};
