import { FreePlan } from '@cycle-app/ui';

import { BILLING_LIMIT_DOCS } from 'src/constants/billing.constants';
import { PageId } from 'src/constants/routing.constant';
import { useIsFreePlan, useCurrentBilling } from 'src/hooks/api/useProduct';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import { useNavigate } from 'src/hooks/useNavigate';

export const SidebarBilling = () => {
  const billing = useCurrentBilling();
  const isFreePlan = useIsFreePlan();
  const { navigate } = useNavigate();
  const { isEnabled } = useFeatureFlag(FeatureFlag.Billing);

  if (!isEnabled || !isFreePlan) return null;

  return (
    <FreePlan
      maxDocs={BILLING_LIMIT_DOCS}
      nbDocs={billing?.nbDocs}
      onClickUpgrade={() => navigate(PageId.BillingUpgrade)}
    />
  );
};
