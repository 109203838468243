import { ReactNode, FC } from 'react';
import { useInView } from 'react-intersection-observer';

import { SCROLL_CONTAINER_ID } from 'src/constants/notifications.constants';

import { NotificationCardSkeleton } from './NotificationCardSkeleton';

export const NotificationCardLazy: FC<{ children: ReactNode }> = ({ children }) => {
  const [ref, isVisible] = useInView({
    root: document.getElementById(SCROLL_CONTAINER_ID)?.parentElement,
    rootMargin: '200px',
    initialInView: false,
  });

  return (
    <div ref={ref}>
      {isVisible ? children : <NotificationCardSkeleton />}
    </div>
  );
};
