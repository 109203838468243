import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { routing } from 'src/constants/routing.constant';
import { RouteParams } from 'src/types/routes.types';

import { usePageId } from './usePageId';

export const usePathParams = (): RouteParams => {
  const pageId = usePageId();
  const params = useRouteMatch<RouteParams>(routing[pageId])?.params;

  return useMemo(() => ({
    // Board
    productSlug: params?.productSlug,
    boardSlug: params?.boardSlug,

    // Doc page
    docSlug: params?.docSlug,

    // Settings params
    doctypeId: params?.doctypeId,
    attributeId: params?.attributeId,

    // Callback
    provider: params?.provider,
    customerId: params?.customerId,
    companyId: params?.companyId,
  }), [params]);
};
