import { WheelIcon } from '@cycle-app/ui/icons';

import { useOpenBoardConfig } from 'src/hooks/boards/useOpenBoardConfig';

import { Action } from './BoardHeader.styles';
import { HiddenAction } from './HiddenAction';

export const BoardSettings = () => {
  const openBoardConfig = useOpenBoardConfig();

  return (
    <HiddenAction>
      <Action
        onClick={openBoardConfig}
        tooltip="View settings"
        tooltipPlacement="bottom"
      >
        <WheelIcon size={16} />
      </Action>
    </HiddenAction>
  );
};
