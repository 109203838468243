import { useApolloClient } from '@apollo/client';
import {
  DocBaseFragment,
  GroupFilteredFragment,
  ListPositionInput,
  MateFragment,
  MoveDocsInBoardGroupDocument,
  Property,
  UserNodeDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';
import { isPresent } from 'ts-is-present';

import { Events, Objects } from 'src/constants/analytics.constants';
import { useGetAttributeDefinitionFromCache } from 'src/hooks/api/cache/cacheAttributeDefinition';
import { useGetDoctypeFromCache } from 'src/hooks/api/cache/cacheDoctype';
import { useGetGroup, useGetGroupNoFiltered, useUpdateDocsGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import { useUpdateDocsStatusMutation } from 'src/hooks/doc/useUpdateDocsStatusMutation';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { customAttributeTypeData, getPropertyValue, isCustomProperty } from 'src/utils/attributes.util';

type UseMoveDocs = () => {
  moveDocs: (p: UseMoveDocsParams) => Promise<void>;
  loading: boolean;
};

interface UseMoveDocsParams {
  docsMoved: Array<DocBaseFragment | null | undefined>;
  previousGroupIds?: string[];
  groupId: string;
  docsListUpdated?: Array<DocBaseFragment | null | undefined>;
  addedDoc?: DocBaseFragment;
  groupByProperty?: Property;
  position: ListPositionInput;
}

export const useMoveDocs: UseMoveDocs = () => {
  const { cache } = useApolloClient();
  const getAttributeDefinition = useGetAttributeDefinitionFromCache();
  const getGroup = useGetGroup();
  const getGroupNoFiltered = useGetGroupNoFiltered();
  const updateDocsGroup = useUpdateDocsGroup();
  const { updateDocsStatus } = useUpdateDocsStatusMutation();

  const [moveDocsMutation, { loading }] = useSafeMutation(MoveDocsInBoardGroupDocument, {
    onCompleted: () => trackAnalytics(Events.DocUpdated, {
      object: Objects.DocBoardPosition,
    }),
  });

  const getUser = useCallback((userId: string) => cache.readQuery<{ node: MateFragment }>({
    query: UserNodeDocument,
    variables: {
      userId,
    },
  })?.node, [cache]);

  const getDoctype = useGetDoctypeFromCache();

  const moveDocs = useCallback(async ({
    docsMoved,
    previousGroupIds,
    groupId,
    docsListUpdated,
    addedDoc,
    groupByProperty,
    position,
  }: UseMoveDocsParams) => {
    const docsMovedId = docsMoved.map(doc => doc?.id).filter(isPresent);
    if (docsMovedId.length === 0) return;

    const nextGroup = groupByProperty ? getGroup(groupId) : getGroupNoFiltered(groupId);

    if (!nextGroup) return;

    const previousGroups = previousGroupIds?.reduce((acc, previousGroupId) => {
      const group = getGroup(previousGroupId);
      if (!group) return acc;
      acc[group.node.id] = group;
      return acc;
    }, {} as Record<string, { node: GroupFilteredFragment }>);

    if (previousGroups) {
      // Remove docs from previous groups
      Object.keys(previousGroups).forEach(previousGroupId => {
        const group = previousGroups[previousGroupId];
        if (!group?.node?.docs) return;

        const updatedDocs = group.node.docs.edges.filter(edge => !docsMovedId.includes(edge.node.id)).map(edge => edge.node);
        updateDocsGroup({
          groupData: group,
          updatedDocs,
        });
      });
    }

    const updatedDocs = docsListUpdated?.filter(isPresent);

    if (updatedDocs && updatedDocs.length > 0) {
      updateDocsGroup({
        groupData: nextGroup,
        updatedDocs,
      });
    } else if (addedDoc) {
      updateDocsGroup({
        groupData: nextGroup,
        addedDoc,
      });
    }

    // Until there's a mutation available to update multiple docs, we have to run 1 mutation for each doc
    const optimisticResponse = docsMovedId.map((docId) => {
      const doc = docsMoved.find(d => d?.id === docId);
      if (!doc) return null;

      if (nextGroup?.node.__typename === 'DocGroupWithPropertyValue') {
        // @todo handle Assignee and Doctype
        const newValue = nextGroup.node.propertyValue ? getPropertyValue(nextGroup.node.propertyValue) : undefined;
        const optimisticValue = groupByProperty?.__typename && isCustomProperty(groupByProperty) && newValue
          ? customAttributeTypeData[groupByProperty.__typename].optimistic?.(String(newValue), String(newValue))
          : undefined;

        const docPreviousGroup = previousGroups && Object.values(previousGroups).find(previousGroup => {
          const groupDocIds = previousGroup.node.docs.edges.map(e => e.node.id);
          return groupDocIds.includes(docId);
        });
        const previousGroupHasNoValue = docPreviousGroup?.node.propertyValue === null;
        const nextGroupHasNoValue = nextGroup.node.propertyValue === null;

        const definition = groupByProperty?.id ? getAttributeDefinition(groupByProperty?.id) : null;

        const optimisticAttributes = [
          ...doc.attributes.edges
            .filter((attribute) => (nextGroupHasNoValue ? attribute.node.definition.id !== groupByProperty?.id : true))
            .map((attribute) => (
              attribute.node.definition.id === groupByProperty?.id && !nextGroupHasNoValue
                ? {
                  ...attribute,
                  node: {
                    ...attribute.node,
                    ...optimisticValue,
                  },
                }
                : attribute
            )),
          ...previousGroupHasNoValue && definition && groupByProperty?.__typename === 'AttributeSingleSelectDefinition' ? [{
            __typename: 'DocAttributeEdge',
            node: {
              __typename: 'DocAttributeSingleSelect',
              id: 'temp-id',
              definition,
              ...optimisticValue,
            },
          }] : [],
        ];

        const nextPropertyValue = nextGroup.node.propertyValue;

        const optimisticAssignee = groupByProperty?.__typename === 'AssigneeDefinition' && nextPropertyValue?.__typename === 'OtherUser'
          ? getUser(nextPropertyValue.id) ?? doc.assignee
          : doc.assignee;

        const optimisticDoctype = groupByProperty?.__typename === 'DoctypeDefinition' && nextPropertyValue?.__typename === 'Doctype'
          ? getDoctype(nextPropertyValue.id) ?? doc.doctype : doc.doctype;

        return {
          ...doc,
          assignee: nextGroupHasNoValue && groupByProperty?.__typename === 'AssigneeDefinition' ? null : optimisticAssignee,
          doctype: optimisticDoctype,
          attributes: {
            ...doc.attributes,
            edges: optimisticAttributes,
          },
          ...nextPropertyValue?.__typename === 'Status' && {
            status: {
              ...doc.status,
              id: nextPropertyValue.id,
              category: nextPropertyValue.category,
            },
          },
        };
      }
      return doc;
    });

    await moveDocsMutation({
      variables: {
        docIds: docsMovedId,
        groupId,
        position,
      },
      optimisticResponse: {
        moveDocsInBoardGroup: optimisticResponse.filter(isPresent) as Array<DocBaseFragment>,
      },
    });

    // Update doc status in boards grouped by status
    if (nextGroup.node.__typename === 'DocGroupWithPropertyValue' && nextGroup.node.propertyValue?.__typename === 'Status') {
      const status = nextGroup.node.propertyValue;
      await updateDocsStatus(docsMovedId, status.id);
    }

    if (nextGroup?.node.__typename === 'DocGroupWithPropertyValue') {
      const definition = groupByProperty?.id ? getAttributeDefinition(groupByProperty?.id) : null;
      const newValue = nextGroup.node.propertyValue ? getPropertyValue(nextGroup.node.propertyValue) : undefined;
      docsMovedId.forEach(docId => trackAnalytics(Events.DocUpdated, {
        object: Objects.DocAttributeValue,
        content: {
          docId,
          attributeId: definition?.id,
          attributeName: definition?.name,
          value: newValue,
        },
      }));
    }
  }, [getAttributeDefinition, getDoctype, getGroup, getGroupNoFiltered, getUser, moveDocsMutation, updateDocsGroup, updateDocsStatus]);

  return {
    moveDocs,
    loading,
  };
};
