import { Role } from '@cycle-app/graphql-codegen';
import { Button, SelectPanel } from '@cycle-app/ui';
import { CloseIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { emailRegex, capitalize } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import { useForm } from 'react-hook-form';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { Select, Caret } from 'src/components/GithubIssueCreationForm/RepositoriesField/RepositoriesField.styles';
import useProductMembersMutations from 'src/hooks/api/mutations/useProductMembersMutations';
import { useIsStandardPlan, useProduct } from 'src/hooks/api/useProduct';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setAddMember, useGetAddMember } from 'src/reactives/addMember.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { Layer } from 'src/types/layers.types';

import { Form, StyledInput, Label, BillingInfoMessage } from './SettingsUserModal.styles';

interface AddUserFormData {
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
}

export const SettingsUserAddUserModal = () => {
  const { visible: isModalVisible } = useGetAddMember();
  return (
    <AnimatePresence>
      {isModalVisible && (
        <SettingsUserAddUserModalContent />
      )}
    </AnimatePresence>
  );
};

const SettingsUserAddUserModalContent = () => {
  const { product } = useProduct();
  const {
    addUser,
    isAddUserLoading,
  } = useProductMembersMutations();
  const [isRolesDropdownOpen, { toggleCallback }] = useOptimizedBooleanState(false);
  const {
    // @NB we don't want to have an admin role (for now)
    // canInviteAdmin,
    canInviteCollaborator, canInviteMaker,
  } = useGetPermission();
  const isStandardPlan = useIsStandardPlan();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<AddUserFormData>({
    defaultValues: {
      email: '',
      role: Role.Collaborator,
    },
  });

  const hideModal = () => setAddMember({ visible: false });

  if (!product) return null;

  const { role } = watch();

  return (
    <PortalModalStyled hide={hideModal}>
      <Header>
        <Title>
          {`Add a member to ${product.name}`}
        </Title>
        <CloseButtonStyled size="L" onClick={hideModal}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledInput
          autoFocus
          type="email"
          label="Email address"
          placeholder="example@cycle.app"
          error={errors.email?.message}
          {...register('email', {
            required: 'required',
            pattern: {
              value: emailRegex,
              message: 'Email format is incorrect',
            },
          })}
        />
        <DropdownLayer
          layer={Layer.DropdownModal}
          visible={isRolesDropdownOpen}
          hide={toggleCallback}
          placement="bottom-start"
          content={(
            <SelectPanel
              selectedValue={role}
              options={[
                // @NB we don't want to have an admin role (for now)
                // ...(canInviteAdmin ? [{
                //   label: 'Admin',
                //   value: Role.Admin,
                // }] : []),
                ...(canInviteMaker ? [{
                  label: 'Maker',
                  value: Role.Maker,
                }] : []),
                ...(canInviteCollaborator ? [{
                  label: 'Collaborator',
                  value: Role.Collaborator,
                }] : []),
              ]}
              onOptionChange={(newOption) => {
                setValue('role', newOption.value as Role);
                toggleCallback();
              }}
              hideSearch
            />
          )}
        >
          <Label>Role</Label>
          <Select
            onClick={toggleCallback}
            type="button"
            forceFocus={isRolesDropdownOpen}
          >
            {capitalize(role.toLowerCase())}
            <Caret />
          </Select>
        </DropdownLayer>
        {isStandardPlan && (
          <BillingInfoMessage>
            <InfoIconOutline />
            The billing will automatically update if you add a maker.
          </BillingInfoMessage>
        )}
        <Actions>
          <Button
            size="M"
            type="button"
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </Button>
          <Button
            size="M"
            type="submit"
            isLoading={isAddUserLoading}
          >
            Add
          </Button>
        </Actions>
      </Form>
    </PortalModalStyled>
  );

  async function onSubmit(values: AddUserFormData) {
    if (!isAddUserLoading) {
      await addUser(values.email, values.role);
      hideModal();
    }
  }
};
