import { routing, PageId } from 'src/constants/routing.constant';
import { useRouteMatch } from 'src/hooks/router/useRouteMatch';
import { useGetLastInboxBoard } from 'src/reactives/lastView.reactive';

import { useParams } from '../router/useParams';
import { useIsOnboarding } from '../useIsOnboarding';
import { useInboxSection } from './useInboxSection';

export const useCurrentInboxSlug = () => {
  const match = useRouteMatch(routing[PageId.InboxView]);
  return match?.params.boardSlug;
};

export const useCurrentInboxView = () => {
  const isOnboarding = useIsOnboarding();
  const params = useParams();
  const lastView = useGetLastInboxBoard();
  const { boards } = useInboxSection();
  const boardSlug = isOnboarding ? lastView.boardSlug : params.boardSlug;
  return {
    boardSlug,
    board: boards.find(board => boardSlug?.includes(board.id)),
  };
};
