export enum LocalKey {
  Auth = 'auth',
  DocPanel = 'doc-panel',
  DoctypeGraph = 'doctype-graph',
  Groups = 'groups',
  LastView = 'last-view',
  Onboarding = 'onboarding',
  Realtime = 'realtime',
  Sections = 'sections',
  Sidebar = 'sidebar',
  Theme = 'theme',
  UserPreferences = 'user-preferences',
  Workspace = 'workspace',
}
