import {
  DocAttribute,
  DocAttributeFragment,
  CustomAttributeDefinition,
} from '@cycle-app/graphql-codegen';
import { Tag } from '@cycle-app/ui';
import { toTagDate } from '@cycle-app/utilities';
import { FC } from 'react';

import { Info, Url } from 'src/components/DocAttributes/DocAttributes.styles';
import { getCustomAttributeTypeData, getDocAttributeValue, isAttributeScalar } from 'src/utils/attributes.util';

type DocTagPropertiesProps = {
  properties: DocAttribute[];
};

export const DocTagProperties: FC<DocTagPropertiesProps> = ({ properties }) => {
  return (
    <>
      {properties.map(property => {
        const attributeData = getCustomAttributeTypeData(property.definition.__typename ?? 'AttributeTextDefinition');
        const attributeValueRaw = getDocAttributeValue(property as DocAttributeFragment);
        const attributeValues = Array.isArray(attributeValueRaw) ? attributeValueRaw : [attributeValueRaw];

        return attributeValues.map(attributeValue => {
          if (attributeValue == null) return null;

          const key = isAttributeScalar(property.definition as CustomAttributeDefinition)
            ? property.definition.id
            : `${property.definition.id}-${attributeValue}`;

          return (
            <Tag
              key={key}
              color={property.definition.color}
              limitSize={false}
              tooltip={{
                content: (
                  <div>
                    <Info>
                      {attributeData.icon}
                      <span>{property.definition.name}</span>
                    </Info>
                    {property.definition.__typename === 'AttributeUrlDefinition' && (
                      <Url>
                        {attributeValue}
                      </Url>
                    )}
                  </div>
                ),
                placement: 'top',
                withPortal: true,
              }}
            >
              {(() => {
                switch (property.definition.__typename) {
                  case ('AttributeUrlDefinition'): return property.definition.name;
                  case ('AttributeDateDefinition'): return toTagDate(String(attributeValue));
                  default: return attributeValue;
                }
              })()}
            </Tag>
          );
        });
      })}
    </>
  );
};
