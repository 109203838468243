import { ColorTheme } from '@cycle-app/utilities';
import { useTheme } from 'styled-components';

export const FlowMarkerDefs = () => {
  const theme = useTheme();
  return (
    <svg style={{
      width: 0,
      height: 0,
      opacity: 0,
      display: 'block',
    }}
    >
      <defs>
        {/* Active version of the default react-flow arrow */}
        <marker
          className="react-flow__arrowhead"
          id="react-flow__arrow-active"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          orient="auto"
          refX="0"
          refY="0"
        >
          <polyline
            stroke={theme.userColors.main}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            fill="none"
            points="-5,-4 0,0 -5,4"
          />
        </marker>

        {/* Insight specific double arrow */}
        <marker
          className="react-flow__arrowhead"
          id="react-flow__arrow-insight"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          orient="auto"
          refX="0"
          refY="0"
        >
          <polyline
            stroke={ColorTheme.Blue500}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            fill="none"
            points="-5,-4 0,0 -5,4"
          />
          <polyline
            stroke={ColorTheme.Blue500}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            fill="none"
            points="0,-4 5,0 0,4"
          />
        </marker>
      </defs>
    </svg>
  );
};
