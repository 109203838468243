import { AnimationControls, useAnimation } from 'framer-motion';
import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  MutableRefObject,
  useRef,
} from 'react';

const H = 52;
const W = 53;

const DELAY = 3;

export const bottomLeftSequence = [{
  y: 0,
  x: 0,
}, {
  y: -H,
  x: 0,
}, {
  y: -H,
  x: W,
}, {
  y: 0,
  x: W,
}];

export const bottomRightSequence = [{
  y: 0,
  x: 0,
}, {
  y: 0,
  x: -W,
}, {
  y: -H,
  x: -W,
}, {
  y: -H,
  x: 0,
}];

export const topLeftSequence = [{
  y: 0,
  x: 0,
}, {
  y: 0,
  x: W,
}, {
  y: H,
  x: W,
}, {
  y: H,
  x: 0,
}];

export const topRightSequence = [{
  y: 0,
  x: 0,
}, {
  y: H,
  x: 0,
}, {
  y: H,
  x: -W,
}, {
  y: 0,
  x: -W,
}];

export const useAnimateIntegrationsIllustration = () => {
  const [iconSwap, setIconSwap] = useState(0);
  const bottomLeftControls = useAnimation();
  const bottomLeftAnimationCount = useRef(0);
  const bottomRightControls = useAnimation();
  const bottomRightAnimationCount = useRef(0);
  const topLeftControls = useAnimation();
  const topLeftAnimationCount = useRef(0);
  const topRightControls = useAnimation();
  const topRightAnimationCount = useRef(0);
  const timeoutRef = useRef(0);

  const createSequence = useCallback((ctrl: AnimationControls, seq: typeof bottomLeftSequence, ref: MutableRefObject<number>) => {
    async function animate() {
      // eslint-disable-next-line no-param-reassign
      ref.current += 1;
      await ctrl.start({
        ...seq[1],
        transition: {
          duration: 0.2,
          delay: getDelay(ref.current),
          easings: 'linear',
        },
      });
      // eslint-disable-next-line no-param-reassign
      ref.current += 1;
      await ctrl.start({
        ...seq[2],
        transition: {
          duration: 0.2,
          delay: getDelay(ref.current),
          easings: 'linear',
        },
      });
      // eslint-disable-next-line no-param-reassign
      ref.current += 1;
      await ctrl.start({
        ...seq[3],
        transition: {
          duration: 0.2,
          delay: getDelay(ref.current),
          easings: 'linear',
        },
      });
      // eslint-disable-next-line no-param-reassign
      ref.current += 1;
      await ctrl.start({
        ...seq[0],
        transition: {
          duration: 0.2,
          delay: getDelay(ref.current),
          easings: 'linear',
        },
      });
      await animate();
    }

    return {
      start: animate,
      stop: () => {
        window.clearTimeout(timeoutRef.current);
        // eslint-disable-next-line no-param-reassign
        ref.current = 0;
        ctrl.set(seq[0]);
        ctrl.stop();
      },
    };
  }, []);

  const bottomLeftAnim = useMemo(() => createSequence(
    bottomLeftControls,
    bottomLeftSequence,
    bottomLeftAnimationCount,
  ), [bottomLeftControls, createSequence]);

  const topLeftAnim = useMemo(() => createSequence(
    topLeftControls,
    topLeftSequence,
    topLeftAnimationCount,
  ), [topLeftControls, createSequence]);

  const bottomRightAnim = useMemo(() => createSequence(
    bottomRightControls,
    bottomRightSequence,
    bottomRightAnimationCount,
  ), [bottomRightControls, createSequence]);

  const topRightAnim = useMemo(() => createSequence(
    topRightControls,
    topRightSequence,
    topRightAnimationCount,
  ), [topRightControls, createSequence]);

  const start = useCallback(() => Promise.all([
    bottomLeftAnim.start(),
    bottomRightAnim.start(),
    topLeftAnim.start(),
    topRightAnim.start(),
  ]), [bottomLeftAnim, bottomRightAnim, topLeftAnim, topRightAnim]);

  const stop = useCallback(() => {
    window.clearTimeout(timeoutRef.current);
    setIconSwap(0);
    bottomLeftAnim.stop();
    bottomRightAnim.stop();
    topLeftAnim.stop();
    topRightAnim.stop();
  }, [bottomLeftAnim, bottomRightAnim, topLeftAnim, topRightAnim]);

  useEffect(() => () => {
    window.clearTimeout(timeoutRef.current);
  }, []);

  return {
    bottomLeftControls,
    bottomRightControls,
    iconSwap,
    onAnimationComplete,
    onMouseLeave,
    onMouseEnter,
    topLeftControls,
    topRightControls,
  };

  function onAnimationComplete() {
    if (bottomRightAnimationCount.current && !(bottomRightAnimationCount.current % 2)) {
      timeoutRef.current = window.setTimeout(() => setIconSwap(current => (current ? 0 : 1)), (DELAY / 2) * 1000);
    }
  }

  function onMouseLeave() {
    stop();
  }

  async function onMouseEnter() {
    await start();
  }
};

function getDelay(count: number) {
  if (count === 1) return 0;
  return count % 2 ? DELAY : 1;
}
