export const CLASSNAME_USED_FOR_FOCUS = 'doc-title';

export const PARAGRAPH_TYPE = 'paragraph';
export const BULLET_LIST_TYPE = 'bulletList';
export const CHECK_LIST_TYPE = 'taskList';
export const ORDERED_LIST_TYPE = 'orderedList';
export const LINEAR_EXTENSION_NAME = 'linear-mention';
export const LINEAR_EXTENSION_TAGNAME = 'linear-tagname';
export const GITHUB_EXTENSION_NAME = 'github-issue';
export const BLOCKQUOTE = 'blockquote';
export const HEADING_TYPE = 'heading';
export const EDITOR_MAX_CONTENT_WIDTH = 700;
export const EDITOR_LATERAL_PADDING = 16;

export const PROSE_MIRROR_LIST_MARK_TYPES: string[] = [BULLET_LIST_TYPE, CHECK_LIST_TYPE, ORDERED_LIST_TYPE];

export const COMMANDS = {
  DOC_MENTION: '#',
  EMOJI: ':',
  GITHUB_ISSUE: '/GitHub issues ',
  SLASH: '/',
  USER_MENTION: '@',
  LINEAR: '/Linear issues ',
  NOTION: '/Notion ',
} as const;

export type CommandKey = keyof typeof COMMANDS;
