import { typo, Skeleton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Header = styled.header`
  margin-bottom: 88px;

  h1 {
    font-size: 26px;
    font-weight: 600;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 780px;
  margin: 0 auto;
`;

export const Bold = styled.span`
  ${typo.body500}
`;

export const PriceToPaySkeleton = styled(Skeleton)`
  height: 8px;
  display: inline-block;
  width: 40px;
  margin-left: 5px;
`;
