import { DoctypeRelativeFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, Emoji } from '@cycle-app/ui';
import { useMemo } from 'react';

export type SelectDocTypeProps = {
  docTypes: DoctypeRelativeFragment[];
  onSelect: (id: string) => void;
};

export const SelectDocType = ({
  docTypes, onSelect,
}: SelectDocTypeProps) => {
  const options = useMemo(() => docTypes.map(d => ({
    value: d.id,
    label: d.name,
    icon: <Emoji emoji={d.emoji} size={14} />,
  })), [docTypes]);

  return (
    <SelectPanel
      options={options}
      onOptionChange={option => onSelect(option.value)}
    />
  );
};
