import { useCallback } from 'react';

import { setLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';
import { getOpenedLayersAbove } from 'src/utils/layers.util';

interface UseLayerCallbacksParams {
  layer: Layer;
  hide?: VoidFunction;
  onDropdownMounted?: VoidFunction;
  onDropdownHidden?: VoidFunction;
}
export default function useDropdown({
  layer,
  hide: hideProps,
  onDropdownMounted,
  onDropdownHidden,
}: UseLayerCallbacksParams) {
  const onMount = useCallback(() => {
    onDropdownMounted?.();
    setLayer(layer, true);
  }, [layer, onDropdownMounted]);

  const onHide = useCallback(() => {
    onDropdownHidden?.();
    setLayer(layer, false);
  }, [layer, onDropdownHidden]);

  const hide = useCallback(() => {
    if (!getOpenedLayersAbove(layer)) {
      hideProps?.();
    }
  }, [layer, hideProps]);

  return {
    onMount,
    onHide,
    hide,
  };
}
