import { StatusFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { categoryKeys } from 'src/constants/status.constants';
import { useProduct } from 'src/hooks/api/useProduct';

/**
 * @returns lists of statuses linked to all the doc types provided
 * @param docTypeIds: ids of the doc types to which the statuses should be linked
 */
export const useProductStatuses = (docTypeIds?: string[]) => {
  const { product } = useProduct();

  return useMemo(() => Object.values(categoryKeys).reduce<Record<string, StatusFragment[]>>((acc, key) => ({
    ...acc,
    [key]: nodeToArray(product?.status?.[key])
      .filter(status => !docTypeIds || docTypeIds.some(
        id => status.doctypes.edges.some(d => d.node.id === id),
      )),
  }), {}), [docTypeIds, product?.status]);
};
