import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const HomeIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="1 1 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.99993 3.53942L3.65424 8.39732V14.5253H6.60358V11.3916C6.60358 11.0494 6.73953 10.7212 6.98152 10.4792C7.2235 10.2373 7.5517 10.1013 7.89392 10.1013H10.1059C10.4481 10.1013 10.7764 10.2373 11.0183 10.4792C11.2603 10.7212 11.3963 11.0494 11.3963 11.3916V14.5253H14.3456V8.39732L8.99993 3.53942ZM8.13001 2.33734C8.36774 2.12033 8.67801 2 8.99993 2C9.32184 2 9.63212 2.12033 9.86985 2.33734L9.87091 2.3383L15.3991 7.36205L15.4095 7.37167C15.5367 7.49177 15.6385 7.63609 15.709 7.79615C15.7796 7.9562 15.8174 8.12876 15.8202 8.30364L15.8204 8.31557L15.8203 14.7097C15.8203 15.0519 15.6843 15.3801 15.4424 15.6221C15.2004 15.8641 14.8722 16 14.53 16H11.2119C10.8697 16 10.5415 15.8641 10.2995 15.6221C10.0575 15.3801 9.9216 15.0519 9.9216 14.7097V11.576H8.07826V14.7097C8.07826 15.0519 7.94231 15.3801 7.70033 15.6221C7.45834 15.8641 7.13014 16 6.78792 16H3.4699C3.12769 16 2.79948 15.8641 2.5575 15.6221C2.31551 15.3801 2.17957 15.0519 2.17957 14.7097V8.30364C2.18239 8.12876 2.22029 7.95621 2.29082 7.79615C2.36135 7.63609 2.4632 7.49177 2.59036 7.37167L2.60074 7.36205L8.12895 2.3383L8.13001 2.33734Z" fill="#999999" />
  </Svg>
);
