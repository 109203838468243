import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useProduct } from 'src/hooks/api/useProduct';
import { People } from 'src/types/editor.types';
import { getOtherUserId } from 'src/utils/me.util';

export default function useEditorContributors(): People {
  const { product } = useProduct('cache-only');

  return useMemo(
    () => nodeToArray(product?.users).map(user => (user.__typename === 'Me'
      ? ({
        ...user,
        id: getOtherUserId(user.id),
      })
      : user)),
    [product?.users],
  );
}
