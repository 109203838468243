import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const LinearIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g clipPath="url(#clip0_624_32973)">
      <path d="M0.176453 16.3621L11.638 27.8236C5.78498 26.8343 1.16582 22.2151 0.176453 16.3621Z" fill="#5E6AD2" />
      <path d="M0 13.2505L14.7495 28C15.6267 27.9532 16.4825 27.8256 17.3095 27.6246L0.375371 10.6906C0.174422 11.5176 0.0468694 12.3733 0 13.2505Z" fill="#5E6AD2" />
      <path d="M1.10956 8.48937L19.5108 26.8906C20.181 26.6029 20.8242 26.2645 21.4357 25.8802L2.11996 6.56445C1.73561 7.1759 1.3972 7.81916 1.10956 8.48937Z" fill="#5E6AD2" />
      <path d="M3.3667 4.87581C5.936 1.89041 9.74225 0 13.99 0C21.7276 0 28.0001 6.27253 28.0001 14.0101C28.0001 18.2579 26.1097 22.0641 23.1243 24.6334L3.3667 4.87581Z" fill="#5E6AD2" />
    </g>
    <defs>
      <clipPath id="clip0_624_32973">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
