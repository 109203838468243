import { useApolloClient, gql } from '@apollo/client';
import {
  DocBaseFragment,
  GroupFilteredFragment,
  GroupNodeDocument,
  GroupNoFilteredFragment,
  GroupNoFilteredNodeDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { defaultPagination, defaultGroupPagination } from 'src/utils/pagination.util';

import { useBoardGroups } from '../useBoardGroups';

export const useGetGroup = () => {
  const { cache } = useApolloClient();
  return useCallback((id: string) => cache.readQuery<{ node: GroupFilteredFragment }>({
    query: GroupNodeDocument,
    variables: {
      groupId: id,
      ...defaultGroupPagination,
    },
  }), [cache]);
};

export const useGetDocGroup = () => {
  const { groups } = useBoardGroups();

  const getGroup = useGetGroup();
  const getGroupNoGroupby = useGetGroupNoFiltered();

  return useCallback((docId: string) => {
    if (!groups) return null;

    const originalGroupId = Object.keys(groups).find(groupId => {
      const group = groups[groupId];
      const docsId = Object.keys(group.docs);
      return docsId.includes(docId);
    });

    if (!originalGroupId) return null;

    return groups[originalGroupId].typeName === 'DocGroupWithPropertyValue'
      ? getGroup(originalGroupId)
      : getGroupNoGroupby(originalGroupId);
  }, [getGroup, getGroupNoGroupby, groups]);
};

export const useGetGroupNoFiltered = () => {
  const { cache } = useApolloClient();
  return useCallback((id: string) => cache.readQuery<{ node: GroupNoFilteredFragment }>({
    query: GroupNoFilteredNodeDocument,
    variables: {
      groupId: id,
      ...defaultPagination,
    },
  }), [cache]);
};

type UpdateDocsGroup = { groupData: { node: GroupFilteredFragment | GroupNoFilteredFragment } }
& ({
  updatedDocs: Array<DocBaseFragment>;
} | {
  addedDoc: DocBaseFragment;
});

export const useUpdateDocsGroup = () => {
  const { cache } = useApolloClient();

  return useCallback((params: UpdateDocsGroup) => {
    const { groupData } = params;

    cache.modify({
      id: groupData.node.id,
      fields: {
        docs: (docs) => {
          if ('updatedDocs' in params) {
            const toUpdate = {
              ...docs,
              edges: params.updatedDocs.map(doc => {
                const docRef = cache.writeFragment({
                  data: doc,
                  fragment: gql`
                  fragment DocMoved on Doc {
                    id
                  }
                `,
                });
                return { node: docRef };
              }),
            };
            return toUpdate;
          }

          const addedDocRef = cache.writeFragment({
            data: params.addedDoc,
            fragment: gql`
              fragment AddedDoc on Doc {
                id
              }
            `,
          });
          return {
            ...docs,
            edges: [{ node: addedDocRef }].concat(docs.edges),
          };
        },
      },
    });
  }, [cache]);
};
