import { FC } from 'react';

import { Svg, SvgProps } from '../Svg/Svg.styles';

export const Paper: FC<SvgProps> = (props) => (
  <Svg {...props} width="80" height="98" viewBox="0 0 80 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2942 0.615574L15.0265 0.347343L15.2942 0.615578L0.616266 15.2646C0.526728 15.354 0.476404 15.4753 0.476404 15.6018V96.3697C0.476404 96.6328 0.689684 96.8461 0.952791 96.8461H78.2777C78.5408 96.8461 78.7541 96.6328 78.7541 96.3697V0.952758C78.7541 0.689662 78.5408 0.476381 78.2777 0.476381H15.6307C15.5046 0.476381 15.3835 0.526443 15.2942 0.615574Z" fill="#F7F7F7" stroke="black" strokeWidth="0.952761" />
    <path d="M78.7536 0.952758C78.7536 0.689662 78.5403 0.476381 78.2772 0.476381H16.1889C15.9258 0.476381 15.7125 0.689664 15.7125 0.952761V14.2539C15.7125 15.0432 15.0727 15.683 14.2834 15.683H0.952295C0.689195 15.683 0.475916 15.8963 0.475916 16.1594V96.3697C0.475916 96.6328 0.689195 96.8461 0.952295 96.8461H78.2772C78.5403 96.8461 78.7536 96.6328 78.7536 96.3697V0.952758Z" fill="white" stroke="black" strokeWidth="0.952761" />
  </Svg>
);
