import { DocBaseFragment, OperatorIsInOrNot } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import groupBy from 'lodash/groupBy';
import { isPresent } from 'ts-is-present';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useGetDocFromCache } from 'src/hooks/api/cache/cacheDoc';
import { useUpdateDocsGroup, useGetGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import { useMoveDocs } from 'src/hooks/api/mutations/useMoveDoc';
import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { setBulkLoading } from 'src/reactives/selection.reactive';
import { isStatusDefinition } from 'src/types/graphql.types';

import { useStatusToaster } from './useStatusToaster';
import { useUpdateDocsStatusMutation } from './useUpdateDocsStatusMutation';

export const useUpdateDocsStatus = () => {
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const addStatusToaster = useStatusToaster();
  const { groups } = useBoardGroups();
  const moveMutation = useMoveDocs();
  const updateMutation = useUpdateDocsStatusMutation();
  const getDocFromCache = useGetDocFromCache();
  const getGroup = useGetGroup();
  const updateGroup = useUpdateDocsGroup();
  const isCompatible = useIsCompatible();

  const updateDocsStatus = async (docIds: string[], statusId: string, undoable = true) => {
    setBulkLoading({ isLoading: true });

    const docsByStatus = groupBy(docIds.map(getDocFromCache).filter(isPresent), 'status.id');
    const undo = () => {
      return Promise.all(Object.entries(docsByStatus).map(([prevStatusId, docsFromGroup]) => {
        return updateDocsStatus(docsFromGroup.map(doc => doc.id), prevStatusId, false);
      }));
    };

    if (!isCompatible(statusId)) {
      filterGroups(docIds);
      await updateMutation.updateDocsStatus(docIds, statusId);
    } else if (isStatusDefinition(groupByProperty)) {
      await moveDocs(docIds, statusId);
    } else {
      await updateMutation.updateDocsStatus(docIds, statusId);
    }

    addStatusToaster(docIds, statusId, undoable ? undo : undefined);
  };

  const filterGroups = (docIds: string[]) => {
    if (!groups) return;
    for (const group of Object.keys(groups).map(getGroup).filter(isPresent)) {
      if (group.node.__typename === 'DocGroupWithPropertyValue') {
        updateGroup({
          groupData: group,
          updatedDocs: group.node.docs.edges
            .filter(edge => !docIds.includes(edge.node.id))
            .map(edge => edge.node),
        });
      }
    }
  };

  const moveDocs = (docIds: string[], statusId: string) => {
    if (!groups) return null;
    const groupIds = Object.keys(groups);

    const groupIdOfNewValue = groupIds.find(id => groups[id].attributeValueId === statusId);

    if (!groupIdOfNewValue) {
      filterGroups(docIds);
      return updateMutation.updateDocsStatus(docIds, statusId);
    }

    const docsFromNewGroup = Object.keys(groups[groupIdOfNewValue].docs).map(id => groups[groupIdOfNewValue].docs[id]);

    const [docs, previousGroupIds, hiddenDocs] = docIds.reduce<[DocBaseFragment[], string[], DocBaseFragment[]]>((acc, docId) => {
      const doc = getDocFromCache(docId);
      if (!doc) return acc;
      const groupId = groupIds.find(id => groups[id].attributeValueId === doc?.status?.id);
      if (!groupId) {
        acc[2].push(doc);
        return acc;
      }
      acc[0].push(doc);
      acc[1].push(groupId);
      return acc;
    }, [[], [], []]);

    // If the docs come from hidden groups (when undoing),
    // add them to the new group and update their statuses
    if (hiddenDocs.length) {
      const groupOfNewValue = getGroup(groupIdOfNewValue);
      if (groupOfNewValue) {
        updateGroup({
          groupData: groupOfNewValue,
          updatedDocs: [
            ...hiddenDocs,
            ...nodeToArray(groupOfNewValue.node.docs),
          ],
        });
      }
      return updateMutation.updateDocsStatus(hiddenDocs.map(doc => doc.id), statusId);
    }

    return moveMutation.moveDocs({
      groupId: groupIdOfNewValue,
      previousGroupIds,
      docsMoved: docs,
      docsListUpdated: [...docs, ...docsFromNewGroup],
      position: {
        before: docsFromNewGroup.length ? docsFromNewGroup[0].id : '',
      },
      groupByProperty,
    });
  };

  return {
    updateDocsStatus,
    loading: moveMutation.loading || updateMutation.loading,
  };
};

const useIsCompatible = () => {
  const filterProperties = useBoardConfig(ctx => ctx.boardConfig?.filterProperties);
  return (statusId: string) => nodeToArray(filterProperties).every(property => {
    if (property.__typename !== 'FilterPropertyRuleStatus') return true;
    return nodeToArray(property.statusRule.values)
      .filter(value => value.selected)
      .some(value => (property.statusRule.operator === OperatorIsInOrNot.Is
        ? value.value.id === statusId
        : value.value.id !== statusId
      ));
  });
};
