import { typo, Skeleton, boxShadowZ1 } from '@cycle-app/ui';
import { NextArrowIcon } from '@cycle-app/ui/icons';
import { NodeViewWrapper } from '@tiptap/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DocParentDropdown from 'src/components/DocParentDropdown/DocParentDropdown';

export const DocMentionNodeView = styled(NodeViewWrapper)`
  background-color: ${p => p.theme.colors.background.hoverSoft};
  border-radius: 4px;
  margin: -4px 0;
  display: inline-flex;
  cursor: pointer;
  position: relative;
`;

export const LinkIcon = styled(NextArrowIcon)`
  transform: rotate(-45deg);
  height: 14px;
  width: 14px;
`;

export const DocId = styled.div`
  line-height: 24px;
`;

export const Left = styled.span`
  border-right: 1px solid ${p => p.theme.colors.border.primary};
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px 6px;
  white-space: nowrap;
  ${typo.caption600};
`;

export const TitleContainer = styled.div`
  padding: 2px 6px;
  display: flex;
  align-items: center;

  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${typo.body500};

  > :first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > :nth-child(2) {
    max-width: 40vw;
  }
`;

export const LinkItem = styled(Link)`
  display: inline-flex;
`;

export const ParentElementContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: calc(100% + 14px);
  left: 0;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease;
  
  ${/* sc-selector */ DocMentionNodeView}:focus &,
  ${/* sc-selector */ DocMentionNodeView}:hover & {
    visibility: visible;
    pointer-events: all;
    opacity: 1;
    bottom: calc(100% + 4px);
  }
  
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    top: 100%;
    left: 0;
  }
  `;

export const StyledDocParentDropdown = styled(DocParentDropdown)`
  ${boxShadowZ1}
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
`;

export const LoadingState = styled(Skeleton)`
  width: 100px;
  height: 16px;
`;
