import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';

import { Spinner } from '../../Spinner/Spinner.styles';
import { Tooltip, TooltipPlacement } from '../../Tooltip/Tooltip';
import { Container, Variant, Size, SpinnerContainer, ButtonCss } from './Button.styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: Size;
  variant?: Variant;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  tooltip?: ReactNode;
  tooltipPlacement?: TooltipPlacement;
  useUserColor?: boolean;
  isLoading?: boolean;
  forceFocus?: boolean;
  active?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  className = '',
  variant = 'primary',
  size = 'S',
  isLoading,
  iconStart,
  iconEnd,
  children,
  useUserColor = true,
  tooltip,
  tooltipPlacement,
  forceFocus,
  active,
  type = 'button',
  ...buttonProps
}, ref) => {
  const button = (
    <Container
      ref={ref}
      {...buttonProps}
      className={`${className} ${forceFocus ? 'force-focus' : ''}`}
      $variant={variant}
      $size={size}
      $isLoading={isLoading}
      $iconOnly={!children}
      $useUserColor={useUserColor}
      $active={active}
      type={type}
    >
      {isLoading && (
        <SpinnerContainer>
          <Spinner className="spinner" />
        </SpinnerContainer>
      )}

      {iconStart}
      {children}
      {iconEnd}
    </Container>
  );

  return tooltip
    ? (
      <Tooltip
        content={tooltip}
        placement={tooltipPlacement}
        disabled={isLoading}
        withPortal
      >
        {button}
      </Tooltip>
    )
    : button;
});

export type ButtonVariant = Variant;

export { ButtonCss };
