import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  DocNodeDocument,
  DocFullNodeDocument,
  DocBaseFragment,
  DocFullFragment,
  DocNodeQueryVariables,
  DocChildrensDocument,
  DocChildrensQueryVariables,
  DocChildrensFragment,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { useAppParams } from 'src/hooks/useAppParams';
import { useLazyQueryAsync } from 'src/hooks/useLazyQueryAsync';
import { useGetCreateDoc } from 'src/reactives/createDoc.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { getDocKey } from 'src/utils/doc.util';
import { defaultHierarchyPagination } from 'src/utils/pagination.util';

import useOptimizedBooleanState from '../useOptimizedBooleanState';
import { useProduct, useProductBase } from './useProduct';

export const useDoc = (docId?: string | null, skip = false, fetchPolicy?: WatchQueryFetchPolicy) => {
  const { product } = useProduct();
  const { docId: routeDocId } = useAppParams();

  const {
    data, loading,
  } = useQuery<{ node?: DocBaseFragment }, DocNodeQueryVariables>(DocNodeDocument, {
    skip: skip || (!docId && !routeDocId),
    variables: {
      id: docId || routeDocId || '',
    },
    fetchPolicy,
  });

  const doc = data?.node
    ? {
      ...data?.node,
      _docKey: getDocKey(product?.key, data?.node.publicId),
    } as DocBaseFragment : null;

  return {
    doc,
    loading,
  };
};

export const useDocV2 = (docId?: string | null) => {
  const { product } = useProduct();

  const {
    data, loading,
  } = useQuery<{ node?: DocBaseFragment }, DocNodeQueryVariables>(DocNodeDocument, {
    skip: !docId,
    variables: {
      id: docId || '',
    },
  });

  const doc = data?.node
    ? {
      ...data?.node,
      _docKey: getDocKey(product?.key, data?.node.publicId),
    } as DocBaseFragment : null;

  return {
    doc,
    isLoading: loading,
  };
};

export const useLazyDoc = () => {
  const getDoc = useLazyQueryAsync<{ node: DocBaseFragment }, DocNodeQueryVariables>(DocNodeDocument);
  return async (docId: string) => {
    const res = await getDoc({ id: docId });
    return res.data.node;
  };
};

type UseFullDocParams = {
  docId?: string | null;
  draft?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
} | undefined;

export const useFullDoc = ({
  docId,
  draft,
  fetchPolicy,
}: UseFullDocParams = {}) => {
  const product = useProductBase();
  const { docId: routeDocId } = useAppParams();
  const { docId: draftId } = useGetCreateDoc();

  const docIdToFetch = draft ? draftId : (docId || routeDocId);

  const {
    data, loading,
  } = useQuery<{ node?: DocFullFragment }, DocNodeQueryVariables>(DocFullNodeDocument, {
    skip: !docIdToFetch,
    fetchPolicy: fetchPolicy ?? 'cache-first',
    variables: {
      id: docIdToFetch as string,
    },
  });

  const doc = data?.node
    ? {
      ...data.node,
      _docKey: getDocKey(product?.key, data?.node.publicId),
    } as FullDocWithPublicId : null;

  return {
    doc,
    loading,
  };
};

export const useDocChildrens = (variables: Partial<Pick<DocChildrensQueryVariables, 'docId' | 'doctypeId'>>, skip?: boolean) => {
  const product = useProductBase();
  const {
    data,
    loading,
    fetchMore,
  } = useQuery<{ node?: DocChildrensFragment }, DocChildrensQueryVariables>(DocChildrensDocument, {
    variables: {
      ...variables as DocChildrensQueryVariables,
      ...defaultHierarchyPagination,
    },
    skip: skip || !variables.docId || !variables.doctypeId,
  });
  const [isPaginationLoading, {
    setTrueCallback: setPaginationLoading, setFalseCallback: unsetPaginationLoading,
  }] = useOptimizedBooleanState(false);

  const loadMore = useCallback(async (cursor: string) => {
    setPaginationLoading();
    await fetchMore({
      variables: {
        ...variables,
        ...defaultHierarchyPagination,
        cursor,
      },
    });
    unsetPaginationLoading();
  }, [fetchMore, variables, setPaginationLoading, unsetPaginationLoading]);

  const docs = useMemo(() => nodeToArray(data?.node?.children).map(doc => ({
    ...doc,
    _docKey: getDocKey(product?.key, doc.publicId) || '',
  })), [data, product?.key]);

  return {
    docs,
    count: data?.node?.children.count ?? 0,
    pageInfo: data?.node?.children.pageInfo,
    loading,
    loadMore,
    isPaginationLoading,
  };
};
