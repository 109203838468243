import { HIGHLIGHT_DATA_ID, HIGHLIGHT_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { Editor, posToDOMRect } from '@tiptap/core';
import { Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { AddMarkStep, RemoveMarkStep, Step } from 'prosemirror-transform';
import { EditorView } from 'prosemirror-view';
import tippy, { Instance, Props } from 'tippy.js';

import { isElementOutside } from 'src/utils/elements.util';

export interface HighlightViewPluginProps {
  pluginKey: PluginKey | string;
  editor: Editor;
  element: HTMLElement;
  tippyOptions?: Partial<Props>;
  updateDelay?: number;
  onOpen?: (instance: Instance, props: { id: string; create?: boolean; text?: string }) => void;
  onClose?: () => void;
}

type HighlightViewProps = HighlightViewPluginProps & {
  view: EditorView;
};

export class HighlightView {
  public editor: Editor;

  public element: HTMLElement;

  public view: EditorView;

  public preventHide = false;

  public tippy: Instance | undefined;

  public tippyOptions?: Partial<Props>;

  public updateDelay: number;

  public mouseOutSetTimeout = 0;

  public onOpen: HighlightViewPluginProps['onOpen'];

  public onClose: HighlightViewPluginProps['onClose'];

  public isDestroyed: boolean;

  constructor({
    editor,
    element,
    onOpen,
    onClose,
    tippyOptions = {},
    updateDelay = 250,
    view,
  }: HighlightViewProps) {
    this.isDestroyed = false;
    this.editor = editor;
    this.element = element;
    this.view = view;
    this.updateDelay = updateDelay;
    this.view.dom.addEventListener('mouseenter', this.handleMouseover, true);
    this.view.dom.addEventListener('mouseleave', this.handleMouseout, true);
    this.element.addEventListener('mouseenter', this.handlePopperMouseover);
    this.element.addEventListener('mouseleave', this.handlePopperMouseout);
    this.editor.on('transaction', this.handleTransaction);
    this.tippyOptions = tippyOptions;
    this.element.style.visibility = 'visible';
    this.onOpen = onOpen;
    this.onClose = onClose;
  }

  handleTransaction = (attrs: { editor: Editor; transaction: Transaction }) => {
    const { transaction } = attrs;
    const docMarkSteps = transaction.steps.filter(isHighlightStep);
    if (docMarkSteps.length) {
      const addMarkStep = docMarkSteps.find(step => (
        step instanceof AddMarkStep &&
        step.mark.attrs.id
      ));
      if (addMarkStep) {
        const { id } = addMarkStep.mark.attrs;
        const markStepsWithId = docMarkSteps.filter(step => step.mark.attrs.id);
        const { from } = markStepsWithId[0];
        const { to } = markStepsWithId[markStepsWithId.length - 1];
        const text = transaction.doc.textBetween(from, to, ' ', ' ');
        if (id) {
          const target = this.view.dom.querySelector(`[${HIGHLIGHT_DATA_ID}="${id}"]`);
          if (target) {
            this.createTooltip();
            if (this.tippy) {
              const rect = target.getBoundingClientRect();
              const pos = this.view.posAtDOM(target, -1);
              this.tippy.setProps({
                getReferenceClientRect: (() => {
                  try {
                    return posToDOMRect(this.view, pos, pos);
                  } catch {
                    return rect;
                  }
                }),
              });
              this.tippy.show();
              this.onOpen?.(this.tippy, {
                id,
                create: true,
                text,
              });
            }
          }
        }
      }
    }
  };

  handlePopperMouseover = () => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    window.clearTimeout(this.mouseOutSetTimeout);
  };

  handlePopperMouseout = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    if (
      event instanceof MouseEvent &&
      event.relatedTarget instanceof HTMLElement &&
      isElementOutside(this.element, event.relatedTarget)
    ) {
      this.onClose?.();
      this.tippy?.hide();
    }
  };

  handleMouseout = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    if (
      event.target instanceof HTMLElement &&
      event.target.getAttribute(HIGHLIGHT_DATA_ID)
    ) {
      this.mouseOutSetTimeout = window.setTimeout(() => {
        this.onClose?.();
        this.tippy?.hide();
      }, 50);
    }
  };

  createTooltip() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) {
      return;
    }

    this.tippy = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'top-start',
      hideOnClick: 'toggle',
      offset: [0, 0],
      ...this.tippyOptions,
    });
  }

  handleMouseover = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    if (
      event.target instanceof HTMLElement &&
      event.target.getAttribute(HIGHLIGHT_DATA_ID)
    ) {
      window.clearTimeout(this.mouseOutSetTimeout);
      const { target } = event;
      const id = target.getAttribute(HIGHLIGHT_DATA_ID);
      if (id) {
        const node = this.view.dom.querySelector(`[${HIGHLIGHT_DATA_ID}="${id}"]`);
        if (node) {
          this.createTooltip();
          if (this.tippy) {
            const rect = node.getBoundingClientRect();
            const pos = this.view.posAtDOM(node, -1);
            this.tippy.setProps({
              getReferenceClientRect: (() => {
                try {
                  return posToDOMRect(this.view, pos, pos);
                } catch {
                  return rect;
                }
              }),
            });
            this.tippy.show();
            this.onOpen?.(this.tippy, { id });
          }
        }
      }
    }
  };

  cleanup() {
    this.tippy?.destroy();
    this.view.dom.removeEventListener('mouseenter', this.handleMouseover, true);
    this.view.dom.removeEventListener('mouseleave', this.handleMouseover, true);
    this.element.removeEventListener('mouseenter', this.handlePopperMouseover);
    this.element.removeEventListener('mouseleave', this.handlePopperMouseout);
    this.editor.off('transaction', this.handleTransaction);
  }

  destroy() {
    this.isDestroyed = true;
    this.cleanup();
  }
}

export const HighlightViewPlugin = (options: HighlightViewPluginProps) => {
  return new Plugin({
    key: typeof options.pluginKey === 'string'
      ? new PluginKey(options.pluginKey)
      : options.pluginKey,
    view: view => new HighlightView({
      // Missmatch between library versions on the type "view", we need to update.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      view,
      ...options,
    }),
  });
};

function isHighlightStep(step: Step): step is (AddMarkStep | RemoveMarkStep) {
  return (
    (step as (Partial<AddMarkStep | RemoveMarkStep>)).mark?.type.name === HIGHLIGHT_EXTENSION_NAME
  );
}
