import { Color } from '@cycle-app/graphql-codegen/generated';
import { Avatar } from '@cycle-app/ui';
import { Direction } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div``;

export const AvatarContainer = styled(motion.div)<{ $color: Color }>`
  position: absolute;
  color: ${p => p.theme.nuances[p.$color].main};
  z-index: 1;
`;

export const StyledAvatar = styled(Avatar)`
  --size: 28px;
`;

export const IconDiretionConainer = styled.div`
  position: absolute;
  transform: translate(0,-50%);
  top: 50%;
  display: flex;
  align-items: center;
  right: -10px;
`;

export const IconRotationConainer = styled.div`
  transform: rotate(var(--rotate));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledDirectionIcon = styled(Direction)`
  transform: rotate(130deg);
`;
