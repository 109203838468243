import { MoveStatusInProductListDocument, StatusFragment, ListPositionInput, StatusType, namedOperations } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import sortBy from 'lodash/sortBy';
import { useCallback } from 'react';

import { categoryKeys } from 'src/constants/status.constants';
import { useProduct } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { lockWorkflows, unlockWorkflows } from 'src/reactives/settingsWorkflows.reactive';

type Direction = 'up' | 'down';

export const useMoveStatusInProductList = (status: StatusFragment, direction: Direction, docTypeId?: string) => {
  const { product } = useProduct();
  const statuses = sortBy(nodeToArray(product?.status?.[categoryKeys[status.category]]), 'position');
  const filteredStatuses = statuses.filter(s => !docTypeId || s.doctypes.edges.some(e => e.node.id === docTypeId));

  const indexFiltered = filteredStatuses.findIndex(s => s.id === status.id);
  const index = statuses.findIndex(s => s.id === status.id);

  let isMoveableUp = indexFiltered > 0;
  let isMoveableDown = indexFiltered < filteredStatuses.length - 1;

  // “To do” ne peut pas passer devant “To link”
  if (isMoveableUp && status.type === StatusType.ToDo) {
    isMoveableUp = statuses[index - 1].type !== StatusType.ToLink;
  }
  if (isMoveableDown && status.type === StatusType.ToLink) {
    isMoveableDown = statuses[index + 1].type !== StatusType.ToDo;
  }

  // “Loop closed” ne peut pas passer avant “Shipped”.
  if (isMoveableUp && status.type === StatusType.LoopClosed) {
    isMoveableUp = statuses[index - 1].type !== StatusType.Shipped;
  }
  if (isMoveableDown && status.type === StatusType.Shipped) {
    isMoveableDown = statuses[index + 1].type !== StatusType.LoopClosed;
  }

  const isMoveable = direction === 'down' ? isMoveableDown : isMoveableUp;

  const [mutate, result] = useSafeMutation(MoveStatusInProductListDocument, {
    refetchQueries: [namedOperations.Query.productBySlug],
    awaitRefetchQueries: true,
    onCompleted: unlockWorkflows,
  });

  const move = useCallback(() => {
    if (!isMoveable) return null;

    const position = getNewPosition(statuses, index, direction);
    if (!position) return null;

    lockWorkflows();

    return mutate({
      variables: {
        statusId: status.id,
        position: position.variable,
      },
    });
  }, [direction, index, isMoveable, mutate, status.id, statuses]);

  return {
    move,
    isMoveable,
    ...result,
  };
};

const getNewPosition = (statuses: StatusFragment[], index: number, direction: Direction): {
  variable: ListPositionInput;
  optimistic: number;
} | null => {
  if (direction === 'up') {
    const prevStatus = statuses[index - 1];
    if (!prevStatus) return null;
    return {
      variable: { before: prevStatus?.id },
      optimistic: prevStatus.position,
    };
  }

  // 'after' is not yet implemented in the backend
  if (index < statuses.length - 2) {
    const nextStatus = statuses[index + 2];
    if (!nextStatus) return null;
    return {
      variable: { before: nextStatus?.id },
      optimistic: nextStatus.position,
    };
  }

  // Use 'after' with an id to position at the end of the list
  const nextStatus = statuses[statuses.length - 1];
  if (!nextStatus) return null;
  return {
    variable: { after: nextStatus?.id },
    optimistic: nextStatus.position + 1,
  };
};
