import {
  BookIcon,
  BookOpenIcon,
  HelpIcon,
  NoCommentIcon,
  QuestionOutlineIcon,
  WhatsNewIcon,
} from '@cycle-app/ui/icons';
import { useEffect, useRef } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import {
  CHANGELOG_URL,
  FAQ_URL,
  FEEDBACK_URL,
  HELP_URL,
  SLACK_URL,
  TWITTER_URL,
} from 'src/constants/contacts.constants';
import { useSidebarProductTour } from 'src/hooks/productTour/useSidebarProductTour';
import { toggleAddMember } from 'src/reactives/addMember.reactive';

import {
  AddMemberIcon,
  DropdownItem,
  DropdownPanel,
  DropdownSecondaryItem,
  DropdownSeparator,
  NavigationItem,
  LearningCenterContainer,
  BluePoint,
  BluePointInnerMiddle,
  BluePointInnerSmall,
} from './SidebarFooterActions.styles';

export const SidebarFooterActions = () => {
  const learningCenterButtonRef = useRef<HTMLDivElement>(null);
  const {
    shouldHighlight,
    setLearningCenterButtonEl,
    terminateTour,
    showBluePoint,
  } = useSidebarProductTour();

  useEffect(() => {
    if (shouldHighlight && learningCenterButtonRef.current) {
      setLearningCenterButtonEl(learningCenterButtonRef.current);
    }
  }, [shouldHighlight]);

  return (
    <div>
      <ToggleDropdown
        placement="right"
        withPortal
        button={props => (
          <LearningCenterContainer ref={learningCenterButtonRef}>
            {showBluePoint && (
              <BluePoint>
                <BluePointInnerMiddle>
                  <BluePointInnerSmall />
                </BluePointInnerMiddle>
              </BluePoint>
            )}
            <NavigationItem
              icon={<BookIcon />}
              label="Learning center"
              onClick={(...params) => {
                terminateTour();
                props.onClick(...params);
              }}
              data-active={props['data-active']}
            />
          </LearningCenterContainer>
        )}
        content={props => (
          <DropdownPanel onClick={props.hide}>
            <DropdownItem
              icon={<BookOpenIcon />}
              label="Explore Help Center"
              href={HELP_URL}
            />
            <DropdownItem
              icon={<QuestionOutlineIcon />}
              label="Go to FAQ"
              href={FAQ_URL}
            />
            <DropdownItem
              icon={<NoCommentIcon />}
              label="Give us product feedback"
              href={FEEDBACK_URL}
            />

            <DropdownSeparator />

            <DropdownSecondaryItem
              label="Join our Slack community "
              href={SLACK_URL}
            />
            <DropdownSecondaryItem
              label="Follow @CycleProduct on Twitter"
              href={TWITTER_URL}
            />
          </DropdownPanel>
        )}
      />
      <NavigationItem
        icon={<HelpIcon />}
        label="Contact support"
        onClick={() => window.Intercom?.('show')}
      />
      <NavigationItem
        icon={<WhatsNewIcon />}
        label="What’s new"
        href={CHANGELOG_URL}
      />
      <NavigationItem
        icon={<AddMemberIcon />}
        label="Invite new member"
        onClick={toggleAddMember}
      />
    </div>
  );
};
