import { FC } from 'react';
import { useController, Control } from 'react-hook-form';

import { DocAssigneesDropdown } from 'src/components/DocAssigneesDropdown/DocAssigneesDropdown';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { CreateDocMentionFormValues } from 'src/types/doc.types';
import { Layer } from 'src/types/layers.types';

import {
  Container, Label, Caret, Input, Assignee, StyledAvatar, InputContent,
} from './AssigneeField.styles';

type Props = {
  control: Control<CreateDocMentionFormValues>;
};

export const AssigneeField: FC<Props> = ({ control }) => {
  const [isVisible, {
    setTrueCallback, setFalseCallback,
  }] = useOptimizedBooleanState(false);
  const {
    field: {
      onChange, value,
    },
  } = useController({
    name: 'assignee',
    control,
  });

  return (
    <Container>
      <Label>Assignee</Label>
      <DocAssigneesDropdown
        isVisible={isVisible}
        onHide={setFalseCallback}
        onShow={setTrueCallback}
        onChange={onChange}
        layer={Layer.DropdownModalZ1}
      >
        <Input>
          {value ? (
            <Assignee
              showAssigneeName
              forceFocus={false}
            >
              <StyledAvatar
                user={value}
                size={20}
                pointer
              />
              {value && (
                <InputContent>
                  <p>{`${value.firstName} ${value.lastName}`}</p>
                </InputContent>
              )}
            </Assignee>
          ) : <InputContent>Choose from list</InputContent>}
          <Caret />
        </Input>
      </DocAssigneesDropdown>
    </Container>
  );
};
