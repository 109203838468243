import { typo, ShyScrollbar } from '@cycle-app/ui';
import styled, { keyframes } from 'styled-components';

import DocAttributes from 'src/components/DocAttributes/DocAttributes';
import { ReadOnlyEditor } from 'src/components/Editor';

const containerAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const Container = styled(ShyScrollbar)`
  min-height: 147px;
  max-height: calc(100vh - 147px);
  width: 567px;
  max-width: 40vw;
  opacity: 0;

  /* To position the scrollbar at the right of the content */
  margin-right: -12px;
  padding-right: 12px;

  animation: ${containerAnimation} 0s 0.1s forwards;
`;

export const Header = styled.div`
  display: flex;
  gap: 4px;
`;

export const Assignee = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  color: ${p => p.theme.colors.actionButton.color};
  ${typo.body400}
`;

export const AssigneeFullname = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Title = styled.h1`
  margin: 16px 0 8px;
  outline: none;
  width: 100%;
  ${typo.headerLarge};
`;

export const StyledDocAttributes = styled(DocAttributes)`
  margin-top: 10px;
  margin-bottom: 18px;
`;

export const StyledReadOnlyEditor = styled(ReadOnlyEditor)`
  height: auto;
`;
