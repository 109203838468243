import { Navigation } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import { FC, useRef, useCallback } from 'react';

import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { usePageId, useParentPage, PageId } from 'src/hooks/usePageId';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { HANDLE_RESIZE_DIV_ID, useSidebarResize } from 'src/hooks/useSidebarResize';
import { useGetAnimation } from 'src/reactives/animation.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useSidebar } from 'src/reactives/sidebar.reactive';

import { Container, HandleResize, Mask, ToggleSidebarButton } from './Sidebar.styles';
import { SidebarActions } from './SidebarActions/SidebarActions';
import { SidebarBilling } from './SidebarBilling';
import { SidebarBoard } from './SidebarBoard/SidebarBoard';
import { ActionsSection, SidebarFooter } from './SidebarBoard/SidebarBoard.styles';
import { SidebarFooterActions } from './SidebarFooterActions';
import SidebarHeader from './SidebarHeader/SidebarHeader';

const Sidebar: FC = () => {
  const isOnboarding = useIsOnboarding();
  const parentPage = useParentPage();
  const isMobile = useIsMobile();
  const pageId = usePageId();

  const [{
    width: widthNav,
    resizing,
  }, setSidebar] = useSidebar();
  const collapsed = useSidebarCollapsed();

  const navigationRef = useRef<HTMLDivElement>(null);
  const { enabled: animate } = useGetAnimation();

  const onResizeStart = useCallback(() => setSidebar({
    resizing: true,
  }), [setSidebar]);

  const onResizeEnd = useCallback((newWidth: number) => setSidebar({
    width: newWidth,
    resizing: false,
  }), [setSidebar]);

  const onResizeMove = useCallback((newWidth: number) => setSidebar({
    width: newWidth,
  }), [setSidebar]);

  const resizingDisabled = collapsed || isMobile;

  const { startListen } = useSidebarResize({
    containerRef: navigationRef,
    currentWidth: widthNav,
    minWidth: 185,
    maxWidth: 350,
    onResizeStart,
    onResizeEnd,
    onResizeMove,
    resizing,
    disabled: resizingDisabled,
  });

  const animateSidebar = animate && !resizing;

  if (['settings', 'billing-update'].includes(parentPage)) return null;

  return (
    <>
      {isMobile && (
        <Mask onClick={() => setSidebar({ displayed: false })} />
      )}
      <Container
        {...isMobile && {
          transition: {
            type: 'tween',
            duration: 0.2,
          },
          initial: { x: -300 },
          animate: { x: 0 },
          exit: { x: -300 },
        }}
        $withBorder={pageId === PageId.DocFullPage}
      >
        <Navigation
          ref={navigationRef}
          $collapsed={collapsed}
          $width={widthNav}
          animate={animateSidebar}
        >
          <SidebarHeader />

          <ActionsSection $collapsed={collapsed}>
            <SidebarActions />
          </ActionsSection>

          <SidebarBoard animate={animate} collapsed={collapsed} />

          {!collapsed && !isOnboarding && (
            <SidebarFooter>
              <SidebarBilling />
              <SidebarFooterActions />
            </SidebarFooter>
          )}

          {!isMobile && (
            <HandleResize
              id={HANDLE_RESIZE_DIV_ID}
              resizingDisabled={resizingDisabled}
              resizing={resizing}
              onMouseDown={startListen}
              onKeyDown={e => e.preventDefault()}
            >
              <ToggleSidebarButton
                $collapsed={collapsed}
                onClick={() => setSidebar({ collapsed: !collapsed })}
              >
                <CaretIcon />
              </ToggleSidebarButton>
            </HandleResize>
          )}
        </Navigation>
      </Container>
    </>
  );
};

export default Sidebar;
