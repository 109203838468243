import { useHotkeys } from '@cycle-app/utilities';
import { BubbleMenu } from '@tiptap/react';
import { FC, useEffect, useMemo, useState } from 'react';

import { Action } from 'src/components/Editor/Action';
import LinkBubble from 'src/components/Editor/LinkBubble/LinkBubble';
import { LINEAR_EXTENSION_NAME, GITHUB_EXTENSION_NAME } from 'src/constants/editor.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { extensionName as embedExtensionName } from 'src/editorExtensions/Iframely/IframelyExtension';
import { extensionName as notionMentionExtensionName } from 'src/editorExtensions/Notion/NotionMentionExtension';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { useBubbleMenuProductTour } from 'src/hooks/productTour/useBubbleMenuProductTour';
import useDropdown from 'src/hooks/useDropdown';
import { ActionCategory, toolbarActions, ActionId } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';

import { Container, Category } from './Bubble.styles';

interface Props {
  className?: string;
  categories?: ActionCategory[];
  disabledActions?: ActionId[];
  isFeedbackDocType?: boolean;
}
const Bubble: FC<Props> = ({
  className,
  categories = ['insight', 'title', 'text', 'lists', 'elements', 'integrations'],
  disabledActions = [],
}) => {
  const { editor } = useEditorContext();
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const dropdownProps = useDropdown({ layer: Layer.Dropdown });
  const {
    setTurnIntoInsightStep,
    shouldPreventEsc,
    isActive: isProductTourActive,
  } = useBubbleMenuProductTour();

  const [isActive, setIsActive] = useState(true);

  const allowedActions = useMemo(
    () => toolbarActions.filter(action => categories.includes(action.category)),
    [categories],
  );

  const hasSelectedMultipleDocs = useMemo(
    () => getDocTitlesFromSelectedText(editor.state).selectionValues.length > 1,
    [getDocTitlesFromSelectedText, editor.state],
  );

  const isActiveLink = editor.isActive('link');

  const isActiveBlockWithoutMenu =
    editor.isActive('codeBlock') ||
    editor.isActive('image') ||
    editor.isActive('file') ||
    editor.isActive(GITHUB_EXTENSION_NAME) ||
    editor.isActive(LINEAR_EXTENSION_NAME) ||
    editor.isActive(notionMentionExtensionName) ||
    editor.isActive(embedExtensionName) ||
    editor.isActive('horizontalRule');

  const {
    state: {
      selection: {
        from, to,
      },
    },
  } = editor;

  useEffect(() => {
    setIsActive(true);

    /**
     * At every editor transaction, the from to will change then we set the
     * next step but only when there is an actual selection
     */
    if (from !== to) setTurnIntoInsightStep();
  }, [from, to]);

  const menu = useMemo(() => {
    if (from === to || isActiveBlockWithoutMenu) {
      return null;
    }
    if (isActiveLink) {
      setIsActive(true);
      return 'link';
    }
    setIsActive(true);
    return 'formatting';
  }, [from, to, isActiveLink, isActiveBlockWithoutMenu]);

  useHotkeys('escape', () => {
    if (shouldPreventEsc) return;

    setIsActive(false);
    dropdownProps.onHide?.();
  });

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        // appendTo is necessary for css selector we need tippy instance to be inside the editor div
        appendTo: 'parent',
        onHidden: shouldPreventEsc ? () => undefined : dropdownProps.onHide,
        onMount: instance => {
          // the popper is not centered, we neeed to call update.
          window.setTimeout(() => instance.popperInstance?.update(), 20);
          dropdownProps.onMount();
        },
        maxWidth: 'auto',
      }}
    >
      {renderMenu()}
    </BubbleMenu>
  );

  function renderMenu() {
    if (!isActive) {
      return null;
    }
    if (menu === 'link') {
      return <LinkBubble />;
    }
    if (menu === 'formatting') {
      return renderFormattingMenu();
    }
    return null;
  }

  function renderFormattingMenu() {
    return (
      <Container
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.1,
          delay: 0.02,
        }}
        exit={{
          opacity: 0,
          translateY: 5,
        }}
        className={className}
      >
        {allowedActions.map(({
          category, actions,
        }) => !!actions.filter(action => !disabledActions.includes(action.id)).length && (
          <Category key={category}>
            {actions
              .filter(action => !disabledActions.includes(action.id))
              .map(action => (
                <Action
                  key={action.id}
                  action={action.id === ActionId.TurnTextIntoDocMention && hasSelectedMultipleDocs
                    ? {
                      ...action,
                      label: action.labelAlt ?? action.label,
                    }
                    : action}
                  onClick={() => {
                    if (action.id !== ActionId.TurnTextIntoInsight && isProductTourActive) return;
                    action.toggle?.(editor);
                  }}
                  active={!!action.node && editor.isActive(action.node, action.nodeParams)}
                />
              ))}
          </Category>
        ))}
      </Container>
    );
  }
};

export default Bubble;
