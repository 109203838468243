import { ShyScrollbar } from '@cycle-app/ui';
import styled from 'styled-components';

import ProductItem from '../ProductsMenu/ProductItem/ProductItem';

export const Container = styled.div`
  width: 100%;
  padding: 8px 0;
`;

export const List = styled(ShyScrollbar)`
  max-height: 284px;
`;

export const StyledProductItem = styled(ProductItem)`
  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }
`;
