import Typed from 'react-typed';

import clippyUrl from 'src/assets/clippy.png';
import { useProductBase } from 'src/hooks';
import { useMe } from 'src/hooks/api/useMe';

import {
  Container,
  IllustrationContainer,
  Content,
  Title,
  Text,
  Img,
} from './HelpMeWriting.styles';

export const HelpMeWriting = () => {
  const { me } = useMe();
  const product = useProductBase();

  return (
    <Container>
      <IllustrationContainer>
        <Img src={clippyUrl} />
      </IllustrationContainer>
      <Content>
        <Title>Cycle bot</Title>
        <Text>
          {product?.name && (
            <Typed
              strings={[`So tell me ${me.firstName}, what's your product feedback on ${product.name}? I'm sure you have some ideas on how you could improve your product. Jot down your notes and we'll process them together in a minute 👇`]}
              typeSpeed={40}
            />
          )}
        </Text>
      </Content>
    </Container>
  );
};
