import styled from 'styled-components';

import { Action } from './CommandAction/CommandAction.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NoResult = styled(Action)`
  pointer-events: none;
  color: ${p => p.theme.colors.commandbar.action.colorHover};
  border-width: 0;
`;
