import { ViewType } from '@cycle-app/graphql-codegen';
import {
  forwardRef,
  memo,
  MouseEventHandler,
  ReactChild,
  useCallback,
} from 'react';

import { useDocContext } from 'src/contexts/docContext';
import { useDocItemProductTour } from 'src/hooks/productTour/useDocItemProductTour';

import { DocItemHandle } from './DocItem.types';
import { useIsDocFocused } from './useIsDocFocused';
import { useIsDocInGroupView } from './useIsDocInGroupView';

type DocItemContainerProps = {
  docIndex?: number;
  groupId?: string;
  viewType: ViewType;
  isLazy?: boolean;
  isGroupInBoardView?: boolean;
  skeleton: (height?: number) => ReactChild;
  placeholder?: ReactChild;
  children: (isFocused: boolean) => ReactChild;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const DocItemContainer = memo(forwardRef<DocItemHandle, DocItemContainerProps>(({
  docIndex,
  isGroupInBoardView = true,
  isLazy = false,
  groupId,
  viewType,
  children,
  skeleton,
  onClick,
}, ref) => {
  const isDisabled = useDocContext(ctx => !ctx._docKey);
  const [focusRef, isFocused, setIsFocusedTrue, setIsFocusedFalse] = useIsDocFocused(ref);
  const { setClickFeedbackEl } = useDocItemProductTour();

  const [viewRef, isDocInGroupView, height] = useIsDocInGroupView({
    docIndex,
    groupId: groupId as string,
    viewType,
    skip: !isLazy || !groupId || !isGroupInBoardView,
  });

  const setRef = useCallback(node => {
    setClickFeedbackEl(node, docIndex);
    focusRef.current = node;
    viewRef(node);
  }, [focusRef, viewRef]);

  const isDocInView = !isLazy || (isDocInGroupView && isGroupInBoardView);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={setRef}
      onClickCapture={onClick}
      onMouseEnter={setIsFocusedTrue}
      onMouseLeave={setIsFocusedFalse}
      style={{
        height: (isDocInView || !height) ? 'auto' : height,
        pointerEvents: isDisabled ? 'none' : 'unset',
      }}
    >
      {isDocInView ? children(isFocused) : skeleton(height)}
    </div>
  );
}));
