import { DotsMenu } from '@cycle-app/ui';
import { TrashIcon, UnionIcon, LinkIcon, RefreshIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC } from 'react';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

type Props = {
  editor: NodeViewRendererProps['editor'];
  getPos: NodeViewRendererProps['getPos'];
  node: NodeViewRendererProps['node'];
  url: string;
  onRefetch: () => void;
};

export const IntegrationEditorMenu: FC<Props> = ({
  editor,
  getPos,
  node,
  url,
  onRefetch,
}) => {
  const [isDropdownVisible, { toggleCallback: toggleDropdown }] = useOptimizedBooleanState(false);
  const copyToClipboard = useCopyToClipboard({
    successNotification: 'Url copied to clipboard',
  });

  return (
    <DotsMenu
      placement="bottom-end"
      visible={isDropdownVisible}
      forceFocus={isDropdownVisible}
      setVisible={toggleDropdown}
      zIndex={mappingZindex[Layer.Dropdown]}
      options={[
        ...(onRefetch ? [{
          label: 'Refresh',
          value: 'refresh',
          icon: <RefreshIcon />,
          onSelect: () => onRefetch(),
        }] : []),
        {
          label: 'Open',
          value: 'open',
          icon: <UnionIcon />,
          onSelect: () => {
            window.open(url, '_blank');
          },
        },
        {
          label: 'Copy link',
          value: 'copy',
          icon: <LinkIcon />,
          onSelect: () => {
            copyToClipboard(url);
          },
        },
        {
          label: 'Remove',
          value: 'delete',
          icon: <TrashIcon />,
          variant: 'danger',
          onSelect: () => deleteNodeRange({
            editor,
            node,
            getPos,
          }),
        },
      ]}
    />
  );
};
