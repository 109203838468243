import { Tooltip, ActionButton, SelectPanel } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import shuffle from 'lodash/shuffle';
import { FC, useMemo } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { ACTIVE_INTEGRATIONS } from 'src/constants/integrations.constants';
import { useProductIntegrations } from 'src/hooks';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { useIntegrationAction } from 'src/hooks/useIntegrationAction';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetIntegrationPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { FrontEndIntegration } from 'src/types/integrations.types';
import { integrationsData } from 'src/utils/integrations.utils';

import { Container, Info, SourceButton } from './InboxSources.style';

const openLimitationsModal = () => setLimitationsModal({ action: 'INTEGRATION_ADD' });

const CYCLE_INTEGRATION = {
  integrationType: FrontEndIntegration.CYCLE,
  integration: undefined,
};

export const InboxSources: FC = () => {
  const install = useInstallIntegration();
  const { sourcesByStatus } = useProductIntegrations();
  const { canAddIntegration } = useGetIntegrationPermission();
  const { canReadSettings } = useGetPermission();
  const getIntegrationAction = useIntegrationAction();

  const actives = sourcesByStatus.uninstalled.filter(i => ACTIVE_INTEGRATIONS.includes(i.integrationType));
  const inactives = sourcesByStatus.uninstalled.filter(i => !ACTIVE_INTEGRATIONS.includes(i.integrationType));

  const installed = useMemo(() => shuffle([
    ...sourcesByStatus.installed,
    CYCLE_INTEGRATION,
  ]), [sourcesByStatus.installed]);

  return (
    <Container>
      {installed.map(source => {
        const data = integrationsData[source.integrationType];
        return (
          <Tooltip
            key={source.integrationType}
            content={data.action ?? data.label}
            placement="bottom"
          >
            <SourceButton onClick={getIntegrationAction(source.integrationType, source.integration)}>
              {data.icon}
            </SourceButton>
          </Tooltip>
        );
      })}
      {canReadSettings && sourcesByStatus.uninstalled.length && (
        <ToggleDropdown
          button={props => (
            <ActionButton
              tooltip="Add source"
              tooltipPlacement="bottom"
              onClick={canAddIntegration ? props.onClick : openLimitationsModal}
              ref={props.ref}
            >
              <AddIcon />
            </ActionButton>
          )}
          content={props => (
            <SelectPanel
              hideSearch
              onOptionChange={async option => {
                props.hide();
                const integration = actives.find(source => source.integrationType === option.value)?.integration;
                if (!integration) return;
                await install(integration);
              }}
              options={[
                ...actives.map(source => {
                  const value = source.integrationType;
                  const data = integrationsData[value];
                  return {
                    value,
                    label: data.label,
                    icon: data.icon,
                  };
                }),
                ...inactives.map(source => {
                  const value = source.integrationType;
                  const data = integrationsData[value];
                  return {
                    value,
                    label: data.label,
                    icon: data.icon,
                    disabled: true,
                    end: <Info>Coming soon</Info>,
                  };
                }),
              ]}
            />
          )}
        />
      )}
    </Container>
  );
};
