import { ThemeType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import step1gifDark from 'src/assets/capture-feedback-dark.gif';
import step1pngDark from 'src/assets/capture-feedback-dark.png';
import step1gif from 'src/assets/capture-feedback.gif';
import step1png from 'src/assets/capture-feedback.png';
import step2gifDark from 'src/assets/extract-insights-dark.gif';
import step2pngDark from 'src/assets/extract-insights-dark.png';
import step2gif from 'src/assets/extract-insights.gif';
import step2png from 'src/assets/extract-insights.png';
import step3gifDark from 'src/assets/to-process-dark.gif';
import step3pngDark from 'src/assets/to-process-dark.png';
import step3gif from 'src/assets/to-process.gif';
import step3png from 'src/assets/to-process.png';
import { setOnboarding, useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { BackButton, Footer, NextButton } from '../OnboardingLayout/OnboardingLayout.styles';
import {
  AsideContainer, AsideContainerStep2, AsideContainerStep3, AsideContainerStep4, Content, Cover, CoverImg, CoverImgStep2, CoverStep2, CoverImgStep3,
} from './OnboardingStepReadonly.styles';
import { Step4Img } from './Step4Img';

const steps = [
  {
    screen: LightOnboardingScreen.Readonly,
    cover: (isLight: boolean) => <Cover><CoverImg src={isLight ? step1png : step1pngDark} alt="" /></Cover>,
    title: 'Capture customer feedback from many sources',
    aside: (isLight: boolean) => <AsideContainer><img src={isLight ? step1gif : step1gifDark} alt="" /></AsideContainer>,
    content: (
      <>
        All of your feedback, all in one place. User interviews, NPS surveys, feature requests, churn reasons...
        Bring them all into Cycle and make sure no product feedback gets unnoticed.
      </>
    ),
  },
  {
    screen: LightOnboardingScreen.Readonly2,
    cover: (isLight: boolean) => <CoverStep2><CoverImgStep2 src={isLight ? step2png : step2pngDark} alt="" /></CoverStep2>,
    title: 'Extract product insights in your inbox',
    aside: (isLight: boolean) => <AsideContainerStep2><img src={isLight ? step2gif : step2gifDark} alt="" /></AsideContainerStep2>,
    content: (
      <>
        Fly through your feedback with intuitive keyboard shortcuts, extract product insights,
        link them to relevant initiatives, and get to Inbox zero in no time.
      </>
    ),
  },
  {
    screen: LightOnboardingScreen.Readonly3,
    cover: (isLight: boolean) => <CoverStep2><CoverImgStep3 src={isLight ? step3png : step3pngDark} alt="" /></CoverStep2>,
    title: 'Write and and organize your PRDs',
    aside: (isLight: boolean) => <AsideContainerStep3><img src={isLight ? step3gif : step3gifDark} alt="" /></AsideContainerStep3>,
    content: (
      <>
        Connect customer feedback to product delivery workflows. Write your PRDs with all the customer context you need.
        No split screen, no tool switching; it’s all here.
      </>
    ),
  },
  {
    screen: LightOnboardingScreen.Readonly4,
    cover: () => <CoverStep2><Step4Img /></CoverStep2>,
    title: 'Close the loop with customers at each release',
    aside: () => (
      <AsideContainerStep4
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.2 },
        }}
      >
        <Step4Img />
      </AsideContainerStep4>
    ),
    content: (
      <>
        Just shipped a cool feature? Use Cycle to get back to folks who requested it. Right where they asked.
        Close that feedback loop and build trust with customers.
      </>
    ),
  },
];

export const OnboardingStepReadonly: FC = () => {
  const isMobile = useIsMobile();
  const {
    screen, theme,
  } = useGetOnboarding();
  const stepIndex = steps.findIndex(s => s.screen === screen);
  const step = steps[stepIndex];
  const isLight = theme === ThemeType.Eclipse;
  return (
    <OnboardingLayout
      cover={isMobile && step?.cover(isLight)}
      headline="LEARN MORE ABOUT CYCLE"
      title={step?.title}
      aside={step?.aside(isLight)}
      isVerticalCentered={false}
      main={(
        <>
          <Content>{step?.content}</Content>
          <Footer>
            {stepIndex > 0 && (
              <BackButton
                size="M"
                onClick={() => setOnboarding({ screen: steps[stepIndex - 1].screen })}
              >
                Back
              </BackButton>
            )}
            <NextButton
              size="M"
              onClick={() => setOnboarding({ screen: steps[stepIndex + 1]?.screen || LightOnboardingScreen.Done })}
            >
              Next
            </NextButton>
          </Footer>
        </>
      )}
    />
  );
};
