import { NavigationSection, Input, InputComponents } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const ActionsSection = styled(NavigationSection)<{ $collapsed: boolean }>`
  gap: 0;
  margin-left: -8px;
  margin-right: -8px;

  > * {
    display: flex;
    min-width: 0;
  }

  ${p => p.$collapsed && css`
    gap: 4px;
    margin-left: 0;
    margin-right: 0;
  `}
`;

export const BoardsSection = styled(NavigationSection)<{
  $collapsed: boolean;
  $hasBorderTop: boolean;
  $hasBorderBottom: boolean;
}>`
  gap: 12px;
  margin-bottom: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  ${p => !p.$collapsed && css`
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 8px;
    padding-right: 8px;
  `}

  ${p => p.$collapsed && css`
    gap: 0;
  `}

  ${p => p.$hasBorderTop && css`
    border-top-color: ${p.theme.colors.sidebar.separator};
  `}

  ${p => p.$hasBorderBottom && css`
    border-bottom-color: ${p.theme.colors.sidebar.separator};
  `}
`;

export const BoardSectionInput = styled(Input)`
  & input {
    height: 34px;
    background: ${p => p.theme.colors.sidebar.newSectionInput.bg};
    border-color: ${p => p.theme.colors.sidebar.newSectionInput.border};
    color: ${p => p.theme.colors.sidebar.newSectionInput.color};
  }

  ${InputComponents.ActionIcon} {
    top: 0;
    bottom: 0;
    
    svg {
      width: 7px;
      height: 7px;
    }

    &:hover, &:focus, &.force-focus {
      color: ${p => p.theme.colors.sidebar.newSectionInput.color};
    }
  }
`;

export const SidebarFooter = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
