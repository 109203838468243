import styled from 'styled-components';

import top from '../../assets/login-top.svg';

// HubSpot iframe 800x600
const hubspot = '@media (max-height : 680px)';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: ${p => p.theme.colors.background.tertiary};
  background-image: url(${top});
  background-repeat: no-repeat;
  background-size: 285px;
  background-position: top left;
  overflow: hidden;

  ${hubspot} {
    background-size: 180px;
  }

  > p {
    width: 100%;
    height: 120px;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 38px;
    line-height: 47px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 445px;

    ${hubspot} {
      font-size: 30px;
      line-height: 40px;
      top: 52%;
    }
  }
`;

export const EyesContainer = styled.div`
  position: absolute;
  top: 80px;
  left: 55px;

  ${hubspot} {
    transform: scale(0.6);
    transform-origin: -55% -55%;
  }
`;

export const PlugContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  ${hubspot} {
    transform: scale(0.6);
    transform-origin: bottom right;
  }
`;
