import { DoctypeRelativeFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { Elements, Position } from 'react-flow-renderer';

import { useDoctype } from 'src/hooks/api/useDocType';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { findFeedback, findInsight, isFeedback, isInsight } from 'src/utils/docType.util';

import { defaultOptions } from './defaultOptions';
import { getChildren } from './getChildren';
import { getFeedbackHierarchyElements } from './getFeedbackHierarchyElements';
import { getInsightHierarchyElements } from './getInsightHierarchyElements';

export const useFeedbackHierarchyElements = (): Elements => {
  const { doctypes } = useProductDoctypesFull();
  const feedbackDocType = findFeedback(doctypes);
  const insightDoctype = findInsight(doctypes);
  if (!feedbackDocType || !insightDoctype) return [];
  return getFeedbackHierarchyElements(feedbackDocType, doctypes, {
    integrationsEdgeType: 'integrations',
    insightParents: nodeToArray(insightDoctype?.parents),
  });
};

export const useDoctypeHierarchyElements = (): Elements => {
  const { doctypes } = useProductDoctypesFull();
  const docType = useDoctype();
  if (!docType) return [];

  if (isFeedback(docType)) return getFeedbackHierarchyElements(docType, doctypes);
  if (isInsight(docType)) return getInsightHierarchyElements(docType, doctypes);

  const parents = nodeToArray(docType?.parents);
  const children = nodeToArray(docType?.children);
  const insightDocType = findInsight(children);
  const lastIndex = nodeToArray(docType?.parents).length;
  const currentId = 'current';

  // eslint-disable-next-line no-nested-ternary
  const placeHolder = [
    {
      ...defaultOptions,
      id: 'add-parent',
      type: 'custom',
      data: {
        root: true,
        hasChildren: true,
        asPlaceholder: true,
        indexColumn: lastIndex,
        level: 0,
        target: 'parent',
      },
    },
    {
      ...defaultOptions,
      id: 'current',
      type: 'custom',
      data: {
        doctypeId: docType.id,
        asPlaceholder: parents.length > 0,
        indexColumn: lastIndex + 0.1,
        level: 1,
      },
      targetPosition: Position.Left,
    },
    {
      id: 'link-add-parent',
      source: 'add-parent',
      target: currentId,
      type: 'customEdge',
      data: {
        root: true,
        rootId: docType.id,
        asPlaceholder: true,
      },
    },
    ...parents.length === 0 ? [
      ...getChildren({
        parentDoctype: docType,
        level: 2,
        parentElementId: currentId,
        targetPosition: Position.Left,
        indexColumn: lastIndex + 0.2,
        isLastRank: true,
        sourceAdd: currentId,
        rootId: 'add-parent',
      }),
      ...getInsightElements({
        insightDocType,
        indexColumn: -0.4,
        parentElementId: currentId,
      }),
    ] : [],
  ];

  const parentsGraph = parents.map((doctypeParent, index) => {
    const doctypeParentId = `${doctypeParent.id}-0`;
    const parentCurrentId = `${doctypeParentId}-${docType?.id}`;

    const insightElements = getInsightElements({
      insightDocType,
      indexColumn: index - 0.4,
      iteration: parents.length > 1 ? index + 1 : 0,
      parentElementId: parentCurrentId,
    });

    return [
      {
        ...defaultOptions,
        id: doctypeParentId,
        type: 'custom',
        data: {
          root: true,
          doctypeId: doctypeParent.id,
          indexColumn: index,
          level: 0,
          target: 'parent',
        },
      },
      {
        ...defaultOptions,
        id: parentCurrentId,
        type: 'custom',
        data: {
          doctypeId: docType?.id,
          indexColumn: index + 0.1,
          level: 1,
          iteration: parents.length > 1 ? index + 1 : 0,
          rootId: doctypeParent.id,
        },
        targetPosition: Position.Left,
      },
      {
        id: `link-${doctypeParentId}-${currentId}`,
        source: doctypeParentId,
        target: parentCurrentId,
        type: 'customEdge',
      },
      ...getChildren({
        parentDoctype: docType,
        level: 2,
        parentElementId: parentCurrentId,
        targetPosition: Position.Left,
        indexColumn: index + 0.2,
        isLastRank: true,
        sourceAdd: parentCurrentId,
        iteration: parents.length > 1 ? index + 1 : 0,
        rootId: doctypeParent.id,
      }),
      ...insightElements,
    ];
  }).flat();

  return [
    // Map parents
    ...parentsGraph,

    // Add empty nodes
    ...placeHolder,
  ];
};

type GetInsightElementsFn = (options: {
  insightDocType?: DoctypeRelativeFragment;
  indexColumn: number;
  iteration?: number;
  parentElementId: string;
}) => Elements;

const getInsightElements: GetInsightElementsFn = ({
  insightDocType,
  indexColumn,
  iteration,
  parentElementId,
}) => {
  const insightElementId = `${insightDocType?.id}-2-${parentElementId}`;
  return !insightDocType ? [] : [{
    ...defaultOptions,
    id: insightElementId,
    type: 'custom',
    data: {
      doctypeId: insightDocType.id,
      level: 2,
      indexColumn,
      target: 'children',
      iteration,
    },
    sourcePosition: Position.Right,
  }, {
    id: `link-${insightElementId}-${parentElementId}`,
    source: insightElementId,
    targetHandle: 'insight-target',
    target: parentElementId,
    type: 'customEdge',
  }];
};
