import { Spinner } from '@cycle-app/ui';
import { FC } from 'react';

import { Loading } from 'src/app/Auth/Welcome/Welcome.styles';
import { useProductFromInitial } from 'src/hooks/api/useProductFromInitial';

export const StepLoader: FC = ({ children }) => {
  const {
    product, loading: productLoading,
  } = useProductFromInitial();

  if (!product || productLoading) {
    return <Loading><Spinner /></Loading>;
  }

  return <>{children}</>;
};
