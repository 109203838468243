import { EmojiData, EmojiInput } from '@cycle-app/ui';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useParentPage } from 'src/hooks/usePageId';
import { DEFAULT_EMOJI } from 'src/utils/emoji.util';

import {
  Form,
  Row,
  InputStyled,
} from './BoardEditCommonModal.styles';

type BoardEditDefaultValues = {
  name?: string;
  description?: string;
  emoji?: string | null;
};

export type BoardEditCommonFormData = {
  name: NonNullable<BoardEditDefaultValues['name']>;
  description: NonNullable<BoardEditDefaultValues['description']>;
  emoji: NonNullable<BoardEditDefaultValues['emoji']>;
};

export type BoardEditFormProps = {
  defaultValues?: BoardEditDefaultValues | null;
  focusNameOnMount?: boolean;
  focusDescriptionOnMount?: boolean;
  onSubmit: (data: BoardEditCommonFormData) => void;
  isIconHidden?: boolean;
  isNameDisabled?: boolean;
};

export const BoardEditForm: FC<BoardEditFormProps> = ({
  defaultValues, focusNameOnMount, focusDescriptionOnMount, onSubmit, children, isIconHidden = false, isNameDisabled = false,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setFocus,
    formState,
  } = useForm<BoardEditCommonFormData>({
    defaultValues: {
      name: defaultValues?.name || '',
      description: defaultValues?.description || '',
      emoji: defaultValues?.emoji || DEFAULT_EMOJI,
    },
  });

  useEffect(() => {
    if (focusNameOnMount) {
      setFocus('name');
    } else if (focusDescriptionOnMount) {
      setFocus('description');
    }
  }, [focusDescriptionOnMount, focusNameOnMount, setFocus]);

  const parentPage = useParentPage();

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        if (!formState.isSubmitting) onSubmit(data);
      })}
    >
      <Row>
        {!isIconHidden && (
          <Controller
            name="emoji"
            control={control}
            render={({
              field: {
                value,
                onChange,
              },
            }) => (
              <EmojiInput
                emoji={value}
                label="Icon"
                onSelect={({ id }: EmojiData) => onChange(id)}
              />
            )}
          />
        )}
        <InputStyled
          id="boardEdit-name"
          label="View name"
          placeholder="Your view name"
          autoFocus
          disabled={isNameDisabled}
          {...register('name', { required: true })}
        />
      </Row>
      <InputStyled
        id="boardEdit-description"
        label="Description"
        placeholder={parentPage === 'inbox' ? 'Your view description' : 'Your view description'}
        {...register('description')}
      />
      {children}
    </Form>
  );
};
