import { FC, memo, useRef, useState, useEffect } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { BoardsSection } from './SidebarBoard.styles';
import { SidebarBoardStarredSections } from './SidebarBoardSections/SidebarBoardSection/SidebarBoardStarredSections';
import SidebarBoardSections from './SidebarBoardSections/SidebarBoardSections';

interface Props {
  animate?: boolean;
  collapsed: boolean;
}

export const SidebarBoard: FC<Props> = memo(({ collapsed }) => (
  <ScrollableBoardsSection collapsed={collapsed}>
    <SidebarBoardStarredSections />
    <SidebarBoardSections />
  </ScrollableBoardsSection>
));

const ScrollableBoardsSection: FC<Props> = ({
  children, collapsed,
}) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [hasBorderTop, setHasBorderTop] = useState(false);
  const [hasBorderBottom, setHasBorderBottom] = useState(false);

  const checkBorders = () => {
    const el = sectionRef.current;
    if (!el) return;
    setHasBorderTop(el.scrollTop > 0);
    setHasBorderBottom(el.scrollHeight - el.clientHeight > el.scrollTop);
  };

  const onScroll = useThrottledCallback(checkBorders, 100);

  // Check for border bottom on mount and when a board section is collapsed or expanded.
  useEffect(() => {
    const el = sectionRef.current;
    const onClick = () => {
      if (!el) return;
      setTimeout(() => setHasBorderBottom(el.scrollHeight - el.clientHeight > el.scrollTop));
    };

    onClick();
    el?.addEventListener('click', onClick);
    return () => {
      el?.removeEventListener('click', onClick);
    };
  }, []);

  return (
    <BoardsSection
      ref={sectionRef}
      onScroll={onScroll}
      $full
      $collapsed={collapsed}
      $hasBorderTop={hasBorderTop}
      $hasBorderBottom={hasBorderBottom}
    >
      {children}
    </BoardsSection>
  );
};
