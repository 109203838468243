import { PenIcon, DuplicateIcon, TrashIcon, ForwardIcon } from '@cycle-app/ui/icons';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';

export const useBoardContextActions = (): CommandActionCategory => {
  const {
    canCreateBoard, canDeleteBoard, canUpdateBoard,
  } = useGetPermission();
  const { canPlanCreateBoard } = useGetBillingPermission();
  return ({
    id: 'board-context-actions',
    label: 'View actions',
    actions: [
      ...canUpdateBoard ? [{
        id: 'update-board',
        label: 'Update info',
        shortcut: shortcuts[ShortcutBoard.Update],
        icon: <PenIcon />,
      }] : [],
      ...canCreateBoard && canPlanCreateBoard ? [{
        id: 'duplicate-board',
        label: 'Duplicate',
        shortcut: shortcuts[ShortcutBoard.Duplicate],
        icon: <DuplicateIcon />,
      }] : [],
      ...canDeleteBoard ? [{
        id: 'delete-board',
        label: 'Delete',
        shortcut: shortcuts[ShortcutBoard.Delete],
        icon: <TrashIcon />,
      }] : [],
      ...canUpdateBoard ? [{
        id: 'publish-board',
        label: 'Publish changes',
        shortcut: shortcuts[ShortcutBoard.Publish],
        icon: <ForwardIcon />,
      }] : [],
    ],
  });
};
