import { FilterPropertyRuleEdgesFragment, PropertiesFragment } from '@cycle-app/graphql-codegen';
import { SelectOption, Avatar, SelectPanel } from '@cycle-app/ui';
import { useMemo, FC, ReactNode } from 'react';

import { useMe } from '../../hooks/api/useMe';
import { useBoardConfigFilters } from '../../hooks/boards/useBoardConfigFilters';
import useOptimizedBooleanState from '../../hooks/useOptimizedBooleanState';
import {
  getFilterData,
  GetFilterMultiValuesInput,
  isUsersFilterData,
  selectableUserToOption,
  UserFilterData,
} from '../../utils/boardConfig/filtersData.util';
import DropdownLayer from '../DropdownLayer/DropdownLayer';
import { StyledButton, Caret, SelectLineLabel, User } from './HomeRecentAssignee.styles';

interface Props {
  boardConfigId: string;
  filterableProperties: PropertiesFragment;
  filterProperties: FilterPropertyRuleEdgesFragment;
  boardID?: string;
  onComplete?: VoidFunction;
  isBoardLoading?: VoidFunction;
}

export const HomeRecentAssignee: FC<Props> = ({
  boardConfigId,
  filterableProperties: allFilterableProperties,
  filterProperties,
  boardID,
  onComplete,
}) => {
  const {
    filters,
    onValueAdded,
    onValueRemoved,
  } = useBoardConfigFilters({
    boardConfigId,
    boardID,
    onComplete,
    allFilterableProperties,
    filterProperties,
  });
  const { me } = useMe();
  const filter = filters.find(f => f.__typename === 'FilterPropertyRuleAssignee');
  const filterData = useMemo(() => getFilterData(filter), [filter]);
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  return <div>{filterData && isUsersFilterData(filterData) && renderUsersFilterOperatorAndValue(filterData)}</div>;

  function renderUsersFilterOperatorAndValue(params: UserFilterData): ReactNode {
    const {
      operator,
      userOptions,
      getFilterValueInput,
    } = params;
    const selectedOptions = userOptions.map(selectableUserToOption).filter(({ selected }) => selected);
    const firstSelectedUser = userOptions.find(({ selected }) => selected)?.value;

    const meOption = userOptions.find(o => o.value.id === me.id);
    const options = meOption ? [
      meOption,
      ...userOptions.filter(o => o.value.id !== me.id),
    ] : userOptions;

    return (
      <>
        {filter && operator && (
          <DropdownLayer
            placement="bottom-start"
            visible={isDropdownVisible}
            hide={hideDropdown}
            width={250}
            content={(
              <SelectPanel
                onSelectAll={() => (
                  onValueAdded(filter.id)(getFilterValueInput(options.map(selectableUserToOption).map(o => o.value), operator))
                )}
                onUnselectAll={() => (
                  onValueAdded(filter.id)(getFilterValueInput([], operator))
                )}
                isMulti
                options={options.map(selectableUserToOption).map(o => ({
                  ...o,
                  renderLabel: () => (
                    <SelectLineLabel>
                      {o.label}
                      {me.id === o.value && ' (me)'}
                    </SelectLineLabel>
                  ),
                }))}
                onSelectOption={o => onMultiSelectValueAdded(getFilterValueInput, operator)([
                  ...selectedOptions,
                  {
                    ...o,
                    selected: true,
                  },
                ])}
                toggleAllValuesVariant="toggle"
                onUnselectOption={o => onValueRemoved(filter?.id)(o.value)}
              />
            )}
          >
            <StyledButton
              forceFocus={isDropdownVisible}
              onClick={toggleDropdown}
              variant="light"
            >
              {firstSelectedUser
                ? (
                  <>
                    {firstSelectedUser && (
                      <User>
                        <Avatar size={16} user={firstSelectedUser} />
                        {`${firstSelectedUser.firstName} ${firstSelectedUser.lastName}`}
                      </User>
                    )}
                    {selectedOptions.length > 1 && (
                      <span>{`+${selectedOptions.length - 1}`}</span>
                    )}
                  </>
                )
                : 'All assignees'}
              <Caret $isOn={isDropdownVisible} />
            </StyledButton>
          </DropdownLayer>
        )}
      </>
    );
  }

  function onMultiSelectValueAdded(getFilterValueInput: GetFilterMultiValuesInput, operator: string) {
    return (newOptions: SelectOption[]) => filter && onValueAdded(filter.id)(
      getFilterValueInput(newOptions.map(o => o.value), operator),
    );
  }
};
