import { Button } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Caret = styled(DownIcon) <{ $isOn?: boolean }>`
  ${p => p.$isOn && 'transform: rotate(180deg);'}
  width: 12px;
`;

export const SelectLineLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledButton = styled(Button)`
  --bg: transparent;
  gap: 4px;

  &.force-focus {
    --bg: ${p => p.theme.colors.button.secondary.bgFocus};
  }

  svg {
    color: ${p => p.theme.colors.text.disabled};
  }

  &:hover {
    svg {
      color: ${p => p.theme.colors.text.primary};
    }
  }
`;
