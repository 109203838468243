import { Setter } from '@cycle-app/utilities';
import { forwardRef, useState } from 'react';

import { useUpdateDocGroupWithPropertyValueName } from 'src/hooks/api/mutations/useUpdateDocGroupWithPropertyValueName';
import { useUpdateSelectStringAttributeValue } from 'src/hooks/api/mutations/useUpdateSelectStringAttributeValue';
import { useUpdateStatusWithId } from 'src/hooks/status';
import { isElementOutside } from 'src/utils/elements.util';

import { InputGroupName, InputContainer, SaveButton } from './BoardGroup.styles';

export type BoardGroupNameEditableProps = {
  groupName: string;
  setReadOnly: Setter<boolean>;
  attributeValueId?: string;
  docGroupId?: string;
  isList?: boolean;
  statusId?: string;
};

export const BoardGroupNameEditable = forwardRef<HTMLInputElement, BoardGroupNameEditableProps>(({
  groupName,
  setReadOnly,
  attributeValueId,
  docGroupId,
  isList,
  statusId,
}, ref) => {
  const updateStatus = useUpdateStatusWithId();
  const updateValue = useUpdateSelectStringAttributeValue();
  const updateDocGroupWithPropertyValueName = useUpdateDocGroupWithPropertyValueName();
  const [value, setValue] = useState(groupName);

  return (
    <InputContainer
      tabIndex={-1}
      $isFixedWidth={isList}
      onBlur={e => {
        if (isElementOutside(e.currentTarget, e.relatedTarget)) {
          setReadOnly(true);
        }
      }}
    >
      <InputGroupName
        ref={ref}
        autoFocus
        defaultValue={groupName}
        onChange={e => setValue(e.target.value)}
        onKeyUp={async e => {
          if (e.key === 'Escape') setReadOnly(true);
          else if (e.key === 'Enter') await handleSave();
        }}
      />
      <SaveButton
        type="button"
        onClick={handleSave}
        disabled={(attributeValueId && !value.trim()) || groupName === value}
      >
        Save
      </SaveButton>
    </InputContainer>
  );

  async function handleSave() {
    if (statusId) {
      await updateStatus(statusId, {
        value: value.trim(),
      });
    } else if (attributeValueId) {
      await updateValue({
        valueId: attributeValueId,
        value,
      });
    } else if (docGroupId) {
      await updateDocGroupWithPropertyValueName({
        docGroupId,
        name: !value.trim() ? null : value,
      });
    }
    setReadOnly(true);
  }
});
