import { pathToRegexp } from 'path-to-regexp';
import { useLocation } from 'react-router-dom';

import { PageId, routing } from 'src/constants/routing.constant';

export { PageId };

export const usePageId = (): PageId => {
  const location = useLocation();
  return getPageIdFromPathname(location.pathname);
};

const getPageIdFromPathname = (pathname: string): PageId => {
  const routes = Object.values(routing);
  const matchedRoute = routes.find((route) => !!pathToRegexp(route).exec(pathname));

  if (!matchedRoute) {
    return PageId.Main;
  }

  const routeIndex = routes.indexOf(matchedRoute);
  return Object.keys(routing)[routeIndex] as PageId;
};

export const useParentPage = () => {
  const pageId = usePageId();

  if ([PageId.Main, PageId.HomeDoc].includes(pageId)) {
    return 'home';
  }
  if ([PageId.Welcome, PageId.Board, PageId.Doc, PageId.DocFullPage].includes(pageId)) {
    return 'board';
  }

  if ([PageId.Inbox, PageId.InboxView, PageId.InboxDoc].includes(pageId)) {
    return 'inbox';
  }

  if ([
    PageId.Settings,
    PageId.SettingsAttributes,
    PageId.SettingsDocTypes,
    PageId.SettingsUsers,
    PageId.SettingsHierarchy,
    PageId.SettingsIntegrations,
    PageId.SettingsCustomers,
    PageId.SettingsCustomer,
    PageId.SettingsCompanies,
    PageId.SettingsCompany,
    PageId.SettingsBilling,
    PageId.SettingsWorkflows,
    PageId.BillingPlans,
  ].includes(pageId)) {
    return 'settings';
  }

  if ([PageId.BillingUpgrade, PageId.BillingDowngrade].includes(pageId)) {
    return 'billing-update';
  }

  return 'auth';
};
