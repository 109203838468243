import { CustomerFragment, OperatorIsInOrNot } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { isCustomerCompatible, isCompanyCompatible } from 'src/utils/compatibility.util';

export const useCustomerFromBoardConfig = () => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const customerIsRequired = useBoardConfig(ctx => ctx.customerIsRequired);
  const companyIsRequired = useBoardConfig(ctx => ctx.companyIsRequired);

  const customerBoardfilter = useMemo(() => boardConfig?.filterProperties.edges
    .find(filterProperty => (
      filterProperty.node.__typename === 'FilterPropertyRuleCustomer'
    )), [boardConfig]);

  const companyBoardfilter = useMemo(() => boardConfig?.filterProperties.edges
    .find(filterProperty => (
      filterProperty.node.__typename === 'FilterPropertyRuleCompany'
    )), [boardConfig]);

  const customerFilter = useMemo(() => (
    customerBoardfilter?.node.__typename === 'FilterPropertyRuleCustomer' &&
      customerBoardfilter.node.customerRule.__typename === 'RuleCustomerMultipleValues' ? customerBoardfilter.node : null
  ), [customerBoardfilter]);

  const companyFilter = useMemo(() => (
    companyBoardfilter?.node.__typename === 'FilterPropertyRuleCompany' &&
      companyBoardfilter.node.companyRule.__typename === 'RuleCompanyMultipleValues' ? companyBoardfilter.node : null
  ), [companyBoardfilter]);

  const selectedCustomers = useMemo(() => {
    if (customerFilter?.customerRule?.__typename === 'RuleCustomerMultipleValues') {
      return customerFilter?.customerRule.selectedValues.edges;
    }
    return [];
  }, [customerFilter]);

  const isCustomerCompatibleWithBoardConfig = (customer?: CustomerFragment | null) => (
    isCustomerCompatible(customer?.id ?? '', customerFilter) &&
    isCompanyCompatible(customer?.company?.id ?? '', companyFilter)
  );

  return {
    firstSelectedCustomerId:
      customerFilter?.customerRule.__typename === 'RuleCustomerMultipleValues' &&
      customerFilter?.customerRule.operator === OperatorIsInOrNot.Is
        ? selectedCustomers[0]?.node.value.id
        : null,
    isCustomerCompatibleWithBoardConfig,
    selectedCustomers,
    customerIsRequired,
    companyIsRequired,
  };
};
