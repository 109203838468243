import { GraphQLError } from 'graphql';
import { useForm, UseFormProps, Path, FieldValues } from 'react-hook-form';

import { useToaster } from '../useToaster';

export interface ErrorMap<T> {
  fieldName: Path<T>;
  messagePattern: RegExp;
}

// Error handling is not speced yet so keep it here.
// For now we match the message with the field name.
const getFieldsMutationErrors = <T extends FieldValues>(errors: readonly GraphQLError[], errorsMap: ErrorMap<T>[]) => errors.map(({ message }) => {
  const mappedError = errorsMap.find(mutationErrorMap => mutationErrorMap.messagePattern.test(message));
  return mappedError && {
    message,
    ...mappedError,
  };
});

export const useEnhancedForm = <T extends FieldValues>(formProps: UseFormProps<T>) => {
  const formHook = useForm<T>(formProps);
  const { add: addToaster } = useToaster();

  const displayFieldsErrors = (errors: readonly GraphQLError[], errorsMap: ErrorMap<T>[], showInToaster = false) => {
    const displayErrors = getFieldsMutationErrors(errors, errorsMap);
    // Some errors were not handled, avoid mixed error UI feedbacks.
    if (displayErrors.some(displayError => !displayError?.fieldName)) {
      addToaster({
        title: 'An error occured',
        message: 'Please try again later',
      });
      return;
    }
    displayErrors.forEach(displayError => {
      if (displayError?.fieldName) {
        formHook.setError(displayError.fieldName, {
          type: 'manual',
          message: displayError.message,
        });
        if (showInToaster) {
          addToaster({
            message: displayError.message,
          });
        }
      }
    });
  };

  return {
    displayFieldsErrors,
    ...formHook,
  };
};
