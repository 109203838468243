import {
  UpdateCompanyDocument,
  UpdateCompanyMutationVariables,
} from '@cycle-app/graphql-codegen';

import { useErrorToaster } from 'src/hooks/useErrorToaster';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCompanyUpdate = () => {
  const { add: addErrorToaster } = useErrorToaster();
  const [updateCompanyMutation, { loading }] = useSafeMutation(UpdateCompanyDocument, {
    onError: () => {
      addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' });
    },
  });

  const updateCompany = (variables: UpdateCompanyMutationVariables) => updateCompanyMutation({
    variables,
  });

  return {
    updateCompany,
    isLoading: loading,
  };
};
