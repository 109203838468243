import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

interface UserPreferencesState {
  isDocPreviewHintEnabled: boolean;
}

export const {
  getValue: getUserPreferences,
  setValue: setUserPreferences,
  hookState: useUserPreferences,
} = make<UserPreferencesState>({
  defaultState: {
    isDocPreviewHintEnabled: true,
  },
  localKey: LocalKey.UserPreferences,
});
