import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { CornerDownRightIcon } from '@cycle-app/ui/icons';
import { FC, useEffect } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setDocItem } from 'src/reactives/docItem.reactive';

import {
  StyledTag,
  TagContent,
  StyledDocHierarchy,
} from './DocChildren.styles';

interface Props {
  className?: string;
  doc: DocBaseFragment;
}

const DocChildren: FC<Props> = ({
  className,
  doc,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItem({ hoverDocId: null });
    }
  }, [isDropdownVisible]);

  if (doc.doctype.children?.edges.length === 0) {
    return null;
  }

  return (
    <DropdownLayer
      className={className}
      visible={isDropdownVisible}
      hide={hideDropdown}
      disableDocPreview={false}
      placement="bottom-start"
      content={(
        <StyledDocHierarchy docId={doc.id} doctypeId={doc.doctype.id} />
      )}
    >
      <StyledTag
        forceFocus={isDropdownVisible}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown();
        }}
      >
        <TagContent>
          <CornerDownRightIcon />
          {doc.childrenCount > 0 && (
            <span>{doc.childrenCount}</span>
          )}
        </TagContent>
      </StyledTag>
    </DropdownLayer>
  );
};

export default DocChildren;
