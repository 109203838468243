import { Input, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 290px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
`;

export const StyledInput = styled(Input)`
  input {
    height: 40px;
    border-radius: 6px;
    padding: 8px 12px;
    ::placeholder {
      color: ${p => p.theme.colors.text.secondary};
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const DocTypeButton = styled(Button).attrs({
  size: 'L',
  variant: 'outlined',
})`
  justify-content: flex-start;
  padding: 8px;
  height: 40px;
  color: ${p => p.theme.colors.text.secondary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  background: none;
  width: 100%;
`;
