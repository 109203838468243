import { MateFragment } from '@cycle-app/graphql-codegen';
import { EyeIcon } from '@cycle-app/ui/icons';
import { VFC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useBoard } from 'src/hooks/api/useBoard';
import { useDoc } from 'src/hooks/api/useDoc';
import { useUrl } from 'src/hooks/useUrl';
import { getBoardSlug, getDocSlug } from 'src/utils/slug.util';
import { INCOGNITO_NAME } from 'src/utils/users.util';

import { UserTooltip, User, TooltipLink, StyledAvatar } from './RealtimeUsers.styles';
import { UserLocation } from './UserLocation';

export interface Props {
  user: MateFragment;
  showLocation?: boolean;
}

const RealtimeUser: VFC<Props> = ({
  user,
  showLocation,
}) => {
  const getUrl = useUrl();

  const board = useBoard(user._boardId, !user._boardId);
  const { doc } = useDoc(user._docId, !user._docId);

  const locationUrl = doc
    ? getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) })
    : getUrl(PageId.Board, { boardSlug: board && Object.keys(board).length ? getBoardSlug(board) : '' });

  const displayedName = user.incognito ? INCOGNITO_NAME : `${user.firstName} ${user.lastName}`;

  return (
    <User key={user.id}>
      <UserTooltip
        $color={user?.color}
        title={showLocation ? displayedName : undefined}
        content={showLocation ? (
          <TooltipLink
            to={locationUrl}
            title={doc?.title || board?.name}
          >
            <EyeIcon />
            <span>
              Viewing
              {' '}
              <UserLocation user={user} />
            </span>
          </TooltipLink>
        ) : displayedName}
        placement="bottom"
        controlled={showLocation}
        interactive={showLocation}
        withPortal
      >
        <StyledAvatar
          key={user.id}
          user={user}
          pointer
        />
      </UserTooltip>
    </User>
  );
};

export default RealtimeUser;
