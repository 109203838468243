import { typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background: ${p => p.theme.colors.lightOnboarding.layout.card.bg};
  border: 1px solid ${p => p.theme.colors.border.primary};
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.06), 0px 25px 40px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 44px 25px;
  height: 236px;
  width: 100%;

  ${mobile} {
    max-width: 232px;
    padding: 25px;
    height: 150px;
    margin-bottom: 40px;
    margin-left: 12px;
    margin-right: 12px;
  }
`;

export const Avatar1 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(27%, -46%);
  width: 95px;

  ${mobile} {
    width: 70px;
  }
`;

export const Avatar2 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 130px;
  transform: translate(-40%, 35%);

  ${mobile} {
    transform: translate(-40%, 55%);
    width: 100px;
  }
`;

export const Title = styled.div`
  ${typo.headerMedium}
  color: ${p => p.theme.colors.background.active};
`;

export const Skeleton = styled(motion.div)`
  background: ${p => p.theme.colors.background.tertiary};
  border-radius: 6px;
  height: 8px;
`;

export const Skeletons = styled.div`
  > * {
    margin-top: 8px;
  }

  > :first-child, > :nth-child(2) {
    margin-top: 24px;
  }

  ${mobile} {
    > :first-child {
      display: none;
    }
  }
`;
