import { PusherProviderProps } from '@harelpls/use-pusher';

import { getAuth } from 'src/reactives/auth.reactive';

export const pusherConfig = (): PusherProviderProps => ({
  auth: {
    headers: { Authorization: `Bearer ${getAuth().token}` },
  },
  authEndpoint: `${process.env.HTTP_API_URI}/pusher/auth`,
  clientKey: process.env.PUSHER_API_KEY,
  cluster: process.env.PUSHER_CLUSTER,
});
