import { SearchIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { setCompanyProfileModal, setCustomerProfileModal } from 'src/reactives';

import { CustomersCommonTabList } from '../CustomersCommonTabList/CustomersCommonTabList';
import {
  Tabs,
  Nav,
  Actions,
  SearchInput,
} from './Customers.styles';
import { CustomersAddCustomerButton } from './CustomersAddCustomerButton';
import { CustomersListPeople } from './CustomersListPeople';

export const CustomersList = () => {
  const [searchText, setSearchText] = useState('');
  const {
    customers, fetchNextPage, loading, hasNextPage, searchCustomers,
  } = useCustomers();

  const searchCustomersDebounced = useDebouncedCallback((search: string) => searchCustomers(search), 300);

  return (
    <Tabs>
      <Nav>
        <CustomersCommonTabList />
        <Actions>
          <SearchInput
            iconBefore={<SearchIcon />}
            placeholder="Search…"
            value={searchText}
            onChange={async (e) => {
              setSearchText(e.target.value);
              await searchCustomersDebounced(e.target.value);
            }}
          />
          <CustomersAddCustomerButton />
        </Actions>
      </Nav>
      <CustomersListPeople
        customers={customers ?? []}
        searchText={searchText}
        onCustomerClick={customerId => setCustomerProfileModal({
          customerId,
          isOpen: true,
        })}
        onCompanyClick={companyId => setCompanyProfileModal({
          companyId,
          isOpen: true,
        })}
        isLoading={!searchText && loading}
        hasNextPage={hasNextPage}
        loadMore={fetchNextPage}
        actionsCellSize={50}
      />
    </Tabs>
  );
};
