import { TrashIcon } from '@cycle-app/ui/icons';

import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useInboxSection, useCurrentInboxSlug } from 'src/hooks/inbox';
import { useNavigate } from 'src/hooks/useNavigate';
import { getBoardSlug } from 'src/utils/slug.util';

import { DeleteButton } from './InboxViewSettings.styles';

export const useDeleteCurrentView = () => {
  const { navigateToInbox } = useNavigate();
  const { removeBoard } = useBoardMutations();
  const { boards } = useInboxSection();
  const currentSlug = useCurrentInboxSlug();
  return async () => {
    const index = boards.findIndex(board => currentSlug?.includes(board.id));
    if (index < 1) return;
    const currentBoard = boards[index].id;
    const prevSlug = getBoardSlug(boards[index - 1]);
    await removeBoard(currentBoard);
    navigateToInbox(prevSlug);
  };
};

export const InboxDeleteView = () => {
  const deleteCurrentView = useDeleteCurrentView();
  return (
    <DeleteButton
      size="M"
      variant="warning-secondary"
      iconStart={<TrashIcon />}
      onClick={deleteCurrentView}
    >
      Delete view
    </DeleteButton>
  );
};
