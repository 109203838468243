import { useQuery } from '@apollo/client';
import {
  IntegrationType,
  FetchNotionPageByIdDocument,
  NotionMinimalPageFragment,
  NotionElementType,
  NotionMinimalDatabaseFragment,
} from '@cycle-app/graphql-codegen/generated';
import { useMemo } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';

export const useNotionPage = (page: NotionMinimalPageFragment | NotionMinimalDatabaseFragment) => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Notion);
  const integrationId = integration?.id;
  const isActive = !!integration?.provider;

  const {
    data, loading: isLoading,
  } = useQuery(FetchNotionPageByIdDocument, {
    variables: {
      integrationId: integrationId ?? '',
      input: {
        id: page.id,
        type: page.__typename === 'NotionPage' ? NotionElementType.Page : NotionElementType.Database,
      },
    },
    skip: !integrationId || !isActive,
  });

  const result = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Notion'
      ) return null;
      return data.node.provider.search?.[0];
    },
    [data],
  );

  return {
    page: result,
    isLoading,
  };
};
