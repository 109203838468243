import { CloseIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useFullDoc } from 'src/hooks/api/useDoc';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';

import { EditorContainer, StyledDocAttributes } from '../StepEditDocs/StepEditDocs.styles';
import {
  Layer, StyledReadOnlyEditor, Title, StyledContent, Header, StyledContainer, StyledCloseButton,
} from './DocPreview.styles';

interface Props {
  docId: string;
  onClose: VoidFunction;
}

export const DocPreview: FC<Props> = ({
  docId, onClose,
}) => {
  const { doc } = useFullDoc({ docId });
  const {
    yDoc, isSync,
  } = useRealtimeEditorConfig({ docId });
  return (
    <Layer
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: { duration: 0.2 },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.2 },
      }}
    >
      <StyledContainer>
        <Header>
          <StyledCloseButton onClick={onClose}>
            <CloseIcon />
          </StyledCloseButton>
        </Header>
        <StyledContent>
          <EditorContainer>
            <Title>{doc?.title}</Title>
            {doc && <StyledDocAttributes doc={doc} readOnly />}
            {yDoc && isSync && (
              <StyledReadOnlyEditor
                interactive={false}
                collaboration={{ document: yDoc }}
              />
            )}
          </EditorContainer>
        </StyledContent>
      </StyledContainer>
    </Layer>
  );
};
