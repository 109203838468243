import { make } from 'src/utils/reactives.util';

type GeneralPermissions =
  'canInviteAdmin' |
  'canInviteMaker' |
  'canInviteCollaborator' |
  'canDeleteAdmin' |
  'canCreateBoard' |
  'canUpdateBoard' |
  'canDeleteBoard' |
  'canCreateBoardSection' |
  'canUpdateBoardSection' |
  'canDeleteBoardSection' |
  'canReorderBoards' |
  'canCreateGroup' |
  'canUpdateGroup' |
  'canReorderGroups' |
  'canReadPlayground' |
  'canReadSettings' |
  'canUpdateUserRoleCollaborator' |
  'canUpdateUserRoleMaker' |
  'canUpdateUserRoleAdmin' |
  'canUpgradeBilling';

export const {
  hookValue: useGetPermission,
  setValue: setPermission,
  resetValue: resetPermission,
} = make<Record<GeneralPermissions, boolean>>({
  defaultState: {
    canInviteAdmin: false,
    canInviteMaker: false,
    canInviteCollaborator: true,
    canDeleteAdmin: false,
    canCreateBoard: false,
    canUpdateBoard: false,
    canDeleteBoard: false,
    canCreateBoardSection: false,
    canUpdateBoardSection: false,
    canDeleteBoardSection: false,
    canReorderBoards: false,
    canCreateGroup: false,
    canUpdateGroup: false,
    canReorderGroups: false,
    canReadPlayground: false,
    canReadSettings: false,
    canUpdateUserRoleCollaborator: false,
    canUpdateUserRoleMaker: false,
    canUpdateUserRoleAdmin: false,
    canUpgradeBilling: false,
  },
});

type BillingPermissions =
  'canCurrentPlanDeleteMaker' |
  'canCurrentPlanUpdateMaker' |
  'canGoIncognito' |
  'canUploadNotRestricted' |
  'canPlanCreateBoardSection' |
  'canPlanCreateBoard';

export const {
  hookValue: useGetBillingPermission,
  setValue: setBillingPermission,
  resetValue: resetBillingPermission,
} = make<Record<BillingPermissions, boolean>>({
  defaultState: {
    canCurrentPlanDeleteMaker: false,
    canCurrentPlanUpdateMaker: false,
    canGoIncognito: false,
    canUploadNotRestricted: false,
    canPlanCreateBoardSection: false,
    canPlanCreateBoard: false,
  },
});

type IntegrationPermissions = 'canAddIntegration';

export const {
  hookValue: useGetIntegrationPermission,
  setValue: setIntegrationPermission,
  resetValue: resetIntegrationPermission,
} = make<Record<IntegrationPermissions, boolean>>({
  defaultState: {
    canAddIntegration: false,
  },
});

export const resetAllPermissions = () => {
  resetPermission();
  resetBillingPermission();
  resetIntegrationPermission();
};

export const useGetAllPermissions = () => ({
  ...useGetPermission(),
  ...useGetBillingPermission(),
  ...useGetIntegrationPermission(),
});
