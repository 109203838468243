import BoardEditCommonModal from 'src/components/BoardEditCommonModal/BoardEditCommonModal';
import { BoardEditCommonFormData } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { PageId } from 'src/constants/routing.constant';
import useForkBoardMutation from 'src/hooks/api/mutations/useForkBoardMutation';
import { useBoard } from 'src/hooks/api/useBoard';
import { useNavigate } from 'src/hooks/useNavigate';
import { getBoardSlug } from 'src/utils/slug.util';

interface DuplicateBoardModalProps {
  boardId: string;
  onHide: VoidFunction;
  boardConfigId?: string;
  onDone?: VoidFunction;
}

const DuplicateBoardModal = ({
  boardId, onHide, onDone, ...props
}: DuplicateBoardModalProps) => {
  const board = useBoard(boardId);
  const { navigate } = useNavigate();

  const {
    forkBoardFromBoardConfig,
    loadingForkBoard,
  } = useForkBoardMutation(board?.id);

  if (!board) return null;

  return (
    <BoardEditCommonModal
      defaultValues={{
        ...board,
        name: `Copy of ${board.name}`,
      }}
      title="Duplicate view"
      onHide={onHide}
      onSubmit={onSubmit}
      loading={loadingForkBoard}
    />
  );

  async function onSubmit({
    name, description, emoji,
  }: BoardEditCommonFormData) {
    onHide();

    const boardConfigId = props.boardConfigId ?? board?.publishedBoardConfig?.id;

    if (!boardConfigId) return;

    const mutation = await forkBoardFromBoardConfig({
      boardConfigId,
      name,
      description,
      emoji,
    });

    onDone?.();

    const boardConfig = mutation.data?.addBoardFromBoardConfig;
    if (boardConfig == null) return;

    const boardSlug = getBoardSlug(boardConfig);
    navigate(PageId.Board, { boardSlug });
  }
};

export default DuplicateBoardModal;
