import { ShinyItem, transitionCss, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Workspace = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const Logo = styled.div<{ bgSrc?: string }>`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  border-radius: 4px;
  background-image: ${p => (p.bgSrc ? `url(${p.bgSrc})` : 'none')};
  background-size: cover;
  background-position: center;
  transition: width .2s ease-in-out, height .2s ease-in-out;
`;

export const PageName = styled.div`
  font-size: 18px;
  font-weight: 600;
  transform: translateY(-1px); /* 1px transform for perseptive center */
  user-select: none;

  display: flex;
  align-items: center;
  min-width: 0;
  color: ${p => p.theme.colors.sidebar.color};

  h1 {
    ${typo.body500}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${p => p.theme.colors.sidebar.header.title.color};
    line-height: 17px;
  }
`;

export const Caret = styled.div`
  display: flex;
  color: ${p => p.theme.colors.sidebar.header.item.caret.color};
  ${transitionCss()};
  svg {
    width: 12px;
  }
`;

export const Item = styled.div<{ disabled?: boolean; $collapsed: boolean }>`
  background-color: ${p => p.theme.colors.sidebar.header.item.bg};
  min-width: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 5px;
  padding: ${p => (p.$collapsed ? 6 : 4)}px;
  ${transitionCss()};

  ${p => !p.disabled && css`
    cursor: pointer;

    &.force-focus, &:hover {
      background-color: ${p.theme.colors.sidebar.header.item.bgHover};
      ${Caret} {
        color: ${p.theme.colors.sidebar.header.item.caret.colorHover};
      }
    }
  `};

  ${p => p.disabled && `
    ${Caret} {
      display: none;
    }
  `};
`;

export const ItemLoading = styled(ShinyItem).attrs({ $sidebar: true }) <{ $entity: 'product' | 'user' }>`
  width: ${p => (p.$entity === 'user' ? 60 : 140)}px;
`;

interface ContainerProps {
  $collapsed?: boolean;
}

export const Container = styled.section<ContainerProps>`
  margin-bottom: 12px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 4px;

  transition: background-color .2s ease-in-out;
  background-color: ${(props) => props.theme.colors.sidebar.header.bg};

  ${p => p.$collapsed && css`
    margin-bottom: 2px;
    height: auto;
    flex-direction: column;
    gap: 10px;

    ${PageName},
    ${Caret} {
      display: none;
    }

    ${Logo} {
      margin-right: 0;
    }
  `};

  > *:first-child {
    min-width: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;

  > div {
    max-width: 100%;
  }
`;
