import { ReactNode } from 'react';

import { BILLING_LIMIT_BOARDS, BILLING_LIMIT_DOCS, BILLING_LIMIT_INTEGRATIONS } from 'src/constants/billing.constants';
import { FILE_SIZE_LIMIT_RESTRICTED } from 'src/constants/upload.constant';
import { getFileSizeWithUnit } from 'src/utils/files.util';

const UNLIMITED = 'Unlimited';

export enum Status {
  soon = 'Coming soon',
  new = 'New',
  beta = 'Beta',
}

type Features = {
  name: string;
  helper?: ReactNode;
  features: {
    name: string;
    status?: Status;
    tooltip?: ReactNode;
    plans: ({
      value: ReactNode;
    } | null)[];
  }[];
}[];

export const features: Features = [{
  name: 'Usage',
  features: [{
    name: 'Docs / month',
    tooltip: 'Cycle is built around the collaborative doc. Feedback, insights, initiatives are all docs.',
    plans: [{ value: BILLING_LIMIT_DOCS }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Workspaces',
    tooltip: 'Your workspace is the full-on repository of all docs and views for your project.',
    plans: [{ value: 1 }, { value: 1 }, { value: UNLIMITED }],
  }, {
    name: 'Makers',
    tooltip: 'Makers are working on the processes and settings within Cycle. Typically, PMs are makers.',
    plans: [{ value: UNLIMITED }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Collaborators',
    tooltip: (
      <>
        Collaborators enrich the maker&apos;s work and can create, edit and collaborate on docs.Typically designers, developers are collaborators.
      </>
    ),
    plans: [{ value: UNLIMITED }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Guests',
    status: Status.soon,
    plans: [{ value: UNLIMITED }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Integrations',
    status: Status.new,
    tooltip: 'Bring all your customer feedback from various sources into Cycle through our library of integrations.',
    plans: [{ value: BILLING_LIMIT_INTEGRATIONS }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Views',
    tooltip: 'Views are filtered views on the data model. Typically views will be User Research, Roadmap, Feedback loop, ...',
    plans: [{ value: BILLING_LIMIT_BOARDS }, { value: UNLIMITED }, { value: UNLIMITED }],
  }],
}, {
  name: 'Collaboration',
  features: [{
    name: 'Real-time collaboration',
    tooltip: 'Edits are synced in real-time. Collaborate with your team seamlessly.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Comments',
    tooltip: 'Collaborate and iterate with your team by leaving comments in docs.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'User mentions',
    tooltip: 'Mention any teammate in docs to notify or assign them on specific topics.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Incognito mode',
    tooltip: 'Need some me-time? Switch to Incognito to get off the collaboration radar for a time!',
    plans: [null, { value: true }, { value: true }],
  }, {
    name: 'Starred views',
    status: Status.new,
    tooltip: 'Keep your most used views at hand!',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Private views',
    status: Status.soon,
    tooltip: 'Use private views to run specific analysis or display information relevant to you only.',
    plans: [null, { value: true }, { value: true }],
  }],
}, {
  name: 'Features',
  helper: 'Coming soon: Public roadmap & change log, Activity log, Customer tags',
  features: [{
    name: 'Inbox',
    status: Status.new,
    tooltip: 'The Inbox centralizes customer feedback from all sources in one place, so you can process it seamlessly.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Search and commands',
    tooltip: 'Looking for something? Use the command panel to navigate your workspace seamlessly.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Customers',
    tooltip: 'Sync your customers from all sources, and always keep the link between customer insights and product initiatives.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Bulk actions',
    tooltip: 'Edit multiple docs in bulk.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Embeds',
    tooltip: 'Embed URLs to visualise its content directly in your docs.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: '20+ content templates',
    tooltip: 'Use content templates from a library of inspiring companies, or create your own.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Personalized themes',
    tooltip: 'Personalize Cycle to your liking: dark mode, colours — your choice!',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Hierarchy',
    tooltip: 'Cycle adapts to your processes. Organize your product information as you see it.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'CSV import',
    tooltip: 'Import any product data (feedback, backlog) you already have.',
    status: Status.new,
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Cover pictures',
    tooltip: 'Make your docs and views visual by adding cover pictures.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Doc preview',
    tooltip: <>Quickly preview any doc&apos;s content with a simple shortcut.</>,
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Doc mentions',
    tooltip: 'Refer to any doc by simply mentioning it, wherever you want.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Public-api',
    status: Status.beta,
    tooltip: 'Automate your workflows on top of Cycle.',
    plans: [null, { value: true }, { value: true }],
  }, {
    name: 'File uploads',
    tooltip: 'Upload any file in your docs to add more context.',
    plans: [{ value: `Up to ${getFileSizeWithUnit(FILE_SIZE_LIMIT_RESTRICTED, true)} per file` }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Image uploads',
    tooltip: 'Upload any media file (image, video) in your docs to add more context.',
    plans: [{ value: `Up to ${getFileSizeWithUnit(FILE_SIZE_LIMIT_RESTRICTED, true)} per image` }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Transcripts',
    tooltip: 'Have your calls and interviews transcribed as text in your docs, so you can process them seamlessly.',
    status: Status.soon,
    plans: [{ value: '2h / month' }, { value: UNLIMITED }, { value: UNLIMITED }],
  }, {
    name: 'Custom sections',
    tooltip: 'Organize you space by grouping views in sections.',
    plans: [null, { value: true }, { value: true }],
  }],
}, {
  name: 'Admin & security',
  helper: 'Coming soon: CSV exports, Audit logs, SAML based SSO, Advanced permissions, Advanced security controls.',
  features: [{
    name: 'Workflow management',
    tooltip: 'You decide how your teammates will be using Cycle by setting up your workspace accordingly.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Customizable notifications',
    tooltip: 'Notifications help you keep everyone up to date.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Duplicate workspaces',
    tooltip: 'Keep your current setup when starting a new project.',
    plans: [null, null, { value: true }],
  }, {
    name: 'Advanced user management',
    tooltip: 'Assign specific roles to your teammates.',
    plans: [null, null, { value: true }],
  }, {
    name: 'Custom contracts and invoicing',
    tooltip: <>We want to make sure billing and admin doesn&apos;t get in your way.</>,
    plans: [null, null, { value: true }],
  }],
}, {
  name: 'Support',
  features: [{
    name: 'Cycle Community',
    tooltip: 'Join an insightful community of product leaders in global fast-growing companies.',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Cycle Help Center',
    tooltip: 'Become a master user in Cycle!',
    plans: [{ value: true }, { value: true }, { value: true }],
  }, {
    name: 'Onboarding & setup support',
    tooltip: 'Get a head-start by having a startup call with us.',
    plans: [null, { value: true }, { value: true }],
  }, {
    name: 'Premium support',
    tooltip: 'Reach out to us within the app and get instant assistance.',
    plans: [null, { value: true }, { value: true }],
  }, {
    name: 'Dedicated customer success manager',
    tooltip: 'Our customer success team is there to help you get the most out of Cycle.',
    plans: [null, null, { value: true }],
  }],
}];
