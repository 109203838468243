import { ALL_DOCS_BOARD_SLUG } from 'src/constants/routing.constant';
import { useGetBoardParams } from 'src/reactives/boardParams.reactive';

import { usePathParams } from './usePathParams';

type AppParams = {
  productId: string | null | undefined;
} & (
  {
    context: 'default-product-alldocs-board';
    productSlug: null | undefined;
    boardSlug: string | null | undefined;
    boardId: string | null | undefined;
    docId: string | null | undefined;
  } | {
    context: 'alldocs-board';
    productSlug: string;
    boardSlug: 'all-docs' | null | undefined;
    boardId: null | undefined;
    docId: string | null | undefined;
  } | {
    context: 'alldocs-board-doc-panel';
    productSlug: string;
    boardSlug: 'all-docs';
    boardId: null | undefined;
    docId: string | null | undefined;
  } | {
    context: 'specific-board';
    productSlug: string;
    boardSlug: string;
    boardId: string | undefined;
    docId: null | undefined;
  } | {
    context: 'specific-board-doc-panel';
    productSlug: string;
    boardSlug: string;
    boardId: string | undefined;
    docId: string;
  } | {
    context: 'doc-fullpage';
    productSlug: string;
    boardSlug: null | undefined;
    boardId: null | undefined;
    docId: string;
  });

export const useAppParams = (): AppParams => {
  const {
    productSlug: productSlugFromParams,
    boardSlug,
    docSlug,
  } = usePathParams();

  const {
    productId: forceProductId,
    productSlug: forceProductSlug,
  } = useGetBoardParams();

  const productSlug = forceProductSlug ?? productSlugFromParams ?? undefined;
  const productId = forceProductId;
  const docId = parseParam(docSlug);
  const boardId = parseParam(boardSlug);

  // Typescript inference is not strong enough to use a variable to regroup returned data
  if (productSlug === undefined) {
    return {
      context: 'default-product-alldocs-board',
      productId,
      productSlug,
      boardSlug,
      boardId,
      docId,
    };
  }

  if (boardSlug === undefined) {
    return docId
      ? {
        context: 'doc-fullpage',
        productId,
        productSlug,
        boardSlug,
        boardId: undefined,
        docId,
      } : {
        context: 'alldocs-board',
        productId,
        productSlug,
        boardSlug,
        boardId: undefined,
        docId,
      };
  }
  if (boardSlug === ALL_DOCS_BOARD_SLUG) {
    return docId
      ? {
        context: 'alldocs-board-doc-panel',
        productId,
        productSlug,
        boardSlug,
        boardId: null,
        docId,
      }
      : {
        context: 'alldocs-board',
        productId,
        productSlug,
        boardSlug,
        boardId: null,
        docId,
      };
  }

  return docId === undefined
    ? {
      context: 'specific-board',
      productId,
      productSlug,
      boardSlug,
      boardId,
      docId,
    } : {
      context: 'specific-board-doc-panel',
      productId,
      productSlug,
      boardSlug,
      boardId,
      docId,
    };
};

export const parseParam = (boardSlug: string | undefined) => {
  if (!boardSlug) {
    return undefined;
  }

  if (boardSlug === ALL_DOCS_BOARD_SLUG) {
    return boardSlug;
  }

  const splittedParam = boardSlug.split('-');
  return splittedParam[splittedParam.length - 1];
};
