import { removeAccents } from '@cycle-app/utilities';
import { Node, mergeAttributes } from '@tiptap/core';
import { Editor, ReactNodeViewRenderer, ReactRenderer } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';
import { FC } from 'react';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import MentionName from 'src/components/Editor/NodeViews/Mention/MentionView';
import { COMMANDS } from 'src/constants/editor.constants';
import { suggestionPlugin } from 'src/plugins/suggestionPlugin';
import { People } from 'src/types/editor.types';
import { Layer } from 'src/types/layers.types';
import { getTippyPopup, onKeyDown } from 'src/utils/editor/tippy.utils';

import MentionDropdown from './MentionUserDropdown';

const SUGGESTION_LENGTH = 5;

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

interface MentionParams {
  readOnly?: boolean;
  people: People;
  onUserMentioned?: (userId: string) => void;
}

const extensionName = 'mention';
export const MENTION_EXTENSION_TAGNAME = 'cycle-editor-mention';

const getMentionExtension = ({
  readOnly = false,
  people,
  onUserMentioned,
}: MentionParams) => Node.create<MentionOptions>({
  key: extensionName,
  name: extensionName,

  addOptions: () => ({
    HTMLAttributes: {
      class: extensionName,
    },
    suggestion: {
      char: COMMANDS.USER_MENTION,

      items: ({ query }) => filterItemsFromQuery(query, people),

      allowSpaces: false,

      command: ({
        editor,
        range,
        props,
      }) => {
        if (!props.id) return;
        onUserMentioned?.(props.id);
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: extensionName,
              attrs: props,
            },
            {
              type: 'text',
              text: ' ',
            },
          ])
          .run();
      },

      allow: ({
        editor,
        range,
      }) => editor.can().insertContentAt(range, { type: extensionName }),

      render: () => {
        // To avoid rendering the dropdown if last char is @ in readonly editor
        if (readOnly) return {};

        let reactRenderer: ReactRenderer;
        let popup: TippyInstance<TippyProps>[];

        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionDropdown as FC, {
              props: {
                ...props,
                hide: () => {
                  popup[0].hide();
                },
              },
              editor: props.editor as Editor,
            });

            popup = getTippyPopup({
              props,
              reactRenderer,
              layer: Layer.DropdownZ1,
              options: {
                hideOnClick: false,
                onHide: () => {
                  if (props.editor) {
                    const state = {
                      active: false,
                      key: null,
                      range: {},
                      query: null,
                      text: null,
                      composing: false,
                    };
                    props.editor.view.dispatch(
                      props.editor.view.state.tr.setMeta(
                        // @ts-ignore
                        props.pluginKey,
                        state,
                      ),
                    );
                  }
                },
              },
            });
          },
          onUpdate(props) {
            reactRenderer.updateProps(props);
            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          },
          onKeyDown(props) {
            if (props.event.key === 'Tab' || props.event.key === 'ArrowRight') {
              if (popup.length && !popup[0].state.isDestroyed) {
                props.event.stopImmediatePropagation();
                popup[0].hide();
              }
              return true;
            }

            return onKeyDown({
              ...props,
              popupInstance: popup[0],
            });
          },
          onExit() {
            popup[0].destroy();
            reactRenderer.destroy();
          },
        };
      },
    },
  }),

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: { default: null },
    };
  },

  parseHTML() {
    return [
      { tag: MENTION_EXTENSION_TAGNAME },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      MENTION_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MentionName);
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => this.editor.commands.command(({
        tr, state,
      }) => {
        let isMention = false;
        const { selection } = state;
        const {
          empty, anchor,
        } = selection;

        if (!empty) {
          return false;
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true;
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize);

            return false;
          }
          return false;
        });

        return isMention;
      }),
    };
  },

  addProseMirrorPlugins() {
    return [
      suggestionPlugin({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${extensionName}`),
      }),
    ];
  },

});

function filterItemsFromQuery(query: string | null, people: People): People {
  const searchQuery = removeAccents((query ?? '').toLowerCase());
  return people
    .filter((item) => removeAccents(`${item.firstName} ${item.lastName}`).toLowerCase().includes(searchQuery))
    .slice(0, SUGGESTION_LENGTH);
}

export default getMentionExtension;
