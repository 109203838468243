import { ThemeType } from '@cycle-app/graphql-codegen/generated';
import { ShortcutComponents } from '@cycle-app/ui';
import { UnionBoxIcon, AddIcon } from '@cycle-app/ui/icons';
import { rgba, lighten } from 'polished';
import styled, { css } from 'styled-components';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';

export const CreateDocIconContainer = styled.div`
  border-radius: 4px;
  background-color: ${p => rgba(p.theme.userColors.main, 0.2)};
  padding: 4px;
  display: flex;

  svg {
    width: 8px;
    height: 8px;
  }
`;

export const CreateNewDocNavigationItem = styled(CollapsableNavigationItem)<{
  $isSidebarCollapsed: boolean;
  $colorTheme: ThemeType;
}>`
  font-weight: 500;
  &, svg {
    color: ${p => (p.$colorTheme === ThemeType.Eclipse ? lighten(0.15, p.theme.userColors.main) : p.theme.userColors.main)};
  }

  &:hover {
    background-color: ${p => rgba(p.theme.userColors.main, 0.2)};
    color: ${p => p.theme.userColors.main};

    ${CreateDocIconContainer} {
      background-color: ${p => p.theme.userColors.main};
      &, svg {
        color: ${p => p.theme.colors.text.white};
      }
    }

    ${ShortcutComponents.Key} {
      background-color: ${p => rgba(p.theme.userColors.main, 0.2)};
      color: ${p => p.theme.userColors.main};
    }
  }

  ${p => p.$isSidebarCollapsed && css`
    background-color: ${rgba(p.theme.userColors.main, 0.2)};

    ${CreateDocIconContainer} {
      background-color: transparent;
      padding: 2px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &:hover {
      background-color: ${p.theme.userColors.main};

      ${CreateDocIconContainer} {
        background-color: transparent;
      }
    }
  `}
`;

export const CreateNewPlaceholder = styled.div`
  width: 80px;
  height: 20px;
  position: relative;

  &::before {
    background-color: ${p => p.theme.userColors.main};
    border-radius: 8px;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
  }
`;

export const StyledUnionBoxIcon = styled(UnionBoxIcon)`
  width: 16px;
  height: 16px;
`;

export const AddMemberIcon = styled(AddIcon)`
  width: 10px;
  height: 10px;
  margin: 3px;
`;
