import { CycleLogo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const Content = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Body = styled(motion.div)`
  text-align: center;
  margin-top: 40px;
`;

export const StyledButton = styled(NextButton)`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;

export const LogoRotate = styled(motion.div)`
  width: 100px;
  height: 100px;
`;

export const StyledLogo = styled(CycleLogo)`
  width: 100px;
  height: 100px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
