import { useMemo, useCallback } from 'react';

import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { getBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { setBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutGroupOptions } from 'src/types/shortcuts.types';

interface Params {
  groupId: string;
  onCreateDocTriggered: VoidFunction;
  enabled?: boolean;
}

export const useGroupOptionsShortcutListener = ({
  groupId,
  enabled = true,
  onCreateDocTriggered,
}: Params) => {
  const boardGroupCallbacks = useBoardGroupShortcutCallbacks();

  const callbacks = useMemo(() => ({
    ...boardGroupCallbacks,
    [ShortcutGroupOptions.CreateDoc]: () => {
      if (getActiveProductTour()) return;

      boardGroupCallbacks[ShortcutGroupOptions.CreateDoc]();
      onCreateDocTriggered();
    },
  }), [boardGroupCallbacks, onCreateDocTriggered]);
  const preventCallback = useCallback(() => getBoardGroupState().hoverGroupId !== groupId, [groupId]);

  useHotkeyListener({
    callbacks,
    enabled,
    shortcuts: Object.values(ShortcutGroupOptions),
    disableOnLayers: [Layer.DocPanel, Layer.Dropdown, Layer.Modal],
    preventCallback,
  });
};

export const useBoardGroupShortcutCallbacks = () => useMemo(() => ({
  [ShortcutGroupOptions.CreateDoc]: () => setBoardNewDocPositionState({
    draftPosition: 'top',
    groupId: getBoardGroupState().hoverGroupId,
  }),
}), []);
