import { ApolloProvider } from '@apollo/client';
import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ThemeProvider from 'src/providers/ThemeProvider';
import client from 'src/services/apollo/client';
import { FeatureFlagProvider } from 'src/services/featureFlag/FeatureFlagProvider';

const AppProviders: FC = ({ children }) => (
  <FeatureFlagProvider>
    <ApolloProvider client={client}>
      <ThemeProvider>
        <Router>
          {children}
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  </FeatureFlagProvider>
);

export default AppProviders;
