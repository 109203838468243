import { Emoji } from '@cycle-app/ui';
import { useReducer, ReactNode, useState } from 'react';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useBoardStar } from 'src/hooks/api/mutations/boards/useBoardStar';
import { useBoardsStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';
import { useBoardSections } from 'src/hooks/api/useBoardSections';

type Position = {
  x: number;
  y: number;
};

export const HomeAddStarBoardDropDown = ({ children }: { children: (props: {
  visible: boolean;
  toggle: VoidFunction;
  setCursorPosition: (p: Position) => void;
}) => ReactNode; }) => {
  const [visible, toggle] = useReducer(b => !b, false);
  const [cursorPosition, setCursorPosition] = useState<Position | null>(null);

  const { boards: starredBoards } = useBoardsStarred();

  const { mappingBoards } = useBoardSections();
  const { star } = useBoardStar();

  const boards = Object.values(mappingBoards);
  const options = boards
    .filter(b => {
      const starredBoardIds = starredBoards.map(sb => sb.id);
      return !starredBoardIds.includes(b.id);
    })
    .map(b => ({
      icon: <Emoji emoji={b.emoji || ''} />,
      label: b.name,
      value: b.id,
    }));

  if (!options.length) return null;

  return (
    <DropdownSelectLayer
      visible={visible}
      hide={toggle}
      options={options}
      placement="right-start"
      onChange={(option) => {
        toggle();
        return star(option.value);
      }}
      overridePosition={cursorPosition ? {
        width: 100,
        height: 100,
        left: cursorPosition.x - 100,
        right: 0,
        top: cursorPosition.y,
        bottom: 0,
      } : undefined}
    >
      {children({
        setCursorPosition,
        visible,
        toggle,
      })}
    </DropdownSelectLayer>
  );
};
