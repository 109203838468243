import { ToggleSlackBotFeedbackNotificationsDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useSlackToggleFeedbackNotifications = (providerId?: string) => {
  const [mutate, {
    loading,
    error,
  }] = useSafeMutation(ToggleSlackBotFeedbackNotificationsDocument, {
    variables: {
      providerId,
    },
  });

  return {
    toggle: mutate,
    isLoading: loading,
    error,
  };
};
