import { typo, ShyScrollbar } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  max-height: calc(100vh - 74px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 424px;
`;

export const Title = styled.div`
  ${typo.headerSmall};
`;

export const Lines = styled(ShyScrollbar).attrs({ $overflow: 'auto' })`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContainerProperty = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkNewContainer = styled.div`
  display: flex;
`;
