import { getDocIdFromUrl } from '@cycle-app/utilities';
import { useEffect, useRef } from 'react';
import { render } from 'react-dom';
import tippy, { Instance } from 'tippy.js';
import { useDebounce } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import AppProviders from 'src/app/AppProviders';
import InternalAnchorTooltip from 'src/components/InternalAnchorTooltip/InternalAnchorTooltip';

export const CLASSNAME_EDITOR_ANCHOR = 'cycle-editor-anchor';

type UseInternalAnchorTippy = (p: {
  docContent: string;
}) => void;

export const useDirtyInternalAnchorTippy: UseInternalAnchorTippy = ({ docContent }) => {
  const [docContentDebounce, { cancel }] = useDebounce(docContent, 800);

  const instances = useRef<Record<string, Instance>>({});
  const previousContent = useRef('');

  useEffect(() => {
    if (docContentDebounce !== previousContent.current) {
      Object.keys({ ...instances.current }).forEach((instanceId) => {
        const instance = instances.current[instanceId];
        instance.destroy();
        delete instances.current[instanceId];
      });

      const internalAnchors = document.getElementsByClassName(CLASSNAME_EDITOR_ANCHOR);
      Array.from(internalAnchors).forEach((anchorElement) => {
        const href = anchorElement.getAttribute('href');
        if (!href || href === anchorElement.innerHTML) return;

        const element = document.createElement('div');
        render((
          <AppProviders>
            <InternalAnchorTooltip href={href} />
          </AppProviders>
        ), element);

        instances.current[uuid()] = tippy(anchorElement, {
          content: element,
          maxWidth: 600,
          duration: [300, 0],
          placement: getDocIdFromUrl(href) ? 'bottom' : 'top',
        });
      });

      previousContent.current = docContentDebounce;
    }

    return () => cancel();
  }, [docContentDebounce, cancel]);
};
