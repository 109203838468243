import { transitionCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Path = styled.path<{
  opacity: number;
  isActive?: boolean;
  asPlaceholder?: boolean;
  color?: string;
}>`
  opacity: ${p => p.opacity};
  stroke-dasharray: ${p => (p.asPlaceholder ? 5 : 0)};
  stroke-width: 2;
  stroke: ${p => (p.color || (p.isActive ? p.theme.userColors.main : p.theme.colors.flowPath.stroke))};
  ${transitionCss(['opacity'])}
`;
