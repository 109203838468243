import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { Button, typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Children = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  padding-bottom: 24px;

  display: flex;
  justify-content: space-between;

  h1 {
    ${typo.headerMedium};
  }

  button {
    margin-left: 24px;
  }
`;

export const ModalButton = styled(Button)`
  margin: auto;
`;

export const ModalContent = styled.div`
  margin: 24px 0;
  text-align: center;
`;

export const Image = styled.img<{ previewBorderColor?: ColorApi }>`
  --image-size: 317px;

  border-radius: 50%;
  width: var(--image-size);
  height: var(--image-size);

  box-shadow: 0 0 0 4px ${p => (p.previewBorderColor
    ? p.theme.nuances[p.previewBorderColor].main
    : p.theme.userColors.main)};

  object-fit: cover;
`;

export const Mask = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: ${(props) => transparentize(0.5, props.theme.colors.modal.mask.bg)};
`;
