import { UserJourney } from '@cycle-app/graphql-codegen';

import { TOURS, STEP } from 'src/constants/productTour.constant';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useUpdateMe } from 'src/hooks/user/useUpdateMe';
import { TourName, TourStep, ProductToursState } from 'src/types/productTour.types';
import { make } from 'src/utils/reactives.util';

export const {
  getValue: getTours,
  setValue: setTours,
  hookValue: useTours,
  resetValue: resetTours,
} = make<ProductToursState>({
  defaultState: {
    active: undefined,
    tours: TOURS,
  },
});

/// /////////////////
// HOOKS
/// /////////////////
export const useActiveProductTour = () => {
  const {
    active, tours,
  } = useTours();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);

  return isEnabled && active?.name
    ? tours.find(t => t.name === active.name) || null
    : null;
};

export const useStartTour = () => {
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);

  return (tourName: TourName, el?: HTMLElement) => {
    if (!isEnabled) return;

    setTours({
      active: {
        name: tourName,
        el,
      },
    });
  };
};

export const useFinishTour = () => {
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);
  const activeTour = getActiveProductTour();
  const { updateMe } = useUpdateMe();

  return (tourName: TourName) => {
    if (!isEnabled || !activeTour || activeTour.name !== tourName) return;

    setTours({
      active: undefined,
    });
    updateMe({
      userJourney: UserJourney.Gamification,
    }).catch(console.warn);
  };
};

export const useActiveProductTourStep = () => {
  const tour = useActiveProductTour();

  return tour?.step ?? null;
};

/// /////////////////
// GETTERS
/// /////////////////
const getAllTours = () => getTours().tours;
const getActive = () => getTours().active;

export const getActiveProductTour = () => {
  const {
    active, tours,
  } = getTours();

  return active?.name
    ? tours.find(t => t.name === active.name) || null
    : null;
};

export const getActiveProductTourEl = () => {
  const { active } = getTours();

  return active?.el || null;
};

export const getActiveProductTourStep = () => {
  const activeTour = getActiveProductTour();

  return activeTour ? activeTour.steps[activeTour.step] : null;
};

export const getActiveProductTourStepDetails = () => {
  const activeTour = getActiveProductTour();

  return activeTour ? STEP[activeTour.steps[activeTour.step]] : null;
};

export const getActiveProductTourNextStep = () => {
  const activeTour = getActiveProductTour();

  if (!activeTour) return null;

  const currentStepIndex = activeTour.steps.findIndex(s => s === activeTour.step);
  const nextStepIndex = currentStepIndex + 1 === activeTour.steps.length ? null : currentStepIndex + 1;

  return nextStepIndex ? activeTour.steps[nextStepIndex] : null;
};

export const getActiveProductTourPreviousStep = () => {
  const activeTour = getActiveProductTour();

  if (!activeTour) return null;

  const currentStepIndex = activeTour.steps.findIndex(s => s === activeTour.step);
  const previousStepIndex = currentStepIndex === 0 ? null : currentStepIndex - 1;

  return previousStepIndex ? activeTour.steps[previousStepIndex] : null;
};

/// /////////////////
// SETTERS
/// /////////////////
type setActiveProductTourParams = {
  el?: HTMLElement;
  step?: TourStep;
};

export const setActiveProductTour = ({
  el,
  step,
}: setActiveProductTourParams) => {
  if (!step && !el) return;

  const activeTour = getActiveProductTour();

  if (!activeTour) return;

  const tourUpdated = {
    ...activeTour,
    step: step && activeTour.steps.includes(step) ? step : activeTour.step,
  };

  const elUpdated = el || getActive()?.el;

  setTours({
    tours: [
      ...getAllTours().filter(t => t.name !== activeTour.name),
      tourUpdated,
    ],
    active: {
      name: activeTour.name,
      el: elUpdated,
    },
  });
};

export const resetTour = (tourName: TourName) => {
  const activeTour = getActiveProductTour();

  if (activeTour?.name !== tourName) return;

  const tourUpdated = {
    ...activeTour,
    step: activeTour.steps[0],
  };

  setTours({
    tours: [
      ...getAllTours().filter(t => t.name !== activeTour.name),
      tourUpdated,
    ],
    active: {
      name: activeTour.name,
      el: undefined,
    },
  });
};
