import { CaretIcon } from '@cycle-app/ui/icons';
import { Setter } from '@cycle-app/utilities';
import {
  FC, MouseEvent, ReactNode, useEffect, useState, useMemo,
} from 'react';
import { Placement } from 'tippy.js';

import { Dropdown, DropdownProps } from '../Dropdown/Dropdown';
import { SelectOption, SelectPanel, SelectPanelProps } from '../Selects';
import { TriggerContainer, SelectedValue } from './DropdownSelect.styles';

export interface DropdownSelectProps extends
  Omit<DropdownProps, 'content'>,
  Pick<SelectPanelProps, 'onClearValue' | 'searchPlaceholder' | 'warningOnNoneValue'> {

  className?: string;
  tooltip?: string;
  ariaLabel?: string;
  tooltipPlacement?: Placement;
  options: SelectOption[];
  placement?: Placement;
  onChange?: (selectedOption: SelectOption) => void;
  onMount?: VoidFunction;
  onHide?: VoidFunction;
  onVisibilityChange?: (visible: boolean) => void;
  hideSearch?: boolean;
  selectedValue?: string | undefined;
  listNavDefaultIndex?: number;
  setVisible?: Setter<boolean>;
  contentDropdown?: ReactNode;
  listMaxHeight?: string;
  forceFocus?: boolean;
}

export const DropdownSelect: FC<DropdownSelectProps> = ({
  visible: visibleProps,
  setVisible: setVisibleProps,
  options,
  placement = 'bottom',
  onChange,
  onMount,
  onHide,
  children,
  disabled = false,
  onVisibilityChange,
  hideSearch,
  selectedValue,
  listNavDefaultIndex,
  onClearValue,
  contentDropdown,
  listMaxHeight,
  searchPlaceholder,
  warningOnNoneValue,
  forceFocus,
  ...dropdownProps
}) => {
  const [visibleState, setVisibleState] = useState(false);

  const visible = typeof visibleProps !== 'undefined' ? visibleProps : visibleState;
  const setVisible = setVisibleProps || setVisibleState;

  const selectedOption = useMemo(() => options.find(o => o.value === selectedValue), [options, selectedValue]);

  useEffect(() => {
    onVisibilityChange?.(visible);
  }, [visible, onVisibilityChange]);

  return (
    <Dropdown
      visible={visible}
      placement={placement}
      onMount={onMount}
      onHide={onHide}
      hide={hide}
      content={contentDropdown || (
        <SelectPanel
          searchPlaceholder={searchPlaceholder}
          hideSearch={hideSearch}
          options={options}
          onOptionChange={onOptionChange}
          selectedValue={selectedValue}
          listNavDefaultIndex={listNavDefaultIndex}
          onClearValue={onClearValue}
          listMaxHeight={listMaxHeight}
          warningOnNoneValue={warningOnNoneValue}
        />
      )}
      {...dropdownProps}
    >
      <TriggerContainer
        onClick={!disabled ? toggleSelect : undefined}
        role="button"
        tabIndex={-1}
        $isDisabled={disabled}
      >
        {children}
        {!children && selectedValue && (
          <SelectedValue forceFocus={forceFocus}>
            {selectedOption?.icon}
            {selectedOption?.label}
            <CaretIcon />
          </SelectedValue>
        )}
      </TriggerContainer>
    </Dropdown>
  );

  function onOptionChange(option: SelectOption) {
    if (option.disabled) return;

    if (!option.keepDropdownOnSelect) {
      hide();
    }
    onChange?.(option);
    option.onSelect?.();
  }

  function toggleSelect(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    setVisible(!visible);
  }

  function hide() {
    setVisible(false);
  }
};
