import styled, { css } from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const Cover = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CoverContainer = styled.div<{ withNonConsistentDesign?: boolean }>`
  position: relative;
  margin: 0 24px 24px;
  height: 200px;
  &:hover .image-menu {
    opacity: 1;
  }

  ${p => p.withNonConsistentDesign && css`
    margin: 10px 0 36px 0;
    padding: 0 120px;
    background-color: ${p.theme.colors.background.grey};
    ${Cover} {
      border-radius: 0;
    }
  `};
`;

export const Content = styled.div`
  cursor: text;
  width: 100%;
`;

export const TopSide = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FullCoverModal = styled(PortalModal)`
  padding: 0;

  img {
    display: block;
    height: 100%;
    max-height: calc(100vh - 60px);
  }
`;
