import { MeFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { useOptimizedBooleanState } from 'src/hooks';

import { InfosForm } from './InfosForm';
import { PasswordForm } from './PasswordForm';

interface Props {
  me: MeFragment;
}

export const OnboardingStepPassword: FC<Props> = ({ me }) => {
  const [isPasswordForm, { setTrueCallback: showPasswordForm }] = useOptimizedBooleanState(false);
  return isPasswordForm ? <PasswordForm /> : <InfosForm onSuccess={showPasswordForm} me={me} />;
};
