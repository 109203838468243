import { ApolloError, useQuery } from '@apollo/client';
import {
  InitialAllDocsBoardDocument,
  InitialNodeDocument,
  InitialAllDocsBoardAndDocDocument,
  InitialBoardDocument,
  InitialBoardAndDocDocument,
  InitialDocOnlyDocument,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useEffect, useState } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useAppParams } from 'src/hooks/useAppParams';
import { usePageId } from 'src/hooks/usePageId';
import { getAuth } from 'src/reactives/auth.reactive';
import { setBoardParams } from 'src/reactives/boardParams.reactive';
import { useInitialState, setInitial } from 'src/reactives/initial.reactive';
import { defaultPagination } from 'src/utils/pagination.util';

export const useInitialQuery = () => {
  const pageId = usePageId();
  const { userId } = getAuth();
  const [{ loading: loadingState }] = useInitialState();
  const {
    productSlug,
    docId,
    boardId,
    context,
  } = useAppParams();
  const skipQuery = !userId || !loadingState;

  const initialNodeRes = useQuery(InitialNodeDocument, {
    skip: context !== 'default-product-alldocs-board' || skipQuery,
    variables: {
      id: userId as string,
      ...defaultPagination,
    },
  });

  const initialAllDocsRes = useQuery(InitialAllDocsBoardDocument, {
    skip: context !== 'alldocs-board' || skipQuery,
    variables: {
      id: userId as string,
      productSlug,
      ...defaultPagination,
    },
  });

  const initialAllDocsBoardAndDocRes = useQuery(InitialAllDocsBoardAndDocDocument, {
    skip: context !== 'alldocs-board-doc-panel' || skipQuery,
    variables: {
      id: userId as string,
      docId,
      productSlug,
      ...defaultPagination,
    },
  });

  const initialBoardRes = useQuery(InitialBoardDocument, {
    skip: context !== 'specific-board' || skipQuery,
    variables: {
      id: userId as string,
      productSlug,
      boardId,
      ...defaultPagination,
    },
  });

  const initialBoardAndDocRes = useQuery(InitialBoardAndDocDocument, {
    skip: context !== 'specific-board-doc-panel' || skipQuery,
    variables: {
      id: userId as string,
      productSlug,
      boardId,
      docId,
      ...defaultPagination,
    },
  });

  const initialDocOnlyRes = useQuery(InitialDocOnlyDocument, {
    skip: context !== 'doc-fullpage' || skipQuery,
    variables: {
      id: userId as string,
      productSlug,
      docId,
    },
  });

  const data = initialNodeRes.data ??
    initialAllDocsRes.data ??
    initialAllDocsBoardAndDocRes.data ??
    initialBoardAndDocRes.data ??
    initialBoardRes.data ??
    initialBoardAndDocRes.data ??
    initialDocOnlyRes.data;

  const defaultProduct = initialNodeRes.data?.me?.__typename === 'Me'
    ? initialNodeRes.data.me.product
    : undefined;

  const productWithSlugFromUrl =
    initialAllDocsRes.data?.product ??
    initialAllDocsBoardAndDocRes.data?.product ??
    initialBoardAndDocRes.data?.product ??
    initialBoardRes.data?.product ??
    initialBoardAndDocRes.data?.product ??
    initialDocOnlyRes.data?.product;

  useEffect(() => {
    if (data) {
      setInitial({ loading: false });
    }
  }, [data]);

  useEffect(() => {
    if (defaultProduct) {
      setBoardParams({
        productId: defaultProduct.id,
        ...pageId === PageId.Welcome && defaultProduct && {
          productSlug: defaultProduct.slug,
          boardConfigId: defaultProduct.allDocs.boardConfig.id,
        },
      });
    }
  }, [pageId, defaultProduct]);

  useEffect(() => {
    if (data?.me?.__typename === 'Me' && data?.me.products) {
      setInitial({ products: nodeToArray(data?.me.products) });
    }
  }, [data?.me]);

  const hasError = useHandleInitialErrors([
    initialNodeRes.error,
    initialAllDocsRes.error,
    initialAllDocsBoardAndDocRes.error,
    initialBoardRes.error,
    initialBoardAndDocRes.error,
    initialDocOnlyRes.error,
  ]);

  return {
    productSlug: productWithSlugFromUrl?.slug ?? defaultProduct?.slug,
    hasError,
    loading: loadingState,
  };
};

const useHandleInitialErrors = (maybeErrors: Array<ApolloError | undefined>) => {
  const nbErrors = maybeErrors.filter(err => err !== undefined).length;
  const [hasError, setHasError] = useState(false);

  // Update hasError state only if error === true
  // because useQuery is skipped then and error re-pass to `false`
  useEffect(() => {
    if (nbErrors > 0) {
      setHasError(true);
    }
  }, [nbErrors]);

  return hasError;
};
