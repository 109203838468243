import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { CustomerEmail } from './CustomerEmail';

export const Container = styled.div`
  align-items: center;
  grid-area: details;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mobile} {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const StyledCustomerEmail = styled(CustomerEmail)`
  min-height: 28px;
`;
