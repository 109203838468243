import { useQuery } from '@apollo/client';
import { SearchDocDocument, DoctypeRelativeFragment, AddNewDocDocument, AddNewDocAttributeValue } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { nodeToArray, useListNav, useHotkeys } from '@cycle-app/utilities';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';

import { useFetchDocType } from 'src/hooks/api/useDocType';
import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { AddNewItemLine } from './AddNewItemLine';
import {
  CREATE_VALUE_PREFIX,
  REMOVE_VALUE,
  DOC_TYPES_AMOUNT_LIMIT,
  CREATE_VALUE_MORE,
} from './DocSearchDropdown.constants';
import {
  SearchResults,
  StyledSelectLine,
  ResultLabel,
  ResultTitle as Title,
  ListContainer,
  NoResultSpinner,
} from './DocSearchDropdown.styles';
import { cropDocTypes } from './DocSearchDropdown.utils';

export type DocSearchResultProps = {
  onAdd?: (docId: string) => void;
  onRemove?: VoidFunction;
  showNoneOption?: boolean;
  possibleDoctypes?: Array<DoctypeRelativeFragment> | null;
  childDoctypeId?: string;
  search?: string;
  inheritedAttributes?: AddNewDocAttributeValue[];
  customerId?: string;
  sourceId?: string;
  showLeaveBoardWarnings?: boolean;
};

export const DocSearchResult: FC<DocSearchResultProps> = ({
  onAdd,
  onRemove,
  showNoneOption,
  possibleDoctypes: possibleDocTypesProps,
  childDoctypeId,
  search = '',
  inheritedAttributes,
  customerId,
  sourceId,
}) => {
  const { product } = useProduct();
  const { fetch: fetchDocType } = useFetchDocType();

  const [createDoc, { loading: loadingCreateDoc }] = useSafeMutation(AddNewDocDocument);
  const {
    data,
    refetch: searchDocs,
    loading,
  } = useQuery(SearchDocDocument, {
    variables: {
      text: '',
      productId: product?.id ?? '',
      childDoctypeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const results = useMemo(() => nodeToArray(data?.searchDoc), [data?.searchDoc]);
  const docTypes = nodeToArray(product?.doctypes);
  const possibleDocTypes = possibleDocTypesProps || docTypes;
  const optionsValues: string[] = useMemo(() => ([
    ...!search.length && showNoneOption ? [REMOVE_VALUE] : [],
    ...results.map(({ id }) => id),
    ...search && possibleDocTypes.length <= DOC_TYPES_AMOUNT_LIMIT
      ? possibleDocTypes.map(p => `${CREATE_VALUE_PREFIX}${p.id}`)
      : [],
    ...search && possibleDocTypes.length > DOC_TYPES_AMOUNT_LIMIT
      ? cropDocTypes(possibleDocTypes).map(p => `${CREATE_VALUE_PREFIX}${p.id}`)
      : [],
    ...(possibleDocTypesProps?.length || 0) > DOC_TYPES_AMOUNT_LIMIT
      ? [CREATE_VALUE_MORE]
      : [],
  ]), [possibleDocTypes, possibleDocTypesProps?.length, results, search, showNoneOption]);
  const [creatingDocTypeId, setCreatingDocTypeId] = useState('');

  const onCreateDoc = useCallback(async (docTypeId: string) => {
    if (!docTypeId || !product?.id) return;

    setCreatingDocTypeId(docTypeId);
    /**
     * We fetch the doc type if not present to be sure we always have the id
     */
    const docType = docTypes.find(d => d.id === docTypeId) || (await fetchDocType(docTypeId));
    const docTypesAttributeIds: string[] = docType?.attributeDefinitions.edges.map(({ node }) => node.id) ?? [];

    const commonAttributes: AddNewDocAttributeValue[] =
      inheritedAttributes?.filter((attr) => docTypesAttributeIds.includes(attr.attributeDefinitionId)) ?? [];

    const docId = (await createDoc({
      variables: {
        doctypeId: docTypeId,
        title: search,
        productId: product?.id,
        attributes: commonAttributes,
        ...sourceId && {
          source: {
            sourceId,
          },
        },
        customerId,
      },
    })).data?.addNewDoc?.id;

    if (docId) {
      onAdd?.(docId);
    }
  }, [
    customerId,
    onAdd,
    createDoc,
    search,
    sourceId,
    fetchDocType,
    inheritedAttributes,
    docTypes,
    product?.id,
  ]);

  const onResultSelected = useCallback(async (optionId: string | null) => {
    if (!optionId || optionId === CREATE_VALUE_MORE) return;

    if (optionId === REMOVE_VALUE) {
      onRemove?.();
    } else if (optionId.includes(CREATE_VALUE_PREFIX)) {
      await onCreateDoc(optionId.replace(CREATE_VALUE_PREFIX, ''));
    } else {
      onAdd?.(optionId);
    }
  }, [onAdd, onRemove, onCreateDoc]);

  const {
    listProps,
    itemProps,
    selected,
    hoverDisabled,
    select,
  } = useListNav({
    optionsValues,
    value: null,
    onSelect: onResultSelected,
    autoFocus: true,
    defaultIndex: 0,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    searchDocs({
      text: search,
      productId: product?.id ?? '',
    });
  }, [searchDocs, search, product?.id]);

  useHotkeys('tab', e => {
    e.preventDefault();
    e.stopPropagation();

    if (!selected) return;

    if (selected.includes(CREATE_VALUE_PREFIX) || selected.includes(CREATE_VALUE_MORE)) {
      select(optionsValues[0]);
    } else {
      const firstCreateOptionIndex = optionsValues.findIndex(o => o.includes(CREATE_VALUE_PREFIX));

      if (firstCreateOptionIndex !== -1) {
        select(optionsValues[firstCreateOptionIndex]);
      }
    }
  });

  const showRemoveSelectOption = showNoneOption && !search;
  const showAddSelectOption = !!search;
  const showResultsContainer = !!results.length || showRemoveSelectOption;

  return (
    <ListContainer {...listProps}>
      {loading && data?.searchDoc?.edges.length === 0 && (
        <NoResultSpinner />
      )}
      {showResultsContainer && (
        <SearchResults $hasMarginBottom={!showAddSelectOption}>
          {showRemoveSelectOption && (
            <StyledSelectLine
              isSelected={selected === REMOVE_VALUE}
              hoverDisabled={hoverDisabled}
              label="None"
              {...itemProps(REMOVE_VALUE)}
            />
          )}
          {results.map((result) => (
            <StyledSelectLine
              key={result.id}
              isSelected={selected === result.id}
              hoverDisabled={hoverDisabled}
              startSlot={<Emoji emoji={result.doctype.emoji} />}
              label={(
                <ResultLabel>
                  <Title>{result.title}</Title>
                </ResultLabel>
                )}
              {...itemProps(result.id)}
            />
          ))}
        </SearchResults>
      )}

      {showAddSelectOption && (
        <AddNewItemLine
          docTypes={possibleDocTypes}
          getItemProps={itemProps}
          selected={selected || ''}
          isHoverDisabled={hoverDisabled}
          search={search || ''}
          docTypeIdLoading={loadingCreateDoc && creatingDocTypeId ? creatingDocTypeId : ''}
          onSelect={onResultSelected}
        />
      )}
    </ListContainer>
  );
};
