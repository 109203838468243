import { BoardWithMinimalConfigAndDraftConfigIdFragment, SectionType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';

import { useProductFromInitial } from './api/useProductFromInitial';

interface Variables {
  name?: string;
  emoji?: string | null;
  sectionType?: SectionType;
}

export const useOnboardingPreviewBoard = (variables: Variables): BoardWithMinimalConfigAndDraftConfigIdFragment | null => {
  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);
  const { product } = useProductFromInitial();
  return useMemo(() => {
    if (!product) return null;

    const sectionType = variables.sectionType ?? (isInboxEnabled ? SectionType.Inbox : SectionType.Default);
    const sections = product.boardSections.edges.filter(({ node }) => node.type === sectionType);

    // eslint-disable-next-line no-restricted-syntax
    for (const boardSection of sections) {
      // eslint-disable-next-line no-restricted-syntax
      for (const board of boardSection.node.boards.edges) {
        if (
          (!variables.emoji || board.node.emoji === variables.emoji) &&
          variables.name && board.node.name.match(new RegExp(variables.name, 'i'))
        ) {
          return board.node;
        }
      }
    }
    // TODO: Later we will fallback to the built-in board.
    return null;
  }, [variables, product, isInboxEnabled]);
};
