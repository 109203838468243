import { Spinner } from '@cycle-app/ui';
import { FC, HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';

import { useEditorContext } from 'src/contexts/editorContext';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetBillingPermission } from 'src/reactives/permission.reactive';

import { isUploadRestricted } from '../../../utils/files.util';
import { DropArea } from './ContainerDropFile.styles';
import Placeholder from './Placeholder/DropPlaceholder';

interface Props extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

const ContainerDropFile: FC<Props> = ({
  children,
  disabled,
  ...htmlProps
}) => {
  const {
    editor, onUpload, isUploading,
  } = useEditorContext();
  const { canUploadNotRestricted } = useGetBillingPermission();
  const {
    getRootProps,
    isDragActive,
  } = useDropzone({
    onDropAccepted,
    disabled,
  });

  return (
    <div
      {...htmlProps}
      {...getRootProps()}
    >
      {children}
      {(isUploading || isDragActive) && !disabled && (
        <DropArea>
          {isUploading ? <Spinner /> : <Placeholder />}
        </DropArea>
      )}
    </div>
  );

  async function onDropAccepted(acceptedFiles: File[]) {
    const file = acceptedFiles[0];
    if (isUploadRestricted(file, canUploadNotRestricted)) {
      setLimitationsModal({ action: 'UPLOAD_NOT_RESTRICTED' });
      return;
    }

    const uploadedFile = await onUpload?.(file, 'drop');

    if (!uploadedFile) return;

    editor?.chain()
      .focus()
      .setFile({ file: uploadedFile })
      .createParagraphNear()
      .run();
  }
};

export default ContainerDropFile;
