import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';

import { DirectionProps, Props } from '../../types/icon.types';
import { getRotate } from '../../utils/icon.util';

export const STROKE_CURRENT_COLOR_CLASS_NAME = 'stroke--currentColor';
export const FILL_CURRENT_COLOR_CLASS_NAME = 'fill--currentColor';
export const FILL_USER_COLOR_CLASS_NAME = 'fill--userColor';
export const FILL_INHERIT_CLASS_NAME = 'fill--inherit';
export const FILL_NONE_CLASS_NAME = 'fill--none';

export const Svg = styled.svg<Props & DirectionProps>`
  ${p => p.size && css`
    width: ${p.size}px !important;
    height: ${p.size}px !important;
  `}

  ${(props) => props.direction && `
    transform: rotate(${getRotate(props.direction)}deg);
  `};

  transition: transform .1s;

  &:not([data-no-fill]) {
    path, .fill--color {
      fill: currentColor;
    }

    .stroke--color {
      stroke: currentColor;
    }
  }

  .${STROKE_CURRENT_COLOR_CLASS_NAME} {
    stroke: currentColor;
  }

  .${FILL_CURRENT_COLOR_CLASS_NAME} {
    fill: currentColor;
  }

  .${FILL_USER_COLOR_CLASS_NAME} {
    fill: ${p => p.theme.userColors.main};
  }

  .${FILL_INHERIT_CLASS_NAME} {
    fill: inherit;
  }

  .${FILL_NONE_CLASS_NAME} {
    fill: none;
  }
`;

export type SvgProps = ComponentProps<typeof Svg>;
