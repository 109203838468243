import { ActionButton, CompanyLogo } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { FloatingPenButtonContainer } from '../FloatingPenButton/FloatingPenButton.styles';

export const StyledActionButton = styled(ActionButton)`
  gap: 4px;
  min-height: 28px;

  &[disabled] {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const Logo = styled(CompanyLogo)`
  --company-logo-size: 16px;
  --company-logo-icon-size: 8px;
`;

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const StyledFloatingPenButtonContainer = styled(FloatingPenButtonContainer)`
  :hover ${StyledActionButton}:not([disabled]) {
    color: ${p => p.theme.colors.actionButton.colorHover};
    background-color: ${p => p.theme.colors.actionButton.bgHover};
  }
`;
