import { LinearStatus as LinearStatusType } from '@cycle-app/graphql-codegen';
import {
  LinearStatusBacklogIcon,
  LinearStatusCanceledIcon,
  LinearStatusDoneIcon,
  LinearStatusInProgressIcon,
  LinearStatusToDoIcon,
} from '@cycle-app/ui/icons';
import { FC } from 'react';

import { Tooltip, IconContainer } from './LinearStatus.styles';

type Props = {
  className?: string;
  status: LinearStatusType;
  tooltip?: boolean;
};
export const LinearStatus: FC<Props> = ({
  className, status, tooltip = true,
}) => {
  return tooltip ? (
    <Tooltip
      content={status.name}
      placement="top"
      withPortal
    >
      <IconContainer className={className}>
        {renderIcon()}
      </IconContainer>
    </Tooltip>
  ) : (
    <IconContainer className={className}>
      {renderIcon()}
    </IconContainer>
  );

  function renderIcon() {
    switch (status.name) {
      case 'Todo':
        return <LinearStatusToDoIcon />;
      case 'In Progress':
        return <LinearStatusInProgressIcon />;
      case 'Done':
        return <LinearStatusDoneIcon />;
      case 'Canceled':
        return <LinearStatusCanceledIcon />;
      case 'Backlog':
      default:
        return <LinearStatusBacklogIcon />;
    }
  }
};
