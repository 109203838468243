import { make } from 'src/utils/reactives.util';

const defaultState = {
  companyId: '',
  isOpen: false,
};

type CompanyProfileModalResult = typeof defaultState;

export const {
  getValue: getCompanyProfileModal,
  hookState: useCompanyProfileModal,
  resetValue: resetCompanyProfileModal,
  setValue: setCompanyProfileModal,
} = make<CompanyProfileModalResult>({
  defaultState,
});
