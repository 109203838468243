import intersection from 'lodash/intersection';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { useGetSelection } from 'src/reactives/selection.reactive';

import { useGetDocFromCache } from './cache/cacheDoc';
import { useGetDoctypeAttributeDefinitions } from './cache/cacheDoctype';

export const useAttributesFromSelection = () => {
  const { selected } = useGetSelection();
  const getDoc = useGetDocFromCache();
  const getDoctypeAttributeDefinitions = useGetDoctypeAttributeDefinitions();

  const attributes = useMemo(() => selected.map(docId => {
    const doc = getDoc(docId);
    if (!doc) return null;
    return getDoctypeAttributeDefinitions(doc?.doctype.id)?.edges.map(edge => edge.node);
  }).flat(), [getDoc, getDoctypeAttributeDefinitions, selected]);

  const doctypesAttributesIdByDoc = useMemo(() => selected.map(docId => {
    const doc = getDoc(docId);
    if (!doc) return null;
    return getDoctypeAttributeDefinitions(doc?.doctype.id)?.edges.map(edge => edge.node.id);
  }), [getDoctypeAttributeDefinitions, getDoc, selected]);

  return useMemo(
    () => intersection(...doctypesAttributesIdByDoc)
      .map(attributeDefinitionId => attributes.find(a => a?.id === attributeDefinitionId))
      .filter(isPresent)
      // TODO: Handle MultiSelect in bulk edit
      .filter(attribute => attribute?.__typename !== 'AttributeMultiSelectDefinition'),
    [attributes, doctypesAttributesIdByDoc],
  );
};
