import { Avatar } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 12px 10px 0 0;
  > * {
    margin: -12px -10px 0 0;
  }
`;

export const StyledAvatar = styled(Avatar)`
  --border-color: ${p => p.theme.colors.background.primary};
`;
