import { MutableRefObject } from 'react';

import { DocItemHandle } from 'src/components/DocItem/DocItem.types';
import { getCommandbar } from 'src/reactives/commandbar.reactive';
import { setDocItem, getDocItem } from 'src/reactives/docItem.reactive';
import { ViewType } from 'src/types/viewType.types';
import { disablePointerEvents } from 'src/utils/mouse.util';

import useAppHotkeys from '../useAppHotkeys';

export const DOC_ITEM_TARGET_CLASSNAME = 'docItem-container';

interface UseDocsNavigationParams {
  enabled: boolean;
  viewType: ViewType;
  items: string[][];
  docItemRefs: MutableRefObject<DocItemHandle[][]>;
}

export const useDocsNavigation = ({
  enabled, viewType, items, docItemRefs,
}: UseDocsNavigationParams) => {
  useAppHotkeys('arrowup,arrowdown,arrowleft,arrowright', navigateWithKeys, { enabled });

  function navigateWithKeys(_: KeyboardEvent, { key }: { key: string }) {
    const {
      hoverDocId, disableHover,
    } = getDocItem();

    if (hoverDocId == null || getCommandbar().visible) {
      return;
    }

    const currentGroupIndex = items.findIndex(group => group.includes(hoverDocId));
    const currentGroup = items[currentGroupIndex];
    const currentIndexInGroup = currentGroup.findIndex(id => id === hoverDocId);

    const getNextLateralGroupIndex = (direction: 'left' | 'right') => {
      let i = currentGroupIndex;
      do {
        if (direction === 'left') {
          if (i <= 0) {
            i = items.length - 1;
          } else {
            i -= 1;
          }
        }
        if (direction === 'right') {
          if (i >= items.length - 1) {
            i = 0;
          } else {
            i += 1;
          }
        }
        if (items[i].length > 0) {
          return i;
        }
      } while (i !== currentGroupIndex);
      return i;
    };

    const getNextPosition = () => {
      let nextGroupIndex = currentGroupIndex;
      let nextIndexInGroup = currentIndexInGroup;

      switch (key) {
        case 'arrowup': {
          if (currentIndexInGroup <= 0) {
            if (viewType === 'LIST') {
              nextGroupIndex = getNextLateralGroupIndex('left');
              const nextGroup = items[nextGroupIndex];
              nextIndexInGroup = nextGroup.length - 1;
            } else {
              nextIndexInGroup = currentGroup.length - 1;
            }
          } else {
            nextIndexInGroup = currentIndexInGroup - 1;
          }
          break;
        }
        case 'arrowdown': {
          if (currentIndexInGroup >= currentGroup.length - 1) {
            if (viewType === 'LIST') {
              nextGroupIndex = getNextLateralGroupIndex('right');
            }
            nextIndexInGroup = 0;
          } else {
            nextIndexInGroup = currentIndexInGroup + 1;
          }
          break;
        }
        case 'arrowleft': {
          if (viewType === 'LIST') { break; }
          nextGroupIndex = getNextLateralGroupIndex('left');
          const nextGroup = items[nextGroupIndex];
          nextIndexInGroup = Math.min(currentIndexInGroup, nextGroup.length - 1);
          break;
        }
        case 'arrowright': {
          if (viewType === 'LIST') { break; }
          nextGroupIndex = getNextLateralGroupIndex('right');
          const nextGroup = items[nextGroupIndex];
          nextIndexInGroup = Math.min(currentIndexInGroup, nextGroup.length - 1);
          break;
        }
        default:
      }

      return [nextGroupIndex, nextIndexInGroup];
    };

    const [nextGroupIndex, nextIndexInGroup] = getNextPosition();
    const nextDocId = items[nextGroupIndex][nextIndexInGroup];
    const currentDocItemRef = docItemRefs.current[currentGroupIndex][currentIndexInGroup];
    const nextDocItemRef = docItemRefs.current[nextGroupIndex][nextIndexInGroup];

    if (!disableHover) {
      disablePointerEvents(DOC_ITEM_TARGET_CLASSNAME);
    }
    currentDocItemRef?.unfocus();
    nextDocItemRef?.focus();

    setDocItem({
      hoverDocId: nextDocId,
      disableHover: true,
      unfocusCurrentDoc: () => nextDocItemRef?.unfocus(),
    });
  }
};
