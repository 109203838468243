import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { ACCEPTED_COVER_IMAGE_FORMAT, FILE_SIZE_LIMIT } from 'src/constants/upload.constant';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { useGetBillingPermission } from 'src/reactives/permission.reactive';

import { setLimitationsModal } from '../../reactives/limitationsModal.reactive';
import { isUploadRestricted } from '../../utils/files.util';
import { useToaster } from '../useToaster';

interface UseCoverDropzoneArgs {
  docId: string;
  disabled?: boolean;
  onSuccess?: VoidFunction;
}

export default function useDocCoverDropzone({
  docId,
  disabled = false,
  onSuccess,
}: UseCoverDropzoneArgs) {
  const { add: addToaster } = useToaster();
  const { canUploadNotRestricted } = useGetBillingPermission();
  const {
    updateDocCover,
    isUploadingCover,
  } = useDocCoverMutations(docId, { onUpdateSuccess: onSuccess });

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    isDragActive,
  } = useDropzone({
    accept: ACCEPTED_COVER_IMAGE_FORMAT,
    maxSize: FILE_SIZE_LIMIT,
    maxFiles: 1,
    disabled,
  });

  useEffect(() => {
    // When multiple files are droped, as long as one file is accepted
    // we don't show any error notification
    const dropedFile: File | undefined = acceptedFiles[0];
    if (dropedFile) {
      if (isUploadRestricted(dropedFile, canUploadNotRestricted)) {
        setLimitationsModal({ action: 'UPLOAD_NOT_RESTRICTED' });
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateDocCover(dropedFile);
      return;
    }

    if (fileRejections.length > 0) {
      addToaster({
        title: 'An error occured',
        message: 'Only one image or GIF file can be droped, maximum size 3Mo',
      });
    }
  }, [addToaster, docId, updateDocCover, acceptedFiles, fileRejections, canUploadNotRestricted]);

  return {
    updateDocCover,
    getRootProps,
    isDragActive,
    isUploadingCover,
  };
}
