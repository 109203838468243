import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Toolbar = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  gap: 4px;
`;

export const LanguageContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export const Language = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  color: white;

  svg {
    width: 10px;
    height: 10px;
  }
`;
