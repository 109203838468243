import { ActionButton } from '@cycle-app/ui';
import { CloseIcon, UserIcon, BellIcon, PaletteIcon, PasswordIcon } from '@cycle-app/ui/icons';
import memoize from 'fast-memoize';
import { AnimatePresence } from 'framer-motion';
import { FC, useCallback, useMemo, useState } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useSettingsModal } from 'src/reactives/settingsModal.reactive';

import AccountTab from './AccountTab/AccountTab';
import AppearanceTab from './AppearanceTab/ApprearanceTab';
import NotificationsTab from './NotificationsTab/NotificationsTab';
import SecurityTab from './SecurityTab/SecurityTab';
import {
  StyledPortalModal,
  LeftPanel,
  Menu,
  MenuItem,
  RightPanel,
  Header,
  Title,
  TabContent,
} from './SettingsModal.styles';

type Tab = 'account' | 'appearance' | 'security' | 'notifications';

const TAB_TITLES: Record<Tab, string> = {
  account: 'Account Settings',
  appearance: 'Appearance',
  security: 'Security',
  notifications: 'Notifications',
};

const SettingsModal: FC = () => {
  const {
    isLoading: isLoadingIntegrations,
    list,
  } = useProductIntegrations();
  const [{ visible }, setSettingsModal] = useSettingsModal();
  const [tab, setTab] = useState<Tab>('account');

  const onHide = useCallback(() => {
    setSettingsModal({ visible: false });
    setTab('account');
  }, [setSettingsModal]);

  const setNewTab = memoize((newTab: Tab) => () => setTab(newTab));

  const isSlackInteInstalled = useMemo(
    () => list.some(item => item.__typename === 'Integration'),
    [list],
  );

  return (
    <AnimatePresence>
      {visible && (
        <StyledPortalModal hide={onHide}>
          <LeftPanel>
            <Menu>
              <MenuItem
                icon={<UserIcon />}
                label={TAB_TITLES.account}
                isActive={tab === 'account'}
                onClick={setNewTab('account')}
              />
              <MenuItem
                icon={<PaletteIcon />}
                label={TAB_TITLES.appearance}
                isActive={tab === 'appearance'}
                onClick={setNewTab('appearance')}
              />
              <MenuItem
                icon={<PasswordIcon />}
                label={TAB_TITLES.security}
                isActive={tab === 'security'}
                onClick={setNewTab('security')}
              />
              <MenuItem
                icon={<BellIcon />}
                label={TAB_TITLES.notifications}
                isActive={tab === 'notifications'}
                onClick={setNewTab('notifications')}
              />
            </Menu>
          </LeftPanel>

          <RightPanel>
            <Header>
              <Title>{TAB_TITLES[tab]}</Title>
              <ActionButton onClick={onHide}>
                <CloseIcon />
              </ActionButton>
            </Header>

            <TabContent>
              {tab === 'account' && <AccountTab />}
              {tab === 'appearance' && <AppearanceTab />}
              {tab === 'security' && <SecurityTab />}
              {tab === 'notifications' && <NotificationsTab showSetupSlack={!isLoadingIntegrations && !isSlackInteInstalled} />}
            </TabContent>
          </RightPanel>
        </StyledPortalModal>
      )}
    </AnimatePresence>
  );
};

export default SettingsModal;
