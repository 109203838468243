import { rotationCss } from '@cycle-app/ui';
import { BellIcon, RefreshIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const StyledRefreshIcon = styled(RefreshIcon)<{ $isRotating?: boolean }>`
  ${p => (p.$isRotating ? rotationCss : null)}
  margin-left: 7px;
`;

export const StyledBellIcon = styled(BellIcon)<{ $isDisabled?: boolean }>`
  ${p => p.$isDisabled && css`color: ${p.theme.colors.text.disabled}`}
`;
