import { forwardRef } from 'react';

import { COLLABORATION_CURSOR_CARET_CLASS, COLLABORATION_CURSOR_LABEL_CLASS } from '../Editor/Editors/Editor.styles';
import { mates } from '../OnboardingCollabMates/OnboardingCollabMates';
import { Cursor, CursorContainer, CursorLabel } from './OnboardingMatesCursors.style';

interface Props {
  className?: string;
}

export const OnboardingMatesCursors = forwardRef<HTMLDivElement, Props>(({ className }, ref) => (
  <CursorContainer className={className} ref={ref}>
    {mates.map(mate => (
      <Cursor
        key={mate.id}
        $color={mate.color}
      >
        <span className={COLLABORATION_CURSOR_CARET_CLASS}>
          <CursorLabel className={COLLABORATION_CURSOR_LABEL_CLASS} $color={mate.color}>
            {mate.firstName}
            {' '}
            {mate.lastName}
          </CursorLabel>
        </span>
      </Cursor>
    ))}
  </CursorContainer>
));
