import { DropdownSelectProps } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';

import { ACTIVE_INTEGRATIONS } from 'src/constants/integrations.constants';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetIntegrationPermission } from 'src/reactives/permission.reactive';
import { integrationsData } from 'src/utils/integrations.utils';

import { IntegrationItemProps } from './IntegrationItem/IntegrationItem';
import {
  AddButton,
  Dropdown,
  Info,
} from './SettingsIntegrations.styles';

interface Props extends Omit<DropdownSelectProps, 'options'> {
  integrations: IntegrationItemProps[];
}

export const IntegrationsDropdown = ({
  integrations, ...props
}: Props) => {
  const install = useInstallIntegration();
  const actives = integrations.filter(i => ACTIVE_INTEGRATIONS.includes(i.integrationType));
  const inactives = integrations.filter(i => !ACTIVE_INTEGRATIONS.includes(i.integrationType));
  const { canAddIntegration } = useGetIntegrationPermission();
  return canAddIntegration ? (
    <Dropdown
      options={[
        ...actives.map(({
          integrationType, integration,
        }) => ({
          label: integrationsData[integrationType].label,
          value: integrationType,
          icon: integrationsData[integrationType].icon,
          onSelect: () => install(integration),
        })),
        ...inactives.map(({ integrationType }) => ({
          label: integrationsData[integrationType].label,
          value: integrationType,
          icon: integrationsData[integrationType].icon,
          disabled: true,
          end: <Info>Coming soon</Info>,
        })),
      ]}
      hideSearch
      placement="bottom-end"
      {...props}
    >
      <AddButton
        iconStart={<AddIcon />}
      >
        Add new
      </AddButton>
    </Dropdown>
  ) : (
    <div>
      <AddButton
        iconStart={<AddIcon />}
        onClick={() => setLimitationsModal({ action: 'INTEGRATION_ADD' })}
      >
        Add new
      </AddButton>
    </div>
  );
};
