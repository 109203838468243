import { mobile } from '@cycle-app/utilities';
import { rgba, darken } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { body400, body500, caption600 } from '../../theme/typo';
import { NAV_ITEM_ANIM_INTERVAL } from '../NavigationSectionSkeleton/nav-item-duration';
import { Shortcut, ShortcutComponents } from '../Shortcut/Shortcut';

type HoverableProps = {
  $isActive?: boolean;
  $isSidebarCollapsed?: boolean;
};
export const Hoverable = styled.div<HoverableProps>`
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-left: 4px;

  display: ${p => (p.$isSidebarCollapsed ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  color: ${p => p.theme.colors.sidebar.item.warning.color};

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-color: ${p => p.theme.colors.sidebar.item.warning.bgHover};
    color: ${p => p.theme.colors.sidebar.item.warning.colorHover};
  }

  ${p => p.$isActive && css`
    background-color: ${p.theme.userColors.main};
    color: ${p.theme.colors.text.white};

    &:hover {
      background-color: ${darken(0.2, p.theme.userColors.main)};
      color: ${p.theme.colors.text.white};
    }
  `}
`;

export const GrowingSpace = styled.div`
  flex-grow: 1;
`;

export const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;

  overflow: hidden;
  opacity: 0;
`;

export const Action = styled.div<{ focus?: boolean }>`
  background-color: var(--bg);
  cursor: pointer;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 20px;
    height: 20px;
  }
`;

export const Icon = styled.div<{
  $isActive?: boolean;
}>`
  color: ${p => (p.$isActive ? p.theme.colors.sidebar.activeItem.color : p.theme.colors.text.disabled)};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 8px;

  .emoji-mart-emoji-native {
    width: 16px;
  }
`;

export const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const shine = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const ContainerLoadingCss = css`
  position: relative;
  color: ${p => p.theme.colors.sidebar.color};
  background-color: ${p => rgba(p.theme.colors.sidebar.color, 0.1)};
  overflow: hidden;
  pointer-events: none;
  height: 28px; /* To get the same height feeling of regular sections */

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${p => rgba(p.theme.colors.sidebar.color, 0)} 0,
      ${p => rgba(p.theme.colors.sidebar.color, 0.1)} 20%,
      ${p => rgba(p.theme.colors.sidebar.color, 0.2)} 60%,
      ${p => rgba(p.theme.colors.sidebar.color, 0)} 100%
    );
    animation: ${shine} ${NAV_ITEM_ANIM_INTERVAL}ms infinite;
    content: '';
  }

  /* We want the text to be above the background */
  ${Name} {
    position: relative;
    z-index: 1;
  }
`;

const ContainerHoverCss = css<ContainerProps>`
  ${p => p.$sidebarColor && css`
    color: {p.theme.colors.text.primary};
  `};
  background-color: ${p => (!p.$sidebarColor
    ? p.theme.colors.settings.navigationItem.bgHover
    : p.theme.colors.sidebar.item.bgHover)};
`;

export const TagWithCount = styled.span<{ $isSidebarCollapsed?: boolean }>`
  background-color: ${p => p.theme.userColors.main};
  margin-left: 8px;
  border-radius: 4px;

  display: ${p => (p.$isSidebarCollapsed ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  height: 18px;
  min-width: 16px;

  color: ${p => p.theme.colors.text.white};
  ${caption600};
`;

export const NavigationShortcut = styled(Shortcut)`
  margin-left: auto;
`;

const ContainerDraggingCss = css<{ $isActive?: boolean }>`
  cursor: grabbing;
`;

const ContainerPlaceholderCss = css<{ $isActive?: boolean; $activeAlpha?: number }>`
  /* stylelint-disable-next-line */
  ${Name}, ${NavigationShortcut}, ${Actions}, ${Icon} {
    opacity: 0;
  }
  background-color: ${p => (p.$isActive
    ? rgba(p.theme.userColors.main, p.$activeAlpha ?? 0.2)
    : p.theme.colors.sidebar.item.bgPlaceholder)};
  border-radius: 8px;
`;

const ContainerPlaceholderSettingsCss = css`
  opacity: 0;
`;

export interface ContainerProps {
  // For god's sake, why there's $ in these p?
  // Because 👇
  // https://styled-components.com/docs/api#transient-p
  $isActive?: boolean;
  $isChild?: boolean;
  $isDragging?: boolean;
  $isFocus?: boolean;
  $isLoading?: boolean;
  $isSidebarCollapsed?: boolean;
  $shortcutAlwaysVisible?: boolean;
  $sidebarColor?: boolean;
  $sortableVisible?: boolean;
  $isSortable?: boolean;
  $asPlaceholder?: boolean;
  $placeholderScale?: [number, number];
  $activeAlpha?: number;
  $isDisabled?: boolean;
}
export const Container = styled(Link) <ContainerProps>`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;

  --color: p.theme.colors.sidebar.color;
  color: ${p => (!p.$sidebarColor ? p.theme.colors.text.secondary : 'var(--color)')};

  ${p => (p.$isChild ? body400 : body500)};
  line-height: 20px;

  min-width: 0;

  border-radius: 5px;
  padding: 6px 6px 6px 14px;

  ${p => p.$isActive && css`
    color: ${!p.$sidebarColor && p.$isChild ? p.theme.colors.text.primary : p.theme.colors.sidebar.activeItem.color};
    font-weight: 500;
    background-color: ${(!p.$sidebarColor && p.$isChild ? p.theme.colors.settings.navigationItem.bgHover : p.theme.userColors.main)};
  `};

  ${NavigationShortcut} {
    display: ${p => (p.$shortcutAlwaysVisible && !p.$isSidebarCollapsed ? 'revert' : 'none')};
  }

  ${ShortcutComponents.Key} {
    background: ${p => p.theme.colors.sidebar.shortcut.bg};
    color: ${p => p.theme.colors.sidebar.shortcut.color};
  }

  ${ShortcutComponents.Then} {
    color: ${p => p.theme.colors.sidebar.shortcut.color};
  }

  &.force-focus, &:hover {
    ${Icon} {
      ${p => !p.$isActive && css`
        color: unset;
      `}
    }

    ${p => !p.$isActive && ContainerHoverCss};
    ${NavigationShortcut} {
      display: ${p => (p.$isSidebarCollapsed ? 'none' : 'revert')};
    }
    ${ShortcutComponents.Key} {
      background: ${p => p.theme.colors.sidebar.shortcut.bgHover};
      color: ${p => p.theme.colors.sidebar.shortcut.colorHover};

      ${p => p.$isActive && css`
        color: ${p.theme.colors.sidebar.shortcut.active.colorHover};
        background-color: ${p.theme.colors.sidebar.shortcut.active.bgHover};
      `};
    }

    ${ShortcutComponents.Then} {
      color: ${p => p.theme.colors.sidebar.shortcut.colorHover};

      ${p => p.$isActive && css`
        color: ${p.theme.colors.sidebar.shortcut.active.colorHover};
      `};
    }

    ${Actions} {
      overflow: visible;
      opacity: 1;
    }
  }

  ${p => p.$asPlaceholder && p.$placeholderScale && css`
    transform-origin: left;
    transform: scale(${p.$placeholderScale.join(', ')});
  `}

  ${p => p.$isFocus && !p.$isActive && ContainerHoverCss};
  ${p => p.$isDragging && ContainerDraggingCss};
  ${p => p.$isLoading && ContainerLoadingCss};
  ${p => p.$asPlaceholder && p.$sidebarColor && ContainerPlaceholderCss};
  ${p => p.$asPlaceholder && !p.$sidebarColor && ContainerPlaceholderSettingsCss};

  ${mobile} {
    ${Actions} {
      display: none;
    }
  }
`;
