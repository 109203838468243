import { DocBaseFragment, GroupsFragment, StatusCategory, DocBaseWithDocSourceFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { getAttributeName } from 'src/utils/attributes.util';
import { getDocKey } from 'src/utils/doc.util';
import { getGroupName, getGroupCategory } from 'src/utils/groups.util';

import useMoreBoardData from './useMoreBoardData';
import { useProductBase } from './useProduct';

export type BoardGroups = Record<string, BoardGroup>;

interface MoreAsync {
  exec: () => Promise<void> | null;
  loading: boolean;
  pageInfo: GroupsFragment['docGroups']['pageInfo'] | null;
}

export interface BoardGroup {
  name: string;
  category?: StatusCategory;
  typeName: 'DocGroupWithPropertyValue' | 'DocGroupWithoutPropertyValue';
  docs: Record<string, DocBaseFragment>;
  pageInfo?: GroupsFragment['docGroups']['pageInfo'] | null;
  more?: MoreAsync;
  attributeValueId?: string;
  statusId?: string;
}

export interface UseBoardGroupsRes {
  groups: BoardGroups | null;
  loading: boolean;
  mappingDocIds: Record<string, DocBaseWithDocSourceFragment>;
  withGroupBy: boolean;
  moreGroups?: MoreAsync;
}

export const useBoardGroups = (): UseBoardGroupsRes => {
  const product = useProductBase();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const loading = useBoardConfig(ctx => ctx.loading);
  const {
    moreDocs,
    moreGroups,
  } = useMoreBoardData();

  const withGroupBy = boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy';

  const groups = useMemo(() => {
    if (boardConfig?.docQuery.__typename === 'BoardQuery') {
      return {
        [boardConfig.docQuery.docGroup.id]: {
          typeName: boardConfig.docQuery.docGroup.__typename,
          name: '',
          docs: nodeToArray(boardConfig.docQuery.docGroup.docs).reduce((result, doc) => ({
            ...result,
            [doc.id]: {
              ...doc,
              _docKey: getDocKey(product?.key, doc.publicId),
              ...'docGroup' in boardConfig.docQuery && {
                _groupId: boardConfig.docQuery.docGroup.id,
              },
            },
          }), {}),
          pageInfo: boardConfig.docQuery.docGroup.docs.pageInfo,
          more: moreDocs,
        },
      };
    }

    if (boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy') {
      const attributeName = getAttributeName(boardConfig.docQuery.groupbyConfig.property);
      return Object.fromEntries(
        nodeToArray(boardConfig.docQuery.docGroups).map((group) => [
          group.id,
          {
            name: getGroupName(group, attributeName),
            category: getGroupCategory(group),
            typeName: group.__typename,
            docs: group.docs.edges.reduce((result, { node: doc }) => ({
              ...result,
              [doc.id]: {
                ...doc,
                _docKey: getDocKey(product?.key, doc.publicId),
                _groupId: group.id,
              },
            }), {}),
            pageInfo: group.docs.pageInfo,
            more: moreGroups,
            attributeValueId: group.propertyValue?.id ?? undefined,
            statusId: group.propertyValue?.__typename === 'Status' ? group.propertyValue.id : undefined,
          },
        ]),
      );
    }

    if (boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
      const attributeName = getAttributeName(boardConfig.docQuery.groupbyConfig.property);
      return Object.fromEntries(
        nodeToArray(boardConfig.docQuery.swimlanes.edges[0]?.node.docGroups).map((group) => [
          group.id,
          {
            name: getGroupName(group, attributeName),
            typeName: group.__typename,
            docs: group.docs.edges.reduce((result, { node: doc }) => ({
              ...result,
              [doc.id]: {
                ...doc,
                _docKey: getDocKey(product?.key, doc.publicId),
                _groupId: group.id,
              },
            }), {}),
            pageInfo: group.docs.pageInfo,
            more: moreGroups,
            attributeValueId: group.propertyValue?.id ?? undefined,
          },
        ]),
      );
    }

    return null;
  }, [
    boardConfig,
    product?.key,
    moreDocs,
    moreGroups,
  ]);

  const mappingDocIds = useMemo(
    () => {
      if (!groups) {
        return {};
      }
      return Object
        .keys(groups)
        .map((groupId) => groups[groupId].docs)
        .reduce((prev, current) => ({
          ...prev,
          ...current,
        }), {});
    },
    [groups],
  );

  return {
    groups,
    loading: loading || groups === null,
    mappingDocIds,
    withGroupBy,
    moreGroups,
  };
};
