import { Emoji } from '@cycle-app/ui';
import { MoreHorizIcon } from '@cycle-app/ui/icons';
import { Setter } from '@cycle-app/utilities';
import {
  FC,
  useCallback,
  useEffect,
  MouseEvent,
} from 'react';
import { Placement } from 'tippy.js';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setDocItem } from 'src/reactives/docItem.reactive';

import { TagStyled, StyledActionButton } from './DocOptions.styles';
import DocOptionsMenu, { Props as DocOptionsMenuProps } from './DocOptionsMenu';

interface Props extends Omit<DocOptionsMenuProps, 'setVisible' | 'onDelete'> {
  dropdownPlacement?: Placement;
  onVisibilityChange?: Setter<boolean>;
  secondaryBg?: boolean;
  disabled?: boolean;
  onDeleteDoc?: VoidFunction;
  buttonSize?: 'S' | 'M' | 'L';
}

const DocOptions: FC<Props> = ({
  doc,
  dropdownPlacement = 'bottom-start',
  onVisibilityChange,
  viewType,
  disabled = false,
  onDeleteDoc,
  buttonSize,
  ...docOptionsMenuProps
}) => {
  const [isDropdownVisible, {
    setValueCallback: setIsVisibleCallback,
    setFalseCallback: onHideDropdown,
    toggleCallback: onToggleDropdown,
  }] = useOptimizedBooleanState(false);

  const onButtonClicked = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleDropdown();
  }, [onToggleDropdown]);

  const [modalDelete, {
    toggleCallback: toggleModalDelete,
    setFalseCallback: hideDeleteModal,
  }] = useOptimizedBooleanState(false);

  const { removeDoc } = useRemoveDoc();

  const onDelete = useCallback(() => {
    onHideDropdown();
    toggleModalDelete();
  }, [onHideDropdown, toggleModalDelete]);

  const onConfirmDelete = useCallback(async () => {
    onDeleteDoc?.();
    await removeDoc(doc.id);
  }, [doc.id, onDeleteDoc, removeDoc]);

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItem({ hoverDocId: null });
    }
  }, [isDropdownVisible]);
  useEffect(() => {
    onVisibilityChange?.(isDropdownVisible);
  }, [isDropdownVisible, onVisibilityChange]);

  return (
    <>
      <DropdownLayer
        visible={isDropdownVisible}
        hide={onHideDropdown}
        placement={dropdownPlacement}
        content={(
          <DocOptionsMenu
            doc={doc}
            setVisible={setIsVisibleCallback}
            viewType={viewType}
            {...docOptionsMenuProps}
            onDelete={onDelete}
          />
        )}
      >
        <StyledActionButton
          forceFocus={isDropdownVisible}
          onClick={onButtonClicked}
          hide={disabled}
          size={buttonSize}
        >
          <MoreHorizIcon />
        </StyledActionButton>
      </DropdownLayer>

      {modalDelete && (
        <DialogModal
          hide={hideDeleteModal}
          title="Delete doc"
          onConfirm={onConfirmDelete}
          confirmLabel="Delete"
          info={(
            <>
              {'Are you sure to delete '}
              <TagStyled limitSize={false} icon={<Emoji emoji={doc.doctype.emoji} />}>
                {doc._docKey}
              </TagStyled>
              {' ?'}
              <br />
              This action can&apos;t be undone.
            </>
          )}
        />
      )}
    </>
  );
};

export default DocOptions;
