import { ViewType } from '@cycle-app/graphql-codegen';
import { Setter } from '@cycle-app/utilities';
import { CollisionDetection, rectIntersection, closestCenter } from '@dnd-kit/core';
import { CSS, Transform } from '@dnd-kit/utilities';

import { BoardGroups } from 'src/hooks/api/useBoardGroups';
import { Items } from 'src/types/item.types';

type GetSortableStyle = (p: {
  transform: Transform | null;
  transition: string | undefined;
}) => { transform?: string; transition?: string };
export const getSortableStyle: GetSortableStyle = ({
  transform,
  transition,
}) => ({
  transform: CSS.Transform.toString(transform),
  transition: transition ?? undefined,
});

export const reorder = (
  list: string[],
  startIndex: number,
  endIndex: number,
  toAdd: string[] = [],
): string[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, ...[removed, ...toAdd]);
  return result;
};

export const move = (
  source: string[],
  destination: string[],
  sourceId: string,
  destinationId: string,
  sourceIndex: number,
  toAdd: string[],
): Items => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(sourceIndex, 1);

  destClone.splice(sourceIndex, 0, ...[removed, ...toAdd]);

  const result: Items = {};
  result[sourceId] = sourceClone;
  result[destinationId] = destClone;

  return result;
};

export const clean = (items: Items, toRemove: string[]): Items => {
  const newItems: Items = {};
  Object.keys(items).forEach((colId) => {
    newItems[colId] = items[colId].filter((id) => !toRemove.includes(id));
  });
  return newItems;
};

export const groupsToItems = (groups: BoardGroups): [Items, number] => {
  const items: Items = {};
  let count = 0;

  Object
    .keys(groups)
    .forEach((groupId) => {
      items[groupId] = Object.keys(groups[groupId].docs);
      count += items[groupId].length;
    });

  return [items, count];
};

type SortGroup = (p: {
  items: Items;
  activeId: string;
  overId: string;
  setItems: Setter<Items>;
}) => string[];
export const sortGroups: SortGroup = ({
  items,
  activeId,
  overId,
  setItems,
}) => {
  const groupIds = Object.keys(items);
  const newSorting = reorder(
    groupIds,
    groupIds.indexOf(activeId),
    groupIds.indexOf(overId),
  );
  setItems(Object.fromEntries(
    newSorting.map(groupId => [groupId, items[groupId]]),
  ));
  return newSorting;
};

type MoveToAnotherGroup = (p: {
  items: Items;
  activeId: string;
  groupActiveId: string;
  groupOverId: string;
  setItems: Setter<Items>;
  toMoveWithActive: string[];
}) => void;
export const moveItemToAnotherGroup: MoveToAnotherGroup = ({
  items,
  activeId,
  groupActiveId,
  groupOverId,
  setItems,
  toMoveWithActive,
}) => {
  const itemsActive = items[groupActiveId].filter((id) => !toMoveWithActive.includes(id));
  const itemsOver = items[groupOverId].filter((id) => !toMoveWithActive.includes(id));

  const result = move(
    itemsActive,
    itemsOver,
    groupActiveId,
    groupOverId,
    itemsActive.indexOf(activeId),
    toMoveWithActive,
  );

  setItems({
    ...items,
    [groupActiveId]: result[groupActiveId],
    [groupOverId]: result[groupOverId],
  });
};

type MoveItemToDroppableGroup = (p: {
  items: Items;
  activeId: string;
  groupActiveId: string;
  groupOverId: string;
  setItems: Setter<Items>;
}) => Items;
export const moveItemToDroppableGroup: MoveItemToDroppableGroup = ({
  items,
  activeId,
  groupActiveId,
  groupOverId,
  setItems,
}) => {
  const itemsUpdated = {
    ...items,
    [groupActiveId]: items[groupActiveId].filter(id => activeId !== id),
    [groupOverId]: [activeId, ...items[groupOverId]],
  };
  setItems(itemsUpdated);
  return itemsUpdated;
};

type SortItems = (p: {
  items: Items;
  activeId: string;
  overId: string;
  groupActiveId: string;
  groupOverId: string;
  setItems: Setter<Items>;
  toMoveWithActive: string[];
}) => Items;
export const sortItems: SortItems = ({
  items,
  activeId,
  overId,
  groupActiveId,
  groupOverId,
  setItems,
  toMoveWithActive,
}) => {
  const itemsActive = items[groupActiveId].filter((id) => !toMoveWithActive.includes(id));
  const itemsOver = items[groupOverId].filter((id) => !toMoveWithActive.includes(id));

  const activeIndex = itemsActive.indexOf(activeId);
  const overIndex = itemsOver.indexOf(overId);

  if (activeIndex === overIndex) {
    return items;
  }

  const itemsUpdatedActiveColumn = reorder(
    itemsActive,
    activeIndex,
    overIndex,
    toMoveWithActive,
  );
  const itemsUpdated = {
    ...(clean(items, toMoveWithActive)),
    [groupActiveId]: itemsUpdatedActiveColumn,
  };
  setItems(itemsUpdated);

  return itemsUpdated;
};

type SortSwimlaneItems = (p: {
  swimlaneItems: Array<string>;
  setSwimlaneItems: Setter<Array<string>>;
  activeId: string;
  overId: string;
}) => Array<string>;

export const sortSwimlaneItems: SortSwimlaneItems = ({
  swimlaneItems,
  setSwimlaneItems,
  activeId,
  overId,
}) => {
  const activeIndex = swimlaneItems.indexOf(activeId);
  const overIndex = swimlaneItems.indexOf(overId);

  const itemsUpdated = reorder(
    swimlaneItems,
    activeIndex,
    overIndex,
  );

  setSwimlaneItems(itemsUpdated);
  return itemsUpdated;
};

export const getBoardCollisionDetection = (viewType: ViewType): CollisionDetection => (args) => {
  let updatedDroppableContainers = args.droppableContainers;
  const currentType = args.active.data.current?.type;

  if (currentType === 'group') {
    updatedDroppableContainers = updatedDroppableContainers
      .filter(c => c.data.current?.type === 'group' && c.data.current?.isDraggable);
  }

  const collisionFunction = viewType === 'LIST' ? closestCenter : rectIntersection;

  const res = collisionFunction({
    ...args,
    droppableContainers: updatedDroppableContainers,
  });
  return res;
};

export const getSwimlanesCollisionDetection: CollisionDetection = (args) => closestCenter({
  ...args,
  droppableContainers: args.droppableContainers
    .filter(c => (c.data.current?.type === 'swimlane' && c.data.current?.isDraggable) || c.data.current?.type !== 'swimlane'),
});

export const sidebarCollisionDetection: CollisionDetection = (args) => {
  let updatedDroppableContainers = args.droppableContainers;
  const currentType = args.active.data.current?.type;

  if (currentType === 'group') {
    updatedDroppableContainers = updatedDroppableContainers
      .filter(c => c.data.current?.type === 'group-droppable');
  }

  const res = closestCenter({
    ...args,
    droppableContainers: updatedDroppableContainers,
  });
  return res;
};
