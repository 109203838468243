import {
  ChangeMyPersonalInfoDocument,
  ChangeMyAvatarDocument,
} from '@cycle-app/graphql-codegen';
import { Input, Button } from '@cycle-app/ui';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { Events, Objects } from 'src/constants/analytics.constants';
import { useMe } from 'src/hooks/api/useMe';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { jobsLabel } from 'src/utils/jobs.util';

import {
  Form,
  Inputs,
  StyledAvatar,
  InputContent,
  UploadNewAvatarBtn,
  Buttons,
} from './AccountTab.styles';

interface FormValues {
  firstName: string;
  lastName: string;
  avatar?: File;
  avatarPreviewUrl?: string;
  jobTitle: string;
}

const AccountTab: FC = () => {
  const { me } = useMe();
  const [changeMyPersonalInfo, { loading: savingPersonalInfo }] = useSafeMutation(ChangeMyPersonalInfoDocument, {
    onCompleted: () => trackAnalytics(Events.UserAccountUpdated, {
      object: Objects.UserName,
    }),
  });
  const [changeMyAvatar, { loading: savingAvatar }] = useSafeMutation(ChangeMyAvatarDocument, {
    onCompleted: () => trackAnalytics(Events.UserAccountUpdated, {
      object: Objects.UserAvatar,
    }),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: me.firstName,
      lastName: me.lastName,
      avatarPreviewUrl: me.avatar?.url,
      jobTitle: me.jobTitle ? jobsLabel[me.jobTitle] : '',
    },
  });

  const avatarUrl = watch('avatarPreviewUrl');

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Inputs>
        <Input
          id="firstName"
          type="text"
          label="First name"
          {...register('firstName')}
        />
        <Input
          id="lastName"
          type="text"
          label="Last name"
          {...register('lastName')}
        />
        <Input
          id="email"
          type="email"
          label="Email"
          defaultValue={me.email}
          readOnly
        />
        <Input
          id="job"
          type="text"
          label="Your job"
          {...register('jobTitle')}
          readOnly
        />

        <ImageInput
          label="Avatar"
          previewModalTitle="New profile picture"
          previewModalSubmitLabel="Set new profile picture"
          onChange={onAvatarChanged}
        >
          {(inputRef) => (
            <InputContent>
              <StyledAvatar
                src={avatarUrl}
                user={me}
                size={64}
                onClick={() => inputRef.current?.click()}
              />
              <UploadNewAvatarBtn onClick={() => inputRef.current?.click()}>Upload new</UploadNewAvatarBtn>
            </InputContent>
          )}
        </ImageInput>
      </Inputs>

      <Buttons>
        <Button
          size="M"
          type="submit"
          isLoading={savingPersonalInfo || savingAvatar}
        >
          Save
        </Button>
      </Buttons>
    </Form>
  );

  async function onSubmit({
    firstName, lastName, avatar,
  }: FormValues) {
    // To rework once the api has one mutation for all fields
    return Promise.all([
      changeMyPersonalInfo({
        variables: {
          firstName,
          lastName,
        },
      }),
      ...avatar
        ? [changeMyAvatar({
          variables: {
            avatar,
          },
        })]
        : [],
    ]);
  }

  async function onAvatarChanged(avatar: File) {
    setValue('avatar', avatar);
    setValue('avatarPreviewUrl', URL.createObjectURL(avatar));
  }
};

export default AccountTab;
