import { Skeleton, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const TeamSelect = styled.button`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  white-space: nowrap;
  padding: 0 6px;
  cursor: pointer;
  
  &[focus] {
    border: 1px solid ${p => p.theme.userColors.main};
  }

  svg {
    margin-left: 4px;
    width: 8px;
    height: 8px;
  }
`;

export const LoadingState = styled(Skeleton)`
  width: 50px;
  margin: 8px 4px;
`;
