import { Tooltip } from '@cycle-app/ui';
import { CurlyArrowIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const MatesContainer = styled.div`
  display: flex;
  padding-left: 28px;
  padding-right: 28px;
  margin-left: auto;
  justify-content: flex-end;
  gap: 4px;
`;

export const StyledCurlyArrowIcon = styled(CurlyArrowIcon)`
  color: ${p => p.theme.colors.text.secondary};
`;

export const CollabTooltipContainer = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
`;

export const CollabIconContainer = styled.div`
  width: 26px;
  height: 26px;
`;

export const CollabTooltipContent = styled.div`
  position: absolute;
  left: -16px;
  top: -22px
`;

export const TooltipContent = styled.div`
  white-space: nowrap;
`;

export const StyledTooltip = styled(Tooltip)`
  background-color: ${p => p.theme.colors.text.secondary};
  max-width: 100vw;
  transform: translateX(-5px);
`;

export const MatesList = styled.div`
  display: flex;
  gap: 2px;
  margin-right: 2px;
`;
