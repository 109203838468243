import { LogoutIcon } from '@cycle-app/ui/icons';

import { useLogout } from 'src/hooks/useLogout';

import { ItemButton } from './Settings.styles';

export const LogoutButton = () => {
  const logout = useLogout();
  return (
    <ItemButton onClick={logout}>
      <LogoutIcon size={12} />
      Log out
    </ItemButton>
  );
};
