import { CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { CustomPropertyInputText, TextType } from '@cycle-app/ui';

import { TextDefinition } from './Form.types';
import { useCustomField } from './useCustomField';

const inputType: Record<TextDefinition, TextType> = {
  AttributeDateDefinition: 'date',
  AttributeEmailDefinition: 'email',
  AttributeNumberDefinition: 'number',
  AttributePhoneDefinition: 'phone',
  AttributeTextDefinition: 'text',
  AttributeUrlDefinition: 'text',
};

export type CustomFieldTextProps = {
  definition: CustomAttributeDefinitionFragment;
};

export const CustomFieldText = ({ definition }: CustomFieldTextProps) => {
  const field = useCustomField(definition.id);
  const type = definition.__typename ? inputType[definition.__typename as TextDefinition] : 'text';
  return (
    <CustomPropertyInputText
      type={type}
      variant="dropdown"
      hasHelper={false}
      onInputChange={(e) => field.onChange(e.target.value)}
      values={[field.value || '']}
      placeholder={`Enter a ${definition.name}`}
      autoFocus={false}
    />
  );
};
