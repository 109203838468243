import { Emoji } from '@cycle-app/ui';
import { useParams } from 'react-router-dom';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { PageId } from 'src/constants/routing.constant';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useBoard } from 'src/hooks/api/useBoard';
import { useNavigate } from 'src/hooks/useNavigate';
import { BoardRouteParams } from 'src/types/routes.types';
import { getBoardSlug } from 'src/utils/slug.util';

import { TagStyled } from './DeleteBoardModal.styled';

type DeleteBoardModalProps = {
  boardId: string;
  onHide: VoidFunction;
};

export const DeleteBoardModal = ({
  boardId, onHide,
}: DeleteBoardModalProps) => {
  const board = useBoard(boardId);
  const { boardSlug } = useParams<BoardRouteParams>();
  const { navigate } = useNavigate();
  const { removeBoard } = useBoardMutations();
  if (!board) return null;
  return (
    <DialogModal
      hide={onHide}
      title="Delete view"
      onConfirm={async () => {
        await removeBoard(board.id);
        if (boardSlug === getBoardSlug(board)) {
          navigate(PageId.Main);
        }
      }}
      info={(
        <>
          {'Are you sure you want to delete '}
          <TagStyled
            limitSize={false}
            icon={board.emoji && (
              <Emoji inline emoji={board.emoji} />
            )}
          >
            {board.name}
          </TagStyled>
          ?
        </>
      )}
      confirmLabel="Delete"
      width="auto"
    />
  );
};
