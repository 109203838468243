import 'src/utils/polyfills.util';
import 'src/utils/whyDidYouRender/whyDidYouRender';

import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';

import 'src/utils/editor/patch.utils';
import App from 'src/app/App';
import { createApp, createPortals } from 'src/utils/elements.util';
import { isProductionEnv, isDevEnv } from 'src/utils/env.util';

import { SENTRY_IGNORED_ERRORS } from './constants/sentry.constants';

if (!isDevEnv()) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing({
      tracingOrigins: ['api.dogfood.cycle.app'],
    }), new SentryFullStory(process.env.SENTRY_ORG_SLUG ?? '')],
    tracesSampleRate: Number(process.env.SENTRY_TRACE_SAMPLE_RATE ?? 0),
    ignoreErrors: SENTRY_IGNORED_ERRORS,
  });
}

if (isProductionEnv()) {
  FullStory.init({ orgId: 'RAGVN' });
}

createApp();
createPortals();

const container = document.getElementById('app');
// suggested by Facebook doc
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);
