import { useFormContext } from 'react-hook-form';

import { FieldInput } from './Form.styles';

export const FieldTitle = () => {
  const form = useFormContext();
  return (
    <FieldInput
      id="title"
      type="title"
      autoComplete="off"
      autoFocus
      error={form.formState.errors.title?.message}
      placeholder="Your feedback title"
      {...form.register('title')}
    />
  );
};
