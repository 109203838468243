import { useMemo, useCallback } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useGetDocFromCache } from 'src/hooks/api/cache/cacheDoc';
import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useIsWelcomePage } from 'src/hooks/useIsWelcomePage';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { getBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { getBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { getCreateDoc, setCreateDoc } from 'src/reactives/createDoc.reactive';
import { getDocItem } from 'src/reactives/docItem.reactive';
import { setNotifications } from 'src/reactives/notifications.reactive';
import { setSettingsModal } from 'src/reactives/settingsModal.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';
import { getDocSlug } from 'src/utils/slug.util';

export const useBoardShortcutListener = () => {
  const isWelcomePage = useIsWelcomePage();
  const pageId = usePageId();
  const callbacks = useBoardShortcutCallbacks();
  useHotkeyListener({
    callbacks,
    shortcuts: Object.values(ShortcutBoard),
    enabled: ![PageId.DocFullPage].includes(pageId) && !isWelcomePage,
    disableOnLayers: [Layer.DocPanel, Layer.Dropdown, Layer.Modal],
    preventCallback: () => (
      getBoardNewDocPositionState().draftPosition !== null ||
      getCreateDoc().modalVisible
    ),
  });
};

export const useBoardShortcutCallbacks = () => {
  const { navigate } = useNavigate();
  const getDoc = useGetDocFromCache();
  const {
    toggleSelectDoc, selectAllDocsInGroup,
  } = useDocsSelection();

  const handleOpenDoc = useCallback(() => {
    const { hoverDocId } = getDocItem();
    if (!hoverDocId) return;
    const doc = getDoc(hoverDocId);
    if (doc) {
      navigate(PageId.Doc, { docSlug: getDocSlug(doc) });
    }
  }, [navigate]);

  const handleOpenDocFullPage = useCallback(() => {
    const { hoverDocId } = getDocItem();
    if (!hoverDocId) return;
    const doc = getDoc(hoverDocId);
    if (hoverDocId && doc) {
      navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
    }
  }, [navigate]);

  const handleSelectDoc = useCallback(() => {
    const { hoverDocId } = getDocItem();
    if (hoverDocId) {
      toggleSelectDoc(hoverDocId);
    }
  }, [toggleSelectDoc]);

  const handleSelectAllDocs = useCallback(() => {
    const { hoverGroupId } = getBoardGroupState();
    if (hoverGroupId) {
      selectAllDocsInGroup(hoverGroupId);
    }
  }, [selectAllDocsInGroup]);

  const createDoc = useCallback(() => {
    if (!getBoardGroupState().hoverGroupId) {
      setCreateDoc({ modalVisible: true });
    }
  }, []);

  return useMemo(() => ({
    [ShortcutBoard.OpenDoc]: handleOpenDoc,
    [ShortcutBoard.OpenDocFullPage]: handleOpenDocFullPage,
    [ShortcutBoard.SelectDoc]: handleSelectDoc,
    [ShortcutBoard.SelectAllDocs]: handleSelectAllDocs,
    [ShortcutBoard.GoToMyAccount]: () => setSettingsModal({ visible: true }),
    [ShortcutBoard.OpenNotifCenter]: () => setNotifications({ isNotifCenterVisible: true }),
    [ShortcutBoard.CreateDoc]: createDoc,
  }), [handleOpenDoc, handleOpenDocFullPage, handleSelectAllDocs, handleSelectDoc, createDoc]);
};
