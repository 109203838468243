import { useBoardPresence } from 'src/hooks';

import { Container, StyledAvatar } from './BoardPresence.style';

export type BoardPresenceProps = {
  boardId: string;
};

export const BoardPresence = ({ boardId }: BoardPresenceProps) => {
  const users = useBoardPresence(boardId);
  return (
    <Container>
      {users.map(user => (
        <StyledAvatar
          key={user.id}
          user={user}
        />
      ))}
    </Container>
  );
};
