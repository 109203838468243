import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { StyledEditor } from '../Form.styles';

interface Props {
  className?: string;
  placeholder?: string;
  doctype?: DoctypeFragment;
  initialValue?: string;
  onChange: (value: string) => void;
  hideQuickActions?: boolean;
}

export const FieldEditor: FC<Props> = ({
  className, initialValue, onChange, doctype, placeholder, hideQuickActions,
}) => {
  return (
    <StyledEditor
      className={className}
      docType={doctype}
      emptyPlaceholder={placeholder}
      hideQuickActions={hideQuickActions}
      initialValue={initialValue}
      onUpdate={({ text }) => onChange(text || '')}
    />
  );
};
