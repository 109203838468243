import { DropdownSelect, DropdownSelectProps } from '@cycle-app/ui';
import { FC, useCallback, useState } from 'react';

import Portal from 'src/components/Portal/Portal';
import { mappingZindex } from 'src/constants/zIndex.constant';
import useDropdown from 'src/hooks/useDropdown';
import { Layer } from 'src/types/layers.types';
import { PortalId } from 'src/types/portal.types';

import { ClosingArea } from '../DropdownLayer/DropdownLayer.styles';

interface Props extends DropdownSelectProps {
  testId?: string;
  layer?: Layer;
  closingArea?: boolean;
  stopClickPropagation?: boolean;
}

const DropdownSelectLayer: FC<Props> = ({
  visible: visibleFromProps,
  setVisible: setVisibleFromProps,
  hide: hideFromProps,
  layer = Layer.Dropdown,
  onMount: onMountProps,
  onHide: onHideProps,
  closingArea = true,
  ...props
}) => {
  const [visible, setIsVisible] = useState(false);
  const setVisible = setVisibleFromProps ?? setIsVisible;

  const hideDropdown = useCallback(() => setVisible(false), [setVisible]);

  const dropdownProps = useDropdown({
    layer,
    hide: hideFromProps ?? hideDropdown,
    onDropdownMounted: onMountProps,
    onDropdownHidden: onHideProps,
  });

  const isDropdownVisible = visibleFromProps ?? visible;

  return (
    <>
      <DropdownSelect
        {...props}
        {...dropdownProps}
        visible={isDropdownVisible}
        forceFocus={isDropdownVisible}
        setVisible={setVisibleFromProps ?? setIsVisible}
        zIndex={mappingZindex[layer]}
      />

      {closingArea && isDropdownVisible && (
        <Portal portalId={PortalId.DropdownClosingArea}>
          <ClosingArea layer={layer} onClick={e => { e.stopPropagation(); hideDropdown(); }} />
        </Portal>
      )}
    </>
  );
};

export default DropdownSelectLayer;
