import { ActionButton, Modal } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { ComponentProps, FC, ReactNode } from 'react';

import { BILLING_LIMIT_BOARDS, BILLING_LIMIT_INTEGRATIONS } from 'src/constants/billing.constants';
import { PageId } from 'src/constants/routing.constant';
import { FILE_SIZE_LIMIT_RESTRICTED } from 'src/constants/upload.constant';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useUrl } from 'src/hooks/useUrl';
import { resetLimitationsModal, useLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { Layer } from 'src/types/layers.types';
import { PermissionAction } from 'src/types/permissionAction.types';
import { getFileSizeWithUnit } from 'src/utils/files.util';

import { LimitationIntegrationCard } from './LimitationIntegrationCard';
import {
  StyledPortalModal,
  Header,
  Title,
  Footer,
  StyledButton,
  Desc,
  StyledBoardsIllustrationCard,
  StyledSectionsIllustrationCard,
  StyledPapersPinnedIllustationCard,
  StyledBoardsAnimationContainer,
  StyledIncognitoIllustationCard,
  ModalOverlay,
} from './LimitationModal.styles';

export type LimitationModalProps = {
  children?: ReactNode;
};

export const LimitationModal: FC<LimitationModalProps> = () => {
  const [{ action }] = useLimitationsModal();
  const getUrl = useUrl();

  if (!action) return null;

  const {
    title, content, description,
  } = getContent(action);

  return (
    <StyledPortalModal layer={Layer.DialogModal} hide={resetLimitationsModal}>
      <Header>
        <Title>{title}</Title>
        <ActionButton onClick={resetLimitationsModal}>
          <CloseIcon />
        </ActionButton>
      </Header>
      {content}
      <Desc>{description}</Desc>
      <Footer>
        <StyledButton onClick={resetLimitationsModal} to={getUrl(PageId.BillingUpgrade)}>
          Discover Cycle Pro
        </StyledButton>
      </Footer>
    </StyledPortalModal>
  );
};

export const BasicLimitationModal: FC<{
  children: (props: ComponentProps<'button'>) => ReactNode;
}> = ({ children }) => {
  const [{ action }] = useLimitationsModal();

  if (!action) return null;

  const {
    title, content, description,
  } = getContent(action);

  return (
    <ModalOverlay>
      <Modal hide={resetLimitationsModal} zIndex={mappingZindex[Layer.DialogModal]}>
        <Header>
          <Title>{title}</Title>
          <ActionButton onClick={resetLimitationsModal}>
            <CloseIcon />
          </ActionButton>
        </Header>
        {content}
        <Desc>{description}</Desc>
        <Footer>
          {children({
            onClick: resetLimitationsModal,
          })}
        </Footer>
      </Modal>
    </ModalOverlay>
  );
};

function getContent(action: PermissionAction) {
  switch (action) {
    case 'BOARD_SECTION_CREATE':
      return {
        title: 'Custom sections is a Pro feature',
        content: <StyledSectionsIllustrationCard />,
        description: (
          <>
            You can&apos;t create custom sections. Upgrade to Cycle Pro to neatly organize your views.
          </>
        ),
      };

    case 'INCOGNITO':
      return {
        title: 'Incognito is a Pro feature',
        content: <StyledIncognitoIllustationCard />,
        description: (
          <>
            You can&apos;t go incognito. Upgrade to Cycle Pro to become invisible whenever you want.
          </>
        ),
      };

    case 'BOARD_CREATE':
      return {
        title: 'Unlimited views is a Pro feature',
        content: (
          <StyledBoardsAnimationContainer>
            <StyledBoardsIllustrationCard />
          </StyledBoardsAnimationContainer>
        ),
        description: (
          <>
            You&apos;re limited to
            {` ${BILLING_LIMIT_BOARDS} `}
            views. Try removing a view first, or upgrade to Cycle Pro for unlimited views.
          </>
        ),
      };

    case 'INTEGRATION_ADD':
      return {
        title: 'Unlimited integrations is a Pro feature',
        content: <LimitationIntegrationCard />,
        description: (
          <>
            You&apos;re limited to
            {` ${BILLING_LIMIT_INTEGRATIONS} `}
            integrations. Try removing an integration first,
            or upgrade to Cycle Pro for unlimited integrations.
          </>
        ),
      };

    case 'UPLOAD_NOT_RESTRICTED':
      return {
        title: 'Large file upload is a Pro feature',
        content: <StyledPapersPinnedIllustationCard />,
        description: (
          <>
            That file is a bit too big. Try uploading a file smaller than
            {' '}
            {getFileSizeWithUnit(FILE_SIZE_LIMIT_RESTRICTED, true)}
            ,
            or upgrade to Cycle Pro for unlimited file and image uploads.
          </>
        ),
      };

    // We should not reach here, but add a fallback anyway.
    default:
      return {
        title: 'Cycle Pro',
        description: (
          <>
            You&apos;re trying to use a pro feature.
          </>
        ),
      };
  }
}
