import { OnboardingLayout } from '@cycle-app/ui';
import { NextArrowIcon } from '@cycle-app/ui/icons';
import React, { FC, useState } from 'react';

import { useOnboardUser } from 'src/hooks/api/mutations/useOnboardUser';

type Props = {
  progress: number;
  isReadOnly?: boolean;
  onOnboard: VoidFunction;
};

export const StepWelcomeReadOnly: FC<Props> = ({
  progress, isReadOnly, onOnboard,
}) => {
  const {
    onboardUser, loading: isOnboardingLoading,
  } = useOnboardUser();

  const [isOnboarded, setIsOnboarded] = useState(false);

  return (
    <OnboardingLayout
      title="Welcome to Cycle!"
      // eslint-disable-next-line max-len
      description="Cycle connects customer feedback to product delivery workflows so you can close the feedback loop and wow your customers at each release."
      main={null}
      progress={progress}
      nextButtonProps={{
        children: 'Take me to my workspace',
        iconEnd: <NextArrowIcon />,
        size: 'L',
        isLoading: isOnboardingLoading,
        onClick: async () => {
          await onboardUser();
          onOnboard();
          setIsOnboarded(true);
        },
      }}
      isModal={isReadOnly}
      isModalVisible={!isOnboarded}
    />
  );
};
