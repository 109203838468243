import { typo, CheckboxInput, ToggleInput } from '@cycle-app/ui';
import styled from 'styled-components';

export const SetupSlackInte = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  background-color: ${p => p.theme.colors.settingsModal.rightPanel.notifications.setupSlack.bg};

  padding: 16px;
  display: flex;
  gap: 16px;

  svg {
    width: 32px;
    height: 32px;
  }

  button {
    margin-left: auto;
    white-space: nowrap;
  }
`;

export const Content = styled.div`
  margin-top: 24px;
`;

export const SetupSlackInteContent = styled.div`
  ${typo.body500};
  line-height: 16px;

  small {
    ${typo.caption400}
  }
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 42px 42px;
  grid-column-gap: 36px;
  grid-row-gap: 12px;
`;

export const Separator = styled.div`
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: ${p => p.theme.colors.settingsModal.rightPanel.notifications.setupSlack.bg};
`;

export const SectionTitle = styled.h1`
  ${typo.body500};
`;

export const ChannelTitle = styled.h2`
  ${typo.body400};
  text-transform: lowercase;
  text-align: center;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const TriggerDescription = styled.p`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
`;

export const ChannelValue = styled(CheckboxInput)`
  margin: auto;
`;

export const StyledToggleInput = styled(ToggleInput)`
  margin: auto;
`;
