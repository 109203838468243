import { UpdateGithubIssueProjectDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { Input, Button } from '@cycle-app/ui';
import { FC, useState, ChangeEvent, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Events, Objects } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useErrorToaster } from 'src/hooks/useErrorToaster';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { parseGithubProjectLink, ParseGithubProjectLinkReturn } from 'src/utils/integrations.utils';

import { Container, Label, Text, ButtonsGroup } from './GithubProjectForm.styles';

type ProjectLinkData = ParseGithubProjectLinkReturn | null;

type Props = {
  isEditing: boolean;
  onSubmit: () => void;
};

export const GithubProjectForm: FC<Props> = ({
  isEditing, onSubmit,
}) => {
  const { add: addErrorToaster } = useErrorToaster();
  const { getIntegration } = useProductIntegrations();
  const [linkGithubProject, { loading }] = useSafeMutation(UpdateGithubIssueProjectDocument, {
    onCompleted: () => {
      trackAnalytics(Events.IntegrationUpdated, {
        type: IntegrationType.Github,
        object: Objects.GitHubProject,
      });
      onSubmit();
    },
    onError: () => addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' }),
  });
  const [link, setLink] = useState<string>('');
  const integration = getIntegration(IntegrationType.Github);

  const onLinkChange = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  }, INPUT_ONCHANGE_DEBOUNCE);

  const projectLinkData: ProjectLinkData = useMemo(() => {
    if (!link) return null;

    const {
      owner, projectId,
    } = parseGithubProjectLink(link) || {};

    if (!owner || !projectId) return null;

    return {
      owner,
      projectId,
    };
  }, [link]);

  const errorMessage: string = getErrorMessage();

  return (
    <Container>
      <Label>{isEditing ? 'Change GitHub project' : 'Link GitHub project'}</Label>
      <Text>
        {isEditing
          ? 'By changing project you will no longer access information from the initial project.'
          : 'Linking a GitHub project will make you able to see and manage project related properties directly from Cycle.'}
      </Text>
      <Input
        type="text"
        onChange={onLinkChange}
        placeholder="https://github.com/orgs/…/projects/…"
        error={errorMessage}
        helper={!errorMessage && projectLinkData?.owner ? (
          <Text>
            {'The project '}
            <b>{`#${projectLinkData.projectId}`}</b>
            {' of '}
            <b>{projectLinkData.owner}</b>
            {' will be linked'}
          </Text>
        ) : undefined}
      />
      <ButtonsGroup>
        <Button
          size="M"
          onClick={onFormSubmit}
          disabled={!projectLinkData}
          isLoading={loading}
        >
          {isEditing ? 'Change project' : 'Link project'}
        </Button>
      </ButtonsGroup>
    </Container>
  );

  async function onFormSubmit() {
    if (!integration?.provider?.id || !projectLinkData?.projectId) return;

    await linkGithubProject({
      variables: {
        providerId: integration.provider.id,
        projectNumber: projectLinkData?.projectId,
      },
    });
  }

  function getErrorMessage() {
    if (!link.length) return '';
    if (!projectLinkData) return '🧐 Hmm, it doesn’t look a link we can process';
    const {
      owner, projectId,
    } = projectLinkData;
    if (!owner || !projectId) return '🧐 Hmm, it doesn’t look like a valid GitHub project link';
    return '';
  }
};
