import { SelectPanel, typo } from '@cycle-app/ui';
import { Label, LineShortcut } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine.styles';
import { Key } from '@cycle-app/ui/components/Shortcut/Shortcut.styles';
import styled from 'styled-components';

import SelectDoc from 'src/components/SelectDoc/SelectDoc';

export const Container = styled.div`
  padding: 16px;
  width: 310px;
`;

export const CurrentProperty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CurrentPropertyName = styled.div`
  ${typo.body600};
  line-height: 1;

  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CurrentPropertyValue = styled.div``;

export const StyledSelectPanel = styled(SelectPanel)`
  padding: 0;
  ${Label} {
    flex: 1;
  }
  ${LineShortcut} {
    opacity: 1;
    justify-content: flex-start;
    width: 65px;
  }
  ${Key} {
    font-weight: 500;
    padding: 0;
    min-width: 0;
    text-transform: uppercase;
    background: transparent;
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const InputContainer = styled.div`
  padding: 12px;
`;

export const InputTextContainer = styled.div`
  margin-top: 12px;
`;

export const SelectDocStyled = styled(SelectDoc)`
  padding: 0;
`;
