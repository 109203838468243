import { typo, Button, Avatar } from '@cycle-app/ui';
import styled from 'styled-components';

export const InfosList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InfosTitle = styled.div`
  ${typo.body500}
  display: flex;
`;

export const InfosDescription = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  margin: 0;
  padding: 0;
`;

export const StyledSelectButton = styled(Button)`
  ${typo.body400};
  height: 24px;
  gap: 4px;
  justify-content: space-between;
  padding: 7px 8px;
  width: 100%;
`;

export const StyledAssigneeSelectButton = styled(Button)`
  ${typo.body400};
  height: 24px;
`;

export const StyledAvatar = styled(Avatar)`
  --border-size: 0px;
  --shadow-size: 0px;
  --padding: 0px;
  --shadow-size-hover: 0px;
`;
