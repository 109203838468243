import { DocCommentFragment, DocCommentsFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CheckIcon, TrashIcon } from '@cycle-app/ui/icons';
import { nodeToArray, getOS } from '@cycle-app/utilities';
import { formatDistanceToNow } from 'date-fns';
import memoize from 'fast-memoize';
import React, {
  FC, ReactNode, useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { CommentEditorOutput, ReadOnlyEditor } from 'src/components/Editor';
import useCommentsMutations from 'src/hooks/api/mutations/useCommentsMutations';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useDraftComment } from 'src/reactives/draftComments.reactive';
import { Layer } from 'src/types/layers.types';
import { fixCommentContent } from 'src/utils/editor/editor.utils';

import DotsMenuLayer from '../DotsMenuLayer/DotsMenuLayer';
import {
  newCommentMaxHeight,
  DocChat as Container,
  CommentsList,
  Comment,
  Content,
  Info,
  AddComment,
  StyledCommentEditor,
  ButtonsContainer,
  StyledMyAvatar,
  CommentMenuContainer,
  UserAvatar,
  NewCommentContainer,
  NewCommentContainerInner,
} from './DocComments.styles';

interface Props {
  className?: string;
  docId: string;
  comments: DocCommentsFragment['comments'] | undefined;
  startOpenedOnComment?: string;
  onUnmount?: VoidFunction;
  maxHeight?: string;
}

const os = getOS();

const DocChat: FC<Props> = ({
  className,
  docId,
  comments,
  startOpenedOnComment,
  onUnmount,
  maxHeight,
}) => {
  const {
    draftComment, setDraftComment,
  } = useDraftComment(docId);

  // To avoid many useless re-renders
  const newComment = useRef<CommentEditorOutput>();
  const targetComment = useRef<HTMLDivElement>();
  const listRef = useRef<HTMLDivElement>(null);

  const commentsEdges = useMemo(() => comments?.edges ?? [], [comments]);

  const {
    addComment,
    resolveThread,
    deleteComment,
    loading: sendingComment,
  } = useCommentsMutations({
    docId,
    comments: commentsEdges,
  });

  const clearNewComment = useCallback(() => {
    newComment.current?.editor?.commands.clearContent();
    newComment.current = undefined;
    setIsCommentEmpty(true);
  }, []);

  const sendComment = useCallback(() => {
    if (!sendingComment && newComment.current) {
      const fixedOutput = fixCommentContent(newComment.current.editor);
      const output = fixedOutput || newComment.current;

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      addComment({
        docId,
        content: output.html,
        mentionedIds: newComment.current.mentionedUserIds,
      });
      clearNewComment();
    }
  }, [addComment, clearNewComment, docId, sendingComment]);

  const onCmdEnter = useCallback((e: KeyboardEvent) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (newComment.current?.editor?.isEmpty) return;
    sendComment();
  }, [sendComment]);

  const [isCommentEmpty, setIsCommentEmpty] = useState(draftComment === undefined);
  const [commentIdFocused, setCommentIdFocused] = useState<string | null>(null);
  const [isAddCommentFocused, setIsAddCommentFocus] = useState(false);

  // Checking isAddCommentFocused avoids conflicts between the events.
  useAppHotkeys('enter', onCmdEnter, { enabled: isAddCommentFocused && (os === 'macOS' || os === 'Windows') });
  useAppHotkeys('command+enter', onCmdEnter, { enabled: isAddCommentFocused && os === 'macOS' });
  useAppHotkeys('control+enter', onCmdEnter, { enabled: isAddCommentFocused && os === 'Windows' });

  useEffect(() => {
    if (!commentsEdges.length) return;
    if (startOpenedOnComment) {
      scrollTo('targetComment');
    } else {
      scrollTo('bottom');
    }
  }, [commentsEdges, startOpenedOnComment]);

  useEffect(() => () => {
    onUnmount?.();
  }, []);

  const commentsReversed = useMemo(() => nodeToArray(comments), [comments]);

  const makeCommentRef = useMemo(() => memoize(
    (id: string) => (el: HTMLDivElement) => {
      if (id === startOpenedOnComment && el) {
        targetComment.current = el;
      }
    },
  ), [startOpenedOnComment]);

  return (
    <Container
      className={className}
      noComments={commentsEdges.length === 0}
    >
      {(commentsReversed.length > 0) && (
        <CommentsList
          ref={listRef}
          $maxHeight={maxHeight}
        >
          {commentsReversed.map((currentComment: DocCommentFragment, index: number) => {
            const {
              id, creator, content, updatedAt, _sending,
            } = currentComment;

            return (
              <Comment
                ref={makeCommentRef(id)}
                key={id}
                className={commentIdFocused === id ? 'force-hover' : ''}
                sending={_sending ?? false}
              >
                <UserAvatar user={creator} size={16} />
                <Content>
                  <Info>
                    <strong>{`${creator.firstName} ${creator.lastName}`}</strong>
                    <small>{formatDistanceToNow(new Date(updatedAt), { addSuffix: true }).replace('about ', '')}</small>
                    {renderCommentActions(currentComment, index)}
                  </Info>
                  <ReadOnlyEditor content={content} />
                </Content>
              </Comment>
            );
          })}
        </CommentsList>
      )}

      <NewCommentContainer>
        <NewCommentContainerInner
          $focused={isAddCommentFocused}
          onFocus={() => setIsAddCommentFocus(true)}
          onBlur={() => setIsAddCommentFocus(false)}
        >
          <AddComment>
            <StyledMyAvatar />
            <StyledCommentEditor
              placeholder={commentsEdges.length ? 'Add reply...' : 'Add comment...'}
              onUpdate={onNewCommentUpdated}
              maxHeight={newCommentMaxHeight}
              autofocus
              content={sendingComment ? '' : draftComment}
            />
          </AddComment>

          {!isCommentEmpty && (
            <ButtonsContainer>
              <Button
                onClick={sendComment}
                isLoading={sendingComment}
              >
                Send
              </Button>
            </ButtonsContainer>
          )}
        </NewCommentContainerInner>
      </NewCommentContainer>
    </Container>
  );

  function renderCommentActions(commentData: DocCommentFragment, index: number): ReactNode {
    const isMyComment = commentData.creator.__typename === 'Me';
    const isFirstComment = index === commentsReversed.length - 1;

    if (!isFirstComment && !isMyComment) return null;

    const firstThreadComment = commentsReversed[commentsReversed.length - 1];

    return (
      <CommentMenuContainer>
        <DotsMenuLayer
          layer={Layer.DropdownZ1}
          onVisibilityChange={visible => {
            if (visible) {
              setCommentIdFocused(commentData.id);
            }
          }}
          onHide={() => setCommentIdFocused(null)}
          options={[
            ...isFirstComment
              ? [{
                label: 'Resolve thread',
                value: 'resolve',
                icon: <CheckIcon />,
                onSelect: () => resolveThread({ commentId: firstThreadComment.id }),
              }] : [],
            ...isMyComment
              ? [{
                label: 'Delete',
                value: 'delete',
                icon: <TrashIcon />,
                onSelect: () => deleteComment({ commentId: commentData.id }),
              }] : [],
          ]}
        />
      </CommentMenuContainer>
    );
  }

  function onNewCommentUpdated(output: CommentEditorOutput) {
    newComment.current = output;
    setIsCommentEmpty(output.editor.isEmpty);
    setDraftComment(output.html);
  }

  function scrollTo(where: 'bottom' | 'targetComment') {
    if (!listRef.current) return;
    if (where === 'bottom') {
      listRef.current.scroll(0, 0);
    } else if (targetComment.current) {
      scrollIntoView(targetComment.current, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
      });
    }
  }
};

export default DocChat;
