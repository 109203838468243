import { useApolloClient } from '@apollo/client';
import { GroupNodeDocument } from '@cycle-app/graphql-codegen';
import { uniq } from 'ramda';
import { useCallback } from 'react';

import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { getHotkey } from 'src/reactives/hotkey.reactive';
import { getSelection, setSelection } from 'src/reactives/selection.reactive';
import { Items } from 'src/types/item.types';
import { SelectableData } from 'src/types/rectangle.types';
import { flatItems } from 'src/utils/items.util';
import { defaultGroupPagination } from 'src/utils/pagination.util';

import { useBulkRectangle } from './useBulkRectangle';

export const DOCS_BULK_SELECTION_TARGET_CLASSNAME = 'item--selectable';

export const useDocsSelection = () => {
  const { cache } = useApolloClient();

  const selectDoc = useCallback((docId: string) => {
    const { selected } = getSelection();
    setSelection({
      selected: [...selected, docId],
      lock: true,
    });
  }, []);

  const unselectDoc = useCallback((docId: string) => {
    const { selected } = getSelection();
    const selectedUpdated = selected.filter((id) => id !== docId);
    setSelection({
      selected: selectedUpdated,
      lock: !!selectedUpdated.length,
    });
  }, []);

  const toggleSelectDoc = useCallback((docId: string) => {
    const { selected } = getSelection();
    const isSelected = selected.includes(docId);
    if (isSelected) {
      unselectDoc(docId);
    } else {
      selectDoc(docId);
    }
  }, [selectDoc, unselectDoc]);

  const selectAllDocsInGroup = useCallback((groupId: string) => {
    const data = cache.readQuery({
      query: GroupNodeDocument,
      variables: {
        groupId,
        ...defaultGroupPagination, // This will not be enough to select all the docs if they span over multiple pages
      },
    });
    if (!data?.node || !('docs' in data.node)) return;

    const docs = data.node.docs.edges?.map(({ node }) => node.id);
    if (docs.length) {
      setSelection({
        selected: docs,
        lock: true,
      });
    }
  }, [cache]);

  return {
    selectDoc,
    unselectDoc,
    toggleSelectDoc,
    selectAllDocsInGroup,
  };
};

interface UseDocsBulkSelectionParams {
  enabled: boolean;
  items: Items;
}

export const useDocsBulkSelection = ({
  enabled,
  items,
}: UseDocsBulkSelectionParams) => {
  const updateSelected = useCallback((newSelected: SelectableData[]) => {
    const { selected: currentSelected } = getSelection();
    const {
      mod, shift,
    } = getHotkey();

    const newSelectedIds = newSelected.map(({ id }) => id);

    const selected = (shift || mod)
      ? uniq([
        ...currentSelected,
        ...newSelectedIds,
      ])
      : newSelectedIds;

    const selectionHasChanged = getSelection().selected.join('-') !== selected.join('-');
    if (selectionHasChanged) {
      setSelection({
        ...(selectionHasChanged ? { selected } : {}),
        lock: !!selected.length,
      });
    }
  }, []);

  useBulkRectangle({
    targetIds: flatItems(items),
    enabled,
    targetSelector: `.${DOCS_BULK_SELECTION_TARGET_CLASSNAME}`,
    areaSelector: `#${BULK_SELECTION_AREA_ID}`,
    onSelectMove: updateSelected,
    onSelectEnd: updateSelected,
  });
};
