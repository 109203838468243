import { Color as ColorApi, ChangeMyPreferencesDocument, ThemeType } from '@cycle-app/graphql-codegen';
import { ColorPicker, Emoji, Button } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { FC, useState, useCallback } from 'react';
import { useTheme, ThemeProvider } from 'styled-components';

import { COLLABORATION_CURSOR_CARET_CLASS, COLLABORATION_CURSOR_LABEL_CLASS } from 'src/components/Editor/Editors/Editor.styles';
import { useMe } from 'src/hooks/api/useMe';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getThemeConfig, setThemeConfig } from 'src/reactives/theme.reactive';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

import {
  Container,
  StyledAvatar,
  ColorSection,
  Preview,
  PreviewItem,
  ThemeSection,
  SectionTitle,
  PreviewLabel,
  PreviewHighlight,
  StyledNavigationItem,
  CursorsPreview,
  CollaborationCursorCaret,
  CollaborationCursorCaretWithName,
  ThemePreviews,
  ThemePreview,
  StyledCheckedDot,
  Buttons,
} from './ApprearanceTab.styles';

const AppearanceTab: FC = () => {
  const { me } = useMe();
  const theme = useTheme();
  const [changeMyPreferences, { loading }] = useSafeMutation(ChangeMyPreferencesDocument);

  const [selectedUserColor, setSelectedUserColor] = useState(me.color);
  const [selectedThemeColor, setSelectedThemeColor] = useState<ThemeType>(convertLegacyLocalStorageValue(getThemeConfig().colorTheme));

  const currentTheme = getTheme(selectedThemeColor, selectedUserColor);
  const selectedMainColor = currentTheme.nuances[selectedUserColor].main;

  const onSave = useCallback(async () => {
    await changeMyPreferences({
      variables: {
        color: selectedUserColor,
        themeId: '', // TODO: useless param, to remove once the api has removed it from the params
      },
      optimisticResponse: {
        changeMyPreferences: {
          __typename: 'Me',
          id: me.id,
          color: selectedUserColor,
        },
      },
    });
    setThemeConfig({ colorTheme: selectedThemeColor });
  }, [changeMyPreferences, selectedThemeColor, selectedUserColor, me.id]);

  return (
    <Container>
      <ThemeProvider theme={currentTheme}>

        <ColorSection>
          <SectionTitle>Choose your color</SectionTitle>
          <ColorPicker
            color={selectedUserColor}
            onClick={setSelectedUserColor}
          />

          <Preview>
            <PreviewItem>
              <PreviewLabel>Avatar</PreviewLabel>
              <StyledAvatar
                user={me}
                src={me.avatar?.url}
                size={40}
                userColor={selectedUserColor}
              />
            </PreviewItem>

            <PreviewItem>
              <PreviewLabel>Menu</PreviewLabel>
              <StyledNavigationItem
                icon={<Emoji emoji="cherry_blossom" />}
                label="Cycle Design Team"
                isActive
              />
            </PreviewItem>

            <PreviewItem>
              <PreviewLabel>Text highlight</PreviewLabel>
              <PreviewHighlight $highlightColor={theme.nuances[selectedUserColor].textHighlight}>
                <mark>Ship stuff </mark>
                people need.
              </PreviewHighlight>
            </PreviewItem>

            <PreviewItem>
              <PreviewLabel>Cursor</PreviewLabel>
              <CursorsPreview $color={selectedMainColor}>
                <CollaborationCursorCaretWithName>
                  <span className={COLLABORATION_CURSOR_CARET_CLASS}>
                    <span className={COLLABORATION_CURSOR_LABEL_CLASS}>John Doe</span>
                  </span>
                </CollaborationCursorCaretWithName>

                <CollaborationCursorCaret className={COLLABORATION_CURSOR_CARET_CLASS} />
              </CursorsPreview>
            </PreviewItem>
          </Preview>
        </ColorSection>

        <ThemeSection>
          <SectionTitle>Choose your theme</SectionTitle>

          <ThemePreviews $color={selectedMainColor}>
            {[
              {
                value: ThemeType.Nightfall,
                label: 'Nightfall',
                img: '/images/preview-theme-nightfall.png',
                imgAlt: 'preview-theme-nightfall',
              },
              {
                value: ThemeType.Eclipse,
                label: 'Eclipse',
                img: '/images/preview-theme-eclipse.png',
                imgAlt: 'preview-theme-eclipse',
              },
              {
                value: ThemeType.Sunrise,
                label: 'Sunrise',
                img: '/images/preview-theme-sunrise.png',
                imgAlt: 'preview-theme-sunrise',
              },
            ].map(item => (
              <ThemePreview
                key={item.value}
                selected={selectedThemeColor === item.value}
                onClick={() => setSelectedThemeColor(item.value)}
              >
                <img src={item.img} alt={item.imgAlt} />
                <div>{item.label}</div>
                {selectedThemeColor === item.value && <StyledCheckedDot color={ColorApi.A} />}
              </ThemePreview>
            ))}
          </ThemePreviews>
        </ThemeSection>
      </ThemeProvider>

      <Buttons>
        <Button
          size="M"
          onClick={onSave}
          isLoading={loading}
        >
          Save
        </Button>
      </Buttons>
    </Container>
  );
};

export default AppearanceTab;
