import { useApolloClient } from '@apollo/client';
import {
  BoardConfigDocument,
  PublishedBoardConfigFullFragment,
  BoardWithDraftConfigDocument,
  BoardWithDraftConfigFragment,
  BoardWithConfigDocument,
  BoardWithFullConfigFragment,
  BoardWithEditableSavedConfigFragmentDoc,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { defaultPagination } from 'src/utils/pagination.util';

export const useGetBoardWithConfigFromCache = (boardId: string) => {
  const { cache } = useApolloClient();

  return useCallback(() => cache.readQuery<{ node: BoardWithFullConfigFragment }>({
    query: BoardWithConfigDocument,
    variables: {
      id: boardId,
      ...defaultPagination,
    },
  })?.node, [cache, boardId]);
};

export const useGetBoardConfigFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((id: string) => cache.readQuery<{ node: PublishedBoardConfigFullFragment }>({
    query: BoardConfigDocument,
    variables: {
      id,
      ...defaultPagination,
    },
  })?.node, [cache]);
};

export const useGetDraftBoardConfigFromCache = (boardId: string) => {
  const { cache } = useApolloClient();

  return useCallback(() => cache.readQuery<{ node: BoardWithDraftConfigFragment }>({
    query: BoardWithDraftConfigDocument,
    variables: {
      id: boardId,
    },
  })?.node.draftBoardConfig, [cache, boardId]);
};

export const useGetEditableSavedBoardConfigFromCache = (boardId: string) => {
  const { cache } = useApolloClient();

  return useCallback(() => cache.readFragment({
    fragment: BoardWithEditableSavedConfigFragmentDoc,
    fragmentName: 'BoardWithEditableSavedConfig',
    id: boardId,
  })?.savedBoardConfig, [boardId, cache]);
};
