import { CommandSection } from 'src/types/commandbar.types';
import { make } from 'src/utils/reactives.util';

export interface CommandbarResult {
  visible: boolean;
  section: CommandSection | null;
  boardId: string | null;
  docId: string | null;
}

export const {
  hookState: useCommandbar,
  hookValue: useGetCommandbar,
  getValue: getCommandbar,
  setValue: setCommandbar,
  resetValue: resetCommandbar,
} = make<CommandbarResult>({
  defaultState: {
    visible: false,
    section: null,
    boardId: null,
    docId: null,
  },
});
