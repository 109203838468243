import { Tooltip, Shortcut } from '@cycle-app/ui';
import { BellActiveIcon, BellIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import NotificationCenter from 'src/app/Main/Board/NotificationCenter/NotificationCenter';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useProduct } from 'src/hooks/api/useProduct';
import { useMobileSidebarActions } from 'src/hooks/useMobileSidebarActions';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { useGetNotifications, setNotifications } from 'src/reactives/notifications.reactive';
import { useResponsive } from 'src/reactives/responsive.reactive';
import { ShortcutBoard } from 'src/types/shortcuts.types';

import { Item, TooltipContent } from './Notifications.styles';

export const Notifications: FC = () => {
  const collapsed = useSidebarCollapsed();
  const [{ breakpoint }] = useResponsive();
  const { product } = useProduct();
  const { notificationsNotRead } = product || {};
  const { close: closeMobileSidebar } = useMobileSidebarActions();
  const { isNotifCenterVisible } = useGetNotifications();

  if (breakpoint === 'mobile') {
    return renderContent();
  }
  return (
    <NotificationCenter>
      {renderContent()}
    </NotificationCenter>
  );

  function renderContent() {
    return (
      <Tooltip
        content={(
          <TooltipContent>
            Notifications
            <Shortcut keys={shortcuts[ShortcutBoard.OpenNotifCenter]} />
          </TooltipContent>
        )}
        placement="bottom"
        withPortal
      >
        <Item
          onClick={() => {
            setNotifications({ isNotifCenterVisible: true });
            closeMobileSidebar();
          }}
          $collapsed={collapsed}
          $active={isNotifCenterVisible}
        >
          {notificationsNotRead ? <BellActiveIcon /> : <BellIcon />}
        </Item>
      </Tooltip>
    );
  }
};
