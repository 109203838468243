import { forwardRef, DOMAttributes } from 'react';

import { ContainerProps, Container, Content, Counter } from './DraggableCard.styles';

export interface DraggableCardProps extends ContainerProps, DOMAttributes<HTMLDivElement> {
  className?: string;
  disableHover?: boolean;
}

export const DraggableCard = forwardRef<HTMLElement, DraggableCardProps>(({
  className,
  children,
  selectedLength = 0,
  ...containerProps
}, ref) => (
  <Container
    // @ts-ignore
    ref={ref}
    className={className}
    {...containerProps}
  >
    {selectedLength > 0 && containerProps.isDragging && <Counter>{selectedLength}</Counter>}
    <Content>
      {children}
    </Content>
  </Container>
));
