/* eslint-disable react/no-children-prop */
import { AnimatePresence } from 'framer-motion';
import { ComponentProps, ReactNode } from 'react';

import { Button } from '../Buttons/Button/Button';
import { CycleLogo } from '../CycleLogo/CycleLogo';
import { Progress } from '../Progress/Progress';
import {
  Aside,
  Body,
  Container,
  Content,
  Description,
  Footer,
  Header,
  Main,
  Root,
  SubTitle,
  Title,
  FooterRight,
  ModalRoot,
  ModalMain,
  ModalContainer,
} from './OnboardingLayout.styles';

export type OnboardingLayoutProps = {
  aside?: ReactNode;
  description?: ReactNode;
  isSkippable?: boolean;
  main?: ReactNode;
  nextButtonProps?: ComponentProps<typeof Button>;
  skipButtonProps?: ComponentProps<typeof Button>;
  prevButtonProps?: ComponentProps<typeof Button>;
  progress: number;
  subtitle?: ReactNode;
  title: ReactNode;
  cover?: ReactNode;
  isModal?: boolean;
  isModalVisible?: boolean;
  isFixedFooter?: boolean;
};

export const OnboardingLayout = ({
  aside,
  description,
  isSkippable = false,
  main,
  nextButtonProps,
  skipButtonProps,
  prevButtonProps,
  progress = 0,
  subtitle,
  title,
  isModal,
  isModalVisible = true,
  cover,
  isFixedFooter,
}: OnboardingLayoutProps) => {
  const content = (
    <>
      <Header>
        <CycleLogo animation="hover" full size={32} />
        <Progress isNeutral percentage={progress} type="ring" useUserColor />
      </Header>

      <Container>
        {cover}
        <Content>
          <Body>
            <Title>{title}</Title>
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
            {description && <Description>{description}</Description>}
            {main}
          </Body>

          <Footer $isFixed={isFixedFooter}>
            {prevButtonProps && (
              <Button children="Back" {...prevButtonProps} size="M" variant="ternary" />
            )}
            <FooterRight>
              {isSkippable && (<Button children="Skip" {...skipButtonProps} size="M" variant="nospace" />)}
              <Button children="Next" {...nextButtonProps} size="M" />
            </FooterRight>
          </Footer>
        </Content>
      </Container>
    </>
  );

  return isModal
    ? (
      <ModalContainer>
        <AnimatePresence>
          {isModalVisible && (
            <ModalRoot $isFixedFooter={isFixedFooter} placement="center" zIndex={1} motionProps={{ initial: 'enter' }}>
              <ModalMain>
                {content}
              </ModalMain>
            </ModalRoot>
          )}
        </AnimatePresence>
      </ModalContainer>
    ) : (
      <Root>
        <Main>
          {content}
        </Main>
        {!isModal && aside && <Aside>{aside}</Aside>}
      </Root>
    );
};
