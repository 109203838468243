import { useQuery } from '@apollo/client';
import {
  DoctypeTemplateCategory,
  TemplatesDocument,
  TemplatesQuery,
  TemplateFragment,
  TemplateNodeDocument,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

type Templates = Partial<Record<DoctypeTemplateCategory, Array<TemplateFragment>>>;

export const useTemplate = (templateId?: string | null) => {
  const { data } = useQuery<{ node: TemplateFragment }>(TemplateNodeDocument, {
    variables: {
      id: templateId,
    },
    skip: !templateId,
  });

  return data?.node;
};

export const useTemplates = () => {
  const {
    data, loading,
  } = useQuery<TemplatesQuery>(TemplatesDocument);

  return {
    loading,
    templates: !loading ? nodeToArray(data?.doctypeTemplates).reduce<Templates>(
      (prev, template) => ({
        ...prev,
        [template.category]: [
          ...prev[template.category] ?? [],
          template,
        ],
      }),
      {},
    ) : null,
  };
};
