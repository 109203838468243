import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const BackArrowIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.78007 12.53C7.63945 12.6705 7.44882 12.7494 7.25007 12.7494C7.05132 12.7494 6.8607 12.6705 6.72007 12.53L2.47007 8.28003C2.32962 8.1394 2.25073 7.94878 2.25073 7.75003C2.25073 7.55128 2.32962 7.36065 2.47007 7.22003L6.72007 2.97003C6.86225 2.83755 7.05029 2.76543 7.2446 2.76885C7.4389 2.77228 7.62428 2.85099 7.76169 2.98841C7.89911 3.12582 7.97782 3.31121 7.98125 3.50551C7.98468 3.69981 7.91255 3.88785 7.78007 4.03003L4.81007 7.00003H12.2501C12.449 7.00003 12.6397 7.07905 12.7804 7.2197C12.9211 7.36035 13.0001 7.55112 13.0001 7.75003C13.0001 7.94894 12.9211 8.13971 12.7804 8.28036C12.6397 8.42101 12.449 8.50003 12.2501 8.50003H4.81007L7.78007 11.47C7.92052 11.6107 7.99941 11.8013 7.99941 12C7.99941 12.1988 7.92052 12.3894 7.78007 12.53Z" fill="#171618" />
  </Svg>
);
