import {
  ChangeProductNameDocument,
  ChangeProductLogoDocument,
  ChangeProductKeyDocument,
} from '@cycle-app/graphql-codegen';
import { Input } from '@cycle-app/ui';
import { FC, ChangeEvent, RefObject, MouseEvent, DragEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { Events, Objects } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useProduct } from 'src/hooks/api/useProduct';
import { useLoader } from 'src/hooks/useLoader';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { Header, SavingLabelStyled } from '../Settings.styles';
import {
  LogoUploadLine,
  Inputs,
  Logo,
  LogoUploadButton,
} from './SettingsWorkspace.styles';

const SettingsWorkspace: FC = () => {
  const { product } = useProduct();

  const [updateProductName, { loading: loadingUpdateProductName }] = useSafeMutation(ChangeProductNameDocument, {
    onCompleted: () => trackAnalytics(Events.ProductUpdated, {
      object: Objects.ProductName,
    }),
  });
  const [updateProductLogo, { loading: loadingUpdateProductLogo }] = useSafeMutation(ChangeProductLogoDocument, {
    onCompleted: () => trackAnalytics(Events.ProductUpdated, {
      object: Objects.ProductLogo,
    }),
  });
  const [updateProductKey, { loading: loadingUpdateProductKey }] = useSafeMutation(ChangeProductKeyDocument, {
    onCompleted: () => trackAnalytics(Events.ProductUpdated, {
      object: Objects.ProductKey,
    }),
  });

  const onChangeNameDebounced = useDebouncedCallback(onChangeName, INPUT_ONCHANGE_DEBOUNCE);
  const onChangeKeyDebounced = useDebouncedCallback(onChangeKey, INPUT_ONCHANGE_DEBOUNCE);

  const loading = loadingUpdateProductName || loadingUpdateProductLogo || loadingUpdateProductKey;
  useLoader({ loading });

  return (
    <>
      <Header>
        <h1>General</h1>
        <SavingLabelStyled />
      </Header>

      <Inputs>
        <Input
          id="name"
          label="Name"
          type="text"
          defaultValue={product?.name}
          onChange={onChangeNameDebounced}
        />

        <ImageInput
          label="Logo"
          previewModalTitle="New logo preview"
          previewModalSubmitLabel="Set new product logo"
          renderPreview={(imageSrc) => (
            <Logo
              src={imageSrc}
              alt="logo"
              $size={250}
            />
          )}
          onChange={onChangeLogo}
        >
          {((inputRef, chooseFile) => (
            <LogoUploadLine>
              {product?.logo?.url && (
                <Logo
                  src={product?.logo?.url}
                  alt="logo"
                  $size={42}
                />
              )}
              <LogoUploadButton
                onClick={openLogoFileInput(inputRef)}
                onDrop={makeHandleDropLogo(chooseFile)}
                onDragOver={(e: DragEvent) => e.preventDefault()}
              >
                Click to replace or drag and drop here
              </LogoUploadButton>
            </LogoUploadLine>
          ))}
        </ImageInput>

        <Input
          id="domain"
          label="Domain"
          type="text"
          placeholder="Some placeholder"
          defaultValue={product?.domain}
          readOnly
        />

        <Input
          id="key"
          label="Key"
          type="text"
          defaultValue={product?.key}
          readOnly
          onChange={onChangeKeyDebounced}
        />
      </Inputs>
    </>
  );

  function openLogoFileInput(ref: RefObject<HTMLInputElement>) {
    return (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      ref.current?.click();
    };
  }

  async function onChangeName(e: ChangeEvent<HTMLInputElement>) {
    if (product) {
      await updateProductName({
        variables: {
          productId: product.id,
          name: e.target.value,
        },
      });
    }
  }

  async function onChangeLogo(logoFile: File) {
    if (product) {
      await updateProductLogo({
        variables: {
          productId: product.id,
          productImage: logoFile,
        },
      });
    }
  }

  async function onChangeKey(e: ChangeEvent<HTMLInputElement>) {
    if (product) {
      await updateProductKey({
        variables: {
          productId: product.id,
          key: e.target.value,
        },
      });
    }
  }

  function makeHandleDropLogo(chooseFile: (file: File) => void) {
    return (e: DragEvent) => {
      e.preventDefault();
      const item = e.dataTransfer.items[0];

      if (item?.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          chooseFile(file);
        }
      }
    };
  }
};

export default SettingsWorkspace;
