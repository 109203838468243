import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const PhoneIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.84462 3.13208C3.65564 3.13208 3.4744 3.20715 3.34077 3.34077C3.21067 3.47088 3.13608 3.64611 3.13223 3.82968C3.27969 6.17818 4.27913 8.39243 5.94335 10.0567C7.60757 11.7209 9.82182 12.7203 12.1703 12.8678C12.3539 12.8639 12.5291 12.7893 12.6592 12.6592C12.7929 12.5256 12.8679 12.3444 12.8679 12.1554V9.98145L10.4749 9.02423L9.76395 10.2091C9.61239 10.4617 9.29241 10.5558 9.02821 10.4255C7.52823 9.68576 6.31424 8.47177 5.57447 6.97179C5.44418 6.70759 5.5383 6.38761 5.79091 6.23605L6.97577 5.52513L6.01855 3.13208H3.84462ZM2.54028 2.54028C2.88621 2.19434 3.35539 2 3.84462 2H6.40178C6.63323 2 6.84137 2.14092 6.92733 2.35582L8.20591 5.55227C8.30909 5.81022 8.20981 6.10492 7.97158 6.24786L6.83835 6.9278C7.38676 7.84558 8.15442 8.61324 9.0722 9.16165L9.75214 8.02842C9.89508 7.79019 10.1898 7.69091 10.4477 7.79409L13.6442 9.07267C13.8591 9.15863 14 9.36677 14 9.59822V12.1554C14 12.6446 13.8057 13.1138 13.4597 13.4597C13.1138 13.8057 12.6446 14 12.1554 14C12.1439 14 12.1325 13.9997 12.121 13.999C9.48935 13.839 7.00718 12.7215 5.14285 10.8571C3.27853 8.99283 2.16097 6.51065 2.00104 3.87895C2.00035 3.86752 2 3.85607 2 3.84462C2 3.35539 2.19434 2.88621 2.54028 2.54028Z" />
  </Svg>
);
