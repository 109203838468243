import { useMemo } from 'react';

import FlowDoctypes from 'src/components/FlowDoctypes/FlowDoctypes';
import { useDoctypeHierarchyElements } from 'src/hooks/hierarchy/useHierarchy';
import { getLayoutedElementsDoctype } from 'src/utils/flow.util';

import { Container } from './SettingsDoctypeHierarchy.styles';

const SettingsDoctypeHierarchy = () => {
  const initialElements = useDoctypeHierarchyElements();
  const layoutedElements = useMemo(
    () => getLayoutedElementsDoctype(initialElements),
    [initialElements],
  );

  return (
    <Container>
      <FlowDoctypes elements={layoutedElements} />
    </Container>
  );
};

export default SettingsDoctypeHierarchy;
