import { CompanyFragment } from '@cycle-app/graphql-codegen';
import { CompanyLogo, Tooltip } from '@cycle-app/ui';
import { TrashIcon } from '@cycle-app/ui/icons';
import { useCallback } from 'react';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { useCompanyUpdate } from 'src/hooks/api/mutations/customers/useCompanyUpdate';

import { Container, Button, Buttons } from './CompanyAvatarEditable.styles';

export type CompanyAvatarEditableProps = {
  company?: CompanyFragment | null;
};

export const CompanyAvatarEditable = ({ company }: CompanyAvatarEditableProps) => {
  const {
    updateCompany, isLoading,
  } = useCompanyUpdate();

  const updateLogo = useCallback(async (avatar?: File) => {
    if (isLoading || !company?.id) return;
    await updateCompany({
      companyId: company.id,
      name: company.name ?? '',
      avatarInput: avatar ? { avatar } : null,
    });
  }, [company, isLoading, updateCompany]);

  return (
    <Container>
      <ImageInput
        previewModalTitle="New company logo"
        previewModalSubmitLabel="Set new company logo"
        onChange={(file) => updateLogo(file)}
      >
        {(inputRef) => (
          <Tooltip
            content="Click to change company logo"
            placement="top"
            withPortal
          >
            <CompanyLogo
              isEditable
              company={company}
              onClick={() => {
                inputRef.current?.click();
              }}
            />
          </Tooltip>
        )}
      </ImageInput>
      <Buttons>
        {!!company?.logo && (
          <Button
            tooltip="Remove logo"
            tooltipPlacement="top"
            onClick={() => updateLogo()}
          >
            <TrashIcon />
          </Button>
        )}
      </Buttons>
    </Container>
  );
};
