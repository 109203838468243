import { Tag, typo } from '@cycle-app/ui';
import styled from 'styled-components';

import DialogModal from 'src/components/DialogModal/DialogModal';

export const TagStyled = styled(Tag)`
  ${typo.body500}
  background-color: ${p => p.theme.colors.background.tertiary};
  border: none;
  display: inline-flex;
  height: auto;
`;

export const DialogModalStyled = styled(DialogModal)`
  width: 550px;
`;
