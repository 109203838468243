/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';

import bottom from '../../assets/login-bottom.svg';
import plug from '../../assets/plug.png';
import { FillSvg } from './LoginPlug.styles';

const wirePath = (x = 601, y = 922) => `M 564 734 C 460 687 419 770 502 804 C 644 857 517 810 631 852 C 743 895 695 951 ${x} ${y}`;

export type LoginPlugProps = {
  plugged: boolean;
  scale?: number;
};

export const LoginPlug = ({
  plugged = false,
  scale = 1,
}: LoginPlugProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wirePathRef = useRef<SVGPathElement>(null);
  const plugRef = useRef<HTMLImageElement>(null);
  const plugControl = useAnimation();

  useEffect(() => {
    let rafId: number | null = null;

    if (plugRef.current && wirePathRef.current) {
      const start = async () => {
        (function loop() {
          rafId = requestAnimationFrame(loop);
          if (!plugRef.current || !wirePathRef.current || !containerRef.current) return;
          const containerRect = containerRef.current.getBoundingClientRect();
          const rect = plugRef.current.getBoundingClientRect();
          const x = (rect.left - containerRect.left) / scale + 218;
          const y = (rect.top - containerRect.top) / scale + 670;
          wirePathRef.current.setAttribute('d', wirePath(x, y));
        }());

        await plugControl.start({
          offsetDistance: plugged ? '8%' : '74%',
          transition: {
            duration: 0.5,
            ease: 'easeOut',
          },
        });

        cancelAnimationFrame(rafId);
      };
      start();
    }

    return () => {
      if (rafId) cancelAnimationFrame(rafId);
    };
  }, [plugged]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: 501,
        height: 354,
        transform: `scale(${scale})`,
        transformOrigin: 'bottom right',
      }}
    >
      {/* Background image */}
      <img src={bottom} alt="" />

      {/* Plug image with motion path animation */}
      <motion.div
        style={{
          position: 'absolute',
          height: 175,
          top: 180,
          left: 240,
          offsetPath: 'path("M 19 61 C 60 57 81 61 114 69")',
          offsetDistance: plugged ? '7%' : '74%',
          transform: 'rotate(2deg)',
        }}
        animate={plugControl}
      >
        <img src={plug} width={180} alt="" />

        {/* Point of connection between the plug and the wire */}
        <div
          ref={plugRef}
          style={{
            position: 'absolute',
            top: 71,
            left: 130,
          }}
        />
      </motion.div>

      {/* Plug mask */}
      <FillSvg viewBox="218 670 501 354">
        <defs>
          <mask id="plug-mask">
            <rect fill="white" width="501" height="354" x="218" y="670" />
            <rect fill="black" width="296" height="354" x="423" y="670" />
            <path fill="black" d="M427 882a9 9 0 1 0 0-19 9 9 0 0 0 0 19Zm0 52a9 9 0 1 0 0-19 9 9 0 0 0 0 19Z" />
          </mask>
        </defs>
        <path
          mask="url(#plug-mask)"
          fill="#FFD231"
          stroke="#171618"
          strokeWidth=".8"
          d="M466 974h-79c-5 0-10-5-10-10V832c0-5 5-10 10-10h79c6 0 10 5 10 10v132c0 5-4 10-10 10Z"
        />
      </FillSvg>

      {/* Wire connected to the plug */}
      <FillSvg viewBox="218 670 501 354">
        <path
          ref={wirePathRef}
          fill="none"
          stroke="#171618"
          strokeWidth={6}
          d={wirePath()}
        />
      </FillSvg>
    </div>
  );
};
