import { IndentIncreaseIcon, EmbedIcon, KanbanIcon } from '@cycle-app/ui/icons';

import {
  BILLING_LIMIT_BOARD_SECTIONS,
  BILLING_LIMIT_INTEGRATIONS,
  BILLING_LIMIT_BOARDS,
} from 'src/constants/billing.constants';
import { useIsFreePlan, useProductCounters } from 'src/hooks';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { BillingUpdateLayout } from '../../BillingUpdateLayout';
import { DowngradeButton, MainBox } from './BillingDowngrade.styles';
import { BillingDowngradeFeatureItem } from './BillingDowngradeFeatureItem';
import { BillingDowngradeModalBoards } from './BillingDowngradeModalBoards';
import { BillingDowngradeModalIntegrations } from './BillingDowngradeModalIntegrations';
import { BillingDowngradeModalSections } from './BillingDowngradeModalSections';
import { BillingDowngradeModalSubmit } from './BillingDowngradeModalSubmit';

export const BillingDowngrade = () => {
  const { canReadSettings } = useGetPermission();
  const {
    customSectionsCount, installedIntegrationsCount, boardsCount, inboxCountWithMyInbox,
  } = useProductCounters();
  const isFreePlan = useIsFreePlan();
  const [isSectionsModalOpened, { toggleCallback: toggleSectionsModal }] = useOptimizedBooleanState(false);
  const [isBoardsModalOpened, { toggleCallback: toggleBoardsModal }] = useOptimizedBooleanState(false);
  const [isIntegrationsModalOpened, { toggleCallback: toggleIntegrationsModal }] = useOptimizedBooleanState(false);
  const [isDowngradeSubmitModalOpened, { toggleCallback: toggleDowngradeSubmitModal }] = useOptimizedBooleanState(false);
  const customSecionsExceeded = customSectionsCount > BILLING_LIMIT_BOARD_SECTIONS;
  const integrationExceeded = installedIntegrationsCount > BILLING_LIMIT_INTEGRATIONS;
  const boardsExceeded = boardsCount > BILLING_LIMIT_BOARDS;
  const someExceeded = customSecionsExceeded || integrationExceeded || boardsExceeded;
  const isDowngradeDisabled = !canReadSettings || someExceeded || isFreePlan;
  const downgradeLabel = isFreePlan ? 'You are already in Free plan' : 'Downgrade';
  return (
    <>
      <BillingUpdateLayout
        title="Downgrade"
        main={(
          <MainBox>
            <BillingDowngradeFeatureItem
              count={customSectionsCount}
              icon={<IndentIncreaseIcon />}
              limit={BILLING_LIMIT_BOARD_SECTIONS}
              name="Sections"
              {...canReadSettings && {
                onEdit: toggleSectionsModal,
              }}
            />
            <BillingDowngradeFeatureItem
              count={installedIntegrationsCount}
              icon={<EmbedIcon />}
              limit={BILLING_LIMIT_INTEGRATIONS}
              name="Unlimited integrations"
              {...canReadSettings && {
                onEdit: toggleIntegrationsModal,
              }}
            />
            <BillingDowngradeFeatureItem
              count={boardsCount + inboxCountWithMyInbox}
              icon={<KanbanIcon />}
              limit={BILLING_LIMIT_BOARDS}
              name="Unlimited views"
              {...canReadSettings && {
                onEdit: toggleBoardsModal,
              }}
            />
            <DowngradeButton
              disabled={isDowngradeDisabled}
              variant="warning"
              size="L"
              tooltipPlacement="top"
              onClick={toggleDowngradeSubmitModal}
              {...someExceeded && !isFreePlan && {
                tooltip: 'Edit the above so you can downgrade',
              }}
            >
              {downgradeLabel}
            </DowngradeButton>
          </MainBox>
        )}
      />
      {isSectionsModalOpened && <BillingDowngradeModalSections onClose={toggleSectionsModal} />}
      {isBoardsModalOpened && <BillingDowngradeModalBoards onClose={toggleBoardsModal} />}
      {isIntegrationsModalOpened && <BillingDowngradeModalIntegrations onClose={toggleIntegrationsModal} />}
      {isDowngradeSubmitModalOpened && <BillingDowngradeModalSubmit onClose={toggleDowngradeSubmitModal} />}
    </>
  );
};
