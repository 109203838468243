import { Emoji, Tooltip } from '@cycle-app/ui';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useCallback } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { useDoc } from 'src/hooks/api/useDoc';
import { useDocSubscription } from 'src/hooks/api/useDocSubscription';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useUrl } from 'src/hooks/useUrl';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocSlug } from 'src/utils/slug.util';

import {
  DocMentionNodeView,
  LinkIcon,
  DocId,
  Left,
  TitleContainer,
  LinkItem,
  ParentElementContainer,
  LoadingState,
  StyledDocParentDropdown,
} from './DocMentionView.styles';

const DocMentionView: FC<NodeViewRendererProps> = ({ node }) => {
  const getUrl = useUrl();

  const product = useProductBase();
  const {
    doc, loading,
  } = useDoc(node.attrs.id);
  useDocSubscription(doc?.id);
  const { isReadOnly } = useEditorContext();

  const onDocMentionHovered = useCallback((hoverDocId: string) => {
    setDocItem({ hoverDocId });
    if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: hoverDocId });
  }, []);
  const onDocMentionLeft = useCallback(() => setDocItem({ hoverDocId: null }), []);
  const onMouseEnter = useCallback(() => onDocMentionHovered(doc?.id ?? ''), [onDocMentionHovered, doc?.id]);

  return (
    <DocMentionNodeView
      onMouseEnter={!loading && !!doc ? onMouseEnter : undefined}
      onMouseLeave={!loading && !!doc ? onDocMentionLeft : undefined}
      data-drag-handle
      data-type="doc-mention"
    >
      {renderContent()}
    </DocMentionNodeView>
  );

  function renderContent() {
    if (loading) return <LoadingState />;
    if (!doc) return null;

    return (
      <>
        <LinkItem to={getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) })}>
          <Left>
            <LinkIcon />
            <Emoji emoji={doc.doctype.emoji} />
            <DocId>{`#${product?.key}-${doc.publicId}`}</DocId>
          </Left>
          <TitleContainer>
            <Tooltip
              placement="bottom"
              content={doc.title}
              withPortal
              zIndex={mappingZindex[Layer.DropdownZ2]}
              displayFullTitle
            >
              {doc.title}
            </Tooltip>
          </TitleContainer>
        </LinkItem>
        {renderParent()}
      </>
    );
  }

  function renderParent() {
    if (!doc?.doctype?.parents?.edges?.length || isReadOnly) return null;

    return (
      <ParentElementContainer>
        <StyledDocParentDropdown doc={doc} />
      </ParentElementContainer>
    );
  }
};

export default DocMentionView;
