import { Paper } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  color: ${p => p.theme.colors.background.primary};
  width: 82px;
  height: 97px;

  > path:first-child {
    fill: ${p => p.theme.colors.background.secondary};
    stroke: ${p => p.theme.colors.text.primary};
  }

  > path:last-child {
    stroke: ${p => p.theme.colors.text.primary};
  }
`;

export const PaperGroup = styled.div`
  transition: transform .2s;
`;

export const PapersContainer = styled.div`
  position: relative;
  width: 100%;

  ${mobile} {
    height: 200px;
    max-width: 260px;
    margin: 0 auto;
  }
`;

export const PaperWrapper = styled.div`
  position: absolute;

  img {
    position: absolute;
    z-index: 1;
  }

  &:first-child {
    left: -5px;
    top: 80px;

    ${PaperGroup} {
      transform: rotate(15deg);
    }

    img {
      left: 4px;
      transform: rotate(348deg);
    }

    svg {
      transform: scale(-1, 1);
    }

    &:hover ${PaperGroup}  {
      transform: rotate(-15deg);
    }
  }

  &:nth-child(2) {
    top: 160px;
    left: 47%;
    z-index: 2;

    img {
      left: -80%;
      top: -20%;
    }

    ${PaperGroup} {
      transform-origin: -10px 10px;
    }

    svg {
      &:first-child {
        transform: translate(-50%, 4px) scale(-1,1);
      }

      &:last-child {
        transform: translate(-50%, 0) scale(-1,1);
        position: absolute;
        left: 4px;
      }
    }

    &:hover ${PaperGroup} {
      transform: rotate(-10deg);
    }
  }

  &:last-child {
    top: 80px;
    left: 63%;
    z-index: 2;

    ${PaperGroup} {
      transform: rotate(345deg);
      transform-origin: 60px 10px;
    }

    img {
      top: -40%;
      right: -25%;
      transform: rotate(11deg);
    }

    &:hover ${PaperGroup}  {
      transform: rotate(315deg);
    }
  }
`;
