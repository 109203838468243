import { DropdownSelect, SelectOption } from '@cycle-app/ui';
import { FC } from 'react';

import { CustomAttributeType } from 'src/types/attribute.types';
import { customAttributeTypeData } from 'src/utils/attributes.util';

import { StyledButton } from './SettingsAttributes.styles';

interface SelectAttributeTypeProps {
  attributeType: CustomAttributeType | undefined;
  onChange: (type: CustomAttributeType) => void;
}

const SelectAttributeType: FC<SelectAttributeTypeProps> = ({
  attributeType, onChange,
}) => {
  const options = Object.entries(customAttributeTypeData).map(([type, {
    icon, label,
  }]) => ({
    value: type,
    label,
    icon,
  }));

  return (
    <DropdownSelect
      options={options}
      hideSearch
      selectedValue={attributeType}
      onChange={(option: SelectOption) => onChange(option.value as CustomAttributeType)}
    >
      <StyledButton tooltip="Choose the attribute type">
        {attributeType ? (
          <>
            {customAttributeTypeData[attributeType].icon}
            {customAttributeTypeData[attributeType].label}
          </>
        ) : 'Choose Type'}
      </StyledButton>
    </DropdownSelect>
  );
};

export default SelectAttributeType;
