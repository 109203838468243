import { useSubscription } from '@apollo/client';
import {
  ProductSubscriptionSubscriptionVariables,
  ProductSubscriptionDocument,
  ProductSubscriptionSubscription,
} from '@cycle-app/graphql-codegen';

export const useProductSubscription = (productId?: string) => useSubscription<
ProductSubscriptionSubscription,
ProductSubscriptionSubscriptionVariables
>(ProductSubscriptionDocument, {
  variables: {
    productId: productId as string,
  },
});
