import ReactFlow, { Elements } from 'react-flow-renderer';

import FlowElement from 'src/components/FlowElement/FlowElement';
import { FlowElementIntegrations } from 'src/components/FlowElement/FlowElementIntegrations';
import FlowPath from 'src/components/FlowPath/FlowPath';

import { Container } from './FlowDoctypes.styles';
import { FlowMarkerDefs } from './FlowMarkerDefs';

interface Props {
  elements: Elements;
}

const FlowDoctypes = ({ elements }: Props) => (
  <>
    <FlowMarkerDefs />
    <Container>
      <ReactFlow
        zoomOnPinch
        zoomOnScroll={false}
        elements={elements}
        defaultZoom={0.8}
        panOnScroll
        nodeTypes={{
          custom: FlowElement,
          integrations: FlowElementIntegrations,
        }}
        edgeTypes={{
          customEdge: FlowPath,
        }}
      />
    </Container>
  </>
);

export default FlowDoctypes;
