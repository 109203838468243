import { Property, ListPositionInput } from '@cycle-app/graphql-codegen';
import { GroupContainer, Spinner } from '@cycle-app/ui';
import { NextArrowIcon, DownArrowIcon } from '@cycle-app/ui/icons';
import { DndContext, DragOverlay, DragOverEvent } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, memo, useCallback, useMemo, useRef } from 'react';

import BoardContentListeners from 'src/components/BoardContentListeners/BoardContentListeners';
import BoardGroup from 'src/components/BoardGroup/BoardGroup';
import BoardGroupSortable from 'src/components/BoardGroup/BoardGroupSortable';
import { DocItemHandle } from 'src/components/DocItem';
import DocItemOverlay from 'src/components/DocItemOverlay/DocItemOverlay';
import { BOARD_CONTENT_ID } from 'src/constants/board.constant';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useToaster } from 'src/hooks';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { useMoveDocs } from 'src/hooks/api/mutations/useMoveDoc';
import { BoardGroup as BoardGroupData, useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { useGroupsDnd, OnItemsMovedParams, OnGroupMovedParams } from 'src/hooks/dnd/useGroupsDnd';
import { setBoardDnd } from 'src/reactives/boardDnd.reactive';
import { getCollapsedGroups, setCollapsedGroups, useGetCollapsedGroups } from 'src/reactives/collapsedGroups.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getSelection, setSelection } from 'src/reactives/selection.reactive';
import { ViewType } from 'src/types/viewType.types';
import { getBoardCollisionDetection, groupsToItems } from 'src/utils/dnd.util';
import { getIsAtLeastOneInsightFromDocIds } from 'src/utils/doc.util';

import { Container, Content, LoadMoreGroups } from './BoardContent.styles';
import BoardContentNewGroup from './BoardContentNewGroup/BoardContentNewGroup';
import { BoardContentSkeleton } from './BoardContentSkeleton';

interface BoardContentProps {
  viewType: ViewType;
  boardConfigId: string | undefined;
}

const GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW: Array<Property['__typename']> = [
  'AssigneeDefinition',
  'BuiltInCompanyDefinition',
  'BuiltInCustomerDefinition'];
const GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP: Array<Property['__typename']> = ['CreatorDefinition', 'DoctypeDefinition'];

const BoardContent: FC<BoardContentProps> = ({
  viewType,
  boardConfigId,
}) => {
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const builtInDisplay = useBoardConfig(ctx => ctx.builtInDisplay);
  const isDndGroupsEnabled = useBoardConfig(ctx => ctx.isDndGroupsEnabled);
  const isGroupCreationDisabled = useBoardConfig(ctx => ctx.isGroupCreationDisabled);
  const insightDocTypeInfo = useBoardConfig(ctx => ctx.insightDocType);
  const { add } = useToaster();

  const {
    groups, mappingDocIds, withGroupBy, moreGroups,
  } = useBoardGroups();

  const docItemRefs = useRef<DocItemHandle[][]>([]);

  const { moveSelectAttributeValue } = useAttributesMutations();
  const { moveDocs } = useMoveDocs();

  const [docIds] = useMemo(() => groupsToItems(groups ?? {}), [groups]);

  const onItemsMoved = useCallback(async ({
    groupId,
    previousGroupIds,
    itemsId,
    updatedItems,
    position,
  }: OnItemsMovedParams) => {
    setBoardDnd({ dragging: false });

    await moveDocs({
      groupId,
      previousGroupIds,
      docsMoved: itemsId.map(itemId => mappingDocIds[itemId]),
      docsListUpdated: updatedItems[groupId].map(itemId => mappingDocIds[itemId]),
      groupByProperty,
      position,
    });

    setSelection({ lock: false });
  }, [groupByProperty, mappingDocIds, moveDocs]);

  const onGroupMoved = useCallback(async ({
    groupId, sortedGroupIds, position,
  }: OnGroupMovedParams) => {
    setBoardDnd({ dragging: false });

    const group = groups?.[groupId];
    if (!groups || !group?.attributeValueId || !groupByProperty) return;

    const beforeGroup = 'before' in position ? groups[position.before] : null;
    const afterGroup = 'after' in position ? groups[position.after] : null;
    if (!beforeGroup?.attributeValueId && !afterGroup?.attributeValueId) return;

    const attributeValuePosition = {
      ...beforeGroup?.attributeValueId && { before: beforeGroup.attributeValueId },
      ...afterGroup?.attributeValueId && { after: afterGroup.attributeValueId },
    } as ListPositionInput;

    await moveSelectAttributeValue({
      attributeId: groupByProperty.id,
      valueId: group.attributeValueId,
      position: attributeValuePosition,
      sortedItems: sortedGroupIds,
      boardConfigId,
    });
  }, [boardConfigId, groupByProperty, groups, moveSelectAttributeValue]);

  // eslint-disable-next-line no-nested-ternary
  const crossGroupStrategy = useMemo(() => (insightDocTypeInfo.isTheOnlyOne
    ? [
      ...GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP,
      ...GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW,
      'StatusDefinition',
    ].includes(groupByProperty?.__typename)
      ? 'disabled'
      : 'sorting'
    : GROUP_BY_RULES_WITH_DISABLED_CROSS_GROUP.includes(groupByProperty?.__typename)
      ? 'disabled'
      : 'sorting'), [groupByProperty?.__typename, insightDocTypeInfo.isTheOnlyOne]);

  const getDoc = useCallback((docId: string) => mappingDocIds[docId], [mappingDocIds]);

  const shouldPreventDnD = (
    e: DragOverEvent,
    groupsOrigin?: string[],
    groupTarget?: string,
    isOnDrop?: boolean,
  ): boolean => {
    if (!GROUP_BY_RULES_WITH_INSIGHT_NO_EDIT_ALLOW.includes(groupByProperty?.__typename)) return false;

    const { selected: selectedDocIds } = getSelection();
    const docToCheck = selectedDocIds.length ? selectedDocIds : [String(e.active.id)];

    if (!docToCheck.length) return false;

    const isInsightInSelection = getIsAtLeastOneInsightFromDocIds(docToCheck, getDoc);

    if (!isInsightInSelection) return false;
    const isMovingToSameGroup = groupsOrigin?.[0] === groupTarget;
    // If only one origin groupe -> Not block DnD
    if (groupsOrigin?.length === 1 && isMovingToSameGroup) return false;
    // If one item AND Insight AND same group -> OK
    // If one item AND NOT Insight -> OK
    // If many items of many group => true (cannot DnD)
    // If many items of same group -> Should be same origin and target
    if (isOnDrop) {
      add({
        message: "You can't make that change since insights's properties are not editable",
      });
    }

    return true;
  };

  const {
    dndContextProps,
    direction,
    activeId,
    activeType,
    items,
  } = useGroupsDnd({
    initialItems: docIds,
    onItemsMoved,
    onGroupMoved,
    crossGroupStrategy,
    collisionDetection: getBoardCollisionDetection(viewType),
    onStart: (docActiveId) => {
      const currentSelected = getSelection().selected;
      if (currentSelected.length && !currentSelected.includes(docActiveId)) {
        setSelection({ selected: [] });
      }
      setBoardDnd({ dragging: true });
    },
    onCancel: () => setBoardDnd({ dragging: false }),
    shouldPreventDnD,
  });

  const collapsedGroups = useGetCollapsedGroups();
  const toggleCollapse = useCallback((groupId) => {
    setCollapsedGroups({ [groupId]: !getCollapsedGroups()[groupId] });
  }, []);

  const {
    canReorderGroups, canCreateGroup,
  } = useGetPermission();

  if (!groups) {
    return (
      <BoardContentSkeleton viewType={viewType} />
    );
  }

  return (
    <Container>
      <BoardContentListeners
        activeId={activeId}
        viewType={viewType}
        docIds={docIds}
        docItemRefs={docItemRefs}
      />

      <DndContext {...dndContextProps}>
        <SortableContext
          items={Object.keys(items)}
          strategy={viewType === ViewType.Kanban ? horizontalListSortingStrategy : verticalListSortingStrategy}
        >
          <Content viewType={viewType}>
            <GroupContainer
              id={BOARD_CONTENT_ID}
              viewType={viewType}
            >
              <>
                {Object
                  .keys(items)
                  .map(((groupId, groupIndex) => {
                    const group: BoardGroupData | undefined = groups[groupId];
                    if (!group) {
                      return null;
                    }

                    const groupItems = items[groupId];

                    return (
                      <BoardGroupSortable
                        ref={(docItemRefsInGroup) => {
                          if (docItemRefsInGroup !== null) {
                            docItemRefs.current[groupIndex] = docItemRefsInGroup;
                          }
                        }}
                        key={groupId}
                        items={groupItems}
                        groupId={groupId}
                        groupIndex={groupIndex}
                        boardConfigId={boardConfigId}
                        groupByProperty={groupByProperty}
                        group={group}
                        activeId={activeId}
                        activeType={activeType}
                        viewType={viewType}
                        getDoc={getDoc}
                        withGroupBy={withGroupBy}
                        disableDnd={!isDndGroupsEnabled || !canReorderGroups}
                        collapse={collapsedGroups[groupId]}
                        toggleCollapse={toggleCollapse}
                        builtInDisplay={builtInDisplay}
                      />
                    );
                  }))}

                {moreGroups?.pageInfo?.hasNextPage && !!moreGroups?.exec && (
                  <LoadMoreGroups
                    onClick={moreGroups.exec}
                    disabled={moreGroups?.loading}
                    isLoading={moreGroups?.loading}
                    viewType={viewType}
                  >
                    {moreGroups?.loading && <Spinner />}
                    {!moreGroups?.loading && (
                      <>
                        {viewType === ViewType.Kanban ? <NextArrowIcon /> : <DownArrowIcon />}
                        <div>Load more</div>
                      </>
                    )}
                  </LoadMoreGroups>
                )}

                {groupByProperty &&
                  !isGroupCreationDisabled &&
                  !moreGroups?.loading &&
                  !moreGroups?.pageInfo?.hasNextPage &&
                  canCreateGroup && <BoardContentNewGroup viewType={viewType} />}
              </>
            </GroupContainer>
          </Content>
          <DragOverlay>
            {activeType === 'item' && activeId && (
              <DocItemOverlay
                activeId={activeId}
                direction={direction}
                viewType={viewType}
              />
            )}
            {activeType === 'group' && activeId && (
              <BoardGroup
                groupId={activeId}
                boardConfigId={boardConfigId}
                group={groups[activeId]}
                activeType={activeType}
                items={items[activeId]}
                viewType={viewType}
                withGroupBy={withGroupBy}
                getDoc={getDoc}
                isDragging
                builtInDisplay={builtInDisplay}
              />
            )}
          </DragOverlay>
        </SortableContext>
      </DndContext>
    </Container>
  );
};

export default memo(BoardContent);
