import { DocBaseFragment, ViewType } from '@cycle-app/graphql-codegen';
import { SortableContext } from '@dnd-kit/sortable';
import { FC } from 'react';

import SortableItem from 'src/components/SortableItem/SortableItem';
import { BuiltInDisplay } from 'src/contexts/boardConfigContext';
import { DocProvider } from 'src/contexts/docContext';
import { useMoreDocsInGroup } from 'src/hooks/api/useMoreDocsInGroup';
import { DndItemType } from 'src/hooks/dnd/useGroupsDnd';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import { DocData } from 'src/hooks/useUrl';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { StyledGroup } from '../BoardContentWithSwimlane.styles';
import {
  DocsList,
  StyledDocItem,
  StyledLoadMore,
} from './SwimlaneGroup.styles';

interface Props {
  className?: string;
  groupId: string;
  swimlaneDocId: string;
  swimlaneDoctypeId: string | undefined;
  builtInDisplay: BuiltInDisplay;
  collapsed: boolean;
  reasonCreateDocDisabled: string | undefined;
  onNewDocClick: VoidFunction;
  pageInfo: {
    hasNextPage: boolean;
    endCursor?: string | null;
  } | undefined;

  docIds: string[];
  selectedDocIds: string[];
  activeId: string | null;
  activeType: DndItemType;

  getDoc: (id: string) => DocBaseFragment | undefined;
  getDocPanelUrl: (data: DocData) => string;

  isOver?: boolean;
}

const SwimlaneGroup: FC<Props> = ({
  className,
  groupId,
  swimlaneDocId,
  swimlaneDoctypeId,
  builtInDisplay,
  collapsed,
  reasonCreateDocDisabled,
  onNewDocClick,
  getDoc,
  getDocPanelUrl,
  docIds,
  selectedDocIds,
  activeId,
  activeType,
  pageInfo,
  isOver,
  children,
}) => {
  const {
    moreDocs,
    loading,
  } = useMoreDocsInGroup(groupId);
  const isMobile = useIsMobile();

  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);

  return (
    <StyledGroup
      className={className}
      collapse={collapsed}
      reasonCreateDocDisabled={reasonCreateDocDisabled}
      onNewDocClick={onNewDocClick}
      isOver={isOver}
      createDocHidden={pageInfo?.hasNextPage}
    >
      <DocsList>
        <SortableContext items={docIds}>
          {docIds
            .filter((itemId) => {
              const isSelected = selectedDocIds.includes(itemId);
              return activeType !== 'item' || !isSelected || activeId === itemId;
            })
            .map((docId) => {
              const doc = getDoc(docId);
              const canHaveSwimlaneDoctypeParent = !!doc?.doctype.parents?.edges.find(({ node }) => node.id === swimlaneDoctypeId);
              return (
                <SortableItem
                  id={docId}
                  key={docId}
                  metaData={{
                    swimlaneDocId,
                    canHaveSwimlaneDoctypeParent,
                  }}
                >
                  {doc && (
                    <DocProvider value={doc}>
                      <StyledDocItem
                        viewType={ViewType.Kanban}
                        showAssignee={builtInDisplay.assignee}
                        showComments={builtInDisplay.comments}
                        showCreator={builtInDisplay.creator}
                        showCreatedAt={builtInDisplay.createdAt && !doc.isDraft && !isMobile}
                        showCover={builtInDisplay.cover}
                        showDocParent={builtInDisplay.parent}
                        showChildren={builtInDisplay.children}
                        showCustomer={builtInDisplay.customer && !!doc?.doctype.customer}
                        showDocId={builtInDisplay.docId && !doc.isDraft}
                        showDocType={builtInDisplay.docType}
                        showSource={builtInDisplay.source && !doc.isDraft && !!doc.source}
                        showStatus={isStatusEnabled && builtInDisplay.status}
                        asPlaceholder={docId === activeId}
                        docUrl={getDocPanelUrl(doc)}
                        isSelectable
                      />
                    </DocProvider>
                  )}
                </SortableItem>
              );
            })}

        </SortableContext>
        {pageInfo?.hasNextPage && pageInfo?.endCursor && (
          <StyledLoadMore
            onClick={() => moreDocs(pageInfo.endCursor as string)}
            loading={loading}
          />
        )}
      </DocsList>
      {children}
    </StyledGroup>
  );
};

export default SwimlaneGroup;
