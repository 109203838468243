import { Button } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  ${mobile} {
    grid-template-columns: 1fr;
  }
`;

export const LogoUploadLine = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img<{ $size?: number }>`
  height: ${p => p.$size}px;
  width: ${p => p.$size}px;
  margin-right: 8px;
  border-radius: 4px;
  object-fit: cover;
`;

export const LogoUploadButton = styled(Button)`
  flex: 1;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => props.theme.userColors.main};
  border: 1px dashed ${(props) => props.theme.userColors.main};
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
