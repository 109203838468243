import { FC, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PageId, routing } from 'src/constants/routing.constant';
import { usePageId } from 'src/hooks/usePageId';
import { useAuthFromUrl } from 'src/hooks/user/useAuthFromUrl';
import { useGetAuth } from 'src/reactives/auth.reactive';
import { pageAnalytics } from 'src/utils/analytics/analytics';

const ProtectedRoute: FC<RouteProps> = ({
  children,
  ...routeProps
}) => {
  const pageId = usePageId();
  const {
    token, onboarded,
  } = useGetAuth();
  const {
    location, tokenFromUrl,
  } = useAuthFromUrl();

  useEffect(() => {
    pageAnalytics(pageId);
  }, [location.pathname]);

  return (
    <Route {...routeProps}>
      {renderRoute()}
    </Route>
  );

  function renderRoute() {
    if (!token && !tokenFromUrl) {
      const search = new URLSearchParams(location.search);
      const searchString = search.toString();
      return (
        <Redirect
          to={{
            pathname: routing[PageId.Login],
            search: `?from=${location.pathname}${searchString ? `?${encodeURIComponent(searchString)}` : ''}`,
          }}
        />
      );
    }
    if (!onboarded && pageId !== PageId.Welcome) {
      return (
        <Redirect to={routing[PageId.Welcome]} />
      );
    }

    return children;
  }
};
export default ProtectedRoute;
