import { Button, ButtonVariant, CheckboxInput } from '@cycle-app/ui';
import { WarningIcon, CloseIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode, ChangeEventHandler } from 'react';

import { Layer } from '../../types/layers.types';
import {
  Title, Actions, Body, PortalModalStyled, IconContainer, Header, CloseButtonStyled, Footer, ConfirmMessageContainer,
} from './DialogModal.styles';

export interface DialogModalProps {
  className?: string;
  title: string;
  info?: string | ReactNode;
  type?: 'warning' | 'default';
  confirmMessage?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  useHighMaskLayer?: boolean;
  hide: () => void;
  onCancel?: () => void;
  onConfirm?: () => (void | Promise<void>);
  variantCancelButton?: ButtonVariant;
  variantSubmitButton?: ButtonVariant;
  loading?: boolean;
  autoHide?: boolean;
  isCancelDisabled?: boolean;
  isCancelLoading?: boolean;
  isConfirmDisabled?: boolean;
  onToggleConfirmation?: ChangeEventHandler<HTMLInputElement>;
  layer?: Layer;
  width?: string;
}

const DialogModal: FC<DialogModalProps> = ({
  className,
  title,
  info,
  type = 'warning',
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  useHighMaskLayer,
  onCancel,
  onConfirm,
  hide,
  variantCancelButton = 'warning-secondary',
  variantSubmitButton = 'warning',
  loading,
  autoHide = true,
  confirmMessage,
  isCancelDisabled,
  isCancelLoading,
  isConfirmDisabled,
  onToggleConfirmation,
  layer = Layer.DialogModal,
  width,
}) => (
  <PortalModalStyled
    animate
    hide={hide}
    useHighMaskLayer={useHighMaskLayer}
    className={className}
    layer={layer}
    width={width}
  >
    <Header>
      <Title>
        {type === 'warning' && (
          <IconContainer>
            <WarningIcon />
          </IconContainer>
        )}
        {title}
      </Title>
      <CloseButtonStyled size="L" onClick={hide}>
        <CloseIcon />
      </CloseButtonStyled>
    </Header>
    {info && (
      <Body>
        {info}
      </Body>
    )}
    <Footer>
      {confirmMessage && onToggleConfirmation && (
        <ConfirmMessageContainer>
          <CheckboxInput
            id="dialog-confirm-checkbox"
            defaultChecked={false}
            label={confirmMessage}
            onChange={onToggleConfirmation}
            useUserColor={false}
          />
        </ConfirmMessageContainer>
      )}
      <Actions>
        <Button
          onClick={() => {
            hide();
            onCancel?.();
          }}
          variant={variantCancelButton}
          size="M"
          disabled={isCancelDisabled}
          isLoading={isCancelLoading}
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={() => {
            if (autoHide) {
              hide();
            }
            onConfirm?.();
          }}
          variant={variantSubmitButton}
          size="M"
          isLoading={loading}
          disabled={isConfirmDisabled}
        >
          {confirmLabel}
        </Button>
      </Actions>
    </Footer>
  </PortalModalStyled>
);

export default DialogModal;
