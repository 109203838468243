import { ActionButton, SelectLine } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { NodeViewWrapper } from '@tiptap/react';
import styled from 'styled-components';

export const Image = styled.img``;

export const MenuContent = styled.div`
  display: flex;
`;

export const DragActionButton = styled(ActionButton)`
  cursor: grab;
`;

export const ImageView = styled(NodeViewWrapper)<{ $isSelected: boolean }>`
  position: relative;
  cursor: ${p => (p.$isSelected ? 'grab' : 'pointer')};
  
  &:hover .image-menu {
    opacity: 1;
  }

  > ${Image} {
    width: 100%;
    height: auto;
    border-radius: 8px;
    outline: ${p => (p.$isSelected ? `${p.theme.userColors.main} solid 2px` : 'none')}
  }
`;

export const SelectLineItem = styled(SelectLine)`
  width: 150px;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  margin-top: 4px
`;
