import { CompanyFragment } from '@cycle-app/graphql-codegen/generated';

import { CompanyName } from './CompanyName';
import { StyledHeader } from './CompanyProfile.styles';

export interface CustomerProfileHeaderProps {
  className?: string;
  company: CompanyFragment;
}

export const CompanyProfileHeader = ({
  className, company,
}: CustomerProfileHeaderProps) => (
  <StyledHeader className={className}>
    <CompanyName company={company} />
  </StyledHeader>
);
