import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const GitLabIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M23.6071 9.15159L23.5733 9.06534L20.3058 0.537843C20.2394 0.370708 20.1217 0.228927 19.9696 0.132843C19.8174 0.038391 19.64 -0.00710008 19.4611 0.00251188C19.2823 0.0121238 19.1107 0.076376 18.9696 0.186593C18.83 0.299974 18.7288 0.45361 18.6796 0.626593L16.4733 7.37659H7.53959L5.33334 0.626593C5.2855 0.452668 5.18402 0.298262 5.04335 0.185343C4.90219 0.0751257 4.73062 0.0108736 4.5518 0.00126161C4.37297 -0.00835035 4.1955 0.0371408 4.04334 0.131593C3.89163 0.228065 3.77402 0.369722 3.70709 0.536592L0.433346 9.06034L0.400845 9.14659C-0.0695264 10.3756 -0.127586 11.7242 0.23542 12.9891C0.598426 14.254 1.36282 15.3666 2.41335 16.1591L2.4246 16.1678L2.4546 16.1891L7.43209 19.9166L9.8946 21.7803L11.3946 22.9128C11.5701 23.0461 11.7843 23.1182 12.0046 23.1182C12.2249 23.1182 12.4391 23.0461 12.6146 22.9128L14.1146 21.7803L16.5771 19.9166L21.5846 16.1666L21.5971 16.1566C22.6453 15.3639 23.4079 14.2525 23.7703 12.9893C24.1328 11.7261 24.0755 10.3794 23.6071 9.15159V9.15159Z" fill="#E24329" />
    <path d="M23.6072 9.15168L23.5734 9.06543C21.9813 9.39223 20.481 10.0666 19.1797 11.0404L12.0034 16.4667C14.4472 18.3154 16.5747 19.9217 16.5747 19.9217L21.5822 16.1717L21.5947 16.1617C22.6443 15.369 23.4081 14.2569 23.7711 12.9926C24.134 11.7284 24.0765 10.3804 23.6072 9.15168Z" fill="#FC6D26" />
    <path d="M7.43213 19.9216L9.89463 21.7853L11.3946 22.9178C11.5701 23.051 11.7843 23.1231 12.0046 23.1231C12.2249 23.1231 12.4392 23.051 12.6146 22.9178L14.1146 21.7853L16.5771 19.9216C16.5771 19.9216 14.4471 18.3103 12.0034 16.4666C9.55963 18.3103 7.43213 19.9216 7.43213 19.9216Z" fill="#FCA326" />
    <path d="M4.82585 11.0403C3.52559 10.0645 2.0256 9.38834 0.433346 9.0603L0.400845 9.14655C-0.0695264 10.3756 -0.127586 11.7242 0.23542 12.9891C0.598426 14.2539 1.36282 15.3665 2.41335 16.1591L2.4246 16.1678L2.4546 16.1891L7.43209 19.9166C7.43209 19.9166 9.5571 18.3103 12.0033 16.4616L4.82585 11.0403Z" fill="#FC6D26" />
  </Svg>
);
