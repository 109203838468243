import { SearchIcon } from '@cycle-app/ui/icons';
import { useState, FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useCompanies } from 'src/hooks/api/queries/customers/useCompanies';
import { setCompanyProfileModal } from 'src/reactives';

import { CustomersCommonTabList } from '../CustomersCommonTabList/CustomersCommonTabList';
import { CompanyAddButton } from './CompanyAddButton';
import {
  Tabs,
  Nav,
  Actions,
  SearchInput,
} from './Customers.styles';
import { CustomerListCompanies } from './CustomersListCompanies';

export const CustomerCompanies: FC = () => {
  const {
    companies, pageInfo, isLoading, fetchNextPage, searchCompanies,
  } = useCompanies();
  const [searchText, setSearchText] = useState('');

  const searchCompaniesDebounced = useDebouncedCallback((search: string) => searchCompanies(search), 300);

  return (
    <Tabs>
      <Nav>
        <CustomersCommonTabList />
        <Actions>
          <SearchInput
            iconBefore={<SearchIcon />}
            placeholder="Search…"
            value={searchText}
            onChange={async (e) => {
              setSearchText(e.target.value);
              await searchCompaniesDebounced(e.target.value);
            }}
          />
          <CompanyAddButton />
        </Actions>
      </Nav>
      <CustomerListCompanies
        companies={companies}
        onCompanyClick={companyId => setCompanyProfileModal({
          companyId,
          isOpen: true,
        })}
        isLoading={!searchText && isLoading}
        hasNextPage={pageInfo?.hasNextPage}
        loadMore={() => fetchNextPage()}
        searchText={searchText}
      />
    </Tabs>
  );
};
