import { Link } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { useCompanies } from 'src/hooks/api/queries/customers/useCompanies';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { usePageId } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';

import { TabList, Tab, Badge } from './CustomersCommonTabList.styles';

export const CustomersCommonTabList = () => {
  const pageId = usePageId();
  const getUrl = useUrl();
  const { companiesCount } = useCompanies();
  const { customerCount } = useCustomers();

  return (
    <TabList>
      <Tab
        as={Link}
        to={getUrl(PageId.SettingsCustomers)}
        $active={PageId.SettingsCustomers === pageId}
      >
        People
        <Badge>{customerCount}</Badge>
      </Tab>
      <Tab
        as={Link}
        to={getUrl(PageId.SettingsCompanies)}
        $active={PageId.SettingsCompanies === pageId}
      >
        Companies
        <Badge>{companiesCount}</Badge>
      </Tab>
    </TabList>
  );
};
