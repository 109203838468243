import { typo, MockDocPanel, MockSlashDropdown } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import DocAttributes from '../../DocAttributes/DocAttributes';
import { ReadOnlyEditor } from '../../Editor';
import { OnboardingCollabMates } from '../../OnboardingCollabMates/OnboardingCollabMates';
import { OnboardingFloatingMates } from '../../OnboardingFloatingMates/OnboardingFloatingMates';

export const Team = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TeamTitle = styled.div`
  ${typo.headerMedium};
`;

export const TeamMember = styled.div`
  ${typo.body400};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledDocAttributes = styled(DocAttributes)`
  margin-top: 10px;
`;

export const StyledReadOnlyEditor = styled(ReadOnlyEditor)`
  margin-top: 40px;
  height: auto;
`;

export const DocTitle = styled.h1`
  ${typo.headerLarge}
`;

export const MorePeople = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledMockDocPanel = styled(MockDocPanel)`
  width: 680px;
  padding-bottom: 160px;
  position: relative;
  margin-bottom: 30px;
  background: ${p => p.theme.colors.onboarding.background.docPanel};

  &::after {
    content: '';
    position: absolute;
    left: -30px;
    right: -30px;
    bottom: -30px;
    height: 90%;
    z-index: 1;
    ${p => css`
      background: linear-gradient(180deg, rgba(49, 49, 49, 0) 0%, ${p.theme.colors.onboarding.background.body} 60%);
    `}
  }
`;

export const StyledSlashParagraph = styled.div`
  position: relative;
  z-index: 2;
`;

export const StyledContentText = styled.div`
  ${typo.body400};
  position: relative;

  p, ${StyledSlashParagraph} {
    ${typo.editorText}
    margin-top: 12px;
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const StyledMockSlashDropdown = styled(MockSlashDropdown)`
  position: absolute;
  top: calc(100% + 12px);
  z-index: 3;
  overflow: unset;

  &::after {
    content: '';
    position: absolute;
    left: -30px;
    right: -30px;
    bottom: -30px;
    height: 100%;
    z-index: 1;
    ${p => css`
      background: linear-gradient(180deg, rgba(49, 49, 49, 0) 0%, ${p.theme.colors.onboarding.background.body} 70%);
    `}
  }
`;

export const StyledOnboardingCollabMates = styled(OnboardingCollabMates)`
  padding-left: 0;
  padding-right: 0;
`;

export const StyledOnboardingFloatingMates = styled(OnboardingFloatingMates)`
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 3;
`;

export const Cover = styled.div`
  position: relative;
`;
