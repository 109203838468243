import { Color } from '@cycle-app/graphql-codegen';
import {
  ShyScrollbarCss, typo, Emoji, Avatar, Tooltip, truncate,
} from '@cycle-app/ui';
import { transparentize, darken } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const User = styled.div`
  position: relative;
  &:first-child {
    margin-left: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const More = styled.button`
  position: relative;
  z-index: 4;
  cursor: pointer;

  width: 26px;
  height: 26px;
  border-radius: 50%;

  background: ${p => p.theme.colors.button.secondaryV2.bgActive};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${p => p.theme.colors.text.secondary};

  span {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const MoreUsersContainer = styled.div`
  ${ShyScrollbarCss};
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 280px;
  max-height: 400px;
`;

export const MoreUsersLine = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  &:hover {
    background-color: ${p => p.theme.colors.background.tertiary};
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export const UserName = styled.div`
  ${typo.caption500};
  line-height: 17px;
  padding: 0px 4px;
`;

export const ViewingLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0px 4px;
  gap: 4px;
  border-radius: 4px;

  ${typo.caption400};
  color: ${p => p.theme.colors.text.secondary};

  > svg {
    flex: none;
  }
  
  > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  :hover, :focus {
    background: ${p => p.theme.colors.background.hover};
  }
`;

export const TooltipLink = styled(ViewingLink)`
  color: ${p => transparentize(0.2, p.theme.colors.text.white)};
  :hover {
    color: ${p => p.theme.colors.text.white};
  }

  overflow: hidden;
  > span {
    ${truncate}
  }
`;

export const EmojiStyled = styled(Emoji)`
  display: inline-block;
  margin-right: 2px;
`;

export const StyledAvatar = styled(Avatar)`
  --size: 22px;
`;

export const UserTooltip = styled(Tooltip)<{ $color?: Color }>`
  background: ${p => (p.$color ? p.theme.nuances[p.$color].main : p.theme.userColors.main)};
  ${TooltipLink}:hover {
    background: ${p => darken(0.1, p.$color ? p.theme.nuances[p.$color].main : p.theme.userColors.main)};
  }
`;
