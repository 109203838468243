import { Role } from '@cycle-app/graphql-codegen';

import {
  OnboardingStepsCollaborator,
  OnboardingStepsMaker,
  OnboardingStepsMobile,
} from 'src/constants/onboarding.constants';

export const getSteps = (isReadOnly = false, isMobile = false) => {
  if (isMobile) return OnboardingStepsMobile;
  if (isReadOnly) return OnboardingStepsCollaborator;
  return OnboardingStepsMaker;
};

export const isUserFlow = (role?: Role) => role && [Role.Collaborator, Role.User].includes(role);
