import { useSortable } from '@dnd-kit/sortable';
import { FC } from 'react';

import { getSortableStyle } from 'src/utils/dnd.util';

import SelectOptionsEditableLine, { SelectOptionsEditableLineProps } from './SelectOptionsEditableLine';

type Props = SelectOptionsEditableLineProps;

const SelectOptionsEditableLineSortable: FC<Props> = (props) => {
  const {
    option,
    sortable,
  } = props;
  const {
    setNodeRef,
    transform,
    transition,
    attributes,
    listeners,
  } = useSortable({
    id: option.value,
    disabled: !sortable,
  });

  const style = getSortableStyle({
    transform,
    transition,
  });

  return (
    <SelectOptionsEditableLine
      {...props}
      {...attributes}
      setNodeRef={setNodeRef}
      style={style}
      listeners={listeners}
    />
  );
};

export default SelectOptionsEditableLineSortable;
