import { useQuery } from '@apollo/client';
import {
  BoardConfigQueryVariables,
  BoardWithConfigDocument,
  BoardWithFullConfigFragment,
  DoctypeType,
  FilterPropertyRuleDoctypeFragment,
  MateFragment,
  Property,
  PublishedBoardConfigFullFragment,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { createContext, useContextSelector, ContextSelector, Context, useHasParentContext } from '@fluentui/react-context-selector';
import { FC, useEffect, useMemo, useRef } from 'react';

import { routing, PageId } from 'src/constants/routing.constant';
import { useRouteMatch } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { useIsCurrentBoardPrivate } from 'src/hooks/boards/useIsCurrentBoardPrivate';
import { parseParam } from 'src/hooks/useAppParams';
import { useIsAllDocsPage } from 'src/hooks/useIsAllDocsPage';
import { FetchMore } from 'src/types/apollo.types';
import { hasFilterPreventingNewDoc } from 'src/utils/boardConfig/boardConfig.util';
import { assigneeIsCompatible, docShouldHaveAnAssignee, docShouldHaveCustomer, docShouldHaveCompany } from 'src/utils/compatibility.util';
import { defaultPagination } from 'src/utils/pagination.util';

export interface BoardConfigContextValue {
  boardId: string | null;
  boardSlug: string | null;
  boardConfig: PublishedBoardConfigFullFragment | null;
  savedBoardConfig: PublishedBoardConfigFullFragment | null;
  loading: boolean;
  hasError: boolean;
  refetch: VoidFunction;
  fetchMore: FetchMore<{ node: PublishedBoardConfigFullFragment }, BoardConfigQueryVariables>;
  usersForAssignee: Array<MateFragment>;
  builtInDisplay: BuiltInDisplay;
  displayedPropertiesIds: string[];
  assigneeIsRequired: boolean;
  hasFilterPreventingNewDoc: boolean;
  groupByProperty: Property | undefined;
  isDndGroupsEnabled: boolean;
  isGroupCreationDisabled: boolean;
  customerIsRequired: boolean;
  companyIsRequired: boolean;
  insightDocType: {
    isTheOnlyOne: boolean;
    isInBoard: boolean;
  };
}

export interface BuiltInDisplay {
  assignee: boolean;
  creator: boolean;
  comments: boolean;
  createdAt: boolean;
  cover: boolean;
  parent: boolean;
  children: boolean;
  customer: boolean;
  docId: boolean;
  docType: boolean;
  source: boolean;
  status: boolean;
}

const GROUP_BY_RULES_WITH_DISABLED_GROUPS_DND: Array<Property['__typename']> = [
  'CreatorDefinition', 'AssigneeDefinition', 'DoctypeDefinition', 'StatusDefinition',
];

const GROUP_BY_RULE_WITH_GROUP_CREATION_DISABLED: Array<Property['__typename']> = [
  'CreatorDefinition', 'AssigneeDefinition', 'DoctypeDefinition', 'StatusDefinition',
];

export const BoardConfigContext = createContext<BoardConfigContextValue | undefined>(undefined) as Context<BoardConfigContextValue>;

export const BoardConfigContextProvider: FC = ({ children }) => {
  const { product } = useProduct();
  const previousBoardSlug = useRef<string | null>(null);

  const matchBoard = useRouteMatch(routing[PageId.Board]);
  const matchInboxView = useRouteMatch(routing[PageId.InboxView]);
  const boardSlug = (matchBoard ?? matchInboxView)?.params.boardSlug;
  const boardIdFromParams = parseParam(boardSlug);

  const isAllDocsPage = useIsAllDocsPage();
  const isPrivateBoard = useIsCurrentBoardPrivate();

  const boardId = isAllDocsPage
    ? product?.allDocs.id
    : boardIdFromParams;

  const {
    data,
    refetch,
    fetchMore,
    loading,
    error,
  } = useQuery<{ node: BoardWithFullConfigFragment }, BoardConfigQueryVariables>(BoardWithConfigDocument, {
    skip: !boardId,
    // Logic would want to use cache-and-network but doing so will trigger a query with default params when calling fetchMore,
    // leading to cache being rewritten and new page content not merged
    fetchPolicy: 'cache-first',
    variables: {
      id: boardId as string,
      ...defaultPagination,
    },
  });

  useEffect(() => {
    if (!boardId) return;
    if (previousBoardSlug.current !== boardSlug) {
      previousBoardSlug.current = boardSlug ?? null;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch({ id: boardId });
    }
  }, [boardId, boardSlug, refetch]);

  const savedBoardConfig = data?.node?.savedBoardConfig ?? null;
  const publishedBoardConfig = data?.node?.publishedBoardConfig ?? null;
  // TODO: find why filterProperties are not always present in boardConfig
  const boardConfig = (
    // eslint-disable-next-line no-nested-ternary
    isPrivateBoard
      ? (savedBoardConfig?.filterProperties ? savedBoardConfig : null)
      : null
  ) ?? (publishedBoardConfig?.filterProperties ? publishedBoardConfig : null);

  const usersForAssignee = useMemo(
    () => product?.users.edges.map(edge => ({
      ...edge.node,
      _compatibleWithBoardConfig: assigneeIsCompatible(edge.node.id, boardConfig),
    })) ?? [],
    [product?.users, boardConfig],
  );
  const assigneeIsRequired = useMemo(() => docShouldHaveAnAssignee(boardConfig), [boardConfig]);
  const customerIsRequired = useMemo(() => docShouldHaveCustomer(boardConfig), [boardConfig]);
  const companyIsRequired = useMemo(() => docShouldHaveCompany(boardConfig), [boardConfig]);
  const hasFilterPreventingNewDocValue = useMemo(() => !!boardConfig && hasFilterPreventingNewDoc(boardConfig), [boardConfig]);

  const displayedProperties = useMemo(
    () => nodeToArray(boardConfig?.properties).filter((p) => p.displayed),
    [boardConfig?.properties],
  );
  const displayedPropertiesIds = useMemo(() => displayedProperties.map(p => p.property.id), [displayedProperties]);
  const builtInDisplay = useMemo(() => ({
    assignee: !!displayedProperties.find(({ property }) => property.__typename === 'AssigneeDefinition'),
    creator: !!displayedProperties.find(({ property }) => property.__typename === 'CreatorDefinition'),
    createdAt: !!displayedProperties.find(({ property }) => property.__typename === 'CreatedatDefinition'),
    cover: !!displayedProperties.find(({ property }) => property.__typename === 'CoverDefinition'),
    parent: !!displayedProperties.find(({ property }) => property.__typename === 'ParentDefinition'),
    customer: !!displayedProperties.find(({ property }) => property.__typename === 'BuiltInCustomerDefinition'),
    docId: !!displayedProperties.find(({ property }) => property.__typename === 'DocidDefinition'),
    docType: !!displayedProperties.find(({ property }) => property.__typename === 'DoctypeDefinition'),
    source: !!displayedProperties.find(({ property }) => property.__typename === 'SourceDefinition'),
    comments: !!displayedProperties.find(({ property }) => property.__typename === 'CommentDefinition'),
    children: !!displayedProperties.find(({ property }) => property.__typename === 'ChildrenDefinition'),
    status: !!displayedProperties.find(({ property }) => property.__typename === 'StatusDefinition'),
  }), [displayedProperties]);

  const groupByProperty = useMemo(() => {
    if (
      boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
      boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
      return boardConfig.docQuery.groupbyConfig.property as Property;
    }
    return undefined;
  }, [boardConfig]);

  const insightDocType = useMemo(() => {
    if (!boardConfig?.filterProperties.edges.find(e => e.node.__typename === 'FilterPropertyRuleDoctype')) {
      return ({
        isTheOnlyOne: false,
        isInBoard: false,
      });
    }

    const docTypeFilterRule = boardConfig.filterProperties.edges.find(
      e => e.node.__typename === 'FilterPropertyRuleDoctype',
    )?.node as FilterPropertyRuleDoctypeFragment | null;
    const isDocTypeSelected = docTypeFilterRule?.doctypeRule.values.edges.some(
      e => e.node.selected && e.node.value.type === DoctypeType.Insight,
    ) || false;
    const isInsightOnlyDocTypeSelected = docTypeFilterRule?.doctypeRule.values.edges.every(
      e => (e.node.selected && e.node.value.type === DoctypeType.Insight) ||
        (!e.node.selected && e.node.value.type !== DoctypeType.Insight),
    ) || false;

    return {
      isTheOnlyOne: isInsightOnlyDocTypeSelected,
      isInBoard: isDocTypeSelected,
    };
  }, [boardConfig]);

  const value = useMemo(() => ({
    assigneeIsRequired,
    boardConfig,
    boardId: boardId ?? null,
    boardSlug: boardSlug ?? null,
    builtInDisplay,
    companyIsRequired,
    customerIsRequired,
    displayedPropertiesIds,
    fetchMore,
    groupByProperty,
    hasError: !!error,
    hasFilterPreventingNewDoc: hasFilterPreventingNewDocValue,
    insightDocType,
    isDndGroupsEnabled: !GROUP_BY_RULES_WITH_DISABLED_GROUPS_DND.includes(groupByProperty?.__typename),
    isGroupCreationDisabled: GROUP_BY_RULE_WITH_GROUP_CREATION_DISABLED.includes(groupByProperty?.__typename),
    loading,
    refetch,
    savedBoardConfig,
    usersForAssignee,
  }), [
    assigneeIsRequired,
    boardConfig,
    boardId,
    boardSlug,
    builtInDisplay,
    companyIsRequired,
    customerIsRequired,
    displayedPropertiesIds,
    error,
    fetchMore,
    groupByProperty,
    hasFilterPreventingNewDocValue,
    insightDocType,
    loading,
    refetch,
    savedBoardConfig,
    usersForAssignee,
  ]);

  return (
    <BoardConfigContext.Provider value={value}>
      {children}
    </BoardConfigContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const useBoardConfig = <T,>(selector: ContextSelector<BoardConfigContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(BoardConfigContext);
  if (!isWrappedWithContext) throw new Error('useBoardConfig must be used within a board');
  return useContextSelector(BoardConfigContext, selector);
};
