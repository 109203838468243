import { ApolloError } from '@apollo/client';
import { IntegrationFullFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { normalizeString } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { CUSTOMERS_INTEGRATIONS } from 'src/constants/integrations.constants';
import { useCreateIntegration } from 'src/hooks/api/mutations/integrations/useCreateIntegration';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useErrorToaster } from 'src/hooks/useErrorToaster';
import { useToaster } from 'src/hooks/useToaster';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';
import { integrationNameTitles } from 'src/utils/integrations.utils';

import { setIntegrationSync, useGetIntegrationSync } from '../reactives/integrationSync.reactive';
import { useProductBase } from './api/useProduct';

type InstallStatus = 'success' | 'error';

type IntegrationCallback = (status: InstallStatus, error?: ApolloError | Error) => void;

declare global {

  interface Window {
    onIntegrationEnd: Record<string, IntegrationCallback>;
  }
}

const POPUP_WIDTH = 600;
const POPUP_HEIGHT = 600;

const popupParams = () => Object.entries({
  scrollbars: 'yes',
  resizable: 'yes',
  status: 'no',
  location: 'no',
  toolbar: 'no',
  menubar: 'no',
  left: window.screenLeft + window.outerWidth / 2 - POPUP_WIDTH / 2,
  top: window.screenTop + window.outerHeight / 2 - POPUP_HEIGHT / 2,
  width: POPUP_WIDTH,
  height: POPUP_HEIGHT,
}).map(entry => entry.join('=')).join(',');

export const useInstallIntegration = (): (integration?: IntegrationFullFragment) => Promise<void> => {
  const { add: addToaster } = useToaster();
  const { add: addErrorToaster } = useErrorToaster();
  const { refetch } = useProductIntegrations();
  const { createIntegration } = useCreateIntegration();
  const product = useProductBase();
  const productId = product?.id;

  return useCallback(async (integration) => {
    const type = integration?.type;

    if (!type || !productId) return;

    if (type === IntegrationType.Mail) {
      await createIntegration({
        productId,
        type: IntegrationType.Mail,
      });
      refetch();
      return;
    }

    if (!integration?.url) return;

    const title = integrationNameTitles[type];
    const provider = normalizeString(type);

    const popup = window.open(integration.url, '_blank', popupParams());

    const callback: IntegrationCallback = (status, error) => {
      delete window.onIntegrationEnd?.[provider];
      popup?.close();
      switch (status) {
        case 'success':
          refetch();
          setIntegrationSync({ [type]: { isSyncing: true } });
          // right now the slack installation is the only one we cannot track from the backend
          if (type === 'SLACK') {
            trackAnalytics(Events.IntegrationInstalled, {
              type,
              productId,
            });
          }
          if (CUSTOMERS_INTEGRATIONS.includes(type)) {
            addToaster({
              id: `${type}-sync`,
              title: <SyncTitle type={type} />,
              icon: <SyncIcon type={type} />,
              duration: 'infinite',
            });
          }
          addToaster({
            title: 'Successfully installed',
            message: `🙌 Your ${title} integration is ready`,
          });
          break;
        case 'error':
          addErrorToaster({
            message: `🤭 Oops, something occur during the installation of ${title}, we’re looking into it, retry later`,
          });
          if (error) logError(error);
          break;
        default:
          break;
      }
    };

    window.onIntegrationEnd = {
      ...window.onIntegrationEnd,
      [provider]: callback,
    };
  }, [addToaster, addErrorToaster, createIntegration, productId, refetch]);
};

const SyncTitle = ({ type }: { type: IntegrationType }) => {
  const title = integrationNameTitles[type];
  const integrations = useGetIntegrationSync();
  return integrations[type]?.isSyncing
    ? <>{`${title} customer sync in progress`}</>
    : <>{`${title} customer sync done`}</>;
};

const SyncIcon = ({ type }: { type: IntegrationType }) => {
  const integrations = useGetIntegrationSync();
  return integrations[type]?.isSyncing
    ? <Spinner style={{ opacity: 0.5 }} />
    : <CheckIcon />;
};
