import {
  Role,
  ChangeUserProductRoleDocument,
  ProductMembersDocument,
} from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import { getProductMembersQueryVariables } from 'src/hooks/api/useUsers';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useUpdateQuery } from 'src/utils/update-cache/update-query.util';

import { useCurrentBilling } from '../..';

export const useChangeUserProductRole = () => {
  const { product } = useProduct();
  const currentBilling = useCurrentBilling();

  const updateProductMembersQuery = useUpdateQuery({
    query: ProductMembersDocument,
    variables: getProductMembersQueryVariables(product?.id as string),
  });

  const [mutate, result] = useSafeMutation(ChangeUserProductRoleDocument);

  const changeUserProductRole = (role: Role, userId: string, currentRole: Role) => {
    if (!product || role === currentRole) return null;
    return mutate({
      variables: {
        productId: product.id,
        userId,
        role,
      },
      optimisticResponse: {
        changeUserProductRole: {
          id: userId,
        },
      },
      update: (cache) => {
        updateProductMembersQuery(draft => {
          if (draft.node?.__typename !== 'Product') return;
          const user =
            draft.node.notSignedUpUsers.edges.find(({ node }) => node.id === userId) ??
            draft.node.users.edges.find(({ node }) => node.id === userId);
          if (user) {
            user.node.productRole = role;
            if (currentBilling) {
              const billing = cache.identify(currentBilling);
              if (billing) {
                cache.modify({
                  id: billing,
                  fields: {
                    nbMakers: (currentMakers) => {
                      if (role === Role.Maker) {
                        return currentMakers + 1;
                      }
                      if (currentRole === Role.Maker) {
                        return currentMakers - 1;
                      }
                      return currentMakers;
                    },
                  },
                });
              }
            }
          }
        });
      },
    });
  };

  return {
    ...result,
    changeUserProductRole,
  };
};
