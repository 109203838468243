import { FC, ReactNode } from 'react';

import { useInboxSection, useCurrentInboxSlug } from 'src/hooks/inbox';
import { useNavigate } from 'src/hooks/useNavigate';
import { getBoardSlug } from 'src/utils/slug.util';

import { InboxTab } from './InboxTab';
import { Tabs, TabList } from './InboxTabs.styles';

export type InboxTabsProps = {
  lastTabNode?: ReactNode;
  endNode?: ReactNode;
};

export const InboxTabs = ({
  lastTabNode, endNode,
}: InboxTabsProps) => {
  const { customBoards } = useInboxSection();
  return (
    <TabsContainer>
      <TabList>
        <MyInboxTab />
        <AllBoard />
        {customBoards.map(board => <InboxTab key={board.id} board={board} />)}
        {lastTabNode}
      </TabList>
      {endNode}
    </TabsContainer>
  );
};

const TabsContainer: FC = ({ children }) => {
  const { navigateToInbox } = useNavigate();
  const currentSlug = useCurrentInboxSlug();
  const { boards } = useInboxSection();
  return (
    <Tabs
      index={boards.findIndex(board => currentSlug?.includes(board.id))}
      onChange={index => navigateToInbox(getBoardSlug(boards[index]))}
    >
      {children}
    </Tabs>
  );
};

const MyInboxTab = () => {
  const { myInboxBoard } = useInboxSection();
  if (!myInboxBoard) return null;
  return (
    <InboxTab
      board={myInboxBoard}
      tooltip={{ placement: 'top-start' }}
      hideIcon
    />
  );
};

const AllBoard = () => {
  const { allBoard } = useInboxSection();
  if (!allBoard) return null;
  return (
    <InboxTab
      board={allBoard}
      emoji="mailbox_with_mail"
    />
  );
};
