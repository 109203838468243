import { UnrevealedProvider } from '@unrevealed/react';
import { FC } from 'react';

export const FeatureFlagProvider: FC = ({ children }) => {
  return (
    <UnrevealedProvider clientKey={process.env.UNREVEALED_API_KEY as string}>
      {children}
    </UnrevealedProvider>
  );
};
