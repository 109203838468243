import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const UnderlineIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.8125 2.75C4.8125 2.33579 4.47671 2 4.0625 2C3.64829 2 3.3125 2.33579 3.3125 2.75V6.6875C3.3125 7.9307 3.80636 9.12299 4.68544 10.0021C5.56451 10.8811 6.7568 11.375 8 11.375C9.2432 11.375 10.4355 10.8811 11.3146 10.0021C12.1936 9.12299 12.6875 7.9307 12.6875 6.6875V2.75C12.6875 2.33579 12.3517 2 11.9375 2C11.5233 2 11.1875 2.33579 11.1875 2.75V6.6875C11.1875 7.53288 10.8517 8.34363 10.2539 8.9414C9.65613 9.53918 8.84538 9.875 8 9.875C7.15462 9.875 6.34387 9.53918 5.7461 8.9414C5.14832 8.34363 4.8125 7.53288 4.8125 6.6875V2.75ZM2.75 12.5C2.33579 12.5 2 12.8358 2 13.25C2 13.6642 2.33579 14 2.75 14H13.25C13.6642 14 14 13.6642 14 13.25C14 12.8358 13.6642 12.5 13.25 12.5H2.75Z" fill="#171618" />
  </Svg>
);
