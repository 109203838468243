import { DocFullFragment } from '@cycle-app/graphql-codegen/generated';
import { useCallback } from 'react';

import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { useMoreDocsInGroup } from 'src/hooks/api/useMoreDocsInGroup';
import { useLocation } from 'src/hooks/router/useLocation';
import { useNavigate } from 'src/hooks/useNavigate';

type HookResult = {
  navigateToPrevDoc: VoidFunction;
  navigateToNextDoc: VoidFunction;
  isFirstDoc: boolean;
  isLastDoc: boolean;
  loadingNextDoc: boolean;
};

export const usePrevNextDoc = (doc: DocFullFragment | null): HookResult => {
  const { state } = useLocation();
  const groupId = state?.groupId;

  const boardGroups = useBoardGroups();
  const withGroupBy = boardGroups?.withGroupBy;
  const group = groupId ? boardGroups.groups?.[groupId] : null;
  const hasNextPage = group?.pageInfo?.hasNextPage;
  const endCursor = group?.pageInfo?.endCursor;
  const docsList = Object.values(group?.docs ?? {});
  const docIndex = docsList.findIndex(item => item.id === doc?.id);
  const prevDoc = docIndex === 0 ? undefined : docsList[docIndex - 1];
  const nextDoc = docIndex === docsList.length - 1 ? undefined : docsList[docIndex + 1];
  const isFirstDoc = !prevDoc;
  const isLastDoc = !nextDoc;

  const moreDocsInGroup = useMoreDocsInGroup(groupId ?? '');
  const loadingNextDoc = !!group?.more?.loading || moreDocsInGroup.loading;

  const loadMore: VoidFunction = useCallback(async () => {
    if (withGroupBy) {
      await moreDocsInGroup.moreDocs(endCursor ?? '');
    } else {
      await group?.more?.exec?.();
    }
  }, [endCursor, group?.more, moreDocsInGroup, withGroupBy]);

  const { navigateToDocPanelPage } = useNavigate();

  const navigateToPrevDoc = useCallback(() => {
    if (!isFirstDoc) navigateToDocPanelPage(prevDoc, { groupId });
  }, [isFirstDoc, groupId, navigateToDocPanelPage, prevDoc]);

  const navigateToNextDoc = useCallback(() => {
    if (hasNextPage && docIndex >= 0 && docIndex >= docsList.length - 2) loadMore();
    if (!isLastDoc) navigateToDocPanelPage(nextDoc, { groupId });
  }, [isLastDoc, docIndex, docsList.length, groupId, hasNextPage, loadMore, navigateToDocPanelPage, nextDoc]);

  return {
    navigateToPrevDoc,
    navigateToNextDoc,
    isFirstDoc,
    isLastDoc,
    loadingNextDoc,
  };
};
