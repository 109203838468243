import { IntegrationType } from '@cycle-app/graphql-codegen';
import { SelectLineProps } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine';
import { useListNav, isChromeExtension } from '@cycle-app/utilities';
import { NodeViewRendererProps, Range } from '@tiptap/react';
import { FC, useState } from 'react';

import { InstallIntegrationButton } from 'src/components/Integrations/InstallIntegrationButton/InstallIntegrationButton';
import { useEditorContext } from 'src/contexts/editorContext';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { slashActions, actions as allActions, Action, ActionId } from 'src/services/editor/editorActions';

import EmbedForm from './EmbedForm/EmbedForm';
import FileForm from './FileForm/FileForm';
import {
  Container,
  Category,
  CategoryTitle,
  Actions,
  StyledSelectLine,
} from './SlashDropdown.styles';

interface Props extends NodeViewRendererProps {
  range: Range;
  command: () => void;
  items: Array<string>;
  hide: () => void;
}

type Step = 'default' | 'embed' | 'image' | 'file';

const SlashDropdown: FC<Props> = ({
  range, command, items,
}) => {
  const [step, setStep] = useState<Step>('default');
  const {
    editor,
    disabledActions,
  } = useEditorContext();

  const actionsToShow = slashActions
    .map(section => ({
      ...section,
      actions: filterActions(section.actions),
    }))
    .filter(section => section.actions.length);

  const optionsValues = actionsToShow
    .map(({ actions }) => filterActions(actions).map(action => action.id)).flat();

  const {
    selected,
    listProps,
    itemProps,
    hoverDisabled,
  } = useListNav({
    optionsValues,
    onSelect: onActionSelected,
    value: null,
    autoFocus: true,
  });

  if (!items.length) return null;

  return (
    <Container {...listProps} large={step !== 'default' && !isChromeExtension}>
      {renderContent()}
    </Container>
  );

  function renderContent() {
    if (step === 'embed') {
      return (
        <EmbedForm
          range={range}
          onCancel={backToList}
        />
      );
    }

    if (step === 'image') {
      return (
        <FileForm
          range={range}
          onCancel={backToList}
          type="image"
          submitLabel="Add image"
          title={isChromeExtension ? undefined : 'Add image'}
        />
      );
    }

    if (step === 'file') {
      return (
        <FileForm
          range={range}
          onCancel={backToList}
          type="file"
          submitLabel="Add file"
        />
      );
    }

    return renderActions();
  }

  function renderActions() {
    return (
      <>
        {actionsToShow
          .map(({
            category, actions,
          }) => (
            <Category key={category}>
              <CategoryTitle>{category}</CategoryTitle>
              <Actions>
                {actions
                  .filter(action => (items.includes(action.id)))
                  .map(action => {
                    const lineProps = {
                      label: action.label,
                      startSlot: action.icon,
                      isSelected: selected === action.id,
                      hoverDisabled,
                      ...itemProps(action.id),
                    };

                    if (action.id === ActionId.GithubIssue) {
                      return (
                        <GitHubSelectLine
                          key={action.label}
                          {...lineProps}
                        />
                      );
                    }

                    if (action.id === ActionId.Linear) {
                      return (
                        <LinearSelectLine
                          key={action.label}
                          {...lineProps}
                        />
                      );
                    }

                    return (
                      <StyledSelectLine
                        key={action.label}
                        {...lineProps}
                      />
                    );
                  })}
              </Actions>
            </Category>
          ))}
      </>
    );
  }

  function filterActions(actions: Array<Action>) {
    return actions.filter(action => {
      const shouldShowIncludeAction =
        !disabledActions?.includes(action.id) &&
        items.includes(action.id);

      return shouldShowIncludeAction;
    });
  }

  function onActionSelected(actionId: string | null) {
    if (!actionId || !allActions[actionId as ActionId]) return;

    if (actionId === ActionId.Embed) {
      setStep('embed');
      return;
    }
    if (actionId === ActionId.Image) {
      setStep('image');
      return;
    }
    if (actionId === ActionId.File) {
      setStep('file');
      return;
    }

    closeDropdown();
    allActions[actionId as ActionId].toggle?.(editor);
  }

  function backToList() {
    setStep('default');
  }

  function closeDropdown() {
    command();
  }
};

export default SlashDropdown;

const GitHubSelectLine = (props: SelectLineProps) => {
  const { getIntegration } = useProductIntegrations();
  const isDisabled = !getIntegration(IntegrationType.Github)?.provider;
  return (
    <StyledSelectLine
      {...props}
      {...isDisabled && {
        endSlot: <InstallIntegrationButton />,
        onClick: undefined,
      }}
    />
  );
};

const LinearSelectLine = (props: SelectLineProps) => {
  const { getIntegration } = useProductIntegrations();
  const isDisabled = !getIntegration(IntegrationType.Linear)?.provider;
  return (
    <StyledSelectLine
      {...props}
      {...isDisabled && {
        endSlot: <InstallIntegrationButton />,
        onClick: undefined,
      }}
    />
  );
};
