import { Button } from '@cycle-app/ui';
import { TrashIcon } from '@cycle-app/ui/icons';
import { ReactNode, FC } from 'react';

import { DeletableItem, DeleteItemName } from './BillingDowngrade.styles';

interface Props {
  onDelete?: VoidFunction;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltip?: ReactNode;
}

export const BillingDowngradeDeletableItem: FC<Props> = ({
  children, onDelete, isLoading, isDisabled, tooltip,
}) => (
  <DeletableItem>
    <DeleteItemName>
      {children}
    </DeleteItemName>
    <Button
      disabled={isDisabled}
      variant="nospace"
      isLoading={isLoading}
      onClick={onDelete}
      tooltip={tooltip}
      tooltipPlacement="top"
    >
      <TrashIcon />
    </Button>
  </DeletableItem>
);
