import { PageId } from 'src/constants/routing.constant';
import { useProducts } from 'src/hooks/api/useProducts';
import { useNavigate } from 'src/hooks/useNavigate';
import { setCommandbar } from 'src/reactives/commandbar.reactive';

export const useWorkspacesSearch = (search: string, enabled: boolean) => {
  const { navigate } = useNavigate();
  const {
    products, loading, hasNextPage, fetchNextPage,
  } = useProducts({
    searchText: search,
    skip: !enabled,
  });

  const result = [{
    id: 'workspaces',
    actions: products.map(product => ({
      id: product.id,
      label: product.name,
      onSelect: () => {
        navigate(PageId.Main, {
          productSlug: product.slug,
        });
        setCommandbar({ visible: false });
      },
    })),
  }];

  return {
    result,
    loading,
    hasNextPage,
    fetchNextPage,
  };
};
