import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import IframelyView from 'src/components/Editor/NodeViews/Iframely/IframelyView';

interface IFramelyAttributes {
  html?: string;
  url: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframely: {
      setIframely: (options: IFramelyAttributes) => ReturnType;
    };
  }
}

export type IframelyOptions = {
  HTMLAttributes: Record<string, unknown>;
};

export const extensionName = 'iframely';

export default Node.create({
  name: extensionName,

  addOptions: () => ({
    HTMLAttributes: {
      class: 'iframely',
    },
  }),

  group: 'block',

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      url: { default: null },
      html: { default: null },
    };
  },

  parseHTML() {
    return [
      { tag: 'react-component' },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'react-component',
      mergeAttributes(HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframelyView);
  },

  addCommands() {
    return {
      setIframely: (options: IFramelyAttributes) => ({ commands }) => commands.insertContent({
        type: this.name,
        attrs: options,
      }),
    };
  },
});
