import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';

export const Container = styled.div<{
  asPlaceholder?: boolean;
  $collapsed?: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  opacity: ${p => (p.asPlaceholder ? 0 : 1)};
  row-gap: ${p => (p.$collapsed ? '4px' : 0)};
`;

export const DroppableArea = styled.div<{ isOver?: boolean }>`
  border-radius: 4px;
  background-color: ${p => (p.isOver ? p.theme.colors.sidebar.droppableArea.bg : 'transparent')};
`;

export const BoardSectionNavigationItem = styled(CollapsableNavigationItem)<{ $isOpen: boolean }>`
  padding: 0;
  ${typo.body400};
  font-size: 13px;
  line-height: 16px;
  padding-left: 14px;
  margin-bottom: ${p => (p.$isOpen ? '4px' : 0)};

  &:hover, &.force-focus {
    background: none;

    > span {
      color: ${p => p.theme.colors.sidebar.color};
    }

    button {
      background-color: ${p => p.theme.colors.sidebar.boardsSectionItem.bg};
      color: ${p => p.theme.colors.sidebar.boardsSectionItem.color};

      &:hover, &.force-focus {
        background-color: ${p => p.theme.colors.sidebar.boardsSectionItem.bgHover};
        color: ${p => p.theme.colors.sidebar.boardsSectionItem.colorHover};
      }
    }
  }
`;

export const BoardNavigationItem = styled(CollapsableNavigationItem)`
  --color: ${p => p.theme.colors.sidebar.boardItem.color};
`;
