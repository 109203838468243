import { MateWithProductRoleFragment, Role } from '@cycle-app/graphql-codegen/generated';
import { SelectOption } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import { useState, useReducer } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useIsStandardPlan } from 'src/hooks';
import { useChangeUserProductRole } from 'src/hooks/api/mutations/useChangeUserProductRole';
import { useGetBillingPermission, useGetPermission } from 'src/reactives/permission.reactive';
import { Layer } from 'src/types/layers.types';

import {
  UserBlock, SelectButton, Caret, StyledSelectPanel, RoleLabel, BillingInfoMessage, Rows,
} from './SettingsUserModal.styles';
import { UserAvatar, FullName, Email } from './SettingsUsers.styles';

type Props = {
  user: MateWithProductRoleFragment;
  onClose: () => void;
};

export const SettingsUserChangeProductRoleModal = ({
  user, onClose,
}: Props) => {
  const [isDropdownOpen, toggleDropdown] = useReducer(b => !b, false);
  const [selectedRole, setSelectedRole] = useState(user.productRole);
  const options = useProductRoleOptions(selectedRole);
  const { canCurrentPlanUpdateMaker } = useGetBillingPermission();
  const {
    changeUserProductRole, loading,
  } = useChangeUserProductRole();
  const isStandardPlan = useIsStandardPlan();

  return (
    <DialogModal
      layer={Layer.Modal}
      confirmLabel="Update"
      variantSubmitButton="primary"
      variantCancelButton="secondary"
      isConfirmDisabled={!selectedRole || user.productRole === selectedRole}
      hide={onClose}
      loading={loading}
      onConfirm={async () => {
        if (!selectedRole || !user.productRole) return;
        await changeUserProductRole(selectedRole, user.id, user.productRole);
        onClose();
      }}
      title="Edit role"
      type="default"
      info={(
        <Rows>
          <UserBlock>
            <UserAvatar user={user} />
            <div>
              <FullName>{`${user.firstName} ${user.lastName}`}</FullName>
              <Email>{user.email}</Email>
            </div>
          </UserBlock>

          <DropdownLayer
            layer={Layer.DropdownModal}
            visible={isDropdownOpen}
            hide={toggleDropdown}
            placement="bottom-start"
            content={(
              <StyledSelectPanel
                selectedValue={selectedRole ?? undefined}
                options={options}
                onOptionChange={option => {
                  setSelectedRole(option.value as Role);
                  toggleDropdown();
                }}
                hideSearch
              />
          )}
          >
            <SelectButton
              onClick={toggleDropdown}
              iconEnd={<Caret />}
              disabled={selectedRole === Role.Maker && !canCurrentPlanUpdateMaker}
            >
              <RoleLabel>{selectedRole}</RoleLabel>
            </SelectButton>
          </DropdownLayer>
          {isStandardPlan && (
            <BillingInfoMessage>
              <InfoIconOutline />
              The billing will automatically update if you add a maker.
            </BillingInfoMessage>
          )}
        </Rows>
      )}
    />
  );
};

const useProductRoleOptions = (selectedRole: MateWithProductRoleFragment['productRole']): SelectOption[] => {
  const permission = useGetPermission();
  const roles = [];
  // @NB we don't want to have an admin role (for now)
  // if (permission.canUpdateUserRoleAdmin) roles.push(Role.Admin);
  if (permission.canUpdateUserRoleMaker) roles.push(Role.Maker);
  if (permission.canUpdateUserRoleCollaborator) roles.push(Role.Collaborator);

  return roles
    .filter(role => role !== selectedRole)
    .map(role => ({
      value: role,
      label: role,
    }));
};
