import { useMemo } from 'react';
import { isNode } from 'react-flow-renderer';

import FlowDoctypes from 'src/components/FlowDoctypes/FlowDoctypes';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { useGlobalHierarchyElements } from 'src/hooks/hierarchy/useGlobalHierarchy';
import { useFeedbackHierarchyElements } from 'src/hooks/hierarchy/useHierarchy';
import { getLayoutedElements, getLayoutedElementsDoctype } from 'src/utils/flow.util';

export const SettingsHierarchy = () => {
  const globalElements = useGlobalHierarchyElements();
  const feedbackElements = useFeedbackHierarchyElements();

  const layoutedElements = useMemo(() => {
    const globalCount = globalElements.filter(e => isNode(e) && !e.data.phantom).length;
    return [
      ...globalCount > 1 ? getLayoutedElements(globalElements, { marginx: 700 }) : [],
      ...getLayoutedElementsDoctype(feedbackElements),
    ];
  }, [feedbackElements, globalElements]);

  return (
    <>
      <SettingsViewHeader title="Hierarchy" />
      <FlowDoctypes elements={layoutedElements} />
    </>
  );
};
