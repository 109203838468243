import { EdgeProps } from 'react-flow-renderer';

import { AnimatedEdge } from './AnimatedEdge';

export const SourceEdge = (props: EdgeProps) => (
  <AnimatedEdge
    {...props}
    frontOptions={() => {
      const r = Math.random() * 0.7;
      return {
        ease: 'linear',
        duration: 2 - r,
        delay: 1 + r,
      };
    }}
    backOptions={() => ({
      ease: 'linear',
      duration: 2,
      delay: 5,
    })}
  />
);
