import { motion } from 'framer-motion';

import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { SubTitle, Title } from '../OnboardingLayout/OnboardingLayout.styles';
import { Content, StyledButton, StyledLogo, Body, LogoRotate } from './OnboardingStepWelcome.styles';

const LIFT_DURATION = 1.7;
const LIFT_DELAY = 1;

export const OnboardingStepWelcome = () => {
  return (
    <OnboardingLayout
      isAnimated
      hideLogo
      main={(
        <Content
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.div
            initial={{
              translateY: 70,
            }}
            animate={{
              translateY: 0,
              transition: {
                delay: LIFT_DELAY,
                duration: LIFT_DURATION,
              },
            }}
          >
            <LogoRotate
              initial={{
                rotate: '-190deg',
              }}
              animate={{
                rotate: '-1deg',
              }}
              transition={{
                duration: LIFT_DELAY,
                ease: [0.075, 0.82, 0.165, 1],
              }}
            >
              <StyledLogo size={100} />
            </LogoRotate>
          </motion.div>
          <Body
            initial={{
              translateY: 40,
              opacity: 0,
              marginBottom: 40,
            }}
            animate={{
              translateY: 0,
              opacity: 1,
              transition: {
                delay: LIFT_DELAY,
                duration: LIFT_DURATION,
              },
              marginBottom: 0,
            }}
          >
            <Title>Welcome to cycle</Title>
            <SubTitle>Where product folks make things happen</SubTitle>
            <StyledButton
              onClick={() => setOnboarding({ screen: LightOnboardingScreen.AccountVerify })}
              size="M"
            >
              Let’s start
            </StyledButton>
          </Body>
        </Content>
      )}
    />
  );
};
