import { DocChildFragment, CustomerFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption, Tooltip, TextHighlighter } from '@cycle-app/ui';
import { CustomerIconOutline, AddIcon, OpenIcon } from '@cycle-app/ui/icons';
import { isEmail } from '@cycle-app/utilities';
import {
  useMemo, FC, useCallback, MouseEvent, useState, ReactChild,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useCustomerDocFromCache } from 'src/hooks/api/cache/cacheCustomerDoc';
import { useUpdateDocCustomer } from 'src/hooks/api/mutations/useUpdateDocCustomer';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { useCustomerFromBoardConfig } from 'src/hooks/api/useCustomerFromBoardConfig';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { getCustomerOption, isDefaultCustomer, renderCustomerOptionLabel } from 'src/utils/customers.util';

import { CustomersAddCustomerModal } from '../CustomersList/CustomersAddCustomerModal';
import DropdownLayer from '../DropdownLayer/DropdownLayer';
import { FloatingPenButton } from '../FloatingPenButton/FloatingPenButton';
import {
  EditButtonContainer, StyledActionButton,
  StyledCustomerAvatar, TooltipCustomerContent, TooltipCustomerContentUpdateLabel,
  CreateCustomerLine, OpenCustomerButton, StyledCustomerCompanyAction, ActionButtonCursor,
} from './DocCustomer.styles';

type DocCustomerProps = {
  doc: DocChildFragment;
  isCompact?: boolean;
  isDisabled?: boolean;
  onCompanyModalOpen?: (companyId: string) => void;
  onCustomerModalOpen?: (customerId: string) => void;
  onSelect?: (isNotCompatible: boolean) => void;
  placeholderIcon?: ReactChild;
  isRemovable?: boolean;
  showWarnings?: boolean;
};

export const DocCustomer: FC<DocCustomerProps> = ({
  doc,
  isCompact,
  isDisabled = false,
  onCompanyModalOpen,
  onCustomerModalOpen,
  onSelect,
  placeholderIcon = <CustomerIconOutline />,
  isRemovable = true,
  showWarnings,
}) => {
  if (!doc.doctype.customer?.id) return null;

  if (isDisabled) {
    return doc.customer?.id
      ? (
        <StyledActionButton
          size="L"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            if (!doc?.customer?.id || !onCustomerModalOpen) return;
            e.stopPropagation();
            e.preventDefault();
            onCustomerModalOpen(doc.customer.id);
          }}
          $isSelected={!isCompact}
        >
          <CustomerAvatar
            customer={doc.customer}
            isCompact={isCompact}
            onCompanyModalOpen={onCompanyModalOpen}
            isDisabled
          />
          {!isCompact && doc.customer.displayName}
        </StyledActionButton>
      )
      : (
        <ActionButtonCursor $isCompact={isCompact}>
          <StyledActionButton disabled size="L">
            <AvatarPlaceholder isCompact={isCompact} isDisabled icon={placeholderIcon} />
          </StyledActionButton>
        </ActionButtonCursor>
      );
  }

  return (
    <EditCustomer
      onCustomerModalOpen={onCustomerModalOpen}
      onSelect={onSelect}
      doc={doc}
      isCompact={isCompact}
      isRemovable={isRemovable}
      showWarnings={showWarnings}
    >
      {doc.customer?.id
        ? (
          <>
            <CustomerAvatar
              customer={doc.customer}
              isCompact={isCompact}
              onCompanyModalOpen={onCompanyModalOpen}
              isDisabled={isDisabled}
            />
            {!isCompact && doc.customer.displayName}
          </>
        )
        : <AvatarPlaceholder isCompact={isCompact} icon={placeholderIcon} />}
    </EditCustomer>
  );
};

type EditCustomerProps = {
  doc: DocCustomerProps['doc'];
  isCompact: DocCustomerProps['isCompact'];
  onCustomerModalOpen: DocCustomerProps['onCustomerModalOpen'];
  onSelect: DocCustomerProps['onSelect'];
  isRemovable?: boolean;
  showWarnings?: boolean;
};

const EditCustomer: FC<EditCustomerProps> = ({
  children,
  doc,
  isCompact,
  onCustomerModalOpen,
  onSelect,
  isRemovable = true,
  showWarnings,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideSelectDropdown,
    setTrueCallback: showSelectDropdown,
  }] = useOptimizedBooleanState(false);
  const [isAddCustomerVisible, { toggleCallback: toggleAddCustomer }] = useOptimizedBooleanState(false);
  const [searchText, setSearchText] = useState('');
  const {
    customers,
    searchCustomers,
  } = useCustomers();
  const customerDocCache = useCustomerDocFromCache();
  const {
    isCustomerCompatibleWithBoardConfig,
    customerIsRequired,
    companyIsRequired,
  } = useCustomerFromBoardConfig();
  const customerOptions: SelectOption[] = useMemo(() => customers.map(customer => ({
    ...customer,
    _compatibleWithBoardConfig: doc.isDraft || isCustomerCompatibleWithBoardConfig(customer),
  })).map(customer => ({
    ...getCustomerOption(customer, showWarnings),
    ...onCustomerModalOpen && {
      renderLabel: (filterText) => (
        renderCustomerOptionLabel({
          customer,
          filterText,
          lineHover: (
            <OpenCustomerButton
              size="S"
              onClick={e => {
                e.stopPropagation();
                onCustomerModalOpen(customer.id);
              }}
            >
              <OpenIcon />
              Open
            </OpenCustomerButton>
          ),
        })
      ),
    },
  })), [customers, doc.isDraft, isCustomerCompatibleWithBoardConfig, onCustomerModalOpen]);

  const searchCustomersDebounced = useDebouncedCallback((search: string) => searchCustomers(search), 300);

  const { updateDocCustomer } = useUpdateDocCustomer();

  const handleCloseSelectDropdown = async () => {
    hideSelectDropdown();
    setSearchText('');
    await searchCustomers('');
  };

  const onButtonClicked = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (doc.customer?.id && !isCompact && onCustomerModalOpen) {
      onCustomerModalOpen?.(doc.customer.id);
      return;
    }
    showSelectDropdown();
  }, [showSelectDropdown, doc, isCompact, onCustomerModalOpen]);

  return (
    <>
      <DropdownLayer
        layer={Layer.DropdownModal}
        visible={isDropdownVisible}
        hide={handleCloseSelectDropdown}
        placement="bottom-start"
        width={490}
        content={(
          customerOptions && (
            <SelectPanel
              filterOptionsOnInputChange={false}
              options={customerOptions}
              onSearchChange={async (search) => {
                setSearchText(search);
                await searchCustomersDebounced(search);
              }}
              onOptionChange={handleOnOptionChange}
              onClearValue={isRemovable ? handleRemoveCustomer : undefined}
              warningOnNoneValue={!doc.isDraft && (customerIsRequired || companyIsRequired)}
            >
              <CreateCustomerLine
                startSlot={<AddIcon />}
                label={(
                  <>
                    Create
                    {' '}
                    {searchText ? (
                      <TextHighlighter
                        searchWords={[searchText]}
                        textToHighlight={searchText}
                        className="highlight"
                      />
                    ) : 'new customer'}
                  </>
                )}
                onClick={toggleAddCustomer}
              />
            </SelectPanel>
          )
        )}
      >
        <EditButtonContainer $isCompact={isCompact}>
          <StyledActionButton
            size="L"
            forceFocus={isDropdownVisible}
            onClick={onButtonClicked}
            $isSelected={!!doc.customer?.id}
          >
            {children}
          </StyledActionButton>
          {!isCompact && doc.customer?.id && !doc.isDraft && (
            <FloatingPenButton tooltip="Edit customer" tooltipPlacement="top" onClick={showSelectDropdown} />
          )}
        </EditButtonContainer>
      </DropdownLayer>
      {isAddCustomerVisible && (
        <CustomersAddCustomerModal
          defaultValues={{
            name: isEmail(searchText, false) ? '' : searchText,
            email: isEmail(searchText, false) ? searchText : '',
          }}
          layer={Layer.ModalZ2}
          onClose={toggleAddCustomer}
          onCreated={async data => {
            await handleCloseSelectDropdown();
            await handleOnOptionChange({
              value: data.id,
              label: '',
            });
          }}
        />
      )}
    </>
  );

  async function handleOnOptionChange({ value }: SelectOption) {
    const isNotCompatible = !isCustomerCompatibleWithBoardConfig(customers.find(({ id }) => value === id));
    onSelect?.(isNotCompatible);
    customerDocCache.toggleCustomerDoc({
      customer: doc.customer,
      newCustomer: customers.find(c => value === c.id),
      doc,
      doctypeId: doc.doctype.id,
    });
    await updateDocCustomer({
      docId: doc.id,
      customerId: value,
    }, isNotCompatible);
    await handleCloseSelectDropdown();
  }

  async function handleRemoveCustomer() {
    if (doc.customer?.id) {
      customerDocCache.removeCustomerDoc({ doc });
    }
    await updateDocCustomer({
      docId: doc.id,
    }, customerIsRequired || companyIsRequired);
    await handleCloseSelectDropdown();
  }
};

type CustomerAvatarProps = {
  customer: CustomerFragment;
  isCompact: DocCustomerProps['isCompact'];
  isDisabled?: DocCustomerProps['isDisabled'];
  onClick?: (e: MouseEvent) => void;
  onCompanyModalOpen?: DocCustomerProps['onCompanyModalOpen'];
};

export const CustomerAvatar: FC<CustomerAvatarProps> = ({
  customer,
  isCompact,
  isDisabled = false,
  onClick,
  onCompanyModalOpen,
}) => {
  return (
    <StyledCustomerAvatar
      customer={customer}
      tooltip={isCompact && (
        <TooltipCustomerContent>
          <div>{customer.displayName}</div>
          {!isDisabled && (
            <TooltipCustomerContentUpdateLabel>
              Change customer
            </TooltipCustomerContentUpdateLabel>
          )}
        </TooltipCustomerContent>
      )}
      title="Customer"
      size="S"
      isCompanyEditable={!isDefaultCustomer(customer) && !isDisabled}
      onCompanyClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (customer?.company?.id) {
          onCompanyModalOpen?.(customer.company.id);
        }
      }}
      {...onCompanyModalOpen && customer.company && {
        companyTitle: customer.company.name,
        companyTooltip: 'Open company page',
      }}
      companyInput={!isDisabled ? (
        <StyledCustomerCompanyAction customer={customer} size="S">
          <AddIcon />
        </StyledCustomerCompanyAction>
      ) : undefined}
      onClick={onClick}
    />
  );
};

type AvatarPlaceholderProps = {
  isCompact: DocCustomerProps['isCompact'];
  icon: DocCustomerProps['placeholderIcon'];
  isDisabled?: boolean;
};

const AvatarPlaceholder: FC<AvatarPlaceholderProps> = ({
  isCompact,
  isDisabled,
  icon,
}) => {
  const compactContent = isDisabled
    ? <>{icon}</>
    : (
      <Tooltip
        title="Add customer"
        content=""
        placement="top"
        withPortal
      >
        {icon}
      </Tooltip>
    );

  return isCompact
    ? compactContent : (
      <>
        {icon}
        {isDisabled ? 'No customer' : 'Add customer'}
      </>
    );
};
