import { typo } from '@cycle-app/ui';
import { Helper } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import styled from 'styled-components';

export const DropContainer = styled.div`
  position: relative;
  height: 126px;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Or = styled.div`
  ${typo.body400};
  color: ${p => p.theme.colors.text.disabled};
`;

export const ErrorMessage = styled(Helper)``;
