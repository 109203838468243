import { mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { headerLight } from '../../theme/typo';
import { boxShadowZ5 } from '../../utils/styles.util';
import { ShyScrollbarCss } from '../ShyScrollbar/ShyScrollbar.styles';

export const Container = styled.div<{ placement: 'top' | 'center'; zIndex: number }>`
  z-index: ${p => p.zIndex};
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: ${p => (p.placement === 'center' ? 'center' : 'flex-start')};
  justify-content: center;
  padding-top: ${p => (p.placement === 'top' ? '10vh' : 0)};
`;

export const Mask = styled(motion.div) <{ $visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  ${p => !p.$visible && 'opacity: 0;'}
  background-color: ${(props) => transparentize(0.5, props.theme.colors.modal.mask.bg)};
`;

interface ContentProps {
  $naked: boolean;
  $zIndex: number;
  $noMask: boolean;
}
export const Content = styled(motion.div) <ContentProps>`
  border: 1px solid transparent;
  z-index: ${p => p.$zIndex};
  position: relative;

  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);

  ${p => !p.$naked && css`
    ${ShyScrollbarCss};
    border-color: ${p.theme.colors.modal.border};
    padding: 20px;
    border-radius: 12px;
    background-color: ${p.theme.colors.modal.content.bg};
    color: ${p.theme.colors.text.primary};
    ${boxShadowZ5};
  `};

  ${p => p.$noMask && css`
    background-color: ${p.theme.colors.modal.content.bgNoMask};
  `};

  ${mobile} {
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
  }
`;

export const ModalTitle = styled.h1`
  margin: 0;
  ${headerLight};
`;
