import { useInboxSection } from '../inbox/useInboxSection';
import { useIsAllDocsPage } from '../useIsAllDocsPage';
import { usePathParams } from '../usePathParams';

export const useIsCurrentBoardPrivate = () => {
  const isAllDocsPage = useIsAllDocsPage();
  const { boardSlug } = usePathParams();
  const {
    myInboxBoard, allBoard,
  } = useInboxSection();

  return isAllDocsPage ||
    (myInboxBoard && boardSlug?.includes(myInboxBoard?.id)) ||
    (allBoard && boardSlug?.includes(allBoard?.id));
};
