import { Avatar, typo, NavigationItem, CheckedDot, transitionCss } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import {
  CollaborationCursorCSS,
  COLLABORATION_CURSOR_CARET_CLASS,
  COLLABORATION_CURSOR_LABEL_CLASS,
} from 'src/components/Editor/Editors/Editor.styles';

export const Container = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledAvatar = styled(Avatar)`
  margin-top: 12px;
`;

export const SectionTitle = styled.h1`
  ${typo.body500};
  margin-bottom: 16px;
`;

export const ColorSection = styled.section``;

export const Preview = styled.div`
  border-radius: 8px;
  background-color: ${p => p.theme.colors.background.secondary};
  margin-top: 24px;
  padding: 12px 16px;

  display: flex;
  gap: 30px;

  &, * {
    ${transitionCss(['background', 'color'])}
  }
`;

export const PreviewItem = styled.div``;

export const PreviewLabel = styled.h2`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledNavigationItem = styled(NavigationItem)`
  margin-top: 15px;
  width: 188px;
`;

export const PreviewHighlight = styled.div<{ $highlightColor: string }>`
  margin-top: 18px;
  line-height: 25px;
  color: ${p => p.theme.colors.text.primary};

  mark {
    background-color: ${p => p.$highlightColor};
  }
`;

export const CollaborationCursorCaret = styled.div`
  height: 19px;
`;

export const CollaborationCursorCaretWithName = styled.div``;

export const CursorsPreview = styled.div<{ $color: string }>`
  margin-top: 22px;
  display: flex;
  align-items: baseline;
  gap: 89px;
  color: ${p => p.$color};

  ${CollaborationCursorCSS};

  ${CollaborationCursorCaret}.${COLLABORATION_CURSOR_CARET_CLASS} {
    &:hover::before {
      opacity: 1;
    }
  }
  ${CollaborationCursorCaretWithName} .${COLLABORATION_CURSOR_CARET_CLASS} {
    &::before {
      opacity: 0;
    }

    .${COLLABORATION_CURSOR_LABEL_CLASS} {
      background-color: ${p => p.$color};
      opacity: 1;
      top: -15px;
    }
  }
`;

export const ThemeSection = styled.section`
  margin-top: 24px;
`;

export const NavigationItemPreview = styled.div`
  border-radius: 3px;

  position: absolute;
  top: 88px;
  left: 20px;

  width: 23px;
  height: 7px;
`;

export const ThemePreview = styled.div<{ selected: boolean }>`
  position: relative;

  text-align: center;
  cursor: pointer;
  ${typo.body500};

  img {
    margin-bottom: 8px;
    border-radius: 12px;
    width: 174px;
    height: 144px;
    outline: 3px solid transparent;
    outline-offset: -3px;

    ${p => p.selected && css`
      transition: all 200ms ease-in-out;
      outline-color: ${p.theme.colors.settingsModal.rightPanel.appearance.selectionColor};
    `}
  }
`;

export const ThemePreviews = styled.div<{ $color: string }>`
  position: relative;

  display: flex;
  gap: 16px;

  ${ThemePreview} {
    &::before {
      content: '';
      display: block;
      border-radius: 3px;
      background-color: ${p => p.$color};

      position: absolute;
      top: 106px;
      left: 25px;

      width: 23px;
      height: 7px;
    }
  }
`;

export const StyledCheckedDot = styled(CheckedDot).attrs({
  hasIcon: true,
})`
  position: absolute;
  bottom: 49px;
  right: 10px;
`;

export const Buttons = styled.section`
  margin-top: auto;
  margin-left: auto;
`;
