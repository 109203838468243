import { typo, boxShadowZ1, Paper } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css, keyframes } from 'styled-components';

import { BoardsAnimationContainer, IncognitoIllustationCard, PapersPinnedIllustationCard } from '../IllustationCards';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    "integrations docs docs"
    "sections boards uploads"
    "sections incognito uploads";
  gap: 16px;
  grid-template-columns: repeat(24, 1fr);
  margin: 48px auto 220px;
  max-width: 947px;
  position: relative;
  z-index: 3;

  ${mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const CardCss = css`
  ${boxShadowZ1}
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 12px;
  display: flex;
  padding: 32px;
  position: relative;
  transition: transform .5s;
  z-index: 1;
  gap: 65px;

  &::after, &::before {
    content: "";
    position: absolute;
    border-radius: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &::after {
    background-color: inherit;
    z-index: -1;
  }

  &::before {
    background-color: var(--behindBg);
    transition: transform .5s;
    border-radius: 14px;
    z-index: -2;
  }

  &:hover {
    transform: translate(2px, -2px);
  }

  &:hover::before {
    transform: translate(-4px, 4px);
  }

  ${mobile} {
    gap: 42px;
  }
`;

export const Integrations = styled.div`
  ${CardCss}
  --behindBg: ${p => p.theme.colors.background.blue};
  grid-area: integrations;
  grid-column-end: 12;
  height: 160px;

  svg {
    width: 23px;
    height: 23px;
  }
`;

export const Sections = styled.div`
  ${CardCss}
  --behindBg: ${p => p.theme.colors.background.orangered};
  grid-area: sections;
  grid-column-start: 1;
  grid-column-end: 8;
  flex-direction: column;
`;

export const Boards = styled(BoardsAnimationContainer)`
  ${CardCss}
  --behindBg: ${p => p.theme.colors.background.yellow};
  grid-area: boards;
  grid-column-start: 8;
  grid-column-end: 18;
  height: 160px;
`;

export const Uploads = styled.div`
  ${CardCss}
  --behindBg: ${p => p.theme.colors.background.green};
  grid-area: uploads;
  grid-column-start: 18;
  grid-column-end: 25;
  flex-direction: column;
  padding: 32px 0 0;
`;

export const UploadsHeader = styled.div`
  padding-left: 32px;
  padding-right: 32px;
`;

export const Incognito = styled.div`
  ${CardCss}
  --behindBg: ${p => p.theme.colors.background.mauve};
  grid-area: incognito;
  grid-column-start: 8;
  grid-column-end: 18;
  height: 160px;
  gap: 10px;
`;

export const CardTitle = styled.h3`
  ${typo.headerMedium}
`;

export const CardDesc = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  line-height: 18px;
  margin-top: 8px;
`;

export const CardIcon = styled.div`
  ${mobile} {
    align-items: center;
    display: flex;
    flex: 1 0 96px;
    justify-content: center;
  }
`;

export const CardContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UploadsCardContent = styled(CardContent)`
  border-radius: inherit;
  height: 100%;
  overflow: hidden;
`;

export const StyledPapersPinnedIllustationCard = styled(PapersPinnedIllustationCard)`
  height: 100%;
`;

export const StyledPaper = styled(Paper)`
  color: ${p => p.theme.colors.background.primary};
  width: 82px;
  height: 97px;

  > path:first-child {
    fill: ${p => p.theme.colors.background.secondary};
    stroke: ${p => p.theme.colors.text.primary};
  }

  > path:last-child {
    stroke: ${p => p.theme.colors.text.primary};
  }
`;

const docOneSlide = keyframes`
  0% {
    transform: translate(0, -4px);
  }

  10% {
    transform: translate(4px, 0);
  }

  100% {
    transform: translate(4px, 0);
  }
`;

const docTwoSlide = keyframes`
  0% {
    transform: translate(4px, 0);
  }

  10% {
    transform: translate(8px, 4px);
  }

  100% {
    transform: translate(8px, 4px);
  }
`;

const docVanish = keyframes`
  0% {
    opacity: 1;
    transform: translate(8px, 4px);
  }

  10% {
    opacity: 0;
    transform: translate(12px, 8px);
  }

  100% {
    opacity: 0;
    transform: translate(12px, 8px);
  }
`;

export const DocsPaper = styled(StyledPaper)`
  left: 0;
  top: 0;

  &:first-child {
    position: relative;
    transform: translate(0, -4px);
    z-index: 4;
  }

  &:nth-child(2) {
    position: absolute;
    transform: translate(0, -4px);
    transition: transform .1s;
    z-index: 3;
  }

  &:nth-child(3) {
    position: absolute;
    transform: translate(4px, 0);
    transition: transform .1s;
    z-index: 2;
  }

  &:last-child {
    position: absolute;
    transform: translate(8px, 4px);
    transition: opacity .1s, transform .1s;
    z-index: 1;
  }
`;

export const DocsPapersContainer = styled.div`
  position: relative;
`;

export const DocsAnimationContainer = styled.div`
  &:hover ${DocsPaper}:nth-child(2) {
    animation: ${docOneSlide} 2s ease-in infinite;
  }

  &:hover ${DocsPaper}:nth-child(3) {
    animation: ${docTwoSlide} 2s ease-in infinite;
  }

  &:hover ${DocsPaper}:last-child {
    animation: ${docVanish} 2s ease-in infinite;
  }
`;

export const Docs = styled(DocsAnimationContainer)`
  ${CardCss}
  --behindBg: ${p => p.theme.colors.background.pink};
  grid-area: docs;
  grid-column-start: 12;
  grid-column-end: 25;
  height: 160px;
`;

export const StyledIncognitoIllustationCard = styled(IncognitoIllustationCard)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
