import {
  AddIcon,
  BellActiveIcon,
  BellIcon,
  HomeIcon,
  InboxIcon,
  SearchIcon,
} from '@cycle-app/ui/icons';
import { FC, useCallback } from 'react';

import NotificationCenter from 'src/app/Main/Board/NotificationCenter/NotificationCenter';
import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';
import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useFeedbackDocType } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useMobileSidebarActions } from 'src/hooks/useMobileSidebarActions';
import { useNavigate } from 'src/hooks/useNavigate';
import { useParentPage } from 'src/hooks/usePageId';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';
import { getNotifications, setNotifications } from 'src/reactives/notifications.reactive';
import { useResponsive } from 'src/reactives/responsive.reactive';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';
import { Shortcut, ShortcutBoard, ShortcutCommandbar } from 'src/types/shortcuts.types';

import {
  CreateDocIconContainer,
  CreateNewDocNavigationItem,
  CreateNewPlaceholder,
} from './SidebarActions.styles';

export const SidebarActions: FC = () => {
  const feedback = useFeedbackDocType();
  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);
  const { colorTheme } = useGetThemeConfig();
  const { getUrl } = useNavigate();
  const isOnboarding = useIsOnboarding();
  const { close: closeMobileSidebar } = useMobileSidebarActions();
  const { product } = useProduct();
  const collapsed = useSidebarCollapsed();
  const [{ breakpoint }] = useResponsive();

  const parentPage = useParentPage();

  const { notificationsNotRead } = product || {};

  const onSearchClicked = useCallback(() => {
    closeMobileSidebar();
    setCommandbar({
      visible: true,
      section: null,
    });
  }, [closeMobileSidebar]);

  const onNotificationsClicked = useCallback(() => {
    closeMobileSidebar();
    const { isNotifCenterVisible } = getNotifications();
    setNotifications({
      isNotifCenterVisible: !isNotifCenterVisible,
    });
  }, [closeMobileSidebar]);

  const onCreateDocClicked = useCallback(() => {
    if (!feedback?.id) return;
    setCreateDoc({
      modalVisible: true,
      doctypeId: feedback?.id,
    });
  }, [feedback?.id]);

  return (
    <>
      <CollapsableNavigationItem
        linkId="home"
        asPlaceholder={isOnboarding}
        icon={<HomeIcon />}
        label="Home"
        linkTo={getUrl(PageId.Main)}
        onClick={closeMobileSidebar}
        isSidebarColor
        placeholderScale={[0.5, 0.3]}
        activeAlpha={isOnboarding ? 1 : undefined}
        isActive={parentPage === 'home'}
        shortcut={shortcuts[Shortcut.GoToHome]}
        isChild
      />

      {isInboxEnabled && (
        <CollapsableNavigationItem
          linkId="inbox"
          asPlaceholder={isOnboarding}
          icon={<InboxIcon />}
          label="Inbox"
          linkTo={getUrl(PageId.Inbox)}
          onClick={closeMobileSidebar}
          isSidebarColor
          placeholderScale={[0.5, 0.3]}
          activeAlpha={isOnboarding ? 1 : undefined}
          isActive={parentPage === PageId.Inbox}
          shortcut={shortcuts[Shortcut.GoToInbox]}
          isChild
        />
      )}

      <CollapsableNavigationItem
        asPlaceholder={isOnboarding}
        icon={<SearchIcon />}
        label="Search"
        shortcut={shortcuts[ShortcutCommandbar.ToggleCommandBar]}
        onClick={onSearchClicked}
        isSidebarColor
        placeholderScale={[0.5, 0.3]}
        activeAlpha={isOnboarding ? 1 : undefined}
        isChild
      />

      {collapsed && breakpoint !== 'mobile' && (
        <NotificationCenter>
          <CollapsableNavigationItem
            icon={notificationsNotRead ? <BellActiveIcon /> : <BellIcon />}
            label="Notifications"
            tagWithCount={isOnboarding ? 0 : notificationsNotRead}
            shortcut={shortcuts[ShortcutBoard.OpenNotifCenter]}
            onClick={onNotificationsClicked}
            isSidebarColor
            asPlaceholder={isOnboarding}
            placeholderScale={[0.45, 0.3]}
            activeAlpha={isOnboarding ? 1 : undefined}
          />
        </NotificationCenter>
      )}

      <CreateNewDocNavigationItem
        $isSidebarCollapsed={collapsed}
        $colorTheme={colorTheme}
        icon={(
          <CreateDocIconContainer>
            <AddIcon />
          </CreateDocIconContainer>
        )}
        label={isOnboarding ? <CreateNewPlaceholder /> : `New ${feedback?.name}`}
        shortcut={shortcuts[ShortcutBoard.CreateDoc]}
        onClick={onCreateDocClicked}
        isSidebarColor
        isChild
      />
    </>
  );
};
