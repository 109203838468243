import { FEATURES } from '../constants/features.constants';
import { Feature, FeatureValue } from '../types/features.types';

export function isEnabled(feature: Feature): boolean {
  return getFeatureValue(feature) === FeatureValue.On;
}

export function isDisabled(feature: Feature): boolean {
  return getFeatureValue(feature) === FeatureValue.Off;
}

function getFeatureValue(feature: Feature): FeatureValue {
  const featureValue = FEATURES[feature];

  if (!featureValue) {
    console.error(`Missing environment variable for feature ${feature}. Toggled off by default`);
    return FeatureValue.Off;
  }
  if (!isFeatureValue(featureValue)) {
    console.error(`Wrong value for feature ${feature} (accepted values: "on" or "off") Toggled off by default`);
    return FeatureValue.Off;
  }

  return featureValue;
}

function isFeatureValue(value: string): value is FeatureValue {
  return value === FeatureValue.On || value === FeatureValue.Off;
}
