import { typo, Skeleton, Button, ShyScrollbar, InfiniteScroll } from '@cycle-app/ui';
import { opacify, darken } from 'polished';
import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  background-color: ${p => p.theme.colors.docPanel.bg};
  padding: 16px;
`;

export const Container = styled(ShyScrollbar) <{ $isDisabled: boolean }>`
  max-height: 100%;
  overflow-y: auto;

  ${p => p.$isDisabled && css`
    pointer-events: none;
  `}

  > *:first-child {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: 0;
  }

  ${ButtonWrapper} {
    border-bottom: 1px solid ${p => p.theme.colors.docPanel.rightPanel.border};
  }
`;

export const ScrollableContainer = styled(InfiniteScroll)`
  > div {
    border-bottom: 1px solid ${p => p.theme.colors.docPanel.rightPanel.border};
    border-left: 1px solid ${p => p.theme.colors.docPanel.rightPanel.border};
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 48px 0;
  position: relative;
`;

export const Title = styled.h4`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Paragraph = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
`;

export const TitleSkeleton = styled(Skeleton)`
  width: 92px;
  height: 20px;
  height: 16px;
  margin: 5px 0;
`;

export const ParagraphSkeleton = styled(Skeleton)`
  width: 160px;
  height: 12px;
  margin: 2px 0;
`;

export const AddInsightButton = styled(Button).attrs({
  useUserColor: false,
  variant: 'outlined',
  size: 'M',
}) <{ $isFullWidth: boolean; $isFocus: boolean }>`
  ${typo.body500}
  background-color: ${p => (p.theme.colors.docPanel.insightButton.enableUserColor
    ? p.theme.userColors.bg
    : p.theme.colors.button.ternary.bg)};
  color: ${p => (p.theme.colors.docPanel.insightButton.enableUserColor
    ? p.theme.userColors.main
    : p.theme.colors.text.primary)};
  padding: 4px 16px;
  width: ${p => (p.$isFullWidth ? '100%' : 'auto')};
  margin-top: ${p => (p.$isFullWidth ? 0 : '6px')};

  :hover, :active, :focus {
    background-color: ${p => (
    p.theme.colors.docPanel.insightButton.enableUserColor
      ? opacify(0.2, p.theme.userColors.bg)
      : darken(0.1, p.theme.colors.button.ternary.bgHover))};
  }
`;

export const PropertiesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

export const InputLinkDocContainer = styled.div`
  margin-bottom: 24px;
  padding: 4px 12px;

  > * {
    width: 100%;
  }
`;

export const ButtonEmptyWrapper = styled.div`
  margin-top: 4px;
`;
