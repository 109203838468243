import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const LoomIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M128 56.8826H90.571L122.986 38.1676L115.868 25.8342L83.4524 44.5492L102.163 12.1364L89.8297 5.01357L71.1189 37.4263V0H56.881V37.4299L38.163 5.01357L25.8335 12.1328L44.5479 44.5456L12.1325 25.8342L5.01343 38.164L37.4289 56.879H0V71.1176H37.4253L5.01343 89.8324L12.1325 102.166L44.5443 83.4544L25.8299 115.867L38.163 122.986L56.8774 90.5703V128H71.1155V90.5736L89.8264 122.986L102.159 115.867L83.445 83.451L115.86 102.166L122.98 89.8324L90.5676 71.1209H127.993V56.8826H128ZM64 83.3646C53.2642 83.3646 44.5622 74.663 44.5622 63.9266C44.5622 53.1904 53.2642 44.4883 64 44.4883C74.7359 44.4883 83.4375 53.1904 83.4375 63.9266C83.4375 74.663 74.7359 83.3646 64 83.3646Z" fill="#625DF5" />
  </Svg>
);
