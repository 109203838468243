import { DocBaseFragment, DocParentFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { MouseEvent, useMemo, FC, useCallback } from 'react';

import DocSearchDropdown from 'src/components/DocSearchDropdown/DocSearchDropdown';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useAttributesFromDoc } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromDoc';
import { useNavigate } from 'src/hooks/useNavigate';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { getDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';
import { canHaveParent } from 'src/utils/doc.util';

import { ParentIcon } from '../DocParent/DocParent.styles';
import {
  AddParentBtn,
  EditParentBtnContainer,
  EditParentBtn,
  StyledDocParent,
} from './DocPanelParent.styles';

interface Props {
  className?: string;
  doc: DocBaseFragment;
  showParentTitle?: boolean;
  showIcon?: boolean;
  minimal?: boolean;
  placeholder?: string;
  absoluteEdit?: boolean;
  size?: 'S' | 'M';
}
const DocParentDropdown: FC<Props> = ({
  className,
  doc,
  showParentTitle = true,
  showIcon,
  minimal,
  placeholder = 'Add',
  absoluteEdit = false,
  size,
}) => {
  const changeDocParent = useChangeDocParent();

  const [isParentBtnTooltipDisplayed, {
    setTrueCallback: showParentBtnTooltip,
    setFalseCallback: hideParentBtnTooltip,
  }] = useOptimizedBooleanState(false);
  const [isSearchDropdownVisible, {
    setFalseCallback: hideSearchDropdown,
    toggleCallback: toggleSearchDropdown,
  }] = useOptimizedBooleanState(false);

  const changeParent = useCallback(async (parentId: string, opts: { remove?: boolean } = {}) => {
    await changeDocParent({
      docId: doc.id,
      parentId,
      ...opts,
    });
    hideSearchDropdown();
  }, [changeDocParent, doc, hideSearchDropdown]);

  const onRemoveParent = useCallback(() => {
    if (!doc.parent?.id) return null;
    return changeParent(doc.parent.id, { remove: true });
  }, [changeParent, doc.parent?.id]);

  const possibleParentDoctypes = useMemo(() => nodeToArray(doc.doctype.parents), [doc.doctype.parents]);

  const docAttributes = useAttributesFromDoc(doc);

  if (!canHaveParent(doc)) return null;

  return (
    <DocSearchDropdown
      onAdd={changeParent}
      onRemove={onRemoveParent}
      childDoctypeId={doc.doctype.id}
      hideSearchDropdown={hideSearchDropdown}
      isSearchDropdownVisible={isSearchDropdownVisible}
      possibleDoctypes={possibleParentDoctypes}
      dropdownProps={{ layer: Layer.DropdownZ2 }}
      showNoneOption={!!doc.parent}
      inheritedAttributes={docAttributes}
      sourceId={doc.source?.id}
      customerId={doc.doctype.customer ? doc.customer?.id : undefined}
    >
      {doc.parent
        ? (
          <Tooltip
            placement="bottom"
            disabled={isParentBtnTooltipDisplayed}
            content={doc.parent.title}
            withPortal
          >
            <DocParentLink doc={doc.parent}>
              <StyledDocParent
                className={className}
                doc={doc.parent}
                minimal={minimal}
                showTitle={showParentTitle}
                showIcon={showIcon}
                size={size}
                onMouseEnter={() => {
                  setDocItem({ hoverDocId: doc.parent?.id });
                  if (getDocIdPreview().docIdPreview) setDocIdPreview({ docIdPreview: doc.id });
                }}
                onMouseLeave={() => setDocItem({ hoverDocId: null })}
              >
                <EditParentBtnContainer $absolute={absoluteEdit}>
                  <EditParentBtn
                    tooltip="Change parent"
                    tooltipPlacement="bottom"
                    onMouseEnter={showParentBtnTooltip}
                    onMouseLeave={hideParentBtnTooltip}
                    onClick={toggleDropdown}
                  >
                    <PenFilledIcon />
                  </EditParentBtn>
                </EditParentBtnContainer>
              </StyledDocParent>
            </DocParentLink>
          </Tooltip>
        )
        : (
          <AddParentBtn
            className={className}
            size={12}
            forceFocus={isSearchDropdownVisible}
            onClick={toggleDropdown}
          >
            <ParentIcon />
            <span>{placeholder}</span>
          </AddParentBtn>
        )}
    </DocSearchDropdown>
  );

  function toggleDropdown(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    toggleSearchDropdown();
  }
};

export default DocParentDropdown;

const DocParentLink: FC<{ doc: DocParentFragment }> = ({
  doc, ...props
}) => {
  const { navigateToDocFullPage } = useNavigate();
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        navigateToDocFullPage(doc, undefined, e.metaKey);
      }}
      {...props}
    />
  );
};
