import {
  Table, Button, Card, ShyScrollbar, Td, Input, Tr, boxShadowZ1, typo, Th, CellContent,
} from '@cycle-app/ui';
import { mobile, tablet } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';

export const Header = styled.header`
  flex: none;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 28px;

  h1 {
    font-size: 26px;
    font-weight: 600;
    outline: none;
  }

  ${mobile} {
    margin-top: 32px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const Nav = styled(ShyScrollbar)`
  flex: none;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  margin-bottom: 32px;
  overflow-y: hidden;
  gap: 20px;
`;

export const TabList = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
  top: 1px;
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  text-align: center;

  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  margin-left: 8px;

  background-color: ${p => p.theme.colors.customers.badge.bg};
  color: ${p => p.theme.colors.text.secondary};

  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;

export const Tab = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;
  position: relative;
  border-radius: 4px;
  outline: none;
  color: ${p => p.theme.colors.text.secondary};

  ${p => p.$active && css`
    font-weight: 500;
    color: ${p.theme.colors.text.primary};
    &::after {
      background-color: ${p.theme.userColors.main};
    }
  `}

  ${p => p.disabled && css`
    cursor: not-allowed;
  `}

  &:hover {
    background-color: ${p => p.theme.colors.customers.tab.bgHover};
    ${Badge} {
      background-color: ${p => p.theme.colors.customers.badge.bgHover};
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: transparent;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  ${mobile} {
    margin-bottom: 7px;
  }
`;

export const ActionButton = styled(Button).attrs({
  size: 'M',
})`
  padding: 3px 12px;
  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const StyledShyScrollbar = styled(ShyScrollbar)`
  width: 100%;
  max-height: 100%;
  margin-right: -8px;
  padding-right: 8px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
  label {
    margin-bottom: 8px;
  }
`;

export const FormInput = styled(Input)`
  width: 100%;
`;

export const SearchInput = styled(Input)`
  input {
    height: 32px;
    width: 180px
  }
  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const DocTypeButton = styled(Button).attrs({
  variant: 'outlined',
  size: 'M',
})`
  padding: 4px 9px;
`;

export const OptionsCard = styled(Card).attrs({
  disableHover: true,
})`
  min-width: 250px;
  max-height: 400px;
  overflow-y: auto;
  padding: 8px 0px;
  border: none;
`;

export const StyledTable = styled(Table)<{ $isPrimary?: boolean }>`
  background-color: ${p => (p.$isPrimary ? p.theme.colors.background.primary : p.theme.colors.modal.content.bg)};
  table-layout: fixed;
`;

export const CellLink = styled.span<{ $disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 6px;

  padding: 0px 6px;
  border-radius: 4px;
  margin-left: 8px;

  opacity: 0;
  color: ${p => p.theme.colors.text.secondary};
  background-color: ${p => p.theme.colors.customers.link.bg};
  border: 1px solid ${p => p.theme.colors.customers.link.border};
  ${boxShadowZ1};

  ${typo.caption400}
  line-height: 20px;

  ${p => p.$disabled && css`
    pointer-events: none;
  `}

  &:hover {
    background-color: ${p => p.theme.colors.customers.link.bgHover};
  }
`;

export const StyledTr = styled(Tr)`
  &:hover {
    ${CellLink} {
      opacity: 1;
    }
  }
`;

export const StyledDotsMenuLayer = styled(DotsMenuLayer)`
  &.force-focus, &:hover {
    color: ${p => p.theme.colors.text.primary};
    background-color: ${p => p.theme.colors.settings.navigationItem.bgHover};
  }
`;

export const Highlight = styled.span`
  background-color: ${p => p.theme.colors.background.highlight};
  color: ${p => p.theme.colors.text.highlight};
`;

interface CellSize {
  $size?: number;
  $sizeTablet?: number;
  $sizeMobile?: number;
}

const CellCommonCss = css<CellSize>`
  --size: ${p => (p.$size ? `${p.$size}px` : 'auto')};
  --size-tablet: ${p => ((p.$sizeTablet ?? p.$size) ? `${(p.$sizeTablet ?? p.$size)}px` : 'auto')};
  --size-mobile: ${p => ((p.$sizeMobile ?? p.$size) ? `${(p.$sizeMobile ?? p.$size)}px` : 'auto')};
  max-width: var(--size);
  width: var(--size);

  ${mobile} {
    --size: var(--size-mobile);
  }

  ${tablet} {
    --size: var(--size-tablet);
  }
`;

export const StyledTh = styled(Th)<CellSize>`
  ${CellCommonCss}
`;

export const StyledThActions = styled(Th)<CellSize>`
  background-color: inherit;
  position: sticky;
  right: -8px;
  ${CellCommonCss}
`;

export const StyledTd = styled(Td)<CellSize>`
  ${CellCommonCss}
`;

export const TdActions = styled(StyledTd)`
  text-align: right;
  background-color: inherit;
  position: sticky;
  right: -8px;

  > * {
    display: inline-block;
  }
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const CompanyActionSelect = styled(CustomerCompanyAction)`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: transparent;
  gap: 4px;
  justify-content: space-between;
  padding: 7px 8px;
  width: 100%;
  ${typo.body400};

  &:hover, &:active, &:focus {
    background: transparent;
  }
`;

export const CompanyActionSelectLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.primary};
`;

export const DocCountCell = styled(CellContent)`
  justify-content: flex-start;
`;

export const InputContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const UploadNewBtn = styled(Button).attrs({ size: 'M' })`
  white-space: nowrap;
`;

export const RemoveBtn = styled(Button).attrs({
  size: 'M',
  variant: 'nospace',
})`
  white-space: nowrap;
  color: ${p => p.theme.colors.text.secondary}
`;
