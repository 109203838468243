import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { InboxEditViewModal } from 'src/components/InboxEditView';
import { InboxViewSettingsModal } from 'src/components/InboxViewSettings';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { isViewMyInbox, isViewAll } from 'src/utils/inbox.util';

type BoardModalsProps = {
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
  onHide: VoidFunction;
  isLoadingDraftBoardConfig: boolean;
};

export const BoardModals: FC<BoardModalsProps> = (props) => {
  const { board } = props;
  const { canUpdateBoard } = useGetPermission();
  return (
    <>
      {isViewMyInbox(board) && <InboxEditViewModal {...props} />}
      {isViewAll(board) && canUpdateBoard && <InboxEditViewModal {...props} />}
      {!board.isBuiltIn && canUpdateBoard && <InboxViewSettingsModal {...props} />}
    </>
  );
};
