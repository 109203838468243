import { UploadImageDocument, UploadVideoDocument, UploadFileDocument } from '@cycle-app/graphql-codegen';
import { getFileType, FileUploadSource, FileUploadedData } from '@cycle-app/utilities';
import { useCallback, useRef } from 'react';

import { Events } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useToaster } from 'src/hooks/useToaster';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';
import { getFileError } from 'src/utils/files.util';

export type UploadReturn = Promise<FileUploadedData | null>;

const useUploadFile = () => {
  const source = useRef<FileUploadSource | null>(null);
  const { add: addToaster } = useToaster();

  const [uploadImageMutation, { loading: isUploadingImage }] = useSafeMutation(UploadImageDocument, {
    onCompleted: () => {
      trackAnalytics(Events.FileUploaded, {
        type: 'image',
        ...(source.current ? { source: source.current } : {}),
      });
      source.current = null;
    },
    onError: (error) => {
      source.current = null;
      logError(error);
    },
  });
  const [uploadVideoMutation, { loading: isUploadingVideo }] = useSafeMutation(UploadVideoDocument, {
    onCompleted: () => {
      trackAnalytics(Events.FileUploaded, {
        type: 'video',
        ...(source.current ? { source: source.current } : {}),
      });
      source.current = null;
    },
    onError: (error) => {
      source.current = null;
      logError(error);
    },
  });
  const [uploadFileMutation, { loading: isUploadingFile }] = useSafeMutation(UploadFileDocument, {
    onCompleted: () => {
      trackAnalytics(Events.FileUploaded, {
        type: 'file',
        ...(source.current ? { source: source.current } : {}),
      });
      source.current = null;
    },
    onError: (error) => {
      source.current = null;
      logError(error);
    },
  });

  const uploadFile = useCallback(async (file: File): UploadReturn => {
    const fileType = getFileType(file);
    const uploadFunction = {
      image: uploadImageMutation,
      file: uploadFileMutation,
      video: uploadVideoMutation,
    }[fileType];

    const response = await uploadFunction({ variables: { file } });

    const src = response.data?.upload || '';

    return {
      alt: file.name,
      mime: file.type,
      size: file.size,
      src,
      title: file.name,
      type: fileType,
    };
  }, [uploadFileMutation, uploadImageMutation, uploadVideoMutation]);

  const onUpload = useCallback(async (file: File, from: FileUploadSource): UploadReturn => {
    const fileType = getFileType(file);
    const fileError = getFileError(file);

    if (fileError) {
      addToaster({
        title: 'An error occured',
        message: fileError.message,
      });
      trackAnalytics(Events.FileUploadFailed, {
        type: fileType,
        reason: fileError.type,
        sizeInBit: file.size,
        source: from,
      });

      return null;
    }

    source.current = from;

    return uploadFile(file);
  }, [addToaster, uploadFile]);

  return {
    isUploading: isUploadingImage || isUploadingFile || isUploadingVideo,
    onUpload,
  };
};

export default useUploadFile;
