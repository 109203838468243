import {
  AddBoardConfigFilterRuleDocument,
  AddBoardConfigFilterRuleValueDocument,
  ChangeFilterRuleOperatorDocument,
  ChangeFilterRulePropertyDocument,
  FilterValueInput,
  Operator,
  RemoveBoardConfigFilterRuleDocument,
  RemoveBoardConfigFilterRuleValueDocument,
  PropertyFragment,
  AddBoardConfigFilterRuleMutation,
  AddDoctypeFilterRuleDocument,
  RemoveDoctypeFilterRuleDocument,
  FilterPropertyRuleDoctypeFragment,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events, Methods, Objects } from 'src/constants/analytics.constants';
import { useGetDraftBoardConfigFromCache, useGetEditableSavedBoardConfigFromCache } from 'src/hooks/api/cache/cacheBoardConfig';
import { useBoard } from 'src/hooks/api/useBoard';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import {
  getOptimisticNewFilter,
  mappingPropertyFilterRule,
  getOptimisticFilterWithAddedValue,
  getOptimisticFilterWithRemovedValue,
} from 'src/utils/boardConfig/filtersOptimistic.util';

interface DoctypeFilter extends FilterPropertyRuleDoctypeFragment {
  __typename: 'FilterPropertyRuleDoctype';
}

export default function useBoardConfigFilterMutations(draftBoardConfigId: string, boardID?: string, onCompleted?: VoidFunction) {
  const board = useBoard(boardID);
  const boardId = board?.id ?? '';

  const mutationOptions = {
    onCompleted: () => {
      trackAnalytics(Events.BoardUpdated, {
        method: Methods.UI,
        object: Objects.ToolbarFilter,
      });
      onCompleted?.();
    },
  };

  const getDraftBoardConfigFromCache = useGetDraftBoardConfigFromCache(boardId);
  const getEditableSavedBoardConfigFromCache = useGetEditableSavedBoardConfigFromCache(boardId);

  const getBoardConfigFromCache = useCallback(() => {
    const draftBoardConfig = getDraftBoardConfigFromCache();
    const savedBoardConfig = getEditableSavedBoardConfigFromCache();
    return draftBoardConfig ?? savedBoardConfig;
  }, [getDraftBoardConfigFromCache, getEditableSavedBoardConfigFromCache]);

  const [addDoctypeFilterRuleMutation] = useSafeMutation(AddDoctypeFilterRuleDocument, mutationOptions);
  const [removeDoctypeFilterRuleMutation] = useSafeMutation(RemoveDoctypeFilterRuleDocument, mutationOptions);
  const [addFilterRuleMutation] = useSafeMutation(AddBoardConfigFilterRuleDocument, mutationOptions);
  const [removeFilterRuleMutation] = useSafeMutation(RemoveBoardConfigFilterRuleDocument, mutationOptions);
  const [addFilterValueMutation] = useSafeMutation(AddBoardConfigFilterRuleValueDocument, mutationOptions);
  const [removeFilterValueMutation] = useSafeMutation(RemoveBoardConfigFilterRuleValueDocument, mutationOptions);
  const [changeOperatorMutation, { loading: loadingChangeOperator }] = useSafeMutation(ChangeFilterRuleOperatorDocument, mutationOptions);
  const [changePropertyMutation, { loading: loadingChangeProperty }] = useSafeMutation(ChangeFilterRulePropertyDocument, mutationOptions);

  const addDoctypeFilterRule = useCallback((value: FilterValueInput) => {
    const boardConfig = getBoardConfigFromCache();
    if (!boardConfig?.__typename) return null;

    const filter = boardConfig.filterProperties.edges
      .find(({ node }) => node.__typename === 'FilterPropertyRuleDoctype')
      ?.node as DoctypeFilter | undefined;

    if (!filter) return null;

    const optimisticUpdatedFilter = getOptimisticFilterWithAddedValue(filter, value);

    return addDoctypeFilterRuleMutation({
      variables: {
        ruleId: filter.id,
        value,
      },
      ...optimisticUpdatedFilter && boardConfig && {
        optimisticResponse: {
          addBoardConfigFilterRuleValue: {
            ...optimisticUpdatedFilter,
            boardConfig: {
              ...boardConfig,
              filterProperties: {
                __typename: 'FilterPropertyRulesConnection',
                edges: boardConfig.filterProperties.edges.map(e => (e.node.id === filter.id
                  ? {
                    __typename: 'FilterPropertyRuleEdge',
                    node: optimisticUpdatedFilter,
                  }
                  : e)),
              },
            },
          },
        },
      },
    });
  }, [addDoctypeFilterRuleMutation, getBoardConfigFromCache]);

  const removeDoctypeFilterRule = useCallback((filterId: string, valueId: string) => {
    const boardConfig = getBoardConfigFromCache();
    if (!boardConfig?.__typename) return null;

    const filter = boardConfig.filterProperties.edges.find(({ node }) => node.id === filterId)?.node;
    if (!filter) return null;

    const optimisticFilter = getOptimisticFilterWithRemovedValue(filter, valueId);

    return removeDoctypeFilterRuleMutation({
      variables: {
        ruleId: filterId,
        valueId,
      },
      ...optimisticFilter && boardConfig && {
        optimisticResponse: {
          removeBoardConfigFilterRuleValue: {
            ...optimisticFilter,
            boardConfig: {
              ...boardConfig,
              filterProperties: {
                __typename: 'FilterPropertyRulesConnection',
                edges: boardConfig.filterProperties.edges.map(e => (e.node.id === filterId
                  ? {
                    __typename: 'FilterPropertyRuleEdge',
                    node: optimisticFilter,
                  }
                  : e)),
              },
            },
          },
        },
      },
    });
  }, [getBoardConfigFromCache, removeDoctypeFilterRuleMutation]);

  const addFilterRule = useCallback(async (property: PropertyFragment) => {
    const boardConfig = getBoardConfigFromCache();
    const filterRuleType = mappingPropertyFilterRule[property.__typename];
    if (!boardConfig || !filterRuleType) return;

    const optimisticNewFilter = getOptimisticNewFilter(property, mappingPropertyFilterRule);

    await addFilterRuleMutation({
      variables: {
        boardConfigId: draftBoardConfigId,
        propertyId: property.id,
      },
      optimisticResponse: {
        addBoardConfigFilterRule: {
          ...optimisticNewFilter,
          boardConfig: {
            ...boardConfig,
            filterProperties: {
              __typename: 'FilterPropertyRulesConnection',
              edges: [
                ...boardConfig.filterProperties.edges,
                {
                  __typename: 'FilterPropertyRuleEdge',
                  node: optimisticNewFilter,
                },
              ],
            },
          },
        },
      } as AddBoardConfigFilterRuleMutation,
    });
  }, [getBoardConfigFromCache, addFilterRuleMutation, draftBoardConfigId]);

  const removeFilterRule = useCallback(async (ruleId: string) => {
    const boardConfig = getBoardConfigFromCache();
    if (!boardConfig) return;

    await removeFilterRuleMutation({
      variables: {
        ruleId,
      },
      optimisticResponse: {
        removeBoardConfigFilterRule: {
          ...boardConfig,
          filterProperties: {
            ...boardConfig.filterProperties,
            edges: boardConfig.filterProperties.edges.filter(edge => edge.node.id !== ruleId),
          },
        },
      },
    });
  }, [getBoardConfigFromCache, removeFilterRuleMutation]);

  const changeFilterRuleAttribute = useCallback(async (ruleId: string, property: PropertyFragment) => changePropertyMutation({
    variables: {
      ruleId,
      propertyId: property.id,
    },
  }), [changePropertyMutation]);

  const changeFilterRuleOperator = useCallback((ruleId: string, operator: Operator) => changeOperatorMutation({
    variables: {
      ruleId,
      operator,
    },
  }), [changeOperatorMutation]);

  const addFilterValue = useCallback(async (filterId: string, value: FilterValueInput) => {
    const boardConfig = getBoardConfigFromCache();
    if (!boardConfig?.__typename) return;
    const filter = boardConfig.filterProperties.edges.find(({ node }) => node.id === filterId)?.node;
    if (!filter) return;

    const optimisticUpdatedFilter = getOptimisticFilterWithAddedValue(filter, value);

    await addFilterValueMutation({
      variables: {
        ruleId: filterId,
        value,
      },
      optimisticResponse: {
        addBoardConfigFilterRuleValue: optimisticUpdatedFilter,
      },
    });
  }, [addFilterValueMutation, getBoardConfigFromCache]);

  const removeFilterValue = useCallback(async (filterId: string, valueId: string) => {
    const boardConfig = getBoardConfigFromCache();
    if (!boardConfig?.__typename) return;

    const filter = boardConfig.filterProperties.edges.find(({ node }) => node.id === filterId)?.node;
    if (!filter) return;

    const optimisticFilter = getOptimisticFilterWithRemovedValue(filter, valueId);

    await removeFilterValueMutation({
      variables: {
        ruleId: filterId,
        valueId,
      },
      optimisticResponse: {
        removeBoardConfigFilterRuleValue: optimisticFilter,
      },
    });
  }, [getBoardConfigFromCache, removeFilterValueMutation]);

  return {
    addDoctypeFilterRule,
    removeDoctypeFilterRule,
    addFilterRule,
    removeFilterRule,
    changeFilterRuleAttribute,
    changeFilterRuleOperator,
    addFilterValue,
    removeFilterValue,
    loadingChangeOperator,
    loadingChangeProperty,
  };
}
