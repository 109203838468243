import { AddNewDocDocument, DoctypeType, UpdateDocContentDocument } from '@cycle-app/graphql-codegen';
import { Button, ActionButton, Shortcuts, Tag, Emoji } from '@cycle-app/ui';
import { CloseIcon, ExpandIcon, ImageIcon, AddMemberOutlineIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import {
  useReducer, useCallback, useEffect, useRef, useState, FC,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import DialogModal from 'src/components/DialogModal/DialogModal';
import { variantsContent } from 'src/components/DialogModal/DialogModal.motion';
import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import DocEditor from 'src/components/DocEditor/DocEditor';
import { DocStatus } from 'src/components/DocStatus';
import { HelpMeWriting } from 'src/components/HelpMeWriting';
import { CLASSNAME_USED_FOR_FOCUS } from 'src/constants/editor.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useCustomerDocFromCache } from 'src/hooks/api/cache/cacheCustomerDoc';
import { useMe } from 'src/hooks/api/useMe';
import { useProduct } from 'src/hooks/api/useProduct';
import { useCreateDocModalFirstProductTour, useCanOpenCreateDocModel } from 'src/hooks/productTour/useCreateDocModalProductTour';
import { useCreateModalShortcutCallbacks } from 'src/hooks/shortcuts/useCreateModalShortcutListener';
import { useDefaultDocTypeStatus } from 'src/hooks/status/useDefaultDocTypeStatus';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getCreateDoc, setCreateDoc, useGetCreateDoc } from 'src/reactives/createDoc.reactive';
import { useLastDoctypeIdUsed } from 'src/reactives/lastView.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutCreateModal } from 'src/types/shortcuts.types';

import {
  Container,
  PortalModalStyled,
  Actions,
  BigActionButton,
  DocTop,
  Content,
  Toolbar,
  ContentEditableStyled,
  Attributes,
  Buttons,
  DocContainer,
  DocPanelCoverStyled,
  ScrollableContent,
  DocTypeLabel,
  SkeletonCustomer,
  SkeletonAvatar,
  SkeletonUsername,
} from './CreateDocModal.styles';
import { EditorSkeleton } from './EditorSkeleton';
import { usePublishDocInCache } from './usePublishDocInCache';

const closeModal = () => setCreateDoc({
  modalVisible: false,
  docId: null,
  customerId: null,
  doctypeId: null,
});

export const CreateDocModal = () => (
  <ModalContainer>
    <ModalContent />
  </ModalContainer>
);

const ModalContainer: FC = ({ children }) => {
  const { modalVisible } = useGetCreateDoc();
  const canOpenModal = useCanOpenCreateDocModel();
  if (!modalVisible || !canOpenModal) return null;
  return <>{children}</>;
};

const ModalContent = () => {
  const [isModalReady, setModalReady] = useReducer(() => true, false);
  const [isEditorReady, setEditorReady] = useReducer(() => true, false);
  const [editorContent, setEditorContent] = useState('');
  const { product } = useProduct();
  const { me } = useMe();
  const { modalVisible } = useGetCreateDoc();
  const {
    doc,
    onTitleUpdated,
    onOpenCoverInputFile,
    coverInputRef,
    updateDocCover,
    getDropzonePropsCover,
    isDraggingCover,
  } = useDocPanelProps(true);
  const { addCustomerDoc } = useCustomerDocFromCache();
  const [createDoc] = useSafeMutation(AddNewDocDocument);
  const [updateDocContent, { loading: isContentUpdateLoading }] = useSafeMutation(UpdateDocContentDocument);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastDoctypeIdUsed = useLastDoctypeIdUsed();
  const [dialogModal, setDialogModal] = useState(false);
  const [edited, setEdited] = useState(false);

  const defaultDoctypeId = getCreateDoc().doctypeId || lastDoctypeIdUsed;
  const defaultDoctype = nodeToArray(product?.doctypes).find(d => d.id === defaultDoctypeId);
  const isFeedback = defaultDoctype?.type === DoctypeType.Feedback;

  const docContentJson = useRef({
    contentJSON: defaultDoctype?.template?.contentJSON ?? '',
  });

  const defaultStatus = useDefaultDocTypeStatus(defaultDoctypeId);

  const createModalCallbacks = useCreateModalShortcutCallbacks();
  const {
    isActive: isFirstProductTourActive,
    isEditorBlocked,
    isPreventDocTitleEdit,
    setOpenFeedbackStep,
    shouldSetNextStepEl,
    showHelper,
  } = useCreateDocModalFirstProductTour(containerRef);

  const editEditorContent = useDebouncedCallback((newValue) => setEditorContent(newValue), INPUT_ONCHANGE_DEBOUNCE);

  const { loading } = createModalCallbacks;

  useEffect(() => {
    if (!isModalReady) return;
    async function createDraft() {
      const doctypeId = getCreateDoc().doctypeId || lastDoctypeIdUsed;
      if (doctypeId && product && !getCreateDoc().docId) {
        const createdDoc = await createDoc({
          variables: {
            groupId: '',
            title: isFirstProductTourActive
              ? `My first feedback${me.firstName ? ` (${me.firstName})` : ''}`
              : '',
            doctypeId,
            productId: product.id,
            isDraft: true,
            customerId: getCreateDoc().customerId,
          },
        });
        if (getCreateDoc().doctypeId === doctypeId) {
          setCreateDoc({ docId: createdDoc.data?.addNewDoc?.id });
        }
      }
      setEditorReady();
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createDraft();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalReady]);

  const onHideModal = useCallback(() => {
    if (edited) {
      setDialogModal(true);
    } else {
      closeModal();
    }
  }, [edited]);

  useEffect(() => {
    if (doc?.title && !edited) {
      setEdited(true);
    }
  }, [doc?.title, edited]);

  const focusToContent = useCallback(() => {
    const editor = containerRef.current?.querySelector('.ProseMirror') as HTMLElement | null;
    editor?.focus();
  }, [containerRef]);

  useHotkeyListener({
    callbacks: {
      [ShortcutCreateModal.Save]: !isFirstProductTourActive
        ? createModalCallbacks[ShortcutCreateModal.Save]
        : undefined,
      [ShortcutCreateModal.SaveAndOpen]: !isFirstProductTourActive
        ? () => createModalCallbacks[ShortcutCreateModal.SaveAndOpen](docContentJson)
        : undefined,
    },
    shortcuts: Object.values(ShortcutCreateModal),
    enabled: modalVisible,
  });

  const publishDocInCache = usePublishDocInCache();

  return (
    <PortalModalStyled
      hide={isFirstProductTourActive ? undefined : onHideModal}
      motionVariants={variantsContent}
      onAnimationComplete={setModalReady}
    >
      <Container ref={containerRef}>
        <Actions>
          {!isFirstProductTourActive && (
            <>
              <BigActionButton
                onClick={onHideModal}
                tooltipPlacement="top"
                tooltip={(
                  <Shortcuts
                    shortcuts={[{
                      label: 'Cancel',
                      keys: ['esc'],
                    }]}
                  />
            )}
              >
                <CloseIcon />
              </BigActionButton>
              <BigActionButton
                incredibleExceptionSize
                onClick={() => publish(true)}
                tooltipPlacement="top"
                tooltip={(
                  <Shortcuts
                    shortcuts={[{
                      label: 'Save & open full page',
                      keys: shortcuts[ShortcutCreateModal.SaveAndOpen],
                    }]}
                  />
            )}
              >
                <ExpandIcon />
              </BigActionButton>
            </>
          )}
        </Actions>
        <ScrollableContent>
          {!isFirstProductTourActive && (
            <CoverImageInputFile
              ref={coverInputRef}
              onCoverChanged={updateDocCover}
            />
          )}
          {doc?.cover?.url && !isFirstProductTourActive && (
            <DocPanelCoverStyled
              docId={doc.id}
              coverUrl={doc.cover.url}
              getDropzoneProps={getDropzonePropsCover}
              isDragActive={isDraggingCover}
              onUpdateCoverClicked={onOpenCoverInputFile}
              withNonConsistentDesign
            />
          )}
          <Content>
            <DocTop ref={shouldSetNextStepEl} className="doc-top">
              <Toolbar $isDisabled={isFirstProductTourActive}>
                {doc?.assignee || !isFeedback ? (
                  <DocAssignee
                    layer={Layer.DropdownModal}
                    size="L"
                    showLabel
                    assignee={doc?.assignee}
                    docId={doc?.id}
                    buttonIcon={<AddMemberOutlineIcon />}
                    isRemovable={false}
                    showWarnings={false}
                  />
                ) : (
                  <SkeletonAvatar />
                )}

                {doc?.doctype.customer ? (
                  <DocCustomer
                    doc={doc}
                    isRemovable={false}
                    showWarnings={false}
                  />
                ) : isFeedback && (
                  <SkeletonCustomer>
                    <SkeletonAvatar />
                    <SkeletonUsername />
                  </SkeletonCustomer>
                )}

                {doc && !doc?.cover?.url && !isFirstProductTourActive && (
                  <ActionButton size="L" onClick={onOpenCoverInputFile}>
                    <ImageIcon />
                    Add cover
                  </ActionButton>
                )}
              </Toolbar>
              <ContentEditableStyled
                className={CLASSNAME_USED_FOR_FOCUS}
                placeholder="Untitled"
                onChange={onTitleUpdated}
                focusEndOnMount={isFirstProductTourActive}
                onNext={() => containerRef.current?.click()}
                initialValue={isFirstProductTourActive
                  ? `My first feedback${me.firstName ? ` (${me.firstName})` : ''}`
                  : doc?.title}
                onEnter={focusToContent}
                isDisabled={!doc || isPreventDocTitleEdit}
              />
              {!isFirstProductTourActive && (
                <Attributes>
                  {!!doc && (
                    <DocPanelDocAttributes
                      doc={doc}
                      layer={Layer.DropdownModal}
                      isDocTypeReadOnly
                    />
                  )}
                  {!doc && defaultDoctype && (
                    <>
                      <Tag color="grey">
                        <DocTypeLabel>
                          <Emoji emoji={defaultDoctype?.emoji} size={12} />
                          {defaultDoctype.name}
                        </DocTypeLabel>
                      </Tag>
                      {defaultStatus?.id && (
                        <DocStatus
                          statusId={defaultStatus.id}
                          docTypeId={defaultDoctype.id}
                        />
                      )}
                    </>
                  )}
                </Attributes>
              )}
            </DocTop>
          </Content>
          <DocContainer>
            {showHelper && <HelpMeWriting />}
            {isEditorReady && !isEditorBlocked ? (
              <DocEditor
                draft
                defaultDoctypeId={defaultDoctypeId}
                docId={doc?.id}
                parentRef={containerRef}
                displayLoader={false}
                isChildrenCreationEnabled={false}
                applyTemplateOnDoctypeUpdate
                onUpdate={({
                  json, text,
                }) => {
                  editEditorContent(text ?? '');
                  docContentJson.current.contentJSON = json ?? '';
                }}
                hideHierarchy
                hideQuickActions={isFirstProductTourActive}
                autoFocus
              />
            ) : <EditorSkeleton />}
          </DocContainer>
        </ScrollableContent>
        <Buttons>
          {!isFirstProductTourActive && (
            <Button
              variant="secondary"
              size="M"
              onClick={() => setCreateDoc({
                modalVisible: false,
                docId: null,
                customerId: null,
                doctypeId: null,
              })}
            >
              Cancel
            </Button>
          )}
          <Button
            isLoading={loading || isContentUpdateLoading}
            onClick={() => publish().then(setOpenFeedbackStep)}
            size="M"
            tooltipPlacement="top"
            tooltip={!isFirstProductTourActive && (
              <Shortcuts
                shortcuts={[
                  {
                    label: 'Save',
                    keys: shortcuts[ShortcutCreateModal.Save],
                  },
                  {
                    label: 'Save & Open',
                    keys: shortcuts[ShortcutCreateModal.SaveAndOpen],
                  },
                ]}
              />
            )}
            disabled={!editorContent}
          >
            Create
          </Button>
        </Buttons>
      </Container>
      {dialogModal && (
        <DialogModal
          title="Are you sure you want to leave without saving?"
          confirmLabel="Leave"
          useHighMaskLayer
          hide={() => setDialogModal(false)}
          onConfirm={closeModal}
          variantCancelButton="secondary"
          variantSubmitButton="primary"
        />
      )}
    </PortalModalStyled>
  );

  async function publish(openDoc = false) {
    if (!doc) return null;

    setCreateDoc({ modalVisible: false });
    publishDocInCache(doc);

    await updateDocContent({
      variables: {
        docId: doc.id,
        contentJSON: docContentJson.current.contentJSON || JSON.stringify({ type: 'doc' }),
      },
    });

    if (doc.customer) {
      addCustomerDoc({
        doc: {
          ...doc,
          isDraft: false,
        },
      });
    }
    if (openDoc) {
      await createModalCallbacks[ShortcutCreateModal.SaveAndOpen](docContentJson);
      return doc;
    }
    await createModalCallbacks[ShortcutCreateModal.Save]();
    return doc;
  }
};
