import { typo, CheckboxInput, CodeInput, Spinner } from '@cycle-app/ui';
import styled from 'styled-components';

import { AsideDraws } from '../OnboardingLayout/AsideDraws';
import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const StyledButton = styled(NextButton)`
  margin-left: auto;
  margin-top: 30px;
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  margin-top: 4px;
`;

export const CheckboxContainer = styled.div`
  ${typo.body400}
  display: flex;
  gap: 12px;
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 30px;
`;

export const Anchor = styled.a`
  color: ${p => p.theme.colors.text.primary};
  &:hover {
    text-decoration: underline;
  }
`;

export const Form = styled.form`
  margin-top: 24px;
`;

export const StyledAside = styled(AsideDraws)`
  margin-top: 50px;
  width: 100%;
  display: block;
  object-fit: cover;
`;

export const CodeInputContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

export const StyledCodeInput = styled(CodeInput)`
  input {
    ${typo.headerMedium};
  }
`;

export const ConfirmFooter = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const ConfirmError = styled.div`
  color: ${p => p.theme.colors.text.red};
  margin-top: 24px;
`;

export const VerifySpinner = styled(Spinner)`
  width: 12px;
  height: 12px;
`;

export const NospaceButton = styled(NextButton)`
  --color: ${p => p.theme.userColors.main};
  &:hover, &:focus, &:active {
    --color: ${p => p.theme.userColors.main};
  }
  width: auto;
  display: inline-flex;
  padding: 0;
  align-items: center;
  gap: 2px;
`;
