import { NavigationItemProps } from '@cycle-app/ui';

import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useMobileSidebarActions } from 'src/hooks/useMobileSidebarActions';

import { Label } from './SidebarFooterItem.styles';

export const Item = ({
  label, onClick, ...props
}: NavigationItemProps) => {
  const isOnboarding = useIsOnboarding();
  const { close } = useMobileSidebarActions();
  return (
    <CollapsableNavigationItem
      isSidebarColor
      asPlaceholder={isOnboarding}
      placeholderScale={[0.5, 0.3]}
      label={<Label>{label}</Label>}
      onClick={(e) => {
        onClick?.(e);
        close();
      }}
      {...props}
    />
  );
};
