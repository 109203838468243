export enum Layer {
  Sidebar = 'sidebar',
  DocPanel = 'doc-panel',
  Modal = 'modal',

  Dropdown = 'dropdown',
  DropdownZ1 = 'dropdown-z1',
  DropdownZ2 = 'dropdown-z2',
  DropdownBoardConfig = 'dropdown-board-config',
  DropdownCustomProperty = 'dropdown-custom-property',
  DropdownModal = 'dropdown-modal',
  DropdownModalZ1 = 'dropdown-modal-z1',
  DropdownModalZ2 = 'dropdown-modal-z2',

  ModalZ2 = 'modal-z2',
  DropdownModalZ3 = 'dropdown-modal-z3',

  ModalZ3 = 'modal-z3',
  DropdownModalZ4 = 'dropdown-modal-z4',

  DialogModal = 'dialog-modal',

  Selection = 'selection',
  Toaster = 'toaster',
  Commandbar = 'commandbar',
  Loader = 'loader',

  // Masks
  MaskDocPanel = 'mask-doc-panel',
  MaskModal = 'mask-modal',
  MaskModalOverDropdown = 'mask-modal-over-dropdown',
  MaskCommandbar = 'mask-commandbar',

  LoaderFullpage = 'loader-fullpage',
  ProductTour = 'product-tour',
}
