import { WIDTH_COLLAPSED_SIDEBAR } from '@cycle-app/ui';
import { FC, useEffect } from 'react';

import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import Portal from 'src/components/Portal/Portal';
import { Events } from 'src/constants/analytics.constants';
import { useDocSubscription } from 'src/hooks/api/useDocSubscription';
import { useDocsPreview } from 'src/hooks/doc/useDocsPreview';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { setAnimation } from 'src/reactives/animation.reactive';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { useGetSidebar } from 'src/reactives/sidebar.reactive';
import { PortalId } from 'src/types/portal.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import DocPanelBoardPage from './DocPanelBoardPage/DocPanelBoardPage';
import DocPanelFullPage from './DocPanelFullPage/DocPanelFullPage';

interface Props {
  expanded?: boolean;
}

const DocPanel: FC<Props> = ({ expanded = false }) => {
  const {
    displayed,
    width,
    collapsed,
  } = useGetSidebar();
  const {
    doc,
    isLoading: isDocLoading,
    updateDocCover,
    coverInputRef,
    ...commonProps
  } = useDocPanelProps();

  const docId = doc?.id ?? '';
  const widthSidebar = collapsed ? WIDTH_COLLAPSED_SIDEBAR : width;

  useDocSubscription(docId);
  useDocsPreview();

  useEffect(() => {
    setCommandbar({ docId: doc?.id });
    return () => setCommandbar({ docId: null });
  }, [doc]);

  useEffect(() => {
    setAnimation({ enabled: false });
    return () => setAnimation({ enabled: true });
  }, []);

  useEffect(() => {
    trackAnalytics(Events.DocViewed);
  }, []);

  const docPanelBaseProps = {
    doc,
    isDocLoading,
    widthSidebar: displayed ? widthSidebar : 0,
    ...commonProps,
  };

  if (expanded) {
    return (
      <>
        <DocPanelFullPage
          {...docPanelBaseProps}
        />
        <CoverImageInputFile
          ref={coverInputRef}
          onCoverChanged={updateDocCover}
        />
      </>
    );
  }

  return (
    <Portal portalId={PortalId.DocPanel}>
      <DocPanelBoardPage {...docPanelBaseProps} />
      <CoverImageInputFile
        ref={coverInputRef}
        onCoverChanged={updateDocCover}
      />
    </Portal>
  );
};

export default DocPanel;
