import { DoctypeType } from '@cycle-app/graphql-codegen';
import { ViewCard, Tooltip } from '@cycle-app/ui';
import { CheckIcon, CustomerIcon } from '@cycle-app/ui/icons';
import { memo, useCallback } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import DocAttributes from 'src/components/DocAttributes/DocAttributes';
import DocChildren from 'src/components/DocChildren/DocChildren';
import DocComments from 'src/components/DocComments/DocComments';
import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import DocOptions from 'src/components/DocOptions/DocOptions';
import DocParentDropdown from 'src/components/DocParentDropdown/DocParentDropdown';
import DocPrimaryAttributes from 'src/components/DocPrimaryAttributes/DocPrimaryAttributes';
import { DocProcessButton } from 'src/components/DocProcessButton';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useDocContext } from 'src/contexts/docContext';
import { useAddDocToaster } from 'src/hooks/doc/useAddDocToaster';
import { useParentPage } from 'src/hooks/usePageId';
import { setCompanyProfileModal, setCustomerProfileModal } from 'src/reactives';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useDocsSelectedLength, useIsDocSelected } from 'src/reactives/selection.reactive';
import { ViewType } from 'src/types/viewType.types';
import { isBuiltIn } from 'src/utils/docType.util';

import {
  BulkContainer, TitleContainer, TitleContent, Title, Description, Viewers, PlaceholderMulti,
} from './DocItem.styles';
import { DocItemProps } from './DocItem.types';
import { DocItemCheckbox } from './DocItemCheckbox';
import { DocItemViewer } from './DocItemViewer';

const LIMIT_DISPLAY_VIEWERS_LENGTH = 5;

export type DocItemCardProps = Omit<DocItemProps, 'isGroupInBoardView' | 'isLazy' | 'docIndex'> & {
  isFocused?: boolean;
  isReadOnly?: boolean;
  onAddCoverClicked?: VoidFunction;
};

export const DocItemCard = memo(({
  isDragging = false,
  asPlaceholder = false,
  direction,
  viewType,
  showAssignee,
  showCreator,
  showCreatedAt,
  showCover,
  showDocParent,
  showDocId,
  showDocType,
  showSource,
  showChildren,
  showCustomer,
  showComments,
  showProperties = true,
  showStatus,
  description,
  isSelectable = false,
  isFocused = false,
  isReadOnly = true,
  onAddCoverClicked,
}: DocItemCardProps) => {
  const docId = useDocContext(ctx => ctx.id);
  const docTitle = useDocContext(ctx => ctx.title);
  const docCoverUrl = useDocContext(ctx => ctx.cover?.url);
  const isCreating = useDocContext(ctx => ctx._creating);

  const isSelected = useIsDocSelected(docId);
  const selectedLength = useDocsSelectedLength();
  const parentPage = useParentPage();

  return (
    <div id={docId}>
      {isDragging && selectedLength > 1 && !isReadOnly && (
        <PlaceholderMulti direction={direction} />
      )}

      <DocItemViewers />

      <ViewCard
        docParent={showDocParent && <DocItemParent viewType={viewType} />}
        isCreating={!!isCreating}
        isDragging={isDragging}
        direction={direction}
        isHover={isFocused}
        isSelected={isSelected}
        asPlaceholder={asPlaceholder}
        viewType={viewType}
        coverUrl={showCover ? docCoverUrl : undefined}
        preTitle={(showChildren || showDocId || showDocType || showStatus) && viewType === ViewType.List && (
          <DocItemPreTitle
            viewType={viewType}
            showChildren={showChildren}
            showDocId={showDocId}
            showDocType={showDocType}
            showStatus={showStatus}
          />
        )}
        title={description != null ? (
          <TitleContainer>
            <TitleContent>
              <Title>{docTitle}</Title>
              <Description>{description}</Description>
            </TitleContent>
          </TitleContainer>
        ) : docTitle}
        showActions={isFocused && !isReadOnly}
        selectedLength={selectedLength}
        actions={!isReadOnly && (
          <DocItemOptions
            onAddCoverClicked={onAddCoverClicked}
            viewType={viewType}
          />
        )}
        preToolbar={!isReadOnly && (
          <DocItemAttributes
            viewType={viewType}
            isDragging={isDragging}
            showCreator={showCreator}
            showCreatedAt={showCreatedAt}
            showDocId={showDocId}
            showDocType={showDocType}
            showSource={showSource}
            showProperties={showProperties}
            showStatus={showStatus}
          />
        )}
        sectionStart={<div />}
        sectionEnd={(
          ((showChildren && viewType === ViewType.Kanban) ||
            showComments ||
            showAssignee ||
            showCustomer ||
            parentPage === 'inbox')) &&
          !isReadOnly &&
          (
            <DocItemSectionEnd
              showChildren={showChildren}
              viewType={viewType}
              showComments={showComments}
              showAssignee={showAssignee}
              showCustomer={showCustomer}
            />
          )}
      >
        {isSelectable && !isDragging && !asPlaceholder && !isReadOnly && (
          <BulkContainer>
            <Tooltip
              content="Select"
              placement="top"
              withPortal
            >
              <DocItemCheckbox
                docId={docId}
                viewType={viewType}
                isFocused={isFocused}
              >
                <CheckIcon />
              </DocItemCheckbox>
            </Tooltip>
          </BulkContainer>
        )}
      </ViewCard>
    </div>
  );
});

const DocItemPreTitle = memo(({
  showChildren, showDocType, showDocId, viewType, showStatus,
}: DocItemCardProps) => {
  const doc = useDocContext();
  const isMobile = useIsMobile();
  if (isMobile) return null;
  return (
    <>
      {showChildren && <DocChildren doc={doc} />}
      <DocPrimaryAttributes
        doc={doc}
        viewType={viewType}
        showDocId={showDocId}
        showDocType={showDocType}
        isDocTypeReadOnly={isBuiltIn(doc.doctype)}
        showStatus={showStatus}
      />
    </>
  );
});

const DocItemViewers = memo(() => {
  const viewers = useDocContext(ctx => ctx._viewers);
  if (!viewers) return null;
  return (
    <Viewers>
      {viewers.slice(0, LIMIT_DISPLAY_VIEWERS_LENGTH).map(viewerId => (
        <DocItemViewer key={viewerId} userId={viewerId} />
      ))}
    </Viewers>
  );
});

const DocItemParent = memo(({ viewType }: { viewType: ViewType }) => {
  const doc = useDocContext();
  return (
    <DocParentDropdown
      showParentTitle={false}
      doc={doc}
      minimal
      placeholder="Add"
      absoluteEdit={viewType === ViewType.List}
    />
  );
});

const DocItemOptions = memo(({
  viewType, onAddCoverClicked,
}: { viewType: ViewType; onAddCoverClicked?: VoidFunction }) => {
  const doc = useDocContext();
  const docCoverUrl = useDocContext(ctx => ctx.cover?.url);
  const docId = useDocContext(ctx => ctx.id);
  const isSelected = useIsDocSelected(docId);
  const addDocToaster = useAddDocToaster();

  const onSelectProperty = useCallback((isNotCompatible: boolean) => {
    if (isNotCompatible) {
      addDocToaster({
        docId,
        title: 'Property updated',
        message: tag => (
          <span>
            {tag ?? 'Your doc'}
            {' '}
            was successfully updated and left the view
          </span>
        ),
      });
    }
  }, [addDocToaster, docId]);

  return (
    <DocOptions
      doc={doc}
      dropdownPlacement={viewType === ViewType.List ? 'bottom-end' : 'bottom-start'}
      onAddCoverClicked={onAddCoverClicked}
      viewType={viewType}
      secondaryBg={viewType === ViewType.List || !docCoverUrl}
      disabled={!!isSelected}
      onSelectProperty={onSelectProperty}
    />
  );
});

const ProcessButton = memo(({ docId }: { docId: string }) => {
  const docStatusId = useDocContext(ctx => ctx.status?.id);
  const docStatusCategory = useDocContext(ctx => ctx.status?.category);
  const parentPage = useParentPage();
  if (parentPage !== 'inbox' || !docStatusId || !docStatusCategory) return null;
  return (
    <DocProcessButton
      docId={docId}
      docStatusCategory={docStatusCategory}
      parent="doc-item"
    />
  );
});

const DocItemAttributes = memo(({
  viewType, isDragging, showCreator, showCreatedAt, showDocId, showDocType, showSource, showProperties, showStatus,
}: DocItemCardProps) => {
  const doc = useDocContext();
  const displayedPropertiesIds = useBoardConfig(ctx => ctx.displayedPropertiesIds);
  return (
    <DocAttributes
      doc={doc}
      displayedPropertiesIds={showProperties ? displayedPropertiesIds : []}
      viewType={viewType}
      isDragging={isDragging}
      showCreator={showCreator}
      showCreatedAt={showCreatedAt}
      showDocId={showDocId}
      showDocType={showDocType}
      showSource={showSource}
      showStatus={showStatus}
      displayPrimaryAttributes={viewType === ViewType.Kanban}
    />
  );
});

const DocItemSectionEnd = memo(({
  showAssignee,
  showChildren,
  showComments,
  showCustomer,
  viewType,
}: DocItemCardProps) => {
  const doc = useDocContext();
  const addDocToaster = useAddDocToaster();
  const docId = useDocContext(ctx => ctx.id);
  const docDocSource = useDocContext(ctx => ctx.docSource);

  const onSelectProperty = useCallback((propertyName: string, isNotCompatible: boolean) => {
    if (isNotCompatible) {
      addDocToaster({
        docId,
        title: `${propertyName} updated`,
        message: tag => (
          <span>
            {tag ?? 'Your doc'}
            {' '}
            was successfully updated and left the view
          </span>
        ),
      });
    }
  }, [docId, addDocToaster]);
  const isAssigneeRemovable = doc?.doctype.type === DoctypeType.Custom;
  const isCustomerRemovable = doc?.doctype.type === DoctypeType.Custom;
  const isAssigneeEditable = !docDocSource;
  const isCustomerEditable = !docDocSource;
  return (
    <>
      {showChildren && viewType === ViewType.Kanban && (
        <DocChildren doc={doc} />
      )}
      {showComments && (
        <DocComments
          docId={docId}
          nbComments={doc.commentsCount}
        />
      )}
      {showAssignee && (
        <DocAssignee
          docId={docId}
          assignee={doc.assignee}
          onSelect={user => onSelectProperty('Assignee', !user?._compatibleWithBoardConfig)}
          isDisabled={!isAssigneeEditable}
          isRemovable={isAssigneeRemovable}
        />
      )}
      {showCustomer && (
        <DocCustomer
          doc={doc}
          isCompact
          placeholderIcon={<CustomerIcon />}
          onSelect={isNotCompatible => onSelectProperty('Customer', isNotCompatible)}
          onCustomerModalOpen={customerId => setCustomerProfileModal({
            isOpen: true,
            customerId,
          })}
          onCompanyModalOpen={companyId => setCompanyProfileModal({
            isOpen: true,
            companyId,
          })}
          isDisabled={!isCustomerEditable}
          isRemovable={isCustomerRemovable}
        />
      )}
      <ProcessButton docId={docId} />
    </>
  );
});
