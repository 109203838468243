import { InfiniteScroll } from '@cycle-app/ui';
import { WheelIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useProducts } from 'src/hooks/api/useProducts';
import { useNavigate } from 'src/hooks/useNavigate';
import { setLastView } from 'src/reactives/lastView.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetSidebar } from 'src/reactives/sidebar.reactive';
import { Shortcut } from 'src/types/shortcuts.types';

import ProductItem from './ProductItem/ProductItem';
import {
  Container,
  Footer,
  ProductMenuShortcut,
  SettingsLink,
  List,
} from './ProductsMenu.styles';

interface Props {
  hide: () => void;
}

const ProductsMenu: FC<Props> = ({ hide }) => {
  const {
    navigate, getUrl,
  } = useNavigate();
  const currentProduct = useProductBase();
  const {
    products, loading, hasNextPage, fetchNextPage,
  } = useProducts();
  const { width: sidebarWidth } = useGetSidebar();
  const isMobile = useIsMobile();

  const optionsValues = useMemo(() => [
    ...products.map(product => product.slug),
    'settings',
  ], [products]);

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues,
    onSelect: onItemSelected,
  });

  const { canReadSettings } = useGetPermission();

  return (
    <Container $width={sidebarWidth + 10} {...listProps}>
      <List>
        <InfiniteScroll
          isLoading={loading}
          hasMoreData={hasNextPage}
          loadMore={fetchNextPage}
        >
          {products.map(product => (
            <ProductItem
              key={product.id}
              product={product}
              selected={product.slug === selected}
              isActive={product.id === currentProduct?.id}
              {...itemProps(product.slug)}
            />
          ))}
        </InfiniteScroll>
      </List>

      {!isMobile && canReadSettings && (
        <Footer>
          <SettingsLink
            to={getUrl(PageId.Settings)}
            {...itemProps('settings')}
            selected={selected === 'settings'}
            onClick={() => setLastView({ settingsFromUrl: window.location.pathname })}
          >
            <WheelIcon />
            <span>Workspace settings</span>
            <ProductMenuShortcut keys={shortcuts[Shortcut.GoToSettings]} />
          </SettingsLink>
        </Footer>
      )}
    </Container>
  );

  function onItemSelected(itemId: string | null) {
    if (!itemId) return;

    hide();

    if (itemId === 'settings') {
      navigate(PageId.Settings);
      return;
    }

    navigate(PageId.Main, { productSlug: itemId });
  }
};

export default ProductsMenu;
