// @ts-nocheck Stolen from https://github.com/ueberdosis/tiptap/issues/214#issuecomment-964557956
/* eslint-disable @typescript-eslint/no-explicit-any */

// @todo type this mess

import { SuggestionOptions } from '@tiptap/suggestion';
import { PluginKey, Plugin } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';

import { COMMANDS } from 'src/constants/editor.constants';

import { findSuggestionMatch } from './findSuggestionMatch';

type SuggestionParams = SuggestionOptions & {
  pluginKey?: PluginKey;
  prefixSpace?: boolean;
};

export const SuggestionPluginKey = new PluginKey('suggestion');
export const suggestionPlugin = ({
  pluginKey = SuggestionPluginKey,
  editor,
  char = COMMANDS.USER_MENTION,
  allowSpaces = false,
  prefixSpace = true,
  startOfLine = false,
  decorationTag = 'span',
  decorationClass = 'suggestion',
  command,
  items,
  render,
  allow,
}: SuggestionParams) => {
  const renderer = render?.();

  const exitSuggestion = (view: any, pluginKeyName: string) => {
    const state = {
      active: false,
      key: null,
      range: {},
      query: null,
      text: null,
      composing: false,
    };
    view.dispatch(view.state.tr.setMeta(pluginKeyName, state));
  };

  return new Plugin({
    key: pluginKey,
    view() {
      return {
        update: async (view: any, prevState: any) => {
          const prev: any = this.key?.getState(prevState);
          const next: any = this.key?.getState(view.state);
          // See how the state changed
          const moved =
            prev.active &&
            next.active &&
            !!prev.range.from &&
            prev.range.from !== next.range.from;
          const started = !prev.active && next.active;

          const stopped = prev.active && !next.active;
          const changed = !started && !stopped && prev.query !== next.query;
          const handleStart = started || moved;
          const handleChange = changed && !moved;
          const handleExit = stopped || moved;

          // Cancel when suggestion isn't active
          if (!handleStart && !handleChange && !handleExit) {
            return;
          }

          const state = handleExit && !handleStart ? prev : next;
          const decorationNode = document.querySelector(
            `[data-decoration-id="${state.decorationId}"]`,
          );
          const props: any = {
            editor,
            pluginKey,
            range: state.range,
            query: state.query,
            text: state.text,
            items:
              handleChange || handleStart
                ? await items?.({
                  editor,
                  query: state.query,
                })
                : [],
            command: (commandProps: any) => {
              if (!commandProps) {
                exitSuggestion(props.editor.view, props.pluginKey.key);
                return;
              }
              command?.({
                editor,
                range: state.range,
                props: commandProps,
              });
            },
            decorationNode,
            // virtual node for popper.js or tippy.js
            // this can be used for building popups without a DOM node
            clientRect: decorationNode
              ? () => {
                // because of `items` can be asynchrounous we’ll search for the current decoration node
                const decorationId = this.key?.getState(editor.state)?.decorationId;
                const currentDecorationNode = document.querySelector(
                  `[data-decoration-id="${decorationId}"]`,
                );

                return currentDecorationNode?.getBoundingClientRect() ?? null;
              }
              : null,
          };
          if (handleExit) {
            exitSuggestion(view, pluginKey.key);
            renderer?.onExit?.(props);
          }
          if (handleChange) {
            renderer?.onUpdate?.(props);
          }
          if (handleStart) {
            renderer?.onStart?.(props);
          }
        },
      };
    },
    state: {
      // Initialize the plugin's internal state.
      init() {
        return {
          active: false,
          key: null,
          range: {},
          query: null,
          text: null,
          composing: false,
        };
      },
      // Apply changes to the plugin state from a view transaction.
      apply(transaction, prev, oldState, newState) {
        let next;

        const lastChar = newState.selection.$head.parent.textContent.slice(-1);
        const hasSuggestionChar = [COMMANDS.DOC_MENTION, COMMANDS.USER_MENTION].includes(lastChar);

        if (transaction.doc.textContent !== char && transaction.meta[pluginKey.key]) {
          next = {
            ...transaction.meta[pluginKey.key],
          };
        } else if (hasSuggestionChar) {
          // To handle suggestion char being inserted programmatically by the editor
          next = {
            ...prev,
            active: true,
            key: pluginKey.key,
          };
        } else {
          next = {
            ...prev,
          };
        }

        // when clicking in prev.text === null and prev.range emptyish
        const { composing } = editor.view;
        const { selection } = transaction;
        const {
          empty, from,
        } = selection;
        next.composing = composing;

        // Don't advance with suggesiton if not active and not the open character.
        if (!next.active && next.key !== char) {
          return next;
        }
        if (next.active && next.key === char) {
          next.key = null;
          return next;
        }

        // We can only be suggesting if there is no selection
        // or a composition is active (see: https://github.com/ueberdosis/tiptap/issues/1449)
        if (empty || editor.view.composing) {
          // Reset active state if we just left the previous suggestion range
          if (
            (from < prev.range.from || from > prev.range.to) &&
            !composing &&
            !prev.composing
          ) {
            next.active = false;
          }

          // Try to match against where our cursor currently is
          const match = findSuggestionMatch({
            char,
            allowSpaces,
            prefixSpace,
            startOfLine,
            $position: selection.$from,
          });
          const decorationId = `id_${Math.floor(Math.random() * 0xffffffff)}`;

          // If we found a match, update the current state to show it
          if (match && allow({
            editor,
            range: match.range,
          })) {
            next.active = true;
            next.decorationId = prev.decorationId
              ? prev.decorationId
              : decorationId;
            next.range = match.range;
            next.query = match.query;
            next.text = match.text;
          } else {
            next.active = false;
          }
        } else {
          next.active = false;
        }
        // Make sure to empty the range if suggestion is inactive
        if (!next.active) {
          next.decorationId = null;
          next.range = {};
          next.query = null;
          next.text = null;
        }
        return next;
      },
    },
    props: {
      // Call the keydown hook if suggestion is active.
      handleKeyDown(view, event) {
        const { tr } = view.state;
        const state = this.getState(view.state);

        // Set state and handle start
        if (!state.active && event.key === char) {
          const updatedState = { ...state };
          updatedState.active = true;
          updatedState.key = event.key;
          view.dispatch(tr.setMeta(pluginKey.key, updatedState));
          return undefined;
        }
        if (!state.active) {
          // Ignore everything else if it is inactive.
          return false;
        }
        // Reset state on Escape and handle exit.
        if (event.key === 'Escape') {
          event.stopPropagation();
          exitSuggestion(view, pluginKey.key);
          return undefined;
        }

        return (
          renderer?.onKeyDown?.({
            view,
            event,
            range: state.range,
          }) || false
        );
      },
      // Setup decorator on the currently active suggestion.
      decorations(state) {
        const {
          active, range, decorationId,
        } = this.getState(state);
        if (!active) {
          return null;
        }
        return DecorationSet.create(state.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: decorationTag,
            class: decorationClass,
            'data-decoration-id': decorationId,
          }),
        ]);
      },
    },
  });
};
