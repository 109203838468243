import { typo, CustomerAvatar, MockChrome, CycleLogo } from '@cycle-app/ui';
import { ChromeIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const Main = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
`;

export const MainExtension = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;

export const MainExtensionName = styled.span`
  ${typo.body500}
`;

export const StyledChromeIcon = styled(ChromeIcon)`
  height: 26px;
  width: 26px;
`;

export const ChromeExtensionLogo = styled.div`
  position: absolute;
  right: 93px;
  top: 61px;
  background-color: ${p => p.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
`;

export const StyledChromeTabLogo = styled(CycleLogo)`
  height: 10px;
  width: 10px;
`;

export const StyledCustomerAvatar = styled(CustomerAvatar)`
  margin-right: 12px;
  pointer-events: none;
`;

export const StyledMockChrome = styled(MockChrome)`
  width: 420px;
  position: absolute;
  right: 20px;
  top: 77px;
  z-index: 1;
  padding-bottom: 0;
`;

export const Cover = styled.div`
  position: relative;
  width: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  height: 290px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0;
    bottom: -20px;
    height: 90%;
    z-index: 1;
    ${p => css`
      background: linear-gradient(180deg, rgba(49, 49, 49, 0) 0%, ${p.theme.colors.onboarding.background.body} 90%);
    `}
  }
`;

export const CoverImgContainer = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    bottom: -10px;
    width: 20%;
    height: 100%;
    z-index: 1;
    ${p => css`
      background: linear-gradient(270deg, rgba(49, 49, 49, 0) 0%, ${p.theme.colors.onboarding.background.body} 80%);
    `}
  }
`;
