import { Editor } from '@tiptap/react';
import { RefObject, useEffect, useRef, useCallback } from 'react';

import { lastNodeIsParagraph } from 'src/utils/editor/editor.utils';

interface Params {
  isEnabled?: boolean;
  editor: Editor | null;
  parentRef?: RefObject<HTMLDivElement>;
  position?: 'start' | 'end';
  timeout?: number;
}
export function useForcedFocus({
  editor,
  isEnabled = true,
  parentRef,
  position = 'end',
  timeout = 400,
}: Params) {
  const mounted = useRef(false);

  const focusEnd = useCallback((e: MouseEvent) => {
    // @todo Type e.target
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.target.closest('.content-editor') || e.target.closest('.doc-title') || !editor) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.target.closest('.doc-top')) {
      editor.commands.focus('start');
      return;
    }

    if (lastNodeIsParagraph(editor)) {
      editor.commands.focus('end');
    } else {
      editor.chain().insertContent({ type: 'paragraph' }).focus('end').run();
    }
  }, [editor]);

  useEffect(() => {
    const parentElement = parentRef?.current;

    if (isEnabled) {
      if (editor && !mounted.current) {
        // Dirty timeout to fix autofocus
        setTimeout(() => {
          editor.commands.focus(position);
        }, timeout);

        mounted.current = true;
      }
      parentElement?.addEventListener('click', focusEnd);
    }

    return () => {
      parentElement?.removeEventListener('click', focusEnd);
    };
  }, [focusEnd, parentRef, editor, isEnabled]);
}
