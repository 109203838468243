/* eslint-disable jsx-a11y/label-has-associated-control */
import { BoardWithDraftConfigFragment } from '@cycle-app/graphql-codegen';
import { Spinner, Button, EmojiData } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import { InboxBoardConfigFields } from 'src/components/InboxBoardConfigFields';
import { PageId } from 'src/constants/routing.constant';
import { usePublishBoardConfigMutation } from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { useNavigate } from 'src/hooks/useNavigate';
import { DEFAULT_EMOJI } from 'src/utils/emoji.util';
import { getBoardSlug } from 'src/utils/slug.util';

import {
  Modal, ContentSkeleton, ModalContainer, InputField, Form, FormRow, EmojiInputStyled,
} from './InboxCreateView.styles';

type FormData = {
  name: string;
  description: string;
  emoji: string;
};

export type InboxCreateViewModalProps = {
  hide: VoidFunction;
  draftBoard: BoardWithDraftConfigFragment | null;
};

export const InboxCreateViewModal: FC<InboxCreateViewModalProps> = ({
  hide, draftBoard,
}) => {
  const { data } = useDraftBoardConfig(draftBoard?.id);
  const draftBoardId = data?.node.id;
  const draftBoardConfig = data?.node.draftBoardConfig;

  const { navigate } = useNavigate();

  const { removeBoard } = useBoardMutations();
  const [publishBoardConfig, { loading: isPublishLoading }] = usePublishBoardConfigMutation(draftBoardConfig?.id ?? '');

  const {
    loading: isUpdateLoading,
    publishBoard,
    updateBoard,
  } = useBoardMutations();

  const {
    control,
    formState,
    handleSubmit,
    register,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      emoji: DEFAULT_EMOJI,
    },
  });

  const isLoading = isUpdateLoading || isPublishLoading;
  const hasError = !!Object.keys(formState.errors).length;

  const onCancel = async () => {
    if (isLoading) return;
    hide();
    if (!draftBoard) return;
    await removeBoard(draftBoard?.id);
  };

  return (
    <Modal hide={onCancel}>
      <ModalContainer>
        <Header>
          <Title>Add view</Title>
          <CloseButtonStyled size="L" onClick={onCancel}>
            <CloseIcon />
          </CloseButtonStyled>
        </Header>

        {draftBoardId && draftBoardConfig
          ? (
            <Form
              onReset={async () => {
                if (isLoading) return;
                await onCancel();
              }}
              onSubmit={handleSubmit(async (formData) => {
                if (isLoading) return;
                const [updateResult, publishResult] = await Promise.all([
                  updateBoard({
                    boardId: draftBoardId,
                    ...formData,
                  }),
                  publishBoard({ boardId: draftBoardId }),
                ]);
                if (!updateResult.data?.updateBoard || !publishResult.data?.publishBoard) return;
                // Publish config works only on published board.
                const publishBoardConfigResult = await publishBoardConfig();
                if (!publishBoardConfigResult.data?.publishBoardConfigV2) return;
                hide();
                navigate(PageId.InboxView, { boardSlug: getBoardSlug(updateResult.data.updateBoard) });
              })}
            >
              <FormRow>
                <Controller
                  name="emoji"
                  control={control}
                  render={({
                    field: {
                      value,
                      onChange,
                    },
                  }) => (
                    <div>
                      <label>Icon</label>
                      <EmojiInputStyled
                        emoji={value}
                        onSelect={({ id }: EmojiData) => onChange(id)}
                      />
                    </div>
                  )}
                />
                <InputField
                  id="boardEdit-name"
                  label="View name"
                  placeholder="Your view name"
                  autoFocus
                  {...register('name', {
                    required: 'You must have at least one character.',
                  })}
                  error={formState.errors.name?.message}
                />
              </FormRow>

              <div>
                <label>Description</label>
                <InputField
                  id="boardEdit-description"
                  placeholder="Your view description"
                  {...register('description')}
                />
              </div>

              <InboxBoardConfigFields boardId={draftBoardId} />
              <Actions>
                <Button size="M" type="reset" variant="secondary" disabled={isLoading}>
                  Cancel
                </Button>
                <Button size="M" type="submit" disabled={hasError} isLoading={isLoading}>
                  Save
                </Button>
              </Actions>
            </Form>
          )
          : (
            <ContentSkeleton>
              <Spinner />
            </ContentSkeleton>
          )}
      </ModalContainer>
    </Modal>
  );
};
