import { typo, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from '../PortalModal/PortalModal';
import { variantsContent } from './DialogModal.motion';

export const PortalModalStyled = styled(PortalModal).attrs(p => ({
  motionVariants: variantsContent,
  ...p,
}))<{ width?: string }>`
  min-width: 450px;
  width: ${p => p.width ?? '450px'};
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const Title = styled.h2`
  ${typo.headerSmall};
  align-items: center;
  display: flex;
  font-weight: 500;
  gap: 10px;
`;

export const CloseButtonStyled = styled(ActionButton)`
  height: 24px;
  width: 24px;
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.modal.icon.warning.bg};
  border-radius: 50%;
  color: ${p => p.theme.colors.modal.icon.warning.color};
  display: flex;
  height: 28px;
  justify-content: center;
  flex-basis: 28px;
  min-width: 28px;

  svg {
    height: 14px;
    transform: translate(0, -1px);
    width: 14px;
  }
`;

export const Body = styled.div`
  margin-top: 16px;
  line-height: 18.5px;
  ${typo.body400}
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

export const ConfirmMessageContainer = styled.div`
  margin-top: 3px;
  padding-left: 8px;
`;

export const Actions = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-left: auto;
`;
