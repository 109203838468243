import { FC, Fragment } from 'react';

import { Bar, Step, Steps } from './Stepper.styles';

export interface StepperProps {
  className?: string;
  activeStep: number;
  onStepClick?: (step: number) => void;
  steps: Array<0 | 1>; // 1 if clickable, 0 if not
}

export const Stepper: FC<StepperProps> = ({
  className, steps, activeStep, onStepClick,
}) => {
  // Create a suite of numbers based on the array length
  const stepNumbers = Array.from(Array(steps.length).keys());

  return (
    <Steps className={className}>
      {stepNumbers.map((step) => (
        <Fragment key={step}>
          {step > 0 && <Bar key={`bar-${step}`} />}
          <Step
            key={`step-${step}`}
            onClick={() => {
              if (steps[step] === 0) return;
              onStepClick?.(step);
            }}
            $isActive={activeStep === step}
            $isClickable={steps[step] === 1}
          >
            {step + 1}
          </Step>
        </Fragment>
      ))}
    </Steps>
  );
};
