import { CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';

import { ToggleDropdown } from 'src/components/DropdownLayer/ToggleDropdown';

import { FieldButton } from './Form.styles';
import { useCustomField } from './useCustomField';

export type CustomFieldSelectProps = {
  definition: CustomAttributeDefinitionFragment;
};

export const CustomFieldSelect = ({ definition }: CustomFieldSelectProps) => {
  const field = useCustomField(definition.id);

  if (definition.__typename !== 'AttributeSingleSelectDefinition') return null;

  const options = nodeToArray(definition.values).map(item => ({
    value: item.id,
    label: item.value,
  }));

  const selected = options.find(option => option.value === field.value);

  return (
    <ToggleDropdown
      placement="bottom-start"
      button={props => (
        <FieldButton {...props}>
          {field.value ? selected?.label : 'Choose from list'}
        </FieldButton>
      )}
      content={props => (
        <SelectPanel
          options={options}
          selectedValue={field.value}
          onClearValue={() => {
            field.onChange(undefined);
            props.hide();
          }}
          onOptionChange={(option) => {
            field.onChange(option.value);
            props.hide();
          }}
        />
      )}
    />
  );
};
