import { MouseEventHandler, useRef, ReactNode, RefObject } from 'react';

import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AnyShortcut } from 'src/types/shortcuts.types';

import { DropdownLayer, DropdownLayerProps } from './DropdownLayer';

export const useToggleDropdown = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isDropdownVisible, {
    setTrueCallback: openDropdown,
    setFalseCallback: closeDropdown,
  }] = useOptimizedBooleanState(false);

  const hideDropdown = () => {
    buttonRef.current?.focus();
    closeDropdown();
  };

  return {
    buttonRef,
    isDropdownVisible,
    openDropdown,
    hideDropdown,
  };
};

export type ToggleDropdownProps = DropdownLayerProps & {
  button: ReactNode | ((props: {
    ref: RefObject<HTMLButtonElement>;
    onClick: MouseEventHandler;
    'data-active': boolean;
  }) => ReactNode);
  shortcut?: AnyShortcut;
  content: ReactNode | ((props: {
    hide: VoidFunction;
  }) => ReactNode);
};

export const ToggleDropdown = ({
  button, content, shortcut, ...props
}: ToggleDropdownProps) => {
  const {
    buttonRef: ref,
    isDropdownVisible,
    openDropdown,
    hideDropdown: hide,
  } = useToggleDropdown();

  useHotkeyListener({
    enabled: !!shortcut,
    callbacks: shortcut ? { [shortcut]: openDropdown } : {},
    shortcuts: shortcut ? [shortcut] : [],
  });

  return (
    <DropdownLayer
      {...props}
      visible={isDropdownVisible}
      hide={hide}
      content={typeof content === 'function' ? content({ hide }) : content}
    >
      {typeof button === 'function' ? button({
        ref,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          openDropdown();
        },
        'data-active': isDropdownVisible,
      }) : button}
    </DropdownLayer>
  );
};
