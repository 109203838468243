import { make } from 'src/utils/reactives.util';

type Navigationstate = {
  // Id of the active item in the sidebar while not activated by the history location
  activeItem: string | null;
};

export const {
  hookValue: useGetNavigation,
  setValue: setNavigation,
} = make<Navigationstate>({
  defaultState: {
    activeItem: null,
  },
});
