import { useResponsive } from 'src/reactives/responsive.reactive';
import { useGetSidebar } from 'src/reactives/sidebar.reactive';

export const useSidebarCollapsed = () => {
  const { collapsed } = useGetSidebar();
  const [{ breakpoint }] = useResponsive();

  if (breakpoint === 'mobile') {
    return false;
  }
  return collapsed;
};
