import { StarOutlineIcon, StarIcon } from '@cycle-app/ui/icons';

import { useBoardStar } from 'src/hooks/api/mutations/boards/useBoardStar';
import { useIsBoardStarred } from 'src/hooks/api/queries/boards/useBoardsStarred';

import { Action, StarAction } from './BoardHeader.styles';
import { HiddenAction } from './HiddenAction';

export const BoardStarButton = ({ boardId }: { boardId: string }) => {
  const {
    star, unStar,
  } = useBoardStar();
  const isBoardStarred = useIsBoardStarred();

  return (
    <HiddenAction isDisabled={isBoardStarred(boardId)}>
      {isBoardStarred(boardId) ? (
        <StarAction
          onClick={() => unStar(boardId)}
          tooltip="Unstar"
          tooltipPlacement="bottom"
        >
          <StarIcon size={16} />
        </StarAction>
      ) : (
        <Action
          onClick={() => star(boardId)}
          tooltip="Star"
          tooltipPlacement="bottom"
        >
          <StarOutlineIcon size={16} />
        </Action>
      )}
    </HiddenAction>
  );
};
