import {
  TextButtonCss, TextButtonProps, TextButton, typo, ActionButton, ellipsis, Emoji, Skeleton, ShyScrollbar, Button,
} from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { variantsContent } from '../../DialogModal/DialogModal.motion';
import PortalModal from '../../PortalModal/PortalModal';

export const DowngradeButton = styled(Button)`
  width: 100%;
`;

interface StyledTextButtonCssProps extends TextButtonProps {
  $isError?: boolean;
}

const StyledTextButtonCss = css<StyledTextButtonCssProps>`
  ${TextButtonCss}
  ${typo.body400}
  --bgHover: ${p => (p.$isError ? p.theme.colors.background.redlight : p.theme.colors.background.tertiary)};
  --bgActive: ${p => (p.$isError ? p.theme.colors.background.redlight : p.theme.colors.background.hover)};
  --colorHover: ${p => p.theme.colors.text.secondary};
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
  width: auto;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const StyledTextButton = styled(TextButton)`
  ${StyledTextButtonCss}
`;

export const MainBox = styled.div`
  border-radius: 12px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: ${p => p.theme.colors.background.primary};
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  position: relative;
  padding: 20px;
  z-index: 2;
`;

interface ItemProps {
  $isError?: boolean;
}

export const FeatureItem = styled.div<ItemProps>`
  --border: ${p => p.theme.colors.border.primary};
  --bg: transparent;
  --color: ${p => p.theme.colors.text.secondary};
  align-items: center;
  border: 1px solid var(--border);
  background-color: var(--bg);
  color: var(--color);
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 0 12px;

  ${p => p.$isError && css`
    --border: ${p.theme.colors.text.red};
    --bg: ${p.theme.colors.background.redlight};
    --color: ${p.theme.colors.text.red};
  `}
`;

export const FeatureItemTitle = styled.div`
  ${ellipsis}
  align-items: center;
  display: flex;
  gap: 8px;

  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const FeatureItemName = styled.span`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
  display: block;
  margin-left: 4px;
`;

export const FeatureItemCount = styled.span`
  ${typo.body400};
`;

export const FeatureItemAction = styled.div`
  color: ${p => p.theme.colors.text.secondary};
`;

interface PortalModalStyledProps {
  $isHeaderFixed?: boolean;
}

export const PortalModalStyled = styled(PortalModal).attrs(p => ({
  motionVariants: variantsContent,
  ...p,
}))<PortalModalStyledProps>`
  width: 450px;
  padding: 16px;

  ${p => p.$isHeaderFixed && css`
    display: grid;
    grid-template-rows: min-content 1fr;
    overflow: unset;
  `}
`;

export const ModalHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const ModalTitle = styled.h2`
  ${typo.headerSmall};
  align-items: center;
  display: flex;
  font-weight: 500;
  gap: 10px;
`;

export const ModalCloseButtonStyled = styled(ActionButton)`
  height: 24px;
  width: 24px;
`;

export const ModalBody = styled(ShyScrollbar)`
  ${typo.body400}
  margin-top: 16px;
  max-height: 430px;
`;

export const ModalTop = styled.div`
  margin-top: 16px;
`;

export const ModalInfo = styled.div`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 16px;

  svg {
    height: 12px;
    margin-right: 6px;
    vertical-align: middle;
    width: 12px;
  }
`;

export const DeletableItemList = styled.ul`
  display: grid;
  gap: 16px;
`;

export const DeletableItem = styled.li`
  background-color: ${p => p.theme.colors.background.secondary};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  white-space: nowrap;
  padding: 12px;
  align-items: center;
`;

export const DeleteItemSkeleton = styled(Skeleton)`
  border-radius: 6px;
  height: 40px;
  margin-top: 16px;
`;

export const DeleteItemName = styled.div`
  ${ellipsis}
`;

export const Bold = styled.span`
  ${typo.body500}
`;

export const StyledBoardEmoji = styled(Emoji)`
  margin-right: 8px;
`;

export const IntegrationIconContainer = styled.div`
  display: inline-block;
  margin-right: 8px;

  svg {
    vertical-align: text-top;
  }
`;

export const SubmitForm = styled.form`
  display: grid;
  gap: 16px;
  margin-top: 16px;
`;

export const SubmitFormFooter = styled.div`
  display: flex;
  gap: 16px;
`;
