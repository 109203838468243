import { PublishedBoardConfigFullFragment, OperatorIsEmptyOrNot, OperatorIsInOrNot, FilterPropertyRuleCustomerFragment, FilterPropertyRuleCompanyFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

export const assigneeIsCompatible = (userId: string, boardConfig: PublishedBoardConfigFullFragment | null) => {
  const filterAssignee = boardConfig?.filterProperties.edges.find(edge => edge.node.__typename === 'FilterPropertyRuleAssignee');
  if (filterAssignee?.node.__typename !== 'FilterPropertyRuleAssignee') return true;

  if (filterAssignee?.node.assigneeRule.__typename === 'RuleUserMultipleValues') {
    const selectedValues = nodeToArray(filterAssignee.node.assigneeRule.values)
      .filter(({ selected }) => selected);
    const userIsIncludesInFilter = selectedValues
      .map(({ value }) => value.id).includes(userId);

    if (filterAssignee.node.assigneeRule.operator === OperatorIsInOrNot.Is && !selectedValues.length) {
      return true;
    }

    return filterAssignee.node.assigneeRule.operator === OperatorIsInOrNot.Is
      ? userIsIncludesInFilter
      : !userIsIncludesInFilter;
  }

  if (filterAssignee?.node.assigneeRule.__typename === 'RuleIsEmptyOrNot') {
    return filterAssignee.node.assigneeRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;
  }

  return true;
};

export const docShouldHaveAnAssignee = (boardConfig: PublishedBoardConfigFullFragment | null) => {
  const filterAssignee = boardConfig?.filterProperties.edges.find(edge => edge.node.__typename === 'FilterPropertyRuleAssignee');
  if (filterAssignee?.node.__typename !== 'FilterPropertyRuleAssignee') return false;

  const shouldNotBeEmpty =
    filterAssignee.node.assigneeRule.__typename === 'RuleIsEmptyOrNot' &&
    filterAssignee.node.assigneeRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;

  const shouldBeSpecificAssignee =
    filterAssignee.node.assigneeRule.__typename === 'RuleUserMultipleValues' &&
    filterAssignee.node.assigneeRule.operator === OperatorIsInOrNot.Is && !!nodeToArray(filterAssignee.node.assigneeRule.values)
      .filter(({ selected }) => selected).length;

  return shouldNotBeEmpty || shouldBeSpecificAssignee;
};

export const isCustomerCompatible = (customerId: string, customerFilter: FilterPropertyRuleCustomerFragment | null) => {
  if (!customerFilter) return true;
  const rule = customerFilter.customerRule;

  if (rule?.__typename === 'RuleIsEmptyOrNot') {
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsEmpty) return !customerId;
    if (rule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty) return !!customerId;
  }

  if (rule?.__typename === 'RuleCustomerMultipleValues') {
    const selectedValues = nodeToArray(rule.selectedValues);
    const match = selectedValues.some(({ value }) => value.id === customerId);
    if (rule.operator === OperatorIsInOrNot.Is) return selectedValues.length === 0 || match;
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

export const isCompanyCompatible = (companyId: string, companyFilter: FilterPropertyRuleCompanyFragment | null) => {
  if (!companyFilter) return true;
  const rule = companyFilter.companyRule;

  if (rule?.__typename === 'RuleCompanyMultipleValues') {
    const selectedValues = nodeToArray(rule.selectedValues);
    const match = selectedValues.some(({ value }) => value.id === companyId);
    if (rule.operator === OperatorIsInOrNot.Is) return selectedValues.length === 0 || match;
    if (rule.operator === OperatorIsInOrNot.IsNot) return !match;
  }

  return true;
};

export const docShouldHaveCustomer = (boardConfig: PublishedBoardConfigFullFragment | null) => {
  const filter = boardConfig?.filterProperties.edges.find(edge => edge.node.__typename === 'FilterPropertyRuleCustomer');
  if (filter?.node.__typename !== 'FilterPropertyRuleCustomer') return false;

  const shouldNotBeEmpty =
    filter.node.customerRule.__typename === 'RuleIsEmptyOrNot' &&
    filter.node.customerRule.isEmptyOperator === OperatorIsEmptyOrNot.IsNotEmpty;

  const shouldBeSpecificCustomer =
    filter.node.customerRule.__typename === 'RuleCustomerMultipleValues' &&
    filter.node.customerRule.operator === OperatorIsInOrNot.Is;

  return shouldNotBeEmpty || shouldBeSpecificCustomer;
};

export const docShouldHaveCompany = (boardConfig: PublishedBoardConfigFullFragment | null) => {
  const filter = boardConfig?.filterProperties.edges.find(edge => edge.node.__typename === 'FilterPropertyRuleCompany');
  if (filter?.node.__typename !== 'FilterPropertyRuleCompany') return false;

  const shouldBeSpecificCustomer =
    filter.node.companyRule.__typename === 'RuleCompanyMultipleValues' &&
    filter.node.companyRule.operator === OperatorIsInOrNot.Is;

  return shouldBeSpecificCustomer;
};
