import { SelectOption } from '@cycle-app/ui';
import { TrashIcon, DuplicateIcon, CaretIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps, NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { FC } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useEditorContext } from 'src/contexts/editorContext';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import { Container, Toolbar, LanguageContainer, Language } from './BlockCodeView.styles';

const LANGUAGES = ['html', 'css', 'php', 'javascript', 'typescript', 'json', 'xml'];

interface Props extends NodeViewRendererProps {
  getPos: () => number;
  updateAttributes: (p: { language: string }) => void;
}

const BlockCodeView: FC<Props> = ({
  node,
  getPos,
  updateAttributes,
}) => {
  const { editor } = useEditorContext();

  const options: Array<SelectOption> = [
    {
      label: 'Copy',
      value: 'copy',
      icon: <DuplicateIcon />,
      onSelect: () => navigator.clipboard.writeText(node.content.toString()),
    },
    {
      label: 'Delete',
      value: 'delete',
      icon: <TrashIcon />,
      onSelect: () => deleteNodeRange({
        editor,
        node,
        getPos,
      }),
    },
  ];

  const languages = LANGUAGES.sort((a, b) => a.localeCompare(b));
  const currentLanguage = (node.attrs.language ?? 'auto') as string;

  return (
    <NodeViewWrapper>
      <Container>
        <Toolbar>
          <DotsMenuLayer
            options={options}
            placement="bottom-end"
          />
        </Toolbar>
        <pre spellCheck={false}>
          <NodeViewContent />
        </pre>
        <LanguageContainer>
          <DropdownSelectLayer
            selectedValue={currentLanguage}
            options={[
              {
                label: 'Auto',
                value: 'auto',
                onSelect: () => updateAttributes({ language: 'auto' }),
              },
              ...languages.map(lang => ({
                label: lang,
                value: lang,
                onSelect: () => updateAttributes({ language: lang }),
              })),
            ]}
          >
            <Language>
              <span contentEditable={false}>{currentLanguage}</span>
              <CaretIcon />
            </Language>
          </DropdownSelectLayer>
        </LanguageContainer>
      </Container>
    </NodeViewWrapper>
  );
};

export default BlockCodeView;
