import { useMutation } from '@apollo/client';
import { AddNewDocDocument } from '@cycle-app/graphql-codegen';
import keyBy from 'lodash/keyBy';
import { isPresent } from 'ts-is-present';

import useQueryParams from 'src/hooks/useQueryParams';

import { useProductBase } from '../Product';
import { FormData } from './Form.types';
import { useCustomPropertyDefinitions } from './useCustomPropertyDefinitions';
import { useFeedback } from './useFeedback';

export const useSubmitForm = () => {
  const queryParams = useQueryParams();
  const { product } = useProductBase();
  const { feedback } = useFeedback();
  const definitions = useCustomPropertyDefinitions();
  const [addNewDoc] = useMutation(AddNewDocDocument);

  return async (values: FormData) => {
    if (!product || !feedback) return;
    const definitionsById = keyBy(definitions, 'id');
    const attributes = Object.entries(values.custom).map(([attributeDefinitionId, value]) => {
      switch (definitionsById[attributeDefinitionId]?.__typename) {
        case 'AttributeDateDefinition':
          return {
            attributeDefinitionId,
            value: { date: value },
          };
        case 'AttributeEmailDefinition':
          return {
            attributeDefinitionId,
            value: { email: value },
          };
        case 'AttributeNumberDefinition':
          return {
            attributeDefinitionId,
            value: { number: value },
          };
        case 'AttributePhoneDefinition':
          return {
            attributeDefinitionId,
            value: { phone: value },
          };
        case 'AttributeSingleSelectDefinition':
          return {
            attributeDefinitionId,
            value: { select: value },
          };
        case 'AttributeTextDefinition':
          return {
            attributeDefinitionId,
            value: { text: value },
          };
        case 'AttributeUrlDefinition':
          return {
            attributeDefinitionId,
            value: { url: value },
          };
        case 'AttributeCheckboxDefinition':
          return {
            attributeDefinitionId,
            value: { checkbox: value || false },
          };
        default:
          return null;
      }
    }).filter(isPresent);

    const sourceType = queryParams.get('sourceType');
    const sourceUrl = queryParams.get('sourceLink');
    const sourceDealId = queryParams.get('sourceDealId');
    const source = !sourceType || !sourceUrl ? undefined : {
      url: sourceUrl,
      dealId: sourceDealId,
    };

    await addNewDoc({
      variables: {
        title: values.title,
        doctypeId: feedback.id,
        productId: product.id,
        assignee: values.assignee,
        customerId: values.customerId,
        attributes,
        source: source && {
          sourceHubspot: source,
        },
        contentJSON: values.description || null,
      },
    });
  };
};
