import { SectionType } from '@cycle-app/graphql-codegen';
import { toObject, nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';

import { useProduct } from './useProduct';

export const useBoardSections = () => {
  const {
    product,
    loading,
  } = useProduct();

  const sections = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type !== SectionType.Inbox),
    [product],
  );
  const inboxSection = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type === SectionType.Inbox),
    [product],
  );
  const inbox = useMemo(() => nodeToArray(inboxSection[0]?.boards), [inboxSection]);
  const inboxWitoutMyInbox = useMemo(() => inbox.filter(view => !(view.isBuiltIn && view.name === 'My Inbox')), [inbox]);
  const customSections = useMemo(
    () => nodeToArray(product?.boardSections).filter(section => section.type === SectionType.Custom),
    [product],
  );

  const mappingBoards = useMemo(
    () => Object.fromEntries(
      sections.flatMap(section => nodeToArray(section.boards)).map(board => [board.id, board]),
    ),
    [sections],
  );

  const mappingSections = useMemo(() => toObject(sections), [sections]);

  return {
    sections,
    loading,
    mappingBoards,
    mappingSections,
    customSections,
    inbox,
    inboxWitoutMyInbox,
  };
};
