import { Node } from '@tiptap/core';

export type MentionOptions = {
  HTMLAttributes: Record<string, unknown>;
};

const extensionName = 'mention';

const mentionExtension = Node.create<MentionOptions>({
  name: extensionName,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: extensionName,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ];
  },

});

export default mentionExtension;
