import { typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { ReadOnlyEditor } from 'src/components/Editor';

export const Content = styled(ReadOnlyEditor) <{ isContentBoxed: boolean }>`
  margin: 8px 16px 0 0;
  height: auto;

  ${p => p.isContentBoxed && css`
    margin-right: 0;
    padding: 8px;
    background-color: ${p.theme.colors.background.secondary};
    border-radius: 4px;
  `}
`;

export const Container = styled.div<{
  isWelcomeNotification: boolean;
  isContentBoxed: boolean;
}>`
  padding: 16px;
  display: flex;
  gap: 8px;

  ${p => (p.isWelcomeNotification ? css`
    background-color: ${p.theme.colors.background.bluelight};
  ` : css`
    &:hover {
      background-color: ${p.theme.colors.background.secondary};

      ${p.isContentBoxed && css`
        ${Content} {
          background-color: ${p.theme.colors.background.hover};
        }
      `}
    }
  `)}


  &:not(:last-of-type) {
    border-bottom: 1px solid ${p => p.theme.colors.border.primary}
  }
`;

export const ImageContainer = styled.div`
  margin-top: 2px;
`;

export const MainArea = styled.div`
  position: relative;
  width: 100%;
  min-width: 0;
`;

export const UnreadDot = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${p => p.theme.userColors.main};
`;

export const Title = styled.div<{ isFromCycle: boolean }>`
  margin-right: 16px;
  ${p => (p.isFromCycle ? typo.body600 : typo.body500)}
`;

export const GreyPart = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary}
`;

export const Date = styled.div`
  margin-top: 4px;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.caption400};
`;

export const CycleLogo = styled.img`
  width: 20px;
  height: 20px;
`;
