import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { body600 } from '../../../theme/typo';

export type Size = 'S' | 'M';

export const Container = styled.div<{ $size?: Size; $isEditable?: boolean }>`
  ${p => p.$size === 'S' && css`
    --company-logo-size: 20px;
    --company-logo-icon-size: 11px;
  `}

  ${p => p.$size === 'M' && css`
    --company-logo-size: 64px;
    --company-logo-icon-size: 24px;
  `}

  ${p => p.$isEditable && css`cursor: pointer;`}

  grid-area: avatar;
`;

export const ImageContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: var(--company-logo-size);
  justify-content: center;
  position: relative;
  width: var(--company-logo-size);
  overflow: hidden;

  svg {
    width: var(--company-logo-icon-size);
    height: var(--company-logo-icon-size);
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  ${p => !p.src && css`background-color: ${transparentize(0.85, p.theme.userColors.fg)}`};
  color: ${p => p.theme.userColors.fg};

  ${body600}
  font-size: var(--company-logo-icon-size);
  text-transform: uppercase;
`;
