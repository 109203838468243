import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const NotionIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.2471 4.98288C6.1135 5.68679 6.43853 5.63309 8.06552 5.52453L23.4037 4.60354C23.729 4.60354 23.4585 4.27901 23.35 4.22509L20.8026 2.38356C20.3145 2.00461 19.6643 1.57064 18.4179 1.6792L3.56597 2.76245C3.02432 2.81616 2.91614 3.08698 3.13184 3.30405L5.2471 4.98288ZM6.16798 8.55743V24.6958C6.16798 25.5631 6.6014 25.8876 7.57691 25.834L24.4335 24.8586C25.4095 24.8049 25.5183 24.2083 25.5183 23.5038V7.47369C25.5183 6.77026 25.2477 6.39093 24.6502 6.44507L7.03487 7.47369C6.38477 7.52832 6.16792 7.85351 6.16792 8.55743H6.16798ZM22.8088 9.42312C22.9169 9.91101 22.8088 10.3985 22.32 10.4533L21.5078 10.6151V22.5295C20.8026 22.9085 20.1524 23.1252 19.6105 23.1252C18.7429 23.1252 18.5257 22.8542 17.8758 22.0423L12.5632 13.7022V21.7715L14.2443 22.1508C14.2443 22.1508 14.2443 23.1252 12.888 23.1252L9.14893 23.3421C9.04032 23.1252 9.14893 22.5841 9.52821 22.4757L10.5039 22.2053V11.5361L9.14921 11.4275C9.04053 10.9396 9.31114 10.2361 10.0705 10.1816L14.0817 9.91117L19.6105 18.3599V10.8859L18.2009 10.7241C18.0927 10.1277 18.5257 9.6946 19.0677 9.6409L22.8088 9.42312ZM2.31897 1.30031L17.7674 0.162643C19.6645 -6.06999e-05 20.1526 0.108938 21.3449 0.975066L26.2763 4.44106C27.09 5.03707 27.3612 5.19934 27.3612 5.84906V24.8586C27.3612 26.0499 26.9271 26.7545 25.4098 26.8623L7.46956 27.9457C6.33052 28 5.78843 27.8376 5.19192 27.079L1.56042 22.3673C0.909767 21.5 0.63916 20.8511 0.63916 20.092V3.19511C0.63916 2.22086 1.07329 1.40816 2.31897 1.30031Z" fill="currentColor" />
  </Svg>
);
