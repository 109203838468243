import styled, { css } from 'styled-components';

export type Size = 12 | 14 | 16 | 18 | 24 | 32 | 64;

export const Container = styled.div<{ $size: Size; $inline: boolean; $native: boolean }>`
  max-width: ${p => p.$size}px;
  position: static;
  ${p => p.onClick && 'cursor: pointer'};
  ${p => !p.$native && css`
    max-height: ${p.$size}px;
  `};

  display: ${p => (p.$inline ? 'inline' : 'block')};
  span {
    display: ${p => (p.$inline ? 'inline' : 'block')};
    line-height: ${p => p.$size}px;
  }
`;
