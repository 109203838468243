import {
  SectionsIllustrationCard,
  BoardsIllustrationCard,
} from 'src/components/IllustationCards';
import { FILE_SIZE_LIMIT_RESTRICTED } from 'src/constants/upload.constant';
import { getFileSizeWithUnit } from 'src/utils/files.util';

import {
  Container,
  Boards,
  Docs,
  Incognito,
  Sections,
  Uploads,
  CardTitle,
  CardDesc,
  CardContent,
  CardIcon,
  UploadsHeader,
  DocsPapersContainer,
  DocsPaper,
  StyledIncognitoIllustationCard,
  UploadsCardContent,
  StyledPapersPinnedIllustationCard,
} from './BillingUpdateFeatures.styles';
import { BillingUpdateIntegrationCard } from './BillingUpdateIntegrationCard';

export const BillingUpdateFeatures = () => (
  <Container>
    <BillingUpdateIntegrationCard />
    <Docs>
      <CardIcon>
        <DocsPapersContainer>
          <DocsPaper />
          <DocsPaper />
          <DocsPaper />
          <DocsPaper />
        </DocsPapersContainer>
      </CardIcon>
      <CardContent>
        <CardTitle>Unlimited docs</CardTitle>
        <CardDesc>Drop the monthly docs limit and create as many as you want</CardDesc>
      </CardContent>
    </Docs>
    <Sections>
      <CardContent>
        <CardTitle>Custom sections</CardTitle>
        <CardDesc>Neatly organize your views into sections</CardDesc>
        <SectionsIllustrationCard />
      </CardContent>
    </Sections>
    <Boards>
      <BoardsIllustrationCard />
      <CardContent>
        <CardTitle>Unlimited views</CardTitle>
        <CardDesc>Go crazy and create views without limits</CardDesc>
      </CardContent>
    </Boards>
    <Uploads>
      <UploadsCardContent>
        <UploadsHeader>
          <CardTitle>Unlimited file uploads</CardTitle>
          <CardDesc>
            Upload large files and images (no more
            {` ${getFileSizeWithUnit(FILE_SIZE_LIMIT_RESTRICTED, true)} `}
            limit)
          </CardDesc>
        </UploadsHeader>
        <StyledPapersPinnedIllustationCard />
      </UploadsCardContent>
    </Uploads>
    <Incognito>
      <CardContent>
        <CardTitle>Incognito mode</CardTitle>
        <CardDesc>Become invisble whenever you want</CardDesc>
      </CardContent>
      <StyledIncognitoIllustationCard />
    </Incognito>
  </Container>
);
