import {
  AddBoardConfigSwimlanebyDoctypeDocument,
  RemoveBoardConfigSwimlaneDocument,
  MoveSwimlaneDocument,
  ListPositionInput,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';

interface MoveSwimlaneParams {
  swimlaneId: string;
  position: ListPositionInput;
}

export default function useManageSwimlanes(boardConfigId: string) {
  const [addSwimlaneDoctypeMutation, { loading: loadingAddSwimlane }] = useSafeMutation(AddBoardConfigSwimlanebyDoctypeDocument, {
    onCompleted: () => trackAnalytics(Events.SwimlaneAdded),
  });
  const [removeSwimlaneMutation, { loading: loadingRemoveSwimlane }] = useSafeMutation(RemoveBoardConfigSwimlaneDocument);

  const addSwimlaneDoctype = useCallback((doctypeId: string) => addSwimlaneDoctypeMutation({
    variables: {
      boardConfigId,
      doctypeId,
    },
  }), [addSwimlaneDoctypeMutation, boardConfigId]);

  const removeSwimlane = useCallback(() => removeSwimlaneMutation({
    variables: { boardConfigId },
  }), [removeSwimlaneMutation, boardConfigId]);

  const [moveSwimlaneMutation, { loading: loadingMoveSwimlane }] = useSafeMutation(MoveSwimlaneDocument);

  const moveSwimlane = useCallback(async ({
    swimlaneId,
    position,
  }: MoveSwimlaneParams) => {
    await moveSwimlaneMutation({
      variables: {
        swimlaneId,
        boardConfigId,
        position,
      },
    });
  }, [moveSwimlaneMutation, boardConfigId]);

  return {
    addSwimlaneDoctype,
    removeSwimlane,
    moveSwimlane,
    loadingAddSwimlane,
    loadingRemoveSwimlane,
    loadingMoveSwimlane,
  };
}
