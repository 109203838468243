import { DoctypeFragment, DoctypeType, StatusCategory } from '@cycle-app/graphql-codegen';
import { InfoIconOutline } from '@cycle-app/ui/icons';

import { SettingsViewHeader } from 'src/components/SettingsViewHeader';

import { SettingsCategory } from './SettingsCategory';
import { Page, Sections, Info } from './SettingsWorkflows.styles';

export const SettingsWorkflows = () => (
  <Page>
    <SettingsViewHeader
      title="Workflows"
      description="Add and configure your statuses"
    />
    <Workflows />
  </Page>
);

export const Workflows = ({ docType }: { docType?: DoctypeFragment }) => {
  const info = getInfo(docType);
  return (
    <Sections>
      {info && (
        <Info>
          <InfoIconOutline />
          {info}
        </Info>
      )}
      <SettingsCategory
        label="Not started"
        category={StatusCategory.NotStarted}
        currentDocTypeId={docType?.id}
      />
      <SettingsCategory
        label="Started"
        category={StatusCategory.Started}
        currentDocTypeId={docType?.id}
      />
      <SettingsCategory
        label="Completed"
        category={StatusCategory.Completed}
        currentDocTypeId={docType?.id}
      />
      <SettingsCategory
        label="Canceled"
        category={StatusCategory.Canceled}
        currentDocTypeId={docType?.id}
      />
    </Sections>
  );
};

const getInfo = (docType?: DoctypeFragment): string | null => {
  if (!docType) return null;

  // Insight
  if (docType.type === DoctypeType.Insight) {
    const parents = docType.parents?.edges.map(e => e.node.name) ?? [];
    const formattedParents = `${parents.length > 1 ? 'parents' : 'parent'} (${parents?.join(', ')})`;
    return [
      `For ${docType.name}, statuses are automatically inherited from their ${formattedParents}.`,
      "They can't be manually updated.",
    ].join(' ');
  }

  // Parent of insight
  if (docType?.children?.edges.some(e => e.node.type === DoctypeType.Insight)) {
    const children = docType.children.edges
      .filter(e => e.node.type !== DoctypeType.Insight)
      .map(e => e.node.name);

    if (!children.length) return null;

    const formattedChildren = children.length > 1
      ? `${children.slice(0, -1).join(', ')} and ${children[children.length - 1]}`
      : children[0];

    return [
      `For ${docType.name}, statuses are automatically updated based on ${formattedChildren} statuses.`,
      'They can also be manually updated.',
    ].join(' ');
  }

  return null;
};
