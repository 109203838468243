import { Output } from '@cycle-app/front/src/types/editor.types';
import { extractMentionedUsers } from '@cycle-app/front/src/utils/editor/editor.utils';
import { Editor } from '@tiptap/core';
import { useEditor, EditorContent } from '@tiptap/react';
import React, { FC, useEffect, useCallback } from 'react';

import { EditorContextProvider } from 'src/contexts/editorContext';
import { commentEditorExtensions } from 'src/editorExtensions/editorExtensions';
import useEditorContributors from 'src/hooks/editor/useEditorContributors';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { useForcedFocus } from 'src/hooks/editor/useForcedFocus';
import { ActionId } from 'src/services/editor/editorActions';

import { CommentEditorContainer, CommentEditorContent } from './Editor.styles';

export interface CommentEditorOutput extends Output {
  editor: Editor;
  mentionedUserIds: string[];
}

interface Props {
  className?: string;
  content?: string;
  placeholder?: string;
  autofocus?: boolean;
  onUpdate: (output: CommentEditorOutput) => void;
  disabledActions?: ActionId[];
  maxHeight?: number;
}

export const CommentEditor: FC<Props> = ({
  className,
  autofocus = false,
  maxHeight,
  onUpdate,
  content = '',
  placeholder = '',
  disabledActions = [],
}) => {
  const contributors = useEditorContributors();
  const { onError } = useEditorLogic();

  const handleUpdate = useCallback((e: Editor) => {
    const html = e.getHTML();
    onUpdate({
      html,
      json: e.getJSON(),
      editor: e,
      mentionedUserIds: extractMentionedUsers(html),
    });
  }, [onUpdate]);

  const editor = useEditor({
    extensions: commentEditorExtensions({
      emptyContentPlaceholder: placeholder,
      people: contributors,
      disabledActions,
    }),
    autofocus,
    content,
    onCreate({ editor: e }) {
      handleUpdate(e);
    },
    onUpdate({ editor: e }) {
      handleUpdate(e);
      if (e.isFocused) {
        e.commands.scrollIntoView();
      }
    },
    editorProps: {
      /* Override the default behavior for Enter.
      It stops ProseMirror from calling any other handlers for the given input.
      See https://prosemirror.net/docs/ref/#view.EditorProps */
      handleKeyDown: (_view, e) => e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey,
    },
  });

  useForcedFocus({
    editor,
    isEnabled: autofocus,
    timeout: 0,
  });

  useEffect(() => {
    if (!editor || editor.isFocused || editor.getHTML() === content) return;
    editor.commands.setContent(content);
    handleUpdate(editor);
  }, [content, editor, handleUpdate]);

  if (!editor) {
    return null;
  }

  return (
    <EditorContextProvider
      editor={editor}
      onError={onError}
    >
      <CommentEditorContainer className={className}>
        <CommentEditorContent
          className="content-editor"
          maxHeight={maxHeight}
        >
          <EditorContent editor={editor} />
        </CommentEditorContent>
      </CommentEditorContainer>
    </EditorContextProvider>
  );
};
