import { CaretIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useMobileSidebarActions } from 'src/hooks/useMobileSidebarActions';

import { Container } from './SidebarToggleButton.styles';

interface Props {
  className?: string;
}
export const SidebarToggleButton: FC<Props> = ({ className }) => {
  const { open: openSidebar } = useMobileSidebarActions();

  return (
    <Container
      className={className}
      onClick={openSidebar}
    >
      <CaretIcon />
    </Container>
  );
};

export default SidebarToggleButton;
