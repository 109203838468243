import {
  DocSourceFragment,
  SourceSlack,
  SourceCycle,
  SourceIntercom,
  SourceMail,
  SourceZapier,
  SourceWeb,
  SourceHubspot,
} from '@cycle-app/graphql-codegen';
import { Tag, CycleLogo } from '@cycle-app/ui';
import {
  SlackIcon,
  IntercomIcon,
  EmailIcon,
  ChromeIcon,
  ZapierIcon,
  HubSpotIcon,
} from '@cycle-app/ui/icons';

import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';

import { CycleLogoContainer } from './DocSource.style';

type SourceType =
  | SourceSlack['__typename']
  | SourceCycle['__typename']
  | SourceIntercom['__typename']
  | SourceMail['__typename']
  | SourceWeb['__typename']
  | SourceZapier['__typename']
  | SourceHubspot['__typename'];

export type DocSourceProps = {
  source?: DocSourceFragment | null;
  context?: 'doc-item' | 'doc-panel';
};

export const DocSource = ({
  source, context = 'doc-item',
}: DocSourceProps) => {
  const copyToClipboard = useCopyToClipboard({
    successNotification: 'Link successfully copied to clipboard',
  });

  if (!source) return null;

  return (
    <Tag
      color={context === 'doc-panel' ? 'grey' : undefined}
      icon={<SourceIcon type={source.__typename} size={12} />}
      tooltip={{
        content: getTooltipContent(source.__typename, source.url),
        placement: 'top',
        withPortal: true,
      }}
      {...source.__typename !== 'SourceCycle' && {
        onClickCopyLink: () => copyToClipboard(source.url),
        onClick: () => {
          const url = source.__typename === 'SourceMail' ? `mailto:${source.url}` : source.url;
          window.open(url, '_blank');
        },
      }}
    >
      {sourceName[source.__typename]}
    </Tag>
  );
};

export const sourceName: Record<NonNullable<SourceType>, string> = {
  SourceCycle: 'Cycle',
  SourceHubspot: 'Hubspot',
  SourceIntercom: 'Intercom',
  SourceMail: 'Email',
  SourceSlack: 'Slack',
  SourceWeb: 'Chrome',
  SourceZapier: 'Zapier',
};

const getTooltipContent = (type: string, mail?: string) => {
  switch (type) {
    case 'SourceCycle':
      return 'Cycle';
    case 'SourceMail':
      return `mailto: ${mail}`;
    case 'SourceSlack':
    case 'SourceIntercom':
    case 'SourceHubspot':
    default:
      return 'Click to open';
  }
};

export const SourceIcon = ({
  type, size,
}: { type: SourceType; size?: number }) => {
  switch (type) {
    case 'SourceSlack':
      return <SlackIcon size={size} />;
    case 'SourceIntercom':
      return <IntercomIcon size={size} />;
    case 'SourceMail':
      return <EmailIcon size={size} />;
    case 'SourceWeb':
      return <ChromeIcon size={size} />;
    case 'SourceZapier':
      return <ZapierIcon size={size} />;
    case 'SourceHubspot':
      return <HubSpotIcon size={size} />;
    case 'SourceCycle':
    default:
      return (
        <CycleLogoContainer>
          <CycleLogo size={8} />
        </CycleLogoContainer>
      );
  }
};
