import { boxShadowZ2, typo, transitionCss } from '@cycle-app/ui';
import { tablet } from '@cycle-app/utilities';
import { Tabs, TabList, Tab, TabPanels } from '@reach/tabs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../Settings.styles';

const PAGE_PADDING = 32;

export const SectionTitle = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;
  gap: 8px;

  ${typo.body500}
`;

export const HeaderStyled = styled(Header)`
  border-bottom: none;
  padding-bottom: 10px;
  margin-bottom: 0;
`;

export const Description = styled.div`
  margin-top: 8px;
  ${typo.body400}
`;

export const Title = styled.h2`
  ${typo.headerLarge600}
`;

export const DoctypeTemplates = styled.div`
  grid-area: templates;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 16px;
  
  /* Size of the left column description title */
  margin-top: 40px;
  ${tablet} {
    margin-top: 0;
  }

  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 8px;
  ${boxShadowZ2};
`;

export const ContentTemplate = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;

  border-radius: 6px;
  background-color: ${p => p.theme.colors.background.secondary};
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const HierarchyContainer = styled.div`
  grid-area: hierarchy;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  padding-top: ${PAGE_PADDING}px;

  display: grid;
  gap: 48px 32px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "hierarchy templates"
    "tabs tabs";

  ${tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};
  
  display: inline-flex;
  align-items: center;
  gap: 8px;

  strong {
    color: ${p => p.theme.colors.text.secondary};
    font-weight: 400;
  }
`;

const StyledTabs = styled(Tabs)`
  grid-area: tabs;
`;

const StyledTabList = styled(TabList)`
  padding: 8px ${PAGE_PADDING}px;
  border-bottom: 1px solid ${p => p.theme.colors.home.separator};
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding-bottom: 10px;
  margin: 0 -${PAGE_PADDING}px;
  width: calc(100% + ${PAGE_PADDING * 2}px);
`;

const StyledTab = styled(Tab)`
  position: relative;
  min-width: 82px;
  cursor: pointer;
  ${typo.body400}

  :after {
    content: "";
    height: 2px;
    width: 100%;
    background: ${p => p.theme.userColors.main};
    position: absolute;
    left: 0;
    bottom: -11px;
    opacity: 0;
    transform: scale(0.75);
    ${transitionCss(['transform', 'opacity'])}
  }

  &[data-selected] {
    ${typo.body500}
    color: ${p => p.theme.colors.text.primary};
    :after {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const StyledTabPanels = styled(TabPanels)`
  padding: 32px 0;
`;

export {
  StyledTabs as Tabs,
  StyledTabList as TabList,
  StyledTab as Tab,
  StyledTabPanels as TabPanels,
};
