// import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { useCallback, useMemo, useEffect, RefObject } from 'react';

// import { PageId } from 'src/constants/routing.constant';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
// import { useNavigate } from 'src/hooks/useNavigate';
import {
  getActiveProductTourEl,
  getActiveProductTourNextStep,
  setActiveProductTour,
  useActiveProductTour,
  useActiveProductTourStep,
} from 'src/reactives/productTours.reactive';
import { TourName, TourStep } from 'src/types/productTour.types';
// import { getDocSlug } from 'src/utils/slug.util';

export const useCreateDocModalFirstProductTour = (containerRef?: RefObject<HTMLDivElement>) => {
  const tour = useActiveProductTour();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);
  // const { navigate } = useNavigate();

  useEffect(() => {
    if (
      isEnabled &&
      tour?.name === TourName.FIRST_TOUR &&
      tour?.step === TourStep.WriteDocContent &&
      containerRef?.current &&
      getActiveProductTourEl() !== containerRef.current
    ) {
      setActiveProductTour({ el: containerRef.current });
    }
  }, [containerRef, isEnabled, tour]);

  const showHelper = useMemo(() => tour?.step === TourStep.WriteDocContent, [tour?.step]);

  const setOpenFeedbackStep = useCallback((/* docCreated: DocBaseFragment | null */) => {
    const nextStep = getActiveProductTourNextStep();
    if (nextStep) setActiveProductTour({ step: nextStep });
    // const secondNextStep = getActiveProductTourNextStep();
    // if (secondNextStep) setActiveProductTour({ step: secondNextStep });
    // if (docCreated) navigate(PageId.InboxDoc, { docSlug: getDocSlug(docCreated) });
  }, []);

  return {
    isActive: tour?.name === TourName.FIRST_TOUR,
    isPreventDocTitleEdit: tour?.step ? [TourStep.SeeProperties, TourStep.WriteDocContent].includes(tour.step) : false,
    isEditorBlocked: tour?.step === TourStep.SeeProperties,
    setOpenFeedbackStep,
    shouldSetNextStepEl: (el: HTMLDivElement) => {
      if (
        isEnabled &&
        tour?.name === TourName.FIRST_TOUR &&
        tour.step === TourStep.SeeProperties &&
        el &&
        el !== getActiveProductTourEl()
      ) {
        setActiveProductTour({ el });
      }
    },
    showHelper,
  };
};

export const useCanOpenCreateDocModel = () => {
  const tour = useActiveProductTour();
  const currentStep = useActiveProductTourStep();

  if (!tour) return true;

  return currentStep !== TourStep.CreateFirstFeedback;
};
