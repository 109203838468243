import { MotionProps, Variants } from 'framer-motion';
import { RefObject, FC } from 'react';

import { motionProps as defaultMotionProps, variantsContent, variantsMask } from './Modal.motion';
import { Container, Mask, Content } from './Modal.styles';

export interface ModalProps {
  className?: string;
  hide?: () => void;
  naked?: boolean;
  placement?: 'top' | 'center';
  zIndex: number;
  maskZindex?: number;
  animate?: boolean;
  noMask?: boolean;
  motionProps?: MotionProps;
  motionVariants?: Variants;
  tabIndex?: number;
  contentRef?: RefObject<HTMLDivElement>;
  invisibleMask?: boolean;
  onAnimationComplete?: VoidFunction;
}

export const Modal: FC<ModalProps> = ({
  className,
  hide,
  naked = false,
  children,
  placement = 'center',
  zIndex,
  maskZindex,
  noMask = false,
  animate = true,
  motionVariants,
  motionProps = defaultMotionProps,
  tabIndex,
  contentRef,
  invisibleMask,
  onAnimationComplete,
}) => {
  const content = (
    <Content
      ref={contentRef}
      className={className}
      tabIndex={tabIndex}
      variants={motionVariants ?? variantsContent(!naked)}
      $naked={naked}
      $noMask={noMask}
      $zIndex={zIndex}
      {...animate && motionProps}
      onAnimationComplete={onAnimationComplete}
    >
      {children}
    </Content>
  );

  return noMask
    ? content
    : (
      <Container
        placement={placement}
        zIndex={maskZindex ?? zIndex}
      >
        {maskZindex !== undefined && (
          <Mask
            onClick={hide}
            variants={variantsMask}
            $visible={!invisibleMask}
            {...!invisibleMask && animate && motionProps}
          />
        )}
        {content}
      </Container>
    );
};

export { ModalTitle } from './Modal.styles';
