import { Emoji, TooltipProps, Tooltip } from '@cycle-app/ui';
import { FC, MouseEvent } from 'react';

import { useProductBase } from 'src/hooks/api/useProduct';
import { getDocKey } from 'src/utils/doc.util';

import {
  Container,
  Title,
  PublicId,
  ParentIcon,
} from './DocParent.styles';

interface Props {
  className?: string;
  doc: {
    id: string;
    publicId?: string | null;
    title: string;
    doctype: {
      emoji: string;
    };
  };
  showTitle?: boolean;
  showIcon?: boolean;
  showPublicId?: boolean;
  tooltip?: TooltipProps['content'];
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  minimal?: boolean;
  readonly?: boolean;
  size?: 'XS' | 'S' | 'M';
}
const DocParent: FC<Props> = ({
  doc,
  children,
  showTitle,
  minimal,
  showIcon = true,
  showPublicId = true,
  readonly = false,
  size = 'S',
  tooltip,
  ...containerProps
}) => {
  const product = useProductBase();

  const render = () => (
    <>
      {showIcon && <ParentIcon />}
      <Emoji size={size === 'S' ? 12 : 14} emoji={doc.doctype.emoji} />
      {showPublicId && !showTitle && doc.publicId && product?.key && (
        <PublicId>{getDocKey(product?.key, doc.publicId)}</PublicId>
      )}
      {showTitle && (<Title>{doc.title}</Title>)}
    </>
  );

  return (
    <Container
      {...containerProps}
      size={size}
      $minimal={minimal}
      readonly={readonly}
    >
      {tooltip ? (
        <Tooltip content={tooltip} placement="bottom">
          {render()}
        </Tooltip>
      ) : render()}
      {children}
    </Container>
  );
};

export default DocParent;
