import { ThemeType } from '@cycle-app/graphql-codegen';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

interface ThemeState {
  colorTheme: ThemeType;
}

export const {
  hookState: useThemeConfig,
  hookValue: useGetThemeConfig,
  setValue: setThemeConfig,
  getValue: getThemeConfig,
  resetValue: resetThemeConfig,
} = make<ThemeState>({
  defaultState: {
    colorTheme: ThemeType.Eclipse,
  },
  localKey: LocalKey.Theme,
});

export const useIsEclipseTheme = () => {
  const { colorTheme } = useGetThemeConfig();
  return /^eclipse$/i.test(colorTheme);
};
