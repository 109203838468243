import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  getActiveProductTourEl,
  getActiveProductTourNextStep,
  setActiveProductTour,
  useActiveProductTour,
} from 'src/reactives/productTours.reactive';
import { TourName, TourStep } from 'src/types/productTour.types';

import { useNavigate } from '../useNavigate';
import { PageId } from '../usePageId';

export const useProcessFeedbackProductTour = () => {
  const tour = useActiveProductTour();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);
  const { navigate } = useNavigate();

  const isActive = isEnabled && tour?.name === TourName.FIRST_TOUR;

  const setProcessButtonEl = (el?: HTMLElement | null) => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.ProcessFirstFeedback ||
      !el ||
      getActiveProductTourEl() === el
    ) return;

    setActiveProductTour({ el });
  };

  const setStartGamificationStep = () => {
    if (
      !isEnabled ||
      !tour ||
      tour.name !== TourName.FIRST_TOUR ||
      tour.step !== TourStep.ProcessFirstFeedback
    ) return;

    const nextStep = getActiveProductTourNextStep();

    if (!nextStep) return;

    setActiveProductTour({ step: nextStep });
    navigate(PageId.Inbox);
  };

  return {
    isActive,
    setProcessButtonEl,
    setStartGamificationStep,
  };
};
