import { FC, HTMLAttributes } from 'react';
import { Link as LinkRouter, LinkProps } from 'react-router-dom';

type Props = LinkProps & HTMLAttributes<HTMLAnchorElement>;

/* Its purpose is to serve <Link> from react-router-dom and prevent onDragStart event
 * to fix drag and drop with dnd-kit on Firefox
*/

const LinkDraggable: FC<Props> = (props) => (
  <LinkRouter {...props} draggable={false} onDragStart={(e) => e.preventDefault()} />
);

export default LinkDraggable;
