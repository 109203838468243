import { IntegrationFullFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';

import { useSlackToggleFeedbackNotifications } from 'src/hooks/api/mutations/integrations/useSlackToggleFeedbackNotifications';

import { ActionMenuContent, ActionMenuIcon, ActionMenuTextDescription, ActionMenuTextTitle } from '../../app/Main/Settings/SettingsIntegrations/IntegrationItem/IntegrationItem.styles';
import { StyledBellIcon } from './SlackToggleNotifications.styles';

export interface SlackToggleNotificationsProps {
  integration: IntegrationFullFragment;
  isPublicNotificationEnabled: boolean;
}

export const SlackToggleNotifications = ({
  integration, isPublicNotificationEnabled,
}: SlackToggleNotificationsProps) => {
  const {
    toggle, isLoading,
  } = useSlackToggleFeedbackNotifications(integration.provider?.id);

  return (
    <Button onClick={onClick} disabled={isLoading} variant="nospace">
      <ActionMenuIcon>
        <StyledBellIcon $isDisabled={!isPublicNotificationEnabled} />
      </ActionMenuIcon>
      <ActionMenuContent>
        <ActionMenuTextTitle>
          {isPublicNotificationEnabled ? 'Disable ' : 'Enable '}
        </ActionMenuTextTitle>
        <ActionMenuTextDescription>
          With public notifications on, Cycle bot will publicly notify people whenever a Feedback is created
        </ActionMenuTextDescription>
      </ActionMenuContent>
    </Button>
  );

  async function onClick() {
    await toggle();
  }
};
