import { CellContent } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Avatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
`;

export const StyledCellContent = styled(CellContent)<{ $isInteractive?: boolean }>`
  ${p => p.$isInteractive && css`cursor: pointer;`}
`;
