import { useState, useRef, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const RECT_OBJECT: Omit<DOMRect, 'toJSON'> = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

const DEFAULT_RESULT: DOMRect = {
  ...RECT_OBJECT,
  toJSON: () => JSON.stringify(RECT_OBJECT),
};

type useElObserverParams = {
  el?: HTMLElement;
};

export const useElObserver = ({ el }: useElObserverParams) => {
  const isObserving = useRef<boolean>(false);
  const [rect, setRect] = useState(el ? el.getBoundingClientRect() : DEFAULT_RESULT);

  const setNewRect = useDebouncedCallback((newRect: DOMRectReadOnly) => {
    setRect(newRect);
  }, 300);

  const setNewMeasure = () => el && setNewRect(el.getBoundingClientRect());

  useEffect(() => {
    if (!el || isObserving.current) return undefined;

    const observer = new ResizeObserver(() => {
      setNewMeasure();
    });
    observer.observe(el);
    isObserving.current = true;

    return () => {
      isObserving.current = false;
      observer?.disconnect();
    };
  }, [el]);

  useEffect(() => {
    const timOut = setInterval(setNewMeasure, 1000);
    return clearInterval(timOut);
  }, []);

  return {
    positions: rect,
  };
};
