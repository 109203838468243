import { HIGHLIGHT_DATA_ID } from '@cycle-app/editor-extensions';
import { typo, ShyScrollbar, Modal } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import ContainerDropFile from 'src/components/Editor/ContainerDropFile/ContainerDropFile';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

import { TitleContainer as DocMentionTitleContainer } from '../NodeViews/DocMention/DocMentionView.styles';

export const BUBBLE_MENU_NOT_INTERACTIVE_CLASS = 'bubble-menu-not-interactive';

const LowLightCss = css`
  .hljs-comment,
  .hljs-quote {
    color: #616161;
  }

  .hljs-variable,
  .hljs-template-variable,
  .hljs-attribute,
  .hljs-tag,
  .hljs-regexp,
  .hljs-link,
  .hljs-name,
  .hljs-selector-id,
  .hljs-selector-class {
    color: #f98181;
  }

  .hljs-number,
  .hljs-meta,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-literal,
  .hljs-type,
  .hljs-params {
    color: #fbbc88;
  }

  .hljs-string,
  .hljs-symbol,
  .hljs-bullet {
    color: #b9f18d;
  }

  .hljs-title,
  .hljs-section {
    color: #faf594;
  }

  .hljs-keyword,
  .hljs-selector-tag {
    color: #70cff8;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: 700;
  }
`;

const ContainerCss = css`
  width: 100%;
  height: 100%;
  position: relative;
  .ProseMirror {
    word-break: break-word;
    outline: none;
    .suggestion {
      border-radius: 4px;
      padding: 2px;
      background: ${p => p.theme.colors.background.hoverSoft};
    }
  }
`;

export const COLLABORATION_CURSOR_CARET_CLASS = 'collaboration-cursor__caret';
export const COLLABORATION_CURSOR_LABEL_CLASS = 'collaboration-cursor__label';

export const CollaborationCursorCSS = css`
  .${COLLABORATION_CURSOR_CARET_CLASS} {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid;
    border-right: 1px solid;
    word-break: normal;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -3px;
      left: -3px;
      border-radius: 50%;
      border: 3px solid;
      border-color: inherit;
      transition: opacity .2s ease-in-out;
    }

    &:hover {
      .${COLLABORATION_CURSOR_LABEL_CLASS}{
        opacity: 1;
        pointer-events: auto;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  .${COLLABORATION_CURSOR_LABEL_CLASS} {
    position: absolute;
    top: -28px;
    left: -1px;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    user-select: none;
    color: white;
    padding: 8px;
    border-radius: 4px 4px 4px 0;
    white-space: nowrap;
    transition: opacity .2s ease-in-out;

    opacity: 0;
    pointer-events: none;
  }
`;

const LiCSS = css`
  margin: 6px 0;
`;

export const CommentEditorContainer = styled.div`
  ${ContainerCss};
`;

export const ReadOnlyEditorContainer = styled.div<{ interactive: boolean }>`
  ${ContainerCss};
  ${p => !p.interactive && css`
    pointer-events: none;
  `};
`;

export const EditorContainer = styled(ContainerDropFile)`
  ${ContainerCss};

  &.${BUBBLE_MENU_NOT_INTERACTIVE_CLASS} {
    [data-tippy-root] {
      pointer-events: none;
    }
  }
`;

const EditorCSS = css`
  height: 100%;

  ${typo.body400};

  * {
    user-select: text;
  }

  *[data-drag-handle] {
    * {
      user-select: none;
    }
  }

  .ProseMirror {
    white-space: pre-wrap;

    mark {
      background-color: ${p => p.theme.colors.editor.textHighlight};
      border-radius: 4px;
      padding: 2px 3px;

      &:hover, &:focus, &:active {
        background-color: ${p => p.theme.userColors.markHighlight};
      }
    }

    &[contenteditable='true'] {
      img.ProseMirror-selectednode {
        outline: 3px solid ${(p) => p.theme.userColors.main};
      }
    }

    > * + * {
      margin-top: 12px;
    }

    p,
    h1,
    h2,
    h3 {
      &.is-empty::before {
        content: attr(data-placeholder);
        float: left;
        pointer-events: none;
        height: 0;
        font-weight: 500;
        color: ${(p) => p.theme.colors.text.disabled};
      }
    }

    p {
      ${typo.editorText}
    }

    h1 {
      ${typo.editorH1}
      margin-top: 32px;

      &:first-child {
        margin-top: 0;
      }
    }

    h2 {
      ${typo.editorH2}
      margin-top: 22px;

      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      ${typo.editorH3}
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    a {
      ${typo.editorLink}
    }

    [data-type="doc-mention"] {
      a {
        color: inherit;
        text-decoration: inherit;
      }
    }

    ol {
      padding-left: 21px;
      list-style: decimal;

      li {
        ${LiCSS}
      }
    }

    ul {
      padding-left: 21px;
      list-style: disc;

      li {
        ${LiCSS}
      }

      &[data-type='taskList'] {
        padding-left: 0;
        list-style: none;

        li {
          display: flex;

          label {
            display: flex;
            align-items: flex-start;
            padding-top: 6px;
            padding-right: 5px;
          }

          > div {
            flex: 1;
          }
        }
      }
    }

    pre {
      ${LowLightCss}

      border-radius: 4px;
      color: white;
      background: black;
      width: 100%;
      padding: 24px 16px;

      code {
        padding: 0;
        background: transparent;
        color: inherit;
      }
    }

    blockquote {
      margin: 0;
      margin-top: 12px;
      padding-left: 16px;
      border-color: ${(p) => p.theme.colors.border.primary};
      border-width: 0 0 0 4px;
      border-style: solid;

      p {
        ${typo.body400i}
      }
    }

    hr {
      position: relative;
      overflow: visible;

      &::before {
        background-color: ${(p) => p.theme.userColors.main};
        border-radius: 2px;
        bottom: -5px;
        content: ' ';
        left: -2px;
        opacity: 0;
        position: absolute;
        right: -2px;
        top: -5px;
        transition: opacity .1s;
      }

      &:hover::before {
        opacity: 0.1;
      }

      &.ProseMirror-selectednode::before {
        opacity: 0.2;
      }
    }

    span[data-type="emoji"] {
      font-size: 17px;
      > img {
        height: 1em;
      }
    }

    ${CollaborationCursorCSS}

    // Adds a gap between doc mentions separated by a line break
    br + .node-mention-docs > div {
      margin-top: 4px;
    }
  }
`;

export const Content = styled.div<{ fullHeight?: boolean; $highlightId?: string }>`
  ${EditorCSS}
  display: flex;
  flex-direction: column;

  /* Dirty selector to set that necessary height in a div without classname rendered by the lib */
  > :first-child:last-child {
    height: 100%;
  }
`;

interface StyledContentProps {
  $highlightId?: string;
}

export const StyledContent = styled(Content) <StyledContentProps>`
  ${p => p.$highlightId && css`
    .ProseMirror mark[${HIGHLIGHT_DATA_ID}="${p.$highlightId}"] {
      background-color: ${p.theme.userColors.markHighlight};
    }
  `}
`;

type EmptyBlockProps = {
  $hasBigHeight: boolean;
};
export const EmptyBlock = styled.div<EmptyBlockProps>`
  width: 100%;
  min-height: ${(p) => (p.$hasBigHeight ? '30vh' : '24px')};
  flex: 1;
`;

export const CommentEditorContent = styled(ShyScrollbar)<{ maxHeight?: number }>`
  ${EditorCSS};
  ${p => !!p.maxHeight && css`
    max-height: ${p.maxHeight}px;
  `}

  ${DocMentionTitleContainer} {
    max-width: 170px;
  }
`;

export const PreviewTemplateContainer = styled.div`
  position: absolute;
  right: 24px;
  width: 30vw;
  max-width: 586px;
  top: 24px;
  bottom: 24px;
  z-index: ${mappingZindex[Layer.Modal]};
`;

export const PreviewTemplateModal = styled(Modal)`
  max-width: 100%;
  max-height: 100%;
`;
