import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const BookIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="2 1 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M4.00001 14.5C3.99919 14.3028 4.03742 14.1074 4.11251 13.925C4.1876 13.7426 4.29806 13.577 4.43751 13.4375C4.57696 13.2981 4.74265 13.1876 4.92501 13.1125C5.10737 13.0374 5.3028 12.9992 5.50001 13H14V3.00001H5.50001C5.3028 2.99919 5.10737 3.03742 4.92501 3.11251C4.74265 3.1876 4.57696 3.29806 4.43751 3.43751C4.29806 3.57696 4.1876 3.74265 4.11251 3.92501C4.03742 4.10737 3.99919 4.3028 4.00001 4.50001V14.5Z" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 14.5V15H13" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);
