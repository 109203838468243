import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.secondary};
  margin-right: 16px;
`;
