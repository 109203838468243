import { DoctypeTemplateCategory } from '@cycle-app/graphql-codegen';
import { PenIcon } from '@cycle-app/ui/icons';
import { VFC, useCallback } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { Editor } from 'src/components/Editor';
import { TemplateCategoriesLabel } from 'src/constants/templates.constants';
import { useAddTemplate } from 'src/hooks/api/mutations/templatesHooks';
import { useDoctype } from 'src/hooks/api/useDocType';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { templateDisabledActions } from 'src/services/editor/editorActions';

import { EditorContainer } from '../TemplateModal.styles';
import {
  CancelButton,
  CategoryButton,
  Content,
  Header,
  SaveButton,
  TitleInput,
} from './TemplateEditor.style';
import useEditTemplate from './useEditTemplate';

interface Props {
  className?: string;
  onCancel: VoidFunction;
  onTemplateAdded: VoidFunction;
  modeAdmin?: boolean;
  docTypeId?: string;
}

const NewTemplateEditor: VFC<Props> = ({
  className,
  onCancel,
  onTemplateAdded,
  modeAdmin,
  docTypeId,
}) => {
  const doctype = useDoctype(docTypeId);
  const {
    add,
    loading,
  } = useAddTemplate(doctype);

  const {
    editingTemplate,
    setTitle,
    setContent,
    setContentJSON,
    setCategory,
  } = useEditTemplate({
    modeAdmin,
    docTypeId,
  });
  const [isConfirmationModalDisplayed, {
    setTrueCallback: showConfirmationModal,
    setFalseCallback: hideConfirmationModal,
  }] = useOptimizedBooleanState(false);

  const onCancelClicked = useCallback(() => {
    if (editingTemplate.title.length > 0 || editingTemplate.content !== '<p></p>') {
      showConfirmationModal();
    } else {
      onCancel();
    }
  }, [editingTemplate.title, editingTemplate.content, showConfirmationModal, onCancel]);

  const categories = Object.values(DoctypeTemplateCategory) as DoctypeTemplateCategory[];
  const catOptions = categories.map(c => ({
    label: TemplateCategoriesLabel[c],
    value: c,
  }));

  return (
    <Content className={className}>
      <Header>
        {modeAdmin && (
          <DropdownSelectLayer
            options={catOptions}
            selectedValue={editingTemplate.category}
            placement="bottom-start"
            onChange={({ value }) => {
              setCategory(value);
            }}
          >
            <CategoryButton size="L">
              <PenIcon />
              {TemplateCategoriesLabel[editingTemplate.category]}
            </CategoryButton>
          </DropdownSelectLayer>
        )}
        <TitleInput
          placeholder="My new doc template"
          value={editingTemplate.title}
          onChange={setTitle}
        />
        <CancelButton
          size="M"
          variant="secondary"
          onClick={onCancelClicked}
        >
          Cancel
        </CancelButton>
        <SaveButton
          size="M"
          disabled={!editingTemplate.title}
          onClick={onSave}
          isLoading={loading}
        >
          Save
        </SaveButton>
      </Header>
      <EditorContainer>
        <Editor
          onUpdate={({
            html,
            json,
          }) => {
            setContent(html);
            setContentJSON(json);
          }}
          disabledActions={templateDisabledActions}
          autoFocus
        />
      </EditorContainer>

      {isConfirmationModalDisplayed && (
        <DialogModal
          title="Are you sure you want to quit without saving ?"
          cancelLabel="Keep editing"
          confirmLabel="Leave"
          hide={hideConfirmationModal}
          onCancel={hideConfirmationModal}
          onConfirm={onCancel}
        />
      )}
    </Content>
  );

  async function onSave() {
    if (!doctype && !modeAdmin) return;

    await add({
      ...editingTemplate,
      doctypeId: !modeAdmin && doctype ? doctype.id : undefined,
    });
    onTemplateAdded();
  }
};

export default NewTemplateEditor;
