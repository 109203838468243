import { Skeleton } from '@cycle-app/ui';
import { Tabs as ReachTabs, TabList as ReachTabList, Tab } from '@reach/tabs';
import styled from 'styled-components';

export const Tabs = styled(ReachTabs)`
  margin-top: 21px;
  background-color: inherit;
`;

export const TabList = styled(ReachTabList)`
  background-color: inherit;
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  display: flex;
  gap: 28px;
  padding-top: 25px;
  padding-left: 24px;
  padding-right: 24px;
  position: sticky;
  top: -25px;
  z-index: 1;
`;

interface StyledTabProps {
  $isActive?: boolean;
}

export const StyledTab = styled(Tab)<StyledTabProps>`
  --border: transparent;
  align-items: center;
  display: flex;
  gap: 4px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--border);
  transition: border .1s;
  ${p => p.$isActive && `--border: ${p.theme.userColors.main};`}
`;

export const HierarchyContainer = styled.div`
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const CounterSkeleton = styled(Skeleton)`
  width: 70%;
`;
