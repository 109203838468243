import { make } from 'src/utils/reactives.util';

interface UserModal {
  visible: boolean;
}

export const {
  hookState: useSettingsModal,
  getValue: getSettingsModal,
  setValue: setSettingsModal,
} = make<UserModal>({
  defaultState: {
    visible: false,
  },
});
