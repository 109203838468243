import { IntegrationIllustrationCard } from 'src/components/IllustationCards';
import { useAnimateIntegrationsIllustration } from 'src/hooks/useAnimateIntegrationsIllustration';

import {
  Integrations,
  CardTitle,
  CardDesc,
  CardContent,
} from './BillingUpdateFeatures.styles';

export const BillingUpdateIntegrationCard = () => {
  const {
    onMouseEnter,
    onMouseLeave,
    ...props
  } = useAnimateIntegrationsIllustration();
  return (
    <Integrations onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <IntegrationIllustrationCard {...props} />
      <CardContent>
        <CardTitle>Unlimited integrations</CardTitle>
        <CardDesc>Activate all the integrations you need</CardDesc>
      </CardContent>
    </Integrations>
  );
};
