import { GithubRepositoryFragment } from '@cycle-app/graphql-codegen';
import { CaretIcon } from '@cycle-app/ui/icons';
import { useEffect, FC, useMemo } from 'react';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useGithubRepositories } from 'src/hooks/api/queries/integrations/useGithubRepositories';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { isGithubRepository } from 'src/utils/integrations.utils';

import { RepositorySelect, LoadingState } from './GithubRepositoriesSearchDropdown.style';

type Props = {
  selectedRepoId: string;
  isDisabled: boolean;
  onSelect: (repository: GithubRepositoryFragment) => void;
};

export const GithubRepositoriesSearchDropdown: FC<Props> = ({
  onSelect, selectedRepoId, isDisabled,
}) => {
  const {
    repositories, isLoading,
  } = useGithubRepositories();
  const [isDropdownVisible, {
    toggleCallback: onToggleDropdown,
    setFalseCallback: onHideDropdown,
  }] = useOptimizedBooleanState(false);

  const options = useMemo(() => {
    if (!repositories?.length) return [];

    return repositories
      .filter(isGithubRepository)
      .map(repo => ({
        value: repo.id,
        label: repo.name,
      }));
  }, [repositories]);

  const selectedRepo = repositories && selectedRepoId
    ? repositories.find(r => r?.id === selectedRepoId)
    : undefined;

  useEffect(() => {
    if (selectedRepoId || !repositories?.[0]?.id) return;

    onSelect(repositories[0]);
  }, [onSelect, repositories, selectedRepoId]);

  if (isLoading) return renderSelectButton();

  return (
    <DropdownSelectLayer
      layer={Layer.DropdownBoardConfig}
      placement="bottom"
      options={options}
      visible={isDropdownVisible}
      hide={onHideDropdown}
      onChange={(repository) => {
        const repo = repositories?.find(r => r?.id === repository.value);

        if (repo && !isDisabled) {
          onSelect(repo);
          onHideDropdown();
        }
      }}
    >
      {renderSelectButton()}
    </DropdownSelectLayer>
  );

  function renderSelectButton() {
    return (
      <RepositorySelect onClick={() => {
        if (isDisabled) return;
        onToggleDropdown();
      }}
      >
        {(isLoading || !selectedRepo?.name)
          ? <LoadingState />
          : (
            <>
              {selectedRepo?.name}
              <CaretIcon />
            </>
          )}
      </RepositorySelect>
    );
  }
};
