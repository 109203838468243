import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';

import { isDefaultCustomer } from '../../utils/customers.util';
import { CustomerName } from './CustomerName';
import { Header } from './CustomerProfile.styles';

export interface CustomerProfileHeaderProps {
  className?: string;
  customer: CustomerFragment;
}

export const CustomerProfileHeader = ({
  className, customer,
}: CustomerProfileHeaderProps) => (
  <Header className={className}>
    <CustomerName customer={customer} readOnly={isDefaultCustomer(customer)} />
  </Header>
);
