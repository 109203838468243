import { useCallback, useMemo } from 'react';

import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  getActiveProductTourEl,
  getActiveProductTourStep,
  setActiveProductTour,
  useActiveProductTour,
  getActiveProductTourNextStep,
} from 'src/reactives/productTours.reactive';
import { ProductTourLock, TourName, TourStep } from 'src/types/productTour.types';

import { useLockProductTour } from './useLockProductTour';

export const useInboxZeroProductTour = () => {
  const tour = useActiveProductTour();
  const { isEnabled } = useFeatureFlag(FeatureFlag.FirstProductTour);

  const { locks } = useLockProductTour(tour?.name);

  const isActive = isEnabled && tour?.name === TourName.FIRST_TOUR;

  const shouldShowWelcomeToInboxCopy = useMemo(
    () => tour?.name === TourName.FIRST_TOUR && tour.step === TourStep.CreateFirstFeedback,
    [tour?.name, tour?.step],
  );

  const startFirstProductTour = useCallback((el?: HTMLElement) => {
    if (
      !el ||
      getActiveProductTourStep() !== TourStep.CreateFirstFeedback ||
      el === getActiveProductTourEl()
    ) return;

    setActiveProductTour({ el });
  }, []);

  const setSeePropertiesStep = () => {
    if (
      !isActive ||
      tour.step !== TourStep.CreateFirstFeedback
    ) return;

    const nextStep = getActiveProductTourNextStep();

    if (!nextStep) return;

    setActiveProductTour({ step: nextStep });
  };

  return {
    isActive,
    setSeePropertiesStep,
    shouldForceEmptyInboxState: locks.includes(ProductTourLock.SHOW_EMPTY_INBOX),
    shouldShowWelcomeToInboxCopy,
    startFirstProductTour,
  };
};
