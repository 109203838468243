import styled, { css } from 'styled-components';

import { Label } from '../CheckboxInput/CheckboxInput.styles';

const DotSize = 16;
const CenterDotSize = 8;
const BorderWidth = 1;
const dotMargin = (DotSize - CenterDotSize) / 2 - BorderWidth;

export const Dot = styled.div`
  background: var(--bg);
  border: ${BorderWidth}px solid var(--border);
  border-radius: 50%;
  width: ${DotSize}px;
  height: ${DotSize}px;
  position: relative;

  &::after {
    content: '';
    display: block;
    height: ${CenterDotSize}px;
    width: ${CenterDotSize}px;
    border-radius: 50%;
    background-color: var(--innerColor);
    position: absolute;
    left: ${dotMargin}px;
    top: ${dotMargin}px;
    z-index: 1;
  }
`;

export const StyledInput = styled.input`
  /* native input should be hidden, but still rendered to be focusable */
  opacity: 0;
  width: 0;
  height: 0;
  /* out of the flow to prevent issues on flex/grid layouts */
  position: absolute;

  &:disabled + ${Label} {
    --bg: ${p => p.theme.colors.background.disabled};
    --color: ${p => p.theme.colors.text.disabled};
  }

  &:focus + ${Label} {
    --bg: ${p => p.theme.colors.inputRadio.bg};
    --border: ${p => p.theme.userColors.main};
    --innerColor: ${p => p.theme.colors.text.white};
  }
  
  &:checked + ${Label} {
    --bg: ${p => p.theme.userColors.main};
    --border: ${p => p.theme.userColors.main};
    --innerColor: ${p => p.theme.colors.text.white};
  }
`;

interface ContainerProps {
  $isDisabled: boolean;
}

export const Container = styled.label<ContainerProps>`
  --bg: ${p => p.theme.colors.inputRadio.bg};
  --border: ${p => p.theme.colors.border.input};
  --color: ${p => p.theme.colors.text.primary};
  --innerColor: transparent;

  ${p => p.$isDisabled && css`
    pointer-events: none;
    opacity: 0.75;
  `};

  ${p => !p.$isDisabled && css`
    &:hover {
      --border: ${p.theme.userColors.main};
    }
  `}
`;
