import { typo } from '@cycle-app/ui';
import { mobile, tablet } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AsideContainer = styled(motion.div)`
  align-items: center;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  position: absolute;
  width: 100%;
  top: 35%;

  ${tablet} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Content = styled.p`
  ${typo.body400};
  margin-top: 24px;
`;

export const Cover = styled.div`
  align-items: flex-end;
  display: flex;
  height: 30vh;
  justify-content: center;
  max-height: 350px;
  min-height: 190px;
`;

export const CoverImg = styled.img`
  ${mobile} {
    width: 100%;
    max-width: 350px;
  }
`;

export const CoverImgStep2 = styled.img`
  ${mobile} {
    width: 100%;
    max-width: 245px;
    margin-bottom: 24px;
  }
`;

export const CoverImgStep3 = styled.img`
  ${mobile} {
    width: 100%;
    max-width: 280px;
  }
`;

export const CoverStep2 = styled(Cover)`
  min-height: 230px;
`;

export const AsideContainerStep2 = styled(AsideContainer)`
  padding-left: 60px;
  padding-right: 60px;
  ${tablet} {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const AsideContainerStep3 = styled(AsideContainer)`
  img {
    margin-top: -20px;
  }
`;

export const AsideContainerStep4 = styled(AsideContainer)`
  padding-left: 71px;
  padding-right: 76px;
  ${tablet} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;
