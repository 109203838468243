import { ViewType } from '@cycle-app/graphql-codegen/generated';
import { Group, GroupContainer, Emoji } from '@cycle-app/ui';
import { FC } from 'react';

import { BoardWrapper, Top, Side } from 'src/app/Main/Board/Board.styles';
import { Container, Content } from 'src/app/Main/Board/BoardContent/BoardContent.styles';

import { BoardNameSkeleton, StyledContentList, BoardHeader } from './PreviewBoard.style';
import { PreviewCard } from './PreviewCard';

const groups = [{
  id: 'design',
  name: '🎨 In design',
  itemsCount: 6,
}, {
  id: 'dev',
  name: '🤖 In dev',
  itemsCount: 5,
}];

export const PreviewBoard: FC = () => (
  <BoardWrapper>
    <Top>
      <Side>
        <BoardHeader>
          <Emoji emoji="cherry_blossom" />
          <BoardNameSkeleton />
        </BoardHeader>
      </Side>
    </Top>
    <Container>
      <Content viewType={ViewType.Kanban}>
        <GroupContainer viewType={ViewType.Kanban}>
          {groups.map(group => (
            <Group
              key={group.id}
              inputName={<h2>{group.name}</h2>}
              viewType={ViewType.Kanban}
              withGroupBy
            >
              <StyledContentList>
                {[...Array(group.itemsCount).keys()].map((i) => (
                  <PreviewCard key={i} emoji="cherry_blossom" viewType={ViewType.Kanban} />
                ))}
              </StyledContentList>
            </Group>
          ))}
        </GroupContainer>
      </Content>
    </Container>
  </BoardWrapper>
);
