import { Emoji } from '@cycle-app/ui';
import { EmojiData } from 'emoji-mart';
import { FC } from 'react';

import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { EmojiPickerStyled } from './BoardHeader.styles';

type BoardHeaderEmojiProps = {
  boardId: string;
  emoji: string;
};

export const BoardHeaderEmoji: FC<BoardHeaderEmojiProps> = ({
  boardId,
  emoji,
}) => {
  const { canUpdateBoard } = useGetPermission();
  const { changeBoardEmoji } = useBoardMutations();

  if (canUpdateBoard) {
    return (
      <EmojiPickerStyled
        dropdownProps={{ placement: 'bottom-start' }}
        onSelect={async (emojiData: EmojiData) => {
          if (!emojiData.id) return;
          await changeBoardEmoji(boardId, emojiData.id);
        }}
      >
        <Emoji emoji={emoji} size={18} />
      </EmojiPickerStyled>
    );
  }

  return <Emoji emoji={emoji} size={18} />;
};
