import { EdgeProps } from 'react-flow-renderer';

import { AnimatedEdge } from './AnimatedEdge';

export const CollabEdge = (props: EdgeProps) => (
  <AnimatedEdge
    {...props}
    initialDelay={3}
    frontOptions={(delay) => ({
      ease: 'linear',
      duration: 2,
      delay: delay ?? 5,
    })}
    backOptions={() => {
      const r = Math.random() * 0.7;
      return {
        ease: 'linear',
        duration: 2 - r,
        delay: 1 + r,
      };
    }}
  />
);
