import { tablet } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

interface SidebarWidth {
  $sidebarWidth: number;
}

export const Container = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 0;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  align-items: flex-start;
  gap: 16px;
`;

export const BulkActionsContainer = styled.div<SidebarWidth & { $isModalOpen: boolean }>`
  position: absolute;
  z-index: ${mappingZindex[Layer.Toaster]};

  left: calc(50% + ${p => p.$sidebarWidth / 2}px);
  transform: translateX(-50%);

  ${tablet} {
    position: relative;
    left: auto;
    transform: none;
  }

  ${p => p.$isModalOpen && css`
    display: none;
  `};
`;

export const ToastersContainer = styled.div`
  position: absolute;
  z-index: ${mappingZindex[Layer.Toaster]};
  right: 0;
`;

export const ErrorToastersContainer = styled.div<{ placeLeft: boolean } & SidebarWidth>`
  position: absolute;
  z-index: ${mappingZindex[Layer.Toaster]};

  ${p => p.placeLeft && css`
    left: ${p.$sidebarWidth}px;
  `}
  ${p => !p.placeLeft && css`
    left: calc(50% + ${p.$sidebarWidth / 2}px);
    transform: translateX(-50%);
  `}

  ${tablet} {
    position: relative;
    left: auto;
    transform: none;
  }
`;
