import { CycleLogo } from '@cycle-app/ui';
import { FigmaIcon, MiroIcon, LoomIcon, PitchIcon } from '@cycle-app/ui/icons';
import { useMeasure } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import { useMemo, FC } from 'react';
import ReactFlow, { ReactFlowProps, Position } from 'react-flow-renderer';

import { CollabEdge } from 'src/app/Main/Settings/SettingsIntegrations/Edges/CollabEdge';
import { SourceEdge } from 'src/app/Main/Settings/SettingsIntegrations/Edges/SourceEdge';
import { FrontEndIntegration } from 'src/types/integrations.types';
import { integrationsData } from 'src/utils/integrations.utils';

import { CustomNode, CustomNodeData } from './CustomNode';
import { FlowContainer } from './StepIntegrations.styles';
import { useIntegrationsByType } from './useIntegrationsByType';

const LOGO_SIZE = 110;
const NODE_SIZE = 46;
const NODE_MARGIN = 20;

const PRE_INSTALLED = [FigmaIcon, MiroIcon, PitchIcon, LoomIcon];

type FlowElements = ReactFlowProps['elements'];
type FlowElement = FlowElements[number];
type FlowCustomNodeElement = FlowElements[number] & { data: CustomNodeData };

interface Props {
  className?: string;
  isCover?: boolean;
  isReadOnly?: boolean;
}

export const IntegrationsFlow: FC<Props> = ({
  className, isCover, isReadOnly,
}) => {
  const integrations = useIntegrationsByType(isReadOnly);
  const {
    ref: flowRef,
    rect: flowRect,
  } = useMeasure<HTMLDivElement>();

  const sources = integrations.sources.filter(source => (
    !isReadOnly ||
    (FrontEndIntegration.CHROME === source.type) ||
    (FrontEndIntegration.ZAPIER === source.type) ||
    (isReadOnly && source.integration?.provider)));
  const collaboration = integrations.collaboration.filter(collab => (
    !isReadOnly || (isReadOnly && collab.integration?.provider)));

  const nodeMargin = isCover ? 10 : NODE_MARGIN;
  const sourcesCount = sources.length || 1;
  const collabCount = collaboration.length + PRE_INSTALLED.length;

  const elements = useMemo<FlowElements>(() => {
    if (!flowRect) return [];

    const {
      width, height,
    } = flowRect;

    const flowMargin = width / 10;

    const sourcesHeight = sourcesCount * (NODE_SIZE + nodeMargin) - nodeMargin;
    const sourcesTop = height / 2 - sourcesHeight / 2;

    const collabHeight = collabCount * (NODE_SIZE + nodeMargin) - nodeMargin;
    const collabTop = height / 2 - collabHeight / 2;

    const logoNode: FlowCustomNodeElement = {
      id: 'logo',
      data: {
        label: (
          <motion.div
            initial={{
              scale: 0,
              rotate: '-280deg',
            }}
            animate={{
              scale: 1,
              rotate: '-100deg',
            }}
            transition={{
              duration: 1,
              ease: 'easeOut',
            }}
            onAnimationComplete={() => {
              if (flowRef.current) {
                flowRef.current.setAttribute('data-loaded', 'true');
              }
            }}
          >
            <CycleLogo animation="loop" size={LOGO_SIZE} color="blue" />
          </motion.div>
        ),
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      position: {
        x: width / 2 - LOGO_SIZE / 2,
        y: height / 2 - LOGO_SIZE / 2,
      },
      type: 'default',
    };

    const sourcesNodes = sources.map<FlowCustomNodeElement>((source, i) => ({
      id: `source${i}`,
      data: {
        label: integrationsData[source.type].icon,
        installed: !!source.integration?.provider,
        isReadOnly,
      },
      sourcePosition: Position.Right,
      position: {
        x: flowMargin,
        y: sourcesTop + i * (NODE_SIZE + nodeMargin),
      },
      type: 'source-node',
    }));

    const collabLabels = [
      ...collaboration.map(collab => ({
        getLabel: () => integrationsData[collab.type].icon,
        installed: !!collab.integration?.provider,
      })),
      ...PRE_INSTALLED.map(Icon => ({
        getLabel: () => <Icon />,
        installed: true,
      })),
    ];

    const collabNodes = collabLabels.map<FlowCustomNodeElement>(({
      getLabel, installed,
    }, i) => ({
      id: `collab${i}`,
      data: {
        label: getLabel(),
        installed,
        isReadOnly,
      },
      targetPosition: Position.Left,
      position: {
        x: width - NODE_SIZE - flowMargin,
        y: collabTop + i * (NODE_SIZE + nodeMargin),
      },
      type: 'collab-node',
    }));

    const edges: FlowElement[] = [
      ...sourcesNodes.map(node => ({
        id: `${node.id}-logo`,
        source: node.id,
        target: 'logo',
        type: 'source-edge',
      })),
      ...collabNodes.map(node => ({
        id: `logo-${node.id}`,
        source: 'logo',
        target: node.id,
        type: 'collab-edge',
      })),
    ];

    return [...sourcesNodes, logoNode, ...collabNodes, ...edges].map(element => ({
      ...element,
      className: 'nodrag',
    }));
  }, [collabCount, collaboration, flowRect, nodeMargin, sources, sourcesCount, isReadOnly, flowRef]);

  const maxItemsCount = Math.max(sourcesCount, collabCount);

  return (
    <FlowContainer
      className={className}
      ref={flowRef}
      $isCover={isCover}
      style={{ height: isCover ? `${maxItemsCount * (NODE_SIZE + nodeMargin)}px` : '100%' }}
    >
      <ReactFlow
        elements={elements}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        paneMoveable={false}
        preventScrolling={false}
        nodeTypes={{
          'source-node': CustomNode,
          'collab-node': CustomNode,
        }}
        edgeTypes={{
          'source-edge': SourceEdge,
          'collab-edge': CollabEdge,
        }}
      />
    </FlowContainer>
  );
};
