import { typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import DocAction from 'src/components/DocAction/DocAction';
import DocParentDropdown from 'src/components/DocParentDropdown/DocParentDropdown';
import SavingLabel from 'src/components/SavingLabel/SavingLabel';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const DocTitle = styled(motion.div)`
  ${typo.body500};
`;

export const ExpandedActions = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SavingLabelStyled = styled(SavingLabel)`
  margin-left: 4px;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.caption500}
`;

export const AddCoverAction = styled(DocAction) <{ userColor?: boolean }>`
  display: flex;
  gap: 7px;

  ${p => p.userColor && css`
    color: ${p.theme.userColors.main};
  `};
`;

export const StyledDocPanelBreadcrumb = styled(DocParentDropdown)`
  padding: 8px;
`;
