import { MateWithProductRoleFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import useProductMembersMutations from 'src/hooks/api/mutations/useProductMembersMutations';
import { useProduct } from 'src/hooks/api/useProduct';

import {
  UserBlock,
  UserTag,
  UserAvatarTag,
  WarningLine,
  Logo,
} from './SettingsUserModal.styles';
import { UserAvatar, FullName, Email } from './SettingsUsers.styles';

interface SettingsUserRemoveUserModalProps {
  user: MateWithProductRoleFragment;
  onClose: () => void;
}

const SettingsUserRemoveUserModal: FC<SettingsUserRemoveUserModalProps> = ({
  user, onClose,
}) => {
  const { product } = useProduct();
  const {
    removeUser,
    isRemoveUserLoading,
  } = useProductMembersMutations();

  if (!product) return null;

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <DialogModal
      confirmLabel="Remove"
      hide={onClose}
      loading={isRemoveUserLoading}
      onConfirm={onConfirmRemove}
      title="Remove user"
      info={(
        <>
          <UserBlock>
            <UserAvatar user={user} />
            <div>
              <FullName>{fullName}</FullName>
              <Email>{user.email}</Email>
            </div>
          </UserBlock>
          <WarningLine>
            <span>
              Are you sure you want to remove
              <UserTag limitSize icon={<UserAvatarTag size={16} user={user} />}>{fullName.trim() ? fullName : user.email}</UserTag>
              ?
            </span>
            <br />
            <span>
              (S)he will lose access to
              <UserTag limitSize={false} icon={product.logo?.url && <Logo src={product.logo.url} width="16" height="16" />}>{product.name}</UserTag>
            </span>
          </WarningLine>
        </>
      )}
    />
  );

  async function onConfirmRemove() {
    if (!isRemoveUserLoading && user.productRole) {
      await removeUser(user.id, user.productRole);
      onClose();
    }
  }
};

export default SettingsUserRemoveUserModal;
