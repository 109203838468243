import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMe } from './api/useMe';

declare global {
  interface Window {
    intercomSettings: Record<string, string>;
    Intercom: (action: string, params?: Record<string, string>) => void;
  }
}

export const useIntercom = () => {
  const location = useLocation();
  const { me } = useMe();

  const setup = useRef(false);

  useEffect(() => {
    if (
      !setup.current &&
      process.env.INTERCOM_KEY &&
      window.Intercom
    ) {
      window.intercomSettings = {
        app_id: process.env.INTERCOM_KEY,
        alignment: 'right',
      };

      window.Intercom('boot', {
        name: `${me.firstName} ${me.lastName}`,
        email: me.email,
      });

      setup.current = true;
    }
  }, [me]);

  useEffect(() => {
    if (process.env.INTERCOM_KEY && window.Intercom) {
      window.Intercom('update');
    }
  }, [location.pathname]);
};
