import type { DoctypeRelativeFragment } from '@cycle-app/graphql-codegen';
import { SelectLine, Emoji } from '@cycle-app/ui';
import type { ItemPropsFunction } from '@cycle-app/utilities';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useOptimizedBooleanState } from 'src/hooks';
import { Layer } from 'src/types/layers.types';

import {
  CREATE_VALUE_MORE,
  CREATE_VALUE_PREFIX,
} from './DocSearchDropdown.constants';
import {
  DocTypesContent,
  NewItemButton,
  OtherDocTypesSpinner,
} from './DocSearchDropdown.styles';

export type OtherDocTypeButtonProps = {
  isLoading: boolean;
  docTypes: {
    id: DoctypeRelativeFragment['id'];
    name: DoctypeRelativeFragment['name'];
    emoji: DoctypeRelativeFragment['emoji'];
  }[];
  getItemProps: ItemPropsFunction;
  isHoverDisabled: boolean;
  selected: string;
  onSelect: (optionId: string | null) => void;
};

export const OtherDocTypeButton = ({
  docTypes,
  getItemProps,
  isHoverDisabled,
  isLoading,
  selected,
  onSelect,
}: OtherDocTypeButtonProps) => {
  const [isVisible, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(false);

  return (
    <DropdownLayer
      visible={isVisible}
      hide={setFalseCallback}
      placement="bottom"
      content={(
        <DocTypesContent>
          {docTypes.map(d => (
            <SelectLine
              key={d.id}
              label={d.name}
              startSlot={<Emoji emoji={d.emoji} />}
              onClick={e => {
                e.stopPropagation();
                onSelect(`${CREATE_VALUE_PREFIX}${d.id}`);
                setFalseCallback();
              }}
            />
          ))}
        </DocTypesContent>
      )}
      layer={Layer.DropdownModalZ3}
    >
      <NewItemButton
        $isGray
        variant="secondary"
        isSelected={selected === CREATE_VALUE_MORE}
        hoverDisabled={isHoverDisabled}
        useUserColor={false}
        forceFocus={isVisible}
        {...getItemProps(CREATE_VALUE_MORE)}
        onClick={e => {
          e.stopPropagation();
          setTrueCallback();
        }}
      >
        {isLoading ? <OtherDocTypesSpinner /> : `+${docTypes.length}`}
      </NewItemButton>
    </DropdownLayer>
  );
};
