import { PropertiesFragment, FilterPropertyRuleEdgesFragment, FilterValueInput, Operator } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import memoize from 'fast-memoize';
import { useCallback, useMemo } from 'react';

import useBoardConfigFilterMutations from 'src/hooks/api/mutations/boardConfig/useBoardConfigFilterMutations';

interface Options {
  boardConfigId: string;
  allFilterableProperties: PropertiesFragment;
  filterProperties: FilterPropertyRuleEdgesFragment;
  boardID?: string;
  onComplete?: VoidFunction;
}
export const useBoardConfigFilters = ({
  boardConfigId,
  allFilterableProperties,
  filterProperties,
  boardID,
  onComplete,
}: Options) => {
  const {
    loadingChangeOperator,
    addFilterRule,
    removeFilterRule,
    changeFilterRuleAttribute,
    changeFilterRuleOperator,
    addFilterValue,
    removeFilterValue,
  } = useBoardConfigFilterMutations(boardConfigId, boardID, onComplete);

  const filters = useMemo(
    () => nodeToArray(filterProperties).filter(({ __typename }) => __typename !== 'FilterPropertyRuleDoctype'),
    [filterProperties],
  );
  const filterableProperties = useMemo(
    // Date filter will be implemented later
    () => nodeToArray(allFilterableProperties).filter(p => p.__typename !== 'AttributeDateDefinition'),
    [allFilterableProperties],
  );

  const changeFilter = useCallback(async (filterId: string, valueId: string) => {
    const propertyAdded = filterableProperties.find(p => p.id === valueId);
    if (propertyAdded) {
      await changeFilterRuleAttribute(filterId, propertyAdded);
    }
  }, [changeFilterRuleAttribute, filterableProperties]);

  const addFirstFilterAvailable = useCallback(async () => {
    if (!filterableProperties[0]) return;
    await addFilterRule(filterableProperties[0]);
  }, [filterableProperties, addFilterRule]);

  const onAttributeUpdated = useMemo(
    () => memoize((filterId: string) => (option: SelectOption) => changeFilter(filterId, option.value)),
    [changeFilter],
  );
  const onValueAdded = useMemo(
    () => memoize((filterId: string) => (input: FilterValueInput) => addFilterValue(filterId, input)),
    [addFilterValue],
  );
  const onValueRemoved = useMemo(
    () => memoize((filterId: string) => (valueId: string) => removeFilterValue(filterId, valueId)),
    [removeFilterValue],
  );
  const onOperatorUpdated = useMemo(
    () => memoize((filterId: string) => (operator: Operator) => changeFilterRuleOperator(filterId, operator)),
    [changeFilterRuleOperator],
  );
  const onFilterDeleted = useMemo(
    () => memoize((filterId: string) => () => removeFilterRule(filterId)),
    [removeFilterRule],
  );

  return {
    loadingChangeOperator,
    filterableProperties,
    filters,
    addFirstFilterAvailable,
    onValueAdded,
    onValueRemoved,
    onOperatorUpdated,
    onFilterDeleted,
    onAttributeUpdated,
  };
};
