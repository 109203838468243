import { FC } from 'react';

import RealtimeUsers from 'src/components/RealtimeUsers/RealtimeUsers';
import { useProductPresence } from 'src/hooks';

const BoardRealtimeUsers: FC = () => {
  const users = useProductPresence();

  return (
    <RealtimeUsers
      users={users}
      showLocation
    />
  );
};

export default BoardRealtimeUsers;
