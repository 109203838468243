import { FetchResult } from '@apollo/client';
import { UpdateDocCoverMutation } from '@cycle-app/graphql-codegen';
import { RefObject, useCallback, useRef } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import { DocPanelBaseProps } from 'src/app/Main/Board/DocPanel/DocPanel.types';

import { useUpdateDocTitle } from './api/mutations/updateDocHooks';
import { useFullDoc } from './api/useDoc';
import useDocCoverDropzone from './doc/useCoverDropzone';

interface DocPanelProps extends DocPanelBaseProps {
  getDropzonePropsTopBar: () => DropzoneRootProps;
  updateDocCover: (cover: File) => Promise<FetchResult<UpdateDocCoverMutation, Record<string, any>, Record<string, any>>>;
  coverInputRef: RefObject<HTMLInputElement>;
  isLoading: boolean;
}

export const useDocPanelProps = (draft?: boolean, id?: string): DocPanelProps => {
  const {
    doc, loading,
  } = useFullDoc({
    draft,
    docId: id,
  });
  const docId = doc?.id ?? '';

  const { updateDocTitle } = useUpdateDocTitle();
  const onTitleUpdated = useCallback(async (updatedTitle: string) => {
    if (!docId) return;
    await updateDocTitle({
      docId,
      title: updatedTitle,
    });
  }, [docId, updateDocTitle]);

  const coverInputRef = useRef<HTMLInputElement>(null);

  const {
    getRootProps: getDropzonePropsTopBar,
    isDragActive: isDragActiveFromTopBar,
    isUploadingCover: isUploadingCoverFromTopBar,
    updateDocCover,
  } = useDocCoverDropzone({ docId });
  const {
    getRootProps: getDropzonePropsCover,
    isDragActive: isDragActiveFromCover,
    isUploadingCover: isUploadingCoverFromCover,
  } = useDocCoverDropzone({ docId });

  const isDraggingCover = isDragActiveFromTopBar || isDragActiveFromCover;
  const isUploadingCover = isUploadingCoverFromTopBar || isUploadingCoverFromCover;

  const onOpenCoverInputFile = useCallback(() => coverInputRef.current?.click(), []);

  return {
    doc,
    onTitleUpdated,
    getDropzonePropsTopBar,
    getDropzonePropsCover,
    isDraggingCover,
    isLoading: loading,
    isUploadingCover,
    onOpenCoverInputFile,
    updateDocCover,
    coverInputRef,
    mode: draft ? 'new-doc' : 'edit-doc',
  };
};
