import { SetupMyPasswordDocument } from '@cycle-app/graphql-codegen';
import { OnboardingLayout, LoginIllustration } from '@cycle-app/ui';
import React, { FC } from 'react';

import { useProductFromInitial } from 'src/hooks/api/useProductFromInitial';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setOnboarding } from 'src/reactives/onboarding.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';

import { SubtitleLoadingState } from '../OnboardingView.styles';
import { StyledNewPasswordForm } from './StepCreatePassword.styles';

type Props = {
  progress: number;
  isReadOnly?: boolean;
};

export const StepCreatePassword: FC<Props> = ({
  progress, isReadOnly,
}) => {
  const { product } = useProductFromInitial();
  const [setupPassword, { loading: isSubmitLoading }] = useSafeMutation(SetupMyPasswordDocument);

  return (
    <StyledNewPasswordForm
      onSubmit={onSubmit}
      submitLabel="Next"
      isLoading={isSubmitLoading}
      showSubmit={false}
      Wrapper={({ children }) => (
        <OnboardingLayout
          title="Create your password"
          description={!product ? <SubtitleLoadingState /> : `to join ${product?.name} workspace`}
          main={children}
          aside={<LoginIllustration plugged />}
          progress={progress}
          nextButtonProps={{
            isLoading: isSubmitLoading,
            type: 'submit',
          }}
          isModal={isReadOnly}
        />
      )}
    />
  );

  async function onSubmit(password: string) {
    await setupPassword({ variables: { password } });
    setOnboarding({ screen: OnboardingScreen.Colors });
  }
};
