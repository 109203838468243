import { UserIcon, IncognitoIcon, LogoutIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import { VFC, useCallback, useMemo } from 'react';

import MyAvatar from 'src/components/MyAvatar/MyAvatar';
import { useChangeIncognito } from 'src/hooks/api/mutations/useChangeIncognito';
import { useMe } from 'src/hooks/api/useMe';
import { useLogout } from 'src/hooks/useLogout';
import { useGetBillingPermission } from 'src/reactives/permission.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { getSettingsModal, setSettingsModal } from 'src/reactives/settingsModal.reactive';
import { useGetSidebar } from 'src/reactives/sidebar.reactive';
import { useIsEclipseTheme } from 'src/reactives/theme.reactive';

import { setLimitationsModal } from '../../reactives/limitationsModal.reactive';
import {
  Container,
  Header,
  Options,
  UserInfo,
  Username,
  UserEmail,
  HeaderItem,
  StyledSelectLine,
} from './UserMenu.styles';

interface Props {
  onItemClicked: VoidFunction;
}
const UserMenu: VFC<Props> = ({ onItemClicked }) => {
  const isEclipseTheme = useIsEclipseTheme();
  const { changeIncognito } = useChangeIncognito();
  const { me } = useMe();
  const { width: sidebarWidth } = useGetSidebar();
  const logout = useLogout();
  const isMobile = useIsMobile();
  const { canGoIncognito } = useGetBillingPermission();

  const toggleIncognito = useCallback(() => changeIncognito(!me.incognito), [changeIncognito, me.incognito]);

  const menuItems = useMemo(() => [
    {
      id: 'header-item',
      onSelect: isMobile ? undefined : toggleSettingsModal,
    },
    ...isMobile ? [] : [{
      id: 'account',
      icon: (<UserIcon />),
      label: 'My account',
      onSelect: toggleSettingsModal,
    }],
    {
      id: 'incognito',
      icon: <IncognitoIcon />,
      label: me.incognito ? 'Leave incognito' : 'Go incognito',
      onSelect: async () => {
        if (canGoIncognito) {
          await toggleIncognito();
          return;
        }
        setLimitationsModal({
          action: 'INCOGNITO',
        });
      },
    },
    {
      id: 'logout',
      icon: (<LogoutIcon />),
      label: 'Log out',
      onSelect: logout,
      variant: 'danger' as const,
    },
  ], [logout, me.incognito, isMobile, toggleIncognito, canGoIncognito]);

  const optionsValues = useMemo(
    () => menuItems.map(item => item.id),
    [menuItems],
  );

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    value: null,
    optionsValues,
    onSelect: (itemId) => {
      const item = menuItems.find(i => i.id === itemId);
      item?.onSelect?.();
      onItemClicked();
    },
  });

  return (
    <Container
      $width={sidebarWidth + 10}
      {...listProps}
    >
      <Header>
        <HeaderItem
          selected={selected === 'header-item'}
          {...itemProps('header-item')}
        >
          <MyAvatar oppositeColor={isEclipseTheme} />
          <UserInfo>
            <Username>
              {`${me.firstName} ${me.lastName}`}
            </Username>
            <UserEmail>
              {me.email}
            </UserEmail>
          </UserInfo>
        </HeaderItem>
      </Header>
      <Options>
        {menuItems
          .filter(item => !!item.label)
          .map(item => (
            <StyledSelectLine
              key={item.id}
              isSelected={selected === item.id}
              startSlot={item.icon}
              label={item.label}
              variant={item.variant}
              {...itemProps(item.id)}
            />
          ))}
      </Options>
    </Container>
  );
};

function toggleSettingsModal() {
  setSettingsModal({ visible: !getSettingsModal().visible });
}

export default UserMenu;
