import { DocBaseFragment, ViewType } from '@cycle-app/graphql-codegen/generated';
import { Group, GroupContainer, Spinner, ViewCard } from '@cycle-app/ui';
import { CommentIcon } from '@cycle-app/ui/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { FC } from 'react';

import { BoardWrapper, Top, Side } from 'src/app/Main/Board/Board.styles';
import { Container, Content } from 'src/app/Main/Board/BoardContent/BoardContent.styles';
import DocPrimaryAttributes from 'src/components/DocPrimaryAttributes/DocPrimaryAttributes';
import { useBoard } from 'src/hooks/api/useBoard';
import { BoardGroup } from 'src/hooks/api/useBoardGroups';

import { BoardHeader } from '../../BoardHeader/BoardHeader';
import DocAssignee from '../../DocAssignee/DocAssignee';
import { ActionsContainerSkeleton, StyledContentList } from './StepCreateDocs.styles';

interface Props {
  boardId: string;
  createdDocs: DocBaseFragment[];
  viewType: ViewType;
  groups: [string, BoardGroup][];
  isGrouped: boolean;
}

export const StepCreateDocsBoard: FC<Props> = ({
  boardId, createdDocs, groups, viewType, isGrouped,
}) => {
  const board = useBoard(boardId ?? '');
  if (!board) {
    return (
      <BoardWrapper>
        <Top>
          <Side>
            <BoardHeader>
              <Spinner />
            </BoardHeader>
          </Side>
        </Top>
      </BoardWrapper>
    );
  }
  return (
    <BoardWrapper>
      <Top>
        <Side>
          <BoardHeader>
            {board.name}
          </BoardHeader>
        </Side>
      </Top>
      <Container>
        <Content viewType={viewType}>
          <GroupContainer viewType={viewType}>
            {groups.map(([groupId, group], index) => (
              <Group
                key={groupId}
                inputName={<h2>{group.name}</h2>}
                viewType={viewType}
                withGroupBy
              >
                <StyledContentList>
                  {!isGrouped && renderCreated()}
                  {isGrouped && index === 0
                    ? renderCreated()
                    : Object.entries(group.docs).map(([, doc]) => renderViewCard(doc))}
                </StyledContentList>
              </Group>
            ))}
          </GroupContainer>
        </Content>
      </Container>
    </BoardWrapper>
  );

  function renderCreated() {
    return (
      <AnimatePresence>
        {createdDocs.filter(doc => !!doc.title.trim()).map((doc) => (
          <motion.div
            key={doc._docKey}
            initial={{
              height: 0,
              overflow: 'hidden',
            }}
            animate={{
              height: 'auto',
            }}
            exit={{
              height: 0,
              overflow: 'hidden',
            }}
          >
            {renderViewCard(doc)}
          </motion.div>
        ))}
      </AnimatePresence>
    );
  }

  function renderViewCard(doc: DocBaseFragment) {
    return (
      <ViewCard
        key={doc._docKey}
        viewType={viewType}
        sectionStart={(
          <ActionsContainerSkeleton>
            <CommentIcon />
          </ActionsContainerSkeleton>
        )}
        preTitle={viewType === ViewType.List && (
          <DocPrimaryAttributes doc={doc} viewType={viewType} />
        )}
        preToolbar={viewType === ViewType.Kanban && (
          <DocPrimaryAttributes doc={doc} viewType={viewType} />
        )}
        title={doc.title}
        sectionEnd={(
          <DocAssignee
            assignee={doc.assignee}
          />
        )}
      />
    );
  }
};
