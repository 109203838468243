import { make } from 'src/utils/reactives.util';

interface CreateDocState {
  modalVisible: boolean;
  customerId: string | null;
  docId: string | null;
  doctypeId: string | null;
}

export const {
  hookValue: useGetCreateDoc,
  getValue: getCreateDoc,
  setValue: setCreateDoc,
  resetValue: resetCreateDoc,
} = make<CreateDocState>({
  defaultState: {
    modalVisible: false,
    customerId: null,
    docId: null,
    doctypeId: null,
  },
});
