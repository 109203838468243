import { IntegrationType, IntegrationFullFragment } from '@cycle-app/graphql-codegen';
import { IntegrationCard, Dropdown, Tooltip, Spinner } from '@cycle-app/ui';
import { TrashIcon, MoreHorizIcon, OpenIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { FC, useState } from 'react';

import { GithubProjectDropdown } from 'src/components/GithubProjectDropdown/GithubProjectDropdown';
import { SlackToggleNotifications } from 'src/components/SlackToggleNotifications/SlackToggleNotifications';
import { Events } from 'src/constants/analytics.constants';
import { ZAPIER_CYCLE_APP_URL, CHROME_STORE_CYCLE_APP_URL, CUSTOMERS_INTEGRATIONS } from 'src/constants/integrations.constants';
import { useRemoveIntegration } from 'src/hooks/api/mutations/integrations/useRemoveIntegration';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { useToaster } from 'src/hooks/useToaster';
import { useGetIntegrationSync } from 'src/reactives/integrationSync.reactive';
import { Integration, FrontEndIntegration } from 'src/types/integrations.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';
import { integrationNameTitles, integrationsData } from 'src/utils/integrations.utils';

import {
  Container,
  SlotContainer,
  RemoveModalStyled,
  StyledLineButton,
  ActionMenuIcon,
  ActionsMenu,
  ActionMenu,
  ActionMenuContent,
  ActionMenuTextTitle,
  StyledActionButton,
} from './IntegrationItem.styles';

type AlertModalData = {
  integrationType: IntegrationType;
  integrationId: string;
  action: 'remove' | 'update';
};

export type IntegrationItemProps = {
  integration?: IntegrationFullFragment;
  integrationType: Integration;
};

export const IntegrationItem: FC<IntegrationItemProps> = ({
  integration, integrationType,
}) => {
  const copyToClipboard = useCopyToClipboard({
    successNotification: 'Email copied to clipboard',
  });
  const {
    remove, isLoading: isRemoveLoading,
  } = useRemoveIntegration();
  const { add: addToaster } = useToaster();
  const [alertModal, setAlertModal] = useState<AlertModalData | null>(null);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
  const integrations = useGetIntegrationSync();

  const isSyncing =
    integration?.type &&
    CUSTOMERS_INTEGRATIONS.includes(integration?.type) &&
    integrations[integration.type]?.isSyncing;

  return (
    <>
      <Container>
        <IntegrationCard
          description={integrationsData[integrationType].description}
          logo={integrationsData[integrationType].icon}
          title={integrationsData[integrationType].label}
          slot={renderSlot()}
          actions={renderActionsSlot()}
        />
      </Container>
      {!!alertModal && (
        <RemoveModalStyled
          title={integrationsData[alertModal.integrationType].remove?.title ??
            `Uninstall ${getIntegrationTitle(alertModal.integrationType)}`}
          info={(
            <p>
              {integrationsData[alertModal.integrationType].remove?.text ??
                `Are you sure you want to uninstall ${getIntegrationTitle(alertModal.integrationType)} integration?`}
            </p>
          )}
          hide={() => setAlertModal(null)}
          onConfirm={() => onRemoveIntegration(alertModal.integrationId, alertModal.integrationType)}
          loading={alertModal.action === 'remove' ? isRemoveLoading : false}
        />
      )}
    </>
  );

  function renderSlot() {
    if (!integration?.provider) return null;

    switch (integrationType) {
      case IntegrationType.Github:
        return renderGithubSlot();
      default:
        return null;
    }
  }

  function renderActionsSlot() {
    return (
      <>
        {isSyncing && <Spinner style={{ opacity: 0.5 }} />}

        {integrationType === IntegrationType.Mail && (
          <Tooltip content="Copy email" placement="top">
            <StyledActionButton
              onClick={() => {
                const mailIntegration = integration?.provider?.__typename === 'Mail' ? integration.provider : null;
                copyToClipboard(mailIntegration?.mail || '');
              }}
              size="L"
            >
              <DuplicateIcon />
            </StyledActionButton>
          </Tooltip>
        )}
        <Dropdown
          content={(
            <ActionsMenu>
              {integration?.provider?.__typename === 'Slack' && (
                <ActionMenu>
                  <SlackToggleNotifications
                    isPublicNotificationEnabled={!!integration.provider.isPublicNotificationEnabled}
                    integration={integration}
                  />
                </ActionMenu>
              )}
              {integrationType === FrontEndIntegration.ZAPIER && (
                <ActionMenu>
                  <StyledLineButton
                    onClick={() => window.open(ZAPIER_CYCLE_APP_URL, '_blank')}
                    variant="nospace"
                  >
                    <ActionMenuIcon>
                      <OpenIcon />
                    </ActionMenuIcon>
                    <ActionMenuContent>
                      <ActionMenuTextTitle>
                        Configure
                      </ActionMenuTextTitle>
                    </ActionMenuContent>
                  </StyledLineButton>
                </ActionMenu>
              )}
              {integrationType === FrontEndIntegration.CHROME && (
                <ActionMenu>
                  <StyledLineButton
                    onClick={() => window.open(CHROME_STORE_CYCLE_APP_URL, '_blank')}
                    variant="nospace"
                  >
                    <ActionMenuIcon>
                      <OpenIcon />
                    </ActionMenuIcon>
                    <ActionMenuContent>
                      <ActionMenuTextTitle>
                        Configure
                      </ActionMenuTextTitle>
                    </ActionMenuContent>
                  </StyledLineButton>
                </ActionMenu>
              )}
              {![FrontEndIntegration.ZAPIER, FrontEndIntegration.CHROME].includes(integrationType as FrontEndIntegration) && (
                <ActionMenu>
                  <StyledLineButton
                    onClick={() => {
                      if (!integration?.type) return;
                      setIsActionMenuOpen(false);
                      setAlertModal({
                        action: 'remove',
                        integrationType: integration.type,
                        integrationId: integration.id,
                      });
                    }}
                    variant="nospace"
                  >
                    <ActionMenuIcon>
                      <TrashIcon />
                    </ActionMenuIcon>
                    <ActionMenuContent>
                      <ActionMenuTextTitle>
                        Uninstall
                      </ActionMenuTextTitle>
                    </ActionMenuContent>
                  </StyledLineButton>
                </ActionMenu>
              )}
            </ActionsMenu>
        )}
          visible={isActionMenuOpen}
          hide={() => setIsActionMenuOpen(false)}
          placement="bottom-end"
        >
          <StyledActionButton onClick={() => setIsActionMenuOpen(b => !b)} size="L">
            <MoreHorizIcon />
          </StyledActionButton>
        </Dropdown>
      </>
    );
  }

  function renderGithubSlot() {
    return (
      <SlotContainer>
        <GithubProjectDropdown />
      </SlotContainer>
    );
  }

  function onRemoveIntegration(integrationId: string, type: IntegrationType) {
    remove(integrationId)
      .then(() => {
        trackAnalytics(Events.IntegrationRemoved, {
          type: integrationType,
        });
        addToaster({
          title: 'Successfully uninstalled',
          message: `${getIntegrationTitle(type)} has been successfully uninstalled`,
        });
      })
      .catch(() => {
        logError(Error(`Error on removing integration, integration id: ${integrationId}`));
      });
  }
};

const getIntegrationTitle = (type: IntegrationType) => integrationNameTitles[type] ?? type;
