import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';

import { GithubIssueView } from 'src/components/Editor/NodeViews/GithubIssue/GithubIssueView';
import { COMMANDS, GITHUB_EXTENSION_NAME } from 'src/constants/editor.constants';
import { renderIntegrationMentionSuggestion } from 'src/utils/integrations.utils';

import GithubIssuesDropdown from './GithubIssuesDropdown';

type GithubMentionOptions = {
  HTMLAttributes: Record<string, unknown>;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

export const GITHUB_ISSUE_EXTENSION_TAGNAME = 'github-issue-tagname';

const getGithubIssuesExtension = () => Node.create<GithubMentionOptions>({
  name: GITHUB_EXTENSION_NAME,
  draggable: true,

  addOptions: (): GithubMentionOptions => ({
    HTMLAttributes: {
      class: 'mention-github-issue',
    },
    suggestion: {
      allowSpaces: true,
      char: COMMANDS.GITHUB_ISSUE,
      render: () => renderIntegrationMentionSuggestion(GITHUB_EXTENSION_NAME, GithubIssuesDropdown),
    },
  }),

  group: 'block',

  inline: false,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      id: { default: null },
      state: {
        default: null,
        rendered: false,
      },
      url: {
        default: null,
        rendered: false,
      },
      title: {
        default: null,
        rendered: false,
      },
      description: {
        default: null,
        rendered: false,
      },
      assignees: {
        default: null,
        rendered: [],
      },
      creator: {
        default: null,
        rendered: false,
      },
      cardStatus: {
        default: null,
        rendered: false,
      },
      milestone: {
        default: null,
        rendered: false,
      },
      labels: {
        default: [],
        rendered: false,
      },
    };
  },

  parseHTML() {
    return [
      { tag: GITHUB_ISSUE_EXTENSION_TAGNAME },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      GITHUB_ISSUE_EXTENSION_TAGNAME,
      mergeAttributes(HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(GithubIssueView);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
        pluginKey: new PluginKey(`suggestion-${GITHUB_EXTENSION_NAME}`),
      }),
    ];
  },
});

export default getGithubIssuesExtension;
