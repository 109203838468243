/* stylelint-disable no-descending-specificity */
import styled, { css } from 'styled-components';

import { ViewType } from '../../../../graphql-codegen/generated';
import { TextButton, ActionButton } from '../Buttons';
import { ShyScrollbarCss, ShyScrollbarProps } from '../ShyScrollbar/ShyScrollbar.styles';

interface WithViewType {
  viewType: ViewType;
}

interface CollapseProps {
  collapse: boolean;
}

export type Variant = 'default' | 'swimlane';

interface ContainerProps extends WithViewType, CollapseProps {
  isLoading?: boolean;
  isDragging?: boolean;
  asPlaceholder?: boolean;
  isDraggable?: boolean;
  viewType: ViewType;
  variant?: Variant;
}

export const IndicatorBtn = styled(ActionButton)`
  padding: 0;
  color: ${p => p.theme.colors.text.disabled};
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const GroupTitle = styled.div`
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  gap: 8px;
`;

const HeaderKanbanCollapse = css`
  padding-top: 14px;
  flex-direction: column;
  width: 40px;
  height: 100%;
  border-bottom: 0;

  ${GroupTitle} {
    margin-top: 8px;
    position: absolute;
    top: 28px;
    left: 11px;
    display: flex;
    justify-content: flex-end;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: 0px 0px;

    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const HeaderKanbanCss = css<CollapseProps>`
  border-radius: 8px;
  ${(props) => props.collapse && HeaderKanbanCollapse};
`;

const HeaderListCss = css<CollapseProps>`
  position: sticky;
  z-index: 2;
  top: 0;
  border-radius: ${p => (p.collapse ? '8px' : '8px 8px 0 0')};
`;

const HeaderSwimlaneCss = css<CollapseProps>`
  border-radius: 8px 8px 8px 8px;
  padding: 16px 10px;

  ${p => p.collapse && css`
    ${IndicatorBtn} {
      transform: rotate(90deg);
    }
    &:hover {
      background-color: ${p.theme.colors.background.hover};
    }
  `};
`;

export const Header = styled.div<CollapseProps & WithViewType>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: ${p => (p.collapse ? '8px' : '8px 8px 1px')};
  flex-shrink: 0;
  background-color: ${p => p.theme.colors.group.bg};

  h2, input {
    /* Inconsistency design, cannot use theme/typo */
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: ${(props) => props.theme.colors.text.primary};
    background: transparent;
  }

  h2 {
    padding:  ${p => (p.collapse && p.viewType === ViewType.Kanban ? 0 : '4px 0')};
  }
`;

export const Toolbar = styled.div`
  margin-left: auto;
  display: flex;
  gap: 4px;
`;

export const Content = styled.div<WithViewType & ShyScrollbarProps>`
  ${p => p.viewType === ViewType.Kanban && ShyScrollbarCss};
  padding: 5px 0 0 0;
`;

const ContainerKanbanCssCollapse = css`
  width: auto;
  align-self: stretch;
`;

const ContainerKanbanCss = css<CollapseProps>`
  width: 270px;
  flex-shrink: 0;
  border-radius: 4px;

  ${p => p.collapse && ContainerKanbanCssCollapse};
`;

const ContainerListCss = css<CollapseProps>`
  width: 100%;
  border-radius: 8px;
`;

export const NewDocButton = styled(TextButton)`
  width: 100%;
  margin: 4px 0;
`;

export const NewDocContainer = styled.div`
  padding: 4px 8px;
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.group.bg};
  ${p => p.isLoading && css`
    pointer-events: none;
    ${NewDocButton} {
      opacity: 0;
    }
  `};

  ${(props) => (props.viewType === ViewType.Kanban
    ? ContainerKanbanCss
    : ContainerListCss)};

  ${p => p.isDragging && css`
    cursor: grabbing;
    ${Header} {
      cursor: grabbing;
    }
    ${Content} {
      /* @todo get max-height from initial measure of <Content /> */
      max-height: calc(100vh - 195px);
    }
  `};

  ${p => p.asPlaceholder && css`
    background-color: ${p.theme.userColors.bg};
    border-color: ${p.theme.userColors.br};
    border-style: dashed;
    border-width: 1px;
    /* stylelint-disable-next-line */
    ${Header}, ${Content}, ${NewDocContainer} {
      opacity: 0;
    }
  `};

  ${p => p.variant === 'swimlane' && css`
    min-width: 52px;
    margin-bottom: auto;
  `};

  ${Header} {
    ${p => p.isDraggable && 'cursor: grab'};
    ${p => p.variant === 'default' && p.viewType === ViewType.Kanban && HeaderKanbanCss};
    ${p => p.variant === 'default' && p.viewType === ViewType.List && HeaderListCss};
    ${p => p.variant === 'swimlane' && HeaderSwimlaneCss};
  }
`;

export const SortableElement = styled.div<CollapseProps>`
  display: flex;
  height: ${p => (p.collapse ? '100%' : 'auto')};
`;

export const Count = styled.span`
  margin-left: 4px;
  font-size: 14px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const GroupStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3px;
`;
