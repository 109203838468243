import { Role } from '@cycle-app/graphql-codegen';
import { AnimatePresence } from 'framer-motion';
import { VFC, useCallback } from 'react';

import { useMaybeMe } from 'src/hooks/api/useMe';
import { setTemplate, useGetTemplate } from 'src/reactives/template.reactive';
import { Layer } from 'src/types/layers.types';

import ExistingTemplateEditor from './TemplateEditor/ExistingTemplateEditor';
import { PortalModalStyled } from './TemplateModal.styles';
import Templates from './Templates/Templates';

const TemplateModal: VFC = () => {
  const me = useMaybeMe();
  const {
    admin,
    modalVisible,
    mode,
    selectedTemplateId,
    docTypeId,
  } = useGetTemplate();
  const modeAdmin = me?.role === Role.SuperAdmin && admin;

  const onTemplateUpdated = useCallback(() => {
    if (admin) {
      setTemplate({ mode: 'list' });
    } else {
      setTemplate({ modalVisible: false });
    }
  }, [admin]);

  const hideModal = useCallback(() => setTemplate({
    modalVisible: false,
    selectedTemplateId: null,
  }), []);

  return (
    <AnimatePresence>
      {modalVisible && (
        <PortalModalStyled hide={hideModal} layer={Layer.ModalZ2}>
          {mode === 'edit' && selectedTemplateId ? (
            <ExistingTemplateEditor
              templateId={selectedTemplateId}
              onTemplateUpdated={onTemplateUpdated}
              onTemplateRemoved={hideModal}
              modeAdmin={modeAdmin}
            />
          ) : (
            <Templates
              hideModal={hideModal}
              modeAdmin={modeAdmin}
              docTypeId={docTypeId}
            />
          )}
        </PortalModalStyled>
      )}
    </AnimatePresence>
  );
};

export default TemplateModal;
