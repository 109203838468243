// Monthly
export const FEE_AMOUNT_M_FLAT = 115;
export const FEE_AMOUNT_M_PER_UNIT = 35;

// Annual
// You can find the rates in stripe dashboard > product > "Pro".
export const FEE_AMOUNT_Y_FLAT = 1020;
export const FEE_AMOUNT_Y_PER_UNIT = 300;

export const getAmountFlat = (isAnnual?: boolean) => (
  isAnnual ? FEE_AMOUNT_Y_FLAT / 12 : FEE_AMOUNT_M_FLAT
);

export const getAmountPerUnit = (isAnnual?: boolean) => (
  isAnnual ? FEE_AMOUNT_Y_PER_UNIT / 12 : FEE_AMOUNT_M_PER_UNIT
);

export const getExpectedStandardFee = (billing: { nbMakers: number }, isAnnual?: boolean) => (
  getAmountFlat(isAnnual) + billing.nbMakers * getAmountPerUnit(isAnnual)
);
