import { ActionButton, typo, ShyScrollbarCss, EmojiInput, Input } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const AddButton = styled(ActionButton)`
  padding: 6px;
`;

export const Modal = styled(PortalModal)`
  ${ShyScrollbarCss}
  width: 450px;
  label {
    display: block;
    ${typo.body500};
    margin-bottom: 8px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 448px;
  max-height: 100vh;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 16px;
`;

export const EmojiInputStyled = styled(EmojiInput)`
  background-color:  ${p => p.theme.colors.input.bg};
  border: none;
`;

export const InputField = styled(Input)`
  width: 100%;
`;
