import { ErrorToaster } from '@cycle-app/ui';
import { motion, AnimatePresence } from 'framer-motion';
import { FC } from 'react';

import { useErrorToaster } from 'src/hooks/useErrorToaster';
import { useGetErrorToasters } from 'src/reactives/toasters.reactive';

import { motionProps, variantsContent } from './ErrorToasters.motion';
import { Container } from './ErrorToasters.styles';

export const ErrorToasters: FC = () => {
  const { queue } = useGetErrorToasters();
  const { close } = useErrorToaster();

  return (
    <AnimatePresence>
      {queue.length && (
        <Container>
          {queue.map((toasterConfig) => (
            <motion.div
              key={toasterConfig.id}
              variants={variantsContent}
              layout
              {...motionProps}
            >
              <ErrorToaster
                {...toasterConfig}
                onClose={toasterConfig.closable
                  ? () => close(toasterConfig.id)
                  : undefined}
              />
            </motion.div>
          ))}
        </Container>
      )}
    </AnimatePresence>
  );
};
