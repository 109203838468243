import { useMemo } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const useAssigneeFromBoardConfig = (groupId?: string) => {
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);

  return useMemo(() => {
    if (
      boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' &&
      boardConfig.docQuery.groupbyConfig.property.__typename === 'AssigneeDefinition'
    ) {
      return boardConfig.docQuery.docGroups.edges
        .find(({ node }) => node.id === groupId)
        ?.node.propertyValue?.id;
    }

    if (
      boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy' &&
      boardConfig.docQuery.groupbyConfig.property.__typename === 'AssigneeDefinition'
    ) {
      return boardConfig.docQuery.swimlanes.edges[0]?.node.docGroups.edges
        .find(({ node }) => node.id === groupId)
        ?.node.propertyValue?.id;
    }

    const assigneeFilter = boardConfig?.filterProperties.edges
      .find(filterProperty => filterProperty.node.__typename === 'FilterPropertyRuleAssignee');

    if (
      assigneeFilter?.node.__typename === 'FilterPropertyRuleAssignee' &&
      assigneeFilter.node.assigneeRule.__typename === 'RuleUserMultipleValues'
    ) {
      const selectedAssignees = assigneeFilter.node.assigneeRule.values
        .edges
        .filter(({ node }) => node.selected);
      return selectedAssignees[0]?.node.value.id;
    }

    return null;
  }, [boardConfig, groupId]);
};
