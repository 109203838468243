import { Input } from '@cycle-app/ui';
import { forwardRef, ChangeEvent } from 'react';

import { ACCEPTED_COVER_IMAGE_FORMAT } from 'src/constants/upload.constant';
import { useGetBillingPermission } from 'src/reactives/permission.reactive';

import { setLimitationsModal } from '../../reactives/limitationsModal.reactive';
import { isUploadRestricted } from '../../utils/files.util';

interface Props {
  disabled?: boolean;
  onCoverChanged: (file: File) => void;
}
const CoverImageInputFile = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    disabled,
    onCoverChanged,
  } = props;
  const { canUploadNotRestricted } = useGetBillingPermission();
  return (
    <Input
      ref={ref}
      id="cover"
      disabled={disabled}
      type="file"
      maxLength={1}
      onClick={e => e.stopPropagation()}
      accept={ACCEPTED_COVER_IMAGE_FORMAT.join(',')}
      onChange={onInputChange}
      inputHidden
    />
  );

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return null;

    if (isUploadRestricted(selectedFile, canUploadNotRestricted)) {
      setLimitationsModal({ action: 'UPLOAD_NOT_RESTRICTED' });
      return null;
    }

    return onCoverChanged(selectedFile);
  }
});

export default CoverImageInputFile;
