import Emoji from '@tiptap-pro/extension-emoji';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

import { getDropCursorExtension } from './dropCursor';

const formattingExtensions = [
  StarterKit.configure({
    history: false,
  }),
  getDropCursorExtension({}),
  Image,
  TaskList,
  TaskItem,
  Underline,
  Typography,
  CodeBlockLowlight,
  Link,
  Emoji,
];

export default formattingExtensions;
