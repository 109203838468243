import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { body400 } from '../../../theme/typo';
import { BOX_SHADOW_COLOR_Z2, BOX_SHADOW_COLOR_Z1 } from '../../../utils/styles.util';
import { Label } from '../CheckboxInput/CheckboxInput.styles';

const ToggleHeight = 16;
const DotSize = 14;
const ToggleWidth = 26;
const DotMargin = 2;

export const Toggle = styled.div`
  background: var(--bg);
  border-radius: ${ToggleHeight}px;
  width: ${ToggleWidth + DotMargin}px;
  height: ${ToggleHeight + DotMargin}px;
  position: relative;
  transition: background-color 0.15s ease;
`;

export const ToggleDot = styled.div`
  background: var(--dotBg);
  position: absolute;
  top: ${ToggleHeight - DotSize}px;
  left: ${ToggleHeight - DotSize}px;
  border-radius: ${ToggleHeight}px;
  width: ${DotSize}px;
  height: ${DotSize}px;
  transition: transform 0.15s ease;
  box-shadow: var(--shadowDot);
`;

export const StyledInput = styled.input`
  display: none;

  &:checked + ${Label} {
    --bg: var(--bgChecked);

    ${ToggleDot} {
      transform: translateX(${ToggleWidth - DotSize - DotMargin}px);
    }
  }
`;

export const LabelText = styled.span`
  ${body400};
  color: ${p => p.theme.colors.text.secondary};
`;

interface ContainerProps {
  $isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  --bg: ${p => p.theme.colors.background.disabled};
  --bgChecked: ${p => p.theme.userColors.main};
  --bgHover: ${p => darken(0.1, p.theme.userColors.main)};
  --dotBg: ${p => p.theme.colors.background.white};
  --color: ${p => p.theme.colors.text.primary};
  --shadow: ${BOX_SHADOW_COLOR_Z2};
  --shadowDot: ${BOX_SHADOW_COLOR_Z1};

  ${p => p.$isDisabled && css`
    --color: ${p.theme.colors.text.disabled};
    opacity: 0.75;

    ${Label} {
      cursor: not-allowed;
    }
  `};

  ${p => !p.$isDisabled && css`
    &:hover {
      ${Toggle} {
        filter: drop-shadow(var(--shadow));
      }

      ${StyledInput}:checked + ${Label} {
        --bg: var(--bgHover);
      }
    }
  `}
`;
