import { ActionButton } from '@cycle-app/ui';
import { WheelIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { Me } from '../Me';
import { LogoutButton } from './LogoutButton';
import { ProductSelect } from './ProductSelect';
import { SettingsContainer, List, Item, Label, Footer } from './Settings.styles';
import { ThemeSelect } from './ThemeSelect';

export const Settings = () => {
  return (
    <ToggleDropdown
      layer={Layer.DropdownModalZ1}
      button={props => (
        <ActionButton size="L" tooltip="Settings" {...props}>
          <WheelIcon />
        </ActionButton>
      )}
      content={(
        <SettingsContainer>
          <List>
            <Item>
              <Label>Workspace</Label>
              <ProductSelect />
            </Item>
            <Item>
              <Label>Theme</Label>
              <ThemeSelect />
            </Item>
          </List>
          <Footer>
            <Me full />
            <LogoutButton />
          </Footer>
        </SettingsContainer>
      )}
    />
  );
};
