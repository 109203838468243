import { FC, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { BoardConfigForm } from 'src/components/BoardConfigForm/BoardConfigForm';
import { BoardHeader } from 'src/components/BoardHeader/BoardHeader';
import BoardOptions from 'src/components/BoardOptions/BoardOptions';
import BoardRealtime from 'src/components/BoardRealtime/BoardRealtime';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { ShareBoard } from 'src/components/ShareBoard';
import SidebarToggleButton from 'src/components/SidebarToggleButton/SidebarToggleButton';
import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { PageId, routing } from 'src/constants/routing.constant';
import { useBoardConfig, BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useBoard } from 'src/hooks/api/useBoard';
import { useInitBoard } from 'src/hooks/boards/useInitBoard';
import { useOpenBoardConfig } from 'src/hooks/boards/useOpenBoardConfig';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { setLastView } from 'src/reactives/lastView.reactive';
import { useResponsive } from 'src/reactives/responsive.reactive';
import { ViewType } from 'src/types/viewType.types';
import { getBoardSlug } from 'src/utils/slug.util';

import {
  Top,
  Side,
  RightSide,
  SavingLabelStyled,
  BoardWrapper,
} from './Board.styles';
import BoardContent from './BoardContent/BoardContent';
import BoardContentWithSwimlaneLoader from './BoardContentWithSwimlane/BoardContentWithSwimlaneLoader';
import { CreateDocModal } from './CreateDocModal/CreateDocModal';
import DocPanel from './DocPanel/DocPanel';

const Board: FC = () => {
  const hasError = useBoardConfig(ctx => ctx.hasError);
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const pageId = usePageId();
  const board = useBoard(boardId);
  const location = useLocation();
  const { navigate } = useNavigate();
  const [{ breakpoint }] = useResponsive();
  const openBoardConfig = useOpenBoardConfig();

  useInitBoard({
    board,
    boardConfigId: board?.publishedBoardConfig?.id,
  });

  const boardSlug = getBoardSlug(board);
  useEffect(() => {
    if (!board || !boardSlug) return;
    setLastView({
      boardId: board.id,
      boardSlug,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardSlug]);

  if (hasError) {
    return <ErrorPage />;
  }
  const pageTitle = board?.name;
  /**
   * Viewtype and docQuery typename are fetched for all boards in the initial query, because we want
   * to know which skeleton to display
   */
  const isSwimlaneBoard = board?.publishedBoardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy';
  const viewType = board?.publishedBoardConfig?.viewType ?? ViewType.List;

  return (
    <>
      <Route path={routing[PageId.Board]}>
        {breakpoint === 'mobile' && (
          <SidebarToggleButton />
        )}
        <BoardWrapper id={BULK_SELECTION_AREA_ID}>
          <Top
            initial="rest"
            whileHover="hover"
            animate="rest"
          >
            <Side>
              <BoardHeader>
                {pageTitle}
              </BoardHeader>
            </Side>
            {breakpoint !== 'mobile' && (
              <RightSide>
                <SavingLabelStyled />
                {pageId !== PageId.Welcome && <BoardRealtime />}

                <ShareBoard boardSlug={boardSlug} />

                {board && (
                  <BoardOptions
                    boardId={board.id}
                    placement="bottom-start"
                    tooltipPlacement="bottom"
                    onBoardRemoved={() => navigate(PageId.Main)}
                    openBoardConfig={openBoardConfig}
                  />
                )}
              </RightSide>
            )}
          </Top>
          {isSwimlaneBoard
            ? <BoardContentWithSwimlaneLoader />
            : (
              <BoardContent
                viewType={viewType}
                boardConfigId={board?.publishedBoardConfig?.id}
              />
            )}
        </BoardWrapper>
      </Route>

      <Switch location={location}>
        <Route path={routing[PageId.Doc]}>
          <DocPanel />
        </Route>
        <Route path={routing[PageId.DocFullPage]}>
          <DocPanel expanded />
        </Route>
      </Switch>

      <CreateDocModal />
      <BoardConfigForm />
    </>
  );
};

const BoardWithBoardConfig: FC = () => (
  <BoardConfigContextProvider>
    <Board />
  </BoardConfigContextProvider>
);

export default BoardWithBoardConfig;
