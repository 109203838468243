import { SelectOption } from '@cycle-app/ui';
import { FC, Fragment } from 'react';

import { More } from './DoctypesOptionsPreview.styles';

const DEFAULT_PREVIEW_LENGTH = 4;

interface Props {
  options: SelectOption[];
  previewLength?: number;
}
const DoctypesOptionsPreview: FC<Props> = ({
  options,
  previewLength = DEFAULT_PREVIEW_LENGTH,
}) => (
  <>
    {options.slice(0, previewLength).map(option => (
      <Fragment key={option.value}>
        {option.icon}
      </Fragment>
    ))}
    {options.length > previewLength && (
      <More>
        {`+${options.length - previewLength}`}
      </More>
    )}
  </>
);

export default DoctypesOptionsPreview;
