import { SectionType, ThemeType } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import { Loading } from 'src/app/Auth/Welcome/Welcome.styles';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { PageId } from 'src/constants/routing.constant';
import { useInitialQuery } from 'src/hooks/api/useInitial';
import { useRole } from 'src/hooks/useRoles';
import { useGetOnboarding, setOnboarding } from 'src/reactives/onboarding.reactive';
import { getResponsive } from 'src/reactives/responsive.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';
import { getSteps, isUserFlow } from 'src/utils/onboarding.util';

import { BoardConfigContextProvider } from '../../contexts/boardConfigContext';
import useOptimizedBooleanState from '../../hooks/useOptimizedBooleanState';
import { OnboardingAppBackground } from '../OnboardingAppBackground/OnboardingAppBackground';
import { OnboardingPreviewBoardRoute } from '../OnboardingPreviewBoardRoute/OnboardingPreviewBoardRoute';
import { StepBoards } from './StepBoards/StepBoards';
import chromeDark from './StepChrome/chrome-dark.png';
import chromeLight from './StepChrome/chrome-light.png';
import { StepChrome } from './StepChrome/StepChrome';
import { StepCollaborate } from './StepCollaborate/StepCollaborate';
import { StepCollaborateReadOnly } from './StepCollaborate/StepCollaborateReadOnly';
import { StepConfigureAccount } from './StepConfigureAccount/StepConfigureAccount';
import { StepCreateDocs } from './StepCreateDocs/StepCreateDocs';
import { StepCreatePassword } from './StepCreatePassword/StepCreatePassword';
import { StepEditDocs } from './StepEditDocs/StepEditDocs';
import { StepIntegrations } from './StepIntegrations/StepIntegrations';
import { StepLoader } from './StepLoader/StepLoader';
import { StepPreferences } from './StepPreferences/StepPreferences';
import { StepWelcome } from './StepWelcome/StepWelcome';
import { StepWelcomeReadOnly } from './StepWelcome/StepWelcomeReadOnly';

export const OnboardingView: FC = () => {
  const {
    loading, hasError,
  } = useInitialQuery();
  const {
    role,
    product,
    isLoading: isRoleLoading,
  } = useRole();
  const {
    screen, userColor, initialized, theme,
  } = useGetOnboarding();
  const eclipseTheme = getTheme(ThemeType.Eclipse, userColor);
  const [isReadOnlyOnboarded, { setTrueCallback: setReadOnlyOnboarded }] = useOptimizedBooleanState(false);

  if (hasError) return <ErrorPage />;

  if ((!role || !product || loading || isRoleLoading)) return <Loading><Spinner /></Loading>;

  const isReadOnly = product.userCount > 1 || isUserFlow(role);

  if (!initialized) {
    setOnboarding({
      initialized: true,
      ...isReadOnly && { theme: ThemeType.Sunrise },
    });
  }

  return (
    <>
      <StepLoader>
        {isReadOnly
          ? (
            <ThemeProvider theme={getTheme(theme, userColor)}>
              <OnboardingAppBackground productSlug={product.slug} isOnboarded={isReadOnlyOnboarded}>
                <Helmet>
                  <link rel="prefetch" as="image" href={chromeLight} />
                  <link rel="prefetch" as="image" href={chromeDark} />
                </Helmet>
                {renderStep()}
              </OnboardingAppBackground>
            </ThemeProvider>
          )
          : (
            <ThemeProvider theme={eclipseTheme}>
              {renderStep()}
            </ThemeProvider>
          )}
      </StepLoader>
    </>
  );

  function renderStep() {
    const commonProps = {
      progress: getProgress(),
      isReadOnly,
    };

    switch (screen) {
      case OnboardingScreen.Welcome:
        return isReadOnly
          ? <StepWelcomeReadOnly onOnboard={setReadOnlyOnboarded} {...commonProps} />
          : (
            <OnboardingPreviewBoardRoute name="Inbox">
              <BoardConfigContextProvider>
                <StepWelcome {...commonProps} />
              </BoardConfigContextProvider>
            </OnboardingPreviewBoardRoute>
          );
      case OnboardingScreen.EditDocs:
        return <StepEditDocs {...commonProps} />;
      case OnboardingScreen.CreateDocs:
        return (
          <OnboardingPreviewBoardRoute name="initiatives" pageId={PageId.Board} sectionType={SectionType.Default}>
            <BoardConfigContextProvider>
              <StepCreateDocs {...commonProps} />
            </BoardConfigContextProvider>
          </OnboardingPreviewBoardRoute>
        );
      case OnboardingScreen.Integrations:
        return <StepIntegrations {...commonProps} />;
      case OnboardingScreen.Colors:
        return <StepPreferences {...commonProps} />;
      case OnboardingScreen.CreatePassword:
        return <StepCreatePassword {...commonProps} />;
      case OnboardingScreen.Boards:
        return <StepBoards {...commonProps} />;
      case OnboardingScreen.Collaboration:
        return isReadOnly ? <StepCollaborateReadOnly {...commonProps} /> : <StepCollaborate {...commonProps} />;
      case OnboardingScreen.Chrome:
        return <StepChrome {...commonProps} />;
      default:
        return <StepConfigureAccount {...commonProps} />;
    }
  }

  function getProgress() {
    const steps = getSteps(isReadOnly, getResponsive().breakpoint === 'mobile');

    return ((steps.indexOf(screen as OnboardingScreen) + 1) / steps.length) * 100;
  }
};
