import { Color } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: ${p => p.theme.colors.lightOnboarding.asideApp.gradient};
  }
`;

export const Container = styled.div`
  border: 1px solid ${p => p.theme.colors.lightOnboarding.asideApp.container.border};
  border-radius: 12px;
  display: grid;
  grid-template-columns: 220px 1fr;
  height: 100%;
  left: 70px;
  overflow: hidden;
  position: absolute;
  top: 45px;
  width: 100%;
  z-index: 1;
`;

export const Sidebard = styled.div`
  background: ${p => p.theme.colors.lightOnboarding.asideApp.sidebar.bg};
  padding: 24px;
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SidebarNavs = styled.div`
  margin-top: 24px;
`;

export const SidebarNavitem = styled.div`
  --bg: ${p => p.theme.colors.lightOnboarding.asideApp.sidebarItem.bg};
  --color: ${p => p.theme.colors.lightOnboarding.asideApp.sidebarItem.color};
  align-items: center;
  color: var(--color);
  display: flex;
  gap: 8px;
  padding-left: 1px;

  svg {
    width: 18px;
    opacity: 0.5;
  }

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const SidebarNavitemLabel = styled.div`
  background: var(--bg);
  border-radius: 5px;
  height: 6px;
  width: 50.78px;
`;

export const AddIconContainer = styled.div`
  color: var(--color);
  align-items: center;
  border-radius: 4px;
  background: var(--bg);
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;

  svg {
    height: 10px;
    width: 10px;
  }
`;

interface SidebarNavitemAddProps {
  $color: Color;
}

export const SidebarNavitemAdd = styled(SidebarNavitem)<SidebarNavitemAddProps>`
  --color: ${p => p.theme.nuances[p.$color].main};
  --bg: ${p => rgba(p.theme.nuances[p.$color].main, 0.2)};
`;

export const Main = styled.div`
  background: ${p => p.theme.colors.lightOnboarding.asideApp.main.bg};
  padding: 24px;
`;

export const MainHeader = styled.div`
  background: ${p => p.theme.colors.lightOnboarding.asideApp.mainHeader.bg};
  border-radius: 6px;
  height: 28px;
`;

export const MainCard = styled.div`
  background: ${p => p.theme.colors.lightOnboarding.asideApp.mainCard.bg};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 100px;
  margin-top: 8px;
`;

export const StyledEmoji = styled(Emoji)`
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  max-width: 18px;
  width: 18px;
`;
