import { InternalRefetchQueriesInclude } from '@apollo/client';
import { CreateCustomerDocument, CreateCustomerMutationVariables } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { NormalizedCustomerConnection, NormalizedCustomerEdge } from 'src/types/customers.types';

export const useCustomerCreate = () => {
  const { product } = useProduct();
  const [createCustomer, { loading }] = useSafeMutation(CreateCustomerDocument, { errorPolicy: 'all' });

  const mutate = useCallback(({
    name, email, ...variables
  }: Omit<CreateCustomerMutationVariables, 'productId'>, options?: { refetchQueries?: InternalRefetchQueriesInclude }) => {
    const productId = product?.id;
    if (!productId) return null;

    return createCustomer({
      variables: {
        productId: product.id,
        name: name?.trim() ? name : null,
        email: email?.trim() ? email : null,
        ...variables,
      },
      refetchQueries: options?.refetchQueries,
      update(cache, { data }) {
        if (!data?.createCustomer || !product) return;

        cache.modify({
          id: cache.identify(product),
          fields: {
            customerCount: (customerCount) => customerCount + 1,
            customers: (customers: NormalizedCustomerConnection, {
              readField, toReference,
            }) => {
              if (!data.createCustomer?.id) return customers;
              const newEdge = {
                __typename: 'CustomerEdge',
                node: toReference(data.createCustomer.id),
              } as NormalizedCustomerEdge;

              const index = customers.edges.findIndex(e => (readField('email', e.node) ?? '') > (email || ''));

              return {
                ...customers,
                edges: index === -1 ? [...customers.edges, newEdge] : [
                  ...customers.edges.slice(0, index),
                  newEdge,
                  ...customers.edges.slice(index),
                ],
              };
            },
          },
        });

        if (data.createCustomer.company) {
          cache.modify({
            id: cache.identify(data.createCustomer.company),
            fields: {
              countCustomers: (currentCount) => currentCount + 1,
              customers: (customers: NormalizedCustomerConnection, {
                readField, toReference,
              }) => {
                if (!data.createCustomer?.id) return customers;
                const newEdge = {
                  __typename: 'CustomerEdge',
                  node: toReference(data.createCustomer.id),
                } as NormalizedCustomerEdge;
                const index = customers.edges.findIndex(e => (readField('email', e.node) ?? '') > (email || ''));
                return {
                  ...customers,
                  edges: index === -1 ? [...customers.edges, newEdge] : [
                    ...customers.edges.slice(0, index),
                    newEdge,
                    ...customers.edges.slice(index),
                  ],
                };
              },
            },
          });
        }
      },
    });
  }, [createCustomer, product]);

  return {
    mutate,
    isLoading: loading ?? false,
  };
};
