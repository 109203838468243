import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import { useCompanyCreate } from 'src/hooks/api/mutations/customers/useCompanyCreate';

import { Form, FormInput } from './Customers.styles';

interface Props {
  onClose: () => void;
}

interface FormData {
  email: string;
  name: string;
}

export const CompanyAddModal: FC<Props> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
    },
  });

  const {
    createCompany, isLoading,
  } = useCompanyCreate();

  const onSubmit = useCallback(async (data: FormData) => {
    if (isLoading) return;
    await createCompany(data);
    onClose();
  }, [createCompany, isLoading, onClose]);

  return (
    <PortalModalStyled hide={onClose}>
      <Header>
        <Title>
          Add new company
        </Title>
        <CloseButtonStyled onClick={onClose}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          autoFocus
          type="text"
          label="Name"
          placeholder="Company name"
          error={errors.name?.message}
          {...register('name', {
            required: 'You must have at least 3 characters.',
            min: 3,
          })}
        />
        <Actions>
          <Button
            size="M"
            type="button"
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="M"
            type="submit"
            isLoading={isLoading}
            disabled={!!Object.keys(errors).length}
          >
            Create
          </Button>
        </Actions>
      </Form>
    </PortalModalStyled>
  );
};
