/**
 * We should use properties.util.tsx
 * `Attribute` is renamed to `Property`
 */

import {
  AttributeDefinitionTypeInput,
  AttributeTextValue,
  ChangeDocAttributeValueMutationVariables,
  CustomAttributeDefinition,
  CustomAttributeDefinitionFragment,
  DocAttributeFragment,
  DocBaseFragment,
  Property,
  PropertyValueFragment,
  ScalarAttributes,
  SelectAttributes,
} from '@cycle-app/graphql-codegen';
import { PropertyInputType, SelectOption } from '@cycle-app/ui';
import {
  ArrowRightDownIcon,
  CalendarIcon,
  CheckIcon,
  ChecklistIcon,
  CommentOutlineIcon,
  CustomerIconOutline,
  EnvelopeIcon,
  GeneralOutlineIcon,
  IdIcon,
  ImageIcon,
  LetterAIcon,
  LinkIcon,
  ParentIcon,
  PhoneIcon,
  SingleSelectIcon,
  TextIcon,
  UserIcon,
  StatusIcon,
} from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { ReactNode } from 'react';

import {
  DocAttributeType,
  CustomAttributeType,
  Attribute,
  UseAttributesFromDocReturn,
  GetCustomAttributesFormValueValueReturn,
} from 'src/types/attribute.types';

import { CompanyAttributeIcon } from './attributes.util.styles';

// TODO: remove reference to claps once backend is ready
export const ATTRIBUTE_ICON_MAPPING: Record<NonNullable<Property['__typename']>, ReactNode> = {
  ClapDefinition: null,
  DoctypeDefinition: <GeneralOutlineIcon />,
  CreatorDefinition: <UserIcon />,
  AssigneeDefinition: <UserIcon />,
  AttributeCheckboxDefinition: <CheckIcon />,
  AttributeDateDefinition: <CalendarIcon />,
  AttributeEmailDefinition: <EnvelopeIcon />,
  AttributeSingleSelectDefinition: <SingleSelectIcon />,
  AttributeMultiSelectDefinition: <ChecklistIcon />,
  AttributeNumberDefinition: <IdIcon />,
  AttributePhoneDefinition: <PhoneIcon />,
  AttributeTextDefinition: <LetterAIcon />,
  AttributeUrlDefinition: <LinkIcon />,
  CreatedatDefinition: <CalendarIcon />,
  CoverDefinition: <ImageIcon />,
  DocidDefinition: <IdIcon />,
  DoctitleDefinition: <TextIcon />,
  ParentDefinition: <ParentIcon />,
  ChildrenDefinition: <ParentIcon />,
  BuiltInCustomerDefinition: <CustomerIconOutline />,
  StatusDefinition: <StatusIcon />,
  BuiltInCompanyDefinition: <CompanyAttributeIcon />,
  CommentDefinition: <CommentOutlineIcon />,
  SourceDefinition: <ArrowRightDownIcon />,
};

export interface AttributeTypeData {
  docAttributeTypename: DocAttributeType;
  icon: ReactNode;
  label: string;
  input: PropertyInputType;
  typeInput: AttributeDefinitionTypeInput;
  inputValue: (value: string) => ChangeDocAttributeValueMutationVariables['value'];
  optimistic?: (value: string,
    id?: string,
    currentValues?: AttributeTextValue[],
    attributeDefinition?: CustomAttributeDefinitionFragment,
  ) => any; // @todo replace `any` by type generated from graphql-codegen
}

export const customAttributeTypeData: Record<CustomAttributeType, AttributeTypeData> = {
  AttributeCheckboxDefinition: {
    docAttributeTypename: 'DocAttributeCheckbox',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeCheckboxDefinition,
    label: 'Checkbox',
    input: 'checkbox',
    typeInput: { scalar: { type: ScalarAttributes.Boolean } },
    inputValue: (value) => ({ checkbox: value === 'checked' }),
    optimistic: (value) => ({
      checkboxValue: {
        __typename: 'AttributeCheckboxValue',
        value: value === 'checked',
      },
    }),
  },
  AttributeDateDefinition: {
    docAttributeTypename: 'DocAttributeDate',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeDateDefinition,
    label: 'Date',
    input: 'date',
    typeInput: { scalar: { type: ScalarAttributes.Date } },
    inputValue: (value) => ({ date: new Date(value).toISOString() }),
    optimistic: (value) => ({
      dateValue: {
        __typename: 'AttributeDateValue',
        value,
      },
    }),
  },
  AttributeEmailDefinition: {
    docAttributeTypename: 'DocAttributeEmail',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeEmailDefinition,
    label: 'Email',
    input: 'email',
    typeInput: { scalar: { type: ScalarAttributes.Email } },
    inputValue: (value) => ({ email: value }),
    optimistic: (value) => ({
      emailValue: {
        __typename: 'AttributeEmailValue',
        value,
      },
    }),
  },
  AttributeSingleSelectDefinition: {
    docAttributeTypename: 'DocAttributeSingleSelect',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeSingleSelectDefinition,
    label: 'Single select',
    input: 'select',
    inputValue: (value) => ({ select: value }),
    typeInput: {
      select: {
        type: SelectAttributes.SingleSelect,
        values: [],
      },
    },
    optimistic: (id, text) => ({
      selectValue: {
        __typename: 'AttributeTextValue',
        id,
        value: text ?? '',
      },
    }),
  },
  AttributeMultiSelectDefinition: {
    docAttributeTypename: 'DocAttributeMultiSelect',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeMultiSelectDefinition,
    label: 'Multi select',
    input: 'select',
    typeInput: {
      select: {
        type: SelectAttributes.MultiSelect,
        values: [],
      },
    },
    inputValue: (value) => ({ select: value }),
    optimistic: (id, text, currentValues) => ({
      selectValues: (currentValues || []).concat({
        __typename: 'AttributeTextValue',
        id,
        value: text ?? '',
      }),
    }),
    // TODO: Use this optimistic function instead once the api fixes the value order
    // optimistic: (id, _, currentValues, attributeDefinition) => {
    //   if (!currentValues || attributeDefinition?.__typename !== 'AttributeMultiSelectDefinition') return null;
    //   const currentValuesIds = currentValues.map(v => v.id).concat(id);

    //   return ({
    //     selectValues: attributeDefinition.values.edges
    //       .filter(a => currentValuesIds.includes(a.node.id))
    //       .map(a => ({
    //         __typename: 'AttributeTextValue',
    //         id: a.node.id,
    //         value: a.node.value,
    //       })),
    //   });
    // },
  },
  AttributeNumberDefinition: {
    docAttributeTypename: 'DocAttributeNumber',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeNumberDefinition,
    label: 'Number',
    input: 'number',
    typeInput: { scalar: { type: ScalarAttributes.Number } },
    inputValue: (value) => ({ number: Number(value) }),
    optimistic: (value) => ({
      numberValue: {
        __typename: 'AttributeNumberValue',
        value: Number(value),
      },
    }),
  },
  AttributePhoneDefinition: {
    docAttributeTypename: 'DocAttributePhone',
    icon: ATTRIBUTE_ICON_MAPPING.AttributePhoneDefinition,
    label: 'Phone',
    input: 'phone',
    typeInput: { scalar: { type: ScalarAttributes.Phone } },
    inputValue: (value) => ({ phone: value }),
    optimistic: (value) => ({
      phoneValue: {
        __typename: 'AttributePhoneValue',
        value,
      },
    }),
  },
  AttributeTextDefinition: {
    docAttributeTypename: 'DocAttributeText',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeTextDefinition,
    label: 'Text',
    input: 'text',
    typeInput: { scalar: { type: ScalarAttributes.Text } },
    inputValue: (value) => ({ text: value }),
    optimistic: (value) => ({
      textValue: {
        __typename: 'AttributeTextValue',
        value,
      },
    }),
  },
  AttributeUrlDefinition: {
    docAttributeTypename: 'DocAttributeUrl',
    icon: ATTRIBUTE_ICON_MAPPING.AttributeUrlDefinition,
    label: 'Url',
    input: 'url',
    typeInput: { scalar: { type: ScalarAttributes.Url } },
    inputValue: (value) => ({ url: value }),
    optimistic: (value) => ({
      urlValue: {
        __typename: 'AttributeUrlValue',
        value,
      },
    }),
  },
};

export const ATTRIBUTES_TYPES: CustomAttributeType[] = Object.keys(customAttributeTypeData) as CustomAttributeType[];

export const isCustomProperty = (property?: Property): property is CustomAttributeDefinition => !!property && 'name' in property;

export function getCustomAttributeTypeData(type: CustomAttributeType): AttributeTypeData {
  return customAttributeTypeData[type];
}

export type AttributeValue = number | string | string[] | undefined | null;
export const getDocAttributeValue = (docAttribute: DocAttributeFragment): AttributeValue => {
  if (docAttribute.__typename === 'DocAttributeCheckbox') {
    return docAttribute.checkboxValue?.value ? docAttribute.definition.name : null;
  }
  if (docAttribute.__typename === 'DocAttributeDate') {
    return docAttribute.dateValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeEmail') {
    return docAttribute.emailValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeSingleSelect') {
    return docAttribute.selectValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeMultiSelect') {
    return docAttribute.selectValues?.map(({ value }) => value);
  }
  if (docAttribute.__typename === 'DocAttributeNumber') {
    return docAttribute.numberValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributePhone') {
    return docAttribute.phoneValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeText') {
    return docAttribute.textValue?.value;
  }
  if (docAttribute.__typename === 'DocAttributeUrl') {
    return docAttribute.urlValue?.value;
  }

  return null;
};

export const getDocAttributeId = (docAttribute: DocAttributeFragment): string | undefined | null => {
  if (docAttribute.__typename === 'DocAttributeSingleSelect') {
    return docAttribute.selectValue?.id;
  }
  return null;
};

export const getPropertyValue = (property: PropertyValueFragment): number | string | string[] | boolean | undefined | null => {
  if (property.__typename === 'AttributeTextValue') {
    return property.text;
  }
  if (property.__typename === 'AttributeSelectValue') {
    return property.select;
  }
  if (property.__typename === 'AttributeCheckboxValue') {
    return property.checkbox;
  }
  if (property.__typename === 'AttributeDateValue') {
    return property.date;
  }
  if (property.__typename === 'AttributeEmailValue') {
    return property.email;
  }
  if (property.__typename === 'AttributeNumberValue') {
    return property.number;
  }
  if (property.__typename === 'AttributePhoneValue') {
    return property.phone;
  }
  if (property.__typename === 'AttributeUrlValue') {
    return property.url;
  }

  return null;
};

export const isAttribute = (propertyValue: PropertyValueFragment | null | undefined) => propertyValue?.__typename?.includes('Attribute');

export const getAttributeOptions = (attributeDefinition: CustomAttributeDefinitionFragment): SelectOption[] => {
  if (!('values' in attributeDefinition)) return [];

  return nodeToArray(attributeDefinition.values).map(v => ({
    label: v.value,
    value: v.id,
  }));
};

export function getAttributeName(attribute: Partial<Attribute>): string {
  switch (attribute.__typename) {
    case 'AttributeCheckboxDefinition':
    case 'AttributeDateDefinition':
    case 'AttributeEmailDefinition':
    case 'AttributeMultiSelectDefinition':
    case 'AttributeNumberDefinition':
    case 'AttributePhoneDefinition':
    case 'AttributeSingleSelectDefinition':
    case 'AttributeTextDefinition':
    case 'AttributeUrlDefinition':
      return attribute.name ?? '';

    case 'AssigneeDefinition':
      return 'Assignee';
    case 'CreatorDefinition':
      return 'Creator';
    case 'DoctypeDefinition':
      return 'Doctype';
    case 'CoverDefinition':
      return 'Cover';
    case 'DoctitleDefinition':
      return 'Title';
    case 'DocidDefinition':
      return 'Doc ID';
    case 'CreatedatDefinition':
      return 'Creation date';
    case 'ParentDefinition':
      return 'Parent';
    case 'ChildrenDefinition':
      return 'Children';
    case 'BuiltInCustomerDefinition':
      return 'Customer';
    case 'SourceDefinition':
      return 'Source';
    case 'CommentDefinition':
      return 'Comments';
    case 'StatusDefinition':
      return 'Status';
    default:
      return '';
  }
}

const SCALAR_ATTRIBUTE_DEFINITIONS = [
  'AttributeTextDefinition',
  'AttributeDateDefinition',
  'AttributeEmailDefinition',
  'AttributeUrlDefinition',
  'AttributeNumberDefinition',
  'AttributePhoneDefinition',
];

export function isAttributeScalar(attribute: CustomAttributeDefinition | CustomAttributeDefinitionFragment): boolean {
  if (!attribute.__typename) return false;
  return SCALAR_ATTRIBUTE_DEFINITIONS.includes(attribute.__typename);
}

// TODO: to remove once the api has implemented attributes order
export interface AttributeIdsOrder {
  [attributeDefinitionId: string]: number;
}
export function getAttributeIdsOrder(attributeDefinitions: Array<{ id: string }>): AttributeIdsOrder {
  return attributeDefinitions.reduce(
    (result, { id }, index) => ({
      ...result,
      [id]: index,
    }),
    {},
  );
}

export function getCurrentSelectedValuesFromDocMulti(
  doc: DocBaseFragment,
  attributeDefinition: CustomAttributeDefinitionFragment,
) {
  if (attributeDefinition.__typename !== 'AttributeMultiSelectDefinition') return [];

  const docAttribute = doc.attributes.edges.find(e => e.node.definition.id === attributeDefinition.id);
  if (!docAttribute || docAttribute.node.__typename !== 'DocAttributeMultiSelect') return [];

  return docAttribute.node.selectValues || [];
}

export const getCustomAttributesFormValueValue = (
  attributeValue: UseAttributesFromDocReturn['value'],
  attributeDefinition: CustomAttributeDefinitionFragment,
): GetCustomAttributesFormValueValueReturn => {
  if ('checkbox' in attributeValue) return !!attributeValue.checkbox;
  if ('date' in attributeValue) return attributeValue.date;
  if ('email' in attributeValue) return attributeValue.email;
  if ('number' in attributeValue) return attributeValue.number;
  if ('phone' in attributeValue) return attributeValue.phone;
  if ('text' in attributeValue) return attributeValue.text;
  if ('url' in attributeValue) return attributeValue.url;

  if (attributeDefinition.__typename === 'AttributeSingleSelectDefinition' && 'select' in attributeValue) {
    const attribute = attributeDefinition.values.edges.find(value => attributeValue.select === value.node.id)?.node;
    return attribute ? {
      label: attribute.value,
      value: attribute.id,
    } : undefined;
  }

  if (attributeDefinition.__typename === 'AttributeMultiSelectDefinition') {
    // TODO: take into account the multi select when it will be possible to create doc with that schema
    return undefined;
  }

  return undefined;
};
