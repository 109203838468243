import { ToasterAction, ToasterShortcut } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';

import { Shortcut, shortcuts } from 'src/constants/shortcuts.constants';
import {
  useParams, useGetDocFromCache, useProductStatuses, useNavigate,
  useToaster, AddToasterParam,
} from 'src/hooks';

import { useAddDocToaster } from './useAddDocToaster';

export const useStatusToaster = () => {
  const { add: addToaster } = useToaster();
  const statuses = useProductStatuses();
  const addDocToaster = useAddDocToaster();
  const { docSlug } = useParams();
  const { navigateToDocPanelPage } = useNavigate();
  const getDocFromCache = useGetDocFromCache();

  return (docIds: string[], statusId: string, undo?: VoidFunction) => {
    const productStatus = Object.values(statuses).flat().find(status => status?.id === statusId);
    const label = productStatus?.value.toLowerCase();
    if (!label || !undo) return;

    const undoAndClose = () => {
      undo?.();
      closeToaster?.();
      if (docSlug && docIds.length === 1) {
        const doc = getDocFromCache(docIds[0]);
        if (doc) navigateToDocPanelPage(doc);
      }
    };

    const params: AddToasterParam = {
      icon: <CheckIcon />,
      title: `Marked as ${label}`,
      actions: (
        <ToasterAction
          onClick={undoAndClose}
          iconEnd={(
            <ToasterShortcut
              keys={shortcuts[Shortcut.Undo]}
              className="shortcut shortcut--enabled-last-toaster"
            />
          )}
        >
          Undo
        </ToasterAction>
      ),
      shortcuts: [{
        key: Shortcut.Undo,
        action: undoAndClose,
        lastOnly: true,
      }],
    };

    let closeToaster: VoidFunction;

    if (docIds.length > 1) {
      closeToaster = addToaster({
        ...params,
        message: `Your feedbacks were successfully marked as ${label}`,
      });
    } else {
      closeToaster = addDocToaster({
        ...params,
        docId: docIds[0],
        message: tag => (
          <span>
            {tag ?? 'Your feedback'}
            {` was successfully marked as ${label}`}
          </span>
        ),
      });
    }
  };
};
