import { StatusCategory } from '@cycle-app/graphql-codegen';
import { CheckIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { shortcuts, Shortcut } from 'src/constants/shortcuts.constants';
import { useToggleDocStatus } from 'src/hooks/doc/useToggleDocStatus';
import { useProcessFeedbackProductTour } from 'src/hooks/productTour/useProcessFeedbackProductTour';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useGetDocItem } from 'src/reactives/docItem.reactive';
import { Layer } from 'src/types/layers.types';

import { ProcessButton, StyledShortcuts } from './DocProcessButton.style';

type DocProcessProps = {
  docId: string;
  docStatusCategory: StatusCategory;
  onCompleted?: VoidFunction;
  parent: 'doc-item' | 'doc-panel';
};

export const DocProcessButton: FC<DocProcessProps> = ({
  docId,
  docStatusCategory,
  onCompleted,
  parent,
}) => {
  const { hoverDocId } = useGetDocItem();
  const {
    isActive: isProductTourActive,
    setProcessButtonEl,
    setStartGamificationStep,
  } = useProcessFeedbackProductTour();

  const {
    isCompleted, toggleDocStatus,
  } = useToggleDocStatus({
    docId,
    docStatusCategory,
    onCompleted,
  });

  useHotkeyListener({
    callbacks: { [Shortcut.ProcessDoc]: toggleDocStatus },
    shortcuts: [Shortcut.ProcessDoc],
    disableOnLayers: [Layer.Dropdown],
    enabled: parent === 'doc-panel' || (parent === 'doc-item' && docId === hoverDocId),
  });

  return (
    <ProcessButton
      ref={el => setProcessButtonEl(el)}
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();
        await toggleDocStatus();
        if (isProductTourActive) setStartGamificationStep();
      }}
      $isActive={isCompleted}
      $parent={parent}
      tooltipPlacement={parent === 'doc-panel' ? 'bottom' : 'top'}
      tooltip={!isProductTourActive && (
        <StyledShortcuts
          colors="dark"
          shortcuts={[{
            label: isCompleted ? 'Mark to process' : 'Mark processed',
            keys: shortcuts[Shortcut.ProcessDoc],
          }]}
        />
      )}
    >
      <CheckIcon />
    </ProcessButton>
  );
};
