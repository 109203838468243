import { InfoColorType } from '@cycle-app/utilities';
import { transparentize } from 'polished';
import styled, { ThemedStyledProps } from 'styled-components';

import { getColorFromType } from '../../utils/colors.utils';
import { transitionCss } from '../../utils/transition.util';

const getBackground = (p: ThemedStyledProps<any, any>, isTransparent = false) => {
  let color;

  if (!p.$colorType) {
    color = p.theme.colors.text.secondary;
  } else if (p.$colorType === 'default') {
    color = p.theme.userColors.main;
  } else {
    color = getColorFromType(p.$colorType);
  }
  return isTransparent ? transparentize(0.9, color) : color;
};

export const Circle = styled.circle<{ $colorType?: InfoColorType }>`
  fill: transparent;
  stroke: ${p => p.theme.colors.background.disabled};
  stroke: ${p => getBackground(p, true)};
`;

export const ProgressCircle = styled.circle<{ $colorType?: InfoColorType }>`
  fill: transparent;
  stroke: ${p => p.theme.colors.text.secondary};
  stroke: ${getBackground};
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

type ProgressBarProps = {
  $percentage: number;
  $colorType?: InfoColorType;
};
export const ProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 30px;
  background-color: ${p => getBackground(p, true)};
  overflow: hidden;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: ${p => `${100 - p.$percentage}%`};
    background-color: ${getBackground};
    border-radius: 30px;
    ${transitionCss(['right'])}
  }
`;
