import styled from 'styled-components';

export const CycleLogoContainer = styled.div`
  border-radius: 2px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.background.blue};
  svg {
    --color1: white;
    --color2: white;
  }
`;
