// import { Placement } from 'tippy.js';
// TODO: put back when support all positions

export enum TourName {
  FIRST_TOUR = 'first-tour',
}

export enum TourStep {
  CreateFirstFeedback,
  SeeProperties,
  WriteDocContent,
  OpenFirstFeedback,
  HighlightFeedbackText,
  TurnHighlightIntoInsight,
  SetNewInsightInitiative,
  SetNewInsightProperties,
  SeeCreatedInsight,
  ProcessFirstFeedback,
  StartGamification,
}

export type Tour = {
  name: TourName;
  step: TourStep;
  steps: TourStep[];
};

export type ElementPosition = {
  /**
   * Element height in px
   */
  height: number;
  /**
   * Element width in px
   */
  width: number;
  /**
   * Element space from top in px
   */
  top: number;
  /**
   * Element space from left in px
   */
  left: number;
};

export type ProductToursState = {
  active?: {
    /**
     * If a product tour is enable, the index correspond to the active one
     * else is undefined
     */
    name: TourName;
    /**
     * Will be the element to use for dimension to highlight
     */
    el?: HTMLElement;
  };
  /**
   * Will contain all product tours then keep track of steps for each of them
   */
  tours: Tour[];
};

export type TourStepAction = 'back' | 'next' | 'skip';

type Top = number;
type Right = number;
type Bottom = number;
type Left = number;

export type StepDetails = {
  actions?: TourStepAction[];
  chapter: `${number}/${number}`; // current chapter / total chapter
  /**
   * The gap will be the pixels space around the highlighted area in the app
   */
  frameGap: number | [Top, Right, Bottom, Left];
  text: string | ((name: string) => string);
  title: string;
  tooltipPosition: 'top' | 'bottom-start';
};

export enum ProductTourLock {
  REDIRECT_INBOX = 'redirect-inbox',
  SHOW_EMPTY_INBOX = 'show-empty-inbox',
}
