import { getFigmaOrMiroEmbed } from '@cycle-app/utilities';
import { Editor, Range } from '@tiptap/react';

import { EditorContextValue } from 'src/contexts/editorContext';

const SUPPORTED_EMBED_DOMAINS = [
  'figma.com',
  'docs.google.com',
  'miro.com',
  'loom.com',
  'share.goabstract.com',
  'invisionapp.com',
  'framer.com',
  'whimsical.com',
  'youtube.com',
  'codepen.io',
  'pitch.com',
  'twitter.com',
  'github.com',
];
export const isEmbedSupported = (url: string) => SUPPORTED_EMBED_DOMAINS.some(domain => url.includes(domain));

interface IframelyResult {
  url: string;
  html: string;
}
const getIframelyHtml = async (url: string): Promise<IframelyResult> => {
  const apiKey = process.env.IFRAMELY_API_KEY;

  const fetchApi = await fetch(`//iframe.ly/api/oembed?url=${url}&api_key=${apiKey}`);
  return fetchApi.json();
};

interface AddEmbedParams extends Pick<EditorContextValue, 'onError'> {
  editor: Editor;
  url: string;
  range?: Range;
}
type AddEmbed = (p: AddEmbedParams) => Promise<void>;
export const addEmbed: AddEmbed = async ({
  editor,
  url,
  onError,
  range,
}): Promise<void> => {
  if (!isEmbedSupported(url)) {
    onError?.('This url is not supported');
    return;
  }

  const iframleyContent = await getIframelyHtml(url);
  const {
    isEmbeddable: isEmbeddableFigmaOrMiro, url: urlFigmaOrMiroToEmbed,
  } = getFigmaOrMiroEmbed(url);

  if (!iframleyContent.html && !isEmbeddableFigmaOrMiro) {
    onError?.("Oops, looks like we can't embed this. Sorry, we'll look into it!");
    return;
  }

  const isEmbeddableByIframely = !!iframleyContent.html;
  const content = isEmbeddableByIframely ? iframleyContent : {
    url: urlFigmaOrMiroToEmbed,
  };

  editor
    .chain()
    .focus()
    .deleteRange({
      from: range?.from ?? 0,
      to: range?.to ?? 0,
    })
    .setIframely(content)
    .createParagraphNear()
    .focus()
    .run();

  iframely.load();
};
