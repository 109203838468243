import { RemoveStatusDocument, StatusFragment, namedOperations } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { lockWorkflows, unlockWorkflows } from 'src/reactives/settingsWorkflows.reactive';

export const useDeleteStatus = (status: StatusFragment) => {
  const [mutate, result] = useSafeMutation(RemoveStatusDocument, {
    refetchQueries: [namedOperations.Query.productBySlug],
    awaitRefetchQueries: true,
    onCompleted: unlockWorkflows,
  });

  const deleteStatus = useCallback(() => {
    lockWorkflows();
    return mutate({
      variables: { id: status.id },
    });
  }, [status, mutate]);

  return {
    ...result,
    deleteStatus,
  };
};
