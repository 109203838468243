import { useSubscription } from '@apollo/client';
import {
  UnlinkedDocChildSubscription,
  UnlinkedDocChildDocument,
  UnlinkedDocChildSubscriptionVariables,
} from '@cycle-app/graphql-codegen';

import { useUpdateChildCache } from './cache/cacheHierarchy';

export const useUnlinkedDocChildSubscription = (parentDocId?: string | null) => {
  const updateChild = useUpdateChildCache();
  useSubscription<UnlinkedDocChildSubscription, UnlinkedDocChildSubscriptionVariables>(
    UnlinkedDocChildDocument,
    {
      variables: {
        parentDocId: parentDocId as string,
      },
      onSubscriptionData({ subscriptionData: { data } }) {
        if (!parentDocId || !data) return;
        updateChild({
          parentId: parentDocId,
          docId: data?.unlinkedDocChild,
          action: 'remove',
        });
      },
    },
  );
};
