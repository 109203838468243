import { Color, ThemeType } from '@cycle-app/graphql-codegen';

import { LocalKey } from 'src/types/localStorage.types';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { make } from 'src/utils/reactives.util';

export interface LightOnboardingState {
  screen: LightOnboardingScreen;
  theme: ThemeType;
  userColor: Color;
}

export const {
  hookValue: useGetOnboarding,
  getValue: getOnboarding,
  setValue: setOnboarding,
  resetValue: resetOnboarding,
} = make<LightOnboardingState>({
  defaultState: {
    screen: LightOnboardingScreen.Welcome,
    theme: ThemeType.Eclipse,
    userColor: Color.A,
  },
  localKey: LocalKey.LightOnboarding,
});
