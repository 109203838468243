import { useCallback } from 'react';

import { getResponsive } from 'src/reactives/responsive.reactive';
import { useSidebar, SidebarState } from 'src/reactives/sidebar.reactive';

interface UseSidebarActions {
  toggle: () => void;
  open: () => void;
  close: () => void;
}

export const useMobileSidebarActions = (): UseSidebarActions => {
  const [sidebarState, setSidebarState] = useSidebar();

  const updateMobileSidebarState = useCallback((newState: Partial<SidebarState>) => () => {
    const currentBreakpoint = getResponsive().breakpoint;
    if (currentBreakpoint === 'mobile') {
      setSidebarState(newState);
    }
  }, [setSidebarState]);

  return {
    toggle: updateMobileSidebarState({ displayed: !sidebarState.displayed }),
    open: updateMobileSidebarState({ displayed: true }),
    close: updateMobileSidebarState({ displayed: false }),
  };
};
