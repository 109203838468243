import { SelectPanel } from '@cycle-app/ui';
import styled from 'styled-components';

export const SelectPanelStyled = styled(SelectPanel)`
  padding: 12px 16px;
`;

export const InputContainer = styled.div`
  padding: 12px;
`;

export const InputTextContainer = styled.div`
  padding: 12px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  margin-bottom: 4px;
  > * {
    flex: none;
  }
  button {
    &:hover {
      color: ${p => p.theme.colors.text.disabled};
    }
  }
`;

export const Name = styled.div`
  flex-grow: 1;
`;
