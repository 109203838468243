import { CreateLinearIssueMutationVariables, LinearIssueFullFragment } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import { useCreateLinearIssue } from './useCreateLinearIssue';

type CreateIssuesParams = {
  titles: string[];
} & Omit<CreateLinearIssueMutationVariables, 'title'>;

type Progression = {
  status: 'not-started' | 'in-progress' | 'finished';
  issuesTotal: number;
  issuesSuccess: number;
  issuesFailed: number;
};
const initialProgression: Progression = {
  status: 'not-started',
  issuesTotal: 0,
  issuesSuccess: 0,
  issuesFailed: 0,
};

export const useCreateLinearIssues = () => {
  const { createIssue } = useCreateLinearIssue();
  const [progression, setProgression] = useState<Progression>(initialProgression);

  const createIssues = async ({
    titles, statusId, assigneeId, projectId, docId, teamId,
  }: CreateIssuesParams): Promise<LinearIssueFullFragment[]> => {
    if (!titles.length) throw Error('Data are missing');

    setProgression(() => ({
      status: 'in-progress',
      issuesTotal: titles.length,
      issuesSuccess: 0,
      issuesFailed: 0,
    }));

    const newIssues = await Promise.all<LinearIssueFullFragment>(
      titles.map((title) => new Promise((resolve, reject) => {
        createIssue({
          assigneeId,
          docId,
          projectId,
          statusId,
          title,
          teamId,
        }).then(newIssue => {
          if (newIssue.data?.createLinearIssue) {
            resolve(newIssue.data.createLinearIssue);
            setProgression(oldProgression => ({
              ...oldProgression,
              issuesSuccess: oldProgression.issuesSuccess + 1,
            }));
          } else {
            reject(new Error('error-during-linear-issue-creation'));
            setProgression(oldProgression => ({
              ...oldProgression,
              issuesFailed: oldProgression.issuesFailed + 1,
            }));
          }
        }).catch(e => {
          reject(new Error(e.message));
          setProgression(oldProgression => ({
            ...oldProgression,
            issuesFailed: oldProgression.issuesFailed + 1,
          }));
        });
      })),
    );

    setProgression(oldProgression => ({
      ...oldProgression,
      status: 'finished',
    }));

    return newIssues;
  };

  return {
    createIssues,
    progression,
  };
};
