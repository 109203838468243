import { PageId } from './routing.constant';

export const settings = {
  general: {
    id: 'general',
    name: 'General',
    pageId: PageId.Settings,
  },
  members: {
    id: 'members',
    name: 'Members',
    pageId: PageId.SettingsUsers,
  },
  discovery: {
    id: 'doc-types',
    name: 'Doc types',
    pageId: PageId.SettingsDocTypes,
  },
  properties: {
    id: 'properties',
    name: 'Properties',
    pageId: PageId.SettingsAttributes,
  },
  hierarchy: {
    id: 'hierarchy',
    name: 'Hierarchy',
    pageId: PageId.SettingsHierarchy,
  },
  integrations: {
    id: 'integrations',
    name: 'Integrations',
    pageId: PageId.SettingsIntegrations,
  },
  customers: {
    id: 'customers',
    name: 'Customers',
    pageId: PageId.SettingsCustomers,
  },
  billing: {
    id: 'billing',
    name: 'Billing',
    pageId: PageId.SettingsBilling,
  },
  plans: {
    id: 'plans',
    name: 'Plans',
    pageId: PageId.BillingPlans,
  },
  workflows: {
    id: 'workflows',
    name: 'Workflows',
    pageId: PageId.SettingsWorkflows,
  },
};
