import {
  InviteProductUserDocument,
  ProductUnsignedUsersFragmentDoc,
  RemoveUserFromProductDocument,
  Role,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { Events } from 'src/constants/analytics.constants';
import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { useCurrentBilling } from '../..';

export default function useProductMembersMutations() {
  const { product } = useProduct();
  const currentBilling = useCurrentBilling();

  const [addUserMutation, { loading: isAddUserLoading }] = useSafeMutation(InviteProductUserDocument);
  const [removeUserMutation, { loading: isRemoveUserLoading }] = useSafeMutation(RemoveUserFromProductDocument, {
    onCompleted: () => trackAnalytics(Events.UserDeleted),
  });

  const addUser = useCallback((email: string, role: Role) => {
    if (!product?.id) return null;

    return addUserMutation({
      variables: {
        productId: product.id,
        email,
        role,
      },
      update(cache, { data }) {
        if (!data?.inviteProductUser || !product) return;

        const isUserAlreadyInvited =
        product.users.edges.some(user => user.node.id === data.inviteProductUser?.id) ||
        product.notSignedUpUsers.edges.some(user => user.node.id === data.inviteProductUser?.id);

        if (isUserAlreadyInvited) return;

        if (currentBilling && role === Role.Maker) {
          const billing = cache.identify(currentBilling);
          if (billing) {
            cache.modify({
              id: billing,
              fields: {
                nbMakers: (currentMakers) => currentMakers + 1,
              },
            });
          }
        }

        cache.writeFragment({
          fragment: ProductUnsignedUsersFragmentDoc,
          fragmentName: 'ProductUnsignedUsers',
          data: {
            id: product.id,
            notSignedUpUsers: {
              __typename: 'UsersConnection',
              edges: [
                ...product.notSignedUpUsers.edges,
                {
                  __typename: 'UserEdge',
                  node: data.inviteProductUser,
                },
              ],
            },
          },
        });
      },
    });
  }, [addUserMutation, product, currentBilling]);

  const removeUser = useCallback((userId: string, role: Role) => {
    if (!product?.id) return null;

    return removeUserMutation({
      variables: {
        userId,
        productId: product.id,
      },
      update(cache, { data }) {
        if (!data?.removeUserFromProduct) return;
        if (currentBilling && role === Role.Maker) {
          const billing = cache.identify(currentBilling);
          if (billing) {
            cache.modify({
              id: billing,
              fields: {
                nbMakers: (currentMakers) => currentMakers - 1,
              },
            });
          }
        }
        cache.evict({ id: cache.identify(data.removeUserFromProduct) });
      },
    });
  }, [removeUserMutation, product?.id, currentBilling]);

  return {
    addUser,
    removeUser,
    isAddUserLoading,
    isRemoveUserLoading,
  };
}
