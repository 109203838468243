import { TextButton, ActionButton, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import DocParent from '../DocParent/DocParent';

export const AddParentBtn = styled(TextButton)`
  width: auto;
  padding: 0 4px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const EditParentBtnContainer = styled.div<{ $absolute?: boolean }>`
  ${p => p.$absolute && css`
    position: absolute;
    right: 4px;
  `};
`;

export const EditParentBtn = styled(ActionButton)`
  background-color: ${p => p.theme.colors.background.primary};
  outline: 1px solid ${p => p.theme.colors.actionButton.outline};
  &:hover {
    background-color: ${p => p.theme.colors.background.hover};
  }
`;

export const StyledDocParent = styled(DocParent)`
  position: relative;
  border-radius: 4px;

  ${typo.body400}

  ${EditParentBtn} {
    display: none;
    opacity: 0;
  }
  ${EditParentBtn}.force-focus {
    display: flex;
    opacity: 1;
  }

  &:hover {
    ${EditParentBtn} {
      display: flex;
      opacity: 1;
    }
  }
`;
