import { BoardWithMinimalConfigFragment } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { PageId, routing } from 'src/constants/routing.constant';
import { RouteParams } from 'src/types/routes.types';
import { getBoardSlug, getDocSlug } from 'src/utils/slug.util';

import { useParentPage } from './usePageId';

export { PageId };

interface UseUrl {
  /**
   *
   * Low level.
   *
   * To get an authorized url, make sure to use ```getUrl``` from ```useNavigate()```.
   */
  (): (page: PageId, p?: RouteParams) => string;
}

export const useUrl: UseUrl = () => {
  const fromUrl = useParams<RouteParams>();

  return useCallback(
    (page: PageId, toUse?: RouteParams) => routing[page]
      .replace(':productSlug', toUse?.productSlug ?? fromUrl.productSlug ?? '')
      .replace(':boardSlug', toUse?.boardSlug ?? fromUrl.boardSlug ?? '')
      .replace(':docSlug', toUse?.docSlug ?? fromUrl.docSlug ?? '')
      .replace(':doctypeId', toUse?.doctypeId ?? fromUrl.doctypeId ?? '')
      .replace(':attributeId', toUse?.attributeId ?? fromUrl.attributeId ?? '')
      .replace(':customerId', toUse?.customerId ?? fromUrl.customerId ?? '')
      .replace(':companyId', toUse?.companyId ?? fromUrl.companyId ?? ''),
    [fromUrl]
    ,
  );
};

export interface DocData {
  title: string;
  id: string;
}
export const useDocUrl = () => {
  const parentPageId = useParentPage();
  const getUrl = useUrl();

  const getDocPanelUrl = useCallback(
    (docData: DocData) => {
      const pageIds: Partial<Record<typeof parentPageId, PageId>> = {
        inbox: PageId.InboxDoc,
        home: PageId.HomeDoc,
      };
      return getUrl(pageIds[parentPageId] || PageId.Doc, { docSlug: getDocSlug(docData) });
    },
    [getUrl, parentPageId],
  );

  const getDocFullPageUrl = useCallback(
    (docData: DocData) => getUrl(PageId.DocFullPage, { docSlug: getDocSlug(docData) }),
    [getUrl],
  );

  return {
    getDocPanelUrl,
    getDocFullPageUrl,
  };
};

export const useCustomerUrl = () => {
  const getUrl = useUrl();

  const getCustomerPageUrl = useCallback(
    (customerId: string) => getUrl(PageId.SettingsCustomer, { customerId }),
    [getUrl],
  );

  return {
    getCustomerPageUrl,
  };
};

export const useBoardUrl = (board: BoardWithMinimalConfigFragment) => {
  const getUrl = useUrl();
  return getUrl(PageId.Board, { boardSlug: getBoardSlug(board) });
};
