import { ThemeType } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import {
  Container, PreviewMain, PreviewBar, StyledPreviewDark, StyledPreviewLight, Name,
} from './ThemePicker.styles';

interface Props {
  className?: string;
  onChange: (color: ThemeType) => void;
  theme: ThemeType;
}

export const ThemePicker: FC<Props> = ({
  className, onChange, theme,
}) => {
  return (
    <Container className={className}>
      <div>
        <StyledPreviewLight
          $isSelected={theme === ThemeType.Eclipse}
          onClick={() => onChange(ThemeType.Eclipse)}
        >
          <PreviewMain><PreviewBar /></PreviewMain>
        </StyledPreviewLight>
        <Name>Light</Name>
      </div>
      <div>
        <StyledPreviewDark
          $isSelected={theme === ThemeType.Nightfall}
          onClick={() => onChange(ThemeType.Nightfall)}
        >
          <PreviewMain><PreviewBar /></PreviewMain>
        </StyledPreviewDark>
        <Name>Dark</Name>
      </div>
    </Container>
  );
};
