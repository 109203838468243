import { GroupByConfigBoardQueryWithGroupByFragment, PropertiesFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { EyeClosedIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useCallback, useMemo, useState, ReactNode } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useManageGroupBy from 'src/hooks/api/mutations/boardConfig/useManageGroupby';
import { useFeatureFlag, FeatureFlag } from 'src/hooks/useFeatureFlag';
import { Layer } from 'src/types/layers.types';
import { ATTRIBUTE_ICON_MAPPING, getAttributeName } from 'src/utils/attributes.util';
import { getFilterAttributeOption } from 'src/utils/boardConfig/boardConfig.util';
import { getGroupName } from 'src/utils/groups.util';

import {
  Label,
  LightButton,
  GroupByButtons,
  GroupByButton,
  Caret,
  Separator,
} from './BoardConfigForm.styles';

type VisibleDropdownState = 'groupBy' | 'hiddenGroups' | null;

interface Props {
  boardConfigId: string;
  groupableProperties: PropertiesFragment;
  groupByConfig: GroupByConfigBoardQueryWithGroupByFragment['groupbyConfig'] | null;
  boardID?: string;
  hideHiddenGroups?: boolean;
  label?: ReactNode;
}
const BoardConfigFormGroupby: FC<Props> = ({
  boardConfigId,
  groupableProperties,
  groupByConfig,
  boardID,
  hideHiddenGroups,
  label,
}) => {
  const {
    changeGroupByProperty,
    removeGroupByProperty,
    hideBoardConfigGroupValue,
    showBoardConfigGroupValue,
    hideAllGroups,
    showAllGroups,
  } = useManageGroupBy(boardConfigId, boardID);

  const [visibleDropdown, setVisibleDropdown] = useState<VisibleDropdownState>(null);

  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);
  const groupByOptions = useMemo(
    () => {
      let properties = nodeToArray(groupableProperties);
      if (!isStatusEnabled) properties = properties.filter(p => p.__typename !== 'StatusDefinition');
      return properties.map(getFilterAttributeOption);
    },
    [groupableProperties, isStatusEnabled],
  );

  const selectedGroupBy = useMemo(() => (groupByConfig
    ? {
      __typename: groupByConfig.property.__typename,
      ...getFilterAttributeOption(groupByConfig.property),
    }
    : null), [groupByConfig]);

  const hiddenGroupOptions = useMemo(
    () => nodeToArray(groupByConfig?.values).map((group) => {
      const attributeName = groupByConfig ? getAttributeName(groupByConfig?.property) : undefined;
      return {
        value: group.id,
        label: getGroupName(group, attributeName),
        selected: !group.hidden,
      };
    }),
    [groupByConfig],
  );
  const nbHiddenGroups = useMemo(() => hiddenGroupOptions.filter(o => !o.selected)?.length, [hiddenGroupOptions]);

  const onGroupByChange = useCallback(async (option: SelectOption) => {
    setVisibleDropdown(null);
    const property = groupableProperties.edges.find(({ node }) => node.id === option.value)?.node;
    if (property) {
      await changeGroupByProperty(property);
    }
  }, [groupableProperties, changeGroupByProperty]);
  const onRemoveGroupby = useCallback(() => {
    setVisibleDropdown(null);
    return removeGroupByProperty();
  }, [removeGroupByProperty]);

  const onSelectOption = useCallback((o: SelectOption) => showBoardConfigGroupValue(o.value), [showBoardConfigGroupValue]);
  const onUnselectOption = useCallback((o: SelectOption) => hideBoardConfigGroupValue(o.value), [hideBoardConfigGroupValue]);

  return (
    <>
      {Boolean(label) && <Label>{label}</Label>}

      <GroupByButtons $isFullWidth={hideHiddenGroups && !!selectedGroupBy}>
        <DropdownLayer
          layer={Layer.DropdownModalZ1}
          visible={visibleDropdown === 'groupBy'}
          hide={() => setVisibleDropdown(null)}
          placement="bottom-start"
          content={(
            <SelectPanel
              onClearValue={onRemoveGroupby}
              options={groupByOptions}
              onOptionChange={onGroupByChange}
            />
          )}
        >
          {!selectedGroupBy && (
            <LightButton
              onClick={() => setVisibleDropdown('groupBy')}
              forceFocus={visibleDropdown === 'groupBy'}
            >
              + Add group
            </LightButton>
          )}
          {selectedGroupBy && (
            <GroupByButton
              type="button"
              onClick={() => setVisibleDropdown('groupBy')}
              forceFocus={visibleDropdown === 'groupBy'}
              {...!hideHiddenGroups && { position: 'left' }}
            >
              {ATTRIBUTE_ICON_MAPPING[selectedGroupBy.__typename]}
              {selectedGroupBy.label}
              <Caret />
            </GroupByButton>
          )}
        </DropdownLayer>

        {!hideHiddenGroups && selectedGroupBy && (
          <>
            <Separator />
            <DropdownLayer
              layer={Layer.DropdownModalZ1}
              visible={visibleDropdown === 'hiddenGroups'}
              hide={() => setVisibleDropdown(null)}
              placement="bottom-start"
              content={(
                <SelectPanel
                  isMulti
                  options={hiddenGroupOptions}
                  onSelectOption={onSelectOption}
                  onUnselectOption={onUnselectOption}
                  onUnselectAll={hideAllGroups}
                  unselectAllLabel="Hide all"
                  onSelectAll={showAllGroups}
                  selectAllLabel="Show all"
                />
              )}
            >
              <GroupByButton
                type="button"
                onClick={() => setVisibleDropdown('hiddenGroups')}
                forceFocus={visibleDropdown === 'hiddenGroups'}
                position="right"
              >
                <EyeClosedIcon />
                <div>{`${nbHiddenGroups} hidden group${nbHiddenGroups > 1 ? 's' : ''}`}</div>
              </GroupByButton>
            </DropdownLayer>
          </>
        )}
      </GroupByButtons>
    </>
  );
};

export default BoardConfigFormGroupby;
