import { ActionButton, Info } from '@cycle-app/ui';
import { CloseIcon, InfoIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { ReactNode, FC } from 'react';

import { GithubIssueCreationForm } from 'src/components/GithubIssueCreationForm/GithubIssueCreationForm';
import { CreateGithubIssueParams } from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';

import { StyledPortalModal, HeaderModal, Title } from './GithubIssueCreationModal.styles';

type CommonProps = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
};
type Props = {
  isMulti?: false;
  search: string;
  createIssue: (params: CreateGithubIssueParams) => void;
} & CommonProps | {
  isMulti: true;
  titles: string[];
  createIssues: (params: CreateGithubIssueParams) => void;
  progression?: ReactNode;
} & CommonProps;

export const GithubIssueCreationModal: FC<Props> = ({
  isMulti,
  isOpen,
  onClose,
  isLoading,
  ...props
}) => {
  const initialFormValues = 'search' in props
    ? { title: props.search }
    : {};

  const onSubmit = (values: CreateGithubIssueParams) => {
    if ('createIssue' in props) {
      props.createIssue(values);
    }
    if ('createIssues' in props) {
      props.createIssues(values);
    }
  };

  const hasManyTitles = 'titles' in props && props.titles.length > 1;

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledPortalModal useHighMaskLayer layer={Layer.DropdownBoardConfig} hide={onClose}>
          <HeaderModal>
            <Title>
              {`Create new Github issue${hasManyTitles ? 's' : ''}`}
            </Title>
            <ActionButton onClick={onClose}>
              <CloseIcon />
            </ActionButton>
          </HeaderModal>
          {isMulti && hasManyTitles && (
            <Info icon={<InfoIcon />}>
              {`You will create ${props.titles.length} GitHub issue${hasManyTitles ? 's' : ''}`}
            </Info>
          )}
          <GithubIssueCreationForm
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            isLoading={isLoading}
            submitLabel={'titles' in props
              ? `Create ${props.titles.length} issue${props.titles.length > 1 ? 's' : ''}`
              : undefined}
            footerSlot={'progression' in props ? props.progression : undefined}
          />
        </StyledPortalModal>
      )}
    </AnimatePresence>
  );
};
