import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

interface DoctypeGraph {
  elementHover: string | null;
  rootChildrenHover: string | null;
  rootParentIsHover: boolean;
  insightParentHover: string | null;
}

export const {
  hookState: useDoctypeGraph,
  hookValue: useGetDoctypeGraph,
  getValue: getDoctypeGraph,
  setValue: setDoctypeGraph,
} = make<DoctypeGraph>({
  defaultState: {
    elementHover: null,
    rootChildrenHover: null,
    rootParentIsHover: false,
    insightParentHover: null,
  },
  localKey: LocalKey.DoctypeGraph,
});
