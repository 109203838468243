import { AddNewDocAttributeValue, DocAttribute, DocChildFragment, DoctypeFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji, Spinner } from '@cycle-app/ui';
import { AddIcon, CornerDownRightIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, VFC } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocSource } from 'src/components/DocSource';
import { DocTagKey } from 'src/components/DocTagKey';
import { DocTagProperties } from 'src/components/DocTagProperties';
import InputLinkDoc from 'src/components/InputLinkDoc/InputLinkDoc';
import { InsightCardOptions } from 'src/components/InsightCardOptions';
import { PropertiesContainer } from 'src/components/InsightsList/InsightsList.styles';
import LoadMore from 'src/components/LoadMore/LoadMore';
import { useNavigate } from 'src/hooks';
import { useCustomerDocs } from 'src/hooks/api/queries/customers/useCustomerDocs';
import { useDocChildrens } from 'src/hooks/api/useDoc';
import { useDoctype } from 'src/hooks/api/useDocType';
import { Layer } from 'src/types/layers.types';
import { SIZE_CUSTOMER_DOCS, SIZE_ITEMS_HIERARCHY } from 'src/utils/pagination.util';

import DocHierarchyItem from '../DocHierarchyItem/DocHierarchyItem';
import {
  Doctype,
  DoctypeInfo,
  DoctypeLabel,
  DoctypeCounter,
  AddButton,
  Items,
  StyledInsightCard,
} from './DocHierarchyGroup.styles';

export interface DocHierarchyGroupProps {
  parentId?: string;
  doctypeChildren: DoctypeFragment;
  showInput: boolean;
  setInputDoctypeId?: (doctypeId: string | null) => void;
  onDocLinked: (childrenId: string, doctypeId: string, created: boolean, doc?: DocChildFragment) => Promise<void>;
  createChildrenEnabled?: boolean;
  docAttributes: AddNewDocAttributeValue[];
  sourceId?: string;
  customerId?: string;
  filterByCustomer?: boolean;
  skeleton?: JSX.Element;
  onDocRemove?: (doc: DocChildFragment) => void;
  refefresDeleteQueries?: boolean;
  isModal?: boolean;
}

const DocHierarchyGroup: VFC<DocHierarchyGroupProps> = ({
  parentId,
  doctypeChildren,
  showInput,
  setInputDoctypeId,
  onDocLinked,
  createChildrenEnabled = true,
  docAttributes,
  sourceId,
  customerId,
  filterByCustomer,
  skeleton,
  onDocRemove,
  refefresDeleteQueries,
  isModal,
}) => {
  const shouldFilterByCustomer = filterByCustomer && !!customerId;

  const doctype = useDoctype(doctypeChildren.id);
  const {
    docs: docChildren,
    pageInfo: docChildrenPageInfo,
    isPaginationLoading: isChildrenPaginationLoading,
    loading: isDocChildrenLoading,
    loadMore: loadMoreDocChildren,
    count: docChildrenCount,
  } = useDocChildrens({
    doctypeId: doctypeChildren.id,
    docId: parentId,
  }, shouldFilterByCustomer);

  const {
    docs: customerDocs,
    count: docCustomersCount,
    loadMore: loadMoreCustomerDocs,
    pageInfo: docCustomersPageInfo,
    isLoading: isCustomerDocLoading,
    isPaginationLoading: isCustomerPaginationLoading,
  } = useCustomerDocs({
    doctypeId: doctypeChildren.id,
    id: customerId ?? '',
  }, !shouldFilterByCustomer);

  const { navigateToDocFullPage } = useNavigate();

  const loadMore = shouldFilterByCustomer ? loadMoreCustomerDocs : loadMoreDocChildren;
  const loading = shouldFilterByCustomer ? isCustomerDocLoading : isDocChildrenLoading;
  const isPaginationLoading = isCustomerPaginationLoading || isChildrenPaginationLoading;
  const pageInfo = shouldFilterByCustomer ? docCustomersPageInfo : docChildrenPageInfo;
  const count = shouldFilterByCustomer ? docCustomersCount : docChildrenCount;

  const docs = useMemo(() => (
    shouldFilterByCustomer ? customerDocs : docChildren), [shouldFilterByCustomer, docChildren, customerDocs]);

  const hasChildren = count > 0;
  const showInputDoc = showInput || hasChildren;

  const doctypesAttributeIds: string[] = doctype?.attributeDefinitions.edges.map(({ node }) => node.id) ?? [];

  const inheritedAttributes: AddNewDocAttributeValue[] = docAttributes
    .filter((attr) => doctypesAttributeIds.includes(attr.attributeDefinitionId));

  return (
    <Doctype key={doctypeChildren.id}>
      {!customerId && (
        <DoctypeInfo>
          {parentId && <CornerDownRightIcon />}
          <Emoji emoji={doctypeChildren.emoji} />
          <DoctypeLabel>
            {doctypeChildren.name}
          </DoctypeLabel>
          {hasChildren && (
            <DoctypeCounter>
              <span>{count}</span>
            </DoctypeCounter>
          )}
          {loading && showInputDoc && <Spinner />}
          {!showInputDoc && createChildrenEnabled && (
            <AddButton
              tooltip={`Add a new ${doctypeChildren.name}`}
              tooltipPlacement="top"
              onClick={() => setInputDoctypeId?.(doctypeChildren.id)}
            >
              <AddIcon />
            </AddButton>
          )}
        </DoctypeInfo>
      )}
      {loading && skeleton}
      {showInputDoc && (
        <Items>
          {docs.map(doc => (customerId && doctypeChildren.type === DoctypeType.Insight
            ? (
              <StyledInsightCard
                blockId={doc.docSource?.blockId}
                context={doc.docSource?.content}
                options={(
                  <InsightCardOptions
                    blockId={doc.docSource?.blockId}
                    doc={doc}
                  />
                )}
                onClick={e => navigateToDocFullPage({
                  title: doc.title,
                  id: doc.id,
                }, {}, e.metaKey)}
                properties={(
                  <PropertiesContainer>
                    <DocTagKey
                      docTitle={doc.title}
                      docKey={doc._docKey}
                      docId={doc.id}
                    />
                    <DocTagProperties properties={nodeToArray(doc.attributes) as DocAttribute[]} />
                  </PropertiesContainer>
                )}
                title={doc.title}
                footerRight={(
                  <>
                    <DocSource source={doc.source} />
                    {!!doc.assignee?.id && (
                      <DocAssignee
                        assignee={doc.assignee}
                        showAssigneeName={false}
                        tooltipPlacement="top"
                        isDisabled={!!doc.docSource}
                        isRemovable={false}
                        layer={Layer.DropdownModalZ1}
                        dropdownPlacement="bottom-end"
                        docId={doc.id}
                      />
                    )}
                  </>
                )}
              />
            ) : (
              <DocHierarchyItem
                key={doc.id}
                doc={doc}
                doctype={doctypeChildren}
                parentId={parentId}
                onDocRemove={onDocRemove}
                refefresDeleteQueries={refefresDeleteQueries}
                hideDocCustomer={doc.customer?.id === customerId}
                isModal={isModal}
              />
            )))}
          {isPaginationLoading && skeleton}
          {pageInfo?.hasNextPage && (
            <LoadMore
              onClick={() => loadMore(pageInfo.endCursor ?? '')}
              count={Math.min((count - docs.length), shouldFilterByCustomer ? SIZE_CUSTOMER_DOCS : SIZE_ITEMS_HIERARCHY)}
            />
          )}
          {createChildrenEnabled && !pageInfo?.hasNextPage && showInputDoc && (
            <InputLinkDoc
              autoFocus={!hasChildren}
              label={`Add a new ${doctypeChildren.name}...`}
              placeholder={`Add a new ${doctypeChildren.name}...`}
              hideInput={!hasChildren ? () => setInputDoctypeId?.(null) : undefined}
              onDocLinked={onDocLinked}
              doctype={doctypeChildren}
              parentId={parentId}
              inheritedAttributes={inheritedAttributes}
              sourceId={sourceId}
              customerId={customerId}
              {...shouldFilterByCustomer && {
                docFilter: (node: DocChildFragment) => !node.customer,
              }}
            />
          )}
        </Items>
      )}
    </Doctype>
  );
};

export default DocHierarchyGroup;
