import { ActionButton, typo, ShyScrollbarCss } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { SidebarToggleButton } from 'src/components/SidebarToggleButton';

export const InboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  header {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 32px;
    padding-bottom: 12px;
  }

  header > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  ${mobile} {
    header {
      padding: 16px;
    }
  } 

  h1 {
    ${typo.headerMedium};
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const ToggleSidebar = styled(SidebarToggleButton)`
  position: unset;
  display: none;
  ${mobile} {
    display: flex;
  }
`;

export const SearchButton = styled(ActionButton)`
  flex: none;
  padding: 6px;
`;

export const DocTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 0 5px;
  background: ${p => p.theme.colors.tooltip.bgAlt}
`;

export const TooltipContent = styled.div`
  color: ${p => p.theme.colors.tooltip.textAlt}
`;

export const ViewContainer = styled.div`
  flex: 1;
  padding-top: 24px;
  ${ShyScrollbarCss}

  ${mobile} {
    padding-top: 16px;
  } 
`;
