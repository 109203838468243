import { typo, DropdownCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const NotAtooltip = styled.div`
  ${DropdownCss};

  border-radius: 4px;
  padding: 0 12px 0 4px; /* Not a mistake */

  display: flex;
  align-items: center;
  gap: 4px;

  line-height: 1;

  strong {
    ${typo.caption600};
  }
`;

export const DocTitle = styled.div`
  ${typo.caption400};
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
