import {
  MoveBoardInSectionListDocument,
  MoveSectionInProductListDocument,
  RemoveProductSectionDocument,
  ChangeProductSectionNameDocument,
  ListPositionInput,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProduct } from 'src/hooks/api/useProduct';
import { useLoader } from 'src/hooks/useLoader';
import useSafeMutation from 'src/hooks/useSafeMutation';

interface MoveBoardInSectionsParams {
  boardId: string;
  fromSectionId: string;
  toSectionId: string;
  position: ListPositionInput;
}

interface MoveSectionParams {
  sectionId: string;
  updatedGroupIds: string[];
  position: ListPositionInput;
}

export default function useBoardSectionsMutations() {
  const { product } = useProduct();

  const [moveBoardInSectionsMutation] = useSafeMutation(MoveBoardInSectionListDocument);
  const [moveSectionMutation] = useSafeMutation(MoveSectionInProductListDocument);
  const [renameSectionMutation] = useSafeMutation(ChangeProductSectionNameDocument);
  const [removeSectionMutation, { loading: loadingRemoveSection }] = useSafeMutation(RemoveProductSectionDocument);

  const moveBoardInSectionsList = useCallback((variables: MoveBoardInSectionsParams) => moveBoardInSectionsMutation({
    variables: {
      boardId: variables.boardId,
      sectionId: variables.toSectionId,
      position: variables.position,
    },
  }), [moveBoardInSectionsMutation]);

  const moveSection = useCallback((variables: MoveSectionParams) => moveSectionMutation({
    variables: {
      productId: product?.id ?? '',
      sectionId: variables.sectionId,
      position: variables.position,
    },
  }), [moveSectionMutation, product?.id]);

  const removeSection = useCallback((sectionId: string) => removeSectionMutation({
    variables: { productSectionId: sectionId },
  }), [removeSectionMutation]);

  const renameSection = useCallback((sectionId: string, name: string) => renameSectionMutation({
    variables: {
      sectionId,
      name,
    },
    optimisticResponse: {
      changeProductSectionName: {
        __typename: 'BoardSection',
        id: sectionId,
        name,
      },
    },
  }), [renameSectionMutation]);

  useLoader({ loading: loadingRemoveSection });

  return {
    moveBoardInSectionsList,
    moveSection,
    removeSection,
    renameSection,
    loadingRemoveSection,
  };
}
