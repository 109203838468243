import { CycleLogo } from '@cycle-app/ui';
import styled from 'styled-components';

import { NextButton } from '../OnboardingLayout/OnboardingLayout.styles';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(NextButton)`
  margin-top: 30px;
`;

export const StyledLogo = styled(CycleLogo)`
  margin-bottom: 36px;
`;

export const StyledLogoLoader = styled(CycleLogo)`
  --color1: ${p => p.theme.colors.lightOnboarding.doneLoader.color};
  margin-bottom: 36px;
`;
