import { StatusFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { useCallback, FormEvent } from 'react';
import { useForm } from 'react-hook-form';

import { useUpdateStatus } from 'src/hooks/status';
import useAppHotkeys from 'src/hooks/useAppHotkeys';

import { StyledInput } from './EditStatusForm.styles';

export type EditStatusFormProps = {
  status: StatusFragment;
  usedNames: string[];
  onDone: VoidFunction;
};

export const EditStatusForm = ({
  status, usedNames, onDone,
}: EditStatusFormProps) => {
  useAppHotkeys('escape', onDone);
  const { updateStatus } = useUpdateStatus();

  const {
    handleSubmit, register, formState,
  } = useForm<StatusFragment>({
    defaultValues: status,
  });

  const inputProps = register('value', {
    setValueAs: v => v.trim(),
    validate: {
      minLength: v => v.length > 2 || 'You must have at least 3 characters',
      alreadyUsed: v => !usedNames.includes(v) || 'This name is already used',
    },
  });

  const onBlur = useCallback((e: FormEvent) => {
    onDone();
    return inputProps.onBlur(e);
  }, [inputProps, onDone]);

  const onSubmit = useCallback(async (data: StatusFragment) => {
    await updateStatus(data);
    onDone();
  }, [onDone, updateStatus]);

  const error = formState.errors.value?.message;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Tooltip
        placement="top"
        content={error}
        disabled={!error}
        visible={!!error}
      >
        <StyledInput
          error={error}
          autoFocus
          {...inputProps}
          onBlur={onBlur}
        />
      </Tooltip>
    </form>
  );
};
