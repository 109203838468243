import { Spinner } from '@cycle-app/ui';
import { DownArrowIcon } from '@cycle-app/ui/icons';
import { FC, MouseEvent } from 'react';

import { StyledTextButton } from './LoadMore.styles';

interface Props {
  className?: string;
  count?: number;
  onClick: (e: MouseEvent) => void;
  loading?: boolean;
}
const LoadMore: FC<Props> = ({
  count,
  loading = false,
  ...buttonProps
}) => (
  <StyledTextButton
    {...buttonProps}
    noPointerEvents={loading}
  >
    <DownArrowIcon />
    <span>
      {count
        ? `Load ${count} more`
        : 'Load more'}
    </span>
    {loading && <Spinner />}
  </StyledTextButton>
);

export default LoadMore;
