import { useQuery } from '@apollo/client';
import { FetchGithubProjectDocument, IntegrationType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { GithubStatus } from 'src/types/integrations.types';

const BUILT_IN_GITHUB_PROJECT_STATUS_NAME = 'Status';

export const useGithubProject = () => {
  const { getIntegration } = useProductIntegrations();
  const integration = getIntegration(IntegrationType.Github);
  const {
    data, loading,
  } = useQuery(FetchGithubProjectDocument, {
    skip: !integration?.id,
    variables: {
      integrationId: integration?.id,
    },
  });

  const project = useMemo(
    () => {
      if (
        data?.node?.__typename !== 'Integration' ||
        data?.node.provider?.__typename !== 'Github' ||
        !data?.node?.provider?.project
      ) return null;

      return data.node.provider.project;
    },
    [data],
  );

  const statuses = useMemo(() => {
    if (!project || project.status?.name !== BUILT_IN_GITHUB_PROJECT_STATUS_NAME) return null;

    const result = project.status;

    if (!result.options) return null;

    return result.options as GithubStatus[];
  }, [project]);

  return {
    isLoading: loading,
    project,
    statuses,
  };
};
