import { useState, useEffect } from 'react';

import { WindowDimension } from 'src/types/window.types';

export const useWindowSize = (): WindowDimension => {
  const [windowSize, setWindowSize] = useState<WindowDimension>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
