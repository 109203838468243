import { BoardWithDraftConfigFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { useState, FC } from 'react';

import { CreateDraftBoardModal } from 'src/components/CreateDraftBoardModal';
import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { setCreateDoc } from 'src/reactives/createDoc.reactive';
import { sortDocTypes } from 'src/utils/docType.util';

import {
  Body,
  Header,
  Title,
  QuickActionButton,
} from './HomeQuickActionSection.styles';

export const HomeQuickActionSection: FC = () => {
  const [isCreateBoardModalOpen, setIsCreateBoardModalOpen] = useState(false);
  const [draftBoard, setDraftBoard] = useState<BoardWithDraftConfigFragment | null>(null);
  const docTypes = useProductDoctypes();

  return (
    <>
      <section>
        <Header>
          <Title>
            Quick actions
          </Title>
        </Header>
        <Body>
          {sortDocTypes(docTypes.filter(d => d.type !== DoctypeType.Insight)).map(docType => (
            <QuickActionButton
              key={docType.id}
              onClick={() => setCreateDoc({
                modalVisible: true,
                doctypeId: docType.id,
              })}
            >
              <Emoji emoji={docType.emoji} />
              New
              {' '}
              {docType.name}
            </QuickActionButton>
          ))}
        </Body>
      </section>
      {renderCreateBoardModal()}
    </>
  );

  function renderCreateBoardModal() {
    if (!isCreateBoardModalOpen) return null;

    return (
      <CreateDraftBoardModal
        onHide={() => {
          setIsCreateBoardModalOpen(false);
          setDraftBoard(null);
        }}
        draftBoard={draftBoard}
      />
    );
  }
};
