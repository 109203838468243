import { NodeViewRendererProps } from '@tiptap/react';

interface useNodeSelectionParams extends Pick<NodeViewRendererProps, 'editor' | 'getPos'> {
  selected: boolean;
}

export const useNodeSelection = ({
  editor, getPos, selected,
}: useNodeSelectionParams) => {
  if (selected || typeof getPos === 'boolean') {
    return {
      isSelected: selected,
    };
  }

  const currentPos = getPos() || null;

  if (typeof currentPos !== 'number') {
    return {
      isSelected: false,
    };
  }

  return { isSelected: editor.state.selection.from < currentPos && editor.state.selection.to > currentPos };
};
