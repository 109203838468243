import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';

import { UseAttributesFromDocReturn } from 'src/types/attribute.types';

export const useAttributesFromDoc = (doc: DocBaseFragment | null | undefined): Array<UseAttributesFromDocReturn> => {
  const docProperties = doc?.attributes.edges;

  return useMemo(
    () => (docProperties ?? []).map(docProperty => {
      const propertyTypename = docProperty.node.__typename;

      // SingleSelect
      if (propertyTypename === 'DocAttributeSingleSelect' && docProperty.node.selectValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            select: docProperty.node.selectValue.id,
          },
        };
      }
      // MultiSelect
      // if (propertyTypename === 'DocAttributeMultiSelect') {
      //   return {
      //     attributeDefinitionId: docProperty.node.definition.id,
      //     value: {
      //       selects: docProperty.node.selectValues?.map(value => value.id) || [],
      //     },
      //   };
      // }
      // Text
      if (propertyTypename === 'DocAttributeText' && docProperty.node.textValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            text: docProperty.node.textValue.value,
          },
        };
      }
      // Email
      if (propertyTypename === 'DocAttributeEmail' && docProperty.node.emailValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            email: docProperty.node.emailValue.value,
          },
        };
      }
      // Phone
      if (propertyTypename === 'DocAttributePhone' && docProperty.node.phoneValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            phone: docProperty.node.phoneValue.value,
          },
        };
      }
      // URL
      if (propertyTypename === 'DocAttributeUrl' && docProperty.node.urlValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            url: docProperty.node.urlValue.value,
          },
        };
      }
      // Number
      if (propertyTypename === 'DocAttributeNumber' && docProperty.node.numberValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            number: docProperty.node.numberValue.value,
          },
        };
      }
      // Date
      if (propertyTypename === 'DocAttributeDate' && docProperty.node.dateValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            date: docProperty.node.dateValue.value,
          },
        };
      }
      // Checkbox
      if (propertyTypename === 'DocAttributeCheckbox' && docProperty.node.checkboxValue) {
        return {
          attributeDefinitionId: docProperty.node.definition.id,
          value: {
            checkbox: docProperty.node.checkboxValue.value,
          },
        };
      }

      return null;
    }).filter(isPresent) ?? [],
    [docProperties],
  );
};
