import { Variants } from 'framer-motion';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { AppBackground, Overlay } from 'src/app/Auth/Welcome/Welcome.styles';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { setAuth } from 'src/reactives/auth.reactive';
import { resetBoardParams } from 'src/reactives/boardParams.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { AnimState } from '../../app/Auth/Welcome/Welcome';
import { PageId } from '../../constants/routing.constant';
import { useNavigate } from '../../hooks/useNavigate';
import { useOnboardingPreviewBoard } from '../../hooks/useOnboardingPreviewBoard';
import { getBoardSlug } from '../../utils/slug.util';
import { OnboardingPreviewBoardRoute } from '../OnboardingPreviewBoardRoute/OnboardingPreviewBoardRoute';
import { OnboardingPreviewRoute } from '../OnboardingPreviewRoute/OnboardingPreviewRoute';

interface Props {
  isOnboarded?: boolean;
  productSlug: string;
}

export const OnboardingAppBackground: FC<Props> = ({
  isOnboarded, children, productSlug,
}) => {
  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);
  const { canReadPlayground } = useGetPermission();
  const board = useOnboardingPreviewBoard({ name: 'all' });
  const { colors: { onboarding: { background: { backdrop } } } } = useTheme();
  const backdropVariant: Variants = useMemo(() => ({
    hidden: ({
      '--blur': '10px',
      // any as CSS variables are arbitary type.
    } as any),
    full: ({
      '--blur': '0px',
    } as any),
  }), [backdrop]);
  const { navigate } = useNavigate();

  return (
    <>
      <AppBackground
        variants={backdropVariant}
        initial="hidden"
        transition={{
          duration: 0.5,
        }}
        onAnimationComplete={onReadOnlyOnboarded}
        {...isOnboarded && { animate: 'full' }}
      >
        <Overlay
          initial={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          {...isOnboarded && { animate: { opacity: 0 } }}
        />
        {board || (!board && canReadPlayground)
          ? <OnboardingPreviewBoardRoute name={board?.name} emoji={board?.emoji} />
          : <OnboardingPreviewRoute pageId={PageId.Main} />}
      </AppBackground>
      {children}
    </>
  );

  function onReadOnlyOnboarded(animationState: AnimState) {
    if (animationState !== 'full') return;
    setAuth({ onboarded: true });

    const boardSlug = board ? getBoardSlug({
      name: board.name,
      id: board.id,
    }) : null;

    if ((board || (!board && canReadPlayground)) && boardSlug) {
      const pageId = isInboxEnabled ? PageId.InboxView : PageId.Board;
      navigate(pageId, {
        productSlug,
        boardSlug,
      }, { isOnboarding: false });
    } else {
      navigate(PageId.Main, { productSlug }, { isOnboarding: false });
    }

    resetBoardParams();
  }
};
