import { useDocTypes } from 'src/hooks/api/queries/docTypes/useDocTypes';
import { findFeedback } from 'src/utils/docType.util';

import { useProductBase } from '../Product';

export const useFeedback = () => {
  const productQuery = useProductBase();
  const docTypesQuery = useDocTypes(productQuery.product?.id);
  return {
    feedback: findFeedback(docTypesQuery.docTypes),
    isLoading: productQuery.isLoading || docTypesQuery.isLoading,
  };
};
