import { MotionProps } from 'framer-motion';
import { FC } from 'react';

import { H1Icon, H2Icon, H3Icon } from '../../icons';
import { Container, Category, CategoryTitle, StyledSelectLine, Actions } from './MockSlashDropdown.styles';

interface Props extends MotionProps {
  className?: string;
}

export const MockSlashDropdown: FC<Props> = ({
  className, ...props
}) => (
  <Container className={className} {...props}>
    <Category>
      <CategoryTitle>Text</CategoryTitle>
      <Actions>
        <StyledSelectLine
          startSlot={<H1Icon />}
          label="Heading 1"
        />
        <StyledSelectLine
          startSlot={<H2Icon />}
          label="Heading 2"
        />
        <StyledSelectLine
          startSlot={<H3Icon />}
          label="Heading 3"
        />
      </Actions>
    </Category>
  </Container>
);
