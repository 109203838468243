import { useApolloClient } from '@apollo/client';
import { MateFragment, UserNodeDocument } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

export const useGetUserFromCache = () => {
  const { cache } = useApolloClient();
  return useCallback((userId: string) => cache.readQuery<{ node: MateFragment }>({
    query: UserNodeDocument,
    variables: {
      userId,
    },
  })?.node, [cache]);
};
