import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { EmojiData, EmojiInput, Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import useDoctypesMutations, { Options } from 'src/hooks/api/mutations/useDoctypesMutations';
import { useEnhancedForm, ErrorMap } from 'src/hooks/form/useEnhancedForm';
import { DEFAULT_EMOJI } from 'src/utils/emoji.util';

import { Form, Row, InputStyled } from './DoctypesEditCommonModal.styles';

export type DoctypeEditCommonFormData = Pick<DoctypeFragment, 'name' | 'emoji' | 'description'>;

interface Props extends Options {
  onHide: VoidFunction;
  doctype?: DoctypeFragment;
}

// We do not have an unified format for business errors from the server (yet !)
// so we need to filter only expected errors.
const mutationErrorsMap: ErrorMap<DoctypeEditCommonFormData>[] = [
  {
    // A doctype with the same name already exists
    messagePattern: /^A doctype named/i,
    fieldName: 'name',
  },
];

export const DoctypesEditCommonModal: FC<Props> = (props) => {
  const {
    onHide,
    doctype,
    redirectOnCreate,
  } = props;
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors: formErrors },
    displayFieldsErrors,
  } = useEnhancedForm<DoctypeEditCommonFormData>({
    defaultValues: {
      emoji: doctype?.emoji || DEFAULT_EMOJI,
      description: doctype?.description || '',
      name: doctype?.name || '',
    },
    reValidateMode: 'onChange',
  });
  const {
    loading, addDoctype, updateDoctype,
  } = useDoctypesMutations(doctype?.id, { redirectOnCreate });

  async function onSubmit(data: DoctypeEditCommonFormData) {
    const result = doctype ? await updateDoctype(data) : await addDoctype(data);
    if (result?.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap);
      return;
    }
    onHide();
  }

  const title = doctype ? 'Edit doc type' : 'Create doc type';

  return (
    <PortalModalStyled hide={onModalHide}>
      <Header>
        <Title>{title}</Title>
        <CloseButtonStyled size="L" onClick={onModalHide}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Controller
            name="emoji"
            control={control}
            render={renderEmojiController}
          />
          <InputStyled
            id="doctTypedEdit-name"
            label="Doc type name"
            placeholder="Your doc type name"
            autoFocus
            {...register('name', {
              maxLength: {
                value: 25,
                message: 'You can\'t add more than 25 characters.',
              },
              required: 'You must have at least one character.',
            })}
            error={formErrors.name?.message}
          />
        </Row>
        <InputStyled
          id="doctTypedEdit-description"
          label="Description"
          placeholder="Your doc type description"
          {...register('description')}
        />
        <Actions>
          <Button
            type="button"
            size="M"
            variant="secondary"
            disabled={loading}
            onClick={onModalHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="M"
            isLoading={loading}
            disabled={!!Object.keys(formErrors).length}
          >
            Save
          </Button>
        </Actions>
      </Form>
    </PortalModalStyled>
  );

  function renderEmojiController({
    field: {
      value,
      onChange,
    },
  }: Parameters<ControllerProps['render']>[0]) {
    return (
      <EmojiInput
        emoji={value}
        label="Icon"
        onSelect={({ id }: EmojiData) => onChange(id)}
      />
    );
  }

  function onModalHide() {
    reset();
    onHide();
  }
};
