import { Spinner } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { FC, useEffect, useState, useCallback, useRef } from 'react';

import { useGetLoader } from 'src/reactives/loader.reactive';

import { Container } from './SavingLabel.styles';

const MIN_STATE_DURATION = 600;

type SavingState = 'spinning' | 'success' | null;

interface SavingLabelProps {
  className?: string;
}

const SavingLabel: FC<SavingLabelProps> = ({ className }) => {
  const { loading } = useGetLoader();
  const finishSpinningTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const finishSuccessTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [visible, setVisible] = useState(loading);
  const [savingState, setSavingState] = useState<SavingState>(null);
  const [lockState, setLockState] = useState(false);

  const finishSpinning = useCallback(() => {
    setLockState(false);
    if (!loading) {
      setSavingState('success');
      finishSuccessTimeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, MIN_STATE_DURATION);
    }
  }, [loading]);

  const cleanupTimeouts = () => {
    if (finishSpinningTimeoutRef.current) {
      clearTimeout(finishSpinningTimeoutRef.current);
    }
    if (finishSuccessTimeoutRef.current) {
      clearTimeout(finishSuccessTimeoutRef.current);
    }
  };

  useEffect(() => {
    cleanupTimeouts();
    return cleanupTimeouts;
  }, []);

  useEffect(() => {
    if (loading) {
      setVisible(true);
      setSavingState('spinning');
      cleanupTimeouts();

      setLockState(true);
      finishSpinningTimeoutRef.current = setTimeout(() => {
        setLockState(false);
      }, MIN_STATE_DURATION);
    } else if (!lockState) {
      finishSpinning();
    }
  }, [finishSpinning, loading, lockState]);

  return (
    <Container
      $visible={visible}
      className={className}
    >
      {savingState === 'spinning' ? (
        <>
          <Spinner />
          Saving...
        </>
      ) : (
        <>
          <CheckIcon />
          Saved
        </>
      )}
    </Container>
  );
};

export default SavingLabel;
