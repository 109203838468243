import { CycleLoader } from '@cycle-app/ui';
import * as FullStory from '@fullstory/browser';
import { PusherProvider } from '@harelpls/use-pusher';
import { FC, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Board from 'src/app/Main/Board/Board';
import DocPanel from 'src/app/Main/Board/DocPanel/DocPanel';
import { Settings } from 'src/app/Main/Settings';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import PageTitle from 'src/components/PageTitle/PageTitle';
import { BillingDowngrade } from 'src/components/Views/BillingDowngrade';
import { BillingUpgrade } from 'src/components/Views/BillingUpgrade';
import { HomeView } from 'src/components/Views/Home';
import { Inbox, InboxView } from 'src/components/Views/Inbox';
import { PageId, routing } from 'src/constants/routing.constant';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useInitialQuery } from 'src/hooks/api/useInitial';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { useGetInboxDefaultUrl } from 'src/hooks/inbox';
import { useMainProductTour } from 'src/hooks/productTour/useMainProductTour';
import { useCommandbarShortcutListener } from 'src/hooks/shortcuts/useCommandbarShortcutListener';
import { useGlobalShortcutListener } from 'src/hooks/shortcuts/useGlobalShortcutListener';
import { FeatureFlag, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useInitPermission } from 'src/hooks/useInitPermission';
import { useModListener } from 'src/hooks/useModListener';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { usePathParams } from 'src/hooks/usePathParams';
import { useGetInitial } from 'src/reactives/initial.reactive';
import { setLastView } from 'src/reactives/lastView.reactive';
import { isProductionEnv } from 'src/utils/env.util';
import { pusherConfig } from 'src/utils/pusher.util';

import { useGetPermission } from '../../reactives/permission.reactive';
import BoardSkeleton from './Board/BoardSkeleton';
import MainLayout from './MainLayout/MainLayout';

interface Props {
  preview?: boolean;
}

const Main: FC<Props> = ({ children }) => {
  useInitPermission();
  useModListener();
  useGlobalShortcutListener();
  useCommandbarShortcutListener();
  const {
    productSlug,
    boardSlug,
  } = usePathParams();
  const location = useLocation();
  const { loading } = useGetInitial();
  const {
    hasError,
    productSlug: productSlugInitial,
  } = useInitialQuery();
  const pageId = usePageId();
  const { getUrl } = useNavigate();
  const me = useMaybeMe('cache-only');
  const { isEnabled: isBillingPlanEnabled } = useFeatureFlag(FeatureFlag.BillingPlan);
  const { canReadSettings } = useGetPermission();
  const { shouldRedirectToMyInbox } = useMainProductTour();

  useEffect(() => {
    setLastView({ productSlug });
  }, [productSlug]);

  useEffect(() => {
    if (me && isProductionEnv()) {
      FullStory.identify(me.id, {
        displayName: `${me.firstName} ${me.lastName}`,
        email: me.email,
      });
    }
  }, [me]);

  const { isEnabled: isInboxEnabled } = useFeatureFlag(FeatureFlag.Inbox);
  const getInboxDefaultUrl = useGetInboxDefaultUrl();

  // Redirect to current product provided by api
  // if product slug not available in URL
  if (!productSlug && productSlugInitial && pageId !== PageId.Welcome) {
    return (
      <Redirect to={getUrl(PageId.Main, { productSlug: productSlugInitial })} />
    );
  }

  const myInboxSlug = shouldRedirectToMyInbox();
  if (myInboxSlug) {
    return (
      <Redirect to={getUrl(PageId.InboxView, {
        productSlug: productSlugInitial,
        boardSlug: myInboxSlug,
      })}
      />
    );
  }

  if (hasError) {
    return <ErrorPage showBackButton />;
  }
  if (loading) {
    return <PusherProvider {...pusherConfig()}><CycleLoader /></PusherProvider>;
  }

  return (
    <PusherProvider {...pusherConfig()}>
      <MainLayout>
        <PageTitle />
        <Switch location={location}>
          <Route path={routing[PageId.Board]}>
            {children ?? <Board />}
          </Route>

          {isInboxEnabled && (
            <Route path={routing[PageId.Inbox]}>
              <Inbox>
                <Switch location={location}>
                  <Route path={routing[PageId.InboxView]}>
                    <InboxView>
                      <Switch location={location}>
                        <Route path={routing[PageId.InboxDoc]}>
                          <DocPanel />
                        </Route>
                      </Switch>
                    </InboxView>
                  </Route>
                  <Route>
                    <Redirect to={getInboxDefaultUrl()} />
                  </Route>
                </Switch>
              </Inbox>
            </Route>
          )}

          <Route path={routing[PageId.DocFullPage]}>
            <Board />
          </Route>
          <Route path={routing[PageId.BillingUpgrade]}>
            <BillingUpgrade />
          </Route>
          {isBillingPlanEnabled && (
            <Route path={routing[PageId.BillingDowngrade]}>
              <BillingDowngrade />
            </Route>
          )}
          {canReadSettings && (
            <Route path={routing[PageId.Settings]}>
              <Settings />
            </Route>
          )}
          <Route path={routing[PageId.Welcome]}>
            {children ?? <Board />}
          </Route>
          <Route path={routing[PageId.Main]}>
            <BoardConfigContextProvider>
              <HomeView />
            </BoardConfigContextProvider>
          </Route>
          <Route>
            {productSlug && !boardSlug
              ? <Redirect to={getUrl(PageId.Main)} />
              : <BoardSkeleton />}
          </Route>
        </Switch>
      </MainLayout>
    </PusherProvider>
  );
};

export default Main;
