import { ToasterAction } from '@cycle-app/ui';
import { useCallback, ReactChild } from 'react';

import { DocTagKey } from 'src/components/DocTagKey';
import { useGetDocFromCache } from 'src/hooks/api/cache/cacheDoc';
import { useNavigate } from 'src/hooks/useNavigate';
import { useToaster, AddToasterParam } from 'src/hooks/useToaster';
import { getDocKey } from 'src/utils/doc.util';

import { useProductBase } from '..';

type AddDocToasterParam = Omit<AddToasterParam, 'message'> & {
  docId: string;
  message: (tag: ReactChild | null) => ReactChild;
};

export const useAddDocToaster = () => {
  const product = useProductBase();
  const { add: addToaster } = useToaster();
  const { navigateToDocPanelPage } = useNavigate();
  const getDoc = useGetDocFromCache();

  const addDocToaster = useCallback(({
    docId, message, actions, ...params
  }: AddDocToasterParam) => {
    const doc = getDoc(docId);
    const docKey = getDocKey(product?.key, doc?.publicId);
    const tag = doc && docKey
      ? (
        <DocTagKey
          docKey={docKey}
          docTitle={doc.title}
          docId={doc.id}
          style={{
            display: 'inline-flex',
            margin: '8px 0 4px',
            color: 'inherit',
          }}
        />
      )
      : null;

    const isOnDocView = doc && window.location.pathname.includes(doc.id);

    return addToaster({
      actions: (
        <>
          {doc && !isOnDocView && (
            <ToasterAction onClick={() => navigateToDocPanelPage(doc)}>
              Open
            </ToasterAction>
          )}
          {actions}
        </>
      ),
      message: message(tag),
      ...params,
    });
  }, [addToaster, getDoc, navigateToDocPanelPage, product?.key]);

  return addDocToaster;
};
