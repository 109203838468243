import { BoardWithMinimalConfigAndDraftConfigIdFragment } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { BoardEditForm } from 'src/components/BoardEditCommonModal/BoardEditForm';
import { Header, Title, CloseButtonStyled } from 'src/components/DialogModal/DialogModal.styles';
import { InboxBoardConfigFields } from 'src/components/InboxBoardConfigFields';
import useDraftBoardConfigMutations from 'src/hooks/api/mutations/boardConfig/useDraftBoardConfigMutations';
import useBoardMutations from 'src/hooks/api/mutations/useBoardMutations';
import { useNavigate } from 'src/hooks/useNavigate';
import { getBoardSlug } from 'src/utils/slug.util';

import { InboxDeleteView } from './InboxDeleteView';
import { Modal, ModalFooter, ModalActions } from './InboxViewSettings.styles';

export type InboxEditViewModalProps = {
  onHide: VoidFunction;
  board: BoardWithMinimalConfigAndDraftConfigIdFragment;
  draftBoardConfigId: string;
  isLoadingDraftBoardConfig: boolean;
};

export const InboxViewSettingsModal = ({
  onHide, board, draftBoardConfigId, isLoadingDraftBoardConfig,
}: InboxEditViewModalProps) => {
  const { navigateToInbox } = useNavigate();
  const boardMutations = useBoardMutations();
  const {
    revertUnsavedDraftConfig,
    publishBoardConfig,
    loading,
  } = useDraftBoardConfigMutations(draftBoardConfigId, board.id);

  const revert = async () => {
    if (isLoadingDraftBoardConfig || loading.publish || boardMutations.loading) return;
    await revertUnsavedDraftConfig();
    onHide();
  };

  return (
    <Modal hide={revert}>
      <Header>
        <Title>View settings</Title>
        <CloseButtonStyled size="L" onClick={revert}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <BoardEditForm
        defaultValues={board}
        focusNameOnMount
        onSubmit={async (formData) => {
          const [publishResult, boardResult] = await Promise.all([
            publishBoardConfig(),
            boardMutations.updateBoard({
              ...formData,
              boardId: board.id,
            }),
          ]);
          if (publishResult.errors || boardResult.errors) return;
          onHide?.();
          navigateToInbox(getBoardSlug(boardResult.data?.updateBoard));
        }}
      >
        <InboxBoardConfigFields boardId={board.id} />

        <ModalFooter>
          <ModalActions>
            <InboxDeleteView />
          </ModalActions>

          <ModalActions>
            <Button
              size="M"
              variant="secondary"
              onClick={revert}
              isLoading={loading.revertUnsaved}
            >
              Cancel
            </Button>

            <Button
              size="M"
              type="submit"
              isLoading={boardMutations.loading || loading.publish}
            >
              Save
            </Button>
          </ModalActions>
        </ModalFooter>
      </BoardEditForm>
    </Modal>
  );
};
