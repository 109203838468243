import { ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';

export const StyledDropdownLayer = styled(DropdownLayer)`
  width: 500px;
  /* With that min, the notif center shrinks for low height screens.
  100vh - 112px = 100vh - space between the top of the page and the NotificationCenter (100px) - 12px padding */
  max-height: min(745px, 100vh - 112px);

  display: flex;
  flex-direction: column;

  ${ShyScrollbarCss}

  border-color: ${p => p.theme.colors.notificationCenter.border};
  background-color: ${p => p.theme.colors.notificationCenter.bg};
  color: ${p => p.theme.colors.notificationCenter.color};
`;

export const EmptyState = styled.div`
  padding: 16px;
`;
