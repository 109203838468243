import { MotionProps } from 'framer-motion';
import { FC } from 'react';

import { CloseIcon, ExpandIcon, UpIcon, DownIcon, MoreHorizIcon } from '../../icons';
import {
  Container, Header, HeaderLeftActions, HeaderRightActions, StyledActionButton, Separator, Content,
} from './MockDocPanel.styles';

interface Props extends MotionProps {
  className?: string;
  rightActions?: JSX.Element;
}

export const MockDocPanel: FC<Props> = ({
  className, rightActions, children, ...props
}) => (
  <Container className={className} {...props}>
    <Header>
      <HeaderLeftActions>
        <StyledActionButton disabled>
          <CloseIcon />
        </StyledActionButton>
        <StyledActionButton disabled>
          <ExpandIcon />
        </StyledActionButton>
        <Separator />
        <StyledActionButton disabled>
          <UpIcon />
        </StyledActionButton>
        <StyledActionButton disabled>
          <DownIcon />
        </StyledActionButton>
      </HeaderLeftActions>
      <HeaderRightActions>
        {rightActions}
        <StyledActionButton disabled>
          <MoreHorizIcon />
        </StyledActionButton>
      </HeaderRightActions>
    </Header>
    <Content>{children}</Content>
  </Container>
);
