import { FC } from 'react';

import { useInboxZeroProductTour } from 'src/hooks/productTour/useInboxZeroProductTour';

import { FeedbackAction } from './FeedbackAction';
import { Container, Title, Subtitle, Content, ContainerInner } from './InboxZero.style';
import { InboxZeroSources } from './InboxZeroSources';

export const InboxZero: FC = () => {
  const {
    isActive,
    shouldShowWelcomeToInboxCopy,
    startFirstProductTour,
  } = useInboxZeroProductTour();

  return (
    <Container>
      <ContainerInner ref={el => startFirstProductTour(el as HTMLElement)}>
        <InboxZeroSources isReadOnly={isActive} />

        <Content>
          <Title>
            {shouldShowWelcomeToInboxCopy
              ? 'Welcome to your Inbox!'
              : 'You’ve hit inbox zero'}
          </Title>
          <Subtitle>
            {shouldShowWelcomeToInboxCopy
              ? 'All of your feedback, all in one place! Meet your Inbox ✌️'
              : 'Enjoy your day! ⛅️'}
          </Subtitle>
        </Content>

        <FeedbackAction />
      </ContainerInner>
    </Container>
  );
};
