import { useQuery } from '@apollo/client';
import { ExpectedAmountToPayDocument } from '@cycle-app/graphql-codegen';

import { useProductBase } from 'src/hooks/api/useProduct';

export const useFetchExpectedAmountToPay = () => {
  const product = useProductBase();
  const {
    data, loading,
  } = useQuery(ExpectedAmountToPayDocument, {
    variables: {
      productId: product?.id,
    },
    skip: !product?.id,
    fetchPolicy: 'network-only',
  });
  return {
    expectedAmountToPay: data?.node?.__typename === 'Product' ? data.node.expectedAmountToPay : 0,
    loading,
  };
};
