import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { GlobalStyles } from '@cycle-app/ui/global';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { FC, ReactNode } from 'react';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';

import { useMyColor } from 'src/hooks/api/useMyColor';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

interface Props {
  children: ReactNode;
}

const ThemeProvider: FC<Props> = ({ children }) => {
  const { colorTheme } = useGetThemeConfig();

  const myColor = useMyColor();
  const theme = getTheme(convertLegacyLocalStorageValue(colorTheme), myColor ?? ColorApi.A);

  return (
    <ThemeProviderStyled theme={theme}>
      {children}
      <GlobalStyles />
    </ThemeProviderStyled>
  );
};

export default ThemeProvider;
