/* eslint-disable react/no-array-index-key */
import { Color } from '@cycle-app/graphql-codegen';
import {
  AddIcon,
  HomeIcon,
  InboxIcon,
  SearchIcon,
} from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import {
  AddIconContainer,
  Container,
  Main,
  MainCard,
  MainHeader,
  Sidebard,
  SidebarHeader,
  SidebarNavitem,
  SidebarNavitemAdd,
  SidebarNavitemLabel,
  SidebarNavs,
  StyledEmoji,
  Wrapper,
} from './AsideApp.styles';

interface Props {
  logo: ReactNode;
  avatar: ReactNode;
  color: Color;
}

const top = [
  {
    icon: <HomeIcon />,
    width: 60,
  },
  {
    icon: <InboxIcon />,
    width: 50,
  },
  {
    icon: <SearchIcon />,
    width: 60,
  },
];

const bottom = [
  {
    icon: <StyledEmoji size={14} emoji="world_map" />,
    width: 60,
  },
  {
    icon: <StyledEmoji size={14} emoji="art" />,
    width: 40,
  },
  {
    icon: <StyledEmoji size={14} emoji="cherry_blossom" />,
    width: 60,
  },
  {
    icon: <StyledEmoji size={14} emoji="blossom" />,
    width: 70,
  },
  {
    icon: <StyledEmoji size={14} emoji="robot_face" />,
    width: 60,
  },
  {
    icon: <StyledEmoji size={14} emoji="beetle" />,
    width: 50,
  },
  {
    icon: <StyledEmoji size={14} emoji="rocket" />,
    width: 60,
  },
];

export const AsideApp: FC<Props> = ({
  avatar, logo, color,
}) => {
  return (
    <Wrapper>
      <Container>
        <Sidebard>
          <SidebarHeader>
            {logo}
            {avatar}
          </SidebarHeader>
          <SidebarNavs>
            {top.map((nav, i) => (
              <SidebarNavitem key={i}>
                {nav.icon}
                <SidebarNavitemLabel style={{ width: `${nav.width}px` }} />
              </SidebarNavitem>
            ))}
            <SidebarNavitemAdd $color={color}>
              <AddIconContainer>
                <AddIcon />
              </AddIconContainer>
              <SidebarNavitemLabel style={{ width: '60px' }} />
            </SidebarNavitemAdd>
          </SidebarNavs>
          <SidebarNavs>
            {bottom.map((nav, i) => (
              <SidebarNavitem key={i}>
                {nav.icon}
                <SidebarNavitemLabel style={{ width: `${nav.width}px` }} />
              </SidebarNavitem>
            ))}
          </SidebarNavs>
        </Sidebard>
        <Main>
          <MainHeader />
          <MainCard />
          <MainCard />
          <MainCard />
        </Main>
      </Container>
    </Wrapper>
  );
};
