import { ReactNode, VFC } from 'react';

import { Container, Title, Content } from './Tooltip.styles';

interface Props {
  className?: string;
  title?: ReactNode;
  content?: ReactNode;
  displayFullTitle?: boolean;
  width?: number;
}

export const TooltipContent: VFC<Props> = ({
  className,
  title,
  content,
  displayFullTitle = false,
  width,
}) => (
  <Container className={className} $width={width}>
    {title ? (
      <>
        <Title>{title}</Title>
        {content && <Content>{content}</Content>}
      </>
    ) : (
      <Title showEllipsis={!displayFullTitle}>
        <span>
          {content}
        </span>
      </Title>
    )}
  </Container>
);
