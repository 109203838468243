import { InternalRefetchQueriesInclude } from '@apollo/client';
import { CompanyFragment, CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { Button, SelectOption, CustomerAvatar } from '@cycle-app/ui';
import { CheckIcon, CloseIcon } from '@cycle-app/ui/icons';
import { emailRegex } from '@cycle-app/utilities';
import { useState, useCallback } from 'react';
import { Controller } from 'react-hook-form';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import ImageInput from 'src/components/ImageInput/ImageInput';
import { useCustomerCreate } from 'src/hooks/api/mutations/customers/useCustomerCreate';
import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { getCompanyOption } from 'src/utils/companies.util';

import { ErrorMap, useEnhancedForm } from '../../hooks/form/useEnhancedForm';
import { useToaster } from '../../hooks/useToaster';
import { Layer } from '../../types/layers.types';
import {
  CompanyActionSelect, CompanyActionSelectLabel, Form, FormInput, Label,
  InputContent,
  UploadNewBtn,
  RemoveBtn,
} from './Customers.styles';

interface SettingsCustomerAddCustomerModalProps {
  onClose: () => void;
  customer?: CustomerFragment;
  defaultValues?: Partial<CreateCustomerFormData>;
  defaultCompany?: CompanyFragment | null;
  layer?: Layer;
  dropdownsLayer?: Layer;
  onCreated?: (data: CustomerFragment) => void;
  hiddenFields?: ('name' | 'avatar' | 'company')[];
  refetchQueries?: InternalRefetchQueriesInclude;
}

interface CreateCustomerFormData {
  email: string;
  name: string;
  companyId: string;
  avatar?: string;
  avatarFile?: File;
  avatarPreviewUrl?: string;
}

// We do not have an unified format for business errors from the server (yet !)
// so we need to filter only expected errors.
const mutationErrorsMap: ErrorMap<CreateCustomerFormData>[] = [
  {
    messagePattern: /email already exists/i,
    fieldName: 'email',
  },
];

export const CustomersAddCustomerModal = ({
  onClose,
  customer,
  defaultValues,
  defaultCompany,
  layer,
  onCreated,
  hiddenFields = [],
  refetchQueries,
  dropdownsLayer = Layer.DropdownModalZ3,
}: SettingsCustomerAddCustomerModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    displayFieldsErrors,
  } = useEnhancedForm<CreateCustomerFormData>({
    defaultValues: {
      email: customer?.email || '',
      name: customer?.name || '',
      companyId: defaultCompany?.id ?? '',
      avatarPreviewUrl: '',
      ...defaultValues,
    },
  });
  const { add: addToaster } = useToaster();
  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(
    defaultCompany ? getCompanyOption(defaultCompany) : null,
  );

  const createCustomer = useCustomerCreate();
  const updateCustomer = useCustomerUpdate(customer);

  const isEditing = !!customer?.id;

  const onSubmit = useCallback(async ({
    name: formName, email: formEmail, ...data
  }: CreateCustomerFormData) => {
    if (createCustomer.isLoading || updateCustomer.isLoading) return;
    if (isEditing) {
      const result = await updateCustomer.update({
        customerId: customer.id,
        avatarInput: data.avatarFile ? {
          avatar: data.avatarFile,
        } : null,
        name: formName,
        // Cannot update already set email.
        ...!customer.email && { email: formEmail },
        ...data,
      });
      if (result?.errors) {
        displayFieldsErrors(result.errors, mutationErrorsMap);
        return;
      }
    } else {
      const result = await createCustomer.mutate({
        ...data,
        name: formName,
        email: formEmail,
      }, { refetchQueries });

      if (result?.errors) {
        displayFieldsErrors(result.errors, mutationErrorsMap);
        return;
      }
      if (result?.data?.createCustomer && onCreated) {
        addToaster({
          icon: <CheckIcon />,
          message: 'Customer successfully created',
        });
        onCreated(result?.data?.createCustomer);
      }
    }
    onClose();
  }, [createCustomer, updateCustomer, isEditing, onClose, customer, displayFieldsErrors, refetchQueries, onCreated, addToaster]);

  const [avatarUrl, name, email] = watch(['avatarPreviewUrl', 'name', 'email']);

  const hideName = hiddenFields.includes('name');
  const hideAvatar = hiddenFields.includes('avatar');
  const hideCompany = hiddenFields.includes('company');

  return (
    <PortalModalStyled layer={layer} hide={onClose}>
      <Header>
        <Title>
          {isEditing ? 'Edit customer' : 'Add new customer'}
        </Title>
        <CloseButtonStyled onClick={onClose}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <Form
        onSubmit={async e => {
          // Prevents parent form from beeing submitted.
          e.stopPropagation();
          await handleSubmit(onSubmit)(e);
        }}
      >
        {!hideName && (
          <FormInput
            autoFocus
            type="text"
            label="Name"
            placeholder="Customer name"
            error={errors.name?.message}
            {...register('name', {
              min: 3,
            })}
          />
        )}
        <FormInput
          readOnly={!!customer?.email}
          autoFocus={hideName}
          label="Email"
          placeholder="Customer email"
          helper="Please add an email address if you want to communicate with this customer"
          helperSize="M"
          error={errors.email?.message}
          {...register('email', {
            pattern: {
              value: emailRegex,
              message: 'Email format is incorrect',
            },
          })}
        />

        {!hideCompany && (
          <div>
            <Label>Company</Label>
            <Controller
              control={control}
              name="companyId"
              render={({ field }) => (
                <CompanyActionSelect
                  isReadOnly={!customer && !!defaultCompany}
                  layer={dropdownsLayer}
                  onOptionChange={selectedOption => {
                    field.onChange(selectedOption.value);
                    setSelectedCompany(selectedOption);
                  }}
                  onClearValue={() => {
                    field.onChange('');
                    setSelectedCompany(null);
                  }}
                  showCaret
                >
                  {selectedCompany ? (
                    <CompanyActionSelectLabel>
                      {selectedCompany.icon}
                      {selectedCompany.label}
                    </CompanyActionSelectLabel>
                  ) : 'Select a company'}
                </CompanyActionSelect>
              )}
            />
          </div>
        )}

        {!hideAvatar && isEditing && (
          <ImageInput
            label="Avatar"
            previewModalTitle="New customer picture"
            previewModalSubmitLabel="Set new customer picture"
            onChange={(file) => {
              setValue('avatarFile', file);
              setValue('avatarPreviewUrl', URL.createObjectURL(file));
            }}
          >
            {(inputRef) => (
              <InputContent>
                {customer && (
                  <CustomerAvatar
                    size="M"
                    onClick={() => inputRef.current?.click()}
                    customer={customer}
                    src={avatarUrl}
                  />
                )}
                <UploadNewBtn onClick={() => inputRef.current?.click()}>Upload new</UploadNewBtn>

                <RemoveBtn
                  onClick={() => {
                    setValue('avatarFile', undefined);
                    setValue('avatarPreviewUrl', '');
                  }}
                >
                  Remove
                </RemoveBtn>
              </InputContent>
            )}
          </ImageInput>
        )}

        <Actions>
          <Button
            size="M"
            type="button"
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="M"
            type="submit"
            isLoading={createCustomer.isLoading || updateCustomer.isLoading}
            disabled={!email.trim() && (hideName || !name.trim())}
          >
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </Actions>
      </Form>
    </PortalModalStyled>
  );
};
