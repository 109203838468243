import { ListPositionInput } from '@cycle-app/graphql-codegen';
import {
  closestCorners,
  useSensor,
  useSensors, MouseSensor,
  SensorDescriptor,
  SensorOptions,
  CollisionDetection,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  DragMoveEvent,
} from '@dnd-kit/core';
import { uniq } from 'ramda';
import { useRef, useState, useCallback, useMemo, useLayoutEffect } from 'react';

import { BOARD_CONTENT_ID } from 'src/constants/board.constant';
import { getSelection } from 'src/reactives/selection.reactive';
import { Items } from 'src/types/item.types';
import {
  moveItemToAnotherGroup,
  moveItemToDroppableGroup,
  sortGroups,
  sortItems,
  sortSwimlaneItems,
} from 'src/utils/dnd.util';

// prevents lag on item drop
const DRAG_END_CALLBACK_DELAY = 350;

/**
 * CrossGroupStrategy define what kind of drag and drop is allowed
 * - droppable: all DND are allowed
 * - sorting: only DND inside the same groupe are allowed
 * - disabled: no DND are allowed
 */
type CrossGroupStrategy = 'droppable' | 'sorting' | 'disabled';
type Direction = 'left' | 'right';
export type DndItemType = 'group' | 'item' | 'group-droppable' | 'swimlane' | null;

export interface OnItemsMovedParams {
  activeId: string;
  groupId: string;
  previousGroupId?: string;
  previousGroupIds?: string[];
  itemsId: Array<string>;
  updatedItems: Items;
  position: ListPositionInput;
}

export interface OnGroupMovedParams {
  groupId: string;
  sortedGroupIds: string[];
  position: ListPositionInput;
}

export interface OnSwimlaneSortedParams {
  swimlaneActiveId: string;
  position: ListPositionInput;
}

interface HookParams {
  initialItems: Items;
  initialSwimlaneItems?: Array<string>;
  onItemsMoved?: (p: OnItemsMovedParams) => void;
  onGroupMoved?: (p: OnGroupMovedParams) => void;
  onSwimlaneSorted?: (p: OnSwimlaneSortedParams) => void;
  collisionDetection?: CollisionDetection;
  withVoidItems?: boolean;
  crossGroupStrategy?: CrossGroupStrategy;
  onStart?: (activeId: string) => void;
  onCancel?: VoidFunction;
  updateOverId?: boolean;
  /**
   * This function should be called in at least 2 event functions:
   * 1. in `onDragOver` to prevent the empty card placeholder to appear in the
   * group so the user have the right UX
   * 2. in `onDragEnd` to prevent doc(s) to actually being updated byt the move
   */
  shouldPreventDnD?: (
    event: DragOverEvent,
    groupsOrigin?: string[],
    groupTarget?: string,
    isOnDrop?: boolean
  ) => boolean;
}

interface HookResult {
  activeId: string | null;
  activeType: DndItemType;
  groupActiveId: string | null | undefined;
  dropGroupOverId: string | null | undefined;
  swimlaneItems: Array<string>;
  items: Items;
  direction: Direction;
  overId: string | null;
  dndContextProps: {
    onDragStart: (e: DragStartEvent) => void;
    onDragOver: (e: DragOverEvent) => void;
    onDragEnd: (e: DragEndEvent) => void;
    onDragCancel: () => void;
    onDragMove: (e: DragMoveEvent) => void;
    sensors: SensorDescriptor<SensorOptions>[];
    collisionDetection: CollisionDetection;
  };
}

type Hook = (p: HookParams) => HookResult;

export const useGroupsDnd: Hook = ({
  onStart,
  onCancel,
  initialItems,
  initialSwimlaneItems = [],
  onItemsMoved,
  onGroupMoved,
  onSwimlaneSorted,
  collisionDetection = closestCorners,
  withVoidItems = false,
  crossGroupStrategy = 'sorting',
  updateOverId = false,
  shouldPreventDnD,
}) => {
  const [items, setItems] = useState<Items>(withVoidItems ? setup(initialItems) : initialItems);
  const [swimlaneItems, setSwimlaneItems] = useState(initialSwimlaneItems);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeType, setActiveType] = useState<DndItemType>(null);
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const [direction, setDirection] = useState<Direction>('right');
  const [dropGroupOverId, setDropGroupOverId] = useState<string | null>(null);
  const groupOriginRef = useRef<string | null>(null);
  const originItemsRef = useRef<Items>({});

  const [overIdState, setOverId] = useState<string | null>(null);

  useLayoutEffect(() => {
    setItems(withVoidItems ? setup(initialItems) : initialItems);
  }, [initialItems, withVoidItems]);

  const initialSwimlaneItemsHash = initialSwimlaneItems.join('');
  useLayoutEffect(() => {
    setSwimlaneItems(initialSwimlaneItems);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSwimlaneItemsHash]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const reset = useCallback(() => {
    setActiveId(null);
    setDropGroupOverId(null);
    setActiveType(null);
    setClonedItems(null);
    setDropGroupOverId(null);
    setOverId(null);
    groupOriginRef.current = null;
  }, []);

  const onDragStart = useCallback(({ active }: DragStartEvent) => {
    const stringActiveId = String(active.id);
    setActiveType(active.data.current?.type);
    setActiveId(stringActiveId);
    setClonedItems(items);
    groupOriginRef.current = findContainer(stringActiveId, items) ?? null;
    originItemsRef.current = items;
    onStart?.(stringActiveId);
  }, [items, onStart]);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const stringActiveId = String(event.active.id);
      const toMoveWithActive = getSelected(stringActiveId);
      const groupActiveId = findContainer(stringActiveId, items);
      const overId = String(event.over?.id);
      const groupOverId = overId ? findContainer(overId, items) : null;

      if (activeType === 'item') {
        const prevGroupIds = getPreviousGroupIds(originItemsRef.current, groupOriginRef.current);
        const shouldReset = shouldPreventDnD?.(event, prevGroupIds, groupActiveId, true) || false;
        if (!groupActiveId || !overId || !activeId || !groupOverId || shouldReset) {
          reset();
          return;
        }
        if (crossGroupStrategy === 'droppable' && dropGroupOverId) {
          const updatedItems = moveItemToDroppableGroup({
            items,
            activeId,
            groupActiveId,
            groupOverId: dropGroupOverId,
            setItems,
          });

          if (onItemsMoved) {
            setTimeout(() => {
              const newIndexPosition = updatedItems[dropGroupOverId].indexOf(activeId);
              const beforeId = updatedItems[dropGroupOverId][newIndexPosition + 1] ?? '';

              onItemsMoved?.({
                activeId,
                groupId: dropGroupOverId,
                previousGroupId: groupOriginRef.current && groupOriginRef.current !== groupActiveId ? groupOriginRef.current : undefined,
                itemsId: [activeId, ...toMoveWithActive],
                updatedItems,
                position: {
                  before: beforeId,
                },
              });
            }, DRAG_END_CALLBACK_DELAY);
          }
        } else {
          const previousGroupIds = getPreviousGroupIds(originItemsRef.current, groupOriginRef.current);
          const updatedItems = sortItems({
            items,
            activeId,
            overId,
            groupActiveId,
            groupOverId,
            setItems,
            toMoveWithActive,
          });

          if (onItemsMoved) {
            setTimeout(() => {
              const itemsGroups = updatedItems[groupActiveId].filter(itemId => !toMoveWithActive.includes(itemId));

              const newIndexPosition = itemsGroups.indexOf(activeId);
              const afterId = itemsGroups[newIndexPosition - 1] ?? undefined;
              const beforeId = itemsGroups[newIndexPosition + 1] ?? undefined;

              onItemsMoved?.({
                activeId,
                groupId: groupActiveId,
                previousGroupId: groupOriginRef.current && groupOriginRef.current !== groupActiveId ? groupOriginRef.current : undefined,
                previousGroupIds,
                itemsId: [activeId, ...toMoveWithActive],
                updatedItems,
                position: {
                  before: !beforeId && !afterId ? '' : beforeId,
                  after: !beforeId ? afterId : undefined,
                },
              });
            }, DRAG_END_CALLBACK_DELAY);
          }
        }
      } else if (activeType === 'group') {
        if (!groupActiveId || !overId || !activeId || !groupOverId) {
          reset();
          return;
        }

        if (activeId !== overId) {
          const sortedGroupIds = sortGroups({
            items,
            activeId,
            overId,
            setItems,
          });

          const newIndexPosition = sortedGroupIds.indexOf(activeId);
          const afterId = sortedGroupIds[newIndexPosition - 1] ?? undefined;
          const beforeId = sortedGroupIds[newIndexPosition + 1] ?? undefined;

          onGroupMoved?.({
            groupId: activeId,
            sortedGroupIds,
            position: {
              before: beforeId,
              after: !beforeId ? afterId : undefined,
            },
          });
        } else {
          onCancel?.();
        }
      } else if (activeType === 'swimlane') {
        if (!overId || !activeId) {
          reset();
          return;
        }
        if (activeId !== overId) {
          const swimlaneUpdated = sortSwimlaneItems({
            activeId,
            overId,
            swimlaneItems,
            setSwimlaneItems,
          });

          const newIndexPosition = swimlaneUpdated.indexOf(activeId);
          const afterId = swimlaneUpdated[newIndexPosition - 1] ?? undefined;
          const beforeId = swimlaneUpdated[newIndexPosition + 1] ?? undefined;

          onSwimlaneSorted?.({
            swimlaneActiveId: activeId,
            position: {
              before: beforeId,
              after: !beforeId ? afterId : undefined,
            },
          });
        } else {
          onCancel?.();
        }
      }

      reset();
    },
    [activeId, crossGroupStrategy, items, swimlaneItems, activeType, dropGroupOverId, onCancel, onGroupMoved, onItemsMoved, onSwimlaneSorted, reset],
  );

  const onDragCancel = useCallback(() => {
    if (clonedItems) {
      setItems(clonedItems);
    }
    reset();
    onCancel?.();
  }, [clonedItems, onCancel, reset]);

  const onDragOver = useCallback((event: DragOverEvent) => {
    const {
      over, active,
    } = event;

    if (shouldPreventDnD?.(event)) return;
    if (!over || !activeId) return;

    const overId = String(over.id);

    if (updateOverId) {
      setOverId(overId);
    }

    const groupOverId = findContainer(overId, items);
    const groupActiveId = findContainer(activeId, items);
    const overType = over.data.current?.type;
    const sameGroup = groupActiveId === groupOverId;

    if (activeType !== 'item') {
      return;
    }

    if (crossGroupStrategy === 'droppable' && overId) {
      if (overType === 'group-droppable') {
        setDropGroupOverId(!items[overId].includes(activeId) ? overId : null);
      } else if (overType === 'item') {
        const targetActiveId = findContainer(overId, items);
        setDropGroupOverId(targetActiveId && !sameGroup ? targetActiveId : null);
      }
    } else if (crossGroupStrategy === 'sorting' && groupActiveId && groupOverId && !sameGroup) {
      setDropGroupOverId(groupActiveId);
      moveItemToAnotherGroup({
        items,
        activeId,
        groupActiveId,
        groupOverId,
        toMoveWithActive: getSelected(String(active.id)),
        setItems,
      });
    }
  }, [items, activeId, activeType, updateOverId, crossGroupStrategy, shouldPreventDnD]);

  const onDragMove = useCallback(({ delta }: DragMoveEvent) => {
    const scrollPositionLeft = document.getElementById(BOARD_CONTENT_ID)?.scrollLeft ?? 0;
    setDirection(delta.x - scrollPositionLeft > 0 ? 'right' : 'left');
  }, []);

  const groupActiveId = activeId && activeType === 'item' ? findContainer(activeId, items) : null;

  const dndContextProps = useMemo(() => ({
    onDragCancel,
    onDragStart,
    onDragOver,
    onDragEnd,
    onDragMove,
    sensors,
    collisionDetection,
  }), [onDragCancel, onDragStart, onDragOver, onDragEnd, onDragMove, sensors, collisionDetection]);

  return {
    activeId,
    activeType,
    overId: overIdState,
    groupActiveId,
    dropGroupOverId,
    items,
    swimlaneItems,
    direction,
    dndContextProps,
  };
};

function getSelected(activeId: string) {
  const { selected } = getSelection();
  return selected.filter((selectedId) => selectedId !== activeId);
}

function findContainer(id: string, items: Items) {
  if (id in items) {
    return id;
  }
  return Object.keys(items).find((key) => items[key].includes(id));
}

function setup(items: Items) {
  const setupItems: Items = {};
  Object.keys(items).forEach((col) => {
    setupItems[col] = [`${col}-void`, ...items[col]];
  });
  return setupItems;
}

function getPreviousGroupIds(originItems: Items, originGroup: string | null) {
  const { selected } = getSelection();
  const groupIdsFromSelected = Object.keys(originItems)
    .filter(groupId => {
      const docsInGroup = originItems[groupId];
      return selected.some(s => docsInGroup.includes(s));
    });

  const groupIdsTotal = originGroup
    ? uniq(groupIdsFromSelected.concat([originGroup]))
    : groupIdsFromSelected;

  return groupIdsTotal;
}
