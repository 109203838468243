import { typo, Button, SelectPanel } from '@cycle-app/ui';
import { BackArrowIcon } from '@cycle-app/ui/icons';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { motion } from 'framer-motion';
import { darken } from 'polished';
import styled from 'styled-components';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

export const OVERLAY_Z_INDEX = mappingZindex[Layer.ProductTour];

export const Overlay = styled(motion.div)`
  z-index: ${OVERLAY_Z_INDEX - 5};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.33);
  background-color: rgba(109, 109, 109, 0.28);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled(motion.div)`
  z-index: ${OVERLAY_Z_INDEX - 4};
  position: fixed;
  background-color: ${p => p.theme.colors.components.ProductTourModal.bg};
  padding: 16px;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 408px;
`;

export const Chapters = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
`;

export const Chapter = styled.div<{ $isActive: boolean }>`
  width: 25px;
  height: 4px;
  border-radius: 30px;
  background-color: ${Color.White};
  opacity: ${p => (p.$isActive ? 1 : 0.2)};
`;

export const Title = styled.h2`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.components.ProductTourModal.text};
`;

export const P = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.components.ProductTourModal.text};
`;

export const Actions = styled.div<{ $placement: string }>`
  display: flex;
  justify-content: ${p => p.$placement};
  margin-top: 12px;
`;

export const ActionButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'M',
})`
  color: ${p => p.theme.colors.components.ProductTourModal.text};
  background: ${p => darken(0.1, p.theme.colors.components.ProductTourModal.bg)};
`;

export const HelpDropdown = styled.div<{ $isVisible: boolean }>`
  display: ${p => (p.$isVisible ? 'block' : 'none')};
  position: fixed;
  bottom: 56px;
  right: 16px;

  z-index: ${OVERLAY_Z_INDEX + 3};
`;

export const HelpDropdownContent = styled(SelectPanel)`
  background-color: ${p => p.theme.colors.background.primary};
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const HelpDropdownOverlay = styled.div<{ $isVisible: boolean }>`
  display: ${p => (p.$isVisible ? 'block' : 'none')};
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: ${OVERLAY_Z_INDEX - 3};
`;

export const HelpButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'M',
})`
  ${typo.headerSmall}
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: ${OVERLAY_Z_INDEX - 4};
  background-color: ${p => p.theme.colors.background.primary};
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: ${p => p.theme.colors.text.secondary};

  &:hover {
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const NextArrowIcon = styled(BackArrowIcon)`
  transform: rotate(180deg)
`;
