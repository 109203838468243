import { CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useFeedback } from './useFeedback';

export const useCustomPropertyDefinitions = (): CustomAttributeDefinitionFragment[] | null => {
  const { feedback } = useFeedback();
  if (!feedback) return null;

  return nodeToArray(feedback?.attributeDefinitions)
    .filter(definition => definition.__typename !== 'AttributeMultiSelectDefinition');
};
