import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

import { getLastView, useGetLastView } from './lastView.reactive';

interface GithubRepositoryState {
  lastUsedIds: Record<string, string | null>;
}

export const {
  hookValue: useGetGithubRepository,
  getValue: getGithubRepository,
  setValue: setGithubRepository,
} = make<GithubRepositoryState>({
  defaultState: {
    lastUsedIds: {},
  },
  localKey: LocalKey.LastUsedGitHubRepository,
});

export const setLastRepositoryIdUsed = (repositoryId: string) => {
  const { lastUsedIds } = getGithubRepository();
  const { productSlug } = getLastView();

  if (!repositoryId || !productSlug) return;

  setGithubRepository({
    lastUsedIds: {
      ...lastUsedIds,
      [productSlug]: repositoryId,
    },
  });
};

export const useLastRepositoryIdUsed = () => {
  const { lastUsedIds } = useGetGithubRepository();
  const { productSlug } = useGetLastView();

  if (productSlug == null) return null;

  return lastUsedIds[productSlug] ?? null;
};
