import { typo, ActionButton } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { ViewType } from 'src/types/viewType.types';

export const AddPropertyButton = styled(ActionButton)`
  opacity: 0;
  transition: opacity .2s ease-in-out;
`;

interface ContainerProps {
  viewType?: ViewType;
  nbAttributes: number;
  readOnly: boolean;
  $context: 'doc-item' | 'doc-panel';
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  flex-wrap: wrap;

  ${props => props.viewType === ViewType.List && css`
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    gap: 8px;
    overflow: hidden;
  `}

  ${p => p.readOnly && css`
    pointer-events: none;
  `};

  &:hover {
    ${AddPropertyButton} {
      opacity: 1;
    }
  }

  ${p => p.$context === 'doc-panel' && css`
    gap: 6px;
  `};
`;

export const Info = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 4px;
`;

export const Url = styled.p`
  ${typo.caption400};
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TooltipSubtitle = styled.p`
  ${typo.caption400};
  color: ${p => p.theme.colors.tooltip.textLight};
`;
