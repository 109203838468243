import { BillingPlan, Role } from '@cycle-app/graphql-codegen';

import { PermissionAction } from 'src/types/permissionAction.types';

export const PermissionsCollaborator: PermissionAction[] = [
  'USER_COLLABORATOR_CREATE',
];

export const PermissionsMaker: PermissionAction[] = [
  ...PermissionsCollaborator,
  'BOARD_CREATE',
  'BOARD_UPDATE',
  'BOARD_DELETE',
  'BOARD_SECTION_CREATE',
  'BOARD_SECTION_UPDATE',
  'BOARD_SECTION_DELETE',
  'BOARDS_REORDER',
  'GROUP_CREATE',
  'GROUP_UPDATE',
  'GROUPS_REORDER',
  'USER_MAKER_CREATE',
  'PLAYGROUND_READ',
  'SETTINGS_READ',
  'USER_UPDATE_ROLE_COLLABORATOR',
  'USER_UPDATE_ROLE_MAKER',
];

export const PermissionsAdmin: PermissionAction[] = [
  ...PermissionsMaker,
  'USER_ADMIN_CREATE',
  'USER_ADMIN_DELETE',
  'USER_UPDATE_ROLE_ADMIN',
];

export const PermissionsPlanFree: PermissionAction[] = [];

export const PermissionsPlanStandard: PermissionAction[] = [
  'BOARD_SECTION_CREATE',
  'BOARD_CREATE',
  'UPLOAD_NOT_RESTRICTED',
  'INCOGNITO',
  'INTEGRATION_ADD',
];

export const PermissionsByRoles = {
  [Role.Root]: PermissionsAdmin,
  [Role.SuperAdmin]: PermissionsAdmin,
  [Role.Admin]: PermissionsAdmin,
  [Role.Maker]: PermissionsMaker,
  [Role.Collaborator]: PermissionsCollaborator,
  [Role.User]: PermissionsCollaborator,
};

export const PermissionsByPlan = {
  [BillingPlan.Free]: PermissionsPlanFree,
  [BillingPlan.Standard]: PermissionsPlanStandard,
};
