import { useQuery } from '@apollo/client';
import { ProductDoctypesDocument, ProductDoctypesWithHierarchyFragment } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';

export const useDocTypes = (productId?: string) => {
  const {
    data, loading,
  } = useQuery<{ node: ProductDoctypesWithHierarchyFragment }>(ProductDoctypesDocument, {
    skip: !productId,
    variables: {
      productId,
    },
  });

  return {
    docTypes: nodeToArray(data?.node.doctypes),
    isLoading: loading,
  };
};
