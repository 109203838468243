import { MoreHorizIcon, MoreVertIcon } from '@cycle-app/ui/icons';
import { FC, MouseEvent, useState, useCallback, ReactNode } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect/DropdownSelect';
import { SelectOption } from '../Selects';
import { StyledActionButton } from './DotsMenu.styles';

export interface DotsMenuProps extends DropdownSelectProps {
  options: SelectOption[];
  onColoredBg?: boolean;
  vertical?: boolean;
  onClick?: (e: MouseEvent) => void;
  forceFocus?: boolean;
  icon?: ReactNode;
}

export const DotsMenu: FC<DotsMenuProps> = ({
  className,
  tooltip,
  ariaLabel,
  options,
  placement = 'bottom',
  tooltipPlacement = 'top',
  onColoredBg = false,
  onChange,
  onMount,
  onHide,
  onClick,
  onVisibilityChange,
  vertical = false,
  forceFocus,
  icon,
  visible: isVisibleFromProps,
  setVisible: setVisibleFromProps,
  ...dropdownProps
}) => {
  const [isVisible, setVisibility] = useState(false);

  const onButtonClick = useCallback((e: MouseEvent) => {
    setVisibility(!isVisible);
    onClick?.(e);
  }, [onClick, isVisible, setVisibility]);

  const finalIsVisible = isVisibleFromProps ?? isVisible;
  const finalSetVisible = setVisibleFromProps ?? setVisibility;

  return (
    <DropdownSelect
      visible={finalIsVisible}
      setVisible={finalSetVisible}
      options={options}
      placement={placement}
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
      onMount={onMount}
      onHide={onHide}
      onChange={onChange}
      onVisibilityChange={onVisibilityChange}
      hideSearch
      offsetY={8}
      {...dropdownProps}
    >
      <StyledActionButton
        className={className}
        forceFocus={forceFocus ?? finalIsVisible}
        onColoredBg={onColoredBg}
        tooltip={tooltip}
        tooltipPlacement={tooltipPlacement}
        onClick={onButtonClick}
        ariaLabel={ariaLabel}
      >
        {icon}
        {!icon && (vertical ? <MoreVertIcon /> : <MoreHorizIcon />)}
      </StyledActionButton>
    </DropdownSelect>
  );
};
