import { keyframes, css } from 'styled-components';

export const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const rotationCss = css`
  animation-name: ${rotateAnimation};
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const waveAnimation = keyframes`
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
`;

export const waveCss = css`
  animation: ${waveAnimation} 2.5s infinite;
  transform-origin: 70% 70%;
`;
