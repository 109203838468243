import { OnboardingLayout, Avatar, Tooltip } from '@cycle-app/ui';
import { FC } from 'react';

import { useMaybeMe } from 'src/hooks/api/useMe';
import { setOnboarding } from 'src/reactives/onboarding.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';

import {
  StyledMockDocPanel,
  StyledContentText, StyledSlashParagraph, StyledMockSlashDropdown,
  StyledOnboardingCollabMates, StyledOnboardingFloatingMates, Cover,
} from './StepCollaborate.styles';

type Props = {
  progress: number;
};

export const StepCollaborateReadOnly: FC<Props> = ({ progress }) => {
  const me = useMaybeMe();
  const isMobile = useIsMobile();
  return (
    <OnboardingLayout
      title="Real-time collaboration"
      description={(
        <>
          Cycle&apos;s atomic unit is a natively collaborative doc.
          You can write rich content together with your teammates in real-time.
        </>
      )}
      cover={!isMobile && (
        <Cover>
          <StyledMockDocPanel
            initial={{
              opacity: 0,
              translateY: 60,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
            }}
            rightActions={(
              <StyledOnboardingCollabMates hideTip>
                <Tooltip placement="bottom" content={`${me?.firstName} (me)`}>
                  <Avatar user={me} />
                </Tooltip>
              </StyledOnboardingCollabMates>
            )}
          >
            <StyledContentText>
              <p>Hey,</p>
              <p>
                Welcome to cycle. This is our atomic unit: a natively collaborative doc. You can work on it
                together with your team. And it&apos;s quite rich.
              </p>
              <StyledSlashParagraph>
                /
                <StyledMockSlashDropdown
                  initial={{
                    opacity: 0,
                    translateY: 20,
                  }}
                  animate={{
                    opacity: 1,
                    translateY: 0,
                  }}
                  transition={{
                    delay: 0.6,
                  }}
                />
              </StyledSlashParagraph>
            </StyledContentText>
          </StyledMockDocPanel>
          <StyledOnboardingFloatingMates
            positions={[{
              x: -4,
              y: 38,
              rot: -33,
            }, {
              x: 83,
              y: 13,
              rot: 143,
            }, {
              x: 98,
              y: 66,
              rot: 226,
            }]}
          />
        </Cover>
      )}
      main={null}
      progress={progress}
      nextButtonProps={{
        onClick: () => {
          setOnboarding({ screen: OnboardingScreen.Chrome });
        },
      }}
      isModal
      prevButtonProps={{
        onClick: () => setOnboarding({ screen: OnboardingScreen.Integrations }),
      }}
      isFixedFooter
    />
  );
};
