import { FeatureFlag } from '@cycle-app/utilities';
import { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { CustomersView, CompaniesView } from 'src/components/Views';
import { BillingPlans } from 'src/components/Views/BillingPlans';
import { PageId, routing } from 'src/constants/routing.constant';
import { useIsStandardPlan } from 'src/hooks/api/useProduct';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useGetInitial } from 'src/reactives/initial.reactive';

import { Container, ScrollableContent, ContentPage } from './Settings.styles';
import SettingsAttributes from './SettingsAttributes/SettingsAttributes';
import { SettingsBilling } from './SettingsBilling/SettingsBilling';
import SettingsDoctype from './SettingsDocType/SettingsDocType';
import { SettingsHierarchy } from './SettingsHierarchy';
import { SettingsIntegrations } from './SettingsIntegrations/SettingsIntegrations';
import SettingsSidebar from './SettingsSidebar/SettingsSidebar';
import SettingsUsers from './SettingsUsers/SettingsUsers';
import { SettingsWorkflows } from './SettingsWorkflows';
import SettingsWorkspace from './SettingsWorkspace/SettingsWorkspace';

export const Settings: FC = () => {
  const { loading } = useGetInitial();
  const { isEnabled: isBillingEnabled } = useFeatureFlag(FeatureFlag.Billing);
  const { isEnabled: isBillingPlanEnabled } = useFeatureFlag(FeatureFlag.BillingPlan);
  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);
  const isStandardPlan = useIsStandardPlan();

  // TODO: implement skeletons
  return (
    <Container>
      <SettingsSidebar />
      <ScrollableContent>
        <ContentPage>
          <Switch>
            <Route exact path={routing[PageId.Settings]}>
              {loading ? <div>Loading workspace…</div> : <SettingsWorkspace />}
            </Route>
            <Route path={routing[PageId.SettingsUsers]}>
              {loading ? <div>Loading users…</div> : <SettingsUsers />}
            </Route>
            <Route path={routing[PageId.SettingsCustomers]} exact>
              <CustomersView />
            </Route>
            <Route path={routing[PageId.SettingsCompanies]} exact>
              <CompaniesView />
            </Route>
            {isBillingEnabled && isStandardPlan && (
              <Route path={routing[PageId.SettingsBilling]}>
                <SettingsBilling />
              </Route>
            )}
            <Route path={routing[PageId.SettingsDocTypes]}>
              {loading ? <div>Loading doc types…</div> : <SettingsDoctype />}
            </Route>
            <Route exact path={routing[PageId.SettingsAttributes]}>
              {loading ? <div>Loading attributes…</div> : <SettingsAttributes />}
            </Route>
            <Route exact path={routing[PageId.SettingsHierarchy]}>
              {loading ? <div>Loading hierarchy</div> : <SettingsHierarchy />}
            </Route>
            <Route exact path={routing[PageId.SettingsIntegrations]}>
              {loading ? <div>Loading integrations</div> : <SettingsIntegrations />}
            </Route>
            {isBillingPlanEnabled && (
              <Route exact path={routing[PageId.BillingPlans]}>
                {loading ? <div>Loading plans</div> : <BillingPlans />}
              </Route>
            )}
            {isStatusEnabled && (
              <Route exact path={routing[PageId.SettingsWorkflows]}>
                {loading ? <div>Loading workflows</div> : <SettingsWorkflows />}
              </Route>
            )}
            <Redirect to={routing[PageId.Settings]} />
          </Switch>
        </ContentPage>
      </ScrollableContent>
    </Container>
  );
};
