import { make } from 'src/utils/reactives.util';

export interface AddMemberResult {
  visible: boolean;
}

export const {
  hookState: useAddMember,
  hookValue: useGetAddMember,
  getValue: getAddMember,
  setValue: setAddMember,
  resetValue: resetAddMember,
} = make<AddMemberResult>({
  defaultState: {
    visible: false,
  },
});

export const toggleAddMember = () => setAddMember({ visible: !getAddMember().visible });
