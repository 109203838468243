import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { FC, useState } from 'react';

import { DropdownProps } from '../Dropdown/Dropdown';
import {
  Container,
  ContainerProps,
  Dropdown,
  ColorItemStyled,
  ColorPickerStyled,
} from './NuancePicker.styles';

interface Props extends Omit<DropdownProps, 'content' | 'visible'> {
  color: ColorApi;
  onClick?: (color: ColorApi) => void;
  size?: ContainerProps['$size'];
}
export const NuancePicker: FC<Props> = ({
  color,
  onClick,
  size = 'S',
  ...dropdownProps
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Dropdown
      {...dropdownProps}
      visible={showDropdown}
      hide={() => setShowDropdown(false)}
      content={(
        <ColorPickerStyled
          onClick={onClick}
          color={color}
          small
        />
      )}
    >
      <Container $size={size} onClick={() => setShowDropdown(!showDropdown)}>
        <ColorItemStyled $color={color} />
      </Container>
    </Dropdown>
  );
};
