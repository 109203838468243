import { COMMANDS } from 'src/constants/editor.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';

import {
  IconContainer, Panel, PanelItem, ItemLabel, ItemTitle, ItemDesc, InstallButton,
} from './QuickIntegrations.styles';
import { QuickIntegrationsByType } from './QuickIntegrations.types';

type Props = {
  integrations: QuickIntegrationsByType;
  hide: VoidFunction;
};

export const QuickIntegrationPanel = ({
  hide, integrations,
}: Props) => {
  const install = useInstallIntegration();
  const { editor } = useEditorContext();
  return (
    <Panel>
      {Object.values(integrations).map(item => (
        <PanelItem
          key={item.integration.id}
          $disabled={!item.isInstalled}
          onClick={() => {
            if (!item.isInstalled || !item.data.commandKey) return;
            editor.chain().focus().insertContent(COMMANDS[item.data.commandKey]).run();
            hide();
          }}
        >
          <IconContainer>{item.data.icon}</IconContainer>

          <ItemLabel>
            <ItemTitle>{item.data.title}</ItemTitle>
            <ItemDesc>{`Insert ${item.data.labelOne}`}</ItemDesc>
          </ItemLabel>

          {!item.isInstalled && (
            <InstallButton
              onClick={async e => {
                e.stopPropagation();
                await install(item.integration);
              }}
            >
              Install
            </InstallButton>
          )}
        </PanelItem>
      ))}
    </Panel>
  );
};
