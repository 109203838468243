import { BoardWithMinimalConfigFragment } from '@cycle-app/graphql-codegen';
import { NavigationSectionSkeleton } from '@cycle-app/ui';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useBoardSectionsMutations from 'src/hooks/api/mutations/useBoardSectionsMutations';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useGroupsDnd } from 'src/hooks/dnd/useGroupsDnd';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getSections, useSections } from 'src/reactives/sections.reactive';
import { BoardRouteParams } from 'src/types/routes.types';
import { sidebarCollisionDetection } from 'src/utils/dnd.util';
import { getBoardId } from 'src/utils/slug.util';

import SidebarBoardSection from './SidebarBoardSection/SidebarBoardSection';
import SidebarBoardSectionSortable from './SidebarBoardSection/SidebarBoardSectionSortable';
import SidebarItemBoard from './SidebarBoardSection/SidebarItemBoard/SidebarItemBoard';
import SidebarBoardNewSection from './SidebarBordNewSection/SidebarBoardNewSection';

const SidebarBoardSections: FC = () => {
  const {
    moveBoardInSectionsList,
    moveSection,
  } = useBoardSectionsMutations();
  const {
    sections,
    loading,
    mappingBoards,
    mappingSections,
  } = useBoardSections();
  const { boardSlug } = useParams<BoardRouteParams>();
  const boardId = getBoardId(boardSlug ?? '');
  const isOnboarding = useIsOnboarding();

  const [sectionsState, setSectionsState] = useSections();

  // Dnd boards
  const initialBoardItems = useMemo(
    () => Object.fromEntries(sections.map(section => [section.id, section.boards.edges.map(board => board.node.id)])),
    [sections],
  );
  const {
    activeId,
    activeType,
    groupActiveId,
    dropGroupOverId,
    dndContextProps,
    items,
  } = useGroupsDnd({
    initialItems: initialBoardItems,
    collisionDetection: sidebarCollisionDetection,
    crossGroupStrategy: 'droppable',
    onGroupMoved: params => moveSection({
      sectionId: params.groupId,
      updatedGroupIds: params.sortedGroupIds,
      position: params.position,
    }),
    onItemsMoved: params => moveBoardInSectionsList({
      boardId: params.itemsId[0],
      fromSectionId: params.previousGroupId ?? '',
      toSectionId: params.groupId,
      position: params.position,
    }),
  });

  const {
    canReorderBoards, canCreateBoardSection,
  } = useGetPermission();

  if (loading) {
    return <NavigationSectionSkeleton />;
  }

  return (
    <DndContext {...dndContextProps}>
      <SortableContext
        items={Object.keys(items)}
        strategy={verticalListSortingStrategy}
        disabled={!canReorderBoards}
      >
        {Object.keys(items)
          .filter(sectionId => !!mappingSections[sectionId])
          .map((sectionId) => {
            const isOpen = activeId !== sectionId && (sectionsState[sectionId] === undefined || sectionsState[sectionId]);
            return (
              <SidebarBoardSectionSortable
                key={sectionId}
                section={mappingSections[sectionId]}
                sections={Object.values(mappingSections)}
                isOpen={isOpen}
                toggleSection={toggleSection(sectionId)}
                asPlaceholder={activeType === 'group' && activeId === sectionId}
                boardsId={items[sectionId]}
                getBoard={getBoard}
                droppableEnabled={activeType === 'item' && groupActiveId !== sectionId}
                isOver={sectionId === dropGroupOverId}
              />
            );
          })}
      </SortableContext>
      {!isOnboarding && canCreateBoardSection && <SidebarBoardNewSection />}
      <DragOverlay>
        {activeId && activeType === 'group' && (
          <SidebarBoardSection
            section={mappingSections[activeId]}
            sections={Object.values(mappingSections)}
            isOpen={false}
            isDragging
          />
        )}
        {activeId && activeType === 'item' && (
          <SidebarItemBoard
            board={mappingBoards[activeId]}
            isDragging
            isActive={activeId === boardId}
          />
        )}
      </DragOverlay>
    </DndContext>
  );

  function getBoard(id: string): BoardWithMinimalConfigFragment | undefined {
    return mappingBoards[id];
  }

  function toggleSection(sectionId: string) {
    return () => {
      const currentState = getSections()[sectionId];
      setSectionsState({ [sectionId]: currentState === undefined ? false : !currentState });
    };
  }
};

export default SidebarBoardSections;
