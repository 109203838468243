import { SelectPanel, CustomPropertyInputText, TextType, CheckboxInput } from '@cycle-app/ui';
import { toIsoString } from '@cycle-app/utilities';
import { FC } from 'react';
import { useController, Control } from 'react-hook-form';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { getLastCustomFieldUsed, setLastCustomFieldUsed } from 'src/reactives';
import { CreateDocMentionFormCustomField, CreateDocMentionFormValues } from 'src/types/doc.types';
import { Layer } from 'src/types/layers.types';

import {
  Container, Label, ToggleContainer, InputContainer, Caret, InputContent,
} from './CustomField.styles';

const inputType: Record<'AttributeDateDefinition' | 'AttributeEmailDefinition' | 'AttributeNumberDefinition' | 'AttributePhoneDefinition' | 'AttributeTextDefinition' | 'AttributeUrlDefinition', TextType> = {
  AttributeDateDefinition: 'date',
  AttributeEmailDefinition: 'email',
  AttributeNumberDefinition: 'number',
  AttributePhoneDefinition: 'phone',
  AttributeTextDefinition: 'text',
  AttributeUrlDefinition: 'text',
};

type Props = {
  control: Control<CreateDocMentionFormValues>;
  field: CreateDocMentionFormCustomField;
};

export const CustomField: FC<Props> = ({
  control, field,
}) => {
  const [isVisible, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(false);
  const {
    field: {
      onChange, value,
    },
  } = useController({
    name: field.id,
    control,
    defaultValue: getLastCustomFieldUsed(field.id),
  });

  if (field.type === 'AttributeMultiSelectDefinition') return null;

  return (
    <Container>
      <Label>{field.name}</Label>
      {renderDropdown()}
    </Container>
  );

  function renderDropdown() {
    if (
      field.type === 'AttributeMultiSelectDefinition' ||
      field.type === 'AttributeSingleSelectDefinition'
    ) {
      const options = field?.options?.map(option => ({
        value: option.node.id,
        label: option.node.value,
        selected: field.type === 'AttributeMultiSelectDefinition' &&
          Array.isArray(field.inheritAttributeValueFromDocContext)
          ? (value as { value: string }[]).map(v => v.value).includes(option.node.id)
          : false,
      })) || [];
      const selectPanelProps = field.type === 'AttributeMultiSelectDefinition'
        ? {
          isMulti: true as const,
          onOptionsChange: (newValues: any[]) => {
            onChange(newValues);
            /* TODO: Handle the multi-select value when we will support that
             * property type in the custom fields form
             */
            // setLastCustomFieldUsed(field.id, JSON.stringify(newValues));
          },
        }
        : {
          onOptionChange: (newValue: any) => {
            onChange(newValue);
            setFalseCallback();
            setLastCustomFieldUsed(field.id, newValue);
          },
        };

      return (
        <DropdownLayer
          layer={Layer.DropdownModalZ1}
          visible={isVisible}
          hide={setFalseCallback}
          placement="bottom-start"
          content={(
            <SelectPanel
              options={options}
              {...selectPanelProps}
            />
          )}
        >
          {renderValueContainer()}
        </DropdownLayer>
      );
    }

    return renderValueContainer();
  }

  function renderValueContainer() {
    switch (field.type) {
      case 'AttributeDateDefinition':
        return (
          <CustomPropertyInputText
            type={inputType[field.type]}
            variant="dropdown"
            hasHelper={false}
            onInputChange={(e) => onChange(toIsoString(e.target.value))}
            values={[value || '']}
            placeholder={`Enter a ${field.name}`}
          />
        );
      case 'AttributeEmailDefinition':
      case 'AttributeNumberDefinition':
      case 'AttributePhoneDefinition':
      case 'AttributeTextDefinition':
      case 'AttributeUrlDefinition':
        return (
          <CustomPropertyInputText
            type={inputType[field.type]}
            variant="dropdown"
            hasHelper={false}
            onInputChange={(e) => onChange(e.target.value)}
            values={[value || '']}
            placeholder={`Enter a ${field.name}`}
          />
        );
      case 'AttributeCheckboxDefinition':
        return (
          <ToggleContainer>
            <CheckboxInput
              id={field.id}
              checked={value || false}
              onChange={() => onChange(!value)}
            />
          </ToggleContainer>
        );
      case 'AttributeMultiSelectDefinition': {
        const [firstValue, ...otherValues] = value;
        let label = value.length ? firstValue.label : null;
        if (label && otherValues) {
          label += ` +${otherValues.length}`;
        }

        return (
          <InputContainer onClick={setTrueCallback}>
            <InputContent>
              {label || 'Choose from list'}
            </InputContent>
            <Caret />
          </InputContainer>
        );
      }
      case 'AttributeSingleSelectDefinition':
        return (
          <InputContainer onClick={setTrueCallback}>
            <InputContent>
              {value ? value.label : 'Choose from list'}
            </InputContent>
            <Caret />
          </InputContainer>
        );
      default:
        return null;
    }
  }
};
