import { typo } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const Caret = styled(CaretIcon)`
  margin-left: auto;
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

const focusCSS = css`
  border-color: ${p => p.theme.userColors.main};
  outline: 1px solid ${p => p.theme.userColors.main};
`;
export const Select = styled.button<{ forceFocus: boolean }>`
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 7px 8px;

  width: 100%;
  ${typo.body400};

  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  ${p => p.forceFocus && focusCSS}
  &:focus {
    ${focusCSS};
  }
`;
