import { Avatar, Tooltip } from '@cycle-app/ui';

import { useMaybeMe } from 'src/hooks/api/useMe';
import { getUserLabel } from 'src/utils/users.util';

import { Label } from './NewDoc.styles';

export type MeProps = {
  full?: boolean;
};

export const Me = ({ full }: MeProps) => {
  const me = useMaybeMe();
  return (
    <Tooltip content={getUserLabel(me)} disabled={full}>
      <Label>
        <Avatar user={me} size={20} />
        {full && getUserLabel(me)}
      </Label>
    </Tooltip>
  );
};
