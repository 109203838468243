import { ActionButton, DotsMenu } from '@cycle-app/ui';
import { ResizeImageIcon, MoveIcon, TrashIcon, DownloadIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useRef, useLayoutEffect } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { ImageMenu } from 'src/components/ImageMenu';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { useNodeSelection } from 'src/hooks/editor/useNodeSelection';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { ImageSize, ImageViewAttributes } from 'src/types/editor.types';
import { Layer } from 'src/types/layers.types';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';
import { downloadFile } from 'src/utils/files.util';

import {
  ImageView as StyledImageView,
  Image as StyledImage,
  MenuContent,
  DragActionButton,
  SelectLineItem,
  StyledCheckIcon,
} from './ImageView.styles';

interface ImageViewProps extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (p: { size: ImageSize }) => void;
}

const WIDTH_BY_IMAGE_SIZE: Record<ImageSize, string> = {
  [ImageSize.SMALL]: '50%',
  [ImageSize.MEDIUM]: '75%',
  [ImageSize.LARGE]: '100%',
};

const ImageView: FC<ImageViewProps> = ({
  node,
  selected,
  getPos,
  updateAttributes,
}) => {
  const parent = useRef<HTMLElement>(null);
  const { editor } = useEditorContext();
  const { isSelected } = useNodeSelection({
    editor,
    selected,
    getPos,
  });

  const {
    size,
    src,
    title,
  } = node.attrs as ImageViewAttributes;

  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);
  const [isDotsDropdownVisible, { toggleCallback: toggleDotsDropdown }] = useOptimizedBooleanState(false);

  /**
   * When dragging the node view, Tiptap preview the content of the NodeView parent (which has by default 100% width)
   * Updating the size of the parent (instead of the NodeView) allow to preview to correct image
   */
  useLayoutEffect(() => {
    const nodeWrapperParent = parent.current?.parentElement;
    if (nodeWrapperParent) {
      nodeWrapperParent.style.width = WIDTH_BY_IMAGE_SIZE[size];
    }
  }, [size]);

  return (
    <StyledImageView
      $isSelected={isSelected}
      ref={parent}
      data-drag-handle
    >
      <ImageMenu forceVisible={isDropdownVisible || isDotsDropdownVisible}>
        <DropdownLayer
          visible={isDropdownVisible}
          layer={Layer.DropdownModalZ2}
          hide={hideDropdown}
          content={(
            <>
              <SelectLineItem
                onClick={() => updateAttributes({ size: ImageSize.LARGE })}
                label="Large"
                endSlot={size === ImageSize.LARGE ? <StyledCheckIcon /> : undefined}
              />
              <SelectLineItem
                onClick={() => updateAttributes({ size: ImageSize.MEDIUM })}
                label="Medium"
                endSlot={size === ImageSize.MEDIUM ? <StyledCheckIcon /> : undefined}
              />
              <SelectLineItem
                onClick={() => updateAttributes({ size: ImageSize.SMALL })}
                label="Small"
                endSlot={size === ImageSize.SMALL ? <StyledCheckIcon /> : undefined}
              />
            </>
          )}
        >
          <MenuContent>
            <ActionButton tooltip="Size" tooltipPlacement="top" onClick={toggleDropdown} forceFocus={isDropdownVisible}>
              <ResizeImageIcon />
            </ActionButton>
          </MenuContent>
        </DropdownLayer>

        <DragActionButton tooltip="Move" tooltipPlacement="top" data-drag-handle>
          <MoveIcon />
        </DragActionButton>

        <DotsMenu
          placement="bottom-end"
          visible={isDotsDropdownVisible}
          forceFocus={isDotsDropdownVisible}
          setVisible={toggleDotsDropdown}
          zIndex={mappingZindex[Layer.Dropdown]}
          options={[
            {
              label: 'Download',
              value: 'download',
              icon: <DownloadIcon />,
              onSelect: async () => {
                await downloadFile({
                  mime: 'image',
                  src,
                  title: title || 'Image downloaded from Cycle',
                });
              },
            },
            {
              label: 'Delete',
              value: 'delete',
              icon: <TrashIcon />,
              variant: 'danger',
              onSelect: () => deleteNodeRange({
                editor,
                node,
                getPos,
              }),
            },
          ]}
        />
      </ImageMenu>
      <StyledImage src={src} alt={title} draggable={false} />
    </StyledImageView>
  );
};

export default ImageView;
