import { SwimlaneDocFragment } from '@cycle-app/graphql-codegen';
import { Tag, Emoji } from '@cycle-app/ui';
import { TriangleIcon, EyeClosedIcon } from '@cycle-app/ui/icons';
import { VFC, HTMLAttributes, forwardRef, useState, useCallback } from 'react';

import { BULK_PREVENT_CLASSNAME } from 'src/constants/bulkSelection.constants';
import useManageHiddenSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageHiddenSwimlanes';
import { useLoader } from 'src/hooks/useLoader';

import {
  Menu,
  Container,
  IndicatorBtn,
  SwimlaneGroupName,
  DocTitle,
  StyledLinkDraggable,
} from './SwimlaneDoc.styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: string;
  boardConfigId: string | null | undefined;
  doc?: SwimlaneDocFragment;
  docUrl?: string;
  name?: string;
  collapsed: boolean;
  onToggleCollapsed?: (id: string) => void;
  asPlaceholder?: boolean;
}
const SwimlaneDoc: VFC<Props> = forwardRef<HTMLInputElement, Props>(({
  id,
  boardConfigId,
  doc,
  docUrl,
  name = '',
  collapsed,
  onToggleCollapsed,
  asPlaceholder,
  className = '',
  ...containerProps
}, ref) => {
  const {
    hideSwimlane,
    loading,
  } = useManageHiddenSwimlanes({
    boardConfigId,
    swimlaneConfigId: null,
  });

  useLoader({ loading });

  const [isMenuVisible, setMenuVisible] = useState(false);
  const onHideSwimlane = useCallback(() => hideSwimlane(id, { updateSwimlanes: true }), [hideSwimlane, id]);

  return (
    <Container
      className={`${className} ${BULK_PREVENT_CLASSNAME}`}
      ref={ref}
      asPlaceholder={asPlaceholder}
      {...containerProps}
    >
      {docUrl ? (
        <StyledLinkDraggable
          to={docUrl}
          target="_blank"
          $isMenuVisible={isMenuVisible}
        >
          {renderContent()}
        </StyledLinkDraggable>
      ) : (
        <span className="content">
          {renderContent()}
        </span>
      )}
    </Container>
  );

  function renderContent() {
    return (
      <>
        <IndicatorBtn
          $collapsed={collapsed}
          onClick={(e) => {
            e.preventDefault();
            onToggleCollapsed?.(id);
          }}
        >
          <TriangleIcon direction="bottom" />
        </IndicatorBtn>

        <SwimlaneGroupName>
          {doc && (
            <>
              <Tag><Emoji size={12} emoji={doc.doctype.emoji} /></Tag>
              <Tag>{doc._docKey}</Tag>
            </>
          )}
          <DocTitle>{doc?.title ?? name}</DocTitle>
        </SwimlaneGroupName>

        <Menu
          visible={isMenuVisible}
          setVisible={setMenuVisible}
          forceFocus={isMenuVisible}
          options={[
            // TODO: waiting for the UX
            // {
            //   label: 'Rename',
            //   value: 'rename',
            //   icon: <PenIcon />,
            // },
            {
              label: 'Hide',
              value: 'hide',
              icon: <EyeClosedIcon />,
              disabled: loading,
              onSelect: onHideSwimlane,
            },
          ]}
        />
      </>
    );
  }
});

export default SwimlaneDoc;
