import { useFormContext } from 'react-hook-form';

import { StyledEditor, SkeletonInput } from './Form.styles';
import { useFeedback } from './useFeedback';

export const FieldDescription = () => {
  const { setValue } = useFormContext();
  const {
    feedback, isLoading,
  } = useFeedback();
  if (isLoading) return <SkeletonInput />;

  return (
    <StyledEditor
      docType={feedback}
      emptyPlaceholder="Describe what your doc type is about. Type '/' for commands"
      onUpdate={value => setValue('description', value.json)}
    />
  );
};
