import { ModalTitle, NavigationItem, ShyScrollbar } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const StyledPortalModal = styled(PortalModal)`
  display: flex;
  padding: 0;

  height: 615px;
  max-height: calc(100vh - 40px);
  width: 984px;
  max-width: calc(100vw - 40px);
`;

export const LeftPanel = styled.div`
  background-color: ${p => p.theme.colors.settingsModal.leftPanel.bg};
  padding: 24px;

  position: sticky;
  top: 0;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 182px;
`;

export const MenuItem = styled(NavigationItem)`
  font-weight: ${p => (p.isActive ? 500 : 400)};
`;

export const RightPanel = styled.div`
  background-color: ${p => p.theme.colors.settingsModal.rightPanel.bg};
  padding: 24px;

  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(ModalTitle)`
  margin-right: auto;
`;

export const TabContent = styled(ShyScrollbar)`
  margin: 0 -10px;
  padding: 0 10px;
  height: 100%;
`;
