import EditorFileCard from '@cycle-app/ui/components/Editor/FileCard/FileCard';
import { TrashIcon, DownloadIcon } from '@cycle-app/ui/icons';
import { FileUploadedData } from '@cycle-app/utilities';
import { NodeViewRendererProps } from '@tiptap/react';
import { useCallback, FC } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useEditorContext } from 'src/contexts/editorContext';
import { useNodeSelection } from 'src/hooks/editor/useNodeSelection';
import { Layer } from 'src/types/layers.types';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';
import { getFileSizeWithUnit, downloadFile } from 'src/utils/files.util';

import { FileView as StyledFileView } from './FileView.styles';

interface Props extends NodeViewRendererProps {
  selected: boolean;
}

const FileView: FC<Props> = ({
  node, selected, getPos,
}) => {
  const { editor } = useEditorContext();
  const { isSelected } = useNodeSelection({
    editor,
    selected,
    getPos,
  });
  const file = node.attrs.file as FileUploadedData;

  const onDownload = useCallback(
    () => file && downloadFile(file),
    [file],
  );

  return (
    <StyledFileView $isSelected={isSelected} data-drag-handle>
      <EditorFileCard
        size={file?.size ? getFileSizeWithUnit(file.size) : undefined}
        title={file?.title ?? 'no-name'}
        actions={(
          <DotsMenuLayer
            layer={Layer.DropdownZ1}
            placement="bottom-end"
            options={[{
              label: 'Download',
              value: 'download',
              icon: <DownloadIcon />,
              onSelect: onDownload,
            }, {
              label: 'Delete',
              value: 'delete',
              icon: <TrashIcon />,
              variant: 'danger',
              onSelect: () => deleteNodeRange({
                editor,
                node,
                getPos,
              }),
            }]}
          />
        )}
      />
    </StyledFileView>
  );
};

export default FileView;
