import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { Input, ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import React, {
  ChangeEvent, FC, ReactNode, RefObject, useMemo, useRef, useState,
} from 'react';

import PortalModal from 'src/components/PortalModal/PortalModal';
import { ACCEPTED_IMAGE_FORMAT } from 'src/constants/upload.constant';

import {
  Container,
  Image,
  Children,
  ModalHeader,
  ModalContent,
  ModalButton,
} from './ImageInput.styles';

interface Props {
  className?: string;
  label?: string;
  withPreview?: boolean;
  previewBorderColor?: ColorApi;
  previewModalTitle?: string;
  previewModalSubmitLabel?: string;
  onChange: (image: File) => void;
  renderPreview?: (imageSrc: string) => ReactNode;
  children: (inputRef: RefObject<HTMLInputElement>, chooseFile: (file: File) => void) => ReactNode;
}

const ImageInput: FC<Props> = ({
  className,
  label,
  previewBorderColor,
  previewModalTitle,
  previewModalSubmitLabel,
  children,
  onChange,
  renderPreview,
  withPreview = true,
}) => {
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState<File>();

  const previewImageSrc = useMemo(
    () => (previewImage ? URL.createObjectURL(previewImage) : ''),
    [previewImage],
  );

  return (
    <Container className={className}>
      <Input
        ref={avatarInputRef}
        id={label}
        type="file"
        label={label}
        name={label}
        maxLength={1}
        accept={ACCEPTED_IMAGE_FORMAT.join(',')}
        onChange={onFileSelected}
        onClick={(event) => {
          // --> To allow selecting the same file after having cancelled we need to reset the event target value
          // @ts-ignore react "event" type for onClick prop is missing a lot of properties
          // eslint-disable-next-line no-param-reassign
          event.target.value = null;
        }}
        inputHidden
      />

      <Children>
        {children(avatarInputRef, chooseFile)}
      </Children>

      <AnimatePresence>
        {withPreview && previewImage && (
          <PortalModal
            useHighMaskLayer
            hide={resetPreview}
          >
            <ModalHeader>
              <h1>{previewModalTitle}</h1>
              <ActionButton onClick={resetPreview}>
                <CloseIcon />
              </ActionButton>
            </ModalHeader>

            <ModalContent>
              {renderPreview
                ? renderPreview(previewImageSrc)
                : (
                  <Image
                    previewBorderColor={previewBorderColor}
                    src={previewImageSrc}
                    alt="preview"
                  />
                )}
            </ModalContent>

            <ModalButton
              size="L"
              onClick={onPreviewSubmitted}
            >
              {previewModalSubmitLabel}
            </ModalButton>
          </PortalModal>
        )}
      </AnimatePresence>
    </Container>
  );

  function onPreviewSubmitted() {
    if (previewImage) {
      onChange(previewImage);
      resetPreview();
    }
  }

  function resetPreview() {
    if (previewImageSrc) {
      // To prevent memory leaks
      URL.revokeObjectURL(previewImageSrc);
    }
    setPreviewImage(undefined);
  }

  function onFileSelected(e: ChangeEvent<HTMLInputElement>) {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) { return null; }
    return chooseFile(selectedFile);
  }

  function chooseFile(file: File) {
    return withPreview
      ? setPreviewImage(file)
      : onChange(file);
  }
};

export default ImageInput;
