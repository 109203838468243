import { ThemeType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { getAllThemes } from '@cycle-app/ui/utils/theme.util';

import { setThemeConfig } from 'src/reactives/theme.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

export const useThemesResult = (): Array<CommandActionCategory> => {
  const themeEmojis = {
    [ThemeType.Eclipse]: 'first_quarter_moon',
    [ThemeType.Nightfall]: 'new_moon',
    [ThemeType.Sunrise]: 'full_moon',
  };

  return [{
    id: 'themes',
    actions: getAllThemes().map(theme => ({
      id: theme.id,
      label: theme.name,
      icon: <Emoji emoji={themeEmojis[convertLegacyLocalStorageValue(theme.id)]} />,
      onSelect: () => {
        setThemeConfig({
          colorTheme: theme.id as ThemeType,
        });
      },
    })),
  }];
};
