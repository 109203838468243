import { UpdateDocContentDocument, ViewType, SectionType } from '@cycle-app/graphql-codegen/generated';
import { Input, OnboardingLayout, Spinner } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { JSONContent } from '@tiptap/core';
import { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ThemeProvider } from 'styled-components';

import { AppPreview, previewVariant } from 'src/app/Auth/Welcome/Welcome.styles';
import Main from 'src/app/Main/Main';
import { OnboardingPreviewBoardRoute } from 'src/components/OnboardingPreviewBoardRoute/OnboardingPreviewBoardRoute';
import { ALL_DOCS_BOARD_SLUG, PageId } from 'src/constants/routing.constant';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useGetGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import { useCreateDoc } from 'src/hooks/api/mutations/useCreateDoc';
import { useBoardGroups } from 'src/hooks/api/useBoardGroups';
import { useDoctypesFromBoardConfig } from 'src/hooks/api/useDoctypesFromBoardConfig';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { useProductFromInitial } from 'src/hooks/api/useProductFromInitial';
import { useErrorToaster } from 'src/hooks/useErrorToaster';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getOnboarding, setOnboarding } from 'src/reactives/onboarding.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';
import { createDummyDoc, getDocKey } from 'src/utils/doc.util';

import { ErrorHelper, LoaderContainer, StyledFormContainer } from './StepCreateDocs.styles';
import { StepCreateDocsBoard } from './StepCreateDocsBoard';

type Props = {
  progress: number;
};

interface FormData {
  doc1: string;
  doc2: string;
  doc3: string;
}

const contentJSON: JSONContent = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Hey,',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          // eslint-disable-next-line max-len
          text: "Welcome to Cycle. This is our atomic unit: a natively collaborative doc. You can work on it together with your team. And it's quite rich. Just play around below ",
        },
        {
          type: 'emoji',
          attrs: {
            name: 'point_down',
          },
        },
      ],
    },
  ],
};

export const StepCreateDocs: FC<Props> = ({ progress }) => {
  const {
    handleSubmit,
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      doc1: '',
      doc2: '',
      doc3: '',
    },
  });
  const { add: addErrorToaster } = useErrorToaster();
  const [formError, setFormError] = useState('');
  const me = useMaybeMe();
  const { product } = useProductFromInitial();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const boardSlug = useBoardConfig(ctx => ctx.boardSlug);
  const doctypesFromProduct = useProductDoctypes();
  const boardGroups = useBoardGroups();
  const getGroup = useGetGroup();
  const doctypesFromBoardConfig = useDoctypesFromBoardConfig();
  const [updateDocContent, { loading: isUpdateLoading }] = useSafeMutation(UpdateDocContentDocument);
  // Entries: [groupdId, BoardGroup][]
  const groups = boardGroups.groups && Object.entries(boardGroups.groups)
    .filter(([, group]) => (
      // If the board is grouped, do not show the no value.
      !groupByProperty || (groupByProperty && group.attributeValueId)));

  const groupId = groups?.[0][0];

  const {
    createDoc,
    loading: isCreatedLoading,
  } = useCreateDoc({
    groupId,
    from: 'bottom',
  });

  // Quite same as NewDoc.tsx
  const possibleDoctypes = useMemo(() => {
    if (!groupId || boardSlug === ALL_DOCS_BOARD_SLUG) {
      return doctypesFromProduct;
    }
    const group = getGroup(groupId);
    if (group?.node.propertyValue?.__typename === 'Doctype') {
      const groupDoctype = doctypesFromProduct.find(doctype => doctype.id === group?.node.propertyValue?.id);
      if (groupDoctype) {
        return [groupDoctype];
      }
    }
    return doctypesFromBoardConfig.length ? doctypesFromBoardConfig : doctypesFromProduct;
  }, [doctypesFromBoardConfig, doctypesFromProduct, getGroup, groupId, boardSlug]);

  const doctype = possibleDoctypes[0];
  const defaultDoctype = doctype
    ? {
      ...doctype,
      attributeDefinitions: { edges: [] },
    } : undefined;

  const formDocs = watch(['doc1', 'doc2', 'doc3']);
  const docs = formDocs.map((title, index) => createDummyDoc({
    _docKey: getDocKey(product?.key, `${index + 1}`),
    title,
    assignee: me,
    doctype: defaultDoctype,
    isDraft: false,
  }));

  const selectedTheme = getTheme(getOnboarding().theme, getOnboarding().userColor);
  const viewType = boardConfig?.viewType ?? ViewType.List;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OnboardingLayout
        title="Create your first initiatives"
        main={(
          <StyledFormContainer>
            <Input
              id="doc1"
              label="First initiative"
              {...register('doc1')}
            />
            <Input
              id="doc2"
              label="Second initiative"
              {...register('doc2')}
            />
            <Input
              id="doc3"
              label="Third initiative"
              {...register('doc3')}
            />
            {formError && <ErrorHelper>{formError}</ErrorHelper>}
          </StyledFormContainer>
      )}
        aside={(groups && boardId
          ? (
            <AppPreview
              initial="glimpse"
              variants={previewVariant}
              $full={false}
              $isLoading={false}
            >
              <ThemeProvider theme={selectedTheme}>
                <OnboardingPreviewBoardRoute
                  name="Recent initiatives"
                  emoji="seedling"
                  pageId={PageId.Board}
                  sectionType={SectionType.Default}
                >
                  <Main>
                    <StepCreateDocsBoard
                      boardId={boardId}
                      createdDocs={docs}
                      groups={groups}
                      isGrouped={!!groupByProperty}
                      viewType={viewType}
                    />
                  </Main>
                </OnboardingPreviewBoardRoute>
              </ThemeProvider>
            </AppPreview>
          )
          : <LoaderContainer><Spinner /></LoaderContainer>)}
        progress={progress}
        isSkippable
        skipButtonProps={{
          onClick: () => {
            setOnboarding({
              screen: OnboardingScreen.Welcome,
            });
          },
        }}
        nextButtonProps={{
          type: 'submit',
          isLoading: isCreatedLoading || isUpdateLoading,
        }}
      />
    </form>
  );

  async function onSubmit() {
    const docsToCreate = docs.filter(({ title }) => !!title.trim());
    if (!docsToCreate.length) {
      setFormError('You should create at least one initiative.');
      return;
    }
    // We can't bulk create inside a doc group.
    const result = await createDoc({
      title: docsToCreate[0].title,
      doctype,
    });
    if (result?.data?.addNewDoc?.id) {
      const { id } = result?.data?.addNewDoc;
      await updateDocContent({
        variables: {
          docId: id,
          contentJSON: JSON.stringify(contentJSON),
        },
      });
      setOnboarding({
        createdDocId: id,
        screen: OnboardingScreen.EditDocs,
      });
    } else {
      addErrorToaster({ message: '🧐 Oops, looks like something went wrong on our side, we’re on it!' });
    }
    if (docsToCreate[1]) {
      await createDoc({
        title: docsToCreate[1].title,
        doctype,
      });
    }
    if (docsToCreate[2]) {
      await createDoc({
        title: docsToCreate[2].title,
        doctype,
      });
    }
  }
};
