import { getDocFullUrl } from '@cycle-app/utilities';
import { FC } from 'react';

import { SubmitParams } from 'src/components/DocSearchDropdown/DocSearchDropdown';
import { PageId } from 'src/constants/routing.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { useAttributesFromDoc } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromDoc';
import { useDoc, useLazyDoc } from 'src/hooks/api/useDoc';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useUrl } from 'src/hooks/useUrl';
import { getDocSlug } from 'src/utils/slug.util';

import { DocSearchDropdownStyled, Trigger } from './LinkDropdown.styles';

const LinkDropdown: FC = ({ children }) => {
  const getUrl = useUrl();
  const { editor } = useEditorContext();
  const { doc: currentDoc } = useDoc();
  const getDoc = useLazyDoc();
  const inheritedAttributes = useAttributesFromDoc(currentDoc);

  const [visible, {
    toggleCallback: toggleDropdown,
    setFalseCallback: hideDropdown,
  }] = useOptimizedBooleanState(false);

  return (
    <DocSearchDropdownStyled
      hideSearchDropdown={hideDropdown}
      isSearchDropdownVisible={visible}
      onSubmit={onDocAdded}
      dropdownProps={{ offsetY: 16 }}
      placeholder="Paste link or search docs"
      targetContent="link"
      inheritedAttributes={inheritedAttributes}
    >
      <Trigger onClick={toggleDropdown}>
        {children}
      </Trigger>
    </DocSearchDropdownStyled>
  );

  async function onDocAdded({
    docId,
    searchText,
  }: SubmitParams) {
    const doc = docId ? await getDoc(docId) : null;
    const href = doc
      ? getDocFullUrl(
        getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) }),
      )
      : searchText;

    applyLink(href);
    hideDropdown();
  }

  function applyLink(href: string) {
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href })
      .run();
  }
};

export default LinkDropdown;
