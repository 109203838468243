import { CommentIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Placement } from 'tippy.js';

import DocAction from 'src/components/DocAction/DocAction';
import useDocComments from 'src/hooks/api/queries/useDocComments';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { preventClick } from 'src/utils/mouse.util';

import DocChat from './DocChat';
import { Container, StyledDropdownLayer } from './DocComments.styles';

interface Props {
  docId: string;
  dropdownPlacement?: Placement;
  tooltipPlacement?: Placement;
  nbComments: number;
  startOpenedOnComment?: string;
  maxHeight?: string;
  showLabel?: boolean;
  size?: 'S' | 'M' | 'L';
  buttonIcon?: ReactNode;
}

const DocComments: FC<Props> = ({
  docId,
  dropdownPlacement = 'bottom',
  tooltipPlacement,
  nbComments,
  startOpenedOnComment,
  maxHeight = '50vh',
  showLabel = false,
  size,
  buttonIcon = <CommentIcon />,
}) => {
  const [skip, setSkip] = useState(true);
  const [chatWasShown, { setTrueCallback: setChatWasShownTrue }] = useOptimizedBooleanState(false);
  const [isDropdownVisible, {
    toggleCallback: onToggleDropdown,
    setFalseCallback: onHideDropdown,
  }] = useOptimizedBooleanState(!!startOpenedOnComment);

  const {
    data, error,
  } = useDocComments({
    docId,
    skip,
  });

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItem({ hoverDocId: null });
    }
  }, [isDropdownVisible]);

  useEffect(() => {
    if (skip && isDropdownVisible) {
      setSkip(false);
    }
  }, [isDropdownVisible, skip]);

  return (
    <Container onClick={preventClick}>
      <StyledDropdownLayer
        visible={!!data && !error && isDropdownVisible}
        hide={onHideDropdown}
        placement={dropdownPlacement}
        content={(
          <DocChat
            docId={docId}
            comments={data?.node.comments}
            startOpenedOnComment={!chatWasShown ? startOpenedOnComment : undefined}
            onUnmount={setChatWasShownTrue}
            maxHeight={maxHeight}
          />
        )}
      >
        <DocAction
          onClick={onToggleDropdown}
          tooltipPlacement={tooltipPlacement}
          buttonIcon={buttonIcon}
          label={nbComments > 0 ? 'Comments' : 'Add comment'}
          forceFocus={isDropdownVisible}
          size={size}
          counter={showLabel ? undefined : nbComments}
        >
          {showLabel && (nbComments > 0 ? nbComments : 'Comment')}
        </DocAction>
      </StyledDropdownLayer>
    </Container>
  );
};

export default DocComments;
