export type FileType = 'image' | 'video' | 'file';

export enum FileSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export type FileUploadedData = {
  alt: string;
  size: number;
  src: string;
  title: string;
  type: FileType;
  mime: string;
};

export type FileUploadSource = 'paste' | 'slash-menu' | 'drop';
