import { useQuery } from '@apollo/client';
import { ProductsDocument, ProductsQueryVariables, ProductsFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { PRODUCTS_PAGINATION_SIZE } from 'src/constants/products.constants';
import { useGetAuth } from 'src/reactives/auth.reactive';

import { useMe } from './useMe';

type Props = {
  searchText?: string;
  skip?: boolean;
};

export const useProducts = ({
  searchText, skip,
}: Props = {}) => {
  const { userId } = useGetAuth();
  const { me } = useMe();

  const {
    data, previousData, loading, fetchMore,
  } = useQuery<{ node: ProductsFragment }, ProductsQueryVariables>(ProductsDocument, {
    fetchPolicy: 'cache-first',
    skip: skip || !userId,
    variables: {
      userId: userId as string,
      size: PRODUCTS_PAGINATION_SIZE,
      cursor: searchText ? '' : (me.products.pageInfo.endCursor ?? ''),
      searchText: searchText || undefined,
    },
  });

  const productsConnection = loading ? previousData?.node.products : data?.node.products;
  const endCursor = productsConnection?.pageInfo?.endCursor;
  const hasNextPage = productsConnection?.pageInfo?.hasNextPage ?? false;

  const products = useMemo(() => nodeToArray(productsConnection), [productsConnection]);

  const fetchNextPage = useCallback(async () => {
    if (!hasNextPage) return;
    await fetchMore({
      variables: {
        cursor: endCursor,
      },
    });
  }, [fetchMore, endCursor, hasNextPage]);

  return {
    products,
    loading,
    fetchNextPage,
    hasNextPage,
  };
};
