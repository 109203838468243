import { CompanyWithCountCustomersFragment } from '@cycle-app/graphql-codegen';
import {
  THead,
  TBody,
  Tr,
  RowsSkeleton,
  TextHighlighter,
  InfiniteScroll,
} from '@cycle-app/ui';
import { OpenIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { CompanyActionsMenu } from './CompanyActionsMenu';
import {
  StyledTable,
  TdActions,
  StyledTr,
  CellLink,
  StyledShyScrollbar,
  StyledTd,
  StyledTh,
  StyledThActions,
} from './Customers.styles';
import { CustomerTableCompanyCell } from './CustomerTableCompanyCell';

interface Props {
  companies: CompanyWithCountCustomersFragment[];
  className?: string;
  onCustomerClick?: (id: string) => void;
  onCompanyClick?: (id: string) => void;
  isLoading?: boolean;
  hasNextPage?: boolean;
  loadMore: VoidFunction;
  searchText: string;
  isFullPage?: boolean;
}

export const CustomerListCompanies: FC<Props> = ({
  className, companies, onCompanyClick, isLoading, hasNextPage, loadMore, searchText, isFullPage = true,
}) => (
  <StyledShyScrollbar>
    <InfiniteScroll
      id="companies"
      isLoading={!!isLoading}
      hasMoreData={!!hasNextPage}
      loadMore={loadMore}
    >
      <StyledTable className={className} $isPrimary={isFullPage}>
        <THead>
          <Tr>
            <StyledTh $size={400} $sizeTablet={250} $sizeMobile={250}>Name</StyledTh>
            <StyledTh $size={500} $sizeTablet={150} $sizeMobile={150}>People</StyledTh>
            <StyledThActions $sizeTablet={50} $sizeMobile={50} />
          </Tr>
        </THead>
        <TBody>
          {companies.map(company => (
            <StyledTr key={company.id}>
              <StyledTd $size={400} $sizeTablet={250} $sizeMobile={250}>
                <CustomerTableCompanyCell
                  name={<TextHighlighter className="highlight" searchWords={[searchText]} textToHighlight={company.name ?? ''} />}
                  company={company}
                  link={onCompanyClick ? (
                    <CellLink>
                      <OpenIcon />
                      Open
                    </CellLink>
                  ) : undefined}
                  onClick={() => onCompanyClick?.(company?.id ?? '')}
                />
              </StyledTd>
              <StyledTd $size={500} $sizeTablet={150} $sizeMobile={150}>{company.countCustomers ?? 0}</StyledTd>
              <TdActions>
                {!company.isDefault && <CompanyActionsMenu company={company} />}
              </TdActions>
            </StyledTr>
          ))}
          {isLoading && <RowsSkeleton rows={5} cols={3} />}
        </TBody>
      </StyledTable>
    </InfiniteScroll>
  </StyledShyScrollbar>
);
