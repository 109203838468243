import { Emoji } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';

import { PageId } from 'src/constants/routing.constant';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useNavigate } from 'src/hooks/useNavigate';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';

export const useBoardsResult = (): Array<CommandActionCategory> => {
  const { navigate } = useNavigate();
  const { sections } = useBoardSections();

  return [{
    id: 'boards',
    label: 'Views',
    actions: sections
      .flatMap(section => nodeToArray(section.boards))
      .map(board => ({
        id: board.id,
        label: board.name,
        icon: board.emoji ? <Emoji emoji={board.emoji} /> : null,
        onSelect: () => {
          navigate(PageId.Board, { boardSlug: board.id });
          setCommandbar({ visible: false });
        },
      })),
  }];
};
