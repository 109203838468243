import { Emoji, Tag, SelectOption } from '@cycle-app/ui';
import { FC, useCallback } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useProductBase } from 'src/hooks/api/useProduct';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { DocFromFilter } from 'src/utils/boardConfig/filtersData.util';
import { getDocKey } from 'src/utils/doc.util';

import { Placeholder } from '../../Filter.styles';
import { TagContainer, MoreValues } from '../FilterElements.styles';
import DropdownContent from './DropdownContent';

interface Props {
  className?: string;
  dropdownLayer?: Layer;
  filterPropertyRuleId?: string;
  selectedParents: DocFromFilter[];
  defaultDocParentOptions: DocFromFilter[];
  onOptionsAdded: (newOptions: SelectOption[]) => void;
  onOptionRemoved: (optionValue: string) => void;
  disabled?: boolean;
}
const DocParentFilterElements: FC<Props> = ({
  className = '',
  dropdownLayer = Layer.DropdownBoardConfig,
  filterPropertyRuleId,
  selectedParents,
  defaultDocParentOptions,
  onOptionsAdded,
  onOptionRemoved,
  disabled = false,
}) => {
  const product = useProductBase();

  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  const onUnselectOption = useCallback((o: SelectOption) => onOptionRemoved(o.value), [onOptionRemoved]);
  const onSelectOption = useCallback((o: SelectOption) => onOptionsAdded([{
    ...o,
    selected: true,
  }]),
  [onOptionsAdded]);

  return (
    <DropdownLayer
      layer={dropdownLayer}
      placement="bottom-start"
      visible={isDropdownVisible}
      hide={hideDropdown}
      disabled={disabled}
      content={(
        <DropdownContent
          filterPropertyRuleId={filterPropertyRuleId}
          selectedCount={selectedParents.length}
          defaultDocParentOptions={defaultDocParentOptions}
          onSelectOption={onSelectOption}
          onUnselectOption={onUnselectOption}
        />
      )}
    >
      <TagContainer
        className={`${className} ${isDropdownVisible ? 'force-focus' : ''}`}
        onClick={toggleDropdown}
        disabled={disabled}
      >
        {selectedParents.length
          ? (
            <Tag icon={<Emoji emoji={selectedParents[0].value.doctype.emoji} />}>
              {getDocKey(product?.key, selectedParents[0].value.publicId)}
              {selectedParents.length > 1 && (
                <MoreValues>
                  +
                  {selectedParents.length - 1}
                </MoreValues>
              )}
            </Tag>
          )
          : <Placeholder>Select parent</Placeholder>}
      </TagContainer>
    </DropdownLayer>
  );
};

export default DocParentFilterElements;
