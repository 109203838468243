import { ViewType } from '@cycle-app/graphql-codegen';
import { useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { SIZE_DOCS_PAGE } from 'src/utils/pagination.util';

const INITIAL_DOCS_IN_KANBAN_GROUP_VIEW = 8;

type IsDocInGroupViewHook = (options: {
  docIndex?: number;
  groupId: string;
  viewType: ViewType;
  skip?: boolean;
}) => [
  (node?: Element | null | undefined) => void,
  boolean,
  number | undefined,
];

export const useIsDocInGroupView: IsDocInGroupViewHook = ({
  docIndex, groupId, viewType, skip = false,
}) => {
  const docHeightRef = useRef<number>();
  const initialDocsInView = viewType === ViewType.Kanban ? INITIAL_DOCS_IN_KANBAN_GROUP_VIEW : SIZE_DOCS_PAGE;

  const [docRef, isDocInGroupView] = useInView({
    root: document.getElementById(groupId),
    rootMargin: '400px',
    initialInView: docIndex ? docIndex < initialDocsInView : true,
    skip,
    onChange: useCallback((inView, entry) => {
      if (inView || !entry || docHeightRef.current) return;
      docHeightRef.current = entry.boundingClientRect.height;
    }, []),
  });

  return [
    docRef,
    isDocInGroupView,
    docHeightRef.current,
  ];
};
