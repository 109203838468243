import { LoginEyes } from '../LoginEyes/LoginEyes';
import { LoginPlug, LoginPlugProps } from '../LoginPlug/LoginPlug';
import { LoginTextAnimation } from '../LoginTextAnimation/LoginTextAnimation';
import { Container, EyesContainer, PlugContainer } from './LoginIllustration.styles';

export type LoginIllustrationProps = LoginPlugProps;

export const LoginIllustration = ({ plugged }: LoginIllustrationProps) => (
  <Container>
    <EyesContainer>
      <LoginEyes />
    </EyesContainer>

    <PlugContainer>
      <LoginPlug plugged={plugged} scale={0.8} />
    </PlugContainer>

    <p>
      Turn product feedback into customer
      {' '}
      <LoginTextAnimation />
    </p>
  </Container>
);
