import { Spinner } from '@cycle-app/ui';
import { SearchIcon, CloseIcon } from '@cycle-app/ui/icons';
import { Setter } from '@cycle-app/utilities';
import { FC, FormEvent, useEffect, useRef } from 'react';

import { commandSections } from 'src/constants/commandSections.constants';
import { useEnterCallback } from 'src/hooks/commandPanel/useEnterCallback';
import { useCommandbar } from 'src/reactives/commandbar.reactive';

import {
  Container,
  LogoContainer,
  InputContainer,
  SearchInput,
  Section,
  Loading,
  TagStyled,
  ShortcutStyled,
} from './CommandInput.styles';

interface Props {
  search: string;
  onSearchUpdate: (e: FormEvent<HTMLInputElement>) => void;
  setSearch: Setter<string>;
  isLoading?: boolean;
}

const CommandInput: FC<Props> = ({
  search,
  onSearchUpdate,
  isLoading,
}) => {
  const [enterCallback, loadingEnterCallback] = useEnterCallback(search);
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ section }, setCommandbar] = useCommandbar();

  useEffect(() => {
    inputRef.current?.focus();
  }, [section]);

  const loadingInput = isLoading || loadingEnterCallback;

  return (
    <Container $withSection={!!section}>
      <InputContainer>
        <LogoContainer>
          <SearchIcon />
        </LogoContainer>
        {section && (
          <Section>
            <TagStyled
              size="L"
              onClick={backToSummary}
            >
              {commandSections[section].tagLabel ?? commandSections[section].label}
              <CloseIcon />
            </TagStyled>
          </Section>
        )}
        <SearchInput
          ref={inputRef}
          value={search}
          onChange={onSearchUpdate}
          placeholder={section ? commandSections[section].placeholder : 'Type a command or search a doc'}
          autoFocus
          spellCheck={false}
          onKeyDown={(e) => {
            if (e.code === 'Enter' && !isLoading) enterCallback?.();
            if (e.code === 'Backspace' && !search.length) backToSummary();
          }}
        />
      </InputContainer>
      {loadingInput && (
        <Loading>
          <Spinner />
        </Loading>
      )}
      {!loadingInput && enterCallback && (
        <ShortcutStyled keys={['enter']} />
      )}
    </Container>
  );

  function backToSummary() {
    setCommandbar({ section: null });
  }
};

export default CommandInput;
