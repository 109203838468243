import { FC } from 'react';

import EditBoardModal from 'src/app/Main/Board/EditBoardModal/EditBoardModal';
import { useBoard } from 'src/hooks/api/useBoard';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { TitleButton } from './BoardHeader.styles';

export const BoardTitle: FC = ({ children }) => {
  const board = useBoard();
  const [isEditModalVisible, {
    toggleCallback: toggleEditModal,
    setFalseCallback: hideEditModal,
  }] = useOptimizedBooleanState(false);

  return (
    <>
      <TitleButton
        tooltipPlacement="bottom"
        tooltipDisplayFullTitle
        tooltipTitle={board?.description}
        tooltip="Edit"
        onClick={toggleEditModal}
      >
        <h1>{children}</h1>
      </TitleButton>

      {board && isEditModalVisible && (
        <EditBoardModal
          boardId={board?.id}
          onHide={hideEditModal}
        />
      )}
    </>
  );
};
