import { Skeleton } from '@cycle-app/ui';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const SubtitleLoadingState = styled(Skeleton)`
  width: 180px;
  height: 14px;
  margin: 2px 0;
`;
