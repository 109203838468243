import { Tooltip, Emoji } from '@cycle-app/ui';
import { FC } from 'react';

import { useFeedbackDocType } from 'src/hooks/api/useProductDoctypes';

import { DocTypeLabel, TooltipContent } from './Inbox.styles';

export const TitleTooltip: FC = (props) => (
  <Tooltip
    title={(
      <>
        Get to
        <ViewLabel />
        inbox zero!
      </>
    )}
    content={(
      <TooltipContent>
        Use ctrl+shift+E shortcut to mark as processed
      </TooltipContent>
    )}
    placement="right"
    withPortal
    {...props}
  />
);

const ViewLabel = () => {
  const feedback = useFeedbackDocType();
  if (!feedback) return null;
  return (
    <DocTypeLabel>
      <Emoji emoji={feedback.emoji} size={12} />
      <span>{feedback.name}</span>
    </DocTypeLabel>
  );
};
