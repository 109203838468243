import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji, SelectPanel } from '@cycle-app/ui';
import { FC } from 'react';

import { DocMentionCreationModal } from 'src/components/DocMentionCreationModal/DocMentionCreationModal';
import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setLastDocMentionDocTypeIdUsed } from 'src/reactives/lastView.reactive';

type Props = Pick<ToggleDropdownProps, 'button'> & {
  isDisabled?: boolean;
};

export const DocMentionDropdown: FC<Props> = ({
  button, isDisabled,
}) => {
  const { doctypes } = useProductDoctypesFull();

  const [isModalOpen, {
    setFalseCallback: closeModal,
    setTrueCallback: openModal,
  }] = useOptimizedBooleanState(false);

  return (
    <>
      <ToggleDropdown
        placement="bottom"
        offsetY={16}
        button={button}
        content={props => (
          <SelectPanel
            title="Turn into"
            searchPlaceholder="Search"
            options={doctypes
              .filter(d => d.type !== DoctypeType.Feedback && d.type !== DoctypeType.Insight)
              .map(doctype => ({
                label: doctype.name,
                value: doctype.id,
                icon: <Emoji emoji={doctype.emoji} />,
                disabled: isDisabled,
              }))}
            onOptionChange={(doctypeOption) => {
              props.hide();
              openModal();
              setLastDocMentionDocTypeIdUsed(doctypeOption.value);
            }}
          />
        )}
      />

      <DocMentionCreationModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};
