import { Color as ColorApi, ThemeType } from '@cycle-app/graphql-codegen';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { FC } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import LayoutFooter from 'src/components/LayoutFooter/LayoutFooter';
import { OnboardingView } from 'src/components/OnboardingView/OnboardingView';
import ProtectedRoute from 'src/components/ProtectedRoute/ProtectedRoute';
// import { quotes } from 'src/constants/quotes.constant';
import { PageId, routing } from 'src/constants/routing.constant';
// import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { usePageId } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';
import { useGetAuth } from 'src/reactives/auth.reactive';

import { Container, Content } from './Auth.styles';
import Authorize from './Authorize/Authorize';
import Login from './Login/Login';
import ResetPwd from './ResetPwd/ResetPwd';

// const randomQuote = getRandomItem(quotes);

const Auth: FC = () => {
  const {
    token,
    onboarded,
  } = useGetAuth();
  const pageId = usePageId();
  const getUrl = useUrl();
  // const copyToClipboard = useCopyToClipboard({
  //   successNotification: 'Quote copied to clipboard!',
  // });

  // Right now we want to always use eclipse theme for the login/welcome pages
  // If someday we want a proper nightfall theme we'll just have to remove the theme provider
  const eclipseTheme = getTheme(
    ThemeType.Eclipse,
    ColorApi.A,
  );
  const queryParams = new URLSearchParams(useLocation().search);
  const from = queryParams.get('from');
  if (token && pageId !== PageId.Welcome && pageId !== PageId.OAuth) {
    const defaultRedirect = from || getUrl(PageId.Main);
    return <Redirect to={onboarded ? decodeURIComponent(defaultRedirect) : getUrl(PageId.Welcome)} />;
  }

  return (
    <ThemeProvider theme={eclipseTheme}>
      <Container role="main">
        <Content $hasResponsive={false}>
          <Switch>
            <Route path={routing[PageId.Login]}>
              <Login />
            </Route>
            <Route path={routing[PageId.ResetPwd]}>
              <ResetPwd />
            </Route>
            <ProtectedRoute path={routing[PageId.OAuth]}>
              <Authorize />
            </ProtectedRoute>
            <ProtectedRoute path={routing[PageId.Welcome]}>
              <OnboardingView />
            </ProtectedRoute>
            <Redirect to={routing[PageId.Login]} />
          </Switch>

          <LayoutFooter />
        </Content>
      </Container>
    </ThemeProvider>

  );
};

export default Auth;
