import { BillingPlan, Color } from '@cycle-app/graphql-codegen';
import { useState } from 'react';

import { useCurrentBilling } from 'src/hooks';
import { useFetchStripePortal } from 'src/hooks/api/queries/useFetchStripePortal';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { getExpectedStandardFee, getAmountFlat, getAmountPerUnit } from 'src/utils/billing.util';
import { currencyFormat } from 'src/utils/currency.utils';

import { BillingUpdateLayout } from '../../BillingUpdateLayout';
import {
  PricingBox,
  PriceTag,
  PriceSymbol,
  Pricing,
  PricePeriod,
  PricingHeader,
  UpgradeButton,
  PriceInfo,
  PriceTooltip,
  StyledInfoIconOutline,
  ProBadge,
  StyledFrequencyToggle,
  PricingFrequency,
  PricingFrequencyLabel,
  PricingDiscount,
  PricingFrequencyLabelContent,
} from './BillingUpgrade.styles';
import { BillingUpgradeUsers } from './BillingUpgradeUsers';

export const BillingUpgrade = () => {
  const currentBilling = useCurrentBilling();
  const isStandard = currentBilling?.plan === BillingPlan.Standard;
  const stripePortal = useFetchStripePortal();
  const { canReadSettings } = useGetPermission();
  const upgradeLabel = isStandard ? 'Current plan' : 'Upgrade';
  const [isAnnual, setIsAnnual] = useState(false);
  return (
    <BillingUpdateLayout
      badge={<ProBadge>Pro</ProBadge>}
      title="Enjoy the ride with Pro"
      main={(
        <>
          <PricingFrequency>
            <PricingFrequencyLabel $isActive={!isAnnual}>
              <PricingFrequencyLabelContent>Monthly Billing</PricingFrequencyLabelContent>
            </PricingFrequencyLabel>
            <StyledFrequencyToggle
              id="frequency"
              checked={isAnnual}
              onChange={() => setIsAnnual(currentIsAnnual => !currentIsAnnual)}
            />
            <PricingFrequencyLabel $isActive={isAnnual}>
              <PricingFrequencyLabelContent>
                Annual Billing
                <PricingDiscount color={Color.C}>Save 25%</PricingDiscount>
              </PricingFrequencyLabelContent>
            </PricingFrequencyLabel>
          </PricingFrequency>
          <PricingBox>
            <Pricing>
              <PricingHeader>
                {currentBilling && (
                  <PriceTag>
                    <PriceSymbol>$</PriceSymbol>
                    {currencyFormat(getExpectedStandardFee(currentBilling, isAnnual), { trimSymbol: true })}
                  </PriceTag>
                )}
                <PriceInfo>
                  <PriceTooltip
                    placement="top"
                    withPortal
                    content={(
                      <>
                        {`${currencyFormat(getAmountFlat(isAnnual))} / mo base fee, plus:`}
                        <br />
                        {`${currencyFormat(getAmountPerUnit(isAnnual))} / mo per maker`}
                      </>
                    )}
                  >
                    <StyledInfoIconOutline />
                  </PriceTooltip>
                </PriceInfo>
              </PricingHeader>
              <PricePeriod>
                per month
                {isAnnual && ', billed annually'}
              </PricePeriod>
              <UpgradeButton
                isLoading={stripePortal.loading}
                disabled={!canReadSettings || isStandard}
                variant={(!canReadSettings || isStandard) ? 'ternary' : 'primary'}
                size="L"
                onClick={async () => {
                  const url = await stripePortal.fetch(isAnnual);
                  if (url) {
                    window.open(url, '_blank');
                  }
                }}
              >
                {canReadSettings ? upgradeLabel : 'Contact your administrator'}
              </UpgradeButton>
            </Pricing>
            <BillingUpgradeUsers />
          </PricingBox>
        </>
      )}
    />
  );
};
