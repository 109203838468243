import { useLazyQuery, useQuery } from '@apollo/client';
import { SearchDocHierarchyDocument } from '@cycle-app/graphql-codegen';
import { SelectPanel, Emoji, SelectOption } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { VFC, useMemo, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useProductBase } from 'src/hooks/api/useProduct';

interface Props {
  className?: string;
  onSelect?: (docId: string) => void;
  commonDoctypeParents: Array<string>;
}

const SelectDoc: VFC<Props> = ({
  className,
  onSelect,
  commonDoctypeParents,
}) => {
  const [search, setSearch] = useState('');
  const product = useProductBase();

  const { data: defaultResultData } = useQuery(SearchDocHierarchyDocument, {
    variables: {
      text: product?.key as string,
      productId: product?.id as string,
      doctypeIds: commonDoctypeParents,
    },
    skip: !product,
  });

  const [searchDoc, { data: resultData }] = useLazyQuery(SearchDocHierarchyDocument);
  const updateSearchDebounced = useDebouncedCallback(searchDoc, INPUT_ONCHANGE_DEBOUNCE);

  const data = search ? resultData : defaultResultData;

  const options = useMemo(() => nodeToArray(data?.searchDoc).map(doc => ({
    value: doc.id,
    label: doc.title,
    icon: <Emoji emoji={doc.doctype.emoji} />,
  })), [data]);

  const onSearchChange = useCallback((text: string) => {
    if (!product) return;
    updateSearchDebounced({
      variables: {
        text,
        productId: product?.id,
        doctypeIds: commonDoctypeParents,
      },
    });
    setSearch(text);
  }, []);

  const onOptionChange = useCallback((selectedOption: SelectOption) => {
    onSelect?.(selectedOption.value);
  }, []);

  return (
    <SelectPanel
      className={className}
      options={options}
      onSearchChange={onSearchChange}
      onOptionChange={onOptionChange}
    />
  );
};

export default SelectDoc;
