import { Breakpoint } from '../types/breakpoints.types';

const TABLET_MIN_WIDTH = 768;
const LAPTOP_MIN_WIDTH = 1200;
const SMALL_SCREEN_MAX_HEIGHT = 800;

export const mobile = `@media only screen and (max-width : ${TABLET_MIN_WIDTH - 1}px)`;

export const tablet = `@media only screen and (max-width : ${LAPTOP_MIN_WIDTH - 1}px)`;

export const small = `@media only screen and (max-height : ${SMALL_SCREEN_MAX_HEIGHT}px)`;

export function getBreakpoint(): Breakpoint {
  const windowWidth = window.innerWidth;

  if (windowWidth < TABLET_MIN_WIDTH) {
    return 'mobile';
  }
  if (windowWidth < LAPTOP_MIN_WIDTH) {
    return 'tablet';
  }
  return 'desktop';
}
