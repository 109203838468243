import { ActionButton } from '@cycle-app/ui';
import { AddIcon, CaretIcon } from '@cycle-app/ui/icons';
import { FeatureFlag } from '@cycle-app/utilities';
import { FC, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import { PageId, routing } from 'src/constants/routing.constant';
import { useProduct, useIsStandardPlan } from 'src/hooks/api/useProduct';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { useGetInitial } from 'src/reactives/initial.reactive';
import { useGetLastView } from 'src/reactives/lastView.reactive';

import SettingsDocTypeMenu from './SettingsDocTypeMenu/SettingsDocTypeMenu';
import {
  Container,
  SidebarHeader,
  Title,
  SettingsNavigationSection,
  SettingsNavigationItem,
  Back,
  Logo,
} from './SettingsSidebar.styles';

const SettingsSidebar: FC = () => {
  const { loading } = useGetInitial();
  const pageId = usePageId();
  const { product } = useProduct();
  const { getUrl } = useNavigate();
  const { settingsFromUrl } = useGetLastView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const backToBoardUrl = settingsFromUrl ?? getUrl(PageId.Main);
  const { isEnabled: isBillingEnabled } = useFeatureFlag(FeatureFlag.Billing);
  const { isEnabled: isBillingPlanEnabled } = useFeatureFlag(FeatureFlag.BillingPlan);
  const { isEnabled: isStatusEnabled } = useFeatureFlag(FeatureFlag.Status);
  const isStandardPlan = useIsStandardPlan();
  return (
    <Switch>
      <Container>
        <SidebarHeader>
          <Back to={backToBoardUrl}>
            <CaretIcon direction="left" />
            <Logo $bgSrc={product?.logo?.url} />
            <Title>Settings</Title>
          </Back>
        </SidebarHeader>
        <SettingsNavigationSection>
          <SettingsNavigationItem
            label="General"
            linkTo={getUrl(PageId.Settings)}
            isActive={pageId === PageId.Settings}
          />
          <SettingsNavigationItem
            label="Members"
            linkTo={getUrl(PageId.SettingsUsers)}
            isActive={pageId === PageId.SettingsUsers}
          />
          <SettingsNavigationItem
            label="Customers"
            linkTo={getUrl(PageId.SettingsCustomers)}
            isActive={[
              PageId.SettingsCustomers,
              PageId.SettingsCustomer,
              PageId.SettingsCompanies,
              PageId.SettingsCompany].includes(pageId)}
          />
          {isBillingEnabled && isStandardPlan && (
            <SettingsNavigationItem
              label="Billing"
              linkTo={getUrl(PageId.SettingsBilling)}
              isActive={pageId === PageId.SettingsBilling}
            />
          )}
          {isBillingPlanEnabled && (
            <SettingsNavigationItem
              label="Plans"
              linkTo={getUrl(PageId.BillingPlans)}
              isActive={pageId === PageId.BillingPlans}
            />
          )}
          <SettingsNavigationItem
            label="Doc types"
            linkTo={getUrl(PageId.SettingsDocTypes)}
            isActive={pageId === PageId.SettingsDocTypes}
            contentPlus={(
              <ActionButton
                tooltipPlacement="top"
                tooltip="Add new doc type"
                onClick={handleAddDoctype()}
                onColoredBg={pageId === PageId.SettingsDocTypes}
              >
                <AddIcon />
              </ActionButton>
            )}
          />
          <Route path={routing[PageId.SettingsDocTypes]}>
            {loading ? <div>loading discovery types…</div> : <SettingsDocTypeMenu />}
          </Route>
          <SettingsNavigationItem
            label="Properties"
            linkTo={getUrl(PageId.SettingsAttributes)}
            isActive={pageId === PageId.SettingsAttributes}
          />
          <SettingsNavigationItem
            label="Hierarchy"
            linkTo={getUrl(PageId.SettingsHierarchy)}
            isActive={pageId === PageId.SettingsHierarchy}
          />
          <SettingsNavigationItem
            label="Integrations"
            linkTo={getUrl(PageId.SettingsIntegrations)}
            isActive={pageId === PageId.SettingsIntegrations}
          />
          {isStatusEnabled && (
            <SettingsNavigationItem
              label="Workflows"
              linkTo={getUrl(PageId.SettingsWorkflows)}
              isActive={pageId === PageId.SettingsWorkflows}
            />
          )}
        </SettingsNavigationSection>
        {isModalOpen && <DoctypesEditCommonModal onHide={() => setIsModalOpen(false)} />}
      </Container>
    </Switch>
  );

  function handleAddDoctype() {
    return (e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!product) return;

      setIsModalOpen(true);
    };
  }
};

export default SettingsSidebar;
