import { BillingFrequency } from '@cycle-app/graphql-codegen';
import { CurrentPlanSection, Info } from '@cycle-app/ui';
import { HelpIcon, OpenIcon } from '@cycle-app/ui/icons';
import { add, parseISO, format } from 'date-fns';
import { useState } from 'react';

import { useFetchExpectedAmountToPay } from 'src/hooks/api/queries/useFetchExpectedAmountToPay';
import { useFetchStripePortal } from 'src/hooks/api/queries/useFetchStripePortal';
import { useCurrentBilling, useProduct } from 'src/hooks/api/useProduct';
import { useToaster } from 'src/hooks/useToaster';
import { currencyFormat } from 'src/utils/currency.utils';

import { Header, Container, Bold, PriceToPaySkeleton } from './SettingsBilling.styles';

export const SettingsBilling = () => {
  const { product } = useProduct();
  const currentBilling = useCurrentBilling();
  const [action, setAction] = useState<'manage' | 'info' | 'history' | null>(null);
  const { add: addToaster } = useToaster();
  const stripePortal = useFetchStripePortal();
  const {
    expectedAmountToPay, loading: isExpectedAmountToPayLoading,
  } = useFetchExpectedAmountToPay();
  const isAnnual = currentBilling?.frequency === BillingFrequency.Yearly;

  if (!product) return null;

  // we should have Int (will be fixed later in the schema)
  const formattedExpectedAmountToPay = typeof expectedAmountToPay === 'string'
    ? parseFloat(expectedAmountToPay)
    : expectedAmountToPay;
  return (
    <div>
      <Header>
        <h1>Billing</h1>
      </Header>

      <Container>
        <CurrentPlanSection
          isStandard
          body={(
            <>
              This workspace&apos;s Pro Plan is set for
              {' '}
              <Bold>
                {`${currentBilling?.nbMakers} maker${((currentBilling?.nbMakers ?? 0) > 1 && 's') || ''}`}
              </Bold>
              {' '}
              and  will renew on
              {' '}
              <Bold>
                {getNextBillingDate()}
                .
              </Bold>
              <br />
              Adjusted for your account balance, you will be charged
              <Bold>
                {isExpectedAmountToPayLoading
                  ? <PriceToPaySkeleton /> : ` ${currencyFormat(formattedExpectedAmountToPay ?? 0)}`}
              </Bold>
            </>
          )}
          action={action}
          isLoadingStripePortal={stripePortal.loading}
          onViewBillingInformation={async (selectedAction) => {
            setAction(selectedAction);
            const url = await stripePortal.fetch();
            setAction(null);
            if (url) {
              window.open(url, '_blank');
            } else {
              addToaster({
                title: 'We could not redirect you',
                message: 'Please try again later',
              });
            }
          }}
        />

        <Info
          icon={<HelpIcon />}
          iconRight={<OpenIcon />}
          onclick={() => {
            window.Intercom?.('show');
          }}
        >
          Need help ? Contact Cycle support
        </Info>
      </Container>
    </div>
  );

  function getNextBillingDate() {
    if (currentBilling?.createdAt) {
      const createdAt = parseISO(currentBilling.createdAt);
      return format(add(createdAt, isAnnual ? { years: 1 } : { months: 1 }), 'PPP');
    }
    return '';
  }
};
