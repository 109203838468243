import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const CornerDownRightIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.11159 3.55556C4.11159 3.24873 3.86286 3 3.55604 3C3.24922 3 3.00049 3.24873 3.00049 3.55556V7.44444C3.00049 8.18116 3.29314 8.8877 3.81407 9.40863C4.335 9.92956 5.04154 10.2222 5.77824 10.2222H11.1037L9.27435 12.0516C9.05739 12.2686 9.05739 12.6203 9.27435 12.8373C9.4913 13.0542 9.84306 13.0542 10.06 12.8373L12.8378 10.0595C13.0547 9.84255 13.0547 9.49079 12.8378 9.27383L10.06 6.49605C9.84306 6.27909 9.4913 6.27909 9.27435 6.49605C9.05739 6.71301 9.05739 7.06477 9.27435 7.28173L11.1037 9.11111H5.77824C5.33622 9.11111 4.9123 8.93552 4.59974 8.62296C4.28718 8.3104 4.11159 7.88647 4.11159 7.44444V3.55556Z" />
  </Svg>
);
