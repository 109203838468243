export enum PortalId {
  Modal = 'modal',
  Commandbar = 'commandbar',
  Dropdown = 'dropdown',
  DropdownClosingArea = 'dropdown-closing-area',
  DocPanel = 'doc-panel',
  Selection = 'selection',
  ItemOverlay = 'item-overlay',
  NotificationCenter = 'notification-center',
  LayoutFooter = 'layout-footer',
}
