import { GithubIssueBaseFragment } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { FC, useState, useEffect, useCallback, useRef } from 'react';

import { GithubIssueCreationModal } from 'src/components/GithubIssueCreationModal/GithubIssueCreationModal';
import { GITHUB_EXTENSION_NAME, BULLET_LIST_TYPE, CHECK_LIST_TYPE, ORDERED_LIST_TYPE, PARAGRAPH_TYPE } from 'src/constants/editor.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useCreateGithubIssues } from 'src/hooks/api/mutations/integrations/useCreateGithubIssues';
import useEditorLogic, { SelectedTextData } from 'src/hooks/editor/useEditorLogic';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useToaster } from 'src/hooks/useToaster';
import { CreateGithubIssueParams } from 'src/types/integrations.types';
import { ShortcutEditor } from 'src/types/shortcuts.types';
import { insertIssues } from 'src/utils/editor/editor.utils';
import { logError } from 'src/utils/errors.utils';

import { Trigger, ProgressionLabelContainer, Text } from './GithubIssuesCreationModal.styles';

type Props = {
  children: JSX.Element;
  shortcut?: ShortcutEditor;
};

export const GithubIssuesCreationModal: FC<Props> = ({
  children, shortcut,
}) => {
  const {
    editor, doc,
  } = useEditorContext();
  const selection = useRef<SelectedTextData | null>(null);
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const [titles, setTitles] = useState<string[]>([]);
  const [isModalOpen, { toggleCallback }] = useOptimizedBooleanState(false);
  const {
    createIssues, progression,
  } = useCreateGithubIssues();
  const { add: addToaster } = useToaster();

  const handleTrigger = useCallback((e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onTrigger();
  }, []);

  useAppHotkeys('command+2', handleTrigger, {
    enabled: !!shortcut && shortcut === ShortcutEditor.TurnGithubIssue,
  });
  useAppHotkeys('command+é', handleTrigger, {
    enabled: !!shortcut && shortcut === ShortcutEditor.TurnGithubIssue,
  });

  useEffect(() => {
    const {
      status, issuesFailed, issuesSuccess, issuesTotal,
    } = progression;
    if (
      status === 'finished' ||
      (issuesFailed + issuesSuccess === issuesTotal && status !== 'not-started')
    ) {
      toggleCallback();
    }
  }, [progression]);

  const createNewIssues = useCallback(async ({
    newTitles, values,
  }: {
    newTitles: string[];
    values: CreateGithubIssueParams;
  }): Promise<GithubIssueBaseFragment[]> => {
    if (!doc?.id || !values.repoName) throw Error('incomplete-data');

    const newIssues = await createIssues({
      docId: doc.id,
      repoName: values.repoName,
      assignees: values.assignees?.map(a => a.login) || [],
      status: values.status,
      titles: newTitles,
    });

    return newIssues;
  }, [createIssues, doc]);

  const onCreateIssues = useCallback(async (values: CreateGithubIssueParams) => {
    if (!doc?.id || !values.repoName || !selection.current) return;

    const newIssues = await createNewIssues({
      newTitles: selection.current.selectionValues,
      values,
    });
    const selectionNodeName = editor.state.selection.content().content.firstChild?.type.name ?? '';

    if ([
      BULLET_LIST_TYPE,
      CHECK_LIST_TYPE,
      ORDERED_LIST_TYPE,
      PARAGRAPH_TYPE,
    ].includes(selectionNodeName)) {
      insertIssues({
        editor,
        newIssues,
        extensionName: GITHUB_EXTENSION_NAME,
      });
    } else {
      addToaster({
        title: 'An error occured',
        message: `Sorry, you can't turn ${selectionNodeName} into a GitHub Issue`,
      });
    }
  }, [doc]);

  const onTrigger = useCallback(() => {
    const resultSelectedText = getDocTitlesFromSelectedText(editor.state);

    if (resultSelectedText.error) {
      logError(new Error(resultSelectedText.error));
      addToaster({
        title: 'An error occured',
        message: '🧐 Oops, looks like we cannot turn this, try to change your selection',
      });
    }

    if (!resultSelectedText.selectionValues.length) return;

    selection.current = resultSelectedText;
    setTitles(resultSelectedText.selectionValues);
    toggleCallback();
  }, [addToaster, editor.state, getDocTitlesFromSelectedText, toggleCallback]);

  return (
    <>
      <GithubIssueCreationModal
        isOpen={isModalOpen}
        onClose={toggleCallback}
        titles={titles}
        createIssues={onCreateIssues}
        isLoading={progression.status === 'in-progress'}
        isMulti
        progression={progression.status === 'in-progress' && (
          <ProgressionLabelContainer>
            <Spinner />
            <Text>{`Issue created ${progression.issuesSuccess} / ${progression.issuesTotal}`}</Text>
          </ProgressionLabelContainer>
        )}
      />
      <Trigger onClick={onTrigger}>
        {children}
      </Trigger>
    </>
  );
};
