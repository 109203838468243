import { Input } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  input {
    width: 224px;
    padding: 2px 16px 2px 8px;
    background: ${p => p.theme.colors.background.primary} !important;
  }
  [role="alert"] {
    display: none;
  }
`;
