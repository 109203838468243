import { sidebarTheme } from '@cycle-app/ui/utils/theme.util';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import NotificationCenter from 'src/app/Main/Board/NotificationCenter/NotificationCenter';
import { SettingsUserAddUserModal } from 'src/app/Main/Settings/SettingsUsers/SettingsUserAddUserModal';
import CommandbarModal from 'src/app/Modals/CommandbarModal/CommandbarModal';
import { AppGlobalDialogs } from 'src/components/AppGlobalDialogs';
import LayoutFooter from 'src/components/LayoutFooter/LayoutFooter';
import TemplateModal from 'src/components/TemplateModal/TemplateModal';
import { useCreateProductChannel, useProductBase } from 'src/hooks';
import { useAllNotificationReadSubscription } from 'src/hooks/api/useAllNotificationReadSubscription';
import { useBillingUpdatedSubscription } from 'src/hooks/api/useBillingUpdatedSubscription';
import { useCustomersImportedSubscription } from 'src/hooks/api/useCustomersImportedSubscription';
import { useDeletedDocSubscription } from 'src/hooks/api/useDeletedDocSubscription';
import { useNewNotificationSubscription } from 'src/hooks/api/useNewNotificationSubscription';
import { useProductSubscription } from 'src/hooks/api/useProductSubscription';
import { useIntercom } from 'src/hooks/useIntercom';
import { useResponsive } from 'src/reactives/responsive.reactive';
import { useSidebar } from 'src/reactives/sidebar.reactive';

import Sidebar from '../Sidebar/Sidebar';
import { Container, Page } from './MainLayout.styles';

const MainLayout: FC = ({ children }) => {
  const product = useProductBase();
  const [{ breakpoint }] = useResponsive();

  // TODO: Check where the subscription should be
  useProductSubscription(product?.id);
  useBillingUpdatedSubscription(product?.id);
  useCustomersImportedSubscription(product?.id);
  useDeletedDocSubscription(product?.id);
  useNewNotificationSubscription();
  useAllNotificationReadSubscription(product?.id);

  const [sidebarState, setSidebarState] = useSidebar();

  // Setup intercom here to be sure that user data has been fetched
  useIntercom();

  useEffect(() => {
    setSidebarState({ displayed: breakpoint !== 'mobile' });
  }, [breakpoint]);

  useCreateProductChannel();

  const sidebar = !sidebarState.displayed ? null : <Sidebar />;

  return (
    <Container role="main" aria-busy="false">
      {breakpoint === 'mobile' && (
        <NotificationCenter offset={[5, 5]} />
      )}

      <ThemeProvider theme={sidebarTheme}>
        {breakpoint === 'mobile' ? (
          <AnimatePresence>
            {sidebar}
          </AnimatePresence>
        ) : sidebar}
      </ThemeProvider>

      <Page>
        {children}
      </Page>
      <CommandbarModal />
      <TemplateModal />
      <SettingsUserAddUserModal />
      <AppGlobalDialogs />
      <LayoutFooter />
    </Container>
  );
};

export default MainLayout;
