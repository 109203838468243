import { motion } from 'framer-motion';
import styled from 'styled-components';

import { boxShadowZ4 } from '../../utils/styles.util';
import { ActionButton } from '../Buttons/ActionButton/ActionButton';

export const Container = styled(motion.div)`
  border-radius: 12px;
  padding-bottom: 12px;
  ${boxShadowZ4}
`;

export const Header = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderLeftActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const HeaderRightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Separator = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  height: 16px;
  width: 1px;
  background-color: ${p => p.theme.colors.background.hover};
`;

export const StyledActionButton = styled(ActionButton)`
  padding: 5px;
`;

export const Content = styled.div`
  margin-top: 40px;
  padding-left: 60px;
  padding-right: 60px;
`;
