import styled, { css } from 'styled-components';

export const TabList = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
  top: 1px;
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  text-align: center;

  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  margin-left: 8px;

  background-color: ${p => p.theme.colors.customers.badge.bg};
  color: ${p => p.theme.colors.text.secondary};

  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;

export const Tab = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;
  position: relative;
  border-radius: 4px;
  outline: none;
  color: ${p => p.theme.colors.text.secondary};

  &:hover {
    background-color: ${p => p.theme.colors.customers.tab.bgHover};
    ${Badge} {
      background-color: ${p => p.theme.colors.customers.badge.bgHover};
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: transparent;
  }

  ${p => p.$active && css`
    font-weight: 500;
    color: ${p.theme.colors.text.primary};
    &::after {
      background-color: ${p.theme.userColors.main};
    }
  `}

  ${p => p.disabled && css`
    cursor: not-allowed;
  `}
`;
