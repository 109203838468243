import { IntegrationType } from '@cycle-app/graphql-codegen';
import { SelectLine } from '@cycle-app/ui';
import { FC } from 'react';

import { InstallIntegrationButton } from 'src/components/Integrations/InstallIntegrationButton/InstallIntegrationButton';
import { integrationNameTitles } from 'src/utils/integrations.utils';

type Props = {
  integrationType: IntegrationType;
};

export const IntegrationNotInstalledLine: FC<Props> = ({ integrationType }) => (
  <SelectLine
    label={`🙃 ${integrationNameTitles[integrationType]} integration is not installed`}
    endSlot={<InstallIntegrationButton />}
  />
);
