import { FC, memo } from 'react';

import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { useIsDocSelected } from 'src/reactives/selection.reactive';
import { ViewType } from 'src/types/viewType.types';

import { BulkCheckbox } from './DocItem.styles';

type Props = {
  docId: string;
  viewType: ViewType;
  isFocused: boolean;
};

export const DocItemCheckbox: FC<Props> = memo(({
  children, docId, viewType, isFocused,
}) => {
  const isSelected = useIsDocSelected(docId);
  const { toggleSelectDoc } = useDocsSelection();

  return (
    <BulkCheckbox
      role="checkbox"
      viewType={viewType}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        toggleSelectDoc(docId);
      }}
      isFocused={isFocused}
      isSelected={isSelected}
    >
      {children}
    </BulkCheckbox>
  );
});
