import { SelectOption, SelectPanel, OnSelectOptionChange, Spinner } from '@cycle-app/ui';
import { FC } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';

import { Container } from './FilterElements.styles';

interface Props {
  className?: string;
  dropdownLayer?: Layer;
  value?: string;
  options: SelectOption[];
  onChange?: OnSelectOptionChange;
  loading?: boolean;
  disabled?: boolean;
}
const SingleSelectFilterElement: FC<Props> = ({
  className,
  dropdownLayer = Layer.DropdownBoardConfig,
  children,
  value,
  options,
  onChange,
  loading,
  disabled = false,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  return (
    <DropdownLayer
      layer={dropdownLayer}
      placement="bottom-start"
      visible={isDropdownVisible}
      hide={hideDropdown}
      disabled={disabled}
      content={(
        <SelectPanel
          options={options}
          selectedValue={value}
          onOptionChange={(o) => {
            hideDropdown();
            onChange?.(o);
          }}
          hideSearch
        />
      )}
    >
      <Container
        className={`${className} ${isDropdownVisible ? 'force-focus' : ''}`}
        onClick={toggleDropdown}
        disabled={disabled}
      >
        {loading
          ? <Spinner />
          : children}
      </Container>
    </DropdownLayer>
  );
};

export default SingleSelectFilterElement;
