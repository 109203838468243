import { FC } from 'react';

import { useBoard } from 'src/hooks/api/useBoard';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { Container, Actions } from './BoardHeader.styles';
import { BoardHeaderEmoji } from './BoardHeaderEmoji';
import { BoardSettings } from './BoardSettings';
import { BoardStarButton } from './BoardStarButton';
import { BoardTitle } from './BoardTitle';

export const BoardHeader: FC = ({ children }) => {
  const board = useBoard();
  const { canUpdateBoard } = useGetPermission();
  return (
    <Container>
      {board && <BoardHeaderEmoji emoji={board.emoji ?? 'eye-glasses'} boardId={board.id} />}
      <BoardTitle>{children}</BoardTitle>

      <Actions>
        {board && <BoardStarButton boardId={board.id} />}
        {canUpdateBoard && <BoardSettings />}
      </Actions>
    </Container>
  );
};
