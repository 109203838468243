import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const IncognitoIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.756 7.74391C13.5475 7.74391 14.9998 9.19622 14.9998 10.9877C14.9998 12.7793 13.5475 14.2316 11.756 14.2316C10.3233 14.2316 9.10745 13.3027 8.67797 12.0143H7.32203C6.89255 13.3027 5.67674 14.2316 4.24399 14.2316C2.45247 14.2316 1.00015 12.7793 1.00015 10.9877C1.00015 9.19622 2.45247 7.74391 4.24399 7.74391C5.92025 7.74391 7.29954 9.01537 7.47008 10.6465H8.52992C8.70046 9.01537 10.0798 7.74391 11.756 7.74391ZM4.24399 9.10974C3.20679 9.10974 2.36598 9.95055 2.36598 10.9877C2.36598 12.0249 3.20679 12.8658 4.24399 12.8658C5.28118 12.8658 6.12199 12.0249 6.12199 10.9877C6.12199 9.95055 5.28118 9.10974 4.24399 9.10974ZM11.756 9.10974C10.7188 9.10974 9.87801 9.95055 9.87801 10.9877C9.87801 12.0249 10.7188 12.8658 11.756 12.8658C12.7932 12.8658 13.634 12.0249 13.634 10.9877C13.634 9.95055 12.7932 9.10974 11.756 9.10974ZM10.3983 1.76843C11.2277 1.76843 11.9588 2.31243 12.1971 3.1068L12.8842 5.39533C13.4255 5.48542 13.9552 5.59141 14.4733 5.71333C14.8405 5.79971 15.0681 6.16737 14.9817 6.5345C14.8953 6.90164 14.5277 7.12923 14.1605 7.04285C12.2787 6.60007 10.2254 6.37809 8 6.37809C5.77462 6.37809 3.72127 6.60007 1.83948 7.04285C1.47234 7.12923 1.10469 6.90164 1.01831 6.5345C0.931923 6.16737 1.15952 5.79971 1.52665 5.71333C2.0446 5.59146 2.57408 5.48551 3.11509 5.39545L3.80285 3.1068C4.04116 2.31243 4.77231 1.76843 5.60166 1.76843H10.3983Z" fill="#171618" />
  </Svg>
);
