import { NotionIcon, SlackIcon, IntercomIcon, FigmaIcon } from '@cycle-app/ui/icons';
import { AnimationControls } from 'framer-motion';
import { useMemo, FC } from 'react';

import { bottomLeftSequence, bottomRightSequence, topLeftSequence, topRightSequence } from '../../hooks/useAnimateIntegrationsIllustration';
import { CardIconsGrid, CardIconsGridItem } from './IllustationCards.styles';

interface Props {
  className?: string;
  iconSwap: number;
  bottomLeftControls: AnimationControls;
  bottomRightControls: AnimationControls;
  onAnimationComplete: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  topLeftControls: AnimationControls;
  topRightControls: AnimationControls;
}

export const IntegrationIllustrationCard: FC<Props> = ({
  className,
  iconSwap,
  bottomLeftControls,
  bottomRightControls,
  onAnimationComplete,
  onMouseEnter,
  onMouseLeave,
  topLeftControls,
  topRightControls,
}) => {
  const BottomLeftIcons = useMemo(() => ([
    <IntercomIcon key="bl-intercom" />,
    <SlackIcon key="bl-slack" />,
  ][iconSwap]), [iconSwap]);

  const BottomRightIcons = useMemo(() => ([
    <FigmaIcon key="br-figma" />,
    <NotionIcon key="br-notion" />,
  ][iconSwap]), [iconSwap]);

  const TopLeftIcons = useMemo(() => ([
    <NotionIcon key="tl-notion" />,
    <FigmaIcon key="tl-figma" />,
  ][iconSwap]), [iconSwap]);

  const TopRightIcons = useMemo(() => ([
    <SlackIcon key="tr-slack" />,
    <IntercomIcon key="tr-intercom" />,
  ][iconSwap]), [iconSwap]);

  return (
    <CardIconsGrid
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <CardIconsGridItem
        initial={topLeftSequence[0]}
        animate={topLeftControls}
      >
        {TopLeftIcons}
      </CardIconsGridItem>
      <CardIconsGridItem
        initial={topRightSequence[0]}
        animate={topRightControls}
      >
        {TopRightIcons}
      </CardIconsGridItem>
      <CardIconsGridItem
        initial={bottomLeftSequence[0]}
        animate={bottomLeftControls}
      >
        {BottomLeftIcons}
      </CardIconsGridItem>
      <CardIconsGridItem
        initial={bottomRightSequence[0]}
        animate={bottomRightControls}
        onAnimationComplete={onAnimationComplete}
      >
        {BottomRightIcons}
      </CardIconsGridItem>
    </CardIconsGrid>
  );
};
