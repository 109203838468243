import {
  CSSProperties,
  forwardRef,
  MouseEvent,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import { ParentIcon } from '../../icons';
import { Emoji } from '../Emoji';
import {
  Container,
  Context,
  OptionContainer,
  ParentButton,
  ParentTitle,
  Title,
  ContextButtons,
  ContextContent,
  ContextButton,
  Footer,
} from './InsightCard.styles';

const contextStyles: CSSProperties = {
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
};

type Parent = {
  emoji: string;
  title: string;
};

export type InsightCardProps = {
  blockId?: string | null;
  className?: string;
  context?: string | null;
  footerRight?: ReactNode;
  hasBorder?: boolean;
  isContextReadOnly?: boolean;
  isHighlighted?: boolean;
  isOptionFocus?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onContextCopied?: (data: string) => void;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  onParentClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  options?: ReactNode;
  parent?: Parent;
  properties?: ReactNode;
  title: string;
};

export const InsightCard = forwardRef<HTMLDivElement, InsightCardProps>(({
  blockId,
  className,
  context,
  footerRight,
  hasBorder = false,
  isContextReadOnly = false,
  isHighlighted,
  isOptionFocus = false,
  onClick,
  onContextCopied,
  onMouseEnter,
  onMouseLeave,
  onParentClick,
  options,
  parent,
  properties,
  title,
}, ref) => (
  <Container
    ref={ref}
    $isHighlighted={isHighlighted}
    $withBorder={hasBorder}
    className={className}
    data-block-id={blockId}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {!!parent?.title && (
      <ParentButton
        onClick={onParentClick}
        tooltip={parent.title}
        tooltipDisplayFullTitle
        tooltipPlacement="top-start"
        tooltipWithPortal
        tooltipWithWrapper={false}
      >
        <ParentIcon />
        <Emoji emoji={parent.emoji} size={14} />
        <ParentTitle>{parent.title}</ParentTitle>
      </ParentButton>
    )}
    {!!options && (
      <OptionContainer $isFocus={isOptionFocus}>
        {options}
      </OptionContainer>
    )}
    <Title>{title}</Title>
    {properties}
    {!!context && !isContextReadOnly && <InsightContext blockId={blockId} context={context} onCopied={onContextCopied} />}
    {!!context && isContextReadOnly && <InsightContextReadOnly context={context} />}
    {/* We show the footer with at least the context since we need the extra space */}
    {(!!footerRight || !!context) && <Footer>{footerRight}</Footer>}
    {/* <Footer>okokok</Footer> */}
  </Container>
));

type InsightContextProps = {
  context: string;
  onCopied?: InsightCardProps['onContextCopied'];
  blockId?: string | null;
};

const InsightContext = ({
  context,
  onCopied,
  blockId,
}: InsightContextProps) => {
  const [div, setDiv] = useState<HTMLElement | null>(null);
  const [isExpand, setIsExpand] = useState(false);

  const canExpand = useMemo(() => (div ? div.scrollHeight > div.clientHeight : false), [div]);

  return (
    <Context>
      <ContextContent ref={r => setDiv(r)} style={!isExpand ? contextStyles : undefined}>
        {context}
      </ContextContent>
      <ContextButtons>
        {canExpand && (
          <ContextButton
            onClickCapture={(e) => {
              e.stopPropagation();
              setIsExpand(!isExpand);
            }}
          >
            {isExpand ? 'Show less' : 'Show more'}
          </ContextButton>
        )}
        {onCopied && (
          <ContextButton
            onClickCapture={(e) => {
              e.stopPropagation();
              onCopied(context);
            }}
          >
            {blockId ? 'Copy content' : 'Copy title'}
          </ContextButton>
        )}
      </ContextButtons>
    </Context>
  );
};

type InsightContextReadOnlyProps = {
  context: string;
};

const InsightContextReadOnly = ({ context }: InsightContextReadOnlyProps) => (
  <Context>
    <ContextContent style={contextStyles}>
      {context}
    </ContextContent>
  </Context>
);
