import { Color } from '@cycle-app/graphql-codegen';
import { NuancePicker } from '@cycle-app/ui';
import { FC, useState, useRef, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { useToaster } from 'src/hooks/useToaster';
import { CustomAttributeType } from 'src/types/attribute.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import SelectAttributeType from './SelectAttributeType';
import {
  ReorderCell,
  NameInput,
  Cell,
  RowContainer,
} from './SettingsAttributes.styles';

interface SettingsAttributesLineDraftProps {
  onAttributeAdded: (newAttributeId: string) => void;
}

const DEFAULT_COLOR = Color.A;

const SettingsAttributesLineDraft: FC<SettingsAttributesLineDraftProps> = ({ onAttributeAdded }) => {
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [name, setName] = useState('');
  const [type, setType] = useState<CustomAttributeType>();
  const { add: addToaster } = useToaster();

  const {
    addNewAttribute, loading,
  } = useAttributesMutations();

  const debouncedAddNewAttribute = useDebouncedCallback(async (data: Parameters<typeof addNewAttribute>[0]) => {
    const result = await addNewAttribute(data);
    if (result?.data?.addNewAttribute?.id) {
      onAttributeAdded(result.data.addNewAttribute.id);
      return;
    }
    addToaster({
      title: 'An error occured',
      message: 'Please try again',
    });
  }, 600);

  useEffect(() => {
    nameInputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (name && type) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      debouncedAddNewAttribute({
        name,
        type: getCustomAttributeTypeData(type).typeInput,
        color,
      });
    }
  }, [color, debouncedAddNewAttribute, name, type]);

  return (
    <RowContainer>
      <ReorderCell />
      <Cell>
        <NuancePicker
          color={color}
          onClick={setColor}
          placement="bottom-start"
        />
      </Cell>
      <Cell>
        <NameInput
          ref={nameInputRef}
          value={name}
          placeholder="Name"
          disabled={loading}
          onChange={(value) => setName(value)}
        />
      </Cell>
      <Cell>
        <SelectAttributeType
          attributeType={type}
          onChange={setType}
        />
      </Cell>
      <Cell />
      <Cell />
      <Cell />
    </RowContainer>
  );
};

export default SettingsAttributesLineDraft;
