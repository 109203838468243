import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, FC } from 'react';

import { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { InsightCreateForm } from 'src/components/InsightCreateForm';
import { useDocTypeInsight } from 'src/hooks';
import { useDocSearchProductTour } from 'src/hooks/productTour/useDocSearchProductTour';
import { Layer } from 'src/types/layers.types';
import { CustomPropertyFormData } from 'src/types/property.types';
import { getPropertiesFromDocType, getCustomPropertyValue } from 'src/utils/properties.util';

import { StyledDropdownLayer } from './InsightCreateModal.styles';

type InsightCreateModalProps = {
  hide: VoidFunction;
  onBack?: VoidFunction;
  onInsightCreating?: VoidFunction;
  onInsightCreated?: VoidFunction;
  isOpen: boolean;
  parentDoc?: DocBaseFragment | null;
  feedbackDoc?: DocBaseFragment;
  dropdownProps?: Partial<DropdownLayerProps>;
  defaultContent?: string;
  defaultCustomerId?: string;
  defaultAssignee?: string;
  blockId?: string;
  hideParentDoc?: boolean;
  isCustomerReadonly?: boolean;
  isAssigneeReadonly?: boolean;
};

export const InsightCreateModal: FC<InsightCreateModalProps> = ({
  children,
  hide,
  isOpen,
  onBack,
  parentDoc,
  feedbackDoc,
  dropdownProps,
  defaultContent,
  defaultAssignee,
  defaultCustomerId,
  blockId,
  onInsightCreating,
  onInsightCreated,
  hideParentDoc,
  isCustomerReadonly,
  isAssigneeReadonly,
}) => {
  const { insight: insightDocType } = useDocTypeInsight();
  const { isActive: isProductTourActive } = useDocSearchProductTour();
  const customPropertiesData = useMemo(() => {
    const insightDoctypeProperties = getPropertiesFromDocType(insightDocType);
    const specificProperties: CustomPropertyFormData[] = [];
    const commonProperties: CustomPropertyFormData[] = [];
    const feedbackDocAttributes = nodeToArray(feedbackDoc?.attributes);

    insightDoctypeProperties.forEach(insightProperty => {
      const correspondingFeedbackProperty = feedbackDocAttributes.find(e => e.definition.id === insightProperty.id);

      if (correspondingFeedbackProperty) {
        commonProperties.push({
          id: insightProperty.id,
          definition: insightProperty,
          inheritValue: getCustomPropertyValue(correspondingFeedbackProperty),
        });
      } else {
        specificProperties.push({
          id: insightProperty.id,
          definition: insightProperty,
          inheritValue: null,
        });
      }
    });

    return {
      // common properties that were set in the feedback.
      common: commonProperties,
      specific: specificProperties,
    };
  }, [feedbackDoc, insightDocType]);

  return (
    <StyledDropdownLayer
      visible={isOpen}
      layer={Layer.DropdownModalZ2}
      hide={isProductTourActive ? undefined : hide}
      placement="bottom-end"
      content={isOpen && (
        <InsightCreateForm
          onBack={isProductTourActive ? undefined : onBack}
          onInsightCreated={() => {
            onInsightCreated?.();
            hide();
          }}
          onInsightCreating={onInsightCreating}
          parentDoc={parentDoc}
          feedbackDoc={feedbackDoc}
          customCommonPropertiesData={customPropertiesData.common}
          customSpecificPropertiesData={customPropertiesData.specific}
          defaultAssignee={feedbackDoc?.assignee?.id || defaultAssignee}
          defaultContent={defaultContent}
          defaultCustomerId={feedbackDoc?.customer?.id || defaultCustomerId}
          blockId={blockId}
          hideParentDoc={hideParentDoc}
          isAssigneeReadonly={isAssigneeReadonly}
          isCustomerReadonly={isCustomerReadonly}
        />
      )}
      {...dropdownProps}
      popperOptions={{
        ...(dropdownProps?.popperOptions || {}),
        modifiers: [{
          name: 'flip',
          enabled: false,
        }, {
          name: 'offsetAdapter',
          enabled: true,
          phase: 'read',
          requires: ['popperOffsets'],
          options: {
            offset: 24,
          },
          fn: ({
            state, options,
          }) => {
            const { rects } = state;
            const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
            const overflowY = options.offset + rects.reference.y + rects.reference.height + rects.popper.height - viewportHeight;
            if (overflowY > 0 && state.modifiersData?.popperOffsets?.y) {
              // eslint-disable-next-line operator-assignment, no-param-reassign
              state.modifiersData.popperOffsets.y = Math.max(options.offset, state.modifiersData.popperOffsets.y - overflowY);
            }
            if (viewportHeight <= rects.popper.height) {
              state.elements.popper.setAttribute('data-scroll', 'true');
            }
          },
        }],
      }}
    >
      {children}
    </StyledDropdownLayer>
  );
};
