import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const BookOpenIcon: FC<Props> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 2.75063C1 2.5766 1.06914 2.40969 1.1922 2.28662C1.31526 2.16356 1.48217 2.09442 1.65621 2.09442H5.37735C6.45091 2.09442 7.4046 2.61064 8.00219 3.40772C8.3077 2.99941 8.70432 2.66806 9.16046 2.44006C9.61661 2.21206 10.1197 2.09371 10.6296 2.09442H14.3438C14.5178 2.09442 14.6847 2.16356 14.8078 2.28662C14.9309 2.40969 15 2.5766 15 2.75063V11.9376C15 12.1116 14.9309 12.2785 14.8078 12.4016C14.6847 12.5246 14.5178 12.5938 14.3438 12.5938H10.4004C10.1419 12.5938 9.88589 12.6447 9.64705 12.7436C9.4082 12.8425 9.19118 12.9876 9.00837 13.1704L8.46416 13.7137C8.34112 13.8366 8.17433 13.9056 8.00044 13.9056C7.82654 13.9056 7.65976 13.8366 7.53672 13.7137L6.9925 13.1704C6.80969 12.9876 6.59267 12.8425 6.35383 12.7436C6.11498 12.6447 5.85899 12.5938 5.60046 12.5938H1.65621C1.48217 12.5938 1.31526 12.5246 1.1922 12.4016C1.06914 12.2785 1 12.1116 1 11.9376V2.75063ZM8.66015 5.37547C8.66015 4.85336 8.86756 4.35263 9.23674 3.98344C9.60593 3.61425 10.1067 3.40684 10.6288 3.40684H13.6867V11.2814H10.3995C9.77833 11.2814 9.17461 11.4572 8.65665 11.7818L8.66015 5.37547ZM7.34423 11.7836L7.34773 7.34497L7.34598 5.37372C7.34551 4.85191 7.1379 4.35163 6.76876 3.98282C6.39962 3.61401 5.89916 3.40684 5.37735 3.40684H2.31242V11.2814H5.59959C6.2168 11.2813 6.82149 11.4554 7.34423 11.7836Z" fill="currentcolor" />
  </Svg>
);
