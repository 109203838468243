import getFileExtension from './extensions/FileExtension';
import formattingExtensions from './extensions/formatting';
import { getHighlightMarkExtension } from './extensions/HighlightMarkExtension';
import iframelyExtension from './extensions/IframelyExtension';
import mentionExtension from './extensions/mention';
import mentionDocsExtension from './extensions/mentionDocs';

const backBundle = [
  ...formattingExtensions,
  mentionExtension,
  getFileExtension,
  iframelyExtension,
  mentionDocsExtension,
  getHighlightMarkExtension(),
];

export default backBundle;

export * from './extensions/dropCursor';
export * from './extensions/HighlightMarkExtension';
export * from './constants/editor.constants';
