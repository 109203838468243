import { FC, forwardRef, InputHTMLAttributes } from 'react';

import { Label, Text } from '../CheckboxInput/CheckboxInput.styles';
import { Container, StyledInput, Dot } from './RadioInput.styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value: string | number;
  label?: string;
}
export const RadioInput: FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    ...inputProps
  } = props;
  return (
    <Container
      className={className}
      $isDisabled={!!inputProps.disabled}
    >
      <StyledInput
        ref={ref}
        type="radio"
        {...inputProps}
      />
      <Label htmlFor={inputProps.id}>
        <Dot />
        <Text>{label ?? inputProps.value}</Text>
      </Label>
    </Container>
  );
});
