import { CustomerFragment, ThemeType } from '@cycle-app/graphql-codegen';
import { OnboardingLayout, Avatar, Button } from '@cycle-app/ui';
import { FC } from 'react';

import { CHROME_STORE_CYCLE_APP_URL } from 'src/constants/integrations.constants';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { setOnboarding } from 'src/reactives/onboarding.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetThemeConfig } from 'src/reactives/theme.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';

import mateSrc from '../../OnboardingCollabMates/mate-1.jpg';
import chromeDark from './chrome-dark.png';
import chromeLight from './chrome-light.png';
import companySrc from './company.png';
import {
  Main, MainExtension, StyledChromeIcon, MainExtensionName,
  StyledCustomerAvatar, StyledMockChrome, Cover, CoverImgContainer, StyledChromeTabLogo,
  ChromeExtensionLogo,
} from './StepChrome.styles';

type Props = {
  progress: number;
};

const customer: CustomerFragment = {
  id: '',
  avatar: mateSrc,
  email: '',
  displayName: '',
  company: {
    id: '',
    __typename: 'Company',
    logo: companySrc,
    isDefault: false,
  },
};

export const StepChrome: FC<Props> = ({ progress }) => {
  const me = useMaybeMe();
  const { colorTheme } = useGetThemeConfig();
  const isMobile = useIsMobile();
  return (
    <OnboardingLayout
      title="Cycle Chrome extension"
      description="You can create feedback from anywhere on the web. Install the Chrome extension and give it a try!"
      cover={!isMobile && (
        <Cover>
          <CoverImgContainer>
            <img alt="chrome" src={colorTheme === ThemeType.Nightfall ? chromeDark : chromeLight} />
            <ChromeExtensionLogo>
              <StyledChromeTabLogo size={10} />
            </ChromeExtensionLogo>
          </CoverImgContainer>
          <StyledMockChrome
            initial={{
              opacity: 0,
              translateY: 20,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
            }}
            transition={{ bounce: 0 }}
            customer={<StyledCustomerAvatar size="S" customer={customer} />}
            assignee={<Avatar user={me} />}
          />
        </Cover>
      )}
      main={(
        <Main>
          <MainExtension>
            <StyledChromeIcon />
            <MainExtensionName>
              Cycle - Chrome extension
            </MainExtensionName>
          </MainExtension>
          <Button onClick={() => window.open(CHROME_STORE_CYCLE_APP_URL, '_blank')} size="M" variant="secondary">Install</Button>
        </Main>
      )}
      progress={progress}
      nextButtonProps={{
        onClick: () => {
          setOnboarding({ screen: OnboardingScreen.Welcome });
        },
      }}
      isModal
      prevButtonProps={{
        onClick: () => setOnboarding({ screen: OnboardingScreen.Collaboration }),
      }}
      isFixedFooter
    />
  );
};
