import { OnboardingLayout, Avatar, Tooltip } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, FC } from 'react';

import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { useProductFromInitial } from 'src/hooks/api/useProductFromInitial';
import { useFakeCursors } from 'src/hooks/useFakeCursors';
import { useInterval } from 'src/hooks/useInterval';
import { setOnboarding } from 'src/reactives/onboarding.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';
import { createDummyDoc } from 'src/utils/doc.util';

import { OnboardingCollabMates } from '../../OnboardingCollabMates/OnboardingCollabMates';
import { OnboardingFloatingMates } from '../../OnboardingFloatingMates/OnboardingFloatingMates';
import { OnboardingMatesCursors } from '../../OnboardingMatesCursors/OnboardingMatesCursors';
import { OnboardingPreviewBoardRoute } from '../../OnboardingPreviewBoardRoute/OnboardingPreviewBoardRoute';
import { Container, Content, EditorContainer } from '../StepEditDocs/StepEditDocs.styles';
import contentJSON from './doc.json';
import {
  DocTitle, MorePeople, StyledDocAttributes, StyledReadOnlyEditor, Team, TeamMember,
} from './StepCollaborate.styles';

type Props = {
  progress: number;
};

export const StepCollaborate: FC<Props> = ({ progress }) => {
  const { product } = useProductFromInitial();
  const me = useMaybeMe();
  const members = useMemo(() => nodeToArray(product?.users), [product]);
  const membersLive = useMemo(() => members.slice(0, 5), [members]);
  const doctypesFromProduct = useProductDoctypes();
  const doc = useMemo(() => createDummyDoc({
    title: 'Welcome to Cycle',
    doctype: {
      ...doctypesFromProduct[0],
      attributeDefinitions: { edges: [] },
    },
    creator: me,
  }), [doctypesFromProduct, me]);

  const {
    editorContainerRef, cursorsContainerRef, placeCursors,
  } = useFakeCursors();

  useInterval(placeCursors, 2000);

  return (
    <OnboardingLayout
      title="Cycle is collaborative"
      description={(
        <>
          Cycle&apos;s atomic unit is a natively collaborative doc.
          <br />
          You can work together with your team in real-time.
        </>
      )}
      main={(
        <Team>
          {membersLive.map((member) => (
            <TeamMember key={member.id}>
              <Avatar user={member} />
              {member.firstName}
            </TeamMember>
          ))}
          {members.length > 5 && (
            <MorePeople>
              {`+ ${members.length - 5} other people`}
            </MorePeople>
          )}
        </Team>
      )}
      aside={(
        <OnboardingPreviewBoardRoute>
          <BoardConfigContextProvider>
            <Container>
              <OnboardingFloatingMates />
              <OnboardingCollabMates>
                <Tooltip placement="bottom" content={`${me?.firstName} (me)`}>
                  <Avatar user={me} />
                </Tooltip>
              </OnboardingCollabMates>
              <Content>
                <DocTitle>{doc.title}</DocTitle>
                <StyledDocAttributes doc={doc} readOnly />
                <EditorContainer ref={editorContainerRef}>
                  <OnboardingMatesCursors ref={cursorsContainerRef} />
                  <StyledReadOnlyEditor content={contentJSON} />
                </EditorContainer>
              </Content>
            </Container>
          </BoardConfigContextProvider>
        </OnboardingPreviewBoardRoute>
      )}
      progress={progress}
      nextButtonProps={{
        onClick: () => {
          setOnboarding({ screen: OnboardingScreen.Welcome });
        },
      }}
    />
  );
};
