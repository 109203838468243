import { Button } from '@cycle-app/ui';
import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';

export const InstallIntegrationButton: FC = () => {
  const { navigate } = useNavigate();

  return (
    <Button onClick={(e) => {
      e.stopPropagation();
      navigate(PageId.SettingsIntegrations);
    }}
    >
      Install
    </Button>
  );
};
