import { typo, Input, Button, TextArea, Avatar } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const Trigger = styled.div`
  cursor: pointer;
`;

export const StyledPortalModal = styled(PortalModal)`
  width: 450px;
`;

export const FormRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  label {
    ${typo.body500}
  }
`;

export const CustomInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CustomLabel = styled.div`
${typo.body500}
`;

export const StyledSelectButton = styled(Button)`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: transparent;
  gap: 4px;
  justify-content: space-between;
  padding: 7px 8px;
  width: 100%;
  ${typo.body400};

  &[disabled] {
    cursor: not-allowed;
    pointer-events: unset;
  }
`;

export const SelectButtonTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledTextArea = styled(TextArea)`
  border-color: transparent;
  border-width: 2px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const DualRowBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const StyledAvatar = styled(Avatar)`
  --border-size: 0px;
  --shadow-size: 0px;
  --padding: 0px;
  --shadow-size-hover: 0px;
`;

export const ProgressionLabelContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 6px;
`;

export const ProgressionText = styled.p`
  ${typo.tiny400}
`;

export const InfoContainer = styled.div`
  margin-top: 16px;
`;
