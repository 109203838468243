import { typo } from '@cycle-app/ui';
import { tablet, mobile } from '@cycle-app/utilities';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  padding: 16px 32px;
`;

export const Title = styled.h2`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.text.primary};
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${p => p.theme.colors.home.card.bg};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 16px;
  margin: 0 32px;
  gap: 12px;

  ${tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } 

  ${mobile} {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const QuickActionButton = styled.button`
  ${typo.body500}
  color: ${p => p.theme.userColors.main};
  background-color: ${p => transparentize(0.9, p.theme.userColors.main)};
  padding: 3px 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${p => transparentize(0.85, p.theme.userColors.main)};
  }
`;
