import { DoctypeRelativeFragment, OperatorIsInOrNot, ViewType } from '@cycle-app/graphql-codegen';
import { Button, Shortcut, Shortcuts, Tooltip } from '@cycle-app/ui';
import { WarningIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import {
  useMemo,
  FC,
  FormEvent,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useCreateDoc } from 'src/hooks/api/mutations/useCreateDoc';
import { usePossibleDoctypes } from 'src/hooks/boards/usePossibleDoctypes';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useNavigate } from 'src/hooks/useNavigate';
import { setBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { getNewDocTitle, setNewDocTitle } from 'src/reactives/boardNewDoc/newDocTitle.reactive';
import { useLastDoctypeIdUsed } from 'src/reactives/lastView.reactive';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { ShortcutDocDraft } from 'src/types/shortcuts.types';
import { getDocSlug } from 'src/utils/slug.util';

import {
  DraggableCardStyled,
  Form,
  Actions,
  DoctypeContainer,
  DoctypeSelect,
  DocTitleEditable,
  WarningContainer,
} from './NewDoc.styles';

export interface Props {
  className?: string;
  key?: string;
  groupId: string; // To use when board grouped by is available
  statusId?: string;
  from?: 'top' | 'bottom';
  viewType: ViewType;
  possibleDoctypesId?: Array<string>;
  parentId?: string;
}

const NewDoc: FC<Props> = ({
  className = '',
  from,
  groupId,
  statusId,
  viewType,
  possibleDoctypesId,
  parentId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { navigate } = useNavigate();
  const lastDoctypeIdUsed = useLastDoctypeIdUsed();
  const firstStatusinView = useFirstStatusinView();

  const {
    createDoc,
    hasDefaultAttributes,
    loading,
  } = useCreateDoc({
    from,
    groupId,
    statusId: statusId ?? firstStatusinView?.id,
  });

  const autoScrollToView = useCallback(() => {
    if (!containerRef.current) return;
    scrollIntoView(containerRef.current, {
      scrollMode: 'if-needed',
      block: viewType === 'KANBAN' && from === 'bottom' ? 'start' : 'end',
    });
  }, [viewType, from]);

  useEffect(() => {
    autoScrollToView();
  }, [autoScrollToView]);

  const { getPossibleDoctypes } = usePossibleDoctypes();
  const possibleDoctypes = useMemo(() => getPossibleDoctypes({
    groupId,
    possibleDoctypesId,
  }), [getPossibleDoctypes, groupId, possibleDoctypesId]);
  const [doctype, setDoctype] = useState(possibleDoctypes.find(d => d.id === lastDoctypeIdUsed) || possibleDoctypes[0]);
  const doctypeSelectDisabled = possibleDoctypes.length <= 1;

  const onDoctypeSelected = useCallback((selectedDoctype: DoctypeRelativeFragment) => setDoctype(selectedDoctype), []);

  const onCloseNewDoc = useCallback(() => {
    if (loading || getActiveProductTour()) return;

    setBoardNewDocPositionState({ draftPosition: null });
  }, [loading]);

  const onCreateNewDoc = useCallback(async () => {
    if (loading || getActiveProductTour()) return null;

    const { title } = getNewDocTitle();
    setNewDocTitle({ title: '' });

    const createdDoc = await createDoc({
      title,
      doctype,
      parentId,
    });
    setBoardNewDocPositionState({ draftPosition: null });
    autoScrollToView();
    return createdDoc;
  }, [createDoc, doctype, loading, parentId, autoScrollToView]);

  const onCreateNewDocAndOpen = useCallback(async () => {
    if (getActiveProductTour()) return;

    const createdDoc = (await onCreateNewDoc())?.data?.addNewDoc;
    if (createdDoc) {
      navigate(PageId.Doc, { docSlug: getDocSlug(createdDoc) });
    }
  }, [onCreateNewDoc, navigate]);

  const onCreateDocAndOpenNewDraft = useCallback(async () => {
    if (getActiveProductTour()) return;

    await onCreateNewDoc();
    /**
     * Without this timeout, setBoardNewDocPositionState({ draftPosition: null })
     * would be overwritten by "draftPosition: from" due to React 18 automatic
     * batching. Here we explicitly want to close the new doc first, and then
     * open a new one
     */
    setTimeout(() => {
      setBoardNewDocPositionState({ draftPosition: from });
    }, 0);
  }, [onCreateNewDoc, from]);

  useHotkeyListener({
    callbacks: {
      [ShortcutDocDraft.Abort]: onCloseNewDoc,
      [ShortcutDocDraft.Create]: onCreateNewDoc,
      [ShortcutDocDraft.CreateAndOpen]: onCreateNewDocAndOpen,
      [ShortcutDocDraft.CreateAndNewDraft]: onCreateDocAndOpenNewDraft,
    },
    shortcuts: Object.values(ShortcutDocDraft),
  });

  return (
    <DraggableCardStyled
      draft
      isSelected
      from={from}
      ref={containerRef}
      className={className}
    >
      <Form onSubmit={submit} $viewType={viewType}>
        <DoctypeContainer>
          <DoctypeSelect
            doctype={doctype}
            possibleDoctypes={possibleDoctypes}
            tooltipContent={possibleDoctypes.length > 1 ? 'Change Doctype' : ''}
            onSelect={onDoctypeSelected}
            showTriangle={!doctypeSelectDisabled}
            disabled={doctypeSelectDisabled}
            // filtering already done in getPossibleDoctypes
            excludeBuiltIn={false}
          />
        </DoctypeContainer>
        <DocTitleEditable
          tag="span"
          initialValue={getNewDocTitle().title}
          placeholder="Untitled"
          focusEndOnMount
          onChange={(title) => setNewDocTitle({ title })}
          $viewType={viewType}
        />
        <Actions>
          <Button
            type="button"
            variant="secondary"
            tooltipPlacement="top"
            tooltip={(
              <Shortcut
                keys={shortcuts[ShortcutDocDraft.Abort]}
                label="Cancel"
              />
            )}
            onClick={onCloseNewDoc}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={loading}
            tooltipPlacement="top"
            tooltip={(
              <Shortcuts
                shortcuts={[
                  {
                    label: 'Save',
                    keys: shortcuts[ShortcutDocDraft.Create],
                  },
                  {
                    label: 'Save & Open',
                    keys: shortcuts[ShortcutDocDraft.CreateAndOpen],
                  },
                  {
                    label: 'Save & Create new',
                    keys: shortcuts[ShortcutDocDraft.CreateAndNewDraft],
                  },
                ]}
              />
            )}
          >
            Create
          </Button>

          {hasDefaultAttributes && (
            <WarningContainer>
              <Tooltip
                placement="top"
                title="Default values"
                content="Some values will be set by default due to view filters"
                withPortal
              >
                <WarningIcon />
              </Tooltip>
            </WarningContainer>
          )}
        </Actions>
      </Form>
    </DraggableCardStyled>
  );

  async function submit(e: FormEvent) {
    e.preventDefault();
    await onCreateNewDoc();
  }
};

export default NewDoc;

const useFirstStatusinView = () => {
  const filterProperties = useBoardConfig(ctx => ctx.boardConfig?.filterProperties);
  for (const p of nodeToArray(filterProperties)) {
    if (p.__typename === 'FilterPropertyRuleStatus') {
      const values = nodeToArray(p.statusRule.values);
      return values.find(v => (p.statusRule.operator === OperatorIsInOrNot.Is ? v.selected : !v.selected))?.value;
    }
  }
  return undefined;
};
