import { useFormContext } from 'react-hook-form';

import { CustomFields } from './CustomFields';
import { FieldAssignee } from './FieldAssignee';
import { FieldCustomer } from './FieldCustomer';
import { FieldDescription } from './FieldDescription';
import { FieldTitle } from './FieldTitle';
import { FieldLabel, FormFooter, FormButton, Success } from './Form.styles';
import { SuccessMessage } from './SuccessMessage';

export const FormContent = () => {
  const { formState } = useFormContext();

  if (formState.isSubmitted) {
    return (
      <Success>
        <SuccessMessage />
        <FormButton type="reset">
          Create new doc
        </FormButton>
      </Success>
    );
  }

  return (
    <>
      <div>
        <FieldLabel>Customer</FieldLabel>
        <FieldCustomer />
      </div>

      <div>
        <FieldLabel>Assignee</FieldLabel>
        <FieldAssignee />
      </div>

      <div>
        <FieldLabel>Title</FieldLabel>
        <FieldTitle />
      </div>

      <div>
        <FieldLabel>Description</FieldLabel>
        <FieldDescription />
      </div>

      <CustomFields />

      <FormFooter>
        <FormButton
          type="submit"
          disabled={!!Object.keys(formState.errors).length}
          isLoading={formState.isSubmitting}
        >
          Create
        </FormButton>
      </FormFooter>
    </>
  );
};
