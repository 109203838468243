import { getBreakpoint } from '@cycle-app/utilities';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

export interface SidebarState {
  width: number;
  displayed: boolean;
  collapsed: boolean;
  resizing: boolean;
  animate: boolean;
}

export const {
  hookState: useSidebar,
  hookValue: useGetSidebar,
  getValue: getSidebar,
  setValue: setSidebar,
  resetValue: resetSidebar,
} = make<SidebarState>({
  defaultState: {
    displayed: getBreakpoint() !== 'mobile',
    width: 240,
    collapsed: false,
    resizing: false,
    animate: true,
  },
  localKey: LocalKey.Sidebar,
});
