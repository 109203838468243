import { GithubIssueBaseFragment, GithubRepositoryFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { SelectLine, Spinner } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FC, useState } from 'react';

import { GithubIssueCreationModal } from 'src/components/GithubIssueCreationModal/GithubIssueCreationModal';
import { GithubIssueSelectLine } from 'src/components/GithubIssueSelectLine/GithubIssueSelectLine';
import { GithubRepositoriesSearchDropdown } from 'src/components/GithubRepositoriesSearchDropdown/GithubRepositoriesSearchDropdown';
import { IntegrationEmptyStateLine } from 'src/components/Integrations/EmptyStateLine/IntegrationEmptyStateLine';
import { LineSpinnerContainer, SelectLinesWrapper, SelectWrapper } from 'src/components/Integrations/IntegrationsCommon.styles';
import { IntegrationNotInstalledLine } from 'src/components/Integrations/NotInstalledLine/IntegrationNotInstalledLine';
import { Events, Methods } from 'src/constants/analytics.constants';
import { useCreateGithubIssue } from 'src/hooks/api/mutations/integrations/useCreateGithubIssue';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useToaster } from 'src/hooks/useToaster';
import { CreateGithubIssueParams } from 'src/types/integrations.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';

import { ShortcutsContainer, ShortcutStyled } from './GithubIssuesSearchList.styles';

const CREATE_VALUE = 'create-github-issue-value';

type Props = {
  isIntegrationActive: boolean;
  isLoading: boolean;
  onLink: (selectedIssue: GithubIssueBaseFragment) => void;
  onCreated: (selectedIssue: GithubIssueBaseFragment) => void;
  results: (GithubIssueBaseFragment | null | undefined)[];
  search: string;
  docId?: string;
};

export const GithubIssuesSearchList: FC<Props> = ({
  isIntegrationActive,
  isLoading,
  onLink,
  onCreated,
  results,
  search,
  docId,
}) => {
  const [selectedRepo, setSelectedRepo] = useState<GithubRepositoryFragment | null>(null);
  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues: [...results.map((r) => r?.id ?? ''), CREATE_VALUE],
    onSelect: onResultSelected,
  });
  const {
    createIssue, isLoading: isCreateLoading,
  } = useCreateGithubIssue();
  const [isModalOpen, {
    setTrueCallback: openModal,
    setFalseCallback: closeModal,
  }] = useOptimizedBooleanState(false);
  const { add: addToaster } = useToaster();

  useAppHotkeys('command+enter', (e) => {
    e.stopPropagation();
    openModal();
  }, {
    enabled: !!search.length && !isModalOpen,
    capture: true,
  });

  return (
    <div {...listProps}>
      {renderIssuesContent()}
      {renderCreateIssue()}
    </div>
  );

  function renderIssuesContent() {
    if (!isIntegrationActive) return <IntegrationNotInstalledLine integrationType={IntegrationType.Github} />;

    if (isLoading) return <LineSpinnerContainer><Spinner /></LineSpinnerContainer>;

    if (!results.length && search.length < 3) return <IntegrationEmptyStateLine />;

    return (
      <SelectLinesWrapper>
        {results.map(item => (item ? (
          <GithubIssueSelectLine
            key={item?.id}
            issue={item}
            isSelected={item.id === selected}
            extraProps={itemProps(item.id)}
          />
        ) : null))}
      </SelectLinesWrapper>
    );
  }

  function renderCreateIssue() {
    return isIntegrationActive && !!search ? (
      <>
        <SelectLine
          startSlot={(
            <SelectWrapper>
              {isCreateLoading ? <Spinner /> : '+ New'}
              <GithubRepositoriesSearchDropdown
                isDisabled={isCreateLoading}
                selectedRepoId={selectedRepo?.id ?? ''}
                onSelect={setSelectedRepo}
              />
            </SelectWrapper>
          )}
          endSlot={(
            <ShortcutsContainer>
              <ShortcutStyled keys={['mod']} />
              +
              <ShortcutStyled keys={['enter']} />
              to add details
            </ShortcutsContainer>
          )}
          label={search}
          isSelected={CREATE_VALUE === selected}
          {...itemProps(CREATE_VALUE)}
        />
        {!!docId && (
          <GithubIssueCreationModal
            search={search}
            isOpen={isModalOpen}
            onClose={closeModal}
            createIssue={onCreateGithubIssue}
            isLoading={isCreateLoading}
          />
        )}
      </>
    ) : null;
  }

  async function onResultSelected(issueId: string | null) {
    if (issueId === CREATE_VALUE) {
      try {
        await onCreateGithubIssue({});
      } catch (e) {
        logError(new Error('Error during selection of createGithubIssue'));
      }
    } else {
      const issue = results.find(i => i?.id === issueId);

      if (!issue) return;

      onLink(issue);
    }
  }

  async function onCreateGithubIssue({
    title, repoName, description, assignees, status,
  }: CreateGithubIssueParams) {
    if (
      (!search.trim() && !title) ||
      (!repoName && !selectedRepo?.name) ||
      !docId
    ) return;

    const isFromModal = !!(title && repoName);

    const result = await createIssue({
      title: title || search,
      docId,
      repoName: repoName || selectedRepo?.name || '',
      description,
      assignees: assignees?.map(a => a.login) || [],
      status,
    });

    if (result.data?.createGithubIssue) {
      onCreated(result.data.createGithubIssue);
      trackAnalytics(Events.IntegrationIssueCreated, {
        type: IntegrationType.Github,
        method: isFromModal ? Methods.SlashCommandModal : Methods.SlashCommand,
      });

      if (isModalOpen && isFromModal) {
        closeModal();
      }

      return;
    }

    addToaster({
      title: 'An error occured',
      message: 'An error occured during Github issue creation',
    });
    logError(new Error('Error during createGithubIssue'));
  }
};
