import { CustomAttributeDefinitionFragment, ListPositionInput } from '@cycle-app/graphql-codegen';
import { SelectOption, SelectLine } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { FC, KeyboardEvent, useCallback, useState, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import DialogModal from 'src/components/DialogModal/DialogModal';
import SelectOptionsManager from 'src/components/SelectOptionsManager/SelectOptionsManager';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { usePageId } from 'src/hooks/usePageId';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import { AddOptionButton, AttributeTagIconContainer, AttributeTagStyled, OptionTag } from './AttributeOptionsManager.styles';

interface Props {
  attributeDefinition: CustomAttributeDefinitionFragment;
  options: SelectOption[];
}

const AttributeOptionsManager: FC<Props> = ({
  attributeDefinition, options,
}) => {
  const pageId = usePageId();
  const [isAddingOption, {
    setTrueCallback: setIsAddingOption,
    setTrueCallback: setIsNotAddingOption,
  }] = useOptimizedBooleanState(options.length === 0);
  const [newOptionName, setNewOptionName] = useState('');
  const [optionIdToDelete, setOptionIdToDelete] = useState<string | null>(null);

  const {
    addSelectOption,
    changeSelectOption,
    removeSelectOption,
    moveSelectAttributeValue,
    loadingChangeSelectOption,
  } = useAttributesMutations();
  const debouncedChangeSelectOption = useDebouncedCallback(changeSelectOption, INPUT_ONCHANGE_DEBOUNCE);

  const registerOptionIdToDelete = useCallback((optionId: string) => setOptionIdToDelete(optionId), []);
  const resetOptionIdToDelete = useCallback(() => setOptionIdToDelete(null), []);

  const onSorted = useCallback(
    (valueId: string, position: ListPositionInput, sortedItems: Array<string>) => (
      moveSelectAttributeValue({
        attributeId: attributeDefinition.id,
        valueId,
        position,
        sortedItems,
      })
    ),
    [moveSelectAttributeValue, attributeDefinition],
  );

  const handleKeyPressed = useCallback(async (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && newOptionName) {
      setIsNotAddingOption();
      setNewOptionName('');
      await addSelectOption(attributeDefinition.id, newOptionName);
    }
  }, [addSelectOption, attributeDefinition.id, newOptionName, setIsNotAddingOption]);

  const optionData = useMemo(() => options.find(option => option.value === optionIdToDelete), [options, optionIdToDelete]);

  return (
    <>
      <SelectOptionsManager
        sortable
        onSorted={onSorted}
        options={options}
        onDeleteOption={registerOptionIdToDelete}
        onEditOption={onEditOption}
        isSaving={loadingChangeSelectOption}
        lastLine={isAddingOption
          ? (
            <SelectLine
              isEditable
              startFocused
              label={newOptionName}
              placeholder="Enter to add option"
              onChangeLabel={setNewOptionName}
              onKeyUp={handleKeyPressed}
              onBlur={setIsNotAddingOption}
            />
          )
          : (
            <AddOptionButton
              onClick={setIsAddingOption}
              size={14}
            >
              <AddIcon />
              Add option
            </AddOptionButton>
          )}
      />
      {optionIdToDelete && optionData && (
        <DialogModal
          title="Delete option"
          info={(
            <>
              {'Are you sure you want to delete '}
              <OptionTag>{optionData.label}</OptionTag>
              {' as an option for'}
              <AttributeTagStyled
                limitSize={false}
                icon={<AttributeTagIconContainer>{getCustomAttributeTypeData(attributeDefinition.__typename).icon}</AttributeTagIconContainer>}
              >
                {attributeDefinition.name}
              </AttributeTagStyled>
              ? Docs with this value will lose it.
            </>
          )}
          confirmLabel="Delete"
          useHighMaskLayer
          hide={resetOptionIdToDelete}
          onConfirm={onConfirm}
        />
      )}
    </>
  );

  async function onEditOption(optionId: string, textValue: string) {
    await debouncedChangeSelectOption(optionId, textValue);
  }

  async function onConfirm() {
    if (!optionIdToDelete) return;
    await removeSelectOption(attributeDefinition, optionIdToDelete, [PageId.Doc, PageId.DocFullPage].includes(pageId));
  }
};

export default AttributeOptionsManager;
