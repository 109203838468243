import { DocBaseFragment, ViewType } from '@cycle-app/graphql-codegen';
import { Tag } from '@cycle-app/ui';
import { getDocFullUrl } from '@cycle-app/utilities';
import { FC, MouseEvent, useCallback } from 'react';
import { Placement } from 'tippy.js';

import { DocStatus } from 'src/components/DocStatus';
import DoctypesOptions from 'src/components/DoctypesOptions/DoctypesOptions';
import { Events, Sources, Methods } from 'src/constants/analytics.constants';
import { PageId } from 'src/constants/routing.constant';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { usePageId } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';
import { Layer } from 'src/types/layers.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { isInsight } from 'src/utils/docType.util';
import { getDocSlug } from 'src/utils/slug.util';

import { Container, SkeletonTag } from './DocPrimaryAttributes.styles';

interface Props {
  className?: string;
  doc: Partial<DocBaseFragment>;
  isDragging?: boolean;
  viewType?: ViewType;
  layer?: Layer;
  readOnly?: boolean;
  dropdownPlacement?: Placement;
  showDocId?: boolean;
  showDocType?: boolean;
  showStatus?: boolean;
  context?: 'doc-item' | 'doc-panel';
  isDocTypeReadOnly?: boolean;
  enableStatusShortcut?: boolean;
}

const DocPrimaryAttributes: FC<Props> = ({
  className,
  doc,
  isDragging,
  viewType,
  layer = Layer.Dropdown,
  readOnly = false,
  dropdownPlacement = 'bottom',
  showDocId = true,
  showDocType = true,
  showStatus = true,
  context = 'doc-item',
  isDocTypeReadOnly = false,
  enableStatusShortcut,
}) => {
  const getUrl = useUrl();
  const pageId = usePageId();

  const copyToClipboard = useCopyToClipboard({
    successNotification: `Link to ${doc._docKey} copied to clipboard!`,
  });

  const onTagDocIdClicked = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const docUrl = doc?.id && getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
    const fullDocUrl = docUrl && getDocFullUrl(docUrl);
    if (!fullDocUrl) {
      console.warn('No doc url');
      return;
    }
    copyToClipboard(fullDocUrl);
    trackAnalytics(Events.DocShared, {
      method: Methods.UI,
      source: pageId === PageId.Board ? Sources.Board : Sources.DocPanel,
    });
  }, [copyToClipboard, doc, getUrl, pageId]);

  const docTypeTag = showDocType && (
    <DoctypesOptions
      layer={layer}
      doc={doc}
      doctype={doc.doctype}
      tooltipDisabled={isDragging}
      dropdownPlacement={dropdownPlacement}
      disabled={readOnly || isDocTypeReadOnly}
      context={context}
    />
  );

  const docIdTag = showDocId && !doc.isDraft && (
    <Tag
      onClick={onTagDocIdClicked}
      color={context === 'doc-panel' ? 'grey' : undefined}
      tooltip={{
        placement: 'top',
        disabled: isDragging,
        title: 'Doc ID',
        content: 'Copy link',
        withPortal: true,
      }}
    >
      {doc._docKey ?? <SkeletonTag />}
    </Tag>
  );

  const statusTag = showStatus && doc?.status && doc?.id && doc.doctype?.id && (
    <DocStatus
      docId={doc.id}
      statusId={doc.status.id}
      docTypeId={doc.doctype.id}
      hideLabel={context === 'doc-item'}
      enableShortcut={enableStatusShortcut}
      isDisabled={isInsight(doc.doctype)}
    />
  );

  return (
    <Container
      className={className}
      $viewType={viewType}
      $context={context}
    >
      {context === 'doc-item' ? (
        <>
          {docTypeTag}
          {docIdTag}
          {statusTag}
        </>
      ) : (
        <>
          {docIdTag}
          {docTypeTag}
          {statusTag}
        </>
      )}
    </Container>
  );
};

export default DocPrimaryAttributes;
