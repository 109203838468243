import { ReactNode, forwardRef, memo } from 'react';

import CoverDropzoneOverlay from 'src/components/CoverDropzoneOverlay/CoverDropzoneOverlay';
import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import { useDocContext } from 'src/contexts/docContext';
import useCoverDropzone from 'src/hooks/doc/useCoverDropzone';
import { ViewType } from 'src/types/viewType.types';
import { getEditCoverAction } from 'src/utils/doc.util';

import {
  CoverLoader,
  DropzoneContainer,
} from './DocItem.styles';

type Props = {
  children: ReactNode;
  disabled?: boolean;
  viewType: ViewType;
};

export const DocItemCoverZone = memo(forwardRef<HTMLInputElement, Props>(({
  children, disabled = false, viewType,
}, ref) => {
  const docId = useDocContext(ctx => ctx.id);
  const coverUrl = useDocContext(ctx => ctx.cover?.url);
  const {
    updateDocCover,
    getRootProps,
    isDragActive,
    isUploadingCover,
  } = useCoverDropzone({
    docId,
    disabled,
  });

  const dropzoneProps = getRootProps();

  return (
    <>
      <DropzoneContainer {...dropzoneProps}>
        {isDragActive && (
          <CoverDropzoneOverlay
            label={getEditCoverAction(coverUrl)}
            horizontalLayout={viewType === ViewType.List}
          />
        )}
        {isUploadingCover && dropzoneProps.ref.current && (
          <CoverLoader
            width={dropzoneProps.ref.current.clientWidth + 2}
            height={dropzoneProps.ref.current.clientHeight + 2}
          />
        )}
        {children}
      </DropzoneContainer>
      <CoverImageInputFile
        ref={ref}
        onCoverChanged={updateDocCover}
      />
    </>
  );
}));
