import { BillingPlan, DowngradeToFreePlanDocument, DowngradeToFreePlanMutationVariables } from '@cycle-app/graphql-codegen';

import { useProduct, useCurrentBilling } from '../api/useProduct';
import useSafeMutation from '../useSafeMutation';

export const useBillingDowngrade = () => {
  const { product } = useProduct();
  const currentBilling = useCurrentBilling();
  const [downgradeMutation, { loading }] = useSafeMutation(DowngradeToFreePlanDocument);

  const downgrade = (variables: Omit<DowngradeToFreePlanMutationVariables, 'productId'>) => (product?.id && downgradeMutation({
    variables: {
      productId: product.id,
      ...variables,
    },
    update: (cache, result) => {
      if (result.data?.downgradeToFreePlan && currentBilling) {
        const billing = cache.identify(currentBilling);
        if (billing) {
          cache.modify({
            id: billing,
            fields: {
              plan: () => BillingPlan.Free,
            },
          });
        }
      }
    },
  })) || undefined;

  return {
    downgrade,
    isLoading: loading,
  };
};
