import { BoardWithMinimalConfigFragment } from '@cycle-app/graphql-codegen/generated';
import { OnboardingLayout, Spinner, Emoji, Tooltip, NavigationSectionSkeleton } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { useState, useMemo, FC, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { AppPreview, previewVariant } from 'src/app/Auth/Welcome/Welcome.styles';
import { useBoardPrefetch } from 'src/hooks/api/useBoard';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { getOnboarding, setOnboarding } from 'src/reactives/onboarding.reactive';
import { OnboardingScreen } from 'src/types/onboarding.types';

import { OnboardingPreviewBoardRoute } from '../../OnboardingPreviewBoardRoute/OnboardingPreviewBoardRoute';
import {
  BoardInfo,
  BoardItem, BoardListItem, BoardName, BoardSkeletonContainer, LoaderContainer, SectionListItem, SectionName, StyledInfoIcon,
} from './StepBoards.styles';

type Props = {
  progress: number;
};

export const StepBoards: FC<Props> = ({ progress }) => {
  const {
    sections,
    loading,
  } = useBoardSections();
  const prefetchBoard = useBoardPrefetch();
  const [selectedBoard, setSelectedBoard] = useState<{ id: string; name: string; emoji: string | null | undefined } | null>(null);
  const selectedTheme = getTheme(getOnboarding().theme, getOnboarding().userColor);
  const boardSections = useMemo(() => sections.filter(({ boards }) => !!boards.edges.length), [sections]);

  useEffect(() => {
    const boards = boardSections.reduce((acc, section) => ([
      ...acc,
      ...section.boards.edges.reduce((boardAcc, board) => ([...boardAcc, board.node]), [] as BoardWithMinimalConfigFragment[]),
    ]), [] as BoardWithMinimalConfigFragment[]);

    if (boards.length) {
      // No need to handle the promise as we only prefetch the data.
      Promise.all(boards.slice(1, Math.min(boards.length, 10)).map(board => prefetchBoard(board.id)))
        .then(() => { /* */ })
        .catch(() => { /* */ });
    }
  }, [boardSections, prefetchBoard]);

  // The first section is the inbox built-in section
  const defaultBoard = boardSections[1]?.boards.edges[0].node;
  const previewBoard = selectedBoard ?? defaultBoard;
  return (
    <OnboardingLayout
      title="Your Cycle workspace is made of views"
      description="You can choose any view below to have a preview."
      main={loading ? <BoardSkeletonContainer><NavigationSectionSkeleton /></BoardSkeletonContainer> : (
        <ul>
          {boardSections.map(section => (
            <SectionListItem key={section.id}>
              <SectionName>{section.name}</SectionName>
              <ul>
                {section.boards.edges.map(({ node }) => (
                  <BoardListItem key={node.id}>
                    <BoardItem
                      variant={previewBoard.id === node.id ? 'primary' : 'ternary'}
                      $isActive={previewBoard.id === node.id}
                      onClick={() => setSelectedBoard({
                        id: node.id,
                        name: node.name,
                        emoji: node.emoji,
                      })}
                      size="M"
                    >
                      <BoardName>
                        {node.emoji && <Emoji inline emoji={node.emoji} />}
                        {node.name}
                      </BoardName>
                      {node.description && (
                        <BoardInfo>
                          <Tooltip
                            placement="right"
                            content={node.description}
                            offset={[0, 15]}
                          >
                            <StyledInfoIcon />
                          </Tooltip>
                        </BoardInfo>
                      )}
                    </BoardItem>
                  </BoardListItem>
                ))}
              </ul>
            </SectionListItem>
          ))}
        </ul>
      )}
      aside={!loading && previewBoard ? (
        <AppPreview
          initial="glimpse"
          $isLoading={false}
          $full={false}
          variants={previewVariant}
        >
          <ThemeProvider theme={selectedTheme}>
            <OnboardingPreviewBoardRoute name={previewBoard.name} emoji={previewBoard.emoji} />
          </ThemeProvider>
        </AppPreview>
      ) : (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      progress={progress}
      nextButtonProps={{
        onClick: () => {
          setOnboarding({ screen: OnboardingScreen.Collaboration });
        },
      }}
    />
  );
};
