import styled from 'styled-components';

import { body400 } from '../../../theme/typo';
import { EmojiPicker } from '../../EmojiPicker/EmojiPicker';

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ${body400}
`;

export const EmojiPickerStyled = styled(EmojiPicker)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.border.input};
  border-radius: 4px;
`;
