import { ActionButton, boxShadowZ1 } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledFloatingPenButton = styled(ActionButton)`
  --size: 16px;
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.actionButton.outline};
  height: 16px;
  padding: 2px;
  width: 16px;
  ${boxShadowZ1}
`;

export const FloatingPenButtonContainer = styled.div<{ $isCompact?: boolean }>`
  position: relative;

  &:not(:hover) ${StyledFloatingPenButton} {
    display: none;
  }
`;

export const FloatingPenButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;
