import { BillingPlan } from '@cycle-app/graphql-codegen';
import { useInView } from 'react-intersection-observer';

import { PageId } from 'src/constants/routing.constant';
import { useCurrentBilling, useIsFreePlan, useIsStandardPlan } from 'src/hooks';
import { useNavigate } from 'src/hooks/useNavigate';

import {
  FeatureNameCell, HeadTh, PlaceholderCell, PlanCell, PlanRow, UpgradeRow,
} from './BillingPlan.styles';
import { plans, UpgradeClickProps } from './plans';

export const BillingPlanHeader = () => {
  const currentBilling = useCurrentBilling();
  const isFreePlan = useIsFreePlan();
  const isStandardPlan = useIsStandardPlan();
  const [observedRef, isUpgradeVisible] = useInView({
    rootMargin: '-64px 0px 0px 0px',
  });
  const { navigate } = useNavigate();
  return (
    <thead>
      <PlanRow $isSticky={!isUpgradeVisible}>
        <FeatureNameCell />
        {plans.map(plan => (
          <HeadTh key={plan.id}>
            <PlanCell
              $isFree={plan.id === BillingPlan.Free}
              $isStandard={plan.id === BillingPlan.Standard}
            >
              <span>{plan.name}</span>
              {currentBilling && plan.pricing(currentBilling)}
            </PlanCell>
          </HeadTh>
        ))}
        <PlaceholderCell />
        <PlaceholderCell />
      </PlanRow>
      <UpgradeRow $isSticky={!isUpgradeVisible} ref={observedRef}>
        <FeatureNameCell />
        {plans.map(plan => (
          <HeadTh key={plan.id}>
            {plan.upgrade({
              isFreePlan,
              isStandardPlan,
              onClick: onUpgradeClick,
            })}
          </HeadTh>
        ))}
        <PlaceholderCell />
        <PlaceholderCell />
      </UpgradeRow>
    </thead>
  );

  function onUpgradeClick(selectedPlan: UpgradeClickProps) {
    if (isFreePlan && selectedPlan === BillingPlan.Standard) {
      navigate(PageId.BillingUpgrade);
    }
    if (isStandardPlan && selectedPlan === BillingPlan.Free) {
      navigate(PageId.BillingDowngrade);
    }
  }
};
