export enum LocalKey {
  Auth = 'auth',
  DocPanel = 'doc-panel',
  DoctypeGraph = 'doctype-graph',
  DraftComments = 'draft-comments',
  Groups = 'groups',
  LastUsedCustomField = 'last-used-custom-field',
  LastUsedDocType = 'last-used-doc-type',
  LastUsedGitHubRepository = 'last-used-github-repository',
  LastView = 'last-view',
  LastInboxBoard = 'last-inbox-board',
  NewDoc = 'new-doc',
  Onboarding = 'onboarding',
  LightOnboarding = 'light-onboarding',
  Realtime = 'realtime',
  Sections = 'sections',
  Sidebar = 'sidebar',
  Theme = 'theme',
  UserPreferences = 'user-preferences',
}
