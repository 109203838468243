import { ActionButton } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 4px;
  background: ${p => p.theme.colors.inbox.sources.bg};
  > [data-popover] {
    display: flex;
  }
`;

export const Info = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  margin-left: 40px;
`;

export const SourceButton = styled(ActionButton)`
  ${p => !p.onClick && css`
    pointer-events: none;
  `}

  > svg {
    height: 16px;
    width: 16px;
  }
`;
