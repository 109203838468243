import { typo, Skeleton, CircleSkeleton, ShyScrollbarCss } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

const cardBased = css`
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  height: 136px;
  border-radius: 8px;
  padding: 8px;
`;

export const Header = styled.header`
  display: flex;
  padding: 16px 32px;
`;

export const Title = styled.h2`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.text.primary};
`;

export const MembersContainerWrapper = styled.div`
  position: relative;
  margin: 0 32px;
`;

export const MembersContainer = styled.div`
  ${ShyScrollbarCss}
  display: flex;
  background-color: ${p => p.theme.colors.home.card.bg};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  /* We have to remove padding bottom and always stick to scroll overflow so the box does't change height when scrollbar pops up */
  padding: 8px 8px 0px 8px;
  overflow-x: scroll;
`;

export const MemberCardSkeleton = styled.div`
  ${cardBased}
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

export const MemberAvatarSkeleton = styled(CircleSkeleton)`
  width: 64px;
  height: 64px;
`;

export const MemberNameSkeleton = styled(Skeleton)`
  height: 12px;
  width: 40%;
`;

export const MemberStatusSkeleton = styled(Skeleton)`
  height: 10px;
  width: 60%;
  opacity: 0.7;
`;

export const InviteMemberCard = styled.button`
  ${cardBased}
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.background.tertiary};
  color: ${p => p.theme.colors.text.disabled};
  margin-bottom: 8px;
`;

export const Text = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};
  text-align: center;
`;

export const TextInvite = styled(Text)`
  line-height: 17px;
`;

export const MemberEmptyState = styled.button`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${p => p.theme.colors.card.bg};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  min-height: 150px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 0px 16px;

  &:hover, &:active, &:focus {
    background-color: ${p => p.theme.colors.card.bgHover};
    outline: 2px solid ${p => p.theme.userColors.main};
  }
`;

export const SubTitle = styled.h4`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Image = styled.img`
  margin-bottom: 8px;
  object-fit: cover;
`;

const scrollButtonCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: ${p => p.theme.colors.background.primary};
  color: ${p => p.theme.colors.text.disabled};
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const ScrollLeftButton = styled.button`
  ${scrollButtonCSS}
  left: 8px;
  transform: translateY(-50%) rotate(180deg);
`;

export const ScrollRightButton = styled.button`
  ${scrollButtonCSS}
  right: 8px;
  transform: translateY(-50%);
`;
