import { OnboardingLayout, Spinner } from '@cycle-app/ui';
import { PusherProvider } from '@harelpls/use-pusher';
import { JSONContent } from '@tiptap/core';
import { useEffect, FC, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import DocPanelEditableTitle from 'src/app/Main/Board/DocPanel/DocPanelEditableTitle/DocPanelEditableTitle';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useDocSubscription } from 'src/hooks/api/useDocSubscription';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { useFakeCursors } from 'src/hooks/useFakeCursors';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { getOnboarding, setOnboarding } from 'src/reactives/onboarding.reactive';
import { ActionId } from 'src/services/editor/editorActions';
import { OnboardingScreen } from 'src/types/onboarding.types';
import { pusherConfig } from 'src/utils/pusher.util';
import { getDocSlug } from 'src/utils/slug.util';

import DocEditor from '../../DocEditor/DocEditor';
import { OnboardingCollabMates } from '../../OnboardingCollabMates/OnboardingCollabMates';
import { OnboardingFloatingMates } from '../../OnboardingFloatingMates/OnboardingFloatingMates';
import { OnboardingMatesCursors } from '../../OnboardingMatesCursors/OnboardingMatesCursors';
import { OnboardingPreviewRoute } from '../../OnboardingPreviewRoute/OnboardingPreviewRoute';
import {
  Container, EditorContainer, Content,
  StyledDocPanelRealtime, StyledDocAttributes, LoaderContainer, SteperContainer, StepItem, StyledCheckIcon, StyledCheckContainer, StepContent,
} from './StepEditDocs.styles';

type Props = {
  progress: number;
  isReadOnly?: boolean;
};

const disabledActions: ActionId[] = [
  ActionId.TurnTextIntoDocMention,
  ActionId.TurnTextIntoDocMentionLastUsed,
  ActionId.TurnTextIntoGithubIssueMention,
  ActionId.TurnTextIntoLinearMention,
  ActionId.Notion,
  ActionId.Linear,
  ActionId.GithubIssue,
  ActionId.MentionDoc,
  ActionId.MentionUser,
];

export const StepEditDocs: FC<Props> = ({
  progress, isReadOnly,
}) => {
  const { createdDocId } = getOnboarding();
  const {
    doc,
    onTitleUpdated,
    isLoading,
  } = useDocPanelProps(false, createdDocId);
  useDocSubscription(createdDocId);
  const editorText = useRef<string | null>(null);
  const [sentenceAdded, { setTrueCallback: setSentenceAdded }] = useOptimizedBooleanState(false);
  const [slashAdded, { setTrueCallback: setSlashAdded }] = useOptimizedBooleanState(false);
  const [imageAdded, { setTrueCallback: setImgeAdded }] = useOptimizedBooleanState(false);
  const {
    editorContainerRef, cursorsContainerRef, placeCursors,
  } = useFakeCursors();

  const placeCursorsDebounced = useDebouncedCallback(placeCursors, INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => {
    window.addEventListener('resize', placeCursorsDebounced);
    return () => window.removeEventListener('resize', placeCursorsDebounced);
  }, []);

  return (
    <>
      <OnboardingLayout
        title="Edit the content of your initiative"
        description={(
          <>
            Everything is Cycle is a natively collaborative doc.
            You can write advanced content together with your teammates in real-time.
            Give it a try on the right 👉
          </>
      )}
        main={(
          <SteperContainer>
            <StepItem $isActive={sentenceAdded && imageAdded}>
              <StyledCheckContainer $isActive={sentenceAdded}>
                <StyledCheckIcon />
              </StyledCheckContainer>
              <StepContent>
                <span>✍️</span>
                Write a sentence
              </StepContent>
            </StepItem>
            <StepItem $isActive={imageAdded && slashAdded}>
              <StyledCheckContainer $isActive={imageAdded}>
                <StyledCheckIcon />
              </StyledCheckContainer>
              <StepContent>
                <span>🌅</span>
                Drag &amp; drop an image
              </StepContent>
            </StepItem>
            <StepItem>
              <StyledCheckContainer $isActive={slashAdded}>
                <StyledCheckIcon />
              </StyledCheckContainer>
              <StepContent>
                <span>🧩</span>
                Use the &quot;  /&quot; command
              </StepContent>
            </StepItem>
          </SteperContainer>
        )}
        aside={doc
          ? (
            <OnboardingPreviewRoute pageId={PageId.DocFullPage} routeParams={{ docSlug: getDocSlug(doc) }}>
              <PusherProvider {...pusherConfig()}>
                <BoardConfigContextProvider>
                  <Container>
                    <OnboardingFloatingMates />
                    <OnboardingCollabMates>
                      <StyledDocPanelRealtime />
                    </OnboardingCollabMates>
                    <Content>
                      <DocPanelEditableTitle
                        title={doc.title}
                        onTitleUpdated={newTitle => onTitleUpdated(newTitle)}
                      />
                      <StyledDocAttributes doc={doc} readOnly />
                      <EditorContainer ref={editorContainerRef}>
                        <OnboardingMatesCursors ref={cursorsContainerRef} />
                        <DocEditor
                          docId={doc.id}
                          hideHierarchy
                          hideQuickActions
                          onEditorReady={placeCursorsDebounced}
                          onUpdate={onEditorUpdate}
                          disabledActions={disabledActions}
                        />
                      </EditorContainer>
                    </Content>
                  </Container>
                </BoardConfigContextProvider>
              </PusherProvider>
            </OnboardingPreviewRoute>
          )
          : (
            <LoaderContainer>
              {isLoading ? <Spinner /> : <div>🧐 Oops, looks like something went wrong on our side, we&apos;re on it!</div>}
            </LoaderContainer>
          )}
        progress={progress}
        isSkippable
        skipButtonProps={{
          type: 'button',
          onClick: onSubmit,
        }}
        nextButtonProps={{
          type: 'button',
          onClick: onSubmit,
        }}
        isModal={isReadOnly}
      />
    </>
  );

  function onEditorUpdate({
    json, text,
  }: { html: string; json: string; text: string }) {
    const jsonParsed: JSONContent = JSON.parse(json);
    if (!jsonParsed || !jsonParsed?.content) return;
    // We only need to check the sentences.
    const newText = keepAlphaOnly(text);
    // onEditorUpdate is called on init even user did not updated the content.
    if (editorText.current === null && newText.length) {
      editorText.current = newText;
    }
    placeCursorsDebounced();
    const hasSlashCommand = jsonParsed.content.find(row => (
      !!row.content?.find(content => (content?.text?.match(/(^|\s)\//)))));
    const hasImage = jsonParsed.content.find(row => row.type === 'image' && row.attrs?.src);
    const hasSentenceAdded =
      editorText.current !== null &&
      newText !== editorText.current &&
      newText.length > editorText.current.length;

    if (hasSentenceAdded) {
      setSentenceAdded();
    }
    if (hasSlashCommand) {
      setSlashAdded();
    }
    if (hasImage) {
      setImgeAdded();
    }
    // Update only if it was initialized.
    if (editorText.current !== null) {
      editorText.current = newText;
    }
  }

  function onSubmit() {
    setOnboarding({ screen: OnboardingScreen.Welcome });
  }
};

const alphaOnlyReg = /\W/ig;

function keepAlphaOnly(text: string) {
  return text.replaceAll(alphaOnlyReg, '');
}
