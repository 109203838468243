import { BoardWithDraftConfigFragment } from '@cycle-app/graphql-codegen/generated';
import { Spinner } from '@cycle-app/ui';
import { FC } from 'react';

import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';

import { ContentPreview, ContentSkeleton, StyledPortalModal } from './CreateDraftBoardModal.styles';
import { CreateDraftBoardModalContent } from './CreateDraftBoardModalContent';

interface Props {
  draftBoard?: BoardWithDraftConfigFragment | null;
  onHide: VoidFunction;
}

export const CreateDraftBoardModal: FC<Props> = ({
  draftBoard, onHide,
}) => {
  const { data } = useDraftBoardConfig(draftBoard?.id);
  return (
    <StyledPortalModal hide={onHide}>
      {data?.node.id && data?.node.draftBoardConfig
        ? (
          <CreateDraftBoardModalContent
            draftBoardId={data.node.id}
            draftBoardConfig={data.node.draftBoardConfig}
            onHide={onHide}
          />
        )
        : (
          <>
            <ContentSkeleton><Spinner /></ContentSkeleton>
            <ContentPreview />
          </>
        )}
    </StyledPortalModal>
  );
};
