import { typo, Skeleton, Button } from '@cycle-app/ui';
import { Container as ButtonContainer } from '@cycle-app/ui/components/Buttons/Button/Button.styles';
import { mobile, tablet } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 16px 32px;

  ${ButtonContainer} {
    padding: 2px;
  }
`;

export const Title = styled.h2`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.text.primary};
`;

export const Body = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 32px;
  gap: 16px;
  
  ${tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  ${mobile} {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const cardBaseStyles = css`
  width: 277px;
  min-height: 132px;
  height: auto;
  border-radius: 12px;
  background-color: ${p => p.theme.colors.home.card.bg};
  padding: 16px;
  ${tablet}, ${mobile} {
    width: 100%;
  }
`;

export const LoadingCard = styled.div`
  ${cardBaseStyles}
`;

export const LoadingTitle = styled(Skeleton)`
  width: 65%;
  height: 18px;
  margin-bottom: 10px;
  border-radius: 6px;
`;

export const LoadingDesc = styled(Skeleton)`
  width: 45%;
  height: 16px;
  border-radius: 6px;
  opacity: 0.7;
`;

const cardStyles = css`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

  &:hover, &:active, &:focus {
    background-color: ${p => p.theme.colors.home.card.bgHover};
  }
`;

export const PlaceHolderCard = styled.button`
  ${cardBaseStyles}
  ${cardStyles}
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const StarBoardCard = styled(Link)`
  ${cardBaseStyles}
  ${cardStyles}
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  position: relative;
  gap: 4px;
`;

export const ActionsContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const CardTitle = styled.h3`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  display: inline-flex;
  gap: 10px;
  max-width: calc(100% - 20px);
  align-items: center;
`;

export const CardDescription = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const CardFooter = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

export const Star = styled.div`
  border: 1px solid ${p => p.theme.colors.border.primary};
  color: ${p => p.theme.colors.background.active};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
`;

export const AddButton = styled(Button)`
  width: 24px;
  height: 24px;
  color: ${p => p.theme.colors.text.secondary};
  ${p => p.active && css`
    background: ${p.theme.colors.button.secondary.bgFocus};
  `}
  svg {
     width: 12px;
     height: 12px;
  }
`;

export const EditDescriptionButton = styled(Button)`
  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};

  &:hover {
    color: ${p => p.theme.colors.text.primary};
  }
`;
