import { useEffect } from 'react';

import { CustomersList } from 'src/components/CustomersList/CustomersList';
import { LinkedWith } from 'src/components/LinkedWith';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { SyncedWith } from 'src/components/SyncedWith';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useDocsPreview } from 'src/hooks/doc/useDocsPreview';
import { useGetDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';

import { ButtonsGroup } from './Customers.styles';

export const CustomersView = () => {
  const { enabled: isDocPreviewEnabled } = useGetDocIdPreview();
  useDocsPreview({
    enabled: isDocPreviewEnabled,
    disableOnLayers: [Layer.Toaster],
  });
  useEffect(() => {
    setDocIdPreview({ enabled: true });
  }, []);

  return (
    <>
      <SettingsViewHeader
        title="Customers"
        actionsSlot={(
          <ButtonsGroup>
            <SyncedWith />
            <LinkedWith />
          </ButtonsGroup>
        )}
      />
      <BoardConfigContextProvider>
        <CustomersList />
      </BoardConfigContextProvider>
    </>
  );
};
