import { RemoveIntegrationDocument } from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

export const useRemoveIntegration = () => {
  const [removeIntegration, { loading }] = useSafeMutation(RemoveIntegrationDocument);

  const remove = (integrationId: string) => removeIntegration({
    variables: {
      integrationId,
    },
  });

  return {
    remove,
    isLoading: loading,
  };
};
