import { StatusCategory, StatusType } from '@cycle-app/graphql-codegen';

import { useProductStatuses } from 'src/hooks/product/useProductStatuses';

import { useUpdateDocsStatus } from './useUpdateDocsStatus';

type Props = {
  docId: string;
  docStatusCategory: StatusCategory;
  onCompleted?: VoidFunction;
};

export const useToggleDocStatus = ({
  docId, docStatusCategory, onCompleted,
}: Props) => {
  const { updateDocsStatus } = useUpdateDocsStatus();
  const statuses = useProductStatuses();
  const notStartedId = statuses.notStarted.find(s => s.type === StatusType.ToProcess)?.id;
  const completedId = statuses.completed.find(s => s.type === StatusType.Processed)?.id;

  const isCompleted = docStatusCategory === StatusCategory.Completed;

  const toggleDocStatus = async () => {
    if (!notStartedId || !completedId) return;
    await updateDocsStatus([docId], isCompleted ? notStartedId : completedId);
    onCompleted?.();
  };

  return {
    isCompleted,
    toggleDocStatus,
  };
};
