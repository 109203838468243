import {
  ActionButton,
  Button,
  Input as InputUI,
  SelectLine,
  ShyScrollbar,
  Spinner,
} from '@cycle-app/ui';
import { SearchIcon as SearchIconUI } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

const GAP_SIZE = 8;

export const SearchContainer = styled.div<{ $isSearching: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 ${GAP_SIZE}px;
  width: 500px;
  gap: ${GAP_SIZE}px;
`;

export const Input = styled(InputUI)`
  padding-top: ${GAP_SIZE}px;
  border-radius: 6px;

  input {
    max-height: 30px;
  }
`;

export const SearchResults = styled(ShyScrollbar) <{ $hasMarginBottom: boolean }>`
  margin-bottom: ${p => (p.$hasMarginBottom ? `${GAP_SIZE}px` : 0)};
  max-height: 250px;
`;

export const StyledSelectLine = styled(SelectLine)`
  border-radius: 4px;
  padding: ${GAP_SIZE / 2}px 0;
  height: auto;
`;

export const ResultLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${GAP_SIZE}px;
`;

export const ResultTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditBtn = styled(ActionButton)`
  background-color: ${p => p.theme.colors.actionButton.bgHover};
`;

export const NewItemContainer = styled.div<{ $hasFlexNone?: boolean }>`
  display: flex;
  margin-bottom: ${GAP_SIZE}px;
  gap: ${GAP_SIZE}px;

  > div[data-popover="true"] {
    overflow: hidden;
    min-width: 25%;

    ${p => p.$hasFlexNone && css`
      &:nth-child(4) {
        flex: none;
        flex-grow: 0;
        min-width: initial;
      }
    `}
  }
`;

type NewItemButtonProps = {
  $isGray?: boolean;
  hoverDisabled: boolean;
  isSelected: boolean;
};
export const NewItemButton = styled(Button) <NewItemButtonProps>`
  background-color: ${p => (p.isSelected
    ? p.theme.colors.background.hover
    : p.theme.colors.background.tertiary)};
  /* TODO: reactivate when we iterate on a11n outline focus */
  /* outline: ${p => (p.isSelected ? `2px solid ${p.theme.userColors.main}` : 'none')}; */
  color: ${p => (p.$isGray ? p.theme.colors.text.secondary : p.theme.colors.text.primary)};
  padding: 3px 8px;
  width: 100%;

  &:hover, &:active, &:focus {
    background-color: ${p => p.theme.colors.background.hover};
  }
`;

export const ButtonContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_SIZE}px;
`;

export const NoResultSpinner = styled(Spinner)`
  margin: ${GAP_SIZE / 2}px auto ${GAP_SIZE + GAP_SIZE / 2}px auto;
`;

export const OtherDocTypesSpinner = styled(Spinner)`
  height: 20px;
`;

export const TooltipContent = styled.div`
  display: flex;
  gap: ${GAP_SIZE / 2}px;
  white-space: nowrap;
  align-items: center;
`;

export const SearchIcon = styled(SearchIconUI)`
  width: 12px;
  height: 12px;
`;

export const DocTypesContent = styled.div`
  min-width: 160px;
  padding: 8px 0;
`;
