import { PageId } from 'src/constants/routing.constant';
import { settings } from 'src/constants/settings.constants';
import { setCommandbar } from 'src/reactives/commandbar.reactive';
import { setLastView } from 'src/reactives/lastView.reactive';
import { CommandActionCategory } from 'src/types/commandbar.types';

import { useIsStandardPlan } from '../api/useProduct';
import { useNavigate } from '../useNavigate';

export const useSettingsResult = (): Array<CommandActionCategory> => {
  const { navigate } = useNavigate();
  const isStandardPlan = useIsStandardPlan();

  return [{
    id: 'settings',
    label: 'Settings',
    actions: Object.values(settings).filter(setting => setting.pageId !== PageId.SettingsBilling || isStandardPlan).map(setting => ({
      id: setting.id,
      label: setting.name,
      onSelect: () => {
        setLastView({ settingsFromUrl: window.location.pathname });
        navigate(setting.pageId);
        setCommandbar({ visible: false });
      },
    })),
  }];
};
