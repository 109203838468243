import { NotificationChannel, NotificationTrigger, NotificationType } from '@cycle-app/graphql-codegen/generated';
import { Button } from '@cycle-app/ui';
import { SlackIcon } from '@cycle-app/ui/icons';
import memoize from 'fast-memoize';
import { groupBy } from 'ramda';
import { FC, useMemo, Fragment } from 'react';

import { useToggleNotificationTrigger } from 'src/hooks/api/mutations/userSettings/useToggleNotificationTrigger';
import { useProduct } from 'src/hooks/api/useProduct';

import {
  SetupSlackInte,
  SetupSlackInteContent,
  Content,
  Section,
  SectionTitle,
  ChannelTitle,
  ChannelValue,
  TriggerDescription,
  Separator,
  StyledToggleInput,
} from './NotificationsTab.styles';

const ORDERED_CHANNELS: NotificationChannel[] = [NotificationChannel.Email, NotificationChannel.Slack];
const NOTIFICATION_TYPES = Object.values(NotificationType);

// TODO: remove reference to claps once backend is ready
const DESCRIPTION_BY_NOTIFICATION_TYPE: Record<NotificationType, string | null> = {
  WELCOME_NOTIFICATION: null,
  THREAD_CREATED: null,
  THREAD_UPDATED: null,
  USER_MENTIONED_IN_DOC: 'Someone mentioned you in a doc',
  USER_MENTIONED_IN_COMMENT: 'Someone mentioned you in a comment',
  THREAD_REPLIED: 'Someone commented on a doc you follow',
  DOC_CLAPPED: null,
  ASSIGNED_TO_DOC: 'Someone assigned you to a doc',
};

const READONLY_TRIGGERS_DESCRIPTION: string[] = [
  'You\'re assignee',
  'You\'re creator',
  'You added a comment',
  'You were mentioned in content',
  'You were mentioned in a comment',
];

interface Props {
  showSetupSlack: boolean;
}
const NotificationsTab: FC<Props> = ({ showSetupSlack }) => {
  const { product } = useProduct();
  const toggleNotificationTrigger = useToggleNotificationTrigger();

  const triggersByType = useMemo(
    () => groupBy<NotificationTrigger | null | undefined>((trigger) => trigger?.type ?? '')(product?.notificationTriggers ?? []),
    [product?.notificationTriggers],
  );

  const onTriggerClicked = useMemo(
    () => memoize((trigger: NotificationTrigger) => () => toggleNotificationTrigger(trigger)),
    [toggleNotificationTrigger],
  );

  return (
    <>
      {showSetupSlack && (
        <SetupSlackInte>
          <SlackIcon />
          <SetupSlackInteContent>
            <div>Set up your Slack notifications</div>
            <small>Get your Cycle notifications right from Slack with this integration</small>
          </SetupSlackInteContent>

          <Button size="M">Set up</Button>
        </SetupSlackInte>
      )}

      <Content>
        <Section>
          <SectionTitle>Notifications you receive</SectionTitle>
          {ORDERED_CHANNELS.map(channel => (
            <ChannelTitle key={channel}>{channel}</ChannelTitle>
          ))}
          {Object
            .keys(triggersByType)
            .map(type => {
              if (!isNotificationType(type)) return null;
              const description = DESCRIPTION_BY_NOTIFICATION_TYPE[type];
              if (!description) return null;
              return (
                <Fragment key={type}>
                  <TriggerDescription>{description}</TriggerDescription>
                  {ORDERED_CHANNELS.map(channel => {
                    const trigger = triggersByType[type].find(t => t?.channel === channel);
                    if (!trigger) return null;

                    return (
                      <ChannelValue
                        id={trigger.id}
                        key={channel}
                        checked={trigger.isActive}
                        onChange={onTriggerClicked(trigger)}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
        </Section>

        <Separator />

        <Section>
          <SectionTitle>Docs you&apos;re subscribed to</SectionTitle>
          {ORDERED_CHANNELS.map((i) => (<span key={i} />))}
          {READONLY_TRIGGERS_DESCRIPTION.map(description => (
            <Fragment key={description}>
              <TriggerDescription>{description}</TriggerDescription>
              <StyledToggleInput id={description} defaultChecked disabled />
              {ORDERED_CHANNELS.slice(1).map((i) => (<span key={i} />))}
            </Fragment>
          ))}
        </Section>
      </Content>
    </>
  );
};

function isNotificationType(type: string): type is NotificationType {
  return (NOTIFICATION_TYPES as string[]).includes(type);
}

export default NotificationsTab;
