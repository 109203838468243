import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

export const FileView = styled(NodeViewWrapper)`
  display: flex;
  border-radius: 6px;
  margin: 2px 0;
  ${({ $isSelected }) => $isSelected && css`
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${(p) => p.theme.userColors.main}
  `}
`;
