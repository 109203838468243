import { MoveDoctypeInProductListDocument } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useInsightDocType } from 'src/hooks/api/useDocType';
import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { useLoader } from 'src/hooks/useLoader';
import { useMobileSidebarActions } from 'src/hooks/useMobileSidebarActions';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useSortableDnd } from 'src/hooks/useSortableDnd';
import { DoctypeRouteParams } from 'src/types/routes.types';
import { isBuiltIn, isCustom, sortDocTypes } from 'src/utils/docType.util';

import { DRAFT_DOCTYPE } from '../../SettingsDocType/SettingsDocType';
import { SettingsNavigationSection, SettingsNavigationItem, Separator } from '../SettingsSidebar.styles';
import SettingsDocTypeItem from './SettingsDocTypeItem';

const SettingsDocTypeMenu: FC = () => {
  const pageId = usePageId();
  const { doctypeId: currentDoctypeId } = useParams<DoctypeRouteParams>();
  const hasDraft = currentDoctypeId === DRAFT_DOCTYPE.id;
  const docTypes = useProductDoctypes();

  const insight = useInsightDocType();
  const insightParentsIds = nodeToArray(insight?.parents).map(d => d.id);

  const { getUrl } = useNavigate();
  const { close: closeMobileSidebar } = useMobileSidebarActions();
  const builtInDocTypes = sortDocTypes(docTypes.filter(isBuiltIn));
  const otherDocTypes = docTypes.filter(isCustom);

  const [moveDoctypeInProductList, { loading }] = useSafeMutation(MoveDoctypeInProductListDocument);
  useLoader({ loading });

  const {
    activeId: draggingDocTypeId,
    dndContextProps,
    items: draggableDocTypeIds,
  } = useSortableDnd({
    initialItems: otherDocTypes.map(otherDocType => otherDocType.id),
    onFinish: ({
      position,
      itemMovedId,
    }) => moveDoctypeInProductList({
      variables: {
        doctypeId: itemMovedId,
        position,
      },
    }),
  });

  const draggingDoctype = draggingDocTypeId
    ? otherDocTypes.find(d => d.id === draggingDocTypeId)
    : undefined;

  if (!docTypes.length) return null;

  return (
    <SettingsNavigationSection>
      {builtInDocTypes.map((docType) => (
        <SettingsDocTypeItem
          key={docType.id}
          doctype={docType}
          isActive={docType.id === currentDoctypeId}
          url={getUrl(pageId, { doctypeId: docType.id })}
          onClick={closeMobileSidebar}
        />
      ))}
      <Separator />
      <DndContext {...dndContextProps}>
        <SortableContext items={draggableDocTypeIds} strategy={verticalListSortingStrategy}>
          {hasDraft && (
            <SettingsNavigationItem
              key="doctype-draft"
              icon={<Emoji emoji={DRAFT_DOCTYPE.emoji} />}
              label={DRAFT_DOCTYPE.sidebarName}
              isActive
              isSidebarColor
              isChild
            />
          )}
          {draggableDocTypeIds.map((doctypeId) => {
            const d = docTypes.find(({ id }) => id === doctypeId);

            if (!d) return null;

            const isLastDocType = draggableDocTypeIds.length < 2;

            const isLastInsightParent = insightParentsIds.length === 1 && insightParentsIds[0] === d.id;

            const deleteWarning =
            // eslint-disable-next-line no-nested-ternary
              isLastDocType
                ? 'Every workspace needs at least one doc type'
                : isLastInsightParent
                  ? 'Insight must be linked to at least one doc type'
                  : undefined;

            return (
              <SettingsDocTypeItem
                key={doctypeId}
                doctype={d}
                isActive={[draggingDocTypeId, currentDoctypeId].includes(doctypeId)}
                url={getUrl(pageId, { doctypeId })}
                onClick={closeMobileSidebar}
                isDeleteDisabled={isLastDocType || isLastInsightParent}
                deleteWarning={deleteWarning}
              />
            );
          })}
        </SortableContext>
        <DragOverlay>
          {draggingDoctype && (
            <SettingsNavigationItem
              label={draggingDoctype.name}
              isSortable
              isDragging
              isSidebarColor
              isChild
              icon={<Emoji emoji={draggingDoctype.emoji} />}
            />
          )}
        </DragOverlay>
      </DndContext>
    </SettingsNavigationSection>
  );
};

export default SettingsDocTypeMenu;
