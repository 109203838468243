export const getRandomItem = <DataType>(data: Array<DataType>): DataType => {
  const randomIndex = Math.floor(Math.random() * Math.floor(data.length));
  return data[randomIndex];
};

export const getMatchingIndex = (index: number, length: number) => index - (length * Math.floor(index / length));

export const toObject = <T extends { id: string }>(array: T[]): Record<string, T> => Object.fromEntries(array.map((entry) => [entry.id, entry]));

export const getArrayWithoutDuplicates = <T>(originalArray: T[]) => [...new Set(originalArray)];

type GetComaSeparatedValueParams = {
  array: string[];
};
export const getComaSeparatedValue = ({ array = [] }: GetComaSeparatedValueParams) => array.reduce(
  (acc, curr) => (acc ? `${acc}, ${curr}` : curr),
  '',
);

export const getPairSplitArray = <T>(initialArray: T[]): T[][] => initialArray.reduce<T[][]>((result, _value, index, array) => {
  if (index % 2 === 0) result.push(array.slice(index, index + 2));

  return result;
}, []);

export const last = <T>(array?: T[] | null) => (array ? array[array.length - 1] : undefined);
