import { HIGHLIGHT_EXTENSION_NAME, HIGHLIGHT_VIEW_PLUGIN_NAME } from '@cycle-app/editor-extensions';
import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Instance } from 'tippy.js';

import { useEditorContext } from 'src/contexts/editorContext';
import { HighlightViewPlugin, HighlightViewPluginProps } from 'src/editorExtensions/HighlightMark/HighlightViewPlugin';
import { useDocInsights } from 'src/hooks';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useToaster } from 'src/hooks/useToaster';
import { resetHighlight, setHighlight, useGetHighlight } from 'src/reactives/highlight.reactive';
import { ActionId, actions as editorActions } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';

import { InsightDropdown } from '../InsightDropdown/InsightDropdown';

export type BubbleMenuProps = Omit<Partial<HighlightViewPluginProps>, 'element' | 'editor'> & {
  className?: string;
  children?: React.ReactNode;
  updateDelay?: number;
  isEnabled: boolean;
};

type State = {
  defaultContent?: string;
  blockId: string | null;
  isCreate: boolean;
  overridePosition: DOMRect | null;
  isVisible: boolean;
};

export const HighlightMenu = ({
  pluginKey = HIGHLIGHT_VIEW_PLUGIN_NAME, tippyOptions = {}, updateDelay, className, isEnabled,
}: BubbleMenuProps) => {
  const {
    editor, doc,
  } = useEditorContext();
  const isActiveHighlight = editor.isActive(HIGHLIGHT_EXTENSION_NAME);
  const { add: addToaster } = useToaster();
  const {
    insights, isLoading: isInsightsLoading,
  } = useDocInsights(doc?.id, {
    skip: !isEnabled,
    fetchPolicy: 'cache-and-network', // We do not have real-time yet.
    onCompleted: (data) => {
      if (editor && data.node?.__typename === 'Doc' && data.node.docTargets.edges) {
        editor.commands.cleanPublishedHighlights?.({
          publishedIds: data.node.docTargets.edges.map(e => e.node?.blockId).filter(isNotEmpty),
        });
      }
    },
  });

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [{
    overridePosition, isCreate, defaultContent, blockId, isVisible,
  }, setState] = useState<State>({
    blockId: null,
    defaultContent: '',
    isCreate: false,
    overridePosition: null,
    isVisible: false,
  });
  const docTarget = useMemo(() => (
    insights.find(insight => insight?.blockId === blockId)), [blockId, insights]);
  const {
    blockIdsToDelete, blockIdCreating,
  } = useGetHighlight();

  const docTargetRef = useRef<typeof docTarget | null>(null);
  const insightsRef = useRef<typeof insights | null>(null);
  const insightsLoadingRef = useRef<boolean>(true);
  const blockIdCreatingRef = useRef<typeof blockIdCreating | null>(null);

  useEffect(() => {
    if (blockIdsToDelete?.length) {
      editor.chain().selectAll().unsetHighlightMark({ ids: blockIdsToDelete }).run();
    }
  }, [blockIdsToDelete, editor]);

  useEffect(() => resetHighlight, []);

  const handleTrigger = useCallback((e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isActiveHighlight) {
      addToaster({
        message: 'Your selection is already linked to an insight',
      });
      return;
    }
    editorActions[ActionId.TurnTextIntoInsight].toggle?.(editor);
  }, [editor, isActiveHighlight, addToaster]);

  insightsRef.current = insights;
  insightsLoadingRef.current = isInsightsLoading;
  docTargetRef.current = docTarget;
  blockIdCreatingRef.current = blockIdCreating;

  useAppHotkeys('command+1', handleTrigger, {
    enabled: isEnabled,
  }, [isEnabled]);
  useAppHotkeys('command+&', handleTrigger, {
    enabled: isEnabled,
  }, [isEnabled]);

  const onOpen: HighlightViewPluginProps['onOpen'] = (tippyInstance: Instance, {
    id, create, text,
  }) => {
    setHighlight({
      blockId: id,
      context: 'doc-content',
    });
    setState({
      blockId: id,
      defaultContent: text,
      isCreate: !!create,
      overridePosition: tippyInstance.props.getReferenceClientRect?.() ?? null,
      isVisible: true,
    });
  };

  const onClose = () => {
    if (!docTargetRef.current && !blockIdCreatingRef.current) {
      // This will undo the draft mark that we set.
      editor.commands.undo();
    }
    resetHighlight();
    setState(s => ({
      ...s,
      isVisible: false,
    }));
  };

  useEffect(() => {
    if (!element || !editor) {
      return () => {};
    }
    if (editor.isDestroyed) {
      return () => editor.unregisterPlugin(pluginKey);
    }
    editor.commands.cleanDraftHighlights?.();
    const plugin = HighlightViewPlugin({
      updateDelay,
      editor,
      element,
      pluginKey,
      tippyOptions,
      onOpen,
      onClose,
    });
    editor.registerPlugin(plugin);
    if (insightsRef.current && !insightsLoadingRef.current) {
      editor.commands.cleanPublishedHighlights?.({
        publishedIds: insightsRef.current.map(insight => insight?.blockId).filter(isNotEmpty),
      });
    }
    return () => editor.unregisterPlugin(pluginKey);
    // Intended.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, element]);

  const onHideDropdown = () => {
    if (!docTargetRef.current && !blockIdCreatingRef.current) {
      // This will undo the draft mark that we set.
      editor.commands.undo();
    }
    resetHighlight();
    setState({
      blockId: null,
      defaultContent: '',
      isCreate: false,
      overridePosition: null,
      isVisible: false,
    });
  };

  return (
    <div
      ref={setElement}
      className={className}
      style={{
        pointerEvents: 'all',
        visibility: 'hidden',
      }}
    >
      {blockId && doc && (
        <InsightDropdown
          key={blockId}
          blockId={blockId}
          dropdownProps={{
            placement: 'bottom',
            layer: Layer.DropdownModalZ2,
            ...overridePosition && { overridePosition },
          }}
          defaultContent={defaultContent}
          docTarget={docTarget}
          feedback={doc}
          isInitialVisible={isCreate}
          isVisible={isVisible}
          onHide={onHideDropdown}
          onInsightCreating={() => setHighlight({ blockIdCreating: blockId })}
          onInsightCreated={() => editor.commands.publishHighlightMark({ id: blockId })}
        />
      )}
    </div>
  );
};

function isNotEmpty(data?: string | null): data is string {
  return !!data;
}
