import { StatusCategory, StatusType } from '@cycle-app/graphql-codegen';
import { StatusIcon, Tooltip } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { categoryKeys } from 'src/constants/status.constants';
import { useProduct } from 'src/hooks';
import { useInsightDocType } from 'src/hooks/api/useDocType';
import { useGetWorkflows } from 'src/reactives/settingsWorkflows.reactive';
import { Layer } from 'src/types/layers.types';

import { CreateDocTypeStatusForm } from './CreateDocTypeStatusForm';
import { CreateStatusForm } from './CreateStatusForm';
import { SettingsStatus } from './SettingsStatus';
import {
  Category, CreateButton, Section, Statuses, StatusItem,
  STATUS_HEIGHT, STATUS_GAP,
} from './SettingsWorkflows.styles';

export type SettingsCategoryProps = {
  label: string;
  category: StatusCategory;
  currentDocTypeId?: string;
};

export const SettingsCategory = ({
  label, category, currentDocTypeId,
}: SettingsCategoryProps) => {
  const { product } = useProduct();
  const insight = useInsightDocType();
  const { lock: isWorkflowLocked } = useGetWorkflows();

  const usedNames = useMemo(() => {
    if (!product?.status) return [];
    return Object.values(product.status)
      .flatMap(v => (v === 'StatusDefinition' ? [] : v.edges.map(e => e.node.value)));
  },
  [product]);

  const statuses = useMemo(() => {
    const categoryKey = categoryKeys[category];
    const filtered = nodeToArray(product?.status?.[categoryKey])
      .filter(s => !currentDocTypeId || s.doctypes.edges.some(e => e.node.id === currentDocTypeId));
    return sortBy(filtered, 'position');
  }, [category, currentDocTypeId, product?.status]);

  if (!product || !statuses.length) return null;
  const isCreatingFromInsight = currentDocTypeId === insight?.id;
  const isDisabled = isCreatingFromInsight || isWorkflowLocked;

  return (
    <Section>
      <Category>
        <StatusIcon category={category} withBackground />
        {label}
        <ToggleDropdown
          placement="bottom"
          offsetY={8}
          layer={Layer.Dropdown}
          disabled={isCreatingFromInsight}
          button={props => (
            <Tooltip
              content={isCreatingFromInsight
                ? `It's not possible to create additional statuses for ${insight?.name}`
                : 'Create new status'}
              placement="top"
              style={{ cursor: isDisabled ? 'not-allowed' : 'unset' }}
              disabled={isDisabled}
            >
              <CreateButton
                {...props}
                disabled={isDisabled}
              >
                <AddIcon size={10} />
              </CreateButton>
            </Tooltip>
          )}
          content={props => (currentDocTypeId ? (
            <CreateDocTypeStatusForm
              category={category}
              usedNames={usedNames}
              onDone={props.hide}
              currentDocTypeId={currentDocTypeId}
            />
          ) : (
            <CreateStatusForm
              category={category}
              usedNames={usedNames}
              onDone={props.hide}
            />
          ))}
        />
      </Category>

      <Statuses $count={statuses.length}>
        {statuses.map((status, index) => (
          <StatusItem
            key={`${currentDocTypeId ?? ''}_${status.id}`}
            as={motion.div}
            initial={false}
            animate={{
              y: index * (STATUS_HEIGHT + STATUS_GAP),
            }}
            transition={{
              ease: 'easeInOut',
              duration: 0.15,
            }}
          >
            <SettingsStatus
              status={status}
              usedNames={usedNames}
              isDeletable={statuses.length > 1 && status.type === StatusType.Custom}
              docTypeId={currentDocTypeId}
              isDefault={!!currentDocTypeId && category === StatusCategory.NotStarted && index === 0}
            />
          </StatusItem>
        ))}
      </Statuses>
    </Section>
  );
};
