import { FC, ReactNode } from 'react';

import { ViewType } from '../../../../../graphql-codegen/generated';
import { DraggableCardProps } from '../DraggableCard/DraggableCard';
import {
  Cover,
  StyledDraggableCard,
  Title,
  Info,
  Toolbar,
  ToolbarSection,
  Actions,
} from './ViewCard.styles';

export interface ViewCardProps extends DraggableCardProps {
  title?: ReactNode;
  coverUrl?: string;
  preTitle?: ReactNode;
  preToolbar?: ReactNode;
  sectionStart?: ReactNode;
  sectionEnd?: ReactNode;
  viewType: ViewType;
  actions?: ReactNode;
  showActions?: boolean;
  isHover?: boolean;
  docParent?: ReactNode;
}

export const ViewCard: FC<ViewCardProps> = ({
  className,
  title,
  coverUrl,
  preTitle,
  preToolbar,
  sectionStart,
  sectionEnd,
  viewType,
  actions,
  showActions,
  isHover,
  docParent,
  children,
  ...draggableProps
}) => (
  <StyledDraggableCard
    className={className}
    isHover={isHover}
    {...draggableProps}
  >
    <Info viewType={viewType}>
      {viewType === ViewType.Kanban && coverUrl && (
        <Cover src={coverUrl} />
      )}

      {docParent}

      {preTitle}

      <Title isEmpty={!title}>
        {title ?? 'Untitled'}
      </Title>

      {preToolbar}

      <Toolbar>
        {sectionStart && <ToolbarSection>{sectionStart}</ToolbarSection>}
        {sectionEnd && <ToolbarSection>{sectionEnd}</ToolbarSection>}
        {actions && <Actions forceVisible={showActions}>{actions}</Actions>}
      </Toolbar>

    </Info>

    {children}
  </StyledDraggableCard>
);
