import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { body400, body500, caption500 } from '../../../theme/typo';
import { boxShadowZ1, boxShadowZ2 } from '../../../utils/styles.util';

export type Variant = 'primary' | 'light' | 'warning' | 'secondary' | 'ternary' | 'outlined' |'warning-secondary' | 'nospace';
export type Size = 'S' | 'M' | 'L';

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

interface ContainerProps {
  $variant: Variant;
  $size: Size;
  $isLoading?: boolean;
  $useUserColor?: boolean;
  $iconOnly?: boolean;
  $active?: boolean;
}

export const ButtonCss = css<ContainerProps>`
  --radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: var(--radius);
  background: var(--bg);
  color: var(--color);
  box-sizing: border-box;
  border: 1px solid var(--border);
  cursor: pointer;
   /* Required to position the spinner */
  position: relative;
  transition: box-shadow 100ms linear;

  &:disabled {
    pointer-events: none;
  }

  ${p => p.$isLoading && css`
    color: transparent;
    pointer-events: none;

    ${SpinnerContainer} {
      color: var(--color);
    }
  `}

  ${p => p.$size === 'S' && css`
    ${caption500};
    padding: 1px 8px;

    ${p.$iconOnly && css`padding: 8px;`}
  `}

  ${p => p.$size === 'M' && css`
    ${body500};
    padding: 3px 16px;

    ${p.$iconOnly && css`padding: 8px;`}
  `}

  ${p => p.$size === 'L' && css`
    ${body500};
    padding: 7px 24px;

    ${p.$iconOnly && css`padding: 12px;`}
  `}

  ${p => p.$variant === 'primary' && css`
    --bg: ${p.$useUserColor ? p.theme.userColors.main : p.theme.colors.button.noColor.bg};
    --color: ${p.$useUserColor ? p.theme.colors.text.white : p.theme.colors.button.noColor.color};
    --border: transparent;
    ${boxShadowZ1};

    &:hover, &:focus {
      --bg: ${darken(0.1, p.$useUserColor ? p.theme.userColors.main : p.theme.colors.button.noColor.bg)};
      ${boxShadowZ2};
      outline: none;
    }

    &:active {
      --bg: ${darken(0.2, p.$useUserColor ? p.theme.userColors.main : p.theme.colors.button.noColor.bg)};
      ${boxShadowZ2};
    }

    &:disabled {
      --bg: ${p.theme.colors.background.grey};
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'light' && css`
    --bg: ${p.theme.colors.button.light.bg};
    --color: ${p.theme.colors.text.secondary};
    --border: transparent;
    ${body400};
    padding-top: 0;
    padding-bottom: 0;

    &:hover, &:focus {
      --bg: ${p.theme.colors.button.light.bgHover};
      --color: ${p.theme.colors.text.primary};
      outline: none;
    }

    &:disabled {
      --color: ${p.theme.colors.text.disabled};
      --bg: ${p.theme.colors.background.disabled};
    }
  `};

  ${p => p.$variant === 'warning' && css`
    --bg: ${p.theme.colors.background.red};
    --color: ${p.theme.colors.text.white};
    --border: transparent;

    &:hover, &:focus {
      --bg: ${darken(0.05, p.theme.colors.background.red)};
    }

    &:active {
      --bg: ${darken(0.1, p.theme.colors.background.red)};
    }

    &:disabled {
      --bg: ${p.theme.colors.background.grey};
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'secondary' && css`
    --bg: ${p.theme.colors.button.secondary.bg};
    --color: ${p.$useUserColor && p.theme.colors.button.secondary.enableUserColor ? p.theme.userColors.main : p.theme.colors.button.secondary.color};
    --border: transparent;

    &:hover {
      --bg: ${p.theme.colors.button.secondary.bgHover};
    }
    &:active, &:focus {
      --bg: ${p.theme.colors.button.secondary.bgFocus};
      outline: none;
    }
    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'ternary' && css`
    --bg: ${p.theme.colors.button.ternary.bg};
    --color: ${p.theme.colors.button.ternary.color};
    --border: transparent;
    ${body400};

    &:hover {
      --bg: ${p.theme.colors.button.ternary.bgHover};
    }
    &:focus {
      --bg: ${p.theme.colors.button.ternary.bgFocus};
      outline: 2px solid ${p.theme.userColors.fg};
      outline-offset: -2px;
    }
    &:active {
      --bg: ${p.theme.colors.button.ternary.bgActive};
    }
    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'warning-secondary' && css`
    --bg: transparent;
    --color: ${p.theme.colors.button.warningSecondary.color};
    --border: transparent;

    &:hover {
      --bg: ${p.theme.colors.button.secondary.bgHover};
    }
    &:active, &:focus {
      --bg: ${p.theme.colors.button.secondary.bgFocus};
      outline: none;
    }
    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'outlined' && css`
    --bg: ${p.theme.colors.button.outlined.bg};
    --border: transparent;
    --color: ${p.theme.colors.button.outlined.color};
    ${body400};

    ${p.$active === false && css`
      &:hover, &:focus {
        --bg: ${p.theme.colors.button.outlined.bgHover};
        --color: ${p.theme.colors.button.outlined.colorHover};
      }
    `}
    ${p.$active === true && css`
      --bg: ${p.theme.colors.button.outlined.bgActive};
      --color: ${p.theme.colors.button.outlined.colorActive};
      outline: 2px solid ${p.theme.userColors.main};
    `}
    ${p.$active === undefined && css`
      &:active {
        --bg: ${p.theme.colors.button.outlined.bgActive};
        --color: ${p.theme.colors.button.outlined.colorActive};
        outline: 2px solid ${p.theme.userColors.main};
      }
    `};

    &:disabled {
      --bg: ${p.theme.colors.background.disabled};
      --color: ${p.theme.colors.text.disabled};
    }
  `}

  ${p => p.$variant === 'nospace' && css`
    --bg: transparent;
    --border: transparent;
    --color: ${p.theme.colors.text.secondary};
    padding: 0;

    &:hover, &:focus {
      --color: ${p.theme.colors.text.primary};
    }

    &:active {
      --color: ${p.theme.userColors.main};
    }

    &:disabled {
      --color: ${p.theme.colors.text.disabled};
    }
  `}
`;

export const Container = styled.button`${ButtonCss}`;
