import { FC, useState, useCallback, useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import DropdownLayer, { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useDocSearchProductTour } from 'src/hooks/productTour/useDocSearchProductTour';

import { SearchContainer, Input, SearchIcon } from './DocSearchDropdown.styles';
import { DocSearchResult, DocSearchResultProps } from './DocSearchResult';

import type { Placement } from 'tippy.js';

export interface SubmitParams {
  searchText: string;
  docId?: string;
}

type TargetContent = 'link' | 'docId';

interface Props extends DocSearchResultProps {
  className?: string;
  dropdownProps?: Partial<DropdownLayerProps>;
  hideSearchDropdown: VoidFunction;
  isSearchDropdownVisible?: boolean;
  onSubmit?: (p: SubmitParams) => void;
  placeholder?: string;
  placement?: Placement;
  targetContent?: TargetContent;
}

const DocSearchDropdown: FC<Props> = ({
  children,
  className = '',
  dropdownProps,
  hideSearchDropdown,
  inheritedAttributes,
  isSearchDropdownVisible = false,
  onAdd: onAddProps,
  onSubmit,
  placeholder = 'Search...',
  placement = 'bottom-start',
  targetContent = 'docId',
  ...docSearchProps
}) => {
  const [search, setSearch] = useState('');
  const searchEnabled = useMemo(() => search.substr(0, 4) !== 'http', [search]);
  const {
    isActive: isProductTourActive, setDocSearchModalEl,
  } = useDocSearchProductTour();

  const [searchDebounced] = useDebounce(search, INPUT_ONCHANGE_DEBOUNCE);

  const addAdd = useCallback((docId: string) => {
    onAddProps?.(docId);
    onSubmit?.({
      docId,
      searchText: search,
    });
  }, [onAddProps, onSubmit, search]);

  return (
    <DropdownLayer
      visible={isSearchDropdownVisible}
      hide={isProductTourActive ? undefined : hideSearchDropdown}
      placement={placement}
      content={(
        <SearchContainer
          className={className}
          onClick={e => e.stopPropagation()}
          $isSearching={!!search}
          ref={el => setDocSearchModalEl(el)}
        >
          <Input
            type="text"
            placeholder={placeholder}
            onChange={(e) => setSearch(e.target.value)}
            autoFocus
            onKeyUp={(e) => {
              if (!searchEnabled && e.code === 'Enter') {
                onSubmit?.({
                  searchText: search,
                });
              }
            }}
            value={search}
            iconBefore={<SearchIcon />}
          />
          {(targetContent === 'docId' || searchEnabled) && (
            <DocSearchResult
              {...docSearchProps}
              search={searchDebounced}
              onAdd={addAdd}
              inheritedAttributes={inheritedAttributes}
            />
          )}
        </SearchContainer>
      )}
      {...dropdownProps}
    >
      {children}
    </DropdownLayer>
  );
};

export default DocSearchDropdown;
