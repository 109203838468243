import { MateFragment } from '@cycle-app/graphql-codegen';
import { EyeIcon } from '@cycle-app/ui/icons';
import { VFC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useBoard } from 'src/hooks/api/useBoard';
import { useDoc } from 'src/hooks/api/useDoc';
import { useUrl } from 'src/hooks/useUrl';
import { getBoardSlug, getDocSlug } from 'src/utils/slug.util';
import { INCOGNITO_NAME } from 'src/utils/users.util';

import {
  MoreUsersLine,
  UserInfo,
  ViewingLink,
  UserName,
  StyledAvatar,
} from './RealtimeUsers.styles';
import { UserLocation } from './UserLocation';

interface Props {
  user: MateFragment;
  hideDropdown: VoidFunction;
}

const RealtimeMoreLine: VFC<Props> = ({
  user, hideDropdown,
}) => {
  const getUrl = useUrl();
  const board = useBoard(user._boardId, !user._boardId);
  const { doc } = useDoc(user._docId, !user._docId);

  const locationUrl = doc
    ? getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) })
    : getUrl(PageId.Board, { boardSlug: board ? getBoardSlug(board) : '' });

  const displayedName = user.incognito ? INCOGNITO_NAME : `${user.firstName} ${user.lastName}`;

  return (
    <MoreUsersLine key={user.id}>
      <StyledAvatar user={user} />

      <UserInfo>
        <UserName>
          {displayedName}
        </UserName>

        <ViewingLink onClick={hideDropdown} to={locationUrl}>
          <EyeIcon />
          <span>
            Viewing
            {' '}
            <UserLocation user={user} />
          </span>
        </ViewingLink>
      </UserInfo>
    </MoreUsersLine>
  );
};

export default RealtimeMoreLine;
