import { MateFragment } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Placement } from 'tippy.js';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useMe } from 'src/hooks/api/useMe';
import { Layer } from 'src/types/layers.types';
import { getUserOptions } from 'src/utils/users.util';

type Props = {
  children: ReactNode;
  dropdownPlacement?: Placement;
  hasWarningOnNoneValue?: boolean;
  isVisible?: boolean;
  layer?: Layer;
  onChange?: (user: MateFragment | null) => void;
  onClear?: VoidFunction;
  onHide?: VoidFunction;
  onShow?: VoidFunction;
  selectedId?: string;
  isRemovable?: boolean;
  showWarnings?: boolean;
};

export const DocAssigneesDropdown: FC<Props> = ({
  children,
  dropdownPlacement = 'bottom-start',
  hasWarningOnNoneValue = false,
  isVisible,
  layer = Layer.Dropdown,
  onChange,
  onClear,
  onHide,
  onShow,
  selectedId,
  isRemovable = true,
  showWarnings,
}) => {
  const users = useBoardConfig(ctx => ctx.usersForAssignee);
  const { me } = useMe();

  const options: Array<SelectOption> = useMemo(() => getUserOptions(users, me, showWarnings), [users, me]);

  const onClearValue = useCallback(async () => {
    onHide?.();
    onClear?.();
    onChange?.(null);
  }, [onHide, onClear, onChange]);

  const onSelectUser = useCallback(
    async (option: SelectOption) => {
      const selectedUser = users.find(u => u.id === option.value);
      if (selectedUser) {
        onChange?.(selectedUser);
      }
      onHide?.();
    },
    [users, onHide, onChange],
  );

  return (
    <DropdownSelectLayer
      hide={onHide}
      layer={layer}
      onChange={onSelectUser}
      onClearValue={isRemovable ? onClearValue : undefined}
      options={options}
      placement={dropdownPlacement}
      searchPlaceholder="Search Assignee"
      selectedValue={selectedId}
      setVisible={onShow}
      visible={isVisible}
      warningOnNoneValue={hasWarningOnNoneValue}
    >
      {children}
    </DropdownSelectLayer>
  );
};
