import { BoardWithDraftConfigFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, StatusIcon, SelectOption } from '@cycle-app/ui';
import { EyeClosedIcon } from '@cycle-app/ui/icons';
import sumBy from 'lodash/sumBy';
import { FC } from 'react';

import { LightButton } from 'src/components/BoardConfigForm/BoardConfigForm.styles';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import useManageGroupby from 'src/hooks/api/mutations/boardConfig/useManageGroupby';
import { useDefaultNotStartedStatus } from 'src/hooks/status/useDefaultNotStartedStatus';
import { isBoardQueryWithGroupBy, isStatus } from 'src/types/graphql.types';
import { Layer } from 'src/types/layers.types';

import { Container } from './InboxGroupByStatus.style';

export type InboxGroupByStatusProps = {
  boardId: string;
  draftBoardConfig: NonNullable<BoardWithDraftConfigFragment['draftBoardConfig']>;
};

export const InboxGroupByStatus: FC<InboxGroupByStatusProps> = ({
  boardId, draftBoardConfig,
}) => {
  const defaultNotStartedStatus = useDefaultNotStartedStatus();
  const mutations = useManageGroupby(draftBoardConfig.id, boardId);

  if (!isBoardQueryWithGroupBy(draftBoardConfig.docQuery)) return null;

  const properties = draftBoardConfig.docQuery.groupbyConfig.values.edges;

  const hiddenCount = sumBy(properties, 'node.hidden');

  const options = properties.reduce<SelectOption[]>((acc, { node }) => {
    if (!isStatus(node.propertyValue)) return acc;
    const label = node.propertyValue.value;
    const disabled = node.propertyValue.id === defaultNotStartedStatus?.id;
    acc.push({
      value: node.id,
      label,
      selected: !node.hidden,
      icon: <StatusIcon category={node.propertyValue.category} withBackground />,
      disabled,
      tooltipContent: disabled ? `${label} can’t be hidden` : null,
      tooltipPlacement: 'top-end',
      tooltipOffset: [60, 5],
    });
    return acc;
  }, []);

  return (
    <Container>
      <ToggleDropdown
        placement="right"
        layer={Layer.DropdownModalZ1}
        button={props => (
          <LightButton {...props}>
            <EyeClosedIcon />
            <div>{getButtonLabel(hiddenCount)}</div>
          </LightButton>
        )}
        content={(
          <SelectPanel
            isMulti
            options={options}
            onSelectOption={option => mutations.showBoardConfigGroupValue(option.value)}
            onUnselectOption={option => mutations.hideBoardConfigGroupValue(option.value)}
            onSelectAll={mutations.showAllGroups}
            onUnselectAll={() => Promise.all(options
              .filter(option => !option.disabled)
              .map(option => mutations.hideBoardConfigGroupValue(option.value)))}
            selectAllLabel="Show all"
            unselectAllLabel="Hide all"
          />
        )}
      />
    </Container>
  );
};

const getButtonLabel = (count: number) => (count
  ? `${count} hidden ${count === 1 ? 'status' : 'statuses'}`
  : 'Hide statuses');
