import { ListPositionInput } from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { FC, ReactNode, useMemo } from 'react';

import { useSortableDnd } from 'src/hooks/useSortableDnd';

import SelectOptionsEditableLineSortable from './SelectOptionsEditableLineSortable';
import { Container } from './SelectOptionsManager.styles';

interface Props {
  options: SelectOption[];
  onDeleteOption?: (optionId: string) => void;
  onEditOption?: (optionId: string, textValue: string) => void;
  lastLine?: ReactNode;
  sortable?: boolean;
  onSorted?: (itemId: string, position: ListPositionInput, sortedItem: Array<string>) => void;
  isSaving?: boolean;
}
const SelectOptionsManager: FC<Props> = ({
  options,
  lastLine,
  onEditOption,
  onDeleteOption,
  sortable,
  onSorted,
  isSaving,
}) => {
  const initialItems = useMemo(() => options.map(option => option.value), [options]);

  const {
    items,
    activeId,
    dndContextProps,
  } = useSortableDnd({
    initialItems,
    onFinish: ({
      position, itemMovedId, items: sortedItems,
    }) => {
      onSorted?.(itemMovedId, position, sortedItems);
    },
  });

  return (
    <Container>
      <DndContext {...dndContextProps}>
        <SortableContext items={items}>
          {items.map(itemId => {
            const option = options.find(o => o.value === itemId);
            if (!option) return null;
            return (
              <SelectOptionsEditableLineSortable
                key={option.value}
                option={option}
                onDelete={onDeleteOption}
                onEdit={onEditOption}
                sortable={sortable}
                disableHover={!!activeId && activeId !== itemId}
                isDragging={activeId === itemId}
                isSaving={isSaving}
              />
            );
          })}
          {lastLine}
        </SortableContext>
      </DndContext>
    </Container>
  );
};

export default SelectOptionsManager;
