import { FC, useEffect } from 'react';

import { variantsContent } from 'src/components/DialogModal/DialogModal.motion';
import { useCommandbar } from 'src/reactives/commandbar.reactive';
import { setLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

import { Container, PortalModalStyled } from './CommandbarModal.styles';
import CommandK from './CommandK/CommandK';

const CommandbarModal: FC = () => {
  const [{ visible }, setCommandbar] = useCommandbar();

  useEffect(() => {
    setLayer(Layer.Commandbar, visible);
  }, [visible]);

  if (!visible) return null;

  return (
    <PortalModalStyled
      layer={Layer.Commandbar}
      hide={hide}
      naked
      invisibleMask
      animate
      motionVariants={variantsContent}
    >
      <Container>
        <CommandK />
      </Container>
    </PortalModalStyled>
  );

  function hide() {
    setCommandbar({ visible: false });
  }
};

export default CommandbarModal;
