export enum OnboardingScreen {
  Boards,
  Collaboration,
  Colors,
  ConfigureAccount,
  CreateDocs,
  CreatePassword,
  EditDocs,
  Integrations,
  Welcome,
  Chrome,
}

export enum LightOnboardingScreen {
  Welcome = 'welcome',
  AccountVerify = 'verify',
  AccountInfos = 'infos',
  AccountPreferences = 'preferences',
  Workspace = 'workspace',
  Readonly = 'readonly',
  Readonly2 = 'readonly2',
  Readonly3 = 'readonly3',
  Readonly4 = 'readonly4',
  Done = 'done',
}
