import { AddIcon } from '@cycle-app/ui/icons';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Item } from 'src/app/Main/Sidebar/SidebarFooterItem';

export const AddMemberIcon = styled(AddIcon)`
  width: 10px;
  height: 10px;
  margin: 3px;
`;

export const DropdownPanel = styled.div`
  padding: 8px;
  border-radius: 6px;
  background: ${p => p.theme.colors.sidebar.dropdown.bg};
`;

export const DropdownSeparator = styled.div`
  height: 1px;
  background: ${p => p.theme.colors.sidebar.dropdown.separator};
  margin: 8px 0;
`;

export const NavigationItem = styled(Item)`
  font-size: 13px; 
  padding: 6px 6px 6px 14px;
  margin: 0 -8px;
  &[data-active=true] {
    color: ${p => p.theme.colors.sidebar.colorHover};
    background: ${p => p.theme.colors.sidebar.item.bgHover};
  }
`;

export const DropdownItem = styled(Item)`
  font-size: 14px;
  color: ${p => p.theme.colors.sidebar.dropdown.text};
`;

export const DropdownSecondaryItem = styled(Item)`
  font-size: 12px;
  padding: 4px 8px;
`;

export const LearningCenterContainer = styled.div`
  position: relative;
`;

const bluePointStyle = css`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BluePoint = styled.div`
  ${bluePointStyle}
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  background: ${p => transparentize(0.95, p.theme.userColors.main)};
`;

export const BluePointInnerMiddle = styled.div`
  ${bluePointStyle}
  width: 16px;
  height: 16px;
  background: ${p => transparentize(0.8, p.theme.userColors.main)};
`;

export const BluePointInnerSmall = styled.div`
  ${bluePointStyle}
  width: 8px;
  height: 8px;
  background: ${p => p.theme.userColors.main};
`;
