import { typo } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export interface ContainerProps {
  selected: boolean;
  $disableHover?: boolean;
}

export const Container = styled.div<ContainerProps>`
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
  gap: 8px;
  align-items: center;
  min-width: 0;

  background-color: transparent;
  ${p => !p.$disableHover && css`
    &:hover {
      background-color: ${p.theme.colors.sidebar.item.bgHover};
    }
  `}
`;

interface LogoProps {
  bgSrc?: string;
}
export const Logo = styled.div<LogoProps>`
  width: 26px;
  height: 26px;
  border-radius: 4px;

  background-image: ${p => (`url(${p.bgSrc})` || 'none')};
  background-size: cover;
  background-position: center;

  flex-shrink: 0;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
`;

export const ProductName = styled.div`
  ${typo.body500};
  line-height: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Members = styled.div`
  ${typo.caption400};
  line-height: 16px;
  color: ${p => p.theme.colors.sidebar.color};
`;

export const ActiveIcon = styled(CheckIcon)`
  margin-left: auto;
  margin-right: 8px;
`;
