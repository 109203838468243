import { DoctypeFragment, DoctypeRelativeFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { isPresent } from 'ts-is-present';

const sortedNames = ['Feedback', 'Insight'];

type SortByNameFn = <T extends { name: string }>(items: T[]) => T[];

export const sortDocTypes: SortByNameFn = (docTypes) => [
  ...sortedNames.map(name => docTypes.find(d => d.name === name)).filter(isPresent),
  ...docTypes.filter(d => !sortedNames.includes(d.name)),
];

export const isFeedback = (docType?: DoctypeRelativeFragment) => docType && docType.type === DoctypeType.Feedback;

export const isInsight = (docType?: DoctypeRelativeFragment) => docType && docType.type === DoctypeType.Insight;
export const isNotInsight = (docType?: DoctypeRelativeFragment) => !isInsight(docType);
export const isBuiltIn = (docType?: DoctypeRelativeFragment) => isFeedback(docType) || isInsight(docType);
export const isCustom = (docType?: DoctypeRelativeFragment) => !isBuiltIn(docType);

export const findFeedback = <T extends DoctypeRelativeFragment>(docTypes: T[]) => docTypes.find(isFeedback);

export const findInsight = <T extends DoctypeRelativeFragment>(docTypes: T[]) => docTypes.find(isInsight);

export const getPotentialRelatives = (
  docTypes: DoctypeFragment[],
  docType?: DoctypeFragment | null,
) => {
  const parents = nodeToArray(docType?.parents);
  const doctypeChildren = nodeToArray(docType?.children);
  const relatives = [...parents, ...doctypeChildren];
  const relativeIds = relatives.map(({ id }) => id);
  return docTypes.filter(d => !relativeIds.includes(d.id) && d.id !== docType?.id && isCustom(d));
};

type GetDocTypeNames = (
  docTypes: { name: string }[],
  options?: {
    prefix?: string;
    suffix?: string;
  }
) => string;

export const getDocTypeNames: GetDocTypeNames = (
  docTypes,
  options,
) => {
  const prefix = options?.prefix || '';
  const suffix = options?.suffix || '';

  return docTypes.reduce((prev, docType, index, array) => {
    const comaToAdd = prev === prefix ? '' : ',';

    if (index === array.length - 1) {
      return `${prev}${comaToAdd} ${docType.name.toLowerCase()}${suffix}`;
    }

    return `${prev}${comaToAdd} ${docType.name.toLowerCase()}`;
  }, prefix);
};
