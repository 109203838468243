import { typo, Button, DropdownSelect } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

const reactFlowOverwrites = css`
  .react-flow__node {
    cursor: default;
    background: transparent;
    border: none;
    padding: 0;
    text-align: left;
    box-shadow: none !important;
    width: auto !important;
    height: auto !important;
    color: inherit;
  }

  .react-flow__handle {
    visibility: hidden;
  }

  .react-flow__edge {
    color: ${p => p.theme.colors.border.primary};
  }

  .react-flow__edge-path {
    stroke: currentColor;
    fill: none;
  }

  .react-flow__edge-circle {
    stroke: ${p => p.theme.colors.text.disabled};
    fill: ${p => p.theme.colors.background.primary};
  }
`;

export const IntegrationsContainer = styled.div<{ $showFooter: boolean }>`
  width: 303px;
  footer {
    margin-top: 16px;
    opacity: ${p => (p.$showFooter ? 1 : 0)};
    transition: opacity .2s ease-in-out;
  }
  &:hover {
    footer {
      opacity: 1;
    }
  }
`;

export const IntegrationsTitle = styled.h3`
  ${typo.headerLight}
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IntegrationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddButton = styled(Button).attrs({
  variant: 'light',
  size: 'L',
})`
  width: 100%;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  color: ${p => p.theme.colors.text.secondary};
  &:hover {
    color: ${p => p.theme.colors.text.secondary};
  }
  &:active {
    outline: none;
    color: ${p => p.theme.colors.text.primary};
    background-color: ${p => p.theme.colors.background.hover};
  }
  > svg {
    width: 10px;
    height: 10px;
  }
`;

export const Dropdown = styled(DropdownSelect)`
  [data-disabled=true] {
    filter: grayscale(1);
    opacity: 0.6;
  }
`;

export const Info = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  margin-left: 40px;
`;

export const FlowContainer = styled.div`
  ${reactFlowOverwrites}
  min-width: 800px;
`;

export const Handle = styled.div`
  width: 1px;
  height: 1px;
`;
