import { motion } from 'framer-motion';
import styled from 'styled-components';

import * as typo from '../../theme/typo';
import { DropdownCss } from '../Dropdown/Dropdown.styles';
import { SelectLine } from '../Selects/SelectLine/SelectLine';

const containerPadding = 12;

export const Container = styled(motion.div)<{ large?: boolean }>`
  ${DropdownCss};
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: ${containerPadding}px;
  min-width: ${p => (p.large ? 390 : 268)}px;
  max-height: max(310px, calc(50vh - 40px));
`;

export const Category = styled.div`
  & + & {
    margin-top: 12px;
  }
`;

export const CategoryTitle = styled.h3`
  margin-bottom: 8px;
  text-transform: uppercase;
  ${typo.caption500};
  color: ${p => p.theme.colors.text.disabled};
`;

export const Actions = styled.div`
  margin-left: -${containerPadding}px;
  margin-right: -${containerPadding}px;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledSelectLine = styled(SelectLine)`
  padding-left: ${containerPadding}px;
  padding-right: ${containerPadding}px;
`;
